import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ResponsiveStyleValue, SpaceType } from "../../../types";

export interface TabsContainerProps {
  gap?: ResponsiveStyleValue<SpaceType>;
  isGrow?: ResponsiveStyleValue<boolean>;
}

export const Container = styled.div<TabsContainerProps>(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spaces.md};
  `,
  ({ theme, gap }) =>
    makeResponsiveStyles(theme, gap, (x) => ({
      gap: x ? theme.spaces[x] : undefined,
    })),
  ({ theme, isGrow }) =>
    makeResponsiveStyles(theme, isGrow, (x) => {
      // eslint-disable-next-line no-nested-ternary
      const growValue = x === undefined ? undefined : x ? 1 : 0;

      return { flexGrow: growValue, flexShrink: growValue };
    }),
);
