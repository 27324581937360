import { Theme } from "@sideg/ui-kit";
import { BaseCssProps, ElementColor } from "../../../ui-kit/types";
import { Box } from "../../../ui-kit/containers/box";
import { Typography } from "../../../ui-kit/typography";
import { BadgeContainer } from "../../../ui-kit/containers/badge";
import { ProductDetailsEmptyCircle } from "./ProductDetailsEmptyCircle";
import { StackPanel } from "../../../ui-kit/containers/stack-panel";
import { useProductDetailsEmptyCircleConicGradient } from "../../hooks/empty/useProductDetailsEmptyCircleConicGradient";
import { ProductDetailsEmptyStage } from "./stage/ProductDetailsEmptyStage";
import { Color } from "../../../ui-kit/theme";
import { declensionHelper } from "../../../helpers/entity";

const BoxTypography = Box.withComponent(Typography);

export interface ProductDetailsEmptyProps<TStage extends string> extends BaseCssProps {
  activeTasksCount: number;
  banksCount: number;
  counters: Record<TStage, number | undefined>;
  stageValues: TStage[];
  getStageColor: (stage: TStage) => ElementColor;
  getShortTitle: (type: TStage) => string;
  getStageBackgroundColor: (stage: TStage, theme: Theme) => Color;
}

export const ProductDetailsEmpty = <TStage extends string>({
  activeTasksCount,
  counters,
  stageValues,
  getStageColor,
  getStageBackgroundColor,
  getShortTitle,
  banksCount,
  className,
}: ProductDetailsEmptyProps<TStage>) => {
  const gradient = useProductDetailsEmptyCircleConicGradient<TStage>(counters, stageValues, getStageColor);

  return (
    <Box className={className} display="flex" flexDirection="column" alignItems="center" gap="xxl">
      <ProductDetailsEmptyCircle banksCount={banksCount} gradient={gradient} />
      <StackPanel gap="xs" direction="row" className={className}>
        {stageValues.map((x) => (
          <ProductDetailsEmptyStage
            color={getStageColor(x)}
            getStageBackgroundColor={getStageBackgroundColor}
            stage={x}
            counter={counters[x]}
            shortTitle={getShortTitle(x)}
            key={x}
          />
        ))}
      </StackPanel>
      {activeTasksCount !== undefined && activeTasksCount > 0 && (
        <BadgeContainer px="lg" py="sm">
          <Typography fontSize="fz3" lineHeight="defaultLineHeight" fontWeight={500}>
            {declensionHelper.commonDeclensions.activeTasks.nominative(activeTasksCount, true)}
          </Typography>
        </BadgeContainer>
      )}
      <BoxTypography
        pt="xxl"
        fontWeight={500}
        fontSize="fz4"
        lineHeight="1.5"
        textAlign="center"
        fontColor="typography.primary"
      >
        Выберите заявку в конкретный банк,
        <br />
        чтобы посмотреть детали
      </BoxTypography>
    </Box>
  );
};
