import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import { rootReducer, RootState } from "./rootReducer";
import { loggingSentryInitService } from "../logging/providers/sentry";
import { listenerMiddleware } from "./middlewares/listenerMiddleware";

// TODO: включить проверку на сериализацию и имутабельность после переезда на домены
export const store = configureStore({
  reducer: rootReducer,
  devTools: import.meta.env.MODE !== "production",
  enhancers: [loggingSentryInitService.getReduxEnhancer()],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).prepend(listenerMiddleware),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).prepend(listenerMiddleware),
  });
};
