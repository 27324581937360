
const monthsShort = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']

class DateFormatter {

    private _date: Date
    private _result: string[] = []

    public hours: number
    public minutes: number
    public seconds: number
    public day: number
    public month: number
    public monthIndex: number
    public year: number

    constructor(date: string | Date | number) {
        this._date = 
            typeof date === 'string' || typeof date === 'number' 
            ? new Date(date) 
            : date

        this.hours = this._date.getHours()
        this.minutes = this._date.getMinutes()
        this.seconds = this._date.getSeconds()
        this.day = this._date.getDate()
        this.monthIndex = this._date.getMonth()
        this.month = this.monthIndex + 1
        this.year = this._date.getFullYear()
    }

    /**
     * Returns time in chosen format (12:00 or 12:00:00)
     * @example new DateFormatter('2021-01-01T12:00:00.000').getTime() // returns '12:00'
     * @example new DateFormatter('2021-01-01T12:00:00.000').getTime(true) // returns '12:00:00'
     * 
     * @param {boolean} withSeconds
     * @returns {string} Formatted time
     */
     public getTime(withSeconds: boolean = false): string {
        if (withSeconds) {
            return (
                this.formatWithZeros(this.hours.toString()) + ':' +
                this.formatWithZeros(this.minutes.toString()) + ':' +
                this.formatWithZeros(this.seconds.toString())
            )
        }

        return (
            this.formatWithZeros(this.hours.toString()) + ':' + 
            this.formatWithZeros(this.minutes.toString())
        )
    }

    /**
     * Returns date in chosen format
     * @example DateFormatter.getDateWithYear('2021-01-01T12:00:00.000', '/') // returns 01/01/2021
     * 
     * @param {string | Date} input 
     * @param {string} divider 
     * @param {boolean} reverse 
     * @returns {string} Date in chosen format
     */
    static getDateWithYear(input: Date | string, divider: string = '.', reverse: boolean = false): string {
        const date = typeof input === 'string' ? new Date(input) : input

        const day = this.formatWithZeros(date.getDate().toString())
        const month = this.formatWithZeros((date.getMonth() + 1).toString())
        const year = date.getFullYear()

        if (reverse) 
        return year + divider + month + divider + day

        return day + divider + month + divider + year
    }

    static formatWithZeros(val: string): string {
        return val.length > 1 ? val : '0' + val
    }

    public withDay(): DateFormatter {
        this._result.push(this.day.toString())

        return this
    }

    public withMonth(): DateFormatter {
        this._result.push(months[this.monthIndex])

        return this
    }

    public withShortMonth(): DateFormatter {
        this._result.push(monthsShort[this.monthIndex])

        return this
    }

    public withTime(): DateFormatter {
        this._result.push(
            this.hours + ':' +
            DateFormatter.formatWithZeros(this.minutes.toString())
        )

        return this
    }

    public create(): string {
        const result = this._result.join(' ')
        this._result = []

        return result
    }

    public formatWithZeros(val: string) {
        return val.length > 1 ? val : '0' + val
    }
}

export default DateFormatter