import { ClientSearchState } from "../../../client-search";
import { RootState } from "../../../common/store";
import { CreditWorkingAssetsCreationState } from "./creditWorkingAssetsCreation.types";

const selectBase = (state: RootState) => state.domains.creditWorkingAssets.creation;

export const selectCreditWorkingAssetsCreation = (state: RootState): CreditWorkingAssetsCreationState =>
  selectBase(state).form;

export const selectCreditWorkingAssetsCreationClientSearch = (state: RootState): ClientSearchState =>
  selectBase(state).clientSearch;

export const selectCreditWorkingAssetsCreationCreating = (state: RootState) =>
  selectCreditWorkingAssetsCreation(state).creating;

export const selectCreditWorkingAssetsCreationScoring = (state: RootState) =>
  selectCreditWorkingAssetsCreation(state).scoring;
