import effects from "@sideg/ui-kit/core/theme/__generated__/effects.gen";
import { ExtractTypeFromConst } from "@sideg/ui-kit/core/theme/types/utils/ExtractTypeFromConst";
import { ThemeEffect } from "@sideg/ui-kit/core/theme/types/effects/ThemeEffect";
import { ExtractPaths } from "@sideg/ui-kit/core/theme/types/utils/ExtractPaths";

export type ThemeEffectValue = ThemeEffect | { [key: string]: ThemeEffectValue };

export const generatedEffects = effects satisfies ThemeEffectValue;

export type ThemeEffects = ExtractTypeFromConst<typeof generatedEffects, ThemeEffect>;
export type ThemeEffectsValues = ExtractPaths<ThemeEffects, ThemeEffect>;
