import { useEffect } from "react";
import { useAppSelector } from "../../../common/store";
import { getQueueTasksCountersSelector } from "../store/queueTasksCounters.selectors";

const setAppBadge = (count: number | undefined) => {
  if (count !== undefined && "setAppBadge" in navigator) {
    (navigator as unknown as { setAppBadge: (count: number) => void }).setAppBadge(count);
  }
};

export const useQueueTasksCountersNewCount = () => {
  const count = useAppSelector(getQueueTasksCountersSelector(({ data }) => data?.countQueueTasksNew));

  useEffect(() => {
    setAppBadge(count);
  }, [count]);

  return count;
};
