import { CLIENT_ROOT_URL } from "../../common/urls";
import { makeAppUrl } from "../../../common/urls/internal/utils";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const clientCheckUrls = {
  root: makeAppUrl(
    `${CLIENT_ROOT_URL}-check`,
    "/client-check",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsClientCheck),
  ),
};
