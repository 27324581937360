import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { isArraysContainsSameItems } from "@sideg/helpers";
import { queueTasksListAdapter } from "./queueTasksList.adapter";
import { getLoadingStatusFromAction, isAborted, LoadingStatusEnum } from "../../../common/types/state";
import {
  getQueueTasksList,
  getQueueTasksListCounters,
  getQueueTasksListFilters,
  QUEUE_TASKS_LIST_SLICE_NAME,
} from "./queueTasksList.thunks";
import { QueueTasksListSliceState } from "./queueTasksList.types";
import { completeQueueTask, queueTasksNeedUpdate } from "../../common/store";
import { queueTasksListFilterParamsDefaultKeys } from "../types/filter/QueueTasksListFilterParams";
import { getObjectNotUndefinedKeys } from "../../../common/utils/getObjectNotUndefinedKeys";
import { queueTasksListMapper } from "./queueTasksList.mapper";

const initialState = queueTasksListAdapter.getInitialState<QueueTasksListSliceState>({
  status: LoadingStatusEnum.Idle,
  filters: {
    status: LoadingStatusEnum.Idle,
    data: undefined,
  },
  countersStatus: LoadingStatusEnum.Idle,
  hasRealtimeUpdates: false,
  isDefaultFiltering: true,
  isNewTasksFilter: false,
  hasSuggestions: false,
});

const slice = createSlice({
  name: QUEUE_TASKS_LIST_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(queueTasksNeedUpdate, (state, { payload }) => {
        if (
          !state.lastUpdate ||
          state.lastUpdate.queueTaskId !== payload.queueTaskId ||
          state.lastUpdate.actionType !== payload.actionType
        ) {
          state.lastUpdate = {
            queueTaskId: payload.queueTaskId,
            actionType: payload.actionType,
          };

          if (payload.queueTask && payload.actionType === "Updated") {
            queueTasksListAdapter.updateOne(state, { id: payload.queueTaskId, changes: payload.queueTask });
          }

          if (payload.queueTask && payload.actionType === "Cancelled") {
            queueTasksListAdapter.removeOne(state, payload.queueTaskId);
          }

          if (state.isDefaultFiltering) {
            state.countersStatus = LoadingStatusEnum.Idle;
            state.hasRealtimeUpdates = state.isNewTasksFilter && payload.actionType === "Created";
          }
        }
      })
      .addCase(getQueueTasksList.pending, (state, action) => {
        if (state.pagination && action.meta.arg && action.meta.arg.page <= state.pagination.pageNumber) {
          queueTasksListAdapter.removeAll(state);
          state.pagination = undefined;
        }

        state.hasSuggestions = false;
      })
      .addCase(getQueueTasksList.fulfilled, (state, action) => {
        if (action.payload.body.paginationInformation?.pageNumber === 1) {
          queueTasksListAdapter.setAll(state, action.payload.body.data);
        } else {
          queueTasksListAdapter.upsertMany(state, action.payload.body.data);
        }

        state.pagination = action.payload.body.paginationInformation;
        state.hasSuggestions = !!action.payload.body.queueTasksSuggested;
        state.hasRealtimeUpdates = false;

        if (action.meta.arg) {
          const isRequestWithDefaultFiltering = isArraysContainsSameItems(
            queueTasksListFilterParamsDefaultKeys,
            getObjectNotUndefinedKeys(action.meta.arg),
          );

          state.countersStatus =
            !state.isDefaultFiltering && isRequestWithDefaultFiltering ? LoadingStatusEnum.Idle : state.countersStatus;
          state.isDefaultFiltering = isRequestWithDefaultFiltering;
          state.isNewTasksFilter =
            isRequestWithDefaultFiltering &&
            action.meta.arg.tab === "New" &&
            action.meta.arg.ordering === "DateAddedDesc";
        }
      })
      .addCase(getQueueTasksList.rejected, (state, action) => {
        state.error = action.payload?.message;
      })
      .addCase(getQueueTasksListFilters.fulfilled, (state, action) => {
        state.filters.data = queueTasksListMapper.mapFilters(action.payload.body);
      })
      .addCase(completeQueueTask.fulfilled, (state, action) => {
        if (state.ids.includes(action.meta.arg.queueTaskId)) {
          queueTasksListAdapter.removeOne(state, action.meta.arg.queueTaskId);
          if (state.pagination) {
            state.pagination.totalCount -= 1;
          }
        }
      })
      .addCase(getQueueTasksListFilters.rejected, (state, action) => {
        state.filters.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(getQueueTasksList), (state, action) => {
        const status = getLoadingStatusFromAction(action);
        if (!isAborted(status)) {
          state.status = status;
        }
      })
      .addMatcher(isAsyncThunkAction(getQueueTasksListFilters), (state, action) => {
        state.filters.status = getLoadingStatusFromAction(action);
      })
      .addMatcher(isAsyncThunkAction(getQueueTasksListCounters), (state, action) => {
        state.countersStatus = getLoadingStatusFromAction(action);
      })
      .addMatcher(isAsyncThunkAction(completeQueueTask), (state, action) => {
        const { queueTaskId } = action.meta.arg;
        queueTasksListAdapter.updateOne(state, {
          id: queueTaskId,
          changes: { action: { status: getLoadingStatusFromAction(action) } },
        });
      });
  },
});

export const queueTasksListReducer = slice.reducer;
