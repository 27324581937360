import Skeleton from "react-loading-skeleton";
import { useResponsiveRange } from "@sideg/ui-kit";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { Text } from "../../../../../common/ui-kit/typography";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsRequestOriginal } from "../../../store/request-original/bankGuaranteeDetailsRequestOriginal.selectors";
import { BankGuaranteeDetailsRequestOriginalHeaderDetailsItem } from "./BankGuaranteeDetailsRequestOriginalHeaderDetailsItem";
import { isIdle, isLoading, isSucceeded } from "../../../../../common/types/state";
import { BankGuaranteeDetailsRequestOriginal } from "../../../types/request-original/BankGuaranteeDetailsRequestOriginal";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeRequestOriginalLabel } from "../../../../request-original";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../../../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { ProductDetailsContentHeaderLayout } from "../../../../../common/product-details";

export type BankGuaranteeDetailsRequestOriginalHeaderProps = BaseCssProps;

export const BankGuaranteeDetailsRequestOriginalHeader = ({
  className,
}: BankGuaranteeDetailsRequestOriginalHeaderProps) => {
  const { details } = useAppSelector(selectBankGuaranteeDetailsRequestOriginal);
  const { status } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);
  const { lowerThan } = useResponsiveRange();
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  const isShown = (key: keyof BankGuaranteeDetailsRequestOriginal): boolean => {
    return isLoading(status) || isIdle(status) || (isSucceeded(status) && details?.[key] !== undefined);
  };

  return (
    <ProductDetailsContentHeaderLayout withPaddingBottom className={className}>
      <StackPanel gap="md" direction="column" alignItems="flex-start">
        <Text
          as="h3"
          fontSize={{ xs: "1.375rem", [desktopBreakpoint]: "1.5" }}
          fontWeight={700}
          lineHeight="defaultLineHeight"
        >
          Запрос оригинала
        </Text>
        {details?.status && <BankGuaranteeRequestOriginalLabel requestOriginalType={details.status.code} />}
        {(isLoading(status) || isIdle(status)) && !details?.status && <Skeleton height={30} width={200} />}
      </StackPanel>
      <Box py="md" display="flex" flexDirection="column" gap="md">
        {isShown("deliveryType") && (
          <BankGuaranteeDetailsRequestOriginalHeaderDetailsItem
            isOnNewLine={lowerThan(desktopBreakpoint)}
            term="Способ получения"
            details={details?.deliveryType?.title ?? <Skeleton inline width={150} />}
          />
        )}
        {details?.deliveryType?.code === "Office" && isShown("officeRecipientType") && (
          <BankGuaranteeDetailsRequestOriginalHeaderDetailsItem
            isOnNewLine={lowerThan(desktopBreakpoint)}
            term="За оригиналом приедет"
            details={details?.officeRecipientType?.title ?? <Skeleton inline width={150} />}
          />
        )}
        {details?.deliveryType?.code !== "Office" && (
          <>
            {isShown("contactFullName") && (
              <BankGuaranteeDetailsRequestOriginalHeaderDetailsItem
                isOnNewLine={lowerThan(desktopBreakpoint)}
                term="ФИО контактного лица"
                details={details?.contactFullName ?? <Skeleton inline width={150} />}
              />
            )}
            {isShown("contactPhoneNumber") && (
              <BankGuaranteeDetailsRequestOriginalHeaderDetailsItem
                isOnNewLine={lowerThan(desktopBreakpoint)}
                term="Телефон контактного лица"
                details={details?.contactPhoneNumber ?? <Skeleton inline width={100} />}
              />
            )}
            {isShown("contactEmail") && (
              <BankGuaranteeDetailsRequestOriginalHeaderDetailsItem
                isOnNewLine={lowerThan(desktopBreakpoint)}
                term="Эл. почта контактного лица"
                details={details?.contactEmail ?? <Skeleton inline width={100} />}
              />
            )}
            {isShown("recipientName") && (
              <BankGuaranteeDetailsRequestOriginalHeaderDetailsItem
                isOnNewLine={lowerThan(desktopBreakpoint)}
                term="Наименование ЮЛ получателя"
                details={details?.recipientName ?? <Skeleton inline width={150} />}
              />
            )}
            {isShown("address") && (
              <BankGuaranteeDetailsRequestOriginalHeaderDetailsItem
                isOnNewLine={lowerThan(desktopBreakpoint)}
                term="Полный адрес"
                details={details?.address ?? <Skeleton inline width={300} />}
              />
            )}
          </>
        )}
      </Box>
      {isShown("additionalInformation") && (
        <BankGuaranteeDetailsRequestOriginalHeaderDetailsItem
          isOnNewLine
          term="Дополнительная информация"
          details={details?.additionalInformation ?? <Skeleton inline count={2} />}
        />
      )}
    </ProductDetailsContentHeaderLayout>
  );
};
