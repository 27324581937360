import { clientV4 } from "../../../common/api";
import { CreateDraftBankGuaranteeCreationInputDto } from "./dto/input/CreateDraftBankGuaranteeCreationInputDto";
import { ScoringBankGuaranteeCreationInputDto } from "./dto/input/ScoringBankGuaranteeCreationInputDto";
import { ScoringBankGuaranteeCreationOutputDto } from "./dto/output/ScoringBankGuaranteeCreationOutputDto";
import { ScoringByGuaranteeBankGuaranteeCreationInputDto } from "./dto/input/ScoringByGuaranteeBankGuaranteeCreationInputDto";
import { CreateBankGuaranteeCreationInputDto } from "./dto/input/CreateBankGuaranteeCreationInputDto";
import { CreateBankGuaranteeCreationOutputDto } from "./dto/output/CreateBankGuaranteeCreationOutputDto";
import { CreateByGuaranteeBankGuaranteeCreationInputDto } from "./dto/input/CreateByGuaranteeBankGuaranteeCreationInputDto";

const GUARANTEE_CONTROLLER_NAME = "Guarantees";

export const bankGuaranteeCreationApi = {
  createDraft: (model: CreateDraftBankGuaranteeCreationInputDto) =>
    clientV4.post<CreateDraftBankGuaranteeCreationInputDto, unknown>(
      `${GUARANTEE_CONTROLLER_NAME}/create-draft`,
      model,
    ),
  scoring: (model: ScoringBankGuaranteeCreationInputDto) =>
    clientV4.post<ScoringBankGuaranteeCreationInputDto, ScoringBankGuaranteeCreationOutputDto>(
      `${GUARANTEE_CONTROLLER_NAME}/scoring`,
      model,
    ),
  scoringByGuaranteeId: ({ guaranteeId }: ScoringByGuaranteeBankGuaranteeCreationInputDto) =>
    clientV4.post<ScoringByGuaranteeBankGuaranteeCreationInputDto, ScoringBankGuaranteeCreationOutputDto>(
      `${GUARANTEE_CONTROLLER_NAME}/${guaranteeId}/scoring`,
      {},
    ),
  create: (model: CreateBankGuaranteeCreationInputDto) =>
    clientV4.post<CreateBankGuaranteeCreationInputDto, CreateBankGuaranteeCreationOutputDto>(
      `${GUARANTEE_CONTROLLER_NAME}/create`,
      model,
    ),
  createByGuarantee: ({ guaranteeId, ...model }: CreateByGuaranteeBankGuaranteeCreationInputDto) =>
    clientV4.post<typeof model, CreateBankGuaranteeCreationOutputDto>(
      `${GUARANTEE_CONTROLLER_NAME}/${guaranteeId}/create`,
      model,
    ),
};
