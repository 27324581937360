import styled from "@emotion/styled";

export const Avatar = styled.figure<{ size: string; fontSize: string; backGround: string }>`
  background: ${(props) => props.backGround};
  border-radius: 50%;
  display: inline-block;
  font-size: ${(props) => props.fontSize};
  font-weight: 300;
  margin: 0;
  position: relative;
  vertical-align: middle;
  line-height: 1.28;
  height: ${(props) => props.size};
  width: ${(props) => props.size};

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;
