import styled from "@emotion/styled";

export const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  background: none;
  border: none;
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz2};
  padding: 0;
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.xs};

  &:hover:not(:disabled) {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primaryDark};

    & > svg > path {
      stroke: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  &:focus {
    text-decoration: underline;
  }

  & > svg {
    width: 14px;
    height: 14px;
    line-height: 14;
    & > path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.secondary};
    cursor: default;

    & > path {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }
`;
