import { combineSliceNames } from "../../../common/store/utils";
import { CLIENT_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { clientCheckApi } from "../api/dto/clientCheckApi";

const SLICE_NAME = combineSliceNames(CLIENT_SLICE_NAME, "check");

export const {
  getContracts: getContractsClientCheck,
  getFullReport: getFullReportClientCheck,
  getBriefReport: getBriefReportClientCheck,
} = createApiThunks(SLICE_NAME, clientCheckApi);
