import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectChatDialogUnreadMessages } from "../../store/chatDialog.selectors";
import { markReadChatDialog } from "../../store/chatDialog.thunks";

export const useChatDialogMarkMessagesAsRead = () => {
  const dispatch = useAppDispatch();
  const unreadMessagesCount = useAppSelector(selectChatDialogUnreadMessages);

  useEffect(() => {
    if (unreadMessagesCount > 0) {
      dispatch(markReadChatDialog());
    }
  }, [dispatch, unreadMessagesCount]);
};
