import { RootState } from "../../../../common/store";
import {
  creditExecutionListItemsAdapter,
  creditExecutionListItemsClientsAdapter,
  creditExecutionListItemsDemandsAdapter,
} from "./creditExecutionListItems.adapter";
import { CreditExecutionListItemsState } from "./creditExecutionListItems.types";

export const selectCreditExecutionListItems = (state: RootState): CreditExecutionListItemsState =>
  state.domains.creditExecution.list.items;

const selectField =
  <TKey extends keyof CreditExecutionListItemsState>(
    key: TKey
  ): ((state: RootState) => CreditExecutionListItemsState[TKey]) =>
  (state) =>
    selectCreditExecutionListItems(state)[key];

export const { selectIds: selectCreditExecutionListItemsIds, selectById: selectCreditExecutionListItemsById } =
  creditExecutionListItemsAdapter.getSelectors(selectField("creditExecution"));

export const { selectById: selectCreditExecutionListItemsDemandById } =
  creditExecutionListItemsDemandsAdapter.getSelectors(selectField("demands"));

export const { selectById: selectCreditExecutionListItemsClientById } =
  creditExecutionListItemsClientsAdapter.getSelectors(selectField("clients"));
