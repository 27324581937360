import * as React from "react";
import AgentReportModel from "../../../models/AgentReportModel";
import { formatterDayMonthYear, formatterMonthNameYear } from "../../../essentials/helpers";

interface IAgentReportItemInfoProps {
  report: AgentReportModel;
}

const AgentReportItemInfo: React.FC<IAgentReportItemInfoProps> = (props) => {
  const formatDate = (d: string) => {
    const date = new Date(d);
    return formatterDayMonthYear.format(date);
  };

  const formatPeriodName = (d: string) => {
    const date = new Date(d);
    return formatterMonthNameYear.format(date);
  };

  return (
    <table className="table table_condensed table_bordered">
      <tbody className="table__body">
        <tr className="table__row">
          <td className="table__row-item">Статус</td>
          <td className="table__row-item">
            {props.report.status.title}
            {props.report.status.code !== "Completed" &&
            props.report.status.code !== "Terminated" &&
            props.report.agentSide
              ? "(на вашей стороне)"
              : "(на стороне менеджера)"}
          </td>
        </tr>
        <tr className="table__row">
          <td className="table__row-item">Дата создания</td>
          <td className="table__row-item">{formatDate(props.report.createDate)}</td>
        </tr>
        <tr className="table__row">
          <td className="table__row-item">Дата обновления</td>
          <td className="table__row-item">{formatDate(props.report.updateDate)}</td>
        </tr>
        <tr className="table__row">
          <td className="table__row-item">Период акта сверки</td>
          <td className="table__row-item">{formatPeriodName(props.report.dateFrom)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default AgentReportItemInfo;
