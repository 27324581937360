import { PropsWithChildren } from "react";
import { BaseButton } from "../../../../../../../../../common/ui-kit/form-controls/button";
import { Tooltip, useTooltip } from "../../../../../../../../../common/ui-kit/pop-up/tooltip";
import { Icon, QuestionCircleIcon } from "../../../../../../../../../common/ui-kit/icons";

const Button = BaseButton.withComponent("button");

export interface BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualTooltipProps
  extends PropsWithChildren {}

export const BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualTooltip = ({
  children,
}: BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualTooltipProps) => {
  const { ref, popupProps, onClick, arrow, placement, isOpened } = useTooltip({ position: "top", type: "click" });

  return (
    <>
      <Button type="button" ref={ref} onClick={onClick}>
        <Icon width={15} height={15} stroke="typography.medium" as={QuestionCircleIcon} />
      </Button>
      {isOpened && (
        <Tooltip popUpProps={popupProps} placement={placement} arrowData={arrow.data} arrowRef={arrow.ref}>
          {children}
        </Tooltip>
      )}
    </>
  );
};
