const isParsableNumber = (purchaseNumber?: string): purchaseNumber is string => {
  if (!purchaseNumber) {
    return false;
  }

  const trimmed = purchaseNumber.trim();
  if (/^\d+$/.test(trimmed)) {
    switch (trimmed.length) {
      case 11:
      case 18:
      case 19:
        return true;
      default:
        return false;
    }
  }

  return false;
};

export const purchaseNumberRules = {
  isParsableNumber,
};
