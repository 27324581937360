import { useMemo } from "react";
import { useAppDispatch } from "../../../store";
import { useApiMutationAction } from "../../../hooks";
import { webPushesSubscriptionMapper } from "../../utils/webPushesSubscriptionMapper";
import { checkSubscriptionWebPushes, subscribeWebPushes, unsubscribeWebPushes } from "../../store/webPushes.thunks";
import { pushErrorGlobalToast } from "../../../../global/toasts";
import { isBaseApiError } from "../../../api/types";

export const useWebPushesSubscriptionBackendApi = () => {
  const dispatch = useAppDispatch();
  const { mutate } = useApiMutationAction();

  return useMemo(() => {
    const isSubscribed = async (subscription: PushSubscription | undefined): Promise<boolean> => {
      if (subscription) {
        const endpoint = webPushesSubscriptionMapper.mapEndpoint(subscription);
        const result = await mutate(checkSubscriptionWebPushes, { endpoint }, { errorMessagePlaceholder: "" });

        return result?.body.isSubscribed === true;
      }

      return false;
    };

    const subscribe = async (subscription: PushSubscription | undefined): Promise<boolean> => {
      if (subscription) {
        const dto = webPushesSubscriptionMapper.mapSubscription(subscription);

        const result = await mutate(subscribeWebPushes, dto, {
          errorHandler: async (e) => {
            const placeholder = "Не удалось подписаться на уведомления";
            const error = isBaseApiError(e) ? e.message : undefined;
            dispatch(pushErrorGlobalToast(error ?? placeholder));

            return error ?? placeholder;
          },
        });

        return result?.ok === true;
      }

      return false;
    };

    const unsubscribe = async (subscription: PushSubscription | undefined): Promise<boolean> => {
      if (subscription) {
        const endpoint = webPushesSubscriptionMapper.mapEndpoint(subscription);

        const result = await mutate(
          unsubscribeWebPushes,
          { endpoint },
          {
            errorHandler: async (e) => {
              const placeholder = "Не удалось отписаться от уведомлений";
              const error = isBaseApiError(e) ? e.message : undefined;
              dispatch(pushErrorGlobalToast(error ?? placeholder));

              return error ?? placeholder;
            },
          }
        );

        return result?.ok === true;
      }

      return false;
    };

    return { subscribe, unsubscribe, isSubscribed };
  }, [dispatch, mutate]);
};
