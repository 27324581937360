/* eslint-disable react/no-children-prop */
import { forwardRef } from "react";
import { dateTimeHelper } from "@sideg/helpers";
import * as S from "./ChatDialogLeftSideMessage.styled";
import { Text } from "../../../../common/ui-kit/typography";
import { ChatDialogMessageText } from "./ChatDialogMessageText";

export interface ChatDialogLeftSideMessageProps {
  message: string;
  userName: string;
  date: string;
  className?: string;
}

export const ChatDialogLeftSideMessage = forwardRef<HTMLDivElement, ChatDialogLeftSideMessageProps>(
  ({ message, date, userName, className }, ref) => {
    return (
      <S.Message className={className} ref={ref}>
        <Text fontColor="colors.primary" fontSize="fz1">
          {userName}
        </Text>
        <ChatDialogMessageText message={message} fontColor="primary" />
        <Text as="span" fontColor="primaryCaption" fontSize="fz1">
          {dateTimeHelper.format(new Date(date), "timeOnly")}
        </Text>
      </S.Message>
    );
  },
);
