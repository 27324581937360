import { CSSObject } from "@emotion/react";
import * as React from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { createTabs } from "../../../ui-kit/containers/tabs";

export const createResponsiveTabs = <TTabs extends string>() => {
  const Tabs = createTabs<TTabs>();

  const Container = ({
    className,
    activeTab,
    onTabChange,
    children,
    gap,
  }: Omit<React.ComponentProps<typeof Tabs.Container>, "isGrow">) => {
    const desktopBreakpoint = useThemeDesktopBreakpoint();

    return (
      <Tabs.Container
        gap={gap ?? { xs: "none", [desktopBreakpoint]: "md" }}
        isGrow={{ xs: true, [desktopBreakpoint]: false }}
        activeTab={activeTab}
        onTabChange={onTabChange}
        className={className}
      >
        {children}
      </Tabs.Container>
    );
  };

  type TabProps = Pick<React.ComponentProps<typeof Tabs.Tab>, "tab" | "fontSize" | "className" | "text"> & {
    counterValue?: string | number;
    xsFlexBasis: CSSObject["flexBasis"];
  };

  const Tab = ({ tab, className, text, counterValue, xsFlexBasis, fontSize = "fz2" }: TabProps) => {
    const desktopBreakpoint = useThemeDesktopBreakpoint();

    return (
      <Tabs.Tab
        fontSize={fontSize}
        isGrow={{ xs: true, [desktopBreakpoint]: false }}
        px="none"
        py="md"
        tab={tab}
        className={className}
        text={text}
        flexBasis={{ xs: xsFlexBasis, [desktopBreakpoint]: "unset" }}
      >
        {counterValue !== undefined && <Tabs.Counter>{counterValue}</Tabs.Counter>}
      </Tabs.Tab>
    );
  };

  return { Container, Tab };
};
