import { DemandQueueTaskComment as DemandQueueTaskCommentType } from "../../types/DemandQueueTaskComment";
import { DemandQueueTaskCommentWithFiles } from "../comment/DemandQueueTaskCommentWithFiles";

export interface DemandQueueTaskSingleCommentCardProps {
  comment: DemandQueueTaskCommentType;
}

export const DemandQueueTaskCompletedSingleCommentCard = ({
  comment: { author, date, body, files },
}: DemandQueueTaskSingleCommentCardProps) => {
  return <DemandQueueTaskCommentWithFiles author={author} text={body} date={new Date(date)} files={files} />;
};
