import { Form, Formik } from "formik";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Box } from "../../../../common/ui-kit/containers/box";
import { SettlementAndCashCreationFormContent } from "./SettlementAndCashCreationFormContent";
import { settlementAndCashCreationValidationSchema } from "../../validation/settlementAndCashCreationValidationSchema";
import { SettlementAndCashCreationFormValues } from "../../types/SettlementAndCashCreationFormValues";
import { useApiMutation } from "../../../../common/hooks";
import { createSettlementsAndCashCreation } from "../../store/settlementsAndCashCreation.thunks";
import { useAppDispatch } from "../../../../common/store";
import { pushErrorGlobalToast, pushSuccessGlobalToast } from "../../../../global/toasts";
import { useAppNavigation } from "../../../../common/urls/internal/hooks";

export type SettlementAndCashCreationFormProps = BaseCssProps;

export const SettlementAndCashCreationForm = ({ className }: SettlementAndCashCreationFormProps) => {
  const dispatch = useAppDispatch();
  const { mutate } = useApiMutation(createSettlementsAndCashCreation, { errorMessagePlaceholder: "" });
  const navigate = useAppNavigation();

  const onSubmit = async (values: SettlementAndCashCreationFormValues) => {
    const castedValues = settlementAndCashCreationValidationSchema.cast(values);
    const result = await mutate({
      comment: castedValues.comment,
      contactPerson: castedValues.contactFullName,
      phoneNumber: castedValues.contactPhoneNumber,
      email: castedValues.contactEmail,
      inn: castedValues.customerTaxpayerNumber,
      addressLegal: castedValues.customerAddress,
      name: castedValues.customerName,
      banks: castedValues.banks,
    });

    if (result?.body) {
      dispatch(pushSuccessGlobalToast("Заявка на РКО отправлена"));
      navigate((x) => x.root);
    } else {
      dispatch(pushErrorGlobalToast("Не удалось отправить заявку на РКО"));
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={settlementAndCashCreationValidationSchema.getDefault() as SettlementAndCashCreationFormValues}
      validationSchema={settlementAndCashCreationValidationSchema}
    >
      {() => (
        <Box as={Form} display="flex" flexDirection="column" flexShrink={1} flexGrow={1} className={className}>
          <SettlementAndCashCreationFormContent />
        </Box>
      )}
    </Formik>
  );
};
