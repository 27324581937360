import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectQueueTasksNotifications } from "../../store/queueTasksNotifications.selectors";
import { isFailed, isLoading } from "../../../../common/types/state";
import { DotsLoader } from "../../../../common/ui-kit/loaders/DotsLoader";
import { useDebounce } from "../../../../common/hooks/useDebounce";
import { queueTasksNotificationsUpdate } from "../../store/queueTasksNotifications.slice";
import { ButtonLink } from "../../../../common/ui-kit/buttons/ButtonLink";
import * as S from "./QueueTasksNotificationsLoadingStatus.styled";

export const QueueTasksNotificationsLoadingStatus = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectQueueTasksNotifications);
  const [debounceStatus] = useDebounce(status, 1_000);

  const onUpdateClick = () => {
    dispatch(queueTasksNotificationsUpdate());
  };

  return (
    <S.Container>
      {isLoading(debounceStatus) && (
        <>
          <span>Обновляем список уведомлений</span>
          <DotsLoader />
        </>
      )}
      {isFailed(status) && (
        <>
          <span>Ошибка получения задач.&nbsp;</span>
          <ButtonLink onClick={onUpdateClick}>Повторить</ButtonLink>
        </>
      )}
    </S.Container>
  );
};
