import styled, { CSSObject } from "@emotion/styled";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";

export const Container = styled(AppLayoutResponsiveListPage.List)<{ paddingTop: CSSObject["paddingTop"] }>`
  padding-top: ${({ paddingTop, theme }) =>
    paddingTop ? `calc(${paddingTop} + ${theme.spaces.xxl})` : theme.spaces.xxl};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding-top: ${({ theme }) => theme.spaces.xxl};
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.25rem;
`;
