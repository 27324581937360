import { ScoringBankModel } from "../../../entity/models/ScoringBankModel"
import { ScoringModel }  from "../../../entity/models/ScoringModel"

export const SCORING_OPTIONS_FETCHING = 'SCORING_OPTIONS_FETCHING'
export const SCORING_OPTIONS_FETCHED = 'SCORING_OPTIONS_FETCHED'
export const SCORING_OPTIONS_FETCHING_ERROR = 'SCORING_OPTIONS_FETCHING_ERROR'
export const SCORING_OPTIONS_SET_INITIAL = 'SCORING_OPTIONS_SET_INITIAL'

export const SCORING_DATA_PROCESSING = 'SCORING_DATA_PROCESSING'
export const SCORING_DATA_SUCCESS = 'SCORING_DATA_SUCCESS'
export const SCORING_DATA_ERROR = 'SCORING_DATA_ERROR'
export const SCORING_DATA_SET_INITIAL = 'SCORING_DATA_SET_INITIAL'

export interface ScoringOptionsFetching {
    type: typeof SCORING_OPTIONS_FETCHING
}

export interface ScoringOptionsFetched {
    type: typeof SCORING_OPTIONS_FETCHED
    payload: ScoringModel
}

export interface ScoringOptionsFetchingError {
    type: typeof SCORING_OPTIONS_FETCHING_ERROR
}

export interface ScoringOptionsSetInitial {
    type: typeof SCORING_OPTIONS_SET_INITIAL
}

export interface ScoringDataProcessing {
    type: typeof SCORING_DATA_PROCESSING
}

export interface ScoringDataSuccess {
    type: typeof SCORING_DATA_SUCCESS
    payload: ScoringBankModel
}

export interface ScoringDataError {
    type: typeof SCORING_DATA_ERROR
}

export interface ScoringDataSetInitial {
    type: typeof SCORING_DATA_SET_INITIAL
}

export type ScoringDispatchTypes =
    ScoringOptionsFetching |
    ScoringOptionsFetched |
    ScoringOptionsFetchingError |
    ScoringOptionsSetInitial |
    ScoringDataProcessing |
    ScoringDataSuccess |
    ScoringDataError |
    ScoringDataSetInitial