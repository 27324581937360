import { useEffect, useState } from "react";
import { BankGuaranteeDetailsFilterContextType } from "../../types/filters/BankGuaranteeDetailsFilterContextType";
import { QueueTaskId } from "../../../../common/types/demand";
import { useSingleSearchParam } from "../../../../common/filters";
import { useBankGuaranteeDetailsFilterIsQueueTaskIdOnPage } from "./useBankGuaranteeDetailsFilterIsQueueTaskIdOnPage";

const KEY: Extract<keyof BankGuaranteeDetailsFilterContextType, "queueTaskId"> = "queueTaskId";

// Если queueTaskId уже был в url, а потом пропал со страницы - удаляем queueTaskId из url
export const useBankGuaranteeDetailsFilterRemoveQueueTaskIfDisappearEffect = (queueTaskId: QueueTaskId | undefined) => {
  const [prevQueueTaskId, setPrevQueueTaskId] = useState(queueTaskId);

  const isQueueTaskIdOnPage = useBankGuaranteeDetailsFilterIsQueueTaskIdOnPage(queueTaskId);
  const [prevIsQueueTaskIdOnPage, setPrevIsQueueTaskIdOnPage] = useState(isQueueTaskIdOnPage);

  const [, setQueueTaskId] = useSingleSearchParam(KEY);

  useEffect(() => {
    if (prevQueueTaskId === queueTaskId && !isQueueTaskIdOnPage && prevIsQueueTaskIdOnPage) {
      setQueueTaskId(null);
    }

    setPrevIsQueueTaskIdOnPage(isQueueTaskIdOnPage);
    setPrevQueueTaskId(queueTaskId);
  }, [isQueueTaskIdOnPage, prevIsQueueTaskIdOnPage, prevQueueTaskId, queueTaskId, setQueueTaskId]);
};
