import * as S from "./NavigationBrandWithBreadcrumbs.styled";
import { BaseCssProps } from "../../../../ui-kit/types";
import { BrandLink } from "../../BrandLink";
import { ChevronRightIcon } from "../../../../ui-kit/icons";

export interface NavigationBrandWithBreadcrumbsProps extends BaseCssProps {
  url: string;
  title: string;
}

export const NavigationBrandWithBreadcrumbs = ({ url, title, className }: NavigationBrandWithBreadcrumbsProps) => {
  return (
    <S.Wrapper className={className}>
      <BrandLink />
      <ChevronRightIcon />
      <S.BreadcrumbLink to={url}>{title}</S.BreadcrumbLink>
    </S.Wrapper>
  );
};
