import Skeleton from "react-loading-skeleton";
import { BankGuaranteeDetailsHeaderMobileAlert } from "./BankGuaranteeDetailsHeaderMobileAlert";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";

export type BankGuaranteeDetailsHeaderMobileAlertSkeletonProps = BaseCssProps;

export const BankGuaranteeDetailsHeaderMobileAlertSkeleton = ({
  className,
}: BankGuaranteeDetailsHeaderMobileAlertSkeletonProps) => {
  return (
    <BankGuaranteeDetailsHeaderMobileAlert className={className} variant="inProcess" title={<Skeleton width={100} />}>
      <Skeleton count={3} />
    </BankGuaranteeDetailsHeaderMobileAlert>
  );
};
