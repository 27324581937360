import * as React from "react";
import { useVerticalDraggableSlider } from "../hooks/useVerticalDraggableSlider";
import { ConditionTitleType } from "../types/ConditionTitleType";
import * as S from "./ConditionsLayout.styled";
import { FilteringBaseOrderType } from "../../../../filters";
import { SortIcon } from "../../../../ui-kit/icons";

interface ConditionsWrapperProps {
  titles: ConditionTitleType[];
  sortHandler: () => void;
  order: FilteringBaseOrderType;
  children: React.ReactNode;
}

export const ConditionsTable = ({ titles, sortHandler, children, order }: ConditionsWrapperProps) => {
  const { onMouseLeave, onMouseMove, onMousedown, isGrabbing, ref } = useVerticalDraggableSlider();

  return (
    <S.TableContainer
      columnsSize={titles.map((title) => title.size)}
      onMouseDown={onMousedown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseLeave}
      onMouseMove={onMouseMove}
      isGrabbing={isGrabbing}
      ref={ref}
    >
      <S.TitleContainer>
        {titles.map((item, index) => (
          <S.Title key={item.title} onClick={index === 0 ? sortHandler : undefined}>
            {item.title}
            {index === 0 && (
              <S.Icon order={order}>
                <SortIcon />
              </S.Icon>
            )}
          </S.Title>
        ))}
      </S.TitleContainer>
      {children}
    </S.TableContainer>
  );
};
