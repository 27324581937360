import { CreditWorkingAssetsDetailsDemand } from "../../types/CreditWorkingAssetsDetailsDemand";
import { createAppEntityAdapter } from "../../../../common/store/utils";
import { DemandId } from "../../../../common/types/demand";

export const creditWorkingAssetsDetailsDemandsAdapter = createAppEntityAdapter<
  CreditWorkingAssetsDetailsDemand,
  DemandId
>({
  selectId: (x) => x.demandId,
  sortComparer: (a, b) => (a.index ?? 0) - (b.index ?? 0),
});
