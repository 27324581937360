import styled from "@emotion/styled";

export const FullPageContainerHeaderCenteredWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.md};

  &::after {
    content: "";
    flex: 1;
  }
`;
