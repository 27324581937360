import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../common/store";
import { globalToastsAdapter } from "./globalToasts.adapter";
import { GlobalToastsState } from "./globalToasts.types";
import { LoadingStatusEnum } from "../../../common/types/state";
import { globalToastConfig } from "../configs";

export const selectGlobalToasts = (state: RootState): GlobalToastsState => state.domains.global.toasts;

export const { selectAll: selectGlobalToastsAll, selectById: selectGlobalToastById } =
  globalToastsAdapter.getSelectors(selectGlobalToasts);

export const selectWaitedToastIds = createSelector([selectGlobalToastsAll], (items) => {
  return items
    .filter((x) => x.status === LoadingStatusEnum.Idle || x.status === LoadingStatusEnum.Loading)
    .map((x) => x.id)
    .slice(0, globalToastConfig.maxToastsCount);
});
