const serialize = <T>(value: T): string => JSON.stringify(value);
const deserialize = <T>(
  value: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reviver?: ((this: any, key: string, value: any) => any) | undefined
): T => JSON.parse(value, reviver);

export const jsonService = {
  serialize,
  deserialize,
};
