import { useFormikContext } from "formik";
import { PhoneNumberInput } from "@sideg/ui-kit/atoms/form-controls/phone-number-input";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { AgencyType, agencyType as agencyTypeEnum } from "../../../../common/types/agencies";
import { useGetFieldName, useGetFormFieldError } from "../../../../common/form";
import { PublicUserRegistrationFormValues } from "../../types/PublicUserRegistrationFormValues";
import { TaxpayerFormInput } from "../../../../common/ui/form-controls/taxpayer-input";
import { PublicUserRegistrationFormCompanyTypeField } from "./fields/PublicUserRegistrationFormCompanyTypeField";
import { PublicUserRegistrationControlButtonsContainer } from "./PublicUserRegistrationControlButtonsContainer";
import { PublicUserRegistrationFormAgreementField } from "./fields/PublicUserRegistrationFormAgreementField";
import { usePublicUserRegistrationCheckAgencyEffect } from "../../hooks/usePublicUserRegistrationCheckAgencyEffect";
import { PublicUserRegistrationCommonFormNote } from "./note/PublicUserRegistrationCommonFormNote";
import { ButtonLoading } from "../../../../common/ui-kit/form-controls/button";
import { AppLinkButton } from "../../../../common/ui/links";
import { Box } from "../../../../common/ui-kit/containers/box";

export interface PublicUserRegistrationCommonFormContentProps {
  agencyType: AgencyType;
}

export const PublicUserRegistrationCommonFormContent = ({
  agencyType,
}: PublicUserRegistrationCommonFormContentProps) => {
  const getFieldName = useGetFieldName<PublicUserRegistrationFormValues>();
  const getFieldError = useGetFormFieldError<PublicUserRegistrationFormValues>();
  const { getFieldProps, isSubmitting: isDisabled, ...form } = useFormikContext<PublicUserRegistrationFormValues>();

  const { isPassing, isLoading } = usePublicUserRegistrationCheckAgencyEffect(
    getFieldName("taxpayerNumber"),
    getFieldName("companyType"),
    agencyType,
  );

  return (
    <>
      <Box display="flex" flexDirection="column" gap="lg">
        <PublicUserRegistrationFormCompanyTypeField
          agencyType={agencyType}
          fieldName={getFieldName("companyType")}
          isDisabled={isDisabled || isLoading}
        />
        <TaxpayerFormInput
          label={form.values.companyType === "Legal" ? "ИНН организации" : "ИНН"}
          disabled={isDisabled || isLoading}
          {...getFieldProps(getFieldName("taxpayerNumber"))}
          error={
            isPassing === false
              ? `${agencyTypeEnum.getTitle(agencyType)} с таким ИНН уже зарегистрирован`
              : getFieldError(form, "taxpayerNumber")
          }
        />
        {isPassing === false && <PublicUserRegistrationCommonFormNote />}
        <PhoneNumberInput
          {...getFieldProps(getFieldName("phoneNumber"))}
          label="Телефон"
          error={getFieldError(form, "phoneNumber")}
          disabled={isDisabled}
        />
        <FormInput
          type="email"
          label="Электронная почта"
          error={getFieldError(form, "email")}
          spellCheck={false}
          autoComplete="email"
          autoCorrect="off"
          disabled={isDisabled}
          {...getFieldProps(getFieldName("email"))}
        />
        <FormInput
          type="text"
          label="Фамилия, имя и отчество"
          autoComplete="name"
          disabled={isDisabled}
          {...getFieldProps(getFieldName("fullName"))}
          error={getFieldError(form, "fullName")}
        />

        <PublicUserRegistrationFormAgreementField
          isDisabled={isDisabled}
          name={getFieldName("isAgreeWithPrivacyPolicy")}
        />
      </Box>
      <PublicUserRegistrationControlButtonsContainer>
        <AppLinkButton to={(x) => x.publicUser.registration.new} size="medium" color="secondary" isFullWidth>
          Назад
        </AppLinkButton>
        <ButtonLoading isFullWidth size="medium" isLoading={isDisabled} type="submit" disabled={!isPassing}>
          Далее
        </ButtonLoading>
      </PublicUserRegistrationControlButtonsContainer>
    </>
  );
};
