import { CreditWorkingAssetsDetailsDemand } from "../../types/CreditWorkingAssetsDetailsDemand";
import { CreditWorkingAssetsDemandBase } from "../../../common/types";

const mapDemands = (demands: CreditWorkingAssetsDemandBase[]): CreditWorkingAssetsDetailsDemand[] => {
  return demands.map((x, i) => ({ ...x, index: i + 1 }));
};

export const creditWorkingAssetsDetailsDemandsMapper = {
  mapDemands,
};
