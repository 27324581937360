import { Formik } from "formik";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { FormBox, FormSubmitHandler, useGetFieldName, useGetFormFieldError } from "../../../../common/form";
import { PublicUserResetPasswordFormValues } from "../../types/PublicUserResetPasswordFormValues";
import { publicUserResetPasswordFormValidationSchema } from "../../validation/publicUserResetPasswordFormValidationSchema";
import { Typography } from "../../../../common/ui-kit/typography";
import { ButtonLoading } from "../../../../common/ui-kit/form-controls/button";

export interface PublicUserResetPasswordFormProps extends BaseCssProps {
  onSubmit: FormSubmitHandler<PublicUserResetPasswordFormValues>;
}

export const PublicUserResetPasswordForm = ({ className, onSubmit }: PublicUserResetPasswordFormProps) => {
  const getFieldName = useGetFieldName<PublicUserResetPasswordFormValues>();
  const getFieldError = useGetFormFieldError<PublicUserResetPasswordFormValues>();

  return (
    <Formik
      initialValues={publicUserResetPasswordFormValidationSchema.getDefault()}
      onSubmit={onSubmit}
      validationSchema={publicUserResetPasswordFormValidationSchema}
    >
      {({ getFieldProps, isSubmitting, ...form }) => (
        <FormBox className={className} display="flex" flexDirection="column" gap="xxl">
          <Typography fontColor="typography.primary" fontSize="fz3" fontWeight={500} lineHeight="defaultLineHeight">
            Введите свой логин, мы отправим на привязанную к нему электронную почту ссылку для сброса пароля
          </Typography>
          <FormInput
            type="text"
            autoCorrect="off"
            autoCapitalize="off"
            autoComplete="username"
            label="Логин"
            disabled={isSubmitting}
            {...getFieldProps(getFieldName("login"))}
            error={getFieldError(form, "login")}
          />
          <ButtonLoading size="medium" isFullWidth type="submit" isLoading={isSubmitting}>
            Далее
          </ButtonLoading>
        </FormBox>
      )}
    </Formik>
  );
};
