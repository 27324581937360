import { useEffect } from "react";
import { FullPageLayoutContainer } from "../../../common/ui/layout";
import { BankGuaranteeCreationPageHeader } from "../components/header/BankGuaranteeCreationPageHeader";
import { BankGuaranteeCreationForm } from "../components/creating/BankGuaranteeCreationForm";
import { BankGuaranteeCreationScoringCreateNew } from "../components/scoring/create-new/BankGuaranteeCreationScoringCreateNew";
import { useBankGuaranteeCreationChangeScoringStageEffect } from "../hooks/stages/useBankGuaranteeCreationChangeScoringStageEffect";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectBankGuaranteeCreation } from "../store/bankGuaranteeCreation.selectors";
import { newBankGuaranteeCreation } from "../store/bankGuaranteeCreation.slice";
import { useBankGuaranteeCreationFromInitialValues } from "../hooks/creation/useBankGuaranteeCreationFromInitialValues";
import { usePageTitle } from "../../../common/navigation/page-title";
import { ErrorBoundary, ErrorBoundaryDefaultFallbackAlert } from "../../../common/ui/containers/error-boundary";
import { useScrollToTopOnMount } from "../../../common/hooks/scroll";

export const BankGuaranteeCreationPage = () => {
  const dispatch = useAppDispatch();

  usePageTitle("Создание заявки на банковскую гарантию");
  useScrollToTopOnMount();

  useEffect(() => {
    return () => {
      dispatch(newBankGuaranteeCreation());
    };
  }, [dispatch]);

  const { activeStage } = useAppSelector(selectBankGuaranteeCreation);

  const initialValues = useBankGuaranteeCreationFromInitialValues();
  useBankGuaranteeCreationChangeScoringStageEffect();

  return (
    <FullPageLayoutContainer>
      <ErrorBoundary fallback={<ErrorBoundaryDefaultFallbackAlert />}>
        <BankGuaranteeCreationPageHeader />
        {activeStage === "creation" && <BankGuaranteeCreationForm initialValues={initialValues} />}
        {activeStage === "scoring" && <BankGuaranteeCreationScoringCreateNew />}
      </ErrorBoundary>
    </FullPageLayoutContainer>
  );
};
