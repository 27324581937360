import { CSSProperties } from "react";
import { autoUpdate, flip, offset, Placement, ReferenceType, shift, useFloating } from "@floating-ui/react";

export interface UsePopupArgs {
  position: Placement;
  offset?: [number, number];
}

export interface UsePopupReturnType<TReferenceType extends ReferenceType = ReferenceType> {
  referenceElementProps: {
    width: number | undefined;
    ref: (node: TReferenceType | null) => void;
  };
  popupProps: {
    ref: (node: HTMLElement | null) => void;
    style: CSSProperties;
  };
}

export const usePopup = <TReferenceType extends ReferenceType>({
  position,
  offset: offsetValue,
}: UsePopupArgs): UsePopupReturnType<TReferenceType> => {
  const { refs, floatingStyles } = useFloating<TReferenceType>({
    middleware: [
      offset(offsetValue === undefined ? undefined : { crossAxis: offsetValue[0], mainAxis: offsetValue[1] }),
      flip(),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
    placement: position,
  });

  return {
    referenceElementProps: {
      width: refs.reference.current?.getBoundingClientRect().width,
      ref: refs.setReference,
    },
    popupProps: {
      ref: refs.setFloating,
      style: floatingStyles,
    },
  };
};
