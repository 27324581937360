import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { AnySchema, InferType } from "yup";
import { queryParamsService } from "../services/queryParamsService";

const getObject = (searchQuery: string) => {
  return queryParamsService.getObjectFromQueryParams(new URLSearchParams(searchQuery));
};

export const useGetQueryParams = <TValidationSchema extends AnySchema>(
  schema: TValidationSchema,
): [filteringObject: InferType<TValidationSchema>, forceUpdate: () => void] => {
  const { search } = useLocation();
  const prevSearch = useRef<string | null>(search);

  const [needUpdate, setNeedUpdate] = useState(false);
  const [queryObject, setQueryObject] = useState<InferType<TValidationSchema>>(() => {
    return queryParamsService.castQueryObjectForceSync(schema, getObject(search));
  });

  const forceUpdate = useCallback(() => {
    setNeedUpdate(true);
  }, []);

  useEffect(() => {
    if (
      prevSearch.current === null ||
      (search !== prevSearch.current &&
        !queryParamsService.isURLSearchParamsEquals(
          new URLSearchParams(prevSearch.current ?? ""),
          new URLSearchParams(search),
        ))
    ) {
      setNeedUpdate(true);
    }

    prevSearch.current = search;
  }, [search]);

  useEffect(() => {
    let isActive = true;
    const transform = async () => {
      const filter = await queryParamsService.castQueryObjectForce(schema, getObject(search));
      if (isActive) {
        setQueryObject(filter);
        setNeedUpdate(false);
      }
    };

    if (needUpdate) {
      transform();
    }

    return () => {
      isActive = false;
    };
  }, [needUpdate, schema, search]);

  return [queryObject, forceUpdate];
};
