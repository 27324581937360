import { useCallback } from "react";
import { FieldNames } from "../types/FieldNames";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGetFieldError = <TFormValues extends Record<string, any>>() => {
  return useCallback(
    (field: FieldNames<TFormValues>, touched: boolean | undefined, error: string | undefined): string | undefined => {
      return touched && error !== undefined ? error : undefined;
    },
    []
  );
};
