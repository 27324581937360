import { CSSTransition } from "react-transition-group";
import { useGlobalNetworkState } from "../hooks/useGlobalNetworkState";
import * as S from "./GlobalNetworkState.styled";
import { GlobalNetworkStateType } from "../types/GlobalNetworkStateType";
import { useDebounce } from "../../../common/hooks/useDebounce";
import { PortalContainer } from "../../../common/ui/containers/portal";

const stateText: Record<GlobalNetworkStateType, string> = {
  offline: "Нет подключения",
  online: "Подключение восстановлено",
};

export const GlobalNetworkState = () => {
  const state = useGlobalNetworkState();
  const [debounceState] = useDebounce(state, 1_000);

  return (
    <PortalContainer htmlElementId={import.meta.env.VITE_NETWORK_STATUS_CONTAINER_ID ?? ""}>
      <CSSTransition
        timeout={{ enter: 0, exit: 1_000 }}
        unmountOnExit
        in={state === "offline" || state !== debounceState}
      >
        <S.Container state={state}>{stateText[state]}</S.Container>
      </CSSTransition>
    </PortalContainer>
  );
};
