import { createTheme } from "@sideg/ui-kit";
import { SpacesObject } from "./types";

type ThemeColor = "primary" | "secondary" | "warning" | "info" | "success" | "danger" | "dark";
type ColorVariant = "light" | "dark" | "disabled";

export type Colors = `${ThemeColor}${Capitalize<ColorVariant | "">}`;

export type Color = string;

export type SizeUnit = string;

interface BaseComponentColors {
  background: Color;
  text: Color;
}

export interface ControlColors extends BaseComponentColors {
  border: Color;
}

interface ColorPalette {
  main: Color;
  light: Color;
  dark: Color;
  contrastText: Color;
}

export interface AppTheme {
  colors: Record<Colors, string>;
  spaces: SpacesObject;
  makePadding: (a: keyof SpacesObject, b: keyof SpacesObject, c?: keyof SpacesObject, d?: keyof SpacesObject) => string;
  secondaryColors: {
    lightBackground: Color;
    secondaryBackground: Color;
    secondaryBorder: Color;
    primaryBackground: Color;
    notificationBackground: Color;
    alertBackground: Color;
  };
  font: {
    fz1: SizeUnit;
    fz2: SizeUnit;
    fz3: SizeUnit;
    fz4: SizeUnit;
    defaultLineHeight: SizeUnit;
    fontFamily: string;
  };
  palette: {
    control: {
      secondaryLight: Color;
      secondaryMedium: Color;
      primaryLight: Color;
      primaryHover: Color;
      secondaryHover: Color;
    };
    background: {
      light: Color;
      contrastLight: Color;
      contrast: Color;
    };
    typography: {
      primary: Color;
      secondary: Color;
      primaryLight: Color;
      primaryCaption: Color;
      medium: Color;
      success: Color;
      error: Color;
      successSecondary: Color;
    };
    status: {
      error: Color;
      errorBackground: Color;
      success: Color;
      successBackground: Color;
      successSecondary: Color;
      successSecondaryBackground: Color;
      warning: Color;
      warningBackground: Color;
    };
    input: {
      default: ControlColors;
      active: ControlColors;
      hover: ControlColors;
      disabled: ControlColors;
      errorDefault: ControlColors;
      errorActive: ControlColors;
      suggestion: BaseComponentColors;
      errorText: Color;
      placeholder: Color;
      label: Color;
      fieldsetBorder: Color;
      radioButton: {
        active: Color;
      };
    };
    checkbox: {
      label: Color;
      focus: Color;
      default: ControlColors;
      checked: ControlColors;
      disabledDefault: ControlColors;
      disabledChecked: ControlColors;
      error: Omit<ControlColors, "text">;
    };
    switch: {
      trackInactive: Color;
      trackActive: Color;
      trackDisabled: Color;
      thumb: Color;
      iconColor: Color;
      errorTrackColor: Color;
    };
    fileLabel: {
      default: Color;
      hover: Color;
    };
  };
  layout: {
    borderPadding: SizeUnit;
    borderPaddingNumber: number;
    zIndex: number;
    modalBackground: Color;
    publicPage: {
      button: {
        color: Color;
      };
      background: Color;
      textColor: Color;
      formBackgroundColor: Color;
      notificationColor: Color;
      cardPaddingBase: {
        mobile: number;
        desktop: number;
      };
    };
    sidebar: {
      mobile: {
        blockColor: Color;
        counterBackgroundColor: Color;
        dividerColor: Color;
        textColor: Color;
      };
      backgroundColor: Color;
      menu: {
        linkColor: Color;
        activeLinkColor: Color;
        counterTextColor: Color;
      };
      newsWidget: {
        background: Color;
        color: Color;
      };
      zIndex: number;
    };
    header: {
      backgroundColor: Color;
      height: SizeUnit;
      userAvatarBackground: Color;
      zIndex: number;
      popup: {
        userMenuBackgroundColor: Color;
        backgroundColor: Color;
        borderRadius: SizeUnit;
        boxShadow: string;
        accentItemColor: Color;
      };
      button: {
        size: SizeUnit;
        backgroundColor: Color;
        activeBackgroundColor: Color;
        hoverBackgroundColor: Color;
        borderRadius: SizeUnit;
        notificationCountColor: Color;
        notificationCountBorderColor: Color;
      };
    };
    demand: {
      colors: {
        leftSideBackgroundColor: Color;
        rightSideBackgroundColor: Color;
        elementAccentBackgroundColor: Color;
      };
    };
    global: {
      pwa: {
        zIndex: number;
      };
      appUpdater: {
        zIndex: number;
      };
      network: {
        zIndex: number;
      };
    };
  };
}

const primaryColor: ColorPalette = {
  contrastText: "#FFFFFF",
  light: "#B9D3EE",
  main: "#2D71BB",
  dark: "#0A173A",
};

const darkColor: Color = "#000000";
const lightColor: Color = "#FFFFFF";

const errorControlPalette: ControlColors = {
  border: "#FFD8D8",
  background: "#FFD8D8",
  text: darkColor,
};

const errorControlColor: Color = "#EF0000";
const primaryBackground: Color = "#EAF2FC";

const layoutElementAccentBackgroundColor = "#F4F4F4";

const appTheme: AppTheme = {
  colors: {
    primary: primaryColor.main,
    primaryDark: primaryColor.dark,
    primaryDisabled: "#BFBFBF",
    primaryLight: primaryColor.light,
    secondary: "#909090",
    secondaryDark: "",
    secondaryDisabled: "#EBF0F3",
    secondaryLight: "#E5E5E5",
    warning: "#EC8812",
    warningDark: "",
    warningDisabled: "",
    warningLight: "#edd4d4",
    info: "#e6f2ff",
    infoDark: "",
    infoDisabled: "#0965a91a",
    infoLight: "#8a9099",
    success: "#38700d",
    successDark: "#155724",
    successDisabled: "",
    successLight: "#E7FBE4",
    danger: "#c7282d",
    dangerDark: "#571515",
    dangerDisabled: "",
    dangerLight: "#FDEAEA",
    dark: "#444444",
    darkDark: "",
    darkDisabled: "",
    darkLight: "#666666",
  },
  palette: {
    control: {
      secondaryLight: "#F4F5F5",
      secondaryMedium: "#E4E7EA",
      primaryLight: primaryBackground,
      primaryHover: "#0D55A4",
      secondaryHover: "#D9EAFF",
    },
    background: {
      light: "#ffffff",
      contrastLight: "#EDF0F5",
      contrast: "#E7ECF5",
    },
    typography: {
      primary: darkColor,
      secondary: "#444444",
      primaryCaption: "#828282",
      error: errorControlColor,
      success: "#0B9711",
      primaryLight: lightColor,
      medium: "#919191",
      successSecondary: "#86AE15",
    },
    status: {
      error: errorControlColor,
      errorBackground: "#FDEAEA",
      success: "#249F05",
      successBackground: "#E7FBE5",
      successSecondary: "#86AE15",
      successSecondaryBackground: "#F5FFD8",
      warning: "#EC8812",
      warningBackground: "#FFF0DE",
    },
    input: {
      default: {
        text: darkColor,
        border: "#F4F4F4",
        background: "#F4F4F4",
      },
      hover: {
        text: darkColor,
        border: primaryBackground,
        background: primaryBackground,
      },
      active: {
        text: darkColor,
        border: primaryColor.main,
        background: lightColor,
      },
      disabled: {
        text: "#7D7D7D",
        border: "#DEDEDE",
        background: "#DEDEDE",
      },
      errorDefault: errorControlPalette,
      errorActive: {
        ...errorControlPalette,
        text: darkColor,
        border: errorControlColor,
        background: lightColor,
      },
      errorText: errorControlColor,
      suggestion: {
        text: lightColor,
        background: primaryBackground,
      },
      placeholder: "#b3b3b3",
      label: darkColor,
      fieldsetBorder: "#E0E0E0",
      radioButton: {
        active: "#E1EEFF",
      },
    },
    checkbox: {
      label: darkColor,
      focus: "#105198",
      default: { background: lightColor, text: lightColor, border: "#666666" },
      checked: { background: primaryColor.main, text: lightColor, border: primaryColor.main },
      disabledDefault: { background: "#EEEEEE", text: lightColor, border: "#D6D6D6" },
      disabledChecked: { background: "#D6D6D6", text: lightColor, border: "#D6D6D6" },
      error: { background: errorControlPalette.background, border: errorControlColor },
    },
    switch: {
      trackActive: primaryColor.main,
      trackInactive: "#919191",
      trackDisabled: "#D6D6D6",
      thumb: lightColor,
      iconColor: "#C7C7C7",
      errorTrackColor: errorControlPalette.background,
    },
    fileLabel: {
      default: "#E5F2FF",
      hover: "#D9EAFF",
    },
  },
  secondaryColors: {
    lightBackground: "#f0f3f6",
    secondaryBackground: "#f6f7fa",
    secondaryBorder: "#d1d6db",
    primaryBackground,
    notificationBackground: "#ef0000",
    alertBackground: "#E1EEFF",
  },
  font: {
    fz1: "12px",
    fz2: "14px",
    fz3: "16px",
    fz4: "18px",
    defaultLineHeight: "24px",
    fontFamily: '"PT Root UI", "Helvetica", sans-serif',
  },
  layout: {
    borderPadding: "30px",
    borderPaddingNumber: 30,
    zIndex: 10,
    modalBackground: "rgba(10, 23, 58, 0.4)",
    publicPage: {
      button: {
        color: "#5A9CE3",
      },
      background: "#1c1b1b",
      textColor: "#fbfbfc",
      formBackgroundColor: "#2c2c2f",
      notificationColor: "#f21313",
      cardPaddingBase: {
        mobile: 2,
        desktop: 2.5,
      },
    },
    sidebar: {
      mobile: {
        textColor: "#BBCDFC",
        dividerColor: "#3F548E",
        blockColor: "#283D75",
        counterBackgroundColor: "#384C85",
      },
      menu: {
        linkColor: "#649EDD",
        activeLinkColor: "#76C633",
        counterTextColor: "#ffffff",
      },
      newsWidget: {
        background: "#2F4583",
        color: "#FFFFFF",
      },
      zIndex: 11,
      backgroundColor: "#22356A",
    },
    header: {
      backgroundColor: "#0A173A",
      height: "56px",
      userAvatarBackground: "#5BAF1B",
      zIndex: 12,
      popup: {
        userMenuBackgroundColor: "#F6F6F6",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0px 12px 24px rgba(89, 111, 136, 0.4)",
        accentItemColor: "#F4F5F5",
      },
      button: {
        size: "32px",
        backgroundColor: "#203368",
        activeBackgroundColor: "#ffffff",
        hoverBackgroundColor: "#2F4583",
        borderRadius: "4px",
        notificationCountColor: "#ffffff",
        notificationCountBorderColor: "#12214a",
      },
    },
    demand: {
      colors: {
        leftSideBackgroundColor: "#F0F3F6",
        rightSideBackgroundColor: "#F7F8FA",
        elementAccentBackgroundColor: layoutElementAccentBackgroundColor,
      },
    },
    global: {
      pwa: {
        zIndex: 14,
      },
      appUpdater: {
        zIndex: 13,
      },
      network: {
        zIndex: 15,
      },
    },
  },
  spaces: {
    none: "0rem",
    xs: "0.25rem",
    sm: "0.5rem",
    md: "0.75rem",
    lg: "1rem",
    xl: "1.25rem",
    xxl: "1.5rem",
  },
  makePadding(this: AppTheme, a, b, c, d) {
    return [this.spaces[a], this.spaces[b], c && this.spaces[c], d && this.spaces[d]].filter((x) => !!x).join(" ");
  },
};

export const theme = createTheme(appTheme, { desktopBreakpoint: "xl" });
