import Skeleton from "react-loading-skeleton";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { DemandQueueTaskCard } from "../card/DemandQueueTaskCard";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { DemandQueueTaskCommentSkeleton } from "../comment/DemandQueueTaskComment.skeleton";
import { DemandQueueTaskSeparator } from "../separator/DemandQueueTaskSeparator";
import { DemandQueueTaskCardContainer } from "../card/DemandQueueTaskCardContainer";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";

export type DemandQueueTasksActiveCardSkeletonProps = BaseCssProps;

export const DemandQueueTasksActiveCardSkeleton = ({ className }: DemandQueueTasksActiveCardSkeletonProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <DemandQueueTaskCard variant="active" title={<Skeleton width={150} />} className={className}>
      <DemandQueueTaskCommentSkeleton />
      <DemandQueueTaskSeparator />
      <DemandQueueTaskCardContainer>
        <Skeleton width={220} />
        <StackPanel direction={{ xs: "column", [desktopBreakpoint]: "row" }} gap="md">
          <Skeleton width={80} height={32} />
          <Skeleton width={80} height={32} />
        </StackPanel>
        <Skeleton height={60} />
      </DemandQueueTaskCardContainer>
    </DemandQueueTaskCard>
  );
};
