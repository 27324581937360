import styled from "@emotion/styled";
import { AppLink } from "../../../../common/urls/internal/components";

export const Card = styled(AppLink)`
  appearance: none;
  text-decoration: none;
  background-color: ${({ theme }) => theme.palette.fileLabel.default};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  padding: ${({ theme }) => theme.spaces.md} ${({ theme }) => theme.spaces.xxl};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.sm};
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: 1.4;
  text-align: center;
  border: 2px solid transparent;

  &:focus-visible,
  &:active {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.palette.fileLabel.hover};
  }
`;
