import styled from "@emotion/styled";

export const StagesContainer = styled.div`
  display: inline-flex;
  margin-top: ${({ theme }) => theme.spaces.sm};

  & > * + *::before {
    content: "•";
    font-weight: normal;
    margin: 0 ${({ theme }) => theme.spaces.md};
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
