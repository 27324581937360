import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../ui-kit/types";

export interface ProductDetailsAsideNavigationCardSkeletonProps extends BaseCssProps {
  height?: number;
}

export const ProductDetailsAsideNavigationCardSkeleton = ({
  height = 60,
  className,
}: ProductDetailsAsideNavigationCardSkeletonProps) => {
  return <Skeleton height={height} className={className} />;
};
