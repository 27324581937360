import { array, date, number, object, string } from "yup";
import {
  getDateOnlyTransformer,
  getDemandIdSchema,
  getFilterArraySchema,
  getEnumLikeLiteralTypeSchema,
} from "../../../common/validation";
import { productType } from "../../../common/types/demand";
import { getStringToArrayTransformer } from "../../../common/validation/transformers/getStringToArrayTransformer";
import { queueTasksListFilterTab, queueTasksListOrdering } from "../types/filter";

export const queueTasksListFiltersValidationSchema = object({
  search: string(),
  clientNameOrTaxpayerNumber: string(),
  demandId: getDemandIdSchema(),
  productId: number(),
  products: array().of(getEnumLikeLiteralTypeSchema(productType).required()).transform(getStringToArrayTransformer()),
  banks: getFilterArraySchema(string().required()),
  dateAddedFrom: date().transform(getDateOnlyTransformer()).typeError("Укажите корректную дату"),
  dateAddedTo: date().transform(getDateOnlyTransformer()).typeError("Укажите корректную дату"),
  taskTypes: getFilterArraySchema(string().required()),
  ordering: getEnumLikeLiteralTypeSchema(queueTasksListOrdering).default("NewThenByDateAddedDesc"),
  tab: getEnumLikeLiteralTypeSchema(queueTasksListFilterTab).default("All"),
  page: number().default(1),
});
