import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { globalToastsAdapter } from "./globalToasts.adapter";
import {
  GLOBAL_TOASTS_SLICE_NAME,
  pushCustomGlobalToast,
  pushErrorGlobalToast,
  pushSuccessGlobalToast,
  pushWarningGlobalToast,
} from "./globalToasts.actions";
import { GlobalToastsState } from "./globalToasts.types";
import { ToastId } from "../types/ToastId";
import { LoadingStatusEnum } from "../../../common/types/state";

const initialState: GlobalToastsState = globalToastsAdapter.getInitialState();

const slice = createSlice({
  name: GLOBAL_TOASTS_SLICE_NAME,
  initialState,
  reducers: {
    removeToast: (state, action: PayloadAction<ToastId>) => {
      globalToastsAdapter.removeOne(state, action.payload);
    },
    toastShown: (state, action: PayloadAction<ToastId>) => {
      globalToastsAdapter.updateOne(state, { id: action.payload, changes: { status: LoadingStatusEnum.Loading } });
    },
    hideToast: (state, action: PayloadAction<ToastId>) => {
      globalToastsAdapter.updateOne(state, { id: action.payload, changes: { status: LoadingStatusEnum.Succeeded } });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(pushSuccessGlobalToast, pushWarningGlobalToast, pushErrorGlobalToast, pushCustomGlobalToast),
      (state, action) => {
        globalToastsAdapter.addOne(state, action.payload);
      },
    );
  },
});

export const globalToastReducer = slice.reducer;

export const {
  removeToast: removeToastGlobalToasts,
  toastShown: toastShownGlobalToasts,
  hideToast: hideToastGlobalToasts,
} = slice.actions;
