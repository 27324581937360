import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { StackPanel } from "../../../../ui-kit/containers/stack-panel";
import { ProductStageIcon, ProductStageIconProps } from "./ProductStageIcon";
import { Typography } from "../../../../ui-kit/typography";
import { Tooltip, useTooltip } from "../../../../ui-kit/pop-up/tooltip";

export interface ProductStageCounterProps extends ProductStageIconProps {
  tooltip: string;
  count: number;
}

export const ProductStageCounter = ({ icon, tooltip, count, className, color }: ProductStageCounterProps) => {
  const isDesktop = useThemeIsDesktop();
  const { onClick, ref, popupProps, placement, arrow, onMouseLeave, onMouseEnter, isOpened } = useTooltip({
    position: isDesktop ? "bottom" : "bottom-end",
    type: "hoverOrClick",
  });

  return (
    <StackPanel
      gap="xs"
      direction="row"
      alignItems="center"
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      ref={ref}
    >
      <ProductStageIcon icon={icon} color={color} />
      <Typography userSelect="none" fontColor={color} fontSize="fz2" lineHeight="defaultLineHeight" fontWeight={500}>
        {count}
      </Typography>
      {isOpened && (
        <Tooltip popUpProps={popupProps} placement={placement} arrowData={arrow.data} arrowRef={arrow.ref}>
          {tooltip}
        </Tooltip>
      )}
    </StackPanel>
  );
};
