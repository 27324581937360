import * as React from "react";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { DemandId, ProductId, ProductType } from "../../../../../types/demand";
import { ScoringToggleDemandLinkButton } from "../../../../../ui/buttons/scoring-toogle";
import { ProductCreationScoringResultRowDuplicateContainer } from "./ProductCreationScoringResultRowDuplicateContainer";

export interface ProductCreationScoringResultRowDuplicateProps extends BaseCssProps {
  bankName: React.ReactNode;
  demandId?: DemandId;
  productId?: ProductId;
  productType?: ProductType;
}

export const ProductCreationScoringResultRowDuplicate = ({
  bankName,
  productId,
  productType,
  demandId,
  className,
}: ProductCreationScoringResultRowDuplicateProps) => {
  return (
    <ProductCreationScoringResultRowDuplicateContainer
      bankName={bankName}
      className={className}
      action={
        demandId &&
        productId &&
        productType && (
          <ScoringToggleDemandLinkButton demandId={demandId} productType={productType} productId={productId} />
        )
      }
    />
  );
};
