import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { queueTasksCountersWaitingReload, selectQueueTasksCounters } from "../../../../queue-tasks/counters";
import { getUserMenu } from "../utils/getUserMenu";
import { useLocationPathEffect } from "./useLocationPathEffect";
import { LoadingStatusEnum } from "../../../types/state";
import { getQueueTasksCounters } from "../../../../queue-tasks/counters/store/queueTasksCounters.thunks";
import { selectAuthorizedUser } from "../../../authorized-user";

export const useAppMenuItems = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { role, featureFlags } = useAppSelector(selectAuthorizedUser).user!;
  const { status } = useAppSelector(selectQueueTasksCounters);
  const items = useMemo(() => getUserMenu(role, featureFlags), [role, featureFlags]);

  const dispatch = useAppDispatch();

  useLocationPathEffect(() => {
    dispatch(queueTasksCountersWaitingReload());
  }, [dispatch]);

  useEffect(() => {
    if (status === LoadingStatusEnum.Idle) {
      dispatch(getQueueTasksCounters());
    }
  }, [dispatch, status]);

  return items;
};
