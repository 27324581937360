import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { PublicUserRegistrationCardLink } from "../components/card-link/PublicUserRegistrationCardLink";
import { StackPanel } from "../../../common/ui-kit/containers/stack-panel";
import { PublicUserCardWithTitle } from "../../common/components";
import { usePageTitle } from "../../../common/navigation/page-title";

export const PublicUserRegistrationNewPage = () => {
  usePageTitle("Регистрация");

  const isDesktop = useThemeIsDesktop();

  return (
    <PublicUserCardWithTitle title="Регистрация">
      <StackPanel gap="lg" direction="column">
        <PublicUserRegistrationCardLink url={(x) => x.publicUser.registration.client} title="Клиент">
          Отправляет заявки только на свою организацию {isDesktop && <br />} (ИП или юр.лицо)
        </PublicUserRegistrationCardLink>
        <PublicUserRegistrationCardLink url={(x) => x.publicUser.registration.agent} title="Агент">
          Отправляет заявки для своих клиентов.
          <br /> (Может быть физ. лицом, ИП или юр.лицом)
        </PublicUserRegistrationCardLink>
      </StackPanel>
    </PublicUserCardWithTitle>
  );
};
