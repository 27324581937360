import { useCallback } from "react";
import { useCreditExecutionListFilterContext } from "./useCreditExecutionListFilterContext";
import { useCreditExecutionListFilterApiContext } from "./useCreditExecutionListFilterApiContext";
import { FilterTabType } from "../../../../common/filters/use-cases";
import { isFilterAvailableForTabCreditExecution } from "../../validation/rules/isFilterAvailableForTabCreditExecution";

export const useCreditExecutionListFilterOnTabChange = () => {
  const filteringObject = useCreditExecutionListFilterContext();
  const mutator = useCreditExecutionListFilterApiContext();

  return useCallback(
    (tab: FilterTabType) => {
      mutator(filteringObject, (filter, { setValues }) =>
        setValues(filter, {
          tab,
          status: isFilterAvailableForTabCreditExecution(tab, "status") ? filteringObject.status : undefined,
          taskTypes: isFilterAvailableForTabCreditExecution(tab, "taskTypes") ? filteringObject.taskTypes : undefined,
        })
      );
    },
    [filteringObject, mutator]
  );
};
