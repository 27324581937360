import { dateTimeHelper } from "@sideg/helpers";
import { nanoid } from "@reduxjs/toolkit";
import { ApiResponse, isBaseApiError } from "../../api/types";
import { AuthRefreshResponseTypeEnum, StatusCodeEnum } from "../../services/fetchClient";
import { AccessToken } from "../types/jwt/AccessToken";
import { LocalStorageExpiresTimeService } from "../../services/local-storage";
import { AuthenticationUserTokenDetails } from "../types/AuthenticationUserTokenDetails";
import { loggingService } from "../../logging/services/loggingService";
import { authenticationApi } from "../api/authenticationApi";
import { AuthenticationAuthServiceStoredTokenService } from "./stored-token/AuthenticationAuthServiceStoredTokenService";
import { AuthenticationApiStoredTokenService } from "./stored-token/AuthenticationApiStoredTokenService";
import { ApiAuthenticationOutputDto } from "../api/dto/output/ApiAuthenticationOutputDto";

const EXPIRES_TIME_GAP_SECONDS = 30;
const EXPIRES_TIME_KEY = "utb";

export type AuthenticationUserAuthenticatedCallback = (token: AuthenticationUserTokenDetails) => void;

export type AuthenticationRemovedCallback = () => void;

export class AuthenticationService {
  private refreshingPromise: Promise<AuthRefreshResponseTypeEnum> | undefined = undefined;

  private userAuthenticatedListeners: AuthenticationUserAuthenticatedCallback[] = [];

  private authenticationRemovedListeners: AuthenticationRemovedCallback[] = [];

  private readonly localStorageExpiresTimeProvider: LocalStorageExpiresTimeService;

  private readonly authServiceToken: AuthenticationAuthServiceStoredTokenService;

  private readonly apiToken: AuthenticationApiStoredTokenService;

  constructor(
    authServiceToken = new AuthenticationAuthServiceStoredTokenService(),
    apiToken = new AuthenticationApiStoredTokenService(),
    localStorageExpiresTimeProvider = new LocalStorageExpiresTimeService(EXPIRES_TIME_KEY),
  ) {
    this.authServiceToken = authServiceToken;
    this.apiToken = apiToken;
    this.localStorageExpiresTimeProvider = localStorageExpiresTimeProvider;
  }

  public isExpired = () => {
    // Считаем токен истекшим, если пользователь еще не обновился со старого рефреш токена.
    if (this.authServiceToken.isTokenExists()) {
      return true;
    }

    return this.localStorageExpiresTimeProvider.isTimeExpired();
  };

  public refresh = async (): Promise<AuthRefreshResponseTypeEnum> => {
    // Обновляем токен только на первый запрос.
    if (!this.refreshingPromise) {
      this.refreshingPromise = this.actualRefresh();
    }

    const result = await this.refreshingPromise;
    this.refreshingPromise = undefined;

    return result;
  };

  private actualRefresh = async (): Promise<AuthRefreshResponseTypeEnum> => {
    const isApiTokenExists = this.apiToken.isTokenExists();
    const isAuthTokenExists = this.authServiceToken.isTokenExists();

    if (!isApiTokenExists && !isAuthTokenExists) {
      await this.removeAuthentication();

      return AuthRefreshResponseTypeEnum.AuthError;
    }

    try {
      const authenticationResult = isApiTokenExists
        ? await authenticationApi.refreshToken({
            accessToken: this.apiToken.getAccessToken()!,
            deathDate: new Date(),
            userFingerprint: nanoid(36),
          })
        : await authenticationApi.refreshTokenByOldToken(this.authServiceToken.getRefreshToken()!);

      this.saveAuthentication(authenticationResult);
      this.dispatchUserAuthorized(this.apiToken.mapUserTokenDetails(authenticationResult.body));

      return AuthRefreshResponseTypeEnum.Success;
    } catch (err: unknown) {
      if (isBaseApiError(err) && err.status === StatusCodeEnum.Unauthorized) {
        await this.removeAuthentication();

        return AuthRefreshResponseTypeEnum.AuthError;
      }

      loggingService.logError(err);

      return AuthRefreshResponseTypeEnum.NetworkError;
    }
  };

  public authenticate = async (username: string, password: string) => {
    const authenticationResult = await authenticationApi.authenticate(username, password);

    this.saveAuthentication(authenticationResult);
    this.dispatchUserAuthorized(this.apiToken.mapUserTokenDetails(authenticationResult.body));
  };

  public removeAuthentication = async () => {
    this.clearStoredData();
    this.dispatchAuthenticationRemoved();
  };

  public logout = async () => {
    await this.refreshIfExpired();

    try {
      await authenticationApi.logout(this.getAuthorizationHeaders());
    } catch (e) {
      loggingService.logError(e);
    }

    await this.removeAuthentication();
  };

  // eslint-disable-next-line class-methods-use-this
  public getAuthorizationHeaders = () => {
    const header = this.apiToken.getHeaderValue();

    return header !== undefined
      ? {
          Authorization: header,
        }
      : undefined;
  };

  public makeHubConnectionAccessTokenFactory = (): (() => Promise<AccessToken>) => {
    return async () => {
      await this.refreshIfExpired();

      const token = this.apiToken.getAccessToken();
      if (token === undefined) {
        throw new Error("Ошибка обновления токена - токен не найден");
      }

      return token;
    };
  };

  public onUserAuthenticated = (listener: AuthenticationUserAuthenticatedCallback): void => {
    this.userAuthenticatedListeners.push(listener);
  };

  public onAuthenticationRemoved = (listener: AuthenticationRemovedCallback): void => {
    this.authenticationRemovedListeners.push(listener);
  };

  public offUserAuthenticated = (listener: AuthenticationUserAuthenticatedCallback): void => {
    this.userAuthenticatedListeners = this.userAuthenticatedListeners.filter((x) => x !== listener);
  };

  public offAuthenticationRemoved = (listener: AuthenticationRemovedCallback): void => {
    this.authenticationRemovedListeners = this.authenticationRemovedListeners.filter((x) => x !== listener);
  };

  private refreshIfExpired = async (): Promise<void> => {
    if (this.isExpired()) {
      await this.refresh();
    }
  };

  private dispatchUserAuthorized = (tokenDetails: AuthenticationUserTokenDetails) => {
    this.userAuthenticatedListeners.forEach((callback) => {
      callback(tokenDetails);
    });
  };

  private dispatchAuthenticationRemoved = () => {
    this.authenticationRemovedListeners.forEach((callback) => {
      callback();
    });
  };

  private saveAuthentication = (authenticationResult: ApiResponse<ApiAuthenticationOutputDto>) => {
    this.apiToken.saveToken(authenticationResult.body);
    this.setExpiresTime(this.apiToken.getExpiresTime(authenticationResult.body));

    this.authServiceToken.removeToken();
  };

  private setExpiresTime = (tokenExpiresTime: Date) => {
    const expiresTime = dateTimeHelper.simpleModify("subSeconds", tokenExpiresTime, EXPIRES_TIME_GAP_SECONDS);
    this.localStorageExpiresTimeProvider.setExpiresTime(+expiresTime);
  };

  private clearStoredData = () => {
    this.localStorageExpiresTimeProvider.removeExpiresTime();

    this.authServiceToken.removeToken();
    this.apiToken.removeToken();
  };
}
