import { bankGuaranteeDetailsReloadActiveGuarantee } from "../store/bankGuaranteeDetails.actions";
import { BankGuaranteeId } from "../../../common/types/demand";
import { useProductDetailsQueueTasksNeedUpdateListener } from "../../../common/product-details";
import { useAppSelector } from "../../../common/store";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { bankGuaranteeDetailsReloadService } from "../services/bankGuaranteeDetailsReloadService";

export const useBankGuaranteeDetailsQueueTasksNeedUpdateListener = (guaranteeId: BankGuaranteeId) => {
  const { lastRequestTime } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);

  useProductDetailsQueueTasksNeedUpdateListener(
    guaranteeId,
    "BankGuarantee",
    bankGuaranteeDetailsReloadActiveGuarantee,
    lastRequestTime,
    bankGuaranteeDetailsReloadService.timeoutInMs,
  );
};
