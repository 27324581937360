import { combineSliceNames } from "../../../common/store/utils";
import { BANK_GUARANTEE_SLICE_NAME } from "../../common/store";
import { bankGuaranteeDetailsApi } from "../api/bankGuaranteeDetailsApi";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";

export const BANK_GUARANTEE_DETAILS_SLICE_NAME = combineSliceNames(BANK_GUARANTEE_SLICE_NAME, "details");

export const {
  get: getBankGuaranteeDetails,
  getDemand: getBankGuaranteeDetailsDemand,
  getRequestOriginalComments: getRequestOriginalCommentsBankGuaranteeDetails,
  sendRequestOriginalComment: sendRequestOriginalCommentBankGuaranteeDetails,
  uploadGroupDocument: uploadGroupDocumentBankGuaranteeDetailsDemand,
  sendBillChanges: sendBillChangesBankGuaranteeDetailsDemand,
  sendProjectChanges: sendProjectChangesBankGuaranteeDetailsDemand,
  getHistory: getHistoryBankGuaranteeDetailsDemand,
  sendConfirmPayment: sendConfirmPaymentBankGuaranteeDetailsDemand,
} = createApiThunks(BANK_GUARANTEE_DETAILS_SLICE_NAME, bankGuaranteeDetailsApi);
