import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { isLoading, isSucceeded } from "../../../../../common/types/state";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { useAppSelector } from "../../../../../common/store";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  ProductCreationScoringFormSubmitButton,
  ProductCreationScoringLoadingResultCard,
} from "../../../../../common/product-creation";
import { CreditExecutionCreationScoringResultSuccessRow } from "../result-row/CreditExecutionCreationScoringResultSuccessRow";
import { CreditExecutionCreationScoringResultSuccessRowSkeleton } from "../result-row/CreditExecutionCreationScoringResultSuccessRow.skeleton";
import { CreditExecutionCreationScoringFormValues } from "../../../types/scoring/CreditExecutionCreationScoringFormValues";
import { selectCreditExecutionCreationScoring } from "../../../store/creditExecutionCreation.selectors";

const SUCCESS_BANKS_SKELETON_COUNT = 3;

export interface CreditExecutionCreationScoringCreateByExistsSuccessBlockProps extends BaseCssProps {
  creditSum?: number;
}

export const CreditExecutionCreationScoringCreateByExistsSuccessBlock = ({
  creditSum,
  className,
}: CreditExecutionCreationScoringCreateByExistsSuccessBlockProps) => {
  const { data, status } = useAppSelector(selectCreditExecutionCreationScoring);

  const isDesktop = useThemeIsDesktop();
  const isShowSubmitButton = isSucceeded(status);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(isLoading(status) || (data?.success && data.success.length > 0)) && (
        <StackPanel direction="column" gap="md" className={className}>
          <ProductCreationScoringLoadingResultCard
            isLoading={isLoading(status)}
            items={data?.success}
            skeletonElementsCount={SUCCESS_BANKS_SKELETON_COUNT}
            skeleton={CreditExecutionCreationScoringResultSuccessRowSkeleton}
            title="Выберите банки, в которые хотите доотправить заявку"
            footer={
              isDesktop &&
              isShowSubmitButton && (
                <Box pt="md" pb="sm">
                  <ProductCreationScoringFormSubmitButton<CreditExecutionCreationScoringFormValues> />
                </Box>
              )
            }
          >
            {(x) => <CreditExecutionCreationScoringResultSuccessRow scoringResult={x} creditSum={creditSum} />}
          </ProductCreationScoringLoadingResultCard>
          {!isDesktop && isShowSubmitButton && (
            <ProductCreationScoringFormSubmitButton<CreditExecutionCreationScoringFormValues> />
          )}
        </StackPanel>
      )}
    </>
  );
};
