import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { BANK_GUARANTEE_LIST_SLICE_NAME, getCountersBankGuaranteeList } from "../bankGuaranteeList.thunks";
import { BankGuaranteeListCountersState } from "./bankGuaranteeListCounters.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";

const SLICE_NAME = combineSliceNames(BANK_GUARANTEE_LIST_SLICE_NAME, "counters");

const initialState: BankGuaranteeListCountersState = {
  status: LoadingStatusEnum.Idle,
  data: undefined,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountersBankGuaranteeList.fulfilled, (state, action) => {
        state.data = action.payload.body.data;
      })
      .addMatcher(isAsyncThunkAction(getCountersBankGuaranteeList), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const bankGuaranteeListCountersReducer = slice.reducer;
