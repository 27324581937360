import spaces from "@sideg/ui-kit/core/theme/__generated__/spaces.gen";
import { numberHelper } from "@sideg/helpers/number";

export type ThemeGeneratedSpacingType = keyof typeof spaces;
export type ThemeSpacingType = ThemeGeneratedSpacingType | "none";

const MULTIPLIER_CHARACTER = "*" as const;
export type ThemeSpacingValueWithMultiplier = `${ThemeGeneratedSpacingType} ${typeof MULTIPLIER_CHARACTER} ${number}`;

export type ThemeExtendedSpacingType = ThemeSpacingType | ThemeSpacingValueWithMultiplier;

export const isThemeSpacingValueWithMultiplier = (value: unknown): value is ThemeSpacingValueWithMultiplier => {
  if (typeof value === "string") {
    const values = value.split(` ${MULTIPLIER_CHARACTER} `);
    if (values.length === 2) {
      const [spacing, number] = values;

      return (
        Object.prototype.hasOwnProperty.call(spaces, spacing) &&
        numberHelper.isValidNumber(numberHelper.getFromString(number))
      );
    }
  }

  return false;
};

export const getThemeSpacingValueWithMultiplier = (
  value: ThemeSpacingValueWithMultiplier,
): [ThemeGeneratedSpacingType, number] => {
  const [spacing, number] = value.split(` ${MULTIPLIER_CHARACTER} `);

  return [spacing as ThemeGeneratedSpacingType, numberHelper.getFromString(number)];
};

export type ThemeGeneratedSpaceValue = number;
export type ThemeSpaceValue = `${number}rem`;

export type ThemeGeneratedSpacesValues = { [key in ThemeGeneratedSpacingType]: ThemeGeneratedSpaceValue };
export type ThemeSpacingValues = { [K in ThemeSpacingType]: ThemeSpaceValue };

interface ThemeCssSpacesFunction {
  (all: ThemeSpacingType): string;

  (topAndBottom: ThemeSpacingType, leftAndRight: ThemeSpacingType): string;

  (top: ThemeSpacingType, leftAndRight: ThemeSpacingType, bottom: ThemeSpacingType): string;

  (top: ThemeSpacingType, right: ThemeSpacingType, bottom: ThemeSpacingType, left: ThemeSpacingType): string;
}

export interface ThemeSpacingWithMultipleUtil {
  (spacing: ThemeSpacingType, multiplier: number): ThemeSpaceValue;

  (spaceWithMultiplier: ThemeExtendedSpacingType): ThemeSpaceValue;
}

export interface ThemeSpacingUtils {
  withMultiple: ThemeSpacingWithMultipleUtil;
  makeCssSpaces: ThemeCssSpacesFunction;
}
