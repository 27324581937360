import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeListItemsGuaranteesIds } from "../../store/items/bankGuaranteeListItems.selectors";
import { BankGuaranteeListItem } from "./item/BankGuaranteeListItem";
import { BankGuaranteeId } from "../../../../common/types/demand";
import { useCreateNavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { ProductListItemsContainer } from "../../../../common/ui/product/list/container";

export type BankGuaranteeListItemsProps = BaseCssProps;

export const BankGuaranteeListItems = ({ className }: BankGuaranteeListItemsProps) => {
  const ids = useAppSelector(selectBankGuaranteeListItemsGuaranteesIds);
  const from = useCreateNavigationStateFrom("Мои заявки");

  return (
    <ProductListItemsContainer className={className}>
      {ids.map((x) => (
        <BankGuaranteeListItem key={x} id={x as BankGuaranteeId} from={from} />
      ))}
    </ProductListItemsContainer>
  );
};
