import styled from "@emotion/styled";
import { AppLink } from "../../../../common/urls/internal/components";

export const Link = styled(AppLink)`
  text-decoration: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: filter 0.25s ease;
  max-width: 100%;

  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:hover > * {
    filter: brightness(0.95);
  }
`;
