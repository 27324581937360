import { AnySchema, InferType } from "yup";
import { BaseCssProps } from "../../../../ui-kit/types";
import { PaginatedFilteringObjectType } from "../../../types/PaginatedFilteringObjectType";
import { UseFilteringItemsConverterResult } from "../../../hooks/useFilteringItemsConverter";
import { useSetDefaultQuerySearch } from "../../../hooks/useSetDefaultQuerySearch";
import { useSearchFilteringItems } from "../../../hooks/useSearchFilteringItems";
import { FilterBadgesList } from "../../../../ui-kit/filters/badges";

export interface ProductFiltersBadgesProps<
  TSchema extends AnySchema,
  TFilter extends InferType<TSchema> & PaginatedFilteringObjectType
> extends BaseCssProps {
  filteringObject: TFilter;
  schema: TSchema;
  converter: UseFilteringItemsConverterResult<TFilter>;
}

export const ProductFiltersBadges = <
  TSchema extends AnySchema,
  TFilter extends InferType<TSchema> & PaginatedFilteringObjectType
>({
  filteringObject,
  schema,
  converter,
  className,
}: ProductFiltersBadgesProps<TSchema, TFilter>) => {
  const setDefaultQuery = useSetDefaultQuerySearch(schema);
  const filteringItems = useSearchFilteringItems(schema, filteringObject, converter);

  const onCancelClick = (onRemove: (actualFilteringObject: TFilter) => Promise<void> | void) => () => {
    return onRemove(filteringObject);
  };

  return (
    <FilterBadgesList
      className={className}
      filteringItems={filteringItems}
      onClearFiltersClick={setDefaultQuery}
      onCancelClick={onCancelClick}
    />
  );
};
