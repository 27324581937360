import * as React from "react";
import { Text } from "../../../../../../../common/ui-kit/typography";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import {
  ProductListCardContainerDesktop,
  ProductListCardDetailsClientDesktop,
} from "../../../../../../../common/ui/product/list/card";

export interface BankGuaranteeListItemDesktopCardProps extends BaseCssProps {
  clientName: React.ReactNode;
  clientTaxpayerNumber: React.ReactNode;
  guaranteeNumberAndDate: React.ReactNode;
  guaranteeType: React.ReactNode;
  children?: React.ReactNode;
  details?: React.ReactNode;
}

export const BankGuaranteeListItemDesktopCard = ({
  clientName,
  clientTaxpayerNumber,
  guaranteeNumberAndDate,
  guaranteeType,
  children,
  details,
  className,
}: BankGuaranteeListItemDesktopCardProps) => {
  return (
    <ProductListCardContainerDesktop
      className={className}
      details={
        <>
          <ProductListCardDetailsClientDesktop name={clientName} taxpayerNumber={clientTaxpayerNumber} pb="md" />
          <Text fontSize="fz3" fontWeight={700} lineHeight="defaultLineHeight" fontColor="primary">
            {guaranteeNumberAndDate}
          </Text>
          <Text fontSize="fz2" fontWeight={500} lineHeight="defaultLineHeight" fontColor="primary">
            {guaranteeType}
          </Text>
          {details}
        </>
      }
    >
      {children}
    </ProductListCardContainerDesktop>
  );
};
