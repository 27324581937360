import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { NewsCard } from "./NewsCard";

export type NewsCardSkeletonProps = BaseCssProps;

export const NewsCardSkeleton = ({ className }: NewsCardSkeletonProps) => {
  return (
    <NewsCard
      className={className}
      title={<Skeleton inline count={1.2} />}
      date={<Skeleton inline width={120} />}
      description={<Skeleton inline count={2.2} />}
      footer={
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="#">
          <Skeleton width={90} />
        </a>
      }
    />
  );
};
