import { Box, Flex } from "@sideg/ui-kit/atoms/containers";
import { Typography } from "@sideg/ui-kit/atoms/typography";
import { ComponentProps, PropsWithChildren } from "react";
import { BaseCssProps } from "@sideg/ui-kit/common/types";
import { CheckboxSize } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";

const BoxTypography = Box.withComponent(Typography);

export interface FormFieldsetProps extends PropsWithChildren<BaseCssProps & ComponentProps<typeof Flex>> {
  label?: string;
  labelSize?: CheckboxSize;
}

export const FormFieldset = ({ label, className, children, labelSize = "default", ...rest }: FormFieldsetProps) => {
  return (
    <Flex as="fieldset" direction="column" gap="m" className={className} {...rest}>
      {label && (
        <BoxTypography
          pb="s"
          as="legend"
          variant={labelSize === "default" ? "desktop.text16Medium" : "desktop.small14Medium"}
          fontColor="light.neutral.text.primary"
        >
          {label}
        </BoxTypography>
      )}
      {children}
    </Flex>
  );
};
