/**
 * @deprecated используем
 * @see currencyHelper
 */
class PriceHelper {
  static stringToNumber(price: string): number {
    const priceFormatted = price.replace(/[^\d\,\.]+/g, "").replace(/,/g, ".");

    return parseFloat(priceFormatted);
  }

  static numberToString(price: number): string {
    // TODO
    return price.toLocaleString("ru-RU");
  }

  static formatLocale(price: string): string {
    const priceFormatted = price.replace(/[^\d\,\.]+/g, "").replace(/\./g, ",");

    if (priceFormatted === "") {
      return priceFormatted;
    }

    const comma = priceFormatted.indexOf(",") > -1;
    const [rub, cop] = priceFormatted.split(",");

    const rubFormatted = rub ? parseInt(rub).toLocaleString("ru-RU") : "";
    const copFormatted = cop ? cop.substr(0, 2) : "";

    return comma ? (copFormatted ? rubFormatted + "," + copFormatted : rubFormatted + ",") : rubFormatted;
  }
}

export default PriceHelper;
