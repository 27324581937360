import * as React from "react";
import * as S from "./BankGuaranteeDetailsHeaderMobileAlert.styled";
import { StackPanel } from "../../../../../../../common/ui-kit/containers/stack-panel";
import { ClockIcon, FilePlusIcon, InfoIcon, XOctagonIcon } from "../../../../../../../common/ui-kit/icons";
import { BankGuaranteeDetailsDemandStatusType } from "../../../../../types/demand/BankGuaranteeDetailsDemandStatusType";

const getIconByVariant = (variant: BankGuaranteeDetailsDemandStatusType) => {
  switch (variant) {
    case "attachDocuments":
      return FilePlusIcon;
    case "rejected":
      return XOctagonIcon;
    case "active":
      return InfoIcon;
    default:
      return ClockIcon;
  }
};

export interface BankGuaranteeDetailsHeaderMobileAlertProps {
  className?: string;
  title?: React.ReactNode;
  variant: BankGuaranteeDetailsDemandStatusType;
  children?: React.ReactNode;
}

export const BankGuaranteeDetailsHeaderMobileAlert = ({
  className,
  variant,
  title,
  children,
}: BankGuaranteeDetailsHeaderMobileAlertProps) => {
  return (
    <S.AlertWrapper className={className} variant={variant}>
      <StackPanel gap="sm" direction="row" alignItems="center">
        <S.Icon flexGrow={0} flexShrink={0} width={16} height={16} as={getIconByVariant(variant)} />
        <S.AlertTitle variant={variant}>{title}</S.AlertTitle>
      </StackPanel>
      {children}
    </S.AlertWrapper>
  );
};
