import { clientV3 } from "../../../common/api";
import { GetDemandActualDocumentsInputDto } from "./dto/input/GetDemandActualDocumentsInputDto";
import { GetDemandActualDocumentsOutputDto } from "./dto/output/GetDemandActualDocumentsOutputDto";

export const demandActualDocumentsApi = {
  get: async (query: GetDemandActualDocumentsInputDto) => {
    return clientV3.get<GetDemandActualDocumentsInputDto, GetDemandActualDocumentsOutputDto>(
      `/demand/${query.demandId}/actual-documents`
    );
  },
};
