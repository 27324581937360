import { useEffect, useState } from "react";
import {
  useWebPushesIsSubscribed,
  useWebPushesPeriodicallyPrompt,
  useWebPushesSubscriptionApiContext,
} from "../../../common/web-pushes";

export const useQueueTasksNotificationsSubscription = () => {
  const { subscribe, unsubscribe } = useWebPushesSubscriptionApiContext();
  const [isSubscribed, setIsSubscribed] = useWebPushesIsSubscribed();
  const { needShowPrompt, dismiss } = useWebPushesPeriodicallyPrompt(true);

  const [isModalOpened, setIsModalOpened] = useState(false);

  useEffect(() => {
    if (needShowPrompt) {
      setIsModalOpened(true);
    }
  }, [needShowPrompt]);

  const onSwitchChange = async () => {
    setIsModalOpened(false);
    setIsSubscribed((prev) => !prev);

    const isSuccess = await (isSubscribed ? unsubscribe() : subscribe());
    if (!isSuccess) {
      setIsSubscribed((prev) => !prev);
    }
  };

  const onDismiss = () => {
    setIsModalOpened(false);
    dismiss();
  };

  return { onSwitchChange, onDismiss, isSubscribed, isModalOpened, setIsModalOpened };
};
