import styled from "@emotion/styled";

export const Container = styled.section<{ opened: boolean }>`
  overflow: auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.layout.sidebar.zIndex};
  background-color: #fff;

  & > *:only-child {
    min-height: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.mqUp("lg")} {
    width: 390px;
    top: ${({ theme }) => theme.layout.header.height};
    right: 0;
    left: inherit;
    box-shadow: 0 8px 48px rgba(11, 24, 57, 0.48);
  }
`;
