import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useCreditWorkingAssetsListFilterContext } from "../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { useCreditWorkingAssetsListFilteringItemsConverter } from "../../hooks/filters/useCreditWorkingAssetsListFilteringItemsConverter";
import { ProductFiltersBadges } from "../../../../common/filters/use-cases";
import { creditWorkingAssetsListFiltersValidationSchema } from "../../validation/creditWorkingAssetsListFiltersValidationSchema";

export type CreditWorkingAssetsListHeaderFiltersBadgesProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFiltersBadges = ({
  className,
}: CreditWorkingAssetsListHeaderFiltersBadgesProps) => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();
  const converter = useCreditWorkingAssetsListFilteringItemsConverter();

  return (
    <ProductFiltersBadges
      filteringObject={filteringObject}
      schema={creditWorkingAssetsListFiltersValidationSchema}
      converter={converter}
      className={className}
    />
  );
};
