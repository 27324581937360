import { useState } from "react";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import * as S from "./DocumentUploadCommonBankDocumentCardBanks.styled";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { ChevronLeftIcon } from "../../../../../common/ui-kit/icons";
import { CodeWithTitle } from "../../../../../common/types/objects";
import { DocumentUploadCommonBankDocumentCardBanksLabel } from "./DocumentUploadCommonBankDocumentCardBanksLabel";

export interface DocumentUploadCommonBankDocumentCardBanksProps extends BaseCssProps {
  banks: CodeWithTitle[];
}

export const DocumentUploadCommonBankDocumentCardBanks = ({
  banks,
  className,
}: DocumentUploadCommonBankDocumentCardBanksProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useThemeIsDesktop();

  return (
    <StackPanel direction="column" gap="none" className={className}>
      {(isDesktop || banks.length === 1) && <DocumentUploadCommonBankDocumentCardBanksLabel banks={banks} />}
      {!isDesktop && banks.length > 1 && (
        <S.Button isOpen={isOpen} type="button" onClick={() => setIsOpen((prev) => !prev)}>
          <DocumentUploadCommonBankDocumentCardBanksLabel banks={banks} />
          <ChevronLeftIcon />
        </S.Button>
      )}
      {(isOpen || isDesktop) && banks.length > 1 && (
        <S.Ul>
          {banks.map((x) => (
            <li key={x.code}>{x.title}</li>
          ))}
        </S.Ul>
      )}
    </StackPanel>
  );
};
