import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { ReactNode } from "react";
import * as S from "./BankGuaranteeReconciliationPageLayout.styled";
import { useBankGuaranteeDetailsWithRequiredDemandIdLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { Redirect } from "../../../../../common/urls/internal/components";
import { bankGuaranteeDetailsPageUrlSelectors } from "../../../configs/bankGuaranteeDetailsPageUrlSelectors";

export interface BankGuaranteeDetailsReconciliationPageLayoutProps {
  children: ReactNode;
}

export const BankGuaranteeDetailsReconciliationPageLayout = ({
  children,
}: BankGuaranteeDetailsReconciliationPageLayoutProps) => {
  const params = useBankGuaranteeDetailsWithRequiredDemandIdLayoutContext();

  return (
    <ThemeMobileDesktopView
      mobile={<S.Container>{children}</S.Container>}
      desktop={<Redirect to={bankGuaranteeDetailsPageUrlSelectors.demand} params={params} />}
    />
  );
};
