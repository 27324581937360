// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObjectEmpty = (obj: Record<any, any>) => {
  // for..in быстрее чем `Object.keys()`: https://stackoverflow.com/a/59787784/62937
  // eslint-disable-next-line guard-for-in,no-unreachable-loop
  for (const _ in obj) {
    return false;
  }

  return true;
};
