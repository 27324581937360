import { ElementType, forwardRef, useMemo } from "react";
import { MakeButtonProps } from "../../types/ButtonProps";
import { ButtonLoadingLoaderPosition } from "../../types/ButtonLoadingLoaderPosition";
import { ButtonContained } from "../contained/ButtonContained";
import { ButtonOutlined } from "../outline/ButtonOutlined";
import { CircularLoader } from "../../../../animations/loaders";
import { CommonButtonIcon } from "../common/CommonButton";
import { CommonButtonChildrenWithIcons } from "../common/CommonButtonChildrenWithIcons";
import * as S from "./ButtonLoading.styled";

export interface ButtonLoadingProps extends MakeButtonProps<"button"> {
  isLoading?: boolean;
  loaderPosition?: ButtonLoadingLoaderPosition;
  loader?: ElementType;
}

export const ButtonLoading = forwardRef<HTMLButtonElement, ButtonLoadingProps>(
  (
    {
      endIcon,
      startIcon,
      children,
      variant = "contained",
      color = "primary",
      size = "default",
      disabled,
      isLoading,
      loader,
      loaderPosition = "replace",
      ...rest
    },
    ref,
  ) => {
    const Button = useMemo(() => {
      const component = variant === "contained" ? ButtonContained : ButtonOutlined;

      return component.withComponent("button");
    }, [variant]);

    const Loader = useMemo(() => loader ?? CommonButtonIcon.withComponent(CircularLoader), [loader]);

    return (
      <Button
        color={color}
        size={size}
        {...rest}
        isDisabledAsDefaultState={isLoading === true && disabled !== true}
        disabled={disabled || isLoading}
        ref={ref}
      >
        {loaderPosition === "replace" && (
          <>
            {isLoading && (
              <S.CenteredLoader>
                <Loader />
              </S.CenteredLoader>
            )}
            <S.Content isLoading={isLoading}>
              <CommonButtonChildrenWithIcons startIcon={startIcon} endIcon={endIcon}>
                {children}
              </CommonButtonChildrenWithIcons>
            </S.Content>
          </>
        )}
        {loaderPosition !== "replace" && (
          <>
            {(loaderPosition !== "start" || !isLoading) && <CommonButtonIcon as={startIcon} />}
            {loaderPosition === "start" && isLoading && <Loader />}
            {children}
            {(loaderPosition !== "end" || !isLoading) && <CommonButtonIcon as={endIcon} />}
            {loaderPosition === "end" && isLoading && <Loader />}
          </>
        )}
      </Button>
    );
  },
);
