import { useState } from "react";
import { Box } from "../../../ui-kit/containers/box";
import { DotsLoader } from "../../../ui-kit/loaders/DotsLoader";
import { Typography } from "../../../ui-kit/typography";
import { ApplicationUpdaterAlertContainer } from "../container/ApplicationUpdaterAlertContainer";
import { ApplicationUpdaterAlertButton } from "../button/ApplicationUpdaterAlertButton";

export interface ApplicationUpdaterAlertProps {
  onUpdate: () => Promise<void>;
  onDismiss: () => Promise<void>;
}

export const ApplicationUpdaterAlert = ({ onDismiss, onUpdate }: ApplicationUpdaterAlertProps) => {
  const [isProcessLoading, setIsProcessLoading] = useState(false);

  const handleSuccess = async () => {
    setIsProcessLoading(true);
    await onUpdate();
  };

  return (
    <ApplicationUpdaterAlertContainer>
      <span>Доступна новая версия приложения</span>
      <Box display="flex" gap="sm" alignItems="center" justifyContent="center">
        {!isProcessLoading && (
          <>
            <ApplicationUpdaterAlertButton variant="primary" type="button" onClick={handleSuccess}>
              Обновить
            </ApplicationUpdaterAlertButton>
            <ApplicationUpdaterAlertButton variant="secondary" type="button" onClick={onDismiss}>
              Отложить
            </ApplicationUpdaterAlertButton>
          </>
        )}
        {isProcessLoading && (
          <Typography fontSize="fz2">
            <DotsLoader containerDataTestId="loader" />
          </Typography>
        )}
      </Box>
    </ApplicationUpdaterAlertContainer>
  );
};
