import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { useBankGuaranteeCreationEnforcementSumField } from "../../../hooks/creation/fields/useBankGuaranteeCreationEnforcementSumField";

export interface BankGuaranteeCreationEnforcementSumFieldProps {
  disabled?: boolean;
}

export const BankGuaranteeCreationEnforcementSumField = ({
  disabled,
}: BankGuaranteeCreationEnforcementSumFieldProps) => {
  const isExists = useBankGuaranteeCreationEnforcementSumField();
  const [{ name, value, onChange, onBlur, error }] = useBankGuaranteeCreationField("enforcementSum");

  return (
    <SimpleOpacityTransition timeout={300} in={isExists} unmountOnExit mountOnEnter>
      <FormInputPrice
        autoComplete="off"
        label="Сумма обеспечения на исполнение, ₽"
        size="medium"
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        disabled={disabled}
      />
    </SimpleOpacityTransition>
  );
};
