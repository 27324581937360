import styled from "@emotion/styled";

export const ApplicationUpdaterAlertContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.layout.global.appUpdater.zIndex};
  min-height: 140px;
  background-color: ${({ theme }) => theme.layout.sidebar.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.lg};
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  user-select: none;
  padding: 0 ${({ theme }) => theme.spaces.lg};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    bottom: ${({ theme }) => theme.layout.borderPadding};
    left: 50%;
    transform: translateX(-50%);
    right: unset;
    min-height: unset;
    border-radius: 4px;
    box-shadow: 0 8px 20px rgba(28, 34, 86, 0.3);
    padding: ${({ theme }) => theme.spaces.lg};
  }
`;
