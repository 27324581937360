import styled from "@emotion/styled";

export const ItemUl = styled.ul`
  list-style: disc inside;
`;

export const ItemLi = styled.li`
  & > span {
    margin-left: -6px;
  }
`;
