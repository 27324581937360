import * as React from "react";
import { Icon } from "../../../../ui-kit/icons";
import { BaseCssProps, ElementColor } from "../../../../ui-kit/types";

export interface ProductStageIconProps extends BaseCssProps {
  icon: React.ElementType;
  color: ElementColor;
}

export const ProductStageIcon = ({ icon, color, className }: ProductStageIconProps) => {
  return <Icon as={icon} height={15} stroke={color} className={className} />;
};
