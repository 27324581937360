import { createAsyncThunk } from "@reduxjs/toolkit";
import { combineSliceNames, createAppAsyncThunk } from "../../../common/store/utils";
import { CREDIT_EXECUTION_SLICE_NAME, transferCreditExecution } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { creditExecutionListApi } from "../api/creditExecutionListApi";
import { GetCreditExecutionListItemOutputDto } from "../api/dto/output/GetCreditExecutionListOutputDto";
import { GetCreditExecutionListInputDto } from "../api/dto/input/GetCreditExecutionListInputDto";
import { ApiError } from "../../../common/api/types";
import { CreditExecutionId } from "../../../common/types/demand";
import { selectCreditExecutionListTransferPayload } from "./creditExecutionList.selectors";

export const CREDIT_EXECUTION_LIST_SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_SLICE_NAME, "list");

export const {
  getFiltrationParameters: getFiltrationParametersCreditExecutionList,
  getCounters: getCountersCreditExecutionList,
  get: getCreditExecutionList,
} = createApiThunks(CREDIT_EXECUTION_LIST_SLICE_NAME, creditExecutionListApi);

export const getCreditExecutionSingleListItem = createAsyncThunk<
  GetCreditExecutionListItemOutputDto | null,
  GetCreditExecutionListInputDto,
  { rejectValue: ApiError<GetCreditExecutionListInputDto> }
>(
  combineSliceNames(CREDIT_EXECUTION_LIST_SLICE_NAME, "getSingleListItem"),
  async (model, { rejectWithValue, signal }) => {
    try {
      const response = await creditExecutionListApi.get(model, signal);

      return response.body.data.length === 1 ? response.body.data[0] : null;
    } catch (err) {
      return rejectWithValue(err as ApiError<GetCreditExecutionListInputDto>);
    }
  },
);

export const transferCreditExecutionItem = createAppAsyncThunk(
  combineSliceNames(transferCreditExecution.type, "thunk"),
  (creditExecutionId: CreditExecutionId, { dispatch, getState }) => {
    const transferPayload = selectCreditExecutionListTransferPayload(getState(), creditExecutionId);
    if (transferPayload !== undefined) {
      dispatch(transferCreditExecution(transferPayload));
    }
  },
  {},
);
