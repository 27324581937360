import { clientV3, clientV4 } from "../../../common/api";
import { GetAvailableFiltersBankGuaranteeListOutputDto } from "./dto/output/GetAvailableFiltersBankGuaranteeListOutputDto";
import { GetCountersBankGuaranteeListOutputDto } from "./dto/output/GetCountersBankGuaranteeListOutputDto";
import { GetBankGuaranteeListInputDto } from "./dto/input/GetBankGuaranteeListInputDto";
import { GetBankGuaranteeListOutputDto } from "./dto/output/GetBankGuaranteeListOutputDto";

const GUARANTEE_CONTROLLER_NAME = "Guarantee";
const GUARANTEE_V4_CONTROLLER_NAME = "Guarantees";

export const bankGuaranteeListApi = {
  get: (model?: GetBankGuaranteeListInputDto, abortSignal?: AbortSignal) =>
    clientV4.get<GetBankGuaranteeListInputDto, GetBankGuaranteeListOutputDto>(GUARANTEE_V4_CONTROLLER_NAME, model, {
      abortSignal,
    }),
  getAvailableFilters: () =>
    clientV4.get<undefined, GetAvailableFiltersBankGuaranteeListOutputDto>(
      `${GUARANTEE_V4_CONTROLLER_NAME}/filtration-parameters`
    ),
  getCounters: () =>
    clientV3.get<undefined, GetCountersBankGuaranteeListOutputDto>(`${GUARANTEE_CONTROLLER_NAME}/count-active-demands`),
};
