import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ResponsiveStyleValue, SpaceType } from "../../../../ui-kit/types";

export interface ContainerProps {
  isSelected: boolean;
  gap?: ResponsiveStyleValue<SpaceType>;
  columnGap?: ResponsiveStyleValue<SpaceType>;
  rowGap?: ResponsiveStyleValue<SpaceType>;
  minHeight?: ResponsiveStyleValue<CSSObject["minHeight"]>;
}

export const ContainerWithPadding = styled.div`
  padding: ${({ theme }) => theme.spaces.sm};
`;

export const Container = styled(ContainerWithPadding)<ContainerProps>(
  ({ theme, isSelected }) => `
  display: grid;
  background-color: ${isSelected ? theme.secondaryColors.primaryBackground : "transparent"};
  transition: background-color 0.2s ease-in;
  align-items: center;
  grid-template-columns: repeat(18, 1fr);
  border-radius: 4px;
`,
  ({ theme, gap }) => makeResponsiveStyles(theme, gap, (x) => ({ gap: x ? theme.spaces[x] : undefined })),
  ({ theme, rowGap }) => makeResponsiveStyles(theme, rowGap, (x) => ({ rowGap: x ? theme.spaces[x] : undefined })),
  ({ theme, columnGap }) =>
    makeResponsiveStyles(theme, columnGap, (x) => ({ columnGap: x ? theme.spaces[x] : undefined })),
  ({ theme, minHeight }) => makeResponsiveStyles(theme, minHeight, (x) => ({ minHeight: x ?? "3rem" })),
);

export interface ItemProps {
  gridRow?: ResponsiveStyleValue<CSSObject["gridRow"]>;
  gridColumn?: ResponsiveStyleValue<CSSObject["gridColumn"]>;
  justifySelf?: ResponsiveStyleValue<CSSObject["justifySelf"]>;
}

export const Item = styled.div<ItemProps>(
  () => ({ maxWidth: "100%" }),
  ({ theme, gridRow }) => makeResponsiveStyles(theme, gridRow, (x) => ({ gridRow: x })),
  ({ theme, gridColumn }) => makeResponsiveStyles(theme, gridColumn, (x) => ({ gridColumn: x })),
  ({ theme, justifySelf }) => makeResponsiveStyles(theme, justifySelf, (x) => ({ justifySelf: x })),
);
