import { REPORTS_AGENT_ROOT_URL } from "../../common/urls";
import { joinUrlParts, makeAppUrl } from "../../../../common/urls/internal/utils";
import { UserFeatureFlagEnum } from "../../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../../common/urls/internal/utils/makeAppUrl";

export const reportsAgentDetailsUrls = {
  root: makeAppUrl(
    joinUrlParts(REPORTS_AGENT_ROOT_URL, ":reportId"),
    "/agent-report/:reportId",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsReportAgent),
  ),
};
