import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { FormCheckbox } from "@sideg/ui-kit/atoms/checkbox";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { Typography } from "../../../../../common/ui-kit/typography";

export interface BankGuaranteeCreationIsNeedCreditBlockProps extends BaseCssProps {
  isDisabled?: boolean;
}

export const BankGuaranteeCreationIsNeedCreditBlock = ({
  isDisabled,
  className,
}: BankGuaranteeCreationIsNeedCreditBlockProps) => {
  const isDesktop = useThemeIsDesktop();
  const [fieldProps] = useBankGuaranteeCreationField("isNeedCredit");

  return (
    <CreationBlockWithTitle title="Кредит на ПОС" className={className}>
      <Box display="flex" flexDirection="column" gap="lg">
        <FormCheckbox label="Клиенту нужен кредит" {...fieldProps} isDisabled={isDisabled} />
        <Typography fontColor="dark" fontSize="fz2" lineHeight="defaultLineHeight">
          Автоматически создадим и рассмотрим заявку на кредит данному Клиенту {isDesktop && <br />}у вас в кабинете в
          разделе «Кредит на ПОС»
        </Typography>
      </Box>
    </CreationBlockWithTitle>
  );
};
