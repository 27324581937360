import { BaseFile } from "../../../../../common/types/files";
import { FileDownloadLabel } from "../../../../../files/download";
import {
  DemandQueueTaskCommentContainer,
  getFormattedCommentDateDemandQueueTasks,
} from "../../../../../demand/queue-tasks";

export interface BankGuaranteeDetailsComplicatedTasksLabelWithFileProps {
  label: string;
  file: BaseFile;
  date?: Date;
}

export const BankGuaranteeDetailsComplicatedTasksLabelWithFile = ({
  label,
  file,
  date,
}: BankGuaranteeDetailsComplicatedTasksLabelWithFileProps) => {
  return (
    <DemandQueueTaskCommentContainer title={label} date={getFormattedCommentDateDemandQueueTasks(date)} pt="sm" pb="lg">
      <div>
        <FileDownloadLabel fileId={file.fileId} privateKey={file.privateKey} fileName={file.fileName} />
      </div>
    </DemandQueueTaskCommentContainer>
  );
};
