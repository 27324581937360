import { RootState } from "../../../../common/store";
import { BankGuaranteeDetailsRequestOriginalState } from "./bankGuaranteeDetailsRequestOriginal.types";
import { bankGuaranteeDetailsRequestOriginalAdapter } from "./bankGuaranteeDetailsRequestOriginal.adapter";

export const selectBankGuaranteeDetailsRequestOriginal = (state: RootState): BankGuaranteeDetailsRequestOriginalState =>
  state.domains.bankGuarantee.details.requestOriginal;

export const selectBankGuaranteeDetailsRequestOriginalComments = (
  state: RootState
): BankGuaranteeDetailsRequestOriginalState["comments"] => selectBankGuaranteeDetailsRequestOriginal(state).comments;

export const {
  selectIds: selectBankGuaranteeDetailsRequestOriginalCommentsIds,
  selectById: selectBankGuaranteeDetailsRequestOriginalCommentById,
} = bankGuaranteeDetailsRequestOriginalAdapter.getSelectors(selectBankGuaranteeDetailsRequestOriginalComments);
