import { useEffect, useCallback } from "react";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import * as S from "./BankGuaranteeRewardsPage.styled";
import { usePageTitle } from "../../../common/navigation/page-title";
import { useAppDispatch } from "../../../common/store";
import { resetBankGuaranteeRewards } from "../store/bankGuaranteeRewards.slice";
import { getBankGuaranteeRewards } from "../store/bankGuaranteeRewards.thunks";
import { BankGuaranteeRewardsHeader } from "../components/header/BankGuaranteeRewardsHeader";
import { BankGuaranteeRewards } from "../components/BankGuaranteeRewards";
import { useStickyLikeScrollElement } from "../../../common/hooks/scroll";
import { useScrollToElement } from "../hooks/useScrollToElement";
import { BankGuaranteeRewardsFilterSelectWrapper } from "../components/filters/select/BankGuaranteeRewardsFilterSelectWrapper";
import { BankGuaranteeRewardsBanksListFilterDesktopWrapper } from "../components/filters/desktop/BankGuaranteeRewardsBanksListFilterDesktopWrapper";

export const BankGuaranteeRewardsPage = () => {
  usePageTitle("Вознаграждения");

  const isDesktop = useThemeIsDesktop();

  const dispatch = useAppDispatch();

  const { isFixed, ref, fixedElement } = useStickyLikeScrollElement<HTMLDivElement>();
  const { rewardRef, scrollToElement } = useScrollToElement();

  useEffect(() => {
    dispatch(getBankGuaranteeRewards());

    return () => {
      dispatch(resetBankGuaranteeRewards());
    };
  }, [dispatch]);

  const getFixedElemHeight = useCallback(() => {
    if (fixedElement && isFixed) {
      return fixedElement.getBoundingClientRect().height;
    }

    return 0;
  }, [fixedElement, isFixed]);

  return (
    <S.Container>
      <BankGuaranteeRewardsHeader />
      <BankGuaranteeRewardsFilterSelectWrapper isFixed={isFixed} onSelectedChanged={scrollToElement} ref={ref} />
      {isDesktop && (
        <BankGuaranteeRewardsBanksListFilterDesktopWrapper
          bankListPaddingTop={getFixedElemHeight()}
          onClick={scrollToElement}
        />
      )}
      <BankGuaranteeRewards ref={rewardRef} paddingTop={`${getFixedElemHeight()}px`} />
    </S.Container>
  );
};
