import styled from "@emotion/styled";

export const RowTitle = styled.div`
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 1.75rem;
  color: ${({ theme }) => theme.palette.typography.primary};
  background-color: ${({ theme }) => theme.palette.typography.primaryLight};
  border-right: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  outline: 1px solid ${({ theme }) => theme.palette.typography.primaryLight};
  font-weight: 700;
  position: sticky;
  left: 0;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    border-right: none;
    line-height: ${({ theme }) => theme.font.defaultLineHeight};
    outline: none;
  }
`;

export const TitleSeparator = styled.span`
  letter-spacing: -1px;
`;
