import { Fragment, ReactNode, useCallback, useRef } from "react";
import {
  UseInfiniteScrollMutationArguments,
  useInfiniteScrollMutation,
  InfiniteScrollMutationComparerResult as ComparerResult,
} from "../../../../common/hooks/infinite-scroll";
import { RootState, useAppSelector } from "../../../../common/store";
import { DemandId, QueueTaskId } from "../../../../common/types/demand";
import { BasePaginatedState } from "../../../../common/types/state";
import { DemandQueueTaskCompletedCardSkeleton } from "../completed-card/DemandQueueTaskCompletedCard.skeleton";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { AppInfinityScroll, AppInfinityScrollTitles } from "../../../../common/ui/product/list/infinite-scroll";
import { getHistoryDemandQueueTasks } from "../../store/demandQueueTasks.thunks";
import { GetHistoryDemandQueueTaskInputDto } from "../../api/input/GetHistoryDemandQueueTaskInputDto";

const useHook = useInfiniteScrollMutation<GetHistoryDemandQueueTaskInputDto>;

type HookArgs = UseInfiniteScrollMutationArguments<GetHistoryDemandQueueTaskInputDto>;

const titles: AppInfinityScrollTitles = {
  end: "Загружены все сообщения по заявке",
  hasNoLoadedItems: "",
  error: "При получении сообщений возникла ошибка",
  nextPageLoading: "Загружаем предыдущие сообщения",
};

export interface DemandQueueTaskInfinityScrollProps extends BaseCssProps {
  demandId: DemandId;
  paginatedStateSelector: (state: RootState) => BasePaginatedState;
  idsSelector: (state: RootState) => QueueTaskId[];
  children: (queueTaskId: QueueTaskId) => ReactNode;
}

export const DemandQueueTaskInfinityScroll = ({
  demandId,
  paginatedStateSelector,
  idsSelector,
  children,
  className,
}: DemandQueueTaskInfinityScrollProps) => {
  const ids = useAppSelector(idsSelector);
  const demandIdRef = useRef(demandId);
  demandIdRef.current = demandId;

  const mapper: HookArgs["mapper"] = useCallback(
    (pageNumber: number) => ({
      demandId: demandIdRef.current,
      pageNumber,
    }),
    [],
  );

  const comparer: HookArgs["comparer"] = useCallback((prevArg, page) => {
    const { demandId: prevDemandId, pageNumber: prevPage } = prevArg;

    if (prevDemandId !== demandIdRef.current) {
      return ComparerResult.Filter;
    }
    if (page !== prevPage) {
      return ComparerResult.Page;
    }

    return ComparerResult.None;
  }, []);

  const { onNextPageFetching } = useHook({
    stateSelector: paginatedStateSelector,
    action: getHistoryDemandQueueTasks,
    mapper,
    comparer,
  });

  return (
    <AppInfinityScroll
      page={1}
      paginatedStateSelector={paginatedStateSelector}
      idsSelector={idsSelector}
      titles={titles}
      onPageChanged={onNextPageFetching}
      onErrorReloadButtonClick={onNextPageFetching}
      emptyLoader={
        <>
          <DemandQueueTaskCompletedCardSkeleton />
          <DemandQueueTaskCompletedCardSkeleton />
          <DemandQueueTaskCompletedCardSkeleton />
        </>
      }
    >
      <StackPanel className={className} gap="xl" direction="column">
        {ids.map((id) => (
          <Fragment key={id}>{children(id)}</Fragment>
        ))}
      </StackPanel>
    </AppInfinityScroll>
  );
};
