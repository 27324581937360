import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { BANK_GUARANTEE_LIST_SLICE_NAME, getAvailableFiltersBankGuaranteeList } from "../bankGuaranteeList.thunks";
import { BankGuaranteeListFiltersState } from "./bankGuaranteeListFilters.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import { bankGuaranteeListFiltersMapper } from "./bankGuaranteeListFilters.mapper";

const SLICE_NAME = combineSliceNames(BANK_GUARANTEE_LIST_SLICE_NAME, "filters");

const initialState: BankGuaranteeListFiltersState = {
  status: LoadingStatusEnum.Idle,
  data: undefined,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableFiltersBankGuaranteeList.fulfilled, (state, action) => {
        state.data = bankGuaranteeListFiltersMapper.mapFilters(action.payload.body);
      })
      .addMatcher(isAsyncThunkAction(getAvailableFiltersBankGuaranteeList), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const bankGuaranteeListFiltersReducer = slice.reducer;
