/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseApiError, createApiError, createApiErrorFromRawWebApiResponse } from "../types";
import { RawWebApiResponse } from "../types/RawWebApiResponse";
import { apiConfig } from "../configs";
import { loggingService } from "../../logging/services/loggingService";

export const apiRejectTransformer = (error: any, isAborted?: boolean): BaseApiError => {
  if (isAborted) {
    return { isApiError: true, isAborted: true };
  }

  if ((error as BaseApiError).isApiError !== undefined) {
    return error;
  }

  const response = error.response?.data as RawWebApiResponse<unknown>;
  if (response?.isError === true) {
    return createApiErrorFromRawWebApiResponse(response, error.response.status, error.message);
  }

  if (error.response?.status) {
    return {
      isApiError: true,
      status: error.response.status,
      rawText: error.message,
    } as BaseApiError;
  }

  loggingService.logError(error);

  return createApiError(apiConfig.errorMessages.general);
};
