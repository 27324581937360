import { useEffect, useState } from "react";
import { GlobalNetworkStateType } from "../types/GlobalNetworkStateType";

export const useGlobalNetworkState = () => {
  const [state, setState] = useState<GlobalNetworkStateType>("online");

  useEffect(() => {
    const onlineChange = () => setState("online");
    const offlineChange = () => setState("offline");

    window.addEventListener("online", onlineChange);
    window.addEventListener("offline", offlineChange);

    return () => {
      window.removeEventListener("online", onlineChange);
      window.removeEventListener("offline", offlineChange);
    };
  }, []);

  return state;
};
