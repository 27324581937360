import { InitializePublicUserResetPasswordInputDto } from "./dto/input/InitializePublicUserResetPasswordInputDto";
import { publicClientV4 } from "../../../common/api";
import { PublicUserResetPasswordCommonOutputDto } from "./dto/output/PublicUserResetPasswordCommonOutputDto";
import { ApprovePublicUserResetPasswordInputDto } from "./dto/input/ApprovePublicUserResetPasswordInputDto";
import { ApiObject } from "../../../common/api/types";

export const publicUserResetPasswordApi = {
  initialize: (model: InitializePublicUserResetPasswordInputDto) =>
    publicClientV4.put<InitializePublicUserResetPasswordInputDto, PublicUserResetPasswordCommonOutputDto>(
      "users/reset-password",
      model,
    ),
  approve: ({ token }: ApprovePublicUserResetPasswordInputDto) =>
    publicClientV4.put<undefined, PublicUserResetPasswordCommonOutputDto>(`users/reset-password/${token}`, undefined),
} satisfies ApiObject;
