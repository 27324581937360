import {
  ProductListItemColumnTitle,
  ProductListItemEllipsisSpan,
} from "../../../../common/ui/product/list/product-list-item";
import { DemandId, GroupProductName, ProductId } from "../../../../common/types/demand";
import { ProductListItemDemandDateBank } from "../../../../common/ui/product/list/product-list-item/ProductListItemDemandDateBank";
import { QueueTasksListItemProductBase } from "../../../../common/types/queue-tasks";

interface QueueTasksNotificationProductProps extends Omit<QueueTasksListItemProductBase, "sum"> {
  id: DemandId | ProductId | GroupProductName;
  dateAdded: string;
  title: string;
}

export const QueueTasksNotificationProduct = ({
  id,
  bankName,
  dateAdded,
  clientName,
  title,
}: QueueTasksNotificationProductProps) => {
  return (
    <>
      <ProductListItemColumnTitle>{title}</ProductListItemColumnTitle>
      <ProductListItemEllipsisSpan title={clientName}>{clientName}</ProductListItemEllipsisSpan>
      <ProductListItemDemandDateBank id={id} dateTime={dateAdded} bankName={bankName} />
    </>
  );
};
