import * as React from "react";
import { getEmptyArray } from "@sideg/helpers";
import { CodeWithTitle } from "../../../../types/objects";
import { BaseCssProps } from "../../../../ui-kit/types";
import { ProductCreationScoringResultCard } from "./ProductCreationScoringResultCard";

export interface ProductCreationScoringLoadingResultCardProps<TItem extends { bank: CodeWithTitle }>
  extends BaseCssProps {
  title: string;
  isLoading: boolean;
  items?: TItem[];
  skeletonElementsCount: number;
  children: (item: TItem) => React.ReactNode;
  footer?: React.ReactNode;
  skeleton: React.ElementType;
  description?: React.ReactNode;
}

export const ProductCreationScoringLoadingResultCard = <TItem extends { bank: CodeWithTitle }>({
  title,
  isLoading,
  items,
  skeletonElementsCount,
  children,
  className,
  footer,
  skeleton: Skeleton,
  description,
}: ProductCreationScoringLoadingResultCardProps<TItem>) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(isLoading || (items && items.length > 0)) && (
        <ProductCreationScoringResultCard description={description} title={title} className={className}>
          {isLoading && getEmptyArray(skeletonElementsCount).map((x) => <Skeleton key={x} />)}
          {items && items.map((x) => <React.Fragment key={x.bank.code}>{children(x)}</React.Fragment>)}
          {footer}
        </ProductCreationScoringResultCard>
      )}
    </>
  );
};
