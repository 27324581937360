import * as React from "react";
import ReactDOM from "react-dom";

export interface PortalContainerProps {
  children: React.ReactNode;
  htmlElementId: string;
}

export const PortalContainer = ({ children, htmlElementId }: PortalContainerProps) => {
  const root = document.getElementById(htmlElementId);
  if (!root) {
    throw Error(`Не найден узел #${htmlElementId} для монтирования контейнера`);
  }

  return ReactDOM.createPortal(children, root);
};
