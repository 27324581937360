import { useRef } from "react";
import { Formik } from "formik";
import { getBankGuaranteeCreationFromValidationSchema } from "../../validation/bankGuaranteeCreationValidationSchema";
import { BankGuaranteeCreationFromValues } from "../../types/BankGuaranteeCreationFormValues";
import { useBankGuaranteeCreationFormSubmit } from "../../hooks/creation/useBankGuaranteeCreationFormSubmit";
import { BankGuaranteeCreationFormContent } from "./BankGuaranteeCreationFormContent";
import { FormBox, FormScrollToFirstErrorAfterSubmit } from "../../../../common/form";

export interface BankGuaranteeCreationFormProps {
  initialValues: BankGuaranteeCreationFromValues;
}

export const BankGuaranteeCreationForm = ({ initialValues }: BankGuaranteeCreationFormProps) => {
  const ref = useRef<HTMLFormElement | null>(null);
  const onSubmit = useBankGuaranteeCreationFormSubmit(initialValues);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={getBankGuaranteeCreationFromValidationSchema()}
    >
      {() => (
        <FormBox display="flex" flexDirection="column" flexShrink={1} flexGrow={1} ref={ref}>
          <FormScrollToFirstErrorAfterSubmit containerRef={ref} />
          <BankGuaranteeCreationFormContent />
        </FormBox>
      )}
    </Formik>
  );
};
