import { string } from "yup";

const MAX_ADDITIONAL_NUMBER_DIGITS_COUNT = 6;

export const getAdditionalPhoneNumberSchema = (lengthMessage = "") => {
  return string()
    .transform((_, initialValue) => {
      return initialValue.replace(/\+|\D/g, "");
    })
    .test("length", lengthMessage, (value) => {
      return value === undefined || value.length <= MAX_ADDITIONAL_NUMBER_DIGITS_COUNT;
    });
};
