import { BankGuaranteeId, DemandId } from "../../../common/types/demand";
import { ButtonLink } from "../../../common/ui-kit/buttons/ButtonLink";
import { Text } from "../../../common/ui-kit/typography";
import { BankGuaranteeFileRegistryStatusType } from "../../common/types";
import { useRequestBankGuaranteeExtractFromRegistry } from "../hooks/useRequestBankGuaranteeExtractFromRegistry";
import { BankGuaranteeExtractFromRegistryFileLabel } from "./BankGuaranteeExtractFromRegistryFileLabel";
import { BankGuaranteeExtractFromRegistryRequestedExtract } from "./BankGuaranteeExtractFromRegistryRequestedExtract";
import { canDownloadBankGuaranteeExtractFromRegistry } from "../utils/canDownloadBankGuaranteeExtractFromRegistry";

const ExtractButtonLink = Text.withComponent(ButtonLink);

interface BankGuaranteeExtractFromRegistryWrapperProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  statusType?: BankGuaranteeFileRegistryStatusType;
}

export const BankGuaranteeExtractFromRegistryWrapper = ({
  guaranteeId,
  demandId,
  statusType,
}: BankGuaranteeExtractFromRegistryWrapperProps) => {
  const requestExtractFromRegistry = useRequestBankGuaranteeExtractFromRegistry(guaranteeId, demandId);

  return (
    <>
      {canDownloadBankGuaranteeExtractFromRegistry(statusType) && (
        <BankGuaranteeExtractFromRegistryFileLabel demandId={demandId} statusType={statusType} />
      )}
      {statusType === "Requested" && <BankGuaranteeExtractFromRegistryRequestedExtract />}
      {statusType === "AvailableForRequest" && (
        <ExtractButtonLink fontWeight={400} fontSize="fz2" onClick={requestExtractFromRegistry}>
          Запросить выписку из реестра БГ
        </ExtractButtonLink>
      )}
    </>
  );
};
