import RequestData from '../utils/RequestData'
import BalancesModel from '../../entity/models/BalancesModel'
import RequestStatus from '../../entity/models/enums/RequestStatus'
import BalanceFromProviderModel from '../../entity/models/BalanceFromProviderModel'
import produce from 'immer'
import {
    BALANCE_FETCHING,
    BALANCE_FETCHED,
    BALANCE_ERROR,
    BALANCE_RESET,

    BALANCE_FROM_PROVIDER_FETCHING,
    BALANCE_FROM_PROVIDER_FETCHED,
    BALANCE_FROM_PROVIDER_ERROR,
    BALANCE_FROM_PROVIDER_RESET,

    BALANCE_SAVE_PROCESSING,
    BALANCE_SAVE_SUCCESS,
    BALANCE_SAVE_ERROR,
    BALANCE_SAVE_RESET,

    BalanceDispatchType
} from '../actions/balance/balanceActionTypes'

interface BalanceState {
    balance: RequestData<BalancesModel>
    fromProvider: RequestData<BalanceFromProviderModel>
    save: RequestData<boolean>
}

const initialState: BalanceState = {
    balance: {
        data: null,
        status: RequestStatus.Initial,
        error: null
    },
    fromProvider: {
        data: null,
        status: RequestStatus.Initial,
        error: null
    },
    save: {
        data: null,
        status: RequestStatus.Initial,
        error: null
    }
}

const balanceReducer = (state: BalanceState = initialState, action: BalanceDispatchType) => {
    switch (action.type) {
        case BALANCE_FETCHING:
            return produce(state, (draft: BalanceState) => {
                draft.balance.status = RequestStatus.Request
                draft.balance.error = null
            })
        case BALANCE_FETCHED:
            return produce(state, (draft: BalanceState) => {
                draft.balance.status = RequestStatus.Success
                draft.balance.data = action.payload
            })
        case BALANCE_ERROR:
            return produce(state, (draft: BalanceState) => {
                draft.balance.status = RequestStatus.Failure
                draft.balance.error = action.error
            })
        case BALANCE_RESET:
            return produce(state, (draft: BalanceState) => {
                draft.balance = initialState.balance
            })
        case BALANCE_FROM_PROVIDER_FETCHING:
            return produce(state, (draft: BalanceState) => {
                draft.fromProvider.status = RequestStatus.Request
                draft.fromProvider.error = null
            })
        case BALANCE_FROM_PROVIDER_FETCHED:
            return produce(state, (draft: BalanceState) => {
                draft.fromProvider.status = RequestStatus.Success
                draft.fromProvider.data = action.payload
            })
        case BALANCE_FROM_PROVIDER_ERROR:
            return produce(state, (draft: BalanceState) => {
                draft.fromProvider.status = RequestStatus.Failure
                draft.fromProvider.error = action.error
            })
        case BALANCE_FROM_PROVIDER_RESET:
            return produce(state, (draft: BalanceState) => {
                draft.fromProvider = initialState.fromProvider
            })
        case BALANCE_SAVE_PROCESSING:
            return produce(state, (draft: BalanceState) => {
                draft.save.status = RequestStatus.Request
                draft.save.error = null
            })
        case BALANCE_SAVE_SUCCESS:
            return produce(state, (draft: BalanceState) => {
                draft.save.status = RequestStatus.Success
                draft.save.data = action.payload
            })
        case BALANCE_SAVE_ERROR:
            return produce(state, (draft: BalanceState) => {
                draft.save.status = RequestStatus.Failure
                draft.save.error = action.error
            })
        case BALANCE_SAVE_RESET:
            return produce(state, (draft: BalanceState) => {
                draft.save = initialState.save
            })
        default:
            return state
    }
}

export default balanceReducer
