import { breakpoints, Breakpoints } from "@sideg/ui-kit/common/types/responsive/Breakpoints";

export type ResponsiveValue<TValue> = { [P in Breakpoints]?: TValue };
export type ResponsiveStyleValue<TValue> = TValue | ResponsiveValue<TValue>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyResponsiveStyleValue = ResponsiveStyleValue<any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyResponsiveValue = ResponsiveValue<any>;

export type ExtractResponsiveStyleValue<TResponsive extends AnyResponsiveStyleValue> =
  TResponsive extends ResponsiveValue<infer TValue> ? TValue : TResponsive;

export type MapToResponsiveStyleValue<TObject> = {
  [Key in keyof TObject]: TObject[Key] extends AnyResponsiveValue ? TObject[Key] : ResponsiveStyleValue<TObject[Key]>;
};

export const isResponsiveValue = <TValue>(value: ResponsiveStyleValue<TValue>): value is ResponsiveValue<TValue> => {
  if (typeof value === "object") {
    return breakpoints.some((x) => x in (value ?? {}));
  }

  return false;
};
