/* eslint-disable react/no-children-prop */
import { PropsWithChildren } from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Typography } from "../../../../common/ui-kit/typography";

export interface DemandQueueTaskCommentTextBodyProps extends PropsWithChildren<BaseCssProps> {}

export const DemandQueueTaskCommentTextBody = ({ children, className }: DemandQueueTaskCommentTextBodyProps) => {
  return (
    <Typography
      as="p"
      withLineBreaks
      fontWeight={500}
      fontSize="fz3"
      lineHeight="defaultLineHeight"
      fontColor="typography.primary"
      children={children}
      className={className}
    />
  );
};
