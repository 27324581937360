import { dateTimeHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { NewsId } from "../../common/types";
import { useAppSelector } from "../../../common/store";
import { selectNewsListItemById } from "../store/newsList.selectors";
import { NewsCard } from "./card/NewsCard";
import { StyledAppLink } from "../../../common/ui/links";
import { AppLink } from "../../../common/urls/internal/components";
import { NavigationState } from "../../../common/navigation/navigation-state";

export interface NewsListItemProps extends BaseCssProps {
  id: NewsId;
  from: NavigationState;
}

export const NewsListItem = ({ id, className, from }: NewsListItemProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const newsItem = useAppSelector((state) => selectNewsListItemById(state, id))!;

  return (
    <NewsCard
      date={dateTimeHelper.getDistanceToNow(new Date(newsItem.creationDate))}
      title={
        <AppLink to={(x) => x.news.details.item} params={{ newsId: id }} state={from}>
          {newsItem.title}
        </AppLink>
      }
      description={newsItem.description}
      className={className}
      footer={
        <StyledAppLink to={(x) => x.news.details.item} params={{ newsId: id }} state={from}>
          Читать далее ⟶
        </StyledAppLink>
      }
    />
  );
};
