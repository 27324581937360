import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import {
  bankGuaranteeDetailsDemandReconciliationStateType,
  BankGuaranteeDetailsDemandReconciliationStateType,
} from "../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationStateType";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { Typography } from "../../../../../../common/ui-kit/typography";
import { BankGuaranteeDetailsDemandReconciliationContentStateIcon } from "./BankGuaranteeDetailsDemandReconciliationContentStateIcon";

export interface BankGuaranteeDetailsDemandReconciliationContentTitleWithStateProps extends BaseCssProps {
  title: string;
  state: BankGuaranteeDetailsDemandReconciliationStateType;
}

export const BankGuaranteeDetailsDemandReconciliationContentTitleWithState = ({
  title,
  state,
  className,
}: BankGuaranteeDetailsDemandReconciliationContentTitleWithStateProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", [desktopBreakpoint]: "row" }}
      justifyContent={{ xs: "flex-start", [desktopBreakpoint]: "space-between" }}
      gap={{ xs: "sm", [desktopBreakpoint]: undefined }}
      className={className}
    >
      <Typography
        fontColor="typography.primary"
        fontSize={{ xs: 1.375, [desktopBreakpoint]: "fz3" }}
        fontWeight={700}
        lineHeight="defaultLineHeight"
        whiteSpace="nowrap"
      >
        {title}
      </Typography>
      <Box display="flex" flexDirection="row" gap="xs" alignItems="center">
        <BankGuaranteeDetailsDemandReconciliationContentStateIcon state={state} />
        <Typography
          fontColor="typography.secondary"
          fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}
          fontWeight={500}
          lineHeight="defaultLineHeight"
          whiteSpace="nowrap"
        >
          {bankGuaranteeDetailsDemandReconciliationStateType.getTitle(state)}
        </Typography>
      </Box>
    </Box>
  );
};
