import * as React from "react";
import * as S from "./CancelButton.styled";
import { CancelIcon } from "../../../../ui-kit/icons";

export type CancelButtonProps = Omit<React.ComponentProps<typeof S.Button>, "children">;

export const CancelButton = (props: CancelButtonProps) => {
  return (
    <S.Button {...props}>
      <CancelIcon />
    </S.Button>
  );
};
