import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { loggingSentryConfigs } from "../configs";
import { RootState } from "../../../../store";

const beforeSend: Sentry.BrowserOptions["beforeSend"] = (event, hint) => {
  const error = hint.originalException;
  if (error instanceof AxiosError && error.code === AxiosError.ERR_NETWORK) {
    return null;
  }

  return event;
};

const init = () => {
  const { isEnabled, release, dsn, environment } = loggingSentryConfigs;
  if (isEnabled) {
    Sentry.init({
      release,
      dsn,
      environment,
      normalizeDepth: 10,
      beforeSend,
    });
  }
};

const getReduxEnhancer = () =>
  Sentry.createReduxEnhancer({
    configureScopeWithState(scope: Sentry.Scope, state: RootState) {
      scope.setUser({
        username: state.domains.user.authorizedUser.user?.login,
      });
    },
    stateTransformer: (state: RootState | undefined) => {
      if (state?.domains) {
        return {
          ...state,
          domains: {
            ...state.domains,
            chat: "REMOVED",
          },
        };
      }

      return state;
    },
  });

export const loggingSentryInitService = {
  init,
  getReduxEnhancer,
};
