import { FiltersWrapperWithSearchInput } from "../../../../../../common/filters/use-cases/components/desktop/FiltersWrapperWithSearchInput.desktop";
import { useCreditWorkingAssetsListFilterContext } from "../../../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { useAppSelector } from "../../../../../../common/store";
import { selectCreditWorkingAssetsFilters } from "../../../../store/filters/creditWorkingAssetsListFilters.selectors";
import { useProductFilterSearchInput } from "../../../../../../common/filters/use-cases";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { CreditWorkingAssetsListHeaderFiltersBanksDesktop } from "./CreditWorkingAssetsListHeaderFiltersBanks.desktop";
import { isFilterAvailableForTabCreditWorkingAssets } from "../../../../validation/rules/isFilterAvailableForTabCreditWorkingAssets";
import { CreditWorkingAssetsListFilterContextValue } from "../../../../types/filters/CreditWorkingAssetsListFilterContextValue";
import { CreditWorkingAssetsListHeaderFiltersStatusDesktop } from "./CreditWorkingAssetsListHeaderFiltersStatus.desktop";
import { CreditWorkingAssetsListHeaderFiltersTaskTypesDesktop } from "./CreditWorkingAssetsListHeaderFiltersTaskTypes.desktop";
import { CreditWorkingAssetsListHeaderFiltersDatesDesktop } from "./CreditWorkingAssetsListHeaderFiltersDates.desktop";

export type CreditWorkingAssetsListHeaderFiltersDesktopProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFiltersDesktop = ({
  className,
}: CreditWorkingAssetsListHeaderFiltersDesktopProps) => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();
  const { data } = useAppSelector(selectCreditWorkingAssetsFilters);

  const { onChange, value } = useProductFilterSearchInput(filteringObject);
  const isForTab = (filter: keyof CreditWorkingAssetsListFilterContextValue) =>
    isFilterAvailableForTabCreditWorkingAssets(filteringObject.tab, filter);

  return (
    <FiltersWrapperWithSearchInput value={value} onChange={onChange} className={className}>
      {data && (
        <>
          {isForTab("bankIds") && <CreditWorkingAssetsListHeaderFiltersBanksDesktop />}
          {isForTab("status") && <CreditWorkingAssetsListHeaderFiltersStatusDesktop />}
          {isForTab("taskTypes") && <CreditWorkingAssetsListHeaderFiltersTaskTypesDesktop />}
          {isForTab("dateCreateFrom") && isForTab("dateCreateTo") && (
            <CreditWorkingAssetsListHeaderFiltersDatesDesktop />
          )}
        </>
      )}
    </FiltersWrapperWithSearchInput>
  );
};
