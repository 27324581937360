import { Modal } from "../../../../../../../../common/ui/containers/modal";
import { BankGuaranteeDetailsDemandReconciliationConfirmPaymentForm } from "../../form/BankGuaranteeDetailsDemandReconciliationConfirmPaymentForm";
import { Typography } from "../../../../../../../../common/ui-kit/typography";
import { Box } from "../../../../../../../../common/ui-kit/containers/box";
import { UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler } from "../../../../../../types/demand/reconciliation/UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler";
import { LoadingStatusEnum } from "../../../../../../../../common/types/state";

interface BankGuaranteeDetailsDemandReconciliationConfirmPaymentDesktopProps {
  isModalOpen: boolean;
  onClose: () => void;
  handleSubmit: UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler;
  status: LoadingStatusEnum;
}

export const BankGuaranteeDetailsDemandReconciliationConfirmPaymentDesktop = ({
  isModalOpen,
  onClose,
  handleSubmit,
  status,
}: BankGuaranteeDetailsDemandReconciliationConfirmPaymentDesktopProps) => {
  return (
    <Modal isShown={isModalOpen} onClose={onClose} title="Подтвердить оплату">
      <Box display="flex" flexDirection="column" gap="xxl" flexGrow={1}>
        <Typography lineHeight="defaultLineHeight" fontSize="fz2" fontWeight={500}>
          Если вы можете подтвердить оплату счета клиентом, отправьте нам платежное поручение
        </Typography>
        <BankGuaranteeDetailsDemandReconciliationConfirmPaymentForm
          status={status}
          onClose={onClose}
          handleSubmit={handleSubmit}
        />
      </Box>
    </Modal>
  );
};
