import { BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardMobile } from "../card/BankGuaranteeDetailsDemandHeaderReconciliationNavigationCard.mobile";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { useBankGuaranteeDetailsLayoutContext } from "../../../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { useAppSelector } from "../../../../../../../common/store";
import { selectBankGuaranteeDetailsActiveTasksProjectCount } from "../../../../../store/active-tasks/bankGuaranteeDetailsActiveTasks.selectors";
import { BankGuaranteeDetailsDemandActualProject } from "../../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandActualProject";

export interface BankGuaranteeDetailsDemandHeaderReconciliationBillNavigationCardProps extends BaseCssProps {
  model: BankGuaranteeDetailsDemandActualProject | undefined;
}

export const BankGuaranteeDetailsDemandHeaderReconciliationProjectNavigationCard = ({
  model,
  className,
}: BankGuaranteeDetailsDemandHeaderReconciliationBillNavigationCardProps) => {
  const params = useBankGuaranteeDetailsLayoutContext();
  const counterValue = useAppSelector(selectBankGuaranteeDetailsActiveTasksProjectCount);

  return (
    model && (
      <BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardMobile
        state={model.state.code}
        to={(urls) => urls.bankGuarantee.details.project}
        params={params}
        title="Согласование проекта БГ"
        counter={counterValue}
        className={className}
      />
    )
  );
};
