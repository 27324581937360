import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDetailsActiveDemand } from "../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { isIdle } from "../../../../common/types/state";
import { getDemandActualDocuments } from "../../../../demand/actual-documents";

export const useBankGuaranteeDetailsDemandDocumentsFetch = () => {
  const dispatch = useAppDispatch();
  const {
    activeDemandId,
    documents: { status },
  } = useAppSelector(selectBankGuaranteeDetailsActiveDemand);

  useEffect(() => {
    if (activeDemandId !== undefined && isIdle(status)) {
      dispatch(getDemandActualDocuments({ demandId: activeDemandId }));
    }
  }, [activeDemandId, dispatch, status]);
};
