import { useMemo } from "react";
import { useSearchParamsOnce } from "../../../../common/filters";
import { useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsCreation } from "../../store/creditWorkingAssetsCreation.selectors";
import { CreditWorkingAssetsCreationFormValues } from "../../types/creation/CreditWorkingAssetsCreationFormValues";
import {
  creditWorkingAssetsCreationFromValidationSchema,
  getCreditWorkingAssetsCreationFromValidationSchema,
} from "../../validation/creditWorkingAssetsCreationValidationSchema";

export const useCreditWorkingAssetsCreationFromInitialValues = (): CreditWorkingAssetsCreationFormValues => {
  const queryParams = useSearchParamsOnce(creditWorkingAssetsCreationFromValidationSchema);

  const {
    creating: { data: formValues },
  } = useAppSelector(selectCreditWorkingAssetsCreation);

  return useMemo(() => {
    return getCreditWorkingAssetsCreationFromValidationSchema().cast(formValues ?? queryParams, {
      assert: false,
    }) as CreditWorkingAssetsCreationFormValues;
  }, [formValues, queryParams]);
};
