import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { QueueTaskId } from "../../../../../common/types/demand";
import { useAppSelector } from "../../../../../common/store";
import { selectCreditExecutionDetailsActiveTaskById } from "../../../store/active-tasks/creditExecutionDetailsActiveTasks.selectors";
import { DemandQueueTasksActiveCard } from "../../../../../demand/queue-tasks";

export interface CreditExecutionDetailsActiveTaskProps extends BaseCssProps {
  queueTaskId: QueueTaskId;
}

export const CreditExecutionDetailsActiveTask = ({ queueTaskId, className }: CreditExecutionDetailsActiveTaskProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const queueTask = useAppSelector((state) => selectCreditExecutionDetailsActiveTaskById(state, queueTaskId))!;

  return <DemandQueueTasksActiveCard task={queueTask} className={className} />;
};
