import { Navigate, Outlet } from "react-router-dom";
import { AppLayoutRoute } from "../types/AppRoute";
import { useAppRouteAccess } from "../hooks/useAppRouteAccess";

export interface AppLayoutElementProps {
  route: AppLayoutRoute;
}

export const AppLayoutElement = ({ route }: AppLayoutElementProps) => {
  const { redirectTo, state, isAllowed } = useAppRouteAccess(route.type, true);

  return isAllowed ? (
    <route.component>
      <Outlet />
    </route.component>
  ) : (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <Navigate to={redirectTo!} state={state} />
  );
};
