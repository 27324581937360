import { BankGuaranteeDetailsDemand } from "../../types/demand/BankGuaranteeDetailsDemand";

export const isDemandApproved = (
  demand: BankGuaranteeDetailsDemand | undefined,
): demand is BankGuaranteeDetailsDemand => {
  if (!demand) {
    return false;
  }

  return demand?.status.code === "Approved";
};
