import { AnyOutputApiAsyncThunk } from "../../store/utils";
import { useFilteredPaginatedListPageWithMapper } from "./useFilteredPaginatedListPageWithMapper";

export interface UseFilteredPaginatedListPageArgs<TFilter extends { page: number }> {
  filteringObject: TFilter;
  action: AnyOutputApiAsyncThunk<TFilter | undefined>;
}

const EMPTY_MAPPER = <TFilter extends { page: number }>(filter: TFilter) => filter;

const MAPPER_ALWAYS_NOT_EQUAL = <TFilter extends { page: number }, TInput>(_filter: TFilter, _prevArg: TInput) => false;

export const useFilteredPaginatedListPage = <TFilter extends { page: number }>({
  filteringObject,
  action,
}: UseFilteredPaginatedListPageArgs<TFilter>) => {
  return useFilteredPaginatedListPageWithMapper<TFilter, TFilter | undefined>({
    filteringObject,
    action,
    comparer: MAPPER_ALWAYS_NOT_EQUAL,
    mapper: EMPTY_MAPPER,
  });
};
