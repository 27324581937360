import { useCallback, useEffect, useRef, useState } from "react";
import { AppLayoutOverlayPanelKey } from "../../app-layout/types";
import { useAppLayout } from "./useAppLayout";

export const useAppLayoutModalOrOverlayPanelState = (panelKey: AppLayoutOverlayPanelKey) => {
  const { activeOverlayPanel } = useAppLayout();
  const [isOpen, setIsOpen] = useState(activeOverlayPanel.value === panelKey);

  const activeOverlayPanelRef = useRef(activeOverlayPanel);
  activeOverlayPanelRef.current = activeOverlayPanel;

  useEffect(() => {
    setIsOpen(activeOverlayPanel.value === panelKey);
  }, [activeOverlayPanel.value, panelKey]);

  const changePanelState = useCallback(
    (value: boolean) => {
      if (value) {
        activeOverlayPanelRef.current.setValue(panelKey);
      } else if (activeOverlayPanelRef.current.value === panelKey) {
        activeOverlayPanelRef.current.setValue(undefined);
      }

      setIsOpen(value);
    },
    [panelKey],
  );

  return [isOpen, changePanelState] as const;
};
