/**
 * Форматирует преданную строку в телефон в формате +7 999 01-02-03
 * @param phone Номер телефона (должен содержать 11 символов)
 */
const formatPhoneNumber = (phone: string) => {
  if (phone.length === 11) {
    const match = phone.match(/^7(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+7 ${match[1]} ${match[2]}-${match[3]}-${match[4]}`;
    }
  }

  return phone;
};

/**
 * Возвращает только цифры, входящие в строку
 * @param string Исходная строка
 */
const getDigitsFromString = (string: string) => {
  return string.replace(/\D/g, "");
};

/**
 * Возвращает слово, склоненное в нужную форму для его употребления с числом
 * @param number Число
 * @param wordRoot "Корень" слова - его неизменяемая часть.
 * @param endings Окончания слова - для одного, двух, нескольких
 * @param includeNumberBefore Добавить в начало результирующей строки число
 * @example wordRoot: `Банк`; endings: ["", "а", "ов"]; при number = 1: Банк; =2: Банка; =5: Банков
 */
const getWordDeclension = (
  number: number,
  wordRoot: string,
  endings: [one: string, two: string, few: string],
  includeNumberBefore = true,
): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  const endingIndex = number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5];

  return `${includeNumberBefore ? `${number} ` : ""}${wordRoot}${endings[endingIndex]}`;
};

/**
 * Определяет, содержит ли строка только латинские символы, пробелы точки и запятые
 * @param value Строка для проверки
 */
const isOnlyLatinSpacesDots = (value: string) => {
  return /^[a-zA-Z\s.,]+$/.test(value);
};

export const entityHelper = {
  formatPhoneNumber,
  getWordDeclension,
  isOnlyLatinSpacesDots,
  getDigitsFromString,
};
