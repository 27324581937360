import { useFormikContext } from "formik";
import { dateTimeHelper } from "@sideg/helpers";
import { FormSelectResponsive } from "@sideg/ui-kit/atoms/form-controls/select";
import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import { UNSELECTED_ENUM_LITERAL } from "../../../common/types/utils";
import { BankGuaranteeCalculatorFormValues } from "../types/BankGuaranteeCalculatorFormValues";
import { useCustomFieldOnChange, useGetFieldName, useGetFormFieldError } from "../../../common/form";
import { BlockResponsiveSubmitButton } from "../../../common/ui-kit/buttons/block-responsive-submit";
import {
  GuaranteeTypeCode,
  guaranteeTypeCodeForm,
  FederalLawTypeWithCommerce,
  federalLawTypeWithCommerce,
} from "../../../common/types/demand";

export interface BankGuaranteeCalculatorFormContentProps {
  submitError?: string;
}

export const BankGuaranteeCalculatorFormContent = ({ submitError }: BankGuaranteeCalculatorFormContentProps) => {
  const getFieldName = useGetFieldName<BankGuaranteeCalculatorFormValues>();
  const getFieldError = useGetFormFieldError<BankGuaranteeCalculatorFormValues>();
  const onCustomChange = useCustomFieldOnChange<BankGuaranteeCalculatorFormValues>();
  const form = useFormikContext<BankGuaranteeCalculatorFormValues>();

  return (
    <>
      <FormSelectResponsive
        name={getFieldName("guaranteeType")}
        label="Тип БГ"
        items={guaranteeTypeCodeForm.values}
        getItemTitle={guaranteeTypeCodeForm.getTitle}
        selectedItem={form.values.guaranteeType ?? UNSELECTED_ENUM_LITERAL}
        isItemDisabled={(x) => x === UNSELECTED_ENUM_LITERAL}
        onBlur={() => {
          form.setFieldTouched(getFieldName("guaranteeType"), true, true);
        }}
        onChange={(newValue) => {
          form.setFieldValue(getFieldName("guaranteeType"), newValue as GuaranteeTypeCode, true);
        }}
        error={getFieldError(form, "guaranteeType")}
        disabled={form.isSubmitting}
      />
      <FormSelectResponsive
        name={getFieldName("federalLaw")}
        label="ФЗ"
        items={federalLawTypeWithCommerce.values}
        getItemTitle={federalLawTypeWithCommerce.getTitle}
        selectedItem={form.values.federalLaw}
        onBlur={() => {
          form.setFieldTouched(getFieldName("federalLaw"), true, true);
        }}
        onChange={(newValue) => {
          form.setFieldValue(getFieldName("federalLaw"), newValue as FederalLawTypeWithCommerce, true);
        }}
        error={getFieldError(form, "federalLaw")}
        disabled={form.isSubmitting}
      />
      <FormInputPrice
        autoComplete="off"
        label="Сумма обеспечения, ₽"
        {...form.getFieldProps(getFieldName("guaranteeSum"))}
        error={getFieldError(form, "guaranteeSum")}
        disabled={form.isSubmitting}
      />
      <DateFormInput
        placeholder="дд.мм.гггг"
        label="Дата начала БГ"
        min={new Date()}
        {...form.getFieldProps(getFieldName("dateStart"))}
        onChange={(e: string) => onCustomChange(form.setFieldValue, "dateStart", e, true)}
        error={getFieldError(form, "dateStart")}
        autoComplete="off"
        disabled={form.isSubmitting}
      />
      <DateFormInput
        placeholder="дд.мм.гггг"
        label="Дата окончания БГ"
        min={dateTimeHelper.simpleModify("addDays", new Date(), 1)}
        {...form.getFieldProps(getFieldName("dateEnd"))}
        onChange={(e: string) => onCustomChange(form.setFieldValue, "dateEnd", e, true)}
        error={getFieldError(form, "dateEnd")}
        autoComplete="off"
        disabled={form.isSubmitting}
      />
      <BlockResponsiveSubmitButton type="submit" isLoading={form.isSubmitting} error={submitError}>
        Рассчитать
      </BlockResponsiveSubmitButton>
    </>
  );
};
