import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useCreditExecutionListFilterContext } from "../../hooks/filters/useCreditExecutionListFilterContext";
import { ProductFiltersBadges } from "../../../../common/filters/use-cases";
import { creditExecutionListFiltersValidationSchema } from "../../validation/creditExecutionListFiltersValidationSchema";
import { useCreditExecutionListFilteringItemsConverter } from "../../hooks/filters/useCreditExecutionListFilteringItemsConverter";

export type CreditExecutionListHeaderFiltersBadgesProps = BaseCssProps;

export const CreditExecutionListHeaderFiltersBadges = ({ className }: CreditExecutionListHeaderFiltersBadgesProps) => {
  const filteringObject = useCreditExecutionListFilterContext();
  const converter = useCreditExecutionListFilteringItemsConverter();

  return (
    <ProductFiltersBadges
      filteringObject={filteringObject}
      schema={creditExecutionListFiltersValidationSchema}
      converter={converter}
      className={className}
    />
  );
};
