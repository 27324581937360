import { useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";
import { ApiAsyncThunk } from "../../store/utils";
import { useAppDispatch } from "../../store";
import { LoadingStatusEnum } from "../../types/state";
import { isBaseApiError } from "../../api/types";
import { loggingService } from "../../logging/services/loggingService";

export interface UseApiMutationActionOptions {
  errorHandler?: (e: unknown) => Promise<string>;
  errorMessagePlaceholder?: string;
}

export const apiMutationDefaultErrorHandler = (e: unknown): string | undefined => {
  if (isBaseApiError(e)) {
    return e.message;
  }

  loggingService.logCritical(e);

  return undefined;
};

export const useApiMutationAction = () => {
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState(LoadingStatusEnum.Idle);
  const [error, setError] = useState<string | undefined>(undefined);

  const isMounted = useRef<boolean | null>(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const mutate = useMemo(() => {
    const setIfMounted =
      <T>(setState: React.Dispatch<React.SetStateAction<T>>) =>
      (value: T) => {
        if (isMounted.current === true) {
          setState(value);
        }
      };

    const setErrorIfMounted = setIfMounted(setError);
    const setStatusIfMounted = setIfMounted(setStatus);

    return async <TOutput, TInput>(
      thunk: ApiAsyncThunk<TOutput, TInput>,
      inputModel: TInput,
      { errorHandler, errorMessagePlaceholder }: UseApiMutationActionOptions,
    ): Promise<TOutput | undefined> => {
      try {
        setStatusIfMounted(LoadingStatusEnum.Loading);
        setErrorIfMounted(undefined);

        const result = await dispatch(thunk(inputModel)).unwrap();
        setStatusIfMounted(LoadingStatusEnum.Succeeded);

        return result;
      } catch (e: unknown) {
        let errorText: string | undefined;
        if (!errorHandler) {
          errorText = apiMutationDefaultErrorHandler(e) ?? errorMessagePlaceholder;
        } else {
          errorText = await errorHandler(e);
        }

        setErrorIfMounted(errorText);
        setStatusIfMounted(LoadingStatusEnum.Failed);

        return undefined;
      }
    };
  }, [dispatch]);

  return useMemo(() => {
    return { mutate, error, status };
  }, [error, mutate, status]);
};
