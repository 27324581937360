import LoaderCompact from "../../../common/loader-compact/LoaderCompact";
import styles from "./ClientFormBlock.module.css";
import { ReactNode } from "react";

interface ClientFormBlockProps {
  title?: string;
  request?: boolean;
  children?: ReactNode;
}

const ClientFormBlock: React.FC<ClientFormBlockProps> = ({ title, request, children }) => {
  return (
    <div className={styles["container"]}>
      {title && (
        <div className={styles["title-block"]}>
          <span className={styles["title"]}>
            {title}
            {request && <LoaderCompact className={styles["loader"]} />}
          </span>
        </div>
      )}
      {children}
    </div>
  );
};

export default ClientFormBlock;
