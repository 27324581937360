import styled from "@emotion/styled";
import { AppLinkButton } from "../../../../common/ui/links";

export const Link = styled(AppLinkButton)`
  border-radius: 8px;
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  padding: ${({ theme }) => theme.spaces.md};
  transition: background-color 0.25s linear;

  & svg {
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    padding: ${({ theme }) => theme.spaces.sm};

    border-radius: 50%;
    border: 1px solid #fff;

    & > path {
      stroke: #fff;
    }
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;
