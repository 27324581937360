import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { CreditWorkingAssetsListTabFilter } from "./tabs/CreditWorkingAssetsListTabFilter";
import { useCreateNavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { ProductListCreationButton } from "../../../../common/ui/product/list/creation-button";
import { FilterBadgesListEmpty } from "../../../../common/ui-kit/filters/badges";
import { CreditWorkingAssetsListHeaderFilters } from "./filters/CreditWorkingAssetsListHeaderFilters";
import { CreditWorkingAssetsListHeaderFiltersBadges } from "./CreditWorkingAssetsListHeaderFiltersBadges";
import { useFirstRenderThunkDispatch } from "../../../../common/hooks";
import { getFiltrationParametersCreditWorkingAssetsList } from "../../store/creditWorkingAssetsList.thunks";
import { useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsFilters } from "../../store/filters/creditWorkingAssetsListFilters.selectors";

export const CreditWorkingAssetsListHeader = () => {
  const state = useCreateNavigationStateFrom("Мои заявки");
  const { data, status } = useAppSelector(selectCreditWorkingAssetsFilters);

  useFirstRenderThunkDispatch(status, getFiltrationParametersCreditWorkingAssetsList);

  return (
    <AppLayoutResponsiveListPage.Header>
      <StackPanel gap="lg" direction="row" justifyContent="space-between">
        <AppLayoutResponsiveListPage.Title>Мои заявки на кредит на ПОС</AppLayoutResponsiveListPage.Title>
        <ProductListCreationButton to={(x) => x.creditWorkingAssets.creation.create} state={state} />
      </StackPanel>
      <CreditWorkingAssetsListHeaderFilters />
      {!data && <FilterBadgesListEmpty />}
      {data && <CreditWorkingAssetsListHeaderFiltersBadges />}
      <AppLayoutResponsiveListPage.TabsContainer>
        <CreditWorkingAssetsListTabFilter />
      </AppLayoutResponsiveListPage.TabsContainer>
    </AppLayoutResponsiveListPage.Header>
  );
};
