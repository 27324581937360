import { useMemo } from "react";

const DATA_ATTRIBUTE_NAME = "data-is-field-with-error" as const;

type Booleanish<T extends boolean> = T extends true ? "true" : "false";

export const useCreateFormElementErrorDataAttribute = <T extends boolean>(
  isError: T,
): readonly [name: typeof DATA_ATTRIBUTE_NAME, value: Booleanish<T>] => {
  return useMemo(() => [DATA_ATTRIBUTE_NAME, `${isError}` as Booleanish<T>] as const, [isError]);
};
