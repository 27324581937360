import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { isIdle, isLoading } from "../../../common/types/state";
import { getBankGuaranteeDetails } from "../store/bankGuaranteeDetails.thunks";
import { AsyncThunkActionRequest } from "../../../common/types/thunks";
import { BankGuaranteeId } from "../../../common/types/demand";
import { bankGuaranteeDetailsGuaranteeDetailsActions } from "../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.slice";
import {
  useBankGuaranteeDetailsNeedReloadListener,
  UseBankGuaranteeDetailsNeedReloadListenerEffect,
} from "./useBankGuaranteeDetailsNeedReloadListener";

export const useBankGuaranteeDetailsFetcher = (guaranteeId: BankGuaranteeId | undefined) => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);

  useEffect(() => {
    if (isLoading(status)) {
      dispatch(bankGuaranteeDetailsGuaranteeDetailsActions.setLastRequestTime(Date.now()));
    }
  }, [dispatch, status]);

  const onReload: UseBankGuaranteeDetailsNeedReloadListenerEffect = useCallback((_, api) => {
    api.dispatch(bankGuaranteeDetailsGuaranteeDetailsActions.reload());
  }, []);

  useBankGuaranteeDetailsNeedReloadListener(onReload);

  const requestRef = useRef<AsyncThunkActionRequest<{ id: BankGuaranteeId }> | undefined>(undefined);

  useEffect(() => {
    requestRef.current?.abort();
    requestRef.current = undefined;
  }, [guaranteeId]);

  useEffect(() => {
    if (isIdle(status) && guaranteeId) {
      requestRef.current = dispatch(getBankGuaranteeDetails({ id: guaranteeId }));
    }
  }, [dispatch, guaranteeId, status]);
};
