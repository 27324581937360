import { date, number, object, mixed, string, boolean } from "yup";
import { dateTimeHelper } from "@sideg/helpers";
import {
  FormSchemaType,
  getDateOnlyTransformer,
  getEnumLikeLiteralTypeSchema,
  getFormattedNumberTransformer,
  getRequestDateSchemaType,
  getTaxpayerNumberSchema,
  isFormValuesSchemaType,
} from "../../../common/validation";
import { clientTaxSystemType, federalLawType } from "../../../common/types/demand";
import { federalLawRules } from "./rules/federalLaw.rules";

const getCreditExecutionCreationValidationSchema = (schemaType: FormSchemaType) =>
  object({
    clientId: number().required("Не забудьте заполнить"),
    clientTaxSystem: getEnumLikeLiteralTypeSchema(clientTaxSystemType, "Не забудьте заполнить")
      .default("Simplified")
      .required("Не забудьте заполнить"),
    taxpayerNumber: isFormValuesSchemaType(schemaType) ? getTaxpayerNumberSchema().notRequired() : mixed().strip(),
    purchaseNumber: string()
      .default("")
      .trim("Укажите номер закупки")
      .max(80, "Длина не должна быть более 80 символов")
      .required("Не забудьте заполнить"),
    purchaseLink: string()
      .default("")
      .trim("Не забудьте заполнить")
      .min(1, "Не забудьте заполнить")
      .required("Не забудьте заполнить"),
    isClosedAuction: boolean().default(false).required("Не забудьте заполнить"),
    federalLaw: getEnumLikeLiteralTypeSchema(federalLawType)
      .default("Fz223")
      .required("Не забудьте заполнить")
      .when(["isClosedAuction"], {
        is: (isClosedAuction: boolean) => isClosedAuction,
        then: () =>
          string()
            .required("Не забудьте заполнить")
            .oneOf(federalLawRules.closedAuctionCollection, "Допустима закупка только по 44/223ФЗ"),
        otherwise: (schema) => schema,
      }),
    contractSubject: string().trim("Не забудьте заполнить").required("Не забудьте заполнить").default(""),
    beneficiaryTaxpayerNumber: getTaxpayerNumberSchema().default("").required("Не забудьте заполнить"),
    beneficiaryName: string().default("").required("Не забудьте заполнить"),
    dateStart: isFormValuesSchemaType(schemaType)
      ? date()
          .transform(getDateOnlyTransformer())
          .typeError("Укажите корректную дату")
          .min(
            dateTimeHelper.unaryModify("startOfDay", new Date()),
            `Дата начала должна быть не ранее сегодняшнего дня`,
          )
          .default(() => new Date())
          .required("Не забудьте заполнить")
      : getRequestDateSchemaType(),
    dateEnd: isFormValuesSchemaType(schemaType)
      ? date()
          .transform(getDateOnlyTransformer())
          .when("dateStart", (dateStart: Date | null | undefined, schema) => {
            return dateTimeHelper.isValid(dateStart)
              ? schema.min(
                  dateTimeHelper.simpleModify("addDays", dateStart, 1),
                  "Дата окончания не должна быть ранее даты начала",
                )
              : schema.min(
                  dateTimeHelper.simpleModify("addDays", new Date(), 1),
                  "Дата окончания должна быть не ранее завтрашнего дня",
                );
          })
          .typeError("Укажите корректную дату")
          .required("Не забудьте заполнить")
      : getRequestDateSchemaType(),
    creditSum: number()
      .transform(getFormattedNumberTransformer())
      .typeError("Укажите корректную сумму")
      .min(1, "Сумма должна быть больше нуля")
      .required("Не забудьте заполнить"),
  });

export const getCreditExecutionCreationFromValidationSchema = () =>
  getCreditExecutionCreationValidationSchema("formValues");

export const getCreditExecutionCreationRequestValidationSchema = () =>
  getCreditExecutionCreationValidationSchema("request");

export const creditExecutionCreationFromValidationSchema = getCreditExecutionCreationFromValidationSchema();

export const creditExecutionCreationRequestValidationSchema = getCreditExecutionCreationRequestValidationSchema();
