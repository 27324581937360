import { BaseCssProps } from "../../../../../../../../common/ui-kit/types";
import { ProductListItemDemandItemLayoutDesktop } from "../../../../../../../../common/ui/product/list/product-list-item";

export type CreditExecutionListItemDemandDesktopSkeletonProps = BaseCssProps;

export const CreditExecutionListItemDemandDesktopSkeleton = ({
  className,
}: CreditExecutionListItemDemandDesktopSkeletonProps) => {
  return (
    <ProductListItemDemandItemLayoutDesktop className={className}>
      <ProductListItemDemandItemLayoutDesktop.Skeleton height={18} />
      <ProductListItemDemandItemLayoutDesktop.Skeleton />
      <ProductListItemDemandItemLayoutDesktop.Skeleton />
      <ProductListItemDemandItemLayoutDesktop.Skeleton />
      <ProductListItemDemandItemLayoutDesktop.Skeleton />
      <ProductListItemDemandItemLayoutDesktop.Skeleton />
    </ProductListItemDemandItemLayoutDesktop>
  );
};
