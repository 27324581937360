import * as S from "@sideg/ui-kit/atoms/checkbox/components/checkbox/FormCheckbox.styled";
import { CheckboxSize } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";

const getSvgProps = (checkboxSize?: CheckboxSize): { viewBox: string; d: string } => {
  switch (checkboxSize) {
    case "small":
      return {
        viewBox: "0 0 18 18",
        d: "M7.37744 14.0001L2.37744 9.00008L3.78744 7.59008L7.37744 11.1701L14.2174 4.33008L15.6274 5.75008L7.37744 14.0001Z",
      };
    case "default":
      return {
        viewBox: "0 0 24 24",
        d: "M9.83659 18.6668L3.16992 12.0001L5.04992 10.1201L9.83659 14.8934L18.9566 5.77344L20.8366 7.66677L9.83659 18.6668Z",
      };
    default:
      return {
        viewBox: "0 0 24 24",
        d: "M9.83659 18.6668L3.16992 12.0001L5.04992 10.1201L9.83659 14.8934L18.9566 5.77344L20.8366 7.66677L9.83659 18.6668Z",
      };
  }
};

export interface FormCheckboxIconProps {
  checkboxSize: CheckboxSize;
  isDisabled: boolean;
}

export const FormCheckboxIcon = ({ checkboxSize, isDisabled }: FormCheckboxIconProps) => {
  return (
    <S.Icon checkboxSize={checkboxSize} viewBox={getSvgProps(checkboxSize).viewBox} xmlns="http://www.w3.org/2000/svg">
      <S.Rect rx="2" isDisabled={isDisabled} />
      <S.Path d={getSvgProps(checkboxSize).d} />
    </S.Icon>
  );
};
