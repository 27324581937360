import { BaseCssProps } from "../../../ui-kit/types";
import { Box } from "../../../ui-kit/containers/box";
import { Typography } from "../../../ui-kit/typography";

export type ProductDetailsDemandTasksEmptyStateProps = BaseCssProps;

export const ProductDetailsDemandTasksEmptyState = ({ className }: ProductDetailsDemandTasksEmptyStateProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" className={className}>
      <Typography fontWeight={500} lineHeight="defaultLineHeight" fontSize="fz2" fontColor="typography.primaryCaption">
        По заявке нет сообщений
      </Typography>
    </Box>
  );
};
