import { useState } from "react";
import { useField } from "formik";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { FactoringCreationDebtorDocument } from "../../types/FactoringCreationDebtorDocument";
import { CreationBlockCard } from "../../../../common/ui-kit/cards/creation-block-card";
import { DocumentUploadActiveDocument, useDocumentUploadLabelText } from "../../../../document/upload";
import { FactoringCreationDebtorDocumentFromValues } from "../../types/FactoringCreationDebtorDocumentFromValues";
import { FileUpload } from "../../../../files/upload";
import { useGetFieldName } from "../../../../common/form";

export interface FactoringCreationDebtorDocumentCardProps extends BaseCssProps {
  name: string;
  documents: FactoringCreationDebtorDocument[];
  isDisabled?: boolean;
}

export const FactoringCreationDebtorDocumentCard = ({
  name,
  documents,
  isDisabled,
  className,
}: FactoringCreationDebtorDocumentCardProps) => {
  const getFieldName = useGetFieldName<FactoringCreationDebtorDocumentFromValues>();
  const [{ value }, , { setValue }] = useField<FactoringCreationDebtorDocumentFromValues>(name);
  const [, { error, touched }] = useField(`${name}.${getFieldName("file")}`);

  const [document] = useState<FactoringCreationDebtorDocument | undefined>(
    documents.find((x) => x.documentTypeId === value?.documentTypeId),
  );

  const uploadFileLabel = useDocumentUploadLabelText({
    isLoading: isDisabled === true,
    attachedFilesCount: value?.file ? 1 : 0,
    isMultiFile: false,
    isReplaceableCollection: false,
    loadingFilesCount: value?.file ? 1 : 0,
  });

  const onDrop = async (files: File[]) => {
    if (files.length === 1) {
      await setValue({ ...value, file: files[0], dateAdded: new Date() }, true);
    }
  };

  const onRemove = async () => {
    await setValue({ ...value, file: undefined, dateAdded: undefined });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {document && (
        <CreationBlockCard className={className} padding="md">
          <DocumentUploadActiveDocument
            title={document.name}
            description={document.description}
            template={document.template}
            isRequired
            isMultiple={false}
            canUpload
            isLoading={isDisabled}
            onDrop={onDrop}
            error={touched ? error : undefined}
            uploadLabelText={uploadFileLabel}
          >
            {value?.file && (
              <FileUpload
                fileName={value.file.name}
                date={value.dateAdded ?? undefined}
                canRemove={!isDisabled}
                onRemove={onRemove}
              />
            )}
          </DocumentUploadActiveDocument>
        </CreationBlockCard>
      )}
    </>
  );
};
