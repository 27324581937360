import { CodeWithTitle } from "./CodeWithTitle";

type Title = string;

export interface EntitiesCodeWithTitle<
  TEntity extends string,
  TCodeWithTitle extends CodeWithTitle<TEntity> = CodeWithTitle<TEntity>,
> {
  ids: TEntity[];
  titles: Record<TEntity, Title>;
  codeWithTitleList: TCodeWithTitle[];
  maxTitleLength: number;
}

export const getEntitiesCodeWithTitle = <T extends string, TCodeWithTitle extends CodeWithTitle<T> = CodeWithTitle<T>>(
  codeWithTitle: TCodeWithTitle[],
): EntitiesCodeWithTitle<T, TCodeWithTitle> => {
  const ids = [] as T[];
  let maxTitleLength = 0;

  const titles = codeWithTitle.reduce(
    (accum, { code, title }) => {
      accum[code] = title;
      maxTitleLength = maxTitleLength < title.length ? title.length : maxTitleLength;

      ids.push(code);

      return accum;
    },
    {} as Record<T, string>,
  );

  return {
    ids,
    titles,
    codeWithTitleList: codeWithTitle,
    maxTitleLength,
  };
};
