import BankAccountModel from "../../../entity/models/BankAccountModel"
import CompanyModel from "../../../entity/models/CompanyModel"
import DadataAddressModel from "../../../entity/models/DadataAddressModel"
import QuestionnaireClientModel from "../../../entity/models/QuestionnaireClientModel"

export const CLIENT_FORM_CLIENT_FETCHING = 'CLIENT_FORM_CLIENT_FETCHING'
export const CLIENT_FORM_CLIENT_FETCHED = 'CLIENT_FORM_CLIENT_FETCHED'
export const CLIENT_FORM_CLIENT_RESET = 'CLIENT_FORM_CLIENT_RESET'
export const CLIENT_FORM_CLIENT_ERROR = 'CLIENT_FORM_CLIENT_ERROR'

export const CLIENT_FORM_ACCOUNT_FETCHING = 'CLIENT_FORM_ACCOUNT_FETCHING'
export const CLIENT_FORM_ACCOUNT_FETCHED = 'CLIENT_FORM_ACCOUNT_FETCHED'
export const CLIENT_FORM_ACCOUNT_ERROR = 'CLIENT_FORM_ACCOUNT_ERROR'
export const CLIENT_FORM_ACCOUNT_RESET = 'CLIENT_FORM_ACCOUNT_RESET'

export const CLIENT_FORM_COMPANY_FETCHING = 'CLIENT_FORM_COMPANY_FETCHING'
export const CLIENT_FORM_COMPANY_FETCHED = 'CLIENT_FORM_COMPANY_FETCHED'
export const CLIENT_FORM_COMPANY_ERROR = 'CLIENT_FORM_COMPANY_ERROR'
export const CLIENT_FORM_COMPANY_RESET = 'CLIENT_FORM_COMPANY_RESET'

export const CLIENT_FORM_UPDATE_PROCESSING = 'CLIENT_FORM_UPDATE_PROCESSING'
export const CLIENT_FORM_UPDATE_SUCCESS = 'CLIENT_FORM_UPDATE_SUCCESS'
export const CLIENT_FORM_UPDATE_ERROR = 'CLIENT_FORM_UPDATE_ERROR'
export const CLIENT_FORM_UPDATE_RESET = 'CLIENT_FORM_UPDATE_RESET'

export const CLIENT_FORM_ADDRESS_FETCHING = 'CLIENT_FORM_ADDRESS_FETCHING'
export const CLIENT_FORM_ADDRESS_FETCHED = 'CLIENT_FORM_ADDRESS_FETCHED'
export const CLIENT_FORM_ADDRESS_ERROR = 'CLIENT_FORM_ADDRESS_ERROR'
export const CLIENT_FORM_ADDRESS_RESET = 'CLIENT_FORM_ADDRESS_RESET'

export const CLIENT_FORM_SET_SRC = 'CLIENT_FORM_SET_SRC'
export const CLIENT_FORM_RESET_SRC = 'CLIENT_FORM_RESET_SRC'

export interface ClientFormClientFetching {
    type: typeof CLIENT_FORM_CLIENT_FETCHING
}

export interface ClientFormClientFetched {
    type: typeof CLIENT_FORM_CLIENT_FETCHED
    payload: QuestionnaireClientModel
}

export interface ClientFormClientReset {
    type: typeof CLIENT_FORM_CLIENT_RESET
}

export interface ClientFormClientError {
    type: typeof CLIENT_FORM_CLIENT_ERROR
    error: any
}

export interface ClientFormAccountFetching {
    type: typeof CLIENT_FORM_ACCOUNT_FETCHING
}

export interface ClientFormAccountFetched {
    type: typeof CLIENT_FORM_ACCOUNT_FETCHED
    payload: BankAccountModel
}

export interface ClientFormAccountError {
    type: typeof CLIENT_FORM_ACCOUNT_ERROR
    error: any
}

export interface ClientFormAccountReset {
    type: typeof CLIENT_FORM_ACCOUNT_RESET
}

export interface ClientFormCompanyFetching {
    type: typeof CLIENT_FORM_COMPANY_FETCHING
}

export interface ClientFormCompanyFetched {
    type: typeof CLIENT_FORM_COMPANY_FETCHED
    payload: CompanyModel
}

export interface ClientFormCompanyError {
    type: typeof CLIENT_FORM_COMPANY_ERROR
    error: any
}

export interface ClientFormCompanyReset {
    type: typeof CLIENT_FORM_COMPANY_RESET
}

export interface ClientFormUpdateProcessing {
    type: typeof CLIENT_FORM_UPDATE_PROCESSING
}

export interface ClientFormUpdateSuccess {
    type: typeof CLIENT_FORM_UPDATE_SUCCESS
}

export interface ClientFormUpdateError {
    type: typeof CLIENT_FORM_UPDATE_ERROR
    error: any
}

export interface ClientFormUpdateReset {
    type: typeof CLIENT_FORM_UPDATE_RESET
}

export interface ClientFormAddressFetching {
    type: typeof CLIENT_FORM_ADDRESS_FETCHING
}

export interface ClientFormAddressFetched {
    type: typeof CLIENT_FORM_ADDRESS_FETCHED
    payload: DadataAddressModel
}

export interface ClientFormAddressError {
    type: typeof CLIENT_FORM_ADDRESS_ERROR
    error: any
}

export interface ClientFormAddressReset {
    type: typeof CLIENT_FORM_ADDRESS_RESET
}

export interface ClientFormSetSrc {
    type: typeof CLIENT_FORM_SET_SRC
    payload: string
}

export interface ClientFormResetSrc {
    type: typeof CLIENT_FORM_RESET_SRC
}

export type ClientFormDispatchType =
    ClientFormClientFetching |
    ClientFormClientFetched |
    ClientFormClientReset |
    ClientFormClientError |
    ClientFormAccountFetching |
    ClientFormAccountFetched |
    ClientFormAccountError |
    ClientFormAccountReset |
    ClientFormCompanyFetching |
    ClientFormCompanyFetched |
    ClientFormCompanyError |
    ClientFormCompanyReset |
    ClientFormUpdateProcessing |
    ClientFormUpdateSuccess |
    ClientFormUpdateError |
    ClientFormUpdateReset |
    ClientFormAddressFetching |
    ClientFormAddressFetched |
    ClientFormAddressError |
    ClientFormAddressReset |
    ClientFormSetSrc |
    ClientFormResetSrc