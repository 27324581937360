import { useFormikContext } from "formik";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { PhoneNumberInput } from "@sideg/ui-kit/atoms/form-controls/phone-number-input";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { FullPageContainerCenteredMainWithFooter } from "../../../../common/ui/containers/full-page";
import { CreationBlockWithTitle } from "../../../../common/ui-kit/cards/creation-block-card";
import { useGetFieldName, useGetFormFieldError } from "../../../../common/form";
import { SettlementAndCashCreationFormValues } from "../../types/SettlementAndCashCreationFormValues";
import { TaxpayerFormInput } from "../../../../common/ui/form-controls/taxpayer-input";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { SettlementAndCashCreationFormBanksList } from "./SettlementAndCashCreationFormBanksList";
import { CreationSubmitButton } from "../../../../common/ui/buttons/creation-submit-button";

export const SettlementAndCashCreationFormContent = () => {
  const getFieldName = useGetFieldName<SettlementAndCashCreationFormValues>();
  const getFieldError = useGetFormFieldError<SettlementAndCashCreationFormValues>();
  const form = useFormikContext<SettlementAndCashCreationFormValues>();
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  const isDisabled = form.isSubmitting;

  return (
    <FullPageContainerCenteredMainWithFooter
      footer={
        <CreationSubmitButton
          type="submit"
          isLoading={isDisabled}
          error={form.touched?.banks ? (form.errors.banks as string) : undefined}
        >
          Отправить заявку
        </CreationSubmitButton>
      }
    >
      <CreationBlockWithTitle title="Клиент">
        <StackPanel gap="md" direction="column">
          <TaxpayerFormInput
            label="ИНН"
            size="medium"
            autoComplete="off"
            {...form.getFieldProps(getFieldName("customerTaxpayerNumber"))}
            error={getFieldError(form, "customerTaxpayerNumber")}
            disabled={isDisabled}
          />
          <FormInput
            autoComplete="off"
            type="text"
            label="Наименование"
            size="medium"
            {...form.getFieldProps(getFieldName("customerName"))}
            error={getFieldError(form, "customerName")}
            disabled={isDisabled}
          />
          <FormInput
            autoComplete="off"
            type="text"
            label="Юридический адрес"
            size="medium"
            {...form.getFieldProps(getFieldName("customerAddress"))}
            error={getFieldError(form, "customerAddress")}
            disabled={isDisabled}
          />
        </StackPanel>
      </CreationBlockWithTitle>
      <CreationBlockWithTitle title="Контактное лицо">
        <StackPanel gap="md" direction="column">
          <FormInput
            autoComplete="off"
            type="text"
            label="Фамилия, имя и отчество"
            size="medium"
            {...form.getFieldProps(getFieldName("contactFullName"))}
            error={getFieldError(form, "contactFullName")}
            disabled={isDisabled}
          />
          <StackPanel gap="md" direction={{ xs: "column", [desktopBreakpoint]: "row" }}>
            <PhoneNumberInput
              {...form.getFieldProps(getFieldName("contactPhoneNumber"))}
              label="Мобильный телефон"
              size="medium"
              error={getFieldError(form, "contactPhoneNumber")}
              autoComplete="tel"
              disabled={form.isSubmitting}
            />
            <FormInput
              autoComplete="off"
              type="email"
              size="medium"
              label="Электронная почта"
              {...form.getFieldProps(getFieldName("contactEmail"))}
              error={getFieldError(form, "contactEmail")}
              disabled={isDisabled}
            />
          </StackPanel>
        </StackPanel>
      </CreationBlockWithTitle>
      <CreationBlockWithTitle title="Комментарий">
        <FormTextarea
          size="medium"
          {...form.getFieldProps(getFieldName("comment"))}
          minRows={3}
          disabled={isDisabled}
          error={getFieldError(form, "comment")}
        />
      </CreationBlockWithTitle>
      <SettlementAndCashCreationFormBanksList isDisabled={isDisabled} />
    </FullPageContainerCenteredMainWithFooter>
  );
};
