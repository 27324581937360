import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../ui-kit/types";
import { ProductCreationScoringSumLimitRangeContainer } from "./ProductCreationScoringSumLimitRangeContainer";

export type ProductCreationScoringSumLimitRangeSkeletonProps = BaseCssProps;

export const ProductCreationScoringSumLimitRangeSkeleton = ({
  className,
}: ProductCreationScoringSumLimitRangeSkeletonProps) => {
  return (
    <ProductCreationScoringSumLimitRangeContainer className={className}>
      <Skeleton inline width={80} /> – <Skeleton inline width={80} />
    </ProductCreationScoringSumLimitRangeContainer>
  );
};
