import { FormSelectResponsive } from "@sideg/ui-kit/atoms/form-controls/select";
import { GuaranteeTypeCode, guaranteeTypeCodeForm } from "../../../../../common/types/demand";
import { UNSELECTED_ENUM_LITERAL } from "../../../../../common/types/utils";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";

export interface BankGuaranteeCreationGuaranteeTypeFieldProps {
  disabled?: boolean;
}

export const BankGuaranteeCreationGuaranteeTypeField = ({ disabled }: BankGuaranteeCreationGuaranteeTypeFieldProps) => {
  const [{ value, name, error }, { setValue, setTouched }] = useBankGuaranteeCreationField("guaranteeType");

  return (
    <FormSelectResponsive
      name={name}
      selectSize="medium"
      items={guaranteeTypeCodeForm.values}
      getItemTitle={guaranteeTypeCodeForm.getTitle}
      selectedItem={value ?? UNSELECTED_ENUM_LITERAL}
      isItemDisabled={(x) => x === UNSELECTED_ENUM_LITERAL}
      onBlur={async () => {
        await setTouched(true, true);
      }}
      onChange={async (newValue) => {
        await setValue(newValue as GuaranteeTypeCode, true);
      }}
      error={error}
      disabled={disabled}
    />
  );
};
