import { ConditionTitleType } from "../../../common/ui/layout/conditions";

export const conditionsTitles: ConditionTitleType[] = [
  {
    title: "Банк",
    size: "180px",
  },
  {
    title: "ФЗ",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Максимальная сумма",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Максимальный срок",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Срок деятельности компании",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Типы продуктов",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Стоп-факторы",
    size: "400px",
  },
  {
    title: "Документы",
    size: "minmax(250px, 1fr)",
  },
];
