import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { ProductListItemStageCountersContainer } from "../../../../../../common/ui/product/stage";
import { bankGuaranteeDemandStageType } from "../../../../../common/types";
import { BankGuaranteeListItemDemandCounters } from "../../../../types/item/BankGuaranteeListItemDemandCounters";
import { BankGuaranteeListItemStageCounter } from "./BankGuaranteeListItemStageCounter";

export interface BankGuaranteeListItemStageCountersProps extends BaseCssProps {
  counters: BankGuaranteeListItemDemandCounters;
}

export const BankGuaranteeListItemStageCounters = ({
  className,
  counters,
}: BankGuaranteeListItemStageCountersProps) => {
  return (
    <ProductListItemStageCountersContainer className={className}>
      {bankGuaranteeDemandStageType.values
        .filter((x) => !!counters[x])
        .map((x) => (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <BankGuaranteeListItemStageCounter key={x} stage={x} count={counters[x]!} />
        ))}
    </ProductListItemStageCountersContainer>
  );
};
