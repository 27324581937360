import { createSlice, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { BANK_GUARANTEE_LIST_SLICE_NAME, getBankGuaranteeList } from "../bankGuaranteeList.thunks";
import { BankGuaranteeListItemsState } from "./bankGuaranteeListItems.types";
import { getLoadingStatusFromAction, isAborted, LoadingStatusEnum } from "../../../../common/types/state";
import {
  bankGuaranteeListItemClientAdapter,
  bankGuaranteeListItemDemandsAdapter,
  bankGuaranteeListItemsGuaranteesAdapter,
} from "./bankGuaranteeListItems.adapter";
import { bankGuaranteeListItemsMapper } from "./bankGuaranteeListItems.mapper";
import { BankGuaranteeId } from "../../../../common/types/demand";
import { GetBankGuaranteeListItemOutputDto } from "../../api/dto/output/GetBankGuaranteeListOutputDto";
import { onBankGuaranteeExtractFromRegistryStatusChanged } from "../../../extract-from-registry";
import { bankGuaranteeFileRegistryStatusType } from "../../../common/types";

const SLICE_NAME = combineSliceNames(BANK_GUARANTEE_LIST_SLICE_NAME, "items");

const initialState: BankGuaranteeListItemsState = {
  status: LoadingStatusEnum.Idle,
  guarantees: bankGuaranteeListItemsGuaranteesAdapter.getInitialState(),
  demands: bankGuaranteeListItemDemandsAdapter.getInitialState(),
  clients: bankGuaranteeListItemClientAdapter.getInitialState(),
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changeItem: (
      state,
      action: PayloadAction<{ id: BankGuaranteeId; item: GetBankGuaranteeListItemOutputDto | undefined }>,
    ) => {
      const actualGuarantee = state.guarantees.entities[action.payload.id];
      if (actualGuarantee) {
        if (action.payload.item === undefined) {
          const { demandIds, issuedDemandId } = actualGuarantee;
          if (issuedDemandId !== undefined) {
            bankGuaranteeListItemDemandsAdapter.removeOne(state.demands, issuedDemandId);
          }
          bankGuaranteeListItemDemandsAdapter.removeMany(state.demands, demandIds);
          bankGuaranteeListItemsGuaranteesAdapter.removeOne(state.guarantees, action.payload.id);
        } else {
          const { guarantee, demands, client } = bankGuaranteeListItemsMapper.mapItem(action.payload.item);
          bankGuaranteeListItemsGuaranteesAdapter.upsertOne(state.guarantees, guarantee);
          bankGuaranteeListItemDemandsAdapter.upsertMany(state.demands, demands);
          bankGuaranteeListItemClientAdapter.upsertOne(state.clients, client);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankGuaranteeList.pending, (state, action) => {
        if (state.pagination && action.meta.arg && action.meta.arg.page <= state.pagination.pageNumber) {
          bankGuaranteeListItemsGuaranteesAdapter.removeAll(state.guarantees);
          bankGuaranteeListItemDemandsAdapter.removeAll(state.demands);
          bankGuaranteeListItemClientAdapter.removeAll(state.clients);
          state.pagination = undefined;
        }
      })
      .addCase(getBankGuaranteeList.fulfilled, (state, action) => {
        const { clients, demands, guarantees } = bankGuaranteeListItemsMapper.mapList(action.payload.body.data);
        if (action.payload.body.paginationInformation?.pageNumber === 1) {
          bankGuaranteeListItemsGuaranteesAdapter.setAll(state.guarantees, guarantees);
          bankGuaranteeListItemDemandsAdapter.setAll(state.demands, demands);
          bankGuaranteeListItemClientAdapter.setAll(state.clients, clients);
        } else {
          bankGuaranteeListItemsGuaranteesAdapter.upsertMany(state.guarantees, guarantees);
          bankGuaranteeListItemDemandsAdapter.upsertMany(state.demands, demands);
          bankGuaranteeListItemClientAdapter.upsertMany(state.clients, clients);
        }

        state.pagination = action.payload.body.paginationInformation;
      })
      .addCase(getBankGuaranteeList.rejected, (state, action) => {
        state.error = action.payload?.message;
      })
      .addCase(onBankGuaranteeExtractFromRegistryStatusChanged, (state, action) => {
        const item = state.demands.entities[action.payload.demandId];
        if (item && item.approval?.fileRegistryStatus) {
          item.approval.fileRegistryStatus.code = action.payload.newStatus;
          item.approval.fileRegistryStatus.title = bankGuaranteeFileRegistryStatusType.getTitle(
            action.payload.newStatus,
          );
        }
      })
      .addMatcher(isAsyncThunkAction(getBankGuaranteeList), (state, action) => {
        const status = getLoadingStatusFromAction(action);
        if (!isAborted(status)) {
          state.status = status;
        }
      });
  },
});

export const { changeItem: changeBankGuaranteeListItem } = slice.actions;

export const bankGuaranteeListItemsReducer = slice.reducer;
