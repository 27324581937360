import { useSelect, UseSelectProps } from "downshift";

type IsItemDisabled<TItem> = (item: TItem, index: number) => boolean;

const getNonDisabledIndex = <TItem>(
  start: number,
  backwards: boolean,
  items: TItem[],
  isItemDisabled: IsItemDisabled<TItem>,
  circular = false,
): number => {
  const count = items.length;

  if (backwards) {
    // eslint-disable-next-line no-plusplus
    for (let index = start; index >= 0; index--) {
      if (!isItemDisabled(items[index], index)) {
        return index;
      }
    }
  } else {
    // eslint-disable-next-line no-plusplus
    for (let index = start; index < count; index++) {
      if (!isItemDisabled(items[index], index)) {
        return index;
      }
    }
  }

  if (circular) {
    return getNonDisabledIndex(backwards ? count - 1 : 0, backwards, items, isItemDisabled);
  }

  return -1;
};

// https://github.com/downshift-js/downshift/blob/0be1752af4e2633b12d6bbd8cd072dc0e21187d6/src/utils.js#L323
const getHighlightedIndex = <TItem>(
  start: number,
  offset: 1 | -1,
  items: TItem[],
  isItemDisabled: IsItemDisabled<TItem>,
  circular = false,
) => {
  const count = items.length;
  if (count === 0) {
    return -1;
  }

  const itemsLastIndex = count - 1;

  if (start < 0 || start > itemsLastIndex) {
    // eslint-disable-next-line no-param-reassign
    start = offset > 0 ? -1 : itemsLastIndex + 1;
  }

  let current = start + offset;

  if (current < 0) {
    current = circular ? itemsLastIndex : 0;
  } else if (current > itemsLastIndex) {
    current = circular ? 0 : itemsLastIndex;
  }

  const highlightedIndex = getNonDisabledIndex(current, offset < 0, items, isItemDisabled, circular);

  if (highlightedIndex === -1) {
    return start >= count ? -1 : start;
  }

  return highlightedIndex;
};

export const withSelectCircularNavigation = <TItem>({
  circularNavigation,
  ...props
}: UseSelectProps<TItem> & {
  circularNavigation?: boolean;
}): UseSelectProps<TItem> => {
  if (circularNavigation !== true) {
    return props;
  }

  const isItemDisabled = (item: unknown, index: number) => props.isItemDisabled?.(item as TItem, index) ?? false;
  const stateReducer: UseSelectProps<TItem>["stateReducer"] = (state, actionAndChanges) => {
    const changes = props.stateReducer?.(state, actionAndChanges) ?? actionAndChanges.changes;

    switch (actionAndChanges.type) {
      case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowDown:
        return {
          ...changes,
          highlightedIndex: getHighlightedIndex(
            state.highlightedIndex,
            1,
            props.items,
            isItemDisabled,
            circularNavigation,
          ),
        };
      case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowUp:
        return {
          ...changes,
          highlightedIndex: getHighlightedIndex(
            state.highlightedIndex,
            -1,
            props.items,
            isItemDisabled,
            circularNavigation,
          ),
        };
      default:
        return changes;
    }
  };

  return { ...props, stateReducer };
};
