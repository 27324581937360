import { useChatDialogHub } from "../../../../../chat/dialog";
import { useHub } from "../../../../hubs";
import {
  queueTasksHubConnection,
  useQueueTasksHubLogOutNeedUpdateListener,
} from "../../../../hubs-use-cases/queue-tasks";
import { useQueueTasksNavigationHub } from "../../../../../queue-tasks/notifications";

export const useAppGlobalHubs = () => {
  useChatDialogHub();
  useHub(queueTasksHubConnection);
  useQueueTasksHubLogOutNeedUpdateListener();
  useQueueTasksNavigationHub();
};
