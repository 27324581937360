import { forwardRef } from "react";
import {
  FormNumberFormatInput,
  FormNumberFormatInputProps,
} from "@sideg/ui-kit/atoms/form-controls/number-format-input";

export type TaxpayerFormInputProps = Omit<FormNumberFormatInputProps, "type" | "value" | "defaultValue" | "inputMode">;

export const TaxpayerFormInput = forwardRef<HTMLInputElement, TaxpayerFormInputProps>((props, ref) => {
  return (
    <FormNumberFormatInput
      isAllowed={(values) => values.formattedValue.length <= 12}
      inputMode="numeric"
      {...props}
      ref={ref}
    />
  );
});
