import { makeEnumLikeLiteral } from "../../../../../common/types/utils";

const titles = {
  All: "Без темы",
  Bill: "Стоимость БГ",
  Project: "Проект БГ",
};

export const bankGuaranteeDetailsDemandNewCommentGroupType = makeEnumLikeLiteral(
  ["All", "Bill", "Project"] as const,
  titles,
);
