import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { FullPageContainerHeaderContainer } from "../../../ui/containers/full-page";
import { useProductCreationBackToStage } from "../../hooks/creation/useProductCreationBackToStage";
import { ProductCreationPageHeaderDesktop } from "./ProductCreationPageHeader.desktop";
import { ProductCreationPageHeaderMobile } from "./ProductCreationPageHeader.mobile";
import { ProductCreationPageHeaderProps } from "./ProductCreationPageHeaderProps";

export const ProductCreationPageHeader = ({
  title,
  activeStage,
  onPrevStageClick,
  from,
}: Required<ProductCreationPageHeaderProps>) => {
  const { canBackPrevious, onBackPrevious } = useProductCreationBackToStage(activeStage, onPrevStageClick);

  return (
    <FullPageContainerHeaderContainer>
      <ThemeMobileDesktopView
        mobile={
          <ProductCreationPageHeaderMobile
            onBackToPreviousStage={onBackPrevious}
            activeStage={activeStage}
            from={from}
            canBackToPreviousStage={canBackPrevious}
            title={title}
          />
        }
        desktop={
          <ProductCreationPageHeaderDesktop
            onBackToPreviousStage={onBackPrevious}
            activeStage={activeStage}
            from={from}
            canBackToPreviousStage={canBackPrevious}
            title={title}
            onPrevStageClick={onPrevStageClick}
          />
        }
      />
    </FullPageContainerHeaderContainer>
  );
};
