import { boolean, date, DateSchema, mixed, number, NumberSchema, object, string, StringSchema } from "yup";
import { dateTimeHelper } from "@sideg/helpers";
import {
  FormSchemaType,
  getDateOnlyTransformer,
  getEnumLikeLiteralTypeSchema,
  getFormattedNumberTransformer,
  getRequestDateSchemaType,
  getTaxpayerNumberSchema,
  isFormValuesSchemaType,
} from "../../../common/validation";
import {
  clientTaxSystemType,
  federalLawTypeWithCommerce,
  FederalLawTypeWithCommerce,
  GuaranteeTypeCode,
  guaranteeTypeCode,
} from "../../../common/types/demand";
import { federalLawRules } from "../../common/validation";
import { purchaseIdentificationCodeRules } from "./rules/purchaseIdentificationCode.rules";
import { enforcementSumRules } from "./rules/enforcementSum.rules";
import { contractFinalSumRules } from "./rules/contractFinalSum.rules";
import { dateEventRules } from "./rules/dateEvent.rules";
import { contractPrepaidSumRules } from "./rules/contractPrepaidSum.rules";

const getBankGuaranteeCreationValidationSchema = (schemaType: FormSchemaType) =>
  object({
    purchaseNumber: string()
      .default("")
      .when("federalLaw", (federalLaw: FederalLawTypeWithCommerce, schema: StringSchema) => {
        return federalLaw === "COMMERCE"
          ? schema.strip()
          : schema
              .trim("Укажите номер закупки")
              .max(80, "Длина не должна быть более 80 символов")
              .required("Не забудьте заполнить");
      }),
    lotNumber: string()
      .required("Не забудьте заполнить")
      .max(10, "Номер лота - не более 10 символов")
      .default("1")
      .strict(),
    purchaseLink: string()
      .max(500, "Требуется корректная ссылка на закупку длиной до 500 символов ")
      .default("")
      .when("federalLaw", (federalLaw: FederalLawTypeWithCommerce, schema: StringSchema) => {
        return federalLaw === "COMMERCE"
          ? schema.notRequired()
          : schema.trim("Не забудьте заполнить").min(1, "Не забудьте заполнить").required("Не забудьте заполнить");
      }),
    isClosedAuction: boolean().default(false).required("Не забудьте заполнить"),
    guaranteeSum: number()
      .transform(getFormattedNumberTransformer())
      .typeError("Укажите корректную сумму")
      .min(0, "Сумма не может быть отрицательной")
      .required("Не забудьте заполнить"),
    contractFirstSum: number()
      .transform(getFormattedNumberTransformer())
      .typeError("Укажите корректную сумму")
      .min(0, "Сумма не может быть отрицательной")
      .required("Не забудьте заполнить"),
    contractFinalSum: number()
      .transform(getFormattedNumberTransformer())
      .typeError("Укажите корректную сумму")
      .when("guaranteeType", (guaranteeType: GuaranteeTypeCode, schema: NumberSchema) => {
        return contractFinalSumRules.isExists(guaranteeType)
          ? schema.min(0, "Сумма не может быть отрицательной").required("Не забудьте заполнить")
          : schema.strip();
      }),
    federalLaw: getEnumLikeLiteralTypeSchema(federalLawTypeWithCommerce)
      .default("Fz223")
      .required("Не забудьте заполнить")
      .when(["isSingleSupplier", "isClosedAuction"], {
        is: (isSingleSupplier: boolean, isClosedAuction: boolean) =>
          federalLawRules.closedAuctionAndSingleSupplier.condition(isSingleSupplier, isClosedAuction),
        then: () =>
          string()
            .required("Не забудьте заполнить")
            .oneOf(federalLawRules.closedAuctionAndSingleSupplier.collection, "Допустима закупка только по 44/223ФЗ"),
        otherwise: (schema) => schema,
      })
      .when("guaranteeType", (guaranteeType: GuaranteeTypeCode, schema) => {
        if (federalLawRules.paymentGuaranteeType.condition(guaranteeType)) {
          return string()
            .required("Не забудьте заполнить")
            .oneOf(federalLawRules.paymentGuaranteeType.collection, "Для типа БГ возможна только коммерческая закупка");
        }

        return schema;
      }),
    contractPrepaidSum: number()
      .default(0)
      .transform(getFormattedNumberTransformer())
      .typeError("Укажите корректную сумму")
      .when("guaranteeType", (guaranteeType: GuaranteeTypeCode, schema: NumberSchema) => {
        return contractPrepaidSumRules.isExists(guaranteeType)
          ? schema.min(0, "Сумма не может быть отрицательной").notRequired()
          : schema.strip();
      }),
    contractSubject: string().trim("Не забудьте заполнить").required("Не забудьте заполнить").default(""),
    isWriteOffRequest: boolean().default(false).required("Не забудьте заполнить"),
    dateStart: isFormValuesSchemaType(schemaType)
      ? date()
          .transform(getDateOnlyTransformer())
          .typeError("Укажите корректную дату")
          .min(
            dateTimeHelper.unaryModify("startOfDay", new Date()),
            `Дата начала должна быть не ранее сегодняшнего дня`,
          )
          .default(() => new Date())
          .required("Не забудьте заполнить")
      : getRequestDateSchemaType(),
    dateEnd: isFormValuesSchemaType(schemaType)
      ? date()
          .transform(getDateOnlyTransformer())
          .when("dateStart", (dateStart: Date | null | undefined, schema) => {
            return dateTimeHelper.isValid(dateStart)
              ? schema.min(
                  dateTimeHelper.simpleModify("addDays", dateStart, 1),
                  "Дата окончания не должна быть ранее даты начала",
                )
              : schema.min(
                  dateTimeHelper.simpleModify("addDays", new Date(), 1),
                  "Дата окончания должна быть не ранее завтрашнего дня",
                );
          })
          .typeError("Укажите корректную дату")
          .required("Не забудьте заполнить")
      : getRequestDateSchemaType(),
    guaranteeType: getEnumLikeLiteralTypeSchema(guaranteeTypeCode).required("Не забудьте заполнить"),
    // Обратная совместимость - поле для url из писем.
    taxpayerNumber: isFormValuesSchemaType(schemaType) ? getTaxpayerNumberSchema().notRequired() : mixed().strip(),
    clientId: number().required("Не забудьте заполнить"),
    clientTaxSystem: getEnumLikeLiteralTypeSchema(clientTaxSystemType, "Не забудьте заполнить")
      .default("Simplified")
      .required("Не забудьте заполнить"),
    beneficiaryTaxpayerNumber: getTaxpayerNumberSchema().default("").required("Не забудьте заполнить"),
    beneficiaryName: string().default("").required("Не забудьте заполнить"),
    dateEvent: isFormValuesSchemaType(schemaType)
      ? date()
          .transform(getDateOnlyTransformer())
          .typeError("Укажите корректную дату")
          .when("guaranteeType", (guaranteeType: GuaranteeTypeCode, schema: DateSchema) => {
            if (dateEventRules.isExists(guaranteeType)) {
              return schema.required("Не забудьте заполнить");
            }

            return schema.strip();
          })
      : getRequestDateSchemaType(),
    enforcementSum: number()
      .transform(getFormattedNumberTransformer())
      .typeError("Укажите корректную сумму")
      .when("guaranteeType", (guaranteeType: GuaranteeTypeCode, schema: NumberSchema) => {
        return enforcementSumRules.isExists(guaranteeType) ? schema.required("Не забудьте заполнить") : schema.strip();
      }),
    purchaseIdentificationCode: string()
      .length(36, "ИКЗ должен состоять из 36 цифр")
      .default("")
      .when(["isSingleSupplier", "isClosedAuction", "federalLaw"], {
        is: (isSingleSupplier: boolean, isClosedAuction: boolean, federalLaw: FederalLawTypeWithCommerce) => {
          return purchaseIdentificationCodeRules.isExists(isSingleSupplier, isClosedAuction, federalLaw);
        },
        then: (schema) => schema.required("Не забудьте заполнить"),
        otherwise: (schema) => schema.strip(),
      }),
    isReprovision: boolean().default(false).required("Не забудьте заполнить"),
    isSingleSupplier: boolean().default(false).required("Не забудьте заполнить"),
    isNeedCredit: boolean().default(false).notRequired(),
    isClientWithSignerPerson: boolean().default(false).notRequired(),
  });

export const getBankGuaranteeCreationFromValidationSchema = () =>
  getBankGuaranteeCreationValidationSchema("formValues");

export const getBankGuaranteeCreationRequestValidationSchema = () =>
  getBankGuaranteeCreationValidationSchema("request");

export const bankGuaranteeCreationFromValidationSchema = getBankGuaranteeCreationFromValidationSchema();

export const bankGuaranteeCreationRequestValidationSchema = getBankGuaranteeCreationRequestValidationSchema();
