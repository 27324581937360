import { Link } from "react-router-dom";
import { MenuItem as MenuItemType } from "../../utils/getUserMenu";
import * as S from "./NavigationMenuUl.desktop.styled";
import { useMenuItem } from "../../hooks/useMenuItem";

export const NavigationMenuUlDesktop = ({ items }: { items: MenuItemType[] }) => {
  return (
    <ul>
      {items.map((x) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <MenuItem key={x.title} item={x} />
      ))}
    </ul>
  );
};

const MenuItem = ({ item }: { item: MenuItemType }) => {
  const { counter, isActive } = useMenuItem(item);

  return (
    <S.Li active={isActive} nestedLevel={item.nestingLevel}>
      {!item.isGroupingItem && item.url && (
        <Link to={item.url.url}>
          {item.title}
          {!!counter && <S.Counter active={isActive}>{counter}</S.Counter>}
        </Link>
      )}
      {item.isGroupingItem && item.items?.length !== undefined && item.items.length > 0 && item.items[0].url && (
        <Link to={item.items[0].url.url}>{item.title}</Link>
      )}
      {item.items && <NavigationMenuUlDesktop items={item.items} />}
    </S.Li>
  );
};
