import { useMemo } from "react";
import { useBankGuaranteeCreationForm } from "../useBankGuaranteeCreationForm";
import { purchaseIdentificationCodeRules } from "../../../validation/rules/purchaseIdentificationCode.rules";

export const useBankGuaranteeCreationPurchaseIdentificationCodeField = () => {
  const {
    values: { isSingleSupplier, isClosedAuction, federalLaw },
  } = useBankGuaranteeCreationForm();

  return useMemo(() => {
    return purchaseIdentificationCodeRules.isExists(isSingleSupplier, isClosedAuction, federalLaw);
  }, [federalLaw, isClosedAuction, isSingleSupplier]);
};
