import styled from "@emotion/styled";
import { ProductListItemColumn } from "../../../../common/ui/product/list/product-list-item";

export const Container = styled(ProductListItemColumn)`
  gap: ${({ theme }) => theme.spaces.none};
  overflow: hidden;
  width: 100%;

  &:empty {
    display: none;
  }
`;
