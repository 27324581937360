import { useFormikContext } from "formik";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { CreationBlockCard, CreationBlockCardTitle } from "../../../../ui-kit/cards/creation-block-card";
import { useGetFieldName, useGetFormFieldError } from "../../../../form";
import { WidgetsCalculatorFormValues } from "../../types/WidgetsCalculatorFormValues";
import { useWidgetsCalculatorAmountMutationEffect } from "../../hooks/useWidgetsCalculatorAmountMutationEffect";
import { amountFieldsEnumLike, AmountSchemaKeys } from "../../validation/widgetsCalculatorValidationSchema";
import * as S from "../grid-container/WidgetsCalculatorGridContainer.styled";
import { WidgetsCalculatorRadioButton } from "../radiobutton/WidgetsCalculatorRadioButton";
import { Text } from "../../../../ui-kit/typography";
import { Box } from "../../../../ui-kit/containers/box";
import { useWidgetsCalculatorIsAmountAvailable } from "../../hooks/useWidgetsCalculatorIsAmountAvailable";
import { StackPanel } from "../../../../ui-kit/containers/stack-panel";

const Input = S.Input.withComponent(FormInputPrice);
const BoxText = Box.withComponent(Text);

const amountSymbol: Record<AmountSchemaKeys, string> = {
  sum: "₽",
  commission: "₽",
  percent: "%",
};

export const WidgetsCalculatorFormAmountBlock = () => {
  const getFieldError = useGetFormFieldError<WidgetsCalculatorFormValues>();
  const getFieldName = useGetFieldName<WidgetsCalculatorFormValues>();

  const form = useFormikContext<WidgetsCalculatorFormValues>();
  const isAvailable = useWidgetsCalculatorIsAmountAvailable();

  useWidgetsCalculatorAmountMutationEffect();

  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <StackPanel direction="column" gap="lg">
      <Box>
        <CreationBlockCardTitle>Сумма и комиссия</CreationBlockCardTitle>
        <Text lineHeight="defaultLineHeight" fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}>
          рассчитывается исходя из количества дней
        </Text>
      </Box>
      <CreationBlockCard padding={{ xs: "md", md: "lg" }}>
        <S.Grid>
          {amountFieldsEnumLike.values.map((x, i) => (
            <Input
              index={i + 1}
              autoComplete="off"
              key={x}
              label={amountFieldsEnumLike.getTitle(x)}
              {...form.getFieldProps(getFieldName(x))}
              error={getFieldError(form, x)}
              disabled={!isAvailable || form.values.amountLocker === x}
              afterInput={
                <BoxText px="sm" fontWeight={500} fontSize="fz4" lineHeight="1.4" fontColor="medium">
                  {amountSymbol[x]}
                </BoxText>
              }
            />
          ))}
          {amountFieldsEnumLike.values.map((x, i) => (
            <S.Checker index={i + 1} key={x}>
              <WidgetsCalculatorRadioButton
                {...form.getFieldProps(getFieldName("amountLocker"))}
                value={x}
                checked={x === form.values.amountLocker}
                disabled={!isAvailable}
              />
            </S.Checker>
          ))}
        </S.Grid>
      </CreationBlockCard>
    </StackPanel>
  );
};
