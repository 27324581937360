import { useField } from "formik";
import { numberHelper } from "@sideg/helpers";
import { useGetFieldName } from "../../../form";
import { WidgetsCalculatorFormValues } from "../types/WidgetsCalculatorFormValues";

export const useWidgetsCalculatorIsAmountAvailable = () => {
  const getFieldName = useGetFieldName<WidgetsCalculatorFormValues>();
  const [{ value }] = useField<number>(getFieldName("period"));

  return numberHelper.isValidNumber(value) && value > 0;
};
