import { DemandId } from "../../../../../../common/types/demand";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { ButtonLinkWithIcon } from "../../../../../../common/ui-kit/buttons/link-with-icon";
import { RepeatIcon } from "../../../../../../common/ui-kit/icons";
import { useBankGuaranteeDetailsDemandRequestOriginal } from "../../../../hooks/demand/actions/useBankGuaranteeDetailsDemandRequestOriginal";
import { BankGuaranteeRequestOriginalCreationResponsiveView } from "../../../../../request-original";

export interface BankGuaranteeDetailsDemandHeaderRequestOriginalButtonProps extends BaseCssProps {
  demandId: DemandId;
}

export const BankGuaranteeDetailsDemandHeaderRequestOriginalButton = ({
  demandId,
  className,
}: BankGuaranteeDetailsDemandHeaderRequestOriginalButtonProps) => {
  const { openModal, isModalOpen, onSuccess, closeModal } = useBankGuaranteeDetailsDemandRequestOriginal();

  return (
    <>
      <ButtonLinkWithIcon type="button" onClick={openModal} icon={RepeatIcon} className={className}>
        Запрос оригинала
      </ButtonLinkWithIcon>
      <BankGuaranteeRequestOriginalCreationResponsiveView
        demandId={demandId}
        isOpen={isModalOpen}
        onClose={closeModal}
        onSuccess={onSuccess}
      />
    </>
  );
};
