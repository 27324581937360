import { BaseCssProps } from "../../../../common/ui-kit/types";
import { IssuingCompanyId } from "../../../../common/types/companies";
import { FileLabel } from "../../../../common/ui-kit/labels";
import { useDownloadFileApiMutation } from "../../../../common/hooks";
import { getOfferPublicUserCommon } from "../../store/publicUserCommon.thunks";

export interface PublicUserOfferFileLabelProps extends BaseCssProps {
  fileName: string;
  issuingCompanyId: IssuingCompanyId;
}

export const PublicUserOfferFileLabel = ({ fileName, issuingCompanyId, className }: PublicUserOfferFileLabelProps) => {
  const { download, status } = useDownloadFileApiMutation(getOfferPublicUserCommon, { issuingCompanyId });

  return <FileLabel fileName={fileName} download={download} status={status} className={className} />;
};
