import * as React from "react";
import { BaseCssProps } from "../../../ui-kit/types";
import { ProductDetailsLayout } from "./ProductDetailsLayout";
import { useProductDetailsAsideState } from "../../hooks/useProductDetailsAsideState";

export interface ProductDetailsLayoutWrapperProps extends BaseCssProps {
  isRootPath: boolean;
  children?: React.ReactNode;
  aside?: React.ReactNode;
}

export const ProductDetailsLayoutWrapper = React.memo(
  ({ isRootPath, aside, children, className }: ProductDetailsLayoutWrapperProps) => {
    const { isOnlyAsideShown, isAsideShown } = useProductDetailsAsideState(isRootPath);

    return (
      <ProductDetailsLayout
        aside={aside}
        className={className}
        isOnlyAsideShown={isOnlyAsideShown}
        isAsideShown={isAsideShown}
      >
        {children}
      </ProductDetailsLayout>
    );
  }
);
