import styled from "@emotion/styled";

export const FormContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.lg};
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${({ theme }) => theme.spaces.lg} 0;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    max-width: 700px;
  }
`;
