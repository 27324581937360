import { logger as domainLogger, LogLevel } from "../domains/common/logging";
import { AxiosResponse } from "axios";
import ApiResponseModel from "../entity/models/ApiResponseModel";
import { createStringFromApiResponse } from "./helpers";

export enum LogLevelEnum {
  Warning = "Warning",
  Error = "Error",
  Message = "Message",
}

const levels: Record<LogLevelEnum, LogLevel> = {
  [LogLevelEnum.Error]: "error",
  [LogLevelEnum.Message]: "info",
  [LogLevelEnum.Warning]: "warn",
};

/**
 * @deprecated Нужно использовать доменный логгер
 * @see domainLogger
 */
const logger = (logLevel: LogLevelEnum, error: unknown) => {
  domainLogger.log(levels[logLevel], error);
};

/**
 * @deprecated Не используем
 */
export const loggerForErrorApiResponse = (logLevel: LogLevelEnum, response: AxiosResponse<ApiResponseModel<any>>) => {
  const message = createStringFromApiResponse(response);
  logger(logLevel, message);
};

export default logger;
