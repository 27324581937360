export enum BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus {
  Confirmed = "Confirmed",
  AwaitingConfirmation = "AwaitingConfirmation",
  ConfirmationRejected = "ConfirmationRejected",
  ManagerConfirmation = "ManagerConfirmation",
}

export const isBankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatusWithTitle = (
  status: BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus | undefined,
): status is
  | BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.Confirmed
  | BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.ManagerConfirmation => {
  return (
    status === BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.Confirmed ||
    status === BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.ManagerConfirmation
  );
};
