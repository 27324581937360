import { DateFormats, dateTimeHelper } from "@sideg/helpers/date";
import { PatternFormat } from "react-number-format";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { ClipboardEventHandler, ComponentProps, forwardRef, useCallback, useEffect, useState } from "react";
import { useElementId } from "@sideg/ui-kit/atoms/form-controls/common/hooks";
import { useForwardedRef } from "@sideg/ui-kit/helpers/global";
import { BaseCssProps, ControlSize } from "@sideg/ui-kit/common/types";
import { dateFormInputHandleChange } from "@sideg/ui-kit/atoms/form-controls/date-input/utils/dateFormInputHandleChange";
import { handleDateFormInputPaste } from "@sideg/ui-kit/atoms/form-controls/date-input/utils/handleDateFormInputPaste";
import { DateFormInputPickerButton } from "@sideg/ui-kit/atoms/form-controls/date-input/components/DateFormInputPickerButton";

export interface DateFormInputProps
  extends Omit<
      ComponentProps<"input">,
      "type" | "min" | "max" | "onChange" | "value" | "ref" | "defaultValue" | "className" | "size"
    >,
    BaseCssProps {
  min?: Date;
  max?: Date;
  value?: string | Date | null;
  onChange?: (date: string) => void;
  label?: string;
  error?: string;
  size?: ControlSize;
}

const inputHandler = dateFormInputHandleChange;
const handlePaste = handleDateFormInputPaste;

export const DateFormInput = forwardRef<HTMLInputElement, DateFormInputProps>(
  ({ value, min, max, size = "default", onChange, label, error, id, name, className, disabled, ...rest }, ref) => {
    const innerRef = useForwardedRef(ref);

    const elementId = useElementId("input", id, name);

    const [inputValue, setInputValue] = useState("");
    const [pickerValue, setPickerValue] = useState("");

    const handleChange = useCallback((inputStringValue: string, format: DateFormats) => {
      const [pickerDate, inputCorrectValue] = inputHandler(inputStringValue, format);

      setPickerValue(pickerDate);
      setInputValue(inputCorrectValue);

      return inputCorrectValue;
    }, []);

    useEffect(() => {
      if (value !== undefined && value !== null) {
        const stringValue = typeof value === "string" ? value : dateTimeHelper.format(value, "dateOnly");
        handleChange(stringValue, "dateOnly");
      } else {
        setPickerValue("");
        setInputValue("");
      }
    }, [handleChange, value]);

    const onDateChange = (inputStringValue: string, format: DateFormats) => {
      const newValue = handleChange(inputStringValue, format);
      if (onChange) {
        onChange(newValue);
      }
    };

    const onPaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
      const handleValue = handlePaste(e.clipboardData.getData("Text"));
      if (handleValue !== undefined) {
        e.preventDefault();
        const [pastedText, format] = handleValue;
        handleChange(pastedText, format);
      }
    };

    return (
      <PatternFormat
        type="text"
        size={size}
        customInput={FormInput}
        mask="_"
        getInputRef={innerRef}
        format="##.##.####"
        inputMode="numeric"
        value={inputValue}
        name={name}
        onValueChange={(numberFormatValues) => {
          onDateChange(numberFormatValues.formattedValue, "dateOnly");
        }}
        className={className}
        id={elementId}
        onPaste={onPaste}
        label={label}
        error={error}
        disabled={disabled}
        beforeInput={
          <DateFormInputPickerButton
            size={size}
            value={pickerValue}
            min={min}
            max={max}
            disabled={disabled}
            onChange={(e) => {
              onDateChange(e.target.value, "yearMonthDayDashed");
            }}
          />
        }
        {...rest}
      />
    );
  },
);
