import { IssuingCompanyId } from "../../../../../common/types/companies";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { FileLabel } from "../../../../../common/ui-kit/labels";
import { useAppSelector } from "../../../../../common/store";
import { selectPublicUserRegistrationAgent } from "../../../store/agent/publicUserRegistrationAgent.selectors";
import { useDownloadFileApiMutation } from "../../../../../common/hooks";
import { downloadStatementPublicUserRegistration } from "../../../store/publicUserRegistration.thunks";

export interface PublicUserRegistrationAgentDocumentStatementProps extends BaseCssProps {
  issuingCompanyId: IssuingCompanyId;
}

export const PublicUserRegistrationAgentDocumentStatement = ({
  issuingCompanyId,
  className,
}: PublicUserRegistrationAgentDocumentStatementProps) => {
  const { data } = useAppSelector(selectPublicUserRegistrationAgent);
  const { status, download } = useDownloadFileApiMutation(downloadStatementPublicUserRegistration, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    agencyId: data!.agencyId,
    issuingCompanyId,
  });

  return <FileLabel fileName="Заявление на присоединение" className={className} download={download} status={status} />;
};
