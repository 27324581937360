import { BankGuaranteeId, DemandId } from "../../../../../../common/types/demand";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeReissueResponsiveView } from "../../../../../reissue";
import { useBankGuaranteeDetailsDemandReissue } from "../../../../hooks/demand/actions/useBankGuaranteeDetailsDemandReissue";
import { ButtonLinkWithIcon } from "../../../../../../common/ui-kit/buttons/link-with-icon";
import { RepeatIcon } from "../../../../../../common/ui-kit/icons";

export interface BankGuaranteeDetailsDemandHeaderReissueButtonProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
}

export const BankGuaranteeDetailsDemandHeaderReissueButton = ({
  guaranteeId,
  demandId,
  className,
}: BankGuaranteeDetailsDemandHeaderReissueButtonProps) => {
  const { openModal, isModalOpen, onSuccess, closeModal } = useBankGuaranteeDetailsDemandReissue();

  return (
    <>
      <ButtonLinkWithIcon type="button" onClick={openModal} icon={RepeatIcon} className={className}>
        Перевыпустить
      </ButtonLinkWithIcon>
      <BankGuaranteeReissueResponsiveView
        guaranteeId={guaranteeId}
        demandId={demandId}
        isOpen={isModalOpen}
        onClose={closeModal}
        onSuccess={onSuccess}
      />
    </>
  );
};
