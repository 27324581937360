import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../ui-kit/types";
import { BaseFormElementError } from "../../../../ui-kit/form-controls/common/components";
import { Box } from "../../../../ui-kit/containers/box";
import { ButtonLoading, ButtonLoadingProps } from "../../../../ui-kit/form-controls/button";

export interface CreationSubmitButtonProps
  extends BaseCssProps,
    Omit<ButtonLoadingProps, "className" | "ref" | "variant" | "color" | "size" | "isFullWidth" | "alignSelf"> {
  error?: string;
}

export const CreationSubmitButton = ({ error, className, ...buttonProps }: CreationSubmitButtonProps) => {
  const isDesktop = useThemeIsDesktop();

  return (
    <Box display="flex" flexDirection="column" gap="xs" className={className}>
      <ButtonLoading
        loaderPosition="replace"
        alignSelf="flex-start"
        isFullWidth={!isDesktop}
        size="medium"
        variant="contained"
        color="primary"
        {...buttonProps}
      />
      <BaseFormElementError error={error} />
    </Box>
  );
};
