import { memo } from "react";
import { useAppSelector } from "../../../../../common/store";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  selectCreditExecutionDetailsActiveDemand,
  selectCreditExecutionDetailsActiveDemandDocuments,
} from "../../../store/active-demand/creditExecutionDetailsActiveDemand.selectors";
import { useCreditExecutionDetailsDemandDocumentsFetch } from "../../../hooks/useCreditExecutionDetailsDemandDocumentsFetch";
import { ProductDetailsDemandActualDocumentsList } from "../../../../../common/product-details";

export type CreditExecutionDetailsDemandActualDocumentsListProps = BaseCssProps;

export const CreditExecutionDetailsDemandActualDocumentsList = memo(
  ({ className }: CreditExecutionDetailsDemandActualDocumentsListProps) => {
    const {
      documents: { status },
    } = useAppSelector(selectCreditExecutionDetailsActiveDemand);

    const documents = useAppSelector(selectCreditExecutionDetailsActiveDemandDocuments);

    useCreditExecutionDetailsDemandDocumentsFetch();

    return (
      <ProductDetailsDemandActualDocumentsList loadingStatus={status} documents={documents} className={className} />
    );
  }
);
