import styled from "@emotion/styled";
import { AvatarInitial } from "../../../../common/ui-kit/avatar/AvatarInitial";
import { SelectPopupContainerItem } from "../../../../common/ui-kit/form-controls/select";
import { NavigationPopUpContainer } from "../../../../common/ui/navigation/components/NavigationPopUpContainer";

export const UserAvatarInitial = styled(AvatarInitial)``;

export const UserButton = styled.button<{ isActive: boolean; isNewManager: boolean }>`
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  transition: opacity 0.2s linear;
  padding: 0;

  &:hover {
    & > ${UserAvatarInitial} {
      outline: 4px solid ${({ theme }) => theme.colors.primary};
    }
  }

  &:focus {
    & > ${UserAvatarInitial} {
      outline: 4px solid ${({ theme }) => theme.layout.header.button.activeBackgroundColor};
    }
  }

  & > ${UserAvatarInitial} {
    outline: 4px solid ${({ isActive, theme }) =>
      isActive ? theme.layout.header.button.activeBackgroundColor : theme.layout.header.backgroundColor};

    ::after {
      content: "";
      display: ${({ isNewManager }) => (isNewManager ? "block" : "none")};
      position: absolute;
      top: -6px;
      left: 70%;
      background-color: ${({ theme }) => theme.secondaryColors.notificationBackground};
      border-radius: 50%;
      border: 2px solid ${({ theme }) => theme.layout.header.backgroundColor};
      width: 9px;
      height: 9px;
      font-family: ${({ theme }) => theme.font.fontFamily};
    }
  }
}
`;

export const UserMenuItemsWrapper = styled(NavigationPopUpContainer.withComponent("ul"))`
  width: 280px;
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${({ theme }) => theme.spaces.lg} 0;
`;

export const UserMenuItem = styled(SelectPopupContainerItem)`
  padding-left: ${({ theme }) => theme.spaces.lg};
  font-size: ${({ theme }) => theme.font.fz3};
  gap: 8px;

  &:not(:first-of-type) {
    border-top: none;
  }

  & > svg {
    width: 24px;
    height: 24px;

    & > path {
      stroke: ${({ theme }) => theme.palette.typography.primary};
    }
  }
`;

export const UserMenuItemWithDetails = styled.li`
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  padding: ${({ theme }) => theme.spaces.lg};
  padding-bottom: 0;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 24px;
    height: 24px;

    & > path {
      stroke: #000000;
    }
  }
`;
