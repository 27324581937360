import { dateTimeHelper, currencyHelper } from "@sideg/helpers";
import { useThemeDesktopBreakpoint, useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsReissueById } from "../../../store/reissues/BankGuaranteeDetailsReissues.selectors";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { Text } from "../../../../../common/ui-kit/typography";
import { Grid } from "../../../../../common/ui-kit/containers/responsive-grid";
import { BankGuaranteeDetailsIssueDetails } from "../../issue-details/BankGuaranteeDetailsIssueDetails";
import { BankGuaranteeDetailsIssueDetailsItem } from "../../issue-details/BankGuaranteeDetailsIssueDetailsItem";
import { FileDownloadLabel } from "../../../../../files/download";
import { ProductDetailsDemandRoundedCard } from "../../../../../common/product-details";

export interface BankGuaranteeDetailsDemandReissueProps extends BaseCssProps {
  id: number;
}

export const BankGuaranteeDetailsDemandReissue = ({ id }: BankGuaranteeDetailsDemandReissueProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const reissue = useAppSelector((state) => selectBankGuaranteeDetailsReissueById(state, id))!;

  const desktopBreakpoint = useThemeDesktopBreakpoint();
  const isDesktop = useThemeIsDesktop();

  return (
    <StackPanel as={ProductDetailsDemandRoundedCard} direction="column" gap="lg">
      <Text fontSize="fz2" lineHeight="defaultLineHeight" fontWeight={700} fontColor="colors.dark">
        {dateTimeHelper.format(new Date(reissue.dateCreate), "dayShortMonthYear")}
        {reissue.dateComplete && ` — ${dateTimeHelper.format(new Date(reissue.dateComplete), "dayShortMonthYear")}`}
      </Text>
      <Grid container columnGap="lg">
        <Grid item xs={12} {...{ [desktopBreakpoint]: 6 }}>
          <BankGuaranteeDetailsIssueDetails>
            <BankGuaranteeDetailsIssueDetailsItem term="Статус" details={reissue.status} />
            <BankGuaranteeDetailsIssueDetailsItem term="Причина" details={reissue.reason} />
            {reissue.issueDate && (
              <BankGuaranteeDetailsIssueDetailsItem
                term="Дата выпуска"
                details={dateTimeHelper.format(new Date(reissue.issueDate), "dateOnly")}
              />
            )}
          </BankGuaranteeDetailsIssueDetails>
        </Grid>
        <Grid item xs={12} {...{ [desktopBreakpoint]: 6 }}>
          {reissue.costAgent !== undefined && (
            <BankGuaranteeDetailsIssueDetailsItem
              term="Базовая стоимость"
              details={currencyHelper.getCurrencyString(reissue.costAgent)}
            />
          )}
          {reissue.costFact !== undefined && (
            <BankGuaranteeDetailsIssueDetailsItem
              term={isDesktop ? "Стоимость фактическая" : "Стоимость факт."}
              details={currencyHelper.getCurrencyString(reissue.costFact)}
            />
          )}
        </Grid>
      </Grid>
      {(reissue.bankDocument || reissue.project || reissue.original) && (
        <StackPanel gap="sm" direction="column" alignItems="flex-start">
          {reissue.bankDocument && (
            <FileDownloadLabel
              fileId={reissue.bankDocument.fileId}
              privateKey={reissue.bankDocument.privateKey}
              fileName="Доверенность/Лицензия"
            />
          )}
          {reissue.original && (
            <FileDownloadLabel
              fileId={reissue.original.fileId}
              privateKey={reissue.original.privateKey}
              fileName="Скан-копия гарантии"
            />
          )}
        </StackPanel>
      )}
    </StackPanel>
  );
};
