import { FormInput, FormInputProps } from "@sideg/ui-kit/atoms/form-controls/input";
import { forwardRef, useState } from "react";
import * as S from "@sideg/ui-kit/atoms/form-controls/password-input/components/FormInputPassword.styled";
import { VisibilityIcon, VisibilityOffIcon } from "@sideg/ui-kit/atoms/icons";

export interface FormInputPasswordProps extends Omit<FormInputProps, "afterInput" | "type"> {}

export const FormInputPassword = forwardRef<HTMLInputElement, FormInputPasswordProps>(({ disabled, ...rest }, ref) => {
  const [isIconVisible, setIsIconVisible] = useState(false);

  const onClick = () => {
    setIsIconVisible((prev) => !prev);
  };

  return (
    <FormInput
      type={isIconVisible ? "text" : "password"}
      disabled={disabled}
      spellCheck="false"
      afterInput={
        <S.IconWrapper type="button" onClick={onClick} title="Показать пароль">
          {isIconVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </S.IconWrapper>
      }
      {...rest}
      ref={ref}
    />
  );
});
