import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { getPublicUserOffers, PUBLIC_USER_OFFERS_SLICE_NAME } from "./publicUserOffers.thunks";
import { PublicUserOffersSliceState, PublicUserOffersState } from "./publicUserOffers.types";
import { publicUserOffersAdapter } from "./publicUserOffers.adapter";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";

const initialState: PublicUserOffersState = publicUserOffersAdapter.getInitialState<PublicUserOffersSliceState>({
  status: LoadingStatusEnum.Idle,
});

const slice = createSlice({
  name: PUBLIC_USER_OFFERS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublicUserOffers.fulfilled, (state, action) => {
        publicUserOffersAdapter.setAll(state, action.payload.body.offers);
      })
      .addMatcher(isAsyncThunkAction(getPublicUserOffers), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const publicUserOffersReducer = slice.reducer;
