import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../../common/types/utils";

const titles = {
  details: "Данные заявки",
  scoring: "Выбор банков",
};

export const creditWorkingAssetsCreationCreateByExistsPageType = makeEnumLikeLiteral(
  ["details", "scoring"] as const,
  titles
);

export type CreditWorkingAssetsCreationCreateByExistsPageType = EnumLikeLiteralType<
  typeof creditWorkingAssetsCreationCreateByExistsPageType
>;
