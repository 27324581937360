import { useState } from "react";
import { useFormikContext } from "formik";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { PhoneNumberInput } from "@sideg/ui-kit/atoms/form-controls/phone-number-input";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { FormResponsiveRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox/components/radio-group/FormResponsiveRadioButtonGroup";
import {
  MaxHeightAppearanceTransition,
  SimpleOpacityTransition,
} from "../../../../../common/ui-kit/animations/transitions";
import { CreationBlockCard, CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { requestOriginalCreationFieldMaxLength } from "../../../validation/bankGuaranteeRequestOriginalCreationFormValidationSchema";
import { useGetFieldName, useGetFormFieldError } from "../../../../../common/form";
import { BankGuaranteeRequestOriginalCreationFormValues } from "../../../types/BankGuaranteeRequestOriginalCreationFormValues";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { deliveryTypeRules } from "../../../validation/rules/deliveryTypeRules";
import {
  bankGuaranteeRequestOriginalDeliveryType,
  BankGuaranteeRequestOriginalDeliveryType,
  bankGuaranteeRequestOriginalOfficeRecipientType,
} from "../../../../common/types";
import { useApiMutation } from "../../../../../common/hooks";
import { getRequestOriginalDeliveryCompaniesList } from "../../../store/bankGuaranteeRequestOriginalCreation.thunks";
import { DemandId } from "../../../../../common/types/demand";
import { CreationNote } from "../../../../../common/ui-kit/notifications/note";
import { Grid } from "../../../../../common/ui-kit/containers/responsive-grid";

export interface BankGuaranteeRequestOriginalCreationFormContentProps extends BaseCssProps {
  demandId: DemandId;
}

export const BankGuaranteeRequestOriginalCreationFormContent = ({
  className,
  demandId,
}: BankGuaranteeRequestOriginalCreationFormContentProps) => {
  const [deliveryTypes, setDeliveryTypes] = useState<string[] | undefined>(undefined);
  const form = useFormikContext<BankGuaranteeRequestOriginalCreationFormValues>();
  const desktopBreakpoint = useThemeDesktopBreakpoint();
  const getFieldError = useGetFormFieldError<BankGuaranteeRequestOriginalCreationFormValues>();
  const getFieldName = useGetFieldName<BankGuaranteeRequestOriginalCreationFormValues>();

  const { mutate: getDeliveryCompaniesListMutate } = useApiMutation(getRequestOriginalDeliveryCompaniesList, {
    errorMessagePlaceholder: "",
  });

  const onDeliveryTypeChange = async (newValue: BankGuaranteeRequestOriginalDeliveryType) => {
    form.setFieldValue(getFieldName("deliveryType"), newValue, true);
    if (deliveryTypes === undefined && newValue === "Delivery") {
      const result = await getDeliveryCompaniesListMutate(demandId);
      setDeliveryTypes(result?.body);
    }
  };

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} gap="lg" className={className}>
      <CreationBlockCard padding={{ xs: "md", md: "lg" }}>
        <StackPanel gap="md" direction="column">
          <FormResponsiveRadioButtonGroup
            value={form.values.deliveryType}
            onChange={onDeliveryTypeChange}
            onBlur={() => {
              form.setFieldTouched(getFieldName("deliveryType"), true, true);
            }}
            label="Способ получения оригинала"
            itemsPlacement="start"
            radioSize="default"
            types={bankGuaranteeRequestOriginalDeliveryType.values}
            name={getFieldName("deliveryType")}
            getTitle={bankGuaranteeRequestOriginalDeliveryType.getTitle}
            disabled={form.isSubmitting}
          />
          <SimpleOpacityTransition
            timeout={0}
            in={
              deliveryTypeRules.isDelivery(form.values.deliveryType) &&
              deliveryTypes !== undefined &&
              deliveryTypes.length > 0
            }
            unmountOnExit
            mountOnEnter
          >
            <CreationNote fontSize="fz3">
              {deliveryTypes !== undefined && deliveryTypes.length > 0 && (
                <>
                  {deliveryTypes.length === 1 && `Банк отправляет службой «${deliveryTypes[0]}»`}
                  {deliveryTypes.length > 1 &&
                    `Банк отправляет в зависимости от адреса доставки службами ${deliveryTypes
                      .map((x) => `«${x}»`)
                      .join(", ")}`}
                </>
              )}
            </CreationNote>
          </SimpleOpacityTransition>
          <SimpleOpacityTransition
            timeout={0}
            in={!deliveryTypeRules.isDelivery(form.values.deliveryType)}
            unmountOnExit
            mountOnEnter
          >
            <FormResponsiveRadioButtonGroup
              value={form.values.officeRecipientType}
              onChange={(newValue) => {
                form.setFieldValue(getFieldName("officeRecipientType"), newValue, true);
              }}
              onBlur={() => {
                form.setFieldTouched(getFieldName("officeRecipientType"), true, true);
              }}
              label="За оригиналом приедет"
              itemsPlacement="start"
              radioSize="default"
              types={bankGuaranteeRequestOriginalOfficeRecipientType.values}
              name={getFieldName("officeRecipientType")}
              getTitle={bankGuaranteeRequestOriginalOfficeRecipientType.getTitle}
              disabled={form.isSubmitting}
            />
          </SimpleOpacityTransition>
          <FormTextarea
            label="Комментарий"
            minRows={2}
            {...form.getFieldProps(getFieldName("comment"))}
            error={getFieldError(form, "comment")}
            autoComplete="off"
            disabled={form.isSubmitting}
          />
        </StackPanel>
      </CreationBlockCard>
      <MaxHeightAppearanceTransition
        maxHeightEnter="0px"
        maxHeightExit="1000px"
        timeoutEnter={300}
        timeoutExit={300}
        timeout={250}
        in={deliveryTypeRules.isDelivery(form.values.deliveryType)}
        unmountOnExit
        mountOnEnter
      >
        <StackPanel direction="column" gap="lg">
          <CreationBlockWithTitle title="Контактное лицо">
            <StackPanel gap="md" direction="column">
              <Grid container columnGap="xs" rowGap="md">
                <Grid
                  item
                  as={FormInput}
                  xs={12}
                  {...{ [desktopBreakpoint]: 4 }}
                  type="text"
                  label="Фамилия"
                  error={getFieldError(form, "surname")}
                  spellCheck={false}
                  autoComplete="on"
                  autoCorrect="off"
                  disabled={form.isSubmitting}
                  {...form.getFieldProps(getFieldName("surname"))}
                />
                <Grid
                  item
                  as={FormInput}
                  xs={12}
                  {...{ [desktopBreakpoint]: 4 }}
                  type="text"
                  label="Имя"
                  error={getFieldError(form, "name")}
                  spellCheck={false}
                  autoComplete="on"
                  autoCorrect="off"
                  disabled={form.isSubmitting}
                  {...form.getFieldProps(getFieldName("name"))}
                />
                <Grid
                  item
                  as={FormInput}
                  xs={12}
                  {...{ [desktopBreakpoint]: 4 }}
                  type="text"
                  label="Отчество"
                  error={getFieldError(form, "patronymic")}
                  spellCheck={false}
                  autoComplete="on"
                  autoCorrect="off"
                  disabled={form.isSubmitting}
                  {...form.getFieldProps(getFieldName("patronymic"))}
                />
              </Grid>
              <StackPanel
                gap={{ xs: "md", [desktopBreakpoint]: "xs" }}
                direction={{ xs: "column", [desktopBreakpoint]: "row" }}
              >
                <PhoneNumberInput
                  {...form.getFieldProps(getFieldName("phone"))}
                  label="Телефон"
                  error={getFieldError(form, "phone")}
                  disabled={form.isSubmitting}
                />
                <FormInput
                  type="email"
                  label="Электронная почта"
                  error={getFieldError(form, "email")}
                  spellCheck={false}
                  autoComplete="off"
                  autoCorrect="off"
                  disabled={form.isSubmitting}
                  maxLength={requestOriginalCreationFieldMaxLength.emailContact}
                  {...form.getFieldProps(getFieldName("email"))}
                />
              </StackPanel>
            </StackPanel>
          </CreationBlockWithTitle>
          <CreationBlockWithTitle title="Получатель (юридическое лицо)">
            <StackPanel gap="md" direction="column">
              <FormInput
                type="text"
                label="Наименование"
                error={getFieldError(form, "recipient")}
                spellCheck={false}
                autoComplete="off"
                autoCorrect="off"
                disabled={form.isSubmitting}
                {...form.getFieldProps(getFieldName("recipient"))}
              />
              <FormInput
                type="text"
                label="Полный адрес"
                error={getFieldError(form, "address")}
                spellCheck={false}
                autoComplete="off"
                autoCorrect="off"
                disabled={form.isSubmitting}
                maxLength={requestOriginalCreationFieldMaxLength.destination}
                {...form.getFieldProps(getFieldName("address"))}
              />
            </StackPanel>
          </CreationBlockWithTitle>
        </StackPanel>
      </MaxHeightAppearanceTransition>
    </Box>
  );
};
