import { checkNeverType } from "@sideg/helpers";
import { ElementColor } from "../../../types";
import { BadgeVariant } from "../types/BadgeVariant";

export const getBadgeBackgroundColor = (variant: BadgeVariant): ElementColor => {
  switch (variant) {
    case "primary":
      return "info";
    case "info":
      return "secondaryLight";
    case "danger":
      return "dangerLight";
    case "success":
      return "status.successBackground";
    case "warning":
      return "status.warningBackground";
    default:
      return checkNeverType(variant);
  }
};
