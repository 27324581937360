import { BankGuaranteeCreationGuaranteeTypeBlock } from "./blocks/BankGuaranteeCreationGuaranteeTypeBlock";
import { BankGuaranteeCreationContractBlock } from "./blocks/BankGuaranteeCreationContractBlock";
import { BankGuaranteeCreationBeneficiaryBlock } from "./blocks/BankGuaranteeCreationBeneficiaryBlock";
import { BankGuaranteeCreationCostBlock } from "./blocks/BankGuaranteeCreationCostBlock";
import { BankGuaranteeCreationAuctionBlock } from "./blocks/BankGuaranteeCreationAuctionBlock";
import { BankGuaranteeCreationTermBlock } from "./blocks/BankGuaranteeCreationTermBlock";
import { useAppSelector } from "../../../../common/store";
import { isLoading } from "../../../../common/types/state";
import {
  selectBankGuaranteeCreationClientSearch,
  selectBankGuaranteeCreationCreating,
} from "../../store/bankGuaranteeCreation.selectors";
import { useBankGuaranteeCreationForm } from "../../hooks/creation/useBankGuaranteeCreationForm";
import { useBankGuaranteeCreationCreatingFormStage } from "../../hooks/creation/useBankGuaranteeCreationCreatingFormStage";
import { useBankGuaranteeCreationFormIsPurchaseFetching } from "../../hooks/creation/purchase/useBankGuaranteeCreationFormIsPurchaseFetching";
import { FullPageContainerCenteredMainWithFooter } from "../../../../common/ui/containers/full-page";
import { BankGuaranteeCreationFromValues } from "../../types/BankGuaranteeCreationFormValues";
import { CreationSubmitButton } from "../../../../common/ui/buttons/creation-submit-button";
import { useCreationFormFieldTouchedEffect } from "../../../../common/form";
import { ClientSearchCreationClientBlock } from "../../../../client-search";
import { BankGuaranteeCreationIsNeedCreditBlock } from "./blocks/BankGuaranteeCreationIsNeedCreditBlock";
import { BankGuaranteeCreationIsClientSignerField } from "./fields/BankGuaranteeCreationIsClientSignerField";

export const BankGuaranteeCreationFormContent = () => {
  const stage = useBankGuaranteeCreationCreatingFormStage();
  const { error, status } = useAppSelector(selectBankGuaranteeCreationCreating);

  const form = useBankGuaranteeCreationForm();

  const isPurchaseFetching = useBankGuaranteeCreationFormIsPurchaseFetching();

  useCreationFormFieldTouchedEffect();

  return (
    <FullPageContainerCenteredMainWithFooter
      footer={
        stage === "contract" && (
          <CreationSubmitButton type="submit" disabled={form.isSubmitting} isLoading={isLoading(status)} error={error}>
            Перейти к выбору банков
          </CreationSubmitButton>
        )
      }
    >
      <ClientSearchCreationClientBlock<BankGuaranteeCreationFromValues>
        clientStateSelector={selectBankGuaranteeCreationClientSearch}
        fixedKey="BankGuarantee"
        isDisabled={form.isSubmitting}
      >
        <BankGuaranteeCreationIsClientSignerField />
      </ClientSearchCreationClientBlock>
      {stage !== "client" && <BankGuaranteeCreationGuaranteeTypeBlock isDisabled={form.isSubmitting} />}
      {stage === "contract" && (
        <>
          <BankGuaranteeCreationContractBlock
            isDisabled={isPurchaseFetching || form.isSubmitting}
            isPurchaseNumberDisabled={form.isSubmitting}
          />
          <BankGuaranteeCreationBeneficiaryBlock isDisabled={isPurchaseFetching || form.isSubmitting} />
          <BankGuaranteeCreationCostBlock isDisabled={isPurchaseFetching || form.isSubmitting} />
          <BankGuaranteeCreationAuctionBlock isDisabled={isPurchaseFetching || form.isSubmitting} />
          <BankGuaranteeCreationTermBlock isDisabled={isPurchaseFetching || form.isSubmitting} />
          <BankGuaranteeCreationIsNeedCreditBlock isDisabled={isPurchaseFetching || form.isSubmitting} />
        </>
      )}
    </FullPageContainerCenteredMainWithFooter>
  );
};
