import { useListTrimMoreThan } from "../../../../../../../common/hooks";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import { useAppSelector } from "../../../../../../../common/store";
import { CreditExecutionId } from "../../../../../../../common/types/demand";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import {
  ProductListCardDemandsListContainerDesktop,
  ProductListCardMoreActionsButton,
  ProductListCardShowMoreButtonDesktop,
  ProductListCardTasksCountBadgeDesktop,
} from "../../../../../../../common/ui/product/list/card";
import { useCreditExecutionListItemMoreActions } from "../../../../../hooks/useCreditExecutionListItemMoreActions";
import { selectCreditExecutionListItemsById } from "../../../../../store/items/creditExecutionListItems.selectors";
import { CreditExecutionListItemDemandsListDesktop } from "./list/CreditExecutionListItemDemandsList.desktop";
import { CreditExecutionListItemStageCounters } from "../../../counters/CreditExecutionListItemStageCounters";
import { declensionHelper } from "../../../../../../../common/helpers/entity";

export interface CreditExecutionListItemDemandsDesktopProps extends BaseCssProps {
  creditExecutionId: CreditExecutionId;
  from: NavigationState;
}

export const CreditExecutionListItemDemandsDesktop = ({
  className,
  creditExecutionId,
  from,
}: CreditExecutionListItemDemandsDesktopProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const item = useAppSelector((state) => selectCreditExecutionListItemsById(state, creditExecutionId))!;
  const { items, isShown, toggleIsShown, needTrim } = useListTrimMoreThan(item.demandIds, 3);

  const actions = useCreditExecutionListItemMoreActions(item, from);

  return (
    <ProductListCardDemandsListContainerDesktop
      className={className}
      banksCountTitle={declensionHelper.commonDeclensions.bank.nominative(item.demandIds.length, true)}
      counters={<CreditExecutionListItemStageCounters counters={item.counters} />}
      activeTasksCountBadge={
        item.activeTasksCount > 0 && <ProductListCardTasksCountBadgeDesktop count={item.activeTasksCount} />
      }
      actionsButton={actions.length > 0 && <ProductListCardMoreActionsButton availableActions={actions} />}
      footer={
        needTrim && (
          <Box pt="sm">
            <ProductListCardShowMoreButtonDesktop isShow={!isShown} onClick={toggleIsShown} />
          </Box>
        )
      }
    >
      <CreditExecutionListItemDemandsListDesktop creditExecutionId={creditExecutionId} demandIds={items} from={from} />
    </ProductListCardDemandsListContainerDesktop>
  );
};
