import { useEffect, useLayoutEffect } from "react";
import { useAppLayout } from "./useAppLayout";
import { appLayoutConfigs } from "../../app-layout/configs";

export const useFullPageLayout = () => {
  const {
    isFullSize: { setValue },
  } = useAppLayout();

  useEffect(() => {
    document.body.classList.add(appLayoutConfigs.fullPageBodyClassName);

    return () => {
      document.body.classList.remove(appLayoutConfigs.fullPageBodyClassName);
    };
  }, []);

  useEffect(() => {
    setValue(true);
  }, [setValue]);

  useLayoutEffect(() => {
    return () => setValue(false);
  }, [setValue]);
};
