import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xxl};
  min-height: 2rem;
  justify-content: flex-end;
  transition: min-height 0.15s 0.15s ease;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.lg};
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - ${({ theme }) => theme.spaces.lg});

    ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
      flex-grow: unset;
      flex-basis: auto;
    }
  }
`;
