import { getEmptyArray } from "@sideg/helpers";
import { LoadingStatusEnum, isLoading } from "../../../types/state";

const AVERAGE_DEMANDS_COUNT = 3;

export const getProductDetailsAsideNavigationCardSkeletonItems = (
  status: LoadingStatusEnum,
  isTransferred: boolean,
  loadedDemandsCount: number,
  averageDemandsCount: number = AVERAGE_DEMANDS_COUNT,
): number[] => {
  if (!isLoading(status)) {
    return [];
  }

  if (isTransferred) {
    return getEmptyArray(Math.max(averageDemandsCount - loadedDemandsCount, 1));
  }

  if (loadedDemandsCount === 0) {
    return getEmptyArray(averageDemandsCount);
  }

  return [];
};
