import { memo } from "react";
import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeListTabFilter } from "./tabs/BankGuaranteeListTabFilter";
import { BankGuaranteeListFilters } from "./filters/BankGuaranteeListFilters";
import { BankGuaranteeListFiltersBadges } from "./filters/BankGuaranteeListFiltersBadges";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeListFilters } from "../../store/filters/bankGuaranteeListFilters.selectors";
import { useCreateNavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { ProductListCreationButton } from "../../../../common/ui/product/list/creation-button";
import { FilterBadgesListEmpty } from "../../../../common/ui-kit/filters/badges";
import { getAvailableFiltersBankGuaranteeList } from "../../store/bankGuaranteeList.thunks";
import { useFirstRenderThunkDispatch } from "../../../../common/hooks";

export const BankGuaranteeListHeader = memo(() => {
  const state = useCreateNavigationStateFrom("Мои заявки");
  const { data, status } = useAppSelector(selectBankGuaranteeListFilters);

  useFirstRenderThunkDispatch(status, getAvailableFiltersBankGuaranteeList);

  return (
    <AppLayoutResponsiveListPage.Header>
      <StackPanel gap="md" direction="row" justifyContent="space-between">
        <AppLayoutResponsiveListPage.Title>Мои заявки</AppLayoutResponsiveListPage.Title>
        <ProductListCreationButton to={(x) => x.bankGuarantee.creation.create} state={state} />
      </StackPanel>
      <BankGuaranteeListFilters />
      {!data && <FilterBadgesListEmpty />}
      {!!data && <BankGuaranteeListFiltersBadges />}
      <AppLayoutResponsiveListPage.TabsContainer>
        <BankGuaranteeListTabFilter />
      </AppLayoutResponsiveListPage.TabsContainer>
    </AppLayoutResponsiveListPage.Header>
  );
});
