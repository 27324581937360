import { mixed, object, string } from "yup";
import { getEnumLikeLiteralTypeSchema } from "../../../common/validation";
import { bankGuaranteeReissueType } from "../types/BankGuaranteeReissueType";

export const bankGuaranteeReissueFormValidationSchema = object({
  reissueReason: getEnumLikeLiteralTypeSchema(bankGuaranteeReissueType)
    .transform((value: unknown, originalValue: unknown) => {
      if (!Object.is(NaN, Number(originalValue))) {
        return Number(originalValue);
      }

      return value;
    })
    .default(1)
    .required("Не забудьте заполнить"),
  file: mixed().required("Необходимо прикрепить файл"),
  comment: string().required("Укажите детали перевыпуска").default(""),
});
