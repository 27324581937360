import { css, CSSObject, SerializedStyles } from "@emotion/react";
import { Theme } from "@sideg/ui-kit/core/theme/types/Theme";
import { breakpoints, Breakpoints } from "@sideg/ui-kit/common/types/responsive/Breakpoints";
import {
  AnyResponsiveStyleValue,
  CssStyleBuilder,
  ExtractResponsiveStyleValue,
  isResponsiveValue,
  ResponsiveStyleValue,
} from "@sideg/ui-kit/common/types";
import { FilteredKeys } from "@sideg/helpers/types";
import { isObjectEmpty } from "@sideg/helpers";

type StyleBuilder<TValue> = (value: TValue, theme: Theme, breakpoint?: Breakpoints) => CSSObject;

const isResponsiveValueIsEmptyObject = (value: unknown): value is object => {
  return typeof value === "object" && value !== null && isObjectEmpty(value);
};

export const makeResponsiveStyles = <TValue>(
  theme: Theme,
  value: ResponsiveStyleValue<TValue>,
  builder: StyleBuilder<TValue>,
): SerializedStyles => {
  if (!isResponsiveValue(value)) {
    return css(isResponsiveValueIsEmptyObject(value) ? undefined : builder(value, theme));
  }

  const breakpointsTemplates: CSSObject[] = breakpoints.map((breakpoint) => {
    const breakpointValue = value[breakpoint];
    if (breakpointValue === undefined || isResponsiveValueIsEmptyObject(breakpointValue)) {
      return {};
    }

    return {
      [`${theme.breakpoints.mqUp(breakpoint)}`]: builder(breakpointValue, theme, breakpoint),
    };
  });

  return css(breakpointsTemplates);
};

export const makeResponsiveStylesBuilder =
  <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TProps extends Record<string, any>,
  >() =>
  <TProp extends FilteredKeys<TProps, AnyResponsiveStyleValue>, TValue extends TProps[TProp] = TProps[TProp]>(
    key: TProp,
    builder: StyleBuilder<ExtractResponsiveStyleValue<TValue>> = (value) => ({ [key]: value }),
  ): CssStyleBuilder<TProps> => {
    return (props) => {
      return makeResponsiveStyles<TValue>(props.theme, props[key], builder);
    };
  };
