import { useState } from "react";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { CreditExecutionCreationCreateByExistsUrlParams } from "../types/creation/CreditExecutionCreationCreateByExistsUrlParams";
import { withValidUrlParams } from "../../../common/urls/internal/HOCs";
import { creditExecutionCreationCreateByExistsUrlValidationSchema } from "../validation/creditExecutionCreationCreateByExistsUrlValidationSchema";
import { Redirect } from "../../../common/urls/internal/components";
import {
  ProductCreationCreateByExistsBriefContainer,
  ProductCreationCreateByExistsMainContentWrapper,
} from "../../../common/product-creation";
import { FullPageContainerMainContentWrapper } from "../../../common/ui/containers/full-page";
import { CreditExecutionCreationCreateByExistsPageType } from "../types/creation/CreditExecutionCreationCreateByExistsPageType";
import { isLoading } from "../../../common/types/state";
import { CreditExecutionCreationCreateByExistsHeader } from "../components/header/create-by-exists/CreditExecutionCreationCreateByExistsHeader";
import { useCreditExecutionBriefData } from "../../common/brief/hooks/useCreditExecutionBriefData";
import { CreditExecutionCreationScoringCreateByExistsBrief } from "../components/scoring/create-by-exists/CreditExecutionCreationScoringCreateByExistsBrief";
import { CreditExecutionCreationScoringCreateByExistsForm } from "../components/scoring/create-by-exists/CreditExecutionCreationScoringCreateByExistsForm";

export interface CreditExecutionCreationCreateByExistsPageProps {
  match: CreditExecutionCreationCreateByExistsUrlParams;
}

const Page = ({ match }: CreditExecutionCreationCreateByExistsPageProps) => {
  const [activePageType, setActivePageType] = useState<CreditExecutionCreationCreateByExistsPageType>("scoring");
  const { data: brief, status: briefStatus } = useCreditExecutionBriefData(match.creditExecutionId);

  return (
    <>
      <CreditExecutionCreationCreateByExistsHeader onPageTypeClick={setActivePageType} activePage={activePageType} />
      <FullPageContainerMainContentWrapper>
        <ProductCreationCreateByExistsMainContentWrapper>
          <ThemeMobileDesktopView
            mobile={
              <>
                {activePageType === "details" && (
                  <CreditExecutionCreationScoringCreateByExistsBrief isLoading={isLoading(briefStatus)} data={brief} />
                )}
                {activePageType === "scoring" && (
                  <CreditExecutionCreationScoringCreateByExistsForm
                    creditExecutionId={match.creditExecutionId}
                    creditSum={brief?.contractSum}
                  />
                )}
              </>
            }
            desktop={
              <>
                <CreditExecutionCreationScoringCreateByExistsForm
                  creditExecutionId={match.creditExecutionId}
                  creditSum={brief?.contractSum}
                />
                <ProductCreationCreateByExistsBriefContainer>
                  <CreditExecutionCreationScoringCreateByExistsBrief isLoading={isLoading(briefStatus)} data={brief} />
                </ProductCreationCreateByExistsBriefContainer>
              </>
            }
          />
        </ProductCreationCreateByExistsMainContentWrapper>
      </FullPageContainerMainContentWrapper>
    </>
  );
};

export const CreditExecutionCreationCreateByExistsPage = withValidUrlParams(
  Page,
  (x) => x.creditExecution.creation.createByCreditExecution,
  creditExecutionCreationCreateByExistsUrlValidationSchema,
  <Redirect to={(x) => x.creditExecution.list.root} />,
);
