import { CreationProductCard } from "./CreationProductCard";
import { useNavigationStateFrom } from "../../../common/navigation/navigation-state";
import { Grid } from "../../../common/ui-kit/containers/responsive-grid";
import { useUserCreationProducts } from "../../../common/app-products";
import * as S from "./CreationProductsTable.styled";

export const CreationProductsTable = () => {
  const state = useNavigationStateFrom();
  const creationItems = useUserCreationProducts();

  return (
    <S.Container>
      <Grid container gap="xxl">
        {creationItems.map((x) => (
          <Grid item xs={12} md={6} xl={4} key={x.url.url}>
            <CreationProductCard title={x.title} description={x.description} link={x.url.url} from={state?.from} />
          </Grid>
        ))}
      </Grid>
    </S.Container>
  );
};
