import { useCallback } from "react";
import { LegalServicesProductId } from "../../../common/types";
import { FormSubmitHandler } from "../../../../common/form";
import {
  LegalServicesCreationDocumentFormValue,
  LegalServicesCreationFormValues,
} from "../../types/LegalServicesCreationFormValues";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { useApiMutationAction } from "../../../../common/hooks";
import { legalServicesCreationValidationSchema } from "../../validation/legalServicesCreationValidationSchema";
import { legalServicesCreationThunks } from "../../store/legalServicesCreation.thunks";
import { isBaseApiError } from "../../../../common/api/types";
import { pushErrorGlobalToast } from "../../../../global/toasts";
import { FileTemp } from "../../../../files/temp";
import { SendLegalServicesCreationDocumentInputDto } from "../../api/dto/input/SendLegalServicesCreationInputDto";
import {
  selectLegalServicesCreationById,
  selectLegalServicesCreationClientSearch,
} from "../../store/legalServicesCreation.selectors";

const isDocumentWithFile = (
  document: LegalServicesCreationDocumentFormValue,
): document is LegalServicesCreationDocumentFormValue & { files: FileTemp[] } => {
  return document.files !== undefined && document.files.length > 0;
};

const mapDocuments = (
  documents: LegalServicesCreationFormValues["documents"] | undefined,
): SendLegalServicesCreationDocumentInputDto[] => {
  return Object.values<LegalServicesCreationDocumentFormValue>(documents ?? {})
    .filter(isDocumentWithFile)
    .map((document) => ({
      files: document.files?.map((y) => y.file),
      docType: document.docType,
    }));
};

export const useLegalServicesCreationFormSubmit = (
  productId: LegalServicesProductId,
  onSubmitted?: () => void,
): FormSubmitHandler<LegalServicesCreationFormValues> => {
  const dispatch = useAppDispatch();
  const { mutate } = useApiMutationAction();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const product = useAppSelector((state) => selectLegalServicesCreationById(state, productId))!;
  const client = useAppSelector(selectLegalServicesCreationClientSearch);

  return useCallback(
    async (values) => {
      const { comment, documents, email, fullName, phoneNumber } = legalServicesCreationValidationSchema.cast(values);

      const result = await mutate(
        legalServicesCreationThunks.send,
        {
          comment,
          legalAddress: client.check?.data?.fullAddress ?? "",
          beneficiaryName: client.check?.data?.fullName ?? "",
          beneficiaryTaxpayerNumber: client.check?.data?.taxpayerNumber ?? "",
          contactEmail: email,
          contactName: fullName,
          contactPhone: phoneNumber,
          productName: product.productName,
          documents: mapDocuments(documents),
        },
        {
          errorHandler: async (e) => {
            let message = "Во время обработки запроса произошла ошибка. Пожалуйста, попробуйте ещё раз позже.";
            if (isBaseApiError(e)) {
              message = e.message ?? message;
            }

            dispatch(pushErrorGlobalToast(message));

            return message;
          },
        },
      );

      if (result?.body !== undefined) {
        onSubmitted?.();
      }
    },
    [
      client.check?.data?.fullAddress,
      client.check?.data?.fullName,
      client.check?.data?.taxpayerNumber,
      dispatch,
      mutate,
      onSubmitted,
      product.productName,
    ],
  );
};
