import styled from "@emotion/styled";

export const Label = styled.span`
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.md};
  background-color: ${({ theme }) => theme.palette.fileLabel.default};
  border-radius: 4px;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme }) => theme.colors.primary};
  width: fit-content;
`;

export const Time = styled.time`
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme }) => theme.palette.typography.primaryCaption};
  flex-shrink: 0;
  flex-grow: 0;
`;

export const RemoveButton = styled.button`
  appearance: none;
  border: none;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme }) => theme.palette.typography.error};
  flex-shrink: 0;
  flex-grow: 0;
  background: none;
  cursor: pointer;
  user-select: none;

  &:focus-visible {
    text-decoration: underline;
  }
`;
