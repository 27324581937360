import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { NEWS_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const newsDetailsUrls = {
  item: makeAppUrl(
    joinUrlParts(NEWS_ROOT_URL, ":newsId"),
    "/news/:newsId",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsNews),
  ),
};
