import * as React from "react";
import * as S from "./BankGuaranteeRewardsItemCard.styled";
import { conditionsTitles } from "../../utils/conditionsTitles";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export interface BankGuaranteeRewardsItemCardProps extends BaseCssProps {
  bankName: React.ReactNode;
  children: React.ReactNode;
}

export const BankGuaranteeRewardsItemCard = React.forwardRef<HTMLDivElement, BankGuaranteeRewardsItemCardProps>(
  ({ bankName, className, children }, ref) => {
    return (
      <S.Wrapper className={className} ref={ref}>
        <S.BankTitle>{bankName}</S.BankTitle>
        <S.TitleContainer>
          {conditionsTitles.map((item) => (
            <S.TableCaption key={item.title}>{item.title}</S.TableCaption>
          ))}
        </S.TitleContainer>
        <S.TableContainer columnsSizes={conditionsTitles.map((title) => title.size)}>{children}</S.TableContainer>
      </S.Wrapper>
    );
  }
);
