import { Theme, useTheme } from "@sideg/ui-kit";
import * as S from "./ProductDetailsEmptyStage.styled";
import { Typography } from "../../../../ui-kit/typography";
import { ElementColor } from "../../../../ui-kit/types";
import { Color } from "../../../../ui-kit/theme";

export interface ProductDetailsEmptyStageProps<TStage> {
  color: ElementColor;
  getStageBackgroundColor: (stage: TStage, theme: Theme) => Color;
  stage: TStage;
  counter?: number;
  shortTitle: string;
}

export const ProductDetailsEmptyStage = <TStage extends string>({
  counter,
  shortTitle,
  color,
  stage,
  getStageBackgroundColor,
}: ProductDetailsEmptyStageProps<TStage>) => {
  const theme = useTheme();

  return (
    <S.Stage color={color} backgroundColor={getStageBackgroundColor(stage, theme)}>
      <Typography fontWeight={700} fontSize={1.5} lineHeight="defaultLineHeight">
        {counter ?? 0}
      </Typography>
      <Typography fontWeight={500} fontSize="fz1" lineHeight="1.4">
        {shortTitle}
      </Typography>
    </S.Stage>
  );
};
