import styled from "@emotion/styled";
import { FullPageContainerMainContentWrapper } from "../../../../common/ui/containers/full-page";

export const Container = styled(FullPageContainerMainContentWrapper)`
  padding: calc(${({ theme }) => theme.spaces.xxl} * 3) ${({ theme }) => theme.spaces.xl}
    ${({ theme }) => theme.spaces.xl} ${({ theme }) => theme.spaces.xl};

  & > *:only-of-type {
    flex-grow: 1;
  }

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    & > *:only-of-type {
      flex-grow: 0;
    }
  }
`;
