import { CreditWorkingAssetsListFilterContextValue } from "../../types/filters/CreditWorkingAssetsListFilterContextValue";
import { FilterTabType } from "../../../../common/filters/use-cases";

export const isFilterAvailableForTabCreditWorkingAssets = (
  tab: FilterTabType,
  filter: keyof CreditWorkingAssetsListFilterContextValue
): boolean => {
  if (tab === "Active" && filter === "status") {
    return false;
  }

  if (tab === "Completed" && filter === "taskTypes") {
    return false;
  }

  return true;
};
