import { FullPageContainerHeaderTitle } from "../../../../common/ui/containers/full-page";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { useBackLinkHeaderAction } from "../../../../common/ui/layout/header";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export type SettlementAndCashCreationHeaderMobileProps = BaseCssProps;

export const SettlementAndCashCreationHeaderMobile = ({ className }: SettlementAndCashCreationHeaderMobileProps) => {
  useBackLinkHeaderAction({ url: "/" });

  return (
    <StackPanel gap="lg" direction="column" alignItems="center" className={className} justifyContent="center">
      <FullPageContainerHeaderTitle text="Новая заявка на банковскую гарантию" />
    </StackPanel>
  );
};
