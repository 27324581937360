import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import { Theme } from "@sideg/ui-kit";
import { Color } from "../../../../ui-kit/theme";
import { StyledContainerWithCustomScroll } from "../../../../ui-kit/containers/components/StyledContainerWithCustomScroll";

export interface ContentProps {
  width?: CSSObject["width"];
  background?: (theme: Theme) => Color;
  isWithoutControls?: boolean;
  noClip?: boolean;
}

export const Content = styled(StyledContainerWithCustomScroll)<ContentProps>`
  max-width: min(720px, 90vw);
  background-color: ${({ theme, background }) => background?.(theme) ?? theme.palette.background.light};
  width: ${({ width }) => width};
  box-shadow: 0 8px 40px rgba(55, 63, 86, 0.6);
  border-radius: 12px;
  padding: ${({ theme, isWithoutControls }) => (isWithoutControls ? "0" : `${theme.spaces.xxl}`)};
  overflow-x: ${({ noClip }) => (noClip ? "unset" : "hidden")};
  overflow-y: ${({ noClip }) => (noClip ? "unset" : "auto")};
  transform: translateY(-200px);
  transition: transform 0.25s ease-in-out;

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 16px;
  }
`;

export const Container = styled.section`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.layout.zIndex + 10};
  background-color: ${({ theme }) => theme.layout.modalBackground};
  overflow: hidden;
  padding: 5vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  &.enter-done {
    opacity: 1;
    pointer-events: visible;

    & > ${Content} {
      transform: translateY(0);
    }
  }

  &.exit {
    opacity: 0;

    & > ${Content} {
      transform: translateY(-200px);
    }
  }
`;
