import { useEffect, useRef, useState } from "react";

export type UseIntersectionObserverArgs = IntersectionObserverInit;

export const useIntersectionObserver = (options?: UseIntersectionObserverArgs) => {
  const [entry, setEntry] = useState<IntersectionObserverEntry | undefined>();
  const [node, setNode] = useState<HTMLDivElement | null>(null);

  const updateEntry = ([newValue]: IntersectionObserverEntry[]) => {
    setEntry(newValue);
  };

  const observer = useRef(new IntersectionObserver(updateEntry, options));

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) {
      currentObserver.observe(node);
    }

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, entry] as const;
};
