import * as S from "./BankGuaranteeRewardsHeader.styled";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";
import { Text } from "../../../../common/ui-kit/typography";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeRewards } from "../../store/bankGuaranteeRewards.selectors";
import { bankGuaranteeRewardsCompanyType } from "../../types/BankGuaranteeRewardsAgencyType";

export type BankGuaranteeRewardsHeaderProps = BaseCssProps;

export const BankGuaranteeRewardsHeader = ({ className }: BankGuaranteeRewardsHeaderProps) => {
  const { companyType } = useAppSelector(selectBankGuaranteeRewards);

  return (
    <S.Container className={className}>
      <AppLayoutResponsiveListPage.Title>Вознаграждения</AppLayoutResponsiveListPage.Title>
      <Text fontWeight={500} fontSize="fz4">
        для агента {companyType ? `(${bankGuaranteeRewardsCompanyType.getTitle(companyType)})` : ""} с учетом
        действующих акций
      </Text>
    </S.Container>
  );
};
