import { Formik } from "formik";
import { bankGuaranteeDetailsDemandReconciliationConfirmPaymentValidationSchema } from "../../../../../validation/bankGuaranteeDetailsDemandReconciliationConfirmPaymentValidationSchema";
import { BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormContent } from "./BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormContent";
import { UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler } from "../../../../../types/demand/reconciliation/UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler";
import { FormBox } from "../../../../../../../common/form";
import { LoadingStatusEnum } from "../../../../../../../common/types/state";

interface BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormProps {
  handleSubmit: UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler;
  onClose?: () => void;
  status: LoadingStatusEnum;
}

export const BankGuaranteeDetailsDemandReconciliationConfirmPaymentForm = ({
  handleSubmit,
  onClose,
  status,
}: BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormProps) => {
  return (
    <Formik
      initialValues={bankGuaranteeDetailsDemandReconciliationConfirmPaymentValidationSchema.getDefault()}
      onSubmit={handleSubmit}
      validationSchema={bankGuaranteeDetailsDemandReconciliationConfirmPaymentValidationSchema}
    >
      <FormBox display="flex" flexDirection="column" flexGrow={1} gap="xxl">
        <BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormContent status={status} onClose={onClose} />
      </FormBox>
    </Formik>
  );
};
