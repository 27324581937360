/*
 * Этот файл сгенерирован автоматически. Пожалуйста, не изменяйте его.
 * Дата генерации: Tue Dec 19 2023 18:26:52 GMT+0300 (Moscow Standard Time)
 */

export default {
  xl: 2.5,
  l: 1.5,
  m: 1,
  sm: 0.75,
  s: 0.5,
  xs: 0.25,
} as const;
