/* eslint-disable no-bitwise */
import { Color } from "../theme";

const roundComponentValue = (value: number) => {
  if (value > 255) {
    return 255;
  }
  if (value < 0) {
    return 0;
  }

  return value;
};

export const lightenDarkenColor = (color: Color, amount: number) => {
  if (color.length < 1) {
    return color;
  }

  let prefix = "";
  let trimmedColor = color;
  let alpha = "";

  if (color[0] === "#") {
    trimmedColor = color.slice(1);
    prefix = "#";
  }

  if (trimmedColor.length !== 6 && trimmedColor.length !== 8) {
    return color;
  }

  if (trimmedColor.length === 8) {
    alpha = trimmedColor.slice(-2);
    trimmedColor = trimmedColor.slice(0, -2);
  }

  const number = parseInt(trimmedColor, 16);

  const r = roundComponentValue((number >> 16) + amount);
  const b = roundComponentValue(((number >> 8) & 0x00ff) + amount);
  const g = roundComponentValue((number & 0x0000ff) + amount);

  return prefix + (g | (b << 8) | (r << 16)).toString(16) + alpha;
};
