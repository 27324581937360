import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { QueueTaskId } from "../../../../../common/types/demand";
import { useAppSelector } from "../../../../../common/store";
import { selectCreditWorkingAssetsDetailsTasksHistoryById } from "../../../store/tasks-history/creditWorkingAssetsDetailsTasksHistory.selectors";
import { DemandQueueTaskCompletedCard } from "../../../../../demand/queue-tasks";

export interface CreditWorkingAssetsDetailsTasksHistoryListItemProps extends BaseCssProps {
  queueTaskId: QueueTaskId;
}

export const CreditWorkingAssetsDetailsTasksHistoryListItem = ({
  queueTaskId,
  className,
}: CreditWorkingAssetsDetailsTasksHistoryListItemProps) => {
  const queueTask = useAppSelector((state) => selectCreditWorkingAssetsDetailsTasksHistoryById(state, queueTaskId));

  if (!queueTask) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  return (
    <DemandQueueTaskCompletedCard
      className={className}
      title={queueTask.title}
      managerComment={queueTask.managerComment}
      agentComment={queueTask.agentComment}
    />
  );
};
