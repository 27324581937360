import { useCallback } from "react";

export const useBankGuaranteeCreationScoringIsBankSelected = (selectedBankIds: string[]) => {
  return useCallback(
    (bank: string) => {
      return selectedBankIds.includes(bank);
    },
    [selectedBankIds]
  );
};
