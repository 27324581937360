import { useEffect, useState } from "react";
import * as React from "react";
import PaginatedList from "../../../models/PaginatedList";
import "./Pagination.scss";

interface IPaginationProps {
  data: PaginatedList<any>;
  onChangePage: (page: number) => void;
}

interface ItemInfo {
  page?: number;
  title: string;
  isActive: boolean;
}

const Pagination: React.FC<IPaginationProps> = (props) => {
  const [items, setItems] = useState<ItemInfo[]>();
  const padeDiff = 2;
  const separator = ". . .";

  useEffect(() => {
    const items: ItemInfo[] = [];
    const currentPage = props.data.page;
    const totalPages = props.data.totalPages;
    const pageStart = currentPage - padeDiff;
    const pageEnd = currentPage + padeDiff;

    // первая страница
    items.push({ page: 1, title: "1", isActive: currentPage === 1 });

    if (pageStart > 2) {
      items.push({ title: separator, isActive: false });
    }

    for (let i = pageStart; i <= pageEnd; i++) {
      if (i <= 1 || i >= totalPages) {
        continue;
      }

      items.push({ page: i, title: `${i}`, isActive: currentPage === i });
    }

    if (pageEnd < totalPages - 1) {
      items.push({ title: separator, isActive: false });
    }

    if (totalPages > 1) {
      // последняя страница
      items.push({ page: totalPages, title: `${totalPages}`, isActive: currentPage === totalPages });
    }

    setItems(items);
  }, []);

  const getClass = (item: ItemInfo): string => {
    const cls = ["pagination__link"];
    if (item.isActive) {
      cls.push("pagination__link_active");
    } else if (!item.page) {
      cls.push("pagination__link_separator");
    }

    return cls.join(" ");
  };

  const handleClick = (isActive: boolean, page?: number) => {
    if (page && !isActive) {
      props.onChangePage(page);
    }
  };

  return (
    <>
      {items && (
        <ul className="pagination">
          {items.map((item, index) => {
            return (
              <li className="pagination__item" key={index}>
                <span className={getClass(item)} onClick={() => handleClick(item.isActive, item.page)}>
                  {item.title}
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default Pagination;
