import { clientV4 } from "../../../api";
import { GetRejectionReasonsProductRejectionOutputDto } from "./output/GetRejectionReasonsProductRejectionOutputDto";
import { GetRejectionReasonsProductRejectionInputDto } from "./input/GetRejectionReasonsProductRejectionInputDto";

export const productRejectionApi = {
  getRejectionReasons: (model: GetRejectionReasonsProductRejectionInputDto) => {
    return clientV4.get<GetRejectionReasonsProductRejectionInputDto, GetRejectionReasonsProductRejectionOutputDto>(
      `demands/rejection-reasons`,
      model
    );
  },
};
