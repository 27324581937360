import { FilesDownloadInputDto } from "./input/FilesDownloadInputDto";
import { clientV4 } from "../../../common/api";
import { FilesDownloadOutputDto } from "./output/FilesDownloadOutputDto";

export const filesDownloadApi = {
  get: ({ fileId, ...model }: FilesDownloadInputDto) =>
    clientV4.get<typeof model, FilesDownloadOutputDto>(`/files/octet-stream/${fileId}`, model, {
      responseType: "blob",
    }),
};
