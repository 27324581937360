import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { BaseState, getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import { creditWorkingAssetsDetailsActiveDemandDocumentsAdapter } from "./creditWorkingAssetsDetailsActiveDemandDocuments.adapter";
import { getDemandActualDocuments } from "../../../../demand/actual-documents";
import {
  CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME,
  creditWorkingAssetsDetailsActiveDemandChanged,
  creditWorkingAssetsDetailsActiveProductChanged,
} from "../creditWorkingAssetsDetails.actions";
import { CreditWorkingAssetsDetailsActiveDemandState } from "./creditWorkingAssetsDetailsActiveDemand.types";
import { getDemandCreditWorkingAssetsDetails } from "../creditWorkingAssetsDetails.thunks";
import { isCreditWorkingAssetsDetailsNeedReloadMatcher } from "../creditWorkingAssetsDetails.matchers";

const SLICE_NAME = combineSliceNames(CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME, "activeDemand");

const initialState: CreditWorkingAssetsDetailsActiveDemandState = {
  fetch: { status: LoadingStatusEnum.Idle },
  canSendComment: undefined,
  documents: creditWorkingAssetsDetailsActiveDemandDocumentsAdapter.getInitialState<BaseState>({
    status: LoadingStatusEnum.Idle,
  }),
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(creditWorkingAssetsDetailsActiveProductChanged, () => initialState)
      .addCase(creditWorkingAssetsDetailsActiveDemandChanged, (state, action) => {
        return { ...initialState, activeDemandId: action.payload };
      })
      .addCase(getDemandCreditWorkingAssetsDetails.fulfilled, (state, action) => {
        state.canSendComment = action.payload.body.canSendComment;
      })
      .addCase(getDemandCreditWorkingAssetsDetails.rejected, (state, action) => {
        state.fetch.error = action.payload?.message;
        state.canSendComment = undefined;
      })
      .addCase(getDemandActualDocuments.fulfilled, (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          creditWorkingAssetsDetailsActiveDemandDocumentsAdapter.setAll(state.documents, action.payload.body.data);
        }
      })
      .addMatcher(isCreditWorkingAssetsDetailsNeedReloadMatcher, (state) => {
        return { ...initialState, activeDemandId: state.activeDemandId };
      })
      .addMatcher(isAsyncThunkAction(getDemandCreditWorkingAssetsDetails), (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          state.fetch.status = getLoadingStatusFromAction(action);
        }
      })
      .addMatcher(isAsyncThunkAction(getDemandActualDocuments), (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          state.documents.status = getLoadingStatusFromAction(action);
        }
      });
  },
});

export const creditWorkingAssetsDetailsActiveDemandReducer = slice.reducer;
