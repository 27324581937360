import { ReactNode } from "react";
import { BaseCssProps } from "../../../types";
import * as S from "./Switch.styled";
import { CancelIcon, DoneIcon } from "../../../icons";

export interface SwitchProps extends BaseCssProps, Omit<JSX.IntrinsicElements["input"], "ref" | "type" | "className"> {
  label?: ReactNode | string;
  labelPosition?: "before" | "after";
  withStateIcon?: boolean;
  isError?: boolean;
}

export const Switch = ({
  className,
  withStateIcon,
  label,
  labelPosition = "before",
  isError,
  ...inputProps
}: SwitchProps) => {
  const labelContent = typeof label === "string" ? <S.LabelText>{label}</S.LabelText> : label;

  return (
    <S.Label isDisabled={inputProps.disabled} className={className}>
      {labelPosition === "before" && labelContent}
      <S.SwitchContainer>
        <S.Input isError={isError} type="checkbox" role="switch" {...inputProps} />
        {withStateIcon && <S.StateIcon data-id={12} as={CancelIcon} {...S.getDataIconAttribute("unchecked")} />}
        {withStateIcon && <S.StateIcon as={DoneIcon} {...S.getDataIconAttribute("checked")} />}
      </S.SwitchContainer>
      {labelPosition === "after" && labelContent}
    </S.Label>
  );
};
