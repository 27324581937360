import ClientFormBlock from "../../../../../../ui/components/client-form/parts/block/ClientFormBlock";
import CustomSelect from "../../../../../../ui/components/client-form/parts/select/ClientFormSelect";
import styles from "./CalculatorPurchaseInfo.module.css";
import { convertOptions, getMultiOptions, getOptions } from "../data/mappers";
import { ClientFormMultiSelect } from "../../../../../../ui/components/client-form/parts/multi-select/ClientFormMultiSelect";
import { ScoringModelItemsType } from "../../../../../../entity/models/ScoringModel";
import { ScoringFormValues } from "../data/initialValues";

interface CalculatorPurchaseInfoProps {
  values: ScoringFormValues;
  fzOptions?: ScoringModelItemsType;
  tenderInfoOptions?: ScoringModelItemsType;
}
export const CalculatorPurchaseInfo = ({ values, fzOptions, tenderInfoOptions }: CalculatorPurchaseInfoProps) => {
  const getFzOptions = (fzOptions: ScoringModelItemsType) => {
    const options = getOptions(fzOptions);
    return convertOptions(options);
  };

  return (
    <ClientFormBlock title="Информация о закупке">
      <div className={styles.wrapper}>
        <div className={styles.purchaseInfo}>
          <div className={styles.purchaseInfo__fz}>
            <div className={styles.purchaseInfo__title}>
              <span>Тип Закупки</span>
            </div>
            <div className={styles.purchaseInfo__select}>
              {fzOptions && (
                <CustomSelect
                  label="Выберите ФЗ"
                  selectedValue={values.fzType}
                  name="fzType"
                  options={getFzOptions(fzOptions)}
                  removable={true}
                />
              )}
            </div>
          </div>
          {/* <div className={styles['purchaseInfo__purchaseNumber']}>
                        <div className={styles['purchaseInfo__select']}>
                            <ClientFormInput
                                label="Введите номер для 44,223,615 ФЗ"
                                name="tenderForm.tenderNumber"
                                disabled={!(purchaseInfoValues.fzType === 'TypeFederalLaw44' || purchaseInfoValues.fzType === 'TypeFederalLaw223' || purchaseInfoValues.fzType === 'TypeFederalLaw185Or615')}
                            />
                        </div>
                    </div> */}
          <div className={styles.purchaseInfo__additionalAboutPurchase}>
            {tenderInfoOptions && (
              <ClientFormMultiSelect
                label="Дополнительно о Закупке"
                options={getMultiOptions(tenderInfoOptions)}
                name={"scoringItems"}
              />
            )}
          </div>
        </div>
      </div>
    </ClientFormBlock>
  );
};
