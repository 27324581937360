import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeListItemDesktopCard } from "./card/BankGuaranteeListItemDesktopCard";
import { BankGuaranteeListItemDesktopDemandsSkeleton } from "./demand-list/BankGuaranteeListItemDesktopDemands.skeleton";

export type BankGuaranteeListItemDesktopSkeletonProps = BaseCssProps;

export const BankGuaranteeListItemDesktopSkeleton = ({ className }: BankGuaranteeListItemDesktopSkeletonProps) => {
  return (
    <BankGuaranteeListItemDesktopCard
      className={className}
      clientName={<Skeleton />}
      clientTaxpayerNumber={<Skeleton inline width={100} />}
      guaranteeNumberAndDate={<Skeleton width={220} />}
      guaranteeType={<Skeleton width={200} />}
      details={
        <>
          <Skeleton width={230} />
          <Skeleton width={140} />
          <Skeleton width={160} />
        </>
      }
    >
      <BankGuaranteeListItemDesktopDemandsSkeleton />
    </BankGuaranteeListItemDesktopCard>
  );
};
