import { useCallback } from "react";
import { addNewCompletedTaskBankGuaranteeDetailsTasksHistory } from "../../../store/tasks-history/bankGuaranteeDetailsTasksHistory.slice";
import { useAppDispatch, useAppSelector } from "../../../../../common/store";
import { useBankGuaranteeDetailsLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { pushSuccessGlobalToast } from "../../../../../global/toasts";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { bankGuaranteeDetailsReloadActiveDemand } from "../../../store/bankGuaranteeDetails.actions";
import { selectBankGuaranteeDetailsActiveDemand } from "../../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { useBankGuaranteeDetailsDemandNewCommentOnSubmit } from "../../../hooks/new-comment/useBankGuaranteeDetailsDemandQueueTasksNewCommentOnSubmit";
import { BankGuaranteeDetailsDemandNewCommentContent } from "./BankGuaranteeDetailsDemandNewCommentContent";
import { useBankGuaranteeDetailsFilterContext } from "../../../hooks/filters/context/useBankGuaranteeDetailsFilterContext";
import { useBankGuaranteeDetailsFilterApiContext } from "../../../hooks/filters/context/useBankGuaranteeDetailsFilterApiContext";
import { BankGuaranteeDetailsDemandQueueTaskCompleted } from "../../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskCompleted";
import { selectBankGuaranteeDetailsDemandById } from "../../../store/demands/bankGuaranteeDetailsDemands.selectors";

export type BankGuaranteeDetailsDemandNewCommentProps = BaseCssProps;

export const BankGuaranteeDetailsDemandNewComment = ({ className }: BankGuaranteeDetailsDemandNewCommentProps) => {
  const dispatch = useAppDispatch();
  const { canSendComment } = useAppSelector(selectBankGuaranteeDetailsActiveDemand);

  const context = useBankGuaranteeDetailsFilterContext();
  const mutator = useBankGuaranteeDetailsFilterApiContext();
  const { demandId } = useBankGuaranteeDetailsLayoutContext();
  const demand = useAppSelector((state) => selectBankGuaranteeDetailsDemandById(state, demandId ?? 0));
  const onSuccess = useCallback(
    (completedQueueTask: BankGuaranteeDetailsDemandQueueTaskCompleted) => {
      dispatch(addNewCompletedTaskBankGuaranteeDetailsTasksHistory(completedQueueTask));
      dispatch(bankGuaranteeDetailsReloadActiveDemand());
      dispatch(pushSuccessGlobalToast("Сообщение отправлено"));
      mutator(context, (service) => service.setNewCommentFormClosed());
    },
    [context, dispatch, mutator],
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const onSubmit = useBankGuaranteeDetailsDemandNewCommentOnSubmit(demandId!, onSuccess);

  return (
    <BankGuaranteeDetailsDemandNewCommentContent
      canSendComment={canSendComment === true && !!demandId}
      className={className}
      projectBillProcess={demand?.projectBillProcess?.code}
      isCommentFormOpened={context.isNewCommentOpened}
      onSendCommentButtonClick={() => mutator(context, (service) => service.setNewCommentFormOpen())}
      onSubmit={onSubmit}
      onCancel={() => mutator(context, (service) => service.setNewCommentFormClosed())}
    />
  );
};
