import { memo, useMemo } from "react";
import { CSSObject } from "@emotion/react";
import { ThemeMobileDesktopView, useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { useBankGuaranteeDetailsAllDemandsActions } from "../../../hooks/useBankGuaranteeDetailsAllDemandsActions";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { StyledAppLink } from "../../../../../common/ui/links";
import { Text } from "../../../../../common/ui-kit/typography";
import { useBankGuaranteeDetailsLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { BankGuaranteeRejectionRejectGuaranteeResponsiveView } from "../../../../rejection";
import { useBankGuaranteeDetailsGuaranteeRejection } from "../../../hooks/useBankGuaranteeDetailsGuaranteeRejection";
import { useCreateNavigationStateFrom } from "../../../../../common/navigation/navigation-state";
import { RejectButton } from "../../../../../common/ui/buttons/reject-button";

export interface BankGuaranteeDetailsAsideActionsProps {
  className?: string;
}

export const BankGuaranteeDetailsAsideActions = memo(({ className }: BankGuaranteeDetailsAsideActionsProps) => {
  const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();
  const { canRejectAll, canSendMore } = useBankGuaranteeDetailsAllDemandsActions();

  const { isModalOpen, openModal, closeModal, afterSuccess, panelKey } =
    useBankGuaranteeDetailsGuaranteeRejection(guaranteeId);

  const justifyContent = useMemo((): CSSObject["justifyContent"] => {
    if (canRejectAll && canSendMore) {
      return "space-between";
    }

    return canRejectAll ? "flex-end" : "flex-start";
  }, [canRejectAll, canSendMore]);

  const from = useCreateNavigationStateFrom("Заявка");
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <StackPanel direction="row" gap="sm" alignItems="center" justifyContent={justifyContent} className={className}>
      {canSendMore && (
        <Text fontWeight={500} lineHeight="defaultLineHeight" fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}>
          <StyledAppLink to={(x) => x.bankGuarantee.creation.createByGuarantee} params={{ guaranteeId }} state={from}>
            Отправить в другие
          </StyledAppLink>
        </Text>
      )}

      {canRejectAll && (
        <>
          <RejectButton onClick={openModal} size={{ xs: "default", [desktopBreakpoint]: "small" }}>
            <ThemeMobileDesktopView mobile="Отменить все" desktop="Отменить все заявки" />
          </RejectButton>
          <BankGuaranteeRejectionRejectGuaranteeResponsiveView
            guaranteeId={guaranteeId}
            isOpen={isModalOpen}
            onClose={closeModal}
            afterSuccess={afterSuccess}
            panelKey={panelKey}
          />
        </>
      )}
    </StackPanel>
  );
});
