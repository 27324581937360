import styled from "@emotion/styled";

export const Button = styled.button`
  appearance: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  padding: ${({ theme }) => theme.spaces.xs};
  border-radius: 4px;
  background: none;

  &:hover {
    background-color: ${({ theme }) => theme.palette.control.secondaryLight};
  }

  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  & > svg {
    height: 15px;
    width: 15px;

    & > path {
      stroke: ${({ theme }) => theme.palette.typography.medium};
    }
  }
`;
