import { Box } from "../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { CreditWorkingAssetsDetailsActiveTasks } from "./active-tasks/CreditWorkingAssetsDetailsActiveTasks";
import { CreditWorkingAssetsDetailsDemandNewComment } from "./CreditWorkingAssetsDetailsDemandNewComment";
import { CreditWorkingAssetsDetailsTasksHistory } from "./tasks-history/CreditWorkingAssetsDetailsTasksHistory";
import { isSucceeded } from "../../../../common/types/state";
import { useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsDetailsActiveDemand } from "../../store/active-demand/creditWorkingAssetsDetailsActiveDemand.selectors";
import { selectCreditWorkingAssetsDetailsActiveTasksTotal } from "../../store/active-tasks/creditWorkingAssetsDetailsActiveTasks.selectors";
import {
  selectCreditWorkingAssetsDetailsTasksHistory,
  selectCreditWorkingAssetsDetailsTasksHistoryTotal,
} from "../../store/tasks-history/creditWorkingAssetsDetailsTasksHistory.selectors";
import { ProductDetailsDemandTasksEmptyState } from "../../../../common/product-details";

export type CreditWorkingAssetsDetailsDemandProps = BaseCssProps;

export const CreditWorkingAssetsDetailsDemand = ({ className }: CreditWorkingAssetsDetailsDemandProps) => {
  const {
    fetch: { status: fetchStatus },
  } = useAppSelector(selectCreditWorkingAssetsDetailsActiveDemand);
  const { status: taskHistoryFetchStatus } = useAppSelector(selectCreditWorkingAssetsDetailsTasksHistory);

  const activeTasksCount = useAppSelector(selectCreditWorkingAssetsDetailsActiveTasksTotal);
  const historyTasksCount = useAppSelector(selectCreditWorkingAssetsDetailsTasksHistoryTotal);

  const isLoadedEmptyState =
    isSucceeded(taskHistoryFetchStatus) &&
    isSucceeded(fetchStatus) &&
    activeTasksCount === 0 &&
    historyTasksCount === 0;

  return (
    <Box display="flex" flexDirection="column" gap="lg" className={className}>
      <CreditWorkingAssetsDetailsDemandNewComment />
      <Box display="flex" flexDirection="column" gap="xxl">
        {isLoadedEmptyState && <ProductDetailsDemandTasksEmptyState />}
        <CreditWorkingAssetsDetailsActiveTasks />
        <CreditWorkingAssetsDetailsTasksHistory />
      </Box>
    </Box>
  );
};
