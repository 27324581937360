import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { FormSelectResponsive } from "@sideg/ui-kit/atoms/form-controls/select";
import { FormResponsiveRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox/components/radio-group/FormResponsiveRadioButtonGroup";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { BankGuaranteeDocumentsFilteringObject } from "../types/BankGuaranteeDocumentsFilteringObject";
import { bankGuaranteeDocumentsClientLegalFormType } from "../types/BankGuaranteeDocumentsClientLegalFormType";
import { filtrationService, useSetActualPathnameQuerySearch } from "../../../common/filters";
import { useAppSelector } from "../../../common/store";
import { selectBankGuaranteeDocuments } from "../store/bankGuaranteeDocuments.selectors";
import { UNSELECTED_ENUM_LITERAL } from "../../../common/types/utils";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { Box } from "../../../common/ui-kit/containers/box";

export interface BankGuaranteeDocumentsFiltersProps extends BaseCssProps {
  filteringObject: BankGuaranteeDocumentsFilteringObject;
}

export const BankGuaranteeDocumentsFilters = ({ filteringObject }: BankGuaranteeDocumentsFiltersProps) => {
  const setQuery = useSetActualPathnameQuerySearch();
  const { banks } = useAppSelector(selectBankGuaranteeDocuments);

  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <AppLayoutResponsiveListPage.Header>
      <AppLayoutResponsiveListPage.Title>Необходимые документы</AppLayoutResponsiveListPage.Title>
      <Box
        display="flex"
        flexDirection={{ xs: "column", [desktopBreakpoint]: "row" }}
        gap="lg"
        pt={{ xs: "md", [desktopBreakpoint]: "xxl" }}
        pb={{ xs: "md", [desktopBreakpoint]: "none" }}
      >
        {banks?.data !== undefined && (
          <FormSelectResponsive
            label="Банк"
            items={banks.data.ids}
            selectedItem={filteringObject.bank ?? UNSELECTED_ENUM_LITERAL}
            getItemTitle={(x) => banks.data?.titles[x] ?? ""}
            onChange={(newValue) => {
              setQuery(filtrationService.setValue(filteringObject, "bank", newValue));
            }}
            isItemDisabled={(x) => x === UNSELECTED_ENUM_LITERAL}
          />
        )}
        <FormResponsiveRadioButtonGroup
          value={filteringObject.legalForm}
          onChange={(newValue) => {
            setQuery(filtrationService.setValue(filteringObject, "legalForm", newValue));
          }}
          itemsPlacement="start"
          radioSize="default"
          label="Правовая форма"
          types={bankGuaranteeDocumentsClientLegalFormType.values}
          getTitle={bankGuaranteeDocumentsClientLegalFormType.getTitle}
          name="legalForm"
        />
      </Box>
    </AppLayoutResponsiveListPage.Header>
  );
};
