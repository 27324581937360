import { memo, useMemo } from "react";
import { useAppLayoutHeaderActionEffect } from "../../../../../common/ui/layout/app-layout-containers";
import { NavigationBrandWithBreadcrumbs } from "../../../../../common/ui/navigation";

export interface BankGuaranteeDetailsHeaderNavigationDesktopProps {
  title: string;
  url: string;
}

export const BankGuaranteeDetailsHeaderNavigationDesktop = memo(
  ({ title, url }: BankGuaranteeDetailsHeaderNavigationDesktopProps) => {
    const component = useMemo(() => <NavigationBrandWithBreadcrumbs title={title} url={url} />, [title, url]);

    useAppLayoutHeaderActionEffect(component);

    return undefined;
  },
);
