import { combineSliceNames } from "../../../common/store/utils";
import { PUBLIC_USER_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { publicUserRegistrationApi } from "../api/publicUserRegistrationApi";

export const PUBLIC_USER_REGISTRATION_SLICE_NAME = combineSliceNames(PUBLIC_USER_SLICE_NAME, "registration");

export const {
  checkAgency: checkAgencyPublicUserRegistration,
  registerClient: registerClientPublicUserRegistration,
  registerAgent: registerAgentPublicUserRegistration,
  getAgency: getAgencyPublicUserRegistration,
  downloadStatement: downloadStatementPublicUserRegistration,
  sign: signPublicUserRegistration,
  sendCode: sendCodePublicUserRegistration,
  completeAgencyRegistration: completeAgencyRegistrationPublicUserRegistration,
} = createApiThunks(PUBLIC_USER_REGISTRATION_SLICE_NAME, publicUserRegistrationApi);
