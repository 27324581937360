import { DemandId, ProductId, ProductType } from "../../../types/demand";
import { generatePath } from "./generatePath";

export const generateDemandProductUrl = (product: ProductType, demandId: DemandId, productId: ProductId): string => {
  switch (product) {
    case "BankGuarantee":
      return generatePath((x) => x.bankGuarantee.details.demand, { guaranteeId: productId, demandId });
    case "CreditExecution":
      return generatePath((x) => x.creditExecution.details.demand, { creditExecutionId: productId, demandId });
    case "ReportAgent":
      return generatePath((x) => x.reports.agent.details.root, { reportId: demandId });
    case "CreditWorkingAssets":
      return generatePath((x) => x.creditWorkingAssets.details.demand, { creditWorkingAssetsId: productId, demandId });
    case "AgencyRegistration":
      return generatePath((x) => x.agency.demand.root, { demandId });
    default:
      return "";
  }
};
