import { ReactNode } from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { CreationBlockCard } from "../../../../common/ui-kit/cards/creation-block-card";
import { Box } from "../../../../common/ui-kit/containers/box";

const BoxCard = CreationBlockCard.withComponent(Box);

export interface AgencyDemandDocumentsContainerProps extends BaseCssProps {
  children: ReactNode;
}

export const AgencyDemandDocumentsContainer = ({ children, className }: AgencyDemandDocumentsContainerProps) => {
  return (
    <BoxCard className={className} display="flex" flexDirection="column" gap="lg" padding="lg">
      {children}
    </BoxCard>
  );
};
