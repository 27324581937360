import { Box } from "../../../../ui-kit/containers/box";
import { Button } from "../../../../ui-kit/form-controls/button";
import { BellInCircleIcon, CancelIcon, CheckIcon, Icon, QuestionCircleIcon } from "../../../../ui-kit/icons";
import { BaseCssProps } from "../../../../ui-kit/types";
import { Typography } from "../../../../ui-kit/typography";
import { StyledAppLink } from "../../../../ui/links";

const CheckIconSuccess = () => <Icon as={CheckIcon} stroke="typography.success" />;

export interface WebPushesPromptMobileProps extends BaseCssProps {
  onAllowClick: () => void;
  onDisallowClick: () => void;
}

export const WebPushesPromptMobile = ({ onAllowClick, onDisallowClick, className }: WebPushesPromptMobileProps) => {
  return (
    <Box
      className={className}
      display="flex"
      flexDirection="column"
      backgroundColor="secondary.primaryBackground"
      borderRadius="8px"
      padding="md"
    >
      <Box display="flex" flexDirection="row" gap="md">
        <Box display="flex" flexDirection="column" gap="xs">
          <Typography fontColor="typography.primary" fontWeight={500} fontSize="21px" lineHeight="defaultLineHeight">
            Уведомления в браузере
          </Typography>
          <Typography fontColor="typography.primary" opacity={0.6} fontWeight={500} fontSize="fz2" lineHeight="1.4">
            Разрешите браузеру отправлять вам уведомления от lk.teledoc.ru о новых задачах или о смене статуса
          </Typography>
          <Box pt="sm">
            <StyledAppLink to={(x) => x.user.webPushes.root}>
              <Icon as={QuestionCircleIcon} />
              <Typography fontWeight={500} fontSize="fz2" lineHeight="1.125">
                Подробнее
              </Typography>
            </StyledAppLink>
          </Box>
        </Box>
        <Icon as={BellInCircleIcon} width="48px" height="40px" flexShrink={0} />
      </Box>
      <Box display="flex" gap="md" pt="lg">
        <Button
          startIcon={CheckIconSuccess}
          variant="contained"
          color="light"
          onClick={onAllowClick}
          type="button"
          size="small"
          isFullWidth
        >
          Разрешить
        </Button>
        <Button
          startIcon={CancelIcon}
          variant="contained"
          color="light"
          onClick={onDisallowClick}
          type="button"
          size="small"
          isFullWidth
        >
          В другой раз
        </Button>
      </Box>
    </Box>
  );
};
