import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeCreationScoring } from "../../../store/bankGuaranteeCreation.selectors";
import { scoringByGuaranteeIdBankGuaranteeCreation } from "../../../store/bankGuaranteeCreation.thunks";
import { BankGuaranteeId } from "../../../../../common/types/demand";
import { isIdle } from "../../../../../common/types/state";

export const useBankGuaranteeCreationScoringCreateByGuarantee = (guaranteeId: BankGuaranteeId) => {
  const dispatch = useAppDispatch();
  const { status, data } = useAppSelector(selectBankGuaranteeCreationScoring);

  useEffect(() => {
    if (isIdle(status)) {
      dispatch(scoringByGuaranteeIdBankGuaranteeCreation({ guaranteeId }));
    }
  }, [dispatch, guaranteeId, status]);

  return useMemo(() => ({ status, data }), [status, data]);
};
