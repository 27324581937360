import { dateTimeHelper } from "@sideg/helpers";
import { BankGuaranteeCalculatorFormValues } from "../types/BankGuaranteeCalculatorFormValues";
import { CalculateBankGuaranteeCalculatorInputDto } from "../api/dto/input/CalculateBankGuaranteeCalculatorInputDto";
import ProductType from "../../../../models/ProductType";
import { CalculateBankGuaranteeCalculatorOutputDto } from "../api/dto/output/CalculateBankGuaranteeCalculatorOutputDto";
import { BankGuaranteeCalculatorBankResult } from "../types/BankGuaranteeCalculatorBankResult";

const mapInput = (formValues: BankGuaranteeCalculatorFormValues): CalculateBankGuaranteeCalculatorInputDto => {
  return {
    guaranteeTypeCode: formValues.guaranteeType,
    fzCode: formValues.federalLaw.replace("Fz", ""),
    dateStart: dateTimeHelper.format(formValues.dateStart, "yearMonthDayDashed"),
    dateEnd: dateTimeHelper.format(formValues.dateEnd, "yearMonthDayDashed"),
    productSum: formValues.guaranteeSum,
    scoringProduct: ProductType.BankGuarantee,
    beneficiarINN: "7714874744",
    clientINN: "7714874744",
    contractSum: 100_000,
    lotNumber: "0188300004518000024",
    closedAuction: false,
    clientTaxSystem: null,
    enforcementSum: null,
    prepaidSum: null,
    reprovision: null,
    writeOffRequest: null,
    dateEvent: null,
  };
};

const mapOutput = (response: CalculateBankGuaranteeCalculatorOutputDto): BankGuaranteeCalculatorBankResult[] => {
  return Object.values(response)
    .filter((x) => x.price !== undefined && x.price !== null)
    .map((x) => ({
      bankCode: x.bank.code,
      bankName: x.bank.shortName,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      price: x.price!,
    }));
};

export const bankGuaranteeCalculatorMapperService = {
  mapInput,
  mapOutput,
};
