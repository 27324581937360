import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { BankGuaranteeDetailsRequestOriginalState } from "./bankGuaranteeDetailsRequestOriginal.types";
import { BasePaginatedState, getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import { bankGuaranteeDetailsRequestOriginalAdapter } from "./bankGuaranteeDetailsRequestOriginal.adapter";
import { combineSliceNames } from "../../../../common/store/utils";
import {
  BANK_GUARANTEE_DETAILS_SLICE_NAME,
  getBankGuaranteeDetails,
  getRequestOriginalCommentsBankGuaranteeDetails,
  sendRequestOriginalCommentBankGuaranteeDetails,
} from "../bankGuaranteeDetails.thunks";
import { bankGuaranteeDetailsRequestOriginalMapper } from "./bankGuaranteeDetailsRequestOriginal.mapper";
import { isBankGuaranteeDetailsGuaranteeChangedMatcher } from "../bankGuaranteeDetails.matchers";

const initialState: BankGuaranteeDetailsRequestOriginalState = {
  details: undefined,
  comments: bankGuaranteeDetailsRequestOriginalAdapter.getInitialState<BasePaginatedState>({
    pagination: undefined,
    status: LoadingStatusEnum.Idle,
  }),
};

const SLICE_NAME = combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "requestOriginal");

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    update: (state) => {
      state.comments.status = LoadingStatusEnum.Idle;
      state.comments.pagination = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankGuaranteeDetails.fulfilled, (state, action) => {
        const requestOriginalOutputDto = action.payload.body.requestOriginal;
        state.details =
          requestOriginalOutputDto === undefined
            ? undefined
            : bankGuaranteeDetailsRequestOriginalMapper.mapResponse(requestOriginalOutputDto);
      })
      .addCase(getRequestOriginalCommentsBankGuaranteeDetails.fulfilled, (state, action) => {
        state.comments.pagination = action.payload.body.paginationInformation;
        bankGuaranteeDetailsRequestOriginalAdapter.upsertMany(state.comments, action.payload.body.data);
      })
      .addCase(sendRequestOriginalCommentBankGuaranteeDetails.fulfilled, (state, action) => {
        bankGuaranteeDetailsRequestOriginalAdapter.upsertOne(state.comments, action.payload.body);
      })
      .addMatcher(isAsyncThunkAction(getRequestOriginalCommentsBankGuaranteeDetails), (state, action) => {
        state.comments.status = getLoadingStatusFromAction(action);
      })
      .addMatcher(isBankGuaranteeDetailsGuaranteeChangedMatcher, () => initialState);
  },
});

export const bankGuaranteeDetailsRequestOriginalReducer = slice.reducer;

export const { update: updateBankGuaranteeDetailsRequestOriginal } = slice.actions;
