import * as React from "react";
import { PropsWithChildren, useEffect } from "react";
import { ModalWrapper } from "../../../wrapper/ModalWrapper";
import { IAgentReportCreateState } from "../../../../../store/reducers/agentReportReducer";
import { connect } from "react-redux";
import { agentReportCreateInitial } from "../../../../../store/actions/agentReportActions";
import { AgentReportStatus } from "../../../../../store/actions/agentReportActionsTypes";
import MessageModal from "../../message/MessageModal";
import AgentReportCreateModal from "./AgentReportCreateModal";
import { useAppDispatch } from "../../../../../domains/common/store";

interface IAgentReportCreateModalWrapperProps {
  onClose: Function;
  onReload: Function;
  state?: IAgentReportCreateState;
}

const AgentReportCreateModalWrapper: React.FC<PropsWithChildren<IAgentReportCreateModalWrapperProps>> = (props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(agentReportCreateInitial());
  }, []);

  useEffect(() => {
    if (props.state?.status === AgentReportStatus.Success) {
      props.onReload();
    }
  }, [props.state]);

  return (
    <ModalWrapper onClose={props.onClose}>
      {props.state?.status === AgentReportStatus.Success ? (
        <MessageModal
          title={"Акт сверки запрошен"}
          message={
            "Ваш акт сформирован и отправлен на проверку бухгалтеру. После проверки он поступит Вам на согласование"
          }
        />
      ) : (
        <AgentReportCreateModal />
      )}
    </ModalWrapper>
  );
};

const mapStateToProps = (state: any, ownState: any): IAgentReportCreateModalWrapperProps => {
  return {
    ...ownState,
    state: state.agentReportReducer.create,
  };
};

export default connect(mapStateToProps)(AgentReportCreateModalWrapper);
