import { string, object, ref } from "yup";

export const userChangePasswordValidationSchema = object({
  newPassword: string()
    .required("Не забудьте заполнить")
    .min(6, "Пароль должен содержать минимум 6 символов")
    .notOneOf([ref("oldPassword")], "Не должен совпадать со старым паролем")
    .default(""),
  oldPassword: string().required("Не забудьте заполнить").default(""),
  confirmPassword: string()
    .required("Не забудьте заполнить")
    .oneOf([ref("newPassword"), null], "Пароли должны совпадать")
    .notOneOf([ref("oldPassword")], "Не должен совпадать со старым паролем")
    .min(6, "Пароль должен содержать минимум 6 символов")
    .default(""),
});
