import { currencyHelper, numberHelper } from "@sideg/helpers";
import { ProductSumWithRate } from "../../../../../../common/types/demand";
import { Typography } from "../../../../../../common/ui-kit/typography";
import { DotSeparatedRowContainer } from "../../../../../../common/ui-kit/containers/dot-separated-row";

export interface BankGuaranteeDetailsBillComplicatedTaskBillInfoRewardAgentProps {
  rewardAgent: ProductSumWithRate | undefined;
}

export const BankGuaranteeDetailsBillComplicatedTaskBillInfoRewardAgent = ({
  rewardAgent,
}: BankGuaranteeDetailsBillComplicatedTaskBillInfoRewardAgentProps) => {
  return (
    rewardAgent && (
      <>
        <Typography fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.primary">
          Вознаграждение агента
        </Typography>
        <DotSeparatedRowContainer dotColor="typography.medium">
          <Typography fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.primary">
            {currencyHelper.getCurrencyString(rewardAgent.sum)}
          </Typography>
          <Typography fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.medium">
            {numberHelper.getFormattedDecimal(rewardAgent.rate, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
            %
          </Typography>
        </DotSeparatedRowContainer>
      </>
    )
  );
};
