import { createSlice } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { creditWorkingAssetsDetailsActiveTasksAdapter } from "./creditWorkingAssetsDetailsActiveTasks.adapter";
import { queueTaskClosedDemandQueueTasks, uploadDocumentDemandQueueTasks } from "../../../../demand/queue-tasks";
import { CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME } from "../creditWorkingAssetsDetails.actions";
import { getDemandCreditWorkingAssetsDetails } from "../creditWorkingAssetsDetails.thunks";
import { isCreditWorkingAssetsDetailsProductChangedMatcher } from "../creditWorkingAssetsDetails.matchers";

const SLICE_NAME = combineSliceNames(CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME, "activeTasks");

const initialState = creditWorkingAssetsDetailsActiveTasksAdapter.getInitialState();

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDemandCreditWorkingAssetsDetails.fulfilled, (state, action) => {
        creditWorkingAssetsDetailsActiveTasksAdapter.setAll(state, action.payload.body.queueTasks ?? []);
      })
      .addCase(queueTaskClosedDemandQueueTasks, (state, action) => {
        creditWorkingAssetsDetailsActiveTasksAdapter.removeOne(state, action.payload);
      })
      .addCase(uploadDocumentDemandQueueTasks.fulfilled, (state, action) => {
        const queueTask = state.entities[action.meta.arg.queueTaskId];
        if (queueTask?.canRealTimeUpload) {
          const document = queueTask.action.requestedFiles?.find(
            (x) => x.id === action.meta.arg.id && x.requestedDocType === action.meta.arg.requestDocType
          );
          if (document) {
            const { files } = action.payload.body;
            if (files.length) {
              if (document.isMultiFile) {
                document.oldFiles = [...(document.oldFiles ?? []), ...files];
              } else {
                // eslint-disable-next-line prefer-destructuring
                document.oldFile = files[0];
              }
            }
          }
        }
      })
      .addMatcher(isCreditWorkingAssetsDetailsProductChangedMatcher, () => initialState);
  },
});

export const creditWorkingAssetsDetailsActiveTasksReducer = slice.reducer;
