import Skeleton from "react-loading-skeleton";
import { FullPageContainerCompound } from "../../../common/ui/containers/full-page";
import { useAppSelector } from "../../../common/store";
import { selectAgencyDemand } from "../store/agencyDemand.selectors";
import { NavigationStateFrom } from "../../../common/navigation/navigation-state/types/NavigationStateFrom";
import { BaseCssProps } from "../../../common/ui-kit/types";

export interface AgencyDemandHeaderProps extends BaseCssProps {
  from: NavigationStateFrom;
}

export const AgencyDemandHeader = ({ className, from }: AgencyDemandHeaderProps) => {
  const { data } = useAppSelector(selectAgencyDemand);

  return (
    <FullPageContainerCompound.Header
      className={className}
      leftSide={<FullPageContainerCompound.HeaderBackLinkButton url={from.url} title={from.backTitle} />}
    >
      <FullPageContainerCompound.HeaderTypography>
        {data?.taskTitle ?? <Skeleton width={300} />}
      </FullPageContainerCompound.HeaderTypography>
    </FullPageContainerCompound.Header>
  );
};
