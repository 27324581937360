import { useMemo } from "react";
import { useResponsiveRange } from "@sideg/ui-kit/core/responsive/";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive/hooks/useThemeDesktopBreakpoint";
import { DeviceResponsiveType } from "@sideg/ui-kit/common/types";

export const useThemeDeviceResponsiveType = (): DeviceResponsiveType => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();
  const { higherThan, lowerThan } = useResponsiveRange();

  return useMemo(() => {
    if (higherThan(desktopBreakpoint, true)) {
      return "desktop";
    }

    if (lowerThan(desktopBreakpoint)) {
      return "mobile";
    }

    return "unknown";
  }, [desktopBreakpoint, higherThan, lowerThan]);
};
