import { AnySchema, InferType } from "yup";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { queryParamsService } from "../services/queryParamsService";

export interface UseSearchParamsOnceOptions {
  isNeedValidateBeforeCast?: boolean;
  isNeedReturnOnlySpecifiedInQueryFields?: boolean;
}

export const useSearchParamsOnce = <TValidationSchema extends AnySchema>(
  schema: TValidationSchema,
  options?: UseSearchParamsOnceOptions
): InferType<TValidationSchema> => {
  const { search } = useLocation();

  const [query] = useState(() => {
    const urlObject = queryParamsService.getObjectFromQueryParams(new URLSearchParams(search));

    if (options?.isNeedValidateBeforeCast && !schema.isValidSync(urlObject)) {
      return {};
    }

    const values = schema.cast(urlObject, {
      stripUnknown: true,
      assert: false,
    }) as InferType<TValidationSchema>;

    if (options?.isNeedReturnOnlySpecifiedInQueryFields === true) {
      Object.keys(values).forEach((key) => {
        if (!(key in urlObject)) {
          delete values[key];
        }
      });
    }

    return values;
  });

  return query;
};
