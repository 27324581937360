import { forwardRef, ReactNode } from "react";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import {
  DemandQueueTaskActiveBillInfo,
  DemandQueueTaskCommentContainer,
  DemandQueueTaskSeparator,
  DemandQueueTaskVariant,
  getFormattedCommentDateDemandQueueTasks,
} from "../../../../../../demand/queue-tasks";
import { CodeWithTitle } from "../../../../../../common/types/objects";
import { DemandQueueTaskCardContainer } from "../../../../../../demand/queue-tasks/components/card/DemandQueueTaskCardContainer";
import { BankGuaranteeDetailsBillComplicatedTaskBillInfo } from "../info/BankGuaranteeDetailsBillComplicatedTaskBillInfo";
import { getBankGuaranteeDetailsTaskTitle } from "../../../../utils/demand/tasks/getBankGuaranteeDetailsTaskTitle";
import { GuaranteeQueueTasksGroupType } from "../../../../../../common/types/queue-tasks";
import { BankGuaranteeDetailsComplicatedTasksLabelWithFile } from "../../../common/components/BankGuaranteeDetailsComplicatedTasksLabelWithFile";
import { BankGuaranteeDetailsComplicatedTaskCard } from "../../../common/components/BankGuaranteeDetailsComplicatedTaskCard";
import { BankGuaranteeDetailsComplicatedTaskDateCreatedPlacement } from "../../../common/types/BankGuaranteeDetailsComplicatedTaskDateCreatedPlacement";

export interface BankGuaranteeDetailsComplicatedTaskBillCardProps extends BaseCssProps {
  isFocused?: boolean;
  billInfo?: DemandQueueTaskActiveBillInfo;
  variant: DemandQueueTaskVariant;
  dateCreated?: Date;
  dateCreatedPlacement: BankGuaranteeDetailsComplicatedTaskDateCreatedPlacement;
  taskTitle: string;
  taskGroupType?: CodeWithTitle<GuaranteeQueueTasksGroupType>;
  managerComment?: ReactNode;
  children: ReactNode;
  isPinned?: boolean;
}

export const BankGuaranteeDetailsComplicatedTaskBillCard = forwardRef<
  HTMLDivElement,
  BankGuaranteeDetailsComplicatedTaskBillCardProps
>(
  (
    {
      isFocused,
      isPinned,
      className,
      variant,
      taskGroupType,
      taskTitle,
      billInfo,
      dateCreated,
      dateCreatedPlacement,
      managerComment,
      children,
    },
    ref,
  ) => {
    return (
      <BankGuaranteeDetailsComplicatedTaskCard
        className={className}
        variant={variant}
        title={getBankGuaranteeDetailsTaskTitle(taskTitle, taskGroupType)}
        date={dateCreatedPlacement === "card" ? dateCreated : undefined}
        ref={ref}
        isFocused={isFocused}
        isPinned={isPinned}
      >
        {managerComment}
        {billInfo && (
          <>
            <DemandQueueTaskCommentContainer
              title="Стоимость БГ"
              date={
                dateCreatedPlacement === "content" ? getFormattedCommentDateDemandQueueTasks(dateCreated) : undefined
              }
            >
              <DemandQueueTaskCardContainer padding="none">
                <BankGuaranteeDetailsBillComplicatedTaskBillInfo
                  costFinal={billInfo.costFinal}
                  costBase={billInfo.costBase}
                  rewardAgent={billInfo.rewardAgent}
                  excessAgent={billInfo.excessAgent}
                  discountBank={billInfo.discountBank}
                  discountAgent={billInfo.discountAgent}
                />
              </DemandQueueTaskCardContainer>
            </DemandQueueTaskCommentContainer>
            <DemandQueueTaskSeparator />
          </>
        )}
        {billInfo?.billFile && (
          <>
            <BankGuaranteeDetailsComplicatedTasksLabelWithFile
              label="Счет на оплату"
              date={dateCreatedPlacement === "file" ? dateCreated : undefined}
              file={billInfo?.billFile}
            />
            <DemandQueueTaskSeparator />
          </>
        )}
        {children}
      </BankGuaranteeDetailsComplicatedTaskCard>
    );
  },
);
