import { Box } from "../../../common/ui-kit/containers/box";
import { ProductDetailsDocumentsHeader } from "../../../common/product-details";
import { CreditExecutionDetailsDocuments } from "../component/documents/CreditExecutionDetailsDocuments";

export const CreditExecutionDetailsDocumentsPage = () => {
  return (
    <Box display="flex" flexDirection="column" flexGrow={1} flexShrink={1} overflow="hidden">
      <ProductDetailsDocumentsHeader />
      <CreditExecutionDetailsDocuments />
    </Box>
  );
};
