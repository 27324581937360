import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../common/store";
import { BankGuaranteeListFiltersState } from "./bankGuaranteeListFilters.types";
import { createFuseSearchSelector } from "../../../../common/store/utils";
import { CodeWithTitle } from "../../../../common/types/objects";
import { BankGuaranteeFiltersLists } from "../../types/filters/BankGuaranteeFiltersLists";
import { bankSearchTransformer } from "../../../../common/utils/bankSearchTransformer";

export const selectBankGuaranteeListFilters = (state: RootState): BankGuaranteeListFiltersState =>
  state.domains.bankGuarantee.list.filters;

export const selectBankGuaranteeListFiltersBanks = createFuseSearchSelector<CodeWithTitle>({
  selector: (state) => selectBankGuaranteeListFilters(state).data?.banks.codeWithTitleList ?? [],
  ignoreLessThan: 0.55,
  keys: ["title"],
  searchTransformer: bankSearchTransformer,
});

export const selectBankGuaranteeListFiltersBanksCodes = createSelector(
  [(state) => state, (state, search: string) => search],
  (state, search) => {
    return selectBankGuaranteeListFiltersBanks(state, search).map((x) => x.code);
  },
);

export const selectBankGuaranteeListFiltersMaxLength = createSelector(
  [(state) => state, (_, key: keyof BankGuaranteeFiltersLists) => key],
  (state, key) => {
    const titles = selectBankGuaranteeListFilters(state).data?.[key].titles;
    const values = Object.values(titles ?? {});

    return Math.max(...values.map((x) => x.replace(/\s/g, "").length));
  },
);
