import { memo } from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditExecutionListTabs } from "./CreditExecutionListTabs";
import { useCreditExecutionListFilterContext } from "../../../hooks/filters/useCreditExecutionListFilterContext";
import { filterTabType, useFiltersTabCounterGetValue } from "../../../../../common/filters/use-cases";
import { selectCreditExecutionListCounters } from "../../../store/counters/creditExecutionListCounters.selectors";
import { useCreditExecutionListFilterOnTabChange } from "../../../hooks/filters/useCreditExecutionListFilterOnTabChange";

export type CreditExecutionListTabFilterProps = BaseCssProps;

export const CreditExecutionListTabFilter = memo(({ className }: CreditExecutionListTabFilterProps) => {
  const getTabCounterValue = useFiltersTabCounterGetValue((state) => selectCreditExecutionListCounters(state).data);
  const filteringObject = useCreditExecutionListFilterContext();
  const onTabChange = useCreditExecutionListFilterOnTabChange();

  return (
    <CreditExecutionListTabs.Container activeTab={filteringObject.tab} onTabChange={onTabChange} className={className}>
      {filterTabType.values.map((x) => (
        <CreditExecutionListTabs.Tab
          key={x}
          tab={x}
          text={filterTabType.getTitle(x)}
          xsFlexBasis="auto"
          counterValue={getTabCounterValue(x)}
        />
      ))}
    </CreditExecutionListTabs.Container>
  );
});
