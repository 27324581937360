import styled from "@emotion/styled";

export const Container = styled.section<{ isTableContent?: boolean }>`
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 1.6;
  color: ${({ theme }) => theme.palette.typography.primary};
  overflow-x: ${({ isTableContent }) => (isTableContent ? "auto" : "unset")};

  .block > .section + .section {
    margin-bottom: ${({ theme }) => theme.spaces.xl};
  }

  .section .gray {
    color: ${({ theme }) => theme.palette.typography.medium};
    font-weight: 500;
  }

  .block:not(:first-of-type) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: ${({ theme }) => theme.spaces.xxl};
  }

  .title--main {
    font-size: 1.75rem;
    font-weight: 700;
  }

  .title--block {
    font-size: ${({ theme }) => theme.font.fz4};
    font-weight: 600;
    line-height: 25px;
    margin-top: 6px;

    &:only-child {
      display: none;
    }
  }

  .marker__item {
    position: relative;
    padding: 5px 0 0 20px;
    margin-bottom: 20px;
    list-style: none;
  }

  .marker__item::before {
    position: absolute;
    top: 8px;
    left: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    content: "●";
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1.4rem;
    line-height: 1;
  }

  .marker__item--Alert:before {
    color: ${({ theme }) => theme.palette.status.error};
  }

  .marker__item--Exclamation:before {
    color: ${({ theme }) => theme.palette.status.warning};
  }

  .marker__item--Tick:before {
    color: ${({ theme }) => theme.palette.status.success};
  }

  .marker__title {
    font-weight: 600;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  .list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .list__item {
    position: relative;
    padding: 5px 0 0 20px;
    list-style-type: none;
  }

  .list__item::before {
    position: absolute;
    top: 4px;
    left: 0;
    display: block;
    content: "—";
  }

  .panel {
    margin-bottom: 20px;
  }

  .panel-body > table > tbody > tr > td {
    padding-right: 20px !important;
  }

  .panel-body > table > tbody > tr > td:nth-of-type(2) {
    width: 20% !important;
  }

  .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    color: ${({ theme }) => theme.palette.typography.primary};
    font-size: ${({ theme }) => theme.font.fz3};
    line-height: 1.6;
  }

  .table td,
  .table th {
    padding: ${({ theme }) => theme.spaces.md};
    vertical-align: top;
  }

  .table tr:not(:first-of-type) {
    & > th,
    & > td {
      border-top: 1px solid ${({ theme }) => theme.colors.secondaryLight};
    }
  }

  .panel-body > table > tbody > tr > td.w-50 {
    width: 50% !important;
  }

  .panel-body > table > tbody > tr > td.w-75 {
    width: 75% !important;
  }

  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }

  .h5,
  h5 {
    font-size: 1.25rem;
    font-weight: bold;
  }

  span.good {
    color: limegreen;
  }

  span.bad {
    color: red;
  }

  #printBtn {
    display: none;
  }

  button[data-toggle="collapse"] {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-bottom: 10px;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.typography.primary};
  }

  .collapse {
    margin-left: ${({ theme }) => theme.spaces.md};
  }
`;
