import styled from "@emotion/styled";
import { appLayoutConfigs } from "../../configs";

export interface SidebarOpenedProps {
  sidebarOpened: boolean;
}

export const Header = styled.header<{ fixed?: boolean }>`
  height: ${({ theme }) => theme.layout.header.height};
  max-height: ${({ theme }) => theme.layout.header.height};
  padding: 0 calc(${({ theme }) => theme.layout.borderPadding} / 1.5);
  background-color: ${({ theme }) => theme.layout.header.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: ${({ fixed }) => (fixed ? "fixed" : "relative")};
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.layout.zIndex + 1};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    position: relative;
    z-index: ${({ theme }) => theme.layout.header.zIndex};
    padding: 0 ${({ theme }) => theme.layout.borderPadding};
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  align-items: stretch;
  min-block-size: calc(100vh - ${({ theme }) => theme.layout.header.height});
`;

export const Main = styled.main<{ isFixedHeader: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${({ theme, isFixedHeader }) => (isFixedHeader ? theme.layout.header.height : 0)} 0 0 0;
  margin: 0;
  z-index: ${({ theme }) => theme.layout.zIndex};
  flex-grow: 1;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding: 0;
  }
`;

export const Aside = styled.aside<SidebarOpenedProps>`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: column;
  overflow: auto;
  position: fixed;
  left: 0;
  top: ${({ theme }) => theme.layout.header.height};
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.layout.sidebar.zIndex};
  background-color: ${({ theme }) => theme.layout.sidebar.backgroundColor};
  visibility: ${({ sidebarOpened }) => (sidebarOpened ? "visible" : "hidden")};
  transform: ${({ sidebarOpened }) => (sidebarOpened ? "translateX(0px)" : "translateX(-110vw)")};
  transition: ${({ sidebarOpened }) =>
    sidebarOpened
      ? "transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)"
      : "transform 0.6s cubic-bezier(0.16, 1, 0.3, 1), visibility 0s linear 0.6s"};

  ${({ theme }) => theme.breakpoints.mqUp("lg")} {
    width: 390px;
    right: 0;
    left: inherit;
    transform: ${({ sidebarOpened }) => (sidebarOpened ? "translateX(0px)" : "translateX(110vw)")};
  }

  ${({ theme }) => theme.breakpoints.mqUp("xl")} {
    width: 270px;
    transform: translateX(0px);
    visibility: visible;
    overflow: hidden;
    height: auto;
    position: inherit;
    right: inherit;
    left: inherit;
    top: inherit;
    bottom: inherit;

    // TODO: решение со скрытием aside между рендерами полной и не полной страницы без глобального класса
    body.${appLayoutConfigs.fullPageBodyClassName} & {
      display: none;
    }
  }
`;

export const AsideFooter = styled.footer`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  padding: ${({ theme }) => theme.spaces.md};
`;
