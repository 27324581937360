import { useEffect, useState } from "react";
import { BankGuaranteeCreationFormStages } from "../../types/creation/BankGuaranteeCreationFormStages";
import { useBankGuaranteeCreationForm } from "./useBankGuaranteeCreationForm";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeCreationClientSearch } from "../../store/bankGuaranteeCreation.selectors";
import { clientRules, ClientSearchCheckedModel } from "../../../../client-search";
import { GuaranteeTypeCode } from "../../../../common/types/demand";

const getActualStage = (
  clientCheck: ClientSearchCheckedModel | undefined,
  guaranteeType: GuaranteeTypeCode | undefined
): BankGuaranteeCreationFormStages => {
  if (clientCheck !== undefined) {
    if (clientRules.isClientPassing(clientCheck)) {
      return guaranteeType !== undefined ? "contract" : "guaranteeType";
    }
  }

  return "client";
};

export const useBankGuaranteeCreationCreatingFormStage = () => {
  const { check } = useAppSelector(selectBankGuaranteeCreationClientSearch);
  const {
    values: { guaranteeType },
  } = useBankGuaranteeCreationForm();

  const [stage, setStage] = useState<BankGuaranteeCreationFormStages>(getActualStage(check?.data, guaranteeType));

  useEffect(() => {
    setStage(getActualStage(check?.data, guaranteeType));
  }, [check, guaranteeType]);

  return stage;
};
