import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BankGuaranteeDetailsDemandReconciliationProjectContent } from "../../../reconciliation/project/BankGuaranteeDetailsDemandReconciliationProjectContent";
import { BankGuaranteeDetailsDemandHeaderReconciliationProjectNavigationCard } from "./BankGuaranteeDetailsDemandHeaderReconciliationProjectNavigationCard";
import { useBankGuaranteeDetailsReconciliationState } from "../../../../../hooks/reconciliation/useBankGuaranteeDetailsReconciliationState";
import { selectBankGuaranteeDetailsActiveDemandActualProject } from "../../../../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { BankGuaranteeDetailsDemandReconciliationProjectContentSkeleton } from "../../../reconciliation/project/BankGuaranteeDetailsDemandReconciliationProjectContent.skeleton";
import { BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardSkeleton } from "../card/BankGuaranteeDetailsDemandHeaderReconciliationNavigationCard.mobile.skeleton";
import { BankGuaranteeDetailDemandHeaderReconciliationCardDesktop } from "../card/BankGuaranteeDetailDemandHeaderReconciliationCard.desktop";

export const BankGuaranteeDetailsDemandHeaderReconciliationProject = () => {
  const { model, needShowSkeleton } = useBankGuaranteeDetailsReconciliationState(
    selectBankGuaranteeDetailsActiveDemandActualProject,
  );

  return (
    <ThemeMobileDesktopView
      mobile={
        <>
          {needShowSkeleton && <BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardSkeleton />}
          {!needShowSkeleton && <BankGuaranteeDetailsDemandHeaderReconciliationProjectNavigationCard model={model} />}
        </>
      }
      desktop={
        <>
          {needShowSkeleton && (
            <BankGuaranteeDetailDemandHeaderReconciliationCardDesktop>
              <BankGuaranteeDetailsDemandReconciliationProjectContentSkeleton />
            </BankGuaranteeDetailDemandHeaderReconciliationCardDesktop>
          )}
          {!needShowSkeleton && model !== undefined && (
            <BankGuaranteeDetailDemandHeaderReconciliationCardDesktop>
              <BankGuaranteeDetailsDemandReconciliationProjectContent model={model} />
            </BankGuaranteeDetailDemandHeaderReconciliationCardDesktop>
          )}
        </>
      }
    />
  );
};
