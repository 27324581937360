import { createSlice, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { CreditExecutionListItemsState } from "./creditExecutionListItems.types";
import { getLoadingStatusFromAction, isAborted, LoadingStatusEnum } from "../../../../common/types/state";
import { creditExecutionListItemsMapper } from "./creditExecutionListItems.mapper";
import { CreditExecutionId } from "../../../../common/types/demand";
import { CREDIT_EXECUTION_LIST_SLICE_NAME, getCreditExecutionList } from "../creditExecutionList.thunks";
import {
  creditExecutionListItemsAdapter,
  creditExecutionListItemsClientsAdapter,
  creditExecutionListItemsDemandsAdapter,
} from "./creditExecutionListItems.adapter";
import { GetCreditExecutionListItemOutputDto } from "../../api/dto/output/GetCreditExecutionListOutputDto";

const initialState: CreditExecutionListItemsState = {
  pagination: undefined,
  demands: creditExecutionListItemsDemandsAdapter.getInitialState(),
  clients: creditExecutionListItemsClientsAdapter.getInitialState(),
  creditExecution: creditExecutionListItemsAdapter.getInitialState(),
  status: LoadingStatusEnum.Idle,
};

const slice = createSlice({
  name: combineSliceNames(CREDIT_EXECUTION_LIST_SLICE_NAME, "items"),
  initialState,
  reducers: {
    changeItem: (
      state,
      action: PayloadAction<{ id: CreditExecutionId; item: GetCreditExecutionListItemOutputDto | undefined }>,
    ) => {
      const actualValue = state.creditExecution.entities[action.payload.id];

      if (actualValue) {
        if (action.payload.item === undefined) {
          const { demandIds } = actualValue;
          creditExecutionListItemsDemandsAdapter.removeMany(state.demands, demandIds);
          creditExecutionListItemsAdapter.removeOne(state.creditExecution, action.payload.id);
        } else {
          const { product, demands, client } = creditExecutionListItemsMapper.mapItem(action.payload.item);
          creditExecutionListItemsAdapter.upsertOne(state.creditExecution, product);
          creditExecutionListItemsDemandsAdapter.upsertMany(state.demands, demands);
          creditExecutionListItemsClientsAdapter.upsertOne(state.clients, client);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCreditExecutionList.pending, (state, action) => {
        if (state.pagination && action.meta.arg && action.meta.arg.page <= state.pagination.pageNumber) {
          creditExecutionListItemsAdapter.removeAll(state.creditExecution);
          creditExecutionListItemsDemandsAdapter.removeAll(state.demands);
          creditExecutionListItemsClientsAdapter.removeAll(state.clients);
          state.pagination = undefined;
        }
      })
      .addCase(getCreditExecutionList.fulfilled, (state, action) => {
        const { clients, demands, creditExecution } = creditExecutionListItemsMapper.mapList(action.payload.body.data);
        if (action.payload.body.paginationInformation?.pageNumber === 1) {
          creditExecutionListItemsAdapter.setAll(state.creditExecution, creditExecution);
          creditExecutionListItemsDemandsAdapter.setAll(state.demands, demands);
          creditExecutionListItemsClientsAdapter.setAll(state.clients, clients);
        } else {
          creditExecutionListItemsAdapter.upsertMany(state.creditExecution, creditExecution);
          creditExecutionListItemsDemandsAdapter.upsertMany(state.demands, demands);
          creditExecutionListItemsClientsAdapter.upsertMany(state.clients, clients);
        }

        state.pagination = action.payload.body.paginationInformation;
      })
      .addCase(getCreditExecutionList.rejected, (state, action) => {
        state.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(getCreditExecutionList), (state, action) => {
        const status = getLoadingStatusFromAction(action);
        if (!isAborted(status)) {
          state.status = getLoadingStatusFromAction(action);
        }
      });
  },
});

export const { changeItem: changeItemCreditExecutionListItems } = slice.actions;

export const creditExecutionListItemsReducer = slice.reducer;
