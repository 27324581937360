import { useEffect, useState } from "react";
import { CreditExecutionId } from "../../../common/types/demand";
import { useProductDetailsQueueTasksNeedUpdateListener } from "../../../common/product-details";
import { creditExecutionDetailsReloadActiveProduct } from "../store/creditExecutionDetails.actions";
import { useAppSelector } from "../../../common/store";
import { selectCreditExecutionDetailsProductDetails } from "../store/product-details/creditExecutionDetailsProductDetails.selectors";

export const useCreditExecutionDetailsQueueTasksNeedUpdateListener = (creditExecutionId: CreditExecutionId) => {
  const [lastProductUpdateTime, setLastProductUpdateTime] = useState<number>(0);
  const { lastSuccessRequestId } = useAppSelector(selectCreditExecutionDetailsProductDetails);

  useEffect(() => {
    if (lastSuccessRequestId !== undefined) {
      setLastProductUpdateTime(Date.now());
    }
  }, [lastSuccessRequestId]);

  useProductDetailsQueueTasksNeedUpdateListener(
    creditExecutionId,
    "CreditExecution",
    creditExecutionDetailsReloadActiveProduct,
    lastProductUpdateTime
  );
};
