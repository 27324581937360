import { JSX } from "react";
import { TransitionGroup } from "react-transition-group";
import { useCombobox } from "downshift";
import { FormSearchInput } from "@sideg/ui-kit/atoms/form-controls/search-input";
import { useAppSelector } from "../../../common/store";
import { ClientId } from "../../../common/types/demand";
import { SearchClientListItem } from "../list-item/SearchClientListItem";
import { SimpleOpacityTransition } from "../../../common/ui-kit/animations/transitions";
import * as S from "./SearchClientInput.styled";
import { ClientSearchClientStateSelector } from "../../types/ClientSearchClientStateSelector";
import { getClientSearchSelectors } from "../../store/clientSearch.selectors";
import { SearchClientFindAlert } from "../alerts/SearchClientFindAlert";

export interface BankGuaranteeSearchClientInputProps {
  className?: string;
  value: string | undefined;
  onChange: JSX.IntrinsicElements["input"]["onChange"];
  onPaste: JSX.IntrinsicElements["input"]["onPaste"];
  onSelect: (clientId: ClientId) => void | Promise<void>;
  clientStateSelector: ClientSearchClientStateSelector;
  handleClean?: () => void;
}

export const SearchClientInput = ({
  className,
  onSelect,
  clientStateSelector,
  value,
  onChange,
  onPaste,
  handleClean,
}: BankGuaranteeSearchClientInputProps) => {
  const ids = useAppSelector(getClientSearchSelectors(clientStateSelector).selectIds);

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    items: ids,
    isOpen: true,
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) {
        onSelect(changes.selectedItem as ClientId);
      }
    },
  });

  return (
    <S.Wrapper className={className}>
      <div>
        <FormSearchInput
          {...getInputProps()}
          placeholder="ИНН или наименование"
          onChange={onChange}
          onPaste={onPaste}
          value={value ?? ""}
          enterKeyHint="search"
          spellCheck={false}
          autoComplete="off"
          autoCorrect="off"
          handleClean={handleClean}
        />
      </div>
      <SearchClientFindAlert clientStateSelector={clientStateSelector} inputSearchValue={value} />
      <TransitionGroup component={S.ItemsUl} {...getMenuProps()}>
        {ids.map((x, index) => (
          <SimpleOpacityTransition key={x} timeout={500}>
            <SearchClientListItem
              clientStateSelector={clientStateSelector}
              {...getItemProps({ item: x, index })}
              highlighted={highlightedIndex === index}
              id={x as ClientId}
            />
          </SimpleOpacityTransition>
        ))}
      </TransitionGroup>
    </S.Wrapper>
  );
};
