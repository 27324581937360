import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { FileDownloadLabel } from "../../../../../../files/download";
import { BaseFile } from "../../../../../../common/types/files";
import { BankGuaranteeDetailsDemandReconciliationContent } from "../content/BankGuaranteeDetailsDemandReconciliationContent";

export interface BankGuaranteeDetailsDemandReconciliationBillFileProps extends BaseCssProps {
  file: BaseFile;
}

export const BankGuaranteeDetailsDemandReconciliationBillFile = ({
  file,
  className,
}: BankGuaranteeDetailsDemandReconciliationBillFileProps) => {
  return (
    <BankGuaranteeDetailsDemandReconciliationContent.Block alignItems="flex-start" className={className}>
      <BankGuaranteeDetailsDemandReconciliationContent.Typography fontColor="typography.primary" fontWeight={700}>
        Счет на оплату
      </BankGuaranteeDetailsDemandReconciliationContent.Typography>
      <FileDownloadLabel fileId={file.fileId} privateKey={file.privateKey} fileName={file.fileName} />
    </BankGuaranteeDetailsDemandReconciliationContent.Block>
  );
};
