import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { Typography } from "../../../../../../common/ui-kit/typography";
import { usePublicUserRegistrationAgentDocumentApproveCountdown } from "../../../../hooks/agent/document-approve/usePublicUserRegistrationAgentDocumentApproveCountdown";

export interface PublicUserRegistrationAgentDocumentCountdownProps extends BaseCssProps {
  timeInSeconds: number;
  onTimerExpires: () => void;
}

export const PublicUserRegistrationAgentDocumentApproveCountdown = ({
  onTimerExpires,
  timeInSeconds,
  className,
}: PublicUserRegistrationAgentDocumentCountdownProps) => {
  const label = usePublicUserRegistrationAgentDocumentApproveCountdown(timeInSeconds, onTimerExpires);

  return (
    <Typography
      className={className}
      fontSize="fz2"
      fontWeight={500}
      fontColor="typography.medium"
      lineHeight="defaultLineHeight"
    >
      {label}
    </Typography>
  );
};
