import { FiltersWrapperWithSearchInput } from "../../../../../../common/filters/use-cases/components/desktop/FiltersWrapperWithSearchInput.desktop";
import { useCreditExecutionListFilterContext } from "../../../../hooks/filters/useCreditExecutionListFilterContext";
import { useAppSelector } from "../../../../../../common/store";
import { selectCreditExecutionFilters } from "../../../../store/filters/creditExecutionListFilters.selectors";
import { useProductFilterSearchInput } from "../../../../../../common/filters/use-cases";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { CreditExecutionListHeaderFiltersBanksDesktop } from "./CreditExecutionListHeaderFiltersBanks.desktop";
import { CreditExecutionListFilterContextValue } from "../../../../types/filters/CreditExecutionListFilterContextValue";
import { CreditExecutionListHeaderFiltersStatusDesktop } from "./CreditExecutionListHeaderFiltersStatus.desktop";
import { CreditExecutionListHeaderFiltersTaskTypesDesktop } from "./CreditExecutionListHeaderFiltersTaskTypes.desktop";
import { CreditExecutionListHeaderFiltersDatesDesktop } from "./CreditExecutionListHeaderFiltersDates.desktop";
import { isFilterAvailableForTabCreditExecution } from "../../../../validation/rules/isFilterAvailableForTabCreditExecution";

export type CreditExecutionListHeaderFiltersDesktopProps = BaseCssProps;

export const CreditExecutionListHeaderFiltersDesktop = ({
  className,
}: CreditExecutionListHeaderFiltersDesktopProps) => {
  const filteringObject = useCreditExecutionListFilterContext();
  const { data } = useAppSelector(selectCreditExecutionFilters);

  const { onChange, value } = useProductFilterSearchInput(filteringObject);

  const isForTab = (filter: keyof CreditExecutionListFilterContextValue) =>
    isFilterAvailableForTabCreditExecution(filteringObject.tab, filter);

  return (
    <FiltersWrapperWithSearchInput value={value} onChange={onChange} className={className}>
      {data && (
        <>
          {isForTab("bankIds") && <CreditExecutionListHeaderFiltersBanksDesktop />}
          {isForTab("status") && <CreditExecutionListHeaderFiltersStatusDesktop />}
          {isForTab("taskTypes") && <CreditExecutionListHeaderFiltersTaskTypesDesktop />}
          {isForTab("dateCreateFrom") && isForTab("dateCreateTo") && <CreditExecutionListHeaderFiltersDatesDesktop />}
        </>
      )}
    </FiltersWrapperWithSearchInput>
  );
};
