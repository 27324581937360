import { DependencyList, EffectCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useLocationPathEffect = (callBackFunction: EffectCallback, deps: DependencyList) => {
  const location = useLocation();
  const [prevPathname, setPrevPathname] = useState(location.pathname);

  useEffect(() => {
    if (prevPathname !== location.pathname) {
      callBackFunction();
    }

    setPrevPathname(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, prevPathname, ...deps]);
};
