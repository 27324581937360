import styled from "@emotion/styled";

export const BankGuaranteeListItemMobileDemandListShowButton = styled.button<{ isShown: boolean }>`
  appearance: none;
  margin: 0;
  display: flex;
  padding: ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.sm};
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.sm};
  align-items: center;
  font-weight: 500;
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  background-color: ${({ theme }) => theme.palette.control.secondaryLight};
  border: none;
  border-radius: 4px;
  align-self: flex-start;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  -webkit-tap-highlight-color: ${({ theme }) => theme.palette.control.secondaryMedium};
  user-select: none;

  & > svg {
    width: 14px;
    height: 14px;
    transform: rotate(${({ isShown }) => (isShown ? "90deg" : "-90deg")});

    & > path {
      stroke: ${({ theme }) => theme.colors.dark};
    }
  }
`;
