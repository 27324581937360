import * as S from "./CreditWorkingAssetsDetailsProductPage.styled";
import { useAppSelector } from "../../../common/store";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";
import { CreditWorkingAssetsDetailsEmpty } from "../component/empty/CreditWorkingAssetsDetailsEmpty";

export const CreditWorkingAssetsDetailsProductPage = () => {
  const { data } = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails);

  return <S.Container>{data !== undefined && <CreditWorkingAssetsDetailsEmpty />}</S.Container>;
};
