import { ScoringBankModel } from "../../../entity/models/ScoringBankModel"
import { ScoringModel }  from "../../../entity/models/ScoringModel"

import {
    SCORING_OPTIONS_FETCHING,
    SCORING_OPTIONS_FETCHED,
    SCORING_OPTIONS_FETCHING_ERROR,
    SCORING_OPTIONS_SET_INITIAL,
    SCORING_DATA_PROCESSING,
    SCORING_DATA_SUCCESS,
    SCORING_DATA_ERROR,
    SCORING_DATA_SET_INITIAL,
    ScoringDispatchTypes
} from "./scoringActionTypes"

export const scoringOptionsFetching = (): ScoringDispatchTypes => {
    return {
        type: SCORING_OPTIONS_FETCHING
    }
}

export const scoringOptionsFetched = (payload: ScoringModel): ScoringDispatchTypes => {
    return {
        type: SCORING_OPTIONS_FETCHED,
        payload
    }
}

export const scoringOptionsFetchingError = (): ScoringDispatchTypes => {
    return {
        type: SCORING_OPTIONS_FETCHING_ERROR
    }
}

export const scoringOptionstSetInitial = (): ScoringDispatchTypes => {
    return {
        type: SCORING_OPTIONS_SET_INITIAL
    }
}

export const scoringDataProcessing = (): ScoringDispatchTypes => {
    return {
        type: SCORING_DATA_PROCESSING
    }
}

export const scoringDataSuccess = (payload:ScoringBankModel): ScoringDispatchTypes => {
    return {
        type: SCORING_DATA_SUCCESS,
        payload
    }
}

export const scoringDataError = (): ScoringDispatchTypes => {
    return {
        type: SCORING_DATA_ERROR
    }
}

export const scoringDataSetInitial = (): ScoringDispatchTypes => {
    return {
        type: SCORING_DATA_SET_INITIAL
    }
}