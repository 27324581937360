import * as registration from "./serviceWorkerRegistration";
import { serviceWorkerEventsService } from "./serviceWorkerEventsService";
import { ServiceWorkerEventType } from "../types/ServiceWorkerEventType";

const dispatchEventCallback = (type: ServiceWorkerEventType) => (reg: ServiceWorkerRegistration) =>
  serviceWorkerEventsService.dispatch(type, reg);

const register = () => {
  registration.register({
    onUpdate: dispatchEventCallback("onRegistrationUpdate"),
    onSuccess: dispatchEventCallback("onRegistrationSuccess"),
    onRegister: dispatchEventCallback("onRegistrationRegister"),
  });
};

export const serviceWorkerRegistrationService = {
  register,
};
