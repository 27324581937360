import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useCreditWorkingAssetsDetailsContext } from "../../hooks/context/useCreditWorkingAssetsDetailsContext";
import { ProductDetailsDocumentsWrapper } from "../../../../common/product-details";
import { Redirect } from "../../../../common/urls/internal/components";
import { useCreditWorkingAssetsDetailsDocuments } from "../../hooks/documents/useCreditWorkingAssetsDetailsDocuments";
import { CreditWorkingAssetsDetailsDocumentsItem } from "./CreditWorkingAssetsDetailsDocumentsItem";

export type CreditWorkingAssetsDetailsDocumentsProps = BaseCssProps;

export const CreditWorkingAssetsDetailsDocuments = ({ className }: CreditWorkingAssetsDetailsDocumentsProps) => {
  const { creditWorkingAssetsId } = useCreditWorkingAssetsDetailsContext();
  const { ids, status, isNeedToHide } = useCreditWorkingAssetsDetailsDocuments();

  if (isNeedToHide) {
    return <Redirect to={(x) => x.creditWorkingAssets.details.product} params={{ creditWorkingAssetsId }} withSearch />;
  }

  return (
    <ProductDetailsDocumentsWrapper className={className} ids={ids} status={status}>
      {(x) => <CreditWorkingAssetsDetailsDocumentsItem key={x} documentId={x} />}
    </ProductDetailsDocumentsWrapper>
  );
};
