import styled from "@emotion/styled";
import { PRODUCT_CARD_CONTAINER_LEFT_MARGIN_PX } from "../container/ProductListCardContainer.desktop.constants";

const BUTTON_WIDTH_PX = 28;

export const Button = styled.button<{ isShow: boolean }>`
  background-color: ${({ theme }) => theme.palette.control.secondaryLight};
  color: ${({ theme }) => theme.palette.typography.medium};
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 2px ${({ theme }) => theme.spaces.xs};
  width: ${({ isShow }) => (isShow ? "100%" : `${BUTTON_WIDTH_PX}px`)};
  height: ${({ isShow }) => (isShow ? "100%" : `${BUTTON_WIDTH_PX}px`)};
  margin-left: ${({ isShow }) => (!isShow ? `-${PRODUCT_CARD_CONTAINER_LEFT_MARGIN_PX + BUTTON_WIDTH_PX / 2}px` : "0")};
  cursor: pointer;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.xs};

  transition: all 0.2s linear;

  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  & > svg {
    height: 16px;
    width: 16px;
    transform: ${({ isShow }) => (isShow ? "rotate(180deg)" : "unset")};

    & > path {
      stroke: ${({ theme }) => theme.palette.typography.medium};
    }
  }
`;
