import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../types/utils";

const titles = {
  success: "можно отправить заявку",
  reject: "отказ",
  duplicate: "дубли предыдущей заявки",
};

export const productCreationScoringResultType = makeEnumLikeLiteral(["success", "reject", "duplicate"], titles);

export type ProductCreationScoringResultType = EnumLikeLiteralType<typeof productCreationScoringResultType>;

export type ProductCreationScoringResultCapitalizedType = Capitalize<ProductCreationScoringResultType>;
