import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeCreationPurchase } from "../../../store/bankGuaranteeCreation.selectors";
import { isSucceeded } from "../../../../../common/types/state";
import { useBankGuaranteeCreationForm } from "../useBankGuaranteeCreationForm";
import { BankGuaranteeCreationFromValues } from "../../../types/BankGuaranteeCreationFormValues";
import { GuaranteeTypeCode } from "../../../../../common/types/demand";

export const useBankGuaranteeCreationPurchaseFieldsMutateEffect = () => {
  const [prevGuaranteeType, setPrevGuaranteeType] = useState<GuaranteeTypeCode | undefined>(undefined);
  const { status, data, fetchedPurchaseNumber } = useAppSelector(selectBankGuaranteeCreationPurchase);
  const {
    setValues,
    values: { guaranteeType },
  } = useBankGuaranteeCreationForm();

  useEffect(() => {
    setPrevGuaranteeType(undefined);
  }, [fetchedPurchaseNumber]);

  useEffect(() => {
    if (isSucceeded(status) && data) {
      if (prevGuaranteeType !== undefined && guaranteeType !== prevGuaranteeType) {
        setValues((prev) => {
          const newValues = {
            contractPrepaidSum: data.sumPrepaid,
            guaranteeSum: data.sumByGuaranteeType?.[prev.guaranteeType] ?? undefined,
            enforcementSum: data.sumEnforcement?.[prev.guaranteeType] ?? undefined,
            dateEvent: data.dateEvent?.[prev.guaranteeType] ? new Date(data.dateEvent[prev.guaranteeType]) : "",
          } as BankGuaranteeCreationFromValues;

          return { ...prev, ...newValues };
        }, true);
      }

      setPrevGuaranteeType(guaranteeType);
    }
  }, [data, guaranteeType, prevGuaranteeType, setValues, status]);
};
