import { dateTimeHelper } from "@sideg/helpers";

const getPeriodInDays = (dateFrom: Date, dateTo: Date) => {
  return (
    dateTimeHelper.getDifferenceInDays(
      dateTimeHelper.unaryModify("startOfDay", dateFrom),
      dateTimeHelper.unaryModify("startOfDay", dateTo),
    ) + 1
  );
};

const getDateTo = (dateFrom: Date, daysCount: number) => {
  return dateTimeHelper.simpleModify("addDays", dateFrom, daysCount - 1);
};

const getDateFrom = (dateTo: Date, daysCount: number) => {
  return dateTimeHelper.simpleModify("subDays", dateTo, daysCount - 1);
};

export const widgetsCalculatorPeriodsCalculationService = {
  getPeriodInDays,
  getDateTo,
  getDateFrom,
};
