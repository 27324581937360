import { forwardRef, ReactNode } from "react";
import * as S from "@sideg/ui-kit/atoms/checkbox/components/checkbox/FormCheckbox.styled";
import { Typography } from "@sideg/ui-kit/atoms/typography";
import { CheckboxSize } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";
import { FormCheckboxIcon } from "@sideg/ui-kit/atoms/checkbox/components/checkbox/FormCheckboxIcon";
import {
  getCheckboxLabelColor,
  getCheckboxLabelVariant,
} from "@sideg/ui-kit/atoms/checkbox/utils/getCheckboxLabelProperties";

export interface FormCheckboxProps extends Omit<JSX.IntrinsicElements["input"], "type" | "ref" | "size" | "disabled"> {
  label: ReactNode;
  checkboxSize?: CheckboxSize;
  isDisabled?: boolean;
}

export const FormCheckbox = forwardRef<HTMLInputElement, FormCheckboxProps>(
  ({ label, isDisabled = false, checkboxSize = "default", ...rest }, ref) => {
    return (
      <S.Label isDisabled={isDisabled}>
        <S.CheckboxInputContainer checkboxSize={checkboxSize}>
          <S.CheckboxInput type="checkbox" disabled={isDisabled} checkboxSize={checkboxSize} ref={ref} {...rest} />
          <FormCheckboxIcon checkboxSize={checkboxSize} isDisabled={isDisabled} />
        </S.CheckboxInputContainer>
        <Typography fontColor={getCheckboxLabelColor(isDisabled)} variant={getCheckboxLabelVariant(checkboxSize)}>
          {label}
        </Typography>
      </S.Label>
    );
  },
);
