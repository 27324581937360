import { useField } from "formik";
import { useMemo } from "react";
import { FileTemp } from "../types/FileTemp";
import { generateFileTempId } from "../types/FileTempId";

export const useHandleFileFieldChange = (fieldName: string) => {
  const [field, , { setValue }] = useField(fieldName);

  return useMemo(
    () => ({
      files: field.value as FileTemp[],
      handleFileChange: async (files: File[]) => {
        if (files.length > 0) {
          await setValue([
            ...Array.from(field.value),
            ...Array.from(files ?? []).map(
              (x): FileTemp => ({ id: generateFileTempId(), file: x, dateAdded: new Date() }),
            ),
          ]);
        }
      },
      removeFile: async (x: FileTemp) => {
        await setValue(field.value.filter((y: FileTemp) => y.id !== x.id));
      },
    }),
    [field.value, setValue],
  );
};
