import { useMemo } from "react";
import { TransitionGroup } from "react-transition-group";
import { useAppSelector } from "../../../../../common/store";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { CreditExecutionDetailsAsideDemandsListItem } from "./CreditExecutionDetailsAsideDemandsListItem";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useCreditExecutionDetailsContext } from "../../../hooks/context/useCreditExecutionDetailsContext";
import { selectCreditExecutionDetailsProductDetails } from "../../../store/product-details/creditExecutionDetailsProductDetails.selectors";
import { selectCreditExecutionDetailsDemandsIds } from "../../../store/demands/creditExecutionDetailsDemands.selectors";
import {
  ProductDetailsAsideNavigationCardSkeleton,
  getProductDetailsAsideNavigationCardSkeletonItems,
} from "../../../../../common/product-details";

export type CreditExecutionDetailsAsideDemandsListProps = BaseCssProps;

export const CreditExecutionDetailsAsideDemandsList = ({ className }: CreditExecutionDetailsAsideDemandsListProps) => {
  const { creditExecutionId } = useCreditExecutionDetailsContext();
  const { status, isTransferred } = useAppSelector(selectCreditExecutionDetailsProductDetails);
  const demandIds = useAppSelector(selectCreditExecutionDetailsDemandsIds);

  const skeletonItems: number[] = useMemo(() => {
    return getProductDetailsAsideNavigationCardSkeletonItems(status, isTransferred, demandIds.length);
  }, [demandIds.length, isTransferred, status]);

  return (
    <StackPanel gap="sm" direction="column" className={className}>
      <TransitionGroup component={null}>
        {demandIds.map((x) => (
          <SimpleOpacityTransition key={x} timeout={250}>
            <CreditExecutionDetailsAsideDemandsListItem creditExecutionId={creditExecutionId} demandId={x} />
          </SimpleOpacityTransition>
        ))}
      </TransitionGroup>
      {skeletonItems.map((x) => (
        <ProductDetailsAsideNavigationCardSkeleton height={90} key={x} />
      ))}
    </StackPanel>
  );
};
