import styled from "@emotion/styled";
import { BaseButton } from "@sideg/ui-kit/atoms/button/components/common/BaseButton";
import { CommonInputIcon } from "@sideg/ui-kit/atoms/form-controls/input/common/components/CommonInput";
import { CalendarIcon } from "@sideg/ui-kit/atoms/icons";

export const PickerButtonWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: ${({ theme }) => theme.spacing.sm};

  &:focus-within {
    & svg > path {
      stroke: ${({ theme }) => theme.palette.light.primary.text.default};
    }
  }
`;

export const PickerButton = BaseButton.withComponent("button");

export const ControlCalendarIcon = styled(CommonInputIcon.withComponent(CalendarIcon))`
  &:disabled {
    cursor: default;
  }

  & > path {
    stroke: ${({ theme }) => theme.palette.light.neutral.text.primary};
  }
`;

export const DatePickerInput = styled.input`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  &::-webkit-clear-button {
    display: none;
  }

  &:disabled,
  &:disabled::-webkit-calendar-picker-indicator {
    cursor: default;
  }
`;
