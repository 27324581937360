import { CreditWorkingAssetsId } from "../../../../../common/types/demand";
import { useProductRejectionModalPanel } from "../../../../../common/product-rejection";
import { useCreditWorkingAssetsListItemModificationView } from "../../../hooks/item-modification/useCreditWorkingAssetsListItemModificationView";
import { useAppSelector } from "../../../../../common/store";
import { selectCreditWorkingAssetsListItemsById } from "../../../store/items/creditWorkingAssetsListItems.selectors";
import { CreditWorkingAssetsRejectionProduct } from "../../../../common/rejection";

export interface CreditWorkingAssetsListItemRejectProductProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
}

export const CreditWorkingAssetsListItemRejectProduct = ({
  creditWorkingAssetsId,
}: CreditWorkingAssetsListItemRejectProductProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const item = useAppSelector((state) => selectCreditWorkingAssetsListItemsById(state, creditWorkingAssetsId))!;
  const modalState = useProductRejectionModalPanel();
  const {
    isModalOpen,
    closeModal,
    onSuccess: afterSuccess,
  } = useCreditWorkingAssetsListItemModificationView(creditWorkingAssetsId, modalState, "Заявка успешно отменена");

  return (
    <CreditWorkingAssetsRejectionProduct
      isOpen={isModalOpen}
      onClose={closeModal}
      afterSuccess={afterSuccess}
      creditWorkingAssetsId={creditWorkingAssetsId}
      groupProductName={item.groupProductName}
    />
  );
};
