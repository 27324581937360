import { joinUrlParts } from "../../../common/urls/internal/utils";
import { PUBLIC_USER_URL_ROOT_PATH } from "../../common/urls";
import { AppUrlTypeEnum } from "../../../common/urls/internal/types";
import { makePublicOrCommonUrl } from "../../../common/urls/internal/utils/makeAppUrl";

export const publicUserResetPasswordUrls = {
  forgotPassword: makePublicOrCommonUrl(
    joinUrlParts(PUBLIC_USER_URL_ROOT_PATH, "forgot-password"),
    "/account/forgot-password",
    AppUrlTypeEnum.Public,
  ),
  /**
   * @deprecated Используется для совместимости со старыми письмами
   */
  resetPasswordLegacy: makePublicOrCommonUrl(
    joinUrlParts(PUBLIC_USER_URL_ROOT_PATH, "reset-password-init"),
    "/account/reset-password-init",
    AppUrlTypeEnum.Public,
  ),
  /**
   * @deprecated Используется для совместимости со старыми письмами
   */
  resetPasswordApproveLegacy: makePublicOrCommonUrl(
    joinUrlParts(PUBLIC_USER_URL_ROOT_PATH, "ForgotPassword"),
    "/account/ForgotPassword",
    AppUrlTypeEnum.Public,
  ),
};
