import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../utils";

const titles = {
  BankGuarantee: "Банковская гарантия",
  CreditExecution: "Кредит на исполнение контракта",
  ReportAgent: "Акт-сверка",
  CreditWorkingAssets: "Кредит на пополнение оборотных средств",
  AgencyRegistration: "Регистрация агентства",
};

export const productType = makeEnumLikeLiteral(
  ["BankGuarantee", "CreditExecution", "ReportAgent", "CreditWorkingAssets", "AgencyRegistration"] as const,
  titles
);

export type ProductType = EnumLikeLiteralType<typeof productType>;
