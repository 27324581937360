import * as React from "react";
import { FullPageContainerMainContentWrapper } from "./main/FullPageContainerMainContentWrapper";
import { FullPageContainerCenteredFooter } from "./footer/FullPageContainerCenteredFooter";
import { FullPageContainerMainContentCenteredContainer } from "./main/FullPageContainerMainContentCenteredContainer";

export interface FullPageContainerCenteredMainWithFooterProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export const FullPageContainerCenteredMainWithFooter = React.memo(
  ({ children, footer }: FullPageContainerCenteredMainWithFooterProps) => {
    return (
      <>
        <FullPageContainerMainContentWrapper>
          <FullPageContainerMainContentCenteredContainer gap="xxl" gapMultiplier={1.5}>
            {children}
          </FullPageContainerMainContentCenteredContainer>
        </FullPageContainerMainContentWrapper>

        <FullPageContainerCenteredFooter>{footer}</FullPageContainerCenteredFooter>
      </>
    );
  },
);
