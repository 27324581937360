import styled, { CSSObject } from "@emotion/styled";
import { css } from "@emotion/react";
import { Theme } from "@sideg/ui-kit";
import { FilteringBaseOrderType } from "../../../../filters";
import { StyledContainerWithCustomScroll } from "../../../../ui-kit/containers/components/StyledContainerWithCustomScroll";

export const TableContainer = styled(StyledContainerWithCustomScroll)<{
  columnsSize: CSSObject["gridTemplateColumns"][];
  isGrabbing: boolean;
}>`
  display: grid;
  margin-top: ${({ theme }) => theme.spaces.xxl};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  grid-template-columns:${({ columnsSize }) => {
    return columnsSize.join(" ");
  }};
  overflow-x: auto;
  font-weight: 400;
  cursor: ${({ isGrabbing }) => (isGrabbing ? "grabbing" : "grab")}
}

;
`;

export const TitleContainer = styled.div`
  display: contents;
  font-weight: 400;
  font-size: 0.9rem;
`;

const fixedColumnStyles = (theme: Theme) => css`
  border-right: 1px solid ${theme.colors.secondaryLight};
  background-color: ${theme.palette.background.light};
  left: 0;
  padding-left: ${theme.spaces.sm};

  ${theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    position: sticky;
    filter: drop-shadow(1px 23px 10px lightgray);
  }
`;

export const Title = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.secondaryLight};
    border-left: none;
    border-right: none;
    padding: ${theme.spaces.xl};
    text-align: left;
    display: flex;
    align-items: center;

    &:first-of-type {
      ${fixedColumnStyles(theme)}
    }

    &:first-of-type:hover {
      background-color: ${theme.palette.control.secondaryLight};
      cursor: pointer;
    }
  `,
);

export const Icon = styled.span<{ order: FilteringBaseOrderType }>`
  padding: ${({ theme }) => theme.spaces.sm};
  background-color: ${({ theme }) => theme.palette.control.primaryLight};
  margin-left: ${({ theme }) => theme.spaces.md};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  & > svg {
    width: 18px;
    height: 18px;
    transform: ${({ order }) => (order === "desc" ? "scale(1, -1)" : "unset")};
    transition: transform 0.15s linear;
  }
`;

export const ConditionItem = styled.div`
  padding: ${({ theme }) => theme.spaces.xl};
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  white-space: break-spaces;
  word-break: break-word;
`;

export const ConditionItemTitle = styled(ConditionItem)(
  ({ theme }) => fixedColumnStyles(theme),
  css`
    pointer-events: none;
  `,
);
