import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../store";
import { hubConnected, hubConnecting, hubDisconnected, onHubConnectionError } from "../store/hubs.slice";
import { SignalrConnection } from "../types/SignalrConnection";
import { useHubState } from "./useHubState";
import { loggingService } from "../../logging/services/loggingService";
import { HubConnectionStatus } from "../types/HubConnectionStatus";
import { useHubUpdateListener } from "./useHubUpdateListener";
import { useHubInitial } from "./useHubInitial";

export const useHub = <THubId extends string>(connection: SignalrConnection<THubId, string, string>) => {
  const dispatch = useAppDispatch();
  const { hubId, error, status } = useHubState(connection);

  useHubInitial(connection);
  useHubUpdateListener(hubId);

  useEffect(() => {
    const startHub = async () => {
      try {
        dispatch(hubConnecting({ hubId }));
        await connection.start();
        dispatch(hubConnected({ hubId }));
      } catch (err) {
        loggingService.logError(err);
        dispatch(onHubConnectionError({ hubId, error: "Не удалось установить подключение" }));
      }
    };

    if (hubId !== undefined && status === HubConnectionStatus.Idle) {
      startHub();
    }
  }, [connection, dispatch, hubId, status]);

  useEffect(() => {
    if (hubId !== undefined) {
      connection.onClose((id, isDisconnectedAfterRetries) => {
        dispatch(hubDisconnected({ hubId, isDisconnectedAfterRetries }));
      });
    }

    return () => {
      if (hubId !== undefined) {
        connection.stop();
      }
    };
  }, [connection, dispatch, hubId]);

  return useMemo(() => ({ error, isConnected: status === HubConnectionStatus.Connected }), [error, status]);
};
