import { QueueTasksNotificationsList } from "./pop-up-content/QueueTasksNotificationsList";
import { QueueTasksNotificationsBellButton } from "./button/QueueTasksNotificationsBellButton";
import { useHubState } from "../../../common/hubs";
import { ConditionalView } from "../../../common/ui/containers/conditional";
import { queueTasksHubConnection } from "../../../common/hubs-use-cases/queue-tasks";
import { usePopupButton } from "../../../common/ui-kit/buttons/hooks/usePopupButton";
import { NavigationPopUpContainer } from "../../../common/ui/navigation/components/NavigationPopUpContainer";
import * as S from "./QueueTasksNotificationsNavigationPopupButton.styled";
import { QueueTasksNotificationsNavigationWebPushesSubscription } from "./QueueTasksNotificationsNavigationWebPushesSubscription";
import { useQueueTasksNotificationsSubscription } from "../hooks/useQueueTasksNotificationsSubscription";
import { WebPushesPromptModalDesktop } from "../../../common/web-pushes";

export const QueueTasksNotificationsNavigationPopupButton = () => {
  const { isConnected } = useHubState(queueTasksHubConnection);

  const { isSubscribed, onDismiss, onSwitchChange, setIsModalOpened, isModalOpened } =
    useQueueTasksNotificationsSubscription();

  const {
    state: [isOpened],
    buttonProps,
    popupProps,
  } = usePopupButton<HTMLButtonElement>({
    position: "bottom",
    offset: [0, 12],
    stayOpenedWhenClickOutside: isModalOpened,
  });

  const allowOutsideClick = (e: TouchEvent | MouseEvent) => {
    return buttonProps.element.current?.contains(e.target as Node) ?? false;
  };

  return (
    <ConditionalView condition={isConnected}>
      <QueueTasksNotificationsBellButton isActive={isOpened} {...buttonProps} />
      {isOpened && (
        <NavigationPopUpContainer {...popupProps}>
          <S.PopUpContainer>
            <QueueTasksNotificationsList
              allowOutsideClick={allowOutsideClick}
              before={
                <QueueTasksNotificationsNavigationWebPushesSubscription
                  isSubscribed={isSubscribed}
                  onSwitchChange={onSwitchChange}
                  onHelpButtonClick={() => {
                    setIsModalOpened(true);
                  }}
                />
              }
            />
          </S.PopUpContainer>
        </NavigationPopUpContainer>
      )}
      <WebPushesPromptModalDesktop
        isShown={isModalOpened}
        onClose={onDismiss}
        isWithActionButtons={!isSubscribed}
        onAgreeClick={onSwitchChange}
        onCancelClick={onDismiss}
      />
    </ConditionalView>
  );
};
