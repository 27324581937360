import { entityHelper } from "@sideg/helpers";
import { usePublicUserRegistrationsRegisterAgentStageType } from "../../hooks/agent/usePublicUserRegistrationsRegisterAgentStageType";
import { AgencyId } from "../../../../common/types/agencies";
import { PublicUserRegistrationAgentForm } from "./PublicUserRegistrationAgentForm";
import { PublicUserRegistrationAgentDocuments } from "./PublicUserRegistrationAgentDocuments";
import { PublicUserRegistrationSuccessResultCard } from "../result-card/PublicUserRegistrationSuccessResultCard";
import { useAppSelector } from "../../../../common/store";
import { selectPublicUserRegistrationAgent } from "../../store/agent/publicUserRegistrationAgent.selectors";
import { usePublicUserRegistrationRegisterAgentFormInitial } from "../../hooks/agent/usePublicUserRegistrationRegisterAgentFormInitial";

export interface PublicUserRegistrationAgentProps {
  agencyId: AgencyId | undefined;
}

export const PublicUserRegistrationAgent = ({ agencyId }: PublicUserRegistrationAgentProps) => {
  const stage = usePublicUserRegistrationsRegisterAgentStageType(agencyId);
  const { data } = useAppSelector(selectPublicUserRegistrationAgent);
  const { initialValues, initialTouched, isNeedToValidateOnMount } =
    usePublicUserRegistrationRegisterAgentFormInitial(agencyId);

  return (
    <>
      {stage === "form" && (
        <PublicUserRegistrationAgentForm
          initialValues={initialValues}
          initialTouched={initialTouched}
          isNeedToValidateOnMount={isNeedToValidateOnMount}
        />
      )}
      {stage === "documents" && agencyId !== undefined && <PublicUserRegistrationAgentDocuments agencyId={agencyId} />}
      {stage === "success" && data !== undefined && (
        <PublicUserRegistrationSuccessResultCard
          email={data.email}
          phone={entityHelper.formatPhoneNumber(data.phoneNumber)}
        />
      )}
    </>
  );
};
