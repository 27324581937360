import { PublicUserRegistrationClient } from "../components/client/PublicUserRegistrationClient";
import { usePublicUserRegistrationFormInitialState } from "../hooks/usePublicUserRegistrationFormInitialState";

export const PublicUserRegistrationClientPage = () => {
  const { initialValues, initialTouched, isNeedToValidateOnMount } =
    usePublicUserRegistrationFormInitialState("Client");

  return (
    <PublicUserRegistrationClient
      initialValues={initialValues}
      initialTouched={initialTouched}
      isNeedToValidateOnMount={isNeedToValidateOnMount}
    />
  );
};
