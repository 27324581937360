import LotModel from "../../../entity/models/LotModel"

export const LOT_FETCHING = 'LOT_FETCHING'
export const LOT_FETCHED = 'LOT_FETCHED'
export const LOT_ERROR = 'LOT_ERROR'
export const LOT_RESET = 'LOT_RESET'

export const LOT_UPDATE_PROCESSING = 'LOT_UPDATE_PROCESSING'
export const LOT_UPDATE_SUCCESS = 'LOT_UPDATE_SUCCESS'
export const LOT_UPDATE_ERROR = 'LOT_UPDATE_ERROR'
export const LOT_UPDATE_RESET = 'LOT_UPDATE_RESET'

export interface LotFetching {
    type: typeof LOT_FETCHING
}

export interface LotFetched {
    type: typeof LOT_FETCHED
    payload: LotModel
}

export interface LotError {
    type: typeof LOT_ERROR
    error: any
}

export interface LotReset {
    type: typeof LOT_RESET
}

export interface LotUpdateProcessing {
    type: typeof LOT_UPDATE_PROCESSING
}

export interface LotUpdateSuccess {
    type: typeof LOT_UPDATE_SUCCESS
    payload: boolean
}

export interface LotUpdateError {
    type: typeof LOT_UPDATE_ERROR
    error: any
}

export interface LotUpdateReset {
    type: typeof LOT_UPDATE_RESET
}

export type LotDispatchType =
    LotFetching |
    LotFetched |
    LotError |
    LotReset |
    LotUpdateProcessing |
    LotUpdateSuccess |
    LotUpdateError |
    LotUpdateReset
