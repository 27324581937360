import { Typography, TypographyProps } from "@sideg/ui-kit/atoms/typography";
import { BaseCssProps, ControlSize } from "@sideg/ui-kit/common/types";
import {
  getSelectSecondLineTextVariant,
  getSelectTextVariant,
} from "@sideg/ui-kit/atoms/form-controls/select/utils/getSelectTextVariant";
import { Flex } from "@sideg/ui-kit/atoms/containers";
import { PropsWithChildren, ReactNode } from "react";

export interface SelectPopupItemTitleProps extends TypographyProps, PropsWithChildren, BaseCssProps {
  titleSize?: ControlSize;
  secondLine?: ReactNode;
}

export const SelectPopupItemTitle = ({
  children,
  fontColor,
  variant,
  titleSize,
  secondLine,
  className,
  ...rest
}: SelectPopupItemTitleProps) => {
  const typographyFontColor = fontColor ?? "light.neutral.text.primary";
  const typographyVariant = variant ?? getSelectTextVariant(titleSize);
  const isNeedSecondLine = secondLine !== undefined;

  return (
    <Flex direction="column" gap="xs * 0.5" className={className}>
      <Typography {...rest} variant={typographyVariant} fontColor={typographyFontColor}>
        {children}
      </Typography>
      {isNeedSecondLine && (
        <Typography variant={getSelectSecondLineTextVariant(titleSize)} fontColor="light.neutral.text.tertiary">
          {secondLine}
        </Typography>
      )}
    </Flex>
  );
};
