import { memo } from "react";
import { Text } from "../../../../../common/ui-kit/typography";
import { isLoading } from "../../../../../common/types/state";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  ProductDetailsAsideNavigationCard,
  ProductDetailsAsideNavigationCardCounter,
  ProductDetailsAsideNavigationCardSkeleton,
} from "../../../../../common/product-details";
import { useCreditExecutionDetailsDocuments } from "../../../hooks/documents/useCreditExecutionDetailsDocuments";

export type CreditExecutionDetailsNavigationCardDocumentsProps = BaseCssProps;

export const CreditExecutionDetailsNavigationCardDocuments = memo(
  ({ className }: CreditExecutionDetailsNavigationCardDocumentsProps) => {
    const { ids, status, data, isNeedToHide, isExact, path } = useCreditExecutionDetailsDocuments();

    return (
      <>
        {data === undefined && isLoading(status) && <ProductDetailsAsideNavigationCardSkeleton height={40} />}
        {!isNeedToHide && data && (
          <ProductDetailsAsideNavigationCard
            to={path}
            isActive={isExact}
            className={className}
            after={
              <ProductDetailsAsideNavigationCardCounter variant="accent">
                {ids.length}
              </ProductDetailsAsideNavigationCardCounter>
            }
          >
            <Text fontSize="fz2" fontWeight={500}>
              Запрашиваемые документы
            </Text>
          </ProductDetailsAsideNavigationCard>
        )}
      </>
    );
  }
);
