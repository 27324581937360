import { CreditExecutionId, DemandId } from "../../../../common/types/demand";

export type CreditExecutionListItemModificationType = "remove-product" | "remove-demand";

export interface CreditExecutionListItemModificationState {
  type?: CreditExecutionListItemModificationType;
  creditExecutionId?: CreditExecutionId;
  demandId?: DemandId;
}

export const isCreditExecutionListItemRemoveProductModification = (
  state: CreditExecutionListItemModificationState
): state is { type: "remove-product"; creditExecutionId: CreditExecutionId } => {
  return state.type === "remove-product" && state.creditExecutionId !== undefined;
};

export const isCreditExecutionListItemRemoveDemandModification = (
  state: CreditExecutionListItemModificationState
): state is { type: "remove-demand"; creditExecutionId: CreditExecutionId; demandId: DemandId } => {
  return state.type === "remove-demand" && state.creditExecutionId !== undefined && state.demandId !== undefined;
};
