import { CssStyleBuilder } from "@sideg/ui-kit/common/types";

export type BuildersMap<TProps> = Record<keyof TProps, CssStyleBuilder<TProps>>;

export const makeStyleBuilders =
  <TProps>(buildersMap: BuildersMap<TProps>) =>
  <TExcludeKeys extends keyof TProps>(exclude?: TExcludeKeys[]): CssStyleBuilder<Omit<TProps, TExcludeKeys>>[] => {
    return Object.entries<CssStyleBuilder<TProps>>(buildersMap)
      .filter(([key]) => (exclude as string[] | undefined)?.includes(key) !== true)
      .map(([, value]) => value) as CssStyleBuilder<Omit<TProps, TExcludeKeys>>[];
  };
