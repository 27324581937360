import { PayloadAction, createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { UserDetailsState } from "./userDetails.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { getUserDetails, postUserDetails, USER_DETAILS_SLICE_NAME } from "./userDetails.thunks";
import { userDetailsMapper } from "./userDetails.mapper";

const initialState: UserDetailsState = {
  get: {
    status: LoadingStatusEnum.Idle,
  },
  update: {
    status: LoadingStatusEnum.Idle,
  },
};

const slice = createSlice({
  initialState,
  name: USER_DETAILS_SLICE_NAME,
  reducers: {
    userDetailsPostUpdate: (state) => {
      state.update.status = LoadingStatusEnum.Idle;
    },
    userDetailsGetUpdate: (state) => {
      state.get.status = LoadingStatusEnum.Idle;
    },
    updateLastPulledDetailsTime: (state, action: PayloadAction<number>) => {
      state.lastPulledDetailsTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.fulfilled, (state, action) => {
        const { user, manager } = userDetailsMapper.fromResponse(action.payload.body);
        state.user = user;
        state.manager = manager;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.get.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(getUserDetails), (state, action) => {
        state.get.status = getLoadingStatusFromAction(action);
      })
      .addMatcher(isAsyncThunkAction(postUserDetails), (state, action) => {
        state.update.status = getLoadingStatusFromAction(action);
      });
  },
});

export const { userDetailsPostUpdate, userDetailsGetUpdate, updateLastPulledDetailsTime } = slice.actions;

export const userDetailsReducer = slice.reducer;
