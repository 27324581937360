import LoadingSkeleton, { SkeletonProps as LoadingSkeletonProps } from "react-loading-skeleton";
import { ClassNames } from "@emotion/react";

export interface SkeletonProps extends LoadingSkeletonProps {
  isFullWidth?: boolean;
}

export const Skeleton = ({ isFullWidth, containerClassName, ...rest }: SkeletonProps) => {
  return (
    <ClassNames>
      {({ cx, css }) => (
        <LoadingSkeleton
          containerClassName={cx(
            {
              [css`
                width: 100%;
              `]: isFullWidth,
            },
            containerClassName
          )}
          {...rest}
        />
      )}
    </ClassNames>
  );
};
