import { useEffect, useState } from "react";
import { Box } from "../../../common/ui-kit/containers/box";
import { ProductDetailsDemandBody } from "../../../common/product-details";
import { useCreditExecutionDetailsDemandInitialFetch } from "../hooks/useCreditExecutionDetailsDemandInitialFetch";
import { CreditExecutionDetailsDemand } from "../component/demand/CreditExecutionDetailsDemand";
import { CreditExecutionDetailsDemandHeader } from "../component/demand/header/CreditExecutionDetailsDemandHeader";
import { CreditExecutionDetailsDemandHeaderTab } from "../types/demand/CreditExecutionDetailsDemandHeaderTab";
import { useCreditExecutionDetailsContext } from "../hooks/context/useCreditExecutionDetailsContext";
import { CreditExecutionDetailsDemandActualDocumentsList } from "../component/demand/actual-documents/CreditExecutionDetailsDemandActualDocumentsList";

export const CreditExecutionDetailsDemandPage = () => {
  const { demandId } = useCreditExecutionDetailsContext();
  const [activeTab, setActiveTab] = useState<CreditExecutionDetailsDemandHeaderTab>("tasks");

  useCreditExecutionDetailsDemandInitialFetch();

  useEffect(() => {
    setActiveTab("tasks");
  }, [demandId]);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} flexShrink={1} overflow="hidden">
      <CreditExecutionDetailsDemandHeader activeTab={activeTab} onTabClick={setActiveTab} />
      <ProductDetailsDemandBody>
        {activeTab === "tasks" && <CreditExecutionDetailsDemand />}
        {activeTab === "documents" && <CreditExecutionDetailsDemandActualDocumentsList />}
      </ProductDetailsDemandBody>
    </Box>
  );
};
