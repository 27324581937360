import Skeleton from "react-loading-skeleton";
import { useFormikContext } from "formik";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { FullPageContainerCenteredMainWithFooter } from "../../../../../common/ui/containers/full-page";
import {
  ProductCreationScoringLoadingResultCard,
  ProductCreationScoringRejectsPanel,
  ProductCreationScoringResultCard,
  ProductCreationScoringResultRowDuplicate,
  ProductCreationScoringResultRowReject,
  ProductCreationScoringResultRowRejectSkeleton,
  ProductCreationScoringResultSummary,
  useProductCreationScoringRejectPanel,
  ProductCreationScoringFormSubmitButton,
  useCreationScoringTotalSum,
  ProductCreationScoringFormTotalLabel,
} from "../../../../../common/product-creation";
import { useAppSelector } from "../../../../../common/store";
import {
  selectCreditWorkingAssetsCreationCreating,
  selectCreditWorkingAssetsCreationScoring,
} from "../../../store/creditWorkingAssetsCreation.selectors";
import { CreditWorkingAssetsCreationScoringResultSuccessRow } from "../result-row/CreditWorkingAssetsCreationScoringResultSuccessRow";
import { isLoading } from "../../../../../common/types/state";
import { CreditWorkingAssetsCreationScoringResultSuccessRowSkeleton } from "../result-row/CreditWorkingAssetsCreationScoringResultSuccessRow.skeleton";
import { LineSeparatedContainer } from "../../../../../common/ui-kit/containers/separated";
import { CreationBlockCardDescriptionSecondary } from "../../../../../common/ui-kit/cards/creation-block-card";
import { CreditWorkingAssetsCreationScoringFormValues } from "../../../types/scoring/CreditWorkingAssetsCreationScoringFormValues";

export const CreditWorkingAssetsCreationScoringCreateNewFormContent = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { creditSum } = useAppSelector(selectCreditWorkingAssetsCreationCreating).data!;
  const { data, status } = useAppSelector(selectCreditWorkingAssetsCreationScoring);
  const { openPanel, closePanel } = useProductCreationScoringRejectPanel();

  const {
    values: { banks },
  } = useFormikContext<CreditWorkingAssetsCreationScoringFormValues>();

  const actualSum = useCreationScoringTotalSum(banks);

  return (
    <FullPageContainerCenteredMainWithFooter
      footer={
        data?.success?.length !== undefined &&
        data.success.length > 0 && (
          <ProductCreationScoringFormSubmitButton<CreditWorkingAssetsCreationScoringFormValues> />
        )
      }
    >
      <ProductCreationScoringResultSummary
        isSkeleton={isLoading(status)}
        successCount={data?.success?.length}
        rejectCount={data?.reject?.length}
        duplicateCount={data?.duplicate?.length}
        onShowRejectsClick={openPanel}
      />

      <ProductCreationScoringLoadingResultCard
        isLoading={isLoading(status)}
        title="Выберите банки, в которые хотите отправить заявку"
        description={
          <>
            {!isLoading(status) && (
              <ProductCreationScoringFormTotalLabel requestedSum={creditSum} actualSum={actualSum} />
            )}
            {isLoading(status) && <Skeleton width={300} />}
          </>
        }
        skeleton={CreditWorkingAssetsCreationScoringResultSuccessRowSkeleton}
        skeletonElementsCount={5}
        items={data?.success}
      >
        {(x) => <CreditWorkingAssetsCreationScoringResultSuccessRow creditSum={creditSum} scoringResult={x} />}
      </ProductCreationScoringLoadingResultCard>
      <ThemeMobileDesktopView
        mobile={
          data?.reject?.length !== undefined &&
          data.reject.length > 0 && (
            <ProductCreationScoringRejectsPanel rejectsCount={data.reject.length} onCloseClick={closePanel}>
              <LineSeparatedContainer borderWidth={1}>
                {data.reject.map((x) => (
                  <ProductCreationScoringResultRowReject key={x.bank.code} bankName={x.bank.title} text={x.message} />
                ))}
              </LineSeparatedContainer>
            </ProductCreationScoringRejectsPanel>
          )
        }
        desktop={
          <ProductCreationScoringLoadingResultCard
            isLoading={isLoading(status)}
            title="Отказ банка"
            skeleton={ProductCreationScoringResultRowRejectSkeleton}
            skeletonElementsCount={2}
            items={data?.reject}
          >
            {(x) => <ProductCreationScoringResultRowReject bankName={x.bank.title} text={x.message} />}
          </ProductCreationScoringLoadingResultCard>
        }
      />
      {data?.duplicate?.length !== undefined && data.duplicate.length > 0 && (
        <ProductCreationScoringResultCard
          title="Дубли"
          description={
            <CreationBlockCardDescriptionSecondary>
              У Вас уже есть заявка по данному лоту. Для восстановления заявки обратитесь к личному менеджеру, для
              изменения параметров напишите в комментарии к заявке
            </CreationBlockCardDescriptionSecondary>
          }
        >
          {data.duplicate.map((x) => (
            <ProductCreationScoringResultRowDuplicate
              key={x.bank.code}
              bankName={x.bank.title}
              productType="CreditWorkingAssets"
              productId={x.existingDemand?.productId}
              demandId={x.existingDemand?.demandId}
            />
          ))}
        </ProductCreationScoringResultCard>
      )}
    </FullPageContainerCenteredMainWithFooter>
  );
};
