import {
  BankGuaranteeDetailsComplicatedTaskProjectDecisionType,
  isBankGuaranteeDetailsComplicatedTaskProjectDecisionProjectAgreed,
} from "../types/BankGuaranteeDetailsComplicatedTaskProjectDecisionType";

const isExists = (decision: BankGuaranteeDetailsComplicatedTaskProjectDecisionType | undefined) =>
  !isBankGuaranteeDetailsComplicatedTaskProjectDecisionProjectAgreed(decision);

export const bankGuaranteeDetailsComplicatedTaskFileRules = {
  isExists,
};
