import { combineSliceNames } from "../../../common/store/utils";
import { BANK_GUARANTEE_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { bankGuaranteeRewardsApi } from "../api/bankGuaranteeRewardsApi";

export const BANK_GUARANTEE_REWARDS_SLICE_NAME = combineSliceNames(BANK_GUARANTEE_SLICE_NAME, "reward");

export const { get: getBankGuaranteeRewards } = createApiThunks(
  BANK_GUARANTEE_REWARDS_SLICE_NAME,
  bankGuaranteeRewardsApi
);
