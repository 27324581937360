import { FormCheckbox } from "@sideg/ui-kit/atoms/checkbox";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { useBankGuaranteeCreationContractCheckboxFieldsDisabledState } from "../../../hooks/creation/fields/useBankGuaranteeCreationContractCheckboxFieldsDisabledState";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";

export interface BankGuaranteeCreationContractCheckboxFieldsProps {
  disabled?: boolean;
}

export const BankGuaranteeCreationContractCheckboxFields = ({
  disabled,
}: BankGuaranteeCreationContractCheckboxFieldsProps) => {
  const [isClosedAuctionProps] = useBankGuaranteeCreationField("isClosedAuction");
  const [isSingleSupplierProps] = useBankGuaranteeCreationField("isSingleSupplier");

  const isDisabledByValidationRules = useBankGuaranteeCreationContractCheckboxFieldsDisabledState();

  return (
    <StackPanel direction={{ xs: "column", md: "row" }} gap={{ xs: "md", md: "xxl" }}>
      <FormCheckbox
        label="Закрытый аукцион"
        {...isClosedAuctionProps}
        isDisabled={isDisabledByValidationRules || disabled}
      />
      <FormCheckbox
        label="Единственный поставщик"
        {...isSingleSupplierProps}
        isDisabled={isDisabledByValidationRules || disabled}
      />
    </StackPanel>
  );
};
