import { useCallback } from "react";
import { FormikHelpers } from "formik";
import { CreditExecutionCreationFormValues } from "../../types/creation/CreditExecutionCreationFormValues";
import {
  getCreditExecutionCreationFromValidationSchema,
  getCreditExecutionCreationRequestValidationSchema,
} from "../../validation/creditExecutionCreationValidationSchema";
import { shallowEqualObjects } from "../../../../common/utils/shallowEqualObjects";
import { formValidationFieldService } from "../../../../common/form";
import { CreditExecutionCreationRequest } from "../../types/creation/CreditExecutionsCreationRequest";
import { useAppDispatch } from "../../../../common/store";
import { createDraftCreditExecutionCreation } from "../../store/creditExecutionCreation.thunks";
import {
  setIsFormSameAsInitialCreditExecutionCreation,
  setNextStageCreditExecutionCreation,
} from "../../store/creditExecutionCreation.slice";

export const useCreditExecutionCreationFormSubmit = (initialValues: CreditExecutionCreationRequest) => {
  const dispatch = useAppDispatch();

  return useCallback(
    async (
      formValues: CreditExecutionCreationFormValues,
      formikHelpers: FormikHelpers<CreditExecutionCreationFormValues>
    ) => {
      try {
        const formCastedValues = getCreditExecutionCreationFromValidationSchema().cast(formValues);

        const actualCastedValues = getCreditExecutionCreationRequestValidationSchema().cast(formCastedValues, {
          assert: true,
          stripUnknown: true,
        }) as CreditExecutionCreationRequest;

        const initialCastedValues = getCreditExecutionCreationRequestValidationSchema().cast(initialValues);

        const isEquals = shallowEqualObjects(initialCastedValues, actualCastedValues);

        dispatch(setIsFormSameAsInitialCreditExecutionCreation(isEquals));

        if (!isEquals) {
          await dispatch(createDraftCreditExecutionCreation(actualCastedValues)).unwrap();
        } else {
          dispatch(setNextStageCreditExecutionCreation());
        }
      } catch (e: unknown) {
        formikHelpers.setErrors(formValidationFieldService.tryGetValidationErrors(e));
      }
    },
    [dispatch, initialValues]
  );
};
