import { useMemo } from "react";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDetailsDocumentsIds } from "../../store/documents/bankGuaranteeDetailsDocuments.selectors";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { useBankGuaranteeDetailsLayoutContext } from "../layout/useBankGuaranteeDetailsLayoutContext";
import { useAppUrlMatch } from "../../../../common/urls/internal/hooks";
import { isNeedToHideProductDetailsDocuments } from "../../../../common/product-details";

export const useBankGuaranteeDetailsDocuments = () => {
  const ids = useAppSelector(selectBankGuaranteeDetailsDocumentsIds);
  const { data, status } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);
  const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();
  const { path, isExact } = useAppUrlMatch((x) => x.bankGuarantee.details.documents, {
    guaranteeId,
  });

  return useMemo(() => {
    const isNeedToHide = isNeedToHideProductDetailsDocuments(ids, data, status, isExact);

    return {
      ids,
      path,
      isExact,
      data,
      isNeedToHide,
      status,
    };
  }, [data, ids, isExact, path, status]);
};
