import styled from "@emotion/styled";
import { ControlSize } from "@sideg/ui-kit/common/types";
import { getIconSize } from "@sideg/ui-kit/atoms/form-controls/input/common/utils/getIconSize";
import { getSizes } from "@sideg/ui-kit/atoms/form-controls/input/common/utils/getInputSize";
import { BaseButton } from "@sideg/ui-kit/atoms/button/components/common/BaseButton";

export const Button = styled(BaseButton.withComponent("button"))<{
  error: boolean;
  opened: boolean;
  disabled: boolean;
  expandIconSize: ControlSize;
  size: ControlSize;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.m};
  padding: ${({ theme, size }) => getSizes(theme, size).padding};
  width: 100%;

  background-color: ${({ theme, disabled, error }) => {
    if (error) {
      return theme.palette.light.primary.text.inverse;
    }
    if (disabled) {
      return theme.palette.light.neutral.bgr.secondary;
    }

    return theme.palette.light.neutral.tonal;
  }};

  &:focus {
    outline: none;
  }

  border-radius: ${({ theme }) => theme.radii.control};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.light.primary.tonal.hover};
  }

  &:disabled {
    cursor: auto;
    border-color: ${({ theme }) => theme.palette.light.neutral.outline.secondary};
    background-color: ${({ theme }) => theme.palette.light.neutral.bgr.secondary};
  }

  & > svg {
    ${({ expandIconSize }) => {
      const { height, width } = getIconSize(expandIconSize);

      return { height, width };
    }}
    transform: ${({ opened }) => `rotate(${opened ? 90 : -90}deg)`};
    transition: transfrom 0.5s ease-in-out;

    & > path {
      stroke: ${({ theme }) => theme.palette.light.neutral.text.tertiary};
    }
  }
`;
