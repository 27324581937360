import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { BaseState, getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import { creditExecutionDetailsActiveDemandDocumentsAdapter } from "./creditExecutionDetailsActiveDemandDocuments.adapter";
import { getDemandActualDocuments } from "../../../../demand/actual-documents";
import {
  CREDIT_EXECUTION_DETAILS_SLICE_NAME,
  creditExecutionDetailsActiveDemandChanged,
  creditExecutionDetailsActiveProductChanged,
} from "../creditExecutionDetails.actions";
import { CreditExecutionDetailsActiveDemandState } from "./creditExecutionDetailsActiveDemand.types";
import { getDemandCreditExecutionDetails } from "../creditExecutionDetails.thunks";
import { isCreditExecutionDetailsNeedReloadMatcher } from "../creditExecutionDetails.matchers";

const SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_DETAILS_SLICE_NAME, "activeDemand");

const initialState: CreditExecutionDetailsActiveDemandState = {
  fetch: { status: LoadingStatusEnum.Idle },
  canSendComment: undefined,
  documents: creditExecutionDetailsActiveDemandDocumentsAdapter.getInitialState<BaseState>({
    status: LoadingStatusEnum.Idle,
  }),
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(creditExecutionDetailsActiveProductChanged, () => initialState)
      .addCase(creditExecutionDetailsActiveDemandChanged, (state, action) => {
        return { ...initialState, activeDemandId: action.payload };
      })
      .addCase(getDemandCreditExecutionDetails.fulfilled, (state, action) => {
        state.canSendComment = action.payload.body.canSendComment;
      })
      .addCase(getDemandCreditExecutionDetails.rejected, (state, action) => {
        state.fetch.error = action.payload?.message;
        state.canSendComment = undefined;
      })
      .addCase(getDemandActualDocuments.fulfilled, (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          creditExecutionDetailsActiveDemandDocumentsAdapter.setAll(state.documents, action.payload.body.data);
        }
      })
      .addMatcher(isCreditExecutionDetailsNeedReloadMatcher, (state) => {
        return { ...initialState, activeDemandId: state.activeDemandId };
      })
      .addMatcher(isAsyncThunkAction(getDemandCreditExecutionDetails), (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          state.fetch.status = getLoadingStatusFromAction(action);
        }
      })
      .addMatcher(isAsyncThunkAction(getDemandActualDocuments), (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          state.documents.status = getLoadingStatusFromAction(action);
        }
      });
  },
});

export const creditExecutionDetailsActiveDemandReducer = slice.reducer;
