import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditExecutionId } from "../../../../../common/types/demand";
import { CreditExecutionListItemMobile } from "./mobile/CreditExecutionListItem.mobile";
import { CreditExecutionListItemDesktop } from "./desktop/CreditExecutionListItem.desktop";
import { useCreateNavigationStateFrom } from "../../../../../common/navigation/navigation-state";

export interface CreditExecutionListItemProps extends BaseCssProps {
  creditExecutionId: CreditExecutionId;
}

export const CreditExecutionListItem = ({ creditExecutionId, className }: CreditExecutionListItemProps) => {
  const from = useCreateNavigationStateFrom("Мои заявки");

  return (
    <ThemeMobileDesktopView
      mobile={<CreditExecutionListItemMobile creditExecutionId={creditExecutionId} className={className} from={from} />}
      desktop={
        <CreditExecutionListItemDesktop creditExecutionId={creditExecutionId} className={className} from={from} />
      }
    />
  );
};
