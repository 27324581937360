import { useEffect, useRef } from "react";
import { FormikContextType, useFormikContext } from "formik";
import { useGetFieldName, useTypedField } from "../../../../../../common/form";
import { PublicUserRegistrationAgentDocumentApproveFormValues } from "../../../../types/agent/PublicUserRegistrationAgentDocumentApproveFormValues";
import { useDebounce } from "../../../../../../common/hooks/useDebounce";

export const PublicUserRegistrationAgentDocumentApproveFormSubmitListener = () => {
  const getFieldName = useGetFieldName<PublicUserRegistrationAgentDocumentApproveFormValues>();
  const form = useFormikContext<PublicUserRegistrationAgentDocumentApproveFormValues>();

  const formRef = useRef<null | FormikContextType<PublicUserRegistrationAgentDocumentApproveFormValues>>(form);
  formRef.current = form;

  const [{ value }] = useTypedField<string>(getFieldName("code"));
  const [debounceValue] = useDebounce(value);

  useEffect(() => {
    if (
      formRef.current?.isValid === true &&
      formRef.current?.isValidating !== true &&
      formRef.current?.dirty === true
    ) {
      formRef.current?.submitForm();
    }
  }, [debounceValue]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
