/**
 * Возвращает трансформер для преобразования строки в массив
 * @param separator Разделитель значений массива
 * @example "10.12.2021" -> Date
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const getStringToArrayTransformer =
  (separator = ",") =>
  (value: any, originalValue: any) => {
    if (!value && typeof originalValue === "string" && originalValue !== "") {
      return originalValue.split(separator);
    }

    return value;
  };
