import * as React from "react";
import { AppLayoutState } from "../types/AppLayoutState";
import { AppLayoutOverlayPanelKey } from "../types";

export interface LayoutContextState {
  isFullSize: AppLayoutState<boolean>;
  activeOverlayPanel: AppLayoutState<AppLayoutOverlayPanelKey | undefined>;
  isMenuOpened: AppLayoutState<boolean>;
  isFixedOverflow: AppLayoutState<boolean>;
  headerAction: AppLayoutState<React.ReactNode | undefined>;
}

const getDefaultState = <T>(defaultValue: T): AppLayoutState<T> => ({
  value: defaultValue,
  setValue: () => {
    throw Error("Попытка установить значение через контекст по-умолчанию");
  },
});

export const LayoutContext = React.createContext<LayoutContextState>({
  isFullSize: getDefaultState<boolean>(false),
  isMenuOpened: getDefaultState<boolean>(false),
  activeOverlayPanel: getDefaultState<AppLayoutOverlayPanelKey | undefined>(undefined),
  isFixedOverflow: getDefaultState<boolean>(false),
  headerAction: getDefaultState<React.ReactNode | undefined>(undefined),
});
