import { useMemo } from "react";
import { ApiAsyncThunk } from "../../../../common/store/utils";
import { CreateCreditExecutionCreationOutputDto } from "../../api/dto/output/CreateCreditExecutionCreationOutputDto";
import { BankCreationModelWithCreditSum } from "../../../../common/types/objects";
import {
  CreditExecutionCreationScoringFormBankValues,
  CreditExecutionCreationScoringFormValues,
} from "../../types/scoring/CreditExecutionCreationScoringFormValues";
import { useApiMutationAction } from "../../../../common/hooks";
import { useAppNavigation } from "../../../../common/urls/internal/hooks";
import { ApiResponse, isBaseApiError } from "../../../../common/api/types";
import { useAppDispatch } from "../../../../common/store";
import { pushErrorGlobalToast } from "../../../../global/toasts";
import { creditExecutionCreationScoringValidationSchema } from "../../validation/creditExecutionCreationScoringValidationSchema";

export const useCreditExecutionCreationSubmitAfterScoring = <
  TInput extends { banks: BankCreationModelWithCreditSum[] }
>(
  thunk: ApiAsyncThunk<ApiResponse<CreateCreditExecutionCreationOutputDto>, TInput>,
  input: Omit<TInput, "banks">
) => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigation();
  const { mutate } = useApiMutationAction();

  return useMemo(() => {
    const onSubmit = async (values: CreditExecutionCreationScoringFormValues) => {
      const castedValues = creditExecutionCreationScoringValidationSchema.cast(values);

      const banks: BankCreationModelWithCreditSum[] = Object.values(castedValues.banks)
        .filter((value: unknown): value is CreditExecutionCreationScoringFormBankValues => {
          return typeof (value as CreditExecutionCreationScoringFormBankValues)?.bankId === "string";
        })
        .map(
          (value: CreditExecutionCreationScoringFormBankValues): BankCreationModelWithCreditSum => ({
            bankId: value.bankId,
            creditSum: value.creditSum,
          })
        );

      const result = await mutate(thunk, { ...input, banks } as TInput, {
        errorHandler: async (e) => {
          const error = isBaseApiError(e)
            ? e.message ?? ""
            : "Не удалось отменить заявку. Попробуйте позже или свяжитесь с персональным менеджером";

          dispatch(pushErrorGlobalToast(error));

          return error;
        },
      });
      if (result?.body.creditExecutionId) {
        navigate((x) => x.creditExecution.details.documents, {
          creditExecutionId: result.body.creditExecutionId,
        });
      }
    };

    return { onSubmit };
  }, [dispatch, input, mutate, navigate, thunk]);
};
