import styled, { CSSObject } from "@emotion/styled";
import { AppLayoutResponsiveListPage } from "../../../../../common/ui/layout/app-layout-containers";

export const Container = styled(AppLayoutResponsiveListPage.List)<{ bankListPaddingTop: CSSObject["paddingTop"] }>`
  padding-top: ${({ bankListPaddingTop }) => bankListPaddingTop};
  padding-bottom: 0;
`;

export const Separator = styled.div<{
  topPosition: CSSObject["top"];
  isFixed: boolean;
  width: CSSObject["width"];
}>`
  width: ${({ width }) => width};
  height: 1px;
  position: ${({ isFixed }) => (isFixed ? "fixed" : "static")};
  top: ${({ topPosition }) => topPosition};
  z-index: ${({ theme }) => theme.layout.zIndex};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
`;
