import { AppLayoutOverlayPanelKey, makeAppLayoutOverlayPanelKey } from "../../ui/layout/app-layout/types";
import { DemandId, ProductId, ProductType } from "../../types/demand";

export const makeProductRejectionOverlayPanelKey = (
  product: ProductType,
  productId: ProductId,
  demandId?: DemandId
): AppLayoutOverlayPanelKey => {
  return makeAppLayoutOverlayPanelKey(`product-rejection-${product}-${productId}-${demandId ?? ""}-overlay-panel`);
};
