import { cssModuleMultipleClasses } from "../../essentials/helpers";
import styles from "./MySubmitButton.module.css";

interface IMySubmitButton {
  text: string;
  loading?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  onClickFunction?: Function;
  style?: MySubmitButtonStyle;
}

export enum MySubmitButtonStyle {
  Default = "Default",
  Delete = "Delete",
}

const MySubmitButton: React.FC<IMySubmitButton> = ({
  text,
  loading = false,
  type = "button",
  disabled = false,
  onClickFunction = () => {},
  style = MySubmitButtonStyle.Default,
}) => {
  const getButtonClassName = (style: MySubmitButtonStyle) => {
    switch (style) {
      case MySubmitButtonStyle.Delete:
        return cssModuleMultipleClasses([styles.button, styles.buttonDelete]);
      default:
        return cssModuleMultipleClasses([styles.button, styles.buttonDefault]);
    }
  };

  return (
    <button
      className={getButtonClassName(style)}
      type={type}
      disabled={disabled}
      onClick={() => {
        onClickFunction();
      }}
    >
      {loading ? "Загрузка..." : text}
    </button>
  );
};

export default MySubmitButton;
