import { BaseCssProps } from "../../../../common/ui-kit/types";
import { BankGuaranteeDocumentsListItemContainerDesktop } from "./BankGuaranteeDocumentsListItemContainer.desktop";
import { BankGuaranteeDocumentsListHeaderTextDesktop } from "./BankGuaranteeDocumentsListHeaderText.desktop";

export type BankGuaranteeDocumentsListHeaderDesktopProps = BaseCssProps;

export const BankGuaranteeDocumentsListHeaderDesktop = ({
  className,
}: BankGuaranteeDocumentsListHeaderDesktopProps) => {
  return (
    <BankGuaranteeDocumentsListItemContainerDesktop
      className={className}
      name={
        <BankGuaranteeDocumentsListHeaderTextDesktop>Название документа</BankGuaranteeDocumentsListHeaderTextDesktop>
      }
      taxSystem={
        <BankGuaranteeDocumentsListHeaderTextDesktop>
          Система налогообложения
        </BankGuaranteeDocumentsListHeaderTextDesktop>
      }
      amounts={
        <BankGuaranteeDocumentsListHeaderTextDesktop>Диапазон сумм БГ</BankGuaranteeDocumentsListHeaderTextDesktop>
      }
      template={
        <BankGuaranteeDocumentsListHeaderTextDesktop>Шаблон документа</BankGuaranteeDocumentsListHeaderTextDesktop>
      }
    />
  );
};
