import { GetBankGuaranteeDetailsOutputDto } from "../../api/dto/output/GetBankGuaranteeDetailsOutputDto";
import { BankGuaranteeDetailsGuaranteeDetailsSliceState } from "./bankGuaranteeDetailsGuaranteeDetails.types";

const mapFromResponse = ({
  requestOriginal,
  client,
  dateAdded,
  federalLaw,
  guaranteeId,
  guaranteeSum,
  guaranteeType,
  purchaseNumber,
  purchaseLink,
  purchaseIdentificationCode,
  briefAndFounders,
}: GetBankGuaranteeDetailsOutputDto): BankGuaranteeDetailsGuaranteeDetailsSliceState => {
  return {
    guaranteeId,
    guaranteeType,
    purchaseLink,
    purchaseNumber,
    client,
    federalLaw,
    guaranteeSum,
    dateAdded,
    purchaseIdentificationCode,
    briefAndFounders,
    requestOriginalDemandId: requestOriginal?.demandId,
  };
};

export const bankGuaranteeDetailsGuaranteeDetailsMapper = {
  mapFromResponse,
};
