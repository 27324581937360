import { ReactNode } from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { Text } from "../../../../../ui-kit/typography";
import { BaseCssProps } from "../../../../../ui-kit/types";

export interface FullPageContainerHeaderTitleProps extends BaseCssProps {
  text: ReactNode;
}

export const FullPageContainerHeaderTitle = ({ text, className }: FullPageContainerHeaderTitleProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Text
      as="h2"
      fontSize={{ xs: 1.875, [desktopBreakpoint]: 1.25 }}
      fontWeight={{ xs: 700, [desktopBreakpoint]: 500 }}
      lineHeight={{ xs: "1.2", [desktopBreakpoint]: "defaultLineHeight" }}
      textAlign="center"
      className={className}
    >
      {text}
    </Text>
  );
};
