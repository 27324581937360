import {
  DocumentUploadActiveDocument,
  DocumentUploadActiveDocumentProps,
} from "../../active-document/DocumentUploadActiveDocument";
import { DocumentUploadCommonBankDocumentCardBanks } from "../banks/DocumentUploadCommonBankDocumentCardBanks";
import { DocumentUploadCommonBankDocumentContainer } from "../DocumentUploadCommonBankDocumentContainer";
import { CodeWithTitle } from "../../../../../common/types/objects";

export interface DocumentUploadCommonBankDocumentCardProps extends DocumentUploadActiveDocumentProps {
  banks: CodeWithTitle[];
}

export const DocumentUploadCommonBankDocumentCard = ({
  banks,
  className,
  ...uploadProps
}: DocumentUploadCommonBankDocumentCardProps) => {
  return (
    <DocumentUploadCommonBankDocumentContainer
      className={className}
      footer={<DocumentUploadCommonBankDocumentCardBanks banks={banks} />}
    >
      <DocumentUploadActiveDocument {...uploadProps} />
    </DocumentUploadCommonBankDocumentContainer>
  );
};
