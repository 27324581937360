import styled from "@emotion/styled";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ElementColor, getElementColor, ResponsiveStyleValue } from "../../../types";
import { Color } from "../../../theme";

export interface IndicatorProps {
  indicatorColor: Color;
  size: ResponsiveStyleValue<number>;
  borderColor?: ElementColor;
}

export const Indicator = styled.div<IndicatorProps>`
  ${({ theme, size }) => makeResponsiveStyles(theme, size, (x) => ({ width: x, height: x }))};
  border-radius: 50%;
  border: ${({ theme, borderColor }) => (borderColor ? `1px solid ${getElementColor(borderColor, theme)}` : "unset")};
  background-color: ${({ indicatorColor }) => indicatorColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
