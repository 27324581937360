import styled from "@emotion/styled";

export const Container = styled.span<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 2px ${({ theme }) => theme.spaces.sm};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
`;

export const Text = styled.span<{ color: string }>`
  font-size: ${({ theme }) => theme.font.fz3};
  font-weight: 500;
  color: ${({ color }) => color};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    font-size: ${({ theme }) => theme.font.fz2};
  }
`;
