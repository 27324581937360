import { makeAppUrl } from "../../../common/urls/internal/utils";
import { CREDIT_WORKING_ASSETS_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const creditWorkingAssetsListUrls = {
  root: makeAppUrl(
    CREDIT_WORKING_ASSETS_ROOT_URL,
    CREDIT_WORKING_ASSETS_ROOT_URL,
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCwa),
  ),
};
