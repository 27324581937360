import styled from "@emotion/styled";

export const GridContainer = styled.section`
  display: grid;
  grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  gap: ${({ theme }) => theme.spaces.sm};

  & > *:nth-of-type(3) {
    grid-column: 1 / 3;
  }

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    grid-template-columns: 1fr 120px ${({ theme }) => theme.spaces.sm} 2fr;
    row-gap: ${({ theme }) => theme.spaces.lg};

    & > *:nth-of-type(3) {
      grid-column: 4 / 5;
    }
  }
`;
