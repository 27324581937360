import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../../common/types/utils";

const titles = {
  tasks: "Сообщения",
  documents: "Документы",
};

export const creditWorkingAssetsDetailsDemandHeaderTab = makeEnumLikeLiteral(["tasks", "documents"] as const, titles);

export type CreditWorkingAssetsDetailsDemandHeaderTab = EnumLikeLiteralType<
  typeof creditWorkingAssetsDetailsDemandHeaderTab
>;
