import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  creditWorkingAssetsDetailsDemandHeaderTab,
  CreditWorkingAssetsDetailsDemandHeaderTab,
} from "../../../types/demand/CreditWorkingAssetsDetailsDemandHeaderTab";
import { ProductDetailsContentHeaderLayout } from "../../../../../common/product-details";
import { CreditWorkingAssetsDetailsDemandHeaderTabs } from "./CreditWorkingAssetsDetailsDemandHeaderTabs";
import { useCreditWorkingAssetsDetailsContext } from "../../../hooks/context/useCreditWorkingAssetsDetailsContext";
import { useAppSelector } from "../../../../../common/store";
import { selectCreditWorkingAssetsDetailsDemandById } from "../../../store/demands/creditWorkingAssetsDetailsDemands.selectors";
import { CreditWorkingAssetsDetailsDemandHeaderDetails } from "./details/CreditWorkingAssetsDetailsDemandHeaderDetails";
import { CreditWorkingAssetsDetailsDemandHeaderRejectionButton } from "./CreditWorkingAssetsDetailsDemandHeaderRejectionButton";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../../../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";
import { getCreditWorkingAssetsDemandChips } from "../../../utils/getCreditWorkingAssetsDemandChips";

export interface CreditWorkingAssetsDetailsDemandHeaderProps extends BaseCssProps {
  activeTab: CreditWorkingAssetsDetailsDemandHeaderTab;
  onTabClick: (tab: CreditWorkingAssetsDetailsDemandHeaderTab) => void | Promise<void>;
}

export const CreditWorkingAssetsDetailsDemandHeader = ({
  activeTab,
  onTabClick,
  className,
}: CreditWorkingAssetsDetailsDemandHeaderProps) => {
  const { creditWorkingAssetsId, demandId } = useCreditWorkingAssetsDetailsContext();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectCreditWorkingAssetsDetailsDemandById(state, demandId!));
  const { data } = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails);

  return (
    <ProductDetailsContentHeaderLayout className={className}>
      <ProductDetailsContentHeaderLayout.TitleAndRejectionButtonWrapper>
        <ProductDetailsContentHeaderLayout.Title
          title={<ProductDetailsContentHeaderLayout.BankName bankName={demand?.bank.title} />}
        />
        {demand?.isActive === true && (
          <CreditWorkingAssetsDetailsDemandHeaderRejectionButton
            creditWorkingAssetsId={creditWorkingAssetsId}
            demandId={demand.demandId}
            bankName={demand.bank.title}
            groupProductName={data?.groupProductName ?? creditWorkingAssetsId}
          />
        )}
      </ProductDetailsContentHeaderLayout.TitleAndRejectionButtonWrapper>
      <ProductDetailsContentHeaderLayout.HeaderChips>
        {demand ? (
          getCreditWorkingAssetsDemandChips(demand).map((x) => (
            <ProductDetailsContentHeaderLayout.HeaderChip variant={x.type} key={x.type + x.text}>
              {x.text}
            </ProductDetailsContentHeaderLayout.HeaderChip>
          ))
        ) : (
          <Skeleton height={28} width={200} />
        )}
      </ProductDetailsContentHeaderLayout.HeaderChips>

      <CreditWorkingAssetsDetailsDemandHeaderDetails />
      <CreditWorkingAssetsDetailsDemandHeaderTabs.Container activeTab={activeTab} onTabChange={onTabClick} gap="lg">
        {creditWorkingAssetsDetailsDemandHeaderTab.values.map((x) => (
          <CreditWorkingAssetsDetailsDemandHeaderTabs.Tab
            key={x}
            tab={x}
            xsFlexBasis="auto"
            fontSize="fz3"
            text={creditWorkingAssetsDetailsDemandHeaderTab.getTitle(x)}
          />
        ))}
      </CreditWorkingAssetsDetailsDemandHeaderTabs.Container>
    </ProductDetailsContentHeaderLayout>
  );
};
