import { useEffect } from "react";
import { useFormikContext } from "formik";
import { ClientCheckFormValues } from "../../types/ClientCheckFormValues";
import { useDebounce } from "../../../../common/hooks/useDebounce";

export const ClientHeaderFormAutoSubmit = () => {
  const { submitForm, values, isValid } = useFormikContext<ClientCheckFormValues>();
  const [debounceValues] = useDebounce(values, 300);

  useEffect(() => {
    if (isValid && values === debounceValues) {
      submitForm();
    }
  }, [isValid, submitForm, debounceValues, values]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
