import { combineReducers } from "redux";
import { bankGuaranteeListFiltersReducer } from "./filters/bankGuaranteeListFilters.slice";
import { bankGuaranteeListItemsReducer } from "./items/bankGuaranteeListItems.slice";
import { bankGuaranteeListCountersReducer } from "./counters/bankGuaranteeListCounters.slice";
import { bankGuaranteeListItemModificationReducer } from "./item-modification/bankGuaranteeListItemModification.slice";

export const bankGuaranteeListReducer = combineReducers({
  filters: bankGuaranteeListFiltersReducer,
  items: bankGuaranteeListItemsReducer,
  counters: bankGuaranteeListCountersReducer,
  modification: bankGuaranteeListItemModificationReducer,
});
