import styled from "@emotion/styled";
import { css, CSSObject } from "@emotion/react";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ResponsiveStyleValue, SpaceType } from "../../../types";
import { FontSizeType } from "../../../types/FontSizeType";

export interface TabProps {
  isActive?: boolean;
  px?: ResponsiveStyleValue<SpaceType>;
  py?: ResponsiveStyleValue<SpaceType>;
  fontSize?: ResponsiveStyleValue<FontSizeType>;
  isGrow?: ResponsiveStyleValue<boolean>;
  flexBasis?: ResponsiveStyleValue<CSSObject["flexBasis"]>;
}

export const Tab = styled.button<TabProps>(
  ({ theme, isActive }) => css`
    font-family: inherit;
    font-size: ${theme.font.fz2};
    line-height: ${theme.font.defaultLineHeight};
    color: ${isActive ? theme.colors.primary : theme.colors.dark};
    font-weight: 500;
    cursor: pointer;
    border: none;
    padding: ${theme.spaces.lg} 0;
    margin: 0;
    appearance: none;
    background-color: transparent;
    background-image: linear-gradient(${theme.colors.primary}, ${theme.colors.primary});
    background-position: ${isActive ? "100% 100%" : "0 100%"};
    background-size: ${isActive ? "100% 3px" : "0 3px;"};
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s;
    -webkit-tap-highlight-color: ${theme.colors.primaryLight};
    user-select: none;

    &:focus-visible {
      & > span {
        color: ${theme.colors.primary};
        border-bottom: ${isActive ? "none" : ` 1px solid ${theme.colors.primary}`};
      }
    }
  `,
  ({ theme, fontSize }) =>
    makeResponsiveStyles(theme, fontSize, (x) => ({
      fontSize: x === undefined ? undefined : theme.font[x],
    })),
  ({ theme, px }) =>
    makeResponsiveStyles(theme, px, (x) => ({
      paddingLeft: x ? theme.spaces[x] : undefined,
      paddingRight: x ? theme.spaces[x] : undefined,
    })),
  ({ theme, py }) =>
    makeResponsiveStyles(theme, py, (x) => ({
      paddingTop: x ? theme.spaces[x] : undefined,
      paddingBottom: x ? theme.spaces[x] : undefined,
    })),
  ({ theme, flexBasis }) =>
    makeResponsiveStyles(theme, flexBasis, (x) => ({
      flexBasis: x,
    })),
  ({ theme, isGrow }) =>
    makeResponsiveStyles(theme, isGrow, (x) => {
      // eslint-disable-next-line no-nested-ternary
      const growValue = x === undefined ? undefined : x ? 1 : 0;

      return { flexGrow: growValue, flexShrink: growValue };
    }),
);
