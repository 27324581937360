import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { BrandLink } from "../../../../navigation";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";
import { WidgetsCalculatorAppLayoutButton } from "../../../../../widgets/calculator";
import { ChatDialogHeaderAppLayoutButton } from "../../../../../../chat/dialog";
import { QueueTasksNotificationsNavigationPopupButton } from "../../../../../../queue-tasks/notifications";
import { Box } from "../../../../../ui-kit/containers/box";
import { UserDetailsNavigationPopupUserButton } from "../../../../../../user/details";
import { AppLayoutHeaderHamburgerButton } from "../header-button/AppLayoutHeaderHamburgerButton";
import { useAppLayout } from "../../../app-layout-containers";
import { useAppLayoutIsFixedHeader } from "../../hooks/useAppLayoutIsFixedHeader";
import * as S from "./AppLayoutContent.styled";

export const AppLayoutContentHeader = () => {
  const { activeOverlayPanel, isMenuOpened, headerAction } = useAppLayout();

  const isFixed = useAppLayoutIsFixedHeader(isMenuOpened.value, activeOverlayPanel.value);
  const isDesktop = useThemeIsDesktop();

  const toggleHamburger = () => {
    isMenuOpened.setValue(!isMenuOpened.value);
  };

  return (
    <S.Header fixed={isFixed}>
      {headerAction.value === undefined || (isFixed && !isDesktop) ? <BrandLink /> : headerAction.value}
      <StackPanel gap="md" direction="row">
        <WidgetsCalculatorAppLayoutButton />
        <ChatDialogHeaderAppLayoutButton />
        {isDesktop && (
          <>
            <QueueTasksNotificationsNavigationPopupButton />
            <Box pl="lg">
              <UserDetailsNavigationPopupUserButton />
            </Box>
          </>
        )}
        {!isDesktop && <AppLayoutHeaderHamburgerButton onClick={toggleHamburger} open={isMenuOpened.value} />}
      </StackPanel>
    </S.Header>
  );
};
