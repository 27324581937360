import * as S from "./SearchClientCheckCompanyCard.styled";
import { TrashIcon } from "../../../common/ui-kit/icons";
import { Text, Typography } from "../../../common/ui-kit/typography";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { Box } from "../../../common/ui-kit/containers/box";

const BoxTypography = Typography.withComponent(Box);

export interface BankGuaranteeSearchClientCheckCompanyCardProps extends BaseCssProps {
  shortName?: string;
  fullName?: string;
  taxpayerNumber: string;
  fullAddress?: string;
  onRemove?: () => void | Promise<void>;
}

export const SearchClientCheckCompanyCard = ({
  shortName,
  fullName,
  taxpayerNumber,
  fullAddress,
  onRemove,
  className,
}: BankGuaranteeSearchClientCheckCompanyCardProps) => {
  return (
    <S.Card className={className}>
      <S.TitleRow>
        <Text fontWeight={700} fontSize="fz4" lineHeight="defaultLineHeight">
          {shortName}
        </Text>
        {onRemove && (
          <S.RemoveButton type="button" title="Выбрать другого клиента" onClick={onRemove}>
            <TrashIcon />
          </S.RemoveButton>
        )}
      </S.TitleRow>
      <span>{fullName}</span>
      {!fullAddress && <Text fontColor="primaryCaption">ИНН&nbsp;{taxpayerNumber}</Text>}
      {fullAddress && (
        <>
          <BoxTypography as="span" fontSize="fz2" fontWeight={500} pt="sm" fontColor="typography.primaryCaption">
            ИНН:&nbsp;{taxpayerNumber}
          </BoxTypography>
          <Typography as="span" fontSize="fz2" fontWeight={500} fontColor="typography.primaryCaption">
            Юр. адрес:&nbsp;{fullAddress}
          </Typography>
        </>
      )}
    </S.Card>
  );
};
