import styled from "@emotion/styled";

export const Title = styled.span`
  flex: 1;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
`;

export const Time = styled.time`
  opacity: 0.5;
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  color: ${({ theme }) => theme.colors.dark};
`;
