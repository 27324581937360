import styled from "@emotion/styled";
import searchIcon from "../icons/search.cached.svg";

export const SearchInput = styled.input`
  font-size: ${({ theme }) => theme.font.fz2};
  padding: 8px 8px 8px 35px;
  border: 1px solid ${({ theme }) => theme.colors.primaryLight};
  border-radius: 4px;
  background: url(${searchIcon}) no-repeat 12px center;

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
`;
