import { combineSliceNames } from "../../../common/store/utils";
import { PUBLIC_USER_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { publicUserPrivacyPoliciesApi } from "../api/publicUserPrivacyPoliciesApi";

export const PUBLIC_USER_PRIVACY_POLICIES_SLICE_NAME = combineSliceNames(PUBLIC_USER_SLICE_NAME, "privacyPolicies");

export const { getList: getListPublicUserPrivacyPolicies, getFile: getFilePublicUserPrivacyPolicies } = createApiThunks(
  PUBLIC_USER_PRIVACY_POLICIES_SLICE_NAME,
  publicUserPrivacyPoliciesApi
);
