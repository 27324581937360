import { useCallback } from "react";
import { CreditWorkingAssetsId } from "../../../common/types/demand";
import { useAppDispatch } from "../../../common/store";
import { transferCreditWorkingAssetsItem } from "../store/creditWorkingAssetsList.thunks";

export const useCreditWorkingAssetsListItemTransferAction = (creditWorkingAssetsId: CreditWorkingAssetsId) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(transferCreditWorkingAssetsItem(creditWorkingAssetsId));
  }, [dispatch, creditWorkingAssetsId]);
};
