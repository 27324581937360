import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.layout.borderPadding};
  padding: ${({ theme }) => theme.layout.borderPadding} 0;
  margin-bottom: ${({ theme }) => theme.layout.borderPadding};
`;
