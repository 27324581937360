import {ISelectValue} from "../../../../controls-old/select/Select";

const monthNames: string[] = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

export const months = (): ISelectValue[] => {
    const loop = 12
    const result = Array<ISelectValue>(loop)

    for (let i = 0; i < loop; i++) {
        result[i] = {
            key: i.toString(),
            value: monthNames[i]
        }
    }

    return result
}

export const years = (): ISelectValue[] => {
    const loop = 5
    const result = Array<ISelectValue>(loop)
    let currentYear = new Date().getFullYear()

    for (let i = 0; i < loop; i++) {
        result[i] = {
            key: (currentYear - i).toString(),
            value: (currentYear - i).toString()
        }
    }

    return result
}