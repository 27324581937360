import styled from "@emotion/styled";
import { Theme } from "@emotion/react";

export interface WrapperProps {
  backgroundColor?: keyof Theme["palette"]["background"];
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1;
  background-color: ${({ theme, backgroundColor }) => theme.palette.background[backgroundColor ?? "contrastLight"]};
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    background-color: ${({ theme }) => theme.palette.background.light};
  }
`;

export const CommonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: calc(${({ theme }) => theme.layout.borderPadding} / 1.5);
  padding-right: calc(${({ theme }) => theme.layout.borderPadding} / 1.5);

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding-right: ${({ theme }) => theme.layout.borderPadding};
    padding-left: calc(${({ theme }) => theme.layout.borderPadding} + 15px);
  }

  ${({ theme }) => theme.breakpoints.mqUp("xxl")} {
    max-width: 1120px;
  }
`;

export const Header = styled(CommonContainer.withComponent("header"))`
  background-color: ${({ theme }) => theme.palette.background.light};
  padding-top: calc(${({ theme }) => theme.layout.borderPadding} / 1.5);

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding-top: ${({ theme }) => theme.layout.borderPadding};
  }
`;

export const TabsFilterContainer = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
    align-items: center;
    justify-content: space-between;
  }
`;

export const ListContainer = styled(CommonContainer.withComponent("section"))`
  padding-top: ${({ theme }) => theme.spaces.lg};
  padding-bottom: ${({ theme }) => theme.spaces.lg};
`;
