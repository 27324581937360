import { useCallback, useEffect, useRef, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { AnyOutputApiAsyncThunk } from "../../store/utils";
import { BasePaginatedState, isIdle } from "../../types/state";
import { AsyncThunkActionRequest } from "../../types/thunks";

export enum MutationComparerResult {
  Page,
  Filter,
  None,
}

export interface UseInfiniteScrollMutationArguments<TFilter> {
  stateSelector: (state: RootState) => BasePaginatedState;
  action: AnyOutputApiAsyncThunk<TFilter>;
  comparer: (prevArg: TFilter, page: number) => MutationComparerResult;
  mapper: (page: number) => TFilter;
}

const FIRST_PAGE = 1;

export const useInfiniteScrollMutation = <TFilter>({
  stateSelector,
  action,
  comparer,
  mapper,
}: UseInfiniteScrollMutationArguments<TFilter>) => {
  const dispatch = useAppDispatch();
  const { status, pagination } = useAppSelector(stateSelector);

  const [page, setPage] = useState(pagination?.pageNumber ?? FIRST_PAGE);
  const [prevArg, setPrevArg] = useState(() => mapper(page));

  const statusRef = useRef(status);
  const requestRef = useRef<AsyncThunkActionRequest<unknown> | undefined>(undefined);

  useEffect(() => {
    if (isIdle(status) && !isIdle(statusRef.current)) {
      setPage(FIRST_PAGE);
      setPrevArg(mapper(FIRST_PAGE));
    }
    statusRef.current = status;
  }, [mapper, status]);

  useEffect(() => {
    const changes = comparer(prevArg, page);

    if (changes === MutationComparerResult.Filter) {
      setPage(FIRST_PAGE);
      setPrevArg(mapper(FIRST_PAGE));
    }
    if (changes === MutationComparerResult.Page) {
      setPrevArg(mapper(page));
    }
  }, [comparer, mapper, page, prevArg]);

  useEffect(() => {
    // NOTE: Если сменилась заявка, и есть активный запрос вытягивания истории задач - отменяем запрос
    if (requestRef.current && pagination === undefined) {
      requestRef.current?.abort();
    }
  }, [pagination]);

  useEffect(() => {
    requestRef.current = dispatch(action(prevArg));

    return () => {
      requestRef.current?.abort();
    };
  }, [dispatch, action, prevArg]);

  const onNextPageFetching = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  return {
    onNextPageFetching,
    page,
  };
};
