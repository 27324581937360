import { useCallback, useEffect } from "react";
import { QueueTasksListHeader } from "../components/header/QueueTasksListHeader";
import { usePageTitle } from "../../../common/navigation/page-title";
import { QueueTasksList } from "../components/QueueTasksList";
import { useGetQueryParams } from "../../../common/filters";
import { useQueueTasksListQueryStateMutator } from "../hooks/useQueueTasksListQueryStateMutator";
import { queueTasksListFiltersValidationSchema } from "../validation/queueTasksListFiltersValidationSchema";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { useAppDispatch } from "../../../common/store";
import { getQueueTasksListFilters } from "../store/queueTasksList.thunks";

export const QueueTasksListPage = () => {
  usePageTitle("Активные задачи");

  const dispatch = useAppDispatch();
  const [filteringObject, forceUpdate] = useGetQueryParams(queueTasksListFiltersValidationSchema);
  const mutator = useQueueTasksListQueryStateMutator(queueTasksListFiltersValidationSchema);

  const onListUpdateClick = useCallback(() => {
    if (filteringObject.page === 1) {
      forceUpdate();
    } else {
      mutator(filteringObject, "add", "page", 1);
    }
  }, [filteringObject, forceUpdate, mutator]);

  useEffect(() => {
    dispatch(getQueueTasksListFilters());
  }, [dispatch]);

  return (
    <AppLayoutResponsiveListPage>
      <QueueTasksListHeader filteringObject={filteringObject} mutator={mutator} onUpdateClick={onListUpdateClick} />
      <QueueTasksList filteringObject={filteringObject} forceUpdate={forceUpdate} />
    </AppLayoutResponsiveListPage>
  );
};
