import { useField } from "formik";
import { ClipboardEvent, useCallback } from "react";
import { isValidUrl } from "@sideg/helpers";

const PURCHASE_NUMBER_PARAMETER_NAME = "regNumber";

export const usePurchaseOnPaste = (name: string) => {
  const [, , { setValue, setTouched }] = useField<string>(name);

  return useCallback(
    async (event: ClipboardEvent<HTMLInputElement>) => {
      const text = event.clipboardData.getData("Text");
      if (text !== undefined && isValidUrl(text)) {
        const url = new URL(text);
        const purchaseNumber = url.searchParams.get(PURCHASE_NUMBER_PARAMETER_NAME);
        if (purchaseNumber) {
          event.preventDefault();

          await setTouched(true, true);
          await setValue(purchaseNumber, true);
        }
      }
    },
    [setTouched, setValue],
  );
};
