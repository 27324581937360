import { boolean, mixed, object } from "yup";
import { getEnumLikeLiteralTypeSchema, getNumberBrandSchema } from "../../../common/validation";
import { QueueTaskId } from "../../../common/types/demand";
import { bankGuaranteeDetailsDemandHeaderTab } from "../types/demand/BankGuaranteeDetailsDemandHeaderTab";
import {
  BankGuaranteeDetailsFilterContainerToScrollType,
  bankGuaranteeDetailsFilterContainerToScrollTypeValues,
} from "../types/filters/BankGuaranteeDetailsFilterContainerToScrollTypeValues";
import { bankGuaranteeDetailsDemandNewCommentGroupType } from "../types/demand/new-comment/bankGuaranteeNewMessageGroupType";

export const bankGuaranteeDetailsFilterValidationSchema = object({
  queueTaskId: getNumberBrandSchema<QueueTaskId>().notRequired(),
  queueTaskGroup: getEnumLikeLiteralTypeSchema(bankGuaranteeDetailsDemandNewCommentGroupType)
    .notRequired()
    .default("All"),
  tab: getEnumLikeLiteralTypeSchema(bankGuaranteeDetailsDemandHeaderTab).notRequired().default("tasks"),
  isNewCommentOpened: boolean().notRequired().default(false),
  containerToScroll: mixed<BankGuaranteeDetailsFilterContainerToScrollType>()
    .oneOf([...bankGuaranteeDetailsFilterContainerToScrollTypeValues])
    .notRequired(),
});
