import * as React from "react";
import Skeleton from "react-loading-skeleton";
import { currencyHelper, dateTimeHelper } from "@sideg/helpers";
import { CodeWithTitle } from "../../../types/objects";
import { DemandIssueCreditBase } from "../../../types/demand";
import { ProductRejectionDemandBase } from "../../../product-rejection";
import { ProductDetailsDemandHeaderDetailsItem } from "../../types/ProductDetailsDemandHeaderDetailsItem";

interface ProductCreditsOnlyDetailsDemand {
  status: CodeWithTitle<string>;
  creditSum: number;
  confirmedCreditSum?: number;
  approvedCreditSum?: number;
  dateEnd: string;
  dateStart: string;
  termination?: ProductRejectionDemandBase;
  issue?: DemandIssueCreditBase;
}

export const getProductCreditsOnlyDetailsDemandHeaderDetailsItems = <T extends ProductCreditsOnlyDetailsDemand>(
  demand: T | undefined,
): {
  main: ProductDetailsDemandHeaderDetailsItem[];
  approve: ProductDetailsDemandHeaderDetailsItem[];
} => {
  const main: ProductDetailsDemandHeaderDetailsItem[] = [];
  const approve: ProductDetailsDemandHeaderDetailsItem[] = [];

  const pushMain = (title: string, data: React.ReactNode) => main.push({ title, data });
  const pushApprove = (title: string, data: React.ReactNode) => approve.push({ title, data });

  const isLoading = demand === undefined;

  pushMain("Статус заявки", isLoading ? <Skeleton width={90} /> : demand.status.title);
  pushMain(
    "Запрашиваемая сумма",
    isLoading ? <Skeleton width={120} /> : currencyHelper.getCurrencyString(demand.creditSum),
  );

  if (demand?.confirmedCreditSum !== undefined) {
    pushMain("Одобренная сумма", currencyHelper.getCurrencyString(demand.confirmedCreditSum));
  }

  if (demand?.approvedCreditSum !== undefined) {
    pushMain("Выданная сумма", currencyHelper.getCurrencyString(demand.approvedCreditSum));
  }

  if (demand?.issue === undefined) {
    pushMain(
      "Даты кредита",
      isLoading ? (
        <Skeleton width={80} />
      ) : (
        `${dateTimeHelper.format(new Date(demand.dateStart), "dayShortMonthYear")} – ${dateTimeHelper.format(
          new Date(demand.dateEnd),
          "dayShortMonthYear",
        )}`
      ),
    );
  }

  if (demand?.termination !== undefined) {
    pushMain("Причина отказа", demand.termination.reason.title);
    pushMain("", demand.termination.comment);
  }

  if (demand?.issue) {
    pushMain("Дата выдачи кредита", dateTimeHelper.format(new Date(demand.issue.dateIssue), "dayShortMonthYear"));
    pushMain("Дата окончания кредита", dateTimeHelper.format(new Date(demand.issue.dateEnd), "dayShortMonthYear"));

    pushApprove("Номер договора", demand.issue.number);
    if (demand.issue.actualSum !== undefined) {
      pushApprove("Фактическая стоимость", currencyHelper.getCurrencyString(demand.issue.actualSum));
    }
    if (demand.issue.actualRate !== undefined) {
      pushApprove("Фактическая ставка", `${demand.issue.actualRate}%`);
    }
    if (demand.issue.netRate !== undefined) {
      pushApprove("Годовая ставка за пользование", `${demand.issue.netRate}%`);
    }
    if (demand.issue.agentSum !== undefined) {
      pushApprove("Агентское вознаграждение", currencyHelper.getCurrencyString(demand.issue.agentSum));
    }
  }

  return { main, approve };
};
