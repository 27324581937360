import { Context, useContext } from "react";

export const useContextInside = <TContextValue>(value: Context<TContextValue>) => {
  const context = useContext(value);
  if (context === undefined && import.meta.env.MODE !== "production") {
    throw new Error(`Попытка использовать хук вне контекста ${value.displayName}`);
  }

  return context;
};
