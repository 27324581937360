import * as React from "react";
import * as S from "./BankGuaranteeRewardsTermItem.styled";

export interface BankGuaranteeRewardsTermItemProps {
  rate: React.ReactNode;
  dateStart: React.ReactNode;
  dateEnd?: React.ReactNode;
  children: React.ReactNode;
}

export const BankGuaranteeRewardsTermItem = ({
  rate,
  dateStart,
  dateEnd,
  children,
}: BankGuaranteeRewardsTermItemProps) => {
  return (
    <>
      {rate}
      <S.Body>
        <S.Terms>
          {children}
          {dateStart}
          {dateEnd}
        </S.Terms>
      </S.Body>
    </>
  );
};
