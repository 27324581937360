import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { isLoading, isSucceeded } from "../../../common/types/state";
import { AlertLoadingToResult } from "../../../common/ui-kit/notifications/alert-with-indicator";
import { useAppSelector } from "../../../common/store";
import { ClientSearchClientStateSelector } from "../../types/ClientSearchClientStateSelector";
import { getClientSearchSelectors } from "../../store/clientSearch.selectors";
import * as S from "./SearchClientFindAlert.styled";
import { Icon, InfoIcon } from "../../../common/ui-kit/icons";
import { Text } from "../../../common/ui-kit/typography";
import { Box } from "../../../common/ui-kit/containers/box";

const BoxIcon = Box.withComponent(Icon);

export interface SearchClientFindLoadingAlertProps {
  clientStateSelector: ClientSearchClientStateSelector;
  inputSearchValue: string | undefined;
}

export const SearchClientFindAlert = ({ clientStateSelector, inputSearchValue }: SearchClientFindLoadingAlertProps) => {
  const { status, check } = useAppSelector(clientStateSelector);
  const ids = useAppSelector(getClientSearchSelectors(clientStateSelector).selectIds);

  const isStatusLoading = isLoading(status);
  const isNotFound =
    isSucceeded(status) &&
    check?.data === undefined &&
    ids.length === 0 &&
    inputSearchValue !== undefined &&
    inputSearchValue.length > 0;

  const show = isStatusLoading || isNotFound;
  const isDesktop = useThemeIsDesktop();

  return (
    <S.LoaderContainer show={isDesktop || show}>
      <AlertLoadingToResult
        isLoading={isStatusLoading}
        loadingText="Ищем организацию"
        isError={isNotFound}
        errorText="Такой организации не нашлось среди ваших клиентов. Попробуйте найти по ИНН"
        isIdle={isDesktop && !isStatusLoading && !isNotFound}
        idleText={
          <S.IdleLabelContainer>
            <BoxIcon flexGrow={0} flexShrink={0} as={InfoIcon} stroke="typography.medium" />
            <Text fontSize="fz2" lineHeight="defaultLineHeight" fontWeight={500} fontColor="medium">
              Поиск по наименованию — из списка ваших клиентов
            </Text>
          </S.IdleLabelContainer>
        }
      />
    </S.LoaderContainer>
  );
};
