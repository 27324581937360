import { Box } from "../../../../common/ui-kit/containers/box";
import { BackLinkButton } from "../../../../common/ui/buttons/BackLinkButton";
import * as S from "./UserProfilePageHeaderDesktop.desktop.styled";
import { NavigationStateFrom } from "../../../../common/navigation/navigation-state/types/NavigationStateFrom";
import { Text } from "../../../../common/ui-kit/typography";

interface UserProfilePageHeaderDesktopProps {
  from: NavigationStateFrom;
}

export const UserProfilePageHeaderDesktop = ({ from }: UserProfilePageHeaderDesktopProps) => {
  return (
    <S.Header>
      <S.Container>
        <Box flexGrow={1} flexShrink={1} flexBasis="0%">
          <BackLinkButton linkTo={from.url} text={from.backTitle} />
        </Box>
        <Text fontSize={1.625} fontColor="colors.dark" fontWeight={500} textAlign="center">
          Профиль
        </Text>
      </S.Container>
    </S.Header>
  );
};
