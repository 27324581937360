import { combineReducers, createSlice, isAnyOf, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { CreditWorkingAssetsCreationState } from "./creditWorkingAssetsCreation.types";
import {
  createDraftCreditWorkingAssetsCreation,
  CREDIT_WORKING_ASSETS_CREATION_SLICE_NAME,
  scoringByExistsCreditWorkingAssetsCreation,
  scoringCreditWorkingAssetsCreation,
} from "./creditWorkingAssetsCreation.thunks";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { combineSliceNames } from "../../../common/store/utils";
import { getClientSearchReducer } from "../../../client-search";
import { ProductCreationStages } from "../../../common/product-creation";

const initialState: CreditWorkingAssetsCreationState = {
  activeStage: "creation",
  creating: { status: LoadingStatusEnum.Idle, data: undefined },
  scoring: { status: LoadingStatusEnum.Idle, data: undefined },
  isFormSameAsInitial: false,
};

const slice = createSlice({
  name: CREDIT_WORKING_ASSETS_CREATION_SLICE_NAME,
  initialState,
  reducers: {
    new: () => initialState,
    changeStage: (state, action: PayloadAction<ProductCreationStages>) => {
      state.activeStage = action.payload;
    },
    setNextStage: (state) => {
      state.activeStage = state.activeStage === "creation" ? "scoring" : "creation";
    },
    setIsFormSameAsInitial: (state, action: PayloadAction<boolean>) => {
      state.isFormSameAsInitial = action.payload;

      if (!action.payload) {
        state.scoring = initialState.scoring;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDraftCreditWorkingAssetsCreation.pending, (state) => {
        state.creating.error = undefined;
      })
      .addCase(createDraftCreditWorkingAssetsCreation.fulfilled, (state, action) => {
        state.creating.data = action.meta.arg;
      })
      .addCase(createDraftCreditWorkingAssetsCreation.rejected, (state, action) => {
        state.creating.error = action.payload?.message;
      })
      .addMatcher(
        isAnyOf(scoringCreditWorkingAssetsCreation.fulfilled, scoringByExistsCreditWorkingAssetsCreation.fulfilled),
        (state, action) => {
          state.scoring.data = action.payload.body.groups;
        }
      )
      .addMatcher(isAsyncThunkAction(createDraftCreditWorkingAssetsCreation), (state, action) => {
        state.creating.status = getLoadingStatusFromAction(action);
      })
      .addMatcher(
        isAsyncThunkAction(scoringCreditWorkingAssetsCreation, scoringByExistsCreditWorkingAssetsCreation),
        (state, action) => {
          state.scoring.status = getLoadingStatusFromAction(action);
        }
      );
  },
});

export const creditWorkingAssetsCreationReducer = combineReducers({
  form: slice.reducer,
  clientSearch: getClientSearchReducer({
    key: "CreditWorkingAssets",
    caseExtraReducers: (builder, clientSearchInitialState) => {
      builder.addCase(slice.actions.new, () => clientSearchInitialState);
    },
    sliceName: combineSliceNames(CREDIT_WORKING_ASSETS_CREATION_SLICE_NAME, "clientSearch"),
  }),
});

export const {
  new: newCreditWorkingAssetsCreation,
  changeStage: changeStageCreditWorkingAssetCreation,
  setNextStage: setNextStageCreditWorkingAssetCreation,
  setIsFormSameAsInitial: setIsFormSameAsInitialCreditWorkingAssetCreation,
} = slice.actions;
