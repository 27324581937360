import { Fragment } from "react";
import { ConditionItem, ConditionItemTitle } from "../../../common/ui/layout/conditions";
import { BankGuaranteeCondition } from "../api/dto/output/GetBankGuaranteeConditionsOutputDto";
import * as S from "./BankGuaranteeConditionsTableBody.styled";
import { FileDownloadLabel } from "../../../files/download";

export interface BankGuaranteeConditionsTableBodyProps {
  conditions: BankGuaranteeCondition[];
}

export const BankGuaranteeConditionsTableBody = ({ conditions }: BankGuaranteeConditionsTableBodyProps) => {
  return (
    <>
      {conditions.map((item) => (
        <Fragment key={item.id}>
          <ConditionItemTitle>{item.bank.shortName}</ConditionItemTitle>
          <ConditionItem>{item.fzTypes.join(", ")}</ConditionItem>
          <ConditionItem>{item.maxSum}</ConditionItem>
          <ConditionItem>{item.maxTerm}</ConditionItem>
          <ConditionItem>{item.companyLifeTime}</ConditionItem>
          <ConditionItem>
            <S.ItemUl>
              {item.guaranteeTypes.map((type) => (
                <S.ItemLi key={type.code}>
                  <span>{type.title}</span>
                </S.ItemLi>
              ))}
            </S.ItemUl>
          </ConditionItem>
          <ConditionItem>{item.stopFactors}</ConditionItem>
          <ConditionItem>
            {item.bankDocument && item.bankDocument?.file.privateKey && (
              <FileDownloadLabel
                fileId={item.bankDocument.file.fileId}
                privateKey={item.bankDocument.file.privateKey}
                fileName={item.bankDocument.file.fileName}
              />
            )}
          </ConditionItem>
        </Fragment>
      ))}
    </>
  );
};
