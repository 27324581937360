import styled from "@emotion/styled";

export const ProductListItemColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    gap: ${({ theme }) => theme.spaces.sm};
  }
`;
