import { Formik } from "formik";
import { useRef } from "react";
import { LegalServicesProductId } from "../../../common/types";
import { LegalServicesCreationFromBrief } from "./brief/LegalServicesCreationFromBrief";
import { FormBox, FormScrollToFirstErrorAfterSubmit } from "../../../../common/form";
import { useLegalServicesCreationFormSubmit } from "../../hooks/form/useLegalServicesCreationFormSubmit";
import { LegalServicesCreationFormContent } from "./LegalServicesCreationFormContent";
import { LegalServicesCreationFormValues } from "../../types/LegalServicesCreationFormValues";
import { legalServicesCreationValidationSchema } from "../../validation/legalServicesCreationValidationSchema";
import { useSearchParamsOnce } from "../../../../common/filters";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { LegalServicesCreationFormContainer } from "./container/LegalServicesCreationFormContainer";

export interface LegalServicesCreationFormProps extends BaseCssProps {
  productId: LegalServicesProductId;
  onSubmitted: () => void;
}

export const LegalServicesCreationForm = ({ productId, onSubmitted, className }: LegalServicesCreationFormProps) => {
  const ref = useRef<HTMLFormElement | null>(null);
  const onSubmit = useLegalServicesCreationFormSubmit(productId, onSubmitted);

  const initialValues = useSearchParamsOnce(legalServicesCreationValidationSchema);

  return (
    <LegalServicesCreationFormContainer
      className={className}
      brief={<LegalServicesCreationFromBrief productId={productId} />}
    >
      <Formik<LegalServicesCreationFormValues>
        initialValues={initialValues as LegalServicesCreationFormValues}
        validationSchema={legalServicesCreationValidationSchema}
        onSubmit={onSubmit}
      >
        <FormBox ref={ref} display="flex" flexDirection="column" gap="xxl" flexShrink={1} flexGrow={1}>
          <FormScrollToFirstErrorAfterSubmit containerRef={ref} />
          <LegalServicesCreationFormContent productId={productId} />
        </FormBox>
      </Formik>
    </LegalServicesCreationFormContainer>
  );
};
