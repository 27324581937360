import { useEffect, useState, useMemo, useCallback } from "react";

export const useStickyLikeScrollElement = <T extends HTMLElement>(offsetTop?: number) => {
  const [referenceElement, setReferenceElement] = useState<T | null>(null);

  const [isFixed, setIsFixed] = useState(false);
  const [startAt, setStartAt] = useState<number | undefined>(undefined);

  const onFixed = useCallback(() => {
    if (referenceElement && !isFixed) {
      setStartAt(referenceElement.offsetTop - (offsetTop ?? 0));
    }
    setIsFixed(!!referenceElement && startAt !== undefined && document.documentElement.scrollTop > startAt);
  }, [isFixed, offsetTop, referenceElement, startAt]);

  useEffect(() => {
    onFixed();

    window.addEventListener("scroll", onFixed);

    return () => {
      window.removeEventListener("scroll", onFixed);
    };
  }, [onFixed]);

  return useMemo(
    () => ({ isFixed, ref: setReferenceElement, fixedElement: referenceElement }),
    [isFixed, referenceElement]
  );
};
