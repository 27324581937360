import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { StackPanel } from "../../../../../../../common/ui-kit/containers/stack-panel";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { Typography } from "../../../../../../../common/ui-kit/typography";
import { ProductListCardContainerMobile } from "../../../../../../../common/ui/product/list/card";

export interface CreditExecutionListItemCardMobileProps extends BaseCssProps {
  numberAndDate: React.ReactNode;
  statusBadge: React.ReactNode;
  actionButton: React.ReactNode;
  clientName: React.ReactNode;
  details: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
}

export const CreditExecutionListItemCardMobile = ({
  numberAndDate,
  details,
  footer,
  actionButton,
  clientName,
  statusBadge,
  className,
  children,
}: CreditExecutionListItemCardMobileProps) => {
  return (
    <ProductListCardContainerMobile
      header={
        <>
          <StackPanel gap="xs" direction="row" alignItems="center" justifyContent="space-between">
            <Typography as="h3" fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight">
              {numberAndDate}
            </Typography>
            <StackPanel gap="md" direction="row" alignItems="center">
              {statusBadge}
              {actionButton}
            </StackPanel>
          </StackPanel>
          <Box display="flex" gap="sm" flexDirection="column" pb="md">
            <Typography fontSize="fz4" lineHeight="defaultLineHeight" fontWeight={700}>
              {clientName}
            </Typography>
            {details}
          </Box>
        </>
      }
      footer={footer}
      className={className}
    >
      {children}
    </ProductListCardContainerMobile>
  );
};
