import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import { FullPageContainerCommonCenteredWrapper } from "../common/FullPageContainerCommonCenteredWrapper";

export interface FooterCenteredContainerProps {
  alignItems?: CSSObject["alignItems"];
}

export const FooterCenteredContainer = styled(FullPageContainerCommonCenteredWrapper)<FooterCenteredContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ alignItems }) => alignItems};
`;

export const Footer = styled.footer`
  min-height: 120px;
  display: flex;
  background-color: ${({ theme }) => theme.layout.demand.colors.rightSideBackgroundColor};
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    background-color: ${({ theme }) => theme.secondaryColors.lightBackground};
  }
`;
