import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import { useBankGuaranteeCreationDateEventField } from "../../../hooks/creation/fields/useBankGuaranteeCreationDateEventField";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";

export interface BankGuaranteeCreationDateEventFieldProps {
  disabled?: boolean;
}

export const BankGuaranteeCreationDateEventField = ({ disabled }: BankGuaranteeCreationDateEventFieldProps) => {
  const { isExists, label } = useBankGuaranteeCreationDateEventField();
  const [{ name, value, error }, { setValue, setTouched }] = useBankGuaranteeCreationField("dateEvent");

  return (
    <SimpleOpacityTransition timeout={300} in={isExists} unmountOnExit mountOnEnter>
      <DateFormInput
        autoComplete="off"
        placeholder="дд.мм.гггг"
        size="medium"
        label={label}
        name={name}
        value={value}
        error={error}
        onChange={async (e) => {
          await setValue(e as unknown as Date, true);
        }}
        onBlur={() => setTouched(true, true)}
        disabled={disabled}
      />
    </SimpleOpacityTransition>
  );
};
