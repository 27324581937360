import { useFormikContext } from "formik";
import { Fragment } from "react";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { isLoading, isSucceeded } from "../../../../common/types/state";
import { FullPageContainerCompound } from "../../../../common/ui/containers/full-page";
import { useAppSelector } from "../../../../common/store";
import { selectAgencyDemand, selectAgencyDemandDocumentsIds } from "../../store/agencyDemand.selectors";
import { AgencyDemandDocument } from "../documents/AgencyDemandDocument";
import { Typography } from "../../../../common/ui-kit/typography";
import { ButtonLoading } from "../../../../common/ui-kit/form-controls/button";
import { LineSeparator } from "../../../../common/ui-kit/separators";
import { AgencyDemandDocumentsContainer } from "../documents/AgencyDemandDocumentsContainer";
import { AgencyDemandDocumentsSkeleton } from "../documents/AgencyDemandDocuments.skeleton";

export const AgencyDemandFormContent = () => {
  const { status, data } = useAppSelector(selectAgencyDemand);
  const ids = useAppSelector(selectAgencyDemandDocumentsIds);

  const form = useFormikContext();
  const isDesktop = useThemeIsDesktop();

  return (
    <>
      <FullPageContainerCompound.MainWrapper>
        <FullPageContainerCompound.MainCenteredContainer gap="xxl">
          <Typography fontSize="fz3" lineHeight="defaultLineHeight" fontWeight={500}>
            {data?.message}
          </Typography>
          {data !== undefined && (
            <AgencyDemandDocumentsContainer>
              {ids.map((x, index) => (
                <Fragment key={x}>
                  {index > 0 && <LineSeparator />}
                  <AgencyDemandDocument
                    documentId={x}
                    canRealTimeUpload={data.canRealTimeUpload === true}
                    queueTaskId={data.id}
                  />
                </Fragment>
              ))}
            </AgencyDemandDocumentsContainer>
          )}
          {data === undefined && isLoading(status) && <AgencyDemandDocumentsSkeleton />}
        </FullPageContainerCompound.MainCenteredContainer>
      </FullPageContainerCompound.MainWrapper>
      <FullPageContainerCompound.Footer alignItems="flex-start">
        <ButtonLoading
          variant="contained"
          size="medium"
          type="submit"
          disabled={!isSucceeded(status)}
          isLoading={form.isSubmitting}
          isFullWidth={!isDesktop}
        >
          Отправить на проверку
        </ButtonLoading>
      </FullPageContainerCompound.Footer>
    </>
  );
};
