import { combineSliceNames } from "../../../common/store/utils";
import { LEGAL_SERVICES_SLICE_NAME } from "../../common/store";
import { legalServicesCreationApi } from "../api/legalServicesCreationApi";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";

export const LEGAL_SERVICES_CREATION_SLICE_NAME = combineSliceNames(LEGAL_SERVICES_SLICE_NAME, "creation");

export const legalServicesCreationThunks = createApiThunks(
  LEGAL_SERVICES_CREATION_SLICE_NAME,
  legalServicesCreationApi,
);
