import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { queueTasksListSuggestionsAdapter } from "./queueTasksListSuggestions.adapter";
import { QueueTasksListSuggestionsSliceState } from "./queueTasksListSuggestions.types";
import { QUEUE_TASKS_LIST_SUGGESTIONS_SLICE_NAME } from "./queueTasksListSuggestions.thunks";
import { getQueueTasksList } from "../../store/queueTasksList.thunks";
import { completeQueueTask } from "../../../common/store";
import { getLoadingStatusFromAction } from "../../../../common/types/state";

const initialState = queueTasksListSuggestionsAdapter.getInitialState<QueueTasksListSuggestionsSliceState>({});

const slice = createSlice({
  name: QUEUE_TASKS_LIST_SUGGESTIONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQueueTasksList.pending, () => {
        return initialState;
      })
      .addCase(getQueueTasksList.fulfilled, (state, action) => {
        const suggestions = action.payload.body.queueTasksSuggested;
        if (suggestions) {
          queueTasksListSuggestionsAdapter.setAll(state, suggestions.data);
          state.productType = suggestions.productType;
          state.productId = suggestions.productId;
          state.hasMoreItems =
            !!suggestions.paginationInformation?.pageCount && suggestions.paginationInformation?.pageCount > 1;
        }
      })
      .addCase(completeQueueTask.fulfilled, (state, action) => {
        queueTasksListSuggestionsAdapter.removeOne(state, action.meta.arg.queueTaskId);
      })
      .addMatcher(isAsyncThunkAction(completeQueueTask), (state, action) => {
        const { queueTaskId } = action.meta.arg;
        queueTasksListSuggestionsAdapter.updateOne(state, {
          id: queueTaskId,
          changes: { action: { status: getLoadingStatusFromAction(action) } },
        });
      });
  },
});

export const queueTasksListSuggestionsReducer = slice.reducer;
