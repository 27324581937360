import * as React from "react";
import * as S from "./LabelNotificationWrapper.styled";

export interface LabelNotificationWrapperProps {
  isWithNotification?: boolean;
  children: React.ReactNode;
}

export const LabelNotificationWrapper = ({ isWithNotification, children }: LabelNotificationWrapperProps) => {
  const Wrapper = isWithNotification ? S.Wrapper : React.Fragment;

  return <Wrapper>{children}</Wrapper>;
};
