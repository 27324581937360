export enum UserFeatureFlagEnum {
  IsSendScoringToAllBanks = "IsSendScoringToAllBanks",
  IsNews = "IsNews",
  IsOffer = "IsOffer",
  IsProductBG = "IsProductBG",
  IsProductBGCalc = "IsProductBGCalc",
  IsProductBGReward = "IsProductBGReward",
  IsProductCwa = "IsProductCwa",
  IsProductCe = "IsProductCe",
  IsProductSc = "IsProductSc",
  IsProductFactoring = "IsProductFactoring",
  IsProductLegalServices = "IsProductLegalServices",
  IsClientCheck = "IsClientCheck",
  IsUdaCalc = "IsUdaCalc",
  IsReportAgent = "IsReportAgent",
  IsReportDemands = "IsReportDemands",
}

export type UserFeatureFlagPermission = UserFeatureFlagEnum | true;
