import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  Director: "Директор",
  Courier: "Лицо по доверенности или курьер",
};

export const bankGuaranteeRequestOriginalOfficeRecipientType = makeEnumLikeLiteral(
  ["Director", "Courier"] as const,
  titles
);

export type BankGuaranteeRequestOriginalOfficeRecipientType = EnumLikeLiteralType<
  typeof bankGuaranteeRequestOriginalOfficeRecipientType
>;
