import styled from "@emotion/styled";
import { ToastType } from "../types/ToastType";

export const Container = styled.article`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces.sm};
  gap: ${({ theme }) => theme.spaces.sm};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.layout.header.backgroundColor};
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  font-weight: 500;
  line-height: 1.4;
  font-size: ${({ theme }) => theme.font.fz3};
  box-shadow: 0 8px 20px rgba(28, 34, 86, 0.3);

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    font-size: ${({ theme }) => theme.font.fz2};
    line-height: 20px;
  }
`;

export const Icon = styled.svg<{ type: ToastType }>`
  width: 24px;
  height: 24px;
  flex-grow: 0;
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    width: 20px;
    height: 20px;
  }

  & > path {
    stroke: ${({ theme, type }) => {
      switch (type) {
        case "success":
          return theme.palette.status.success;
        case "warning":
          return theme.palette.status.warning;
        case "error":
          return theme.palette.status.error;
        default:
          return theme.colors.primary;
      }
    }};
  }
`;

export const CancelButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    width: 20px;
    height: 20px;
  }

  & > svg {
    width: 16px;
    height: 16px;

    & > path {
      stroke: ${({ theme }) => theme.colors.secondaryLight};
    }
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;
