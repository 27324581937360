import { RawApiResponse } from "../types/RawApiResponse";
import {
  ApiBlobResponse,
  ApiResponse,
  BaseApiError,
  createApiError,
  createApiErrorFromRawWebApiResponse,
} from "../types";
import { StatusCodeEnum } from "../../services/fetchClient";
import { RawWebApiResponse, isRawWebApiResponse } from "../types/RawWebApiResponse";
import { apiConfig } from "../configs";
import { FetchClientHeaders } from "../../services/fetchClient/types/FetchClientHeaders";
import {
  CONTENT_DISPOSITION_HEADER,
  getFileNameFromContentDisposition,
} from "../../utils/getFileNameFromContentDisposition";

const alwaysSuccessResponseTransformer = <T = unknown>(response: RawApiResponse<T>): ApiResponse<T> => {
  if (response.code === StatusCodeEnum.Success || response.code === StatusCodeEnum.Unauthorized) {
    return { ok: response.code === StatusCodeEnum.Success, status: response.code, body: response.body };
  }

  if (response.errors?.length) {
    throw createApiError({
      message: response.errors[0].message,
      status: response.code,
      isApiError: true,
    } as BaseApiError);
  }

  throw createApiError(response);
};

export const blobResponseTransformer = (data: Blob, headers?: FetchClientHeaders): ApiResponse<ApiBlobResponse> => {
  if (data.type.startsWith("application/json")) {
    // eslint-disable-next-line no-throw-literal
    throw {
      status: StatusCodeEnum.BadRequest,
      rawText: apiConfig.errorMessages.general,
      isApiError: true,
    };
  }

  let fileName = "unknown";
  const header = headers?.[CONTENT_DISPOSITION_HEADER];

  if (typeof header === "string") {
    const dispositionFileName = getFileNameFromContentDisposition(header);
    fileName = dispositionFileName ?? fileName;
  }

  return {
    ok: true,
    status: StatusCodeEnum.Success,
    body: {
      data,
      fileName,
    },
  };
};

export const apiResponseTransformer = <T = unknown>(
  data: RawApiResponse<T> | RawWebApiResponse<T> | Blob,
): ApiResponse<T> | RawWebApiResponse<T> => {
  const alwaysSuccessResponse = data as RawApiResponse<T>;
  if (alwaysSuccessResponse?.code) {
    return alwaysSuccessResponseTransformer<T>(alwaysSuccessResponse);
  }

  const response = data as RawWebApiResponse<T>;
  if (response?.isError !== undefined) {
    if (response?.isError === false) {
      return { ok: true, status: StatusCodeEnum.Success, body: response.body };
    }

    return response;
  }

  throw createApiError(apiConfig.errorMessages.general);
};

export const apiResponseSuccessTransformer = <T = unknown>(
  data: RawApiResponse<T> | RawWebApiResponse<T> | Blob,
): ApiResponse<T> => {
  const result = apiResponseTransformer<T>(data);

  if (isRawWebApiResponse<T>(result)) {
    throw createApiErrorFromRawWebApiResponse(result);
  }

  return result;
};
