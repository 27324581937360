import { PropsWithChildren, useRef } from "react";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";
import { Box } from "../../../../common/ui-kit/containers/box";
import * as S from "./NewCommentTransition.styled";

export type DemandQueueTasksNewCommentTransitionProps = PropsWithChildren<CSSTransitionProps>;

export const DemandQueueTasksNewCommentTransition = ({
  children,
  ...props
}: DemandQueueTasksNewCommentTransitionProps) => {
  const nodeRef = useRef(null);

  return (
    <S.NewCommentTransition {...props} nodeRef={nodeRef}>
      <Box ref={nodeRef}>{children}</Box>
    </S.NewCommentTransition>
  );
};
