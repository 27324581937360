import { GetBankGuaranteeListItemOutputDto } from "../../api/dto/output/GetBankGuaranteeListOutputDto";
import { BankGuaranteeListItemClient } from "../../types/item/BankGuaranteeListItemClient";
import { BankGuaranteeListItemGuarantee } from "../../types/item/BankGuaranteeListItemGuarantee";
import { BankGuaranteeListItemDemand } from "../../types/item/BankGuaranteeListItemDemand";

export const mapItem = (
  item: GetBankGuaranteeListItemOutputDto
): {
  client: BankGuaranteeListItemClient;
  guarantee: BankGuaranteeListItemGuarantee;
  demands: BankGuaranteeListItemDemand[];
} => {
  const { demands, client } = item;
  const issuedDemandId = item.issuedDemand?.demandId;

  const guarantee: BankGuaranteeListItemGuarantee = {
    guaranteeId: item.guaranteeId,
    clientId: client.id,
    demandIds: demands.map((x) => x.demandId),
    guaranteeSum: item.guaranteeSum,
    guaranteeType: item.guaranteeType,
    dateAdded: item.dateAdded,
    federalLaw: item.federalLaw,
    purchaseLink: item.purchaseLink,
    purchaseNumber: item.purchaseNumber,
    issuedDemandId,
    activeTasksCount: item.countActiveTasks,
    canSendMore: issuedDemandId === undefined,
    canRejectAll: issuedDemandId === undefined && demands.some((x) => x.isActive),
    counters: item.counters,
  };

  return { client, demands: item.issuedDemand ? [item.issuedDemand] : demands, guarantee };
};

const mapList = (
  list: GetBankGuaranteeListItemOutputDto[]
): {
  clients: BankGuaranteeListItemClient[];
  guarantees: BankGuaranteeListItemGuarantee[];
  demands: BankGuaranteeListItemDemand[];
} => {
  const clients: BankGuaranteeListItemClient[] = [];
  const guarantees: BankGuaranteeListItemGuarantee[] = [];
  const demands: BankGuaranteeListItemDemand[] = [];

  list.forEach((x) => {
    const mappedValue = mapItem(x);

    clients.push(mappedValue.client);
    guarantees.push(mappedValue.guarantee);
    demands.push(...mappedValue.demands);
  });

  return { clients, demands, guarantees };
};

export const bankGuaranteeListItemsMapper = {
  mapItem,
  mapList,
};
