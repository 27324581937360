import { FormResponsiveRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox/components/radio-group/FormResponsiveRadioButtonGroup";
import { bankGuaranteeDetailsDemandNewCommentGroupType } from "../../../../types/demand/new-comment/bankGuaranteeNewMessageGroupType";
import { useGetFieldName, useTypedField } from "../../../../../../common/form";
import { CommentFormFormValues } from "../../../../../../common/product-comment";
import { GuaranteeQueueTasksGroupType } from "../../../../../../common/types/queue-tasks";
import { useBankGuaranteeDetailsFilterContext } from "../../../../hooks/filters/context/useBankGuaranteeDetailsFilterContext";
import { useBankGuaranteeDetailsFilterApiContext } from "../../../../hooks/filters/context/useBankGuaranteeDetailsFilterApiContext";

export type BankGuaranteeDetailsDemandNewCommentCardFormFiltersProps = {
  disabled?: boolean;
};

export const BankGuaranteeDetailsDemandNewCommentCardFormFilters = ({
  disabled,
}: BankGuaranteeDetailsDemandNewCommentCardFormFiltersProps) => {
  const getFieldName = useGetFieldName<CommentFormFormValues>();
  const [{ name }, { setValue, setTouched }] = useTypedField<GuaranteeQueueTasksGroupType>(getFieldName("groupType"));
  const context = useBankGuaranteeDetailsFilterContext();
  const mutate = useBankGuaranteeDetailsFilterApiContext();

  return (
    <FormResponsiveRadioButtonGroup
      value={context.queueTaskGroup}
      onChange={(newValue) => {
        mutate(context, (service) => service.changeGroupType(newValue));
        setValue(newValue, true);
      }}
      onBlur={() => {
        setTouched(true);
      }}
      types={bankGuaranteeDetailsDemandNewCommentGroupType.values}
      getTitle={bankGuaranteeDetailsDemandNewCommentGroupType.getTitle}
      name={name}
      label=""
      disabled={disabled}
    />
  );
};
