import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectCreditExecutionCreationScoring } from "../../store/creditExecutionCreation.selectors";
import { ApiAsyncThunk } from "../../../../common/store/utils";
import { ApiResponse } from "../../../../common/api/types";
import { ScoringCreditExecutionCreationOutputDto } from "../../api/dto/output/ScoringCreditExecutionCreationOutputDto";

export const useCreditExecutionCreationScoring = <TInput>(
  thunk: ApiAsyncThunk<ApiResponse<ScoringCreditExecutionCreationOutputDto>, TInput>,
  inputModel: TInput
) => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectCreditExecutionCreationScoring);

  useEffect(() => {
    if (data === undefined) {
      dispatch(thunk(inputModel));
    }
  }, [data, dispatch, inputModel, thunk]);
};
