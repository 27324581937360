import { useScrollToTopOnMount } from "../../../common/hooks/scroll";
import { usePageTitle } from "../../../common/navigation/page-title";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { useGetQueryParams } from "../../../common/filters";
import { usePaginatedQueryStateMutator } from "../../../common/filters/use-cases";
import { CreditExecutionListFilterContext } from "../store/filter-context/CreditExecutionListFilterContext";
import { CreditExecutionListFilterApiContext } from "../store/filter-context/CreditExecutionListFilterApiContext";
import { creditExecutionListFiltersValidationSchema } from "../validation/creditExecutionListFiltersValidationSchema";
import { CreditExecutionListHeader } from "../components/header/CreditExecutionListHeader";
import { useAppSelector } from "../../../common/store";
import { selectCreditExecutionListCounters } from "../store/counters/creditExecutionListCounters.selectors";
import { useFirstRenderThunkDispatch } from "../../../common/hooks";
import { getCountersCreditExecutionList } from "../store/creditExecutionList.thunks";
import { CreditExecutionList } from "../components/list/CreditExecutionList";

export const CreditExecutionListPage = () => {
  usePageTitle("Мои заявки на КИК");
  useScrollToTopOnMount();

  const [filteringObject, forceUpdate] = useGetQueryParams(creditExecutionListFiltersValidationSchema);
  const mutator = usePaginatedQueryStateMutator();
  const { status } = useAppSelector(selectCreditExecutionListCounters);

  useFirstRenderThunkDispatch(status, getCountersCreditExecutionList);

  return (
    <AppLayoutResponsiveListPage>
      <CreditExecutionListFilterContext.Provider value={filteringObject}>
        <CreditExecutionListFilterApiContext.Provider value={mutator}>
          <CreditExecutionListHeader />
          <CreditExecutionList onErrorReloadButtonClick={forceUpdate} />
        </CreditExecutionListFilterApiContext.Provider>
      </CreditExecutionListFilterContext.Provider>
    </AppLayoutResponsiveListPage>
  );
};
