import styled from "@emotion/styled";

export const Container = styled.div`
  display: block;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz4};
  line-height: 1;
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  text-decoration: none;

  & > svg > path {
    stroke: ${({ theme }) => theme.palette.typography.primaryLight};
  }
`;
