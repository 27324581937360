import styled from "@emotion/styled";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";

export const NewCommentTransition = styled(CSSTransition)<CSSTransitionProps>`
  &.enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
  }

  &.enter-active {
    max-height: 1000px;
    opacity: 1;
    overflow: unset;
    pointer-events: none;
    transition:
      max-height ${({ timeout }) => timeout}ms linear,
      opacity ${({ timeout }) => timeout}ms ease-in;
  }

  &.enter-done {
    pointer-events: visible;
  }

  &.exit {
    max-height: 1000px;
    opacity: 1;
    overflow: unset;
    pointer-events: none;
  }

  &.exit-active {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition:
      max-height ${({ timeout }) => timeout}ms linear,
      opacity ${({ timeout }) => timeout}ms ease-in;
  }

  &.exit-done {
    opacity: 0;
    pointer-events: none;
  }
`;
