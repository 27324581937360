import styled from "@emotion/styled";
import { CommonInputIcon } from "@sideg/ui-kit/atoms/form-controls/input/common/components/CommonInput";
import { MinusInCircleIcon, PlusInCircleIcon } from "@sideg/ui-kit/atoms/icons";

export const ControlButton = styled.button`
  appearance: none;
  background: ${({ theme }) => theme.palette.light.neutral.bgr.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;

  &:disabled {
    display: none;
  }

  & > svg {
    & > path {
      stroke: ${({ theme }) => theme.palette.light.primary.text.default};
    }
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.light.primary.text.default};
  }
`;

export const MinusButtonIcon = styled(CommonInputIcon.withComponent(MinusInCircleIcon))``;
export const PlusButtonIcon = styled(CommonInputIcon.withComponent(PlusInCircleIcon))``;
