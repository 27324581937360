import { WidgetsNewsHeaderButton } from "./WidgetsNewsHeaderButton";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../../ui-kit/icons";
import { NavigationType } from "../../hooks/useWidgetsNewsSlider";
import { BaseCssProps } from "../../../../ui-kit/types";
import * as S from "./WidgetsNewsHeader.styled";

export interface WidgetsNewsHeaderProps extends BaseCssProps {
  onNavigationClick: (type: NavigationType) => Promise<void> | void;
  canNavigatePrevious: boolean;
  canNavigateNext: boolean;
}

export const WidgetsNewsHeader = ({
  onNavigationClick,
  canNavigateNext,
  canNavigatePrevious,
  className,
}: WidgetsNewsHeaderProps) => {
  const onClick = (type: NavigationType) => () => onNavigationClick(type);

  return (
    <S.Header className={className}>
      <span>Новости</span>
      <div>
        <WidgetsNewsHeaderButton
          icon={<ChevronLeftIcon />}
          onClick={onClick("previous")}
          disabled={!canNavigatePrevious}
        />
        <WidgetsNewsHeaderButton icon={<ChevronRightIcon />} onClick={onClick("next")} disabled={!canNavigateNext} />
      </div>
    </S.Header>
  );
};
