import { dateTimeHelper } from "@sideg/helpers";
import { useAppSelector } from "../../../../common/store";
import {
  FilteringItemsConverterDictionary,
  getFilteringValueTextByEntitiesCodeWithTitle,
  useFilteringItemsConverterDictionary,
} from "../../../../common/filters";
import { CreditExecutionListFilterContextValue } from "../../types/filters/CreditExecutionListFilterContextValue";
import { selectCreditExecutionFiltersData } from "../../store/filters/creditExecutionListFilters.selectors";

export const useCreditExecutionListFilteringItemsConverter = () => {
  const data = useAppSelector(selectCreditExecutionFiltersData);

  const dictionary: FilteringItemsConverterDictionary<CreditExecutionListFilterContextValue> = {
    page: null,
    tab: null,
    search: null,
    clientNameOrTaxpayerNumber: null,
    productId: null,
    demandIdOrProductId: null,
    bankIds: getFilteringValueTextByEntitiesCodeWithTitle(data?.bankIds),
    status: (value, context) =>
      value === undefined ? null : getFilteringValueTextByEntitiesCodeWithTitle(data?.statuses)(value, context),
    taskTypes: getFilteringValueTextByEntitiesCodeWithTitle(data?.taskTypes),
    dateCreateFrom: (value) => (value === undefined ? null : `с ${dateTimeHelper.format(value, "dayFullMonthYear")}`),
    dateCreateTo: (value) => (value === undefined ? null : `по ${dateTimeHelper.format(value, "dayFullMonthYear")}`),
  };

  return useFilteringItemsConverterDictionary(dictionary);
};
