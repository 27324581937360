import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditExecutionListItemDesktopSkeleton } from "./desktop/CreditExecutionListItem.desktop.skeleton";
import { CreditExecutionListItemMobileSkeleton } from "./mobile/CreditExecutionListItem.mobile.skeleton";

export type CreditExecutionListItemSkeletonProps = BaseCssProps;

export const CreditExecutionListItemSkeleton = ({ className }: CreditExecutionListItemSkeletonProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={<CreditExecutionListItemMobileSkeleton />}
      desktop={<CreditExecutionListItemDesktopSkeleton className={className} />}
    />
  );
};
