import { memo } from "react";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { NavigationStateFrom } from "../../../../../common/navigation/navigation-state";
import { BankGuaranteeDetailsHeaderNavigationMobile } from "./BankGuaranteeDetailsHeaderNavigation.mobile";
import { BankGuaranteeDetailsHeaderNavigationDesktop } from "./BankGuaranteeDetailsHeaderNavigation.desktop";
import { useBankGuaranteeDetailsLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { usePageTitle } from "../../../../../common/navigation/page-title";

export interface BankGuaranteeDetailsHeaderNavigationProps {
  initialFrom: NavigationStateFrom;
}

export const BankGuaranteeDetailsHeaderNavigation = memo(
  ({ initialFrom }: BankGuaranteeDetailsHeaderNavigationProps) => {
    const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();

    usePageTitle(`Заявка ${guaranteeId}`);

    return (
      <ThemeMobileDesktopView
        mobile={<BankGuaranteeDetailsHeaderNavigationMobile from={initialFrom} />}
        desktop={
          <BankGuaranteeDetailsHeaderNavigationDesktop title={initialFrom.backTitle ?? ""} url={initialFrom.url} />
        }
      />
    );
  },
);
