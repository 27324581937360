import * as React from "react";
import DigitalSignatureType from "../../../models/DigitalSignatureType";
import Button, { ButtonColor, ButtonType } from "../../../components/controls-old/button/Button";
import AgentReportModel from "../../../models/AgentReportModel";

interface IAgentReportItemSignProps {
  report: AgentReportModel;
  onSignSimplified: () => void;
  onSignQualified: () => void;
}

const AgentReportItemSign: React.FC<IAgentReportItemSignProps> = (props) => {
  return (
    <div className="agent-report-item__sign-wrapper">
      <div className="agent-report-item__header">Подписание акта сверки</div>
      <div className="agent-report-item__sign-info">
        <p className="agent-report-item__sign-info-block">Добрый день!</p>
        <p className="agent-report-item__sign-info-block">
          Акт для согласования во вложении.
          <br />
          Просьба проверить банковские реквизиты на листе &laquo;СУММАРНО&raquo;! Если реквизиты неактуальные, просьба
          сообщить верные реквизиты Вашему менеджеру.
          <br />
        </p>
        <p className="agent-report-item__sign-info-block">Вы можете:</p>
        <ul className="agent-report-item__sign-info-block list">
          <li className="list__item">
            Подписать акт здесь &mdash; в личном кабинете &laquo;Теледок&raquo;, <span className="text_bold">либо</span>
          </li>
          <li className="list__item">
            Отправить через систему электронного документооборота &laquo;Диадок&raquo;,{" "}
            <span className="text_bold">либо</span>
          </li>
          <li className="list__item">
            Прислать на бумажном носителе в 2-х экземплярах по адресу:{" "}
            <span className="text_bold">
              125124, г. Москва, 3-я улица Ямского Поля, дом 2, корпус 26, офис 118, для ООО "Виктория"
            </span>
          </li>
        </ul>
        <p className="agent-report-item__sign-info-block">
          Обращаем Ваше внимание, что при отправке актов на бумажном носителе необходимо распечатывать документы со всех
          листов. Мы не сможем выплатить КВ при наличии неполного комплекта документов.
          <br />
        </p>
      </div>
      <div className="agent-report-item__sign-additional">
        {props.report.digitalSignature === DigitalSignatureType.Qualified && (
          <>
            <p className="agent-report-item__sign-additional-block">
              Для подписи необходимо установленное расширение &laquo;КриптоПро ЭЦП Browser plug-in&raquo;
            </p>
            <Button
              onClick={() => props.onSignQualified()}
              isDisabled={false}
              type={ButtonType.Button}
              color={ButtonColor.Green}
            >
              Подписать ЭП
            </Button>
          </>
        )}
        {props.report.digitalSignature === DigitalSignatureType.Simplified && (
          <>
            <p className="agent-report-item__sign-additional-block">
              Для подписи с помощью кода из смс необходимо использовать номер телефона{" "}
              <span className="text_bold">{props.report.agentPhone}</span>
            </p>
            <Button
              onClick={() => props.onSignSimplified()}
              isDisabled={false}
              type={ButtonType.Button}
              color={ButtonColor.Green}
            >
              Подписать ЭП
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AgentReportItemSign;
