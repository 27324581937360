import { FloatingPortal } from "@floating-ui/react";
import { PropsWithChildren } from "react";
import * as S from "@sideg/ui-kit/atoms/form-controls/select/components/select-popup/SelectPopup.style";
import { SelectPopupContainerItem } from "@sideg/ui-kit/atoms/form-controls/select/components/select-popup/item/SelectPopupContainerItem";
import { SelectPopupContainerItemJustified } from "@sideg/ui-kit/atoms/form-controls/select/components/select-popup/item/SelectPopupContainerItemJustified";
import { SelectPopupContainer } from "@sideg/ui-kit/atoms/form-controls/select/components/select-popup/SelectPopupContainer";
import { SelectPopupItemTitle } from "@sideg/ui-kit/atoms/form-controls/select/components/select-popup/item/templates/SelectPopupItemTitle";
import { SelectPopupItemWithIcon } from "@sideg/ui-kit/atoms/form-controls/select/components/select-popup/item/templates/SelectPopupItemWithIcon";

export interface SelectPopupProps extends PropsWithChildren {}

const Popup = ({ children }: SelectPopupProps) => {
  return <FloatingPortal>{children}</FloatingPortal>;
};

Popup.Container = SelectPopupContainer;
Popup.StyledContainer = S.Ul;
Popup.Item = SelectPopupContainerItem;
Popup.ItemJustified = SelectPopupContainerItemJustified;
Popup.ItemWithIcon = SelectPopupItemWithIcon;
Popup.ItemTitle = SelectPopupItemTitle;

export const SelectPopup = Popup;
