import { Form, Formik, FormikTouched } from "formik";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { bankGuaranteeCalculatorValidationSchema } from "../validation/BankGuaranteeCalculatorValidationSchema";
import { BankGuaranteeCalculatorFormValues } from "../types/BankGuaranteeCalculatorFormValues";
import { Box } from "../../../common/ui-kit/containers/box";
import { BankGuaranteeCalculatorFormContent } from "./BankGuaranteeCalculatorFormContent";

export interface BankGuaranteeCalculatorFormProps extends BaseCssProps {
  handleSubmit: (values: BankGuaranteeCalculatorFormValues) => Promise<void>;
  submitError?: string;
  initialValues: BankGuaranteeCalculatorFormValues;
  isInitialTouched?: boolean;
}

export const BankGuaranteeCalculatorForm = ({
  className,
  handleSubmit,
  submitError,
  initialValues,
  isInitialTouched,
}: BankGuaranteeCalculatorFormProps) => {
  const initialTouched = isInitialTouched
    ? Object.keys(initialValues).reduce((accum, key) => {
        accum[key as keyof BankGuaranteeCalculatorFormValues] = true;

        return accum;
      }, {} as FormikTouched<BankGuaranteeCalculatorFormValues>)
    : undefined;

  return (
    <Formik<BankGuaranteeCalculatorFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={bankGuaranteeCalculatorValidationSchema}
      initialTouched={initialTouched}
    >
      <Box as={Form} display="flex" flexDirection="column" gap="lg" flexShrink={1} flexGrow={1} className={className}>
        <BankGuaranteeCalculatorFormContent submitError={submitError} />
      </Box>
    </Formik>
  );
};
