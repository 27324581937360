import { dateTimeHelper } from "@sideg/helpers";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeListFilters } from "../../store/filters/bankGuaranteeListFilters.selectors";
import { BankGuaranteeListFilterParams } from "../../types/filters/BankGuaranteeListFilterParams";
import {
  useFilteringItemsConverterDictionary,
  FilteringItemsConverterDictionary,
  getFilteringValueTextByEntitiesCodeWithTitle,
} from "../../../../common/filters";
import { bankGuaranteeRequestOriginalStatus } from "../../../common/types";

export const useBankGuaranteeListFilteringItemsConverter = () => {
  const { data } = useAppSelector(selectBankGuaranteeListFilters);

  const dictionary: FilteringItemsConverterDictionary<BankGuaranteeListFilterParams> = {
    page: null,
    demandIdOrProductId: null,
    search: null,
    tab: null,
    productId: null,
    clientNameOrTaxpayerNumber: null,
    bankIds: getFilteringValueTextByEntitiesCodeWithTitle(data?.banks),
    status: (value, context) =>
      value === undefined ? null : getFilteringValueTextByEntitiesCodeWithTitle(data?.statuses)(value, context),
    taskTypes: getFilteringValueTextByEntitiesCodeWithTitle(data?.taskTypes),
    guaranteeTypes: getFilteringValueTextByEntitiesCodeWithTitle(data?.guaranteeTypes),
    dateCreateFrom: (value) => (value === undefined ? null : `с ${dateTimeHelper.format(value, "dayFullMonthYear")}`),
    dateCreateTo: (value) => (value === undefined ? null : `по ${dateTimeHelper.format(value, "dayFullMonthYear")}`),
    requestOriginalStatuses: (value) => bankGuaranteeRequestOriginalStatus.getTitle(value),
  };

  return useFilteringItemsConverterDictionary(dictionary);
};
