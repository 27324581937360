import { ServiceWorkerEventType } from "../types/ServiceWorkerEventType";

export const serviceWorkerEventsService = {
  dispatch: (type: ServiceWorkerEventType, registration: ServiceWorkerRegistration) => {
    document.dispatchEvent(new CustomEvent(type, { detail: registration }));
  },
  on: (type: ServiceWorkerEventType, handler: (e: Event) => void | Promise<void>) => {
    document.addEventListener(type, handler);
  },
  off: (type: ServiceWorkerEventType, handler: (e: Event) => void | Promise<void>) => {
    document.removeEventListener(type, handler);
  },
  isServiceWorkerEvent: (e: Event): e is CustomEvent<ServiceWorkerRegistration> => {
    return !!(e as CustomEvent<ServiceWorkerRegistration>)?.detail;
  },
};
