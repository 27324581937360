import { useState } from "react";
import FocusTrap from "focus-trap-react";
import { QueueTasksListFilterParams } from "../../types/filter";
import { Icon, SearchIcon } from "../../../../common/ui-kit/icons";
import * as S from "./QueueTasksListItemClientTooltip.styled";
import { Tooltip } from "../../../../common/ui-kit/pop-up/tooltip";
import { usePopupButton } from "../../../../common/ui-kit/buttons/hooks/usePopupButton";
import { Box } from "../../../../common/ui-kit/containers/box";

export interface QueueTasksListItemClientTooltipProps {
  name: string;
  taxpayerNumber: string;
}

export const QueueTasksListItemClientTooltip = ({ name, taxpayerNumber }: QueueTasksListItemClientTooltipProps) => {
  const {
    state: [isOpened],
    buttonProps,
    popupProps,
    arrow,
    placement,
  } = usePopupButton({ position: "top", offset: [0, 4] });

  const [queueTasksListFilter] = useState({
    search: taxpayerNumber,
  } as QueueTasksListFilterParams);

  return (
    <>
      <S.TooltipButton {...buttonProps} type="button">
        {name}
      </S.TooltipButton>
      {isOpened && (
        <Tooltip popUpProps={popupProps} placement={placement} arrowData={arrow.data} arrowRef={arrow.ref}>
          <FocusTrap>
            <Box>
              <S.ActionButton to={(x) => x.queueTasks.list.root} search={queueTasksListFilter}>
                <Icon as={SearchIcon} stroke="typography.primaryLight" />
                Найти задачи
              </S.ActionButton>
            </Box>
          </FocusTrap>
        </Tooltip>
      )}
    </>
  );
};
