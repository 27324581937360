import { GetCreditWorkingAssetsDetailsInputDto } from "./dto/input/GetCreditWorkingAssetsDetailsInputDto";
import { clientV4 } from "../../../common/api";
import { GetCreditWorkingAssetsDetailsOutputDto } from "./dto/output/GetCreditWorkingAssetsDetailsOutputDto";
import { GetDemandCreditWorkingAssetsDetailsInputDto } from "./dto/input/GetDemandCreditWorkingAssetsDetailsInputDto";
import { GetDemandCreditWorkingAssetsDetailsOutputDto } from "./dto/output/GetDemandCreditWorkingAssetsDetailsOutputDto";
import { UploadGroupDocumentCreditWorkingAssetsDetailsInputDto } from "./dto/input/UploadGroupDocumentCreditWorkingAssetsDetailsInputDto";

const CONTROLLER_NAME = "creditWorkingAssets";

export const creditWorkingAssetsDetailsApi = {
  get: ({ creditWorkingAssetsId }: GetCreditWorkingAssetsDetailsInputDto) =>
    clientV4.get<GetCreditWorkingAssetsDetailsInputDto, GetCreditWorkingAssetsDetailsOutputDto>(
      `${CONTROLLER_NAME}/${creditWorkingAssetsId}`,
    ),
  getDemand: ({ creditWorkingAssetsId, demandId }: GetDemandCreditWorkingAssetsDetailsInputDto) =>
    clientV4.get<GetDemandCreditWorkingAssetsDetailsInputDto, GetDemandCreditWorkingAssetsDetailsOutputDto>(
      `${CONTROLLER_NAME}/${creditWorkingAssetsId}/demand/${demandId}`,
    ),
  uploadGroupDocument: ({ creditWorkingAssetsId, ...rest }: UploadGroupDocumentCreditWorkingAssetsDetailsInputDto) => {
    return clientV4.postForm<
      Omit<UploadGroupDocumentCreditWorkingAssetsDetailsInputDto, "creditWorkingAssetsId">,
      GetCreditWorkingAssetsDetailsOutputDto
    >(`${CONTROLLER_NAME}/${creditWorkingAssetsId}/group-upload-document`, rest);
  },
};
