import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  1: "Изменение параметов БГ",
  2: "Заказчик не согласовал текст",
};
export const bankGuaranteeReissueType = makeEnumLikeLiteral([1, 2] as const, titles);

export const bankGuaranteeReissueTypeString = makeEnumLikeLiteral(["1", "2"] as const, titles);

export type BankGuaranteeReissueType = EnumLikeLiteralType<typeof bankGuaranteeReissueType>;
