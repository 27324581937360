import styled from "@emotion/styled";
import { FilteringBaseOrderType } from "../../../common/filters";

export const Table = styled.table`
  width: 100%;

  & > thead {
    border-bottom: 1px solid ${({ theme }) => theme.palette.control.secondaryMedium};

    & th {
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.palette.control.secondaryLight};
      }
    }
  }

  & tr {
    font-size: ${({ theme }) => theme.font.fz3};
    line-height: ${({ theme }) => theme.font.defaultLineHeight};
    font-weight: 500;

    & > td,
    & > th {
      padding: ${({ theme }) => theme.spaces.md};
    }

    & > th {
      font-weight: 400;
      text-align: left;
      white-space: nowrap;
      user-select: none;
    }

    & > td:last-of-type {
      width: 3%;
      text-align: right;
      font-feature-settings: "tnum" on, "lnum" on;
      white-space: nowrap;
    }

    &:nth-of-type(even) {
      background-color: ${({ theme }) => theme.palette.control.secondaryLight};
    }
  }
`;

export const Icon = styled.span<{ isActive: boolean; order: FilteringBaseOrderType }>`
  padding: ${({ theme }) => theme.spaces.sm};
  background-color: ${({ theme, isActive }) => (isActive ? theme.palette.control.primaryLight : "transparent")};
  margin-left: ${({ theme }) => theme.spaces.md};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  & > svg {
    width: 18px;
    height: 18px;
    transform: ${({ isActive, order }) => (isActive && order === "desc" ? "scale(1, -1)" : "unset")};
    transition: transform 0.15s linear;
  }
`;
