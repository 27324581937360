import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ElementColor, getElementColor, ResponsiveStyleValue } from "../../../../types";
import { CircularLoaderIcon } from "../../../../icons";

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`;

export interface CircularLoaderProps {
  size?: ResponsiveStyleValue<number>;
  elementColor?: ResponsiveStyleValue<ElementColor>;
}

export const CircularLoader = styled(CircularLoaderIcon, {
  shouldForwardProp: (propName) => propName !== "elementColor",
})<CircularLoaderProps>(
  () => css`
    animation: ${rotate} 2s linear infinite;
    transform-origin: center center;
    display: block;

    & > circle {
      stroke-dasharray: 170, 200;
      stroke-dashoffset: -10;
      animation: ${dash} 1.5s ease-in-out infinite;
      stroke-linecap: round;
      stroke-width: 6px;
    }
  `,
  ({ theme, size }) =>
    makeResponsiveStyles(theme, size, (x) =>
      x
        ? {
            width: `${x}px`,
            height: `${x}px`,
          }
        : {},
    ),
  ({ theme, elementColor }) =>
    makeResponsiveStyles(theme, elementColor ?? "primary", (x) => ({
      stroke: getElementColor(x, theme),
    })),
);
