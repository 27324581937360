import AgentReportModel from "../../../models/AgentReportModel";
import { useState } from "react";
import IValue from "../../../components/controls-old/IValue";
import { AgentReportUpdateFile, AgentReportUpdateForm } from "../../../store/actions/agentReportUpdateActionsType";
import { agentReportUpdateSend } from "../../../store/actions/agentReportActions";
import AgentReportItemInfo from "./AgentReportItemInfo";
import { AgentReportItemFiles } from "./AgentReportItemFiles";
import AgentReportItemSign from "./AgentReportItemSign";
import AgentReportItemComments from "./AgentReportItemComments";
import AgentReportSignSimplifiedModalWrapper from "../../../components/modal/pages/agent-report/sign/simplified/AgentReportSignSimplifiedModalWrapper";
import { AgentReportSignQualifiedModalWrapper } from "../../../components/modal/pages/agent-report/sign/qualified/AgentReportSignQualifiedModalWrapper";
import { useAppDispatch } from "../../../domains/common/store";

interface AgentReportItemContentProps {
  data: AgentReportModel;
}

const AgentReportItemContent = ({ data }: AgentReportItemContentProps) => {
  const dispatch = useAppDispatch();
  const [comment, setComment] = useState<IValue>({ isValid: false });
  const [files, setFiles] = useState<AgentReportUpdateFile[]>([]);
  const [showSignSimplified, setSignSimplified] = useState(false);
  const [showSignQualified, setSignQualified] = useState(false);

  const handleSendRework = () => {
    const form = {
      comment: comment.value,
      approved: false,
      fileList: files,
    } as AgentReportUpdateForm;

    dispatch(agentReportUpdateSend(data.id, form));
  };

  const handleSendApproved = () => {
    const form = {
      comment: comment.value,
      approved: true,
      fileList: undefined,
    } as AgentReportUpdateForm;

    dispatch(agentReportUpdateSend(data.id, form));
  };

  const handleSignControl = (isSign: boolean) => {
    setSignSimplified(false);
    setSignQualified(false);
    if (isSign) {
      const form = {
        comment: undefined,
        approved: false,
        fileList: undefined,
      } as AgentReportUpdateForm;

      dispatch(agentReportUpdateSend(data.id, form));
    }
  };

  return (
    <>
      <div className="agent-report-item">
        <div className="agent-report-item__header">Информация по акту сверки</div>

        <AgentReportItemInfo report={data} />

        {data.status.code !== "Check" && data.files && data.files.length > 0 && (
          <AgentReportItemFiles
            files={data.files}
            isLoadFile={data.status.code === "Processing" && data.agentSide}
            onChangeFiles={(items) => setFiles(items)}
          />
        )}

        {data.status.code === "Aggreed" && data.agentSide && !data.isSigned && (
          <AgentReportItemSign
            report={data}
            onSignQualified={() => setSignQualified(true)}
            onSignSimplified={() => setSignSimplified(true)}
          />
        )}

        {(data.agentSide || (data.comments && data.comments.length > 0)) && (
          <AgentReportItemComments
            isAgentSide={data.agentSide}
            status={data.status}
            comments={data.comments}
            onCommentChange={(value) => setComment(value)}
            onRework={() => handleSendRework()}
            onApproved={() => handleSendApproved()}
          />
        )}
      </div>

      {showSignSimplified && (
        <AgentReportSignSimplifiedModalWrapper
          files={data.files}
          reportId={data.id}
          onClose={(isSign) => handleSignControl(isSign)}
        />
      )}
      {showSignQualified && (
        <AgentReportSignQualifiedModalWrapper
          files={data.files}
          reportId={data.id}
          onClose={(isSign) => handleSignControl(isSign)}
        />
      )}
    </>
  );
};

export default AgentReportItemContent;
