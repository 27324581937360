import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Theme } from "@sideg/ui-kit";
import { StyledNavigationButtonWithCounter } from "../../../../common/ui/navigation/components/buttons/StyledNavigationButtonWithCounter";
import { colorWithAlpha } from "../../../../common/ui-kit/utils";

const getPulseAnimation = ({ theme }: { theme: Theme }) => {
  return keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${colorWithAlpha(theme.secondaryColors.notificationBackground, 100)};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px ${colorWithAlpha(theme.secondaryColors.notificationBackground, 0)};
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${colorWithAlpha(theme.secondaryColors.notificationBackground, 0)};
    }
  `;
};

export const Button = styled(StyledNavigationButtonWithCounter)<{ animate: boolean }>`
  position: relative;

  & > svg {
    border-radius: 50%;
    animation: ${getPulseAnimation} linear 1.2s infinite;
    animation-iteration-count: ${({ animate }) => (animate ? "infinite" : 0)};
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border: 2px solid ${({ theme }) => theme.layout.header.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -6px;
  right: -10px;
  background-color: ${({ theme }) => theme.layout.header.button.backgroundColor};
  border-radius: 50%;

  & > svg {
    width: 12px;
    height: 12px;
  }
`;
