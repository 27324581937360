import { ComponentProps } from "react";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { Box } from "../../../../../ui-kit/containers/box";
import { Typography } from "../../../../../ui-kit/typography";
import { BellIcon, Icon } from "../../../../../ui-kit/icons";

const PARAGRAPH_PROPS: Partial<ComponentProps<typeof Typography>> = {
  fontWeight: 500,
  lineHeight: "defaultLineHeight",
  fontSize: "fz3",
  fontColor: "typography.primary",
  as: "p",
};

export type WebPushesPromptModalContentDesktopProps = BaseCssProps;

export const WebPushesPromptModalContentDesktop = ({ className }: WebPushesPromptModalContentDesktopProps) => {
  return (
    <Box
      className={className}
      display="flex"
      flexDirection="row"
      gap="sm"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" gap="lg">
        <Typography {...PARAGRAPH_PROPS}>Разрешите уведомления в вашем браузере для lk.teledoc.ru.</Typography>
        <Typography {...PARAGRAPH_PROPS}>
          Уведомления будут появляться в браузере, независимо на какой странице вы сейчас находитесь.
        </Typography>
        <Typography {...PARAGRAPH_PROPS}>
          Это полезно, так как вы всегда будете знать об изменениях в задачах и новых ответах и сможете быстро к ним
          перейти.
        </Typography>
        <Typography {...PARAGRAPH_PROPS}>
          Вы можете включить или выключить браузерные уведомления в разделе всех уведомлений{" "}
          <Icon as={BellIcon} width={16} height={16} stroke="primary" />
        </Typography>
      </Box>
      <img
        alt="Подписка на уведомления"
        width={280}
        height={180}
        src="/assets/images/web-pushes/notification-desktop.svg"
      />
    </Box>
  );
};
