import { createRawJwtClient } from "../../domains/common/api/fetchClient/createApiClient";

/**
 * @deprecated Нужно использовать клиенты из домена
 * @see clientV1
 * @see clientV2
 * @see clientV3
 * @see clientV4
 */
export const axiosJWT = createRawJwtClient();
