import { combineReducers, createSlice, isAnyOf, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { CreditExecutionCreationState } from "./creditExecutionCreation.types";
import {
  CREDIT_EXECUTION_CREATION_SLICE_NAME,
  createDraftCreditExecutionCreation,
  scoringCreditExecutionCreation,
  scoringByExistsCreditExecutionCreation,
} from "./creditExecutionCreation.thunks";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { combineSliceNames } from "../../../common/store/utils";
import { getClientSearchReducer } from "../../../client-search";
import { ProductCreationStages } from "../../../common/product-creation";
import { getPurchaseReducer } from "../../../common/purchase";

const initialState: CreditExecutionCreationState = {
  activeStage: "creation",
  creating: { status: LoadingStatusEnum.Idle, data: undefined },
  scoring: { status: LoadingStatusEnum.Idle, data: undefined },
  isFormSameAsInitial: false,
};

const slice = createSlice({
  name: CREDIT_EXECUTION_CREATION_SLICE_NAME,
  initialState,
  reducers: {
    new: () => initialState,
    changeStage: (state, action: PayloadAction<ProductCreationStages>) => {
      state.activeStage = action.payload;
    },
    setNextStage: (state) => {
      state.activeStage = state.activeStage === "creation" ? "scoring" : "creation";
    },
    setIsFormSameAsInitial: (state, action: PayloadAction<boolean>) => {
      state.isFormSameAsInitial = action.payload;

      if (!action.payload) {
        state.scoring = initialState.scoring;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDraftCreditExecutionCreation.pending, (state) => {
        state.creating.error = undefined;
      })
      .addCase(createDraftCreditExecutionCreation.fulfilled, (state, action) => {
        state.creating.data = action.meta.arg;
      })
      .addCase(createDraftCreditExecutionCreation.rejected, (state, action) => {
        state.creating.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(createDraftCreditExecutionCreation), (state, action) => {
        state.creating.status = getLoadingStatusFromAction(action);
      })
      .addMatcher(
        isAnyOf(scoringCreditExecutionCreation.fulfilled, scoringByExistsCreditExecutionCreation.fulfilled),
        (state, action) => {
          state.scoring.data = action.payload.body.groups;
        }
      )
      .addMatcher(
        isAsyncThunkAction(scoringCreditExecutionCreation, scoringByExistsCreditExecutionCreation),
        (state, action) => {
          state.scoring.status = getLoadingStatusFromAction(action);
        }
      );
  },
});

export const creditExecutionCreationReducer = combineReducers({
  form: slice.reducer,
  clientSearch: getClientSearchReducer({
    key: "CreditExecution",
    caseExtraReducers: (builder, clientSearchInitialState) => {
      builder.addCase(slice.actions.new, () => clientSearchInitialState);
    },
    sliceName: combineSliceNames(CREDIT_EXECUTION_CREATION_SLICE_NAME, "clientSearch"),
  }),
  purchase: getPurchaseReducer({
    key: "CreditExecution",
    sliceName: combineSliceNames(CREDIT_EXECUTION_CREATION_SLICE_NAME, "purchase"),
  }),
});

export const {
  new: newCreditExecutionCreation,
  changeStage: changeStageCreditExecutionCreation,
  setNextStage: setNextStageCreditExecutionCreation,
  setIsFormSameAsInitial: setIsFormSameAsInitialCreditExecutionCreation,
} = slice.actions;
