import DemandCounterModel from "../../models/DemandCounterModel";

export enum AgentReportListStatus {
    Initial = 'INITIAL',
    Processing = 'PROCESSING',
    Failed = 'FAILED',
    Success = 'SUCCESS',
}

export type AgentReportListCounterState = {
    status: AgentReportListStatus
    data?: DemandCounterModel
    errorMessage?: string
}

export const AgentReportListCounterDefaultState = (): AgentReportListCounterState => {
    return {
        status: AgentReportListStatus.Initial
    }
}