import { useState } from "react";
import { useBankGuaranteeListFilterArray } from "../../../../hooks/filters/useBankGuaranteeListFilterArray";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { FilterMultipleCombobox } from "../../../../../../common/ui-kit/form-controls/select";
import { useBankGuaranteeListFilterContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterContext";
import {
  selectBankGuaranteeListFiltersBanksCodes,
  selectBankGuaranteeListFiltersMaxLength,
} from "../../../../store/filters/bankGuaranteeListFilters.selectors";
import { useAppSelector } from "../../../../../../common/store";

export type BankGuaranteeListFiltersBanksProps = BaseCssProps;

export const BankGuaranteeListFiltersBanks = ({ className }: BankGuaranteeListFiltersBanksProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const [inputValue, setInputValue] = useState("");

  const { getItemTitle, selectedItems, getItems, onSelectedChange } = useBankGuaranteeListFilterArray({
    filteringObject,
    key: "bankIds",
    itemsSelector: (state) => selectBankGuaranteeListFiltersBanksCodes(state, inputValue),
    getTitleFunc: (filters) => (item) => filters?.banks.titles[item] ?? item,
  });

  const maxItemsLength = useAppSelector((state) => selectBankGuaranteeListFiltersMaxLength(state, "banks"));

  return (
    <FilterMultipleCombobox
      text="Банки"
      selectedItems={selectedItems}
      getItemTitle={getItemTitle}
      onSelectedChanged={onSelectedChange}
      onSearchChanged={setInputValue}
      searchPlaceholder="Поиска банка"
      getItems={getItems}
      className={className}
      maxItemsLength={maxItemsLength}
    />
  );
};
