import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { FormCheckbox } from "@sideg/ui-kit/atoms/checkbox";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { Typography } from "../../../../../common/ui-kit/typography";
import { useTypedField } from "../../../../../common/form";
import { StyledAppLink } from "../../../../../common/ui/links";
import { usePublicUserRegistrationPrivacyPolicyClickHandler } from "../../../hooks/usePublicUserRegistrationPrivacyPolicyClickHandler";

export interface PublicUserRegistrationFormAgreementFieldProps extends BaseCssProps {
  name: string;
  isDisabled: boolean;
}

export const PublicUserRegistrationFormAgreementField = ({
  name,
  className,
  isDisabled,
}: PublicUserRegistrationFormAgreementFieldProps) => {
  const [fieldProps] = useTypedField<boolean>(name);
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  const handleClick = usePublicUserRegistrationPrivacyPolicyClickHandler();

  return (
    <FormCheckbox
      className={className}
      isDisabled={isDisabled}
      label={
        <>
          <Typography fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}>
            Я даю согласие на обработку&nbsp;
          </Typography>
          <StyledAppLink to={(x) => x.publicUser.privacyPolicies.root} onClick={handleClick}>
            <Typography fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}>персональных данных</Typography>
          </StyledAppLink>
        </>
      }
      {...fieldProps}
    />
  );
};
