import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
`;

export const Button = styled.button`
  appearance: none;
  background-color: ${({ theme }) => theme.palette.control.secondaryLight};
  color: ${({ theme }) => theme.palette.typography.medium};
  padding: ${({ theme }) => theme.spaces.xs};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border: 2px solid ${({ theme }) => theme.palette.control.secondaryLight};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.control.secondaryMedium};
    border-color: ${({ theme }) => theme.palette.control.secondaryMedium};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  & > svg {
    height: 16px;

    & > path {
      stroke: ${({ theme }) => theme.palette.typography.medium};
    }
  }
`;
