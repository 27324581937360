import * as React from "react";
import { BaseCssProps } from "../../../../../../../ui-kit/types";
import * as S from "./ProductListCardContainer.desktop.styled";

export interface ProductListCardContainerDesktopProps extends BaseCssProps {
  children?: React.ReactNode;
  details?: React.ReactNode;
}

export const ProductListCardContainerDesktop = React.forwardRef<HTMLDivElement, ProductListCardContainerDesktopProps>(
  ({ children, details, className }, ref) => {
    return (
      <S.Article ref={ref} className={className}>
        <S.Header>{details}</S.Header>
        <S.Separator />
        <S.Main>{children}</S.Main>
      </S.Article>
    );
  }
);
