import { PaginatedFilteringObjectType } from "../types/PaginatedFilteringObjectType";
import { FilteringObjectValue } from "../types/FilteringObjectValue";
import { FilteringObjectType } from "../types/FilteringObjectType";
import { useFilteringItemsConverter } from "./useFilteringItemsConverter";

export type FilteringItemsConverterDictionary<TFilter extends FilteringObjectType> = {
  [P in keyof TFilter]: null | ((value: FilteringObjectValue<TFilter, P>, context: TFilter) => string | null);
};

export const useFilteringItemsConverterDictionary = <TFilter extends PaginatedFilteringObjectType>(
  dictionary: FilteringItemsConverterDictionary<TFilter>
) => {
  const getTitle = <TKey extends keyof TFilter>(
    key: TKey,
    value: FilteringObjectValue<TFilter, TKey>,
    context: TFilter
  ) => {
    return dictionary[key]?.(value, context) ?? null;
  };

  return useFilteringItemsConverter<TFilter>(getTitle);
};
