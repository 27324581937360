import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Theme } from "@sideg/ui-kit";
import { usePopupButton } from "../../../../buttons/hooks/usePopupButton";
import { Color } from "../../../../theme";

export interface TooltipArrowBaseProps extends Pick<ReturnType<typeof usePopupButton>, "placement"> {
  arrowData: ReturnType<typeof usePopupButton>["arrow"]["data"];
}

export interface TooltipArrowProps extends TooltipArrowBaseProps {
  backgroundColor: Color | ((theme: Theme) => Color);
  borderColor: Color | ((theme: Theme) => Color);
}

export const TooltipArrow = styled.div<TooltipArrowProps>(
  ({ theme, borderColor, backgroundColor, arrowData }) => {
    return css`
      position: absolute;
      width: 10px;
      height: 10px;
      left: ${arrowData?.x === undefined ? undefined : `${arrowData.x}px`};
      top: ${arrowData?.y === undefined ? undefined : `${arrowData.y}px`};

      &:after {
        content: " ";
        background-color: ${typeof backgroundColor === "function" ? backgroundColor(theme) : backgroundColor};
        box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
        position: absolute;
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
        border: 1px solid ${typeof borderColor === "function" ? borderColor(theme) : borderColor};
      }
    `;
  },
  ({ placement }) => {
    if (placement.startsWith("top")) {
      return css`
        bottom: -5px;

        :after {
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
          border-top: none;
          border-left: none;
          left: 0;
        }
      `;
    }
    if (placement.startsWith("bottom")) {
      return css`
        top: -6px;

        :after {
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
          border-bottom: none;
          border-right: none;
          left: 0;
        }
      `;
    }

    if (placement.startsWith("left")) {
      return css`
        right: -5px;

        :after {
          box-shadow: 1px 0 0 rgb(0 0 0 / 10%);
          border-left: none;
          border-bottom: none;
        }
      `;
    }

    if (placement.startsWith("right")) {
      return css`
        left: -6px;

        :after {
          box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
          border-right: none;
          border-top: none;
        }
      `;
    }

    return {};
  },
);
