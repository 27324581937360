import styled from "@emotion/styled";
import { checkNeverType } from "@sideg/helpers";
import { ProductDetailsHeaderDemandCreditChipType } from "../../types/ProductDetailsHeaderDemandChip";
import { ProductDetailsContentLayoutContainer } from "../layout/ProductDetailsContentLayoutContainer";

export const ContentHeaderContainer = styled(ProductDetailsContentLayoutContainer)<{
  withPaddingBottom?: boolean;
  ignorePaddingX?: boolean;
}>`
  padding-top: ${({ theme }) => theme.spaces.xxl};
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.light};
  gap: ${({ theme }) => theme.spaces.md};
  padding-bottom: ${({ theme, withPaddingBottom }) => (withPaddingBottom ? theme.spaces.xxl : 0)};
  padding-right: ${({ ignorePaddingX }) => (ignorePaddingX ? "unset" : undefined)};
  padding-left: ${({ ignorePaddingX }) => (ignorePaddingX ? "unset" : undefined)};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding-top: ${({ theme }) => theme.layout.borderPadding};
    padding-bottom: ${({ theme, withPaddingBottom }) => (withPaddingBottom ? theme.layout.borderPadding : 0)};
    padding-right: ${({ ignorePaddingX }) => (ignorePaddingX ? "unset" : undefined)};
    padding-left: ${({ ignorePaddingX }) => (ignorePaddingX ? "unset" : undefined)};
  }
`;

export const HeaderChip = styled.div<{
  variant: ProductDetailsHeaderDemandCreditChipType;
}>`
  font-weight: 500;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-size: ${({ theme }) => theme.font.fz2};
  padding: 2px 6px;
  border-radius: 4px;
  color: ${({ theme, variant }) => {
    if (variant === "danger") {
      return theme.palette.typography.error;
    }

    if (variant === "success") {
      return theme.palette.typography.success;
    }

    return theme.colors.dark;
  }};
  background-color: ${({ theme, variant }) => {
    if (variant === "danger") {
      return theme.palette.status.errorBackground;
    }

    if (variant === "success") {
      return theme.palette.status.successBackground;
    }

    if (variant === "info") {
      return theme.palette.control.secondaryLight;
    }

    return checkNeverType(variant);
  }};
`;

export const DetailsItemsDl = styled.dl<{ maxTermLength: number }>`
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: ${({ theme }) => theme.spaces.sm};

  & > * {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & > dt {
    width: ${({ maxTermLength }) => maxTermLength}ch;
  }
`;
