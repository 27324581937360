import {
  FileDispatchTypes,
  MANAGER_SIGN_FETCHED,
  MANAGER_SIGN_FETCHING,
  MANAGER_SIGN_FETCHING_ERROR,
  USER_SIGN_FETCHED,
  USER_SIGN_FETCHING,
  USER_SIGN_FETCHING_ERROR,
} from "./fileActionTypes";

export const userSignFetching = (): FileDispatchTypes => {
  return {
    type: USER_SIGN_FETCHING,
  };
};

export const userSignFetched = (): FileDispatchTypes => {
  return {
    type: USER_SIGN_FETCHED,
  };
};

export const userSignFetchingError = (): FileDispatchTypes => {
  return {
    type: USER_SIGN_FETCHING_ERROR,
  };
};

export const managerSignFetching = (): FileDispatchTypes => {
  return {
    type: MANAGER_SIGN_FETCHING,
  };
};

export const managerSignFetched = (): FileDispatchTypes => {
  return {
    type: MANAGER_SIGN_FETCHED,
  };
};

export const managerSignFetchingError = (): FileDispatchTypes => {
  return {
    type: MANAGER_SIGN_FETCHING_ERROR,
  };
};
