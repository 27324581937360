import { FilteredKeys } from "../../types/utils";

const externalUrls = {
  home: {
    homePage: "https://teledoc.ru/",
  },
  social: {
    telegram: "https://t.me/teledoc_today",
  },
  manufacturer: {
    home: "https://sideg.ru/",
  },
};

type ApplicationUrls = { [key: string]: string | ApplicationUrls };

type ExternalUrlType<T> = {
  [P in FilteredKeys<T, ApplicationUrls | string>]: T[P] extends string ? T[P] : ExternalUrlType<T[P]>;
};

export type AppExternalUrls = ExternalUrlType<typeof externalUrls>;

export const appExternalUrls: AppExternalUrls = externalUrls;
