import styled from "@emotion/styled";
import { ProductDetailsContentLayoutContainer } from "../../../common/product-details";

export const Container = styled(ProductDetailsContentLayoutContainer)`
  background-color: ${({ theme }) => theme.palette.background.light};
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
