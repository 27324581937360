import styled from "@emotion/styled";

export const PopUp = styled.div`
  border-radius: 4px;
  background: ${({ theme }) => theme.layout.header.backgroundColor};
  border: 1px solid ${({ theme }) => theme.layout.header.backgroundColor};
  max-width: 160px;
  padding: ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.sm};
  position: relative;
  white-space: pre-line;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz1};
  line-height: 1.4;
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  z-index: ${({ theme }) => theme.layout.zIndex};
`;
