import styled from "@emotion/styled";

export const Label = styled.label`
  position: relative;

  & > input[type="radio"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    flex: 0 0;

    & + div {
      padding: 0;
      border: 2px solid ${({ theme }) => theme.palette.control.secondaryLight};
      border-radius: 50%;
      height: 38px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      background-color: ${({ theme }) => theme.palette.background.light};
      cursor: pointer;

      & > svg > path {
        stroke: transparent;
      }
    }

    &:focus-visible + div {
      border-color: ${({ theme }) => theme.colors.primary};
    }

    &:disabled + div {
      cursor: default;
      background-color: ${({ theme }) => theme.palette.control.secondaryLight};
    }

    &:disabled:checked + div {
      background-color: ${({ theme }) => theme.palette.control.secondaryLight};

      & > svg > path {
        stroke: ${({ theme }) => theme.palette.control.secondaryMedium};
      }
    }

    &:hover:not(:disabled) + div {
      background-color: ${({ theme }) => theme.palette.control.primaryLight};
    }

    &:checked:not(:disabled) + div {
      & > svg > path {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;
