import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { FullPageContainerHeaderDesktop, FullPageContainerHeaderDesktopProps } from "./FullPageContainerHeader.desktop";
import { Box } from "../../../../../ui-kit/containers/box";

export type PublicPageContainerHeaderProps = FullPageContainerHeaderDesktopProps;

export const FullPageContainerHeader = ({ className, leftSide, children }: PublicPageContainerHeaderProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Box padding={{ xs: "md", [desktopBreakpoint]: "xl" }} className={className}>
      <FullPageContainerHeaderDesktop leftSide={leftSide}>{children}</FullPageContainerHeaderDesktop>
    </Box>
  );
};
