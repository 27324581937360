import { BankGuaranteeDetailsDemandReconciliationStateType } from "../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationStateType";
import { BadgeVariant } from "../../../../common/ui-kit/containers/badge/types/BadgeVariant";

export const getBankGuaranteeDetailsDemandBadgeVariantByReconciliationState = (
  state: BankGuaranteeDetailsDemandReconciliationStateType,
): BadgeVariant => {
  switch (state) {
    case "Agreement":
      return "primary";
    case "Approved":
      return "success";
    case "Bank":
      return "info";
    default:
      return "primary";
  }
};
