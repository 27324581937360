import { useContext } from "react";
import {
  CreditExecutionDetailsContext,
  CreditExecutionDetailsContextType,
} from "../../store/context/CreditExecutionDetailsContext";

export const useCreditExecutionDetailsContext = (): CreditExecutionDetailsContextType => {
  const value = useContext(CreditExecutionDetailsContext);

  if (value === undefined) {
    throw new Error("Попытка получить значение контекста вне контекста");
  }

  return value;
};
