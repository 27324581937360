import { dateTimeHelper } from "@sideg/helpers";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { Box } from "../../../common/ui-kit/containers/box";
import * as S from "./FileUpload.styled";

export interface FileUploadProps {
  fileName: string;
  date?: Date;
  canRemove?: boolean;
  onRemove?: () => void | Promise<void>;
  className?: string;
}

export const FileUpload = ({ fileName, canRemove, onRemove, date, className }: FileUploadProps) => {
  const isDesktop = useThemeIsDesktop();

  return (
    <Box display="flex" gap="sm" alignItems="center" justifyContent="space-between" className={className}>
      <Box flexGrow={1} flexShrink={1} overflow="hidden">
        <S.Label>{fileName}</S.Label>
      </Box>
      {date && isDesktop && (
        <S.Time dateTime={date.toString()}>Прикреплен {dateTimeHelper.formatRelative(date, new Date())}</S.Time>
      )}
      {onRemove && canRemove && (
        <S.RemoveButton type="button" onClick={onRemove}>
          Удалить
        </S.RemoveButton>
      )}
    </Box>
  );
};
