import { Global } from "@emotion/react";

export interface ModalGlobalStylesProps {
  isModalOpened: boolean;
}

export const ModalGlobalStyles = ({ isModalOpened }: ModalGlobalStylesProps) => {
  return (
    <Global
      styles={() => ({
        html: {
          overflow: isModalOpened ? "hidden" : undefined,
        },
      })}
    />
  );
};
