import { RootState, useAppSelector } from "../../../../common/store";
import { isLoading, LoadingStatusEnum } from "../../../../common/types/state";
import { selectBankGuaranteeDetailsActiveDemand } from "../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";

export const useBankGuaranteeDetailsReconciliationState = <TModel>(
  selector: (state: RootState) => TModel | undefined,
): { status: LoadingStatusEnum; model: TModel | undefined; needShowSkeleton: boolean } => {
  const {
    fetch: { status },
  } = useAppSelector(selectBankGuaranteeDetailsActiveDemand);
  const model = useAppSelector(selector);

  return { status, model, needShowSkeleton: model === undefined && isLoading(status) };
};
