import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.xs};
  line-height: 0;

  & > svg {
    width: 18px;
    height: 18px;

    & > path {
      stroke: ${({ theme }) => theme.palette.typography.primaryLight};
    }
  }
`;

export const BreadcrumbLink = styled(Link)`
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  text-decoration: none;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz4};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
`;
