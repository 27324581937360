import styled from "@emotion/styled";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";

export const ProductListCardDemandListItemAppearanceTransition = styled(CSSTransition)<CSSTransitionProps>`
  &.enter {
    max-height: 0;
    overflow: hidden;
  }

  &.enter-active {
    max-height: 100px;
    overflow: unset;
    transition: max-height ${({ timeout }) => timeout}ms linear;
  }

  &.exit {
    max-height: 100px;
    overflow: unset;
  }

  &.exit-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height ${({ timeout }) => timeout}ms linear;
  }
`;
