import { InferType, ValidationError } from "yup";
import { WidgetsCalculatorFormValues } from "../types/WidgetsCalculatorFormValues";
import { amountsSchemaWithLocker, periodsSchemaWithLocker } from "../validation/widgetsCalculatorValidationSchema";

export const isWidgetsCalculatorFormPartValid = <
  TSchema extends typeof periodsSchemaWithLocker | typeof amountsSchemaWithLocker
>(
  values: WidgetsCalculatorFormValues,
  schema: TSchema,
  lockerKey: keyof WidgetsCalculatorFormValues
) => {
  let countInvalidValues = 0;
  let invalidKey: keyof InferType<TSchema> | undefined;

  try {
    schema.validateSync(values, { abortEarly: false });
  } catch (e: unknown) {
    const errors = (e as ValidationError)?.inner;
    errors?.forEach((x) => {
      countInvalidValues += 1;
      invalidKey = x.path as keyof InferType<TSchema>;
    });
  }

  return (
    (countInvalidValues === 0 && invalidKey === undefined) ||
    (countInvalidValues === 1 && invalidKey === values[lockerKey])
  );
};
