import { useCallback } from "react";
import { Box } from "../../../../common/ui-kit/containers/box";
import { BankGuaranteeDetailsDemandHeader } from "../../components/demand/header/BankGuaranteeDetailsDemandHeader";
import { BankGuaranteeDetailsDemandBody } from "../../components/demand/body/BankGuaranteeDetailsDemandBody";
import { BankGuaranteeDetailsDemandActualDocumentsList } from "../../components/demand/actual-documents/BankGuaranteeDetailsDemandActualDocumentsList";
import { BankGuaranteeDetailsDemand } from "../../components/demand/BankGuaranteeDetailsDemand";
import { useBankGuaranteeDetailsDemandFilteringTabs } from "../../hooks/demand/useBankGuaranteeDetailsDemandFilteringTabs";
import { BankGuaranteeDetailsDemandReissues } from "../../components/demand/reissues/BankGuaranteeDetailsDemandReissues";
import { useBankGuaranteeDetailsFilterApiContext } from "../../hooks/filters/context/useBankGuaranteeDetailsFilterApiContext";
import { useBankGuaranteeDetailsFilterContext } from "../../hooks/filters/context/useBankGuaranteeDetailsFilterContext";
import { BankGuaranteeDetailsDemandHeaderTab } from "../../types/demand/BankGuaranteeDetailsDemandHeaderTab";

export const BankGuaranteeDetailsDemandPage = () => {
  const filteringTabs = useBankGuaranteeDetailsDemandFilteringTabs();

  const filterMutator = useBankGuaranteeDetailsFilterApiContext();
  const filteringObject = useBankGuaranteeDetailsFilterContext();

  const setActiveTab = useCallback(
    (tab: BankGuaranteeDetailsDemandHeaderTab) => {
      filterMutator(filteringObject, (service) => service.changeTab(tab));
    },
    [filterMutator, filteringObject],
  );

  const activeTab = filteringObject.tab;

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} flexShrink={1} overflow="hidden">
      <BankGuaranteeDetailsDemandHeader tabs={filteringTabs} activeTab={activeTab} onTabClick={setActiveTab} />
      <BankGuaranteeDetailsDemandBody>
        {activeTab === "tasks" && <BankGuaranteeDetailsDemand />}
        {activeTab === "documents" && <BankGuaranteeDetailsDemandActualDocumentsList />}
        {activeTab === "reissues" && <BankGuaranteeDetailsDemandReissues />}
      </BankGuaranteeDetailsDemandBody>
    </Box>
  );
};
