import * as React from "react";
import { useMemo } from "react";
import { AppRouteUserRoleElement } from "../types/AppRoute";
import { useAppSelector } from "../../store";
import { selectAuthorizedUser } from "../../authorized-user";

export interface AppRoutePermissionElementProps {
  permissionElement: AppRouteUserRoleElement;
  baseElement: React.ReactNode;
}

export const AppRoutePermissionElement = ({ baseElement, permissionElement }: AppRoutePermissionElementProps) => {
  const { user } = useAppSelector(selectAuthorizedUser);

  const element = useMemo(() => {
    if (user?.role) {
      return permissionElement[user.role] ?? baseElement;
    }

    return baseElement;
  }, [baseElement, permissionElement, user?.role]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{element}</>;
};
