import * as React from "react";
import * as S from "./ProductListCardDemandsListContainer.desktop.styled";
import { BaseCssProps } from "../../../../../../../../ui-kit/types";
import { StackPanel } from "../../../../../../../../ui-kit/containers/stack-panel";
import { Typography } from "../../../../../../../../ui-kit/typography";

export interface ProductListCardDemandsListContainerDesktopProps extends BaseCssProps {
  banksCountTitle: React.ReactNode;
  counters: React.ReactNode;
  activeTasksCountBadge: React.ReactNode;
  actionsButton: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

export const ProductListCardDemandsListContainerDesktop = ({
  banksCountTitle,
  counters,
  activeTasksCountBadge,
  actionsButton,
  children,
  footer,
  className,
}: ProductListCardDemandsListContainerDesktopProps) => {
  return (
    <S.Wrapper className={className}>
      <S.Header>
        <StackPanel gap="xxl" direction="row" alignItems="center">
          <Typography fontSize="fz4" lineHeight="defaultLineHeight" fontColor="typography.primary" fontWeight={700}>
            {banksCountTitle}
          </Typography>
          {counters}
        </StackPanel>
        <StackPanel gap="md" direction="row" alignItems="center">
          {activeTasksCountBadge}
          {actionsButton}
        </StackPanel>
      </S.Header>
      <S.Main>{children}</S.Main>
      {footer}
    </S.Wrapper>
  );
};
