import { useCallback } from "react";
import { CreditExecutionId } from "../../../common/types/demand";
import { useAppDispatch } from "../../../common/store";
import { transferCreditExecutionItem } from "../store/creditExecutionList.thunks";

export const useCreditExecutionListItemTransferAction = (creditExecutionId: CreditExecutionId) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(transferCreditExecutionItem(creditExecutionId));
  }, [creditExecutionId, dispatch]);
};
