import { InferType } from "yup";
import { useEffect } from "react";
import { Redirect } from "../../../common/urls/internal/components";
import { withValidUrlParams } from "../../../common/urls/internal/HOCs";
import { agencyDemandUrlValidationSchema } from "../validation/agencyDemandUrlValidationSchema";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectAgencyDemand } from "../store/agencyDemand.selectors";
import { isIdle } from "../../../common/types/state";
import { getAgencyDemand } from "../store/agencyDemand.thunks";
import { FullPageLayoutContainer } from "../../../common/ui/layout";
import { usePageTitle } from "../../../common/navigation/page-title";
import { AgencyDemandForm } from "../components/form/AgencyDemandForm";
import { AgencyDemandHeader } from "../components/AgencyDemandHeader";
import { useNavigationStateFromWithFallback } from "../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../common/urls/internal/utils";
import { resetAgencyDemand } from "../store/agencyDemand.slice";

interface PageProps {
  match: InferType<typeof agencyDemandUrlValidationSchema>;
}

const Page = ({ match }: PageProps) => {
  const dispatch = useAppDispatch();
  const { status, data } = useAppSelector(selectAgencyDemand);
  const { from } = useNavigationStateFromWithFallback(selectAppUrl((x) => x.home).url, "Назад");

  usePageTitle(data?.taskTitle ?? "");

  useEffect(() => {
    if (isIdle(status)) {
      dispatch(getAgencyDemand({ demandId: match.demandId }));
    }
  }, [dispatch, match.demandId, status]);

  useEffect(() => {
    return () => {
      dispatch(resetAgencyDemand());
    };
  }, [dispatch]);

  return (
    <FullPageLayoutContainer>
      <AgencyDemandHeader from={from} />
      <AgencyDemandForm from={from} />
    </FullPageLayoutContainer>
  );
};

export const AgencyDemandPage = withValidUrlParams(
  Page,
  (x) => x.agency.demand.root,
  agencyDemandUrlValidationSchema,
  <Redirect to={(x) => x.home} />
);
