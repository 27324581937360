import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { LegalServicesCreationFormContainer } from "./container/LegalServicesCreationFormContainer";

export interface LegalServicesCreationFormSkeletonProps extends BaseCssProps {}

export const LegalServicesCreationFormSkeleton = ({ className }: LegalServicesCreationFormSkeletonProps) => {
  return (
    <LegalServicesCreationFormContainer className={className} brief={<Skeleton height={400} />}>
      <Skeleton height={200} />
    </LegalServicesCreationFormContainer>
  );
};
