import styled from "@emotion/styled";

export const Container = styled.section`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.contrastLight};
  overflow: auto;
`;

export const Header = styled.header`
  flex: 0 0 ${({ theme }) => theme.layout.header.height};
  background-color: ${({ theme }) => theme.layout.header.backgroundColor};
  padding: ${({ theme }) => theme.spaces.md};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
