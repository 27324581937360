import { DateFormats, dateTimeHelper } from "@sideg/helpers/date";

export const handleDateFormInputPaste = (pastedValue: string): [value: string, format: DateFormats] | undefined => {
  const pastedText = dateTimeHelper.replaceExtraCharsFromDateString(pastedValue);
  if (pastedText.length >= 6) {
    const format = dateTimeHelper.getFirstDateFormatMatcher(pastedText, [
      "yearMonthDayDashed",
      "dayMonthShortYearDot",
      "dayFullMonthYear",
      "dayShortMonthYear",
    ]);

    return format === undefined ? undefined : [pastedText, format];
  }

  return undefined;
};
