import RequestStatus from "../../entity/models/enums/RequestStatus";
import QuestionnaireFiLiModel from "../../entity/models/QuestionnaireFiLiModel";
import RequestData from "../utils/RequestData";
import produce from "immer";
import {
  FI_LI_LIST_FETCHING,
  FI_LI_LIST_FETCHED,
  FI_LI_LIST_ERROR,
  FI_LI_LIST_RESET,
  FI_LI_ITEM_FETCHING,
  FI_LI_ITEM_FETCHED,
  FI_LI_ITEM_ERROR,
  FI_LI_ITEM_RESET,
  FI_LI_DELETE_PROCESSING,
  FI_LI_DELETE_SUCCESS,
  FI_LI_DELETE_ERROR,
  FI_LI_DELETE_RESET,
  FI_LI_ADD_PROCESSING,
  FI_LI_ADD_SUCCESS,
  FI_LI_ADD_ERROR,
  FI_LI_ADD_RESET,
  FI_LI_UPDATE_PROCESSING,
  FI_LI_UPDATE_SUCCESS,
  FI_LI_UPDATE_ERROR,
  FI_LI_UPDATE_RESET,
  FiLiDispatchType,
} from "../actions/fi-li/fiLiActionTypes";
import QuestionnairePersonFounderModel from "../../entity/models/QuestionnairePersonFounderModel";

interface FiLiState {
  list: RequestData<QuestionnaireFiLiModel[]>;
  item: RequestData<QuestionnairePersonFounderModel>;
  delete: RequestData<boolean>;
  add: RequestData<boolean>;
  update: RequestData<boolean>;
}

const initialState: FiLiState = {
  list: {
    status: RequestStatus.Initial,
    data: null,
    error: null,
  },
  item: {
    status: RequestStatus.Initial,
    data: null,
    error: null,
  },
  delete: {
    status: RequestStatus.Initial,
    data: null,
    error: null,
  },
  add: {
    status: RequestStatus.Initial,
    data: null,
    error: null,
  },
  update: {
    status: RequestStatus.Initial,
    data: null,
    error: null,
  },
};

const fiLiReducer = (state = initialState, action: FiLiDispatchType) => {
  switch (action.type) {
    case FI_LI_LIST_FETCHING:
      return produce(state, (draft: FiLiState) => {
        draft.list.status = RequestStatus.Request;
        draft.list.error = null;
      });
    case FI_LI_LIST_FETCHED:
      return produce(state, (draft: FiLiState) => {
        draft.list.status = RequestStatus.Success;
        draft.list.data = action.payload;
      });
    case FI_LI_LIST_ERROR:
      return produce(state, (draft: FiLiState) => {
        draft.list.status = RequestStatus.Failure;
        draft.list.error = action.error;
      });
    case FI_LI_LIST_RESET:
      return produce(state, (draft: FiLiState) => {
        draft.list = initialState.list;
      });
    case FI_LI_ITEM_FETCHING:
      return produce(state, (draft: FiLiState) => {
        draft.item.status = RequestStatus.Request;
        draft.item.error = null;
      });
    case FI_LI_ITEM_FETCHED:
      return produce(state, (draft: FiLiState) => {
        draft.item.status = RequestStatus.Success;
        draft.item.data = action.payload;
      });
    case FI_LI_ITEM_ERROR:
      return produce(state, (draft: FiLiState) => {
        draft.item.status = RequestStatus.Failure;
        draft.item.error = action.error;
      });
    case FI_LI_ITEM_RESET:
      return produce(state, (draft: FiLiState) => {
        draft.item = initialState.item;
      });
    case FI_LI_DELETE_PROCESSING:
      return produce(state, (draft: FiLiState) => {
        draft.delete.status = RequestStatus.Request;
        draft.delete.error = null;
      });
    case FI_LI_DELETE_SUCCESS:
      return produce(state, (draft: FiLiState) => {
        draft.delete.status = RequestStatus.Success;
        draft.delete.data = action.payload;
      });
    case FI_LI_DELETE_ERROR:
      return produce(state, (draft: FiLiState) => {
        draft.delete.status = RequestStatus.Failure;
        draft.delete.error = action.error;
      });
    case FI_LI_DELETE_RESET:
      return produce(state, (draft: FiLiState) => {
        draft.delete = initialState.delete;
      });
    case FI_LI_ADD_PROCESSING:
      return produce(state, (draft: FiLiState) => {
        draft.add.status = RequestStatus.Request;
        draft.add.error = null;
      });
    case FI_LI_ADD_SUCCESS:
      return produce(state, (draft: FiLiState) => {
        draft.add.status = RequestStatus.Success;
        draft.add.data = action.payload;
      });
    case FI_LI_ADD_ERROR:
      return produce(state, (draft: FiLiState) => {
        draft.add.status = RequestStatus.Failure;
        draft.add.error = action.error;
      });
    case FI_LI_ADD_RESET:
      return produce(state, (draft: FiLiState) => {
        draft.add = initialState.add;
      });
    case FI_LI_UPDATE_PROCESSING:
      return produce(state, (draft: FiLiState) => {
        draft.update.status = RequestStatus.Request;
        draft.update.error = null;
      });
    case FI_LI_UPDATE_SUCCESS:
      return produce(state, (draft: FiLiState) => {
        draft.update.status = RequestStatus.Success;
        draft.update.data = action.payload;
      });
    case FI_LI_UPDATE_ERROR:
      return produce(state, (draft: FiLiState) => {
        draft.update.status = RequestStatus.Failure;
        draft.update.error = action.error;
      });
    case FI_LI_UPDATE_RESET:
      return produce(state, (draft: FiLiState) => {
        draft.update = initialState.update;
      });
    default:
      return state;
  }
};

export default fiLiReducer;
