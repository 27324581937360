import { BaseCssProps } from "../../../../../../../ui-kit/types";
import * as S from "./ProductListCardTasksCountBadge.desktop.styled";
import { Icon, InfoIcon } from "../../../../../../../ui-kit/icons";
import { declensionHelper } from "../../../../../../../helpers/entity";

export interface ProductListCardTasksCountBadgeDesktopProps extends BaseCssProps {
  count: number;
}

export const ProductListCardTasksCountBadgeDesktop = ({
  count,
  className,
}: ProductListCardTasksCountBadgeDesktopProps) => {
  return (
    <S.Badge className={className}>
      <Icon as={InfoIcon} height={15} />
      {declensionHelper.commonDeclensions.activeTasks.nominative(count, true)}
    </S.Badge>
  );
};
