import styled from "@emotion/styled";
import { PRODUCT_CARD_CONTAINER_LEFT_MARGIN_PX } from "./ProductListCardContainer.desktop.constants";

export const Article = styled.article`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Header = styled.header`
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xs};
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: ${({ theme }) => theme.spaces.sm};
`;

export const Separator = styled.div`
  width: 1px;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.secondaryLight};
`;

export const Main = styled.section`
  justify-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: ${PRODUCT_CARD_CONTAINER_LEFT_MARGIN_PX}px;
`;
