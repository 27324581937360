import { useEffect } from "react";
import { ClientId, TaxpayerNumber } from "../../common/types/demand";
import { useAppDispatch, useAppSelector } from "../../common/store";
import { checkSearchClient } from "../store/clientSearch.thunks";
import { SearchClientCheckCompanyCard } from "./card/SearchClientCheckCompanyCard";
import { ClientSearchClientStateSelector } from "../types/ClientSearchClientStateSelector";
import { ClientSearchFixedKey } from "../types/ClientSearchFixedKey";
import { getClientSearchSelectors } from "../store/clientSearch.selectors";
import { BaseCssProps } from "../../common/ui-kit/types";

export interface BankGuaranteeSearchClientCheckedProps extends BaseCssProps {
  clientId: ClientId;
  clientStateSelector: ClientSearchClientStateSelector;
  fixedKey: ClientSearchFixedKey;
  onRemove?: () => void | Promise<void>;
  isWithAddress?: boolean;
}

export const SearchClientChecked = ({
  clientId,
  onRemove,
  clientStateSelector,
  fixedKey,
  className,
  isWithAddress,
}: BankGuaranteeSearchClientCheckedProps) => {
  const dispatch = useAppDispatch();
  const { check } = useAppSelector(clientStateSelector);
  const client = useAppSelector((state) => getClientSearchSelectors(clientStateSelector).selectById(state, clientId));

  useEffect(() => {
    if (check === undefined || (check?.data !== undefined && check.data.id !== clientId)) {
      dispatch(checkSearchClient({ clientId, key: fixedKey }));
    }
  }, [dispatch, check, clientId, fixedKey]);

  return (
    <SearchClientCheckCompanyCard
      className={className}
      shortName={check?.data?.name ?? client?.name ?? "-"}
      fullName={check?.data?.fullName ?? client?.fullName ?? check?.data?.name ?? client?.name ?? "-"}
      fullAddress={isWithAddress ? check?.data?.fullAddress : undefined}
      taxpayerNumber={check?.data?.taxpayerNumber ?? client?.taxpayerNumber ?? ("-" as TaxpayerNumber)}
      onRemove={onRemove}
    />
  );
};
