import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { ProductRejectionMobile } from "./ProductRejection.mobile";
import { ProductRejectionDesktop } from "./ProductRejection.desktop";
import { ProductRejectionCommonProps } from "./ProductRejectionCommonProps";

export const ProductRejection = ({
  isOpen,
  product,
  description,
  onClose,
  error,
  submitHandler,
  title,
  panelKey,
}: ProductRejectionCommonProps) => {
  return (
    <ThemeMobileDesktopView
      desktop={
        <ProductRejectionDesktop
          description={description}
          submitHandler={submitHandler}
          onClose={onClose}
          error={error}
          title={title}
          isOpen={isOpen}
          product={product}
        />
      }
      mobile={
        <ProductRejectionMobile
          description={description}
          submitHandler={submitHandler}
          onClose={onClose}
          product={product}
          title={title}
          error={error}
          panelKey={panelKey}
        />
      }
    />
  );
};
