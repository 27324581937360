import { BrowserRouter, useRoutes } from "react-router-dom";
import { ReactNode } from "react";
import { mapAppRoutes } from "../utils/mapAppRoutes";
import { appRoutes } from "../appRoutes";

const Routes = () => useRoutes(mapAppRoutes(appRoutes));

export interface AppRouterProps {
  children?: ReactNode;
}

export const AppRouter = ({ children }: AppRouterProps) => {
  return (
    <BrowserRouter>
      {children}
      <Routes />
    </BrowserRouter>
  );
};
