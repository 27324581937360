/* eslint-disable @typescript-eslint/no-explicit-any */
import { HubConnection, HubConnectionState } from "@microsoft/signalr";

export class SignalrConnection<THub extends string, TMethods extends string, TCallbacks extends string> {
  private readonly hubId: THub;

  private readonly connection: HubConnection;

  private isTryingToReconnect = false;

  constructor(hubId: THub, connection: HubConnection) {
    this.hubId = hubId;
    this.connection = connection;

    this.connection.onreconnecting(() => {
      this.isTryingToReconnect = true;
    });

    this.connection.onreconnected(() => {
      this.isTryingToReconnect = false;
    });
  }

  getId() {
    return this.hubId;
  }

  async start() {
    if (this.connection.state === HubConnectionState.Disconnected) {
      this.isTryingToReconnect = false;
      await this.connection.start();
    }
  }

  async stop() {
    if (
      this.connection.state === HubConnectionState.Connected ||
      this.connection.state === HubConnectionState.Connecting
    ) {
      await this.connection.stop();
      this.isTryingToReconnect = false;
    }
  }

  onClose(callback: (hubId: THub, isClosedAfterReconnecting: boolean) => void) {
    this.connection.onclose(() => callback(this.hubId, this.isTryingToReconnect));
  }

  on<TOutput = undefined>(methodName: TCallbacks, newMethod: (arg: TOutput) => void) {
    this.connection.on(methodName, newMethod);
  }

  off(methodName: TCallbacks, method: (...args: any[]) => void) {
    this.connection.off(methodName, method);
  }

  invoke<TOutput>(methodName: TMethods, ...args: any[]) {
    return this.connection.invoke<TOutput>(methodName, ...args);
  }
}
