import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { LegalServicesCreationItems } from "../components/list/LegalServicesCreationItems";
import { legalServicesCreationThunks } from "../store/legalServicesCreation.thunks";
import { useAppSelector } from "../../../common/store";
import { selectLegalServicesCreation } from "../store/legalServicesCreation.selectors";
import { usePageTitle } from "../../../common/navigation/page-title";
import { useFirstRenderThunkDispatch } from "../../../common/hooks";

export const LegalServicesCreationListPage = () => {
  usePageTitle("Юридические услуги");

  const state = useAppSelector(selectLegalServicesCreation);
  useFirstRenderThunkDispatch(state.status, legalServicesCreationThunks.getProducts);

  return (
    <AppLayoutResponsiveListPage backgroundColor="light">
      <AppLayoutResponsiveListPage.Header>
        <AppLayoutResponsiveListPage.Title>Юридические услуги</AppLayoutResponsiveListPage.Title>
      </AppLayoutResponsiveListPage.Header>
      <LegalServicesCreationItems />
    </AppLayoutResponsiveListPage>
  );
};
