import { currencyHelper } from "@sideg/helpers";
import { Typography } from "../../../../../../common/ui-kit/typography";

export interface BankGuaranteeDetailsBillComplicatedTaskBillInfoCostDataProps {
  title: string;
  price: number | undefined;
}

export const BankGuaranteeDetailsBillComplicatedTaskBillInfoCostData = ({
  title,
  price,
}: BankGuaranteeDetailsBillComplicatedTaskBillInfoCostDataProps) => {
  return (
    price !== undefined && (
      <>
        <Typography fontSize="fz2" lineHeight="defaultLineHeight" fontWeight={400} fontColor="typography.secondary">
          {title}
        </Typography>
        <Typography
          textAlign="right"
          fontSize="fz2"
          lineHeight="defaultLineHeight"
          fontWeight={500}
          fontColor="typography.secondary"
        >
          {currencyHelper.getCurrencyString(price)}
        </Typography>
      </>
    )
  );
};
