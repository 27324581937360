import styled from "@emotion/styled";
import { getAsideListItemCommonStyles } from "../../../../common/ui/layout/sidebar/components/aside-block-mobile/AsideBlockListItem.styled";
import { WebPushesSubscriptionState } from "../../../../common/web-pushes";

export const Li = styled.li(({ theme }) => getAsideListItemCommonStyles(theme));

type SubscriptionIconState = WebPushesSubscriptionState & ("available" | "subscribed");

export const SubscriptionIcon = styled.div<{ state: SubscriptionIconState }>`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.layout.sidebar.mobile.counterBackgroundColor};
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg > path {
    stroke: ${({ theme, state }) =>
      state === "available" ? theme.palette.typography.primaryLight : theme.palette.typography.success};
  }
`;
