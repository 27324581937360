import radii from "@sideg/ui-kit/core/theme/__generated__/radii.gen";
import { ExtractTypeFromConst } from "@sideg/ui-kit/core/theme/types/utils/ExtractTypeFromConst";
import { ThemeRadius, ThemeRadiusValue } from "@sideg/ui-kit/core/theme/types/radii/ThemeRadius";
import { ExtractPaths } from "@sideg/ui-kit/core/theme/types/utils/ExtractPaths";
import { makeRemValues } from "@sideg/ui-kit/core/theme/utils/makeRemValues";

export type ThemeRadiiValue = { [key in ThemeRadius]: ThemeRadiusValue };

export const generatedRadii = makeRemValues({ ...radii, none: 0 }) satisfies ThemeRadiiValue;

export type ThemeRadii = ExtractTypeFromConst<typeof generatedRadii, ThemeRadiiValue>;
export type ThemeRadiiValues = ExtractPaths<ThemeRadii, ThemeRadiusValue>;
