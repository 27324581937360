import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { BankGuaranteeListItemDesktopDemandListSkeleton } from "./list/BankGuaranteeListItemDesktopDemandList.skeleton";
import { ProductListCardDemandsListContainerDesktop } from "../../../../../../../common/ui/product/list/card";

export type BankGuaranteeListItemDesktopDemandsSkeletonProps = BaseCssProps;

export const BankGuaranteeListItemDesktopDemandsSkeleton = ({
  className,
}: BankGuaranteeListItemDesktopDemandsSkeletonProps) => {
  return (
    <ProductListCardDemandsListContainerDesktop
      className={className}
      banksCountTitle={<Skeleton inline width={60} height={28} />}
      counters={<Skeleton inline width={120} height={28} />}
      activeTasksCountBadge={<Skeleton />}
      actionsButton={<Skeleton inline width={28} height={28} />}
    >
      <BankGuaranteeListItemDesktopDemandListSkeleton itemsCount={4} />
    </ProductListCardDemandsListContainerDesktop>
  );
};
