import { BaseCssProps } from "../../../../../common/ui-kit/types";
import * as S from "./PublicUserRegistrationAgentDocumentCard.styled";
import { PublicUserOfferFileLabel } from "../../../../common/components";
import { PublicUserRegistrationAgentDocumentStatement } from "./PublicUserRegistrationAgentDocumentStatement";
import { PublicUserRegistrationAgentDocumentApprove } from "./PublicUserRegistrationAgentDocumentApprove";
import { IssuingCompanyId } from "../../../../../common/types/companies";
import { useAppSelector } from "../../../../../common/store";
import { selectPublicUserRegistrationAgentDocumentById } from "../../../store/agent/publicUserRegistrationAgent.selectors";

export interface PublicUserRegistrationAgentDocumentCardProps extends BaseCssProps {
  issuingCompanyId: IssuingCompanyId;
}

export const PublicUserRegistrationAgentDocumentCard = ({
  issuingCompanyId,
  className,
}: PublicUserRegistrationAgentDocumentCardProps) => {
  const document = useAppSelector((state) => selectPublicUserRegistrationAgentDocumentById(state, issuingCompanyId));

  return (
    <S.Card className={className}>
      <S.Part>
        <PublicUserOfferFileLabel fileName={document?.offerName ?? ""} issuingCompanyId={issuingCompanyId} />
        <PublicUserRegistrationAgentDocumentStatement issuingCompanyId={issuingCompanyId} />
      </S.Part>
      <S.Part>
        <PublicUserRegistrationAgentDocumentApprove issuingCompanyId={issuingCompanyId} />
      </S.Part>
    </S.Card>
  );
};
