import { useMemo } from "react";
import { QueueTasksListFilterParams, QueueTasksListFilters } from "../types/filter";
import { FilteringArrayKeys } from "../../../common/filters";
import { RootState, useAppSelector } from "../../../common/store";
import { useQueueTasksListSetValueMutator } from "./useQueueTasksListSetValueMutator";
import { selectQueueTasksListFilters } from "../store/queueTasksList.selectors";

export interface UseQueueTasksListFilterArrayArgs<
  TKey extends FilteringArrayKeys<QueueTasksListFilterParams>,
  TItem extends QueueTasksListFilterParams[TKey] extends (infer U)[] | undefined ? U : never
> {
  filteringObject: QueueTasksListFilterParams;
  key: TKey;
  itemsSelector: (state: RootState) => TItem[];
  getTitleFunc: (filters: QueueTasksListFilters | undefined) => (item: TItem) => string;
}

export interface UseQueueTasksListFilterArrayReturnType<TItem> {
  getItems: () => TItem[];
  selectedItems: TItem[];
  getItemTitle: (item: TItem) => string;
  onSelectedChange: (selectedItems: TItem[]) => void | Promise<void>;
}

export interface UseQueueTasksListFilterArrayInterface {
  <
    TKey extends FilteringArrayKeys<QueueTasksListFilterParams>,
    TItem extends QueueTasksListFilterParams[TKey] extends (infer U)[] | undefined ? U : never
  >(
    args: UseQueueTasksListFilterArrayArgs<TKey, TItem>
  ): UseQueueTasksListFilterArrayReturnType<TItem>;
}

export const useQueueTasksListFilterArray: UseQueueTasksListFilterArrayInterface = <
  TKey extends FilteringArrayKeys<QueueTasksListFilterParams>,
  TItem extends QueueTasksListFilterParams[TKey] extends (infer U)[] | undefined ? U : never
>({
  filteringObject,
  key,
  itemsSelector,
  getTitleFunc,
}: UseQueueTasksListFilterArrayArgs<TKey, TItem>): UseQueueTasksListFilterArrayReturnType<TItem> => {
  const mutator = useQueueTasksListSetValueMutator();

  const { data: filters } = useAppSelector(selectQueueTasksListFilters);
  const items = useAppSelector(itemsSelector);

  return useMemo(() => {
    const getItemTitle = (item: TItem) => getTitleFunc(filters)(item);
    const getItems = () => items;

    const onSelectedChange = (selectedItems: TItem[]) => {
      mutator(filteringObject, key, selectedItems as QueueTasksListFilterParams[typeof key]);
    };

    return {
      getItems,
      getItemTitle,
      selectedItems: (filteringObject[key] as TItem[]) ?? [],
      onSelectedChange,
    };
  }, [filteringObject, filters, getTitleFunc, items, key, mutator]);
};
