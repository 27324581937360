import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { BankGuaranteeId } from "../../../../../../../common/types/demand";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import { useAppSelector } from "../../../../../../../common/store";
import { selectBankGuaranteeListItemsGuaranteeById } from "../../../../../store/items/bankGuaranteeListItems.selectors";
import { BankGuaranteeListItemStageCounters } from "../../stage/BankGuaranteeListItemStageCounters";
import { BankGuaranteeListItemDesktopDemandList } from "./list/BankGuaranteeListItemDesktopDemandList";
import { useBankGuaranteeListActions } from "../../../../../hooks/list/actions/useBankGuaranteeListActions";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import {
  ProductListCardDemandsListContainerDesktop,
  ProductListCardMoreActionsButton,
  ProductListCardShowMoreButtonDesktop,
  ProductListCardTasksCountBadgeDesktop,
} from "../../../../../../../common/ui/product/list/card";
import { useListTrimMoreThan } from "../../../../../../../common/hooks";
import { declensionHelper } from "../../../../../../../common/helpers/entity";

export interface BankGuaranteeListItemDesktopDemandsProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  from?: NavigationState;
}

export const BankGuaranteeListItemDesktopDemands = ({
  guaranteeId,
  from,
  className,
}: BankGuaranteeListItemDesktopDemandsProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const guarantee = useAppSelector((state) => selectBankGuaranteeListItemsGuaranteeById(state, guaranteeId))!;
  const { items, isShown, toggleIsShown, needTrim } = useListTrimMoreThan(guarantee.demandIds, 3);

  const actions = useBankGuaranteeListActions(guarantee, from);

  return (
    <ProductListCardDemandsListContainerDesktop
      className={className}
      banksCountTitle={declensionHelper.commonDeclensions.bank.nominative(guarantee.demandIds.length, true)}
      counters={<BankGuaranteeListItemStageCounters counters={guarantee.counters} />}
      activeTasksCountBadge={
        guarantee.activeTasksCount > 0 && <ProductListCardTasksCountBadgeDesktop count={guarantee.activeTasksCount} />
      }
      actionsButton={actions.length > 0 && <ProductListCardMoreActionsButton availableActions={actions} />}
      footer={
        needTrim && (
          <Box pt="sm">
            <ProductListCardShowMoreButtonDesktop isShow={!isShown} onClick={toggleIsShown} />
          </Box>
        )
      }
    >
      <BankGuaranteeListItemDesktopDemandList guaranteeId={guaranteeId} demands={items} from={from} />
    </ProductListCardDemandsListContainerDesktop>
  );
};
