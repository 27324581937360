import { useEffect, useMemo, useState } from "react";
import { useTypedField } from "../../../common/form";
import { TaxpayerNumber } from "../../../common/types/demand";
import { valueValidationService } from "../../../common/validation";
import { useApiMutation } from "../../../common/hooks";
import { checkAgencyPublicUserRegistration } from "../store/publicUserRegistration.thunks";
import { AgencyType } from "../../../common/types/agencies";
import { useDebounce } from "../../../common/hooks/useDebounce";
import { isLoading } from "../../../common/types/state";
import { CompanyType } from "../../../common/types/companies";

export interface UsePublicUserRegistrationCheckAgencyEffectReturnType {
  isPassing: boolean | undefined;
  isLoading: boolean;
}

export const usePublicUserRegistrationCheckAgencyEffect = (
  taxpayerNumberFieldName: string,
  companyTypeFieldName: string,
  consumerType: AgencyType,
): UsePublicUserRegistrationCheckAgencyEffectReturnType => {
  const [isPassing, setIsPassing] = useState<boolean | undefined>(undefined);

  const [{ value: taxpayerNumber }] = useTypedField<TaxpayerNumber>(taxpayerNumberFieldName);
  const [{ value: companyType }] = useTypedField<CompanyType>(companyTypeFieldName);

  const [debounceValue] = useDebounce<TaxpayerNumber>(taxpayerNumber);
  const { mutate, status } = useApiMutation(checkAgencyPublicUserRegistration, { errorMessagePlaceholder: "" });

  useEffect(() => {
    let isMounted = true;
    const setIfMounted = (isPassingValue: typeof isPassing) => {
      if (isMounted) {
        setIsPassing(isPassingValue);
      }
    };

    const check = async () => {
      if (await valueValidationService.isValidTaxpayerNumber(debounceValue)) {
        const result = await mutate({ taxpayerNumber: debounceValue, consumerType, companyType });
        setIfMounted(result?.body.canRegister === true);
      } else {
        setIfMounted(undefined);
      }
    };

    check();

    return () => {
      isMounted = false;
    };
  }, [consumerType, companyType, mutate, debounceValue]);

  return useMemo(() => ({ isPassing, isLoading: isLoading(status) }), [isPassing, status]);
};
