import { usePublicUserAuthenticationFormSubmitHandler } from "../../hooks/usePublicUserAuthenticationFormSubmitHandler";
import { PublicUserCardWithTitle } from "../../../common/components";
import { PublicUserAuthenticationForm } from "./PublicUserAuthenticationForm";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export type PublicUserAuthenticationCardProps = BaseCssProps;

export const PublicUserAuthenticationCard = ({ className }: PublicUserAuthenticationCardProps) => {
  const onSubmit = usePublicUserAuthenticationFormSubmitHandler();

  return (
    <PublicUserCardWithTitle className={className} title="Вход в личный кабинет">
      <PublicUserAuthenticationForm onSubmit={onSubmit} />
    </PublicUserCardWithTitle>
  );
};
