import { useState } from "react";
import * as React from "react";
import "./FileUpload.scss";

interface IFileUploadProps {
  id: number;
  onChange: (id: number, file: File | null) => void;
}

const FileUpload: React.FC<IFileUploadProps> = (props) => {
  const [file, setFile] = useState<File | null>();
  const [drag, setDrag] = useState<boolean>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    let file = null;
    if (e.target.files) {
      file = e.target.files.item(0);
    }
    setFile(file);
    props.onChange(id, file);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, id: number) => {
    e.stopPropagation();
    e.preventDefault();
    let file = null;
    if (e.dataTransfer.files) {
      file = e.dataTransfer.files.item(0) as File;
    }
    setFile(file);
    props.onChange(id, file);

    setDrag(false);
  };

  const handleOnDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setDrag(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setDrag(true);
  };

  return (
    <div
      className="file-upload"
      onDragLeave={handleOnDragLeave}
      onDrop={(e) => handleDrop(e, props.id)}
      onDragOver={handleDragOver}
    >
      <label className="file-upload__label" style={drag ? { backgroundColor: "green" } : {}}>
        <input type="file" className="file-upload__input" onChange={(e) => handleFileChange(e, props.id)} />
        <i className="far fa-cloud-upload file-upload__icon" />
        {file ? "Изменить" : "Выбрать"}
      </label>
      <span className="file-upload__filename">{file ? file.name : "Файл не выбран"}</span>
    </div>
  );
};

export default FileUpload;
