import { memo } from "react";
import { TransitionGroup } from "react-transition-group";
import { BankGuaranteeId, DemandId } from "../../../../../../../../common/types/demand";
import { BaseCssProps } from "../../../../../../../../common/ui-kit/types";
import { BankGuaranteeListItemDesktopDemand } from "../../demand-list-item/BankGuaranteeListItemDesktopDemand";
import * as S from "./BankGuaranteeListItemDesktopDemandList.styled";
import { NavigationState } from "../../../../../../../../common/navigation/navigation-state";
import { useAppDispatch } from "../../../../../../../../common/store";
import { onRejectDemandBankGuaranteeList } from "../../../../../../store/item-modification/bankGuaranteeListItemModification.slice";
import { ProductListCardDemandListItemAppearanceTransition } from "../../../../../../../../common/ui/product/list/card";

export interface BankGuaranteeListItemDesktopDemandListProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demands: DemandId[];
  from?: NavigationState;
}

export const BankGuaranteeListItemDesktopDemandList = memo(
  ({ guaranteeId, demands, from, className }: BankGuaranteeListItemDesktopDemandListProps) => {
    const dispatch = useAppDispatch();

    return (
      <TransitionGroup component={S.Ul} className={className}>
        {demands.map((x) => (
          <ProductListCardDemandListItemAppearanceTransition key={x} timeout={250}>
            <BankGuaranteeListItemDesktopDemand
              guaranteeId={guaranteeId}
              demandId={x}
              from={from}
              onRemoveClick={() => {
                dispatch(onRejectDemandBankGuaranteeList({ demandId: x, guaranteeId }));
              }}
            />
          </ProductListCardDemandListItemAppearanceTransition>
        ))}
      </TransitionGroup>
    );
  }
);
