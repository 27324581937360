import { useState } from "react";
import { selectBankGuaranteeListFiltersBanks } from "../../../../../store/filters/bankGuaranteeListFilters.selectors";
import { useAppSelector } from "../../../../../../../common/store";
import { FormSearchInput } from "../../../../../../../common/ui-kit/form-controls/search-input";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { BankGuaranteeListMobileFilterParams } from "../../../../../types/filters/BankGuaranteeListMobileFilterParams";
import { FilterFormMobileCheckboxesWithCollapse } from "../../../../../../../common/filters/use-cases";

export type BankGuaranteeListFiltersBanksWithSearchContainerProps = BaseCssProps;

export const BankGuaranteeListFiltersBanksWithSearchContainer = ({
  className,
}: BankGuaranteeListFiltersBanksWithSearchContainerProps) => {
  const [inputValue, setInputValue] = useState("");
  const banks = useAppSelector((state) => selectBankGuaranteeListFiltersBanks(state, inputValue));

  return (
    <FilterFormMobileCheckboxesWithCollapse<BankGuaranteeListMobileFilterParams>
      title="Банки"
      fieldName="bankIds"
      items={banks}
      className={className}
      beforeList={
        <FormSearchInput
          placeholder="Наименование банка"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      }
    />
  );
};
