import { createSlice, isAnyOf, isAsyncThunkAction } from "@reduxjs/toolkit";
import { CreditExecutionDetailsProductDetailsState } from "./creditExecutionDetailsProductDetails.types";
import { getLoadingStatusFromAction, isAborted, LoadingStatusEnum } from "../../../../common/types/state";
import { combineSliceNames } from "../../../../common/store/utils";
import {
  CREDIT_EXECUTION_DETAILS_SLICE_NAME,
  creditExecutionDetailsActiveProductChanged,
} from "../creditExecutionDetails.actions";
import { getCreditExecutionDetails, uploadGroupDocumentCreditExecutionDetails } from "../creditExecutionDetails.thunks";
import { creditExecutionDetailsProductDetailsMapper } from "./creditExecutionDetailsProductDetails.mapper";
import { isCreditExecutionDetailsNeedReloadMatcher } from "../creditExecutionDetails.matchers";

const initialState: CreditExecutionDetailsProductDetailsState = {
  status: LoadingStatusEnum.Idle,
  data: undefined,
  isTransferred: false,
  lastSuccessRequestId: undefined,
};

const slice = createSlice({
  name: combineSliceNames(CREDIT_EXECUTION_DETAILS_SLICE_NAME, "product-details"),
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(creditExecutionDetailsActiveProductChanged, (state, action) => {
        if (action.payload !== undefined) {
          return {
            ...initialState,
            data: creditExecutionDetailsProductDetailsMapper.fromTransfer(action.payload),
            isTransferred: true,
          };
        }

        return initialState;
      })
      .addMatcher(
        isAnyOf(uploadGroupDocumentCreditExecutionDetails.fulfilled, getCreditExecutionDetails.fulfilled),
        (state, { payload, meta }) => {
          state.data = creditExecutionDetailsProductDetailsMapper.fromResponse(payload.body);
          state.isTransferred = false;
          state.lastSuccessRequestId = meta.requestId;
        }
      )
      .addMatcher(isCreditExecutionDetailsNeedReloadMatcher, (state) => {
        state.status = LoadingStatusEnum.Idle;
      })
      .addMatcher(isAsyncThunkAction(getCreditExecutionDetails), (state, action) => {
        const status = getLoadingStatusFromAction(action);
        if (!isAborted(status)) {
          state.status = status;
        }
      });
  },
});

export const creditExecutionDetailsProductDetailsReducer = slice.reducer;
