import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import {
  ProductCreationScoringResultSummaryMobile,
  ProductCreationScoringResultSummaryMobileProps,
} from "./mobile/ProductCreationScoringResultSummary.mobile";
import { ProductCreationScoringResultSummaryDesktop } from "./desktop/ProductCreationScoringResultSummary.desktop";

export const ProductCreationScoringResultSummary = ({
  onShowRejectsClick,
  ...props
}: ProductCreationScoringResultSummaryMobileProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={<ProductCreationScoringResultSummaryMobile {...props} onShowRejectsClick={onShowRejectsClick} />}
      desktop={<ProductCreationScoringResultSummaryDesktop {...props} />}
    />
  );
};
