import { object, string } from "yup";

export const publicUserRegistrationAgentDocumentApproveValidationSchema = object({
  code: string()
    .transform((value, originalValue) => {
      return originalValue.replace(/\D/g, "");
    })
    .default("")
    .required("Укажите код подтверждения")
    .matches(/^\d+$/, "Код должен содержать только цифры")
    .length(6, "Код должен состоять из 6 цифр"),
});
