import { ClientSearchState } from "../../../client-search";
import { PurchaseState } from "../../../common/purchase";
import { RootState } from "../../../common/store";
import { isLoading } from "../../../common/types/state";
import { CreditExecutionCreationState } from "./creditExecutionCreation.types";

const selectBase = (state: RootState) => state.domains.creditExecution.creation;

export const selectCreditExecutionCreation = (state: RootState): CreditExecutionCreationState => selectBase(state).form;

export const selectCreditExecutionCreationClientSearch = (state: RootState): ClientSearchState =>
  selectBase(state).clientSearch;

export const selectCreditExecutionCreationPurchase = (state: RootState): PurchaseState => selectBase(state).purchase;

export const selectCreditExecutionCreationIsPurchaseStatusFetching = (state: RootState): boolean => {
  return isLoading(selectCreditExecutionCreationPurchase(state).status);
};

export const selectCreditExecutionCreationCreating = (state: RootState) =>
  selectCreditExecutionCreation(state).creating;

export const selectCreditExecutionCreationScoring = (state: RootState) => selectCreditExecutionCreation(state).scoring;
