import { BankId } from "../../../../../common/types/banks";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { SettlementAndCashCreationFromBankContainer } from "./SettlementAndCashCreationFromBankContainer";
import { useSettlementsAndCashCreationBanksItemFieldState } from "../../../hooks/useSettlementsAndCashCreationBanksItemFieldState";
import { ScoringToggleBankButton } from "../../../../../common/ui/buttons/scoring-toogle";

export interface SettlementAndCashCreationFromBankProps extends BaseCssProps {
  bankId: BankId;
  bankName: string;
  isDisabled?: boolean;
}

export const SettlementAndCashCreationFromBank = ({
  isDisabled,
  bankId,
  bankName,
  className,
}: SettlementAndCashCreationFromBankProps) => {
  const { isSelected, toggleBank } = useSettlementsAndCashCreationBanksItemFieldState(bankId);

  return (
    <SettlementAndCashCreationFromBankContainer
      bankName={bankName}
      className={className}
      isSelected={isSelected}
      button={<ScoringToggleBankButton isDisabled={isDisabled} onClick={toggleBank} isSelected={isSelected} />}
    />
  );
};
