import { joinUrlParts } from "../../../common/urls/internal/utils";
import { PUBLIC_USER_URL_ROOT_PATH } from "../../common/urls";
import { AppUrlTypeEnum } from "../../../common/urls/internal/types";
import { makePublicOrCommonUrl } from "../../../common/urls/internal/utils/makeAppUrl";

const index = makePublicOrCommonUrl(
  joinUrlParts(PUBLIC_USER_URL_ROOT_PATH, "new"),
  "/account/new",
  AppUrlTypeEnum.Public,
);

export const publicUserRegistrationUrls = {
  new: index,
  agent: makePublicOrCommonUrl(
    joinUrlParts(index.url, "agent", ":agencyId?"),
    "/account/new/agent/:agencyId?",
    AppUrlTypeEnum.Public,
  ),
  client: makePublicOrCommonUrl(joinUrlParts(index.url, "client"), "/account/new/client", AppUrlTypeEnum.Public),
  /**
   * @deprecated Совместимость со старыми адресами
   */
  newLegacy: makePublicOrCommonUrl(
    joinUrlParts(PUBLIC_USER_URL_ROOT_PATH, "registration"),
    "/account/registration",
    AppUrlTypeEnum.Public,
  ),
};
