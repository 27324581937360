import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";
import { NumericFormatProps } from "react-number-format/types/types";
import { FormInput, FormInputProps } from "@sideg/ui-kit/atoms/form-controls/input";

export interface FormInputPriceProps
  extends Omit<FormInputProps, "type" | "defaultValue" | "value">,
    Omit<NumericFormatProps, "customInput" | "size" | "type"> {}

export const FormInputPrice = forwardRef<HTMLInputElement, FormInputPriceProps>(({ className, ...rest }, ref) => {
  return (
    <NumericFormat
      getInputRef={ref}
      className={className}
      customInput={FormInput}
      decimalSeparator=","
      allowedDecimalSeparators={[",", "."]}
      allowLeadingZeros={false}
      thousandSeparator=" "
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      autoComplete="off"
      inputMode="decimal"
      type="text"
      {...rest}
    />
  );
});
