import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../common/store";
import { bankGuaranteeDetailsActiveTasksAdapter } from "./bankGuaranteeDetailsActiveTasks.adapter";
import { BankGuaranteeDetailsActiveTasksTypes } from "./bankGuaranteeDetailsActiveTasks.types";
import { GuaranteeQueueTasksGroupType } from "../../../../common/types/queue-tasks";

export const selectBankGuaranteeDetailsActiveTasks = (state: RootState): BankGuaranteeDetailsActiveTasksTypes =>
  state.domains.bankGuarantee.details.activeTasks;

const {
  selectById: selectBankGuaranteeDetailsActiveTaskById,
  selectIds: selectBankGuaranteeDetailsActiveTasksIds,
  selectTotal: selectBankGuaranteeDetailsActiveTasksTotal,
  selectAll,
} = bankGuaranteeDetailsActiveTasksAdapter.getSelectors(selectBankGuaranteeDetailsActiveTasks);

export const selectBankGuaranteeDetailsActiveTasksIdsByGroupType = createSelector(
  [selectAll, (_state, groupType: GuaranteeQueueTasksGroupType) => groupType],
  (tasks, groupType) => {
    if (groupType === "All") {
      return tasks.map((task) => task.id);
    }

    return tasks.filter((task) => task.groupType?.code === groupType).map((task) => task.id);
  },
);

const createSelectCountByGroupType = (groupType: GuaranteeQueueTasksGroupType) =>
  createSelector([selectAll], (tasks) => {
    return tasks.filter((task) => task.groupType?.code === groupType).length;
  });

export const selectBankGuaranteeDetailsActiveTasksBillCount = createSelectCountByGroupType("Bill");
export const selectBankGuaranteeDetailsActiveTasksProjectCount = createSelectCountByGroupType("Project");

export {
  selectBankGuaranteeDetailsActiveTaskById,
  selectBankGuaranteeDetailsActiveTasksIds,
  selectBankGuaranteeDetailsActiveTasksTotal,
};
