import { useFormikContext } from "formik";
import { useEffect } from "react";
import { numberHelper } from "@sideg/helpers";
import { useGetFieldName } from "../../../form";
import { WidgetsCalculatorFormValues } from "../types/WidgetsCalculatorFormValues";
import { amountsSchemaWithLocker } from "../validation/widgetsCalculatorValidationSchema";
import { useWidgetsCalculatorIsAmountAvailable } from "./useWidgetsCalculatorIsAmountAvailable";
import { isWidgetsCalculatorFormPartValid } from "../utils/isWidgetsCalculatorFormPartValid";
import { guaranteeCommissionCalculationHelper } from "../../../helpers/guarantee";

export const useWidgetsCalculatorAmountMutationEffect = () => {
  const getFieldName = useGetFieldName<WidgetsCalculatorFormValues>();
  const { values, setFieldValue } = useFormikContext<WidgetsCalculatorFormValues>();
  const isAvailable = useWidgetsCalculatorIsAmountAvailable();

  useEffect(() => {
    const { period } = values;
    const isValidPeriod = numberHelper.isValidNumber(period);

    if (
      !isAvailable ||
      !isValidPeriod ||
      !isWidgetsCalculatorFormPartValid(values, amountsSchemaWithLocker, "amountLocker")
    ) {
      return;
    }

    const { sum, commission, percent, amountLocker } = amountsSchemaWithLocker.cast(values, {
      assert: false,
    });

    if (amountLocker === "sum" && numberHelper.isValidNumber(commission) && numberHelper.isValidNumber(percent)) {
      setFieldValue(
        getFieldName("sum"),
        guaranteeCommissionCalculationHelper.calculateSum(commission, period, percent),
        true,
      );
    } else if (
      amountLocker === "commission" &&
      numberHelper.isValidNumber(sum) &&
      numberHelper.isValidNumber(percent)
    ) {
      setFieldValue(
        getFieldName("commission"),
        guaranteeCommissionCalculationHelper.calculateCommission(period, sum, percent),
        true,
      );
    } else if (
      amountLocker === "percent" &&
      numberHelper.isValidNumber(commission) &&
      numberHelper.isValidNumber(sum)
    ) {
      setFieldValue(
        getFieldName("percent"),
        guaranteeCommissionCalculationHelper.calculatePercent(commission, period, sum),
        true,
      );
    }
  }, [getFieldName, isAvailable, setFieldValue, values]);
};
