import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { CREDIT_WORKING_ASSETS_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

const product = makeAppUrl(
  joinUrlParts(CREDIT_WORKING_ASSETS_ROOT_URL, ":creditWorkingAssetsId"),
  "/credit-working-assets/:creditWorkingAssetsId",
  makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCwa),
);

export const creditWorkingAssetsDetailsUrls = {
  product,
  demand: makeAppUrl(
    joinUrlParts(product.url, "demand", ":demandId"),
    "/credit-working-assets/:creditWorkingAssetsId/demand/:demandId",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCwa),
  ),
  documents: makeAppUrl(
    joinUrlParts(product.url, "documents"),
    "/credit-working-assets/:creditWorkingAssetsId/documents",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCwa),
  ),
};
