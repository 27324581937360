import { useMemo } from "react";
import {
  makeProductListCardMoreAction,
  ProductListCardMoreActionWithHandler,
} from "../../../common/ui/product/list/card";
import { CreditWorkingAssetsListItem } from "../types/items/CreditWorkingAssetsListItem";
import { NavigationState } from "../../../common/navigation/navigation-state";
import { PlusIcon, TrashIcon } from "../../../common/ui-kit/icons";
import { useAppDispatch } from "../../../common/store";
import { onProductRejectCreditWorkingAssetsListItemModification } from "../store/item-modification/creditWorkingAssetsListItemModification.slice";
import { useAppNavigation } from "../../../common/urls/internal/hooks";

export const useCreditWorkingAssetsListItemMoreActions = (
  product: CreditWorkingAssetsListItem,
  from?: NavigationState
): ProductListCardMoreActionWithHandler[] => {
  const dispatch = useAppDispatch();
  const navigator = useAppNavigation();

  return useMemo(() => {
    const items: ProductListCardMoreActionWithHandler[] = [];

    items.push({
      ...makeProductListCardMoreAction("send-more", "Отправить в другие банки", PlusIcon),
      handler: () => {
        navigator(
          (x) => x.creditWorkingAssets.creation.createByCreditWorkingAssets,
          {
            creditWorkingAssetsId: product.creditWorkingAssetsId,
          },
          { state: from }
        );
      },
    });

    if (product.canRejectAll) {
      items.push({
        ...makeProductListCardMoreAction("remove-product", "Отменить заявку", TrashIcon),
        handler: () => {
          dispatch(
            onProductRejectCreditWorkingAssetsListItemModification({
              creditWorkingAssetsId: product.creditWorkingAssetsId,
            })
          );
        },
      });
    }

    return items;
  }, [dispatch, from, navigator, product.canRejectAll, product.creditWorkingAssetsId]);
};
