import { number, object, string } from "yup";
import { currencyHelper, numberHelper } from "@sideg/helpers";
import { getFormattedNumberTransformer, getLazyObjectWithDynamicKeysSchema } from "../../../common/validation";

export const creditWorkingAssetsCreationScoringBankValidationSchema = object({
  bankId: string().required(),
  creditSumFrom: number().notRequired().nullable(),
  creditSumTo: number().notRequired().nullable(),
  creditSum: number()
    .required("Укажите сумму")
    .transform(getFormattedNumberTransformer())
    .typeError("Укажите корректную сумму")
    .when("creditSumFrom", (creditSumFrom, schema) => {
      return numberHelper.isValidNumber(creditSumFrom)
        ? schema.min(creditSumFrom, `Сумма должна быть больше ${currencyHelper.getCurrencyString(creditSumFrom)}`)
        : schema;
    })
    .when("creditSumTo", (creditSumTo, schema) => {
      return numberHelper.isValidNumber(creditSumTo)
        ? schema.max(creditSumTo, `Сумма должна быть меньше ${currencyHelper.getCurrencyString(creditSumTo)}`)
        : schema;
    }),
});

export const creditWorkingAssetsCreationScoringValidationSchema = object({
  banks: getLazyObjectWithDynamicKeysSchema(creditWorkingAssetsCreationScoringBankValidationSchema),
  banksCount: number()
    .default(0)
    .when("banks", (banks: Record<string, unknown> | undefined, schema) => {
      if (banks !== undefined) {
        const values = Object.values(banks);
        if (values.some((x) => x !== undefined)) {
          return schema.notRequired();
        }
      }

      return schema.min(1, "Необходимо выбрать хотя бы один банк");
    }),
});
