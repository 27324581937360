import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useBankGuaranteeListFilterContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterContext";
import {
  selectBankGuaranteeListFilters,
  selectBankGuaranteeListFiltersMaxLength,
} from "../../../../store/filters/bankGuaranteeListFilters.selectors";
import { FilterMultipleSelectList } from "../../../../../../common/ui-kit/form-controls/select";
import { useBankGuaranteeListFilterArray } from "../../../../hooks/filters/useBankGuaranteeListFilterArray";
import { useAppSelector } from "../../../../../../common/store";

export type BankGuaranteeListFiltersTaskTypesProps = BaseCssProps;

export const BankGuaranteeListFiltersTaskTypes = ({ className }: BankGuaranteeListFiltersTaskTypesProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();

  const { getItemTitle, selectedItems, getItems, onSelectedChange } = useBankGuaranteeListFilterArray({
    filteringObject,
    key: "taskTypes",
    itemsSelector: (state) => selectBankGuaranteeListFilters(state).data?.taskTypes.ids ?? [],
    getTitleFunc: (filters) => (item) => filters?.taskTypes.titles[item] ?? item,
  });

  const maxItemsLength = useAppSelector((state) => selectBankGuaranteeListFiltersMaxLength(state, "taskTypes"));

  return (
    <FilterMultipleSelectList
      className={className}
      text="Задачи"
      selectedItems={selectedItems}
      getItemTitle={getItemTitle}
      onSelectedChanged={onSelectedChange}
      getItems={getItems}
      maxItemsLength={maxItemsLength}
    />
  );
};
