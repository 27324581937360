import { bankGuaranteeReissueApi } from "../api/bankGuaranteeReissueApi";
import { combineSliceNames } from "../../../common/store/utils";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { BANK_GUARANTEE_SLICE_NAME } from "../../common/store";

export const BANK_GUARANTEE_REISSUE_SLICE_NAME = combineSliceNames(BANK_GUARANTEE_SLICE_NAME, "reissue");

export const { reissue: reissueBankGuarantee } = createApiThunks(
  BANK_GUARANTEE_REISSUE_SLICE_NAME,
  bankGuaranteeReissueApi
);
