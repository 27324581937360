import { useLayoutEffect, useState } from "react";
import { useAppSelector } from "../../../../common/store";
import { selectChatDialogLastMessageId } from "../../store/chatDialog.selectors";

export const useChatDialogFirstPageFetchEffect = (callback: () => void | Promise<void>) => {
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const lastMessageId = useAppSelector(selectChatDialogLastMessageId);

  useLayoutEffect(() => {
    if (isFirstFetch && lastMessageId !== undefined) {
      callback();
      setIsFirstFetch(false);
    }
  }, [isFirstFetch, lastMessageId, callback]);
};
