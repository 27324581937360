import { createSelector } from "@reduxjs/toolkit";
import { ProductDetailsDocumentsState } from "../../../../common/product-details";
import { RootState } from "../../../../common/store";
import { creditExecutionDetailsDocumentsAdapter } from "./creditExecutionDetailsDocuments.adapter";

export const selectCreditExecutionDetailsDocuments = (state: RootState): ProductDetailsDocumentsState =>
  state.domains.creditExecution.details.documents;

const { selectAll, selectById } = creditExecutionDetailsDocumentsAdapter.getSelectors(
  selectCreditExecutionDetailsDocuments,
);

export const selectCreditExecutionDetailsDocumentById = selectById;

export const selectCreditExecutionDetailsDocumentsIds = createSelector([selectAll], (items) => {
  return items.filter((x) => x.isUploaded !== true).map((x) => x.id);
});
