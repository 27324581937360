import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../../common/types/utils";

const titles = {
  NewThenByDateAddedDesc: "Сначала непрочитанные",
  DateAddedAsc: "По дате создания",
  DateAddedDesc: "Сначала новые",
};

export const queueTasksListOrdering = makeEnumLikeLiteral(
  ["NewThenByDateAddedDesc", "DateAddedAsc", "DateAddedDesc"] as const,
  titles
);

export type QueueTasksListOrderingType = EnumLikeLiteralType<typeof queueTasksListOrdering>;
