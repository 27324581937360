import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { FactoringCreationDebtorId } from "../../types/FactoringCreationDebtorId";
import { useFactoringCreationDebtorField } from "../../hooks/useFactoringCreationDebtorField";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { CreationBlockCard, CreationBlockCardTitle } from "../../../../common/ui-kit/cards/creation-block-card";
import { useGetFieldName, useGetFormFieldError } from "../../../../common/form";
import { FactoringCreationDebtorFormValues } from "../../types/FactoringCreationDebtorFormValues";
import { FactoringCreationFormValues } from "../../types/FactoringCreationFormValues";
import { TaxpayerFormInput } from "../../../../common/ui/form-controls/taxpayer-input";
import { FactoringCreationDebtorDocument } from "../../types/FactoringCreationDebtorDocument";
import { FactoringCreationDebtorDocumentCard } from "./FactoringCreationDebtorDocumentCard";
import { RejectButton } from "../../../../common/ui/buttons/reject-button";
import { FactoringCreationFormDebtorContainer } from "./FactoringCreationFormDebtorContainer";

export interface FactoringCreationFormDebtorProps extends BaseCssProps {
  canRemove?: boolean;
  onRemove: () => void;
  debtorId: FactoringCreationDebtorId;
  index: number;
  isDisabled?: boolean;
  documents: FactoringCreationDebtorDocument[];
}

export const FactoringCreationFormDebtor = ({
  debtorId,
  onRemove,
  canRemove,
  index,
  isDisabled,
  documents,
  className,
}: FactoringCreationFormDebtorProps) => {
  const getFieldName = useGetFieldName<FactoringCreationDebtorFormValues>();
  const getFieldError = useGetFormFieldError<FactoringCreationDebtorFormValues>();

  const form = useFormikContext<FactoringCreationFormValues>();
  const [{ name, value }] = useFactoringCreationDebtorField(debtorId);
  const debtorErrors = (form.errors.debtors?.[debtorId] ?? {}) as FormikErrors<FactoringCreationDebtorFormValues>;
  const debtorTouched = (form.touched.debtors?.[debtorId] ?? {}) as FormikTouched<FactoringCreationDebtorFormValues>;

  const debtorForm = { errors: debtorErrors, touched: debtorTouched };

  return (
    <FactoringCreationFormDebtorContainer className={className}>
      <StackPanel gap="xs" direction="row" alignItems="center" justifyContent="space-between">
        <CreationBlockCardTitle>Дебитор {index}</CreationBlockCardTitle>
        {canRemove && !isDisabled && (
          <RejectButton onClick={onRemove} size="default">
            Удалить
          </RejectButton>
        )}
      </StackPanel>
      <CreationBlockCard padding={{ xs: "md", md: "lg" }}>
        <StackPanel gap="md" direction="column">
          <TaxpayerFormInput
            label="ИНН"
            size="medium"
            autoComplete="off"
            {...form.getFieldProps(`${name}.${getFieldName("taxpayerNumber")}`)}
            error={getFieldError(debtorForm, "taxpayerNumber")}
            disabled={isDisabled}
          />
          <FormInputPrice
            autoComplete="off"
            label="Лимит финансирования, тыс. ₽"
            size="medium"
            {...form.getFieldProps(`${name}.${getFieldName("fundingLimit")}`)}
            error={getFieldError(debtorForm, "fundingLimit")}
            disabled={isDisabled}
          />
        </StackPanel>
      </CreationBlockCard>
      {value?.documentTempIds &&
        value.documentTempIds.map((x: string) => (
          <FactoringCreationDebtorDocumentCard
            key={x}
            name={`${name}.documents.${x}`}
            documents={documents}
            isDisabled={isDisabled}
          />
        ))}
    </FactoringCreationFormDebtorContainer>
  );
};
