import { useEffect, useState } from "react";
import { MediaQueryListLegacy } from "@sideg/ui-kit/core/responsive/types/MediaQueryListLegacy";

export const useMediaQuery = (mediaQuery: string): boolean => {
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    const match: MediaQueryListLegacy = window.matchMedia(mediaQuery);
    setIsMatch(match.matches);

    const handler = (event: MediaQueryListEvent) => {
      setIsMatch(event.matches);
    };

    if ("addEventListener" in match) {
      match.addEventListener("change", handler);
    } else {
      match.addListener(handler);
    }

    return () => {
      if ("removeEventListener" in match) {
        match.removeEventListener("change", handler);
      } else {
        match.removeListener(handler);
      }
    };
  }, [mediaQuery]);

  return isMatch;
};
