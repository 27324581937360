import Skeleton from "react-loading-skeleton";
import { CreditExecutionListItemStageCountersSkeleton } from "../../counters/CreditExecutionListItemStageCounters.skeleton";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { ProductListCardFooterDemandLinkMobileSkeleton } from "../../../../../../common/ui/product/list/card";
import { CreditExecutionCommonDetailsSkeleton } from "../../../../../common/components";
import { CreditExecutionListItemCardMobile } from "./card/CreditExecutionListItemCard.mobile";

export type CreditExecutionListItemMobileSkeletonProps = BaseCssProps;

export const CreditExecutionListItemMobileSkeleton = ({ className }: CreditExecutionListItemMobileSkeletonProps) => {
  return (
    <CreditExecutionListItemCardMobile
      className={className}
      numberAndDate={<Skeleton width={120} />}
      statusBadge={<Skeleton width={60} borderRadius={16} />}
      actionButton={<Skeleton width={28} height={28} />}
      clientName={<Skeleton width={220} />}
      details={<CreditExecutionCommonDetailsSkeleton />}
      footer={<ProductListCardFooterDemandLinkMobileSkeleton />}
    >
      <CreditExecutionListItemStageCountersSkeleton />
    </CreditExecutionListItemCardMobile>
  );
};
