import { ReactNode } from "react";
import { webPushesSubscriptionStateContext } from "../store/webPushesSubscriptionStateContext";
import { useWebPushesSubscription } from "../hooks/subscription/useWebPushesSubscription";
import { webPushesSubscriptionApiContext } from "../store/webPushesSubscriptionApiContext";
import { useAppSelector } from "../../store";
import { selectAuthorizedUser } from "../../authorized-user";
import { webPushesGetServiceWorkerRegistration } from "../utils/webPushesGetServiceWorkerRegistration";

export interface WebPushesSubscriptionProviderProps {
  children?: ReactNode;
}

const WebPushesSubscriptionProviderAuthenticated = ({ children }: WebPushesSubscriptionProviderProps) => {
  const { subscriptionState, ...api } = useWebPushesSubscription(webPushesGetServiceWorkerRegistration);

  return (
    <webPushesSubscriptionStateContext.Provider value={subscriptionState}>
      <webPushesSubscriptionApiContext.Provider value={api}>{children}</webPushesSubscriptionApiContext.Provider>
    </webPushesSubscriptionStateContext.Provider>
  );
};

export const WebPushesSubscriptionProvider = ({ children }: WebPushesSubscriptionProviderProps) => {
  const { isAuthenticated } = useAppSelector(selectAuthorizedUser);

  return (
    <>
      {!isAuthenticated && children}
      {isAuthenticated && (
        <WebPushesSubscriptionProviderAuthenticated>{children}</WebPushesSubscriptionProviderAuthenticated>
      )}
    </>
  );
};
