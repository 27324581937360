import styled from "@emotion/styled";
import { Avatar } from "./Avatar";

export const getInitialsFromUserName = (username: string | undefined) => {
  if (!username || username.length === 0) {
    return "";
  }

  if (username.length === 1) {
    return username.toUpperCase();
  }

  const parts = username
    .replace(/[^а-яА-Яa-zA-z ]/g, "")
    .split(" ")
    .filter((item) => item.length);

  return parts.length === 1 ? username.slice(0, 2) : `${parts[0][0]}${parts[1]?.[0]}`;
};

export const AvatarInitial = styled(Avatar)<{ initials: string; color: string }>`
  &::before {
    color: ${(props) => props.color};
    content: "${(props) => props.initials}";
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`;
