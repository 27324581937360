import { Key } from "react";
import { BaseCssProps } from "../../../../types";
import { SelectPopupContainerItemJustified } from "../SelectPopupContainerItemJustified";
import { CheckIcon, Icon } from "../../../../icons";
import { useMultiselectWithInnerSearchInput } from "../../hooks/useMultiselectWithInnerSearchInput";
import * as S from "./FilterMultipleCombobox.styled";
import { useFilterSelectContainerMinWidth } from "../../hooks/useFilterSelectContainerMinWidth";

export interface FilterMultipleComboboxProps<TItem> extends BaseCssProps {
  selectedItems?: TItem[];
  getItemTitle: (item: TItem) => string;
  onSelectedChanged: (selectedItems: TItem[]) => void | Promise<void>;
  getItems: () => TItem[];
  text: string;
  searchPlaceholder?: string;
  onSearchChanged: (search: string) => void | Promise<void>;
  maxItemsLength?: number;
}

export const FilterMultipleCombobox = <TItem,>({
  selectedItems: selected,
  getItemTitle,
  onSelectedChanged,
  text,
  getItems,
  className,
  onSearchChanged,
  searchPlaceholder,
  maxItemsLength,
}: FilterMultipleComboboxProps<TItem>) => {
  const {
    isOpen,
    containerMenuProps,
    getItemsMenuProps,
    getItemProps,
    popupProps,
    popupReferenceElementProps,
    inputProps,
    buttonProps,
    highlightedIndex,
    selectedItems,
  } = useMultiselectWithInnerSearchInput({
    getItems,
    selected,
    onSelectedChanged,
    onSearchChanged,
  });

  const minWidth = useFilterSelectContainerMinWidth(popupReferenceElementProps, maxItemsLength);

  return (
    <S.Wrapper className={className}>
      <S.Button opened={isOpen} {...buttonProps}>
        {text}
      </S.Button>
      <S.Container open={isOpen} {...containerMenuProps} ref={popupProps.ref} as="div" minWidth={minWidth}>
        <S.SearchContainer>
          <S.Input {...inputProps} placeholder={searchPlaceholder} />
        </S.SearchContainer>
        <S.Ul {...getItemsMenuProps()}>
          {getItems().map((item, index) => (
            <SelectPopupContainerItemJustified
              highlighted={highlightedIndex === index}
              active={selectedItems?.includes(item)}
              key={item as Key}
              {...getItemProps({ item, index })}
            >
              <span>{getItemTitle(item)}</span>
              {selectedItems?.includes(item) && <Icon as={CheckIcon} stroke="success" />}
            </SelectPopupContainerItemJustified>
          ))}
        </S.Ul>
      </S.Container>
    </S.Wrapper>
  );
};
