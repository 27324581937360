import { useCombobox, UseComboboxProps } from "downshift";

export const withComboboxCircularNavigation = <TItem>({
  circularNavigation,
  ...props
}: UseComboboxProps<TItem> & {
  circularNavigation?: boolean;
}): UseComboboxProps<TItem> => {
  if (circularNavigation !== false) {
    return props;
  }

  const stateReducer: UseComboboxProps<TItem>["stateReducer"] = (state, actionAndChanges) => {
    const changes = props.stateReducer?.(state, actionAndChanges) ?? actionAndChanges.changes;
    switch (actionAndChanges.type) {
      case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
        if (state.highlightedIndex === props.items.length - 1) {
          return { ...changes, highlightedIndex: state.highlightedIndex };
        }
        break;
      case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
        if (state.highlightedIndex === 0) {
          return { ...changes, highlightedIndex: state.highlightedIndex };
        }
        break;
      default:
        return changes;
    }

    return changes;
  };

  return { ...props, stateReducer };
};
