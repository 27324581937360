import { useEffect, useState } from "react";
import { clientRules, ClientSearchCheckedModel } from "../../../../client-search";
import { useAppSelector } from "../../../../common/store";
import { selectCreditExecutionCreationClientSearch } from "../../store/creditExecutionCreation.selectors";
import { CreditExecutionCreationFormStages } from "../../types/creation/CreditExecutionCreationFormStages";

const getActualStage = (clientCheck: ClientSearchCheckedModel | undefined): CreditExecutionCreationFormStages => {
  return clientCheck !== undefined && clientRules.isClientPassing(clientCheck) ? "terms" : "client";
};

export const useCreditExecutionCreationCreatingFormStage = () => {
  const { check } = useAppSelector(selectCreditExecutionCreationClientSearch);

  const [stage, setStage] = useState<CreditExecutionCreationFormStages>(getActualStage(check?.data));

  useEffect(() => {
    setStage(getActualStage(check?.data));
  }, [check]);

  return stage;
};
