import { object, date, string, ref } from "yup";

export const CalculatorValidationSchema = object({
  guaranteePeriod: object({
    startDate: date().nullable(),
    endDate: date().nullable().min(ref("startDate"), "Позже даты начала"),
  }),
  guaranteeTimeDays: string().when("guaranteePeriod", {
    is: (guaranteePeriod: { startDate: Date; endDate: Date }) =>
      !!guaranteePeriod.startDate || !!guaranteePeriod.endDate,
    then: string().nullable().required("Заполните период полностью/кол-во дней, либо оставьте поле не заполненым"),
    otherwise: string(),
  }),
});
