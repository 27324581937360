import * as S from "./WidgetCalculatorRadioButtonTextIcon.styled";
import { BaseCssProps } from "../../../../ui-kit/types";
import { LockIcon } from "../../../../ui-kit/icons";

export type WidgetCalculatorRadioButtonTextIconProps = BaseCssProps;

export const WidgetCalculatorRadioButtonTextIcon = ({ className }: WidgetCalculatorRadioButtonTextIconProps) => {
  return (
    <S.Span className={className}>
      <LockIcon />
    </S.Span>
  );
};
