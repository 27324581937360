import * as React from "react";
import { Box } from "../../../../ui-kit/containers/box";
import { ChevronRightIcon, Icon } from "../../../../ui-kit/icons";
import * as S from "./ProductDetailsAsideNavigationCard.styled";
import { ElementColor, ResponsiveStyleValue, SpaceType } from "../../../../ui-kit/types";

export interface ProductDetailsAsideNavigationCardProps extends Omit<React.ComponentProps<typeof S.Card>, "ref"> {
  isActive?: boolean;
  isSecondary?: boolean;
  backgroundColor?: ElementColor;
  arrowColor?: ElementColor;
  px?: ResponsiveStyleValue<SpaceType>;
  py?: ResponsiveStyleValue<SpaceType>;
  isFullWidth?: ResponsiveStyleValue<boolean>;
  after?: React.ReactNode;
}

export const ProductDetailsAsideNavigationCard = ({
  isActive,
  isSecondary,
  backgroundColor,
  children,
  after,
  arrowColor = "primary",
  px = "md",
  py = "sm",
  isFullWidth,
  ...rest
}: ProductDetailsAsideNavigationCardProps) => {
  return (
    <S.Card
      isActive={isActive}
      isSecondary={isSecondary}
      backgroundColor={backgroundColor}
      px={px}
      py={py}
      isFullWidth={isFullWidth}
      {...rest}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        overflow="hidden"
        gap="xs"
        flexGrow={1}
        flexShrink={1}
        pr="xs"
      >
        {children}
        {after}
      </Box>
      <Icon as={ChevronRightIcon} stroke={arrowColor} flexGrow={0} flexShrink={0} />
    </S.Card>
  );
};
