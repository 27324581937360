import Skeleton from "react-loading-skeleton";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { numberHelper } from "@sideg/helpers";
import { useBankGuaranteeDetailsLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { Text, Typography } from "../../../../../common/ui-kit/typography";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeDetailsDemandStatusType } from "../../../types/demand/BankGuaranteeDetailsDemandStatusType";
import { BankGuaranteeDetailsDemandHeaderStatusChip } from "./status/BankGuaranteeDetailsDemandHeaderStatusChip";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { DotSeparatedRowContainer } from "../../../../../common/ui-kit/containers/dot-separated-row";

export interface BankGuaranteeDetailsDemandHeaderDemandNumberProps extends BaseCssProps {
  bankTitle?: string;
  statusType?: BankGuaranteeDetailsDemandStatusType;
  statusText?: string;
  agentRewardRate?: number;
}

export const BankGuaranteeDetailsDemandHeaderDemandNumberAndStatus = ({
  bankTitle,
  statusType,
  statusText,
  agentRewardRate,
  className,
}: BankGuaranteeDetailsDemandHeaderDemandNumberProps) => {
  const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();

  return (
    <StackPanel direction="column" gap="sm" className={className} alignItems="flex-start">
      <ThemeMobileDesktopView
        desktop={
          <>
            <Text as="h3" fontWeight={700} lineHeight="defaultLineHeight" fontSize={1.5}>
              Заявка № {guaranteeId} {bankTitle ? `в ${bankTitle}` : <Skeleton width={200} />}
            </Text>
            <Box display="flex" flexDirection="row" alignItems="center" gap="sm">
              {agentRewardRate !== undefined && (
                <BankGuaranteeDetailsDemandHeaderStatusChip variant="inProcess">
                  КВ{" "}
                  {numberHelper.getFormattedDecimal(agentRewardRate, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                  %
                </BankGuaranteeDetailsDemandHeaderStatusChip>
              )}
              {statusType !== undefined && (
                <BankGuaranteeDetailsDemandHeaderStatusChip variant={statusType}>
                  {statusText}
                </BankGuaranteeDetailsDemandHeaderStatusChip>
              )}
              {statusType === undefined && <Skeleton width={150} />}
            </Box>
          </>
        }
        mobile={
          <>
            <Text as="h3" fontWeight={700} lineHeight="defaultLineHeight" fontSize={1.375}>
              {bankTitle ?? <Skeleton width={250} />}
            </Text>
            <DotSeparatedRowContainer dotColor="typography.medium">
              <Typography fontSize="fz3" lineHeight="defaultLineHeight" fontWeight={500} fontColor="typography.primary">
                Заявка № {guaranteeId}
              </Typography>
              {agentRewardRate !== undefined && (
                <Typography
                  fontSize="fz3"
                  lineHeight="defaultLineHeight"
                  fontWeight={500}
                  fontColor="typography.success"
                >
                  КВ{" "}
                  {numberHelper.getFormattedDecimal(agentRewardRate, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                  %
                </Typography>
              )}
            </DotSeparatedRowContainer>
          </>
        }
      />
    </StackPanel>
  );
};
