import { forwardRef, ReactNode } from "react";
import { Typography } from "@sideg/ui-kit/atoms/typography";
import { CheckboxSize } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";
import {
  getCheckboxLabelColor,
  getCheckboxLabelVariant,
} from "@sideg/ui-kit/atoms/checkbox/utils/getCheckboxLabelProperties";
import * as S from "@sideg/ui-kit/atoms/checkbox/components/radio/FormRadioCheckbox.styled";
import { FormRadioCheckboxIcon } from "@sideg/ui-kit/atoms/checkbox/components/radio/FormRadioCheckboxIcon";

export interface FormRadioCheckboxProps
  extends Omit<JSX.IntrinsicElements["input"], "type" | "ref" | "size" | "disabled"> {
  label: ReactNode;
  radioCheckboxSize?: CheckboxSize;
  isDisabled?: boolean;
}

export const FormRadioCheckbox = forwardRef<HTMLInputElement, FormRadioCheckboxProps>(
  ({ label, isDisabled = false, radioCheckboxSize = "default", ...rest }, ref) => {
    return (
      <S.Label isDisabled={isDisabled}>
        <S.RadioCheckboxInputContainer checkboxSize={radioCheckboxSize}>
          <S.RadioCheckboxInput
            type="radio"
            disabled={isDisabled}
            checkboxSize={radioCheckboxSize}
            ref={ref}
            {...rest}
          />
          <FormRadioCheckboxIcon radioCheckboxSize={radioCheckboxSize} isDisabled={isDisabled} />
        </S.RadioCheckboxInputContainer>
        <Typography fontColor={getCheckboxLabelColor(isDisabled)} variant={getCheckboxLabelVariant(radioCheckboxSize)}>
          {label}
        </Typography>
      </S.Label>
    );
  },
);
