import { combineReducers } from "redux";
import { creditWorkingAssetsDetailsTransferReducer } from "./transfer/creditWorkingAssetsDetailsTransfer.slice";
import { creditWorkingAssetsDetailsProductDetailsReducer } from "./product-details/creditWorkingAssetsDetailsProductDetails.slice";
import { creditWorkingAssetsDetailsActiveDemandReducer } from "./active-demand/creditWorkingAssetsDetailsActiveDemand.slice";
import { creditWorkingAssetsDetailsDemandsReducer } from "./demands/creditWorkingAssetsDetailsDemands.slice";
import { creditWorkingAssetsDetailsActiveTasksReducer } from "./active-tasks/creditWorkingAssetsDetailsActiveTasks.slice";
import { creditWorkingAssetsDetailsTasksHistoryReducer } from "./tasks-history/creditWorkingAssetsDetailsTasksHistory.slice";
import { creditWorkingAssetsDetailsDocumentsReducer } from "./documents/creditWorkingAssetsDetailsDocuments.slice";

export const creditWorkingAssetsDetailsReducer = combineReducers({
  transfer: creditWorkingAssetsDetailsTransferReducer,
  productDetails: creditWorkingAssetsDetailsProductDetailsReducer,
  activeDemand: creditWorkingAssetsDetailsActiveDemandReducer,
  demands: creditWorkingAssetsDetailsDemandsReducer,
  documents: creditWorkingAssetsDetailsDocumentsReducer,
  activeTasks: creditWorkingAssetsDetailsActiveTasksReducer,
  tasksHistory: creditWorkingAssetsDetailsTasksHistoryReducer,
});
