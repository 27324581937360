import { useField } from "formik";
import { FactoringCreationDebtorId } from "../types/FactoringCreationDebtorId";
import { useGetFieldName } from "../../../common/form";
import { FactoringCreationFormValues } from "../types/FactoringCreationFormValues";
import { FactoringCreationDebtorFormValues } from "../types/FactoringCreationDebtorFormValues";

export const useFactoringCreationDebtorField = (debtorId: FactoringCreationDebtorId) => {
  const getFieldName = useGetFieldName<FactoringCreationFormValues>();

  return useField<FactoringCreationDebtorFormValues | undefined>(`${getFieldName("debtors")}.${debtorId}`);
};
