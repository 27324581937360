import { useEffect, useRef } from "react";
import { useBankGuaranteeDetailsFilterContext } from "./context/useBankGuaranteeDetailsFilterContext";
import { useBankGuaranteeDetailsFilterApiContext } from "./context/useBankGuaranteeDetailsFilterApiContext";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDetailsTasksHistory } from "../../store/tasks-history/bankGuaranteeDetailsTasksHistory.selectors";
import { isSucceeded } from "../../../../common/types/state";
import { useBankGuaranteeDetailsFilterIsQueueTaskIdOnPage } from "./useBankGuaranteeDetailsFilterIsQueueTaskIdOnPage";

// 1. Если задача есть на странице (в активных или в истории) - навешиваем в url нужный контейнер для скролла.
// Это позволяет навесить якорь при первом открытии страницы с queueTaskId в searchQuery, и при появлении задачи на странице.
// 2. Если задачи нет на странице - предоставляем ref-якорь, который нужно повесить на элемент внизу страницы.
// После каждой успешной загрузки страницы проверяем есть ли элемент на странице. Если нет - скроллим к якорю, тем самым
// вызывая бесконечную прокрутку, и триггерим загрузку новой страницы.
export const useBankGuaranteeDetailsFilterScrollToQueueTask = () => {
  const scrollAnchorRef = useRef<HTMLDivElement | null>(null);

  const filteringObject = useBankGuaranteeDetailsFilterContext();
  const filteringObjectRef = useRef(filteringObject);
  filteringObjectRef.current = filteringObject;

  const mutator = useBankGuaranteeDetailsFilterApiContext();
  const mutatorRef = useRef(mutator);
  mutatorRef.current = mutator;

  const { status } = useAppSelector(selectBankGuaranteeDetailsTasksHistory);

  const isQueueTaskIdOnPage = useBankGuaranteeDetailsFilterIsQueueTaskIdOnPage(filteringObject.queueTaskId);

  useEffect(() => {
    if (isSucceeded(status) && filteringObject.queueTaskId !== undefined && !isQueueTaskIdOnPage) {
      scrollAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isQueueTaskIdOnPage, filteringObject.queueTaskId, status]);

  useEffect(() => {
    const filter = filteringObjectRef.current;
    if (isQueueTaskIdOnPage) {
      mutatorRef.current(filter, (service) => service.scrollToActiveQueueTaskId());
    }
  }, [isQueueTaskIdOnPage]);

  return { bottomAnchorRef: scrollAnchorRef };
};
