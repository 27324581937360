import { useMemo } from "react";
import { NavigationBrandWithBreadcrumbs } from "../../../../common/ui/navigation";
import { useAppLayoutHeaderActionEffect } from "../../../../common/ui/layout/app-layout-containers";
import { NavigationStateFrom } from "../../../../common/navigation/navigation-state/types/NavigationStateFrom";

export interface CreditWorkingAssetsDetailsNavigationHeaderDesktopProps {
  from: NavigationStateFrom;
}

export const CreditWorkingAssetsDetailsNavigationHeaderDesktop = ({
  from: { backTitle, url },
}: CreditWorkingAssetsDetailsNavigationHeaderDesktopProps) => {
  const component = useMemo(
    () => <NavigationBrandWithBreadcrumbs title={backTitle ?? ""} url={url} />,
    [backTitle, url]
  );

  useAppLayoutHeaderActionEffect(component);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
