import * as React from "react";
import Skeleton from "react-loading-skeleton";
import { ProductCreationBriefItem } from "./ProductCreationBriefItem";
import { BaseCssProps } from "../../../ui-kit/types";

export interface ProductCreationBriefItemOrSkeletonProps extends BaseCssProps {
  lines?: number;
  isLoading?: boolean;
  title: string;
  description: React.ReactNode | undefined | null;
}

export const ProductCreationBriefItemOrSkeleton = ({
  lines = 1,
  isLoading,
  title,
  description,
  className,
}: ProductCreationBriefItemOrSkeletonProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(isLoading || description) && (
        <ProductCreationBriefItem
          className={className}
          title={title}
          description={isLoading ? <Skeleton count={lines} /> : description}
        />
      )}
    </>
  );
};
