import * as React from "react";
import { BaseCssProps } from "../../../../../../../ui-kit/types";
import { Box } from "../../../../../../../ui-kit/containers/box";
import { Text } from "../../../../../../../ui-kit/typography";
import { BoxProps } from "../../../../../../../ui-kit/containers/box/components/Box";

export interface ProductListCardDetailsClientDesktopProps extends BaseCssProps {
  name?: React.ReactNode;
  taxpayerNumber?: React.ReactNode;
  pb?: BoxProps["pb"];
}

export const ProductListCardDetailsClientDesktop = ({
  className,
  name,
  taxpayerNumber,
  pb,
}: ProductListCardDetailsClientDesktopProps) => {
  return (
    <Box pb={pb} display="flex" flexDirection="column" gap="xs" className={className}>
      <Text fontSize={1.31} fontWeight={700} lineHeight="defaultLineHeight" fontColor="primary">
        {name}
      </Text>
      <Text fontSize="fz1" fontWeight={500} lineHeight="defaultLineHeight" fontColor="primaryCaption">
        ИНН {taxpayerNumber}
      </Text>
    </Box>
  );
};
