import { Theme } from "@emotion/react";
import { ThemeColorOrTransparent } from "@sideg/ui-kit/core/theme/types/colors/ThemeColor";
import { ThemePaletteColors } from "@sideg/ui-kit/core/theme/types/colors/ThemePalette";

export const getText = (
  error: boolean | undefined,
  disabled: boolean | undefined,
  isSelected: boolean | undefined,
): ThemePaletteColors => {
  if (disabled) {
    if (error) {
      return "light.signal.tonal.error";
    }

    return "light.neutral.outline.secondary";
  }

  if (error) {
    return "light.signal.text.error";
  }

  if (isSelected) {
    return "light.neutral.text.primary";
  }

  return "light.neutral.text.quarternary";
};

export const getBackground = (
  theme: Theme,
  disabled: boolean | undefined,
  hover: boolean | undefined,
  isSelected: boolean | undefined,
): ThemeColorOrTransparent => {
  if (hover) {
    if (isSelected) {
      return theme.palette.light.primary.tonal.hover;
    }

    if (disabled) {
      return "transparent";
    }

    return theme.palette.light.primary.tonal.default;
  }

  if (isSelected) {
    return theme.palette.light.primary.tonal.pressed;
  }

  return "transparent";
};

export const getRadioButtonGroupPalette = { text: getText, background: getBackground };
