import { ComponentProps, forwardRef, MutableRefObject, ReactNode } from "react";
import { Text } from "../../../../ui-kit/typography";
import * as S from "./ProductCreationScoringPopup.styled";
import { TooltipArrow, TooltipArrowBaseProps } from "../../../../ui-kit/pop-up/tooltip";
import { getProductCreationScoringPopupColorByVariant } from "../../../types/scoring/ProductCreationScoringPopupVariant";

export interface ProductCreationScoringPopupProps
  extends Omit<ComponentProps<typeof S.PopUp>, "ref">,
    TooltipArrowBaseProps {
  children?: ReactNode;
  arrowRef: MutableRefObject<HTMLElement | null>;
}

export const ProductCreationScoringPopup = forwardRef<HTMLDivElement, ProductCreationScoringPopupProps>(
  ({ arrowData, arrowRef, placement, children, variant, ...popupProps }, ref) => {
    return (
      <S.PopUp {...popupProps} ref={ref} variant={variant}>
        <TooltipArrow
          ref={arrowRef as MutableRefObject<HTMLDivElement>}
          placement={placement}
          borderColor={(theme) => getProductCreationScoringPopupColorByVariant(theme, variant).border}
          backgroundColor={(theme) => theme.palette.background.light}
          arrowData={arrowData}
        />
        <Text as="p" fontSize="fz2" lineHeight="1.4">
          {children}
        </Text>
      </S.PopUp>
    );
  },
);
