import styled from "@emotion/styled";
import { FormFileDropInputVariant } from "../../types/FormFileInputVariant";

export const InputWrapper = styled.div<{
  variant: FormFileDropInputVariant;
  isDisabled?: boolean;
  isFocused?: boolean;
}>`
  border-radius: 4px;
  background-color: ${({ theme, variant }) =>
    variant === "error" ? theme.palette.status.errorBackground : theme.secondaryColors.primaryBackground};
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: ${({ theme }) => theme.colors.primaryLight};

  & > label {
    padding: ${({ theme }) => theme.spaces.md};
    width: 100%;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    justify-content: flex-start;
    background-color: ${({ theme, variant }) =>
      variant === "dragging" ? theme.secondaryColors.primaryBackground : "transparent"};
    border: 1px ${({ isFocused }) => (isFocused ? "solid" : "dashed")}
      ${({ theme, variant }) => (variant === "error" ? theme.palette.status.error : theme.colors.primary)};

    & > label {
      padding: ${({ theme }) => theme.spaces.lg};
    }

    &:hover {
      background-color: ${({ theme, variant, isDisabled }) =>
        // eslint-disable-next-line no-nested-ternary
        isDisabled
          ? "transparent"
          : variant === "error"
          ? theme.palette.status.errorBackground
          : theme.secondaryColors.primaryBackground};
    }

    &:focus,
    &:focus-within {
      border: 1px solid ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;
