import * as S from "./WidgetsCalculatorRadioButton.styled";
import { LockIcon } from "../../../../ui-kit/icons";

export interface WidgetsCalculatorRadioButtonProps
  extends Omit<
    JSX.IntrinsicElements["input"],
    "type" | "value" | "disabled" | "defaultValue" | "placeholder" | "className"
  > {
  value: string;
  disabled?: boolean;
  className?: string;
}

export const WidgetsCalculatorRadioButton = ({
  value,
  className,
  disabled,
  ...rest
}: WidgetsCalculatorRadioButtonProps) => {
  return (
    <S.Label className={className}>
      <input type="radio" {...rest} value={value} disabled={disabled} />
      <div>
        <LockIcon />
      </div>
    </S.Label>
  );
};
