import { useCallback } from "react";
import { BankGuaranteeId } from "../../../../common/types/demand";
import { useAppDispatch } from "../../../../common/store";
import { transferBankGuaranteeItem } from "../../store/bankGuaranteeList.thunks";

export const useBankGuaranteeListItemTransferPayloadOnClick = (guaranteeId: BankGuaranteeId) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(transferBankGuaranteeItem(guaranteeId));
  }, [dispatch, guaranteeId]);
};
