import { BankGuaranteeId, DemandId } from "../../../../common/types/demand";
import { BankGuaranteeListItemModificationType } from "../../types/bankGuaranteeListItemModificationType";

export interface BankGuaranteeListItemModificationState {
  guaranteeId?: BankGuaranteeId;
  demandId?: DemandId;
  type?: BankGuaranteeListItemModificationType;
}

export const isRejectGuaranteeBankGuaranteeListItemModification = (
  state: BankGuaranteeListItemModificationState
): state is { type: "reject-guarantee"; guaranteeId: BankGuaranteeId } => {
  return state.type === "reject-guarantee" && state.demandId === undefined && state.guaranteeId !== undefined;
};

export const isRejectDemandBankGuaranteeListItemModification = (
  state: BankGuaranteeListItemModificationState
): state is { type: "reject-demand"; guaranteeId: BankGuaranteeId; demandId: DemandId } => {
  return state.type === "reject-demand" && state.demandId !== undefined && state.guaranteeId !== undefined;
};

export const isReissueGuaranteeBankGuaranteeListItemModification = (
  state: BankGuaranteeListItemModificationState
): state is { type: "reissue-guarantee"; guaranteeId: BankGuaranteeId; demandId: DemandId } => {
  return state.type === "reissue-guarantee" && state.demandId !== undefined && state.guaranteeId !== undefined;
};

export const isRequestOriginalGuaranteeBankGuaranteeListItemModification = (
  state: BankGuaranteeListItemModificationState
): state is { type: "request-original-guarantee"; guaranteeId: BankGuaranteeId; demandId: DemandId } => {
  return state.type === "request-original-guarantee" && state.demandId !== undefined && state.guaranteeId !== undefined;
};
