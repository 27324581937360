import { useEffect, useMemo } from "react";
import * as React from "react";
import { useInfiniteScroll } from "../hooks/useInfiniteScroll";

export interface InfiniteScrollProps {
  initialPage?: number;
  hasMore: boolean;
  loading: boolean;
  endMessage?: React.ReactNode;
  loader?: React.ReactNode;
  children?: React.ReactNode;
  onPageChanged?: () => void | Promise<void>;
  className?: string;
  reversed?: boolean;
  rootMargin?: number;
}

export const InfiniteScroll = React.forwardRef<HTMLDivElement, InfiniteScrollProps>(
  (
    { hasMore, loading, initialPage, loader, endMessage, children, onPageChanged, className, reversed, rootMargin },
    ref
  ) => {
    const [page, observerRef] = useInfiniteScroll(hasMore, loading, { initialPage, rootMargin, reversed });

    useEffect(() => {
      if (page !== initialPage) {
        onPageChanged?.();
      }
    }, [initialPage, onPageChanged, page]);

    const nodes = useMemo<{ id: number; node: React.ReactNode }[]>(() => {
      const arr = [
        children,
        hasMore && !loading && <div ref={observerRef} />,
        loading && loader,
        !hasMore && !loading && endMessage,
      ];

      return (reversed ? arr.reverse() : arr).map((x, i) => ({ id: i, node: x }));
    }, [children, endMessage, hasMore, loader, loading, observerRef, reversed]);

    return (
      <div className={className} ref={ref}>
        {nodes.map((x) => (
          <React.Fragment key={x.id}>{x.node}</React.Fragment>
        ))}
      </div>
    );
  }
);
