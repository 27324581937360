import styled from "@emotion/styled";
import { StyledNavigationButton } from "./StyledNavigationButton";

export const StyledNavigationButtonWithNotificationDot = styled(StyledNavigationButton)<{
  withNotification?: boolean;
}>`
  &::after {
    content: "";
    display: ${(props) => (props.withNotification ? "block" : "none")};
    position: absolute;
    top: -2px;
    left: 55%;
    background-color: ${({ theme }) => theme.secondaryColors.notificationBackground};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.layout.header.backgroundColor};
    width: 12px;
    height: 12px;
    font-family: ${({ theme }) => theme.font.fontFamily};
  }
`;
