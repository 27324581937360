import { getEmptyArray } from "@sideg/helpers";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import * as S from "./NewsListItems.styled";
import { NewsCardSkeleton } from "../card/NewsCard.skeleton";

const skeletonItems = getEmptyArray(10);

export type NewsListItemsSkeletonProps = BaseCssProps;

export const NewsListItemsSkeleton = ({ className }: NewsListItemsSkeletonProps) => {
  return (
    <S.Container className={className}>
      {skeletonItems.map((x) => (
        <NewsCardSkeleton key={x} />
      ))}
    </S.Container>
  );
};
