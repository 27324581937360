import { useCallback } from "react";
import { InferType } from "yup";
import { FormSubmitHandler } from "../../../../../common/form";
import { apiMutationDefaultErrorHandler, useApiMutationAction } from "../../../../../common/hooks";
import { useAppDispatch } from "../../../../../common/store";
import { QueueTaskId } from "../../../../../common/types/demand";
import { queueTaskClosedDemandQueueTasks } from "../../../../../demand/queue-tasks";
import { pushErrorGlobalToast } from "../../../../../global/toasts";
import { sendProjectChangesBankGuaranteeDetailsDemand } from "../../../store/bankGuaranteeDetails.thunks";
import { BankGuaranteeDetailsComplicatedTaskProjectFormValues } from "../types/BankGuaranteeDetailsComplicatedTaskProjectFormValues";
import {
  bankGuaranteeDetailsComplicatedTaskProjectContactsValidationSchema,
  getBankGuaranteeDetailsComplicatedTasksProjectValidationSchema,
} from "../validation/bankGuaranteeDetailsComplicatedTasksProjectValidationSchema";
import { isBankGuaranteeDetailsComplicatedTaskProjectDecisionChangesOffer } from "../types/BankGuaranteeDetailsComplicatedTaskProjectDecisionType";

type ContactsFormValues = InferType<typeof bankGuaranteeDetailsComplicatedTaskProjectContactsValidationSchema>;

const COMMENT_FIELD_NAME: keyof BankGuaranteeDetailsComplicatedTaskProjectFormValues = "comment";
const CONTACTS_KEYS = Object.keys(
  bankGuaranteeDetailsComplicatedTaskProjectContactsValidationSchema.getDefault(),
) as (keyof ContactsFormValues)[];

const isProjectHasChanges = (
  initialValues: BankGuaranteeDetailsComplicatedTaskProjectFormValues,
  formValues: BankGuaranteeDetailsComplicatedTaskProjectFormValues,
) => {
  if (formValues.file || (formValues.comment && formValues.comment.trim().length > 0)) {
    return true;
  }

  return CONTACTS_KEYS.some((fieldName) => initialValues[fieldName] !== formValues[fieldName]);
};

const isChangeOfferDecisionWithoutChanges = (
  initialValues: BankGuaranteeDetailsComplicatedTaskProjectFormValues,
  formValues: BankGuaranteeDetailsComplicatedTaskProjectFormValues,
) => {
  return (
    isBankGuaranteeDetailsComplicatedTaskProjectDecisionChangesOffer(formValues.decision) &&
    !isProjectHasChanges(initialValues, formValues)
  );
};

export const useBankGuaranteeDetailsComplicatedTaskProjectFormOnSubmit = (
  queueTaskId: QueueTaskId,
  schema: ReturnType<typeof getBankGuaranteeDetailsComplicatedTasksProjectValidationSchema>,
  initialValues: BankGuaranteeDetailsComplicatedTaskProjectFormValues,
): FormSubmitHandler<BankGuaranteeDetailsComplicatedTaskProjectFormValues> => {
  const dispatch = useAppDispatch();
  const { mutate } = useApiMutationAction();

  return useCallback(
    async (values, { setFieldError, setFieldTouched }) => {
      const castedValues = schema.cast(values) as BankGuaranteeDetailsComplicatedTaskProjectFormValues;

      if (isChangeOfferDecisionWithoutChanges(initialValues, castedValues)) {
        setFieldTouched(COMMENT_FIELD_NAME, true, false);
        setFieldError(COMMENT_FIELD_NAME, "Внесите правки в проект, или выберите другое действие");

        return;
      }

      const result = await mutate(
        sendProjectChangesBankGuaranteeDetailsDemand,
        {
          queueTaskId,
          decision: castedValues.decision,
          comment: castedValues.comment,
          projectFile: castedValues.file?.file,
          beneficiaryContacts: {
            phone: castedValues.beneficiaryPhoneNumber,
            additionalNumber: castedValues.beneficiaryAdditionalNumber,
            email: castedValues.beneficiaryEmail,
          },
          clientContacts: {
            phone: castedValues.clientPhoneNumber,
            additionalNumber: castedValues.clientAdditionalNumber,
            email: castedValues.clientEmail,
          },
          guaranteePrintType: castedValues.guaranteePrintType,
        },
        {
          errorHandler: async (e) => {
            const error = apiMutationDefaultErrorHandler(e) ?? "Не удалось отправить решение по задаче";
            dispatch(pushErrorGlobalToast(error));

            return error;
          },
        },
      );

      if (result) {
        dispatch(queueTaskClosedDemandQueueTasks(queueTaskId));
      }
    },
    [dispatch, initialValues, mutate, queueTaskId, schema],
  );
};
