import { useMemo } from "react";
import * as React from "react";
import { BankGuaranteeId, DemandId } from "../../../../common/types/demand";
import { AppLink } from "../../../../common/urls/internal/components";
import { useBankGuaranteeListItemTransferPayloadOnClick } from "./useBankGuaranteeListItemTransferPayloadOnClick";

export type UseBankGuaranteeListItemDemandLinkReturnType = Required<
  Pick<React.ComponentProps<typeof AppLink>, "to" | "params" | "onClick">
>;

export const useBankGuaranteeListItemDemandLink = (
  guaranteeId: BankGuaranteeId,
  demandId?: DemandId
): UseBankGuaranteeListItemDemandLinkReturnType => {
  const onClick = useBankGuaranteeListItemTransferPayloadOnClick(guaranteeId);

  return useMemo(() => {
    return {
      to: (x) => (demandId === undefined ? x.bankGuarantee.details.guarantee : x.bankGuarantee.details.demand),
      params: { guaranteeId, demandId },
      onClick,
    };
  }, [guaranteeId, demandId, onClick]);
};
