import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  creditExecutionDetailsDemandHeaderTab,
  CreditExecutionDetailsDemandHeaderTab,
} from "../../../types/demand/CreditExecutionDetailsDemandHeaderTab";
import { ProductDetailsContentHeaderLayout } from "../../../../../common/product-details";
import { CreditExecutionDetailsDemandHeaderTabs } from "./CreditExecutionDetailsDemandHeaderTabs";
import { useCreditExecutionDetailsContext } from "../../../hooks/context/useCreditExecutionDetailsContext";
import { useAppSelector } from "../../../../../common/store";
import { selectCreditExecutionDetailsDemandById } from "../../../store/demands/creditExecutionDetailsDemands.selectors";
import { CreditExecutionDetailsDemandHeaderDetails } from "./details/CreditExecutionDetailsDemandHeaderDetails";
import { CreditExecutionDetailsDemandHeaderRejectionButton } from "./CreditExecutionDetailsDemandHeaderRejectionButton";
import { selectCreditExecutionDetailsProductDetails } from "../../../store/product-details/creditExecutionDetailsProductDetails.selectors";
import { Skeleton } from "../../../../../common/ui/product/list/product-list-item/demand/ProductListItemDemandItemLayout.desktop.styled";
import { getCreditExecutionDemandChips } from "../../../utils/getCreditExecutionDemandChips";

export interface CreditExecutionDetailsDemandHeaderProps extends BaseCssProps {
  activeTab: CreditExecutionDetailsDemandHeaderTab;
  onTabClick: (tab: CreditExecutionDetailsDemandHeaderTab) => void | Promise<void>;
}

export const CreditExecutionDetailsDemandHeader = ({
  activeTab,
  onTabClick,
  className,
}: CreditExecutionDetailsDemandHeaderProps) => {
  const { creditExecutionId, demandId } = useCreditExecutionDetailsContext();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectCreditExecutionDetailsDemandById(state, demandId!));
  const { data } = useAppSelector(selectCreditExecutionDetailsProductDetails);

  return (
    <ProductDetailsContentHeaderLayout className={className}>
      <ProductDetailsContentHeaderLayout.TitleAndRejectionButtonWrapper>
        <ProductDetailsContentHeaderLayout.Title
          title={<ProductDetailsContentHeaderLayout.BankName bankName={demand?.bank.title} />}
        />
        {demand?.isActive === true && (
          <CreditExecutionDetailsDemandHeaderRejectionButton
            creditExecutionId={creditExecutionId}
            demandId={demand.demandId}
            bankName={demand.bank.title}
            groupProductName={data?.groupProductName ?? creditExecutionId}
          />
        )}
      </ProductDetailsContentHeaderLayout.TitleAndRejectionButtonWrapper>

      <ProductDetailsContentHeaderLayout.HeaderChips>
        {demand ? (
          getCreditExecutionDemandChips(demand).map((x) => (
            <ProductDetailsContentHeaderLayout.HeaderChip variant={x.type} key={x.type + x.text}>
              {x.text}
            </ProductDetailsContentHeaderLayout.HeaderChip>
          ))
        ) : (
          <Skeleton height={28} width={200} />
        )}
      </ProductDetailsContentHeaderLayout.HeaderChips>

      <CreditExecutionDetailsDemandHeaderDetails />
      <CreditExecutionDetailsDemandHeaderTabs.Container activeTab={activeTab} onTabChange={onTabClick} gap="lg">
        {creditExecutionDetailsDemandHeaderTab.values.map((x) => (
          <CreditExecutionDetailsDemandHeaderTabs.Tab
            key={x}
            tab={x}
            xsFlexBasis="auto"
            fontSize="fz3"
            text={creditExecutionDetailsDemandHeaderTab.getTitle(x)}
          />
        ))}
      </CreditExecutionDetailsDemandHeaderTabs.Container>
    </ProductDetailsContentHeaderLayout>
  );
};
