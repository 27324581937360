import { useMemo } from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditExecutionId } from "../../../../../common/types/demand";
import { useCreditExecutionCreationScoring } from "../../../hooks/scoring/useCreditExecutionCreationScoring";
import { useCreditExecutionCreationSubmitAfterScoring } from "../../../hooks/creation/useCreditExecutionCreationSubmitAfterScoring";
import { CreditExecutionCreationScoringForm } from "../CreditExecutionCreationScoringForm";
import { CreditExecutionCreationScoringCreateByExistsFormContent } from "./CreditExecutionCreationScoringCreateByExistsFormContent";
import {
  createByExistsCreditExecutionCreation,
  scoringByExistsCreditExecutionCreation,
} from "../../../store/creditExecutionCreation.thunks";

export interface CreditExecutionCreationScoringCreateByExistsFormProps extends BaseCssProps {
  creditExecutionId: CreditExecutionId;
  creditSum?: number;
}

export const CreditExecutionCreationScoringCreateByExistsForm = ({
  creditExecutionId,
  creditSum,
  className,
}: CreditExecutionCreationScoringCreateByExistsFormProps) => {
  const inputModel = useMemo(() => ({ creditExecutionId }), [creditExecutionId]);
  useCreditExecutionCreationScoring(scoringByExistsCreditExecutionCreation, inputModel);

  const { onSubmit } = useCreditExecutionCreationSubmitAfterScoring(createByExistsCreditExecutionCreation, inputModel);

  return (
    <CreditExecutionCreationScoringForm className={className} onSubmit={onSubmit}>
      <CreditExecutionCreationScoringCreateByExistsFormContent creditSum={creditSum} />
    </CreditExecutionCreationScoringForm>
  );
};
