import { FormEventHandler } from "react";
import { useElementId } from "@sideg/ui-kit/atoms/form-controls/common/hooks";
import { BaseFormElementErrorProps, BaseFormInputElement } from "../../../common/components";
import { useSelectPopupMemo } from "../../hooks/useSelectPopup";
import * as S from "./FormSelect.desktop.styled";
import { CheckIcon, ChevronLeftIcon } from "../../../../icons";
import { BaseCssProps } from "../../../../types";

export interface FormSelectDesktopProps<TItem extends string> extends BaseFormElementErrorProps, BaseCssProps {
  label?: string;
  name?: string;
  id?: string;
  items: TItem[];
  selectedItem: TItem;
  getItemTitle: (item: TItem) => string;
  onChange: (selectedItem: TItem) => void | Promise<void>;
  onBlur?: FormEventHandler;
  isItemDisabled?: (item: TItem) => boolean;
  disabled?: boolean;
}

export const FormSelectDesktop = <TItem extends string>({
  label,
  items,
  selectedItem,
  id,
  error,
  errorDataTestId,
  name,
  className,
  onChange,
  getItemTitle,
  onBlur,
  isItemDisabled,
  disabled,
}: FormSelectDesktopProps<TItem>) => {
  const elementId = useElementId("select", id, name);

  const { isOpen, highlightedIndex, getItemProps, floatingProps, referenceProps } = useSelectPopupMemo({
    items,
    selectedItem,
    id: elementId,
    onSelectedItemChange: (e) => {
      if (e.selectedItem) {
        onChange(e.selectedItem);
      }
    },
    isItemDisabled: (item: TItem) => isItemDisabled?.(item) ?? false,
    circularNavigation: true,
    position: "bottom",
    offset: [0, 4],
  });

  return (
    <BaseFormInputElement
      error={error}
      errorDataTestId={errorDataTestId}
      label={label}
      labelFor={referenceProps.id}
      className={className}
      disabled={disabled}
    >
      <S.Button
        onBlur={onBlur}
        type="button"
        name={name}
        {...referenceProps}
        // active={isOpen}
        disabled={disabled}
      >
        <span>{getItemTitle(selectedItem)}</span>
        <ChevronLeftIcon />
      </S.Button>
      <S.Ul {...floatingProps} isOpen={isOpen}>
        {isOpen &&
          items.map((item, index) => {
            return (
              <S.Li
                selected={selectedItem === item}
                key={item}
                highlighted={index === highlightedIndex}
                disabled={isItemDisabled?.(item) ?? false}
                {...getItemProps({ item, index })}
              >
                {selectedItem === item && <CheckIcon />}
                {getItemTitle(item)}
              </S.Li>
            );
          })}
      </S.Ul>
    </BaseFormInputElement>
  );
};
