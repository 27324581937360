import { PropsWithChildren } from "react";
import { Box } from "../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../common/ui-kit/types";

export interface UserProfileInfoWrapperProps extends PropsWithChildren<BaseCssProps> {}

export const UserProfileInfoWrapper = ({ children, className }: UserProfileInfoWrapperProps) => {
  return (
    <Box className={className} display="flex" flexDirection="column" gap="lg">
      {children}
    </Box>
  );
};
