import { Navigate, useLocation } from "react-router-dom";
import { AppUrlSelector } from "../types";
import { ExtractRouteParams, generatePath } from "../utils";

export interface RedirectProps<TUrl extends string> {
  to: AppUrlSelector<TUrl, never> | string;
  params?: ExtractRouteParams<TUrl>;
  withSearch?: boolean;
}

export const Redirect = <TUrl extends string>({ to, params, withSearch }: RedirectProps<TUrl>) => {
  const { search } = useLocation();

  return (
    <Navigate
      to={typeof to === "string" ? to : { pathname: generatePath(to, params), search: withSearch ? search : undefined }}
      replace
    />
  );
};
