import { QueueTaskId } from "../../../../common/types/demand";
import { RootState, useAppDispatch, useAppSelector } from "../../../../common/store";
import { CodeWithTitle } from "../../../../common/types/objects";
import { QueueTasksListItemState } from "../../store/queueTasksList.types";
import { ButtonLink } from "../../../../common/ui-kit/buttons/ButtonLink";
import { isLoading } from "../../../../common/types/state";
import { completeQueueTask } from "../../../common/store";
import { DotsLoader } from "../../../../common/ui-kit/loaders/DotsLoader";

export interface QueueTasksListItemActionButtonProps {
  queueTaskId: QueueTaskId;
  entitySelector: (state: RootState, queueTaskId: QueueTaskId) => QueueTasksListItemState | undefined;
  action: CodeWithTitle;
}

export const QueueTasksListItemActionButton = ({
  queueTaskId,
  entitySelector,
  action: { code, title },
}: QueueTasksListItemActionButtonProps) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { action } = useAppSelector((state) => entitySelector(state, queueTaskId))!;
  const isActionLoading = isLoading(action?.status);
  const onClick = () => {
    dispatch(completeQueueTask({ queueTaskId, decisionCode: code }));
  };

  return (
    <ButtonLink onClick={onClick} type="button" disabled={isActionLoading}>
      {title} {isActionLoading && <DotsLoader />}
    </ButtonLink>
  );
};
