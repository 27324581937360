import * as React from "react";
import { BaseCssProps } from "../../../../../ui-kit/types";
import * as S from "./FullPageContainerCenteredFooter.styled";

export interface FullPageContainerCenteredFooterProps extends BaseCssProps, S.FooterCenteredContainerProps {
  children: React.ReactNode;
}

export const FullPageContainerCenteredFooter = React.memo(
  ({ children, className, alignItems = "stretch" }: FullPageContainerCenteredFooterProps) => {
    return (
      <S.Footer className={className}>
        <S.FooterCenteredContainer alignItems={alignItems}>{children}</S.FooterCenteredContainer>
      </S.Footer>
    );
  }
);
