import * as React from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import * as S from "./BankGuaranteeDetailsIssueDetailsItem.styled";

export interface BankGuaranteeDetailsIssueDetailsItemProps extends BaseCssProps {
  term: React.ReactNode;
  details?: React.ReactNode;
}

export const BankGuaranteeDetailsIssueDetailsItem = React.memo(
  ({ term, details, className }: BankGuaranteeDetailsIssueDetailsItemProps) => {
    return (
      <S.Wrapper className={className}>
        <dt>{term}</dt>
        <dd>{details}</dd>
      </S.Wrapper>
    );
  }
);
