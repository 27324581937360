import { useMemo } from "react";
import { BankCreationModelWithCreditSum } from "../../../types/objects";
import { getFormattedNumberTransformer } from "../../../validation";

export const useCreationScoringTotalSum = (banks: Record<string, BankCreationModelWithCreditSum>): number => {
  return useMemo(() => {
    if (banks === undefined) {
      return 0;
    }

    return Object.values(banks).reduce((accum: number, bank) => {
      const creditSum = bank?.creditSum;

      return typeof creditSum === "number" ? accum + creditSum : accum + getFormattedNumberTransformer()(0, creditSum);
    }, 0);
  }, [banks]);
};
