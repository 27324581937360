import { useFormikContext } from "formik";
import { useDemandActiveTaskDecision } from "projects/agent/src/domains/demand/common/hooks/useDemandActiveTaskDecision";
import { BlockResponsiveSubmitButton } from "../../../../common/ui-kit/buttons/block-responsive-submit";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { DemandQueueTaskActiveDecision } from "../../types/active/DemandQueueTaskActiveDecision";

export interface DemandQueueTaskActiveFormSubmitButtonProps extends BaseCssProps {
  decisions: DemandQueueTaskActiveDecision[];
}

export const DemandQueueTaskActiveFormSubmitButton = ({
  className,
  decisions,
}: DemandQueueTaskActiveFormSubmitButtonProps) => {
  const { isSubmitting } = useFormikContext();
  const submitDecision = useDemandActiveTaskDecision(decisions);

  return (
    <BlockResponsiveSubmitButton type="submit" className={className} isLoading={isSubmitting}>
      {submitDecision}
    </BlockResponsiveSubmitButton>
  );
};
