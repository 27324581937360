import * as S from "./QueueTasksNotificationProductWrapper.styled";
import { QueueTasksListItemProduct } from "../../../../common/types/queue-tasks";
import { QueueTasksNotificationProduct } from "./QueueTasksNotificationProduct";

interface QueueTasksNotificationProductWrapperProps {
  product: QueueTasksListItemProduct;
}

export const QueueTasksNotificationProductWrapper = ({ product }: QueueTasksNotificationProductWrapperProps) => {
  return (
    <S.Container>
      {product.guarantee && (
        <QueueTasksNotificationProduct
          id={product.productId}
          dateAdded={product.dateAdded}
          title={product.guarantee.guaranteeType.title}
          clientTaxpayerNumber={product.guarantee.clientTaxpayerNumber}
          clientName={product.guarantee.clientName}
          bankName={product.guarantee.bankName}
        />
      )}
      {product.creditExecution && (
        <QueueTasksNotificationProduct
          id={product.groupProductName ?? product.productId}
          dateAdded={product.dateAdded}
          title={product.title}
          clientTaxpayerNumber={product.creditExecution.clientTaxpayerNumber}
          clientName={product.creditExecution.clientName}
          bankName={product.creditExecution.bankName}
        />
      )}
      {product.creditWorkingAssets && (
        <QueueTasksNotificationProduct
          id={product.groupProductName ?? product.productId}
          dateAdded={product.dateAdded}
          title={product.title}
          clientTaxpayerNumber={product.creditWorkingAssets.clientTaxpayerNumber}
          clientName={product.creditWorkingAssets.clientName}
          bankName={product.creditWorkingAssets.bankName}
        />
      )}
    </S.Container>
  );
};
