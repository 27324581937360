import { useSearchParamsOnce } from "../../../common/filters";
import { publicUserResetPasswordTokenValidationSchema } from "../validation/publicUserResetPasswordTokenValidationSchema";
import { PublicUserResetPasswordApprove } from "../types/PublicUserResetPasswordApprove";
import { PublicUserResetPasswordPageType } from "../types/PublicUserResetPasswordPageType";

export const usePublicUserResetPasswordPageType = (): Partial<PublicUserResetPasswordApprove> & {
  type: PublicUserResetPasswordPageType;
} => {
  const searchParams = useSearchParamsOnce(publicUserResetPasswordTokenValidationSchema, {
    isNeedValidateBeforeCast: true,
  });

  return { type: searchParams.token !== undefined ? "approve" : "initial", token: searchParams.token };
};
