import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  selectCreditWorkingAssetsDetailsTasksHistory,
  selectCreditWorkingAssetsDetailsTasksHistoryIds,
} from "../../../store/tasks-history/creditWorkingAssetsDetailsTasksHistory.selectors";
import { useCreditWorkingAssetsDetailsContext } from "../../../hooks/context/useCreditWorkingAssetsDetailsContext";
import { CreditWorkingAssetsDetailsTasksHistoryListItem } from "./CreditWorkingAssetsDetailsTasksHistoryListItem";
import { DemandQueueTaskInfinityScroll } from "../../../../../demand/queue-tasks";

export type CreditWorkingAssetsDetailsTasksHistoryProps = BaseCssProps;

export const CreditWorkingAssetsDetailsTasksHistory = ({ className }: CreditWorkingAssetsDetailsTasksHistoryProps) => {
  const { demandId } = useCreditWorkingAssetsDetailsContext();

  return (
    <DemandQueueTaskInfinityScroll
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      demandId={demandId!}
      idsSelector={selectCreditWorkingAssetsDetailsTasksHistoryIds}
      paginatedStateSelector={selectCreditWorkingAssetsDetailsTasksHistory}
      className={className}
    >
      {(x) => <CreditWorkingAssetsDetailsTasksHistoryListItem queueTaskId={x} />}
    </DemandQueueTaskInfinityScroll>
  );
};
