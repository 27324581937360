import { useMemo } from "react";
import { BankGuaranteeListFilterParams } from "../../types/filters/BankGuaranteeListFilterParams";
import { FilteringArrayKeys } from "../../../../common/filters";
import { RootState, useAppSelector } from "../../../../common/store";
import { BankGuaranteeFiltersLists } from "../../types/filters/BankGuaranteeFiltersLists";
import { useBankGuaranteeListFilterApiContext } from "../filter-context/useBankGuaranteeListFilterApiContext";
import { selectBankGuaranteeListFilters } from "../../store/filters/bankGuaranteeListFilters.selectors";

export interface UseBankGuaranteeListFilterArrayArgs<
  TKey extends FilteringArrayKeys<BankGuaranteeListFilterParams>,
  TItem extends BankGuaranteeListFilterParams[TKey] extends (infer U)[] | undefined ? U : never
> {
  filteringObject: BankGuaranteeListFilterParams;
  key: TKey;
  itemsSelector: (state: RootState) => TItem[];
  getTitleFunc: (filters: BankGuaranteeFiltersLists | undefined) => (item: TItem) => string;
}

export interface UseBankGuaranteeListFilterArrayReturnType<TItem> {
  getItems: () => TItem[];
  selectedItems: TItem[];
  getItemTitle: (item: TItem) => string;
  onSelectedChange: (selectedItems: TItem[]) => void | Promise<void>;
}

export const useBankGuaranteeListFilterArray = <
  TKey extends FilteringArrayKeys<BankGuaranteeListFilterParams>,
  TItem extends BankGuaranteeListFilterParams[TKey] extends (infer U)[] | undefined ? U : never
>({
  filteringObject,
  key,
  itemsSelector,
  getTitleFunc,
}: UseBankGuaranteeListFilterArrayArgs<TKey, TItem>): UseBankGuaranteeListFilterArrayReturnType<TItem> => {
  const mutator = useBankGuaranteeListFilterApiContext();

  const { data: filters } = useAppSelector(selectBankGuaranteeListFilters);
  const items = useAppSelector(itemsSelector);

  return useMemo(() => {
    const getItemTitle = (item: TItem) => getTitleFunc(filters)(item);
    const getItems = () => items;

    const onSelectedChange = (selectedItems: TItem[]) => {
      mutator(filteringObject, (filter, { setValue }) =>
        setValue(filter, key, selectedItems as BankGuaranteeListFilterParams[typeof key])
      );
    };

    return {
      getItems,
      getItemTitle,
      selectedItems: (filteringObject[key] as TItem[]) ?? [],
      onSelectedChange,
    };
  }, [filteringObject, filters, getTitleFunc, items, key, mutator]);
};
