import { RawApiResponse } from "../../../api/types/RawApiResponse";
import { QueueTasksNeedUpdatePayload } from "../../../types/queue-tasks";
import { useHubEvent } from "../../../hubs";
import { queueTasksHubConnection } from "../api/queueTasksHubConnection";
import { authenticationService } from "../../../authentication";

const USER_DISABLE_TASK_TYPE = "UserDisable";

const userDisableEventHandler = async (payload: RawApiResponse<QueueTasksNeedUpdatePayload>) => {
  if (
    payload.body.productType === "AgencyRegistration" &&
    payload.body.queueTask?.taskType.code === USER_DISABLE_TASK_TYPE
  ) {
    await authenticationService.logout();
  }
};

export const useQueueTasksHubLogOutNeedUpdateListener = () => {
  useHubEvent(queueTasksHubConnection, "NeedUpdate", userDisableEventHandler);
};
