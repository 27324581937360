import { useTheme, useResponsiveRange } from "@sideg/ui-kit";
import { QueueTasksListHeaderFilterBadges } from "../filters/QueueTasksListHeaderFilterBadges";
import { QueueTasksListFilterParams, queueTasksListOrdering } from "../../types/filter";
import { QueueTaskListTabFilter } from "../filters/QueueTaskListTabFilter";
import { useAppSelector } from "../../../../common/store";
import { selectQueueTasksList, selectQueueTasksListFilters } from "../../store/queueTasksList.selectors";
import { QueueTasksListQueryStateMutator } from "../../hooks/useQueueTasksListQueryStateMutator";
import { QueueTasksListHasRealtimeUpdates } from "../QueueTasksListHasRealtimeUpdates";
import { QueueTasksListCreationButton } from "../creation-button/QueueTasksListCreationButton";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { QueueTasksListFilters } from "../filters/QueueTasksListFilters";
import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";
import { QueueTasksListHeaderWebPushesPrompt } from "./QueueTasksListHeaderWebPushesPrompt";
import * as S from "./QueueTasksListHeader.styled";

export interface QueueTasksListHeaderProps {
  filteringObject: QueueTasksListFilterParams;
  mutator: QueueTasksListQueryStateMutator;
  onUpdateClick: () => void | Promise<void>;
}

export const QueueTasksListHeader = ({ mutator, filteringObject, onUpdateClick }: QueueTasksListHeaderProps) => {
  const { data: filters } = useAppSelector(selectQueueTasksListFilters);
  const { hasRealtimeUpdates } = useAppSelector(selectQueueTasksList);

  const theme = useTheme();
  const { higherThan } = useResponsiveRange();

  return (
    <S.Header>
      <StackPanel
        gap={{ xs: "xxl", [theme.breakpoints.desktopBreakpoint]: "xs" }}
        direction={{ xs: "column-reverse", [theme.breakpoints.desktopBreakpoint]: "row" }}
        justifyContent={{ xs: "unset", [theme.breakpoints.desktopBreakpoint]: "space-between" }}
      >
        <AppLayoutResponsiveListPage.Title>Активные задачи</AppLayoutResponsiveListPage.Title>
        <QueueTasksListCreationButton />
        <QueueTasksListHeaderWebPushesPrompt />
      </StackPanel>
      <QueueTasksListFilters filteringObject={filteringObject} />
      {!!filters && <QueueTasksListHeaderFilterBadges filteringObject={filteringObject} />}
      <AppLayoutResponsiveListPage.TabsContainer>
        <QueueTaskListTabFilter
          filteringObject={filteringObject}
          activeTab={filteringObject.tab}
          onChange={(x) => {
            mutator(filteringObject, "add", "tab", x);
          }}
        />
        {higherThan(theme.breakpoints.desktopBreakpoint, true) && (
          <>
            {hasRealtimeUpdates && <QueueTasksListHasRealtimeUpdates onUpdateClick={onUpdateClick} />}
            {filteringObject.ordering && (
              <S.DropdownButton
                items={queueTasksListOrdering.values}
                selected={filteringObject.ordering}
                getItemTitle={queueTasksListOrdering.getTitle}
                onSelectedChanged={(x) => {
                  mutator(filteringObject, "add", "ordering", x);
                }}
                text={queueTasksListOrdering.getTitle(filteringObject.ordering)}
                maxItemsLength={queueTasksListOrdering.values.length}
                selectTextSize="fz2"
                buttonSize="small"
              />
            )}
          </>
        )}
      </AppLayoutResponsiveListPage.TabsContainer>
    </S.Header>
  );
};
