import { forwardRef, ReactNode } from "react";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { CodeWithTitle } from "../../../../../../common/types/objects";
import { getBankGuaranteeDetailsTaskTitle } from "../../../../utils/demand/tasks/getBankGuaranteeDetailsTaskTitle";
import { GuaranteeQueueTasksGroupType } from "../../../../../../common/types/queue-tasks";
import { DemandQueueTaskSeparator, DemandQueueTaskVariant } from "../../../../../../demand/queue-tasks";
import { BaseFile } from "../../../../../../common/types/files";
import { BankGuaranteeDetailsComplicatedTasksLabelWithFile } from "../../../common/components/BankGuaranteeDetailsComplicatedTasksLabelWithFile";
import { BankGuaranteeDetailsComplicatedTaskCard } from "../../../common/components/BankGuaranteeDetailsComplicatedTaskCard";
import { BankGuaranteeDetailsComplicatedTaskDateCreatedPlacement } from "../../../common/types/BankGuaranteeDetailsComplicatedTaskDateCreatedPlacement";

export interface BankGuaranteeDetailsComplicatedTaskProjectCardProps extends BaseCssProps {
  isFocused?: boolean;
  isPinned?: boolean;
  variant: DemandQueueTaskVariant;
  dateCreated?: Date;
  dateCreatedPlacement: BankGuaranteeDetailsComplicatedTaskDateCreatedPlacement;
  taskTitle: string;
  taskGroupType?: CodeWithTitle<GuaranteeQueueTasksGroupType>;
  managerComment?: ReactNode;
  file?: BaseFile;
  children: ReactNode;
}

export const BankGuaranteeDetailsComplicatedTaskProjectCard = forwardRef<
  HTMLDivElement,
  BankGuaranteeDetailsComplicatedTaskProjectCardProps
>(
  (
    {
      isFocused,
      isPinned,
      className,
      variant,
      taskGroupType,
      taskTitle,
      dateCreated,
      dateCreatedPlacement,
      managerComment,
      children,
      file,
    },
    ref,
  ) => {
    return (
      <BankGuaranteeDetailsComplicatedTaskCard
        className={className}
        variant={variant}
        title={getBankGuaranteeDetailsTaskTitle(taskTitle, taskGroupType)}
        date={dateCreatedPlacement === "card" ? dateCreated : undefined}
        ref={ref}
        isFocused={isFocused}
        isPinned={isPinned}
      >
        {managerComment}
        {file && (
          <>
            <BankGuaranteeDetailsComplicatedTasksLabelWithFile
              label="Файл проекта"
              file={file}
              date={dateCreatedPlacement === "file" ? dateCreated : undefined}
            />
            <DemandQueueTaskSeparator />
          </>
        )}
        {children}
      </BankGuaranteeDetailsComplicatedTaskCard>
    );
  },
);
