import { useEffect, useMemo, useState } from "react";
import { serviceWorkerEventsService } from "../services/serviceWorkerEventsService";
import { ServiceWorkerUpdateApiContextType } from "../types/ServiceWorkerUpdateApiContextType";
import { ServiceWorkerNeedUpdateContextType } from "../types/ServiceWorkerNeedUpdateContextType";
import { ServiceWorkerNeedForceUpdateContextType } from "../types/ServiceWorkerNeedForceUpdateContextType";

const isServiceWorkerExists = () => navigator.serviceWorker?.controller !== null;

export const useServiceWorkerContextState = (): [
  ServiceWorkerNeedUpdateContextType,
  ServiceWorkerNeedForceUpdateContextType,
  ServiceWorkerUpdateApiContextType
] => {
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | undefined>(undefined);
  const [isNeedForceUpdate, setIsNeedForceUpdate] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    const handler = (e: Event) => {
      if (serviceWorkerEventsService.isServiceWorkerEvent(e)) {
        setRegistration(e.detail);
        if (e.detail.waiting && isServiceWorkerExists()) {
          setIsNeedUpdate(true);
        }
      }
    };

    serviceWorkerEventsService.on("onRegistrationSuccess", handler);
    serviceWorkerEventsService.on("onRegistrationRegister", handler);
    serviceWorkerEventsService.on("onRegistrationUpdate", handler);

    return () => {
      serviceWorkerEventsService.off("onRegistrationSuccess", handler);
      serviceWorkerEventsService.off("onRegistrationRegister", handler);
      serviceWorkerEventsService.off("onRegistrationUpdate", handler);
    };
  }, []);

  const api: ServiceWorkerUpdateApiContextType = useMemo(() => {
    const checkUpdate: ServiceWorkerUpdateApiContextType["checkUpdate"] = async (options) => {
      setIsNeedForceUpdate(options.isNeedForceUpdate === true);
      setIsNeedUpdate(true);
      if (registration?.waiting) {
        setIsNeedUpdate(true);
      } else {
        await registration?.update();
      }
    };

    const update = async () => {
      if (registration?.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    };

    const dismiss = async () => {
      setIsNeedUpdate(false);
    };

    return { checkUpdate, update, dismiss };
  }, [registration]);

  return [isNeedUpdate, isNeedUpdate && isNeedForceUpdate, api];
};
