import styles from "./ClientFormSelectOptionWrapper.module.css";
import ClientFormSelectOption from "../../select/option/ClientFormSelectOption";
import { ClientFormSelectOpt } from "../ClientFormMultiSelect";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { useDebounce } from "../../../../../../domains/common/hooks/useDebounce";

interface ClientFormSelectOptionWrapperProps {
  title: string;
  options: ClientFormSelectOpt[];
  multiSelected: Set<string>;
  onChange: (option: string) => void;
  inputValue: string | null;
}

export const ClientFormSelectOptionWrapper = ({
  title,
  options,
  multiSelected,
  onChange,
  inputValue,
}: ClientFormSelectOptionWrapperProps) => {
  const fuse = new Fuse(options, {
    isCaseSensitive: false,
    keys: ["title"],
  });
  const [filteredResults, setFilteredResults] = useState<ClientFormSelectOpt[]>(options);
  const [debounceInputValue] = useDebounce(inputValue, 500);

  useEffect(() => {
    debounceInputValue || (debounceInputValue && debounceInputValue.trim() !== "")
      ? setFilteredResults(fuse.search(debounceInputValue).map((result) => result.item))
      : setFilteredResults(options);
  }, [debounceInputValue]);

  return !!filteredResults.length ? (
    <>
      <ul className={styles["options-title"]}>{title}</ul>
      {filteredResults.map((option, index) => (
        <ClientFormSelectOption
          key={option.value}
          index={index}
          option={option}
          selected={multiSelected.has(option.value)}
          onChange={onChange}
        />
      ))}
    </>
  ) : null;
};
