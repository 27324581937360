import { LocalStorageService } from "../../../services/local-storage";
import { JwtTokenInfo } from "../../types/jwt/JwtTokenInfo";
import { AuthenticationUserTokenDetails } from "../../types/AuthenticationUserTokenDetails";
import { RefreshToken } from "../../types/jwt/RefreshToken";
import { AccessToken } from "../../types/jwt/AccessToken";
import { jsonService } from "../../../services/json";

export abstract class BaseAuthenticationStoredTokenService<TAuthResponse> {
  protected readonly localStorage: LocalStorageService;

  protected constructor(localStorage: LocalStorageService) {
    this.localStorage = localStorage;
  }

  public saveToken(tokenInfo: TAuthResponse) {
    tokenInfo && this.localStorage.setValue(jsonService.serialize(tokenInfo));
  }

  public getJwtTokenInfo(): JwtTokenInfo | undefined {
    const serializedValue = this.localStorage.getValue();

    return serializedValue === undefined
      ? undefined
      : this.mapTokenInfoFromResponse(jsonService.deserialize<TAuthResponse>(serializedValue));
  }

  protected abstract mapTokenInfoFromResponse(tokenInfo: TAuthResponse): JwtTokenInfo;

  public getUserTokenDetails(): AuthenticationUserTokenDetails | undefined {
    const serializedValue = this.localStorage.getValue();

    return serializedValue === undefined
      ? undefined
      : this.mapUserTokenDetails(jsonService.deserialize<TAuthResponse>(serializedValue));
  }

  public abstract mapUserTokenDetails(tokenInfo: TAuthResponse): AuthenticationUserTokenDetails;

  public abstract getExpiresTime(tokenInfo: TAuthResponse): Date;

  public removeToken() {
    this.localStorage.removeItem();
  }

  public isTokenExists(): boolean {
    return this.localStorage.getValue() !== undefined;
  }

  public getRefreshToken(): RefreshToken | undefined {
    const token = this.getJwtTokenInfo();

    return token?.refreshToken;
  }

  public getAccessToken(): AccessToken | undefined {
    const token = this.getJwtTokenInfo();

    return token?.accessToken;
  }

  public getHeaderValue(): string | undefined {
    const token = this.getJwtTokenInfo();

    return token !== undefined ? `${token.tokenType} ${token.accessToken}` : undefined;
  }
}
