import { BankGuaranteeDetailsDemandReconciliationStateType } from "../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationStateType";
import { CircleIndicator } from "../../../../../../common/ui-kit/notifications/circle-indicator";
import { Icon, LikeIcon } from "../../../../../../common/ui-kit/icons";

export interface BankGuaranteeDetailsDemandReconciliationContentStateIconProps {
  state: BankGuaranteeDetailsDemandReconciliationStateType;
}

export const BankGuaranteeDetailsDemandReconciliationContentStateIcon = ({
  state,
}: BankGuaranteeDetailsDemandReconciliationContentStateIconProps) => {
  return (
    <>
      {state === "Agreement" && <CircleIndicator size={{ xs: 10, xl: 8 }} color={(theme) => theme.colors.primary} />}
      {state === "Bank" && (
        <CircleIndicator size={{ xs: 10, xl: 8 }} color={(theme) => theme.palette.typography.medium} />
      )}
      {state === "Approved" && <Icon as={LikeIcon} stroke="status.success" />}
    </>
  );
};
