import { Link } from "react-router-dom";
import { MenuItem } from "../../utils/getUserMenu";
import { useMenuItem } from "../../hooks/useMenuItem";
import { useMenuItemOpen } from "../../hooks/useMenuItemOpen";
import * as S from "./NavigationMenuUl.mobile.styled";
import { StackPanel } from "../../../../ui-kit/containers/stack-panel";
import { AsideBlockCollapseButton } from "../../../../ui/layout/sidebar";

export const NavigationMenuUlMobile = ({ items, nested = false }: { items: MenuItem[]; nested?: boolean }) => {
  return (
    <S.Ul nested={nested}>
      {items.map((x) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <MenuItemMobile key={x.title} item={x} />
      ))}
    </S.Ul>
  );
};

const MenuItemMobile = ({ item }: { item: MenuItem }) => {
  const { counter, isActive } = useMenuItem(item);
  const [open, toggleOpen] = useMenuItemOpen(isActive, false);

  return (
    <S.Li active={isActive} nestedLevel={item.nestingLevel}>
      {!item.isGroupingItem && item.url && (
        <Link to={item.url.url}>
          <StackPanel gap="sm" direction="row" alignItems="center" justifyContent="space-between">
            <span>{item.title}</span>
            {!!counter && <S.Counter>{counter}</S.Counter>}
          </StackPanel>
        </Link>
      )}
      {item.isGroupingItem && (
        <AsideBlockCollapseButton onClick={toggleOpen} active={isActive} open={open}>
          <span>{item.title}</span>
        </AsideBlockCollapseButton>
      )}
      {item.items && open && (
        <NavigationMenuUlMobile items={item.items} nested={item.items.some((x) => x.nestingLevel > 0)} />
      )}
    </S.Li>
  );
};
