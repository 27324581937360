import { useCallback } from "react";
import { useSetActualPathnameQuerySearch, filtrationService, FilteringArrayKeys } from "../../../common/filters";
import { QueueTasksListFilterParams } from "../types/filter";

export const useQueueTasksListSetValueMutator = () => {
  const setQuery = useSetActualPathnameQuerySearch();

  return useCallback(
    <TKey extends FilteringArrayKeys<QueueTasksListFilterParams>, TValue extends QueueTasksListFilterParams[TKey]>(
      filteringObject: QueueTasksListFilterParams,
      key: TKey,
      value: TValue,
    ) => {
      setQuery(filtrationService.setValues(filteringObject, { [key]: value, page: 1 }));
    },
    [setQuery],
  );
};
