import * as React from "react";
import * as S from "./CreationBlockWithTitle.styled";
import { Box } from "../../../containers/box";

export interface CreationBlockWithTitleProps extends Omit<React.ComponentProps<typeof S.Card>, "title"> {
  title: string;
  description?: React.ReactNode;
}

export const CreationBlockWithTitle = ({ title, padding, description, ...rest }: CreationBlockWithTitleProps) => {
  return (
    <Box as="section" display="flex" flexDirection="column" gap="xxl">
      <S.Title>{title}</S.Title>
      {description}
      <S.Card padding={padding ?? { xs: "md", md: "lg" }} {...rest} />
    </Box>
  );
};
