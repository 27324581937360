import { useState } from "react";
import { useCreditWorkingAssetsListFilterContext } from "../../../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useProductFilterArray } from "../../../../../../common/filters/use-cases";
import { useAppSelector } from "../../../../../../common/store";
import {
  selectCreditWorkingAssetsFiltersData,
  selectCreditWorkingAssetsListFiltersBanksCodes,
} from "../../../../store/filters/creditWorkingAssetsListFilters.selectors";
import { FilterMultipleCombobox } from "../../../../../../common/ui-kit/form-controls/select";

export type CreditWorkingAssetsListHeaderFiltersBanksDesktopProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFiltersBanksDesktop = ({
  className,
}: CreditWorkingAssetsListHeaderFiltersBanksDesktopProps) => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();
  const [inputValue, setInputValue] = useState("");

  const filteredBanks = useAppSelector((state) => selectCreditWorkingAssetsListFiltersBanksCodes(state, inputValue));

  const { getItemTitle, selectedItems, getItems, onSelectedChange, maxItemLength } = useProductFilterArray({
    filteringObject,
    key: "bankIds",
    availableFiltersSelector: selectCreditWorkingAssetsFiltersData,
    customFilteredItems: filteredBanks,
  });

  return (
    <FilterMultipleCombobox
      text="Банки"
      selectedItems={selectedItems}
      getItemTitle={getItemTitle}
      onSelectedChanged={onSelectedChange}
      onSearchChanged={setInputValue}
      searchPlaceholder="Поиска банка"
      getItems={getItems}
      className={className}
      maxItemsLength={maxItemLength}
    />
  );
};
