import styled from "@emotion/styled";
import { FormSelectMobile } from "../../../../../../common/ui-kit/form-controls/select";

const Select = styled(FormSelectMobile)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > ${Select} {
    margin-bottom: ${({ theme }) => theme.spaces.xl};
  }

  & > * + *:not(${Select}) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  }
`;
