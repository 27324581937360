import { useElementId } from "@sideg/ui-kit/atoms/form-controls/common/hooks";
import { BaseFormInputElement } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormInputElement";
import { Typography } from "@sideg/ui-kit/atoms/typography";
import { ChevronLeftIcon } from "@sideg/ui-kit/atoms/icons";
import { BaseCssProps, ControlSize } from "@sideg/ui-kit/common/types";
import { FormEventHandler } from "react";
import { BaseFormElementErrorProps } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormElementError";
import { CSSObject } from "@emotion/react";
import { SelectPopup, useSelectPopupMemo } from "@sideg/ui-kit/atoms/form-controls/select";
import { useFilterSelectContainerMinWidth } from "@sideg/ui-kit/atoms/form-controls/select/hooks/useFilterSelectContainerMinWidth";
import { getSelectTextVariant } from "@sideg/ui-kit/atoms/form-controls/select/utils/getSelectTextVariant";
import * as S from "@sideg/ui-kit/atoms/form-controls/select/components/form-select/desktop/FormSelect.desktop.styled";
import { ButtonProps } from "@sideg/ui-kit/atoms/button";

export interface FormSelectDesktopProps<TItem extends string>
  extends Omit<ButtonProps, "className" | "onChange" | "onBlur">,
    BaseFormElementErrorProps,
    BaseCssProps {
  items: TItem[];
  selectedItem: TItem;
  getItemTitle: (item: TItem) => string;
  onChange: (selectedItem: TItem) => void | Promise<void>;
  name?: string;
  id?: string;
  onBlur?: FormEventHandler;
  isItemDisabled?: (item: TItem) => boolean;
  disabled?: boolean;
  selectSize?: ControlSize;
  label?: string;
  maxSelectPopupWidth?: CSSObject["maxWidth"];
  maxItemsLength?: number;
  iconPosition?: "before" | "after";
  iconStroke?: "success" | "neutral";
}

export const FormSelectDesktop = <TItem extends string>({
  label,
  items,
  selectedItem,
  id,
  error,
  errorDataTestId,
  name,
  className,
  onChange,
  getItemTitle,
  onBlur,
  isItemDisabled,
  disabled,
  selectSize = "default",
  maxSelectPopupWidth,
  maxItemsLength,
  iconPosition,
  iconStroke,
  ...buttonProps
}: FormSelectDesktopProps<TItem>) => {
  const elementId = useElementId("select", id, name);

  const { isOpen, highlightedIndex, getItemProps, floatingProps, referenceProps, referenceElementProps } =
    useSelectPopupMemo({
      items,
      selectedItem,
      id: elementId,
      onSelectedItemChange: (e) => {
        if (e.selectedItem) {
          onChange(e.selectedItem);
        }
      },
      isItemDisabled: (item: TItem) => isItemDisabled?.(item) ?? false,
      circularNavigation: true,
      position: "bottom",
      offset: [0, 4],
    });

  const minWidth = useFilterSelectContainerMinWidth(referenceElementProps, selectSize, maxItemsLength);

  return (
    <BaseFormInputElement
      error={error}
      errorDataTestId={errorDataTestId}
      label={label}
      labelFor={referenceProps.id}
      className={className}
      disabled={disabled}
    >
      <S.Button
        {...referenceProps}
        {...buttonProps}
        opened={isOpen}
        error={error !== undefined}
        expandIconSize={selectSize}
        size={selectSize}
        onBlur={onBlur}
        type="button"
        name={name}
        disabled={disabled ?? false}
      >
        <Typography variant={getSelectTextVariant(selectSize)} fontColor="light.neutral.text.primary">
          {getItemTitle(selectedItem)}
        </Typography>
        <ChevronLeftIcon />
      </S.Button>
      {isOpen && (
        <SelectPopup>
          <SelectPopup.StyledContainer
            {...floatingProps}
            minWidth={minWidth}
            maxWidth={maxSelectPopupWidth}
            open={isOpen}
          >
            {items.map((item, index) => (
              <SelectPopup.ItemWithIcon
                size={selectSize}
                iconPosition={iconPosition}
                iconStroke={iconStroke}
                active={selectedItem === item}
                key={item}
                highlighted={index === highlightedIndex}
                {...getItemProps({ item, index })}
              >
                <SelectPopup.ItemTitle titleSize={selectSize}>{getItemTitle(item)}</SelectPopup.ItemTitle>
              </SelectPopup.ItemWithIcon>
            ))}
          </SelectPopup.StyledContainer>
        </SelectPopup>
      )}
    </BaseFormInputElement>
  );
};
