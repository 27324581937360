import { currencyHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../ui-kit/types";
import { Typography } from "../../../../ui-kit/typography";

export interface ProductCreationScoringFormTotalLabelProps extends BaseCssProps {
  actualSum: number;
  requestedSum: number;
}

export const ProductCreationScoringFormTotalLabel = ({
  requestedSum,
  actualSum,
  className,
}: ProductCreationScoringFormTotalLabelProps) => {
  return (
    <Typography
      className={className}
      fontWeight={500}
      fontSize="fz3"
      lineHeight="defaultLineHeight"
      fontColor="typography.primary"
    >
      Запрошено {currencyHelper.getCurrencyStringWithoutRubleSign(actualSum, true)} /{" "}
      {currencyHelper.getCurrencyStringWithoutRubleSign(requestedSum, true)} ₽
    </Typography>
  );
};
