import Skeleton from "react-loading-skeleton";
import { getEmptyArray } from "@sideg/helpers";
import * as S from "./BankGuaranteeRewardsBanksListFilter.desktop.styled";
import { BankGuaranteeRewardsBanksListFilterCardDesktop } from "../card/BankGuaranteeRewardsBanksListFilterCard.desktop";

const emptyRewardsFilterSkeletonArray = getEmptyArray(20);

export const BankGuaranteeRewardsBanksListFilterSkeleton = () => {
  return (
    <BankGuaranteeRewardsBanksListFilterCardDesktop>
      {emptyRewardsFilterSkeletonArray.map((i) => (
        <S.ItemWrapper key={i}>
          <Skeleton width={160} />
        </S.ItemWrapper>
      ))}
    </BankGuaranteeRewardsBanksListFilterCardDesktop>
  );
};
