import { currencyHelper, numberHelper } from "@sideg/helpers";
import { federalLawTypeWithCommerce } from "../../../common/types/demand";
import { BankModelShort, getEntitiesCodeWithTitle } from "../../../common/types/objects";
import {
  BankGuaranteeRewardOutputDto,
  BankGuaranteeRewardTermOutputDto,
} from "../api/output/GetBankGuaranteeRewardsOutputDto";
import { BankGuaranteeRewards } from "../types/BankGuaranteeReward";

const getTermsTitles = ({
  federalLaw,
  guaranteeCount,
  guaranteeSumFrom,
  guaranteeSumTo,
  guaranteeType,
  isOffer,
  sumGuaranteeMonth,
  totalCostFrom,
  guaranteeRate,
}: BankGuaranteeRewardTermOutputDto) => {
  const termsTitles = [];

  if (guaranteeType) {
    termsTitles.push(guaranteeType.title);
  }

  if (federalLaw) {
    termsTitles.push(federalLawTypeWithCommerce.getTitle(federalLaw.code));
  }

  if (guaranteeSumFrom && guaranteeSumTo) {
    termsTitles.push(
      `Сумма БГ ${currencyHelper.getCurrencyString(guaranteeSumFrom)} - ${currencyHelper.getCurrencyString(
        guaranteeSumTo,
      )}`,
    );
  } else if (guaranteeSumFrom && !guaranteeSumTo) {
    termsTitles.push(`Сумма БГ от ${currencyHelper.getCurrencyString(guaranteeSumFrom)}`);
  } else if (!guaranteeSumFrom && guaranteeSumTo) {
    termsTitles.push(`Сумма БГ до ${currencyHelper.getCurrencyString(guaranteeSumTo)}`);
  }

  if (guaranteeCount) {
    termsTitles.push(`от ${guaranteeCount} БГ в месяц`);
  }

  if (sumGuaranteeMonth) {
    termsTitles.push(`Сумма БГ за месяц от ${currencyHelper.getCurrencyString(sumGuaranteeMonth)}`);
  }

  if (totalCostFrom) {
    termsTitles.push(`Итоговая стоимость от ${currencyHelper.getCurrencyString(totalCostFrom)}`);
  }

  if (isOffer) {
    termsTitles.push(`Оферта`);
  }

  if (guaranteeRate !== undefined) {
    termsTitles.push(
      `Ставка за БГ от ${numberHelper.getFormattedDecimal(guaranteeRate, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}% годовых`,
    );
  }

  return termsTitles;
};

const mapMany = (responseItems: BankGuaranteeRewardOutputDto[]): BankGuaranteeRewards => {
  const banks = [] as BankModelShort[];

  const mappedFilteredRewards = responseItems.map((reward) => {
    banks.push(reward.bank);

    return {
      bank: reward.bank,
      terms: reward.terms.map((term) => {
        return {
          standardRate: term.standardRate,
          excessRate: term.excessRate,
          priority: term.priority,
          dateStart: term.dateStart,
          dateEnd: term.dateEnd,
          termsTitles: getTermsTitles(term),
        };
      }),
    };
  });

  return {
    rewards: mappedFilteredRewards,
    banks: getEntitiesCodeWithTitle(banks),
  };
};

export const bankGuaranteeRewardsMapper = {
  mapMany,
};
