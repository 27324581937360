import { Box } from "../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { CreditExecutionDetailsActiveTasks } from "./active-tasks/CreditExecutionDetailsActiveTasks";
import { CreditExecutionDetailsDemandNewComment } from "./CreditExecutionDetailsDemandNewComment";
import { CreditExecutionDetailsTasksHistory } from "./tasks-history/CreditExecutionDetailsTasksHistory";
import { isSucceeded } from "../../../../common/types/state";
import { useAppSelector } from "../../../../common/store";
import { selectCreditExecutionDetailsActiveDemand } from "../../store/active-demand/creditExecutionDetailsActiveDemand.selectors";
import { selectCreditExecutionDetailsActiveTasksTotal } from "../../store/active-tasks/creditExecutionDetailsActiveTasks.selectors";
import {
  selectCreditExecutionDetailsTasksHistory,
  selectCreditExecutionDetailsTasksHistoryTotal,
} from "../../store/tasks-history/creditExecutionDetailsTasksHistory.selectors";
import { ProductDetailsDemandTasksEmptyState } from "../../../../common/product-details";

export type CreditExecutionDetailsDemandProps = BaseCssProps;

export const CreditExecutionDetailsDemand = ({ className }: CreditExecutionDetailsDemandProps) => {
  const {
    fetch: { status: fetchStatus },
  } = useAppSelector(selectCreditExecutionDetailsActiveDemand);

  const { status: taskHistoryFetchStatus } = useAppSelector(selectCreditExecutionDetailsTasksHistory);

  const activeTasksCount = useAppSelector(selectCreditExecutionDetailsActiveTasksTotal);
  const historyTasksCount = useAppSelector(selectCreditExecutionDetailsTasksHistoryTotal);

  const isLoadedEmptyState =
    isSucceeded(taskHistoryFetchStatus) &&
    isSucceeded(fetchStatus) &&
    activeTasksCount === 0 &&
    historyTasksCount === 0;

  return (
    <Box display="flex" flexDirection="column" gap="lg" className={className}>
      <CreditExecutionDetailsDemandNewComment />
      <Box display="flex" flexDirection="column" gap="xxl">
        {isLoadedEmptyState && <ProductDetailsDemandTasksEmptyState />}
        <CreditExecutionDetailsActiveTasks />
        <CreditExecutionDetailsTasksHistory />
      </Box>
    </Box>
  );
};
