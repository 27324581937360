import { useCallback, useEffect, useRef, useState } from "react";
import {
  AppInfinityScroll,
  AppInfinityScrollTitles,
} from "../../../../../common/ui/product/list/infinite-scroll/components/AppInfinityScroll";
import { useAppDispatch, useAppSelector } from "../../../../../common/store";
import { useBankGuaranteeDetailsLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { DemandQueueTaskCompletedCardSkeleton } from "../../../../../demand/queue-tasks";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  selectBankGuaranteeDetailsRequestOriginalComments,
  selectBankGuaranteeDetailsRequestOriginalCommentsIds,
} from "../../../store/request-original/bankGuaranteeDetailsRequestOriginal.selectors";
import { BankGuaranteeDetailsRequestOriginalComment } from "./BankGuaranteeDetailsRequestOriginalComment";
import { getRequestOriginalCommentsBankGuaranteeDetails } from "../../../store/bankGuaranteeDetails.thunks";
import { AsyncThunkActionRequest } from "../../../../../common/types/thunks";
import { isIdle, isLoading } from "../../../../../common/types/state";

const titles: AppInfinityScrollTitles = {
  end: "Загружены все сообщения",
  hasNoLoadedItems: "Еще нет сообщений",
  error: "При получении сообщений возникла ошибка",
  nextPageLoading: "Загружаем предыдущие сообщения",
};

export type BankGuaranteeDetailsRequestOriginalCommentsProps = BaseCssProps;

export const BankGuaranteeDetailsRequestOriginalComments = ({
  className,
}: BankGuaranteeDetailsRequestOriginalCommentsProps) => {
  const dispatch = useAppDispatch();
  const { pagination, status } = useAppSelector(selectBankGuaranteeDetailsRequestOriginalComments);
  const { demandId } = useBankGuaranteeDetailsLayoutContext();

  const ids = useAppSelector(selectBankGuaranteeDetailsRequestOriginalCommentsIds);

  const [isNextPageFetching, setIsNextPageFetching] = useState(false);
  const onNextPageFetching = useCallback(() => {
    setIsNextPageFetching(true);
  }, []);

  const requestRef = useRef<AsyncThunkActionRequest<{ pageNumber: number }> | undefined>(undefined);

  useEffect(() => {
    // Если сменилась заявка, и есть активный запрос вытягивания истории задач - отменяем запрос
    if (requestRef.current && pagination === undefined) {
      requestRef.current?.abort();
    }
  }, [pagination]);

  useEffect(() => {
    if (isIdle(status)) {
      setIsNextPageFetching(true);
    }
  }, [status]);

  useEffect(() => {
    if (isNextPageFetching && !isLoading(status)) {
      requestRef.current = dispatch(
        getRequestOriginalCommentsBankGuaranteeDetails({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          demandId: demandId!,
          pageNumber: (pagination?.pageNumber ?? 0) + 1 ?? 1,
        })
      );

      setIsNextPageFetching(false);
    }
  }, [demandId, dispatch, isNextPageFetching, pagination?.pageNumber, status]);

  return (
    <AppInfinityScroll
      page={1}
      paginatedStateSelector={selectBankGuaranteeDetailsRequestOriginalComments}
      idsSelector={selectBankGuaranteeDetailsRequestOriginalCommentsIds}
      titles={titles}
      onPageChanged={onNextPageFetching}
      onErrorReloadButtonClick={onNextPageFetching}
      emptyLoader={
        <StackPanel className={className} gap="xl" direction="column">
          <DemandQueueTaskCompletedCardSkeleton />
          <DemandQueueTaskCompletedCardSkeleton />
          <DemandQueueTaskCompletedCardSkeleton />
        </StackPanel>
      }
    >
      <StackPanel gap="xl" direction="column">
        {ids.map((x) => (
          <BankGuaranteeDetailsRequestOriginalComment id={x as number} key={x} />
        ))}
      </StackPanel>
    </AppInfinityScroll>
  );
};
