import Skeleton from "react-loading-skeleton";
import { FactoringCreationFormDebtorSkeletonItem } from "./FactoringCreationFormDebtorItem.skeleton";
import { FactoringCreationFormDebtorContainer } from "./FactoringCreationFormDebtorContainer";

const SKELETON_ITEMS = [
  {
    widths: [60, "100%", 200, "100%"],
    heights: ["20px", "40px", "20px", "40px"],
  },
  {
    widths: ["90%", 40, "100%"],
    heights: [20, 20, 90],
  },
  {
    widths: ["90%", 40, "100%"],
    heights: [20, 20, 90],
  },
];

export const FactoringCreationFormDebtorSkeleton = () => {
  return (
    <FactoringCreationFormDebtorContainer>
      <Skeleton width={90} height={24} />
      {SKELETON_ITEMS.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FactoringCreationFormDebtorSkeletonItem key={index} widths={item.widths} heights={item.heights} />
      ))}
    </FactoringCreationFormDebtorContainer>
  );
};
