import { currencyHelper, dateTimeHelper, numberHelper } from "@sideg/helpers";
import { useThemeDesktopBreakpoint, useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { BankGuaranteeDetailsDemand } from "../../../../types/demand/BankGuaranteeDetailsDemand";
import { Text } from "../../../../../../common/ui-kit/typography";
import { BankGuaranteeId } from "../../../../../../common/types/demand";
import { FileDownloadLabel } from "../../../../../../files/download";
import { Grid } from "../../../../../../common/ui-kit/containers/responsive-grid";
import { BankGuaranteeDetailsIssueDetails } from "../../../issue-details/BankGuaranteeDetailsIssueDetails";
import { BankGuaranteeDetailsIssueDetailsItem } from "../../../issue-details/BankGuaranteeDetailsIssueDetailsItem";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import * as S from "./BankGuaranteeDetailsDemandHeaderIssue.styled";
import { BankGuaranteeDetailsDemandHeaderReissueButton } from "../action-buttons/BankGuaranteeDetailsDemandHeaderReissueButton";
import { BankGuaranteeExtractFromRegistryWrapper } from "../../../../../extract-from-registry";
import { BankGuaranteeDetailsDemandHeaderRequestOriginalButton } from "../action-buttons/BankGuaranteeDetailsDemandHeaderRequestOriginalButton";
import { useAppSelector } from "../../../../../../common/store";
import { selectBankGuaranteeDetailsRequestOriginal } from "../../../../store/request-original/bankGuaranteeDetailsRequestOriginal.selectors";
import { BankGuaranteeRequestOriginalLabelLink } from "../../../../../request-original";
import { ProductDetailsContentLayoutContainer } from "../../../../../../common/product-details";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";

export const BoxContainer = Box.withComponent(ProductDetailsContentLayoutContainer);

export interface BankGuaranteeDetailsDemandHeaderIssueProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demand: BankGuaranteeDetailsDemand;
}

export const BankGuaranteeDetailsDemandHeaderIssue = ({
  guaranteeId,
  demand,
  className,
}: BankGuaranteeDetailsDemandHeaderIssueProps) => {
  const isDesktop = useThemeIsDesktop();
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  const { approval, bank } = demand;
  const { details: requestOriginalDetails } = useAppSelector(selectBankGuaranteeDetailsRequestOriginal);

  return (
    <BoxContainer display="flex" flexDirection="column" gap="md" className={className}>
      <Text fontSize={1.5} lineHeight="defaultLineHeight" fontColor="primary" fontWeight={700}>
        Банковская гарантия выпущена
      </Text>
      <Text fontSize="fz3" lineHeight="defaultLineHeight" fontColor="primary" fontWeight={500}>
        по заявке {guaranteeId} в банке {bank.title},{isDesktop ? " " : <br />}
        {approval?.issueDate ? dateTimeHelper.format(new Date(approval?.issueDate), "dayFullMonthYear") : ""}
      </Text>
      {approval && (
        <Grid container columnGap="sm">
          <Grid item xs={12} {...{ [desktopBreakpoint]: 6 }}>
            <BankGuaranteeDetailsIssueDetails>
              <BankGuaranteeDetailsIssueDetailsItem term="Номер гарантии" details={approval.guaranteeNumber} />
              <BankGuaranteeDetailsIssueDetailsItem
                term="Сумма гарантии"
                details={currencyHelper.getCurrencyString(approval.guaranteeSum)}
              />
              {approval.costAgent !== undefined && (
                <BankGuaranteeDetailsIssueDetailsItem
                  term="Базовая стоимость"
                  details={currencyHelper.getCurrencyString(approval.costAgent)}
                />
              )}
              {approval.discountBank !== undefined && (
                <BankGuaranteeDetailsIssueDetailsItem
                  term="Скидка за счет банка"
                  details={currencyHelper.getCurrencyString(approval.discountBank)}
                />
              )}
              {approval.discountAgent !== undefined && (
                <BankGuaranteeDetailsIssueDetailsItem
                  term="Скидка за счет агента"
                  details={currencyHelper.getCurrencyString(approval.discountAgent)}
                />
              )}
              {approval.excessAgent !== undefined && (
                <BankGuaranteeDetailsIssueDetailsItem
                  term="Превышение агента"
                  details={currencyHelper.getCurrencyString(approval.excessAgent)}
                />
              )}
              {approval.costFact !== undefined && (
                <BankGuaranteeDetailsIssueDetailsItem
                  term="Стоимость фактическая"
                  details={currencyHelper.getCurrencyString(approval.costFact)}
                />
              )}
              {approval.rewardAgent !== undefined && (
                <BankGuaranteeDetailsIssueDetailsItem
                  term="Вознаграждение агента"
                  details={`${currencyHelper.getCurrencyString(approval.rewardAgent.sum)} 
                  (${numberHelper.getFormattedDecimal(approval.rewardAgent.rate, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}%)`}
                />
              )}
            </BankGuaranteeDetailsIssueDetails>
          </Grid>
          <Grid item xs={12} {...{ [desktopBreakpoint]: 6 }}>
            <BankGuaranteeDetailsIssueDetailsItem
              term="Дата начала"
              details={dateTimeHelper.format(new Date(approval.guaranteeDateStart), "dateOnly")}
            />
            <BankGuaranteeDetailsIssueDetailsItem
              term="Дата окончания"
              details={dateTimeHelper.format(new Date(approval.guaranteeDateEnd), "dateOnly")}
            />
            <BankGuaranteeDetailsIssueDetailsItem
              term="Дата выпуска"
              details={dateTimeHelper.format(new Date(approval.issueDate), "dateOnly")}
            />
          </Grid>
        </Grid>
      )}
      <Box
        pt="sm"
        display="flex"
        flexDirection={{ xs: "column", [desktopBreakpoint]: "row" }}
        gap="md"
        alignItems={{ xs: "flex-start", [desktopBreakpoint]: "unset" }}
      >
        {approval?.original && (
          <FileDownloadLabel
            fileId={approval?.original.fileId}
            privateKey={approval?.original.privateKey}
            fileName="Скан-копия гарантии"
          />
        )}
        {approval?.bankDocument && (
          <FileDownloadLabel
            fileId={approval?.bankDocument.fileId}
            privateKey={approval?.bankDocument.privateKey}
            fileName="Доверенность/Лицензия"
          />
        )}
        {approval?.fileRegistryStatus && (
          <BankGuaranteeExtractFromRegistryWrapper
            guaranteeId={guaranteeId}
            demandId={demand.demandId}
            statusType={approval.fileRegistryStatus.code}
          />
        )}
      </Box>
      <S.ActionButtonsContainer>
        <BankGuaranteeDetailsDemandHeaderReissueButton guaranteeId={guaranteeId} demandId={demand.demandId} />
        {!requestOriginalDetails && (
          <BankGuaranteeDetailsDemandHeaderRequestOriginalButton demandId={demand.demandId} />
        )}
        {requestOriginalDetails && (
          <BankGuaranteeRequestOriginalLabelLink
            demandId={demand.demandId}
            guaranteeId={guaranteeId}
            requestOriginalType={requestOriginalDetails.status.code}
            isWithNotification={requestOriginalDetails.hasNewChange}
          />
        )}
      </S.ActionButtonsContainer>
    </BoxContainer>
  );
};
