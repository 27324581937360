import * as React from "react";
import { Box } from "../../../../common/ui-kit/containers/box";
import { Text } from "../../../../common/ui-kit/typography";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export interface DocumentUploadActiveDocumentContainerProps extends BaseCssProps {
  title: React.ReactNode;
  badge?: React.ReactNode;
  description?: React.ReactNode;
  template?: React.ReactNode;
  oldFile?: React.ReactNode;
  children?: React.ReactNode;
  uploader?: React.ReactNode;
}

export const DocumentUploadActiveDocumentContainer = ({
  title,
  badge,
  description,
  template,
  oldFile,
  className,
  uploader,
  children,
}: DocumentUploadActiveDocumentContainerProps) => {
  return (
    <Box as="article" display="flex" flexDirection="column" gap="md" className={className}>
      <Text fontWeight={700} fontSize="fz3" lineHeight="defaultLineHeight" fontColor="primary">
        {title}
        {badge}
      </Text>
      {description !== undefined && (
        <Text fontWeight={500} fontSize="fz2" lineHeight="1.5" fontColor="primaryCaption">
          {description}
        </Text>
      )}
      {template !== undefined && (
        <Box display="flex" gap="sm" flexDirection="row" alignItems="center" py="sm">
          <Text fontWeight={500} fontSize="fz3" lineHeight="defaultLineHeight" fontColor="primary">
            Шаблон:
          </Text>
          {template}
        </Box>
      )}
      {oldFile}
      {children && (
        <Box display="flex" flexDirection="column" pt="sm">
          {children}
        </Box>
      )}
      {uploader}
    </Box>
  );
};
