import { css } from "@emotion/react";
import { Theme } from "@sideg/ui-kit";

export const getAsideListItemCommonStyles = (theme: Theme) => css`
  font-size: ${theme.font.fz4};
  line-height: ${theme.font.defaultLineHeight};

  & > span,
  & > a {
    display: block;
  }

  & > button {
    width: 100%;
  }

  & > span,
  & > button,
  & > a,
  & > a span,
  & > a button {
    color: ${theme.layout.sidebar.menu.linkColor};
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    font-weight: 400;
    font-style: normal;
    border: none;
    background-color: transparent;
    font-size: inherit;
    font-family: inherit;

    &:hover,
    &:hover * {
      color: ${theme.layout.sidebar.menu.activeLinkColor};
      text-decoration: none;
    }

    &:focus-visible {
      text-decoration: underline;
    }
  }

  & svg {
    height: 20px;
    width: 20px;

    & > path {
      stroke: ${theme.layout.sidebar.menu.linkColor};
    }
  }
`;
