import { ReactNode, memo } from "react";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps, ElementColor } from "../../../../common/ui-kit/types";
import {
  ProductListDetailsContainer,
  ProductListItemDetailsItemContainer,
} from "../../../../common/ui/product/list/container";

export interface CreditExecutionCommonDetailsContainerProps extends BaseCssProps {
  requested: ReactNode;
  confirmed: ReactNode;
  federalLaw: ReactNode;
  purchaseTerms: ReactNode;
  period: ReactNode;
  briefButton: ReactNode;
  termFontColor?: ElementColor;
}

export const CreditExecutionCommonDetailsContainer = memo(
  ({
    requested,
    period,
    federalLaw,
    purchaseTerms,
    confirmed,
    className,
    briefButton,
    termFontColor,
  }: CreditExecutionCommonDetailsContainerProps) => {
    const items = [
      { term: "Сумма контракта", value: requested },
      { term: "Одобрено", value: confirmed },
      { term: "Даты кредита", value: period },
    ];

    return (
      <>
        <ProductListDetailsContainer className={className} items={items} termFontColor={termFontColor} />
        <ProductListItemDetailsItemContainer term={federalLaw} description={purchaseTerms} />
        <ThemeMobileDesktopView mobile={null} desktop={briefButton} />
      </>
    );
  },
);
