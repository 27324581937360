import { CreditExecutionListFilterContextValue } from "../../types/filters/CreditExecutionListFilterContextValue";
import { FilterTabType } from "../../../../common/filters/use-cases";

export const isFilterAvailableForTabCreditExecution = (
  tab: FilterTabType,
  filter: keyof CreditExecutionListFilterContextValue
): boolean => {
  if (tab === "Active" && filter === "status") {
    return false;
  }

  if (tab === "Completed" && filter === "taskTypes") {
    return false;
  }

  return true;
};
