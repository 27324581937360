import { Fragment } from "react";
import styled from "@emotion/styled";

export const BaseInput = styled(Fragment)`
  display: inline-flex;
  flex: 0 1 auto;
  padding: ${({ theme }) => theme.spacing.sm};
  width: 100%;
  position: relative;
  background-color: transparent;
  border: none;
  margin: 0;
  font-family: inherit;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.light.neutral.text.primary};
  caret-color: ${({ theme }) => theme.palette.light.primary.text.default};
  appearance: none;

  &:focus {
    color: ${({ theme }) => theme.palette.light.neutral.text.primary};
  }

  &:disabled {
    color: ${({ theme }) => theme.palette.light.neutral.text.tertiary};
    -webkit-text-fill-color: ${({ theme }) => theme.palette.light.neutral.text.tertiary};
    opacity: 1;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.light.neutral.text.quarternary};
    font-weight: 500;
  }

  &:focus-visible {
    outline: none;
  }

  &[type="password"]::-ms-reveal {
    display: none;
  }

  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }
`;
