import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import { StyledContainerWithCustomScroll } from "../../../containers/components/StyledContainerWithCustomScroll";

export const SelectPopupContainer = styled(StyledContainerWithCustomScroll.withComponent("ul"))<{
  minWidth?: CSSObject["minWidth"];
  open: boolean;
}>`
  min-width: ${({ minWidth }) => minWidth ?? `300px`};
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  box-shadow: ${({ open }) => (open ? "0px 12px 24px rgba(89, 111, 136, 0.4)" : "unset")};
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.background.light};
  z-index: ${({ theme, open }) => (open ? theme.layout.zIndex + 1 : -1)};
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: ${({ open, theme }) => (open ? theme.spaces.md : "0px")};
  max-height: 400px;
  padding: ${({ theme }) => theme.spaces.xs};
`;
