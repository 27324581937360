import axios from "axios";

export const lkApiUrl = (): string => import.meta.env.VITE_LK_API_URL as string;
export const lkApiV2 = (): string => lkApiUrl() + import.meta.env.VITE_LK_API_V2_PATH;
export const lkApiV3 = (): string => lkApiUrl() + import.meta.env.VITE_LK_API_V3_PATH;

export const clientV2 = axios.create({
  baseURL: lkApiV2(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const clientV3 = axios.create({
  baseURL: lkApiV3(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const MAX_FILE_SIZE: number = 31457280;
export const MAX_FORM_SIZE: number = 188743680;
export const MAX_INTEGER: number = 2147483647;
