import { useEffect, useMemo } from "react";
import { ToastId } from "../types/ToastId";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { hideToastGlobalToasts, removeToastGlobalToasts, toastShownGlobalToasts } from "../store/globalToasts.slice";
import { selectGlobalToastById } from "../store/globalToasts.selectors";
import { ToastProps } from "../../../common/ui-kit/containers/toast";

export const useToast = (id: ToastId) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const toast = useAppSelector((state) => selectGlobalToastById(state, id))!;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toastShownGlobalToasts(id));

    setTimeout(() => {
      dispatch(hideToastGlobalToasts(id));
    }, toast.duration);
  }, [dispatch, id, toast.duration]);

  useEffect(() => {
    return () => {
      dispatch(removeToastGlobalToasts(id));
    };
  }, [dispatch, id]);

  return useMemo(
    (): ToastProps => ({
      type: toast.type,
      children: toast.text,
      onClose: () => {
        dispatch(hideToastGlobalToasts(id));
      },
    }),
    [dispatch, id, toast.text, toast.type]
  );
};
