import { AnyObjectSchema, InferType } from "yup";
import { useCallback, useMemo } from "react";
import { useSearchParamsOnce } from "../../hooks/useSearchParamsOnce";
import { usePaginatedSubsetQueryMutator } from "./usePaginatedSubsetQueryMutator";
import { PaginatedFilteringObjectType } from "../../types/PaginatedFilteringObjectType";

export const useProductFilterSubsetForm = <
  TFilter extends PaginatedFilteringObjectType,
  TSchema extends AnyObjectSchema
>(
  filteringObject: TFilter,
  schema: TSchema
) => {
  const initialValues = useSearchParamsOnce(schema);

  const mutator = usePaginatedSubsetQueryMutator();
  const applyFilters = useCallback(
    <TSubset extends Partial<TFilter> & InferType<TSchema>>(values: TSubset) => {
      mutator(filteringObject, schema, values);
    },
    [filteringObject, mutator, schema]
  );

  return useMemo(() => ({ initialValues, applyFilters }), [applyFilters, initialValues]);
};
