import { ElementColor } from "../../../common/ui-kit/types";
import { CreditWorkingAssetsDemandStageType } from "../../common/types";

export const getCreditWorkingAssetsDemandStageColorStatus = (
  stage: CreditWorkingAssetsDemandStageType,
  hasActiveTasks: boolean
): ElementColor => {
  if (stage === "Rejected") {
    return "typography.medium";
  }

  if (stage === "Approved") {
    return "status.success";
  }

  if (hasActiveTasks) {
    return "primary";
  }

  if (stage === "Confirmed") {
    return "typography.successSecondary";
  }

  return "typography.primaryCaption";
};
