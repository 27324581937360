import { ReactNode } from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { Typography } from "../../../../../ui-kit/typography";

export interface FullPageContainerHeaderTitleTypographyProps extends BaseCssProps {
  children?: ReactNode;
}

export const FullPageContainerHeaderTitleTypography = ({
  children,
  className,
}: FullPageContainerHeaderTitleTypographyProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Typography
      as="h2"
      className={className}
      fontWeight={700}
      fontSize={{ xs: "fz4", [desktopBreakpoint]: 1.25 }}
      lineHeight={{ xs: "1.2", [desktopBreakpoint]: "defaultLineHeight" }}
      textAlign={{ xs: "center", [desktopBreakpoint]: "left" }}
    >
      {children}
    </Typography>
  );
};
