import { useFormikContext } from "formik";
import { Typography } from "projects/agent/src/domains/common/ui-kit/typography";
import { BankGuaranteeDetailsDemandQueueTaskActive } from "../../../../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskActive";
import { FileUpload, FileUploadDropzoneInputBlock } from "../../../../../../../files/upload";
import { useGetFieldName } from "../../../../../../../common/form";
import { BankGuaranteeDetailsComplicatedTaskProjectFormValues } from "../../../types/BankGuaranteeDetailsComplicatedTaskProjectFormValues";
import { useHandleSingleFileFieldChange } from "../../../../../../../files/temp";
import {
  DemandQueueTaskActiveFormCommentField,
  DemandQueueTaskActiveFormDecisionsField,
} from "../../../../../../../demand/queue-tasks";
import { BlockResponsiveSubmitButton } from "../../../../../../../common/ui-kit/buttons/block-responsive-submit";
import { BankGuaranteeDetailComplicatedTaskProjectActiveContactsBlock } from "./contacts/BankGuaranteeDetailComplicatedTaskProjectActiveContactsBlock";
import { BankGuaranteeDetailsComplicatedTaskProjectActiveGuaranteePrintTypeBlock } from "./guarantee-print-type/BankGuaranteeDetailsComplicatedTaskProjectActiveGuaranteePrintTypeBlock";
import { DemandQueueTaskActiveFormAsideCommentField } from "../../../../../../../demand/queue-tasks/components/active-form/DemandQueueTaskActiveFormAsideCommentField";
import {
  isBankGuaranteeDetailsComplicatedTaskProjectDecisionChangesOffer,
  isBankGuaranteeDetailsComplicatedTaskProjectDecisionProjectAgreed,
  isBankGuaranteeDetailsComplicatedTaskProjectDecisionSelected,
} from "../../../types/BankGuaranteeDetailsComplicatedTaskProjectDecisionType";

export interface BankGuaranteeDetailComplicatedTaskProjectActiveFromContentProps {
  task: BankGuaranteeDetailsDemandQueueTaskActive;
}

export const BankGuaranteeDetailComplicatedTaskProjectActiveFormContent = ({
  task,
}: BankGuaranteeDetailComplicatedTaskProjectActiveFromContentProps) => {
  const getFieldName = useGetFieldName<BankGuaranteeDetailsComplicatedTaskProjectFormValues>();
  const { values, isSubmitting } = useFormikContext<BankGuaranteeDetailsComplicatedTaskProjectFormValues>();
  const isDisabled = isSubmitting;

  const isDecisionSelected = isBankGuaranteeDetailsComplicatedTaskProjectDecisionSelected(values.decision);
  const isProjectAgreed = isBankGuaranteeDetailsComplicatedTaskProjectDecisionProjectAgreed(values.decision);
  const isChangesOffer = isBankGuaranteeDetailsComplicatedTaskProjectDecisionChangesOffer(values.decision);

  const { file, removeFile, handleFileChange } = useHandleSingleFileFieldChange(getFieldName("file"));

  return (
    <>
      {task.decisions && <DemandQueueTaskActiveFormDecisionsField decisions={task.decisions} isDisabled={isDisabled} />}
      {isDecisionSelected && (
        <>
          <Typography fontSize="fz2" fontWeight={700} lineHeight="defaultLineHeight">
            Пожалуйста, уточните данные
          </Typography>
          {isProjectAgreed && (
            <>
              {task.projectInfo?.isNeedGuaranteePrintType && (
                <BankGuaranteeDetailsComplicatedTaskProjectActiveGuaranteePrintTypeBlock />
              )}
              {task.projectInfo?.deliveryMessage !== undefined && (
                <DemandQueueTaskActiveFormAsideCommentField comment={task.projectInfo.deliveryMessage} />
              )}
            </>
          )}
          {values.isNeedContacts && (
            <BankGuaranteeDetailComplicatedTaskProjectActiveContactsBlock
              isDisabled={isDisabled}
              actualBeneficiaryContacts={task.projectInfo?.actualBeneficiaryContacts}
              actualClientContacts={task.projectInfo?.actualClientContacts}
            />
          )}
          {isChangesOffer && (
            <>
              <DemandQueueTaskActiveFormCommentField isDisabled={isDisabled} />
              {file && <FileUpload fileName={file.file.name} date={file.dateAdded} canRemove onRemove={removeFile} />}
              <FileUploadDropzoneInputBlock
                handleFileChange={handleFileChange}
                text={values.file ? "Заменить файл проекта" : "Прикрепить файл проекта"}
                isDisabled={isDisabled}
                isMultiple={false}
              />
            </>
          )}
          <BlockResponsiveSubmitButton type="submit" isLoading={isSubmitting}>
            Отправить
          </BlockResponsiveSubmitButton>
        </>
      )}
    </>
  );
};
