import * as React from "react";
import { BaseCssProps } from "../../../../ui-kit/types";
import * as S from "./FiltersWrapperWithSearchInput.desktop.styled";

export interface FiltersWrapperWithSearchInputProps extends BaseCssProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
}

export const FiltersWrapperWithSearchInput = ({
  value,
  onChange,
  children,
  className,
}: FiltersWrapperWithSearchInputProps) => {
  return (
    <S.Container className={className}>
      <S.Search value={value} onChange={onChange} type="search" placeholder="Поиск по номеру заявки, клиенту, ИНН" />
      {children}
    </S.Container>
  );
};
