import styled from "@emotion/styled";

export const Footer = styled.footer<{ withLayoutBorder?: boolean }>`
  padding: ${({ theme, withLayoutBorder }) =>
    withLayoutBorder
      ? `${theme.layout.borderPadding} ${theme.layout.borderPadding}  0 ${theme.layout.borderPadding}`
      : `${theme.layout.borderPadding} 0 0 0`};
  margin: auto 0 0 0;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: 1.4;
  font-family: inherit;
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  padding: ${({ theme }) => theme.spaces.lg} 0;
  color: ${({ theme }) => theme.colors.secondary};
  gap: ${({ theme }) => theme.spaces.sm};

  ${({ theme }) => theme.breakpoints.mqUp("md")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
