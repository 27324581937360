import { GetCreditExecutionDetailsOutputDto } from "../../api/dto/output/GetCreditExecutionDetailsOutputDto";
import { CreditExecutionDetailsProductDetails } from "../../types/CreditExecutionDetailsProductDetails";
import { CreditExecutionDemandBase } from "../../../common/types";
import { CreditExecutionTransferPayload } from "../../../common/store";

const canRejectAll = (demands: CreditExecutionDemandBase[]): boolean => demands.some((x) => x.isActive);

const fromResponse = (response: GetCreditExecutionDetailsOutputDto): CreditExecutionDetailsProductDetails => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { missingDocuments, demands, ...rest } = response;

  return { ...rest, canRejectAll: canRejectAll(demands) };
};

const fromTransfer = (transfer: CreditExecutionTransferPayload): CreditExecutionDetailsProductDetails => {
  return { ...transfer.product, canRejectAll: canRejectAll(transfer.demands) };
};

export const creditExecutionDetailsProductDetailsMapper = {
  fromResponse,
  fromTransfer,
};
