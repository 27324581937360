import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useCreditExecutionDetailsContext } from "../../hooks/context/useCreditExecutionDetailsContext";
import { ProductDetailsDocumentsWrapper } from "../../../../common/product-details";
import { Redirect } from "../../../../common/urls/internal/components";
import { useCreditExecutionDetailsDocuments } from "../../hooks/documents/useCreditExecutionDetailsDocuments";
import { CreditExecutionDetailsDocumentsItem } from "./CreditExecutionDetailsDocumentsItem";

export type CreditExecutionDetailsDocumentsProps = BaseCssProps;

export const CreditExecutionDetailsDocuments = ({ className }: CreditExecutionDetailsDocumentsProps) => {
  const { creditExecutionId } = useCreditExecutionDetailsContext();
  const { ids, status, isNeedToHide } = useCreditExecutionDetailsDocuments();

  if (isNeedToHide) {
    return <Redirect to={(x) => x.creditExecution.details.creditExecution} params={{ creditExecutionId }} withSearch />;
  }

  return (
    <ProductDetailsDocumentsWrapper className={className} ids={ids} status={status}>
      {(x) => <CreditExecutionDetailsDocumentsItem key={x} documentId={x} />}
    </ProductDetailsDocumentsWrapper>
  );
};
