import { useCallback } from "react";
import * as React from "react";
import { AnyObjectSchema, InferType } from "yup";
import { PaginatedFilteringObjectType } from "../../../../types/PaginatedFilteringObjectType";
import { useProductFilterSubsetForm } from "../../../hooks/useProductFilterSubsetForm";
import { AppLayoutOverlayPanelContentWithHeader } from "../../../../../ui/layout/app-layout-containers";
import { FiltersMobileForm } from "../form/FiltersMobileForm";
import { Text } from "../../../../../ui-kit/typography";

export interface FiltersMobilePanelProps<
  TFilter extends PaginatedFilteringObjectType,
  TSchema extends AnyObjectSchema
> {
  filteringObject: TFilter;
  schema: TSchema;
  onCloseClick: () => void;
  title?: string;
  children: React.ReactNode;
}

export const FiltersMobilePanel = <TFilter extends PaginatedFilteringObjectType, TSchema extends AnyObjectSchema>({
  filteringObject,
  schema,
  onCloseClick,
  title = "Фильтры",
  children,
}: FiltersMobilePanelProps<TFilter, TSchema>) => {
  const { applyFilters, initialValues } = useProductFilterSubsetForm(filteringObject, schema);

  const handleSubmit = useCallback(
    <TSubset extends Partial<TFilter> & InferType<TSchema>>(values: TSubset) => {
      applyFilters(values);
      onCloseClick();
    },
    [applyFilters, onCloseClick]
  );

  return (
    <AppLayoutOverlayPanelContentWithHeader
      onCloseClick={onCloseClick}
      title={
        <Text fontColor="primaryLight" fontWeight={500} fontSize="fz4">
          {title}
        </Text>
      }
    >
      <FiltersMobileForm initialValues={initialValues} onSubmit={handleSubmit}>
        {children}
      </FiltersMobileForm>
    </AppLayoutOverlayPanelContentWithHeader>
  );
};
