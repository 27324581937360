import styled from "@emotion/styled";

export const Article = styled.article`
  background: ${({ theme }) => theme.palette.background.light};
  box-shadow: 0 4px 20px rgba(10, 71, 137, 0.25);
  border-radius: 8px;
`;

export const Header = styled.header`
  padding: ${({ theme }) => theme.spaces.md} ${({ theme }) => theme.spaces.md} 0 ${({ theme }) => theme.spaces.md};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xs};
`;

export const Separator = styled.hr`
  border: none;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  padding: 0;
  margin: 0;
  appearance: none;
`;

export const Main = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.sm};
  padding: ${({ theme }) => theme.spaces.md};
`;

export const Footer = styled.footer`
  padding: 0 ${({ theme }) => theme.spaces.md} ${({ theme }) => theme.spaces.md} ${({ theme }) => theme.spaces.md};
`;
