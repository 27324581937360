import { useCallback, useEffect, useState } from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsDetailsActiveDemand } from "../../store/active-demand/creditWorkingAssetsDetailsActiveDemand.selectors";
import { useCreditWorkingAssetsDetailsContext } from "../../hooks/context/useCreditWorkingAssetsDetailsContext";
import {
  DemandQueueTaskCompleted,
  DemandQueueTasksNewComment,
  useDemandQueueTasksNewCommentOnSubmit,
} from "../../../../demand/queue-tasks";
import { creditWorkingAssetsDetailsReloadActiveDemand } from "../../store/creditWorkingAssetsDetails.actions";
import { pushSuccessGlobalToast } from "../../../../global/toasts";
import { addNewCompletedTaskCreditWorkingAssetsDetailsTasksHistory } from "../../store/tasks-history/creditWorkingAssetsDetailsTasksHistory.slice";

export type CreditWorkingAssetsDetailsDemandNewCommentProps = BaseCssProps;

export const CreditWorkingAssetsDetailsDemandNewComment = ({
  className,
}: CreditWorkingAssetsDetailsDemandNewCommentProps) => {
  const dispatch = useAppDispatch();

  const { canSendComment } = useAppSelector(selectCreditWorkingAssetsDetailsActiveDemand);
  const { demandId } = useCreditWorkingAssetsDetailsContext();

  const [isCommentFormOpened, setIsCommentFormOpened] = useState(false);

  useEffect(() => {
    setIsCommentFormOpened(false);
  }, [demandId]);

  const onSuccess = useCallback(
    (completedQueueTask: DemandQueueTaskCompleted) => {
      dispatch(addNewCompletedTaskCreditWorkingAssetsDetailsTasksHistory(completedQueueTask));
      dispatch(creditWorkingAssetsDetailsReloadActiveDemand());
      dispatch(pushSuccessGlobalToast("Сообщение отправлено"));
      setIsCommentFormOpened(false);
    },
    [dispatch]
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const onSubmit = useDemandQueueTasksNewCommentOnSubmit(demandId!, onSuccess);

  return (
    <DemandQueueTasksNewComment
      canSendComment={canSendComment === true && !!demandId}
      className={className}
      isCommentFormOpened={isCommentFormOpened}
      onSendCommentButtonClick={() => setIsCommentFormOpened(true)}
      onSubmit={onSubmit}
      onCancel={() => setIsCommentFormOpened(false)}
    />
  );
};
