import { useCallback, useRef } from "react";
import { useBankGuaranteeDetailsFilterContext } from "../filters/context/useBankGuaranteeDetailsFilterContext";
import { DemandId } from "../../../../common/types/demand";
import { getHistoryBankGuaranteeDetailsDemand } from "../../store/bankGuaranteeDetails.thunks";
import { useBankGuaranteeDetailsLayoutContext } from "../layout/useBankGuaranteeDetailsLayoutContext";
import {
  InfiniteScrollMutationComparerResult as ComparerResult,
  UseInfiniteScrollMutationArguments,
  useInfiniteScrollMutation,
} from "../../../../common/hooks/infinite-scroll";
import { selectBankGuaranteeDetailsTasksHistory } from "../../store/tasks-history/bankGuaranteeDetailsTasksHistory.selectors";
import { GetHistoryBankGuaranteeDetailsDemandInputDto } from "../../api/dto/input/GetHistoryBankGuaranteeDetailsDemandInputDto";
import { GuaranteeQueueTasksGroupType } from "../../../../common/types/queue-tasks";

const useHook = useInfiniteScrollMutation<GetHistoryBankGuaranteeDetailsDemandInputDto>;

type HookArgs = UseInfiniteScrollMutationArguments<GetHistoryBankGuaranteeDetailsDemandInputDto>;

const map = (
  demandId: DemandId,
  groupType: GuaranteeQueueTasksGroupType,
  pageNumber: number,
): ReturnType<HookArgs["mapper"]> => ({
  demandId,
  pageNumber,
  groupType,
});

const isEqual = (
  demandId: DemandId,
  groupType: GuaranteeQueueTasksGroupType,
  prevObj: GetHistoryBankGuaranteeDetailsDemandInputDto,
  page: number,
): ReturnType<HookArgs["comparer"]> => {
  const { pageNumber: prevPage, demandId: prevDemandId, groupType: prevGroupType } = prevObj;
  const filterChanged = prevDemandId !== demandId || prevGroupType !== groupType;

  if (page === prevPage && filterChanged) {
    return ComparerResult.Filter;
  }

  if (page !== prevPage && page !== 1 && !filterChanged) {
    return ComparerResult.Page;
  }

  return ComparerResult.None;
};

export const useBankGuaranteeDetailsDemandTasksHistory = () => {
  const { demandId } = useBankGuaranteeDetailsLayoutContext();
  const demandIdRef = useRef(demandId);
  demandIdRef.current = demandId;

  const filteringObject = useBankGuaranteeDetailsFilterContext();

  const mapper: HookArgs["mapper"] = useCallback(
    (page) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return map(demandIdRef.current!, filteringObject.queueTaskGroup, page);
    },
    [filteringObject.queueTaskGroup],
  );

  const comparer: HookArgs["comparer"] = useCallback(
    (prevObj, page) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return isEqual(demandIdRef.current!, filteringObject.queueTaskGroup, prevObj, page);
    },
    [filteringObject.queueTaskGroup],
  );

  const { onNextPageFetching } = useHook({
    stateSelector: selectBankGuaranteeDetailsTasksHistory,
    action: getHistoryBankGuaranteeDetailsDemand,
    mapper,
    comparer,
  });

  return { onNextPageFetching };
};
