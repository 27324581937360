import { ClipboardEvent, useCallback, useMemo, useState } from "react";
import * as React from "react";
import { useDebounce } from "../../common/hooks/useDebounce";

export const useSearchClientInputValue = (initialValue?: string) => {
  const [value, setValue] = useState(initialValue);
  const [debounceValue, setDebounceValue] = useDebounce<string | undefined>(value);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const onPaste = useCallback(
    (event: ClipboardEvent<HTMLInputElement>) => {
      const text = event.clipboardData.getData("Text");
      if (text !== undefined) {
        event.preventDefault();

        setValue(text);
        setDebounceValue(text);
      }
    },
    [setDebounceValue],
  );

  const setValues = useCallback(
    (val: string | undefined) => {
      setValue(val);
      setDebounceValue(val);
    },
    [setDebounceValue],
  );

  return useMemo(
    () => ({ value, debounceValue, onChange, onPaste, setValues }),
    [debounceValue, onChange, onPaste, setValues, value],
  );
};
