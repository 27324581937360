import { ElementType, useMemo } from "react";
import {
  BankGuaranteeDetailsDemandActiveTaskCommonProps,
  BankGuaranteeDetailsDemandCompletedTaskCommonProps,
  BankGuaranteeDetailsDemandTaskCommonProps,
} from "../../types/demand/tasks/BankGuaranteeDetailsDemandTaskCommonProps";
import { BankGuaranteeDetailsCompletedTaskCard } from "../../components/tasks/card/BankGuaranteeDetailsCompletedTaskCard";
import { BankGuaranteeDetailsDemandQueueTaskActive } from "../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskActive";
import { BankGuaranteeDetailsDemandQueueTaskCompleted } from "../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskCompleted";
import { BankGuaranteeDetailsActiveTaskCard } from "../../components/tasks/card/BankGuaranteeDetailsActiveTaskCard";
import {
  BankGuaranteeDetailsComplicatedTaskBillActive,
  BankGuaranteeDetailsComplicatedTaskBillCompleted,
} from "../../complicated-tasks/bill";
import {
  BankGuaranteeDetailComplicatedTaskProjectActive,
  BankGuaranteeDetailsComplicatedTaskProjectCompleted,
} from "../../complicated-tasks/project";

type ActiveOrCompletedComponent<TIsActive extends boolean> = ElementType<
  BankGuaranteeDetailsDemandTaskCommonProps<
    true extends TIsActive ? BankGuaranteeDetailsDemandQueueTaskActive : BankGuaranteeDetailsDemandQueueTaskCompleted
  >
>;

const getActiveOrCompleted = <TIsActive extends boolean>(
  isActive: TIsActive,
  active: ElementType<BankGuaranteeDetailsDemandActiveTaskCommonProps>,
  completed: ElementType<BankGuaranteeDetailsDemandCompletedTaskCommonProps>,
): ActiveOrCompletedComponent<TIsActive> => {
  return (isActive === true ? active : completed) as ActiveOrCompletedComponent<TIsActive>;
};

export const useBankGuaranteeDetailsTaskComponent = <TIsActive extends boolean>(
  queueTaskType: string | undefined,
  isActiveTask: TIsActive,
): ActiveOrCompletedComponent<TIsActive> => {
  return useMemo(() => {
    switch (queueTaskType) {
      case "BillFormalization":
        return getActiveOrCompleted(
          isActiveTask,
          BankGuaranteeDetailsComplicatedTaskBillActive,
          BankGuaranteeDetailsComplicatedTaskBillCompleted,
        );
      case "ProjectFormalization":
        return getActiveOrCompleted(
          isActiveTask,
          BankGuaranteeDetailComplicatedTaskProjectActive,
          BankGuaranteeDetailsComplicatedTaskProjectCompleted,
        );
      default:
        return getActiveOrCompleted(
          isActiveTask,
          BankGuaranteeDetailsActiveTaskCard,
          BankGuaranteeDetailsCompletedTaskCard,
        );
    }
  }, [isActiveTask, queueTaskType]);
};
