import styled from "@emotion/styled";
import { AppLayoutResponsiveListPage } from "../../app-layout-containers";

export const ConditionsAppResponsiveLayout = styled(AppLayoutResponsiveListPage.List)`
  max-width: 100%;
  overflow: auto;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    max-width: 100%;
  }
`;
