import styled from "@emotion/styled";
import { Button, ButtonLoading } from "../../../../../../../common/ui-kit/form-controls/button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xxl};
  flex-grow: 1;
  max-width: 580px;
`;

export const RejectButton = styled(Button)`
  width: 9rem;
`;

export const ConfirmButton = styled(ButtonLoading)<{ isFullWidth: boolean }>`
  height: ${({ isFullWidth }) => isFullWidth && "3rem"};
`;
