import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { ThemeTypographyVariantType } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypography";
import { getValueByPath } from "@sideg/ui-kit/core/theme/utils/getValueByPath";
import { mapThemeTypographyVariantToCssObject } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypographyVariantTokenValue";
import { ThemePaletteColors } from "@sideg/ui-kit/core/theme/types/colors/ThemePalette";
import { makeResponsiveStylesBuilder } from "@sideg/ui-kit/common/utils/responsive/makeResponsiveStyles";
import {
  ThemeTypographyFontSizes,
  ThemeTypographyFontWeights,
  ThemeTypographyLineHeights,
} from "@sideg/ui-kit/core/theme/types/typography/ThemeTypographySizes";
import {
  MapToResponsiveStyleValue,
  ResponsiveStyleValue,
  SizeInPixels,
  SizeInRems,
  SizeWithAnyUnit,
  tryGetSizeAndUnit,
} from "@sideg/ui-kit/common/types";
import { PickCssObjectProps } from "@sideg/ui-kit/common/types/styles/PickCssObjectProps";

type TypographySize = SizeInRems | SizeInPixels | number;

type TypographyCssProps = Partial<
  MapToResponsiveStyleValue<
    PickCssObjectProps<"opacity" | "textAlign" | "whiteSpace" | "wordBreak" | "userSelect" | "fontFeatureSettings">
  >
>;

export interface TypographyProps extends TypographyCssProps {
  variant?: ResponsiveStyleValue<ThemeTypographyVariantType>;
  fontSize?: ResponsiveStyleValue<ThemeTypographyFontSizes | TypographySize>;
  lineHeight?: ResponsiveStyleValue<ThemeTypographyLineHeights | TypographySize>;
  fontColor?: ResponsiveStyleValue<ThemePaletteColors>;
  fontWeight?: ResponsiveStyleValue<ThemeTypographyFontWeights>;
  withLineBreaks?: ResponsiveStyleValue<boolean>;
  withTextOverflowEllipsis?: ResponsiveStyleValue<boolean>;
}

const responsive = makeResponsiveStylesBuilder<TypographyProps>();

const mapTypographySizeToCssSize = <TValues extends string>(
  themeValues: Record<TValues, SizeWithAnyUnit>,
  value: TValues | TypographySize,
): SizeWithAnyUnit | undefined => {
  if (value in themeValues) {
    return themeValues[value];
  }

  const parsedValue = tryGetSizeAndUnit(value);
  if (parsedValue?.unit !== undefined) {
    return `${parsedValue.size}${parsedValue.unit}`;
  }

  if (typeof value === "number") {
    return `${value}rem`;
  }

  return undefined;
};

export const Typography = styled("span", {
  shouldForwardProp: (propName: string) =>
    isPropValid(propName) && propName !== "fontSize" && propName !== "fontWeight",
})<TypographyProps>(
  { fontFamily: "inherit" },
  responsive("variant", (variant, theme) => (variant ? mapThemeTypographyVariantToCssObject(variant, theme) : {})),
  responsive("fontColor", (color, theme) => {
    return color ? { color: getValueByPath(theme.palette, color) } : {};
  }),
  responsive("fontWeight", (fontWeight, theme) => {
    return fontWeight ? { fontWeight: theme.typography.weights[fontWeight] } : {};
  }),
  responsive("fontSize", (fontSize, theme) => {
    return fontSize ? { fontSize: mapTypographySizeToCssSize(theme.typography.sizes, fontSize) } : {};
  }),
  responsive("lineHeight", (lineHeight, theme) => {
    return lineHeight ? { lineHeight: mapTypographySizeToCssSize(theme.typography.lineHeights, lineHeight) } : {};
  }),
  responsive("withLineBreaks", (withLineBreaks) =>
    withLineBreaks
      ? {
          whiteSpace: "pre-line",
          wordBreak: "break-word",
        }
      : {},
  ),
  responsive("withTextOverflowEllipsis", (withTextOverflowEllipsis) =>
    withTextOverflowEllipsis
      ? {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }
      : {},
  ),
  responsive("textAlign"),
  responsive("whiteSpace"),
  responsive("wordBreak"),
  responsive("userSelect"),
  responsive("opacity"),
  responsive("fontFeatureSettings"),
);
