import { combineSliceNames } from "../../../common/store/utils";
import { BANK_GUARANTEE_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { bankGuaranteeExtractFromRegistryApi } from "../api/bankGuaranteeExtractFromRegistryApi";

export const BANK_GUARANTEE_EXTRACT_FROM_REGISTRY_SLICE_NAME = combineSliceNames(
  BANK_GUARANTEE_SLICE_NAME,
  "extract-from-registry"
);

export const { get: getBankGuaranteeExtractFromRegistry, post: postBankGuaranteeExtractFromRegistry } = createApiThunks(
  BANK_GUARANTEE_EXTRACT_FROM_REGISTRY_SLICE_NAME,
  bankGuaranteeExtractFromRegistryApi
);
