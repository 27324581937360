import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { FormCheckbox } from "@sideg/ui-kit/atoms/checkbox";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeCreationDateEventField } from "../fields/BankGuaranteeCreationDateEventField";
import { useBankGuaranteeCreationForm } from "../../../hooks/creation/useBankGuaranteeCreationForm";
import { useBankGuaranteeCreationFieldHelpers } from "../../../hooks/creation/fields/useBankGuaranteeCreationFieldHelpers";
import { useGetFormFieldError } from "../../../../../common/form";
import { BankGuaranteeCreationFromValues } from "../../../types/BankGuaranteeCreationFormValues";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";
import { BaseCssProps } from "../../../../../common/ui-kit/types";

export interface BankGuaranteeCreationAuctionBlockProps extends BaseCssProps {
  isDisabled?: boolean;
}

export const BankGuaranteeCreationAuctionBlock = ({
  className,
  isDisabled,
}: BankGuaranteeCreationAuctionBlockProps) => {
  const form = useBankGuaranteeCreationForm();
  const { getFieldName } = useBankGuaranteeCreationFieldHelpers();
  const getFieldError = useGetFormFieldError<BankGuaranteeCreationFromValues>();

  const [isWriteOffRequestProps] = useBankGuaranteeCreationField("isWriteOffRequest");

  return (
    <CreationBlockWithTitle title="Аукцион" className={className}>
      <StackPanel direction="column" gap="md">
        <BankGuaranteeCreationDateEventField disabled={isDisabled} />
        <FormInput
          autoComplete="off"
          type="text"
          size="medium"
          label="Номер лота"
          {...form.getFieldProps(getFieldName("lotNumber"))}
          error={getFieldError(form, "lotNumber")}
          disabled={isDisabled}
        />
        <FormInputPrice
          autoComplete="off"
          label="Сумма обеспечения, ₽"
          size="medium"
          {...form.getFieldProps(getFieldName("guaranteeSum"))}
          error={getFieldError(form, "guaranteeSum")}
          disabled={isDisabled}
        />
        <FormCheckbox
          label="Есть требование о бесспорном списании средств"
          {...isWriteOffRequestProps}
          isDisabled={isDisabled}
        />
      </StackPanel>
    </CreationBlockWithTitle>
  );
};
