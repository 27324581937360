import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { CSSObject } from "@emotion/react";
import { makeResponsiveStyles, Theme } from "@sideg/ui-kit";
import { FontSizeType } from "../../types/FontSizeType";
import { SizeUnit } from "../../theme";
import { ResponsiveStyleValue } from "../../types";

type LineHeight = keyof Pick<Theme["font"], "defaultLineHeight">;
type ColorPalette = `colors.${keyof Theme["colors"]}`;

export type FontColor = keyof Theme["palette"]["typography"] | ColorPalette;

export interface TextProps {
  fontSize?: ResponsiveStyleValue<FontSizeType | SizeUnit | number>;
  lineHeight?: ResponsiveStyleValue<LineHeight | SizeUnit>;
  fontWeight?: ResponsiveStyleValue<number>;
  fontColor?: ResponsiveStyleValue<FontColor>;
  textAlign?: ResponsiveStyleValue<CSSObject["textAlign"]>;
  whiteSpace?: ResponsiveStyleValue<CSSObject["whiteSpace"]>;
  wordBreak?: ResponsiveStyleValue<CSSObject["wordBreak"]>;
  opacity?: ResponsiveStyleValue<CSSObject["opacity"]>;
  fontFeatureSettings?: ResponsiveStyleValue<CSSObject["fontFeatureSettings"]>;
  withLineBreaks?: boolean;
}

const isColorPalette = (color: string): color is ColorPalette => {
  return color.startsWith("colors.");
};

export const Text = styled("span", {
  shouldForwardProp: (propName: string) =>
    isPropValid(propName) && propName !== "fontSize" && propName !== "fontWeight",
})<TextProps>(
  { fontFamily: "inherit" },
  ({ theme, fontColor }) =>
    makeResponsiveStyles(theme, fontColor ?? "primary", (x, t) => ({
      color: isColorPalette(x) ? t.colors[x.replace("colors.", "") as keyof Theme["colors"]] : t.palette.typography[x],
    })),
  ({ theme, fontWeight }) => makeResponsiveStyles(theme, fontWeight ?? 400, (x) => ({ fontWeight: x })),
  ({ theme, textAlign }) => makeResponsiveStyles(theme, textAlign, (x) => ({ textAlign: x })),
  ({ theme, whiteSpace }) => makeResponsiveStyles(theme, whiteSpace, (x) => ({ whiteSpace: x })),
  ({ theme, wordBreak }) => makeResponsiveStyles(theme, wordBreak, (x) => ({ wordBreak: x })),
  ({ theme, opacity }) => makeResponsiveStyles(theme, opacity, (x) => ({ opacity: x })),
  ({ theme, fontFeatureSettings }) =>
    makeResponsiveStyles(theme, fontFeatureSettings, (x) => ({ fontFeatureSettings: x })),
  ({ theme, fontSize }) =>
    makeResponsiveStyles(theme, fontSize ?? "fz3", (x, t) => {
      const fz = typeof x === "number" ? `${x}rem` : t.font[x as FontSizeType] ?? x;

      return { fontSize: fz };
    }),
  ({ theme, lineHeight }) =>
    makeResponsiveStyles(theme, lineHeight ?? "1.4", (x, t) => {
      return { lineHeight: t.font[x as LineHeight] ?? x };
    }),
  ({ theme, withLineBreaks }) =>
    makeResponsiveStyles(theme, withLineBreaks, (x) => {
      if (x) {
        return {
          whiteSpace: "pre-line",
          wordBreak: "break-word",
        };
      }

      return {};
    }),
);
