import { StyledNavigationButtonWithCounter } from "../../../common/ui/navigation/components/buttons/StyledNavigationButtonWithCounter";
import { useHubState } from "../../../common/hubs";
import { useChatDialogAppLayoutOverlayPanel } from "../hooks/useChatDialogAppLayoutOverlayPanel";
import { useAppSelector } from "../../../common/store";
import { selectChatDialogUnreadMessages } from "../store/chatDialog.selectors";
import { ChatIcon } from "../../../common/ui-kit/icons";
import { chatDialogHubConnection } from "../api/chatDialogHubConnection";
import { AppLayoutOverlayPanel } from "../../../common/ui/layout/app-layout-containers";
import { ChatDialog } from "./ChatDialog";
import { useReachGoalAnalytics } from "../../../common/analytics/hooks";

export const ChatDialogHeaderAppLayoutButton = () => {
  const { isOpened, closeChat, toggleChat, key } = useChatDialogAppLayoutOverlayPanel();
  const { isConnected } = useHubState(chatDialogHubConnection);
  const count = useAppSelector(selectChatDialogUnreadMessages);
  const reachGoal = useReachGoalAnalytics();

  if (!isConnected) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  const handleButtonClick = () => {
    if (!isOpened) {
      reachGoal("GLOBAL_CHAT_WIDGET_BUTTON_CLICK");
    }

    toggleChat();
  };

  return (
    <>
      <StyledNavigationButtonWithCounter
        isActive={isOpened}
        count={count > 0 ? count : undefined}
        type="button"
        onClick={handleButtonClick}
      >
        <ChatIcon />
      </StyledNavigationButtonWithCounter>
      <AppLayoutOverlayPanel panelKey={key}>
        <ChatDialog onCloseClick={closeChat} />
      </AppLayoutOverlayPanel>
    </>
  );
};
