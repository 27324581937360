import styled from "@emotion/styled";
import { AppLink } from "../../../../../../../common/urls/internal/components";

export const Li = styled.li`
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  list-style: none;
`;

export const Container = styled(AppLink, { shouldForwardProp: (propName) => propName !== "isWithRequest" })<{
  isWithRequest: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.sm};
  gap: ${({ theme }) => theme.spaces.sm};
  text-decoration: none;
  color: inherit;
  background-color: ${({ theme, isWithRequest }) =>
    isWithRequest ? theme.palette.control.primaryLight : theme.palette.control.secondaryLight};
  border-radius: 4px;
  user-select: none;
`;

export const StatusText = styled.span<{ isWithRequest: boolean }>`
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme, isWithRequest }) => (isWithRequest ? theme.colors.primary : theme.palette.typography.medium)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Bank = styled.span<{ isRejected: boolean }>`
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: 1;
  color: ${({ theme }) => theme.palette.typography.primary};
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
  text-decoration: ${({ isRejected }) => (isRejected ? "line-through" : "none")};
`;
