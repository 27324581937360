import styled from "@emotion/styled";
import { AppExternalLink } from "../../../../urls/external";

export const Link = styled(AppExternalLink)`
  & {
    filter: grayscale(100%);
  }

  &:hover {
    filter: none;
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.palette.background.light};
    border-radius: 4px;
  }
`;
