import { GetBankGuaranteeDocumentsInputDto } from "./input/GetBankGuaranteeDocumentsInputDto";
import { clientV2 } from "../../../common/api";
import { GetBankGuaranteeDocumentsOutputDto } from "./output/GetBankGuaranteeDocumentsOutputDto";
import { GetBanksBankGuaranteeDocumentsOutputDto } from "./output/GetBanksBankGuaranteeDocumentsOutputDto";

export const bankGuaranteeDocumentsApi = {
  get: ({ bankCode, legalForm }: Omit<GetBankGuaranteeDocumentsInputDto, "productType">, abortSignal?: AbortSignal) =>
    clientV2.get<GetBankGuaranteeDocumentsInputDto, GetBankGuaranteeDocumentsOutputDto>(
      "/Information/DocumentList",
      { bankCode, legalForm, productType: "BankGuarantee" },
      { abortSignal },
    ),
  getBanks: () =>
    clientV2.post<undefined, GetBanksBankGuaranteeDocumentsOutputDto>(
      "/Information/GetBanksForProduct?productType=1",
      undefined,
    ),
};
