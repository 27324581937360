import styled from "@emotion/styled";

export const BankGuaranteeDocumentsListItemBadge = styled.span`
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  padding: 2px 8px;
  display: inline-block;
  border-radius: 11px;
  user-select: none;
  background: linear-gradient(90deg, #ef0000 0%, #fa4e77 100%);
`;
