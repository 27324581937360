import { isAnyOf } from "@reduxjs/toolkit";
import { queueTaskClosedDemandQueueTasks, uploadDocumentDemandQueueTasks } from "../../../demand/queue-tasks";
import {
  creditExecutionDetailsActiveDemandChanged,
  creditExecutionDetailsActiveProductChanged,
  creditExecutionDetailsReloadActiveDemand,
  creditExecutionDetailsReloadActiveProduct,
} from "./creditExecutionDetails.actions";

export const isCreditExecutionDetailsNeedReloadMatcher = isAnyOf(
  creditExecutionDetailsActiveDemandChanged,
  uploadDocumentDemandQueueTasks.fulfilled,
  queueTaskClosedDemandQueueTasks,
  creditExecutionDetailsReloadActiveProduct,
  creditExecutionDetailsReloadActiveDemand
);

export const isCreditExecutionDetailsProductChangedMatcher = isAnyOf(
  creditExecutionDetailsActiveDemandChanged,
  creditExecutionDetailsActiveProductChanged
);
