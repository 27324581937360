import {
  FullPageContainerHeaderDesktop,
  FullPageContainerHeaderTitle,
} from "../../../../common/ui/containers/full-page";
import { Box } from "../../../../common/ui-kit/containers/box";
import { BackLinkButton } from "../../../../common/ui/buttons/BackLinkButton";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export type SettlementAndCashCreationHeaderDesktopProps = BaseCssProps;

export const SettlementAndCashCreationHeaderDesktop = ({ className }: SettlementAndCashCreationHeaderDesktopProps) => {
  return (
    <FullPageContainerHeaderDesktop
      className={className}
      leftSide={
        <Box flexGrow={1} flexShrink={1} flexBasis="0%">
          <BackLinkButton linkTo="/" text="Назад" />
        </Box>
      }
    >
      <FullPageContainerHeaderTitle text="Новая заявка на расчетно-кассовое обслуживание" />
    </FullPageContainerHeaderDesktop>
  );
};
