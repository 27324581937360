import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    padding: ${({ theme }) => theme.spaces.md} 0;

    & + * {
      border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
    }
  }
`;
