import { boolean, mixed, object, string } from "yup";
import {
  DemandQueueTaskActiveProjectGuaranteePrintType,
  demandQueueTaskActiveProjectGuaranteePrintTypeWrapped,
  demandQueueTaskActiveValidationSchema,
} from "../../../../../demand/queue-tasks";
import { getAdditionalPhoneNumberSchema, getFormattedPhoneSchema } from "../../../../../common/validation";
import { BankGuaranteeDetailsComplicatedTaskProjectDecisionType } from "../types/BankGuaranteeDetailsComplicatedTaskProjectDecisionType";
import { bankGuaranteeDetailsComplicatedTaskProjectGuaranteePrintTypeFormRules } from "./bankGuaranteeDetailsComplicatedTaskProjectGuaranteePrintTypeForm.rules";
import { bankGuaranteeDetailsComplicatedTaskFileRules } from "./bankGuaranteeDetailsComplicatedTaskFile.rules";

export const bankGuaranteeDetailsComplicatedTaskProjectContactsValidationSchema = object({
  clientPhoneNumber: getFormattedPhoneSchema("Укажите телефон").default(""),
  clientAdditionalNumber: getAdditionalPhoneNumberSchema("").default(""),
  clientEmail: string()
    .default("")
    .trim("Укажите электронную почту")
    .email("Укажите корректную электронную почту")
    .required("Укажите электронную почту"),
  beneficiaryPhoneNumber: getFormattedPhoneSchema("Укажите телефон").default(""),
  beneficiaryAdditionalNumber: getAdditionalPhoneNumberSchema("").default(""),
  beneficiaryEmail: string()
    .default("")
    .trim("Укажите электронную почту")
    .email("Укажите корректную электронную почту")
    .required("Укажите электронную почту"),
});

export const bankGuaranteeDetailsComplicatedTaskProjectGuaranteePrintTypeValidationSchema = object({
  guaranteePrintType: mixed<DemandQueueTaskActiveProjectGuaranteePrintType>().when(
    "decision",
    (decision: BankGuaranteeDetailsComplicatedTaskProjectDecisionType | undefined, schema) => {
      return bankGuaranteeDetailsComplicatedTaskProjectGuaranteePrintTypeFormRules.isExists(decision)
        ? schema
            .oneOf(demandQueueTaskActiveProjectGuaranteePrintTypeWrapped.values)
            .required("Выберите один из вариантов ответа")
        : schema.strip();
    },
  ),
});

export const bankGuaranteeDetailsComplicatedTasksProjectValidationSchema = demandQueueTaskActiveValidationSchema.shape({
  documents: string().strip(),
  file: mixed().when(
    "decision",
    (decision: BankGuaranteeDetailsComplicatedTaskProjectDecisionType | undefined, schema) => {
      return bankGuaranteeDetailsComplicatedTaskFileRules.isExists(decision)
        ? schema.nullable(true).notRequired()
        : schema.strip();
    },
  ),
  isNeedContacts: boolean().required("").default(false),
});

export const getBankGuaranteeDetailsComplicatedTasksProjectValidationSchema = (
  isNeedContacts: boolean | undefined,
  isNeedGuaranteePrintTypeInfo: boolean | undefined,
) => {
  let validationSchema = bankGuaranteeDetailsComplicatedTasksProjectValidationSchema;

  if (isNeedContacts) {
    validationSchema = validationSchema.concat(bankGuaranteeDetailsComplicatedTaskProjectContactsValidationSchema);
  }

  if (isNeedGuaranteePrintTypeInfo) {
    validationSchema = validationSchema.concat(
      bankGuaranteeDetailsComplicatedTaskProjectGuaranteePrintTypeValidationSchema,
    );
  }

  return validationSchema;
};
