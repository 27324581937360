import * as React from "react";
import { Tooltip, useTooltip } from "../../../../../ui-kit/pop-up/tooltip";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";
import { Typography } from "../../../../../ui-kit/typography";
import { BaseCssProps, ElementColor } from "../../../../../ui-kit/types";
import {
  productCreationScoringResultType,
  ProductCreationScoringResultType,
} from "../../../../types/ProductCreationScoringResultType";
import { CopyIcon, Icon, LikeIcon, XOctagonIcon } from "../../../../../ui-kit/icons";

export interface ProductCreationScoringResultSummaryIconMobileProps extends BaseCssProps {
  resultType: ProductCreationScoringResultType;
  count: number;
}

const getIconByResultType = (resultType: ProductCreationScoringResultType): React.ElementType => {
  switch (resultType) {
    case "success":
      return LikeIcon;
    case "reject":
      return XOctagonIcon;
    case "duplicate":
      return CopyIcon;
    default:
      // eslint-disable-next-line react/function-component-definition
      return () => <svg />;
  }
};

const getColorByResultType = (resultType: ProductCreationScoringResultType): ElementColor => {
  switch (resultType) {
    case "success":
      return "status.success";
    case "reject":
      return "status.warning";
    case "duplicate":
    default:
      return "typography.medium";
  }
};

export const ProductCreationScoringResultSummaryIconMobile = ({
  resultType,
  count,
  className,
}: ProductCreationScoringResultSummaryIconMobileProps) => {
  const { onClick, ref, popupProps, placement, arrow, onMouseLeave, onMouseEnter, isOpened } = useTooltip({
    position: "top",
    type: "click",
  });

  const color = getColorByResultType(resultType);

  return (
    <StackPanel
      gap="sm"
      direction="row"
      alignItems="center"
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      ref={ref}
    >
      <Icon as={getIconByResultType(resultType)} height={24} width={24} stroke={color} className={className} />
      <Typography userSelect="none" fontColor={color} fontSize="fz4" lineHeight="defaultLineHeight" fontWeight={700}>
        {count}
      </Typography>
      {isOpened && (
        <Tooltip popUpProps={popupProps} placement={placement} arrowData={arrow.data} arrowRef={arrow.ref}>
          {productCreationScoringResultType.getTitle(resultType)}
        </Tooltip>
      )}
    </StackPanel>
  );
};
