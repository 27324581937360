import { forwardRef } from "react";
import { BankGuaranteeDetailsDemandActiveTaskCommonProps } from "../../../../../bank-guarantee/details/types/demand/tasks/BankGuaranteeDetailsDemandTaskCommonProps";
import { DemandQueueTaskCard, DemandQueueTaskSeparator } from "../../../index";
import { DemandQueueTaskComment } from "../../comment/DemandQueueTaskComment";
import { DemandQueueTaskRequestOptionsForm } from "../form/DemandQueueTaskRequestOptionsForm";

export const DemandQueueTaskRequestOptionsCard = forwardRef<
  HTMLDivElement,
  BankGuaranteeDetailsDemandActiveTaskCommonProps
>(({ task, isFocused, className }, ref) => {
  return (
    <DemandQueueTaskCard
      className={className}
      variant="active"
      title={task.taskTitle}
      date={new Date(task.dateCreated)}
      ref={ref}
      isFocused={isFocused}
      isPinned={task.isPinned}
    >
      <DemandQueueTaskComment author="Менеджер банка" text={task.action.message} />
      <DemandQueueTaskSeparator />
      {task.requestOptions !== undefined && task.decisions !== undefined && (
        <DemandQueueTaskRequestOptionsForm
          queueTaskId={task.id}
          requestOptions={task.requestOptions}
          decision={task.decisions[0]}
        />
      )}
    </DemandQueueTaskCard>
  );
});
