import { forwardRef } from "react";
import { BankGuaranteeRewardsFilterSelect } from "./BankGuaranteeRewardsFilterSelect";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeRewards } from "../../../store/bankGuaranteeRewards.selectors";
import { BankGuaranteeRewardsFilterSelectSkeleton } from "./BankGuaranteeRewardsFilterSelect.skeleton";

interface BankGuaranteeRewardsFilterSelectWrapperProps {
  isFixed: boolean;
  onSelectedChanged: (value: string) => void;
}

export const BankGuaranteeRewardsFilterSelectWrapper = forwardRef<
  HTMLDivElement,
  BankGuaranteeRewardsFilterSelectWrapperProps
>(({ onSelectedChanged, isFixed }, ref) => {
  const { banks } = useAppSelector(selectBankGuaranteeRewards);

  if (banks === undefined) {
    return <BankGuaranteeRewardsFilterSelectSkeleton isFixed={isFixed} />;
  }

  return (
    <BankGuaranteeRewardsFilterSelect banks={banks} isFixed={isFixed} ref={ref} onSelectedChanged={onSelectedChanged} />
  );
});
