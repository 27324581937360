import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { isFailed, isSucceeded } from "../../../common/types/state";
import { PublicUserCardWithTitle, PublicUserResultCard } from "../../common/components";
import { Box } from "../../../common/ui-kit/containers/box";
import { AlertTriangleBigIcon, Icon } from "../../../common/ui-kit/icons";
import {
  selectPublicUserPrivacyPolicies,
  selectPublicUserPrivacyPoliciesAll,
} from "../store/publicUserPrivacyPolicies.selectors";
import { getListPublicUserPrivacyPolicies } from "../store/publicUserPrivacyPolicies.thunks";
import { PublicUserPrivacyPoliciesFileLabel } from "./PublicUserPrivacyPoliciesFileLabel";
import { Skeleton } from "../../../common/ui-kit/animations/skeletons";

export const PublicUserPrivacyPoliciesCard = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectPublicUserPrivacyPolicies);
  const offers = useAppSelector(selectPublicUserPrivacyPoliciesAll);

  useEffect(() => {
    dispatch(getListPublicUserPrivacyPolicies());
  }, [dispatch]);

  return (
    <>
      {!isFailed(status) && (
        <PublicUserCardWithTitle title="Политика обработки персональных данных">
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="xl">
            {!isSucceeded(status) && (
              <>
                <Skeleton isFullWidth containerTestId="skeleton" height={32} />
                <Skeleton isFullWidth containerTestId="skeleton" height={32} />
              </>
            )}
            {isSucceeded(status) &&
              offers.map((x) => (
                <PublicUserPrivacyPoliciesFileLabel
                  key={x.issuingCompanyId}
                  fileName={x.policyName}
                  issuingCompanyId={x.issuingCompanyId}
                />
              ))}
          </Box>
        </PublicUserCardWithTitle>
      )}
      {isFailed(status) && (
        <PublicUserResultCard
          icon={<Icon as={AlertTriangleBigIcon} stroke="status.error" />}
          description="Во время обработки запроса произошла ошибка. Пожалуйста, попробуйте ещё раз позже."
        />
      )}
    </>
  );
};
