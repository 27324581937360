import { AnyAction } from "redux";
import { ListenerEffectAPI } from "@reduxjs/toolkit";
import { useEffect, useRef, useState } from "react";
import { addAppListener, removeAppListener, RootState, useAppDispatch, useAppSelector } from "../../../common/store";
import { AppDispatch } from "../../../common/store/types";
import { isBankGuaranteeDetailsNeedReloadMatcher } from "../store/bankGuaranteeDetails.matchers";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { bankGuaranteeDetailsReloadService } from "../services/bankGuaranteeDetailsReloadService";

export type UseBankGuaranteeDetailsNeedReloadListenerEffect = (
  action: AnyAction,
  api: ListenerEffectAPI<RootState, AppDispatch>,
) => void;

export const useBankGuaranteeDetailsNeedReloadListener = (
  onReload: UseBankGuaranteeDetailsNeedReloadListenerEffect,
  subscribeAfterMount?: boolean,
) => {
  const dispatch = useAppDispatch();
  const [needSubscribe, setNeedSubscribe] = useState(subscribeAfterMount === false);
  const { lastRequestTime } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);

  const lastRequestTimeRef = useRef(lastRequestTime);
  lastRequestTimeRef.current = lastRequestTime;

  useEffect(() => {
    setNeedSubscribe(true);
  }, []);

  useEffect(() => {
    const predicate = isBankGuaranteeDetailsNeedReloadMatcher;
    const effect: UseBankGuaranteeDetailsNeedReloadListenerEffect = (action, api) => {
      if (bankGuaranteeDetailsReloadService.isNeedToReload(lastRequestTimeRef.current)) {
        onReload(action, api);
      }
    };

    if (needSubscribe) {
      dispatch(addAppListener({ predicate, effect }));
    }

    return () => {
      if (needSubscribe) {
        dispatch(removeAppListener({ predicate, effect, cancelActive: true }));
      }
    };
  }, [dispatch, onReload, needSubscribe]);
};
