import { ConditionTitleType } from "../../../common/ui/layout/conditions";

export const conditionsTitles: ConditionTitleType[] = [
  {
    title: "Банк",
    size: "180px",
  },
  {
    title: "Сумма кредита",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Срок действия",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Наличие БГ в банке-кредиторе",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Срок деятельности компании",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Опыт",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Стоп-факторы",
    size: "400px",
  },
  {
    title: "Ставка за выдачу",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Ставка за год",
    size: "minmax(250px, 1fr)",
  },
  {
    title: "Агентское вознаграждение",
    size: "minmax(250px, 1fr)",
  },
];
