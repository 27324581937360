/* eslint-disable @typescript-eslint/no-explicit-any */
import { JSXElementConstructor } from "react";

import * as React from "react";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";
import * as S from "./HeaderAction.styled";
import { ChevronLeftIcon } from "../../../../../ui-kit/icons";

export type HeaderActionProps<T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>> =
  React.ComponentProps<T> & {
    withComponent: T;
    withBackIcon?: boolean;
    children?: React.ReactNode;
  };

export const HeaderAction = React.memo(
  <T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>>({
    withBackIcon,
    withComponent,
    children,
    ...rest
  }: HeaderActionProps<T>) => {
    const Container = S.Container.withComponent(withComponent);

    return (
      <Container {...rest}>
        {withBackIcon ? (
          <StackPanel direction="row" alignItems="center" gap="none">
            <ChevronLeftIcon />
            {children}
          </StackPanel>
        ) : (
          children
        )}
      </Container>
    );
  },
);
