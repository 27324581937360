import styled from "@emotion/styled";
import { AppLink } from "../../../../../urls/internal/components";

export const IssueLink = styled(AppLink)`
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.fz4};
  font-weight: 700;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme }) => theme.palette.typography.success};

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;
