import { useMemo } from "react";
import { useAppDispatch } from "../../../../../common/store";
import { pushSuccessGlobalToast } from "../../../../../global/toasts";
import { bankGuaranteeDetailsActiveDemandOriginalRequested } from "../../../store/bankGuaranteeDetails.actions";
import { useBankGuaranteeRequestOriginalCreationModalPanel } from "../../../../request-original/hooks/useBankGuaranteeRequestOriginalCreationModalPanel";

export const useBankGuaranteeDetailsDemandRequestOriginal = () => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useBankGuaranteeRequestOriginalCreationModalPanel();

  return useMemo(() => {
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const openModal = () => {
      setIsModalOpen(true);
    };

    const onSuccess = () => {
      closeModal();
      dispatch(pushSuccessGlobalToast("Заявка на запрос оригинала отправлена"));
      dispatch(bankGuaranteeDetailsActiveDemandOriginalRequested());
    };

    return { isModalOpen, openModal, closeModal, onSuccess };
  }, [dispatch, isModalOpen, setIsModalOpen]);
};
