import styled from "@emotion/styled";
import { Theme } from "@sideg/ui-kit";
import { ControlColors } from "../../../theme";
import { InputBoxProps } from "../types/InputBoxProps";

export const Container = styled.div`
  word-wrap: break-word;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  letter-spacing: 0;
  width: 100%;
`;

export const LabelWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.xs};
`;

export const Label = styled.label`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.palette.input.label};
`;

export const InputElement = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

const getInputBoxPalette = (
  theme: Theme,
  error: boolean | undefined,
  disabled: boolean | undefined,
  focus: boolean | undefined,
  hover: boolean | undefined,
): ControlColors => {
  if (disabled) {
    return theme.palette.input.disabled;
  }

  if (focus) {
    if (error) {
      return theme.palette.input.errorActive;
    }

    return theme.palette.input.active;
  }

  if (error) {
    return theme.palette.input.errorDefault;
  }

  if (hover) {
    return theme.palette.input.hover;
  }

  return theme.palette.input.default;
};

export const InputBox = styled.div<InputBoxProps>`
  word-wrap: break-word;
  letter-spacing: 0;
  align-items: center;
  background-clip: padding-box;
  background-color: ${({ error, theme, disabled }) =>
    getInputBoxPalette(theme, error, disabled, false, false).background};
  border: 1px solid ${({ error, theme, disabled }) => getInputBoxPalette(theme, error, disabled, false, false).border};
  border-radius: 4px;
  display: flex;
  position: relative;
  text-align: left;
  transition-duration: 0.25s;
  transition-property: background-color, border-color;
  transition-timing-function: ease;
  width: 100%;

  &:focus-within {
    background-color: ${({ error, theme, disabled }) =>
      getInputBoxPalette(theme, error, disabled, true, false).background};
    border-color: ${({ error, theme, disabled }) => getInputBoxPalette(theme, error, disabled, true, false).border};
  }

  &:hover:not(:focus-within) {
    background-color: ${({ error, theme, disabled }) =>
      getInputBoxPalette(theme, error, disabled, false, true).background};
    border-color: ${({ error, theme, disabled }) => getInputBoxPalette(theme, error, disabled, false, true).border};
  }
`;

export const InputBoxMain = styled.div`
  align-self: stretch;
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 100%;
`;

const FakeComponent = () => {
  // eslint-disable-next-line react/react-in-jsx-scope
  return <></>;
};

export const BaseInputField = styled(FakeComponent)`
  display: inline-flex;
  flex: 0 1 auto;
  padding: ${({ theme }) => theme.spaces.sm};
  max-width: 100%;
  min-width: 100%;
  position: relative;
  background-color: transparent;
  border: none;
  margin: 0;
  font-family: inherit;
  font-weight: 500;
`;

export const InputAfterContainer = styled.div`
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InputBeforeContainer = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
