import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../../common/types/utils";

const titles = {
  tasks: "Сообщения",
  documents: "Документы",
};

export const creditExecutionDetailsDemandHeaderTab = makeEnumLikeLiteral(["tasks", "documents"] as const, titles);

export type CreditExecutionDetailsDemandHeaderTab = EnumLikeLiteralType<typeof creditExecutionDetailsDemandHeaderTab>;
