import { AnySchema, InferType } from "yup";
import { useMemo } from "react";
import { ExtractRouteParams } from "../utils";
import { AppUrlSelector } from "../types";
import { useAppUrlParams } from "./useAppUrlParams";

export const useAppUrlTypedMatch = <
  TUrl extends string,
  TSchema extends AnySchema<{ [P in keyof ExtractRouteParams<TUrl>]: unknown }>
>(
  urlSelector: AppUrlSelector<TUrl, never>,
  schema: TSchema
): InferType<TSchema> | undefined => {
  const params = useAppUrlParams(urlSelector);

  return useMemo(() => {
    if (schema.isValidSync(params)) {
      return schema.cast(params, { stripUnknown: true });
    }

    return undefined;
  }, [params, schema]);
};
