import Skeleton from "react-loading-skeleton";
import { BankGuaranteeRewardsFilterSelectCard } from "../card/BankGuaranteeRewardsFilterSelectCard";

interface BankGuaranteeRewardsFilterSelectSkeletonProps {
  isFixed: boolean;
}

export const BankGuaranteeRewardsFilterSelectSkeleton = ({
  isFixed,
}: BankGuaranteeRewardsFilterSelectSkeletonProps) => {
  return (
    <BankGuaranteeRewardsFilterSelectCard isFixed={isFixed}>
      <Skeleton width={200} height={35} />
    </BankGuaranteeRewardsFilterSelectCard>
  );
};
