import { ExtractPaths } from "@sideg/ui-kit/core/theme/types/utils/ExtractPaths";

const PATH_SEPARATOR = ".";

export const getValueByPath = <TResult>(object: Record<string, unknown>, path: string): TResult => {
  let result = object;
  const parts = path.split(PATH_SEPARATOR);

  for (const part of parts) {
    if (result) {
      result = result[part] as Record<string, unknown>;
    }
  }

  if (result) {
    return result as TResult;
  }

  throw new Error(`unreachable object path: ${path}`);
};

const joinKeys = (...args: (string | undefined)[]) => args.filter(Boolean).join(PATH_SEPARATOR);

export const getPathsCollection = <TObject extends Record<string, unknown>, TValue>(
  object: TObject,
  isValue: (value: unknown) => value is TValue,
  parentKey?: string,
): ExtractPaths<TObject, TValue>[] => {
  return Object.entries(object)
    .flatMap(([key, value]) => {
      const nestedKey = joinKeys(parentKey, key);
      if (isValue(value)) {
        return nestedKey;
      }

      if (typeof value === "object" && !Array.isArray(value) && value !== null) {
        return getPathsCollection<TObject, TValue>(value as TObject, isValue, nestedKey);
      }

      return undefined;
    })
    .filter((path): path is ExtractPaths<TObject, TValue> => path !== undefined);
};
