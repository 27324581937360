import { ElementColor } from "../../../common/ui-kit/types";
import { CreditWorkingAssetsDemandStageType } from "../../common/types";

export const getCreditWorkingAssetsDemandStageColorBankLabel = (
  stage: CreditWorkingAssetsDemandStageType
): ElementColor => {
  switch (stage) {
    case "Approved":
      return "typography.success";
    case "AttachingDocuments":
      return "typography.primaryCaption";
    case "Confirmed":
      return "typography.successSecondary";
    case "Rejected":
      return "typography.medium";
    default:
      return "typography.primary";
  }
};
