import styled from "@emotion/styled";

export const IconWrapper = styled.button`
  border: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 4px;
  height: 100%;
  appearance: none;
  padding: 0 ${({ theme }) => theme.spacing.sm};
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.palette.light.primary.outline};
  }

  & > svg {
    height: 24px;

    path {
      fill: ${({ theme }) => theme.palette.light.neutral.text.quarternary};
    }
  }
`;
