import { publicClientV4 } from "../../../common/api";
import { GetListPublicUserPrivacyPoliciesOutputDto } from "./dto/output/GetListPublicUserPrivacyPoliciesOutputDto";
import { ApiBlobResponse, ApiObject } from "../../../common/api/types";
import { GetFilePublicUserPrivacyPoliciesInputDto } from "./dto/input/GetFilePublicUserPrivacyPoliciesInputDto";

const CONTROLLER_NAME = "registrations/privacy-policies";

export const publicUserPrivacyPoliciesApi = {
  getList: () => publicClientV4.get<undefined, GetListPublicUserPrivacyPoliciesOutputDto>(CONTROLLER_NAME),
  getFile: ({ issuingCompanyId }: GetFilePublicUserPrivacyPoliciesInputDto) =>
    publicClientV4.get<GetFilePublicUserPrivacyPoliciesInputDto, ApiBlobResponse>(
      `${CONTROLLER_NAME}/${issuingCompanyId}`,
      undefined,
      { responseType: "blob" }
    ),
} satisfies ApiObject;
