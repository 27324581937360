import { clientV4 } from "../../../common/api";
import { GetFiltrationParametersCreditWorkingAssetsListOutputDto } from "./dto/output/GetFiltrationParametersCreditWorkingAssetsListOutputDto";
import { GetCreditWorkingAssetsListInputDto } from "./dto/input/GetCreditWorkingAssetsListInputDto";
import { GetCreditWorkingAssetsListOutputDto } from "./dto/output/GetCreditWorkingAssetsListOutputDto";
import { GetCountersCreditWorkingAssetsOutputDto } from "./dto/output/GetCountersCreditWorkingAssetsOutputDto";

const CONTROLLER_NAME = "creditWorkingAssets";

export const creditWorkingAssetsListApi = {
  getFiltrationParameters: () =>
    clientV4.get<undefined, GetFiltrationParametersCreditWorkingAssetsListOutputDto>(
      `${CONTROLLER_NAME}/filtration-parameters`
    ),
  get: (filter?: GetCreditWorkingAssetsListInputDto, abortSignal?: AbortSignal) =>
    clientV4.get<GetCreditWorkingAssetsListInputDto, GetCreditWorkingAssetsListOutputDto>(CONTROLLER_NAME, filter, {
      abortSignal,
    }),
  getCounters: () =>
    clientV4.get<undefined, GetCountersCreditWorkingAssetsOutputDto>(`${CONTROLLER_NAME}/count-active-demands`),
};
