import { useMemo } from "react";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";

export const useProductDetailsAsideState = (isRootPath: boolean) => {
  const isDesktop = useThemeIsDesktop();

  return useMemo(() => {
    return { isAsideShown: isDesktop, isOnlyAsideShown: isRootPath && !isDesktop };
  }, [isDesktop, isRootPath]);
};
