import { AnyObjectSchema, InferType } from "yup";
import { FilteringArrayKeys } from "../../filters/types/FilteringArrayKeys";

export const getSchemaFieldsKeysOfType = <TSchema extends AnyObjectSchema>(
  schema: TSchema,
  type: string
): FilteringArrayKeys<InferType<TSchema>>[] => {
  return Object.entries(schema.fields)
    .filter(([, value]) => (value as AnyObjectSchema)?.type === type)
    .map(([key]) => key) as FilteringArrayKeys<InferType<TSchema>>[];
};
