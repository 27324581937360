import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useApiMutation } from "../../../common/hooks";
import { getDebtorDocumentsFactoringCreation } from "../store/factoringCreation.thunks";
import { FactoringCreationDebtorDocument } from "../types/FactoringCreationDebtorDocument";

export const useFactoringCreationDebtorsDocumentsState = () => {
  const [documents, setDocuments] = useState<undefined | FactoringCreationDebtorDocument[]>(undefined);

  const { error, status, mutate } = useApiMutation(getDebtorDocumentsFactoringCreation, {
    errorMessagePlaceholder: "Не удалось получить список необходимых документов для дебитора",
  });

  const isUnmounted = useRef(false);

  const getDocuments = useCallback(async () => {
    const result = await mutate(undefined);
    if (!isUnmounted.current) {
      setDocuments(
        result?.body?.map((x) => ({
          documentTypeId: x.id,
          description: x.description,
          template: x.template,
          name: x.name,
        }))
      );
    }
  }, [mutate]);

  useEffect(() => {
    if (documents === undefined) {
      getDocuments();
    }

    return () => {
      isUnmounted.current = true;
    };
  }, [documents, getDocuments]);

  return useMemo(
    () => ({ documents, onReload: getDocuments, status, error }),
    [documents, error, getDocuments, status]
  );
};
