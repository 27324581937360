import { useMemo } from "react";
import styled from "@emotion/styled";
import { QueueTasksListContainer } from "./QueueTasksListContainer";
import { NavigationState } from "../../../common/navigation/navigation-state";
import {
  selectQueueTasksListSuggestionById,
  selectQueueTasksListSuggestions,
  selectQueueTasksListSuggestionsIds,
} from "../suggestions/store/queueTasksListSuggestions.selectors";
import { useAppSelector } from "../../../common/store";
import { QueueTasksListFilterParams } from "../types/filter";
import { StyledAppLink } from "../../../common/ui/links";

const StyledSection = styled.section`
  display: inline-flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xxl};
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  font-size: ${({ theme }) => theme.font.fz2};
`;

const StyledH4 = styled.h4`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
`;

export interface QueueTasksListSuggestionsProps {
  from?: NavigationState;
  className?: string;
}

export const QueueTasksListSuggestions = ({ from, className }: QueueTasksListSuggestionsProps) => {
  const { hasMoreItems, productType, productId, ids } = useAppSelector(selectQueueTasksListSuggestions);

  const navigationFilter: QueueTasksListFilterParams = useMemo(() => {
    return {
      products: productType?.code ? [productType.code] : undefined,
      productId,
    } as QueueTasksListFilterParams;
  }, [productId, productType?.code]);

  if (ids.length === 0) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  return (
    <StyledSection className={className}>
      <StyledHeader>
        <StyledH4>Возможно, вас заинтересуют следующие задачи</StyledH4>
        {hasMoreItems && (
          <StyledAppLink to={(x) => x.queueTasks.list.root} search={navigationFilter}>
            Показать все задачи
          </StyledAppLink>
        )}
      </StyledHeader>
      <QueueTasksListContainer
        from={from}
        idsSelector={selectQueueTasksListSuggestionsIds}
        entitySelector={selectQueueTasksListSuggestionById}
      />
    </StyledSection>
  );
};
