import { CodeWithTitle } from "../../../../../common/types/objects";
import { GuaranteeQueueTasksGroupType } from "../../../../../common/types/queue-tasks";

export const getBankGuaranteeDetailsTaskTitle = (
  taskTypeTitle: string,
  groupType: CodeWithTitle<GuaranteeQueueTasksGroupType> | undefined,
) => {
  if (groupType === undefined || groupType.code === "All") {
    return taskTypeTitle;
  }

  return `${taskTypeTitle} • ${groupType.title}`;
};
