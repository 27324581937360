import Skeleton from "react-loading-skeleton";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { ReactNode } from "react";
import { BankGuaranteeDetailsDemandReconciliationContent } from "./BankGuaranteeDetailsDemandReconciliationContent";
import { Box } from "../../../../../../common/ui-kit/containers/box";

export interface BankGuaranteeDetailsDemandReconciliationContentSkeletonProps {
  containerTestId?: string;
  children?: ReactNode;
}

export const BankGuaranteeDetailsDemandReconciliationContentSkeleton = ({
  containerTestId,
  children,
}: BankGuaranteeDetailsDemandReconciliationContentSkeletonProps) => {
  const isDesktop = useThemeIsDesktop();

  return (
    <BankGuaranteeDetailsDemandReconciliationContent.Article data-testid={containerTestId}>
      <BankGuaranteeDetailsDemandReconciliationContent.Block withLineSeparator>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton width={100} />
          <Skeleton width={120} />
        </Box>
      </BankGuaranteeDetailsDemandReconciliationContent.Block>
      <BankGuaranteeDetailsDemandReconciliationContent.Block withLineSeparator>
        <Skeleton borderRadius={14} height={24} />
        <BankGuaranteeDetailsDemandReconciliationContent.Typography>
          <Skeleton width={120} />
        </BankGuaranteeDetailsDemandReconciliationContent.Typography>
      </BankGuaranteeDetailsDemandReconciliationContent.Block>
      {children}
      <BankGuaranteeDetailsDemandReconciliationContent.Block>
        <Box display="flex" alignItems="center" gap="lg">
          <Skeleton width={150} height={isDesktop ? 24 : 60} />
          <Skeleton width={150} height={isDesktop ? 24 : 60} />
        </Box>
      </BankGuaranteeDetailsDemandReconciliationContent.Block>
    </BankGuaranteeDetailsDemandReconciliationContent.Article>
  );
};
