import { BaseCssProps } from "../../../../common/ui-kit/types";
import { DemandQueueTaskCommentContainer } from "./DemandQueueTaskCommentContainer";
import { DemandQueueTaskCommentTextBody } from "./DemandQueueTaskCommentTextBody";
import { getFormattedCommentDateDemandQueueTasks } from "../../utils/getFormattedCommentDateDemandQueueTasks";

export interface DemandQueueTaskCommentProps extends BaseCssProps {
  author: string;
  text?: string;
  date?: Date;
}

export const DemandQueueTaskComment = ({ author, date, text, className }: DemandQueueTaskCommentProps) => {
  return (
    <DemandQueueTaskCommentContainer
      className={className}
      title={author}
      date={getFormattedCommentDateDemandQueueTasks(date)}
    >
      {/* eslint-disable-next-line react/no-children-prop */}
      {text && <DemandQueueTaskCommentTextBody children={text} />}
    </DemandQueueTaskCommentContainer>
  );
};
