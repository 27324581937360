import { useCallback } from "react";
import { useAppDispatch } from "../../../common/store";
import { useApiMutation } from "../../../common/hooks";
import { createFactoringCreation } from "../store/factoringCreation.thunks";
import { useAppNavigation } from "../../../common/urls/internal/hooks";
import { FactoringCreationFormValues } from "../types/FactoringCreationFormValues";
import { factoringCreationValidationSchema } from "../validation/factoringCreationValidationSchema";
import {
  CreateDebtorFactoringCreationInputDto,
  CreateFactoringCreationInputDto,
} from "../api/dto/input/CreateFactoringCreationInputDto";
import { pushErrorGlobalToast, pushSuccessGlobalToast } from "../../../global/toasts";
import { FactoringCreationDebtorDocumentFromValues } from "../types/FactoringCreationDebtorDocumentFromValues";

export const useSubmitFactoringCreationForm = () => {
  const dispatch = useAppDispatch();
  const { mutate } = useApiMutation(createFactoringCreation, { errorMessagePlaceholder: "" });
  const navigate = useAppNavigation();

  return useCallback(
    async (values: FactoringCreationFormValues) => {
      const castedValues = factoringCreationValidationSchema.cast(values, {
        stripUnknown: true,
      }) as FactoringCreationFormValues;

      const debtors = castedValues.debtorsIds
        .map((x: string): CreateDebtorFactoringCreationInputDto | undefined => {
          const debtor = castedValues.debtors?.[x];
          if (debtor === undefined) {
            return undefined;
          }

          return {
            debitorFinancingLimit: debtor.fundingLimit,
            debitorInn: debtor.taxpayerNumber,
            typedFileList: debtor.documentTempIds
              .map((y: string): CreateDebtorFactoringCreationInputDto["typedFileList"] | undefined => {
                const document = (debtor.documents as Record<string, FactoringCreationDebtorDocumentFromValues>)?.[y];

                if (!document) {
                  return undefined;
                }

                return { file: document.file as File, docTypeId: `${document.documentTypeId}` };
              })
              .filter((y: CreateDebtorFactoringCreationInputDto["typedFileList"] | undefined) => y !== undefined),
          };
        })
        .filter((x: CreateDebtorFactoringCreationInputDto | undefined) => x !== undefined);

      const dto: CreateFactoringCreationInputDto = {
        inn: castedValues.customerTaxpayerNumber,
        deferral: castedValues.delay,
        creditLoad: castedValues.creditLoad,
        avgMonthlyShipment: castedValues.averageMonthly,
        revenue: castedValues.revenue,
        factoringDebitors: debtors,
      };

      const result = await mutate(dto);
      if (result?.body) {
        dispatch(pushSuccessGlobalToast("Заявка на факторинг отправлена"));
        navigate((x) => x.root);
      } else {
        dispatch(pushErrorGlobalToast("Не удалось отправить заявку на факторинг"));
      }
    },
    [dispatch, mutate, navigate],
  );
};
