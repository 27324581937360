import styled from "@emotion/styled";
import { BaseInput } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseInput";
import { getSizes } from "@sideg/ui-kit/atoms/form-controls/input/common/utils/getInputSize";
import { Icon } from "@sideg/ui-kit/atoms/icons";
import { CommonInputProps } from "@sideg/ui-kit/atoms/form-controls/input/common/types/CommonInputProps";
import { ControlSize } from "@sideg/ui-kit/common/types";
import { getIconSize } from "@sideg/ui-kit/atoms/form-controls/input/common/utils/getIconSize";

export const CommonInputIcon = styled(Icon)<{ size: ControlSize }>(({ size }) => ({
  ...getIconSize(size),
}));

export const CommonInput = styled(BaseInput.withComponent("input"))<CommonInputProps>(({ theme, inputSize }) => ({
  ...getSizes(theme, inputSize, false),

  [theme.breakpoints.mqUpDesktop()]: {
    ...getSizes(theme, inputSize, true),
  },
}));
