import styled from "@emotion/styled";
import { CreationBlockCard } from "../../../../common/ui-kit/cards/creation-block-card";
import { Text } from "../../../../common/ui-kit/typography";

export const Card = styled(CreationBlockCard.withComponent("article"))`
  min-height: 100px;
  height: 100%;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.md};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    box-shadow: none;
  }
`;

export const Header = styled.header`
  padding: ${({ theme }) => theme.spaces.md};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding: 0;
  }
`;

export const Title = styled(Text)`
  & > a {
    color: ${({ theme }) => theme.palette.typography.primary};
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const Body = styled.p`
  color: ${({ theme }) => theme.palette.typography.primary};
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 1.6;
  word-break: break-word;

  padding: 0 ${({ theme }) => theme.spaces.md};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding: 0;
  }
`;

export const Footer = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryLight};

  & > a {
    display: block;
    text-align: center;
    padding: ${({ theme }) => theme.spaces.md} 0;

    ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
      padding: 0;
      text-align: left;
      display: inline;
    }
  }

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding: ${({ theme }) => theme.spaces.sm} 0;
    margin: 0;
    border-top: none;
  }
`;
