import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import {
  getQueueTasksNotifications,
  QUEUE_TASKS_NOTIFICATIONS_SLICE_NAME,
  readQueueTasksNotification,
} from "./queueTasksNotifications.thunks";
import { queueTasksNotificationsAdapter } from "./queueTasksNotifications.adapter";
import { QueueTasksNotificationsSliceState } from "./queueTasksNotifications.types";
import { completeQueueTask, queueTasksNeedUpdate } from "../../common/store";
import { getQueueTasksCounters } from "../../counters/store/queueTasksCounters.thunks";
import { getQueueTasksListCounters } from "../../list/store/queueTasksList.thunks";

const initialState = queueTasksNotificationsAdapter.getInitialState<QueueTasksNotificationsSliceState>({
  status: LoadingStatusEnum.Idle,
});

const slice = createSlice({
  name: QUEUE_TASKS_NOTIFICATIONS_SLICE_NAME,
  initialState,
  reducers: {
    update: (state) => {
      state.status = LoadingStatusEnum.Idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(queueTasksNeedUpdate, (state, { payload }) => {
        if (
          !state.lastUpdate ||
          state.lastUpdate.queueTaskId !== payload.queueTaskId ||
          state.lastUpdate.actionType !== payload.actionType
        ) {
          state.lastUpdate = {
            queueTaskId: payload.queueTaskId,
            actionType: payload.actionType,
          };

          if (payload.queueTask && payload.actionType === "Updated") {
            queueTasksNotificationsAdapter.updateOne(state, { id: payload.queueTaskId, changes: payload.queueTask });
          }

          if (payload.queueTask && payload.actionType === "Cancelled") {
            queueTasksNotificationsAdapter.removeOne(state, payload.queueTaskId);
          }

          state.status = LoadingStatusEnum.Idle;
        }
      })
      .addCase(getQueueTasksNotifications.fulfilled, (state, action) => {
        queueTasksNotificationsAdapter.setAll(state, action.payload.body.data);

        state.pagination = action.payload.body.paginationInformation;
      })
      .addCase(getQueueTasksCounters.fulfilled, (state, action) => {
        if (state.pagination && state.pagination.totalCount !== action.payload.body.data.countQueueTasksNew) {
          state.status = LoadingStatusEnum.Idle;
        }
      })
      .addCase(getQueueTasksListCounters.fulfilled, (state, action) => {
        if (state.pagination && state.pagination.totalCount !== action.payload.body.newCounter) {
          state.status = LoadingStatusEnum.Idle;
        }
      })
      .addCase(readQueueTasksNotification.fulfilled, (state, action) => {
        if (state.ids.includes(action.meta.arg)) {
          queueTasksNotificationsAdapter.removeOne(state, action.meta.arg);
        }
        state.status = LoadingStatusEnum.Idle;
      })
      .addCase(completeQueueTask.fulfilled, (state, action) => {
        if (state.ids.includes(action.meta.arg.queueTaskId)) {
          queueTasksNotificationsAdapter.removeOne(state, action.meta.arg.queueTaskId);
        }
        state.status = LoadingStatusEnum.Idle;
      })
      .addCase(getQueueTasksNotifications.rejected, (state, action) => {
        state.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(getQueueTasksNotifications), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      })
      .addMatcher(isAsyncThunkAction(readQueueTasksNotification), (state, action) => {
        const queueTaskId = action.meta.arg;
        queueTasksNotificationsAdapter.updateOne(state, {
          id: queueTaskId,
          changes: { read: { status: getLoadingStatusFromAction(action) } },
        });
      })
      .addMatcher(isAsyncThunkAction(completeQueueTask), (state, action) => {
        const { queueTaskId } = action.meta.arg;
        queueTasksNotificationsAdapter.updateOne(state, {
          id: queueTaskId,
          changes: { action: { status: getLoadingStatusFromAction(action) } },
        });
      });
  },
});

export const { update: queueTasksNotificationsUpdate } = slice.actions;

export const queueTasksNotificationsReducer = slice.reducer;
