import { RootState } from "../../../../common/store";
import { CreditWorkingAssetsDetailsActiveDemandState } from "./creditWorkingAssetsDetailsActiveDemand.types";
import { creditWorkingAssetsDetailsActiveDemandDocumentsAdapter } from "./creditWorkingAssetsDetailsActiveDemandDocuments.adapter";
import { selectCreditWorkingAssetsDetails } from "../creditWorkingAssetsDetails.selectors";

export const selectCreditWorkingAssetsDetailsActiveDemand = (
  state: RootState
): CreditWorkingAssetsDetailsActiveDemandState => selectCreditWorkingAssetsDetails(state).activeDemand;

export const { selectAll: selectCreditWorkingAssetsDetailsActiveDemandDocuments } =
  creditWorkingAssetsDetailsActiveDemandDocumentsAdapter.getSelectors(
    (state: RootState) => selectCreditWorkingAssetsDetailsActiveDemand(state).documents
  );
