import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  ReferenceType,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useMemo, useRef, useState } from "react";

export interface UsePopoverOptions {
  initialOpen?: boolean;
  placement?: Placement;
  modal?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  offset?: [number, number];
  stayOpenedWhenClickOutside?: boolean;
}

export const usePopover = <T extends ReferenceType = ReferenceType>({
  initialOpen = false,
  placement = "bottom",
  modal,
  offset: offsetValue,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  stayOpenedWhenClickOutside,
}: UsePopoverOptions = {}) => {
  const arrowRef = useRef<HTMLElement | null>(null);
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const [xOffset, yOffset] = offsetValue ?? [undefined, undefined];

  const data = useFloating<T>({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetValue === undefined ? undefined : { crossAxis: xOffset, mainAxis: yOffset }),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "end",
        padding: xOffset,
      }),
      arrow({
        element: arrowRef,
      }),
      shift({ padding: xOffset }),
    ],
  });

  const { context } = data;

  const click = useClick(context, {
    // eslint-disable-next-line no-eq-null
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context, { enabled: stayOpenedWhenClickOutside !== true });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      arrowRef,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId],
  );
};
