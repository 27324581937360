import { BankGuaranteeId } from "../../../common/types/demand";
import { useBankGuaranteeRejectionSubmitHandler } from "../hooks/useBankGuaranteeRejectionSubmitHandler";
import { ProductRejection } from "../../../common/product-rejection";
import { AppLayoutOverlayPanelKey } from "../../../common/ui/layout/app-layout/types";

interface BankGuaranteeRejectionResponsiveViewProps {
  isOpen: boolean;
  afterSuccess: () => Promise<void>;
  guaranteeId: BankGuaranteeId;
  onClose: () => void | Promise<void>;
  panelKey?: AppLayoutOverlayPanelKey;
}

export const BankGuaranteeRejectionRejectGuaranteeResponsiveView = ({
  guaranteeId,
  afterSuccess,
  isOpen,
  onClose,
  panelKey,
}: BankGuaranteeRejectionResponsiveViewProps) => {
  const { handleFormSubmit, error } = useBankGuaranteeRejectionSubmitHandler("product", { guaranteeId }, afterSuccess);

  return (
    <ProductRejection
      error={error}
      isOpen={isOpen}
      product="BankGuarantee"
      onClose={onClose}
      title="Отмена заявки на БГ"
      description={`Будет отменена заявка № ${guaranteeId} во все банки`}
      submitHandler={handleFormSubmit}
      panelKey={panelKey}
    />
  );
};
