import { Formik } from "formik";
import { CommentFormFormValues } from "../../../../../../common/product-comment";
import { bankGuaranteeDetailsDemandNewCommentFormWithFiltersValidationSchema } from "../../../../validation/bankGuaranteeDetailsNewCommentValidationSchema";
import { BankGuaranteeDetailsDemandNewCommentCardFormFieldsWithFilters } from "./BankGuaranteeDetailsDemandNewCommentCardFormFieldsWithFilters";
import { useBankGuaranteeDetailsFilterContext } from "../../../../hooks/filters/context/useBankGuaranteeDetailsFilterContext";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";

export interface BankGuaranteeDetailsDemandNewCommentCardFormWithFiltersProps extends BaseCssProps {
  onSubmit: (values: CommentFormFormValues) => void | Promise<void>;
  onCancel: () => void;
  isNeedFilters?: boolean;
}

export const BankGuaranteeDetailsDemandNewCommentCardFormWithFilters = ({
  className,
  onCancel,
  onSubmit,
  isNeedFilters,
}: BankGuaranteeDetailsDemandNewCommentCardFormWithFiltersProps) => {
  const context = useBankGuaranteeDetailsFilterContext();
  const initialValues = bankGuaranteeDetailsDemandNewCommentFormWithFiltersValidationSchema.cast({
    groupType: context.queueTaskGroup,
  }) as CommentFormFormValues;

  return (
    <Formik<CommentFormFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={bankGuaranteeDetailsDemandNewCommentFormWithFiltersValidationSchema}
    >
      <BankGuaranteeDetailsDemandNewCommentCardFormFieldsWithFilters
        className={className}
        onCancel={onCancel}
        isNeedFilters={isNeedFilters}
      />
    </Formik>
  );
};
