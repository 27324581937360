import { numberHelper } from "@sideg/helpers";

const DECIMAL_PLACES = 2;

const round = (value: number) => {
  return numberHelper.round(value, DECIMAL_PLACES);
};

const calculatePercent = (commission: number, period: number, sum: number): number => {
  const percent = (((commission / period) * 365) / sum) * 100;

  return round(percent);
};

const calculateSum = (commission: number, period: number, percent: number): number => {
  const sum = (365 * commission) / period / (percent / 100);

  return round(sum);
};

const calculateCommission = (period: number, sum: number, percent: number): number => {
  const commission = (period * sum * (percent / 100)) / 365;

  return round(commission);
};

export const guaranteeCommissionCalculationHelper = {
  calculatePercent,
  calculateSum,
  calculateCommission,
};
