const CertificateAdjuster = Object.create(null);

CertificateAdjuster.extract = function extract(from, what) {
  let certName = "";

  const begin = from.indexOf(what);

  if (begin >= 0) {
    const end = from.indexOf(", ", begin);
    certName = end < 0 ? from.substr(begin) : from.substr(begin, end - begin);
  }

  return certName;
};

CertificateAdjuster.getCertName = function getCertName(certSubjectName) {
  return (
    this.extract(certSubjectName, "SN=") +
    ", " +
    this.extract(certSubjectName, "G=") +
    ", " +
    this.extract(certSubjectName, "CN=")
  );
};

CertificateAdjuster.print2Digit = function print2Digit(digit) {
  return digit < 10 ? "0" + digit : digit;
};

CertificateAdjuster.getCertDate = function getCertDate(paramDate) {
  const certDate = new Date(paramDate);
  return (
    this.print2Digit(certDate.getDate()) +
    "." +
    this.print2Digit(certDate.getMonth() + 1) +
    "." +
    certDate.getFullYear()
  );
};

export default CertificateAdjuster;
