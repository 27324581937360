import * as React from "react";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";
import * as S from "./FilterMobileCollapseContainer.styled";
import { ChevronLeftIcon } from "../../../../../ui-kit/icons";
import { Text } from "../../../../../ui-kit/typography";

export interface FilterMobileCollapseContainerProps {
  isOpened: boolean;
  onClick: () => void | Promise<void>;
  title: string;
  children: React.ReactNode;
  className?: string;
}

export const FilterMobileCollapseContainer = ({
  title,
  children,
  isOpened,
  onClick,
  className,
}: FilterMobileCollapseContainerProps) => {
  return (
    <StackPanel as="section" gap="none" direction="column" className={className}>
      <S.Button isOpened={isOpened} type="button" onClick={onClick}>
        <StackPanel gap="sm" direction="row" justifyContent="space-between" alignItems="center">
          <Text fontColor="colors.dark" fontSize="fz4" lineHeight="defaultLineHeight">
            {title}
          </Text>
          <ChevronLeftIcon />
        </StackPanel>
      </S.Button>
      {isOpened && <S.Container>{children}</S.Container>}
    </StackPanel>
  );
};
