import { createSlice, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { BankGuaranteeBriefState } from "./bankGuaranteeBrief.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { BANK_GUARANTEE_BRIEF_SLICE_NAME, getBankGuaranteeBrief } from "./bankGuaranteeBrief.thunks";
import { BankGuaranteeId } from "../../../common/types/demand";

const initialState: BankGuaranteeBriefState = {
  data: undefined,
  status: LoadingStatusEnum.Idle,
};

const slice = createSlice({
  name: BANK_GUARANTEE_BRIEF_SLICE_NAME,
  initialState,
  reducers: {
    actualize: (state: BankGuaranteeBriefState, action: PayloadAction<BankGuaranteeId>) => {
      if (action.payload !== state.data?.id) {
        return initialState;
      }

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankGuaranteeBrief.fulfilled, (state, { payload }) => {
        state.data = payload.body;
      })
      .addMatcher(isAsyncThunkAction(getBankGuaranteeBrief), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const bankGuaranteeBriefReducer = slice.reducer;

export const { actualize: actualizeBankGuaranteeBrief } = slice.actions;
