import { useEffect, useState } from "react";
import { useResponsiveContext } from "@sideg/ui-kit";

export const useAppLayoutOverflowScrollReset = (isFixedOverflow: boolean) => {
  const { isProbablyMobile } = useResponsiveContext();

  const [yPosition, setYPosition] = useState<undefined | number>();

  useEffect(() => {
    if (isFixedOverflow && isProbablyMobile) {
      setYPosition(window.scrollY);
    }
  }, [isFixedOverflow, isProbablyMobile]);

  useEffect(() => {
    if (!isFixedOverflow && yPosition && isProbablyMobile) {
      window.requestAnimationFrame(() => {
        window.scrollTo(0, yPosition);
      });

      setYPosition(undefined);
    }
  }, [isFixedOverflow, isProbablyMobile, yPosition]);
};
