import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { BankGuaranteeId, DemandId } from "../../../../../../../common/types/demand";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { useAppSelector } from "../../../../../../../common/store";
import { selectBankGuaranteeListItemsDemandById } from "../../../../../store/items/bankGuaranteeListItems.selectors";
import { StackPanel } from "../../../../../../../common/ui-kit/containers/stack-panel";
import { CircleIndicator } from "../../../../../../../common/ui-kit/notifications/circle-indicator";
import { Text } from "../../../../../../../common/ui-kit/typography";
import { FileDownloadLabel } from "../../../../../../../files/download";
import { BankGuaranteeExtractFromRegistryWrapper } from "../../../../../../extract-from-registry";
import { BankGuaranteeListItemReissueButton } from "../../buttons/BankGuaranteeListItemReissueButton";
import { BankGuaranteeListItemRequestOriginalButton } from "../../buttons/BankGuaranteeListItemRequestOriginalButton";
import { BankGuaranteeRequestOriginalLabelLink } from "../../../../../../request-original";
import { useBankGuaranteeListItemTransferPayloadOnClick } from "../../../../../hooks/list/useBankGuaranteeListItemTransferPayloadOnClick";
import { IssueLink } from "../../../../../../../common/ui/product/list/product-list-item";

const BoxText = Box.withComponent(Text.withComponent("div"));

export interface BankGuaranteeListItemDesktopIssueProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  from?: NavigationState;
}

export const BankGuaranteeListItemDesktopIssue = ({
  guaranteeId,
  demandId,
  from,
  className,
}: BankGuaranteeListItemDesktopIssueProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectBankGuaranteeListItemsDemandById(state, demandId))!;
  const onDemandLinkClick = useBankGuaranteeListItemTransferPayloadOnClick(guaranteeId);

  return (
    <Box className={className} display="flex" flexDirection="column" gap="sm">
      <StackPanel gap="sm" direction="row" alignItems="center">
        <IssueLink
          to={(x) => x.bankGuarantee.details.demand}
          onClick={onDemandLinkClick}
          params={{ demandId, guaranteeId }}
          state={from}
        >
          Банковская гарантия выпущена
        </IssueLink>
        {demand.isUpdated && (
          <CircleIndicator color={(theme) => theme.secondaryColors.notificationBackground} size={8} />
        )}
      </StackPanel>
      <BoxText display="flex" gap="sm" fontWeight={500} lineHeight="defaultLineHeight" fontSize="fz2">
        <span>{demand.bank.title}</span>
        {demand.approval?.issueDate && (
          <span>
            {dateTimeHelper.format(new Date(demand.approval.issueDate), specialFormatters.dayFullMonthYearIfDifferent)}
          </span>
        )}
      </BoxText>
      <Box py="sm" display="flex" flexDirection="column" gap="md" alignItems="flex-start">
        <StackPanel gap="md" direction="row" alignItems="center">
          {demand.approval?.original && (
            <FileDownloadLabel
              fileId={demand.approval.original.fileId}
              privateKey={demand.approval.original.privateKey}
              fileName="Скан-копия гарантии"
            />
          )}
          {demand.approval?.fileRegistryStatus && (
            <BankGuaranteeExtractFromRegistryWrapper
              guaranteeId={guaranteeId}
              demandId={demandId}
              statusType={demand.approval.fileRegistryStatus.code}
            />
          )}
        </StackPanel>
        {demand.approval?.bankDocument && (
          <FileDownloadLabel
            fileId={demand.approval.bankDocument.fileId}
            privateKey={demand.approval.bankDocument.privateKey}
            fileName="Доверенность / лицензия"
          />
        )}
      </Box>

      <StackPanel gap="md" direction="row" alignItems="center">
        <BankGuaranteeListItemReissueButton guaranteeId={guaranteeId} demandId={demandId} />
        {demand.approval?.requestOriginalStatus?.code !== undefined && (
          <BankGuaranteeRequestOriginalLabelLink
            guaranteeId={guaranteeId}
            demandId={demandId}
            from={from}
            requestOriginalType={demand.approval.requestOriginalStatus.code}
            isWithNotification={demand.approval.requestOriginalStatus.code === "Rework"}
            onClick={onDemandLinkClick}
          />
        )}
        {demand.approval?.requestOriginalStatus?.code === undefined && (
          <BankGuaranteeListItemRequestOriginalButton guaranteeId={guaranteeId} demandId={demandId} />
        )}
      </StackPanel>
    </Box>
  );
};
