import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useSelectFormElementErrorElements } from "../../ui-kit/form-controls/error";

export const useFormScrollToFirstErrorAfterSubmitEffect = (container: HTMLElement | null) => {
  const { isValid, submitCount, isSubmitting } = useFormikContext();

  const [initialSubmitCount] = useState(submitCount);
  const [prevSubmitCount, setPrevSubmitCount] = useState<number | undefined>(undefined);

  const selectErrorElements = useSelectFormElementErrorElements();

  useEffect(() => {
    // для кейсов, когда submitCount поменялся, но форма еще сабмитится и нет таргета для скролла.
    const isAfterNextSubmit = (previousCount: number | undefined): boolean =>
      (previousCount === undefined || previousCount === submitCount - 1) &&
      !isSubmitting &&
      initialSubmitCount !== submitCount;

    setPrevSubmitCount((prev) => (isAfterNextSubmit(prev) ? submitCount : prev));
  }, [initialSubmitCount, isSubmitting, prevSubmitCount, submitCount]);

  useEffect(() => {
    if (prevSubmitCount === submitCount && container !== null && !isValid) {
      const elements = selectErrorElements(container);

      if (elements.length > 0) {
        elements[0].scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }

    // специально игнорируем другие зависимости, чтобы не зависеть от container и isValid формы
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevSubmitCount, submitCount]);
};
