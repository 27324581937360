import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { CreditExecutionListHeaderFiltersMobile } from "./mobile/CreditExecutionListHeaderFilters.mobile";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditExecutionListHeaderFiltersDesktop } from "./desktop/CreditExecutionListHeaderFilters.desktop";

export type CreditExecutionListHeaderFiltersProps = BaseCssProps;

export const CreditExecutionListHeaderFilters = ({ className }: CreditExecutionListHeaderFiltersProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={<CreditExecutionListHeaderFiltersMobile className={className} />}
      desktop={<CreditExecutionListHeaderFiltersDesktop className={className} />}
    />
  );
};
