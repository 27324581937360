import { useCallback } from "react";
import { QueueTasksListFilterParams } from "../types/filter";
import { useSetActualPathnameQuerySearch, filtrationService, FilteringArrayKeys } from "../../../common/filters";
import { getSchemaFieldsKeysOfType } from "../../../common/validation/utils/getSchemaFieldsKeysOfType";
import { queueTasksListFiltersValidationSchema } from "../validation/queueTasksListFiltersValidationSchema";

type MutationType = "add" | "remove";

export type QueueTasksListQueryStateMutator = <
  TFilter extends QueueTasksListFilterParams,
  TKey extends keyof TFilter,
  TValue extends TFilter[TKey] extends (infer U)[] | undefined ? U : TFilter[TKey],
>(
  filteringObject: TFilter,
  mutationType: MutationType,
  key: TKey,
  value?: TValue,
) => void;

export const useQueueTasksListQueryStateMutator = <TSchema extends typeof queueTasksListFiltersValidationSchema>(
  schema: TSchema,
) => {
  const setQuery = useSetActualPathnameQuerySearch();

  return useCallback(
    <
      TFilter extends QueueTasksListFilterParams,
      TKey extends keyof TFilter,
      TValue extends TFilter[TKey] extends (infer U)[] | undefined ? U : TFilter[TKey],
    >(
      filteringObject: TFilter,
      mutationType: MutationType,
      key: TKey,
      value?: TValue,
    ) => {
      let result = filteringObject;
      const arrayKeys = getSchemaFieldsKeysOfType(schema, "array") as FilteringArrayKeys<TFilter>[];

      if (key !== "page") {
        result = filtrationService.setValue(result, "page", 1);
      }

      const arrayKey = arrayKeys.find((x) => x === (key as FilteringArrayKeys<QueueTasksListFilterParams>));

      if (arrayKey && value !== undefined) {
        result =
          mutationType === "add"
            ? filtrationService.addArrayValue(result, arrayKey, value as string)
            : filtrationService.removeArrayValue(result, arrayKey, value as string);
      } else {
        result =
          mutationType === "add"
            ? filtrationService.setValue(result, key, value as TFilter[TKey])
            : filtrationService.removeValue(result, key);
      }

      setQuery(result);
    },
    [schema, setQuery],
  );
};
