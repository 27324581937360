import { BankGuaranteeDetailsPageLayoutContext } from "../../store/layout-context/BankGuaranteeDetailsPageLayoutContext";
import { useContextInside } from "../../../../common/hooks";
import { BankGuaranteeDetailsLayoutContext } from "../../types/layout/BankGuaranteeDetailsLayoutContext";
import { DemandId } from "../../../../common/types/demand";

export const useBankGuaranteeDetailsLayoutContext = () => useContextInside(BankGuaranteeDetailsPageLayoutContext);

type UseBankGuaranteeDetailsWithRequiredDemandIdLayoutContextResult = BankGuaranteeDetailsLayoutContext & {
  demandId: DemandId;
};

export const useBankGuaranteeDetailsWithRequiredDemandIdLayoutContext =
  (): UseBankGuaranteeDetailsWithRequiredDemandIdLayoutContextResult => {
    return useBankGuaranteeDetailsLayoutContext() as UseBankGuaranteeDetailsWithRequiredDemandIdLayoutContextResult;
  };
