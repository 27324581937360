import * as React from "react";

export interface ConditionalViewProps {
  children?: React.ReactNode;
  condition: boolean;
}

export const ConditionalView = ({ children, condition }: ConditionalViewProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{condition && children}</>
  );
};
