import { ProductRejectionCommonProps } from "./ProductRejectionCommonProps";
import { ProductRejectionForm } from "./form/ProductRejectionForm";
import { Modal } from "../../ui/containers/modal";
import { Text } from "../../ui-kit/typography";
import { Box } from "../../ui-kit/containers/box";

const BoxText = Box.withComponent(Text);

export const ProductRejectionDesktop = ({
  product,
  error,
  submitHandler,
  description,
  onClose,
  isOpen,
  title,
}: Omit<ProductRejectionCommonProps, "panelKey">) => {
  return (
    <Modal onClose={onClose} isShown={isOpen} title={title} noCloseOnClickOutside noClip width="100%">
      <BoxText as="div" pb="xxl" fontWeight={500} fontColor="primary" fontSize="fz4" lineHeight="defaultLineHeight">
        {description}
      </BoxText>
      <ProductRejectionForm handleFormSubmit={submitHandler} error={error} product={product} />
    </Modal>
  );
};
