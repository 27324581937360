import Skeleton from "react-loading-skeleton";
import { Box } from "../../../common/ui-kit/containers/box";
import { BoxProps } from "../../../common/ui-kit/containers/box/components/Box";

export interface UserProfileInfoSkeletonPairSize {
  firstSkeletonWidth: number | string;
  secondSkeletonWidth: number | string;
  firstSkeletonHeight: number | string;
  secondSkeletonHeight: number | string;
}

interface UserProfileInfoSkeletonPairProps extends UserProfileInfoSkeletonPairSize, BoxProps {}

export const UserProfileInfoSkeletonPair = ({
  firstSkeletonWidth,
  secondSkeletonWidth,
  firstSkeletonHeight,
  secondSkeletonHeight,
  ...rest
}: UserProfileInfoSkeletonPairProps) => {
  return (
    <Box display="flex" gap="xs" {...rest}>
      <Skeleton height={firstSkeletonHeight} width={firstSkeletonWidth} />
      <Skeleton height={secondSkeletonHeight} width={secondSkeletonWidth} />
    </Box>
  );
};
