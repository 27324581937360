import * as S from "./TabCounter.styled";

export interface TabCounterProps {
  children: string | number;
  className?: string;
}

export const TabCounter = ({ children, className }: TabCounterProps) => {
  return <S.Counter className={className}>{children}</S.Counter>;
};
