import { PublicUserCardWithTitle } from "../../../common/components";
import { PublicUserRegistrationPageLayout } from "../../pages/PublicUserRegistrationPageLayout";
import {
  PublicUserRegistrationCommonForm,
  PublicUserRegistrationCommonFormProps,
} from "../common-form/PublicUserRegistrationCommonForm";
import { usePublicUserRegistrationRegisterAgentFormOnSubmit } from "../../hooks/agent/usePublicUserRegistrationRegisterAgentFormOnSubmit";
import { PublicUserRegistrationFormInitialState } from "../../types/PublicUserRegistrationFormInitialState";
import { usePageTitle } from "../../../../common/navigation/page-title";

export type PublicUserRegistrationAgentFormProps = Pick<
  PublicUserRegistrationCommonFormProps,
  keyof PublicUserRegistrationFormInitialState
>;

export const PublicUserRegistrationAgentForm = ({
  initialValues,
  initialTouched,
  isNeedToValidateOnMount,
}: PublicUserRegistrationAgentFormProps) => {
  usePageTitle("Регистрация агента");

  const onSubmit = usePublicUserRegistrationRegisterAgentFormOnSubmit();

  return (
    <PublicUserRegistrationPageLayout>
      <PublicUserCardWithTitle title="Регистрация агента">
        <PublicUserRegistrationCommonForm
          agencyType="Agent"
          onSubmit={onSubmit}
          initialValues={initialValues}
          isNeedToValidateOnMount={isNeedToValidateOnMount}
          initialTouched={initialTouched}
        />
      </PublicUserCardWithTitle>
    </PublicUserRegistrationPageLayout>
  );
};
