import { AsideBlockMobile } from "../../../../ui/layout/sidebar";
import { NavigationMenuUlMobile } from "./NavigationMenuUl.mobile";
import { useAppMenuItems } from "../../hooks/useAppMenuItems";

export const NavigationMenuMobile = () => {
  const items = useAppMenuItems();

  return (
    <AsideBlockMobile>
      <nav>
        <NavigationMenuUlMobile items={items} />
      </nav>
    </AsideBlockMobile>
  );
};
