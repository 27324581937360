import { useMemo } from "react";
import { Link } from "react-router-dom";
import { HeaderAction } from "../components/header-action/HeaderAction";
import { useAppLayoutHeaderActionEffect } from "../../app-layout-containers";

interface UseBackLinkHeaderActionArgs {
  url: string;
  text?: string;
  withBackIcon?: boolean;
}

export const useBackLinkHeaderAction = ({ withBackIcon, text = "Отмена", url }: UseBackLinkHeaderActionArgs) => {
  const backLink = useMemo(() => {
    return (
      <HeaderAction withComponent={Link} to={url} withBackIcon={withBackIcon}>
        {text}
      </HeaderAction>
    );
  }, [url, text, withBackIcon]);

  useAppLayoutHeaderActionEffect(backLink);
};
