import Skeleton from "react-loading-skeleton";
import { BankGuaranteeRewardsTermItem } from "./BankGuaranteeRewardsTermItem";

export const BankGuaranteeRewardsItemSkeleton = () => {
  return (
    <BankGuaranteeRewardsTermItem
      rate={<Skeleton width={70} />}
      dateStart={<Skeleton width={150} />}
      dateEnd={<Skeleton width={150} />}
    >
      <Skeleton width={150} />
      <Skeleton width={60} />
      <Skeleton width={90} />
      <Skeleton width={130} />
    </BankGuaranteeRewardsTermItem>
  );
};
