import { dateTimeHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditWorkingAssetsId } from "../../../../../common/types/demand";
import { useAppSelector } from "../../../../../common/store";
import {
  selectCreditWorkingAssetsListItemsById,
  selectCreditWorkingAssetsListItemsClientById,
} from "../../../store/items/creditWorkingAssetsListItems.selectors";
import { CreditWorkingAssetsListItemCardDesktop } from "./card/CreditWorkingAssetsListItemCard.desktop";
import { CreditWorkingAssetsListItemDemandsDesktop } from "./demands/CreditWorkingAssetsListItemDemands.desktop";
import { NavigationState } from "../../../../../common/navigation/navigation-state";
import { CreditWorkingAssetsListItemDetails } from "../details/CreditWorkingAssetsListItemDetails";

export interface CreditWorkingAssetsListItemDesktopProps extends BaseCssProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
  from: NavigationState;
}

export const CreditWorkingAssetsListItemDesktop = ({
  creditWorkingAssetsId,
  from,
  className,
}: CreditWorkingAssetsListItemDesktopProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const item = useAppSelector((state) => selectCreditWorkingAssetsListItemsById(state, creditWorkingAssetsId))!;
  const client = useAppSelector((state) => selectCreditWorkingAssetsListItemsClientById(state, item.clientId));

  return (
    <CreditWorkingAssetsListItemCardDesktop
      clientName={client?.name}
      clientTaxpayerNumber={client?.taxpayerNumber}
      className={className}
      numberAndDate={`Заявка № ${item.groupProductName} • ${dateTimeHelper.format(
        new Date(item.dateAdded),
        "dayShortMonthYear",
      )}`}
      details={<CreditWorkingAssetsListItemDetails item={item} />}
    >
      <CreditWorkingAssetsListItemDemandsDesktop creditWorkingAssetsId={creditWorkingAssetsId} from={from} />
    </CreditWorkingAssetsListItemCardDesktop>
  );
};
