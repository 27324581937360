import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useBankGuaranteeListFilterContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterContext";
import { FilterMultipleSelectList } from "../../../../../../common/ui-kit/form-controls/select";
import { BankGuaranteeRequestOriginalStatus, bankGuaranteeRequestOriginalStatus } from "../../../../../common/types";
import { useBankGuaranteeListFilterApiContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterApiContext";

type BankGuaranteeListFiltersRequestOriginalStatusesProps = BaseCssProps;

export const BankGuaranteeListFiltersRequestOriginalStatuses = ({
  className,
}: BankGuaranteeListFiltersRequestOriginalStatusesProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();

  const mutator = useBankGuaranteeListFilterApiContext();

  const onSelectedChange = (selectedItems: BankGuaranteeRequestOriginalStatus[]) => {
    mutator(filteringObject, (filter, { setValue }) => setValue(filter, "requestOriginalStatuses", selectedItems));
  };

  return (
    <FilterMultipleSelectList
      className={className}
      text="Запрос оригинала"
      selectedItems={filteringObject.requestOriginalStatuses}
      getItemTitle={bankGuaranteeRequestOriginalStatus.getTitle}
      onSelectedChanged={onSelectedChange}
      getItems={() => bankGuaranteeRequestOriginalStatus.values}
      maxItemsLength={Math.max(
        ...bankGuaranteeRequestOriginalStatus.values.map(
          (x) => bankGuaranteeRequestOriginalStatus.getTitle(x).replace(/\s/g, "").length
        )
      )}
    />
  );
};
