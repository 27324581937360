import { forwardRef } from "react";
import { BankGuaranteeDetailsDemandActiveTaskCommonProps } from "../../../../types/demand/tasks/BankGuaranteeDetailsDemandTaskCommonProps";
import { BankGuaranteeDetailsComplicatedTaskProjectCard } from "../task/BankGuaranteeDetailsComplicatedTaskProjectCard";
import { BankGuaranteeDetailComplicatedTaskProjectActiveForm } from "./form/BankGuaranteeDetailComplicatedTaskProjectActiveForm";
import {
  DemandQueueTaskSeparator,
  isDemandQueueTaskActiveActionHasMessageOrProvidedFiles,
} from "../../../../../../demand/queue-tasks";
import { DemandQueueTaskComment } from "../../../../../../demand/queue-tasks/components/comment/DemandQueueTaskComment";
import { DemandQueueTaskAttachedFilesCollapse } from "../../../../../../demand/queue-tasks/components/attached-file/DemandQueueTaskAttachedFilesCollapse";
import {
  getUseBankGuaranteeDetailsComplicatedTaskDateCreatedOptionsByActiveTask,
  useBankGuaranteeDetailsComplicatedTaskDateCreated,
} from "../../../common/hooks/useBankGuaranteeDetailsComplicatedTaskDateCreated";

export const BankGuaranteeDetailComplicatedTaskProjectActive = forwardRef<
  HTMLDivElement,
  BankGuaranteeDetailsDemandActiveTaskCommonProps
>(({ task, className, isFocused }, ref) => {
  const { date, placement } = useBankGuaranteeDetailsComplicatedTaskDateCreated(
    getUseBankGuaranteeDetailsComplicatedTaskDateCreatedOptionsByActiveTask(task),
  );

  return (
    <BankGuaranteeDetailsComplicatedTaskProjectCard
      ref={ref}
      isFocused={isFocused}
      isPinned={task.isPinned}
      taskTitle={task.taskTitle}
      taskGroupType={task.groupType}
      dateCreated={date}
      dateCreatedPlacement={placement}
      variant="active"
      className={className}
      file={task.projectInfo?.projectFile}
      managerComment={
        isDemandQueueTaskActiveActionHasMessageOrProvidedFiles(task.action) && (
          <>
            <DemandQueueTaskComment
              date={placement === "comment" ? date : undefined}
              author="Менеджер банка"
              text={task.action.message}
            />
            {task.action.providedFiles && (
              <DemandQueueTaskAttachedFilesCollapse
                files={task.action.providedFiles.map((x) => ({ title: "", ...x.file }))}
              />
            )}
            <DemandQueueTaskSeparator />
          </>
        )
      }
    >
      <BankGuaranteeDetailComplicatedTaskProjectActiveForm task={task} />
    </BankGuaranteeDetailsComplicatedTaskProjectCard>
  );
});
