import { CreditExecutionId, DemandId } from "../../../../common/types/demand";
import { useCreditExecutionRejectionSubmitHandler } from "../hooks/useCreditExecutionRejectionSubmitHandler";
import { ProductRejection } from "../../../../common/product-rejection";
import { AppLayoutOverlayPanelKey } from "../../../../common/ui/layout/app-layout/types";

export interface CreditExecutionRejectionDemandProps {
  creditExecutionId: CreditExecutionId;
  demandId: DemandId;
  afterSuccess: () => Promise<void>;
  isOpen: boolean;
  onClose: () => void | Promise<void>;
  groupProductName: string | number;
  bankName: string;
  panelKey?: AppLayoutOverlayPanelKey;
}

export const CreditExecutionRejectionDemand = ({
  creditExecutionId,
  demandId,
  afterSuccess,
  isOpen,
  onClose,
  groupProductName,
  bankName,
  panelKey,
}: CreditExecutionRejectionDemandProps) => {
  const { error, handleSubmit } = useCreditExecutionRejectionSubmitHandler(
    "demand",
    { creditExecutionId, demandId },
    afterSuccess,
  );

  return (
    <ProductRejection
      error={error}
      isOpen={isOpen}
      product="CreditExecution"
      onClose={onClose}
      title="Отмена заявки"
      description={`Будет отменена заявка № ${groupProductName} в ${bankName}`}
      submitHandler={handleSubmit}
      panelKey={panelKey}
    />
  );
};
