import Skeleton from "react-loading-skeleton";
import { dateTimeHelper } from "@sideg/helpers";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { useAppSelector } from "../../../common/store";
import { selectNewsDetails } from "../store/newsDetails.selectors";
import * as S from "./NewsDetails.styled";
import { isLoading } from "../../../common/types/state";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { useNavigationStateFrom } from "../../../common/navigation/navigation-state";
import { BackLinkButton } from "../../../common/ui/buttons/BackLinkButton";
import { selectAppUrl } from "../../../common/urls/internal/utils";

export const NewsDetails = () => {
  const { data: newsDetails, status } = useAppSelector(selectNewsDetails);
  const stateFrom = useNavigationStateFrom();
  const isDesktop = useThemeIsDesktop();

  return (
    <>
      <AppLayoutResponsiveListPage.Header>
        <S.Title>{newsDetails?.title ?? <Skeleton count={1} />}</S.Title>
        <S.Date>
          {newsDetails?.creationDate && dateTimeHelper.format(new Date(newsDetails.creationDate), "dayFullMonthYear")}
          {!newsDetails?.creationDate && <Skeleton width={120} />}
        </S.Date>
      </AppLayoutResponsiveListPage.Header>
      <S.Container>
        {newsDetails?.description && <S.Text dangerouslySetInnerHTML={{ __html: newsDetails.description }} />}
        {!newsDetails?.description && isLoading(status) && (
          <S.Text>
            <Skeleton count={1.7} />
            <Skeleton count={2.3} />
            <Skeleton count={4.5} />
          </S.Text>
        )}
        {isDesktop && !isLoading(status) && (
          <div>
            <BackLinkButton
              linkTo={stateFrom?.from.url ?? selectAppUrl((x) => x.news.list.root).url}
              text="Все новости"
            />
          </div>
        )}
      </S.Container>
    </>
  );
};
