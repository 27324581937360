import { useMemo } from "react";
import { useTheme, useResponsiveRange } from "@sideg/ui-kit";
import { useAppLayoutOverflowEffect } from "./useAppLayoutOverflowEffect";
import { useAppLayout } from "./useAppLayout";

export const useAppLayoutOverflow = () => {
  const theme = useTheme();
  const { lowerThan } = useResponsiveRange();
  const { activeOverlayPanel, isMenuOpened } = useAppLayout();

  const isFixedOverflow = useMemo((): boolean => {
    return (
      (isMenuOpened.value && lowerThan(theme.breakpoints.desktopBreakpoint, false)) ||
      activeOverlayPanel.value !== undefined
    );
  }, [activeOverlayPanel.value, isMenuOpened.value, lowerThan, theme.breakpoints.desktopBreakpoint]);

  useAppLayoutOverflowEffect(isFixedOverflow);
};
