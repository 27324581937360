import { useFormikContext } from "formik";
import { MouseEventHandler } from "react";
import { useLocation } from "react-router-dom";
import { PublicUserRegistrationFormValues } from "../types/PublicUserRegistrationFormValues";
import { useAppNavigation } from "../../../common/urls/internal/hooks";
import { NavigationStateFrom } from "../../../common/navigation/navigation-state";
import { encodeQueryParams } from "../../../common/utils/encodeQueryParams";

export const usePublicUserRegistrationPrivacyPolicyClickHandler = (): MouseEventHandler<HTMLAnchorElement> => {
  const { values } = useFormikContext<PublicUserRegistrationFormValues>();
  const navigator = useAppNavigation();

  const { pathname } = useLocation();

  return (event) => {
    event.preventDefault();
    const from: NavigationStateFrom = {
      url: `${pathname}?${encodeQueryParams(values)}`,
      backTitle: "Продолжить регистрацию",
    };
    navigator((x) => x.publicUser.privacyPolicies.root, undefined, { state: { from } });
  };
};
