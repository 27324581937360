import { ThemeMobileDesktopView, useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { StyledAppLink } from "../../../../common/ui/links";
import { useCreditExecutionDetailsContext } from "../../hooks/context/useCreditExecutionDetailsContext";
import { useCreditExecutionDetailsRejection } from "../../hooks/useCreditExecutionDetailsRejection";
import { RejectButton } from "../../../../common/ui/buttons/reject-button";
import { CreditExecutionRejectionProduct } from "../../../common/rejection";
import { useAppSelector } from "../../../../common/store";
import { selectCreditExecutionDetailsProductDetails } from "../../store/product-details/creditExecutionDetailsProductDetails.selectors";
import { Typography } from "../../../../common/ui-kit/typography";
import { useCreateNavigationStateFrom } from "../../../../common/navigation/navigation-state";

export type CreditExecutionDetailsAsideActionsProps = BaseCssProps;

export const CreditExecutionDetailsAsideActions = ({ className }: CreditExecutionDetailsAsideActionsProps) => {
  const { creditExecutionId } = useCreditExecutionDetailsContext();
  const { data } = useAppSelector(selectCreditExecutionDetailsProductDetails);
  const { isModalOpen, openModal, closeModal, afterSuccess, panelKey } =
    useCreditExecutionDetailsRejection(creditExecutionId);

  const from = useCreateNavigationStateFrom("Заявка");
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <StackPanel gap="sm" direction="row" alignItems="center" justifyContent="space-between" className={className}>
      <StyledAppLink
        to={(x) => x.creditExecution.creation.createByCreditExecution}
        params={{ creditExecutionId }}
        state={from}
      >
        <Typography fontWeight={500} fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}>
          Отправить в другие
        </Typography>
      </StyledAppLink>
      {data?.canRejectAll === true && (
        <>
          <RejectButton onClick={openModal} size={{ xs: "default", [desktopBreakpoint]: "small" }}>
            <ThemeMobileDesktopView mobile="Отменить все" desktop="Отменить все заявки" />
          </RejectButton>
          <CreditExecutionRejectionProduct
            creditExecutionId={creditExecutionId}
            afterSuccess={afterSuccess}
            isOpen={isModalOpen}
            onClose={closeModal}
            groupProductName={data.groupProductName}
            panelKey={panelKey}
          />
        </>
      )}
    </StackPanel>
  );
};
