import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { IssuingCompanyId } from "../../../../../common/types/companies";
import { PublicUserRegistrationAgentDocumentApproveForm } from "./approve-form/PublicUserRegistrationAgentDocumentApproveForm";
import { useAppDispatch, useAppSelector } from "../../../../../common/store";
import { changeDocumentSignStatePublicUserRegistrationAgent } from "../../../store/agent/publicUserRegistrationAgent.slice";
import {
  selectPublicUserRegistrationAgentDocumentById,
  selectPublicUserRegistrationAgentIsAnyDocumentSigning,
} from "../../../store/agent/publicUserRegistrationAgent.selectors";
import { DoneIcon, Icon } from "../../../../../common/ui-kit/icons";
import { Typography } from "../../../../../common/ui-kit/typography";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { usePublicUserRegistrationAgentDocumentApproveSendCodeMutation } from "../../../hooks/agent/document-approve/usePublicUserRegistrationAgentDocumentApproveSendCodeMutation";
import { isLoading } from "../../../../../common/types/state";
import { ButtonLoading } from "../../../../../common/ui-kit/form-controls/button";

const BoxTypography = Typography.withComponent(Box);

export interface PublicUserRegistrationAgentDocumentApproveProps {
  issuingCompanyId: IssuingCompanyId;
}

export const PublicUserRegistrationAgentDocumentApprove = ({
  issuingCompanyId,
}: PublicUserRegistrationAgentDocumentApproveProps) => {
  const dispatch = useAppDispatch();
  const document = useAppSelector((state) => selectPublicUserRegistrationAgentDocumentById(state, issuingCompanyId));
  const isDesktop = useThemeIsDesktop();
  const { status, sendCode } = usePublicUserRegistrationAgentDocumentApproveSendCodeMutation(issuingCompanyId);
  const isAnyDocumentSigning = useAppSelector(selectPublicUserRegistrationAgentIsAnyDocumentSigning);

  const onSignButtonClick = async () => {
    await sendCode();
    dispatch(changeDocumentSignStatePublicUserRegistrationAgent({ issuingCompanyId, signState: "signing" }));
  };

  return (
    <>
      {document?.signState === "signed" && (
        <BoxTypography
          display="inline-flex"
          gap="md"
          alignItems="center"
          fontSize="fz3"
          fontWeight={500}
          lineHeight="defaultLineHeight"
          fontColor="status.success"
        >
          <Icon as={DoneIcon} stroke="status.success" width={24} height={24} />
          <span>Заявление подписано</span>
        </BoxTypography>
      )}
      {document?.signState === "initial" && (
        <ButtonLoading
          disabled={isAnyDocumentSigning}
          isLoading={isLoading(status)}
          type="button"
          onClick={onSignButtonClick}
          isFullWidth={!isDesktop}
          size="default"
        >
          Подтвердить согласие
        </ButtonLoading>
      )}
      {document?.signState === "signing" && (
        <PublicUserRegistrationAgentDocumentApproveForm issuingCompanyId={issuingCompanyId} />
      )}
    </>
  );
};
