import { createSelector } from "@reduxjs/toolkit";
import { CreditExecutionTransferPayload } from "../../common/store";
import { RootState } from "../../../common/store";
import { CreditExecutionId } from "../../../common/types/demand";
import { CreditExecutionDemandBase } from "../../common/types";
import { selectCreditExecutionListItemsById } from "./items/creditExecutionListItems.selectors";

export const selectCreditExecutionList = (state: RootState) => state.domains.creditExecution.list;

const selectCreditExecutionListItems = (state: RootState) => selectCreditExecutionList(state).items;
export const selectCreditExecutionListTransferPayload = createSelector(
  [
    selectCreditExecutionListItems,
    (state: RootState, creditExecutionId: CreditExecutionId) =>
      selectCreditExecutionListItemsById(state, creditExecutionId),
  ],
  (items, product): CreditExecutionTransferPayload | undefined => {
    if (!product) {
      return undefined;
    }

    const { clientId, demandIds, ...productBase } = product;

    const client = items.clients.entities[clientId];
    if (!client) {
      return undefined;
    }

    const demands = demandIds
      .map((x) => items.demands.entities[x])
      .filter((x): x is CreditExecutionDemandBase => x !== undefined);

    return {
      product: {
        ...productBase,
        client,
      },
      demands,
    };
  },
);
