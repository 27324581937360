import { BankGuaranteeId, DemandId } from "../../../../common/types/demand";
import { BankGuaranteeReissueForm } from "../form/BankGuaranteeReissueForm";
import { Modal } from "../../../../common/ui/containers/modal";

interface BankGuaranteeReissueModalProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  onSuccess: () => void;
  onClose: () => void;
  isShown: boolean;
}

export const BankGuaranteeReissueModal = ({
  guaranteeId,
  demandId,
  onSuccess,
  onClose,
  isShown,
}: BankGuaranteeReissueModalProps) => {
  return (
    <Modal
      onClose={onClose}
      isShown={isShown}
      title="Перевыпуск банковской гарантии"
      background={(x) => x.palette.background.contrastLight}
    >
      <BankGuaranteeReissueForm guaranteeId={guaranteeId} demandId={demandId} onSuccess={onSuccess} />
    </Modal>
  );
};
