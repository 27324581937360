import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import {
  CREDIT_EXECUTION_LIST_SLICE_NAME,
  getFiltrationParametersCreditExecutionList,
} from "../creditExecutionList.thunks";
import { creditExecutionListFiltersMapper } from "./creditExecutionListFilters.mapper";
import { CreditExecutionListFiltersState } from "./creditExecutionListFilters.types";

const initialState: CreditExecutionListFiltersState = {
  data: undefined,
  status: LoadingStatusEnum.Idle,
};

const slice = createSlice({
  name: CREDIT_EXECUTION_LIST_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFiltrationParametersCreditExecutionList.fulfilled, (state, action) => {
        state.data = creditExecutionListFiltersMapper.mapFilters(action.payload.body);
      })
      .addMatcher(isAsyncThunkAction(getFiltrationParametersCreditExecutionList), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const creditExecutionListFiltersReducer = slice.reducer;
