import { getCssPropsForSpaceProp, SpacesProps } from "@sideg/ui-kit/atoms/containers/types/SpacesProps";
import { isThemeSpacingValueWithMultiplier } from "@sideg/ui-kit/core/theme/types/spacing/ThemeSpacingType";
import { CSSObject } from "@emotion/react";
import { makeResponsiveStylesBuilder } from "@sideg/ui-kit/common/utils";
import { CssStyleBuilder } from "@sideg/ui-kit/common/types";
import { BuildersMap, makeStyleBuilders } from "@sideg/ui-kit/atoms/containers/utils/makeStyleBuilders";

const responsive = makeResponsiveStylesBuilder<SpacesProps>();

const responsiveSpace = (key: keyof SpacesProps): CssStyleBuilder<SpacesProps> => {
  return responsive(key, (space, theme) => {
    if (space) {
      const value = isThemeSpacingValueWithMultiplier(space) ? theme.spacing.withMultiple(space) : theme.spacing[space];
      const keys = getCssPropsForSpaceProp(key);

      return keys.reduce((accum, cssKey) => {
        accum[cssKey] = value;

        return accum;
      }, {} as CSSObject);
    }

    return {};
  });
};

const builders: BuildersMap<SpacesProps> = {
  padding: responsiveSpace("padding"),
  pt: responsiveSpace("pt"),
  pr: responsiveSpace("pr"),
  pb: responsiveSpace("pb"),
  pl: responsiveSpace("pl"),
  px: responsiveSpace("px"),
  py: responsiveSpace("py"),
  margin: responsiveSpace("margin"),
  mt: responsiveSpace("mt"),
  mr: responsiveSpace("mr"),
  mb: responsiveSpace("mb"),
  ml: responsiveSpace("ml"),
  mx: responsiveSpace("mx"),
  my: responsiveSpace("my"),
  gap: responsiveSpace("gap"),
  rowGap: responsiveSpace("rowGap"),
};

export const getSpacesPropsStyleBuilders = makeStyleBuilders(builders);
