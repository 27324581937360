import { usePageTitle } from "../../../common/navigation/page-title";
import { BankGuaranteeConditionsTable } from "../components/BankGuaranteeConditionsTable";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";

export const BankGuaranteeConditionsPage = () => {
  usePageTitle("Условия получения банковской гарантии");

  return (
    <AppLayoutResponsiveListPage backgroundColor="light">
      <AppLayoutResponsiveListPage.Header>
        <AppLayoutResponsiveListPage.Title>Условия банков</AppLayoutResponsiveListPage.Title>
      </AppLayoutResponsiveListPage.Header>
      <BankGuaranteeConditionsTable />
    </AppLayoutResponsiveListPage>
  );
};
