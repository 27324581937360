import { useCallback } from "react";
import { apiMutationDefaultErrorHandler, useApiMutationAction } from "../../../../../common/hooks";
import { useAppDispatch } from "../../../../../common/store";
import {
  DemandQueueTaskActiveDecisionCode,
  queueTaskClosedDemandQueueTasks,
  sendDecisionDemandQueueTasks,
} from "../../../index";
import { QueueTaskId } from "../../../../../common/types/demand";
import { pushErrorGlobalToast } from "../../../../../global/toasts";
import { demandQueueTaskRequestOptionValidationSchema } from "../../../validation/demandQueueTaskRequestOptionValidationSchema";
import { DemandQueueTaskRequestOptionsFormValues } from "../../../types/active/DemandQueueTaskRequestOptionsFormValues";

export const useDemandQueueTaskRequestOptionsFormOnSubmit = (
  queueTaskId: QueueTaskId,
  decision: DemandQueueTaskActiveDecisionCode,
) => {
  const dispatch = useAppDispatch();
  const { mutate } = useApiMutationAction();

  return useCallback(
    async ({ requestOption }: DemandQueueTaskRequestOptionsFormValues) => {
      const castedValues = demandQueueTaskRequestOptionValidationSchema.cast({
        requestOption,
      });

      const result = await mutate(
        sendDecisionDemandQueueTasks,
        { queueTaskId, decision, ...castedValues },
        {
          errorHandler: async (e) => {
            const error = apiMutationDefaultErrorHandler(e) ?? "Не удалось отправить решение по задаче";
            dispatch(pushErrorGlobalToast(error));

            return error;
          },
        },
      );

      if (result) {
        dispatch(queueTaskClosedDemandQueueTasks(queueTaskId));
      }
    },

    [dispatch, mutate, queueTaskId, decision],
  );
};
