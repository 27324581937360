import { useEffect } from "react";
import { navigationPageTitleConfigs } from "../configs";

export const usePageTitle = (title: string, ignoreDefaultPostfix = false) => {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = ignoreDefaultPostfix
      ? title
      : [title, navigationPageTitleConfigs.defaultPrefix].filter((x) => x !== "").join(" - ");

    return () => {
      document.title = previousTitle;
    };
  }, [title, ignoreDefaultPostfix]);
};
