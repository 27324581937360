import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import { Theme } from "@sideg/ui-kit";
import { Color } from "../../../../theme";
import { ButtonColor } from "../../types/ButtonColor";
import { CommonButton } from "../common/CommonButton";
import { ButtonCommonProps } from "../../types/ButtonCommonProps";

interface ButtonContainedStateColor {
  backgroundColor: Color;
  color: Color;
  boxShadow?: CSSObject["boxShadow"];
}

interface ButtonContainedStateColors {
  focusOutlineColor: Color;
  defaultState: ButtonContainedStateColor;
  disabledState: ButtonContainedStateColor;
  hoverState: ButtonContainedStateColor;
}

const getButtonContainedStylesByVariant = (theme: Theme, color: ButtonColor): ButtonContainedStateColors => {
  switch (color) {
    case "light":
      return {
        disabledState: {
          backgroundColor: theme.palette.typography.primaryCaption,
          color: theme.palette.typography.primary,
        },
        hoverState: {
          backgroundColor: theme.palette.typography.primaryLight,
          color: theme.colors.primaryDark,
          boxShadow: "0px 1px 4px rgba(45, 113, 187, 0.16)",
        },
        defaultState: {
          backgroundColor: theme.palette.typography.primaryLight,
          color: theme.colors.primary,
        },
        focusOutlineColor: theme.colors.primary,
      };
    case "secondary":
      return {
        defaultState: {
          backgroundColor: theme.palette.control.primaryLight,
          color: theme.colors.primary,
        },
        hoverState: {
          backgroundColor: theme.palette.control.secondaryHover,
          color: theme.colors.primary,
        },
        disabledState: {
          backgroundColor: theme.palette.control.secondaryMedium,
          color: theme.palette.typography.medium,
        },
        focusOutlineColor: theme.colors.primary,
      };
    default:
      return {
        disabledState: {
          backgroundColor: theme.colors.primaryDisabled,
          color: theme.palette.typography.primaryLight,
        },
        hoverState: {
          backgroundColor: theme.palette.control.primaryHover,
          color: theme.palette.typography.primaryLight,
        },
        defaultState: {
          color: theme.palette.typography.primaryLight,
          backgroundColor: theme.colors.primary,
        },
        focusOutlineColor: theme.colors.primaryDark,
      };
  }
};

export const ButtonContained = styled(CommonButton)<ButtonCommonProps>(
  () => ({
    borderRadius: "0.25rem",
    transition: "background-color 0.3s ease",
  }),
  ({ theme, color, isDisabledAsDefaultState }) => {
    const {
      disabledState: disabled,
      hoverState,
      defaultState,
      focusOutlineColor,
    } = getButtonContainedStylesByVariant(theme, color);

    const disabledState = isDisabledAsDefaultState ? defaultState : disabled;

    return {
      backgroundColor: defaultState.backgroundColor,
      color: defaultState.color,
      boxShadow: defaultState.boxShadow,
      border: `1px solid ${defaultState.backgroundColor}`,
      WebkitTapHighlightColor: hoverState.backgroundColor,

      "&:disabled": {
        backgroundColor: disabledState.backgroundColor,
        borderColor: disabledState.backgroundColor,
        color: disabledState.color,
        boxShadow: disabledState.boxShadow,
      },

      "&:hover:not(:disabled)": {
        backgroundColor: hoverState.backgroundColor,
        borderColor: hoverState.backgroundColor,
        color: hoverState.color,
        boxShadow: hoverState.boxShadow,
      },

      "&:focus-visible": {
        outlineColor: focusOutlineColor,
      },
    };
  },
);
