import styled from "@emotion/styled";
import { Box } from "../../../common/ui-kit/containers/box";

export const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.layout.demand.colors.rightSideBackgroundColor};
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spaces.xl};
`;

export const CenteredContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: calc(1.5 * ${({ theme }) => theme.spaces.xxl});
  margin: ${({ theme }) => theme.spaces.sm} 0;
  padding: 0 ${({ theme }) => theme.spaces.xl};
  width: 100%;

  ${({ theme }) => theme.breakpoints.mqUp("md")} {
    width: 750px;
    padding: 0;
  }
`;
