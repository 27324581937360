import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import {
  CREDIT_EXECUTION_CONDITIONS_SLICE_NAME,
  getCreditExecutionConditions,
} from "./creditExecutionConditions.thunks";
import { CreditExecutionConditionsState } from "./creditExecutionConditions.types";

const initialState: CreditExecutionConditionsState = {
  status: LoadingStatusEnum.Idle,
  conditions: undefined,
};

const slice = createSlice({
  initialState,
  name: CREDIT_EXECUTION_CONDITIONS_SLICE_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCreditExecutionConditions.fulfilled, (state, { payload }) => {
        state.conditions = payload.body;
      })
      .addMatcher(isAsyncThunkAction(getCreditExecutionConditions), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const creditExecutionConditionsReducer = slice.reducer;
