import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import { BaseCssProps } from "../../../common/ui-kit/types";
import * as S from "./ReportsDemand.styled";
import { Grid } from "../../../common/ui-kit/containers/responsive-grid";
import { reportsDemandValidationSchema } from "../validation/reportsDemandValidationSchema";
import { ReportsDemandFormValues } from "../types/ReportsDemandFormValues";
import { useCustomFieldOnChange, useGetFieldName, useGetFormFieldError } from "../../../common/form";
import { BlockResponsiveSubmitButton } from "../../../common/ui-kit/buttons/block-responsive-submit";
import { CreationNote } from "../../../common/ui-kit/notifications/note";
import { ReportsDemandPresets } from "./ReportsDemandPresets";
import { useApiFileSaver, useApiMutation } from "../../../common/hooks";
import { getReportsDemands } from "../store/reportsDemands.thunks";

export type ReportsDemandProps = BaseCssProps;

export const ReportsDemand = ({ className }: ReportsDemandProps) => {
  const getFieldName = useGetFieldName<ReportsDemandFormValues>();
  const getFieldError = useGetFormFieldError<ReportsDemandFormValues>();
  const onCustomChange = useCustomFieldOnChange<ReportsDemandFormValues>();

  const isDesktop = useThemeIsDesktop();

  const saveFile = useApiFileSaver();
  const { error, mutate } = useApiMutation(getReportsDemands, {
    errorMessagePlaceholder:
      "Произошла непредвиденная ошибка. Попробуйте еще раз или свяжитесь с персональным менеджером",
  });

  const onFormSubmit = async (
    values: ReportsDemandFormValues,
    { resetForm }: FormikHelpers<ReportsDemandFormValues>,
  ) => {
    const model = reportsDemandValidationSchema.cast(values) as ReportsDemandFormValues;
    const reportResult = await mutate(model);

    if (reportResult?.body) {
      saveFile(reportResult.body);
      resetForm();
    }
  };

  return (
    <Formik
      initialValues={reportsDemandValidationSchema.getDefault() as ReportsDemandFormValues}
      validationSchema={reportsDemandValidationSchema}
      onSubmit={onFormSubmit}
    >
      {(form: FormikProps<ReportsDemandFormValues>) => (
        <S.Container as={Form} className={className}>
          <CreationNote>
            Выберите один из предложенных периодов для формирования отчета
            {isDesktop && <br />} или укажите период вручную, после чего сформируйте отчет.
          </CreationNote>

          <ReportsDemandPresets />
          <Grid container gap="md">
            <Grid
              item
              as={DateFormInput}
              xs={12}
              md={6}
              placeholder="дд.мм.гггг"
              label="Дата начала"
              {...form.getFieldProps(getFieldName("dateStart"))}
              onChange={(e: string) => onCustomChange(form.setFieldValue, "dateStart", e, true)}
              error={getFieldError(form, "dateStart")}
              autoComplete="off"
              disabled={form.isSubmitting}
            />
            <Grid
              item
              as={DateFormInput}
              xs={12}
              md={6}
              placeholder="дд.мм.гггг"
              label="Дата окончания"
              {...form.getFieldProps(getFieldName("dateEnd"))}
              onChange={(e: string) => onCustomChange(form.setFieldValue, "dateEnd", e, true)}
              error={getFieldError(form, "dateEnd")}
              autoComplete="off"
              disabled={form.isSubmitting}
            />
          </Grid>

          <BlockResponsiveSubmitButton type="submit" isLoading={form.isSubmitting} error={error}>
            Сформировать отчет
          </BlockResponsiveSubmitButton>
        </S.Container>
      )}
    </Formik>
  );
};
