import { GetUserDetailsOutputDto } from "../api/dto/output/GetUserDetailsOutputDto";
import { UserDetails } from "../types/UserDetails";
import { UserManager } from "../types/UserManager";

export const userDetailsMapper = {
  fromResponse: (response: GetUserDetailsOutputDto): { user: UserDetails; manager?: UserManager } => {
    const user: UserDetails = {
      userName: response.userName,
      clientName: response.clientName,
      news: response.news,
      email: response.email,
      role: response.role,
      phone: response.phone,
      status: response.status,
      unreadMessages: response.unreadMessages,
    };

    const manager: UserManager | undefined = !response.manager
      ? undefined
      : {
          userName: response.manager.userName,
          email: response.manager.email,
          phone: response.manager.phone,
          isReplaced: response.isManagerReplaced,
          avatar: response.manager.avatar,
        };

    return { user, manager };
  },
};
