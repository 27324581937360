import { useThemeDeviceResponsiveType } from "@sideg/ui-kit/helpers/responsive/hooks/useThemeDeviceResponsiveType";

/**
 * Возвращает флаг - признак, что текущий девайс - десктоп.
 * В большинстве кейсов, логичнее использовать
 * @see ThemeMobileDesktopView
 * @see useThemeDeviceResponsiveType
 */
export const useThemeIsDesktop = () => {
  const type = useThemeDeviceResponsiveType();

  return type === "desktop";
};
