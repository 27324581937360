import styled from "@emotion/styled";
import { ControlSize, SizeWithUnit } from "@sideg/ui-kit/common/types";
import { CSSObject } from "@emotion/react";

export const SelectPopupContainerItemIconSize: Record<ControlSize, CSSObject["width"]> = {
  small: "18px",
  default: "20px",
  medium: "24px",
};

export const SELECT_POPUP_CONTAINER_ITEM_GAP: SizeWithUnit<"px"> = "8px";

export const SelectPopupContainerItem = styled.li<{
  iconStroke?: "success" | "neutral";
  size?: ControlSize;
  active: boolean;
  highlighted?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SELECT_POPUP_CONTAINER_ITEM_GAP};

  padding: ${({ theme }) => theme.spacing.s};
  background: ${({ highlighted, active, theme }) => {
    if (active) {
      return theme.palette.light.neutral.tonal;
    }
    if (highlighted) {
      return theme.palette.light.primary.tonal.hover;
    }

    return "transparent";
  }};
  border-radius: ${({ theme }) => theme.radii.control};
  border: none;

  user-select: none;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ active, theme }) =>
      active ? theme.palette.light.primary.tonal.hover : theme.palette.light.neutral.tonal};
  }

  & > svg {
    height: ${({ size = "small" }) => SelectPopupContainerItemIconSize[size]};
    width: ${({ size = "small" }) => SelectPopupContainerItemIconSize[size]};
    flex-grow: 0;
    flex-shrink: 0;
  }
  & > svg > path {
    stroke: ${({ theme, iconStroke = "neutral" }) =>
      iconStroke === "success" ? theme.palette.light.signal.text.success : theme.palette.light.neutral.text.primary};
  }
`;
