import { useLayoutEffect, useState } from "react";
import { useResponsiveContext } from "@sideg/ui-kit";

export const useIsIOSSafari = (): boolean => {
  const { isProbablyMobile } = useResponsiveContext();
  const [isIosSafari, setIsIosSafari] = useState(false);

  useLayoutEffect(() => {
    if (!isProbablyMobile) {
      return;
    }
    const iOS = !!navigator.userAgent.match(/iP(ad|hone)/i);
    const webkit = !!navigator.userAgent.match(/WebKit/i);
    setIsIosSafari(iOS && webkit && !navigator.userAgent.match(/CriOS/i));
  }, [isProbablyMobile]);

  return isIosSafari;
};
