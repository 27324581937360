import * as React from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import * as S from "./NewsCard.styled";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Text } from "../../../../common/ui-kit/typography";

interface NewsCardProps extends BaseCssProps {
  date: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  footer: React.ReactNode;
}

export const NewsCard = ({ date, title, description, footer }: NewsCardProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <S.Card>
      <S.Header>
        <S.Title fontWeight={700} fontSize={{ xs: "fz4", [desktopBreakpoint]: 1.5 }} lineHeight="1.6" as="h3">
          {title}
        </S.Title>
        <Text fontWeight={500} fontSize={{ xs: "fz2", [desktopBreakpoint]: "fz3" }} fontColor="primaryCaption">
          {date}
        </Text>
      </S.Header>
      <S.Body>{description}</S.Body>
      <S.Footer>{footer}</S.Footer>
    </S.Card>
  );
};
