import { isLoading } from "../../../../../common/types/state";
import {
  ProductCreationScoringLoadingResultCard,
  ProductCreationScoringResultCard,
  ProductCreationScoringResultRowDuplicate,
  ProductCreationScoringResultRowDuplicateSkeleton,
  ProductCreationScoringResultRowReject,
} from "../../../../../common/product-creation";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { useAppSelector } from "../../../../../common/store";
import { selectCreditWorkingAssetsCreationScoring } from "../../../store/creditWorkingAssetsCreation.selectors";
import { CreditWorkingAssetsCreationScoringCreateByExistsSuccessBlock } from "./CreditWorkingAssetsCreationScoringCreateByExistsSuccessBlock";

export interface CreditWorkingAssetsCreationScoringCreateByExistsFormContentProps {
  creditSum: number | undefined;
}

export const CreditWorkingAssetsCreationScoringCreateByExistsFormContent = ({
  creditSum,
}: CreditWorkingAssetsCreationScoringCreateByExistsFormContentProps) => {
  const { data, status } = useAppSelector(selectCreditWorkingAssetsCreationScoring);

  return (
    <Box flexGrow={1} flexShrink={1} display="flex" flexDirection="column" gap="xxl">
      <CreditWorkingAssetsCreationScoringCreateByExistsSuccessBlock creditSum={creditSum} />
      <ProductCreationScoringLoadingResultCard
        isLoading={isLoading(status)}
        items={data?.duplicate}
        skeletonElementsCount={2}
        title="Банки, в которые уже была отправлена заявка"
        skeleton={ProductCreationScoringResultRowDuplicateSkeleton}
      >
        {(x) => (
          <ProductCreationScoringResultRowDuplicate
            bankName={x.bank.title}
            key={x.bank.code}
            demandId={x.existingDemand?.demandId}
            productId={x.existingDemand?.productId}
            productType="CreditWorkingAssets"
          />
        )}
      </ProductCreationScoringLoadingResultCard>
      {data?.reject && data.reject.length > 0 && (
        <ProductCreationScoringResultCard title="Отказ банка">
          {data.reject.map((x) => (
            <ProductCreationScoringResultRowReject bankName={x.bank.title} key={x.bank.code} text={x.message} />
          ))}
        </ProductCreationScoringResultCard>
      )}
    </Box>
  );
};
