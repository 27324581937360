import { isApiError } from "../../api/types";

const tryGetValidationErrorsNullable = <TModel>(error: unknown): undefined | Record<keyof TModel, string> => {
  if (isApiError<TModel>(error) && error.validationErrors) {
    return Object.keys(error.validationErrors).reduce(
      (accum, key) => {
        const errors = error.validationErrors?.[key as keyof TModel];
        if (errors && errors.length > 0) {
          accum[key as keyof TModel] = errors[0] ?? "";
        }

        return accum;
      },
      {} as Record<keyof TModel, string>,
    );
  }

  return undefined;
};

const tryGetValidationErrors = <TModel>(error: unknown): Record<keyof TModel, string> => {
  return tryGetValidationErrorsNullable(error) ?? ({} as Record<keyof TModel, string>);
};

export const formValidationFieldService = {
  tryGetValidationErrors,
  tryGetValidationErrorsNullable,
};
