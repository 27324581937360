import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import * as S from "./BankGuaranteeListItemDesktopStatusLink.styled";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import { BankGuaranteeId } from "../../../../../../../common/types/demand";
import { BankGuaranteeListItemDemand } from "../../../../../types/item/BankGuaranteeListItemDemand";
import { useBankGuaranteeListItemDemandLink } from "../../../../../hooks/list/useBankGuaranteeListItemDemandLink";
import {
  ProductListCardDemandListItemStatusLinkCounterDesktop,
  ProductListCardDemandsListRejectTooltipIconDesktop,
} from "../../../../../../../common/ui/product/list/card";

export interface BankGuaranteeListItemDesktopStatusLinkProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demand: BankGuaranteeListItemDemand;
  from?: NavigationState;
}

export const BankGuaranteeListItemDesktopStatusLink = ({
  demand,
  guaranteeId,
  from,
  className,
}: BankGuaranteeListItemDesktopStatusLinkProps) => {
  const linkProps = useBankGuaranteeListItemDemandLink(guaranteeId, demand.demandId);

  return (
    <S.Link
      isRejected={!!demand.termination}
      hasActiveTasks={demand.countActiveTasks > 0}
      state={from}
      className={className}
      {...linkProps}
    >
      <span>{demand.status.title}</span>
      {demand.countActiveTasks > 1 && !demand.isTerminated && (
        <ProductListCardDemandListItemStatusLinkCounterDesktop>
          + {demand.countActiveTasks - 1}
        </ProductListCardDemandListItemStatusLinkCounterDesktop>
      )}
      {demand.termination?.comment && (
        <ProductListCardDemandsListRejectTooltipIconDesktop
          comment={demand.termination.comment}
          reason={demand.termination.reason.title}
        />
      )}
    </S.Link>
  );
};
