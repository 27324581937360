import { memo } from "react";
import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";
import { AppInfinityScroll } from "../../../../common/ui/product/list/infinite-scroll";
import { AppInfinityScrollTitles } from "../../../../common/ui/product/list/infinite-scroll/components/AppInfinityScroll";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useFilteredPaginatedListPage } from "../../../../common/filters";
import { useCreditWorkingAssetsListFilterContext } from "../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { getCreditWorkingAssetsList } from "../../store/creditWorkingAssetsList.thunks";
import {
  selectCreditWorkingAssetsListItems,
  selectCreditWorkingAssetsListItemsIds,
} from "../../store/items/creditWorkingAssetsListItems.selectors";
import { CreditWorkingAssetsListItems } from "./CreditWorkingAssetsListItems";
import { ProductListItemsContainer } from "../../../../common/ui/product/list/container";
import { CreditWorkingAssetsListItemSkeleton } from "./CreditWorkingAssetsListItem.skeleton";
import { CreditWorkingAssetsListModification } from "./modification/CreditWorkingAssetsListModification";

const titles: AppInfinityScrollTitles = {
  end: "Загружены все заявки",
  hasNoLoadedItems: "Нет заявок",
  navigateToFirstPage: "Показать предыдущие заявки",
  error: "При получении списка заявок возникла ошибка",
};

export interface CreditWorkingAssetsListProps extends BaseCssProps {
  onErrorReloadButtonClick: () => void | Promise<void>;
}

export const CreditWorkingAssetsList = memo(({ className, onErrorReloadButtonClick }: CreditWorkingAssetsListProps) => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();
  const { onPreviousPageButtonClick, hasPreviousPage, onNextPageFetching } = useFilteredPaginatedListPage({
    filteringObject,
    action: getCreditWorkingAssetsList,
  });

  return (
    <AppLayoutResponsiveListPage.List className={className}>
      <CreditWorkingAssetsListModification />
      <AppInfinityScroll
        page={filteringObject.page}
        paginatedStateSelector={selectCreditWorkingAssetsListItems}
        idsSelector={selectCreditWorkingAssetsListItemsIds}
        titles={titles}
        onPreviousPageButtonClick={onPreviousPageButtonClick}
        onPageChanged={onNextPageFetching}
        onErrorReloadButtonClick={onErrorReloadButtonClick}
        hasPreviousPage={hasPreviousPage}
        emptyLoader={
          <ProductListItemsContainer>
            <CreditWorkingAssetsListItemSkeleton />
            <CreditWorkingAssetsListItemSkeleton />
            <CreditWorkingAssetsListItemSkeleton />
            <CreditWorkingAssetsListItemSkeleton />
          </ProductListItemsContainer>
        }
      >
        <CreditWorkingAssetsListItems />
      </AppInfinityScroll>
    </AppLayoutResponsiveListPage.List>
  );
});
