import * as React from "react";
import { YMInitializer } from "react-yandex-metrika";
import { analyticsConfigs } from "../configs";

export const YandexAnalyticsInitializer = (): React.ReactElement => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {analyticsConfigs.ym.isEnabled && (
        <YMInitializer accounts={[analyticsConfigs.ym.id]} options={analyticsConfigs.ym.options} />
      )}
    </>
  );
};
