import styled from "@emotion/styled";
import { InputBoxProps } from "../../common/types/InputBoxProps";

export const SearchIconButton = styled.button<InputBoxProps>`
  cursor: ${({ disabled }) => (disabled ? "default" : "text")};
  border: none;
  padding: 0 0 0 ${({ theme }) => theme.spaces.sm};
  margin: 0;
  background-color: transparent;
  display: flex;
  align-items: center;

  & > svg > path {
    stroke: ${({ theme }) => theme.palette.input.placeholder};
  }
`;
