import { useEffect, useMemo, useState } from "react";
import { FormFileDropInputVariant } from "../../../common/ui-kit/form-controls/file-input";

export const useFileInputError = (
  variant: FormFileDropInputVariant,
  fieldError: string | undefined,
  errorDropZone: string | undefined
) => {
  const [error, setCurrentError] = useState<string | undefined>();

  useEffect(() => {
    setCurrentError(errorDropZone);
  }, [errorDropZone]);

  useEffect(() => {
    setCurrentError(fieldError);
  }, [fieldError]);

  return useMemo(
    () => ({
      error,
      variant: error ? "error" : variant,
    }),
    [error, variant]
  );
};
