import { TransitionGroup } from "react-transition-group";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { DemandQueueTasksActiveRequestedDocument } from "../../types/active/DemandQueueTasksActiveRequestedDocument";
import { useDemandQueueTaskActiveRequestedDocumentOnDropWithRealTime } from "../../hooks/active/form/useDemandQueueTaskActiveRequestedDocumentOnDropWithRealTime";
import {
  DocumentUploadActiveDocument,
  getDocumentUploadRequestedDocumentOldFiles,
  useDocumentUploadLabelText,
} from "../../../../document/upload";
import { SimpleOpacityTransition } from "../../../../common/ui-kit/animations/transitions";
import { FileUpload } from "../../../../files/upload";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { QueueTaskId } from "../../../../common/types/demand";

export interface DemandQueueTasksRequestedDocumentProps extends BaseCssProps {
  queueTaskId: QueueTaskId;
  document: DemandQueueTasksActiveRequestedDocument;
}

export const DemandQueueTasksRequestedDocumentWithRealTime = ({
  queueTaskId,
  document,
}: DemandQueueTasksRequestedDocumentProps) => {
  const { isLoading, files, onDrop, error, isMultiple } = useDemandQueueTaskActiveRequestedDocumentOnDropWithRealTime(
    queueTaskId,
    document
  );
  const oldFiles = getDocumentUploadRequestedDocumentOldFiles(document);

  const uploadFileLabel = useDocumentUploadLabelText({
    isLoading,
    attachedFilesCount: oldFiles?.length ?? files.length,
    isMultiFile: document.isMultiFile,
    isReplaceableCollection: document.isReplaceableCollection,
    loadingFilesCount: files.length,
  });

  return (
    <DocumentUploadActiveDocument
      key={document.id}
      title={document.documentName}
      description={document.description}
      isRequired={document.isRequired}
      oldFiles={getDocumentUploadRequestedDocumentOldFiles(document)}
      template={document.template}
      isMultiple={isMultiple}
      canUpload
      onDrop={onDrop}
      error={error}
      isLoading={isLoading}
      uploadLabelText={uploadFileLabel}
    >
      {files && files.length > 0 && (
        <TransitionGroup component={StackPanel} gap="md" direction="column">
          {files.map((x) => (
            <SimpleOpacityTransition timeout={250} key={x.id}>
              <FileUpload fileName={x.file.name} canRemove={false} />
            </SimpleOpacityTransition>
          ))}
        </TransitionGroup>
      )}
    </DocumentUploadActiveDocument>
  );
};
