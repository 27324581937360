import styled from "@emotion/styled";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${({ theme }) => `calc(${theme.layout.borderPadding} / 1.5)`};
  margin: 0;
  z-index: ${({ theme }) => theme.layout.zIndex};
  flex-grow: 1;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding: ${({ theme }) =>
      `${theme.layout.borderPadding} ${theme.layout.borderPadding} 0 ${theme.layout.borderPadding}`};
  }
`;
