import { Theme } from "@sideg/ui-kit/core/theme";
import { ControlSize, PickCssObjectProps } from "@sideg/ui-kit/common/types";

import { mapThemeTypographyVariantToCssObject } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypographyVariantTokenValue";

interface InputSizeParams
  extends PickCssObjectProps<"padding" | "height">,
    ReturnType<typeof mapThemeTypographyVariantToCssObject> {}

const SMALL_INPUT_HEIGHT = "32px";
const DEFAULT_INPUT_HEIGHT = "40px";
const MEDIUM_INPUT_HEIGHT = "48px";

export const getSizes = (theme: Theme, size: ControlSize, isDesktop?: boolean): InputSizeParams => {
  switch (size) {
    case "small":
      return {
        ...mapThemeTypographyVariantToCssObject(
          isDesktop ? "controlsDesktop.inputs.small" : "controlsMobile.inputs.small",
          theme,
        ),
        padding: theme.spacing.makeCssSpaces("xs", "s"),
        height: isDesktop ? SMALL_INPUT_HEIGHT : undefined,
      };
    case "medium":
      return {
        ...mapThemeTypographyVariantToCssObject(
          isDesktop ? "controlsDesktop.inputs.large" : "controlsMobile.inputs.large",
          theme,
        ),
        padding: theme.spacing.makeCssSpaces("s", "sm"),
        height: isDesktop ? MEDIUM_INPUT_HEIGHT : undefined,
      };
    case "default":
    default:
      return {
        ...mapThemeTypographyVariantToCssObject(
          isDesktop ? "controlsDesktop.inputs.medium" : "controlsMobile.inputs.medium",
          theme,
        ),
        padding: theme.spacing.makeCssSpaces("s", "sm"),
        height: DEFAULT_INPUT_HEIGHT,
      };
  }
};
