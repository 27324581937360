import { BaseCssProps } from "@sideg/ui-kit/common/types";
import {
  BaseFormElementError,
  BaseFormElementErrorProps,
} from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormElementError";
import * as S from "@sideg/ui-kit/atoms/checkbox/components/radio-group/FormRadioButtonGroup.styled";
import { PropsWithChildren } from "react";
import { Typography } from "@sideg/ui-kit/atoms/typography";
import { FormRadioButtonGroupOption } from "@sideg/ui-kit/atoms/checkbox/components/radio-group/option/FormRadioButtonGroupOption";
import { CheckboxSize } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";
import { ThemeTypographyVariantType } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypography";

export interface FormRadioButtonGroupProps extends BaseCssProps, BaseFormElementErrorProps, PropsWithChildren {
  label?: string;
  itemsPlacement?: "center" | "start";
  labelSize?: CheckboxSize;
  isFullWidth?: boolean;
  disabled?: boolean;
}

export const FormRadioButtonGroup = ({
  error,
  errorDataTestId,
  children,
  className,
  itemsPlacement,
  label,
  labelSize = "small",
  isFullWidth,
  disabled,
}: FormRadioButtonGroupProps) => {
  const labelVariant: ThemeTypographyVariantType =
    labelSize === "default" ? "desktop.text16Medium" : "desktop.small14Medium";
  const labelColor = "light.neutral.text.primary";

  return (
    <S.Fieldset className={className} disabled={disabled}>
      {label && (
        <Typography fontColor={labelColor} variant={labelVariant}>
          {label}
        </Typography>
      )}
      <S.RadioButtonsWrapper isFullWidth={isFullWidth} itemsPlacement={itemsPlacement} disabled={disabled}>
        {children}
      </S.RadioButtonsWrapper>
      <BaseFormElementError errorDataTestId={errorDataTestId} error={error} />
    </S.Fieldset>
  );
};

FormRadioButtonGroup.Option = FormRadioButtonGroupOption;
