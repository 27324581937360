import styled from "@emotion/styled";

export const Container = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.spaces.sm};
  left: 50%;
  transform: translateX(-50%);
  z-index: ${({ theme }) => theme.layout.sidebar.zIndex};
  max-width: 90%;
  min-width: 90%;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.md};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    max-width: min(400px, 80%);
    min-width: unset;
  }
`;
