import styles from "./GuaranteeListItemDemandComment.module.css";

interface IGuaranteeListItemDemandComment {
  title: string;
  comment: string;
  commentType?: CommentType;
}

export enum CommentType {
  GuaranteeList = "GuaranteeList",
  BankScoringOk = "BankScoringOk",
  BankScoringRejected = "BankScoringRejected",
  BankScoringAlreadySubmitted = "BankScoringAlreadySubmitted",
}

const GuaranteeListItemDemandComment = ({
  title,
  comment,
  commentType = CommentType.GuaranteeList,
}: IGuaranteeListItemDemandComment) => {
  const getCommentIconLink = (cType: CommentType): string => {
    const commentIconPath = "/assets/icons/";

    switch (cType) {
      case CommentType.BankScoringOk:
        return commentIconPath + "messageScoringOk.svg";
      case CommentType.BankScoringRejected:
        return commentIconPath + "messageScoringRejected.svg";
      case CommentType.BankScoringAlreadySubmitted:
        return commentIconPath + "messageScoringAlreadySubmitted.svg";
      case CommentType.GuaranteeList:
        return commentIconPath + "message.svg";
      default:
        return commentIconPath + "message.svg";
    }
  };

  return (
    <div className={styles.comment}>
      <div className={styles.commentInfo}>
        <p className={styles.commentTitle}>{title}</p>
        <p className={styles.commentText}>{comment}</p>
      </div>
      <img className={styles.commentIcon} src={getCommentIconLink(commentType)} alt="Комментарий" />
    </div>
  );
};

export default GuaranteeListItemDemandComment;
