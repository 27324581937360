import * as React from "react";
import * as S from "./AlertWithIndicator.styled";

export interface BaseAlertWithIndicatorProps {
  indicator: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

export const BaseAlertWithIndicator = ({ indicator, children, className }: BaseAlertWithIndicatorProps) => {
  return (
    <S.Wrapper className={className}>
      {indicator}
      <div>{children}</div>
    </S.Wrapper>
  );
};
