import styled from "@emotion/styled";
import { useShowMoreText } from "../../../../common/ui-kit/typography";
import { ButtonLink } from "../../../../common/ui-kit/buttons/ButtonLink";

const StyledSection = styled.section`
  background-color: ${({ theme }) => theme.colors.secondaryDisabled};
  padding: 4px 8px 4px 12px;
  border-radius: 4px;
  line-height: 20px;
  width: 100%;

  & > button {
    margin: 0;
    padding: 0;
  }
`;

export interface QueueTasksListItemCommentProps {
  comment: string;
}

export const QueueTasksListItemComment = ({ comment }: QueueTasksListItemCommentProps) => {
  const { text, isOpened, trimmed, setIsOpened } = useShowMoreText(comment, 115);

  return (
    <StyledSection>
      <p>{text}</p>
      {trimmed && (
        <ButtonLink type="button" onClick={() => setIsOpened(!isOpened)}>
          {isOpened ? "Скрыть сообщение" : "Все сообщение"}
        </ButtonLink>
      )}
    </StyledSection>
  );
};
