import styled from "@emotion/styled";

export const Separator = styled.hr`
  width: 100%;
  appearance: none;
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  margin-top: ${({ theme }) => theme.spaces.lg};
  margin-bottom: ${({ theme }) => theme.spaces.md};
`;
