import ClientFormBlock from "../../../../../../ui/components/client-form/parts/block/ClientFormBlock";
import CustomSelect from "../../../../../../ui/components/client-form/parts/select/ClientFormSelect";
import ClientFormInput from "../../../../../../ui/components/client-form/parts/input/ClientFormInput";
import styles from "./CalculatorGuaranteeInfo.module.css";
import { ScoringFormValues } from "../data/initialValues";
import ClientFormCalendar from "../../../../../../ui/components/client-form/parts/calendar/ClientFormCalendar";
import { getMultiOptions, getOptions } from "../data/mappers";
import { ClientFormMultiSelect } from "../../../../../../ui/components/client-form/parts/multi-select/ClientFormMultiSelect";
import { ScoringModelItemsType } from "../../../../../../entity/models/ScoringModel";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { addDays, differenceInCalendarDays } from "date-fns";

interface CalculatorGuaranteeInfoProps {
  values: ScoringFormValues;
  guaranteeTypesOptions?: ScoringModelItemsType;
  guaranteeInfoOptions?: ScoringModelItemsType;
  errors: FormikErrors<ScoringFormValues>;
  touched: FormikTouched<ScoringFormValues>;
}

export const CalculatorGuaranteeInfo = ({
  values,
  guaranteeTypesOptions,
  guaranteeInfoOptions,
  errors,
  touched,
}: CalculatorGuaranteeInfoProps) => {
  const { setFieldValue, setTouched } = useFormikContext();
  const [isEmptyDateValue, setIsEmptyDateValue] = useState<boolean>(false);

  const getTomorrowDate = (currentDate: Date) => {
    return addDays(currentDate, 1);
  };
  useEffect(() => {
    if (!values.guaranteeTimeDays && !values.guaranteePeriod.startDate && !values.guaranteePeriod.endDate) {
      setIsEmptyDateValue(false);
    }
  }, [values.guaranteeTimeDays]);

  useEffect(() => {
    if (
      values.guaranteePeriod.startDate &&
      values.guaranteePeriod.endDate &&
      values.guaranteePeriod.startDate < values.guaranteePeriod.endDate
    ) {
      setFieldValue(
        "guaranteeTimeDays",
        differenceInCalendarDays(values.guaranteePeriod.endDate, values.guaranteePeriod.startDate)
      );
      setTouched({ ...touched, guaranteeTimeDays: false });
      setFieldValue("guaranteePeriod.startDate", null);
      setFieldValue("guaranteePeriod.endDate", null);
    }
  }, [setFieldValue, values.guaranteePeriod.endDate, values.guaranteePeriod.startDate, values.guaranteeTimeDays]);

  const isDisabledForGuaranteeTimeDays = () => {
    if (values.guaranteePeriod.startDate && values.guaranteePeriod.endDate) return false;
    if (
      (values.guaranteePeriod.startDate && !values.guaranteePeriod.endDate) ||
      (!values.guaranteePeriod.startDate && values.guaranteePeriod.endDate)
    )
      return true;
  };

  return (
    <ClientFormBlock title="Информация о гарантии">
      <div className={styles.wrapper}>
        <div className={styles.guaranteeInfo}>
          <div className={styles.guaranteeInfo__bgType}>
            {guaranteeTypesOptions && (
              <CustomSelect
                label="Вид гарантии"
                selectedValue={values.guaranteeType}
                name="guaranteeType"
                options={getOptions(guaranteeTypesOptions)}
                removable={true}
              />
            )}
          </div>
          <div className={styles.guaranteeInfo__guaranteeAmount}>
            <ClientFormInput label="Сумма гарантии, ₽" name="guaranteeSum" price />
          </div>
          <div className={styles.guaranteeInfo__guaranteeQuantityOfDays}>
            <ClientFormInput
              label="Срок гарантии, дней"
              name="guaranteeTimeDays"
              number
              disabled={isDisabledForGuaranteeTimeDays()}
              errors={errors.guaranteeTimeDays}
              touched={touched.guaranteeTimeDays}
              onChange={() => setIsEmptyDateValue(true)}
            />
          </div>
          <div className={styles.guaranteeInfo__guaranteePeriod}>
            <div className={styles.guaranteeInfo__text}>
              <span>или</span>
            </div>
            <div className={styles.guaranteeInfo__dateStart}>
              <ClientFormCalendar
                name="guaranteePeriod.startDate"
                value={values.guaranteePeriod.startDate}
                label="Дата начала БГ"
                disabled={!!values.guaranteeTimeDays}
                isEmptyDateValue={isEmptyDateValue}
                fullwidth
              />
            </div>
            <div className={styles.guaranteeInfo__dateEnd}>
              <ClientFormCalendar
                name="guaranteePeriod.endDate"
                value={values.guaranteePeriod.endDate}
                minDate={
                  (values.guaranteePeriod.startDate && getTomorrowDate(values.guaranteePeriod.startDate)) || new Date()
                }
                label="Дата окончания БГ"
                disabled={!!values.guaranteeTimeDays}
                fullwidth
                isEmptyDateValue={isEmptyDateValue}
                errors={errors.guaranteePeriod?.endDate}
                touched={true}
              />
            </div>
          </div>
          <div className={styles.guaranteeInfo__additionalAboutGuarantee}>
            {guaranteeInfoOptions && (
              <ClientFormMultiSelect
                label="Дополнительно о Гарантии"
                options={getMultiOptions(guaranteeInfoOptions)}
                name={"scoringItems"}
              />
            )}
          </div>
        </div>
      </div>
    </ClientFormBlock>
  );
};
