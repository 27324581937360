import { clientV4 } from "../../../common/api";
import { EmptyOutputDto } from "../../../common/api/types";
import { CreateByExistsCreditExecutionCreationInputDto } from "./dto/input/CreateByExistsCreditExecutionCreationInputDto";
import { CreateCreditExecutionCreationInputDto } from "./dto/input/CreateCreditExecutionCreationInputDto";
import { CreateDraftCreditExecutionCreationInputDto } from "./dto/input/CreateDraftCreditExecutionCreationInputDto";
import { ScoringByExistsCreditExecutionCreationInputDto } from "./dto/input/ScoringByExistsCreditExecutionCreationInputDto";
import { ScoringCreditExecutionCreationInputDto } from "./dto/input/ScoringCreditExecutionCreationInputDto";
import { CreateCreditExecutionCreationOutputDto } from "./dto/output/CreateCreditExecutionCreationOutputDto";
import { ScoringCreditExecutionCreationOutputDto } from "./dto/output/ScoringCreditExecutionCreationOutputDto";

const CONTROLLER_NAME = "CreditExecutions";

export const creditExecutionCreationApi = {
  createDraft: (model: CreateDraftCreditExecutionCreationInputDto) =>
    clientV4.post<CreateDraftCreditExecutionCreationInputDto, EmptyOutputDto>(`${CONTROLLER_NAME}/create-draft`, model),

  scoring: (model: ScoringCreditExecutionCreationInputDto) =>
    clientV4.post<ScoringCreditExecutionCreationInputDto, ScoringCreditExecutionCreationOutputDto>(
      `${CONTROLLER_NAME}/scoring`,
      model,
    ),

  create: (model: CreateCreditExecutionCreationInputDto) =>
    clientV4.post<CreateCreditExecutionCreationInputDto, CreateCreditExecutionCreationOutputDto>(
      `${CONTROLLER_NAME}/create`,
      model,
    ),
  scoringByExists: ({ creditExecutionId }: ScoringByExistsCreditExecutionCreationInputDto) =>
    clientV4.post<ScoringByExistsCreditExecutionCreationInputDto, ScoringCreditExecutionCreationOutputDto>(
      `${CONTROLLER_NAME}/${creditExecutionId}/scoring`,
      undefined,
    ),
  createByExists: ({ creditExecutionId, ...model }: CreateByExistsCreditExecutionCreationInputDto) =>
    clientV4.post<typeof model, CreateCreditExecutionCreationOutputDto>(
      `${CONTROLLER_NAME}/${creditExecutionId}/create`,
      model,
    ),
};
