import { getEmptyArray } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../../../../common/ui-kit/types";
import { CreditExecutionListItemDemandDesktopSkeleton } from "../item/CreditExecutionListItemDemand.desktop.skeleton";
import { ProductListItemDemandItemLayoutDesktop } from "../../../../../../../../common/ui/product/list/product-list-item";

const SKELETON_ITEMS = getEmptyArray(4);

export type CreditExecutionListItemDemandsListDesktopSkeletonProps = BaseCssProps;

export const CreditExecutionListItemDemandsListDesktopSkeleton = ({
  className,
}: CreditExecutionListItemDemandsListDesktopSkeletonProps) => {
  return (
    <ProductListItemDemandItemLayoutDesktop.Container className={className}>
      {SKELETON_ITEMS.map((x) => (
        <CreditExecutionListItemDemandDesktopSkeleton key={x} />
      ))}
    </ProductListItemDemandItemLayoutDesktop.Container>
  );
};
