import { useCallback } from "react";
import { useAppDispatch } from "../../../../common/store";
import { chatDialogHubMarkMessagesRead } from "../../store/chatDialog.slice";
import { useHubEvent } from "../../../../common/hubs";
import { chatDialogHubConnection } from "../../api/chatDialogHubConnection";

export const useChatDialogMarkReadDispatchEvent = () => {
  const dispatch = useAppDispatch();

  const eventHandler = useCallback(() => {
    dispatch(chatDialogHubMarkMessagesRead());
  }, [dispatch]);

  useHubEvent(chatDialogHubConnection, "MarkRead", eventHandler);
};
