import { useState } from "react";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { BankGuaranteeCalculatorForm } from "./BankGuaranteeCalculatorForm";
import { BankGuaranteeCalculatorFormValues } from "../types/BankGuaranteeCalculatorFormValues";
import { BankGuaranteeCalculatorStage } from "../types/BankGuaranteeCalculatorStage";
import { useApiMutation } from "../../../common/hooks";
import { calculateBankGuaranteeCalculator } from "../store/bankGuaranteeCalculator.thunks";
import { bankGuaranteeCalculatorValidationSchema } from "../validation/BankGuaranteeCalculatorValidationSchema";
import { BankGuaranteeCalculatorBankResult } from "../types/BankGuaranteeCalculatorBankResult";
import { bankGuaranteeCalculatorMapperService } from "../services/bankGuaranteeCalculatorMapperService";
import { BankGuaranteeCalculatorResult } from "./BankGuaranteeCalculatorResult";
import * as S from "./BankGuaranteeCalculator.styled";

export type BankGuaranteeCalculatorProps = BaseCssProps;

export const BankGuaranteeCalculator = ({ className }: BankGuaranteeCalculatorProps) => {
  const [stage, setStage] = useState<BankGuaranteeCalculatorStage>("form");
  const [actualFormValues, setActualFormValues] = useState<BankGuaranteeCalculatorFormValues>(
    bankGuaranteeCalculatorValidationSchema.getDefault() as BankGuaranteeCalculatorFormValues
  );
  const [scoringResult, setScoringResult] = useState<BankGuaranteeCalculatorBankResult[] | undefined>(undefined);
  const [isInitialTouched, setIsInitialTouched] = useState(false);

  const { error, mutate } = useApiMutation(calculateBankGuaranteeCalculator, { errorMessagePlaceholder: "" });

  const handleSubmit = async (values: BankGuaranteeCalculatorFormValues): Promise<void> => {
    const castedValues = bankGuaranteeCalculatorValidationSchema.cast(values) as BankGuaranteeCalculatorFormValues;
    const result = await mutate(bankGuaranteeCalculatorMapperService.mapInput(castedValues));

    if (result?.body) {
      setStage("result");
      setActualFormValues(castedValues);
      setScoringResult(bankGuaranteeCalculatorMapperService.mapOutput(result.body));
    }
  };

  const onBackButtonClick = () => {
    setStage("form");
    setScoringResult(undefined);
    setIsInitialTouched(true);
  };

  return (
    <S.Container className={className}>
      {stage === "form" && (
        <BankGuaranteeCalculatorForm
          initialValues={actualFormValues}
          handleSubmit={handleSubmit}
          submitError={error}
          isInitialTouched={isInitialTouched}
        />
      )}
      {stage === "result" && scoringResult && (
        <BankGuaranteeCalculatorResult
          values={scoringResult}
          onBackClick={onBackButtonClick}
          formValues={actualFormValues}
        />
      )}
    </S.Container>
  );
};
