import * as React from "react";
import { FilteringArrayKeys } from "../../../../types/FilteringArrayKeys";
import { CodeWithTitle } from "../../../../../types/objects";
import { FilterMobileCollapseUncontrolledContainer } from "../collapse-container/FilterMobileCollapseUncontrolledContainer";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { FilterFormCheckboxes } from "../../desktop/form-checkboxes/FilterFormCheckboxes";

export interface FilterFormMobileCheckboxesWithCollapseProps<TFormValues> extends BaseCssProps {
  fieldName: FilteringArrayKeys<TFormValues>;
  title: string;
  beforeList?: React.ReactNode;
  items: CodeWithTitle[];
}

export const FilterFormMobileCheckboxesWithCollapse = <TFormValues,>({
  fieldName,
  beforeList,
  title,
  items,
  className,
}: FilterFormMobileCheckboxesWithCollapseProps<TFormValues>) => {
  return (
    <FilterMobileCollapseUncontrolledContainer title={title} className={className}>
      <FilterFormCheckboxes fieldName={fieldName} items={items} beforeList={beforeList} />
    </FilterMobileCollapseUncontrolledContainer>
  );
};
