import { useMemo } from "react";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { ProductStageCounter } from "../../../../../../common/ui/product/stage";
import { BankGuaranteeDemandStageType, bankGuaranteeDemandStageType } from "../../../../../common/types";
import { getBankGuaranteeDemandStageColor, getBankGuaranteeDemandStageIcon } from "../../../../../common/utils";

export interface BankGuaranteeListItemStageCounterProps extends BaseCssProps {
  stage: BankGuaranteeDemandStageType;
  count: number;
}

export const BankGuaranteeListItemStageCounter = ({
  stage,
  count,
  className,
}: BankGuaranteeListItemStageCounterProps) => {
  const icon = useMemo(() => getBankGuaranteeDemandStageIcon(stage), [stage]);
  const color = getBankGuaranteeDemandStageColor(stage);

  return (
    <ProductStageCounter
      color={color}
      count={count}
      className={className}
      icon={icon}
      tooltip={bankGuaranteeDemandStageType.getTitle(stage)}
    />
  );
};
