import { useMemo, useState } from "react";
import { SignalrConnection } from "../types/SignalrConnection";
import { useAppSelector } from "../../store";
import { selectHubById } from "../store/hubs.selectors";
import { HubState } from "../store/hubs.types";
import { HubConnectionStatus } from "../types/HubConnectionStatus";

export const useHubState = <THubId extends string>(
  connection: SignalrConnection<THubId, string, string>,
): HubState<THubId> & { isConnected: boolean } => {
  const [hubId] = useState(connection.getId());
  const hub = useAppSelector((state) => selectHubById(state, hubId));

  return useMemo(() => {
    const { error, status } = hub ?? { status: HubConnectionStatus.Idle };

    return { hubId, error, status, isConnected: status === HubConnectionStatus.Connected };
  }, [hubId, hub]);
};
