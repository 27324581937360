import { memo } from "react";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import * as S from "./BankGuaranteeListItemMobileDemandList.styled";
import { BankGuaranteeListItemMobileDemandSkeleton } from "../demand-list-item/BankGuaranteeListItemMobileDemand.skeleton";

export interface BankGuaranteeListItemMobileDemandListSkeletonProps extends BaseCssProps {
  demandsCount: number;
}

export const BankGuaranteeListItemMobileDemandListSkeleton = memo(
  ({ className, demandsCount }: BankGuaranteeListItemMobileDemandListSkeletonProps) => {
    return (
      <S.Ul className={className}>
        {Array.from({ length: demandsCount }).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <BankGuaranteeListItemMobileDemandSkeleton key={i} />
        ))}
      </S.Ul>
    );
  }
);
