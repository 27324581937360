import { combineSliceNames } from "../../../common/store/utils";
import { CHAT_SLICE_NAME } from "../../common/store";
import { chatDialogApi } from "../api/chatDialogApi";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";

export const CHAT_DIALOG_SLICE_NAME = combineSliceNames(CHAT_SLICE_NAME, "dialog");

export const {
  markRead: markReadChatDialog,
  sendMessage: sendMessageChatDialog,
  getMessages: getMessagesChatDialog,
} = createApiThunks(CHAT_DIALOG_SLICE_NAME, chatDialogApi);
