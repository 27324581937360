import { date, number, object, mixed } from "yup";
import { dateTimeHelper } from "@sideg/helpers";
import {
  FormSchemaType,
  getDateOnlyTransformer,
  getEnumLikeLiteralTypeSchema,
  getFormattedNumberTransformer,
  getRequestDateSchemaType,
  getTaxpayerNumberSchema,
  isFormValuesSchemaType,
} from "../../../common/validation";
import { clientTaxSystemType } from "../../../common/types/demand";

const getCreditWorkingAssetsCreationValidationSchema = (schemaType: FormSchemaType) =>
  object({
    clientId: number().required("Не забудьте заполнить"),
    clientTaxSystem: getEnumLikeLiteralTypeSchema(clientTaxSystemType, "Не забудьте заполнить")
      .default("Simplified")
      .required("Не забудьте заполнить"),
    taxpayerNumber: isFormValuesSchemaType(schemaType) ? getTaxpayerNumberSchema().notRequired() : mixed().strip(),
    dateStart: isFormValuesSchemaType(schemaType)
      ? date()
          .transform(getDateOnlyTransformer())
          .typeError("Укажите корректную дату")
          .min(
            dateTimeHelper.unaryModify("startOfDay", new Date()),
            `Дата начала должна быть не ранее сегодняшнего дня`,
          )
          .default(() => new Date())
          .required("Не забудьте заполнить")
      : getRequestDateSchemaType(),
    dateEnd: isFormValuesSchemaType(schemaType)
      ? date()
          .transform(getDateOnlyTransformer())
          .when("dateStart", (dateStart: Date | null | undefined, schema) => {
            return dateTimeHelper.isValid(dateStart)
              ? schema.min(
                  dateTimeHelper.simpleModify("addDays", dateStart, 1),
                  "Дата окончания не должна быть ранее даты начала",
                )
              : schema.min(
                  dateTimeHelper.simpleModify("addDays", new Date(), 1),
                  "Дата окончания должна быть не ранее завтрашнего дня",
                );
          })
          .typeError("Укажите корректную дату")
          .required("Не забудьте заполнить")
      : getRequestDateSchemaType(),
    creditSum: number()
      .transform(getFormattedNumberTransformer())
      .typeError("Укажите корректную сумму")
      .min(1, "Сумма должна быть больше нуля")
      .required("Не забудьте заполнить"),
  });

export const getCreditWorkingAssetsCreationFromValidationSchema = () =>
  getCreditWorkingAssetsCreationValidationSchema("formValues");

export const getCreditWorkingAssetsCreationRequestValidationSchema = () =>
  getCreditWorkingAssetsCreationValidationSchema("request");

export const creditWorkingAssetsCreationFromValidationSchema = getCreditWorkingAssetsCreationFromValidationSchema();

export const creditWorkingAssetsCreationRequestValidationSchema =
  getCreditWorkingAssetsCreationRequestValidationSchema();
