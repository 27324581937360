import { WebPushesSubscription } from "../types/WebPushesSubscription";
import { jsonService } from "../../services/json";
import { WebPushesSubscriptionEndpoint } from "../types/WebPushesSubscriptionEndpoint";
import { make } from "../../types/utils";

const mapSubscription = (subscription: PushSubscription): WebPushesSubscription => {
  return jsonService.deserialize<WebPushesSubscription>(jsonService.serialize(subscription));
};

const mapEndpoint = (subscription: PushSubscription): WebPushesSubscriptionEndpoint => {
  return make<WebPushesSubscriptionEndpoint>()(subscription.endpoint);
};

export const webPushesSubscriptionMapper = {
  mapSubscription,
  mapEndpoint,
};
