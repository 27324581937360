import { BaseCssProps } from "../../../../common/ui-kit/types";
import { BankGuaranteeId, DemandId } from "../../../../common/types/demand";
import { BankGuaranteeFileRegistryStatusType } from "../../../common/types";
import { ButtonLinkWithIcon } from "../../../../common/ui-kit/buttons/link-with-icon";
import { Mail16Icon } from "../../../../common/ui-kit/icons";
import { useRequestBankGuaranteeExtractFromRegistry } from "../../hooks/useRequestBankGuaranteeExtractFromRegistry";
import { BankGuaranteeExtractFromRegistryRequestedExtract } from "../BankGuaranteeExtractFromRegistryRequestedExtract";

export interface BankGuaranteeExtractFromRegistryButtonMobileProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  statusType?: BankGuaranteeFileRegistryStatusType;
}

export const BankGuaranteeExtractFromRegistryButtonMobile = ({
  guaranteeId,
  demandId,
  statusType,
  className,
}: BankGuaranteeExtractFromRegistryButtonMobileProps) => {
  const requestExtractFromRegistry = useRequestBankGuaranteeExtractFromRegistry(guaranteeId, demandId);

  return (
    <>
      {statusType === "AvailableForRequest" && (
        <ButtonLinkWithIcon type="button" icon={Mail16Icon} className={className} onClick={requestExtractFromRegistry}>
          Запросить выписку из реестра БГ
        </ButtonLinkWithIcon>
      )}
      {statusType === "Requested" && <BankGuaranteeExtractFromRegistryRequestedExtract className={className} />}
    </>
  );
};
