import { useCallback } from "react";
import { CreditExecutionId } from "../../../../common/types/demand";
import { useAppDispatch } from "../../../../common/store";
import { CreditExecutionListFilterContextValue } from "../../types/filters/CreditExecutionListFilterContextValue";
import { GetCreditExecutionListInputDto } from "../../api/dto/input/GetCreditExecutionListInputDto";
import { loggingService } from "../../../../common/logging/services/loggingService";
import { changeItemCreditExecutionListItems } from "../../store/items/creditExecutionListItems.slice";
import { getCreditExecutionSingleListItem } from "../../store/creditExecutionList.thunks";

export const useCreditExecutionListFetchSingleItem = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    async (creditExecutionId: CreditExecutionId, actualFilters: CreditExecutionListFilterContextValue) => {
      const inputModel: GetCreditExecutionListInputDto = {
        ...actualFilters,
        page: 1,
        productId: creditExecutionId,
      };

      try {
        const result = await dispatch(getCreditExecutionSingleListItem(inputModel)).unwrap();
        dispatch(
          changeItemCreditExecutionListItems({
            id: creditExecutionId,
            item: result?.creditExecutionId === creditExecutionId ? result : undefined,
          })
        );
      } catch (e) {
        loggingService.logWarn(new Error("Не удалось обновить элемент в списке заявок"));
      }
    },
    [dispatch]
  );
};
