import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { useAppSelector } from "projects/agent/src/domains/common/store";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeDetailsDemandReconciliationContentBadge } from "../content/BankGuaranteeDetailsDemandReconciliationContentBadge";
import { FileDownloadLabel } from "../../../../../../files/download";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { BankGuaranteeDetailsDemandReconciliationContent } from "../content/BankGuaranteeDetailsDemandReconciliationContent";
import { BankGuaranteeDetailsDemandReconciliationContentTitleWithState } from "../content/BankGuaranteeDetailsDemandReconciliationContentTitleWithState";
import { BankGuaranteeDetailsDemandReconciliationContentOffer } from "../content/BankGuaranteeDetailsDemandReconciliationContentOffer";
import { BankGuaranteeDetailsDemandReconciliationContentFooter } from "../content/BankGuaranteeDetailsDemandReconciliationContentFooter";
import { BankGuaranteeDetailsDemandActualProject } from "../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandActualProject";
import { selectBankGuaranteeDetailsActiveDemand } from "../../../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";

export interface BankGuaranteeDetailsDemandReconciliationProjectContentProps extends BaseCssProps {
  model: BankGuaranteeDetailsDemandActualProject | undefined;
}

export const BankGuaranteeDetailsDemandReconciliationProjectContent = ({
  model,
  className,
}: BankGuaranteeDetailsDemandReconciliationProjectContentProps) => {
  const isDesktop = useThemeIsDesktop();
  const { canSendComment } = useAppSelector(selectBankGuaranteeDetailsActiveDemand);

  return (
    model && (
      <BankGuaranteeDetailsDemandReconciliationContent.Article className={className}>
        <BankGuaranteeDetailsDemandReconciliationContent.Block withLineSeparator>
          <BankGuaranteeDetailsDemandReconciliationContentTitleWithState
            title={isDesktop ? "Проект БГ" : "Согласование проекта БГ"}
            state={model.state.code}
          />
        </BankGuaranteeDetailsDemandReconciliationContent.Block>
        {model.decision && model.decisionOfferQueueTaskId !== undefined && (
          <BankGuaranteeDetailsDemandReconciliationContentBadge
            queueTaskId={model.decisionOfferQueueTaskId}
            decision={model.decision.code}
            groupType={model.groupType.code}
          />
        )}
        <BankGuaranteeDetailsDemandReconciliationContentOffer
          queueTaskId={model.actualOfferQueueTaskId}
          groupType={model.groupType.code}
          state={model?.state.code}
        >
          <Box display="inline-flex">
            <FileDownloadLabel
              fileId={model.file.fileId}
              privateKey={model.file.privateKey}
              fileName={model.file.fileName}
            />
          </Box>
        </BankGuaranteeDetailsDemandReconciliationContentOffer>
        <BankGuaranteeDetailsDemandReconciliationContentFooter
          groupType={model.groupType.code}
          canSendComment={canSendComment}
        />
      </BankGuaranteeDetailsDemandReconciliationContent.Article>
    )
  );
};
