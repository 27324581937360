import { memo } from "react";
import styled from "@emotion/styled";
import { AppLink } from "../../urls/internal/components";
import { ReactComponent as Logo } from "./assets/logo.svg";

const StyledLogo = styled(Logo)`
  height: 18px;
  margin-left: ${({ theme }) => `-${theme.layout.borderPadding}`};
`;

export const BrandLink = memo(() => {
  return (
    <AppLink to={(x) => x.root}>
      <StyledLogo />
    </AppLink>
  );
});
