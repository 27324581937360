import { useAppSelector } from "../../../../../common/store";
import { selectCreditWorkingAssetsListItemModification } from "../../../store/item-modification/creditWorkingAssetsListItemModification.selectors";
import {
  isCreditWorkingAssetsListItemRemoveDemandModification,
  isCreditWorkingAssetsListItemRemoveProductModification,
} from "../../../store/item-modification/creditWorkingAssetsListItemModification.types";
import { CreditWorkingAssetsListItemRejectProduct } from "./CreditWorkingAssetsListItemRejectProduct";
import { CreditWorkingAssetsListItemRejectDemand } from "./CreditWorkingAssetsListItemRejectDemand";

export const CreditWorkingAssetsListModification = () => {
  const state = useAppSelector(selectCreditWorkingAssetsListItemModification);

  return (
    <>
      {isCreditWorkingAssetsListItemRemoveProductModification(state) && (
        <CreditWorkingAssetsListItemRejectProduct creditWorkingAssetsId={state.creditWorkingAssetsId} />
      )}
      {isCreditWorkingAssetsListItemRemoveDemandModification(state) && (
        <CreditWorkingAssetsListItemRejectDemand
          creditWorkingAssetsId={state.creditWorkingAssetsId}
          demandId={state.demandId}
        />
      )}
    </>
  );
};
