import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spaces.lg};
  background-color: ${({ theme }) => theme.colors.primaryDark};
  border-radius: 4px;
  cursor: pointer;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.layout.sidebar.menu.linkColor};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-size: ${({ theme }) => theme.font.fz4};
`;
