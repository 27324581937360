import { DocumentTypeId } from "../../types/documents";
import { isLoading, LoadingStatusEnum } from "../../types/state";

export const isNeedToHideProductDetailsDocuments = (
  ids: DocumentTypeId[],
  data: unknown | undefined,
  status: LoadingStatusEnum,
  isExact: boolean
) => {
  return (data !== undefined && ids.length === 0) || (!isExact && isLoading(status) && ids.length === 0);
};
