import { Box } from "../../../common/ui-kit/containers/box";
import { BankGuaranteeDetailsRequestOriginalHeader } from "../components/request-original/header/BankGuaranteeDetailsRequestOriginalHeader";
import { BankGuaranteeDetailsRequestOriginalBody } from "../components/request-original/BankGuaranteeDetailsRequestOriginalBody";

export const BankGuaranteeDetailsRequestOriginalPage = () => {
  return (
    <Box display="flex" flexDirection="column" flexGrow={1} flexShrink={1} overflow="hidden">
      <BankGuaranteeDetailsRequestOriginalHeader />
      <BankGuaranteeDetailsRequestOriginalBody />
    </Box>
  );
};
