import { clientV4 } from "../../../common/api";
import { GetCreditExecutionListInputDto } from "./dto/input/GetCreditExecutionListInputDto";
import { GetCountersCreditExecutionOutputDto } from "./dto/output/GetCountersCreditExecutionOutputDto";
import { GetCreditExecutionListOutputDto } from "./dto/output/GetCreditExecutionListOutputDto";
import { GetFiltrationParametersCreditExecutionListOutputDto } from "./dto/output/GetFiltrationParametersCreditExecutionListOutputDto";

const CONTROLLER_NAME = "creditExecutions";

export const creditExecutionListApi = {
  getFiltrationParameters: () =>
    clientV4.get<undefined, GetFiltrationParametersCreditExecutionListOutputDto>(
      `${CONTROLLER_NAME}/filtration-parameters`
    ),
  getCounters: () =>
    clientV4.get<undefined, GetCountersCreditExecutionOutputDto>(`${CONTROLLER_NAME}/count-active-demands`),

  get: (filter?: GetCreditExecutionListInputDto, abortSignal?: AbortSignal) =>
    clientV4.get<GetCreditExecutionListInputDto, GetCreditExecutionListOutputDto>(CONTROLLER_NAME, filter, {
      abortSignal,
    }),
};
