import styled from "@emotion/styled";

export const Button = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.layout.sidebar.menu.linkColor};
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:hover:not(:disabled) {
    opacity: 0.8;
  }

  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.layout.sidebar.menu.linkColor};
  }

  path {
    stroke: ${({ theme }) => theme.layout.sidebar.menu.linkColor};
  }
`;
