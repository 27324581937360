import { ApiObject, EmptyOutputDto } from "../../api/types";
import { CheckSubscriptionWebPushesInputDto } from "./dto/input/CheckSubscriptionWebPushesInputDto";
import { clientV4 } from "../../api";
import { CheckSubscriptionWebPushesOutputDto } from "./dto/output/CheckSubscriptionWebPushesOutputDto";
import { UnsubscribeWebPushesInputDto } from "./dto/input/UnsubscribeWebPushesInputDto";
import { SubscribeWebPushesInputDto } from "./dto/input/SubscribeWebPushesInputDto";

const CONTROLLER_NAME = "WebPushes";

export const webPushesApi = {
  checkSubscription: (model: CheckSubscriptionWebPushesInputDto) =>
    clientV4.post<CheckSubscriptionWebPushesInputDto, CheckSubscriptionWebPushesOutputDto>(
      `${CONTROLLER_NAME}/check-subscription`,
      model
    ),
  subscribe: (model: SubscribeWebPushesInputDto) =>
    clientV4.post<SubscribeWebPushesInputDto, EmptyOutputDto>(`${CONTROLLER_NAME}/subscribe`, model),
  unsubscribe: (model: UnsubscribeWebPushesInputDto) =>
    clientV4.put<UnsubscribeWebPushesInputDto, EmptyOutputDto>(`${CONTROLLER_NAME}/unsubscribe`, model),
} satisfies ApiObject;
