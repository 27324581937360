import styled from "@emotion/styled";
import { GlobalNetworkStateType } from "../types/GlobalNetworkStateType";

export const Container = styled.div<{ state: GlobalNetworkStateType }>`
  position: fixed;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.layout.global.network.zIndex};
  min-height: 60px;
  padding: 15px 10px 30px 10px;
  background-color: ${({ theme, state }) =>
    state === "offline" ? theme.palette.typography.medium : theme.palette.status.success};
  transition: background-color 0.3s ease-in-out,
    ${({ state }) => (state === "online" ? "bottom 0.3s 0.7s linear" : "bottom 0.7s linear")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  user-select: none;
  bottom: -200px;

  &.enter-done {
    bottom: 0;
  }

  &.exit {
    bottom: -200px;
  }
`;
