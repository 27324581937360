import { SimpleOpacityTransition } from "../../../common/ui-kit/animations/transitions";
import { clientRules } from "../../validation/rules/client.rules";
import { AlertLoadingToResult } from "../../../common/ui-kit/notifications/alert-with-indicator";
import { isLoading } from "../../../common/types/state";
import { useAppSelector } from "../../../common/store";
import { ClientSearchClientStateSelector } from "../../types/ClientSearchClientStateSelector";
import { useSearchClientErrorLabel } from "../../hooks/useSearchClientErrorLabel";

export interface SearchClientCheckAlertProps {
  clientStateSelector: ClientSearchClientStateSelector;
}

export const SearchClientCheckAlert = ({ clientStateSelector }: SearchClientCheckAlertProps) => {
  const { check } = useAppSelector(clientStateSelector);
  const { isError, errorText } = useSearchClientErrorLabel(check?.data);

  return (
    <SimpleOpacityTransition timeout={250} in>
      <AlertLoadingToResult
        isLoading={isLoading(check?.status)}
        loadingText="Проверяем надежность клиента"
        isError={isError}
        errorText={errorText}
        isSuccess={check?.data && clientRules.isClientPassing(check.data) && !isError}
        successText="Клиент не находится в стадии банкротства и в РНП"
      />
    </SimpleOpacityTransition>
  );
};
