import { useMemo } from "react";
import { BankGuaranteeCalculatorBankResult } from "../types/BankGuaranteeCalculatorBankResult";
import { FilteringBaseOrderType } from "../../../common/filters";
import { BankGuaranteeCalculatorOrderFiled } from "../types/BankGuaranteeCalculatorOrderFiled";

export const useBankGuaranteeCalculatorOrderedResult = (
  collection: BankGuaranteeCalculatorBankResult[],
  orderField: BankGuaranteeCalculatorOrderFiled,
  orderType: FilteringBaseOrderType
) => {
  return useMemo(() => {
    return [...collection].sort((a, b) => {
      const [resultA, resultB] = orderType === "asc" ? [a, b] : [b, a];

      if (orderField === "bankName") {
        return resultA.bankName.localeCompare(resultB.bankName);
      }

      if (orderField === "price") {
        return Number(resultA.price) - Number(resultB.price);
      }

      return 0;
    });
  }, [collection, orderField, orderType]);
};
