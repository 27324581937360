import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: ${({ theme }) => theme.spaces.md};
  gap: ${({ theme }) => theme.spaces.md};
  background-color: #fff;
  overscroll-behavior: none;
  overflow-scrolling: auto;
`;

// Чтобы был сколл у контейнера, и при этом малое количество
// сообщений были прикреплены к низу чата
export const SpaceFiller = styled.div`
  flex: 1 1 auto;
`;
