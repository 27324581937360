import { ComponentProps } from "react";
import { NavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { FullPageLayoutContainer } from "../../../../common/ui/layout";
import { FullPageContainerCompound } from "../../../../common/ui/containers/full-page";
import { UserWebPushesPageHeaderMobile } from "./UserWebPushesPageHeader.mobile";
import { Typography } from "../../../../common/ui-kit/typography";
import { Box } from "../../../../common/ui-kit/containers/box";
import { Switch } from "../../../../common/ui-kit/form-controls/switch";
import {
  isUserSideWebPushesSubscriptionState,
  useWebPushesIsSubscribed,
  useWebPushesSubscriptionApiContext,
  useWebPushesSubscriptionStateContext,
} from "../../../../common/web-pushes";

const PARAGRAPH_PROPS: Partial<ComponentProps<typeof Typography>> = {
  fontWeight: 500,
  lineHeight: "defaultLineHeight",
  fontSize: "fz3",
  fontColor: "typography.medium",
  as: "p",
};

const TypographyBox = Box.withComponent(Typography);

export interface UserWebPushesMobileProps {
  from: NavigationStateFrom;
}

export const UserWebPushesMobile = ({ from }: UserWebPushesMobileProps) => {
  const subscriptionState = useWebPushesSubscriptionStateContext();
  const [isSubscribed, setIsSubscribed] = useWebPushesIsSubscribed();

  const { subscribe, unsubscribe } = useWebPushesSubscriptionApiContext();

  const onSwitchChange = async () => {
    setIsSubscribed((prev) => !prev);
    const isSuccess = await (isSubscribed ? unsubscribe() : subscribe());
    if (!isSuccess) {
      setIsSubscribed((prev) => !prev);
    }
  };

  return (
    <FullPageLayoutContainer>
      <UserWebPushesPageHeaderMobile from={from} />
      <FullPageContainerCompound.MainWrapper>
        <FullPageContainerCompound.MainCenteredContainer gap="xxl">
          <TypographyBox pt="xxl" fontWeight={700} lineHeight="1.4" fontSize={1.5}>
            Уведомления в браузере
          </TypographyBox>
          <Box
            backgroundColor="secondary.primaryBackground"
            pt="xxl"
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-around"
          >
            <img
              alt="Подписка на уведомления"
              width="260"
              height="135"
              src="/assets/images/web-pushes/notification-mobile.svg"
            />
          </Box>
          <Switch
            onChange={onSwitchChange}
            checked={isSubscribed}
            disabled={!isUserSideWebPushesSubscriptionState(subscriptionState)}
            withStateIcon
            labelPosition="before"
            label={
              <TypographyBox fontSize="fz3" lineHeight="defaultLineHeight" fontWeight={500} flexGrow={1} flexShrink={1}>
                Разрешить уведомления
              </TypographyBox>
            }
          />
          <Box pt="md">
            <Typography {...PARAGRAPH_PROPS}>Разрешите уведомления в вашем браузере для lk.teledoc.ru.</Typography>
            <TypographyBox pt="md" {...PARAGRAPH_PROPS}>
              Вы всегда будете знать об изменениях в задачах и новых ответах и сможете быстро к ним перейти.
            </TypographyBox>
          </Box>
        </FullPageContainerCompound.MainCenteredContainer>
      </FullPageContainerCompound.MainWrapper>
    </FullPageLayoutContainer>
  );
};
