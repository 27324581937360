import { memo } from "react";
import { BankGuaranteeId } from "../../../../../../../common/types/demand";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import { BankGuaranteeListItemStageCounters } from "../../stage/BankGuaranteeListItemStageCounters";
import { BankGuaranteeListItemMobileDemandList } from "./BankGuaranteeListItemMobileDemandList";
import { useAppSelector } from "../../../../../../../common/store";
import { selectBankGuaranteeListItemsGuaranteeById } from "../../../../../store/items/bankGuaranteeListItems.selectors";
import { BankGuaranteeListItemMobileDemandsContainer } from "./container/BankGuaranteeListItemMobileDemandsContainer";
import { ProductListCardTasksCountBadgeMobile } from "../../../../../../../common/ui/product/list/card";
import { declensionHelper } from "../../../../../../../common/helpers/entity";

export interface BankGuaranteeListItemMobileDemandsProps {
  guaranteeId: BankGuaranteeId;
  from?: NavigationState;
}

export const BankGuaranteeListItemMobileDemands = memo(
  ({ guaranteeId, from }: BankGuaranteeListItemMobileDemandsProps) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const guarantee = useAppSelector((state) => selectBankGuaranteeListItemsGuaranteeById(state, guaranteeId))!;

    return (
      <BankGuaranteeListItemMobileDemandsContainer
        banksCount={declensionHelper.commonDeclensions.bank.nominative(guarantee.demandIds.length, true)}
        activeTasksBadge={
          guarantee.activeTasksCount > 0 && <ProductListCardTasksCountBadgeMobile count={guarantee.activeTasksCount} />
        }
        counters={<BankGuaranteeListItemStageCounters counters={guarantee.counters} />}
        list={<BankGuaranteeListItemMobileDemandList guaranteeId={guaranteeId} from={from} />}
      />
    );
  },
);
