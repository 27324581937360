import { currencyHelper } from "@sideg/helpers";
import { CreditExecutionId, DemandId } from "../../../../../common/types/demand";
import { useAppSelector } from "../../../../../common/store";
import { Box } from "../../../../../common/ui-kit/containers/box";
import * as S from "./CreditExecutionDetailsAsideDemandsListItem.styled";
import { CircleIndicator } from "../../../../../common/ui-kit/notifications/circle-indicator";
import { useAppUrlMatch } from "../../../../../common/urls/internal/hooks";
import { selectCreditExecutionDetailsDemandById } from "../../../store/demands/creditExecutionDetailsDemands.selectors";
import {
  ProductDetailsAsideNavigationCard,
  ProductDetailsAsideNavigationCardCounter,
  ProductDetailsAsideNavigationCardItemPrice,
} from "../../../../../common/product-details";
import { LikeIcon, QuestionIcon } from "../../../../../common/ui-kit/icons";

export interface CreditExecutionDetailsAsideDemandsListItemProps {
  creditExecutionId: CreditExecutionId;
  demandId: DemandId;
  className?: string;
}

export const CreditExecutionDetailsAsideDemandsListItem = ({
  creditExecutionId,
  demandId,
  className,
}: CreditExecutionDetailsAsideDemandsListItemProps) => {
  const demand = useAppSelector((state) => selectCreditExecutionDetailsDemandById(state, demandId));
  const { path, isExact } = useAppUrlMatch((x) => x.creditExecution.details.demand, {
    creditExecutionId,
    demandId,
  });

  if (!demand) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  return (
    <ProductDetailsAsideNavigationCard
      className={className}
      to={path}
      isActive={isExact}
      isSecondary={demand.isTerminated}
    >
      <Box
        gap="sm"
        display="flex"
        flexDirection="row"
        alignItems="center"
        flexShrink={1}
        flexGrow={1}
        overflow="hidden"
      >
        <S.Icon stage={demand.stage.code} />
        <S.Separator stage={demand.stage.code} />

        <Box
          gap="md"
          display="flex"
          flexDirection="column"
          flexShrink={1}
          flexGrow={1}
          justifyContent="space-between"
          overflow="hidden"
        >
          <Box gap="xs" display="flex" flexDirection="row" alignItems="center">
            <S.BankName stage={demand.stage.code}>{demand.bank.title}</S.BankName>
            {demand.isUpdated && <CircleIndicator color={(theme) => theme.colors.primary} size={4} />}
          </Box>

          <Box gap="lg" display="flex" alignItems="center" flexDirection="row" overflow="hidden">
            <ProductDetailsAsideNavigationCardItemPrice
              color="typography.medium"
              icon={QuestionIcon}
              text={currencyHelper.getCurrencyString(demand.creditSum)}
            />
            {demand.confirmedCreditSum !== undefined && (
              <ProductDetailsAsideNavigationCardItemPrice
                color="typography.successSecondary"
                icon={LikeIcon}
                text={currencyHelper.getCurrencyString(demand.confirmedCreditSum)}
              />
            )}
          </Box>

          <S.StatusWithCounter hasActiveTasks={demand.countActiveTasks > 0} stage={demand.stage.code}>
            <S.Status>
              {!demand.isApproved && demand.status.title}
              {demand.isApproved && `Выдано ${currencyHelper.getCurrencyStringNullable(demand.approvedCreditSum, "")}`}
            </S.Status>
            {demand.countActiveTasks > 1 && <S.TaskCounter>+{demand.countActiveTasks - 1}</S.TaskCounter>}
          </S.StatusWithCounter>
        </Box>
      </Box>

      {demand.countMissingDocuments !== undefined && demand.countMissingDocuments > 0 && (
        <ProductDetailsAsideNavigationCardCounter variant="info">
          {demand.countMissingDocuments}
        </ProductDetailsAsideNavigationCardCounter>
      )}
    </ProductDetailsAsideNavigationCard>
  );
};
