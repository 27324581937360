import { BankGuaranteeDetailsDemandReconciliationProjectContent } from "../../../components/demand/reconciliation/project/BankGuaranteeDetailsDemandReconciliationProjectContent";
import { BankGuaranteeDetailsReconciliationPageLayout } from "./BankGuaranteeDetailsReconciliationPageLayout";
import { selectBankGuaranteeDetailsActiveDemandActualProject } from "../../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { BankGuaranteeDetailsDemandReconciliationProjectContentSkeleton } from "../../../components/demand/reconciliation/project/BankGuaranteeDetailsDemandReconciliationProjectContent.skeleton";
import { useBankGuaranteeDetailsReconciliationState } from "../../../hooks/reconciliation/useBankGuaranteeDetailsReconciliationState";

export const BankGuaranteeDetailsReconciliationProjectPage = () => {
  const { model, needShowSkeleton } = useBankGuaranteeDetailsReconciliationState(
    selectBankGuaranteeDetailsActiveDemandActualProject,
  );

  return (
    <BankGuaranteeDetailsReconciliationPageLayout>
      {needShowSkeleton && <BankGuaranteeDetailsDemandReconciliationProjectContentSkeleton />}
      {!needShowSkeleton && <BankGuaranteeDetailsDemandReconciliationProjectContent model={model} />}
    </BankGuaranteeDetailsReconciliationPageLayout>
  );
};
