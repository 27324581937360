import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError, ApiMethod, ApiObject } from "../../api/types";
import { combineSliceNames } from "./combineSliceNames";
import { FilteredKeys } from "../../types/utils";
import { logger } from "../../logging";

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export type ApiAsyncThunk<TOutput, TInput> = AsyncThunk<TOutput, TInput, { rejectValue: ApiError<TInput> }>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyOutputApiAsyncThunk<TInput> = ApiAsyncThunk<any, TInput>;

export const createApiThunk = <
  TApi extends ApiObject,
  TFunctionName extends keyof TApi,
  TInput extends Parameters<TApi[TFunctionName]>[0],
  TOutput extends Awaited<ReturnType<TApi[TFunctionName]>>,
>(
  sliceName: string,
  api: TApi,
  methodName: TFunctionName,
): ApiAsyncThunk<TOutput, TInput> => {
  const thunkName = combineSliceNames(sliceName, methodName.toString());

  return createAsyncThunk<TOutput, TInput, { rejectValue: ApiError<TInput> }>(
    thunkName,
    async (model, { rejectWithValue, signal }) => {
      try {
        return await api[methodName](model, signal);
      } catch (err) {
        logger.logThunkError(thunkName, err as ApiError<TInput>);

        return rejectWithValue(err as ApiError<TInput>);
      }
    },
  );
};

type ApiAsyncThunks<TApi extends ApiObject> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [P in FilteredKeys<TApi, ApiMethod<any, any>>]: TApi[P] extends ApiMethod<infer TInput, infer TOutput>
    ? ApiAsyncThunk<TOutput, unknown extends TInput ? undefined : TInput>
    : never;
};

export const createApiThunks = <TApi extends ApiObject>(sliceName: string, api: TApi): ApiAsyncThunks<TApi> => {
  return Object.entries(api).reduce((accum, [key]) => {
    accum[key] = createApiThunk(sliceName, api, key);

    return accum;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any) as ApiAsyncThunks<TApi>;
};
