import { BaseCssProps } from "../../../../../ui-kit/types";
import * as S from "./AppPublicLayoutFooter.styled";
import { Typography } from "../../../../../ui-kit/typography";
import { Box } from "../../../../../ui-kit/containers/box";
import { ManufacturerBrandLink } from "../../../../manufacturer/brand";

export type AppPublicLayoutFooterProps = BaseCssProps;

export const AppPublicLayoutFooter = ({ className }: AppPublicLayoutFooterProps) => {
  return (
    <S.Footer className={className}>
      <Box display="flex" flexDirection="column" gap="lg" alignItems="center" justifyContent="center">
        <S.LinksContainer>
          <span>
            <S.InternalLink to={(x) => x.publicUser.privacyPolicies.root}>
              Политика обработки персональных данных
            </S.InternalLink>
          </span>
          <span>
            <S.InternalLink to={(x) => x.publicUser.offers.root}>Правила и порядок работы</S.InternalLink>
          </span>
        </S.LinksContainer>
        <Typography opacity={0.5} fontSize="fz1" lineHeight="defaultLineHeight" fontColor="typography.primaryLight">
          © {import.meta.env.VITE_FOOTER_COMPANY_TITLE}. Все права защищены 2012 — {new Date().getFullYear()} г.
        </Typography>
      </Box>
      <ManufacturerBrandLink />
    </S.Footer>
  );
};
