import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../types/utils";

const titles = {
  Active: "Активные",
  Completed: "Завершенные",
  All: "Все",
};

export const filterTabType = makeEnumLikeLiteral(["Active", "Completed", "All"] as const, titles);

export type FilterTabType = EnumLikeLiteralType<typeof filterTabType>;
