import * as React from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../ui-kit/types";
import { ProductCreationScoringResultRow } from "./ProductCreationScoringResultRow";
import { Typography } from "../../../../ui-kit/typography";

export interface ProductCreationScoringResultRowRejectProps extends BaseCssProps {
  bankName: React.ReactNode;
  text: React.ReactNode;
}

export const ProductCreationScoringResultRowReject = ({
  bankName,
  text,
  className,
}: ProductCreationScoringResultRowRejectProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <ProductCreationScoringResultRow.Container
      gap={{ xs: "none", [desktopBreakpoint]: "xs" }}
      className={className}
      isSelected={false}
    >
      <ProductCreationScoringResultRow.Item
        gridRow={1}
        gridColumn={{ xs: "1/span 18", [desktopBreakpoint]: "1/span 7" }}
      >
        <Typography fontColor="typography.primary" fontWeight={500} lineHeight="defaultLineHeight" fontSize="fz4">
          {bankName}
        </Typography>
      </ProductCreationScoringResultRow.Item>
      <ProductCreationScoringResultRow.Item
        gridRow={{ xs: 2, [desktopBreakpoint]: 1 }}
        gridColumn={{ xs: "1/span 18", [desktopBreakpoint]: "8/span 11" }}
      >
        <Typography fontColor="typography.medium" fontWeight={500} lineHeight="1.5" fontSize="fz2">
          {text}
        </Typography>
      </ProductCreationScoringResultRow.Item>
    </ProductCreationScoringResultRow.Container>
  );
};
