import styled from "@emotion/styled";

export const Block = styled.section`
  padding: ${({ theme }) => theme.spaces.lg};
  background-color: ${({ theme }) => theme.palette.background.contrast};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.lg};
`;

export const ResultsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;
  align-items: center;
`;
