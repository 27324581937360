import styled from "@emotion/styled";
import { Theme } from "@sideg/ui-kit";
import { Color } from "../../../../theme";
import { colorWithAlpha } from "../../../../utils";
import { ButtonColor } from "../../types/ButtonColor";
import { CommonButton } from "../common/CommonButton";
import { ButtonCommonProps } from "../../types/ButtonCommonProps";

interface ButtonOutlinedStateColor {
  borderColor: Color;
  color: Color;
}

interface ButtonOutlinedStates {
  focusOutlineColor: Color;
  tapHighlightColor: Color;
  defaultState: ButtonOutlinedStateColor;
  disabledState: ButtonOutlinedStateColor;
  hoverState: ButtonOutlinedStateColor;
}

const getColorStyles = (theme: Theme, color: ButtonColor): ButtonOutlinedStates => {
  if (color !== "primary" && import.meta.env.MODE !== "production") {
    throw new Error(`Не реализован вариант ${color} кнопки ButtonOutlined`);
  }

  return {
    focusOutlineColor: theme.colors.primaryDark,
    tapHighlightColor: colorWithAlpha(theme.colors.primary, 128),
    defaultState: {
      borderColor: colorWithAlpha(theme.colors.primary, 128),
      color: colorWithAlpha(theme.colors.primary, 220),
    },
    disabledState: {
      borderColor: theme.colors.secondary,
      color: theme.colors.secondary,
    },
    hoverState: {
      borderColor: theme.colors.primary,
      color: theme.colors.primary,
    },
  };
};

export const ButtonOutlined = styled(CommonButton)<ButtonCommonProps>(
  () => ({
    borderRadius: "0.25rem",
    transition: "color, border-color 0.3s ease",
  }),
  ({ theme, color, isDisabledAsDefaultState }) => {
    const {
      defaultState,
      hoverState,
      disabledState: disabled,
      focusOutlineColor,
      tapHighlightColor,
    } = getColorStyles(theme, color);

    const disabledState = isDisabledAsDefaultState ? defaultState : disabled;

    return {
      border: `1px solid ${defaultState.borderColor}`,
      backgroundColor: "transparent",
      color: defaultState.color,
      WebkitTapHighlightColor: tapHighlightColor,

      "&:disabled": {
        backgroundColor: theme.colors.secondaryDisabled,
        color: disabledState.color,
        borderColor: disabledState.borderColor,
      },

      "&:hover:not(:disabled)": {
        borderColor: hoverState.borderColor,
        color: hoverState.color,
      },

      "&:focus-visible": {
        outlineColor: focusOutlineColor,
      },
    };
  },
);
