import Skeleton from "react-loading-skeleton";
import { CreditExecutionListItemStageCountersSkeleton } from "../../../counters/CreditExecutionListItemStageCounters.skeleton";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { ProductListCardDemandsListContainerDesktop } from "../../../../../../../common/ui/product/list/card";
import { CreditExecutionListItemDemandsListDesktopSkeleton } from "./list/CreditExecutionListItemDemands.desktop.skeleton";

export type CreditExecutionListItemDesktopDemandsSkeletonProps = BaseCssProps;

export const CreditExecutionListItemDesktopDemandsSkeleton = ({
  className,
}: CreditExecutionListItemDesktopDemandsSkeletonProps) => {
  return (
    <ProductListCardDemandsListContainerDesktop
      className={className}
      banksCountTitle={<Skeleton inline width={60} height={28} />}
      counters={<CreditExecutionListItemStageCountersSkeleton />}
      activeTasksCountBadge={<Skeleton />}
      actionsButton={<Skeleton inline width={28} height={28} />}
    >
      <CreditExecutionListItemDemandsListDesktopSkeleton />
    </ProductListCardDemandsListContainerDesktop>
  );
};
