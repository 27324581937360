import { ThemeMobileDesktopView, useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { StyledAppLink } from "../../../../common/ui/links";
import { useCreditWorkingAssetsDetailsContext } from "../../hooks/context/useCreditWorkingAssetsDetailsContext";
import { useCreditWorkingAssetsDetailsRejection } from "../../hooks/useCreditWorkingAssetsDetailsRejection";
import { RejectButton } from "../../../../common/ui/buttons/reject-button";
import { CreditWorkingAssetsRejectionProduct } from "../../../common/rejection";
import { useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";
import { Typography } from "../../../../common/ui-kit/typography";
import { useCreateNavigationStateFrom } from "../../../../common/navigation/navigation-state";

export type CreditWorkingAssetsDetailsAsideActionsProps = BaseCssProps;

export const CreditWorkingAssetsDetailsAsideActions = ({ className }: CreditWorkingAssetsDetailsAsideActionsProps) => {
  const { creditWorkingAssetsId } = useCreditWorkingAssetsDetailsContext();
  const { data } = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails);
  const { isModalOpen, openModal, closeModal, afterSuccess, panelKey } =
    useCreditWorkingAssetsDetailsRejection(creditWorkingAssetsId);

  const from = useCreateNavigationStateFrom("Заявка");
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <StackPanel gap="sm" direction="row" alignItems="center" justifyContent="space-between" className={className}>
      <StyledAppLink
        to={(x) => x.creditWorkingAssets.creation.createByCreditWorkingAssets}
        params={{ creditWorkingAssetsId }}
        state={from}
      >
        <Typography fontWeight={500} fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}>
          Отправить в другие
        </Typography>
      </StyledAppLink>
      {data?.canRejectAll === true && (
        <>
          <RejectButton onClick={openModal} size={{ xs: "default", [desktopBreakpoint]: "small" }}>
            <ThemeMobileDesktopView mobile="Отменить все" desktop="Отменить все заявки" />
          </RejectButton>
          <CreditWorkingAssetsRejectionProduct
            panelKey={panelKey}
            creditWorkingAssetsId={creditWorkingAssetsId}
            afterSuccess={afterSuccess}
            isOpen={isModalOpen}
            onClose={closeModal}
            groupProductName={data.groupProductName}
          />
        </>
      )}
    </StackPanel>
  );
};
