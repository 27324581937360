import { clientV3, clientV4 } from "../../../common/api";
import { ApiDataResponse } from "../../../common/api/types";
import { GetBankGuaranteeExtractRegistryInputDto } from "./input/GetBankGuaranteeExtractRegistryInputDto";
import { PostBankGuaranteeExtractRegistryInputDto } from "./input/PostBankGuaranteeExtractRegistryInputDto";
import { GetBankGuaranteeExtractRegistryOutputDto } from "./output/GetBankGuaranteeExtractRegistryOutputDto";

export const bankGuaranteeExtractFromRegistryApi = {
  get: ({ demandId }: GetBankGuaranteeExtractRegistryInputDto) =>
    clientV4.get<GetBankGuaranteeExtractRegistryInputDto, GetBankGuaranteeExtractRegistryOutputDto>(
      `/Guarantees/file-registry/${demandId}`,
      undefined,
      { responseType: "blob" },
    ),

  post: ({ guaranteeId, demandId }: PostBankGuaranteeExtractRegistryInputDto) =>
    clientV3.post<undefined, ApiDataResponse<boolean>>(
      `/Guarantee/${guaranteeId}/demand/${demandId}/request-extract-registry`,
      undefined,
    ),
};
