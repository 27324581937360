import styled from "@emotion/styled";

export const Button = styled.button<{ isOpened: boolean }>`
  appearance: none;
  border: none;
  padding: ${({ theme }) => theme.spaces.md} 0;
  margin: 0;
  background: none;
  color: ${({ theme }) => theme.colors.dark};

  & svg {
    flex-shrink: 0;
    flex-grow: 0;
    transform: rotate(${({ isOpened }) => (isOpened ? "90deg" : "-90deg")});
    transition: all 0.2s linear;

    & > path {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const Container = styled.div`
  padding: ${({ theme }) => theme.spaces.md} 0;
`;
