import { useCallback } from "react";
import { DemandId } from "../../../../common/types/demand";
import { useAppDispatch } from "../../../../common/store";
import { CommentFormFormValues } from "../../../../common/product-comment";
import { sendCommentDemandQueueTasks } from "../../store/demandQueueTasks.thunks";
import { DemandQueueTaskCompleted } from "../../types/DemandQueueTaskCompleted";
import { loggingService } from "../../../../common/logging/services/loggingService";

export const useDemandQueueTasksNewCommentOnSubmit = (
  demandId: DemandId,
  onSuccess?: (completedQueueTask: DemandQueueTaskCompleted) => void | Promise<void>,
) => {
  const dispatch = useAppDispatch();

  return useCallback(
    async ({ comment, files }: CommentFormFormValues) => {
      try {
        const result = await dispatch(
          sendCommentDemandQueueTasks({ demandId, comment, files: files.map((x) => x.file) }),
        ).unwrap();

        onSuccess?.(result.body.queueTaskCard);
      } catch (e) {
        loggingService.logError(e);
      }
    },
    [demandId, dispatch, onSuccess],
  );
};
