import { useCallback, useMemo, useState } from "react";
import { AppLayoutOverlayPanelKey } from "../types";
import { makeAppLayoutState } from "../types/AppLayoutState";
import { LayoutContextState } from "../store/LayoutContext";

export const useAppLayoutContextProvider = (): LayoutContextState => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isFullSize, setIsFullSize] = useState(false);

  const [activeOverlayPanel, setActiveOverlayPanel] = useState<AppLayoutOverlayPanelKey | undefined>(undefined);

  const [isFixedOverflow, setIsFixedOverflow] = useState(false);
  const [headerAction, setHeaderAction] = useState<React.ReactNode | undefined>(undefined);

  const setMenuState = useCallback(
    (value: boolean) => {
      if (activeOverlayPanel !== undefined && value) {
        setActiveOverlayPanel(undefined);
      }

      setIsMenuOpened(value);
    },
    [activeOverlayPanel]
  );

  const setOverlayPanelState = useCallback(
    (value: AppLayoutOverlayPanelKey | undefined) => {
      if (isMenuOpened && value) {
        setIsMenuOpened(false);
      }

      setActiveOverlayPanel(value);
    },
    [isMenuOpened]
  );

  return useMemo(
    () => ({
      isFixedOverflow: makeAppLayoutState(isFixedOverflow, setIsFixedOverflow),
      activeOverlayPanel: makeAppLayoutState(activeOverlayPanel, setOverlayPanelState),
      isFullSize: makeAppLayoutState(isFullSize, setIsFullSize),
      isMenuOpened: makeAppLayoutState(isMenuOpened, setMenuState),
      headerAction: makeAppLayoutState(headerAction, setHeaderAction),
    }),
    [activeOverlayPanel, headerAction, isFixedOverflow, isFullSize, isMenuOpened, setMenuState, setOverlayPanelState]
  );
};
