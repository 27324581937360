import { useEffect, useMemo, useState } from "react";

export const useMenuItemOpen = (isActive: boolean, initialState = false) => {
  const [open, setOpen] = useState(initialState || isActive);

  useEffect(() => {
    setOpen((prev) => (isActive ? true : prev));
  }, [isActive]);

  return useMemo(() => [open, () => setOpen((prev) => !prev)] as const, [open]);
};
