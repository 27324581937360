import { useRef } from "react";
import { Form, Formik } from "formik";
import { useBankGuaranteeDetailsComplicatedTaskBillFormInitialValues } from "../../../hooks/useBankGuaranteeDetailsComplicatedTaskBillFormInitialValues";
import { DemandQueueTaskCardContainer } from "../../../../../../../demand/queue-tasks/components/card/DemandQueueTaskCardContainer";
import { FormScrollToFirstErrorAfterSubmit } from "../../../../../../../common/form";
import { bankGuaranteeDetailsComplicatedTaskBillValidationSchema } from "../../../validation/bankGuaranteeDetailsComplicatedTaskBillValidationSchema";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { BankGuaranteeDetailsDemandQueueTaskActive } from "../../../../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskActive";
import { BankGuaranteeDetailsComplicatedTaskBillFormContent } from "./BankGuaranteeDetailsComplicatedTaskBillFormContent";
import { useBankGuaranteeDetailsComplicatedTaskBillFormOnSubmit } from "../../../hooks/useBankGuaranteeDetailsComplicatedTaskBillFormOnSubmit";

const DemandQueueTaskCardContainerForm = DemandQueueTaskCardContainer.withComponent(Form);

export interface BankGuaranteeDetailsComplicatedTaskBillFormProps extends BaseCssProps {
  task: BankGuaranteeDetailsDemandQueueTaskActive;
}

export const BankGuaranteeDetailsComplicatedTaskBillForm = ({
  task,
  className,
}: BankGuaranteeDetailsComplicatedTaskBillFormProps) => {
  const initialValues = useBankGuaranteeDetailsComplicatedTaskBillFormInitialValues(task.billInfo);
  const ref = useRef<HTMLFormElement | null>(null);
  const onSubmit = useBankGuaranteeDetailsComplicatedTaskBillFormOnSubmit(task.id);

  return (
    <Formik
      validationSchema={bankGuaranteeDetailsComplicatedTaskBillValidationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <DemandQueueTaskCardContainerForm ref={ref} className={className}>
        <FormScrollToFirstErrorAfterSubmit containerRef={ref} />
        <BankGuaranteeDetailsComplicatedTaskBillFormContent task={task} />
      </DemandQueueTaskCardContainerForm>
    </Formik>
  );
};
