import { useFormikContext } from "formik";
import { useCallback } from "react";
import { CreditExecutionCreationFormValues } from "../../../types/creation/CreditExecutionCreationFormValues";
import { PurchaseDetails } from "../../../../../common/purchase";

export const useCreditExecutionCreationPurchaseMutator = () => {
  const { setValues } = useFormikContext<CreditExecutionCreationFormValues>();

  return useCallback(
    (data: PurchaseDetails) => {
      const {
        fzTypeCode,
        purchaseLink,
        isClosedAuction,
        dateStart,
        dateEnd,
        beneficiaryTaxpayerNumber,
        beneficiaryName,
        sumFirst,
        subject,
      } = data;

      if (fzTypeCode && fzTypeCode === "COMMERCE") {
        return;
      }

      setValues(
        (prev) => ({
          ...prev,
          purchaseLink: purchaseLink ?? prev.purchaseLink,
          federalLaw: fzTypeCode ?? prev.federalLaw,
          isClosedAuction: isClosedAuction === true,
          dateStart: dateStart ? new Date(dateStart) : prev.dateStart,
          dateEnd: dateEnd ? new Date(dateEnd) : prev.dateEnd,
          beneficiaryTaxpayerNumber: beneficiaryTaxpayerNumber ?? prev.taxpayerNumber,
          beneficiaryName: beneficiaryName ?? prev.beneficiaryName,
          creditSum: sumFirst ?? prev.creditSum,
          contractSubject: subject ?? prev.contractSubject,
        }),
        true
      );
    },
    [setValues]
  );
};
