import * as React from "react";
import { TransitionGroup } from "react-transition-group";
import { getEmptyArray } from "@sideg/helpers";
import { BaseCssProps } from "../../../ui-kit/types";
import { Box } from "../../../ui-kit/containers/box";
import { isLoading, LoadingStatusEnum } from "../../../types/state";
import { DocumentUploadCommonBankDocumentCardSkeleton } from "../../../../document/upload";
import { ProductDetailsContentLayoutContainer } from "../layout/ProductDetailsContentLayoutContainer";
import { DocumentTypeId } from "../../../types/documents";
import { SimpleOpacityTransition } from "../../../ui-kit/animations/transitions";
import { useIsLoadingWithoutEntities } from "../../../hooks";

const Container = Box.withComponent(ProductDetailsContentLayoutContainer);

const SKELETON_ITEMS = getEmptyArray(5);

export interface ProductDetailsDocumentsProps extends BaseCssProps {
  ids: DocumentTypeId[];
  status: LoadingStatusEnum;
  children: (item: DocumentTypeId) => React.ReactNode;
}

export const ProductDetailsDocumentsWrapper = ({ status, ids, children, className }: ProductDetailsDocumentsProps) => {
  const isLoadingWithoutEntities = useIsLoadingWithoutEntities(status, ids);

  return (
    (isLoading(status) || (ids && ids.length > 0)) && (
      <Container py="xxl" className={className} display="flex" flexDirection="column" gap="xxl">
        {isLoadingWithoutEntities &&
          SKELETON_ITEMS.map((x) => (
            <DocumentUploadCommonBankDocumentCardSkeleton isWithBadge banksCount={5} key={x} />
          ))}
        <TransitionGroup component={null}>
          {ids.map((x) => (
            <SimpleOpacityTransition timeout={250} key={x}>
              {children(x)}
            </SimpleOpacityTransition>
          ))}
        </TransitionGroup>
      </Container>
    )
  );
};
