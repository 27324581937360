import { FieldHelperProps, useField } from "formik";
import { useMemo } from "react";
import { StringifyBoolean, TypedFieldProps } from "../../types/TypedFieldProps";
import { useGetFieldError } from "../useGetFieldError";

export const useTypedField = <TValue, TFieldName extends string = string>(
  name: TFieldName
): [TypedFieldProps<TFieldName, TValue>, FieldHelperProps<TValue>] => {
  const getFieldError = useGetFieldError();
  const [{ value, onBlur, onChange }, { touched, error, initialValue }, helpers] = useField<TValue>(name);

  return useMemo(
    () => [
      {
        value: (typeof value === "boolean" ? value.toString() : value) as StringifyBoolean<TValue>,
        checked: typeof value === "boolean" ? value === true : undefined,
        name,
        onBlur,
        onChange,
        error: getFieldError(name, touched, error),
        initialValue: initialValue as StringifyBoolean<TValue>,
      },
      helpers,
    ],
    [error, name, getFieldError, helpers, initialValue, onBlur, onChange, touched, value]
  );
};
