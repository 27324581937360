import { useState } from "react";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { LegalServicesProductId } from "../../../../common/types";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import * as S from "./LegalServicesCreationFromBrief.styled";
import { useAppSelector } from "../../../../../common/store";
import { selectLegalServicesCreationById } from "../../../store/legalServicesCreation.selectors";
import { Typography } from "../../../../../common/ui-kit/typography";
import { ButtonLink } from "../../../../../common/ui-kit/buttons/ButtonLink";

export interface LegalServicesCreationFromBriefProps extends BaseCssProps {
  productId: LegalServicesProductId;
}

export const LegalServicesCreationFromBrief = ({ productId, className }: LegalServicesCreationFromBriefProps) => {
  const product = useAppSelector((state) => selectLegalServicesCreationById(state, productId))!;

  const isDesktop = useThemeIsDesktop();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleButton = () => setIsCollapsed((prev) => !prev);

  return (
    <S.Container className={className}>
      <Typography fontSize={1.5} lineHeight="1.4" fontWeight={700}>
        {product.productName}
      </Typography>
      {(isDesktop || !isCollapsed) && (
        <>
          {product.description && (
            <Typography withLineBreaks lineHeight="defaultLineHeight" fontSize="fz3" fontWeight={500}>
              {product.description}
            </Typography>
          )}
          <Typography fontSize="fz3" lineHeight="defaultLineHeight" fontWeight={700}>
            Стоимость услуги&nbsp;—&nbsp;{product.productSum}
          </Typography>
          {product.sumConditions && (
            <Typography withLineBreaks lineHeight="defaultLineHeight" fontSize="fz3" fontWeight={500}>
              {product.sumConditions}
            </Typography>
          )}
        </>
      )}
      {!isDesktop && (
        <ButtonLink type="button" onClick={toggleButton}>
          {isCollapsed ? "Описание услуги" : "Свернуть"}
        </ButtonLink>
      )}
    </S.Container>
  );
};
