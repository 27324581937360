import { useEffect, useMemo, useState } from "react";
import { useMultipleSelection, UseMultipleSelectionReturnValue, useSelect, UseSelectReturnValue } from "downshift";
import { usePopup, UsePopupReturnType } from "../../../pop-up";
import { withSelectCircularNavigation } from "../utils/withSelectCircularNavigation";

export interface UseMultiselectPopupProps<TItem> {
  getItems: () => TItem[];
  onSelectedChanged: (items: TItem[]) => void | Promise<void>;
  selectedItems: TItem[] | undefined;
  popupOffset?: [number, number];
}

export interface UseMultiselectPopupInterface {
  <TItem>(props: UseMultiselectPopupProps<TItem>): Omit<UseSelectReturnValue<TItem>, "getDropdownProps"> &
    Pick<UseMultipleSelectionReturnValue<TItem>, "getDropdownProps"> &
    Pick<UsePopupReturnType, "popupProps"> & {
      popupReferenceElementProps: UsePopupReturnType["referenceElementProps"];
    };
}

export const useMultiselectPopup: UseMultiselectPopupInterface = <TItem>({
  getItems,
  onSelectedChanged,
  selectedItems: selectedItemsCollection,
  popupOffset,
}: UseMultiselectPopupProps<TItem>) => {
  const [defaultHighlightedIndex, setDefaultHighlightedIndex] = useState(0);

  const { getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } = useMultipleSelection({
    selectedItems: selectedItemsCollection ?? [],
    onSelectedItemsChange: (x) => x.selectedItems && onSelectedChanged(x.selectedItems),
  });

  const { isOpen, highlightedIndex, setHighlightedIndex, getMenuProps, ...restValues } = useSelect(
    withSelectCircularNavigation({
      defaultHighlightedIndex,
      selectedItem: null,
      items: getItems(),
      circularNavigation: true,
      stateReducer: (_state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
          case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
          case useSelect.stateChangeTypes.ItemClick:
            return {
              ...changes,
              highlightedIndex: changes.highlightedIndex,
              isOpen: true,
            };
          default:
            return changes;
        }
      },
      onStateChange: ({ type, selectedItem }) => {
        switch (type) {
          case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
          case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
          case useSelect.stateChangeTypes.ItemClick:
            if (selectedItem) {
              if (selectedItems.includes(selectedItem)) {
                removeSelectedItem(selectedItem);
              } else {
                addSelectedItem(selectedItem);
              }
            }
            break;
          default:
            break;
        }
      },
    }),
  );

  useEffect(() => {
    setDefaultHighlightedIndex(highlightedIndex);
  }, [highlightedIndex]);

  const { referenceElementProps: popupReferenceElementProps, popupProps } = usePopup({
    position: "bottom-start",
    offset: popupOffset ?? [0, 2],
  });

  return {
    isOpen,
    highlightedIndex,
    setHighlightedIndex,
    ...restValues,
    getDropdownProps,
    getMenuProps,
    popupReferenceElementProps,
    popupProps,
  };
};

// https://github.com/downshift-js/downshift/issues/1540
export const useMultiselectPopupMemo = <TItem>(options: UseMultiselectPopupProps<TItem>) => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getDropdownProps,
    popupReferenceElementProps,
    popupProps,
    ...rest
  } = useMultiselectPopup(options);

  const referenceProps = useMemo(
    () => getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen, ref: popupReferenceElementProps.ref })),
    [getDropdownProps, getToggleButtonProps, isOpen, popupReferenceElementProps.ref],
  );

  const floatingProps = useMemo(
    () => getMenuProps({ ref: popupProps.ref, style: popupProps.style }),
    [getMenuProps, popupProps.ref, popupProps.style],
  );

  return { ...rest, isOpen, popupReferenceElementProps, referenceProps, floatingProps };
};
