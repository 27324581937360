import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { CREDIT_EXECUTION_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const creditExecutionDetailsUrls = {
  creditExecution: makeAppUrl(
    joinUrlParts(CREDIT_EXECUTION_ROOT_URL, ":creditExecutionId"),
    "/credit/:creditExecutionId",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCe),
  ),
  demandsList: makeAppUrl(
    joinUrlParts(CREDIT_EXECUTION_ROOT_URL, ":id", "demand"),
    "/credit/:id/demand",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCe),
  ),
  demand: makeAppUrl(
    joinUrlParts(CREDIT_EXECUTION_ROOT_URL, ":creditExecutionId", "demand", ":demandId"),
    "/credit/:creditExecutionId/demand/:demandId",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCe),
  ),
  documents: makeAppUrl(
    joinUrlParts(CREDIT_EXECUTION_ROOT_URL, ":creditExecutionId", "documents"),
    "/credit/:creditExecutionId/documents",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCe),
  ),
  details: makeAppUrl(
    joinUrlParts(CREDIT_EXECUTION_ROOT_URL, ":creditExecutionId", "details"),
    "/credit/:creditExecutionId/details",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCe),
  ),
};
