import { useEffect, useState } from "react";
import { CreditExecutionConditionsTableBody } from "./CreditExecutionConditionsTableBody";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectCreditExecutionFilterConditions } from "../store/creditExecutionConditions.selectors";
import { getCreditExecutionConditions } from "../store/creditExecutionConditions.thunks";
import { isFailed, isLoading, isSucceeded } from "../../../common/types/state";
import { conditionsTitles } from "../utils/conditionsTitles";
import Loader from "../../../../components/common/Loader";
import InfoBanner, { InfoBannerStyle } from "../../../../components/common/info-banner/InfoBanner";
import { ConditionsAppResponsiveLayout, ConditionsTable } from "../../../common/ui/layout/conditions";
import { FilteringBaseOrderType } from "../../../common/filters";

export const CreditExecutionConditionsTable = () => {
  const dispatch = useAppDispatch();
  const [orderType, setOrderType] = useState<FilteringBaseOrderType>("asc");

  const { conditions, status } = useAppSelector((state) => selectCreditExecutionFilterConditions(state, orderType));

  useEffect(() => {
    dispatch(getCreditExecutionConditions());
  }, [dispatch]);

  return (
    <ConditionsAppResponsiveLayout>
      {isLoading(status) && <Loader />}
      {isFailed(status) && <InfoBanner text="Загрузить условия банков не удалось" style={InfoBannerStyle.Error} />}
      {isSucceeded(status) &&
        conditions &&
        (conditions.length ? (
          <ConditionsTable
            order={orderType}
            titles={conditionsTitles}
            sortHandler={() => setOrderType(orderType === "asc" ? "desc" : "asc")}
          >
            <CreditExecutionConditionsTableBody conditions={conditions} />
          </ConditionsTable>
        ) : (
          <InfoBanner text="Условия банков не найдены" style={InfoBannerStyle.Default} />
        ))}
    </ConditionsAppResponsiveLayout>
  );
};
