import { currencyHelper, dateTimeHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { CreditWorkingAssetsBase } from "../../../common/types";
import {
  ProductCreationBriefItem,
  ProductCreationBriefItemOrSkeleton,
  ProductCreationBriefSeparator,
} from "../../../../common/product-creation";

export interface CreditWorkingAssetsCreationBriefProps extends BaseCssProps {
  isLoading: boolean;
  data: CreditWorkingAssetsBase | undefined;
}

export const CreditWorkingAssetsCreationBrief = ({
  isLoading,
  data,
  className,
}: CreditWorkingAssetsCreationBriefProps) => {
  return (
    <StackPanel direction="column" gap="md" as="dl" className={className}>
      <ProductCreationBriefItemOrSkeleton isLoading={isLoading} title="Клиент" description={data?.client.name} />
      <ProductCreationBriefItemOrSkeleton isLoading={isLoading} title="ИНН" description={data?.client.taxpayerNumber} />
      <ProductCreationBriefSeparator />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isLoading}
        title="Сумма кредита"
        description={currencyHelper.getCurrencyStringNullable(data?.creditSum, undefined)}
      />
      {data?.confirmedCreditSum !== undefined && (
        <ProductCreationBriefItem
          title="Одобрено"
          description={currencyHelper.getCurrencyString(data.confirmedCreditSum)}
        />
      )}
      {data?.approvedCreditSum !== undefined && (
        <ProductCreationBriefItem
          title="Выдано"
          description={currencyHelper.getCurrencyString(data.approvedCreditSum)}
        />
      )}
      <ProductCreationBriefSeparator />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isLoading}
        title="Даты кредита"
        description={
          !data ? undefined : (
            <span>
              c {dateTimeHelper.format(new Date(data.dateStart), "dateOnly")}&nbsp;по&nbsp;
              {dateTimeHelper.format(new Date(data.dateEnd), "dateOnly")}
            </span>
          )
        }
      />
    </StackPanel>
  );
};
