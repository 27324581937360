import { dateTimeHelper, currencyHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import {
  ProductDetailsInformationListBlock,
  ProductDetailsInformationListItem,
} from "../../../../common/product-details";
import { CreditExecutionDetailsBriefAndFounders } from "../../types/CreditExecutionDetailsBriefAndFounders";

export interface CreditExecutionDetailsAuctionBlockProps extends BaseCssProps {
  briefAndFounders: CreditExecutionDetailsBriefAndFounders;
}

export const CreditExecutionDetailsAuctionBlock = ({
  className,
  briefAndFounders,
}: CreditExecutionDetailsAuctionBlockProps) => {
  return (
    <ProductDetailsInformationListBlock title="Аукцион" className={className}>
      <ProductDetailsInformationListItem
        title="Аукцион"
        value={briefAndFounders.isClosedAuction ? "Закрытый" : "Открытый"}
      />
      <ProductDetailsInformationListItem
        title="Срок"
        value={`${dateTimeHelper.format(new Date(briefAndFounders.dateStart), "dateOnly")} – ${dateTimeHelper.format(
          new Date(briefAndFounders.dateEnd),
          "dateOnly",
        )}`}
      />
      {briefAndFounders.contractSum && (
        <ProductDetailsInformationListItem
          title="Сумма контракта"
          value={currencyHelper.getCurrencyString(briefAndFounders.contractSum)}
        />
      )}
    </ProductDetailsInformationListBlock>
  );
};
