import { useMemo } from "react";

export const useCreationScoringSelectAllBanksState = (
  selectedBankIds: string[],
  allSuccessBankIds: string[],
  selectAllBanks: (bankIds: string[]) => void,
) => {
  return useMemo(() => {
    const isAllBanksSelected = allSuccessBankIds.length === selectedBankIds.length;

    return {
      isAllBanksSelected,
      toggleAllBanks: () => selectAllBanks(isAllBanksSelected ? [] : allSuccessBankIds),
    };
  }, [allSuccessBankIds, selectAllBanks, selectedBankIds.length]);
};
