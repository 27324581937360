// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelect, UseSelectProps, UseSelectReturnValue } from "downshift";
import { useMemo } from "react";
import { usePopup, UsePopupArgs, UsePopupReturnType } from "@sideg/ui-kit/atoms/pop-up";
import { withSelectCircularNavigation } from "@sideg/ui-kit/atoms/form-controls/select/utils/withSelectCircularNavigation";

export type UseSelectPopupArgs<TItem> = UseSelectProps<TItem> & UsePopupArgs & { circularNavigation?: boolean };
export type UseSelectPopupReturnType<TItem> = UseSelectReturnValue<TItem> & UsePopupReturnType<HTMLElement>;

interface UseSelectPopupInterface {
  <TItem>(args: UseSelectPopupArgs<TItem>): UseSelectPopupReturnType<TItem>;
}

export const useSelectPopup: UseSelectPopupInterface = <TItem>({
  position,
  offset,
  ...useSelectArgs
}: UseSelectPopupArgs<TItem>) => {
  const { isOpen, ...useSelectReturn } = useSelect(withSelectCircularNavigation(useSelectArgs));
  const { ...usePopupReturn } = usePopup({ position, offset });

  return { isOpen, ...useSelectReturn, ...usePopupReturn };
};

// https://github.com/downshift-js/downshift/issues/1540
export const useSelectPopupMemo = <TItem>(options: UseSelectPopupArgs<TItem>) => {
  const { isOpen, getToggleButtonProps, getMenuProps, referenceElementProps, popupProps, ...rest } =
    useSelectPopup(options);

  const referenceProps = useMemo(
    () => getToggleButtonProps({ ref: referenceElementProps.ref }),
    [getToggleButtonProps, referenceElementProps.ref],
  );

  const floatingProps = useMemo(
    // supressRefError нужен для того, чтобы не было ошибки из-за floating-ui Portal
    () => getMenuProps({ ref: popupProps.ref, style: popupProps.style }, { suppressRefError: true }),
    [getMenuProps, popupProps.ref, popupProps.style],
  );

  return { isOpen, ...rest, referenceElementProps, referenceProps, floatingProps };
};
