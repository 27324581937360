import { ComponentProps, ComponentType, createElement, useMemo } from "react";
import { OmittedButtonProps } from "../types/ButtonProps";
import { ButtonContained } from "../components/contained/ButtonContained";
import { ButtonOutlined } from "../components/outline/ButtonOutlined";
import { CommonButtonChildrenWithIcons } from "../components/common/CommonButtonChildrenWithIcons";

export const withButtonStyles = <TElement extends ComponentType<ComponentProps<TElement>>>(element: TElement) => {
  // eslint-disable-next-line react/function-component-definition
  return ({
    startIcon,
    endIcon,
    children,
    variant = "contained",
    size = "default",
    color = "primary",
    ...props
  }: OmittedButtonProps & ComponentProps<TElement>): JSX.Element => {
    const component = useMemo(() => {
      const button = variant === "contained" ? ButtonContained : ButtonOutlined;

      return button.withComponent(element);
    }, [variant]);

    return createElement(
      component,
      { ...props, size, color } as ComponentProps<typeof component>,
      createElement(CommonButtonChildrenWithIcons, { startIcon, endIcon }, children),
    );
  };
};
