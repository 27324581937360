import { CheckIcon } from "../../../../ui-kit/icons";
import { ScoringToggleButton } from "./ScoringToggleButton";
import { BaseCssProps } from "../../../../ui-kit/types";

export interface ScoringToggleAllBanksButtonProps extends BaseCssProps {
  isSelected?: boolean;
  onClick: () => void | Promise<void>;
  isDisabled?: boolean;
}

export const ScoringToggleAllBanksButton = ({
  onClick,
  isSelected,
  className,
  isDisabled,
}: ScoringToggleAllBanksButtonProps) => {
  return (
    <ScoringToggleButton
      type="button"
      className={className}
      onClick={onClick}
      disabled={isDisabled}
      isSelected={isSelected}
    >
      Выбрать все банки&nbsp;{isSelected && <CheckIcon />}
    </ScoringToggleButton>
  );
};
