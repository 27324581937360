import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { createHub, hubUpdate } from "../store/hubs.slice";
import { SignalrConnection } from "../types/SignalrConnection";
import { selectHubById } from "../store/hubs.selectors";
import { HubConnectionStatus } from "../types/HubConnectionStatus";

export const useHubInitial = <THubId extends string>(connection: SignalrConnection<THubId, string, string>) => {
  const dispatch = useAppDispatch();
  const [isInitial, setIsInitial] = useState(true);

  const hub = useAppSelector((state) => selectHubById(state, connection.getId()));

  useEffect(() => {
    if (hub === undefined) {
      dispatch(createHub({ hubId: connection.getId() }));
    } else if (isInitial && hub.status === HubConnectionStatus.Disconnected) {
      dispatch(hubUpdate({ hubId: connection.getId() }));
      setIsInitial(false);
    }
  }, [connection, dispatch, hub, isInitial]);
};
