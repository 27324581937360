import { useMemo } from "react";
import * as React from "react";
import { AppLink } from "../../../common/urls/internal/components";
import { CreditWorkingAssetsId, DemandId } from "../../../common/types/demand";
import { useCreditWorkingAssetsListItemTransferAction } from "./useCreditWorkingAssetsListItemTransferAction";

export const useCreditWorkingAssetsListItemLink = (
  creditWorkingAssetsId: CreditWorkingAssetsId,
  demandId?: DemandId
): React.ComponentProps<typeof AppLink> => {
  const onClick = useCreditWorkingAssetsListItemTransferAction(creditWorkingAssetsId);

  return useMemo(
    () => ({
      to: (x) =>
        demandId === undefined ? x.creditWorkingAssets.details.product : x.creditWorkingAssets.details.demand,
      params: { creditWorkingAssetsId, demandId },
      onClick,
    }),
    [creditWorkingAssetsId, demandId, onClick]
  );
};
