import Skeleton from "react-loading-skeleton";
import { BankGuaranteeDetailsDemandReconciliationContent } from "../content/BankGuaranteeDetailsDemandReconciliationContent";
import { BankGuaranteeDetailsDemandReconciliationContentSkeleton } from "../content/BankGuaranteeDetailsDemandReconciliationContent.skeleton";

export interface BankGuaranteeDetailsDemandReconciliationProjectContentSkeletonProps {
  containerTestId?: string;
}

export const BankGuaranteeDetailsDemandReconciliationProjectContentSkeleton = ({
  containerTestId,
}: BankGuaranteeDetailsDemandReconciliationProjectContentSkeletonProps) => {
  return (
    <BankGuaranteeDetailsDemandReconciliationContentSkeleton containerTestId={containerTestId}>
      <BankGuaranteeDetailsDemandReconciliationContent.Block withLineSeparator>
        <Skeleton width={80} />
        <Skeleton width={200} height={24} />
      </BankGuaranteeDetailsDemandReconciliationContent.Block>
    </BankGuaranteeDetailsDemandReconciliationContentSkeleton>
  );
};
