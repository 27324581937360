import { BankModel } from "../../../../types/objects";
import { FilteringBaseOrderType } from "../../../../filters";

export const sortConditions = <T extends { bank: BankModel }>(conditions: T[], orderType: FilteringBaseOrderType) => {
  return [...conditions].sort((a, b) => {
    const [resultA, resultB] = orderType === "asc" ? [a, b] : [b, a];

    return resultA.bank.shortName.localeCompare(resultB.bank.shortName);
  });
};
