import { Form, Formik } from "formik";
import { useRef } from "react";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { BankGuaranteeDetailsDemandQueueTaskActive } from "../../../../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskActive";
import { FormScrollToFirstErrorAfterSubmit } from "../../../../../../../common/form";
import { BankGuaranteeDetailComplicatedTaskProjectActiveFormContent } from "./BankGuaranteeDetailComplicatedTaskProjectActiveFormContent";
import { DemandQueueTaskCardContainer } from "../../../../../../../demand/queue-tasks/components/card/DemandQueueTaskCardContainer";
import { useBankGuaranteeDetailsComplicatedTaskProjectForm } from "../../../hooks/useBankGuaranteeDetailsComplicatedTaskProjectForm";
import { useBankGuaranteeDetailsComplicatedTaskProjectFormOnSubmit } from "../../../hooks/useBankGuaranteeDetailsComplicatedTaskProjectFormOnSubmit";

const DemandQueueTaskCardContainerForm = DemandQueueTaskCardContainer.withComponent(Form);

export interface BankGuaranteeDetailComplicatedTaskProjectActiveFormProps extends BaseCssProps {
  task: BankGuaranteeDetailsDemandQueueTaskActive;
}

export const BankGuaranteeDetailComplicatedTaskProjectActiveForm = ({
  task,
  className,
}: BankGuaranteeDetailComplicatedTaskProjectActiveFormProps) => {
  const { initialValues, validationSchema } = useBankGuaranteeDetailsComplicatedTaskProjectForm(task.projectInfo);
  const onSubmit = useBankGuaranteeDetailsComplicatedTaskProjectFormOnSubmit(task.id, validationSchema, initialValues);
  const ref = useRef<HTMLFormElement | null>(null);

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      <DemandQueueTaskCardContainerForm ref={ref} className={className}>
        <FormScrollToFirstErrorAfterSubmit containerRef={ref} />
        <BankGuaranteeDetailComplicatedTaskProjectActiveFormContent task={task} />
      </DemandQueueTaskCardContainerForm>
    </Formik>
  );
};
