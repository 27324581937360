/**
 * Возвращает цену со знаком рубля (134815.91 -> 134 815,91 ₽)
 * или замещающую строку, в случае, если сумма не указана
 * @param value Цена/сумма
 * @param placeholder Строка, замещающая
 * @example 134 815,91 ₽
 */
const getCurrencyStringNullable = <TPlaceholder = string | undefined>(
  value: number | undefined | null,
  placeholder: TPlaceholder,
): string | TPlaceholder => {
  return (
    value?.toLocaleString("ru-RU", {
      style: "currency",
      currency: "RUB",
    }) ?? placeholder
  );
};

/**
 * Возвращает цену со знаком рубля 134815.91 -> 134 815,91 ₽
 * @param value Цена/сумма
 * @example 134 815,91 ₽
 */
const getCurrencyString = (value: number): string => getCurrencyStringNullable(value, "");

/**
 * Возвращает цену без знака рубля 134815.91 -> 134 815,91
 * @param value Цена/сумма
 * @param stripFractionDigitsIfInteger обрезать знаки после запятой, если число целочисленное
 */
const getCurrencyStringWithoutRubleSign = (value: number, stripFractionDigitsIfInteger = false): string => {
  if (stripFractionDigitsIfInteger && Number.isInteger(value)) {
    return value.toLocaleString("ru-RU", { style: "decimal", maximumFractionDigits: 0 });
  }

  return value.toLocaleString("ru-RU", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const currencyHelper = {
  getCurrencyStringNullable,
  getCurrencyString,
  getCurrencyStringWithoutRubleSign,
};
