import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreditExecutionListItemModificationState } from "./creditExecutionListItemModification.types";
import { combineSliceNames } from "../../../../common/store/utils";
import { CreditExecutionId, DemandId } from "../../../../common/types/demand";
import { CREDIT_EXECUTION_LIST_SLICE_NAME } from "../creditExecutionList.thunks";

const initialState: CreditExecutionListItemModificationState = {};

const SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_LIST_SLICE_NAME, "item-modification");

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    onProductReject: (state, action: PayloadAction<{ creditExecutionId: CreditExecutionId }>) => {
      return { type: "remove-product", creditExecutionId: action.payload.creditExecutionId };
    },
    onDemandReject: (
      state,
      {
        payload: { creditExecutionId, demandId },
      }: PayloadAction<{ creditExecutionId: CreditExecutionId; demandId: DemandId }>
    ) => {
      return { type: "remove-demand", creditExecutionId, demandId };
    },
    cancelModification: () => initialState,
  },
});

export const creditExecutionListItemModificationReducer = slice.reducer;

export const {
  onProductReject: onProductRejectCreditExecutionListItemModification,
  onDemandReject: onDemandRejectCreditExecutionListItemModification,
  cancelModification: cancelModificationCreditExecutionListModification,
} = slice.actions;
