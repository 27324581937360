import { FACTORING_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeAppUrl } from "../../../common/urls/internal/utils";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const factoringCreationUrls = {
  create: makeAppUrl(
    FACTORING_ROOT_URL,
    FACTORING_ROOT_URL,
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductFactoring),
  ),
};
