import { SETTLEMENT_AND_CASH_ROOT_URL } from "../../common/urls";
import { makeAppUrl } from "../../../common/urls/internal/utils";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const settlementAndCashCreationUrls = {
  create: makeAppUrl(
    SETTLEMENT_AND_CASH_ROOT_URL,
    SETTLEMENT_AND_CASH_ROOT_URL,
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductSc),
  ),
};
