import * as React from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Box } from "../../../../common/ui-kit/containers/box";
import { CreationBlockCard } from "../../../../common/ui-kit/cards/creation-block-card";
import { DocumentUploadCommonBankDocumentCardSeparator } from "./card/DocumentUploadCommonBankDocumentCardSeparator";

export interface DocumentUploadCommonBankDocumentCardContainerProps extends BaseCssProps {
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

export const DocumentUploadCommonBankDocumentContainer = ({
  children,
  footer,
  className,
}: DocumentUploadCommonBankDocumentCardContainerProps) => {
  return (
    <Box as={CreationBlockCard} display="flex" flexDirection="column" gap="md" padding="lg" className={className}>
      {children}
      <DocumentUploadCommonBankDocumentCardSeparator />
      {footer}
    </Box>
  );
};
