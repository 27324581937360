import { useEffect, useState } from "react";
import * as React from "react";
import Select, { ISelectValue } from "./Select";
import IValue from "../IValue";

export interface ISelectRequiredProps {
  values: Array<ISelectValue>;
  value?: string;
  placeholder: string;
  viewError: boolean;
  name: string;
  onChange: (value: IValue) => void;
}

const SelectRequired: React.FC<ISelectRequiredProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [internalValue, setInternalValue] = useState<IValue>();

  useEffect(() => {
    validateValue(props.value ?? "default");
  }, [props.value]);

  const handleChange = (value: string) => {
    validateValue(value);
  };

  const validateValue = (value?: string) => {
    const result = validate(value);
    setInternalValue(result);
    props.onChange(result);
  };

  const validate = (value?: string): IValue => {
    let result;

    if (value === undefined || value === "default") {
      result = {
        value: value,
        isValid: false,
        errorMessage: "Выберите значение",
      };
    } else {
      result = {
        value: value,
        isValid: true,
        errorMessage: undefined,
      };
    }

    setErrorMessage(result.errorMessage);

    return result;
  };

  return (
    <Select
      values={props.values}
      defaultValue={{ key: "default", value: props.placeholder }}
      value={props.value ?? "default"}
      name={props.name}
      errorMessage={props.viewError ? errorMessage : undefined}
      onChange={handleChange}
    />
  );
};

export default SelectRequired;
