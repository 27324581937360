import Skeleton from "react-loading-skeleton";
import { currencyHelper, dateTimeHelper, isValidUrl } from "@sideg/helpers";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Typography } from "../../../../common/ui-kit/typography";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { CreditExecutionCommonDetailsContainer } from "../../../common/components";
import { isLoading } from "../../../../common/types/state";
import { DotSeparatedRowContainer } from "../../../../common/ui-kit/containers/dot-separated-row";
import { useAppSelector } from "../../../../common/store";
import { selectCreditExecutionDetailsProductDetails } from "../../store/product-details/creditExecutionDetailsProductDetails.selectors";
import { CreditExecutionDetailsNavigationCardDetails } from "./navigation-card/CreditExecutionDetailsNavigationCardDetails";
import { getFederalLawTypeTitle } from "../../../../common/types/demand";
import { Link } from "../../../../common/ui-kit/links";

export type CreditExecutionDetailsAsideProductDetailsProps = BaseCssProps;

export const CreditExecutionDetailsAsideProductDetails = ({
  className,
}: CreditExecutionDetailsAsideProductDetailsProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();
  const { data, status } = useAppSelector(selectCreditExecutionDetailsProductDetails);

  return (
    <StackPanel direction="column" gap="sm" className={className}>
      <Typography
        lineHeight="1.4"
        fontWeight={700}
        fontSize={{ xs: 1.375, [desktopBreakpoint]: 1.5 }}
        fontColor="typography.primary"
      >
        Кредит на исполнение контракта
      </Typography>
      <DotSeparatedRowContainer dotColor="typography.primary">
        <Typography fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.primary">
          {data ? data.client?.name : <Skeleton width={100} inline />}
        </Typography>
        <Typography
          fontWeight={500}
          fontSize="fz2"
          lineHeight="defaultLineHeight"
          fontColor="typography.primaryCaption"
        >
          {data ? `ИНН ${data.client?.taxpayerNumber}` : <Skeleton width={100} inline />}
        </Typography>
      </DotSeparatedRowContainer>

      <CreditExecutionCommonDetailsContainer
        className={className}
        termFontColor="dark"
        federalLaw={data?.federalLaw ? getFederalLawTypeTitle(data.federalLaw.code) : <Skeleton width={110} />}
        purchaseTerms={
          isLoading(status) && data === undefined ? (
            <Skeleton width={190} />
          ) : (
            <DotSeparatedRowContainer dotColor="typography.primary">
              <div />
              <Typography fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.primary">
                {isValidUrl(data?.purchaseLink) ? (
                  <Link href={data?.purchaseLink} target="_blank" rel="noreferrer noopener">
                    {data?.purchaseNumber ? `№ ${data.purchaseNumber}` : "Ссылка на закупку"}
                  </Link>
                ) : (
                  `${data?.purchaseNumber ? `№ ${data.purchaseNumber}` : ""}`
                )}
              </Typography>
            </DotSeparatedRowContainer>
          )
        }
        briefButton={<CreditExecutionDetailsNavigationCardDetails />}
        requested={
          isLoading(status) && data === undefined ? (
            <Skeleton width={150} />
          ) : (
            currencyHelper.getCurrencyStringNullable(data?.contractSum, undefined)
          )
        }
        confirmed={
          isLoading(status) && data === undefined ? (
            <Skeleton width={130} />
          ) : (
            currencyHelper.getCurrencyStringNullable(data?.confirmedCreditSum, undefined)
          )
        }
        period={
          (isLoading(status) && data === undefined) || data === undefined ? (
            <Skeleton width={200} />
          ) : (
            `${dateTimeHelper.format(new Date(data.dateStart), "dayShortMonthYear")} – ${dateTimeHelper.format(
              new Date(data.dateEnd),
              "dayShortMonthYear",
            )}`
          )
        }
      />
    </StackPanel>
  );
};
