import Skeleton from "react-loading-skeleton";
import { BankGuaranteeRewardsItemCard } from "./card/BankGuaranteeRewardsItemCard";
import { BankGuaranteeRewardsItemSkeleton } from "./BankGuaranteeRewardsItem.skeleton";

export const BankGuaranteeRewardsSkeleton = () => {
  return (
    <BankGuaranteeRewardsItemCard bankName={<Skeleton width={150} />}>
      <BankGuaranteeRewardsItemSkeleton />
      <BankGuaranteeRewardsItemSkeleton />
      <BankGuaranteeRewardsItemSkeleton />
      <BankGuaranteeRewardsItemSkeleton />
    </BankGuaranteeRewardsItemCard>
  );
};
