import { useResponsiveRange } from "@sideg/ui-kit";
import { CheckIcon } from "../../../../ui-kit/icons";
import { BaseCssProps } from "../../../../ui-kit/types";
import { ScoringToggleButton } from "./ScoringToggleButton";

export interface ScoringToggleBankButtonProps extends BaseCssProps {
  isSelected?: boolean;
  onClick: () => void | Promise<void>;
  isDisabled?: boolean;
}

export const ScoringToggleBankButton = ({
  isSelected,
  onClick,
  className,
  isDisabled,
}: ScoringToggleBankButtonProps) => {
  const { higherThan } = useResponsiveRange();

  return (
    <ScoringToggleButton
      type="button"
      className={className}
      isSelected={isSelected}
      onClick={onClick}
      disabled={isDisabled}
    >
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      {isSelected ? <CheckIcon /> : <>{higherThan("md", true) ? "Выбрать банк" : "Выбрать"}</>}
    </ScoringToggleButton>
  );
};
