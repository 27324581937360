import { currencyHelper, dateTimeHelper, isValidUrl } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeId, getFederalLawTypeTitle } from "../../../../../../common/types/demand";
import { NavigationState } from "../../../../../../common/navigation/navigation-state";
import { useAppSelector } from "../../../../../../common/store";
import {
  selectBankGuaranteeListItemsClientById,
  selectBankGuaranteeListItemsGuaranteeById,
} from "../../../../store/items/bankGuaranteeListItems.selectors";
import { Link } from "../../../../../../common/ui-kit/links";
import { StackPanel } from "../../../../../../common/ui-kit/containers/stack-panel";
import { ExternalLinkIcon, Icon } from "../../../../../../common/ui-kit/icons";
import { BankGuaranteeListItemDesktopDemands } from "./demand-list/BankGuaranteeListItemDesktopDemands";
import { BankGuaranteeListItemDesktopCard } from "./card/BankGuaranteeListItemDesktopCard";
import { BankGuaranteeListItemDesktopIssue } from "./issue/BankGuaranteeListItemDesktopIssue";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { BankGuaranteeListItemDesktopBrief } from "./brief/BankGuaranteeListItemDesktopBrief";
import { ProductListBriefButton } from "../../../../../../common/ui/product/list/card";
import { ProductListItemDetailsItemContainer } from "../../../../../../common/ui/product/list/container";

const BoxLink = Box.withComponent(Link);

export interface BankGuaranteeListItemDesktopProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  from?: NavigationState;
}

export const BankGuaranteeListItemDesktop = ({ guaranteeId, from }: BankGuaranteeListItemDesktopProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const guarantee = useAppSelector((state) => selectBankGuaranteeListItemsGuaranteeById(state, guaranteeId))!;
  const client = useAppSelector((state) => selectBankGuaranteeListItemsClientById(state, guarantee.clientId));

  return (
    <BankGuaranteeListItemDesktopCard
      clientName={client?.name}
      clientTaxpayerNumber={client?.taxpayerNumber}
      guaranteeNumberAndDate={`Заявка № ${guaranteeId} • ${dateTimeHelper.format(
        new Date(guarantee.dateAdded),
        "dayShortMonthYear",
      )}`}
      guaranteeType={guarantee.guaranteeType.title}
      details={
        <>
          <ProductListItemDetailsItemContainer
            term={getFederalLawTypeTitle(guarantee.federalLaw.code)}
            description={
              <StackPanel gap="xs" direction="row" alignItems="center">
                <span>{guarantee.purchaseNumber}</span>
                {isValidUrl(guarantee.purchaseLink) && (
                  <BoxLink
                    flexGrow={0}
                    flexShrink={0}
                    href={guarantee.purchaseLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Ссылка на закупку"
                  >
                    <Icon as={ExternalLinkIcon} stroke="primary" />
                  </BoxLink>
                )}
              </StackPanel>
            }
          />
          <ProductListItemDetailsItemContainer
            term="Сумма"
            description={currencyHelper.getCurrencyString(guarantee.guaranteeSum)}
          />
          <ProductListBriefButton>
            <BankGuaranteeListItemDesktopBrief guaranteeId={guaranteeId} />
          </ProductListBriefButton>
        </>
      }
    >
      {guarantee.issuedDemandId === undefined && (
        <BankGuaranteeListItemDesktopDemands guaranteeId={guaranteeId} from={from} />
      )}
      {guarantee.issuedDemandId !== undefined && (
        <BankGuaranteeListItemDesktopIssue guaranteeId={guaranteeId} demandId={guarantee.issuedDemandId} from={from} />
      )}
    </BankGuaranteeListItemDesktopCard>
  );
};
