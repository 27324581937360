import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { newsListAdapter } from "./newsList.adapter";
import { BasePaginatedState, getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { getNewsList, NEWS_LIST_SLICE_NAME } from "./newsList.thunks";

const initialState = newsListAdapter.getInitialState<BasePaginatedState>({
  status: LoadingStatusEnum.Idle,
});

const slice = createSlice({
  name: NEWS_LIST_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewsList.pending, (state, action) => {
        if (state.pagination && action.meta.arg && action.meta.arg.page <= state.pagination.pageNumber) {
          newsListAdapter.removeAll(state);
          state.pagination = undefined;
        }
      })
      .addCase(getNewsList.fulfilled, (state, action) => {
        if (action.payload.body.paginationInformation?.pageNumber === 1) {
          newsListAdapter.setAll(state, action.payload.body.data);
        } else {
          newsListAdapter.upsertMany(state, action.payload.body.data);
        }
        state.pagination = action.payload.body.paginationInformation;
      })
      .addCase(getNewsList.rejected, (state, action) => {
        state.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(getNewsList), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const newsListReducer = slice.reducer;
