import { memo } from "react";
import { useAppSelector } from "../../../../../common/store";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  selectCreditWorkingAssetsDetailsActiveDemand,
  selectCreditWorkingAssetsDetailsActiveDemandDocuments,
} from "../../../store/active-demand/creditWorkingAssetsDetailsActiveDemand.selectors";
import { useCreditWorkingAssetsDetailsDemandDocumentsFetch } from "../../../hooks/useCreditWorkingAssetsDetailsDemandDocumentsFetch";
import { ProductDetailsDemandActualDocumentsList } from "../../../../../common/product-details";

export type CreditWorkingAssetsDetailsDemandActualDocumentsListProps = BaseCssProps;

export const CreditWorkingAssetsDetailsDemandActualDocumentsList = memo(
  ({ className }: CreditWorkingAssetsDetailsDemandActualDocumentsListProps) => {
    const {
      documents: { status },
    } = useAppSelector(selectCreditWorkingAssetsDetailsActiveDemand);
    const documents = useAppSelector(selectCreditWorkingAssetsDetailsActiveDemandDocuments);

    useCreditWorkingAssetsDetailsDemandDocumentsFetch();

    return (
      <ProductDetailsDemandActualDocumentsList loadingStatus={status} documents={documents} className={className} />
    );
  }
);
