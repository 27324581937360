import { createAction } from "@reduxjs/toolkit";
import { BANK_GUARANTEE_EXTRACT_FROM_REGISTRY_SLICE_NAME } from "./bankGuaranteeExtractFromRegistry.thunks";
import { combineSliceNames } from "../../../common/store/utils";
import { DemandId } from "../../../common/types/demand";
import { BankGuaranteeFileRegistryStatusType } from "../../common/types";

export const onBankGuaranteeExtractFromRegistryStatusChanged = createAction<{
  demandId: DemandId;
  newStatus: BankGuaranteeFileRegistryStatusType;
}>(
  combineSliceNames(BANK_GUARANTEE_EXTRACT_FROM_REGISTRY_SLICE_NAME, "onBankGuaranteeExtractFromRegistryStatusChanged")
);
