/*
 * Этот файл сгенерирован автоматически. Пожалуйста, не изменяйте его.
 * Дата генерации: Thu Apr 11 2024 11:43:35 GMT+0300 (Москва, стандартное время)
 */

export default {
  controlsMobile: {
    inputs: {
      signature: {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: 0,
        lineHeightPx: 12,
      },
      medium: {
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: 0,
        lineHeightPx: 20,
      },
      small: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: 0,
        lineHeightPx: 18,
      },
      large: {
        fontWeight: 500,
        fontSize: 18,
        letterSpacing: 0,
        lineHeightPx: 22,
      },
    },
    buttons: {
      secondary: {
        fontWeight: 500,
        fontSize: 18,
        letterSpacing: 0,
        lineHeightPx: 24,
      },
      primary: {
        fontWeight: 700,
        fontSize: 18,
        letterSpacing: 0,
        lineHeightPx: 24,
      },
    },
  },
  desktop: {
    text16Medium: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0,
      lineHeightPx: 24,
    },
    text16Bold: {
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: 0,
      lineHeightPx: 24,
    },
    small14Bold: {
      fontWeight: 700,
      fontSize: 14,
      letterSpacing: 0,
      lineHeightPx: 24,
    },
    small14Medium: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0,
      lineHeightPx: 24,
    },
  },
  mobile: {
    text18Medium: {
      fontWeight: 500,
      fontSize: 18,
      letterSpacing: 0,
      lineHeightPx: 24,
    },
    secText16Medium: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0,
      lineHeightPx: 24,
    },
    text18Bold: {
      fontWeight: 700,
      fontSize: 18,
      letterSpacing: 0,
      lineHeightPx: 24,
    },
  },
  generalDesktop: {
    h2: {
      fontWeight: 700,
      fontSize: 24,
      letterSpacing: 0,
      lineHeightPx: 32,
    },
    pDefault: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0,
      lineHeightPx: 24,
    },
    additional: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0,
      lineHeightPx: 20,
    },
    h1: {
      fontWeight: 500,
      fontSize: 36,
      letterSpacing: 0,
      lineHeightPx: 48,
    },
  },
  controlsDesktop: {
    buttons: {
      default: {
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: 0,
        lineHeightPx: 24,
      },
      small: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: 0,
        lineHeightPx: 24,
      },
      large: {
        fontWeight: 700,
        fontSize: 18,
        letterSpacing: 0,
        lineHeightPx: 24,
      },
    },
    popover: {
      small: {
        fontWeight: 500,
        fontSize: 12,
        letterSpacing: 0,
        lineHeightPx: 20,
      },
    },
    inputs: {
      signature: {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: 0,
        lineHeightPx: 18,
      },
      large: {
        fontWeight: 500,
        fontSize: 18,
        letterSpacing: 0,
        lineHeightPx: 26,
      },
      medium: {
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: 0,
        lineHeightPx: 24,
      },
      small: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: 0,
        lineHeightPx: 22,
      },
    },
  },
} as const;
