import { useAppMenuItems } from "../../hooks/useAppMenuItems";
import { NavigationMenuUlDesktop } from "./NavigationMenuUl.desktop";

export const NavigationMenuDesktop = () => {
  const items = useAppMenuItems();

  return (
    <nav>
      <NavigationMenuUlDesktop items={items} />
    </nav>
  );
};
