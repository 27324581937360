export interface PhoneNumber {
  formatted: string;
  raw: string;
}

const phoneNumbers: PhoneNumber[] = [
  {
    formatted: "8 (499) 490-21-19",
    raw: "+74994092119",
  },
  {
    raw: "88007072119",
    formatted: "8 (800) 707-21-19",
  },
];

export const publicUserRegistrationConfig = {
  phoneNumbers,
};
