import { useRef } from "react";
import * as React from "react";
import { Form, Formik } from "formik";
import { demandQueueTaskActiveValidationSchema } from "../../validation/demandQueueTaskActiveValidationSchema";
import { DemandQueueTaskActiveFormValues } from "../../types/active-form/DemandQueueTaskActiveFormValues";
import { sendDecisionDemandQueueTasks } from "../../store/demandQueueTasks.thunks";
import { useAppDispatch } from "../../../../common/store";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { apiMutationDefaultErrorHandler, useApiMutationAction } from "../../../../common/hooks";
import { pushErrorGlobalToast } from "../../../../global/toasts";
import { mapRequestedDocumentDemandQueueTasks } from "../../utils/mapRequestedDocumentDemandQueueTasks";
import { DemandQueueTaskActive } from "../../types/active/DemandQueueTaskActive";
import { useDemandQueueTaskActiveFormInitialValue } from "../../hooks/active/form/useDemandQueueTaskActiveFormInitialValue";
import { queueTaskClosedDemandQueueTasks } from "../../store/demandQueueTasks.actions";
import { FormScrollToFirstErrorAfterSubmit } from "../../../../common/form";

export interface DemandQueueTaskActiveFormContainerProps extends BaseCssProps {
  task: DemandQueueTaskActive;
  children?: React.ReactNode;
}

export const DemandQueueTaskActiveFormContainer = ({
  children,
  task,
  className,
}: DemandQueueTaskActiveFormContainerProps) => {
  const dispatch = useAppDispatch();
  const { mutate } = useApiMutationAction();
  const initialValues = useDemandQueueTaskActiveFormInitialValue(task);

  const ref = useRef<HTMLFormElement | null>(null);

  const onSubmit = async (values: DemandQueueTaskActiveFormValues) => {
    const castedValues = demandQueueTaskActiveValidationSchema.cast(values);
    const documents = mapRequestedDocumentDemandQueueTasks(
      castedValues.documents ? Object.values(castedValues.documents) : []
    );

    const result = await mutate(
      sendDecisionDemandQueueTasks,
      {
        queueTaskId: task.id,
        decision: castedValues.decision,
        comment: castedValues.comment,
        documents,
      },
      {
        errorHandler: async (e) => {
          const error = apiMutationDefaultErrorHandler(e) ?? "Не удалось отправить решение по задаче";
          dispatch(pushErrorGlobalToast(error));

          return error;
        },
      }
    );

    if (result) {
      dispatch(queueTaskClosedDemandQueueTasks(task.id));
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={demandQueueTaskActiveValidationSchema}>
      <Form ref={ref} className={className}>
        <FormScrollToFirstErrorAfterSubmit containerRef={ref} />
        {children}
      </Form>
    </Formik>
  );
};
