import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { FullPageContainerHeaderContainer } from "../../../../common/ui/containers/full-page";
import { FactoringCreationHeaderMobile } from "./FactoringCreationHeader.mobile";
import { FactoringCreationHeaderDesktop } from "./FactoringCreationHeader.desktop";

export type FactoringCreationHeaderProps = BaseCssProps;

export const FactoringCreationHeader = ({ className }: FactoringCreationHeaderProps) => {
  return (
    <FullPageContainerHeaderContainer className={className}>
      <ThemeMobileDesktopView mobile={<FactoringCreationHeaderMobile />} desktop={<FactoringCreationHeaderDesktop />} />
    </FullPageContainerHeaderContainer>
  );
};
