import { ReactNode } from "react";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { Typography } from "../../../../../../common/ui-kit/typography";

const BoxTypography = Box.withComponent(Typography);

export interface BankGuaranteeDetailsDemandReconciliationBillDataItemProps {
  term: ReactNode;
  description: ReactNode;
}

export const BankGuaranteeDetailsDemandReconciliationBillDataItem = ({
  term,
  description,
}: BankGuaranteeDetailsDemandReconciliationBillDataItemProps) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
      <BoxTypography flexBasis="160px" fontColor="dark" fontWeight={500} fontSize="fz2" lineHeight="1.4">
        {term}
      </BoxTypography>
      <Typography fontColor="typography.primary" fontWeight={500} fontSize="fz2" lineHeight="1.4">
        {description}
      </Typography>
    </Box>
  );
};
