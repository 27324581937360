import { PublicUserResetPasswordConditional } from "../components/conditional/PublicUserResetPasswordConditional";
import { PublicUserResetPasswordInitial } from "../components/initial/PublicUserResetPasswordInitial";
import { PublicUserResetPasswordApprove } from "../components/approve/PublicUserResetPasswordApprove";

export const PublicUserResetPasswordForgotPasswordPage = () => {
  return (
    <PublicUserResetPasswordConditional
      initialElement={PublicUserResetPasswordInitial}
      approveElement={PublicUserResetPasswordApprove}
    />
  );
};
