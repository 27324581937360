import { dateTimeHelper } from "@sideg/helpers";

export const encodeQueryParams = (data: Record<string, unknown> = {}): string => {
  const searchParams = new URLSearchParams();
  Object.entries(data)
    .filter(([, value]) => value !== undefined && value !== "")
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => searchParams.append(key, item));

        return;
      }

      let formattedValue: string = value as string;
      if (typeof value === "number") {
        formattedValue = `${value}`;
      }

      if (value instanceof Date) {
        formattedValue = dateTimeHelper.format(value, "yearMonthDayDashed");
      }

      searchParams.append(key, formattedValue);
    });

  return searchParams.toString();
};
