import { capitalize } from "@sideg/helpers";
import { useResponsiveContext } from "@sideg/ui-kit/core/responsive/hooks/useResponsiveContext";
import { Breakpoints, breakpoints } from "@sideg/ui-kit/common/types/responsive/Breakpoints";

type Hooks = { [P in Breakpoints as `useIs${Capitalize<P>}`]: () => boolean };
export const makeSizeHooks = (): Hooks => {
  const makeHook = (size: Breakpoints) => () => {
    const context = useResponsiveContext();

    return context.actualSize === size;
  };

  return breakpoints.reduce(
    (accum, actualValue) => {
      accum[`useIs${capitalize(actualValue)}`] = makeHook(actualValue);

      return accum;
    },
    {} as Record<string, () => boolean>,
  ) as Hooks;
};
