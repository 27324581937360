import styled from "@emotion/styled";
import { BankGuaranteeDemandStageType } from "../../../../common/types";
import { Text } from "../../../../../common/ui-kit/typography";

export const TaskCounter = styled.span`
  background-color: ${({ theme }) => theme.colors.info};
  padding: 2px ${({ theme }) => theme.spaces.sm};
  border-radius: 4px;
  margin-left: ${({ theme }) => theme.spaces.xs};
  letter-spacing: 2px;
`;

export const BankName = styled.span<{ stage: BankGuaranteeDemandStageType }>`
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 1;
  font-weight: 700;
  text-decoration: ${({ stage }) => (stage === "Rejected" ? "line-through" : "unset")};
  color: ${({ theme, stage }) => {
    if (stage === "AttachingDocuments" || stage === "Rejected") {
      return theme.palette.typography.medium;
    }

    if (stage === "Confirmed") {
      return theme.palette.typography.success;
    }

    return theme.palette.typography.primary;
  }};
`;

export const StatusWithCount = styled(Text)`
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const Status = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
