import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../common/store";
import { CreditWorkingAssetsListFiltersState } from "./creditWorkingAssetsListFilters.types";
import { createFuseSearchSelector } from "../../../../common/store/utils";
import { CodeWithTitle } from "../../../../common/types/objects";
import { bankSearchTransformer } from "../../../../common/utils/bankSearchTransformer";

export const selectCreditWorkingAssetsFilters = (state: RootState): CreditWorkingAssetsListFiltersState =>
  state.domains.creditWorkingAssets.list.filters;

export const selectCreditWorkingAssetsFiltersData = (state: RootState): CreditWorkingAssetsListFiltersState["data"] =>
  selectCreditWorkingAssetsFilters(state).data;

export const selectCreditWorkingAssetsListFiltersBanks = createFuseSearchSelector<CodeWithTitle>({
  selector: (state) => selectCreditWorkingAssetsFiltersData(state)?.bankIds.codeWithTitleList ?? [],
  ignoreLessThan: 0.55,
  keys: ["title"],
  searchTransformer: bankSearchTransformer,
});

export const selectCreditWorkingAssetsListFiltersBanksCodes = createSelector(
  [(state) => state, (state, search: string) => search],
  (state, search) => {
    return selectCreditWorkingAssetsListFiltersBanks(state, search).map((x) => x.code);
  }
);
