import { TextareaAutosizeProps } from "react-textarea-autosize";
import { useElementId } from "@sideg/ui-kit/atoms/form-controls/common/hooks";
import { BaseFormElementErrorProps } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormElementError";
import { forwardRef, ReactNode } from "react";
import { BaseFormInputElement } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormInputElement";
import * as S from "@sideg/ui-kit/atoms/form-controls/textarea/components/FormTextarea.styled";
import { ControlSize } from "@sideg/ui-kit/common/types";

export interface FormTextareaProps extends BaseFormElementErrorProps, Omit<TextareaAutosizeProps, "ref"> {
  label?: string;
  afterInput?: ReactNode;
  beforeInput?: ReactNode;
  maxRows?: number;
  minRows?: number;
  status?: ReactNode;
  size?: ControlSize;
}

export const FormTextarea = forwardRef<HTMLTextAreaElement, FormTextareaProps>(
  (
    {
      label,
      status,
      disabled,
      error,
      errorDataTestId,
      minRows,
      maxRows,
      beforeInput,
      afterInput,
      id,
      name,
      size = "default",
      ...rest
    },
    ref,
  ) => {
    const elementId = useElementId("textarea", id, name);

    return (
      <BaseFormInputElement
        size={size}
        label={label}
        labelFor={elementId}
        afterInput={afterInput}
        beforeInput={beforeInput}
        error={error}
        status={status}
        errorDataTestId={errorDataTestId}
        disabled={disabled}
      >
        <S.Textarea
          ref={ref}
          minRows={minRows}
          maxRows={maxRows}
          disabled={disabled}
          name={name}
          id={elementId}
          size={size}
          {...rest}
        />
      </BaseFormInputElement>
    );
  },
);
