import * as React from "react";
import { useFormScrollToFirstErrorAfterSubmitEffect } from "../hooks/useFormScrollToFirstErrorAfterSubmitEffect";

export interface FormScrollToFirstErrorAfterSubmitProps {
  containerRef: React.MutableRefObject<HTMLElement | null>;
}

export const FormScrollToFirstErrorAfterSubmit = ({ containerRef }: FormScrollToFirstErrorAfterSubmitProps) => {
  useFormScrollToFirstErrorAfterSubmitEffect(containerRef.current);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
