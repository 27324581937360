import { useCallback, useEffect, useState } from "react";
import { getEmptyArray } from "@sideg/helpers";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { LegacyBankModel } from "../../../../common/types/banks";
import { useApiMutation } from "../../../../common/hooks";
import { getBanksSettlementsAndCashCreation } from "../../store/settlementsAndCashCreation.thunks";
import { SettlementAndCashCreationFromBank } from "./bank/SettlementAndCashCreationFromBank";
import * as S from "./SettlementAndCashCreationFormBanksList.styled";
import { SettlementAndCashCreationFromBankSkeleton } from "./bank/SettlementAndCashCreationFromBank.skeleton";
import { isFailed, isLoading } from "../../../../common/types/state";
import { Text } from "../../../../common/ui-kit/typography";
import { Box } from "../../../../common/ui-kit/containers/box";
import { ButtonLink } from "../../../../common/ui-kit/buttons/ButtonLink";

const SKELETONS = getEmptyArray(10);

export interface SettlementAndCashCreationFormBanksListProps extends BaseCssProps {
  isDisabled?: boolean;
}

export const SettlementAndCashCreationFormBanksList = ({
  isDisabled,
  className,
}: SettlementAndCashCreationFormBanksListProps) => {
  const { error, status, mutate } = useApiMutation(getBanksSettlementsAndCashCreation, {
    errorMessagePlaceholder: "Не удалось получить список банков",
  });
  const [banks, setBanks] = useState<LegacyBankModel[] | undefined>(undefined);

  const getBanks = useCallback(async () => {
    const result = await mutate(undefined);
    setBanks(result?.body);
  }, [mutate]);

  useEffect(() => {
    if (banks === undefined) {
      getBanks();
    }
  }, [banks, getBanks]);

  return (
    <S.Container padding={{ xs: "xs", md: "sm" }} title="Банки" className={className}>
      {isFailed(status) && (
        <Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
          <Text fontColor="primaryCaption" fontSize="fz2" lineHeight="defaultLineHeight">
            {error}
          </Text>
          <ButtonLink type="button" onClick={getBanks}>
            Попробовать еще раз
          </ButtonLink>
        </Box>
      )}
      {isLoading(status) &&
        (!banks || banks.length === 0) &&
        SKELETONS.map((x) => <SettlementAndCashCreationFromBankSkeleton key={x} />)}
      {banks &&
        banks.length > 0 &&
        banks.map((x) => (
          <SettlementAndCashCreationFromBank
            bankId={x.code}
            bankName={x.shortName}
            key={x.code}
            isDisabled={isDisabled}
          />
        ))}
    </S.Container>
  );
};
