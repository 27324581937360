import { QueueTasksListFilterParams } from "../../../types/filter";
import {
  selectQueueTasksListFilters,
  selectQueueTasksListFiltersMaxLength,
} from "../../../store/queueTasksList.selectors";
import { FilterMultipleSelectList } from "../../../../../common/ui-kit/form-controls/select";
import { useQueueTasksListFilterArray } from "../../../hooks/useQueueTasksListFilterArray";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../../common/store";

export interface QueueTasksListTaskTypesFilterProps extends BaseCssProps {
  filteringObject: QueueTasksListFilterParams;
}

export const QueueTasksListTaskTypesFilter = ({ filteringObject, className }: QueueTasksListTaskTypesFilterProps) => {
  const { getItemTitle, selectedItems, getItems, onSelectedChange } = useQueueTasksListFilterArray({
    filteringObject,
    key: "taskTypes",
    itemsSelector: (state) => selectQueueTasksListFilters(state).data?.taskTypes.ids ?? [],
    getTitleFunc: (filters) => (item) => filters?.taskTypes.titles[item] ?? item,
  });

  const maxItemsLength = useAppSelector((state) => selectQueueTasksListFiltersMaxLength(state, "taskTypes"));

  return (
    <FilterMultipleSelectList
      className={className}
      text="Типы задач"
      selectedItems={selectedItems}
      getItemTitle={getItemTitle}
      onSelectedChanged={onSelectedChange}
      getItems={getItems}
      maxItemsLength={maxItemsLength}
    />
  );
};
