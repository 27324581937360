import { WidgetsNewsItem } from "../types/WidgetsNewsItem";
import { widgetsNewsConfig } from "../configs";
import { WidgetsNewsCached } from "../types/WidgetsNewsCached";
import { jsonService } from "../../../services/json";
import { loggingService } from "../../../logging/services/loggingService";
import { LocalStorageExpiresTimeService, LocalStorageService } from "../../../services/local-storage";

const localStorage = new LocalStorageService(widgetsNewsConfig.storageNewsCacheKey);
const expiresTimeService = new LocalStorageExpiresTimeService(widgetsNewsConfig.storageUpdateBeforeKey);

const save = (news: WidgetsNewsItem[]): void => {
  localStorage.setValue(jsonService.serialize(news));
  expiresTimeService.setExpiresTime(Date.now() + widgetsNewsConfig.newsCacheMinutes * 60 * 1000);
};

const deleteNews = (): void => {
  localStorage.removeItem();
  expiresTimeService.removeExpiresTime();
};

const getNews = (): WidgetsNewsCached | undefined => {
  try {
    const cachedNews = localStorage.getValue();
    const updateBefore = expiresTimeService.getExpiresTime();

    if (cachedNews && updateBefore !== undefined && !expiresTimeService.isTimeExpired()) {
      return {
        updateBefore,
        news: jsonService.deserialize<WidgetsNewsItem[]>(cachedNews),
      };
    }
  } catch (err) {
    loggingService.logError(err);
  }

  deleteNews();

  return undefined;
};

const isCacheExpired = () => expiresTimeService.isTimeExpired();

export const widgetsNewsStorageService = {
  getNews,
  isCacheExpired,
  save,
  deleteNews,
};
