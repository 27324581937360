import { entityHelper } from "@sideg/helpers";

export const commonDeclensions = {
  bank: {
    nominative: (count: number, includeNumberBefore = false) =>
      entityHelper.getWordDeclension(count, "банк", ["", "а", "ов"], includeNumberBefore),
    genitive: (count: number, includeNumberBefore = false) =>
      entityHelper.getWordDeclension(count, "банк", ["а", "ов", "ов"], includeNumberBefore),
    prepositional: (count: number, includeNumberBefore = false) =>
      entityHelper.getWordDeclension(count, "банк", ["е", "ах", "ах"], includeNumberBefore),
  },
  activeTasks: {
    nominative: (count: number, includeNumberBefore = false) => {
      return `${entityHelper.getWordDeclension(
        count,
        "активн",
        ["ая", "ые", "ых"],
        includeNumberBefore,
      )} ${entityHelper.getWordDeclension(count, "задач", ["а", "и", ""], false)}`;
    },
  },
  tasks: {
    nominative: (count: number, includeNumberBefore = false) => {
      return entityHelper.getWordDeclension(count, "задач", ["а", "и", ""], includeNumberBefore);
    },
  },
  condition: {
    nominative: (count: number, includeNumberBefore = false) => {
      return entityHelper.getWordDeclension(count, "услови", ["е", "я", "й"], includeNumberBefore);
    },
  },
};

export const getNumberUpToTenGenitive = (number: number) => {
  switch (number) {
    case 1:
      return "одного";
    case 2:
      return "двух";
    case 3:
      return "трех";
    case 4:
      return "четырех";
    case 5:
      return "пяти";
    case 6:
      return "шести";
    case 7:
      return "семи";
    case 8:
      return "восьми";
    case 9:
      return "девяти";
    default:
      return "";
  }
};

export const declensionHelper = {
  commonDeclensions,
  getNumberUpToTenGenitive,
};
