import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useWebPushesSubscriptionStateContext } from "./context/useWebPushesSubscriptionStateContext";

export const useWebPushesIsSubscribed = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const subscriptionState = useWebPushesSubscriptionStateContext();
  const [isSubscribed, setIsSubscribed] = useState(subscriptionState === "subscribed");

  useEffect(() => {
    setIsSubscribed(subscriptionState === "subscribed");
  }, [subscriptionState]);

  return [isSubscribed, setIsSubscribed];
};
