export const taxpayerNumberValidator = (taxpayerNumber: string): boolean => {
  const part = (index: number): number => {
    return +taxpayerNumber[index];
  };

  if (taxpayerNumber.length === 10) {
    return (
      part(9) ===
      ((2 * part(0) +
        4 * part(1) +
        10 * part(2) +
        3 * part(3) +
        5 * part(4) +
        9 * part(5) +
        4 * part(6) +
        6 * part(7) +
        8 * part(8)) %
        11) %
        10
    );
  }

  if (taxpayerNumber.length === 12) {
    return (
      part(10) ===
        ((7 * part(0) +
          2 * part(1) +
          4 * part(2) +
          10 * part(3) +
          3 * part(4) +
          5 * part(5) +
          9 * part(6) +
          4 * part(7) +
          6 * part(8) +
          8 * part(9)) %
          11) %
          10 &&
      part(11) ===
        ((3 * part(0) +
          7 * part(1) +
          2 * part(2) +
          4 * part(3) +
          10 * part(4) +
          3 * part(5) +
          5 * part(6) +
          9 * part(7) +
          4 * part(8) +
          6 * part(9) +
          8 * part(10)) %
          11) %
          10
    );
  }

  return false;
};
