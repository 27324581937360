import * as React from "react";
import * as S from "./AppLayoutOverlayPanelContentWithHeader.styled";
import { Box } from "../../../../../ui-kit/containers/box";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { CancelButton } from "../../../../buttons/cancel-button";

export interface AppLayoutOverlayPanelContentWithHeaderProps extends BaseCssProps {
  onCloseClick: () => void | Promise<void>;
  title?: React.ReactNode;
  children?: React.ReactNode;
}

export const AppLayoutOverlayPanelContentWithHeader = ({
  onCloseClick,
  title,
  children,
  className,
}: AppLayoutOverlayPanelContentWithHeaderProps) => {
  return (
    <Box as="section" display="flex" flexDirection="column" flexGrow={1} flexShrink={1} className={className}>
      <S.Header>
        {title}
        <CancelButton type="button" onClick={onCloseClick} />
      </S.Header>
      {children}
    </Box>
  );
};
