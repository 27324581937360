import {
  addDays,
  addMonths,
  addSeconds,
  addYears,
  compareAsc,
  differenceInDays,
  differenceInSeconds,
  differenceInYears,
  endOfDay,
  format as formatFns,
  formatDistance,
  formatDistanceStrict,
  formatRelative as formatRelativeFns,
  isMatch,
  isSameDay,
  isValid,
  parse,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subSeconds,
  subYears,
  // eslint-disable-next-line import/no-duplicates
} from "date-fns";
// eslint-disable-next-line import/no-duplicates
import { ru } from "date-fns/locale";
import { capitalize } from "@sideg/helpers/utils/capitalize";

const formats = {
  /**
   * @example 11.02.2022
   */
  dateOnly: "dd.MM.yyyy",
  /**
   * @example 11.02.22
   */
  dayMonthShortYearDot: "dd.MM.yy",
  /**
   * @example 1 марта 2022
   */
  dayFullMonthYear: "d MMMM yyyy",
  /**
   * @example 1 мар 2022
   */
  dayShortMonthYear: "d MMM yyyy",
  /**
   * @example 2022.03.02
   */
  yearMonthDay: "yyyy.MM.dd",
  /**
   * @example 2022-03-02
   */
  yearMonthDayDashed: "yyyy-MM-dd",
  /**
   * @example 1 мар
   */
  dayAndShortMonth: "d MMM",
  /**
   * @example 18:09
   */
  timeOnly: "HH:mm",
};

export type DateFormats = keyof typeof formats;
type DateFormatter = (date: Date) => string;

export const specialFormatters = {
  /**
   * Возвращает формат `День ПолныйМесяц [Год, если не совпадает с текущим], Время`
   * @param date Дата
   * @example 24 ноября, 12:24
   * @example 5 апреля 2021, 11:45
   */
  dayFullMonthYearIfDifferentAndTime: (date: Date) =>
    `d MMMM${date.getFullYear() === new Date().getFullYear() ? "" : " yyyy"}, HH:mm`,

  /**
   * Возвращает формат `День ПолныйМесяц [Год, если не совпадает с текущим]`
   * @param date Дата
   * @example 24 ноября
   * @example 5 апреля 2021
   */
  dayFullMonthYearIfDifferent: (date: Date) =>
    `d MMMM${date.getFullYear() === new Date().getFullYear() ? "" : " yyyy"}`,

  /**
   * Возвращает формат `День КороткийМесяц [Год, если не совпадает с текущим]`
   * @param date Дата
   * @example 24 мар
   * @example 5 мар 2021
   */
  dayShortMonthYearIfDifferent: (date: Date) =>
    `d MMM${date.getFullYear() === new Date().getFullYear() ? "" : " yyyy"}`,

  /**
   * Возвращает формат `Время` или `День КороткийМесяц`, если переданная дата отличается от текущей
   * @param date Дата
   */
  dayAndShortMonthOrTimeOnlyIfSameDay: (date: Date) =>
    isSameDay(date, new Date()) ? formats.timeOnly : formats.dayAndShortMonth,
};

/**
 * Возвращает разницу во времени между переданной датой и текущей датой
 * в читаемом виде
 * @param dateFrom Дата от которой идет отчет периода
 * @example 11 месяцев назад, около 1 года назад, 1 день назад
 */
const getDistanceToNow = (dateFrom: Date): string =>
  formatDistance(dateFrom, new Date(), {
    addSuffix: true,
    locale: ru,
    includeSeconds: true,
  });

const getDistanceToNowStrict = (dateFrom: Date): string =>
  formatDistanceStrict(dateFrom, new Date(), {
    addSuffix: true,
    locale: ru,
  });

/**
 * Возвращает объект даты из строки заданного формата
 * @param date Строка даты в заданном формате
 * @param formatType Формат даты
 * @example ("10.12.2021", "dateOnly") -> Date
 */
const parseDateFromString = (date: string, formatType: DateFormats): Date => {
  return parse(date, formats[formatType], new Date(), { locale: ru });
};

/**
 * Возвращает признак соответствия строки заданному формату
 * @param date Дата
 * @param formatType Формат даты
 * @example ("10.12.2021", "dateOnly") -> true
 * @example ("10-12-2021", "dateOnly") -> false
 */
const isDateMatch = (date: string, formatType: DateFormats): boolean => {
  return isMatch(date, formats[formatType], { locale: ru });
};

/**
 * Возвращает первый формат, совпадающий с переданной строкой
 * @param date Строка даты
 * @param formatTypes Предполагаемые форматы строки
 * @example ("10-12-2021", ["dateOnly", "dayMonthShortYearDot", "yearMonthDayDashed"])) -> "yearMonthDayDashed"
 */
const getFirstDateFormatMatcher = (date: string, formatTypes: DateFormats[]): DateFormats | undefined => {
  return formatTypes.find((x) => isDateMatch(date, x));
};

/**
 * Возвращает дату в заданном формате
 * @param date Дата
 * @param formatType Формат даты или функция, возвращающая формат даты
 * @see specialFormatters
 */
const format = (date: Date, formatType: DateFormats | DateFormatter): string => {
  const dateFormat = typeof formatType === "string" ? formats[formatType as DateFormats] : formatType(date);

  return formatFns(date, dateFormat, { locale: ru });
};

/**
 * Возвращает дату относительно базовой даты в словесном виде
 * @param date Дата
 * @param baseDate Базовая дата
 */
const formatRelative = (date: Date | number, baseDate: Date | number) => {
  return formatRelativeFns(date, baseDate, { locale: ru });
};

/**
 * Возвращает дату в диапазоне от минимальной до максимальной даты (если они заданы)
 * @param date Дата
 * @param min Минимальная дата
 * @param max Максимальная дата
 */
const minMax = (date: Date, min: Date | undefined, max: Date | undefined): Date => {
  if (min !== undefined && date < min) {
    return min;
  }

  if (max !== undefined && date > max) {
    return max;
  }

  return date;
};

const isDateValid = (date: unknown): date is Date => {
  return isValid(date);
};

/**
 * Удаляет лишние символы, которые не могут быть в валидной строке даты
 * @param value Строка с датой
 * @example "11" мар. 2010 г. -> 11 мар 2010
 */
const replaceExtraCharsFromDateString = (value: string): string => {
  return value
    .replace(/[^А-Яа-я\d\- .]|(\D+$)/g, "")
    .replace(/\.\D/g, " ")
    .replace(/  +/g, " ");
};

const simpleOperations = {
  addSeconds,
  subSeconds,
  addDays,
  subDays,
  addMonths,
  subMonths,
  addYears,
  subYears,
};

const simpleModify = (operation: keyof typeof simpleOperations, date: Date | number, amount: number) => {
  return simpleOperations[operation](date, amount);
};

const unaryOperations = {
  startOfDay,
  endOfDay,
  startOfWeek,
  startOfMonth,
};

const unaryModify = (operation: keyof typeof unaryOperations, date: Date | number) => {
  return unaryOperations[operation](date, { locale: ru });
};

const getDifferenceInDays = (dateFrom: Date | number, dateTo: Date | number) => {
  return differenceInDays(dateTo, dateFrom);
};

const differenceOperations = {
  differenceInSeconds,
  differenceInDays,
  differenceInYears,
};

const differenceOperationPrefix = "differenceIn" as const;
type TrimPrefix<TOperation extends string> = TOperation extends `${typeof differenceOperationPrefix}${infer Operation}`
  ? Operation
  : TOperation;
type DifferenceOperationType = Uncapitalize<TrimPrefix<keyof typeof differenceOperations>>;

const getDifferenceIn = (
  operation: DifferenceOperationType,
  dateLeft: Date | number,
  dateRight: Date | number,
): number => {
  const key = `${differenceOperationPrefix}${capitalize(operation)}` as keyof typeof differenceOperations;

  return differenceOperations[key](dateLeft, dateRight);
};

export type CompareDatesResult = -1 | 0 | 1;

/**
 * Сравнивает две даты и возвращает:
 * 1, если первая дата после второй,
 *-1, если первая дата перед второй,
 * 0, если даты равны.
 * @param dateLeft Дата
 * @param dateRight Дата
 */
const compareDates = (dateLeft: Date | number, dateRight: Date | number): CompareDatesResult => {
  return compareAsc(dateLeft, dateRight) as CompareDatesResult;
};

/**
 * Преобразует переданное значение (в секундах) в формат MM:SS
 * @param timeInSeconds Время в секундах
 * @example 91 -> 01:31
 */
const secondsToMinutesAndSecondsString = (timeInSeconds: number): string => {
  const SECONDS_IN_MINUTE = 60;
  const beautify = (value: number) => Math.floor(value).toString().padStart(2, "0");

  return `${beautify(timeInSeconds / SECONDS_IN_MINUTE)}:${beautify(timeInSeconds % SECONDS_IN_MINUTE)}`;
};

export const dateTimeHelper = {
  format,
  formatRelative,
  getDistanceToNow,
  getDistanceToNowStrict,
  parseDateFromString,
  isDateMatch,
  simpleModify,
  unaryModify,
  getFirstDateFormatMatcher,
  minMax,
  isSameDay,
  replaceExtraCharsFromDateString,
  isValid: isDateValid,
  getDifferenceInDays,
  getDifferenceIn,
  compareDates,
  secondsToMinutesAndSecondsString,
};
