import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { BankGuaranteeListFiltersDesktop } from "./desktop/BankGuaranteeListFilters.desktop";
import { BankGuaranteeListFiltersMobile } from "./mobile/BankGuaranteeListFilters.mobile";

export type BankGuaranteeListFiltersProps = BaseCssProps;

export const BankGuaranteeListFilters = ({ className }: BankGuaranteeListFiltersProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={<BankGuaranteeListFiltersMobile className={className} />}
      desktop={<BankGuaranteeListFiltersDesktop className={className} />}
    />
  );
};
