import { combineReducers } from "redux";
import { creditExecutionDetailsTransferReducer } from "./transfer/creditExecutionDetailsTransfer.slice";
import { creditExecutionDetailsProductDetailsReducer } from "./product-details/creditExecutionDetailsProductDetails.slice";
import { creditExecutionDetailsActiveDemandReducer } from "./active-demand/creditExecutionDetailsActiveDemand.slice";
import { creditExecutionDetailsDemandsReducer } from "./demands/creditExecutionDetailsDemands.slice";
import { creditExecutionDetailsActiveTasksReducer } from "./active-tasks/creditExecutionDetailsActiveTasks.slice";
import { creditExecutionDetailsTasksHistoryReducer } from "./tasks-history/creditExecutionDetailsTasksHistory.slice";
import { creditExecutionDetailsDocumentsReducer } from "./documents/creditExecutionDetailsDocuments.slice";

export const creditExecutionDetailsReducer = combineReducers({
  transfer: creditExecutionDetailsTransferReducer,
  productDetails: creditExecutionDetailsProductDetailsReducer,
  activeDemand: creditExecutionDetailsActiveDemandReducer,
  demands: creditExecutionDetailsDemandsReducer,
  documents: creditExecutionDetailsDocumentsReducer,
  activeTasks: creditExecutionDetailsActiveTasksReducer,
  tasksHistory: creditExecutionDetailsTasksHistoryReducer,
});
