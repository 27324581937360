import { BaseCssProps } from "../../../../common/ui-kit/types";
import { DocumentUploadActiveDocument, useDocumentUploadLabelText } from "../../../../document/upload";
import { FileUpload } from "../../../../files/upload";
import { SimpleOpacityTransition } from "../../../../common/ui-kit/animations/transitions";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { LegalServicesCreationProductDocument } from "../../types/LegalServicesCreationProductDocument";
import { useLegalServiceCreationFormDocumentField } from "../../hooks/form/useLegalServiceCreationFormDocumentField";

export interface LegalServicesCreationFormDocumentFieldProps extends BaseCssProps {
  document: LegalServicesCreationProductDocument;
  isDisabled: boolean;
  isLoading: boolean;
}

export const LegalServicesCreationFormDocumentField = ({
  className,
  document,
  isDisabled,
  isLoading,
}: LegalServicesCreationFormDocumentFieldProps) => {
  const { files, onFileDelete, error, handleDrop } = useLegalServiceCreationFormDocumentField(document);

  const uploadFileLabel = useDocumentUploadLabelText({
    isLoading,
    attachedFilesCount: files.length,
    isMultiFile: true,
    isReplaceableCollection: false,
    loadingFilesCount: files.length,
  });

  return (
    <DocumentUploadActiveDocument
      className={className}
      title={document.name}
      isRequired={document.isRequired}
      isMultiple
      canUpload={!isDisabled}
      isLoading={isLoading}
      onDrop={handleDrop}
      error={error}
      uploadLabelText={uploadFileLabel}
    >
      {files && files.length > 0 && (
        <StackPanel direction="column" gap="md">
          {files.map((x) => (
            <SimpleOpacityTransition timeout={250} key={x.id}>
              <FileUpload
                fileName={x.file.name}
                date={x.dateAdded}
                canRemove={!isDisabled && !isLoading}
                onRemove={() => onFileDelete(x.id)}
              />
            </SimpleOpacityTransition>
          ))}
        </StackPanel>
      )}
    </DocumentUploadActiveDocument>
  );
};
