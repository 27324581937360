import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { creditExecutionDetailsDemandsAdapter } from "./creditExecutionDetailsDemands.adapter";
import { combineSliceNames } from "../../../../common/store/utils";
import { creditExecutionDetailsDemandsMapper } from "./creditExecutionDetailsDemands.mapper";
import {
  CREDIT_EXECUTION_DETAILS_SLICE_NAME,
  creditExecutionDetailsActiveProductChanged,
} from "../creditExecutionDetails.actions";
import { getCreditExecutionDetails, uploadGroupDocumentCreditExecutionDetails } from "../creditExecutionDetails.thunks";

const SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_DETAILS_SLICE_NAME, "demands");

const initialState = creditExecutionDetailsDemandsAdapter.getInitialState();

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(creditExecutionDetailsActiveProductChanged, (state, action) => {
        if (action.payload === undefined) {
          creditExecutionDetailsDemandsAdapter.removeAll(state);

          return;
        }

        creditExecutionDetailsDemandsAdapter.setAll(
          state,
          creditExecutionDetailsDemandsMapper.mapDemands(action.payload.demands ?? []),
        );
      })
      .addMatcher(
        isAnyOf(getCreditExecutionDetails.fulfilled, uploadGroupDocumentCreditExecutionDetails.fulfilled),
        (state, action) => {
          creditExecutionDetailsDemandsAdapter.upsertMany(
            state,
            creditExecutionDetailsDemandsMapper.mapDemands(action.payload.body.demands),
          );
        },
      );
  },
});

export const creditExecutionDetailsDemandsReducer = slice.reducer;
