import { createSlice, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import {
  DemandQueueTaskCompleted,
  demandQueueTasksAdapter,
  getHistoryDemandQueueTasks,
} from "../../../../demand/queue-tasks";
import {
  CREDIT_EXECUTION_DETAILS_SLICE_NAME,
  creditExecutionDetailsActiveDemandChanged,
  creditExecutionDetailsActiveProductChanged,
} from "../creditExecutionDetails.actions";
import { CreditExecutionDetailsTasksHistorySliceState } from "./creditExecutionDetailsTasksHistory.types";
import { isCreditExecutionDetailsNeedReloadMatcher } from "../creditExecutionDetails.matchers";

const SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_DETAILS_SLICE_NAME, "tasksHistory");

const initialState = demandQueueTasksAdapter.getInitialState<CreditExecutionDetailsTasksHistorySliceState>({
  status: LoadingStatusEnum.Idle,
  activeDemandId: undefined,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    addNewCompletedTask: (state, action: PayloadAction<DemandQueueTaskCompleted>) => {
      demandQueueTasksAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(creditExecutionDetailsActiveProductChanged, () => initialState)
      .addCase(creditExecutionDetailsActiveDemandChanged, (state, action) => {
        return { ...initialState, activeDemandId: action.payload };
      })
      .addCase(getHistoryDemandQueueTasks.fulfilled, (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          if (action.payload.body.paginationInformation?.pageNumber === 1) {
            demandQueueTasksAdapter.setAll(state, action.payload.body.data ?? []);
          } else {
            demandQueueTasksAdapter.upsertMany(state, action.payload.body.data ?? []);
          }
          state.pagination = action.payload.body.paginationInformation;
        }
      })
      .addMatcher(isCreditExecutionDetailsNeedReloadMatcher, (state) => {
        state.status = LoadingStatusEnum.Idle;
        state.pagination = undefined;
      })
      .addMatcher(isAsyncThunkAction(getHistoryDemandQueueTasks), (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          const status = getLoadingStatusFromAction(action);
          if (status !== LoadingStatusEnum.Aborted) {
            state.status = status;
          }
        }
      });
  },
});

export const creditExecutionDetailsTasksHistoryReducer = slice.reducer;

export const { addNewCompletedTask: addNewCompletedTaskCreditExecutionDetailsTasksHistory } = slice.actions;
