import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { TaxpayerFormInput } from "../../../../../common/ui/form-controls/taxpayer-input";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { AlertLoadingToResult } from "../../../../../common/ui-kit/notifications/alert-with-indicator";
import { useBankGuaranteeCreationBeneficiaryField } from "../../../hooks/creation/fields/useBankGuaranteeCreationBeneficiaryField";

export interface BankGuaranteeCreationBeneficiaryBlockProps {
  isDisabled: boolean;
}

export const BankGuaranteeCreationBeneficiaryBlock = ({ isDisabled }: BankGuaranteeCreationBeneficiaryBlockProps) => {
  const [fieldBeneficiaryNameProps] = useBankGuaranteeCreationField("beneficiaryName");
  const { isLoading, isFailed, fieldProps: fieldTaxpayerNumberProps } = useBankGuaranteeCreationBeneficiaryField();

  return (
    <CreationBlockWithTitle title="Заказчик">
      <StackPanel direction="column" gap="md">
        <TaxpayerFormInput
          label="ИНН"
          size="medium"
          autoComplete="off"
          {...fieldTaxpayerNumberProps}
          disabled={isDisabled || isLoading}
        />
        <SimpleOpacityTransition timeout={250} in={isLoading || isFailed} unmountOnExit>
          <AlertLoadingToResult
            isLoading={isLoading}
            loadingText="Получаем наименование заказчика"
            isError={isFailed}
            errorText="Мы не смогли получить наименование. Укажите его самостоятельно"
          />
        </SimpleOpacityTransition>
        <FormInput
          type="text"
          label="Наименование"
          size="medium"
          spellCheck={false}
          autoComplete="off"
          autoCorrect="off"
          disabled={isDisabled || isLoading}
          {...fieldBeneficiaryNameProps}
        />
      </StackPanel>
    </CreationBlockWithTitle>
  );
};
