import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { RejectionProductType } from "../types/RejectionProductType";

const selectState = (state: RootState) => state.domains.product.rejection;

export const selectProductRejectionReasons = createSelector(
  [selectState, (state: RootState, product: RejectionProductType) => product],
  (state, product) => {
    return state[product];
  }
);
