import { useMemo } from "react";
import { useAppLayout } from "./useAppLayout";
import { AppLayoutOverlayPanelKey } from "../../app-layout/types";

export const useAppLayoutActiveOverlayPanel = (key: AppLayoutOverlayPanelKey) => {
  const { activeOverlayPanel } = useAppLayout();

  return useMemo(
    () => ({
      isOpened: activeOverlayPanel.value === key,
      openPanel: () => {
        activeOverlayPanel.setValue(key);
      },
      closePanel: () => {
        activeOverlayPanel.setValue(undefined);
      },
      togglePanel: () => activeOverlayPanel.setValue(activeOverlayPanel.value === key ? undefined : key),
    }),
    [activeOverlayPanel, key]
  );
};
