import styled from "@emotion/styled";

export const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  position: relative;
  padding-right: 2px;
  padding-top: 2px;

  ::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.secondaryColors.notificationBackground};
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
`;
