import { currencyHelper, dateTimeHelper, isValidUrl } from "@sideg/helpers";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { Link } from "../../../common/ui-kit/links";
import { StackPanel } from "../../../common/ui-kit/containers/stack-panel";
import { Text } from "../../../common/ui-kit/typography";
import { isLoading, LoadingStatusEnum } from "../../../common/types/state";
import { BankGuaranteeBrief } from "../types/BankGuaranteeBrief";
import { useBankGuaranteeDateEventTitle } from "../../common/hooks";
import {
  ProductCreationBriefItem,
  ProductCreationBriefItemOrSkeleton,
  ProductCreationBriefSeparator,
} from "../../../common/product-creation";

export interface BankGuaranteeBriefDescriptionListProps extends BaseCssProps {
  isWithSeparators?: boolean;
  isShowDateStartMovedToToday?: boolean;
  status: LoadingStatusEnum;
  data: BankGuaranteeBrief | undefined;
}

export const BankGuaranteeBriefDescriptionList = ({
  data,
  status,
  isShowDateStartMovedToToday,
  isWithSeparators,
  className,
}: BankGuaranteeBriefDescriptionListProps) => {
  const isFetching = isLoading(status);
  const dateEventLabel = useBankGuaranteeDateEventTitle(data?.guaranteeType.code);

  return (
    <StackPanel direction="column" gap="md" as="dl" className={className}>
      <ProductCreationBriefItemOrSkeleton isLoading={isFetching} title="Клиент" description={data?.client.name} />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="ИНН"
        description={data?.client.taxpayerNumber}
      />
      {data?.client.isClientWithSignerPerson && (
        <ProductCreationBriefItem title="Дополнительно" description="Выбран подписант по доверенности" />
      )}
      {isWithSeparators && <ProductCreationBriefSeparator />}
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Тип гарантии"
        description={data?.guaranteeType.title}
      />
      <ProductCreationBriefItemOrSkeleton isLoading={isFetching} title="ФЗ" description={data?.federalLaw.title} />
      {isWithSeparators && <ProductCreationBriefSeparator />}
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Номер закупки"
        lines={2}
        description={
          data?.purchaseNumber && (
            <>
              <span>{data.purchaseNumber}</span>
              {data.purchaseLink && isValidUrl(data.purchaseLink) && (
                <>
                  <br />
                  <Link href={data.purchaseLink} target="_blank" rel="noopener noopener">
                    Ссылка на закупку
                  </Link>
                </>
              )}
            </>
          )
        }
      />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Предмет контракта"
        lines={3}
        description={data?.contractSubject}
      />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="ИКЗ"
        description={data?.purchaseIdentificationCode}
      />
      {isWithSeparators && <ProductCreationBriefSeparator />}
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Заказчик"
        description={data?.beneficiary.name}
      />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="ИНН"
        description={data?.beneficiary.taxpayerNumber}
      />
      {isWithSeparators && <ProductCreationBriefSeparator />}
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Начальная цена контракта"
        description={currencyHelper.getCurrencyStringNullable(data?.contractFirstSum, undefined)}
      />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Итоговая цена контракта"
        description={currencyHelper.getCurrencyStringNullable(data?.contractFinalSum, undefined)}
      />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Сумма аванса по контракту"
        description={currencyHelper.getCurrencyStringNullable(data?.contractPrepaidSum, undefined)}
      />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Сумма обеспечения на исполнение"
        description={currencyHelper.getCurrencyStringNullable(data?.enforcementSum, undefined)}
      />
      {isWithSeparators && <ProductCreationBriefSeparator />}
      {dateEventLabel && (
        <ProductCreationBriefItemOrSkeleton
          isLoading={isFetching}
          title={dateEventLabel}
          description={data?.dateEvent ? dateTimeHelper.format(new Date(data.dateEvent), "dateOnly") : undefined}
        />
      )}
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Аукцион"
        description={
          // eslint-disable-next-line no-nested-ternary
          data?.isClosedAuction === true ? "Закрытый" : data?.isClosedAuction === false ? "Открытый" : undefined
        }
      />
      <ProductCreationBriefItemOrSkeleton isLoading={isFetching} title="Номер лота" description={data?.lotNumber} />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Сумма обеспечения"
        description={currencyHelper.getCurrencyStringNullable(data?.guaranteeSum, undefined)}
      />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isFetching}
        title="Дополнительно"
        lines={3}
        description={
          !data ? undefined : (
            <StackPanel direction="column" gap="sm">
              <span>
                {data.isWriteOffRequest
                  ? "Есть требование о бесспорном списании"
                  : "Требования о бесспорном	списании нет"}
              </span>
              <span>{data.isReprovision ? "Есть переобеспечение" : "Переобеспечения нет"}</span>
              {data.isSingleSupplier && <div>Единственный поставщик</div>}
            </StackPanel>
          )
        }
      />
      {isWithSeparators && <ProductCreationBriefSeparator />}
      {isShowDateStartMovedToToday && (
        <ProductCreationBriefItemOrSkeleton
          isLoading={isFetching}
          title="Срок БГ"
          description={
            !data ? undefined : (
              <StackPanel gap="sm" direction="column">
                <span>
                  {dateTimeHelper.format(new Date(data.dateStartMoved ?? data.dateStart), "dateOnly")} –{" "}
                  {dateTimeHelper.format(new Date(data.dateEnd), "dateOnly")}
                </span>
                {data.isDateStartMovedToToday && (
                  <Text fontWeight={500} fontSize="fz1" fontColor="colors.warning">
                    Дата начала заявки изменена на сегодняшнюю
                  </Text>
                )}
              </StackPanel>
            )
          }
        />
      )}
      {!isShowDateStartMovedToToday && (
        <ProductCreationBriefItemOrSkeleton
          isLoading={isFetching}
          title="Срок БГ"
          description={
            data ? (
              <span>
                c {dateTimeHelper.format(new Date(data.dateStart), "dateOnly")}&nbsp;по&nbsp;
                {dateTimeHelper.format(new Date(data.dateEnd), "dateOnly")}
              </span>
            ) : undefined
          }
        />
      )}
    </StackPanel>
  );
};
