import { RootState } from "../../../../common/store";
import { CreditExecutionDetailsDemandsState } from "./creditExecutionDetailsDemands.types";
import { creditExecutionDetailsDemandsAdapter } from "./creditExecutionDetailsDemands.adapter";
import { selectCreditExecutionDetails } from "../creditExecutionDetails.selectors";

export const selectCreditExecutionDetailsDemands = (state: RootState): CreditExecutionDetailsDemandsState =>
  selectCreditExecutionDetails(state).demands;

export const {
  selectIds: selectCreditExecutionDetailsDemandsIds,
  selectById: selectCreditExecutionDetailsDemandById,
  selectTotal: selectCreditExecutionDetailsDemandsTotal,
} = creditExecutionDetailsDemandsAdapter.getSelectors(selectCreditExecutionDetailsDemands);
