import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { useMemo } from "react";
import { BankGuaranteeDetailsComplicatedTaskDateCreatedPlacement } from "../types/BankGuaranteeDetailsComplicatedTaskDateCreatedPlacement";
import {
  isDemandQueueTaskActiveActionHasMessageOrProvidedFiles,
  isDemandQueueTaskCommentHaveFilesOrBody,
} from "../../../../../demand/queue-tasks";
import { BankGuaranteeDetailsDemandQueueTaskActive } from "../../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskActive";
import { BankGuaranteeDetailsDemandQueueTaskCompleted } from "../../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskCompleted";

export interface UseBankGuaranteeDetailsComplicatedTaskDateCreatedOptions {
  isActiveTask: boolean;
  date: string | undefined;
  isPinned: boolean | undefined;
  hasFile: boolean;
  hasComment: boolean;
  hasContent: boolean;
}

const getPlacementByFlags = ({
  hasFile,
  hasComment,
  hasContent,
}: Pick<
  UseBankGuaranteeDetailsComplicatedTaskDateCreatedOptions,
  "hasFile" | "hasComment" | "hasContent"
>): BankGuaranteeDetailsComplicatedTaskDateCreatedPlacement => {
  if (hasComment) {
    return "comment";
  }

  if (hasContent) {
    return "content";
  }

  return hasFile ? "file" : "none";
};

export interface UseBankGuaranteeDetailsComplicatedTaskDateCreatedResult {
  date: Date | undefined;
  placement: BankGuaranteeDetailsComplicatedTaskDateCreatedPlacement;
}

export const useBankGuaranteeDetailsComplicatedTaskDateCreated = ({
  isActiveTask,
  date: dateString,
  isPinned,
  ...flags
}: UseBankGuaranteeDetailsComplicatedTaskDateCreatedOptions): UseBankGuaranteeDetailsComplicatedTaskDateCreatedResult => {
  const isDesktop = useThemeIsDesktop();

  return useMemo(() => {
    if (dateString === undefined) {
      return { date: undefined, placement: "none" };
    }

    const date = new Date(dateString);

    if (isActiveTask && (isDesktop || !isPinned)) {
      return { date, placement: "card" };
    }

    return { date, placement: getPlacementByFlags(flags) };
  }, [dateString, flags, isActiveTask, isDesktop, isPinned]);
};

export const getUseBankGuaranteeDetailsComplicatedTaskDateCreatedOptionsByCompletedTask = (
  task: BankGuaranteeDetailsDemandQueueTaskCompleted,
) => {
  return {
    isActiveTask: false,
    isPinned: false,
    date: task.managerComment?.date,
    hasFile: task.billInfo?.billFile !== undefined || task.projectInfo?.projectFile !== undefined,
    hasComment: isDemandQueueTaskCommentHaveFilesOrBody(task.managerComment),
    hasContent: task.billInfo !== undefined,
  };
};

export const getUseBankGuaranteeDetailsComplicatedTaskDateCreatedOptionsByActiveTask = (
  task: BankGuaranteeDetailsDemandQueueTaskActive,
): UseBankGuaranteeDetailsComplicatedTaskDateCreatedOptions => {
  return {
    isActiveTask: true,
    isPinned: task.isPinned,
    date: task.dateCreated,
    hasFile: task.billInfo?.billFile !== undefined || task.projectInfo?.projectFile !== undefined,
    hasComment: isDemandQueueTaskActiveActionHasMessageOrProvidedFiles(task.action),
    hasContent: task.billInfo !== undefined,
  };
};
