import { currencyHelper, dateTimeHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { Typography } from "../../../../../common/ui-kit/typography";
import { CreditWorkingAssetsListItem } from "../../../types/items/CreditWorkingAssetsListItem";
import { CreditWorkingAssetsCommonDetailsContainer } from "../../../../common/components";

export interface CreditWorkingAssetsListItemDetailsProps extends BaseCssProps {
  item: CreditWorkingAssetsListItem;
}

export const CreditWorkingAssetsListItemDetails = ({ item, className }: CreditWorkingAssetsListItemDetailsProps) => {
  return (
    <CreditWorkingAssetsCommonDetailsContainer
      className={className}
      approved={
        item.approvedCreditSum !== undefined && item.approvedCreditSum > 0 ? (
          <Typography fontColor={!item.isActive ? "typography.success" : "typography.primary"}>
            {currencyHelper.getCurrencyStringNullable(item.approvedCreditSum, undefined)}
          </Typography>
        ) : undefined
      }
      requested={currencyHelper.getCurrencyStringNullable(item.creditSum, undefined)}
      confirmed={currencyHelper.getCurrencyStringNullable(item.confirmedCreditSum, undefined)}
      period={`${dateTimeHelper.format(new Date(item.dateStart), "dayShortMonthYear")} – ${dateTimeHelper.format(
        new Date(item.dateEnd),
        "dayShortMonthYear",
      )}`}
    />
  );
};
