import { createSlice } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { LoadingStatusEnum } from "../../../../common/types/state";
import { CreditExecutionDetailsTransferState } from "./creditExecutionDetailsTransfer.types";
import { CREDIT_EXECUTION_DETAILS_SLICE_NAME } from "../creditExecutionDetails.actions";
import { transferCreditExecution } from "../../../common/store";

const initialState: CreditExecutionDetailsTransferState = {
  data: undefined,
  status: LoadingStatusEnum.Idle,
};

const slice = createSlice({
  name: combineSliceNames(CREDIT_EXECUTION_DETAILS_SLICE_NAME, "transfer"),
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(transferCreditExecution, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const creditExecutionDetailsTransferReducer = slice.reducer;

export const { clear: clearCreditExecutionDetailsTransfer } = slice.actions;
