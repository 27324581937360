import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { BaseCssProps } from "../types";

const blink = keyframes` 
50% {
  color: transparent
}`;

const StyledSpan = styled.span`
  animation: ${blink} 1.3s linear infinite;

  &:nth-of-type(1) {
    animation-delay: 0ms;
  }

  &:nth-of-type(2) {
    animation-delay: 250ms;
  }

  &:nth-of-type(3) {
    animation-delay: 500ms;
  }
`;

export interface DotsLoaderProps extends BaseCssProps {
  containerDataTestId?: string;
}

export const DotsLoader = ({ className, containerDataTestId }: DotsLoaderProps) => {
  return (
    <StyledSpan className={className} data-testid={containerDataTestId}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </StyledSpan>
  );
};
