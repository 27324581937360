import { FormikTouched } from "formik";
import { useMemo } from "react";
import { AgencyType } from "../../../common/types/agencies";
import { PublicUserRegistrationFormInitialState } from "../types/PublicUserRegistrationFormInitialState";
import { useSearchParamsOnce } from "../../../common/filters";
import {
  publicUserRegistrationFormValidationSchema,
  publicUserRegistrationFormValidationSchemaGetDefault,
} from "../validation/publicUserRegistrationFormValidationSchema";
import { PublicUserRegistrationAgentFormValues } from "../types/agent/PublicUserRegistrationAgentFormValues";

export const usePublicUserRegistrationFormInitialState = (
  agencyType: AgencyType,
): PublicUserRegistrationFormInitialState => {
  const queryParams = useSearchParamsOnce(publicUserRegistrationFormValidationSchema, {
    isNeedReturnOnlySpecifiedInQueryFields: true,
  });

  return useMemo(() => {
    const initialValues = publicUserRegistrationFormValidationSchemaGetDefault(agencyType, queryParams);

    const queryKeys = Object.keys(queryParams);
    const initialTouched: FormikTouched<PublicUserRegistrationAgentFormValues> = queryKeys.reduce(
      (accum, key) => {
        accum[key] = true;

        return accum;
      },
      {} as Record<string, boolean>,
    );

    return { initialValues, initialTouched, isNeedToValidateOnMount: queryKeys.length > 0 };
  }, [agencyType, queryParams]);
};
