import { currencyHelper } from "@sideg/helpers";
import { ProductCreationScoringSumLimitButton } from "./ProductCreationScoringSumLimitButton";
import { BaseCssProps } from "../../../../ui-kit/types";
import { Typography } from "../../../../ui-kit/typography";
import { ProductCreationScoringSumLimitRangeContainer } from "./ProductCreationScoringSumLimitRangeContainer";

export interface ProductCreationScoringSumLimitRangeProps extends BaseCssProps {
  sumFrom?: number;
  sumTo?: number;
  onSumFromClick: (value: number) => void | Promise<void>;
  onSumToClick: (value: number) => void | Promise<void>;
}

export const ProductCreationScoringSumLimitRange = ({
  sumFrom,
  sumTo,
  onSumFromClick,
  onSumToClick,
  className,
}: ProductCreationScoringSumLimitRangeProps) => {
  if (sumTo === undefined && sumFrom === undefined) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  const rubleSign = <Typography fontColor="primary"> ₽</Typography>;

  if (sumFrom !== undefined && sumTo !== undefined) {
    return (
      <ProductCreationScoringSumLimitRangeContainer className={className}>
        <ProductCreationScoringSumLimitButton onClick={() => onSumFromClick(sumFrom)} type="button">
          {currencyHelper.getCurrencyStringWithoutRubleSign(sumFrom, true)}
        </ProductCreationScoringSumLimitButton>{" "}
        –{" "}
        <ProductCreationScoringSumLimitButton onClick={() => onSumToClick(sumTo)} type="button">
          {currencyHelper.getCurrencyStringWithoutRubleSign(sumTo, true)}
        </ProductCreationScoringSumLimitButton>
        {rubleSign}
      </ProductCreationScoringSumLimitRangeContainer>
    );
  }

  if (sumFrom !== undefined) {
    return (
      <ProductCreationScoringSumLimitRangeContainer className={className}>
        От{" "}
        <ProductCreationScoringSumLimitButton onClick={() => onSumFromClick(sumFrom)} type="button">
          {currencyHelper.getCurrencyStringWithoutRubleSign(sumFrom, true)}
        </ProductCreationScoringSumLimitButton>
        {rubleSign}
      </ProductCreationScoringSumLimitRangeContainer>
    );
  }

  if (sumTo !== undefined) {
    return (
      <ProductCreationScoringSumLimitRangeContainer className={className}>
        До{" "}
        <ProductCreationScoringSumLimitButton onClick={() => onSumToClick(sumTo)} type="button">
          {currencyHelper.getCurrencyStringWithoutRubleSign(sumTo, true)}
        </ProductCreationScoringSumLimitButton>
        {rubleSign}
      </ProductCreationScoringSumLimitRangeContainer>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
