import { Fragment } from "react";
import { getEmptyArray } from "@sideg/helpers";
import { DocumentUploadActiveDocumentSkeleton } from "../../../../document/upload";
import { AgencyDemandDocumentsContainer } from "./AgencyDemandDocumentsContainer";
import { LineSeparator } from "../../../../common/ui-kit/separators";

const SKELETONS_COUNT = 5;
const SKELETONS = getEmptyArray(SKELETONS_COUNT);

export const AgencyDemandDocumentsSkeleton = () => {
  return (
    <AgencyDemandDocumentsContainer>
      {SKELETONS.map((x, index) => (
        <Fragment key={x}>
          {index > 0 && <LineSeparator />}
          <DocumentUploadActiveDocumentSkeleton isWithTemplate={false} />
        </Fragment>
      ))}
    </AgencyDemandDocumentsContainer>
  );
};
