import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { isSucceeded } from "../../../../common/types/state";
import { selectCreditExecutionCreationCreating } from "../../store/creditExecutionCreation.selectors";
import { changeStageCreditExecutionCreation } from "../../store/creditExecutionCreation.slice";

export const useCreditExecutionCreationChangeScoringStageEffect = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectCreditExecutionCreationCreating);

  useEffect(() => {
    if (isSucceeded(status)) {
      dispatch(changeStageCreditExecutionCreation("scoring"));
    }
  }, [dispatch, status]);
};
