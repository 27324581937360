import { createSelector } from "@reduxjs/toolkit";
import { CreditWorkingAssetsTransferPayload } from "../../common/store";
import { RootState } from "../../../common/store";
import { CreditWorkingAssetsId } from "../../../common/types/demand";
import { selectCreditWorkingAssetsListItemsById } from "./items/creditWorkingAssetsListItems.selectors";
import { CreditWorkingAssetsDemandBase } from "../../common/types";

export const selectCreditWorkingAssetsList = (state: RootState) => state.domains.creditWorkingAssets.list;

const selectCreditWorkingAssetsListItems = (state: RootState) => selectCreditWorkingAssetsList(state).items;
export const selectCreditWorkingAssetsListTransferPayload = createSelector(
  [
    selectCreditWorkingAssetsListItems,
    (state: RootState, creditWorkingAssetsId: CreditWorkingAssetsId) =>
      selectCreditWorkingAssetsListItemsById(state, creditWorkingAssetsId),
  ],
  (items, product): CreditWorkingAssetsTransferPayload | undefined => {
    if (!product) {
      return undefined;
    }

    const { clientId, demandIds, ...productBase } = product;

    const client = items.clients.entities[clientId];
    if (!client) {
      return undefined;
    }

    const demands = demandIds
      .map((x) => items.demands.entities[x])
      .filter((x): x is CreditWorkingAssetsDemandBase => x !== undefined);

    return {
      product: {
        ...productBase,
        client,
      },
      demands,
    };
  },
);
