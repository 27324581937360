import { forwardRef } from "react";
import { BankGuaranteeDetailsDemandActiveTaskCommonProps } from "../../../types/demand/tasks/BankGuaranteeDetailsDemandTaskCommonProps";
import { useDemandQueueTaskActiveTaskCardComponent } from "../../../../../demand/queue-tasks/hooks/active/card/useDemandQueueTaskActiveTaskCardComponent";
import { BankGuaranteeDetailsDemandQueueTaskActive } from "../../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskActive";

export const BankGuaranteeDetailsActiveTaskCard = forwardRef<
  HTMLDivElement,
  BankGuaranteeDetailsDemandActiveTaskCommonProps
>(({ task, ...rest }, ref) => {
  const Component = useDemandQueueTaskActiveTaskCardComponent<BankGuaranteeDetailsDemandQueueTaskActive>(task);

  return <Component task={task} {...rest} ref={ref} />;
});
