import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { Box } from "../../../common/ui-kit/containers/box";
import { CheckIcon, Icon } from "../../../common/ui-kit/icons";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { Text } from "../../../common/ui-kit/typography";

export type BankGuaranteeExtractFromRegistryRequestedExtractProps = BaseCssProps;

export const BankGuaranteeExtractFromRegistryRequestedExtract = ({
  className,
}: BankGuaranteeExtractFromRegistryRequestedExtractProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Box className={className} display="flex" alignItems="center" gap="sm">
      <Icon as={CheckIcon} stroke="typography.success" />
      <Text
        fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}
        fontWeight={{ xs: 400, [desktopBreakpoint]: 500 }}
        fontColor="colors.secondary"
        lineHeight="defaultLineHeight"
      >
        Выписка из реестра БГ запрошена
      </Text>
    </Box>
  );
};
