import { useEffect, useMemo, useState } from "react";
import { MediaQueryListLegacy } from "@sideg/ui-kit/core/responsive/types/MediaQueryListLegacy";
import { Breakpoints, breakpoints } from "@sideg/ui-kit/common/types/responsive/Breakpoints";
import { useTheme } from "@sideg/ui-kit/core/theme/hooks/useTheme";
import { PrivateThemeBreakpoints } from "@sideg/ui-kit/core/theme/types/PrivateTheme";

type MatcherHandler = (event: MediaQueryListEvent) => void;

interface BreakpointMediaQueries {
  ids: Breakpoints[];
  queries: Record<Breakpoints, string>;
  values: Record<string, Breakpoints>;
}

const getActualSize = (mediaMatches: MediaQueryListLegacy[], mediaQueries: BreakpointMediaQueries) => {
  const matchedQuery = mediaMatches.find((match) => match.matches)?.media;

  return matchedQuery ? mediaQueries.values[matchedQuery] : undefined;
};

const getMediaQueries = (themeBreakpoints: PrivateThemeBreakpoints): BreakpointMediaQueries => {
  const ids = [...breakpoints].reverse();
  const values: BreakpointMediaQueries["values"] = {};
  const queries = ids.reduce(
    (accum, breakpoint) => {
      const mediaQuery = themeBreakpoints.up(breakpoint);
      accum[breakpoint] = mediaQuery;
      values[mediaQuery] = breakpoint;

      return accum;
    },
    {} as BreakpointMediaQueries["queries"],
  );

  return { ids, queries, values };
};

export const useActualSizeMediaQuery = (): Breakpoints | undefined => {
  const theme = useTheme();

  const mediaQueries: BreakpointMediaQueries = useMemo(() => getMediaQueries(theme.breakpoints), [theme.breakpoints]);
  const mediaMatches: MediaQueryListLegacy[] = useMemo(() => {
    // eslint-disable-next-line no-useless-call
    return mediaQueries.ids.map((breakpoint) => window.matchMedia.call(window, mediaQueries.queries[breakpoint]));
  }, [mediaQueries.ids, mediaQueries.queries]);

  const [actualSize, setActualSize] = useState<Breakpoints | undefined>(getActualSize(mediaMatches, mediaQueries));

  useEffect(() => {
    const handler: MatcherHandler = () => {
      setActualSize(getActualSize(mediaMatches, mediaQueries));
    };

    mediaMatches.forEach((match) => {
      if ("addEventListener" in match) {
        match.addEventListener("change", handler);
      } else {
        match.addListener(handler);
      }
    });

    return () => {
      mediaMatches.forEach((match) => {
        if ("removeEventListener" in match) {
          match.removeEventListener("change", handler);
        } else {
          match.removeListener(handler);
        }
      });
    };
  }, [mediaMatches, mediaQueries]);

  return actualSize;
};
