import { useMemo } from "react";
import { useAppSelector } from "../../store";
import { selectAuthorizedUser } from "../../authorized-user";
import { UserFeatureFlagPermission } from "../../types/user";

export const useIsAvailableFeatureFlagsFeature = (flag: UserFeatureFlagPermission): boolean => {
  const { user } = useAppSelector(selectAuthorizedUser);

  return useMemo(() => {
    return flag === true || user?.featureFlags.includes(flag) === true;
  }, [flag, user?.featureFlags]);
};
