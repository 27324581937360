import * as S from "./ProductListCardShowMoreButton.desktop.styled";
import { BaseCssProps } from "../../../../../../../ui-kit/types";
import { ArrowUpIcon } from "../../../../../../../ui-kit/icons";

export interface ProductCardShowMoreButtonDesktopProps extends BaseCssProps {
  onClick: () => void;
  isShow: boolean;
}

export const ProductListCardShowMoreButtonDesktop = ({
  onClick,
  isShow,
  className,
}: ProductCardShowMoreButtonDesktopProps) => {
  return (
    <S.Button type="button" onClick={onClick} isShow={isShow} className={className}>
      {!isShow && <ArrowUpIcon>↑</ArrowUpIcon>}
      {isShow && (
        <>
          <ArrowUpIcon /> <span>Посмотреть все заявки</span>
        </>
      )}
    </S.Button>
  );
};
