import { memo, useCallback } from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { BankGuaranteeListTabs } from "./BankGuaranteeListTabs";
import { useBankGuaranteeListFilterContext } from "../../../hooks/filter-context/useBankGuaranteeListFilterContext";
import { useBankGuaranteeListFilterApiContext } from "../../../hooks/filter-context/useBankGuaranteeListFilterApiContext";
import { FilterTabType, filterTabType, useFiltersTabCounterGetValue } from "../../../../../common/filters/use-cases";
import { selectBankGuaranteeListCounters } from "../../../store/counters/bankGuaranteeListCounters.selectors";

export type BankGuaranteeListTabFilterProps = BaseCssProps;

export const BankGuaranteeListTabFilter = memo(({ className }: BankGuaranteeListTabFilterProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const mutator = useBankGuaranteeListFilterApiContext();
  const getTabCounterValue = useFiltersTabCounterGetValue((state) => selectBankGuaranteeListCounters(state).data);

  const onTabChange = useCallback(
    (tab: FilterTabType) => {
      mutator(filteringObject, (filter, { setValues }) =>
        setValues(filter, {
          tab,
          status: tab === "Active" ? undefined : filteringObject.status,
          taskTypes: tab === "Completed" ? undefined : filteringObject.taskTypes,
        })
      );
    },
    [filteringObject, mutator]
  );

  return (
    <BankGuaranteeListTabs.Container activeTab={filteringObject.tab} onTabChange={onTabChange} className={className}>
      {filterTabType.values.map((x) => (
        <BankGuaranteeListTabs.Tab
          key={x}
          tab={x}
          text={filterTabType.getTitle(x)}
          xsFlexBasis="auto"
          counterValue={getTabCounterValue(x)}
        />
      ))}
    </BankGuaranteeListTabs.Container>
  );
});
