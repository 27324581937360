import { Color } from "../theme";

export const colorWithAlpha = (color: Color, amount: number) => {
  let prefix = "";
  let trimmedColor = color;

  if (color[0] === "#") {
    trimmedColor = color.slice(1);
    prefix = "#";
  }

  if (trimmedColor.length === 8) {
    trimmedColor = trimmedColor.slice(0, 6);
  }

  if (trimmedColor.length !== 6) {
    return color;
  }

  return `${prefix}${trimmedColor}${amount.toString(16).padStart(2, "0")}`;
};
