import styled from "@emotion/styled";

export const Article = styled.article`
  color: ${({ theme }) => theme.layout.sidebar.newsWidget.color};
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz2};
  padding: 10px;
  font-feature-settings: "tnum" on, "lnum" on;

  & > a {
    line-height: 24px;
    font-weight: bold;
    display: block;
    margin-bottom: 3px;
    color: currentColor;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
  }

  & > p {
    display: block;
    line-height: 20px;
    margin-bottom: 40px;
  }

  & > time {
    position: absolute;
    bottom: 12px;
    left: 10px;
    opacity: 0.4;
  }
`;
