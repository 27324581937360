import { createApiThunks } from "../../store/utils/createApiThunk";
import { webPushesApi } from "../api/webPushesApi";

export const SLICE_NAME = "webPushes";

export const {
  subscribe: subscribeWebPushes,
  unsubscribe: unsubscribeWebPushes,
  checkSubscription: checkSubscriptionWebPushes,
} = createApiThunks(SLICE_NAME, webPushesApi);
