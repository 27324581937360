import { BaseCssProps } from "../../../common/ui-kit/types";
import { BankGuaranteeCalculatorResultTable } from "./BankGuaranteeCalculatorResultTable";
import { BankGuaranteeCalculatorBankResult } from "../types/BankGuaranteeCalculatorBankResult";
import { CreationNote } from "../../../common/ui-kit/notifications/note";
import { Box } from "../../../common/ui-kit/containers/box";
import { ButtonLinkWithIcon } from "../../../common/ui-kit/buttons/link-with-icon";
import { ChevronLeftIcon } from "../../../common/ui-kit/icons";
import { BankGuaranteeCalculatorFormValues } from "../types/BankGuaranteeCalculatorFormValues";
import { AppLinkButton } from "../../../common/ui/links";

export interface BankGuaranteeCalculatorResultProps extends BaseCssProps {
  values: BankGuaranteeCalculatorBankResult[];
  onBackClick: () => void;
  formValues: BankGuaranteeCalculatorFormValues;
}

export const BankGuaranteeCalculatorResult = ({
  values,
  className,
  onBackClick,
  formValues,
}: BankGuaranteeCalculatorResultProps) => {
  return (
    <Box display="flex" flexDirection="column" gap="lg" flexShrink={1} flexGrow={1} className={className}>
      <CreationNote as="p" fontSize="fz3">
        Приведённый расчёт стоимости банковской гарантии является предварительным и не является публичной офертой,
        возможны СКИДКИ!
      </CreationNote>
      <BankGuaranteeCalculatorResultTable values={values} />
      <Box display="flex" gap="sm" alignItems="center" justifyContent="space-between">
        <ButtonLinkWithIcon type="button" onClick={onBackClick} icon={ChevronLeftIcon}>
          Вернуться к форме
        </ButtonLinkWithIcon>
        <AppLinkButton
          variant="contained"
          color="primary"
          to={(x) => x.bankGuarantee.creation.create}
          search={formValues}
        >
          Создать заявку
        </AppLinkButton>
      </Box>
    </Box>
  );
};
