import { BankGuaranteeDetailsDemandHeaderTabsContainer } from "./tabs/BankGuaranteeDetailsDemandHeaderTabsContainer";
import { BankGuaranteeDetailsDemandHeaderTab } from "../../../types/demand/BankGuaranteeDetailsDemandHeaderTab";
import { useBankGuaranteeDetailsLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsDemandById } from "../../../store/demands/bankGuaranteeDetailsDemands.selectors";
import { usePageTitle } from "../../../../../common/navigation/page-title";
import { isDemandApproved } from "../../../utils/demand/isDemandApproved";
import { BankGuaranteeDetailsDemandHeaderIssue } from "./issue/BankGuaranteeDetailsDemandHeaderIssue";
import { BankGuaranteeDetailsDemandHeaderActive } from "./active/BankGuaranteeDetailsDemandHeaderActive";
import { ProductDetailsContentHeaderLayout } from "../../../../../common/product-details";

export interface BankGuaranteeDetailsDemandHeaderProps {
  className?: string;
  tabs: BankGuaranteeDetailsDemandHeaderTab[];
  activeTab: BankGuaranteeDetailsDemandHeaderTab;
  onTabClick: (tab: BankGuaranteeDetailsDemandHeaderTab) => void | Promise<void>;
}

export const BankGuaranteeDetailsDemandHeader = ({
  className,
  tabs,
  activeTab,
  onTabClick,
}: BankGuaranteeDetailsDemandHeaderProps) => {
  const { guaranteeId, demandId } = useBankGuaranteeDetailsLayoutContext();
  const demand = useAppSelector((state) => selectBankGuaranteeDetailsDemandById(state, demandId ?? 0));

  usePageTitle(`Заявка ${guaranteeId} ${demand?.bank.title ? `в ${demand?.bank.title}` : ""}`);

  return (
    <ProductDetailsContentHeaderLayout ignorePaddingX as="header" className={className}>
      {isDemandApproved(demand) && <BankGuaranteeDetailsDemandHeaderIssue demand={demand} guaranteeId={guaranteeId} />}
      {!isDemandApproved(demand) && (
        <BankGuaranteeDetailsDemandHeaderActive demand={demand} guaranteeId={guaranteeId} />
      )}
      <BankGuaranteeDetailsDemandHeaderTabsContainer tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />
    </ProductDetailsContentHeaderLayout>
  );
};
