import { useCallback } from "react";
import { useField } from "formik";
import { DemandQueueTasksActiveRequestedDocument } from "../../../types/active/DemandQueueTasksActiveRequestedDocument";
import { useEffectOnce } from "../../../../../common/hooks";
import { DemandQueueTaskActiveDocumentFormValues } from "../../../types/active-form/DemandQueueTaskActiveDocumentFormValues";
import { FileTemp, FileTempId, generateFileTempId } from "../../../../../files/temp";
import { getDocumentMinLengthDemandQueueTasks } from "../../../utils/getDocumentMinLengthDemandQueueTasks";
import { getFilesErrorDemandQueueTasks } from "../../../utils/getFilesErrorDemandQueueTasks";
import { isMultipleDocumentUploadRequestedDocument } from "../../../../../document/upload";

export const useDemandQueueTaskRequestedDocument = (document: DemandQueueTasksActiveRequestedDocument) => {
  const isMultiple = isMultipleDocumentUploadRequestedDocument(document);
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<
    DemandQueueTaskActiveDocumentFormValues | undefined
  >(`documents.d${document.id}${document.requestedDocType}`);

  const getValue = useCallback(
    (files: FileTemp[]): DemandQueueTaskActiveDocumentFormValues => ({
      id: document.id,
      requestDocType: document.requestedDocType,
      minLength: getDocumentMinLengthDemandQueueTasks(document, files),
      files,
    }),
    [document],
  );

  useEffectOnce(() => {
    if (!value) {
      setValue(getValue([]), true);
    }
  });

  const handleDrop = useCallback(
    async (droppedFiles: File[]) => {
      if (droppedFiles.length === 0 || (!isMultiple && droppedFiles.length > 1)) {
        return;
      }

      const attachedFiles = [
        ...((isMultiple ? value?.files : undefined) ?? []),
        ...droppedFiles.map((x): FileTemp => ({ id: generateFileTempId(), dateAdded: new Date(), file: x })),
      ];

      // Важен порядок. В противном случае будет некорректная ошибка валидации
      await setTouched(true);
      await setValue(getValue(attachedFiles), true);
    },
    [getValue, isMultiple, setTouched, setValue, value?.files],
  );

  const onFileDelete = useCallback(
    async (id: FileTempId) => {
      const files = (value?.files ?? []).filter((x) => x.id !== id);
      await setValue(getValue(files));
    },
    [getValue, setValue, value?.files],
  );

  return {
    files: value?.files ?? ([] as FileTemp[]),
    handleDrop,
    isMultiple,
    error: getFilesErrorDemandQueueTasks(error, touched),
    onFileDelete,
  };
};
