import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditWorkingAssetsListItemCardMobile } from "./card/CreditWorkingAssetsListItemCard.mobile";
import { ProductListCardFooterDemandLinkMobileSkeleton } from "../../../../../common/ui/product/list/card";
import { CreditWorkingAssetsListItemStageCountersSkeleton } from "../counters/CreditWorkingAssetsListItemStageCounters.skeleton";
import { CreditWorkingAssetsCommonDetailsSkeleton } from "../../../../common/components";

export type CreditWorkingAssetsListItemMobileSkeletonProps = BaseCssProps;

export const CreditWorkingAssetsListItemMobileSkeleton = ({
  className,
}: CreditWorkingAssetsListItemMobileSkeletonProps) => {
  return (
    <CreditWorkingAssetsListItemCardMobile
      className={className}
      numberAndDate={<Skeleton width={120} />}
      statusBadge={<Skeleton width={60} borderRadius={16} />}
      actionButton={<Skeleton width={28} height={28} />}
      clientName={<Skeleton width={220} />}
      details={<CreditWorkingAssetsCommonDetailsSkeleton />}
      banksLabel={<Skeleton inline width={90} />}
      counters={<CreditWorkingAssetsListItemStageCountersSkeleton />}
      footer={<ProductListCardFooterDemandLinkMobileSkeleton />}
    />
  );
};
