import styled from "@emotion/styled";
import { ElementColor, getElementColor, SpaceType } from "../../../types";

export const DotSeparatedRowContainer = styled.div<{ dotColor?: ElementColor; gap?: SpaceType }>`
  & > * + *:not(:empty)::before {
    content: "•";
    display: inline-block;
    margin: 0 ${({ theme, gap = "sm" }) => theme.spaces[gap]};
    color: ${({ theme, dotColor }) => getElementColor(dotColor, theme)};
  }
`;
