import {
  BankGuaranteeRequestOriginalLabel,
  BankGuaranteeRequestOriginalLabelProps,
} from "./BankGuaranteeRequestOriginalLabel";
import { BankGuaranteeId, DemandId } from "../../../../common/types/demand";
import { NavigationState } from "../../../../common/navigation/navigation-state";
import * as S from "./BankGuaranteeRequestOriginalLabelLink.styled";

export interface BankGuaranteeRequestOriginalLabelLinkProps extends BankGuaranteeRequestOriginalLabelProps {
  demandId: DemandId;
  guaranteeId: BankGuaranteeId;
  from?: NavigationState;
  onClick?: () => void;
}

export const BankGuaranteeRequestOriginalLabelLink = ({
  demandId,
  requestOriginalType,
  guaranteeId,
  from,
  className,
  isWithNotification,
  onClick,
}: BankGuaranteeRequestOriginalLabelLinkProps) => {
  return (
    <S.Link
      to={(x) => x.bankGuarantee.details.requestOriginal}
      params={{ demandId, guaranteeId }}
      state={from}
      className={className}
      onClick={onClick}
    >
      <BankGuaranteeRequestOriginalLabel
        isWithNotification={isWithNotification}
        requestOriginalType={requestOriginalType}
      />
    </S.Link>
  );
};
