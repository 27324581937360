import { EnumLikeLiteralType, makeEnumLikeLiteral, makeFormEnumLikeLiteral, UNSELECTED_ENUM_LITERAL } from "../utils";

const titles = {
  EXEC: "БГ на обеспечение исполнения контракта",
  GARANT: "БГ на обеспечение гарантийных обязательств",
  PART: "БГ на участие",
  RET: "БГ на возврат аванса",
  PAYMENT: "Гарантия платежа",
};

const types = ["EXEC", "GARANT", "PART", "RET", "PAYMENT"] as const;

export const guaranteeTypeCode = makeEnumLikeLiteral(types, titles);

const formTitles = {
  EXEC: "Исполнение контракта",
  GARANT: "Гарантийные обязательства",
  PART: "Участие",
  RET: "Возврат аванса",
  PAYMENT: "Гарантия платежа",
  [UNSELECTED_ENUM_LITERAL]: "Не выбрано",
};

export const guaranteeTypeCodeForm = makeFormEnumLikeLiteral(types, formTitles);

export type GuaranteeTypeCode = EnumLikeLiteralType<typeof guaranteeTypeCode>;
