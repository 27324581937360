import { UserFeatureFlagEnum } from "../../../common/types/user";
import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { REPORTS_ROOT_URL } from "../../common/urls";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const reportsDemandUrls = {
  root: makeAppUrl(
    joinUrlParts(REPORTS_ROOT_URL, "demands"),
    "/reports/demands",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsReportDemands),
  ),
  /**
   * @deprecated
   */
  rootLegacy: makeAppUrl(
    "/app-report",
    "/app-report",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsReportDemands),
  ),
};
