import { ThemeMobileDesktopView, useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { PlusIcon } from "../../../../../common/ui-kit/icons";
import { Button } from "../../../../../common/ui-kit/form-controls/button";
import { FilterDropdownButton } from "../../../../../common/ui-kit/form-controls/select";
import { DemandQueueTasksNewCommentButtonTransition } from "../../../../../demand/queue-tasks/components/new-comment/DemandQueueTasksNewCommentButtonTransition";
import { DemandQueueTasksNewCommentTransition } from "../../../../../demand/queue-tasks/components/new-comment/DemandQueueTasksNewCommentTransition";
import {
  BankGuaranteeDetailsDemandNewCommentCardForm,
  BankGuaranteeDetailsDemandNewCommentCardFormProps,
} from "./form/BankGuaranteeDetailsDemandNewCommentCardForm";
import { bankGuaranteeDetailsDemandNewCommentGroupType } from "../../../types/demand/new-comment/bankGuaranteeNewMessageGroupType";
import { useBankGuaranteeDetailsFilterContext } from "../../../hooks/filters/context/useBankGuaranteeDetailsFilterContext";
import { useBankGuaranteeDetailsFilterApiContext } from "../../../hooks/filters/context/useBankGuaranteeDetailsFilterApiContext";
import { GuaranteeQueueTasksGroupType } from "../../../../../common/types/queue-tasks";
import { BankGuaranteeProjectBillStatusType } from "../../../../common/types";
import * as S from "./BankGuaranteeDetailsDemandNewCommentContent.styled";

const TIMEOUT = 400;

const EmptySpace = "div";

const getFilterSelectTitle = (groupType: GuaranteeQueueTasksGroupType | undefined | null) => {
  if (groupType === "All" || groupType === undefined || groupType === null) {
    return "Все сообщения";
  }

  return bankGuaranteeDetailsDemandNewCommentGroupType.getTitle(groupType);
};

export interface BankGuaranteeDetailsDemandNewCommentContentProps
  extends BaseCssProps,
    BankGuaranteeDetailsDemandNewCommentCardFormProps {
  canSendComment: boolean;
  isCommentFormOpened: boolean;
  onSendCommentButtonClick: () => void;
  projectBillProcess: BankGuaranteeProjectBillStatusType | undefined;
}

export const BankGuaranteeDetailsDemandNewCommentContent = ({
  canSendComment,
  className,
  onCancel,
  onSubmit,
  isCommentFormOpened,
  onSendCommentButtonClick,
  projectBillProcess,
}: BankGuaranteeDetailsDemandNewCommentContentProps) => {
  const context = useBankGuaranteeDetailsFilterContext();
  const mutator = useBankGuaranteeDetailsFilterApiContext();

  const isProjectBillConfirmedWithHistory = projectBillProcess === "ConfirmedWithHistory";
  const hasProjectBillHistory = projectBillProcess !== "Legacy" && projectBillProcess !== undefined;

  const isCommentFormNotOpenedAndCanSend = !isCommentFormOpened && canSendComment;
  const isDesktop = useThemeIsDesktop();

  return (
    <S.Container className={className}>
      <S.ButtonsContainer>
        {hasProjectBillHistory && (
          <FilterDropdownButton
            items={bankGuaranteeDetailsDemandNewCommentGroupType.values}
            selected={context.queueTaskGroup}
            getItemTitle={(x) => getFilterSelectTitle(x)}
            onSelectedChanged={(x) => {
              mutator(context, (service) => service.changeGroupType(x));
            }}
            text={getFilterSelectTitle(context.queueTaskGroup)}
            buttonSize={isDesktop ? "small" : "medium"}
            selectTextSize={isDesktop ? "fz2" : "fz3"}
            iconPosition="right"
          />
        )}
        {!hasProjectBillHistory && <EmptySpace />}
        <ThemeMobileDesktopView
          mobile={
            <>
              {isCommentFormNotOpenedAndCanSend && (
                <Button
                  variant="contained"
                  color="light"
                  startIcon={PlusIcon}
                  onClick={onSendCommentButtonClick}
                  type="button"
                  size="default"
                >
                  Написать
                </Button>
              )}
              {!isCommentFormNotOpenedAndCanSend && <EmptySpace />}
            </>
          }
          desktop={
            <>
              {isCommentFormNotOpenedAndCanSend && (
                <DemandQueueTasksNewCommentButtonTransition
                  timeout={isCommentFormOpened ? TIMEOUT - 100 : TIMEOUT}
                  in={isCommentFormNotOpenedAndCanSend}
                  mountOnEnter
                  unmountOnExit
                >
                  <Button
                    alignSelf="flex-end"
                    startIcon={PlusIcon}
                    variant="contained"
                    color="light"
                    onClick={onSendCommentButtonClick}
                    type="button"
                    size="small"
                  >
                    Написать новое сообщение
                  </Button>
                </DemandQueueTasksNewCommentButtonTransition>
              )}
              {!isCommentFormNotOpenedAndCanSend && <EmptySpace />}
            </>
          }
        />
      </S.ButtonsContainer>
      <DemandQueueTasksNewCommentTransition
        timeout={TIMEOUT}
        in={canSendComment && isCommentFormOpened}
        unmountOnExit
        mountOnEnter
      >
        <BankGuaranteeDetailsDemandNewCommentCardForm
          onSubmit={onSubmit}
          onCancel={onCancel}
          isNeedFilters={isProjectBillConfirmedWithHistory}
        />
      </DemandQueueTasksNewCommentTransition>
    </S.Container>
  );
};
