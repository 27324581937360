import { GetQueueTasksListFilterOutputDto } from "../api/dto/output/GetQueueTasksListFilterOutputDto";
import { QueueTasksListFilters } from "../types/filter";
import { getEntitiesCodeWithTitle } from "../../../common/types/objects";

const mapFilters = (filter: GetQueueTasksListFilterOutputDto): QueueTasksListFilters => {
  return {
    banks: getEntitiesCodeWithTitle(filter.banks),
    taskTypes: getEntitiesCodeWithTitle(filter.taskTypes),
    products: getEntitiesCodeWithTitle(filter.products),
    productBanks: filter.productBanks,
  };
};

export const queueTasksListMapper = {
  mapFilters,
};
