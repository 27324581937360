import { BankGuaranteeId, DemandId } from "../../../../../common/types/demand";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsDemandById } from "../../../store/demands/bankGuaranteeDetailsDemands.selectors";
import { TextProps } from "../../../../../common/ui-kit/typography";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { BankGuaranteeDetailsDemand } from "../../../types/demand/BankGuaranteeDetailsDemand";
import * as S from "./BankGuaranteeDetailsDemandsListItem.styled";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeDemandStageIcon } from "../../../../common/components";
import { CircleIndicator } from "../../../../../common/ui-kit/notifications/circle-indicator";
import { useAppUrlMatch } from "../../../../../common/urls/internal/hooks";
import {
  ProductDetailsAsideNavigationCard,
  ProductDetailsAsideNavigationCardCounter,
} from "../../../../../common/product-details";

const getStatusColor = (demand: BankGuaranteeDetailsDemand): TextProps["fontColor"] => {
  if (demand.countActiveTasks > 0) {
    return "colors.primary";
  }

  if (demand.status.code === "Approved") {
    return "success";
  }

  if (!demand.isActive) {
    return "medium";
  }

  return "colors.dark";
};

export interface BankGuaranteeDetailsDemandsListItemProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  className?: string;
}

export const BankGuaranteeDetailsDemandsListItem = ({
  guaranteeId,
  demandId,
  className,
}: BankGuaranteeDetailsDemandsListItemProps) => {
  const demand = useAppSelector((state) => selectBankGuaranteeDetailsDemandById(state, demandId));
  const { path, isExact } = useAppUrlMatch((x) => x.bankGuarantee.details.demand, {
    guaranteeId,
    demandId,
  });

  if (!demand) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  return (
    <ProductDetailsAsideNavigationCard
      className={className}
      to={path}
      isActive={isExact}
      isSecondary={demand.isTerminated}
    >
      <Box
        gap="sm"
        display="flex"
        flexDirection="column"
        flexShrink={1}
        flexGrow={1}
        justifyContent="space-between"
        overflow="hidden"
      >
        <Box gap="xs" display="flex" flexDirection="row" alignItems="center">
          <S.BankName stage={demand.stage.code}>{demand.bank.title}</S.BankName>
          {demand.isUpdated && <CircleIndicator color={(theme) => theme.colors.primary} size={4} />}
        </Box>
        <StackPanel direction="row" gap="xs" alignItems="center">
          <BankGuaranteeDemandStageIcon stage={demand.stage.code} />
          <S.StatusWithCount
            as="div"
            lineHeight="1.5"
            fontWeight={500}
            fontSize="fz2"
            fontColor={getStatusColor(demand)}
          >
            <S.Status>{demand.status.title}</S.Status>{" "}
            {demand.countActiveTasks > 1 && <S.TaskCounter>+{demand.countActiveTasks - 1}</S.TaskCounter>}
          </S.StatusWithCount>
        </StackPanel>
      </Box>
      {demand.countMissingDocuments !== undefined && demand.countMissingDocuments > 0 && (
        <ProductDetailsAsideNavigationCardCounter variant="info">
          {demand.countMissingDocuments}
        </ProductDetailsAsideNavigationCardCounter>
      )}
    </ProductDetailsAsideNavigationCard>
  );
};
