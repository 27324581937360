import { useState } from "react";
import { QueueTasksListFilterParams } from "../../../types/filter";
import {
  selectQueueTasksListFilterBanksCodes,
  selectQueueTasksListFiltersMaxLength,
} from "../../../store/queueTasksList.selectors";
import { useQueueTasksListFilterArray } from "../../../hooks/useQueueTasksListFilterArray";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../../common/store";
import { FilterMultipleCombobox } from "../../../../../common/ui-kit/form-controls/select";

export interface QueueTasksListBanksFilterProps extends BaseCssProps {
  filteringObject: QueueTasksListFilterParams;
}

export const QueueTasksListBanksFilter = ({ filteringObject, className }: QueueTasksListBanksFilterProps) => {
  const [inputValue, setInputValue] = useState("");

  const { getItemTitle, selectedItems, getItems, onSelectedChange } = useQueueTasksListFilterArray({
    filteringObject,
    key: "banks",
    itemsSelector: (state) => selectQueueTasksListFilterBanksCodes(state, inputValue),
    getTitleFunc: (filters) => (item) => filters?.banks.titles[item] ?? item,
  });

  const maxItemsLength = useAppSelector((state) => selectQueueTasksListFiltersMaxLength(state, "banks"));

  return (
    <FilterMultipleCombobox
      text="Банки"
      selectedItems={selectedItems}
      getItemTitle={getItemTitle}
      onSelectedChanged={onSelectedChange}
      onSearchChanged={setInputValue}
      searchPlaceholder="Поиска банка"
      getItems={getItems}
      className={className}
      maxItemsLength={maxItemsLength}
    />
  );
};
