import { useGetFieldName } from "../../../../../common/form";
import { PurchaseCreationField } from "../../../../../common/purchase";
import { useCreditExecutionCreationPurchaseMutator } from "../../../hooks/creation/purchase/useCreditExecutionCreationPurchaseMutator";
import { selectCreditExecutionCreationPurchase } from "../../../store/creditExecutionCreation.selectors";
import { CreditExecutionCreationFormValues } from "../../../types/creation/CreditExecutionCreationFormValues";

export interface CreditExecutionCreationPurchaseFieldProps {
  isDisabled?: boolean;
}

export const CreditExecutionCreationPurchaseField = ({ isDisabled }: CreditExecutionCreationPurchaseFieldProps) => {
  const getFieldName = useGetFieldName<CreditExecutionCreationFormValues>();
  const formMutator = useCreditExecutionCreationPurchaseMutator();

  return (
    <PurchaseCreationField
      isDisabled={isDisabled}
      fixedKey="CreditExecution"
      purchaseStateSelector={selectCreditExecutionCreationPurchase}
      formMutator={formMutator}
      name={getFieldName("purchaseNumber")}
    />
  );
};
