import Skeleton from "react-loading-skeleton";
import { getEmptyArray } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { Text } from "../../../../../common/ui-kit/typography";
import { Box } from "../../../../../common/ui-kit/containers/box";

export interface DocumentUploadCommonBankDocumentCardBanksSkeletonProps extends BaseCssProps {
  banksCount: number;
}

export const DocumentUploadCommonBankDocumentCardBanksSkeleton = ({
  banksCount,
  className,
}: DocumentUploadCommonBankDocumentCardBanksSkeletonProps) => {
  return (
    <StackPanel direction="column" gap="xs" className={className}>
      <Text>
        <Skeleton inline width={170} />
      </Text>
      <Box display="inline-flex" gap="sm" flexDirection="row">
        {getEmptyArray(banksCount).map((x) => (
          <Skeleton key={x} width={60} />
        ))}
      </Box>
    </StackPanel>
  );
};
