import styled from "@emotion/styled";
import { QueueTasksListSuggestions } from "./QueueTasksListSuggestions";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";

export const Suggestions = styled(QueueTasksListSuggestions)`
  margin-top: ${({ theme }) => theme.spaces.xxl};
  padding-top: ${({ theme }) => theme.spaces.xxl};
`;

export const Container = styled(AppLayoutResponsiveListPage.List)`
  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding-left: calc(${({ theme }) => theme.layout.borderPadding} + 40px);
  }
`;
