import Skeleton from "react-loading-skeleton";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useQueueTasksListItemSkeleton } from "../../../hooks/list-item/useQueueTasksListItemSkeleton";
import { QueueTasksListItemCard } from "../card/QueueTasksListItemCard";

export type BankGuaranteeListItemDesktopSkeletonProps = BaseCssProps;

export const QueueTasksListItemSkeleton = ({ className }: BankGuaranteeListItemDesktopSkeletonProps) => {
  const skeletonWidths = useQueueTasksListItemSkeleton();

  return (
    <QueueTasksListItemCard
      className={className}
      title={<Skeleton width={skeletonWidths.title} />}
      details={<Skeleton width={skeletonWidths.details} />}
      footer={
        <ThemeMobileDesktopView
          mobile={
            <Box display="flex" alignItems="center" justifyContent="center" pt="md">
              <Skeleton width={skeletonWidths.footer} />
            </Box>
          }
          desktop={<Skeleton width={skeletonWidths.footer} />}
        />
      }
    >
      <Box display="flex" flexDirection="column" alignItems="start">
        {skeletonWidths.children.map((width, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton key={index} width={width} />
        ))}
      </Box>
    </QueueTasksListItemCard>
  );
};
