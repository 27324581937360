import FileModel from "../../../models/FileModel";
import "./AttachmentLink.scss";
import processGettingFile from "../../common/file-link/processGettingFile";

interface AttachmentLinkProps {
  file: FileModel;
}

const AttachmentLink = ({ file }: AttachmentLinkProps) => {
  return (
    <button
      title={file.fileName}
      className="attachment-link"
      onClick={() => processGettingFile(file.fileId, file.privateKey)}
    >
      <span className="attachment-link__wrapper">
        <i className="far fa-paperclip attachment-link__icon" />
        {file.fileName}
      </span>
    </button>
  );
};

export default AttachmentLink;
