import * as React from "react";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import {
  ProductListCardContainerMobile,
  ProductListCardItemStageCountersWithBanksLabelContainerMobile,
} from "../../../../../../common/ui/product/list/card";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { Text } from "../../../../../../common/ui-kit/typography";
import { StackPanel } from "../../../../../../common/ui-kit/containers/stack-panel";

export interface CreditWorkingAssetsListItemCardMobileProps extends BaseCssProps {
  numberAndDate: React.ReactNode;
  statusBadge: React.ReactNode;
  actionButton: React.ReactNode;
  clientName: React.ReactNode;
  details: React.ReactNode;
  banksLabel: React.ReactNode;
  counters: React.ReactNode;
  footer: React.ReactNode;
}

export const CreditWorkingAssetsListItemCardMobile = ({
  numberAndDate,
  banksLabel,
  footer,
  actionButton,
  clientName,
  details,
  statusBadge,
  counters,
  className,
}: CreditWorkingAssetsListItemCardMobileProps) => {
  return (
    <ProductListCardContainerMobile
      header={
        <>
          <StackPanel gap="xs" direction="row" alignItems="center" justifyContent="space-between">
            <Text as="h3" fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight">
              {numberAndDate}
            </Text>
            <StackPanel gap="md" direction="row" alignItems="center">
              {statusBadge}
              {actionButton}
            </StackPanel>
          </StackPanel>
          <Box display="flex" gap="sm" flexDirection="column" pb="md">
            <Text fontSize="fz4" lineHeight="defaultLineHeight" fontWeight={700}>
              {clientName}
            </Text>
            {details}
          </Box>
        </>
      }
      footer={footer}
      className={className}
    >
      <ProductListCardItemStageCountersWithBanksLabelContainerMobile banksLabel={banksLabel}>
        {counters}
      </ProductListCardItemStageCountersWithBanksLabelContainerMobile>
    </ProductListCardContainerMobile>
  );
};
