import { useState } from "react";
import { useCreditExecutionListFilterContext } from "../../../../hooks/filters/useCreditExecutionListFilterContext";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useProductFilterArray } from "../../../../../../common/filters/use-cases";
import { useAppSelector } from "../../../../../../common/store";
import {
  selectCreditExecutionFiltersData,
  selectCreditExecutionListFiltersBanksCodes,
} from "../../../../store/filters/creditExecutionListFilters.selectors";
import { FilterMultipleCombobox } from "../../../../../../common/ui-kit/form-controls/select";

export type CreditExecutionListHeaderFiltersBanksDesktopProps = BaseCssProps;

export const CreditExecutionListHeaderFiltersBanksDesktop = ({
  className,
}: CreditExecutionListHeaderFiltersBanksDesktopProps) => {
  const filteringObject = useCreditExecutionListFilterContext();
  const [inputValue, setInputValue] = useState("");

  const filteredBanks = useAppSelector((state) => selectCreditExecutionListFiltersBanksCodes(state, inputValue));

  const { getItemTitle, selectedItems, getItems, onSelectedChange, maxItemLength } = useProductFilterArray({
    filteringObject,
    key: "bankIds",
    availableFiltersSelector: selectCreditExecutionFiltersData,
    customFilteredItems: filteredBanks,
  });

  return (
    <FilterMultipleCombobox
      text="Банки"
      selectedItems={selectedItems}
      getItemTitle={getItemTitle}
      onSelectedChanged={onSelectedChange}
      onSearchChanged={setInputValue}
      searchPlaceholder="Поиск банка"
      getItems={getItems}
      className={className}
      maxItemsLength={maxItemLength}
    />
  );
};
