import { CreditWorkingAssetsListItemsState } from "./creditWorkingAssetsListItems.types";
import { RootState } from "../../../../common/store";
import {
  creditWorkingAssetsListItemsAdapter,
  creditWorkingAssetsListItemsClientsAdapter,
  creditWorkingAssetsListItemsDemandsAdapter,
} from "./creditWorkingAssetsListItems.adapters";

export const selectCreditWorkingAssetsListItems = (state: RootState): CreditWorkingAssetsListItemsState =>
  state.domains.creditWorkingAssets.list.items;

const selectField =
  <TKey extends keyof CreditWorkingAssetsListItemsState>(
    key: TKey
  ): ((state: RootState) => CreditWorkingAssetsListItemsState[TKey]) =>
  (state) =>
    selectCreditWorkingAssetsListItems(state)[key];

export const { selectIds: selectCreditWorkingAssetsListItemsIds, selectById: selectCreditWorkingAssetsListItemsById } =
  creditWorkingAssetsListItemsAdapter.getSelectors(selectField("creditWorkingAssets"));

export const { selectById: selectCreditWorkingAssetsListItemsDemandById } =
  creditWorkingAssetsListItemsDemandsAdapter.getSelectors(selectField("demands"));

export const { selectById: selectCreditWorkingAssetsListItemsClientById } =
  creditWorkingAssetsListItemsClientsAdapter.getSelectors(selectField("clients"));
