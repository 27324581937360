import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { FullPageContainerHeaderContainer } from "../../../../../common/ui/containers/full-page";
import { BankGuaranteeCreationCreateByGuaranteeHeaderMobile } from "./BankGuaranteeCreationCreateByGuaranteeHeader.mobile";
import { BankGuaranteeId } from "../../../../../common/types/demand";
import { NavigationStateFrom } from "../../../../../common/navigation/navigation-state";
import { BankGuaranteeCreationCreateByGuaranteePageType } from "../../../types/creation/BankGuaranteeCreationCreateByGuaranteePageType";
import { BankGuaranteeCreationCreateByGuaranteeHeaderDesktop } from "./BankGuaranteeCreationCreateByGuaranteeHeader.desktop";

export interface BankGuaranteeCreationCreateByGuaranteeHeaderProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  from: NavigationStateFrom;
  pageType: BankGuaranteeCreationCreateByGuaranteePageType;
}

export const BankGuaranteeCreationCreateByGuaranteeHeader = ({
  guaranteeId,
  pageType,
  from,
  className,
}: BankGuaranteeCreationCreateByGuaranteeHeaderProps) => {
  return (
    <FullPageContainerHeaderContainer className={className}>
      <ThemeMobileDesktopView
        mobile={
          <BankGuaranteeCreationCreateByGuaranteeHeaderMobile
            guaranteeId={guaranteeId}
            pageType={pageType}
            from={from}
          />
        }
        desktop={<BankGuaranteeCreationCreateByGuaranteeHeaderDesktop from={from} />}
      />
    </FullPageContainerHeaderContainer>
  );
};
