import { createContext } from "react";
import { TabsContextType } from "../types/TabsContextType";

export const createTabsContext = <TTab extends string>() =>
  createContext<TabsContextType<TTab>>({
    activeTab: "" as TTab,
    onTabChange: () => {
      throw new Error("Попытка создать контекст со значением по умолчанию");
    },
  });
