import { createApiThunks } from "../../common/store/utils/createApiThunk";
import { clientSearchApi } from "../api/clientSearchApi";

export const CLIENT_SEARCH_SLICE_NAME = "searchClient";

export const {
  find: findSearchClient,
  check: checkSearchClient,
  getName: getNameClientSearch,
} = createApiThunks(CLIENT_SEARCH_SLICE_NAME, clientSearchApi);
