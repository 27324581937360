import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/store";
import { ClientId } from "../../common/types/demand";
import { SearchClientChecked } from "./SearchClientChecked";
import { SearchClientInput } from "./input/SearchClientInput";
import { SimpleOpacityTransition } from "../../common/ui-kit/animations/transitions";
import { StackPanel } from "../../common/ui-kit/containers/stack-panel";
import { ClientSearchClientStateSelector } from "../types/ClientSearchClientStateSelector";
import { ClientSearchFixedKey } from "../types/ClientSearchFixedKey";
import { removeCheckedClientSearch } from "../store/clientSearch.store";
import { SearchClientCheckAlert } from "./alerts/SearchClientCheckAlert";
import { useSearchClientInputValue } from "../hooks/useSearchClientInputValue";
import { useSearchClientInputFindEffect } from "../hooks/useSearchClientInputFindEffect";
import { isLoading } from "../../common/types/state";

export interface ClientSearchProps {
  canRemove: boolean;
  isWithAddress?: boolean;
  isWithPassingAlert?: boolean;
  initialSearchValue?: string;
  clientStateSelector: ClientSearchClientStateSelector;
  fixedKey: ClientSearchFixedKey;
}

export const ClientSearch = ({
  canRemove,
  clientStateSelector,
  fixedKey,
  initialSearchValue,
  isWithAddress,
  isWithPassingAlert,
}: ClientSearchProps) => {
  const dispatch = useAppDispatch();
  const { check } = useAppSelector(clientStateSelector);
  const [selectedClientId, setSelectedClientId] = useState<ClientId | undefined>(check?.data?.id);

  const { value, debounceValue, onPaste, onChange, setValues } = useSearchClientInputValue(initialSearchValue ?? "");

  useSearchClientInputFindEffect(debounceValue, fixedKey);

  useEffect(() => {
    setSelectedClientId(check?.data?.id);
    if (check?.data?.id) {
      setValues(undefined);
    }
  }, [check?.data?.id, setValues]);

  const onRemove = () => {
    dispatch(removeCheckedClientSearch({ key: fixedKey }));
    setSelectedClientId(undefined);
    setValues("");
  };

  const handleClean = () => setValues("");

  return (
    <StackPanel direction="column" gap="md">
      <SimpleOpacityTransition timeout={200} in={selectedClientId !== undefined}>
        <StackPanel direction="column" gap="md">
          {selectedClientId && (
            <>
              <SearchClientChecked
                clientId={selectedClientId}
                onRemove={canRemove && !isLoading(check?.status) ? onRemove : undefined}
                clientStateSelector={clientStateSelector}
                fixedKey={fixedKey}
                isWithAddress={isWithAddress}
              />
              {isWithPassingAlert && <SearchClientCheckAlert clientStateSelector={clientStateSelector} />}
            </>
          )}
        </StackPanel>
      </SimpleOpacityTransition>
      {selectedClientId === undefined && (
        <SearchClientInput
          onSelect={(client) => setSelectedClientId(client)}
          clientStateSelector={clientStateSelector}
          value={value}
          onChange={onChange}
          onPaste={onPaste}
          handleClean={handleClean}
        />
      )}
    </StackPanel>
  );
};
