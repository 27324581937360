import * as React from "react";
import { useState } from "react";
import AgentReportFileModel from "../../../../../../models/AgentReportFileModel";
import { ModalWrapper } from "../../../../wrapper/ModalWrapper";
import { Modal } from "../../../Modal";
import AttachmentLink from "../../../../../controls-old/attachment/AttachmentLink";
import AgentReportType from "../../../../../../models/AgentReportType";
import Button, { ButtonColor, ButtonType } from "../../../../../controls-old/button/Button";
import InputRequired from "../../../../../controls-old/input/InputRequired";
import IValue from "../../../../../controls-old/IValue";
import {
  agentReportSignCodeRequest,
  agentReportSignCodeSend,
} from "../../../../../../store/actions/agentReportActions";
import { AgentReportAction, AgentReportSignCodeForm } from "../../../../../../store/actions/agentReportActionsTypes";
import Loader from "../../../../../common/Loader";
import AgentReportSignCodeModel from "../../../../../../models/AgentReportSignCodeModel";
import "../AgentReportSignModal.scss";
import { useAppDispatch } from "../../../../../../domains/common/store";

enum State {
  Initial,
  GetCode,
}

interface IAgentReportSignSimplifiedModalWrapperProps {
  reportId: number;
  files: AgentReportFileModel[];
  onClose: (sign: boolean) => void;
}

const AgentReportSignSimplifiedModalWrapper: React.FC<IAgentReportSignSimplifiedModalWrapperProps> = (props) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState(State.Initial);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState<IValue>({ isValid: false });
  const [signCode, setSignCode] = useState<AgentReportSignCodeModel>();
  const [viewCodeError, setViewCodeError] = useState(false);

  const handleModalClose = () => {
    props.onClose(signCode ? signCode.signed : false);
  };

  const getFileTitle = (item: AgentReportFileModel): string => {
    const title = [item.issuingCompany.name];

    if (item.typeReport === AgentReportType.Common) {
      title.push("общий");
    } else if (item.typeReport === AgentReportType.Other) {
      title.push("другие продукты");
    } else {
      title.push("банковские гарантии");
    }

    return title.join(" / ");
  };

  const handleGetCode = () => {
    setError(undefined);

    const form: AgentReportSignCodeForm = {
      reportId: props.reportId,
      signCode: null,
      signId: null,
    };

    dispatch(
      agentReportSignCodeRequest(form, (state) => {
        switch (state.type) {
          case AgentReportAction.Processing:
            setLoading(true);
            break;
          case AgentReportAction.Failed:
            setError(state.payload.errorMessage);
            setLoading(false);
            break;
          case AgentReportAction.Success:
            setSignCode(state.payload.data);
            setState(State.GetCode);
            setLoading(false);
            break;
        }
      })
    );
  };

  const handleSendCode = () => {
    setViewCodeError(true);
    if (code.isValid) {
      const form: AgentReportSignCodeForm = {
        reportId: props.reportId,
        signCode: code.value!!,
        signId: signCode!!.signId,
      };

      dispatch(
        agentReportSignCodeSend(form, (state) => {
          switch (state.type) {
            case AgentReportAction.Processing:
              setLoading(true);
              break;
            case AgentReportAction.Failed:
              setError(state.payload.errorMessage);
              setLoading(false);
              break;
            case AgentReportAction.Success:
              setSignCode(state.payload.data);
              setState(State.GetCode);
              setLoading(false);
              break;
          }
        })
      );
    }
  };

  return (
    <ModalWrapper onClose={handleModalClose}>
      <Modal title={"Подписать документы акта сверки"} message={"Будут подписаны следующие документы:"}>
        <table className="table table_condensed table_bordered">
          <tbody className="table__body">
            {props.files.map((item, index) => {
              return (
                <tr className="table__row" key={index}>
                  <td className="table__row-item">{getFileTitle(item)}</td>
                  <td className="table__row-item">
                    <AttachmentLink file={item.fileManager} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="agent-report-sign-modal__actions">
          {loading && <Loader />}
          {error && !loading && <div className="alert alert_danger">{error}</div>}
          {state === State.Initial && !loading && (
            <div className="agent-report-sign-modal__buttons">
              <Button isDisabled={false} type={ButtonType.Button} color={ButtonColor.Blue} onClick={handleGetCode}>
                Получить код
              </Button>
            </div>
          )}
          {state === State.GetCode && !loading && signCode && (
            <>
              {signCode.canSign && !signCode.signed && (
                <>
                  <p className="agent-report-sign-modal__message">{signCode.message}</p>
                  <div className="agent-report-sign-modal__buttons">
                    <div className="agent-report-sign-modal__buttons-item">
                      <InputRequired
                        viewError={viewCodeError}
                        name={"code"}
                        placeholder={"Код из смс"}
                        maxLength={6}
                        onChange={(value) => setCode(value)}
                      />
                    </div>
                    <div className="agent-report-sign-modal__buttons-item">
                      <Button
                        isDisabled={false}
                        type={ButtonType.Button}
                        color={ButtonColor.Green}
                        onClick={handleSendCode}
                      >
                        Подписать
                      </Button>
                    </div>
                  </div>
                </>
              )}
              {(signCode.signed || (!signCode.signed && !signCode.canSign)) && (
                <>
                  <p className="agent-report-sign-modal__message">{signCode.message}</p>
                  <div className="agent-report-sign-modal__buttons">
                    <Button
                      isDisabled={false}
                      type={ButtonType.Button}
                      color={ButtonColor.Gray}
                      onClick={handleModalClose}
                    >
                      Закрыть
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Modal>
    </ModalWrapper>
  );
};

export default AgentReportSignSimplifiedModalWrapper;
