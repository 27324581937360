import { FormCheckbox } from "@sideg/ui-kit/atoms/checkbox";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeCreationGuaranteeTypeField } from "../fields/BankGuaranteeCreationGuaranteeTypeField";
import { BankGuaranteeCreationPurchaseTypeField } from "../fields/BankGuaranteeCreationPurchaseTypeField";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";
import { BaseCssProps } from "../../../../../common/ui-kit/types";

export interface BankGuaranteeCreationGuaranteeTypeBlockProps extends BaseCssProps {
  isDisabled?: boolean;
}

export const BankGuaranteeCreationGuaranteeTypeBlock = ({
  isDisabled,
}: BankGuaranteeCreationGuaranteeTypeBlockProps) => {
  const [fieldProps] = useBankGuaranteeCreationField("isReprovision");

  return (
    <CreationBlockWithTitle title="Тип гарантии">
      <StackPanel direction="column" gap="md">
        <BankGuaranteeCreationGuaranteeTypeField disabled={isDisabled} />
        <BankGuaranteeCreationPurchaseTypeField disabled={isDisabled} />
        <FormCheckbox label="Переобеспечение" {...fieldProps} isDisabled={isDisabled} />
      </StackPanel>
    </CreationBlockWithTitle>
  );
};
