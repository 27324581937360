import { ReactNode } from "react";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { Box } from "../../../../ui-kit/containers/box";
import { CreationBlockCard } from "../../../../ui-kit/cards/creation-block-card";
import { Typography } from "../../../../ui-kit/typography";

export interface ProductDetailsInformationListBlockProps {
  title?: string;
  children?: ReactNode;
  className?: string;
}

export const ProductDetailsInformationListBlock = ({
  title,
  children,
  className,
}: ProductDetailsInformationListBlockProps) => {
  const isDesktop = useThemeIsDesktop();

  return (
    <Box
      as={isDesktop ? "div" : CreationBlockCard}
      padding="lg"
      display="flex"
      gap="lg"
      flexDirection="column"
      className={className}
    >
      {title && (
        <Typography fontWeight={700} fontSize="fz4" lineHeight="1.5">
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};
