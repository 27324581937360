import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeId, DemandId } from "../../../../../../common/types/demand";
import { useAppDispatch } from "../../../../../../common/store";
import { onRequestOriginalBankGuaranteeList } from "../../../../store/item-modification/bankGuaranteeListItemModification.slice";
import { ButtonLinkWithIcon } from "../../../../../../common/ui-kit/buttons/link-with-icon";
import { Mail16Icon } from "../../../../../../common/ui-kit/icons";

export interface BankGuaranteeListItemRequestOriginalButtonProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
}

export const BankGuaranteeListItemRequestOriginalButton = ({
  demandId,
  guaranteeId,
  className,
}: BankGuaranteeListItemRequestOriginalButtonProps) => {
  const dispatch = useAppDispatch();
  const onClick = () => {
    dispatch(onRequestOriginalBankGuaranteeList({ demandId, guaranteeId }));
  };

  return (
    <ButtonLinkWithIcon type="button" icon={Mail16Icon} className={className} onClick={onClick}>
      Запрос оригинала
    </ButtonLinkWithIcon>
  );
};
