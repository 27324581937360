import { CSSObject } from "@emotion/react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ElementColor, getElementColor, ResponsiveStyleValue } from "../../types";

export interface IconProps {
  fill?: ResponsiveStyleValue<ElementColor>;
  stroke?: ResponsiveStyleValue<ElementColor>;
  width?: ResponsiveStyleValue<CSSObject["width"]>;
  height?: ResponsiveStyleValue<CSSObject["height"]>;
  flexShrink?: ResponsiveStyleValue<CSSObject["flexShrink"]>;
  flexGrow?: ResponsiveStyleValue<CSSObject["flexGrow"]>;
  title?: string;
}

export const Icon = styled("svg", { shouldForwardProp: isPropValid })<IconProps>(
  ({ theme, fill }) => makeResponsiveStyles(theme, fill, (x, t) => ({ "& > path": { fill: getElementColor(x, t) } })),
  ({ theme, stroke }) =>
    makeResponsiveStyles(theme, stroke, (x, t) => ({ "& path": { stroke: getElementColor(x, t) } })),
  ({ theme, width }) => makeResponsiveStyles(theme, width, (x) => ({ width: x })),
  ({ theme, height }) => makeResponsiveStyles(theme, height, (x) => ({ height: x })),
  ({ theme, flexShrink }) => makeResponsiveStyles(theme, flexShrink, (x) => ({ flexShrink: x })),
  ({ theme, flexGrow }) => makeResponsiveStyles(theme, flexGrow, (x) => ({ flexGrow: x })),
);
