import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { isLoading, isSucceeded } from "../../../../../common/types/state";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { useAppSelector } from "../../../../../common/store";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  ProductCreationScoringFormSubmitButton,
  ProductCreationScoringLoadingResultCard,
  ProductCreationScoringResultRow,
} from "../../../../../common/product-creation";
import { CreditWorkingAssetsCreationScoringResultSuccessRow } from "../result-row/CreditWorkingAssetsCreationScoringResultSuccessRow";
import { selectCreditWorkingAssetsCreationScoring } from "../../../store/creditWorkingAssetsCreation.selectors";
import { CreditWorkingAssetsCreationScoringResultSuccessRowSkeleton } from "../result-row/CreditWorkingAssetsCreationScoringResultSuccessRow.skeleton";
import { CreditWorkingAssetsCreationScoringFormValues } from "../../../types/scoring/CreditWorkingAssetsCreationScoringFormValues";

const SUCCESS_BANKS_SKELETON_COUNT = 3;

export interface CreditWorkingAssetsCreationScoringCreateByExistsSuccessBlockProps extends BaseCssProps {
  creditSum: number | undefined;
}

export const CreditWorkingAssetsCreationScoringCreateByExistsSuccessBlock = ({
  className,
  creditSum,
}: CreditWorkingAssetsCreationScoringCreateByExistsSuccessBlockProps) => {
  const { data, status } = useAppSelector(selectCreditWorkingAssetsCreationScoring);

  const isDesktop = useThemeIsDesktop();
  const isShowSubmitButton = isSucceeded(status);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(isLoading(status) || (data?.success && data.success.length > 0)) && (
        <StackPanel direction="column" gap="md" className={className}>
          <ProductCreationScoringLoadingResultCard
            isLoading={isLoading(status)}
            items={data?.success}
            skeletonElementsCount={SUCCESS_BANKS_SKELETON_COUNT}
            skeleton={CreditWorkingAssetsCreationScoringResultSuccessRowSkeleton}
            title="Выберите банки, в которые хотите доотправить заявку"
            footer={
              isDesktop &&
              isShowSubmitButton && (
                <ProductCreationScoringResultRow.BoxContainer pt="lg">
                  <ProductCreationScoringFormSubmitButton<CreditWorkingAssetsCreationScoringFormValues> />
                </ProductCreationScoringResultRow.BoxContainer>
              )
            }
          >
            {(x) => <CreditWorkingAssetsCreationScoringResultSuccessRow creditSum={creditSum} scoringResult={x} />}
          </ProductCreationScoringLoadingResultCard>
          {!isDesktop && isShowSubmitButton && (
            <ProductCreationScoringFormSubmitButton<CreditWorkingAssetsCreationScoringFormValues> />
          )}
        </StackPanel>
      )}
    </>
  );
};
