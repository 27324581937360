import { useCallback } from "react";
import { BankGuaranteeId, DemandId } from "../../../../../common/types/demand";
import { BankGuaranteeRequestOriginalCreationResponsiveView } from "../../../../request-original";
import { useBankGuaranteeListRequestOriginalCreationModal } from "../../../hooks/modification/useBankGuaranteeListRequestOriginalCreationModal";

export interface BankGuaranteeListItemRequestOriginalCreationGuaranteeViewProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
}

export const BankGuaranteeListItemRequestOriginalCreationGuaranteeView = ({
  guaranteeId,
  demandId,
}: BankGuaranteeListItemRequestOriginalCreationGuaranteeViewProps) => {
  const { isModalOpen, closeModal, onSuccess } = useBankGuaranteeListRequestOriginalCreationModal(guaranteeId);

  const onSuccessHandler = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  return (
    <BankGuaranteeRequestOriginalCreationResponsiveView
      demandId={demandId}
      onSuccess={onSuccessHandler}
      isOpen={isModalOpen}
      onClose={closeModal}
    />
  );
};
