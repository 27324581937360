import { getEntitiesCodeWithTitle } from "../../../../common/types/objects";
import { BankGuaranteeFiltersLists } from "../../types/filters/BankGuaranteeFiltersLists";
import { GetAvailableFiltersBankGuaranteeListOutputDto } from "../../api/dto/output/GetAvailableFiltersBankGuaranteeListOutputDto";

const mapFilters = (filter: GetAvailableFiltersBankGuaranteeListOutputDto): BankGuaranteeFiltersLists => {
  return {
    banks: getEntitiesCodeWithTitle(filter.bankIds),
    statuses: getEntitiesCodeWithTitle(filter.statuses),
    guaranteeTypes: getEntitiesCodeWithTitle(filter.guaranteeTypes),
    taskTypes: getEntitiesCodeWithTitle(filter.taskTypes ?? []),
  };
};

export const bankGuaranteeListFiltersMapper = {
  mapFilters,
};
