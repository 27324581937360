import { Modal } from "../../../../../ui/containers/modal";
import { WebPushesPromptModalContentDesktop } from "./WebPushesPromptModalContent.desktop";
import {
  WebPushesPromptModalActionsDesktop,
  WebPushesPromptModalActionsDesktopProps,
} from "./WebPushesPromptModalActions.desktop";
import { Box } from "../../../../../ui-kit/containers/box";

export type WebPushesPromptModalDesktopProps<TAction extends boolean> = (TAction extends true
  ? WebPushesPromptModalActionsDesktopProps
  : undefined) & {
  isShown: boolean;
  onClose: () => void;
  isWithActionButtons: TAction;
};

export const WebPushesPromptModalDesktop = <TAction extends boolean>({
  isShown,
  onClose,
  isWithActionButtons,
  onAgreeClick,
  onCancelClick,
}: WebPushesPromptModalDesktopProps<TAction>) => {
  return (
    <Modal title="Уведомления в браузере" isShown={isShown} onClose={onClose} width="100%">
      <WebPushesPromptModalContentDesktop />
      {isWithActionButtons && (
        <>
          <Box isFullWidth pb="xxl" pt="xxl" />
          <WebPushesPromptModalActionsDesktop onCancelClick={onCancelClick} onAgreeClick={onAgreeClick} />
        </>
      )}
    </Modal>
  );
};
