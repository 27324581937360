import { Form, useFormikContext } from "formik";
import { TransitionGroup } from "react-transition-group";
import { Box } from "projects/agent/src/domains/common/ui-kit/containers/box";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { CommentFormFormValues } from "../../../../../../common/product-comment";
import { useGetFieldName, useGetFormFieldError } from "../../../../../../common/form";
import { useHandleFileFieldChange } from "../../../../../../files";
import { FileUpload, FileUploadDropzoneInputBlock } from "../../../../../../files/upload";
import { SimpleOpacityTransition } from "../../../../../../common/ui-kit/animations/transitions";
import { Typography } from "../../../../../../common/ui-kit/typography";
import { ButtonLink } from "../../../../../../common/ui-kit/buttons/ButtonLink";
import { BankGuaranteeDetailsDemandNewCommentCardFormFilters } from "./BankGuaranteeDetailsDemandNewCommentFormFilters";
import { BlockResponsiveSubmitButton } from "../../../../../../common/ui-kit/buttons/block-responsive-submit";
import * as S from "../../../../../../common/product-comment/components/CommentFormFields.styled";

export interface BankGuaranteeDetailsDemandNewCommentCardFormFieldsWithFiltersProps extends BaseCssProps {
  onCancel: () => void;
  isNeedFilters?: boolean;
}

export const BankGuaranteeDetailsDemandNewCommentCardFormFieldsWithFilters = ({
  className,
  onCancel,
  isNeedFilters,
}: BankGuaranteeDetailsDemandNewCommentCardFormFieldsWithFiltersProps) => {
  const isDesktop = useThemeIsDesktop();

  const { touched, errors, getFieldProps, isSubmitting } = useFormikContext<CommentFormFormValues>();
  const getFieldError = useGetFormFieldError<CommentFormFormValues>();
  const getFieldName = useGetFieldName<CommentFormFormValues>();
  const { handleFileChange, removeFile, files } = useHandleFileFieldChange(getFieldName("files"));
  const isFilesExist = files && files.length > 0;

  return (
    <S.Container as={Form} className={className}>
      {isNeedFilters && <BankGuaranteeDetailsDemandNewCommentCardFormFilters />}
      <FormTextarea
        minRows={2}
        maxRows={isDesktop ? 20 : 15}
        placeholder="Комментарий"
        size="medium"
        error={getFieldError({ touched, errors }, "comment")}
        {...getFieldProps(getFieldName("comment"))}
      />
      {isFilesExist && (
        <TransitionGroup component={null}>
          {files.map((x) => (
            <SimpleOpacityTransition timeout={250} key={x.id}>
              <FileUpload
                fileName={x.file.name}
                date={x.dateAdded}
                canRemove={!isSubmitting}
                onRemove={() => removeFile(x)}
              />
            </SimpleOpacityTransition>
          ))}
        </TransitionGroup>
      )}

      <FileUploadDropzoneInputBlock
        text={isFilesExist ? "Прикрепить еще" : "Прикрепить файлы"}
        handleFileChange={handleFileChange}
        isMultiple
        isDisabled={isSubmitting}
      />
      <Box display="flex" flexDirection={isDesktop ? "row" : "column"} gap="lg">
        <BlockResponsiveSubmitButton type="submit" isLoading={isSubmitting}>
          Отправить
        </BlockResponsiveSubmitButton>
        {isDesktop && !isSubmitting && (
          <ButtonLink type="button" onClick={onCancel}>
            <Typography fontSize="fz3" fontColor="primary">
              Отмена
            </Typography>
          </ButtonLink>
        )}
      </Box>
    </S.Container>
  );
};
