import {
  BaseFormElementError,
  BaseFormElementErrorProps,
} from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormElementError";
import { PropsWithChildren, ReactNode } from "react";
import * as S from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormInputElement.styled";
import { Flex } from "@sideg/ui-kit/atoms/containers";
import { ControlSize } from "@sideg/ui-kit/common/types";

export interface BaseFormInputElementProps extends BaseFormElementErrorProps, PropsWithChildren {
  label?: string;
  labelFor?: string;
  disabled?: boolean;
  afterInput?: ReactNode;
  beforeInput?: ReactNode;
  className?: string;
  status?: ReactNode;
  size?: ControlSize;
}

export const BaseFormInputElement = ({
  className,
  beforeInput,
  afterInput,
  disabled,
  labelFor,
  label,
  children,
  errorDataTestId,
  error,
  status,
  size = "default",
}: BaseFormInputElementProps) => {
  return (
    <S.Container className={className}>
      {label && (
        <S.Label size={size} htmlFor={labelFor}>
          {label}
        </S.Label>
      )}
      <S.InputElement role="button">
        <S.InputBox error={!!error} disabled={disabled}>
          {beforeInput && (
            <Flex alignItems="flex-start" alignSelf="stretch" direction="column" justifyContent="center">
              {beforeInput}
            </Flex>
          )}
          <S.InputBoxMain>{children}</S.InputBoxMain>
          {afterInput && (
            <Flex alignItems="flex-end" alignSelf="stretch" direction="column" justifyContent="center">
              {afterInput}
            </Flex>
          )}
        </S.InputBox>
      </S.InputElement>
      <BaseFormElementError errorDataTestId={errorDataTestId} error={error} />
      {status}
    </S.Container>
  );
};
