import { useFullPageLayout } from "../../../common/ui/layout";
import { CreationProductsTable } from "../components/CreationProductsTable";
import { usePageTitle } from "../../../common/navigation/page-title";
import * as S from "./QueueTasksProductCreationPage.styled";
import { QueueTasksCreationHeader } from "../components/header/QueueTasksCreationHeader";

export const QueueTasksProductCreationPage = () => {
  usePageTitle("Новая заявка");
  useFullPageLayout();

  return (
    <S.Container as="main">
      <QueueTasksCreationHeader />
      <CreationProductsTable />
    </S.Container>
  );
};
