import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { Box } from "../../../common/ui-kit/containers/box";
import { Text } from "../../../common/ui-kit/typography";
import { reportsDemandPresetType } from "../types/ReportsDemandPresetType";
import { ButtonLink } from "../../../common/ui-kit/buttons/ButtonLink";
import { useReportsDemandPresetOnClick } from "../hooks/useReportsDemandPresetOnClick";

export const ReportsDemandPresets = () => {
  const onClick = useReportsDemandPresetOnClick();
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Box display="flex" gap="xs" flexDirection="column">
      <Text fontSize="fz3" lineHeight="defaultLineHeight" fontWeight={500}>
        Сформировать отчет за
      </Text>
      <Box
        display="flex"
        flexDirection={{ xs: "column", [desktopBreakpoint]: "row" }}
        gap="md"
        alignItems="flex-start"
        pl={{ xs: "lg", [desktopBreakpoint]: "none" }}
      >
        {reportsDemandPresetType.values.map((x) => (
          <ButtonLink type="button" key={x} onClick={onClick(x)}>
            {reportsDemandPresetType.getTitle(x)}
          </ButtonLink>
        ))}
      </Box>
    </Box>
  );
};
