import * as React from "react";
import { useEffect } from "react";
import { IAgentReportItemState } from "../../../store/reducers/agentReportReducer";
import { connect } from "react-redux";
import { RootState, useAppDispatch } from "../../../domains/common/store";
import {
  agentReportItemInitial,
  agentReportItemLoad,
  agentReportUpdateInitial,
} from "../../../store/actions/agentReportActions";
import { AgentReportStatus } from "../../../store/actions/agentReportActionsTypes";
import Loader from "../../../components/common/Loader";
import "./AgentReportItem.scss";
import AgentReportItemContent from "./AgentReportItemContent";
import { usePageTitle } from "../../../domains/common/navigation/page-title";
import { AppLayoutPageWithFooterContainer } from "../../../domains/common/ui/layout/app-layout-containers";
import { useAppUrlParams } from "../../../domains/common/urls/internal/hooks";

interface IAgentReportItemProps {
  state?: IAgentReportItemState;
  updateState?: IAgentReportItemState;
}

const AgentReportItem: React.FC<IAgentReportItemProps> = (props) => {
  const dispatch = useAppDispatch();
  const params = useAppUrlParams((x) => x.reports.agent.details.root);
  usePageTitle("Акт сверки");

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (props.updateState?.status === AgentReportStatus.Success) {
      loadData();
    }
  }, [props.updateState?.status]);

  const loadData = () => {
    dispatch(agentReportItemInitial());
    dispatch(agentReportUpdateInitial());
    dispatch(agentReportItemLoad(parseInt(params.reportId ?? "")));
  };

  return (
    <AppLayoutPageWithFooterContainer>
      {(props.state?.status === AgentReportStatus.Processing ||
        props.updateState?.status === AgentReportStatus.Processing) && <Loader />}
      {props.updateState?.status === AgentReportStatus.Failed && (
        <div className="alert alert_danger">{props.updateState.errorMessage}</div>
      )}
      {props.state?.status === AgentReportStatus.Failed && (
        <div className="alert alert_danger">{props.state.errorMessage}</div>
      )}
      {props.state?.status === AgentReportStatus.Success && props.state.data && (
        <AgentReportItemContent data={props.state.data} />
      )}
    </AppLayoutPageWithFooterContainer>
  );
};

const mapStateToProps = (state: RootState, ownState: any): IAgentReportItemProps => {
  return {
    ...ownState,
    state: state.agentReportReducer.item,
    updateState: state.agentReportReducer.update,
  };
};

export default connect(mapStateToProps)(AgentReportItem);
