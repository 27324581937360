import { useState } from "react";
import { ButtonLink } from "../../../../../../ui-kit/buttons/ButtonLink";
import { Box } from "../../../../../../ui-kit/containers/box";
import { StackPanel } from "../../../../../../ui-kit/containers/stack-panel";
import { Icon, InfoIcon } from "../../../../../../ui-kit/icons";
import { BaseCssProps } from "../../../../../../ui-kit/types";
import { Typography } from "../../../../../../ui-kit/typography";
import { Modal } from "../../../../../containers/modal";

export interface ProductListBriefButtonProps extends BaseCssProps {
  children: React.ReactNode;
}

export const ProductListBriefButton = ({ children, className }: ProductListBriefButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Box>
        <ButtonLink
          type="button"
          className={className}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <StackPanel gap="sm" direction="row" alignItems="center">
            <Icon as={InfoIcon} stroke="primary" />
            <Typography fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="primary">
              Информация о заявке
            </Typography>
          </StackPanel>
        </ButtonLink>
      </Box>
      <Modal
        isShown={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title="Информация о заявке"
        width="100%"
      >
        {children}
      </Modal>
    </>
  );
};
