import { ApiResponse } from "../../../common/api/types/ApiResponse";
import { invokeHubMethod } from "../../../common/hubs";
import { chatDialogHubConnection } from "./chatDialogHubConnection";
import { GetMessagesChatDialogOutputDto } from "./dto/output/GetMessagesChatDialogOutputDto";
import { GetMessagesChatDialogInputDto } from "./dto/input/GetMessagesChatDialogInputDto";

export const chatDialogApi = {
  markRead: (): Promise<unknown> => invokeHubMethod(chatDialogHubConnection, "MarkRead"),
  getMessages: ({
    pageSize,
    lastMessageId,
  }: GetMessagesChatDialogInputDto): Promise<ApiResponse<GetMessagesChatDialogOutputDto>> =>
    invokeHubMethod(chatDialogHubConnection, "GetMessages", pageSize, lastMessageId),
  sendMessage: (message: string): Promise<boolean> => {
    return chatDialogHubConnection.invoke<boolean>("SendMessage", message);
  },
};
