import { useState } from "react";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../../../../common/store";
import { selectCreditWorkingAssetsListFiltersBanks } from "../../../../../store/filters/creditWorkingAssetsListFilters.selectors";
import { CreditWorkingAssetsListFiltersMobile } from "../../../../../types/filters/CreditWorkingAssetsListFiltersMobile";
import { FilterFormMobileCheckboxesWithCollapse } from "../../../../../../../common/filters/use-cases";
import { FormSearchInput } from "../../../../../../../common/ui-kit/form-controls/search-input";

export type CreditWorkingAssetsListHeaderFiltersBanksMobileProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFiltersBanksMobile = ({
  className,
}: CreditWorkingAssetsListHeaderFiltersBanksMobileProps) => {
  const [inputValue, setInputValue] = useState("");
  const banks = useAppSelector((state) => selectCreditWorkingAssetsListFiltersBanks(state, inputValue));

  return (
    <FilterFormMobileCheckboxesWithCollapse<CreditWorkingAssetsListFiltersMobile>
      title="Банки"
      fieldName="bankIds"
      items={banks}
      className={className}
      beforeList={
        <FormSearchInput
          placeholder="Наименование банка"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          spellCheck={false}
          autoComplete="off"
          autoCorrect="off"
        />
      }
    />
  );
};
