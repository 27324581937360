import { Navigate } from "react-router-dom";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { generatePath } from "../../../common/urls/internal/utils";
import { useBankGuaranteeDetailsLayoutContext } from "../hooks/layout/useBankGuaranteeDetailsLayoutContext";

export const BankGuaranteeDetailsRootPage = () => {
  const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();

  return (
    <ThemeMobileDesktopView
      mobile={undefined}
      desktop={<Navigate to={generatePath((x) => x.bankGuarantee.details.details, { guaranteeId })} />}
    />
  );
};
