export enum FileStatus {
  Processing = "PROCESSING",
  Failed = "FAILED",
  Success = "SUCCESS",
}

export interface IFileState {
  status: FileStatus;
  data?: any;
  errorMessage?: string;
}
