import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { Box } from "../../../common/ui-kit/containers/box";
import { NewsList } from "../components/NewsList";
import { usePageTitle } from "../../../common/navigation/page-title";

const BoxText = Box.withComponent(AppLayoutResponsiveListPage.Title);

export const NewsListPage = () => {
  usePageTitle("Новости");

  return (
    <AppLayoutResponsiveListPage>
      <AppLayoutResponsiveListPage.Header>
        <BoxText pb="xxl">Новости</BoxText>
      </AppLayoutResponsiveListPage.Header>
      <NewsList />
    </AppLayoutResponsiveListPage>
  );
};
