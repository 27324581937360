import { memo } from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDetailsActiveDemand } from "../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import {
  selectBankGuaranteeDetailsTasksHistory,
  selectBankGuaranteeDetailsTasksHistoryIds,
} from "../../store/tasks-history/bankGuaranteeDetailsTasksHistory.selectors";
import { isSucceeded } from "../../../../common/types/state";
import { selectBankGuaranteeDetailsActiveTasksTotal } from "../../store/active-tasks/bankGuaranteeDetailsActiveTasks.selectors";
import { ProductDetailsDemandTasksEmptyState } from "../../../../common/product-details";

export type BankGuaranteeDetailsDemandEmptyStateProps = BaseCssProps;

export const BankGuaranteeDetailsDemandEmptyState = memo(({ className }: BankGuaranteeDetailsDemandEmptyStateProps) => {
  const {
    fetch: { status: fetchStatus },
  } = useAppSelector(selectBankGuaranteeDetailsActiveDemand);
  const activeTasksCount = useAppSelector(selectBankGuaranteeDetailsActiveTasksTotal);

  const { status: taskHistoryFetchStatus } = useAppSelector(selectBankGuaranteeDetailsTasksHistory);
  const taskHistoryItems = useAppSelector(selectBankGuaranteeDetailsTasksHistoryIds);

  const isLoadedEmptyState =
    isSucceeded(taskHistoryFetchStatus) &&
    isSucceeded(fetchStatus) &&
    activeTasksCount === 0 &&
    taskHistoryItems.length === 0;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{isLoadedEmptyState && <ProductDetailsDemandTasksEmptyState className={className} />}</>
  );
});
