import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { FullPageContainerCompound } from "../../../../common/ui/containers/full-page";
import { useNavigationStateFromWithFallback } from "../../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../../common/urls/internal/utils";

export interface LegalServicesCreationFormHeaderProps extends BaseCssProps {}

export const LegalServicesCreationFormHeader = ({ className }: LegalServicesCreationFormHeaderProps) => {
  const { from } = useNavigationStateFromWithFallback(
    selectAppUrl((urls) => urls.legalServices.creation.list).url,
    "Юридические услуги",
  );

  return (
    <FullPageContainerCompound.Header
      className={className}
      leftSide={<FullPageContainerCompound.HeaderBackLinkButton url={from.url} title={from.backTitle} />}
    >
      <FullPageContainerCompound.HeaderTypography>
        Новая заявка
        <ThemeMobileDesktopView mobile={<br />} desktop={undefined} /> на юридическую услугу
      </FullPageContainerCompound.HeaderTypography>
    </FullPageContainerCompound.Header>
  );
};
