import { base64service } from "../../services/base64";
import { webPushesConfigs } from "../configs/webPushesConfigs";
import { WebPushesPushManagerPermissionState } from "../types/WebPushesPushManagerPermissionState";
import { loggingService } from "../../logging/services/loggingService";

const SUBSCRIPTIONS_OPTIONS = {
  userVisibleOnly: true,
  applicationServerKey: base64service.urlBase64ToUint8Array(webPushesConfigs.publicKey),
};

const isFeatureSupported = (
  serviceWorkerRegistration: ServiceWorkerRegistration | undefined,
): serviceWorkerRegistration is ServiceWorkerRegistration & {
  pushManager: PushManager;
} => {
  return serviceWorkerRegistration !== undefined && "pushManager" in serviceWorkerRegistration;
};

export interface WebPushesPermissionStateService {
  isFeatureSupported: typeof isFeatureSupported;
  getPermissionState: (
    serviceWorkerRegistration: ServiceWorkerRegistration | undefined,
  ) => Promise<WebPushesPushManagerPermissionState>;
  requestPermission: (
    serviceWorkerRegistration: ServiceWorkerRegistration | undefined,
  ) => Promise<PushSubscription | undefined>;
  getSubscription: (
    serviceWorkerRegistration: ServiceWorkerRegistration | undefined,
  ) => Promise<PushSubscription | undefined>;
}

export const webPushesPermissionStateService: WebPushesPermissionStateService = {
  isFeatureSupported,
  getPermissionState: async (registration) => {
    if (isFeatureSupported(registration)) {
      return registration.pushManager.permissionState(SUBSCRIPTIONS_OPTIONS);
    }

    return "__NOT_SUPPORTED__";
  },
  getSubscription: async (registration) => {
    if (isFeatureSupported(registration)) {
      return (await registration.pushManager.getSubscription()) ?? undefined;
    }

    return undefined;
  },
  requestPermission: async (registration) => {
    if (!isFeatureSupported(registration)) {
      return undefined;
    }

    try {
      return await registration.pushManager.subscribe(SUBSCRIPTIONS_OPTIONS);
    } catch (e: unknown) {
      loggingService.logInfo(e);

      return undefined;
    }
  },
};
