import { MouseEventHandler } from "react";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { Box } from "../../../../../ui-kit/containers/box";
import { Button } from "../../../../../ui-kit/form-controls/button";

export interface WebPushesPromptModalActionsDesktopProps extends BaseCssProps {
  onCancelClick: MouseEventHandler<HTMLButtonElement>;
  onAgreeClick: MouseEventHandler<HTMLButtonElement>;
}

export const WebPushesPromptModalActionsDesktop = ({
  onCancelClick,
  onAgreeClick,
  className,
}: WebPushesPromptModalActionsDesktopProps) => {
  return (
    <Box className={className} display="flex" alignItems="center" gap="xxl">
      <Button variant="contained" color="primary" type="button" onClick={onAgreeClick}>
        Разрешить
      </Button>
      <Button variant="outlined" color="primary" type="button" onClick={onCancelClick}>
        В другой раз
      </Button>
    </Box>
  );
};
