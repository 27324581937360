import { useEffect, useMemo, useState } from "react";
import { UseSelectStateChange } from "downshift";
import { ProductListCardMoreActionWithHandler } from "../types/ProductListCardActionWithHandler";
import { useDebounce } from "../../../../../hooks/useDebounce";

const DEBOUNCE_DELAY_MS = 30;

// Временный фикс - https://github.com/downshift-js/downshift/issues/1540
// Избегаем множественного вызова обработчика смены выбранного элемента на touch устройствах
export const useProductListCardMoreActionsButtonChangeItem = () => {
  const [selectedItem, setSelectedItem] = useState<ProductListCardMoreActionWithHandler | undefined>(undefined);
  const [selectedItemDebounce, resetDebounce] = useDebounce(selectedItem, DEBOUNCE_DELAY_MS);

  useEffect(() => {
    if (selectedItemDebounce) {
      selectedItemDebounce.handler();
      setSelectedItem(undefined);
    }
  }, [selectedItemDebounce]);

  return useMemo(
    () => ({
      onSelectedItemChange: (state: UseSelectStateChange<ProductListCardMoreActionWithHandler>) => {
        setSelectedItem(state.selectedItem ?? undefined);
      },
      onIsOpenChange: () => {
        setSelectedItem(undefined);
        resetDebounce(undefined);
      },
    }),
    [resetDebounce],
  );
};
