/**
 * @deprecated
 */
enum ProductType {
  BankGuarantee = 1,
  CreditExecution = 3,
  Factoring = 5,
}

export default ProductType;
