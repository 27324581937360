import { dateTimeHelper } from "@sideg/helpers";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { Typography } from "../../../../common/ui-kit/typography";
import { CreditExecutionBrief } from "../types/CreditExecutionBrief";

export interface CreditExecutionBriefDescriptionDateFieldProps {
  data: CreditExecutionBrief;
  isShowDateStartMovedToToday?: boolean;
}

export const CreditExecutionBriefDescriptionDateField = ({
  data,
  isShowDateStartMovedToToday,
}: CreditExecutionBriefDescriptionDateFieldProps) => {
  const dateStart = isShowDateStartMovedToToday ? data.dateStartMoved ?? data.dateStart : data.dateStart;

  return (
    <StackPanel gap="sm" direction="column">
      <span>
        c {dateTimeHelper.format(new Date(dateStart), "dateOnly")}
        &nbsp;по&nbsp;
        {dateTimeHelper.format(new Date(data.dateEnd), "dateOnly")}
      </span>
      {data.isDateStartMovedToToday && (
        <Typography fontWeight={500} fontSize="fz1" fontColor="warning">
          Дата начала заявки изменена на сегодняшнюю
        </Typography>
      )}
    </StackPanel>
  );
};
