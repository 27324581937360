import { AgencyType } from "../../../../common/types/agencies";
import { TaxpayerNumberLengthOptional } from "../../../../common/types/demand";
import { CompanyType } from "../../../../common/types/companies";

export const publicUserRegistrationFromRules = {
  canSetCompanyType: (agencyType: AgencyType): boolean => agencyType === "Agent",
  taxpayerNumberLength: (companyType: CompanyType | undefined): TaxpayerNumberLengthOptional => {
    if (companyType === undefined) {
      return undefined;
    }

    return companyType === "Legal" ? 10 : 12;
  },
};
