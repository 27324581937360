import styled from "@emotion/styled";

export const ProfileAgentWrapper = styled.div`
  padding-bottom: 2em;
`;

export const InfoWrapper = styled.ul`
  margin-bottom: ${({ theme }) => theme.spaces.lg};
`;

export const InfoAgentWrapper = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${({ theme }) => theme.spaces.md} 0;
`;

export const InfoAgentTitle = styled.span`
  font-weight: 700;
  line-height: 1.4;
`;
