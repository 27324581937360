import { RootState } from "../../../../common/store";
import { creditWorkingAssetsDetailsActiveTasksAdapter } from "./creditWorkingAssetsDetailsActiveTasks.adapter";
import { CreditWorkingAssetsDetailsActiveTasksState } from "./creditWorkingAssetsDetailsActiveTasks.types";
import { selectCreditWorkingAssetsDetails } from "../creditWorkingAssetsDetails.selectors";

export const selectCreditWorkingAssetsDetailsActiveTasks = (
  state: RootState
): CreditWorkingAssetsDetailsActiveTasksState => selectCreditWorkingAssetsDetails(state).activeTasks;

export const {
  selectById: selectCreditWorkingAssetsDetailsActiveTaskById,
  selectIds: selectCreditWorkingAssetsDetailsActiveTasksIds,
  selectTotal: selectCreditWorkingAssetsDetailsActiveTasksTotal,
} = creditWorkingAssetsDetailsActiveTasksAdapter.getSelectors(selectCreditWorkingAssetsDetailsActiveTasks);
