import { matchPath, useLocation } from "react-router-dom";
import { useMemo } from "react";
import { BankGuaranteeId, DemandId } from "../../../common/types/demand";
import { joinUrlParts, selectAppUrl } from "../../../common/urls/internal/utils";
import { bankGuaranteeDetailsPageUrlSelectors } from "../configs/bankGuaranteeDetailsPageUrlSelectors";

const PATH_WILDCARD = "*";

export const useBankGuaranteeDetailsIsDemandNestedPath = (
  guaranteeId: BankGuaranteeId,
  demandId: DemandId | undefined,
) => {
  const { pathname } = useLocation();

  return useMemo(() => {
    if (demandId === undefined) {
      return false;
    }

    const matchNestedRoute = matchPath(
      joinUrlParts(selectAppUrl(bankGuaranteeDetailsPageUrlSelectors.demand).url, PATH_WILDCARD),
      pathname,
    );

    return (
      matchNestedRoute?.params.demandId === demandId.toString() &&
      matchNestedRoute.params.guaranteeId === guaranteeId.toString() &&
      (matchNestedRoute.params[PATH_WILDCARD] ?? "") !== ""
    );
  }, [demandId, guaranteeId, pathname]);
};
