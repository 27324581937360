import { clientV2 } from "../../../common/api";
import { PostUserDetailsInputDto } from "./dto/input/PostUserDetailsInputDto";
import { GetUserDetailsOutputDto } from "./dto/output/GetUserDetailsOutputDto";

export const userDetailsApi = {
  get: () => clientV2.get<undefined, GetUserDetailsOutputDto>("/user"),
  post: (userDetails: PostUserDetailsInputDto) => {
    return clientV2.post<PostUserDetailsInputDto, undefined>("/user/contact", userDetails);
  },
};
