import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { QueueTasksListCreationButtonMobile } from "./QueueTasksListCreationButton.mobile";
import { useCreateNavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { QueueTasksListCreationButtonDesktop } from "./QueueTasksListCreationButton.desktop";

export const QueueTasksListCreationButton = () => {
  const state = useCreateNavigationStateFrom("Активные задачи");

  return (
    <ThemeMobileDesktopView
      mobile={<QueueTasksListCreationButtonMobile state={state} />}
      desktop={<QueueTasksListCreationButtonDesktop state={state} />}
    />
  );
};
