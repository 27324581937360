import Fuse from "fuse.js";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

export interface CreateFuseSearchSelectorOptions<TCollectionItem> {
  selector: (state: RootState) => TCollectionItem[];
  keys: { [P in keyof TCollectionItem]: P extends string ? P : never }[keyof TCollectionItem][];
  ignoreLessThan: number;
  searchTransformer?: (search: string) => string;
}

export const createFuseSearchSelector = <TCollectionItem>({
  selector,
  ignoreLessThan,
  keys,
  searchTransformer,
}: CreateFuseSearchSelectorOptions<TCollectionItem>) => {
  const fuse = new Fuse([] as TCollectionItem[], {
    isCaseSensitive: false,
    keys,
    includeScore: true,
  });

  return createSelector([selector, (state, search: string) => search], (items, search): TCollectionItem[] => {
    if (search.trim() === "") {
      return items ?? ([] as TCollectionItem[]);
    }

    fuse.setCollection(items);
    const transformedSearch = searchTransformer?.(search) ?? search;
    const result = fuse.search(transformedSearch);

    return result.filter((x) => x.score && x.score < ignoreLessThan).map((x) => x.item);
  });
};
