import { EntitiesCodeWithTitle } from "../../../../../common/types/objects";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import * as S from "./BankGuaranteeRewardsBanksListFilter.desktop.styled";
import { BankGuaranteeRewardsBanksListFilterCardDesktop } from "../card/BankGuaranteeRewardsBanksListFilterCard.desktop";

export interface BankGuaranteeRewardsBanksListFilterDesktopProps extends BaseCssProps {
  banks: EntitiesCodeWithTitle<string>;
  onClick: (value: string) => void;
}

export const BankGuaranteeRewardsBanksListFilterDesktop = ({
  banks,
  onClick,
  className,
}: BankGuaranteeRewardsBanksListFilterDesktopProps) => {
  return (
    <BankGuaranteeRewardsBanksListFilterCardDesktop className={className}>
      {banks.codeWithTitleList.map((bank) => (
        <S.ItemWrapper key={bank.code}>
          <S.Item href={`#${bank.code}`} onClick={() => onClick(bank.code)}>
            {bank.title}
          </S.Item>
        </S.ItemWrapper>
      ))}
    </BankGuaranteeRewardsBanksListFilterCardDesktop>
  );
};
