import { useEffect } from "react";
import { NewsDetails } from "../components/NewsDetails";
import { usePageTitle } from "../../../common/navigation/page-title";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { NewsDetailsHeader } from "../components/header/NewsDetailsHeader";
import { useAppUrlParams } from "../../../common/urls/internal/hooks";
import { getNewsDetails } from "../store/newsDetails.thunks";
import { updateDetailsNews } from "../store/newsDetails.slice";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectNewsDetails } from "../store/newsDetails.selectors";
import { isFailed } from "../../../common/types/state";

export const NewsDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { data, status } = useAppSelector(selectNewsDetails);
  usePageTitle(data?.title ?? "Новость");

  const params = useAppUrlParams((x) => x.news.details.item);

  useEffect(() => {
    if (params.newsId !== undefined) {
      dispatch(getNewsDetails(params.newsId));
    }

    return () => {
      dispatch(updateDetailsNews());
    };
  }, [dispatch, params.newsId]);

  return (
    <AppLayoutResponsiveListPage backgroundColor="light">
      <NewsDetailsHeader />
      {!isFailed(status) && <NewsDetails />}
    </AppLayoutResponsiveListPage>
  );
};
