import * as React from "react";
import { FormCheckbox } from "@sideg/ui-kit/atoms/checkbox";
import { FilteringArrayKeys } from "../../../../types/FilteringArrayKeys";
import { CodeWithTitle } from "../../../../../types/objects";
import { useFiltersCheckboxesArrayFormField } from "../../../hooks/useFiltersCheckboxesArrayFormField";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";
import { BaseCssProps } from "../../../../../ui-kit/types";

export interface FilterFormCheckboxesProps<TFormValues> extends BaseCssProps {
  fieldName: FilteringArrayKeys<TFormValues>;
  items: CodeWithTitle[];
  beforeList?: React.ReactNode;
}

export const FilterFormCheckboxes = <TFormValues,>({
  fieldName,
  beforeList,
  items,
}: FilterFormCheckboxesProps<TFormValues>) => {
  const { onChange, isChecked } = useFiltersCheckboxesArrayFormField(fieldName);

  return (
    <StackPanel gap="lg" direction="column">
      {beforeList}
      {items.map((item) => (
        <FormCheckbox
          key={item.code}
          label={item.title}
          checked={isChecked(item.code)}
          onChange={onChange(item.code)}
        />
      ))}
    </StackPanel>
  );
};
