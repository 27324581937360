import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { BankGuaranteeId, DemandId } from "../../../../../../../common/types/demand";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import * as S from "./BankGuaranteeListItemDesktopDemand.styled";
import { useAppSelector } from "../../../../../../../common/store";
import { selectBankGuaranteeListItemsDemandById } from "../../../../../store/items/bankGuaranteeListItems.selectors";
import { CircleIndicator } from "../../../../../../../common/ui-kit/notifications/circle-indicator";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { BankGuaranteeListItemDesktopStatusLink } from "../status-link/BankGuaranteeListItemDesktopStatusLink";
import { useBankGuaranteeListItemDemandLink } from "../../../../../hooks/list/useBankGuaranteeListItemDemandLink";
import { BankGuaranteeDemandStageIcon } from "../../../../../../common/components";
import { ProductListCardRejectButton } from "../../../../../../../common/ui/product/list/card";

export interface BankGuaranteeListItemDesktopDemandProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  from?: NavigationState;
  onRemoveClick: () => void;
}

export const BankGuaranteeListItemDesktopDemand = ({
  guaranteeId,
  demandId,
  from,
  className,
  onRemoveClick,
}: BankGuaranteeListItemDesktopDemandProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectBankGuaranteeListItemsDemandById(state, demandId))!;
  const linkProps = useBankGuaranteeListItemDemandLink(guaranteeId, demandId);

  return (
    <S.Li className={className}>
      <S.BankAndIcon>
        <Box pr="md" flexGrow={0} flexShrink={0}>
          <BankGuaranteeDemandStageIcon stage={demand.stage.code} />
        </Box>
        <S.BankLink stage={demand.stage.code} state={from} {...linkProps}>
          {demand.bank.title}
        </S.BankLink>
        {demand.isUpdated && (
          <Box pl="sm">
            <CircleIndicator color={(theme) => theme.colors.primary} size={4} />
          </Box>
        )}
      </S.BankAndIcon>
      <S.StatusContainer title={demand.status.title}>
        <BankGuaranteeListItemDesktopStatusLink guaranteeId={guaranteeId} demand={demand} from={from} />
      </S.StatusContainer>
      <S.DateLabel>
        {dateTimeHelper.format(new Date(demand.dateUpdated), specialFormatters.dayAndShortMonthOrTimeOnlyIfSameDay)}
      </S.DateLabel>
      <S.RemoveButton>{!demand.isTerminated && <ProductListCardRejectButton onClick={onRemoveClick} />}</S.RemoveButton>
    </S.Li>
  );
};
