import { BaseCssProps } from "../../../../common/ui-kit/types";
import * as S from "./BankGuaranteeRequestOriginalLabel.styled";
import { BankGuaranteeRequestOriginalStatus, bankGuaranteeRequestOriginalStatus } from "../../../common/types";
import { getRequestOriginalLabelColors } from "../../utils/getRequestOriginalLabelColors";
import { LabelNotificationWrapper } from "../../../../common/ui-kit/labels";

export interface BankGuaranteeRequestOriginalLabelProps extends BaseCssProps {
  requestOriginalType: BankGuaranteeRequestOriginalStatus;
  isWithNotification?: boolean;
}

export const BankGuaranteeRequestOriginalLabel = ({
  requestOriginalType,
  isWithNotification,
  className,
}: BankGuaranteeRequestOriginalLabelProps) => {
  const { color, backgroundColor } = getRequestOriginalLabelColors(requestOriginalType);

  return (
    <LabelNotificationWrapper isWithNotification={isWithNotification}>
      <S.Container className={className} backgroundColor={backgroundColor}>
        <S.Text color={color}>{bankGuaranteeRequestOriginalStatus.getTitle(requestOriginalType)}</S.Text>
      </S.Container>
    </LabelNotificationWrapper>
  );
};
