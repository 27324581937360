import { useEffect, useState } from "react";
import { closedAuctionAndSingleSupplierRules } from "../../../validation/rules/closedAuctionAndSingleSupplier.rules";
import { useBankGuaranteeCreationForm } from "../useBankGuaranteeCreationForm";
import { useBankGuaranteeCreationFieldHelpers } from "./useBankGuaranteeCreationFieldHelpers";

export const useBankGuaranteeCreationContractCheckboxFieldsDisabledState = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const { getFieldName } = useBankGuaranteeCreationFieldHelpers();
  const {
    values: { federalLaw, isSingleSupplier, isClosedAuction },
    setFieldValue,
  } = useBankGuaranteeCreationForm();

  useEffect(() => {
    const isAvailable = closedAuctionAndSingleSupplierRules.isAvailable(federalLaw);
    setIsDisabled(!isAvailable);

    if (!isAvailable && (isSingleSupplier || isClosedAuction)) {
      setFieldValue(getFieldName("isSingleSupplier"), false, true);
      setFieldValue(getFieldName("isClosedAuction"), false, true);
    }
  }, [federalLaw, getFieldName, isClosedAuction, isSingleSupplier, setFieldValue]);

  return isDisabled;
};
