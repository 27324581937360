import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { BANK_GUARANTEE_DETAILS_SLICE_NAME, getBankGuaranteeDetailsDemand } from "../bankGuaranteeDetails.thunks";
import { BankGuaranteeDetailsActiveDemandState } from "./bankGuaranteeDetailsActiveDemand.types";
import { BaseState, getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import {
  bankGuaranteeDetailsActiveDemandChanged,
  bankGuaranteeDetailsGuaranteeChanged,
} from "../bankGuaranteeDetails.actions";
import { bankGuaranteeDetailsActiveDemandDocumentsAdapter } from "./bankGuaranteeDetailsActiveDemandDocuments.adapter";
import { getDemandActualDocuments } from "../../../../demand/actual-documents";

const SLICE_NAME = combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "activeDemand");

const initialState: BankGuaranteeDetailsActiveDemandState = {
  fetch: { status: LoadingStatusEnum.Idle },
  canSendComment: undefined,
  rewardRate: undefined,
  actualBill: undefined,
  actualProject: undefined,
  documents: bankGuaranteeDetailsActiveDemandDocumentsAdapter.getInitialState<BaseState>({
    status: LoadingStatusEnum.Idle,
  }),
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reload: (state) => ({ ...initialState, activeDemandId: state.activeDemandId }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(bankGuaranteeDetailsGuaranteeChanged, () => initialState)
      .addCase(bankGuaranteeDetailsActiveDemandChanged, (state, action) => {
        return { ...initialState, activeDemandId: action.payload };
      })
      .addCase(getBankGuaranteeDetailsDemand.fulfilled, (state, action) => {
        state.canSendComment = action.payload.body.canSendComment;
        state.actualBill = action.payload.body.actualBill;
        state.actualProject = action.payload.body.actualProject;
      })
      .addCase(getBankGuaranteeDetailsDemand.rejected, (state, action) => {
        state.fetch.error = action.payload?.message;
        state.canSendComment = undefined;
      })
      .addCase(getDemandActualDocuments.fulfilled, (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          bankGuaranteeDetailsActiveDemandDocumentsAdapter.setAll(state.documents, action.payload.body.data);
        }
      })
      .addMatcher(isAsyncThunkAction(getBankGuaranteeDetailsDemand), (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          state.fetch.status = getLoadingStatusFromAction(action);
        }
      })
      .addMatcher(isAsyncThunkAction(getDemandActualDocuments), (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          state.documents.status = getLoadingStatusFromAction(action);
        }
      });
  },
});

export const bankGuaranteeDetailsActiveDemandReducer = slice.reducer;
export const bankGuaranteeDetailsActiveDemandActions = slice.actions;
