import { useState } from "react";
import * as React from "react";
import { useResponsiveContext } from "@sideg/ui-kit";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { ArrowUpIcon } from "../../../../common/ui-kit/icons";
import * as S from "./ChatDialogFooter.styled";

export interface ChatDialogFooterProps {
  onSubmit: (value: string) => void | Promise<void>;
}

export const ChatDialogFooter = ({ onSubmit }: ChatDialogFooterProps) => {
  const [value, setValue] = useState("");
  const { isProbablyMobile } = useResponsiveContext();

  const handleSubmit = () => {
    if (value.trim().length > 0) {
      onSubmit(value);
      setValue("");
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey && !isProbablyMobile) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <S.Wrapper>
      <FormTextarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={onKeyPress}
        placeholder="Ваше сообщение"
        maxRows={2}
        minRows={1}
      />
      <S.Button onClick={handleSubmit} type="button">
        <ArrowUpIcon />
      </S.Button>
    </S.Wrapper>
  );
};
