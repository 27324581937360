import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ym from "react-yandex-metrika";
import { analyticsConfigs } from "../configs";

export const useGlobalAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (analyticsConfigs.ym.isEnabled) {
      ym("hit", location.pathname + location.search);
    }
  }, [location]);
};
