import { CREDIT_EXECUTION_ROOT_URL } from "../../common/urls";
import { makeAppUrl } from "../../../common/urls/internal/utils";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const creditExecutionListUrls = {
  root: makeAppUrl(
    CREDIT_EXECUTION_ROOT_URL,
    CREDIT_EXECUTION_ROOT_URL,
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCe),
  ),
};
