import {
  FlexPropsByType,
  FlexPropsType,
  FlexPropsWithoutPrefix,
  FlexPropsWithPrefix,
  FlexRestProps,
  FlexValidCssKeys,
} from "@sideg/ui-kit/atoms/containers/types/FlexboxProps";
import { makeResponsiveStylesBuilder } from "@sideg/ui-kit/common/utils";
import { CssStyleBuilder } from "@sideg/ui-kit/common/types";

type MappedKeys<TKeys extends FlexRestProps | FlexPropsWithPrefix | FlexPropsWithoutPrefix> = Record<
  keyof TKeys,
  FlexValidCssKeys
>;

const restKeys = {
  alignSelf: "alignSelf",
  alignItems: "alignItems",
  justifyContent: "justifyContent",
} satisfies MappedKeys<FlexRestProps>;

const prefixedKeys = {
  flexDirection: "flexDirection",
  flexGrow: "flexGrow",
  flexShrink: "flexShrink",
  flexBasis: "flexBasis",
  flexWrap: "flexWrap",
} satisfies MappedKeys<FlexPropsWithPrefix>;

const simplifiedKeys = {
  direction: "flexDirection",
  grow: "flexGrow",
  shrink: "flexShrink",
  basis: "flexBasis",
  wrap: "flexWrap",
} satisfies MappedKeys<FlexPropsWithoutPrefix>;

const getBuildersForKeys = <TProps extends FlexRestProps | FlexPropsWithPrefix | FlexPropsWithoutPrefix>(
  map: MappedKeys<TProps>,
) => {
  const responsive = makeResponsiveStylesBuilder<TProps>();

  return (Object.keys(map) as (keyof TProps)[]).map((key) =>
    responsive(key, (value) => (value ? { [map[key]]: value } : {})),
  );
};

export const getFlexboxPropsStyleBuilders = <TType extends FlexPropsType>(
  type: TType,
): CssStyleBuilder<FlexRestProps & FlexPropsByType<TType>>[] => {
  return [
    ...getBuildersForKeys(restKeys),
    ...getBuildersForKeys(type === "simplified" ? simplifiedKeys : prefixedKeys),
  ];
};
