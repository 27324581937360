import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { QueueTasksListFiltersProps } from "./QueueTasksListFiltersProps";
import { QueueTasksListFiltersMobile } from "./mobile/QueueTasksListFilters.mobile";
import { QueueTasksListFiltersDesktop } from "./desktop/QueueTasksListFilters.desktop";

export const QueueTasksListFilters = ({ filteringObject }: QueueTasksListFiltersProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={<QueueTasksListFiltersMobile filteringObject={filteringObject} />}
      desktop={<QueueTasksListFiltersDesktop filteringObject={filteringObject} />}
    />
  );
};
