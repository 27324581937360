import { useMemo } from "react";
import { useAppLayoutActiveOverlayPanel } from "../../../common/ui/layout/app-layout-containers";
import { chatDialogConfigs } from "../configs";

export const useChatDialogAppLayoutOverlayPanel = () => {
  const {
    isOpened,
    openPanel: openChat,
    closePanel: closeChat,
    togglePanel: toggleChat,
  } = useAppLayoutActiveOverlayPanel(chatDialogConfigs.overlayPanelKey);

  return useMemo(
    () => ({ isOpened, openChat, closeChat, toggleChat, key: chatDialogConfigs.overlayPanelKey }),
    [closeChat, isOpened, openChat, toggleChat],
  );
};
