import { clientV3, clientV4 } from "../../common/api";
import { ClientSearchOutputDto } from "./dto/output/ClientSearchOutputDto";
import { ClientSearchInputDto } from "./dto/input/ClientSearchInputDto";
import { ClientSearchCheckInputDto } from "./dto/input/ClientSearchCheckInputDto";
import { ClientSearchCheckOutputDto } from "./dto/output/ClientSearchCheckOutputDto";
import { ClientSearchFixedKey } from "../types/ClientSearchFixedKey";
import { ClientSearchGetNameInputDto } from "./dto/input/ClientSearchGetNameInputDto";
import { ClientSearchGetNameOutputDto } from "./dto/output/ClientSearchGetNameOutputDto";

type ProductField = {
  key: ClientSearchFixedKey;
};

export const clientSearchApi = {
  find: ({ clientNameOrTaxpayerNumber }: ClientSearchInputDto & ProductField, signal?: AbortSignal) =>
    clientV4.post<ClientSearchInputDto, ClientSearchOutputDto>(
      "/clients/find",
      { clientNameOrTaxpayerNumber },
      {
        abortSignal: signal,
      }
    ),
  check: async ({ clientId }: ClientSearchCheckInputDto & ProductField, signal?: AbortSignal) =>
    clientV4.post<ClientSearchCheckInputDto, ClientSearchCheckOutputDto>(
      "/clients/check",
      { clientId },
      {
        abortSignal: signal,
      }
    ),
  getName: async ({ taxpayerNumber }: ClientSearchGetNameInputDto, signal?: AbortSignal) =>
    clientV3.get<ClientSearchGetNameInputDto, ClientSearchGetNameOutputDto>(
      `/client/${taxpayerNumber}/name`,
      undefined,
      { abortSignal: signal }
    ),
};
