import { useMemo } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { MenuItem } from "../utils/getUserMenu";
import { useAppSelector } from "../../../store";

export const useMenuItem = (item: MenuItem) => {
  const location = useLocation();
  const match = matchRoutes(
    item.matches?.map((path) => ({ path })),
    location.pathname
  );

  const counter = useAppSelector((state) => item.counterSelector?.(state));

  return useMemo(() => ({ counter, isActive: match !== null }), [counter, match]);
};
