import * as React from "react";
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCombobox, useMultipleSelection } from "downshift";
import { useDebounce } from "../../../../hooks/useDebounce";
import { usePopup } from "../../../pop-up";
import { withComboboxCircularNavigation } from "../utils/withComboboxCircularNavigation";

export interface UseMultiselectWithInnerSearchInput<TItem> {
  selected: TItem[] | undefined;
  onSelectedChanged: (selectedItems: TItem[]) => void | Promise<void>;
  onSearchChanged: (search: string) => void | Promise<void>;
  searchInputDebounceMs?: number;
  getItems: () => TItem[];
}

export const useMultiselectWithInnerSearchInput = <TItem>({
  onSearchChanged,
  selected,
  onSelectedChanged,
  searchInputDebounceMs = 500,
  getItems,
}: UseMultiselectWithInnerSearchInput<TItem>) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [inputValue, setInputValue] = useState("");
  const [debounceInputValue] = useDebounce(inputValue, searchInputDebounceMs);

  const [defaultHighlightedIndex, setDefaultHighlightedIndex] = useState(0);

  useEffect(() => {
    onSearchChanged && onSearchChanged(debounceInputValue);
  }, [onSearchChanged, debounceInputValue]);

  const { getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } = useMultipleSelection({
    selectedItems: selected ?? [],
    onSelectedItemsChange: (x) => x.selectedItems && onSelectedChanged(x.selectedItems),
  });

  const {
    isOpen,
    highlightedIndex,
    setHighlightedIndex,
    getMenuProps: getMenuPropsCombobox,
    getToggleButtonProps,
    getItemProps,
    getInputProps,
    openMenu,
  } = useCombobox(
    withComboboxCircularNavigation({
      inputValue,
      defaultHighlightedIndex,
      selectedItem: null,
      items: getItems(),
      onIsOpenChange: (change) => {
        if (change.isOpen) {
          inputRef.current?.focus();
        }
      },
      stateReducer: (_state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              highlightedIndex: changes.highlightedIndex,
              isOpen: true,
            };
          default:
            return changes;
        }
      },
      onStateChange: ({ inputValue: input, type, selectedItem }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputChange:
            setInputValue(input ?? "");
            setHighlightedIndex(0);
            break;
          case useCombobox.stateChangeTypes.InputBlur:
            setInputValue("");
            onSearchChanged?.("");
            break;
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            if (selectedItem) {
              if (inputValue.trim() !== "") {
                setHighlightedIndex(0);
              }

              setInputValue("");
              onSearchChanged && onSearchChanged("");
              if (selectedItems.includes(selectedItem)) {
                removeSelectedItem(selectedItem);
              } else {
                addSelectedItem(selectedItem);
              }
            }
            break;
          default:
            break;
        }
      },
    }),
  );

  useEffect(() => {
    setDefaultHighlightedIndex(highlightedIndex);
  }, [highlightedIndex]);

  const { referenceElementProps: popupReferenceElementProps, popupProps } = usePopup({
    position: "bottom-start",
    offset: [0, 2],
  });

  const onButtonKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        openMenu();
      }
    },
    [openMenu],
  );

  const onInputClick = () => {
    // Обходим ограничение downshift, который считает клик по инпуту - блюром инпута
    // и вызывает событие __input_blur__ до срабатывания onClick
    openMenu();
  };

  const onInputChange = (e: FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  // https://github.com/downshift-js/downshift/issues/1540
  const buttonProps = useMemo(
    () =>
      getToggleButtonProps({
        ...getDropdownProps({ ref: popupReferenceElementProps.ref }),
        tabIndex: undefined,
        onKeyDown: onButtonKeyDown,
      }),
    [getDropdownProps, getToggleButtonProps, onButtonKeyDown, popupReferenceElementProps.ref],
  );

  const containerMenuProps = useMemo(
    () => getMenuPropsCombobox({ ref: popupProps.ref, style: popupProps.style }),
    [getMenuPropsCombobox, popupProps.ref, popupProps.style],
  );

  return {
    isOpen,
    selectedItems,
    highlightedIndex,
    buttonProps,
    popupReferenceElementProps,
    popupProps,
    containerMenuProps,
    getItemsMenuProps: getMenuPropsCombobox,
    getItemProps,
    inputProps: getInputProps({ ref: inputRef, value: inputValue, onChange: onInputChange, onClick: onInputClick }),
  };
};
