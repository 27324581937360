import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import { SpaceType } from "../../../../../../common/ui-kit/types";

export const Container = styled.div<{
  columnGap: SpaceType;
  rowGap: SpaceType;
  pt?: SpaceType;
  secondColumnWidth: "auto" | "max-content";
  placeItems?: CSSObject["placeItems"];
}>`
  display: grid;
  grid-template-columns: minmax(0, 185px) ${({ secondColumnWidth }) => secondColumnWidth};
  place-items: ${({ placeItems }) => placeItems};
  grid-auto-flow: row;
  column-gap: ${({ theme, columnGap }) => theme.spaces[columnGap]};
  row-gap: ${({ theme, rowGap }) => theme.spaces[rowGap]};
  padding-top: ${({ theme, pt }) => theme.spaces[pt ?? "none"]};
`;
