import {
  AppLayoutOverlayPanel,
  AppLayoutOverlayPanelContentWithHeader,
} from "../../../../../../../../common/ui/layout/app-layout-containers";
import { Box } from "../../../../../../../../common/ui-kit/containers/box";
import { BankGuaranteeDetailsDemandReconciliationConfirmPaymentForm } from "../../form/BankGuaranteeDetailsDemandReconciliationConfirmPaymentForm";
import { Typography } from "../../../../../../../../common/ui-kit/typography";
import { UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler } from "../../../../../../types/demand/reconciliation/UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler";
import { AppLayoutOverlayPanelKey } from "../../../../../../../../common/ui/layout/app-layout/types";
import { LoadingStatusEnum } from "../../../../../../../../common/types/state";

interface BankGuaranteeDetailsDemandReconciliationConfirmPaymentMobileProps {
  handleSubmit: UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler;
  onClose: () => void;
  panelKey: AppLayoutOverlayPanelKey;
  status: LoadingStatusEnum;
}

export const BankGuaranteeDetailsDemandReconciliationConfirmPaymentMobile = ({
  handleSubmit,
  onClose,
  panelKey,
  status,
}: BankGuaranteeDetailsDemandReconciliationConfirmPaymentMobileProps) => {
  return (
    <AppLayoutOverlayPanel panelKey={panelKey}>
      <AppLayoutOverlayPanelContentWithHeader onCloseClick={onClose}>
        <Box display="flex" flexDirection="column" flexGrow={1} gap="lg" padding="xxl">
          <Typography fontWeight={500} fontSize={2.25}>
            Подтверждение оплаты
          </Typography>
          <Typography fontSize="fz4" fontWeight={500}>
            Если вы можете подтвердить оплату счета клиентом, отправьте нам платежное поручение
          </Typography>
          <BankGuaranteeDetailsDemandReconciliationConfirmPaymentForm status={status} handleSubmit={handleSubmit} />
        </Box>
      </AppLayoutOverlayPanelContentWithHeader>
    </AppLayoutOverlayPanel>
  );
};
