import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectCreditExecutionDetailsActiveDemand } from "../store/active-demand/creditExecutionDetailsActiveDemand.selectors";
import { isIdle } from "../../../common/types/state";
import { getDemandActualDocuments } from "../../../demand/actual-documents";

export const useCreditExecutionDetailsDemandDocumentsFetch = () => {
  const dispatch = useAppDispatch();
  const {
    activeDemandId,
    documents: { status },
  } = useAppSelector(selectCreditExecutionDetailsActiveDemand);

  useEffect(() => {
    if (activeDemandId !== undefined && isIdle(status)) {
      dispatch(getDemandActualDocuments({ demandId: activeDemandId }));
    }
  }, [activeDemandId, dispatch, status]);
};
