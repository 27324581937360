import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsActiveTasksIdsByGroupType } from "../../../store/active-tasks/bankGuaranteeDetailsActiveTasks.selectors";
import { BankGuaranteeActiveTask } from "./BankGuaranteeActiveTask";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { ProductDetailsDemandActiveTasksSkeletonWrapper } from "../../../../../common/product-details";
import { selectBankGuaranteeDetailsActiveDemand } from "../../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { selectBankGuaranteeDetailsDemandById } from "../../../store/demands/bankGuaranteeDetailsDemands.selectors";
import { useBankGuaranteeDetailsFilterContext } from "../../../hooks/filters/context/useBankGuaranteeDetailsFilterContext";
import { useBankGuaranteeDetailsFilterRemoveQueueTaskIfDisappearEffect } from "../../../hooks/filters/useBankGuaranteeDetailsFilterRemoveQueueTaskIfDisappearEffect";

export type BankGuaranteeActiveTasksProps = BaseCssProps;

export const BankGuaranteeActiveTasks = ({ className }: BankGuaranteeActiveTasksProps) => {
  const {
    activeDemandId,
    fetch: { status: fetchStatus },
  } = useAppSelector(selectBankGuaranteeDetailsActiveDemand);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectBankGuaranteeDetailsDemandById(state, activeDemandId!));
  const { queueTaskGroup, queueTaskId } = useBankGuaranteeDetailsFilterContext();

  const tasksIds = useAppSelector((state) =>
    selectBankGuaranteeDetailsActiveTasksIdsByGroupType(state, queueTaskGroup),
  );

  useBankGuaranteeDetailsFilterRemoveQueueTaskIfDisappearEffect(queueTaskId);

  return (
    <ProductDetailsDemandActiveTasksSkeletonWrapper
      demandTasksCount={demand?.countActiveTasks}
      status={fetchStatus}
      fetchedTasksCount={tasksIds.length}
      className={className}
    >
      {tasksIds.map((id) => (
        <BankGuaranteeActiveTask queueTaskId={id} key={id} />
      ))}
    </ProductDetailsDemandActiveTasksSkeletonWrapper>
  );
};
