import { GetQueueTasksListInputDto } from "./input/GetQueueTasksListInputDto";
import { GetQueueTasksListOutputDto } from "./output/GetQueueTasksListOutputDto";
import { clientV4 } from "../../../../common/api";
import { GetQueueTasksListFilterOutputDto } from "./output/GetQueueTasksListFilterOutputDto";
import { GetQueueTasksListCountersOutputDto } from "./output/GetQueueTasksListCountersOutputDto";
import { QUEUE_TASKS_CONTROLLER_NAME } from "../../../common/api/queueTasksApi";

export const queueTasksListApi = {
  get: (filter?: GetQueueTasksListInputDto, signal?: AbortSignal) =>
    clientV4.get<GetQueueTasksListInputDto, GetQueueTasksListOutputDto>(QUEUE_TASKS_CONTROLLER_NAME, filter, {
      abortSignal: signal,
    }),
  getFilters: () => clientV4.get<undefined, GetQueueTasksListFilterOutputDto>(`${QUEUE_TASKS_CONTROLLER_NAME}/filter`),
  getCounters: () =>
    clientV4.get<undefined, GetQueueTasksListCountersOutputDto>(`${QUEUE_TASKS_CONTROLLER_NAME}/counters`),
};
