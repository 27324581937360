import * as React from "react";
import { Modal } from "../../Modal";
import AgentReportCreateForm from "./AgentReportCreateForm";

const AgentReportCreateModal: React.FC = () => {
  return (
    <Modal title={"Запросить акт сверки"} message={"Выберите период"}>
      <AgentReportCreateForm />
    </Modal>
  );
};

export default AgentReportCreateModal;
