import styled from "@emotion/styled";

export const CheckBoxWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;

  li {
    margin: ${({ theme }) => theme.spaces.md} 0;
  }
`;
