import { ReactNode } from "react";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { RouterLinkButton } from "../../../../../links";
import { ChevronLeftIcon } from "../../../../../../ui-kit/icons";
import { Typography } from "../../../../../../ui-kit/typography";
import { BaseCssProps } from "../../../../../../ui-kit/types";

export interface FullPageContainerHeaderBackLinkButtonProps extends BaseCssProps {
  url: string;
  title: ReactNode;
}

export const FullPageContainerHeaderBackLinkButton = ({
  url,
  title,
  className,
}: FullPageContainerHeaderBackLinkButtonProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={
        <RouterLinkButton
          className={className}
          to={url}
          color="secondary"
          variant="contained"
          size="default"
          startIcon={ChevronLeftIcon}
        />
      }
      desktop={
        <RouterLinkButton to={url} className={className} variant="outlined" size="default" startIcon={ChevronLeftIcon}>
          <Typography fontSize="fz4">{title}</Typography>
        </RouterLinkButton>
      }
    />
  );
};
