import {
  ScoringDispatchTypes,
  SCORING_OPTIONS_SET_INITIAL,
  SCORING_OPTIONS_FETCHING,
  SCORING_OPTIONS_FETCHED,
  SCORING_OPTIONS_FETCHING_ERROR,
  SCORING_DATA_SET_INITIAL,
  SCORING_DATA_PROCESSING,
  SCORING_DATA_SUCCESS,
  SCORING_DATA_ERROR,
} from "../actions/scoring/scoringActionTypes";
import { ScoringModel } from "../../entity/models/ScoringModel";
import { ScoringBankModel } from "../../entity/models/ScoringBankModel";
import RequestStatus from "../../entity/models/enums/RequestStatus";

interface ScoringState {
  scoringOptionsStatus: RequestStatus;
  scoringDataProcessingStatus: RequestStatus;
  scoringOptions: ScoringModel | null;
  scoringData: ScoringBankModel | null;
}

const initialState: ScoringState = {
  scoringOptionsStatus: RequestStatus.Initial,
  scoringDataProcessingStatus: RequestStatus.Initial,
  scoringOptions: null,
  scoringData: null,
};

export const scoringReducer = (state: ScoringState = initialState, action: ScoringDispatchTypes): ScoringState => {
  switch (action.type) {
    case SCORING_OPTIONS_SET_INITIAL:
      return {
        ...state,
        scoringOptionsStatus: RequestStatus.Initial,
      };
    case SCORING_OPTIONS_FETCHING:
      return {
        ...state,
        scoringOptionsStatus: RequestStatus.Request,
      };
    case SCORING_OPTIONS_FETCHED:
      return {
        ...state,
        scoringOptionsStatus: RequestStatus.Success,
        scoringOptions: action.payload,
      };
    case SCORING_OPTIONS_FETCHING_ERROR:
      return {
        ...state,
        scoringOptionsStatus: RequestStatus.Failure,
      };
    case SCORING_DATA_SET_INITIAL:
      return {
        ...state,
        scoringDataProcessingStatus: RequestStatus.Initial,
      };
    case SCORING_DATA_PROCESSING:
      return {
        ...state,
        scoringDataProcessingStatus: RequestStatus.Request,
      };
    case SCORING_DATA_SUCCESS:
      return {
        ...state,
        scoringDataProcessingStatus: RequestStatus.Success,
        scoringData: action.payload,
      };
    case SCORING_DATA_ERROR:
      return {
        ...state,
        scoringDataProcessingStatus: RequestStatus.Failure,
      };
    default:
      return state;
  }
};
