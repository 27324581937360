import styled from "@emotion/styled";

export const ProductCreationScoringSumLimitButton = styled.button`
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.primary};
  background: none;
  line-height: 1.1;
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz2};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  &:focus-visible {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primaryDark};
  }

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    font-size: ${({ theme }) => theme.font.fz3};
  }
`;
