import * as React from "react";
import { InferType } from "yup";
import { ProductDetailsLayoutWrapper } from "../../../common/product-details";
import { useFullPageLayout } from "../../../common/ui/layout";
import { useScrollToTopOnMountOrLocationPathChange } from "../../../common/hooks/scroll";
import { CreditExecutionDetailsNavigationHeader } from "../component/navigation-header/CreditExecutionDetailsNavigationHeader";
import { CreditExecutionDetailsContext } from "../store/context/CreditExecutionDetailsContext";
import { useAppUrlMatch } from "../../../common/urls/internal/hooks";
import { CreditExecutionDetailsAside } from "../component/aside/CreditExecutionDetailsAside";
import { useCreditExecutionDetailsFetcher } from "../hooks/useCreditExecutionDetailsFetcher";
import { withValidUrlParams } from "../../../common/urls/internal/HOCs";
import { creditExecutionDetailsUrlValidationSchema } from "../validation/creditExecutionDetailsUrlValidationSchema";
import { Redirect } from "../../../common/urls/internal/components";
import { useCreditExecutionDetailsMatchListener } from "../hooks/context/useCreditExecutionDetailsMatchListener";
import { useCreditExecutionDetailsQueueTasksNeedUpdateListener } from "../hooks/useCreditExecutionDetailsQueueTasksNeedUpdateListener";

export interface CreditExecutionDetailsPageLayoutProps {
  children?: React.ReactNode;
  match: InferType<typeof creditExecutionDetailsUrlValidationSchema>;
}

const Layout = ({ children, match }: CreditExecutionDetailsPageLayoutProps) => {
  useFullPageLayout();
  useScrollToTopOnMountOrLocationPathChange();

  const { isExact: isRootPath } = useAppUrlMatch((x) => x.creditExecution.details.creditExecution, {
    creditExecutionId: match.creditExecutionId,
  });

  useCreditExecutionDetailsMatchListener(match);
  useCreditExecutionDetailsFetcher(match.creditExecutionId);
  useCreditExecutionDetailsQueueTasksNeedUpdateListener(match.creditExecutionId);

  return (
    <CreditExecutionDetailsContext.Provider value={match}>
      <CreditExecutionDetailsNavigationHeader />
      <ProductDetailsLayoutWrapper aside={<CreditExecutionDetailsAside />} isRootPath={isRootPath}>
        {children}
      </ProductDetailsLayoutWrapper>
    </CreditExecutionDetailsContext.Provider>
  );
};

export const CreditExecutionDetailsPageLayout = withValidUrlParams(
  Layout,
  (x) => x.creditExecution.details.demand,
  creditExecutionDetailsUrlValidationSchema,
  <Redirect to={(x) => x.creditExecution.list.root} />
);
