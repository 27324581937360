import { QueueTaskId } from "../../../../common/types/demand";
import { useBankGuaranteeDetailsFilterContext } from "./context/useBankGuaranteeDetailsFilterContext";
import { useBankGuaranteeDetailsFilterApiContext } from "./context/useBankGuaranteeDetailsFilterApiContext";
import { GuaranteeQueueTasksGroupType } from "../../../../common/types/queue-tasks";

export const useBankGuaranteeDetailsFilterOnScrollToQueueTaskClickHandler = (
  queueTaskId: QueueTaskId,
  queueTaskGroup: GuaranteeQueueTasksGroupType,
) => {
  const filterContext = useBankGuaranteeDetailsFilterContext();
  const mutator = useBankGuaranteeDetailsFilterApiContext();

  return () => {
    mutator(filterContext, (service) => service.setQueueTaskId(queueTaskId, queueTaskGroup));
  };
};
