import { useHub } from "../../../../common/hubs";
import { chatDialogHubConnection } from "../../api/chatDialogHubConnection";
import { useChatDialogNewMessageDispatchEvent } from "./useChatDialogNewMessageDispatchEvent";
import { useChatDialogFetchFirstPage } from "../messages/useChatDialogFetchFirstPage";
import { useChatDialogMarkReadDispatchEvent } from "./useChatDialogMarkReadDispatchEvent";

export const useChatDialogHub = () => {
  useHub(chatDialogHubConnection);

  useChatDialogFetchFirstPage();
  useChatDialogNewMessageDispatchEvent();
  useChatDialogMarkReadDispatchEvent();
};
