import { clientV2 } from "../../../common/api";
import { GetDebtorDocumentsFactoringCreationOutputDto } from "./dto/output/GetDebtorDocumentsFactoringCreationOutputDto";
import { CreateFactoringCreationInputDto } from "./dto/input/CreateFactoringCreationInputDto";
import { GetDebtorDocumentsFactoringCreationInputDto } from "./dto/input/GetDebtorDocumentsFactoringCreationInputDto";
import { EmptyOutputDto } from "../../../common/api/types";

export const factoringCreationApi = {
  create: (model: CreateFactoringCreationInputDto) =>
    clientV2.postForm<CreateFactoringCreationInputDto, EmptyOutputDto>("/MiscProduct/Factoring", model),
  getDebtorDocuments: () =>
    clientV2.get<GetDebtorDocumentsFactoringCreationInputDto, GetDebtorDocumentsFactoringCreationOutputDto>(
      "/Information/DocumentList",
      {
        bankCode: "FactoringService",
        legalForm: "legal",
        productType: "Factoring",
      },
    ),
};
