import { combineSliceNames } from "../../../../common/store/utils";
import { CREDIT_WORKING_ASSETS_SLICE_NAME } from "../../store";
import { createApiThunks } from "../../../../common/store/utils/createApiThunk";
import { creditWorkingAssetsRejectionApi } from "../api/creditWorkingAssetsRejectionApi";

const SLICE_NAME = combineSliceNames(CREDIT_WORKING_ASSETS_SLICE_NAME, "rejection");

export const {
  cancelProduct: cancelProductCreditWorkingAssetsRejection,
  cancelDemand: cancelDemandCreditWorkingAssetsRejection,
} = createApiThunks(SLICE_NAME, creditWorkingAssetsRejectionApi);
