import { ReactNode } from "react";
import {
  AppPublicLayoutBlockWrapper,
  AppPublicLayoutLinkButton,
} from "../../../common/ui/layout/app-public-layout-containers";

export interface PublicUserRegistrationPageLayoutProps {
  children?: ReactNode;
}

export const PublicUserRegistrationPageLayout = ({ children }: PublicUserRegistrationPageLayoutProps) => {
  return (
    <AppPublicLayoutBlockWrapper>
      {children}
      <AppPublicLayoutLinkButton to={(x) => x.publicUser.authentication.login}>
        Я уже зарегистрирован
      </AppPublicLayoutLinkButton>
    </AppPublicLayoutBlockWrapper>
  );
};
