import styled from "@emotion/styled";
import { ElementWithErrorProps } from "../types/ElementWithErrorProps";

export const ErrorWrapper = styled.div<ElementWithErrorProps>`
  transition-property: height;
  transition-timing-function: ease;
  width: 100%;
  height: ${({ error }) => (error ? "auto" : "0px")};
  overflow: hidden;
  transition-duration: ${({ error }) => (error ? "unset" : "250ms")};
`;

export const Error = styled.div<ElementWithErrorProps>`
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.input.errorText};
  margin-bottom: -1px;
  opacity: ${({ error }) => (error ? 1 : 0)};
  text-align: left;
  transition: opacity 0.25s;
  transition-delay: ${({ error }) => (error ? ".1s" : "unset")};
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: 1.2;
`;
