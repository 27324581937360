import { useFormikContext } from "formik";
import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { useCreditExecutionListFilterContext } from "../../../../../hooks/filters/useCreditExecutionListFilterContext";
import { useCustomFieldOnChange, useGetFieldError, useGetFieldName } from "../../../../../../../common/form";
import { useAppSelector } from "../../../../../../../common/store";
import {
  FilterFormMobileCheckboxesWithCollapse,
  FilterMobileCollapseUncontrolledContainer,
} from "../../../../../../../common/filters/use-cases";
import { Grid } from "../../../../../../../common/ui-kit/containers/responsive-grid";
import { CreditExecutionListHeaderFiltersBanksMobile } from "./CreditExecutionListHeaderFiltersBanks.mobile";
import * as S from "./CreditExecutionListHeaderFiltersFormContent.mobile.styled";
import { FormSelectMobile } from "../../../../../../../common/ui-kit/form-controls/select";
import { UNSELECTED_ENUM_LITERAL } from "../../../../../../../common/types/utils";
import { CreditExecutionListFiltersMobile } from "../../../../../types/filters/CreditExecutionListFiltersMobile";
import { selectCreditExecutionFiltersData } from "../../../../../store/filters/creditExecutionListFilters.selectors";
import { isFilterAvailableForTabCreditExecution } from "../../../../../validation/rules/isFilterAvailableForTabCreditExecution";

export type CreditExecutionListHeaderFiltersFormContentMobileProps = BaseCssProps;

export const CreditExecutionListHeaderFiltersFormContentMobile = ({
  className,
}: CreditExecutionListHeaderFiltersFormContentMobileProps) => {
  const filteringObject = useCreditExecutionListFilterContext();
  const getFieldName = useGetFieldName<CreditExecutionListFiltersMobile>();
  const getFieldError = useGetFieldError<CreditExecutionListFiltersMobile>();
  const form = useFormikContext<CreditExecutionListFiltersMobile>();
  const onCustomChange = useCustomFieldOnChange<CreditExecutionListFiltersMobile>();

  const data = useAppSelector(selectCreditExecutionFiltersData);

  const isForTab = (filter: keyof CreditExecutionListFiltersMobile) =>
    isFilterAvailableForTabCreditExecution(filteringObject.tab, filter);

  return (
    <S.Wrapper className={className}>
      {data !== undefined && (
        <>
          {data?.statuses && isForTab("status") && (
            <FormSelectMobile label="Статус" {...form.getFieldProps(getFieldName("status"))}>
              <option value={UNSELECTED_ENUM_LITERAL}>Все статусы</option>
              {data.statuses.codeWithTitleList.map((x) => (
                <option value={x.code} key={x.code}>
                  {x.title}
                </option>
              ))}
            </FormSelectMobile>
          )}
          {data?.taskTypes && isForTab("taskTypes") && (
            <FilterFormMobileCheckboxesWithCollapse<CreditExecutionListFiltersMobile>
              title="Задачи"
              fieldName="taskTypes"
              items={data.taskTypes.codeWithTitleList}
            />
          )}
        </>
      )}
      {isForTab("dateCreateTo") && isForTab("dateCreateFrom") && (
        <FilterMobileCollapseUncontrolledContainer title="Дата создания">
          <Grid container gap="md">
            <Grid
              item
              as={DateFormInput}
              label="Дата начала"
              xs={6}
              {...form.getFieldProps(getFieldName("dateCreateFrom"))}
              onChange={(e: string) => onCustomChange(form.setFieldValue, "dateCreateFrom", e, true)}
              error={getFieldError("dateCreateFrom", form.touched.dateCreateFrom, form.errors.dateCreateFrom)}
              autoComplete="off"
            />
            <Grid
              item
              as={DateFormInput}
              label="Дата окончания"
              xs={6}
              {...form.getFieldProps(getFieldName("dateCreateTo"))}
              onChange={(e: string) => onCustomChange(form.setFieldValue, "dateCreateTo", e, true)}
              error={getFieldError("dateCreateTo", form.touched.dateCreateTo, form.errors.dateCreateTo)}
              autoComplete="off"
            />
          </Grid>
        </FilterMobileCollapseUncontrolledContainer>
      )}
      {data?.bankIds && isForTab("bankIds") && <CreditExecutionListHeaderFiltersBanksMobile />}
    </S.Wrapper>
  );
};
