import { DateFormats, dateTimeHelper } from "@sideg/helpers/date";

export const dateFormInputHandleChange = (inputStringValue: string, format: DateFormats) => {
  const isDate = dateTimeHelper.isDateMatch(inputStringValue, format);

  let pickerDate = "";
  let inputCorrectValue = inputStringValue;

  if (isDate) {
    const date = dateTimeHelper.parseDateFromString(inputStringValue, format);

    pickerDate = dateTimeHelper.format(date, "yearMonthDayDashed");
    inputCorrectValue = dateTimeHelper.format(date, "dateOnly");
  }

  return [pickerDate, inputCorrectValue];
};
