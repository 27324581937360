import * as S from "./GlobalToastsContainer.styled";
import { GlobalToasts } from "./GlobalToasts";
import { PortalContainer } from "../../../common/ui/containers/portal";

export const GlobalToastsContainer = () => {
  return (
    <PortalContainer htmlElementId={import.meta.env.VITE_TOAST_CONTAINER_ID ?? ""}>
      <S.Container>
        <GlobalToasts />
      </S.Container>
    </PortalContainer>
  );
};
