import { useMemo, useState } from "react";
import { useField } from "formik";
import { FieldNames } from "../../../form/types/FieldNames";
import { useGetFieldName } from "../../../form";
import { BankCreationModelWithCreditSum, CodeWithTitle } from "../../../types/objects";
import { ProductCreationScoringFormValues } from "../../types/scoring/ProductCreationScoringFormValues";

type CreditSumFormValue = number | null;

type BankFieldValues = Omit<BankCreationModelWithCreditSum, "creditSum"> & {
  creditSum: CreditSumFormValue;
  creditSumFrom?: number | null;
  creditSumTo?: number | null;
};

export interface UseCreationCreditScoringBankFieldArgs {
  bank: CodeWithTitle;
  creditSumFrom?: number;
  creditSumTo?: number;
  creditSum?: number;
}

export const useCreationCreditScoringBankField = ({
  bank,
  creditSumTo,
  creditSumFrom,
  creditSum,
}: UseCreationCreditScoringBankFieldArgs) => {
  const getFieldName = useGetFieldName<ProductCreationScoringFormValues>();
  const [fieldName] = useState(`${getFieldName("banks")}.${bank.code}`);

  const [isAutoFocusOnSumInput, setIsAutoFocusOnSumInput] = useState(false);
  const [{ value }, , { setValue }] = useField<BankFieldValues | undefined>(fieldName);

  return useMemo(() => {
    const isSelected = value !== undefined;

    const getFieldValue = (sum: CreditSumFormValue): BankFieldValues => {
      return {
        bankId: bank.code,
        creditSumFrom,
        creditSumTo,
        creditSum: sum,
      };
    };

    const setFieldValues = async (fieldValue: BankFieldValues | undefined) => {
      await setValue(fieldValue, true);
    };

    const toggleBank = async () => {
      let fieldValue: BankFieldValues | undefined;

      if (!isSelected) {
        const sum = creditSum === undefined ? null : Math.min(creditSum, creditSumTo ?? creditSum);
        fieldValue = getFieldValue(sum);
      }

      await setFieldValues(fieldValue);
      setIsAutoFocusOnSumInput(!isSelected && creditSum === undefined);
    };

    const onLimitSet = async (limitValue: number) => {
      await setFieldValues(getFieldValue(limitValue));
      setIsAutoFocusOnSumInput(false);
    };

    const getNestedFieldName = (name: FieldNames<BankFieldValues>) => `${fieldName}.${name}`;

    return {
      isSelected,
      isAutoFocusOnSumInput,
      toggleBank,
      onLimitSet,
      getNestedFieldName,
    };
  }, [bank.code, creditSum, creditSumFrom, creditSumTo, fieldName, isAutoFocusOnSumInput, setValue, value]);
};
