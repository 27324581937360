import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../ui-kit/types";
import { ProductCreationScoringResultRowReject } from "./ProductCreationScoringResultRowReject";

export type ProductCreationScoringResultRowRejectSkeletonProps = BaseCssProps;

export const ProductCreationScoringResultRowRejectSkeleton = ({
  className,
}: ProductCreationScoringResultRowRejectSkeletonProps) => {
  return (
    <ProductCreationScoringResultRowReject
      className={className}
      bankName={<Skeleton width={150} />}
      text={<Skeleton count={1.4} />}
    />
  );
};
