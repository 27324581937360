import { forwardRef } from "react";
import { PatternFormat, PatternFormatProps } from "react-number-format";
import { FormInput, FormInputProps } from "@sideg/ui-kit/atoms/form-controls/input";

export interface PatternNumberFormatInputProps
  extends Omit<FormInputProps, "ref" | "type" | "value" | "defaultValue">,
    Omit<PatternFormatProps, "customInput" | "ref" | "getInputRef" | "size"> {
  value?: string;
}

export const PatternNumberFormatInput = forwardRef<HTMLInputElement, PatternNumberFormatInputProps>((props, ref) => {
  return <PatternFormat customInput={FormInput} {...props} getInputRef={ref} />;
});
