import * as React from "react";

export interface ProductListCardMoreAction<TKey extends string = string> {
  key: TKey;
  title: string;
  icon: React.ElementType;
}

export interface ProductListCardMoreActionWithHandler<TKey extends string = string>
  extends ProductListCardMoreAction<TKey> {
  handler: () => void | Promise<void>;
}

export const makeProductListCardMoreAction = <TKey extends string>(
  key: TKey,
  title: string,
  icon: React.ElementType
): ProductListCardMoreAction<TKey> => ({ key, title, icon });
