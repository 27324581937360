import { useCallback, useMemo } from "react";
import { createBankGuaranteeCreation } from "../../../store/bankGuaranteeCreation.thunks";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeCreationCreating } from "../../../store/bankGuaranteeCreation.selectors";
import { useAppNavigation } from "../../../../../common/urls/internal/hooks";
import { useApiMutation } from "../../../../../common/hooks";
import { BankGuaranteeCreationSubmitResult } from "../../../types/creation-submit/BankGuaranteeCreationSubmitResult";

export const useBankGuaranteeCreationSubmitCreateNew = (): BankGuaranteeCreationSubmitResult => {
  const { data: creationData } = useAppSelector(selectBankGuaranteeCreationCreating);
  const { error, mutate, status } = useApiMutation(createBankGuaranteeCreation, {
    errorMessagePlaceholder:
      "Произошла непредвиденная ошибка. Попробуйте еще раз или свяжитесь с персональным менеджером",
  });
  const navigator = useAppNavigation();

  const onSubmit = useCallback(
    async (bankIds: string[]) => {
      if (creationData) {
        const result = await mutate({ ...creationData, bankIds });
        if (result?.body.guaranteeId) {
          navigator((x) => x.bankGuarantee.details.documents, { guaranteeId: result.body.guaranteeId });
        }
      }
    },
    [creationData, mutate, navigator]
  );

  return useMemo(() => ({ status, error, onSubmit }), [error, onSubmit, status]);
};
