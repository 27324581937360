import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import {
  AppLayoutOverlayPanel,
  useAppLayoutActiveOverlayPanel,
} from "../../../../../../common/ui/layout/app-layout-containers";
import { FiltersMobilePanel, FiltersSlidersIconButton } from "../../../../../../common/filters/use-cases";
import { creditWorkingAssetsListConfigs } from "../../../../configs";
import { CreditWorkingAssetsListHeaderFiltersFormContentMobile } from "./form/CreditWorkingAssetsListHeaderFiltersFormContent.mobile";
import { useCreditWorkingAssetsListFilterContext } from "../../../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { creditWorkingAssetsListFiltersMobileValidationSchema } from "../../../../validation/creditWorkingAssetsListFiltersMobileValidationSchema";

export type CreditWorkingAssetsListHeaderFiltersOpenButtonMobileProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFiltersOpenButtonMobile = ({
  className,
}: CreditWorkingAssetsListHeaderFiltersOpenButtonMobileProps) => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();

  const { openPanel, closePanel } = useAppLayoutActiveOverlayPanel(
    creditWorkingAssetsListConfigs.filtersOverlayPanelKey
  );

  return (
    <>
      <FiltersSlidersIconButton onClick={openPanel} className={className} />
      <AppLayoutOverlayPanel panelKey={creditWorkingAssetsListConfigs.filtersOverlayPanelKey}>
        <FiltersMobilePanel
          filteringObject={filteringObject}
          schema={creditWorkingAssetsListFiltersMobileValidationSchema}
          onCloseClick={closePanel}
        >
          <CreditWorkingAssetsListHeaderFiltersFormContentMobile />
        </FiltersMobilePanel>
      </AppLayoutOverlayPanel>
    </>
  );
};
