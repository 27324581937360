import { useMemo } from "react";
import { FilteringArrayKeys } from "../../types/FilteringArrayKeys";
import { RootState, useAppSelector } from "../../../store";
import { EntitiesCodeWithTitle } from "../../../types/objects";
import { PaginatedFilteringObjectType } from "../../types/PaginatedFilteringObjectType";
import { usePaginatedQueryStateMutator } from "./usePaginatedQueryStateMutator";

export interface UseProductFilterArrayArgs<
  TFilteringObject extends PaginatedFilteringObjectType,
  TKey extends FilteringArrayKeys<TFilteringObject>,
  TItem extends TFilteringObject[TKey] extends (infer U)[] | undefined ? (U extends string ? U : string) : string
> {
  filteringObject: TFilteringObject;
  key: TKey;
  availableFiltersSelector: (state: RootState) => undefined | Record<TKey, EntitiesCodeWithTitle<TItem>>;
  customFilteredItems?: TItem[];
}

export interface UseProductFilterArrayReturnType<TItem> {
  getItems: () => TItem[];
  selectedItems: TItem[];
  getItemTitle: (item: TItem) => string;
  onSelectedChange: (selectedItems: TItem[]) => void | Promise<void>;
  maxItemLength?: number;
}

export const useProductFilterArray = <
  TFilteringObject extends PaginatedFilteringObjectType,
  TKey extends FilteringArrayKeys<TFilteringObject>,
  TItem extends TFilteringObject[TKey] extends (infer U)[] | undefined ? (U extends string ? U : string) : string
>({
  filteringObject,
  key,
  availableFiltersSelector,
  customFilteredItems,
}: UseProductFilterArrayArgs<TFilteringObject, TKey, TItem>): UseProductFilterArrayReturnType<TItem> => {
  const mutator = usePaginatedQueryStateMutator();

  const filters = useAppSelector(availableFiltersSelector);

  return useMemo(() => {
    const state = filters?.[key];
    const getItemTitle = (item: TItem) => state?.titles[item] ?? item;
    const getItems = () => customFilteredItems ?? state?.ids ?? [];

    const onSelectedChange = (selectedItems: TItem[]) => {
      mutator(filteringObject, (filter, { setValue }) =>
        setValue(filter, key, selectedItems as unknown as TFilteringObject[TKey])
      );
    };

    return {
      getItems,
      getItemTitle,
      selectedItems: (filteringObject[key] as TItem[]) ?? [],
      onSelectedChange,
      maxItemLength: state?.maxTitleLength,
    };
  }, [customFilteredItems, filteringObject, filters, key, mutator]);
};
