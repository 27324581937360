import { Dispatch } from "redux";
import {
  AgentReportAction,
  AgentReportActionPage,
  AgentReportSignCodeForm,
  AgentReportSignForm,
} from "./agentReportActionsTypes";

import {
  agentReportGetItem,
  agentReportGetList,
  agentReportGetSignCode,
  agentReportSendCreateForm,
  agentReportSendSignCode,
  agentReportSendSignFile,
  agentReportSendUpdateForm,
} from "../../api/agentReportApi";
import TabType from "../../models/TabType";
import { AgentReportCreateForm } from "./agentReportCreateActionsTypes";
import ApiResponse from "../../models/ApiResponse";
import { AgentReportUpdateForm } from "./agentReportUpdateActionsType";
import AgentReportSignCodeModel from "../../models/AgentReportSignCodeModel";
import AgentReportSignModel from "../../models/AgentReportSignModel";

export const agentReportListInitial = () => async (dispatch: Dispatch) => {
  dispatch({
    page: AgentReportActionPage.List,
    type: AgentReportAction.Initial,
  });
};

export const agentReportItemInitial = () => async (dispatch: Dispatch) => {
  dispatch({
    page: AgentReportActionPage.Item,
    type: AgentReportAction.Initial,
  });
};

export const agentReportCreateInitial = () => async (dispatch: Dispatch) => {
  dispatch({
    page: AgentReportActionPage.Create,
    type: AgentReportAction.Initial,
  });
};

export const agentReportUpdateInitial = () => async (dispatch: Dispatch) => {
  dispatch({
    page: AgentReportActionPage.Update,
    type: AgentReportAction.Initial,
  });
};

export const agentReportListLoad = (tab: TabType, page: number, itemPerPage: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      page: AgentReportActionPage.List,
      type: AgentReportAction.Processing,
    });

    const response = agentReportGetList(tab, page, itemPerPage);
    response
      .then((response: any) => {
        const data = response.data;
        if (data.code === 200 && !data.error) {
          dispatch({
            page: AgentReportActionPage.List,
            type: AgentReportAction.Success,
            payload: {
              data: data.body,
            },
          });
        } else {
          dispatch({
            page: AgentReportActionPage.List,
            type: AgentReportAction.Failed,
            payload: {
              errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          page: AgentReportActionPage.List,
          type: AgentReportAction.Failed,
          payload: {
            errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
          },
        });
      });
  } catch (e) {
    dispatch({
      page: AgentReportActionPage.List,
      type: AgentReportAction.Failed,
      payload: {
        errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
      },
    });
  }
};

export const agentReportItemLoad = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      page: AgentReportActionPage.Item,
      type: AgentReportAction.Processing,
    });

    const response = agentReportGetItem(id);
    response
      .then((response: any) => {
        const data = response.data;
        if (data.code === 200 && !data.error) {
          dispatch({
            page: AgentReportActionPage.Item,
            type: AgentReportAction.Success,
            payload: {
              data: data.body,
            },
          });
        } else {
          dispatch({
            page: AgentReportActionPage.Item,
            type: AgentReportAction.Failed,
            payload: {
              errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          page: AgentReportActionPage.Item,
          type: AgentReportAction.Failed,
          payload: {
            errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
          },
        });
      });
  } catch (e) {
    dispatch({
      page: AgentReportActionPage.Item,
      type: AgentReportAction.Failed,
      payload: {
        errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
      },
    });
  }
};

export const agentReportCreateSend = (form: AgentReportCreateForm) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      page: AgentReportActionPage.Create,
      type: AgentReportAction.Processing,
    });

    const response = agentReportSendCreateForm(form);
    response
      .then((response: any) => {
        const data = response.data as ApiResponse<boolean>;
        if (data.code === 200 && !data.error) {
          dispatch({
            page: AgentReportActionPage.Create,
            type: AgentReportAction.Success,
          });
        } else {
          dispatch({
            page: AgentReportActionPage.Create,
            type: AgentReportAction.Failed,
            payload: {
              errorMessage: data.errors?.map((error) => error.message).join(", "),
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          page: AgentReportActionPage.Create,
          type: AgentReportAction.Failed,
          payload: {
            errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
          },
        });
      });
  } catch (e) {
    dispatch({
      page: AgentReportActionPage.Create,
      type: AgentReportAction.Failed,
      payload: {
        errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
      },
    });
  }
};

export const agentReportUpdateSend = (id: number, form: AgentReportUpdateForm) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      page: AgentReportActionPage.Update,
      type: AgentReportAction.Processing,
    });

    const response = agentReportSendUpdateForm(id, form);
    response
      .then((response: any) => {
        const data = response.data;
        if (data.code === 200 && !data.error) {
          dispatch({
            page: AgentReportActionPage.Update,
            type: AgentReportAction.Success,
          });
        } else {
          dispatch({
            page: AgentReportActionPage.Update,
            type: AgentReportAction.Failed,
            payload: {
              errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          page: AgentReportActionPage.Update,
          type: AgentReportAction.Failed,
          payload: {
            errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
          },
        });
      });
  } catch (e) {
    dispatch({
      page: AgentReportActionPage.Update,
      type: AgentReportAction.Failed,
      payload: {
        errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
      },
    });
  }
};

export const agentReportSignCodeRequest =
  (form: AgentReportSignCodeForm, callback: (state: any) => void) => async (dispatch: Dispatch) => {
    try {
      callback({
        type: AgentReportAction.Processing,
      });

      const response = agentReportGetSignCode(form);
      response
        .then((response: any) => {
          const data = response.data as ApiResponse<AgentReportSignCodeModel>;
          if (data.code === 200) {
            callback({
              type: AgentReportAction.Success,
              payload: {
                data: data.body,
              },
            });
          } else {
            callback({
              type: AgentReportAction.Failed,
              payload: {
                errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
              },
            });
          }
        })
        .catch((error) => {
          callback({
            type: AgentReportAction.Failed,
            payload: {
              errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
            },
          });
        });
    } catch (e) {
      callback({
        type: AgentReportAction.Failed,
        payload: {
          errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
        },
      });
    }
  };

export const agentReportSignCodeSend =
  (form: AgentReportSignCodeForm, callback: (state: any) => void) => async (dispatch: Dispatch) => {
    try {
      callback({
        type: AgentReportAction.Processing,
      });

      const response = agentReportSendSignCode(form);
      response
        .then((response: any) => {
          const data = response.data as ApiResponse<AgentReportSignCodeModel>;
          if (data.code === 200) {
            callback({
              type: AgentReportAction.Success,
              payload: {
                data: data.body,
              },
            });
          } else {
            callback({
              type: AgentReportAction.Failed,
              payload: {
                errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
              },
            });
          }
        })
        .catch((error) => {
          callback({
            type: AgentReportAction.Failed,
            payload: {
              errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
            },
          });
        });
    } catch (e) {
      callback({
        type: AgentReportAction.Failed,
        payload: {
          errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
        },
      });
    }
  };

export const agentReportSignFile =
  (id: number, form: AgentReportSignForm, callback: (state: any) => void) => async (dispatch: Dispatch) => {
    try {
      callback({
        type: AgentReportAction.Processing,
      });

      const response = agentReportSendSignFile(id, form);
      response
        .then((response: any) => {
          const data = response.data as ApiResponse<AgentReportSignModel>;
          if (data.code === 200) {
            callback({
              type: AgentReportAction.Success,
              data: data.body,
            });
          } else {
            callback({
              type: AgentReportAction.Failed,
              errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
            });
          }
        })
        .catch((error) => {
          callback({
            type: AgentReportAction.Failed,
            errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
          });
        });
    } catch (e) {
      callback({
        type: AgentReportAction.Failed,
        errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
      });
    }
  };
