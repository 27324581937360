import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { CreditExecutionCommonDetailsContainer } from "./CreditExecutionCommonDetailsContainer";

export type CreditExecutionCommonDetailsSkeletonProps = BaseCssProps;

export const CreditExecutionCommonDetailsSkeleton = ({ className }: CreditExecutionCommonDetailsSkeletonProps) => {
  return (
    <CreditExecutionCommonDetailsContainer
      requested={<Skeleton width={150} />}
      confirmed={<Skeleton width={130} />}
      period={<Skeleton width={230} />}
      federalLaw={<Skeleton width={120} />}
      purchaseTerms={<Skeleton width={200} />}
      briefButton={<Skeleton width={170} height={20} />}
      className={className}
    />
  );
};
