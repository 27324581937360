import { Dispatch } from "redux";
import { FileStatus, IFileState } from "./fileActionsTypes";

import { getFileHash } from "../../api/fileApi";
import ApiResponse from "../../models/ApiResponse";

export const fileHash =
  (fileId: bigint, privateKey: string | null, providerType: string, callback: (state: IFileState) => void) =>
  async (dispatch: Dispatch) => {
    try {
      callback({
        status: FileStatus.Processing,
      });

      const response = getFileHash(fileId, privateKey, providerType);
      response
        .then((response: any) => {
          const data = response.data as ApiResponse<string>;
          if (data.code === 200) {
            callback({
              status: FileStatus.Success,
              data: data.body,
            });
          } else {
            callback({
              status: FileStatus.Failed,
              errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
            });
          }
        })
        .catch((error) => {
          callback({
            status: FileStatus.Failed,
            errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
          });
        });
    } catch (e) {
      callback({
        status: FileStatus.Failed,
        errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
      });
    }
  };
