import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { isLoading, isSucceeded, LoadingStatusEnum } from "../../../../types/state";
import { CircularLoader } from "../../../animations/loaders";
import { SimpleOpacityTransition } from "../../../animations/transitions";
import { DoneIcon } from "../../../icons";
import * as S from "./FileLabel.styled";
import { LabelNotificationWrapper } from "../LabelNotificationWrapper";

export interface FileLabelProps {
  fileName: string;
  download: () => Promise<void>;
  status: LoadingStatusEnum;
  isWithNotification?: boolean;
  className?: string;
}

export const FileLabel = ({ fileName, download, status, isWithNotification, className }: FileLabelProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <LabelNotificationWrapper isWithNotification={isWithNotification}>
      <S.Button
        type="button"
        className={className}
        onClick={download}
        showDefaultIcon={!isLoading(status) && !isSucceeded(status)}
        title={`Скачать ${fileName}`}
      >
        <S.Icon>
          <SimpleOpacityTransition timeout={250} in={isLoading(status)} unmountOnExit>
            <CircularLoader size={16} />
          </SimpleOpacityTransition>
          <SimpleOpacityTransition timeout={250} in={isSucceeded(status)} unmountOnExit>
            <DoneIcon />
          </SimpleOpacityTransition>
          <S.IconFile />
          <S.IconDownload />
        </S.Icon>
        <S.FileName
          as="div"
          fontColor="colors.primary"
          fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}
          fontWeight={500}
          lineHeight="defaultLineHeight"
        >
          {fileName}
        </S.FileName>
      </S.Button>
    </LabelNotificationWrapper>
  );
};
