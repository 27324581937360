import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { useBankGuaranteeCreationContractPrepaidSumField } from "../../../hooks/creation/fields/useBankGuaranteeCreationContractPrepaidSumField";

export interface BankGuaranteeCreationContractPrepaidSumFieldProps {
  disabled?: boolean;
}

export const BankGuaranteeCreationContractPrepaidSumField = ({
  disabled,
}: BankGuaranteeCreationContractPrepaidSumFieldProps) => {
  const { error, onBlur, value, onChange, name, isExists } = useBankGuaranteeCreationContractPrepaidSumField();

  return (
    <SimpleOpacityTransition timeout={300} in={isExists} unmountOnExit mountOnEnter>
      <FormInputPrice
        autoComplete="off"
        label="Сумма аванса по контракту, ₽"
        size="medium"
        name={name}
        onBlur={onBlur}
        value={value}
        onChange={onChange}
        error={error}
        disabled={disabled}
      />
    </SimpleOpacityTransition>
  );
};
