import { AnySchema, NumberSchema, StringSchema } from "yup";
import { numberHelper } from "@sideg/helpers";
import { AnyBrand, Brand } from "../../types/utils";

export type MaybeBrand<TBrand extends AnyBrand> = TBrand | undefined;

export const getStringBrandSchema = <TBrand extends Brand<string, unknown>>(): StringSchema<
  MaybeBrand<TBrand>,
  AnySchema
> => {
  return new StringSchema<TBrand, AnySchema, TBrand>();
};

export const getNumberBrandSchema = <TBrand extends Brand<number, unknown>>(): NumberSchema<
  MaybeBrand<TBrand>,
  AnySchema
> => {
  return new NumberSchema<TBrand, AnySchema, TBrand>().transform((value: unknown) => {
    if (typeof value === "string") {
      return numberHelper.getFromString(value);
    }

    if (typeof value === "number") {
      return value;
    }

    return undefined;
  });
};
