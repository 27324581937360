import { combineSliceNames } from "../../../common/store/utils";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { BANK_GUARANTEE_SLICE_NAME } from "../../common/store";
import { bankGuaranteeConditionsApi } from "../api/bankGuaranteeConditionsApi";

export const BANK_GUARANTEE_CONDITIONS_SLICE_NAME = combineSliceNames(BANK_GUARANTEE_SLICE_NAME, "conditions");

export const { get: getBankGuaranteeConditions } = createApiThunks(
  BANK_GUARANTEE_CONDITIONS_SLICE_NAME,
  bankGuaranteeConditionsApi
);
