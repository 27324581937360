import styled from "@emotion/styled";

interface ScoringToggleButtonProps {
  isSelected?: boolean;
}

export const ScoringToggleButton = styled.button<ScoringToggleButtonProps>`
  appearance: none;
  font-family: inherit;
  border: 2px solid transparent;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 1.25;
  padding: ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.lg};
  border-radius: 18px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.secondaryColors.primaryBackground};
  color: ${({ theme, isSelected }) => (isSelected ? theme.palette.typography.primaryLight : theme.colors.primary)};
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: ${({ theme }) => theme.palette.control.secondaryHover};

  &:focus-visible {
    border-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.dark : theme.colors.primary)};
  }

  &:hover {
    text-decoration: none;
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.palette.control.primaryHover : theme.palette.control.secondaryHover};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primaryDisabled};
    color: ${({ theme }) => theme.palette.typography.primaryLight};
    cursor: default;
  }

  & > svg {
    width: 1.3em;
    height: 1.25em;

    & > path {
      stroke: ${({ theme, isSelected }) => (isSelected ? theme.palette.typography.primaryLight : theme.colors.primary)};
    }
  }
`;
