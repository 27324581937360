import { ProductCreation } from "./types/ProductCreation";
import { selectAppUrl } from "../urls/internal/utils";

export const appProductsCreation: ProductCreation[] = [
  {
    url: selectAppUrl((x) => x.bankGuarantee.creation.create),
    title: "Банковская гарантия",
    titleInGenitive: "банковскую гарантию",
    description: "Получите любой вид банковской гарантии",
  },
  {
    url: selectAppUrl((x) => x.creditWorkingAssets.creation.create),
    title: "Кредит на пополнение оборотных средств",
    titleInGenitive: "кредит на пополнение оборотных средств",
    description: "на любые цели",
  },
  {
    url: selectAppUrl((x) => x.creditExecution.creation.create),
    title: "Кредит на исполнение контракта",
    titleInGenitive: "кредит на исполнение контракта",
    description: "для победителей конкурсов в рамках 44-ФЗ и 223-ФЗ",
  },
  {
    url: selectAppUrl((x) => x.factoring.creation.create),
    title: "Факторинг",
    titleInGenitive: "факторинг",
    description: "для получения поставщиком немедленной оплаты",
  },
  {
    url: selectAppUrl((x) => x.settlementAndCash.creation.create),
    title: "Расчетно-кассовое обслуживание",
    titleInGenitive: "расчетно-кассовое обслуживание",
    description: "Дистанционное открытие расчетных счетов",
  },
  {
    url: selectAppUrl((x) => x.legalServices.creation.list),
    title: "Юридические услуги",
    titleInGenitive: "юридические услуги",
    description: "Защита и представление интересов бизнеса",
  },
];
