import { forwardRef } from "react";
import { FormFileInputLabel, FormFileInputLabelProps } from "../label/FormFileInputLabel";
import { BaseCssProps } from "../../../../types";
import { BaseFormElementError } from "../../../common/components/BaseFormElementError";
import * as S from "./FormFileInputBlock.styled";
import { StackPanel } from "../../../../containers/stack-panel";
import { FormFileDropInputVariant } from "../../types/FormFileInputVariant";

export interface FormFileInputBlockProps extends BaseCssProps, Omit<FormFileInputLabelProps, "variant"> {
  error?: string;
  variant: FormFileDropInputVariant;
  wrapperProps?: JSX.IntrinsicElements["div"];
}

export const FormFileInputBlock = forwardRef<HTMLInputElement, FormFileInputBlockProps>(
  ({ text, variant, error, className, inputProps, wrapperProps }, ref) => {
    return (
      <StackPanel direction="column" gap="xs" className={className}>
        <S.InputWrapper variant={variant} isDisabled={inputProps?.disabled} {...wrapperProps}>
          <FormFileInputLabel
            ref={ref}
            inputProps={inputProps}
            text={text}
            variant={variant === "dragging" ? "default" : variant}
          />
        </S.InputWrapper>
        <BaseFormElementError error={error} />
      </StackPanel>
    );
  }
);
