import { Form, Formik, FormikValues } from "formik";
import {
  bankGuaranteeListFilterMoreOptions,
  BankGuaranteeListFilterMoreOptionsType,
} from "../../../../types/filters/BankGuaranteeMoreOptionsType";
import { Modal } from "../../../../../../common/ui/containers/modal";
import { FilterFormCheckboxes } from "../../../../../../common/filters/use-cases";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { CreationBlockCard } from "../../../../../../common/ui-kit/cards/creation-block-card";
import { bankGuaranteeRequestOriginalStatus } from "../../../../../common/types";
import { useGetFieldName } from "../../../../../../common/form";
import { selectBankGuaranteeListFilters } from "../../../../store/filters/bankGuaranteeListFilters.selectors";
import { useAppSelector } from "../../../../../../common/store";
import { BankGuaranteeListFilterParams } from "../../../../types/filters/BankGuaranteeListFilterParams";
import { ButtonLoading } from "../../../../../../common/ui-kit/form-controls/button";

interface BankGuaranteeListFiltersMoreOptionsModalProps {
  applyFilters: (selectedItems: FormikValues) => void;
  openedFiltration: BankGuaranteeListFilterMoreOptionsType | undefined;
  filteringObject: BankGuaranteeListFilterParams;
  onClose: () => void;
}

export const BankGuaranteeListFiltersMoreOptionsModal = ({
  applyFilters,
  openedFiltration,
  filteringObject,
  onClose,
}: BankGuaranteeListFiltersMoreOptionsModalProps) => {
  const { data } = useAppSelector(selectBankGuaranteeListFilters);

  const getFieldName = useGetFieldName<BankGuaranteeListFilterParams>();

  const getItems = () => {
    switch (openedFiltration) {
      case "requestOriginalStatuses":
        return bankGuaranteeRequestOriginalStatus.values.map((value) => ({
          code: value,
          title: bankGuaranteeRequestOriginalStatus.getTitle(value),
        }));
      case "taskTypes":
        return data?.taskTypes.codeWithTitleList;
      default:
        return undefined;
    }
  };

  return (
    <Modal
      isShown={!!openedFiltration}
      title={openedFiltration && bankGuaranteeListFilterMoreOptions.getTitle(openedFiltration)}
      onClose={onClose}
      background={(x) => x.palette.background.contrastLight}
      width="450px"
    >
      {openedFiltration && (
        <Formik initialValues={{ [openedFiltration]: filteringObject[openedFiltration] }} onSubmit={applyFilters}>
          {(form) => (
            <Box as={Form} display="flex" flexDirection="column" gap="xxl">
              <Box as={CreationBlockCard} padding="md" display="flex" flexDirection="column" gap="lg">
                <FilterFormCheckboxes<typeof openedFiltration>
                  fieldName={getFieldName(openedFiltration)}
                  items={getItems() ?? []}
                />
              </Box>
              <ButtonLoading
                isFullWidth
                variant="contained"
                color="primary"
                type="submit"
                isLoading={form.isSubmitting}
              >
                Применить
              </ButtonLoading>
            </Box>
          )}
        </Formik>
      )}
    </Modal>
  );
};
