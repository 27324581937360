import { ElementType } from "react";
import { checkNeverType } from "@sideg/helpers";
import { ClockIcon, LikeIcon, PartIcon, XOctagonIcon } from "../../../../common/ui-kit/icons";
import { BankGuaranteeDetailsDemandReconciliationDecisionType } from "../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationDecisionType";

export const getBankGuaranteeDetailsDemandReconciliationDecisionIcon = (
  decision: BankGuaranteeDetailsDemandReconciliationDecisionType,
): ElementType => {
  switch (decision) {
    case "PartialConfirmed":
      return PartIcon;
    case "Agreement":
      return ClockIcon;
    case "Confirmed":
      return LikeIcon;
    case "Refused":
      return XOctagonIcon;
    default:
      return checkNeverType(decision);
  }
};
