import {
  ProductDetailsAsideNavigationCard,
  ProductDetailsAsideNavigationCardProps,
} from "../../../../../../../common/product-details";
import { CircleIndicator } from "../../../../../../../common/ui-kit/notifications/circle-indicator";
import { BaseCssProps, ElementColor } from "../../../../../../../common/ui-kit/types";
import { Typography } from "../../../../../../../common/ui-kit/typography";
import {
  bankGuaranteeDetailsDemandReconciliationStateType,
  BankGuaranteeDetailsDemandReconciliationStateType,
} from "../../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationStateType";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { BankGuaranteeDetailsDemandReconciliationContentStateIcon } from "../../../reconciliation/content/BankGuaranteeDetailsDemandReconciliationContentStateIcon";

const getBackgroundColorByState = (state: BankGuaranteeDetailsDemandReconciliationStateType): ElementColor => {
  switch (state) {
    case "Approved":
      return "status.successBackground";
    case "Agreement":
      return "control.primaryLight";
    case "Bank":
    default:
      return "control.secondaryLight";
  }
};

export interface BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardMobileProps
  extends BaseCssProps,
    Pick<ProductDetailsAsideNavigationCardProps, "to" | "params"> {
  title: string;
  counter: number;
  state: BankGuaranteeDetailsDemandReconciliationStateType;
}

export const BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardMobile = ({
  to,
  params,
  title,
  counter,
  state,
  className,
}: BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardMobileProps) => {
  return (
    <ProductDetailsAsideNavigationCard
      to={to}
      params={params}
      px="lg"
      py="md"
      isFullWidth
      backgroundColor={getBackgroundColorByState(state)}
      arrowColor="typography.primary"
      after={
        counter > 0 && (
          <Box flexGrow={0} flexShrink={0}>
            <CircleIndicator size={26} color={(theme) => theme.colors.primary}>
              <Typography fontColor="typography.primaryLight" fontWeight={700} fontSize="fz2" lineHeight="16px">
                {counter}
              </Typography>
            </CircleIndicator>
          </Box>
        )
      }
      className={className}
    >
      <Box display="flex" flexDirection="column" gap="xs" className={className} overflow="hidden">
        <Typography
          fontColor="typography.primary"
          fontSize="fz3"
          fontWeight={700}
          lineHeight="defaultLineHeight"
          whiteSpace="nowrap"
          withTextOverflowEllipsis
        >
          {title}
        </Typography>
        <Box display="flex" flexDirection="row" gap="xs" alignItems="center">
          <BankGuaranteeDetailsDemandReconciliationContentStateIcon state={state} />
          <Typography
            fontColor="typography.secondary"
            fontSize="fz2"
            fontWeight={500}
            lineHeight="defaultLineHeight"
            whiteSpace="nowrap"
            withTextOverflowEllipsis
          >
            {bankGuaranteeDetailsDemandReconciliationStateType.getTitle(state)}
          </Typography>
        </Box>
      </Box>
    </ProductDetailsAsideNavigationCard>
  );
};
