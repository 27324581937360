import { GetProductsLegalServicesCreationOutputDto } from "../api/dto/output/GetProductsLegalServicesCreationOutputDto";
import { LegalServicesCreationProduct } from "../types/LegalServicesCreationProduct";
import { LegalServicesProductId } from "../../common/types";
import { make } from "../../../common/types/utils";

const fromResponse = (response: GetProductsLegalServicesCreationOutputDto): LegalServicesCreationProduct[] => {
  return response.map((product, index) => ({ ...product, id: make<LegalServicesProductId>()(`id${index + 1}`) }));
};

export const legalServicesCreationMapper = {
  fromResponse,
};
