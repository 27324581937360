import * as React from "react";
import { AppLayoutRoute, AppRoute } from "../types/AppRoute";
import { AppUrlTypeEnum } from "../../urls/internal/types";

export const makeAppLayoutRoute = (
  component: (props: { children: React.ReactNode }) => React.ReactElement,
  nested?: (AppRoute | AppLayoutRoute)[],
  type = AppUrlTypeEnum.Private
): AppLayoutRoute => {
  return {
    component,
    nested,
    isLayout: true,
    type,
  };
};
