import { memo } from "react";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeRejectionRejectDemandResponsiveView } from "../../../../../rejection";
import { BankGuaranteeId, DemandId } from "../../../../../../common/types/demand";
import { RejectButton } from "../../../../../../common/ui/buttons/reject-button";
import { useBankGuaranteeDetailsGuaranteeRejection } from "../../../../hooks/useBankGuaranteeDetailsGuaranteeRejection";

export interface BankGuaranteeDetailsDemandHeaderRejectionButtonProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  bankName: string;
}

export const BankGuaranteeDetailsDemandHeaderRejectionButton = memo(
  ({ className, guaranteeId, demandId, bankName }: BankGuaranteeDetailsDemandHeaderRejectionButtonProps) => {
    const { isModalOpen, openModal, closeModal, afterSuccess, panelKey } = useBankGuaranteeDetailsGuaranteeRejection(
      guaranteeId,
      demandId,
    );

    return (
      <>
        <RejectButton className={className} onClick={openModal} size="default">
          <ThemeMobileDesktopView mobile="Отменить" desktop="Отменить заявку" />
        </RejectButton>
        <BankGuaranteeRejectionRejectDemandResponsiveView
          isOpen={isModalOpen}
          guaranteeId={guaranteeId}
          onClose={closeModal}
          demandId={demandId}
          bankName={bankName}
          afterSuccess={afterSuccess}
          panelKey={panelKey}
        />
      </>
    );
  },
);
