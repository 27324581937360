import { useAppSelector } from "../../../common/store";
import { useCreateNavigationStateFrom } from "../../../common/navigation/navigation-state";
import {
  selectQueueTasksList,
  selectQueueTasksListItemById,
  selectQueueTasksListItemIds,
} from "../store/queueTasksList.selectors";
import { getQueueTasksList } from "../store/queueTasksList.thunks";
import { QueueTasksListFilterParams } from "../types/filter";
import { QueueTasksListContainer } from "./QueueTasksListContainer";
import { AppInfinityScroll, AppInfinityScrollTitles } from "../../../common/ui/product/list/infinite-scroll";
import * as S from "./QueueTasksList.styled";
import { useFilteredPaginatedListPage } from "../../../common/filters";
import { GetQueueTasksListInputDto } from "../api/dto/input/GetQueueTasksListInputDto";
import { QueueTasksListItemsContainer } from "./QueueTasksListItemsContainer";
import { QueueTasksListItemSkeleton } from "./list-item/skeleton/QueueTasksListItem.skeleton";

const titles: AppInfinityScrollTitles = {
  end: "Больше нет активных задач",
  hasNoLoadedItems: "Нет активных задач",
  navigateToFirstPage: "Показать предыдущие задачи",
  error: "При получении задач возникла ошибка",
};

export interface QueueTasksListProps {
  filteringObject: QueueTasksListFilterParams;
  forceUpdate: () => void;
}

export const QueueTasksList = ({ filteringObject, forceUpdate }: QueueTasksListProps) => {
  const from = useCreateNavigationStateFrom("Активные задачи");
  const { hasSuggestions } = useAppSelector(selectQueueTasksList);

  const { onPreviousPageButtonClick, hasPreviousPage, onNextPageFetching } =
    useFilteredPaginatedListPage<GetQueueTasksListInputDto>({
      filteringObject,
      action: getQueueTasksList,
    });

  return (
    <S.Container>
      <AppInfinityScroll
        page={filteringObject.page}
        paginatedStateSelector={selectQueueTasksList}
        idsSelector={selectQueueTasksListItemIds}
        titles={titles}
        onPreviousPageButtonClick={onPreviousPageButtonClick}
        onPageChanged={onNextPageFetching}
        onErrorReloadButtonClick={forceUpdate}
        hasPreviousPage={hasPreviousPage}
        emptyLoader={
          <QueueTasksListItemsContainer>
            <QueueTasksListItemSkeleton />
            <QueueTasksListItemSkeleton />
            <QueueTasksListItemSkeleton />
            <QueueTasksListItemSkeleton />
            <QueueTasksListItemSkeleton />
            <QueueTasksListItemSkeleton />
            <QueueTasksListItemSkeleton />
          </QueueTasksListItemsContainer>
        }
      >
        <QueueTasksListContainer
          from={from}
          idsSelector={selectQueueTasksListItemIds}
          entitySelector={selectQueueTasksListItemById}
        />
      </AppInfinityScroll>
      {hasSuggestions && <S.Suggestions />}
    </S.Container>
  );
};
