import { isValidUrl } from "@sideg/helpers";
import { Typography } from "../../../../ui-kit/typography";
import { Link } from "../../../../ui-kit/links";
import { federalLawType, getFederalLawTypeTitle } from "../../../../types/demand";
import { BaseCssProps } from "../../../../ui-kit/types";
import { ProductDetailsInformationListBlock } from "./ProductDetailsInformationListBlock";
import { ProductDetailsInformationListItem } from "../item/ProductDetailsInformationListItem";
import { ProductDetailsInformationType } from "../../../types/ProductDetailsInformationType";

export type ProductDetailsInformationFederalLawType = Pick<
  ProductDetailsInformationType,
  "federalLaw" | "purchaseNumber" | "purchaseLink" | "purchaseIdentificationCode" | "briefAndFounders"
>;

export interface ProductDetailsInformationFederalLawBlockProps<T extends ProductDetailsInformationFederalLawType>
  extends BaseCssProps {
  data: T;
}

export const ProductDetailsInformationFederalLawBlock = <T extends ProductDetailsInformationFederalLawType>({
  data,
  className,
}: ProductDetailsInformationFederalLawBlockProps<T>) => {
  return (
    <ProductDetailsInformationListBlock title={getFederalLawTypeTitle(data.federalLaw.code)} className={className}>
      {data.purchaseNumber && <ProductDetailsInformationListItem title="Номер закупки" value={data.purchaseNumber} />}
      {data.federalLaw.code !== "COMMERCE" && (
        <ProductDetailsInformationListItem title="ФЗ" value={federalLawType.getTitle(data.federalLaw.code)} />
      )}
      {data.briefAndFounders?.contractSubject && (
        <ProductDetailsInformationListItem title="Предмет контракта" value={data.briefAndFounders.contractSubject} />
      )}
      {data.purchaseIdentificationCode && (
        <ProductDetailsInformationListItem
          title="Идентификационный код закупки"
          value={data.purchaseIdentificationCode}
        />
      )}
      {isValidUrl(data.purchaseLink) && (
        <Link href={data.purchaseLink} target="_blank" rel="noopener noreferrer">
          <Typography fontSize="fz4" fontWeight={500} lineHeight="defaultLineHeight">
            Ссылка на закупку
          </Typography>
        </Link>
      )}
    </ProductDetailsInformationListBlock>
  );
};
