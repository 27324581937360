import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { BankGuaranteeListItemDesktopSkeleton } from "./desktop/BankGuaranteeListItem.desktop.skeleton";
import { BankGuaranteeListItemMobileSkeleton } from "./mobile/BankGuaranteeListItem.mobile.skeleton";

export type BankGuaranteeListItemSkeletonProps = BaseCssProps;

export const BankGuaranteeListItemSkeleton = ({ className }: BankGuaranteeListItemSkeletonProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={<BankGuaranteeListItemMobileSkeleton />}
      desktop={<BankGuaranteeListItemDesktopSkeleton className={className} />}
    />
  );
};
