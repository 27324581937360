import { useMemo, useState } from "react";
import { useAppNavigation } from "../../../common/urls/internal/hooks";
import { MenuItem } from "../types/MenuItem";
import { KeyIcon, LogoutIcon, UserIcon } from "../../../common/ui-kit/icons";

export const useUserDetailsMenuItems = () => {
  const navigate = useAppNavigation();

  const [modalUserInfoProfileOpen, setModalUserInfoProfileOpen] = useState(false);

  const items: MenuItem[] = useMemo(
    (): MenuItem[] => [
      {
        key: "profile",
        title: "Профиль",
        icon: <UserIcon />,
        onClick: () => setModalUserInfoProfileOpen(true),
      },
      {
        key: "change-password",
        title: "Сменить пароль",
        icon: <KeyIcon />,
        onClick: () => navigate((x) => x.user.changePassword.root),
      },
      {
        key: "logout",
        title: "Выход",
        icon: <LogoutIcon />,
        onClick: () => navigate((x) => x.publicUser.authentication.logout),
      },
    ],
    [navigate]
  );

  const handleCloseUserInfoProfileModal = () => {
    setModalUserInfoProfileOpen(false);
  };

  return {
    items,
    modalUserInfoProfileOpen,
    handleCloseUserInfoProfileModal,
  };
};
