/* eslint-disable no-console */
import { Logger } from "../../types/Logger";
import { LogLevel } from "../../types/LogLevel";
import { LoggerProvider } from "../../types/LoggerProvider";
import { loggingConsoleConfigs } from "./configs";

const getLevelHandler = (logLevel: LogLevel): ((error: unknown) => void) => {
  switch (logLevel) {
    case "warn":
      return console.warn;
    case "debug":
      return console.debug;
    case "info":
      return console.info;
    default:
      return console.error;
  }
};

const logger: Logger = {
  log: (logLevel, error) => getLevelHandler(logLevel)(error),
  logThunkError: (thunkName, error) => console.warn({ thunkName, error }),
};

export const loggingConsoleProvider: LoggerProvider = {
  isEnabled: loggingConsoleConfigs.isEnabled,
  logger,
};
