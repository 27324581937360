import styled from "@emotion/styled";
import { ChevronLeftIcon } from "@sideg/ui-kit/atoms/icons";
import { BaseInput } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseInput";
import { SelectSize } from "@sideg/ui-kit/atoms/form-controls/select/components/filter-expand-button/FilterExpandButton.styled";

export const expandIconSizes: SelectSize<"width"> = {
  small: "16px",
  default: "20px",
  medium: "24px",
};

export const Select = styled(BaseInput.withComponent("select"))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${({ theme }) => theme.spacing.xl};
  appearance: none;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
  }
`;

export const Icon = styled(ChevronLeftIcon)`
  width: ${expandIconSizes.medium};
  height: ${expandIconSizes.medium};
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing.m};
  transform: translateY(-50%) rotate(-90deg);
  pointer-events: none;

  & > path {
    stroke: ${({ theme }) => theme.palette.light.neutral.text.tertiary};
  }
`;
