import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreditWorkingAssetsListItemModificationState } from "./creditWorkingAssetsListItemModification.types";
import { combineSliceNames } from "../../../../common/store/utils";
import { CREDIT_WORKING_ASSETS_LIST_SLICE_NAME } from "../creditWorkingAssetsList.thunks";
import { CreditWorkingAssetsId, DemandId } from "../../../../common/types/demand";

const initialState: CreditWorkingAssetsListItemModificationState = {};

const SLICE_NAME = combineSliceNames(CREDIT_WORKING_ASSETS_LIST_SLICE_NAME, "item-modification");

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    onProductReject: (state, action: PayloadAction<{ creditWorkingAssetsId: CreditWorkingAssetsId }>) => {
      return { type: "remove-product", creditWorkingAssetsId: action.payload.creditWorkingAssetsId };
    },
    onDemandReject: (
      state,
      {
        payload: { creditWorkingAssetsId, demandId },
      }: PayloadAction<{ creditWorkingAssetsId: CreditWorkingAssetsId; demandId: DemandId }>
    ) => {
      return { type: "remove-demand", creditWorkingAssetsId, demandId };
    },
    cancelModification: () => initialState,
  },
});

export const creditWorkingAssetsListItemModificationReducer = slice.reducer;

export const {
  onProductReject: onProductRejectCreditWorkingAssetsListItemModification,
  onDemandReject: onDemandRejectCreditWorkingAssetsListItemModification,
  cancelModification: cancelModificationCreditWorkingAssetsListModification,
} = slice.actions;
