import styled from "@emotion/styled";

export const Indicator = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
