import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../utils";

const titles = {
  Agent: "Агент",
  Client: "Клиент",
};

export const agencyType = makeEnumLikeLiteral(["Agent", "Client"] as const, titles);

export type AgencyType = EnumLikeLiteralType<typeof agencyType>;
