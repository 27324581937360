import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { CreditWorkingAssetsListFiltersState } from "./creditWorkingAssetsListFilters.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import {
  CREDIT_WORKING_ASSETS_LIST_SLICE_NAME,
  getFiltrationParametersCreditWorkingAssetsList,
} from "../creditWorkingAssetsList.thunks";
import { creditWorkingAssetsListFiltersMapper } from "./creditWorkingAssetsListFilters.mapper";

const initialState: CreditWorkingAssetsListFiltersState = {
  data: undefined,
  status: LoadingStatusEnum.Idle,
};

const slice = createSlice({
  name: CREDIT_WORKING_ASSETS_LIST_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFiltrationParametersCreditWorkingAssetsList.fulfilled, (state, action) => {
        state.data = creditWorkingAssetsListFiltersMapper.mapFilters(action.payload.body);
      })
      .addMatcher(isAsyncThunkAction(getFiltrationParametersCreditWorkingAssetsList), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const creditWorkingAssetsListFiltersReducer = slice.reducer;
