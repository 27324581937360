/* eslint-disable @typescript-eslint/no-explicit-any */
import { RawWebApiResponse } from "../../api/types/RawWebApiResponse";
import { RawApiResponse } from "../../api/types/RawApiResponse";
import { apiResponseSuccessTransformer } from "../../api/transformers/apiResponseTransformer";
import { apiRejectTransformer } from "../../api/transformers/apiRejectTransformer";
import { SignalrConnection } from "../types/SignalrConnection";
import { ApiResponse } from "../../api/types";

export const invokeHubMethod = async <
  TResponse,
  TConnection extends SignalrConnection<string, string, string>,
  TMethods extends TConnection extends SignalrConnection<string, infer U, string> ? U : never,
>(
  connection: TConnection,
  methodName: TMethods,
  ...args: any[]
): Promise<ApiResponse<TResponse>> => {
  try {
    const response = await connection.invoke<RawWebApiResponse<TResponse> | RawApiResponse<TResponse>>(
      methodName,
      ...args,
    );

    return apiResponseSuccessTransformer<TResponse>(response);
  } catch (error: any) {
    throw apiRejectTransformer(error);
  }
};
