import styled from "@emotion/styled";
import TextareaAutosize from "react-textarea-autosize";
import { BaseInput } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseInput";
import { ControlSize } from "@sideg/ui-kit/common/types";
import { getSizes } from "@sideg/ui-kit/atoms/form-controls/input/common/utils/getInputSize";

export const Textarea = styled(BaseInput.withComponent(TextareaAutosize))<{ size: ControlSize }>(({ theme, size }) => ({
  ...getSizes(theme, size, false),
  resize: "none",
}));
