import * as React from "react";
import { BaseFormElementError, BaseFormElementErrorProps } from "../../common/components";
import * as S from "./FormChipRadioButtonGroup.styled";
import { FormChipRadioButtonGroupVariant } from "../types/FormChipRadioButtonGroupVariant";
import { BaseCssProps } from "../../../types";

export interface FormChipRadioButtonGroupProps extends BaseFormElementErrorProps, BaseCssProps {
  disabled?: boolean;
  children: React.ReactNode;
  label?: string;
}

export const FormChipRadioButtonGroup = ({
  disabled,
  error,
  errorDataTestId,
  children,
  className,
  label,
}: FormChipRadioButtonGroupProps) => {
  return (
    <S.Wrapper disabled={disabled} className={className}>
      {label && <legend>{label}</legend>}
      <S.RadioButtonsWrapper>{children}</S.RadioButtonsWrapper>
      <BaseFormElementError errorDataTestId={errorDataTestId} error={error} />
    </S.Wrapper>
  );
};

export interface OptionProps
  extends Omit<
    JSX.IntrinsicElements["input"],
    "type" | "value" | "disabled" | "defaultValue" | "placeholder" | "className"
  > {
  label: string;
  value: string;
  variant: FormChipRadioButtonGroupVariant;
  disabled?: boolean;
  className?: string;
}

// eslint-disable-next-line react/function-component-definition
FormChipRadioButtonGroup.Option = ({ label, disabled, value, className, variant, ...rest }: OptionProps) => {
  return (
    <S.Label className={className} variant={variant}>
      <input {...rest} type="radio" value={value} disabled={disabled} />
      <span>{label}</span>
    </S.Label>
  );
};
