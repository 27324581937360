import { useCallback, useEffect, useState } from "react";
import { checkNeverType } from "@sideg/helpers";
import { webPushesPermissionStateService } from "../../services/webPushesPermissionStateService";
import { WebPushesPushManagerPermissionState } from "../../types/WebPushesPushManagerPermissionState";
import { WebPushesSubscriptionState } from "../../types/WebPushesSubscriptionState";
import { useWebPushesSubscriptionBackendApi } from "./useWebPushesSubscriptionBackendApi";
import { WebPushesApiSubscriptionContextType } from "../../types/WebPushesApiSubscriptionContextType";
import { webPushesPromptService } from "../../services/webPushesPromptService";

const getSubscriptionState = (
  state: WebPushesPushManagerPermissionState,
  isUserSubscribed: boolean | undefined,
): WebPushesSubscriptionState => {
  switch (state) {
    case "granted":
      return isUserSubscribed ? "subscribed" : "available";
    case "denied":
      return "denied";
    case "prompt":
      return "available";
    case "__NOT_SUPPORTED__":
      return "notSupported";
    default:
      return checkNeverType(state);
  }
};

export const useWebPushesSubscription = (
  getRegistrationFunc: () => Promise<ServiceWorkerRegistration | undefined>,
): {
  subscriptionState: WebPushesSubscriptionState;
} & WebPushesApiSubscriptionContextType => {
  const [permissionState, setPermissionState] = useState<WebPushesPushManagerPermissionState | undefined>(undefined);
  const [subscriptionState, setSubscriptionState] = useState<WebPushesSubscriptionState>("initial");

  const {
    isSubscribed: isSubscribedApi,
    unsubscribe: unsubscribeApi,
    subscribe: subscribeApi,
  } = useWebPushesSubscriptionBackendApi();

  useEffect(() => {
    const setInitialState = async () => {
      const registration = await getRegistrationFunc();
      setPermissionState(await webPushesPermissionStateService.getPermissionState(registration));
    };

    setInitialState();
  }, [getRegistrationFunc]);

  useEffect(() => {
    const setInitialState = async (state: WebPushesPushManagerPermissionState) => {
      let isUserSubscribed: boolean | undefined;

      if (state === "granted") {
        const registration = await getRegistrationFunc();
        const subscription = await webPushesPermissionStateService.getSubscription(registration);
        isUserSubscribed = await isSubscribedApi(subscription);
      }

      setSubscriptionState(getSubscriptionState(state, isUserSubscribed));
    };

    if (permissionState !== undefined && subscriptionState === "initial") {
      setInitialState(permissionState);
    }
  }, [getRegistrationFunc, isSubscribedApi, permissionState, subscriptionState]);

  const subscribe = useCallback(async () => {
    let isSubscribed = false;
    if (subscriptionState === "available") {
      const registration = await getRegistrationFunc();
      const subscription = await webPushesPermissionStateService.requestPermission(registration);

      isSubscribed = await subscribeApi(subscription);
      if (isSubscribed) {
        setSubscriptionState("subscribed");
      }
    }

    return isSubscribed;
  }, [getRegistrationFunc, subscribeApi, subscriptionState]);

  const unsubscribe = useCallback(async () => {
    let isUnsubscribed = false;
    if (subscriptionState === "subscribed") {
      const registration = await getRegistrationFunc();
      const subscription = await webPushesPermissionStateService.getSubscription(registration);

      webPushesPromptService.setTimeAfterUnsubscribe();
      isUnsubscribed = await unsubscribeApi(subscription);
      if (isUnsubscribed) {
        setSubscriptionState("available");
      }
    }

    return isUnsubscribed;
  }, [getRegistrationFunc, subscriptionState, unsubscribeApi]);

  return { subscriptionState, subscribe, unsubscribe };
};
