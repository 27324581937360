import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../common/store";
import { BankGuaranteeDocumentsState } from "./bankGuaranteeDocuments.types";
import { bankGuaranteeDocumentsAdapter } from "./bankGuaranteeDocuments.adapter";

export const selectBankGuaranteeDocuments = (state: RootState): BankGuaranteeDocumentsState =>
  state.domains.bankGuarantee.documents;

const { selectAll, selectById, selectIds } = bankGuaranteeDocumentsAdapter.getSelectors(selectBankGuaranteeDocuments);

export const selectBankGuaranteeDocumentsItemById = selectById;

export const selectBankGuaranteeDocumentsIds = selectIds;

export const selectGroupedBankGuaranteeDocumentsIds = createSelector([selectAll], (documents) => {
  const requiredDocuments = documents.filter((x) => x.isRequired).map((x) => x.id);
  const notRequiredDocuments = documents.filter((x) => !x.isRequired).map((x) => x.id);

  return { requiredDocuments, notRequiredDocuments };
});
