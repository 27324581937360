import { combineSliceNames } from "../../../common/store/utils";
import { CREDIT_EXECUTION_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { creditExecutionCreationApi } from "../api/creditExecutionCreationApi";

export const CREDIT_EXECUTION_CREATION_SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_SLICE_NAME, "creation");

export const {
  createDraft: createDraftCreditExecutionCreation,
  scoring: scoringCreditExecutionCreation,
  create: createCreditExecutionCreation,
  scoringByExists: scoringByExistsCreditExecutionCreation,
  createByExists: createByExistsCreditExecutionCreation,
} = createApiThunks(CREDIT_EXECUTION_CREATION_SLICE_NAME, creditExecutionCreationApi);
