import * as React from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import * as S from "./ProductCreationScoringResultCard.styled";
import { BaseCssProps } from "../../../../ui-kit/types";

export interface ProductCreationScoringResultCardProps extends BaseCssProps {
  title: string;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

export const ProductCreationScoringResultCard = ({
  title,
  description,
  className,
  children,
}: ProductCreationScoringResultCardProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <S.Card
      borderWidth={{ xs: 1, [desktopBreakpoint]: 0 }}
      title={title}
      description={description}
      padding={{ xs: "xs", [desktopBreakpoint]: "sm" }}
      className={className}
    >
      {children}
    </S.Card>
  );
};
