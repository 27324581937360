import { AgentReportListCounterState, AgentReportListStatus } from "./AgentReportListTypes";
import { Dispatch } from "redux";

import ApiResponse from "../../models/ApiResponse";
import UniversalResponseModel from "../../models/UniversalResponseModel";
import DemandCounterModel from "../../models/DemandCounterModel";
import { getAgentReportCounter } from "../../api/agentReportApi";

export const agentReportListLoadCounter =
  (callback: (state: AgentReportListCounterState) => void) => async (dispatch: Dispatch) => {
    try {
      callback({
        status: AgentReportListStatus.Processing,
      });

      const response = getAgentReportCounter();
      response
        .then((response: any) => {
          const data = response.data as ApiResponse<UniversalResponseModel<DemandCounterModel>>;
          if (data.code === 200) {
            callback({
              status: AgentReportListStatus.Success,
              data: data.body?.data,
            });
          } else {
            callback({
              status: AgentReportListStatus.Failed,
              errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
            });
          }
        })
        .catch((error) => {
          callback({
            status: AgentReportListStatus.Failed,
            errorMessage: "Произошла непредвиденная ошибка. Повторите попытку позднее",
          });
        });
    } catch (e) {
      callback({
        status: AgentReportListStatus.Failed,
        errorMessage: "Произошла ошибка доступа к сервису. Повторите попытку позднее",
      });
    }
  };
