import { useEffect } from "react";
import { useHubState } from "../../../common/hubs";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { getQueueTasksNotifications } from "../store/queueTasksNotifications.thunks";
import { selectQueueTasksNotifications } from "../store/queueTasksNotifications.selectors";
import { isIdle } from "../../../common/types/state";
import { queueTasksNotificationsUpdate } from "../store/queueTasksNotifications.slice";
import { queueTasksHubConnection } from "../../../common/hubs-use-cases/queue-tasks";

export const useQueueTasksNavigationHubFetcher = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectQueueTasksNotifications);

  const { isConnected } = useHubState(queueTasksHubConnection);

  useEffect(() => {
    if (isConnected) {
      dispatch(queueTasksNotificationsUpdate());
    }
  }, [dispatch, isConnected]);

  useEffect(() => {
    if (isConnected && isIdle(status)) {
      dispatch(getQueueTasksNotifications());
    }
  }, [dispatch, isConnected, status]);
};
