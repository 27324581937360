import { useMemo } from "react";
import { useTheme } from "@sideg/ui-kit";
import { Color } from "../../../ui-kit/theme";
import { ElementColor, getElementColor } from "../../../ui-kit/types";

const SEPARATOR_DEG = 2;
const CIRCLE_DEG = 360;

const makeGradientPart = (color: Color, fromDeg: number, toDeg: number) => `${color} ${fromDeg}deg ${toDeg}deg`;

export const useProductDetailsEmptyCircleConicGradient = <TStage extends string>(
  counters: Record<TStage, number | undefined>,
  stageValues: TStage[],
  getStageColor: (stage: TStage) => ElementColor,
) => {
  const theme = useTheme();

  return useMemo(() => {
    const parts = stageValues
      .filter((x) => !!counters[x])
      .map((x) => ({
        stage: x,
        value: counters[x] ?? 0,
      }))
      .sort((a, b) => b.value - a.value);

    const makeStageGradientPart = (stage: TStage, fromDeg: number, toDeg: number) =>
      makeGradientPart(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getElementColor(getStageColor(stage), theme)!,
        fromDeg,
        toDeg,
      );

    if (parts.length === 1) {
      return makeStageGradientPart(parts[0].stage, 0, CIRCLE_DEG);
    }

    const total = parts.reduce((accum, { value }) => accum + value, 0);
    const gradientParts: string[] = [];
    const addGradientPart = (stage: TStage, fromDeg: number, toDeg: number) =>
      gradientParts.push(makeStageGradientPart(stage, fromDeg, toDeg));
    const addSeparatorPart = (fromDeg: number, toDeg: number) =>
      gradientParts.push(makeGradientPart(theme.palette.background.light, fromDeg, toDeg));

    let partFrom = 0;
    parts.forEach(({ stage, value }, index) => {
      const partSize = Math.floor((value * CIRCLE_DEG) / total);

      // Последней частью компенсируем неточности в градусах из-за округления в меньшую сторону
      const partEnd = index === parts.length - 1 ? CIRCLE_DEG : partFrom + partSize;

      addGradientPart(stage, partFrom, partEnd - SEPARATOR_DEG);
      addSeparatorPart(partEnd - SEPARATOR_DEG, partEnd);
      partFrom = partEnd;
    });

    return gradientParts.join(", ");
  }, [counters, getStageColor, stageValues, theme]);
};
