import * as S from "@sideg/ui-kit/atoms/checkbox/components/radio/FormRadioCheckbox.styled";
import { CheckboxSize } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";

const getSvgViewBox = (radioCheckboxSize?: CheckboxSize) => {
  switch (radioCheckboxSize) {
    case "small":
      return "0 0 16 16";
    case "default":
      return "0 0 20 20";
    default:
      return "0 0 20 20";
  }
};

export interface FormRadioCheckboxIconProps {
  radioCheckboxSize: CheckboxSize;
  isDisabled: boolean;
}

export const FormRadioCheckboxIcon = ({ radioCheckboxSize, isDisabled }: FormRadioCheckboxIconProps) => {
  return (
    <S.Icon viewBox={getSvgViewBox(radioCheckboxSize)} xmlns="http://www.w3.org/2000/svg">
      <S.IconOuterCircle isDisabled={isDisabled} outerCircleSizes={radioCheckboxSize} strokeWidth="1.5" />
      <S.IconInnerCircle isDisabled={isDisabled} innerCircleSizes={radioCheckboxSize} />
    </S.Icon>
  );
};
