import { FormCheckbox } from "@sideg/ui-kit/atoms/checkbox";
import { useCreditExecutionCreationCheckboxFieldsDisabledState } from "../../../hooks/creation/fields/useCreditExecutionCreationCheckboxFieldsDisabledState";
import { useCreditExecutionCreationField } from "../../../hooks/creation/fields/useCreditExecutionCreationField";

export interface CreditExecutionCreationCheckboxFieldProps {
  disabled?: boolean;
}

export const CreditExecutionCreationCheckboxField = ({ disabled }: CreditExecutionCreationCheckboxFieldProps) => {
  const [isClosedAuctionProps] = useCreditExecutionCreationField("isClosedAuction");

  const isDisabledByValidationRules = useCreditExecutionCreationCheckboxFieldsDisabledState();

  return (
    <FormCheckbox
      label="Закрытый аукцион"
      {...isClosedAuctionProps}
      isDisabled={isDisabledByValidationRules || disabled}
    />
  );
};
