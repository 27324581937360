import Skeleton from "react-loading-skeleton";
import { CreationBlockCard } from "../../../../common/ui-kit/cards/creation-block-card";
import { Box } from "../../../../common/ui-kit/containers/box";

interface FactoringCreationFormDebtorSkeletonItemProps {
  widths: (string | number)[];
  heights: (string | number)[];
}

const Card = Box.withComponent(CreationBlockCard);

export const FactoringCreationFormDebtorSkeletonItem = ({
  widths,
  heights,
}: FactoringCreationFormDebtorSkeletonItemProps) => {
  return (
    <Card display="flex" flexDirection="column" gap="md" padding={{ xs: "md", md: "lg" }}>
      {widths.map((width, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={i} width={width} height={heights[i]} />
      ))}
    </Card>
  );
};
