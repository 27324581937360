import { FormCheckbox } from "@sideg/ui-kit/atoms/checkbox";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { useGetFieldName } from "../../../../../common/form";
import { Tooltip, useTooltip } from "../../../../../common/ui-kit/pop-up/tooltip";
import { Typography } from "../../../../../common/ui-kit/typography";
import { QuestionCircleIcon } from "../../../../../common/ui-kit/icons";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";
import { BankGuaranteeCreationFromValues } from "../../../types/BankGuaranteeCreationFormValues";

export const BankGuaranteeCreationIsClientSignerField = () => {
  const getFieldName = useGetFieldName<BankGuaranteeCreationFromValues>();
  const [isClientWithSignerPerson] = useBankGuaranteeCreationField(getFieldName("isClientWithSignerPerson"));

  const { onClick, onMouseEnter, onMouseLeave, ref, popupProps, placement, arrow, isOpened } = useTooltip({
    position: "bottom",
    type: "click",
  });

  return (
    <Box display="flex" flexDirection="row" gap="sm" pt="sm">
      <FormCheckbox {...isClientWithSignerPerson} label={<Typography>Подписант по доверенности</Typography>} />
      <Box display="flex" alignItems="center" ref={ref} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
        <QuestionCircleIcon onClick={onClick} />
      </Box>
      {isOpened && (
        <Tooltip popUpProps={popupProps} arrowRef={arrow.ref} arrowData={arrow.data} placement={placement}>
          Подписант будет добавлен к вашей заявке на банк для подписания ЭЦП по доверенности. А также будут запрошены
          доп документы для подписанта
        </Tooltip>
      )}
    </Box>
  );
};
