import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { bankGuaranteeRewardsAdapter } from "./bankGuaranteeRewards.adapter";
import { BankGuaranteeRewardsSliceState } from "./bankGuaranteeRewards.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { BANK_GUARANTEE_REWARDS_SLICE_NAME, getBankGuaranteeRewards } from "./bankGuaranteeRewards.thunks";
import { bankGuaranteeRewardsMapper } from "./bankGuaranteeRewards.mapper";

const initialState = bankGuaranteeRewardsAdapter.getInitialState<BankGuaranteeRewardsSliceState>({
  status: LoadingStatusEnum.Idle,
  banks: undefined,
});

const slice = createSlice({
  name: BANK_GUARANTEE_REWARDS_SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankGuaranteeRewards.fulfilled, (state, action) => {
        const items = bankGuaranteeRewardsMapper.mapMany(action.payload.body.bankRewards);
        bankGuaranteeRewardsAdapter.setAll(state, items.rewards);
        state.banks = items.banks;
        state.companyType = action.payload.body.companyType.code;
      })
      .addMatcher(isAsyncThunkAction(getBankGuaranteeRewards), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const bankGuaranteeRewardsReducer = slice.reducer;

export const { reset: resetBankGuaranteeRewards } = slice.actions;
