import * as Sentry from "@sentry/react";
import { Logger } from "../../../types/Logger";
import { ApiError } from "../../../../api/types";
import { LogLevel } from "../../../types/LogLevel";
import { splitSliceName } from "../../../../store/utils";
import { RejectedThunkError } from "../../../exceptions/RejectedThunkError";

const DEFAULT_ERROR_MESSAGE = "Unknown Error";

type CaptureContext = Parameters<typeof Sentry.captureMessage>[1];

const logThunkError = <T>(thunkName: string, error: ApiError<T>) => {
  if (error.isAborted) {
    return;
  }

  const errorMessage = error.message ?? DEFAULT_ERROR_MESSAGE;

  const getScopeForThunk: CaptureContext = (scope) => {
    const thunkParts = splitSliceName(thunkName);
    const firstPart = thunkParts.length > 0 ? thunkParts[0] : thunkName;

    return scope
      .addBreadcrumb({
        type: "info",
        level: "info",
        category: "started",
        data: error,
      })
      .setFingerprint(error.isApiError ? ["thunk", firstPart] : [errorMessage])
      .setLevel("warning")
      .setTransactionName(`thunk ${thunkName}`);
  };

  if (!error.isApiError) {
    Sentry.captureMessage(errorMessage, getScopeForThunk);
  } else {
    Sentry.captureException(new RejectedThunkError(error.message), getScopeForThunk);
  }
};

const levels: Record<LogLevel, Sentry.SeverityLevel> = {
  error: "error",
  critical: "fatal",
  warn: "warning",
  debug: "debug",
  info: "info",
};

const log: Logger["log"] = (logLevel, error) => {
  if (typeof error === "string") {
    switch (logLevel) {
      case "critical":
      case "error":
        Sentry.captureException(error);
        break;
      default:
        Sentry.captureMessage(error);
    }

    return;
  }

  Sentry.captureException(error, {
    level: levels[logLevel],
  });
};

export const loggingSentryService: Logger = {
  log,
  logThunkError,
};
