import { MouseEvent, useState, useMemo, useRef } from "react";

export const useVerticalDraggableSlider = () => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState<number | undefined>();
  const [scrollLeft, setScrollLeft] = useState<number | undefined>();

  const ref = useRef<HTMLDivElement>(null);

  return useMemo(() => {
    const onMouseLeave = () => {
      setIsMouseDown(false);
    };

    const onMousedown = (e: MouseEvent<HTMLDivElement>) => {
      if (ref.current) {
        setIsMouseDown(true);
        setStartX(e.pageX - ref.current.offsetLeft);
        setScrollLeft(ref.current.scrollLeft);
      }
    };

    const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
      if (isMouseDown && ref.current && startX !== undefined && scrollLeft !== undefined) {
        e.preventDefault();
        const x = e.pageX - ref.current.offsetLeft;
        const walk = (x - startX) * 2;
        if (ref.current) {
          ref.current.scrollLeft = scrollLeft - walk;
        }
      }
    };

    return { onMousedown, onMouseLeave, onMouseMove, isGrabbing: isMouseDown, ref };
  }, [isMouseDown, ref, startX, scrollLeft]);
};
