import { useMemo } from "react";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { ProductStageIcon } from "../../../common/ui/product/stage";
import { getCreditExecutionDemandStageColor, getCreditExecutionDemandStageIcon } from "../utils";
import { CreditExecutionDemandStageType } from "../types";

export interface CreditExecutionStageIconProps extends BaseCssProps {
  stage: CreditExecutionDemandStageType;
}

export const CreditExecutionStageIcon = ({ stage, className }: CreditExecutionStageIconProps) => {
  const color = getCreditExecutionDemandStageColor(stage);
  const icon = useMemo(() => getCreditExecutionDemandStageIcon(stage), [stage]);

  return <ProductStageIcon icon={icon} color={color} className={className} />;
};
