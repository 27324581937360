import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { isIdle } from "../../../common/types/state";
import { AsyncThunkActionRequest } from "../../../common/types/thunks";
import { CreditExecutionId } from "../../../common/types/demand";
import { getCreditExecutionDetails } from "../store/creditExecutionDetails.thunks";
import { selectCreditExecutionDetailsProductDetails } from "../store/product-details/creditExecutionDetailsProductDetails.selectors";

export const useCreditExecutionDetailsFetcher = (creditExecutionId: CreditExecutionId) => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectCreditExecutionDetailsProductDetails);

  const requestRef = useRef<AsyncThunkActionRequest<{ creditExecutionId: CreditExecutionId }> | undefined>(undefined);

  useEffect(() => {
    requestRef.current?.abort();
    requestRef.current = undefined;
  }, [creditExecutionId]);

  useEffect(() => {
    if (isIdle(status)) {
      requestRef.current = dispatch(getCreditExecutionDetails({ creditExecutionId }));
    }
  }, [creditExecutionId, dispatch, status]);
};
