import { useRef } from "react";
import * as S from "./ChatDialogMain.styled";
import { useChatDialogScrollIntoView } from "../../hooks/useChatDialogScrollIntoView";
import { useChatDialogFirstPageFetchEffect } from "../../hooks/messages/useChatDialogFirstPageFetchEffect";
import { useChatDialogNewMessageScrollEvent } from "../../hooks/hub/useChatDialogNewMessageScrollEvent";
import { useChatDialogMarkMessagesAsRead } from "../../hooks/hub/useChatDialogMarkMessagesAsRead";
import { useChatDialogScroll } from "../../hooks/useChatDialogScroll";
import { ChatDialogMainMessages } from "./ChatDialogMainMessages";
import { useChatDialogUnmountEffect } from "../../hooks/useChatDialogUnmountEffect";

export const ChatDialogMain = () => {
  const rootRef = useRef<HTMLDivElement | null>(null);

  const [bottomElementRef, scrollIntoView] = useChatDialogScrollIntoView<HTMLDivElement>();
  useChatDialogFirstPageFetchEffect(scrollIntoView);
  useChatDialogNewMessageScrollEvent(scrollIntoView);
  useChatDialogMarkMessagesAsRead();

  const { onRootScroll, showObserver, observerRef, previousLastMessageRef, previousLastMessageId } =
    useChatDialogScroll({ rootRef });

  useChatDialogUnmountEffect();

  return (
    <S.Wrapper ref={rootRef} onScroll={onRootScroll}>
      {showObserver && <div ref={observerRef} />}
      <S.SpaceFiller />
      <ChatDialogMainMessages previousLastMessageId={previousLastMessageId} ref={previousLastMessageRef} />
      <div ref={bottomElementRef} />
    </S.Wrapper>
  );
};
