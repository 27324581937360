import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { SettlementAndCashCreationFromBankContainer } from "./SettlementAndCashCreationFromBankContainer";

export type SettlementAndCashCreationFromBankSkeletonProps = BaseCssProps;

export const SettlementAndCashCreationFromBankSkeleton = ({
  className,
}: SettlementAndCashCreationFromBankSkeletonProps) => {
  return (
    <SettlementAndCashCreationFromBankContainer
      bankName={<Skeleton width={150} />}
      button={<Skeleton width={100} height={32} borderRadius={16} />}
      isSelected={false}
      className={className}
    />
  );
};
