import { UserProfileInfoAgent } from "../components/UserProfileInfoAgent";
import { UserProfileInfoForm } from "../components/UserProfileInfoForm";
import { selectAgencyDetails } from "../../../agency/details/store/agencyDetails.selectors";
import { useAppSelector } from "../../../common/store";
import { isLoading, isSucceeded } from "../../../common/types/state";
import { CreationBlockWithTitle } from "../../../common/ui-kit/cards/creation-block-card";
import { selectUserDetails } from "../store/userDetails.selectors";
import * as S from "./UserProfilePage.styled";
import { FullPageLayoutContainer } from "../../../common/ui/layout";
import { UserProfileFormWrapper } from "../components/UserProfileFormWrapper";
import { UserProfilePageHeader } from "../components/header/UserProfilePageHeader";
import { Text } from "../../../common/ui-kit/typography";
import { UserProfileInfoSkeletonForm } from "../components/UserProfileInfoForm.skeleton";
import { UserProfileInfoAgentSkeletonForm } from "../components/UserProfileInfoAgent.skeleton";

export const UserProfilePage = () => {
  const {
    user,
    get: { status },
  } = useAppSelector(selectUserDetails);

  const { company, bankAccount, address, contact, status: agencyStatus } = useAppSelector(selectAgencyDetails);

  return (
    <FullPageLayoutContainer>
      <UserProfilePageHeader />
      <S.MainContainer>
        <S.CenteredContainer>
          <UserProfileFormWrapper status={status}>
            <CreationBlockWithTitle title="Пользователь">
              {isLoading(status) && <UserProfileInfoSkeletonForm />}
              {isSucceeded(status) && user && <UserProfileInfoForm user={user} />}
            </CreationBlockWithTitle>
            <CreationBlockWithTitle title={`Агент ${company?.typeTitle ?? ""}`}>
              {isLoading(agencyStatus) && <UserProfileInfoAgentSkeletonForm />}
              {isSucceeded(agencyStatus) && (
                <>
                  <Text fontSize={{ xs: "fz4", xl: "1.375rem" }} fontColor="colors.dark" fontWeight={700}>
                    {company?.name ?? ""}
                  </Text>
                  <UserProfileInfoAgent
                    company={company}
                    bankAccount={bankAccount}
                    address={address}
                    contact={contact}
                  />
                </>
              )}
            </CreationBlockWithTitle>
          </UserProfileFormWrapper>
        </S.CenteredContainer>
      </S.MainContainer>
    </FullPageLayoutContainer>
  );
};
