import styled from "@emotion/styled";
import { StyledRouterLink } from "../../../common/ui/links";
import { NavigationStateFrom } from "../../../common/navigation/navigation-state";
import { StyledH3 } from "../../../common/ui-kit/typography/StyledH3";
import { CreationBlockCard } from "../../../common/ui-kit/cards/creation-block-card";

const StyledArticle = styled(CreationBlockCard)`
  min-height: 100px;
  width: 100%;
  text-align: center;
  position: relative;

  ${({ theme }) => theme.breakpoints.mqUp("md")} {
    height: 200px;
    width: 270px;
  }
`;

const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  padding: 12px;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  flex-grow: 1;
`;

const StyledLink = styled(StyledRouterLink)`
  width: 100%;
  display: block;
  padding: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  text-decoration: none;
  text-align: center;
  flex-grow: 0;

  ${({ theme }) => theme.breakpoints.mqBetween("xs", theme.breakpoints.desktopBreakpoint)} {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: auto;
      content: "";
      background-color: transparent;
    }
  }
`;

export interface CreateProductCardProps {
  title: string;
  description?: string;
  link: string;
  from?: NavigationStateFrom;
}

export const CreationProductCard = ({ title, description, link, from }: CreateProductCardProps) => {
  return (
    <StyledArticle>
      <StyledH3>{title}</StyledH3>
      <StyledParagraph>{description}</StyledParagraph>
      <StyledLink to={link} state={from}>
        Оформить новую заявку
      </StyledLink>
    </StyledArticle>
  );
};
