import { FormNumberInput } from "@sideg/ui-kit/atoms/form-controls/number-input";
import * as S from "../grid-container/WidgetsCalculatorGridContainer.styled";
import { BaseCssProps } from "../../../../ui-kit/types";
import { useGetFieldName } from "../../../../form";
import { WidgetsCalculatorFormValues } from "../../types/WidgetsCalculatorFormValues";
import { useFormControlsNumberInputField } from "../../../../ui/form-controls/number-input-field";

const PeriodInput = S.Input.withComponent(FormNumberInput);

export interface WidgetsCalculatorFormPeriodFieldProps extends BaseCssProps {
  index: number;
  isDisabled?: boolean;
}

export const WidgetsCalculatorFormPeriodField = ({
  index,
  className,
  isDisabled,
}: WidgetsCalculatorFormPeriodFieldProps) => {
  const getFieldName = useGetFieldName<WidgetsCalculatorFormValues>();
  const fieldProps = useFormControlsNumberInputField(getFieldName("period"));

  return <PeriodInput label="Срок дней" index={index} className={className} {...fieldProps} disabled={isDisabled} />;
};
