import Skeleton from "react-loading-skeleton";

export interface BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardSkeletonProps {
  containerTestId?: string;
}

export const BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardSkeleton = ({
  containerTestId,
}: BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardSkeletonProps) => {
  return <Skeleton containerTestId={containerTestId} height={85} />;
};
