import { useMemo } from "react";
import { bankGuaranteeDetailsDemandHeaderTab } from "../../types/demand/BankGuaranteeDetailsDemandHeaderTab";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDetailsReissuesTotal } from "../../store/reissues/BankGuaranteeDetailsReissues.selectors";

export const useBankGuaranteeDetailsDemandFilteringTabs = () => {
  const reissuesCount = useAppSelector(selectBankGuaranteeDetailsReissuesTotal);

  return useMemo(
    () =>
      reissuesCount === 0
        ? bankGuaranteeDetailsDemandHeaderTab.values.filter((x) => x !== "reissues")
        : bankGuaranteeDetailsDemandHeaderTab.values,
    [reissuesCount],
  );
};
