import { capitalize } from "@sideg/helpers";
import { LoggerProvider } from "../types/LoggerProvider";
import { Logger } from "../types/Logger";
import { LogLevel, logLevels } from "../types/LogLevel";
import { loggingSentryProvider } from "../providers/sentry";
import { loggingConsoleProvider } from "../providers/console";

const providers: LoggerProvider[] = [loggingConsoleProvider, loggingSentryProvider];

const executeEach = (action: (x: Logger) => void) => {
  providers.filter((x) => x.isEnabled).forEach((provider) => action(provider.logger));
};

const log: Logger["log"] = (level, error) => executeEach((x) => x.log(level, error));
const logThunkError: Logger["logThunkError"] = (thunkName, error) =>
  executeEach((x) => x.logThunkError(thunkName, error));

type LevelLoggers = Record<`log${Capitalize<LogLevel>}`, (error: unknown) => void>;
const levelLoggers: LevelLoggers = logLevels.reduce((accum, logLevel) => {
  const key = `log${capitalize(logLevel)}` as keyof LevelLoggers;
  accum[key] = (error: unknown) => log(logLevel, error);

  return accum;
}, {} as LevelLoggers);

export const loggingService = {
  log,
  logThunkError,
  ...levelLoggers,
};
