import { createAppEntityAdapter } from "../../../common/store/utils";
import { LegalServicesProductId } from "../../common/types";
import { LegalServicesCreationProduct } from "../types/LegalServicesCreationProduct";

export const legalServicesCreationAdapter = createAppEntityAdapter<
  LegalServicesCreationProduct,
  LegalServicesProductId
>({
  selectId: (product) => product.id,
});
