import { memo } from "react";
import Skeleton from "react-loading-skeleton";
import { Text } from "../../../../common/ui-kit/typography";
import { useAppSelector } from "../../../../common/store";
import { isLoading } from "../../../../common/types/state";
import { selectCreditWorkingAssetsDetailsDemandsTotal } from "../../store/demands/creditWorkingAssetsDetailsDemands.selectors";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";
import { declensionHelper } from "../../../../common/helpers/entity";

export interface CreditWorkingAssetsDetailsAsideNumberTitleProps {
  className?: string;
}

export const CreditWorkingAssetsDetailsAsideNumberTitle = memo(
  ({ className }: CreditWorkingAssetsDetailsAsideNumberTitleProps) => {
    const { status, isTransferred, data } = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails);
    const totalCount = useAppSelector(selectCreditWorkingAssetsDetailsDemandsTotal);

    const showSkeleton = isLoading(status) && (totalCount === 0 || isTransferred);

    return (
      <Text className={className} fontSize="fz4" lineHeight="defaultLineHeight" fontWeight={700}>
        Заявка № {data?.groupProductName ?? <Skeleton inline width={80} />}&nbsp;
        {showSkeleton && <Skeleton width={50} />}
        {!isTransferred &&
          totalCount > 0 &&
          `в ${declensionHelper.commonDeclensions.bank.nominative(totalCount, true)}`}
      </Text>
    );
  },
);
