import { memo } from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditWorkingAssetsListTabs } from "./CreditWorkingAssetsListTabs";
import { useCreditWorkingAssetsListFilterContext } from "../../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { filterTabType, useFiltersTabCounterGetValue } from "../../../../../common/filters/use-cases";
import { useCreditWorkingAssetsListFilterOnTabChange } from "../../../hooks/filters/useCreditWorkingAssetsListFilterOnTabChange";
import { selectCreditWorkingAssetsListCounters } from "../../../store/counters/creditWorkingAssetsListCounters.selectors";

export type CreditWorkingAssetsListTabFilterProps = BaseCssProps;

export const CreditWorkingAssetsListTabFilter = memo(({ className }: CreditWorkingAssetsListTabFilterProps) => {
  const getTabCounterValue = useFiltersTabCounterGetValue((state) => selectCreditWorkingAssetsListCounters(state).data);
  const filteringObject = useCreditWorkingAssetsListFilterContext();
  const onTabChange = useCreditWorkingAssetsListFilterOnTabChange();

  return (
    <CreditWorkingAssetsListTabs.Container
      activeTab={filteringObject.tab}
      onTabChange={onTabChange}
      className={className}
    >
      {filterTabType.values.map((x) => (
        <CreditWorkingAssetsListTabs.Tab
          key={x}
          tab={x}
          text={filterTabType.getTitle(x)}
          xsFlexBasis="auto"
          counterValue={getTabCounterValue(x)}
        />
      ))}
    </CreditWorkingAssetsListTabs.Container>
  );
});
