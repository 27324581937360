import styled from "@emotion/styled";
import { mapThemeTypographyVariantToCssObject } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypographyVariantTokenValue";
import { ElementWithErrorProps } from "@sideg/ui-kit/atoms/form-controls/common/types";

export const ErrorWrapper = styled.div<ElementWithErrorProps>`
  transition-property: height;
  transition-timing-function: ease;
  width: 100%;
  height: ${({ error }) => (error ? "auto" : "0px")};
  overflow: hidden;
  transition: height ${({ error }) => (error ? "unset" : "250ms")} ease;
  margin-top: ${({ theme, error }) => (error ? theme.spacing.xs : 0)};
`;

export const Error = styled.div<ElementWithErrorProps>`
  color: ${({ theme }) => theme.palette.light.signal.text.error};
  text-align: left;
  opacity: ${({ error }) => (error ? 1 : 0)};
  transition: opacity 0.25s;
  transition-delay: ${({ error }) => (error ? ".1s" : "0s")};
  ${({ theme }) => mapThemeTypographyVariantToCssObject("controlsDesktop.inputs.signature", theme)};
`;
