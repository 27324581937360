import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import { PublicUserResetPasswordToken } from "../../types/PublicUserResetPasswordToken";
import { isFailed, isLoading, isSucceeded } from "../../../../common/types/state";
import { AlertTriangleBigIcon, DoneCircleBigIcon, Icon } from "../../../../common/ui-kit/icons";
import { usePublicUserResetPasswordApprove } from "../../hooks/usePublicUserResetPasswordApprove";
import {
  AppPublicLayoutBlockWrapper,
  AppPublicLayoutLinkButton,
} from "../../../../common/ui/layout/app-public-layout-containers";
import { PublicUserDescriptionSuccessText, PublicUserResultCard } from "../../../common/components";
import { Button } from "../../../../common/ui-kit/form-controls/button";
import { AppLinkButton } from "../../../../common/ui/links";

export interface PublicUserResetPasswordApproveProps {
  token: PublicUserResetPasswordToken;
}

export const PublicUserResetPasswordApprove = ({ token }: PublicUserResetPasswordApproveProps) => {
  const { status, mutate, data, error } = usePublicUserResetPasswordApprove(token);

  useEffect(() => {
    mutate();
  }, [mutate]);

  return (
    <AppPublicLayoutBlockWrapper>
      <PublicUserResultCard
        icon={
          <>
            {isSucceeded(status) && (
              <Icon as={DoneCircleBigIcon} stroke="status.success" data-testid={`${status}-icon`} />
            )}
            {isFailed(status) && (
              <Icon as={AlertTriangleBigIcon} stroke="status.error" data-testid={`${status}-icon`} />
            )}
            {isLoading(status) && <Skeleton width={72} height={72} containerTestId={`${status}-icon`} />}
          </>
        }
        title={
          <>
            {isSucceeded(status) && "Пароль обновлен!"}
            {isFailed(status) && "Ошибка обновления пароля"}
            {isLoading(status) && <Skeleton width={200} />}
          </>
        }
        description={
          <>
            {isSucceeded(status) && data && (
              <PublicUserDescriptionSuccessText
                startText="Отправили письмо с новым паролем"
                phone={data.phone}
                email={data.email}
              />
            )}
            {isFailed(status) && error}
            {isLoading(status) && <Skeleton count={2} width={300} />}
          </>
        }
      >
        {isSucceeded(status) && (
          <AppLinkButton isFullWidth size="medium" to={(x) => x.publicUser.authentication.login}>
            Вернуться ко входу
          </AppLinkButton>
        )}
        {isFailed(status) && (
          <Button
            isFullWidth
            type="button"
            onClick={() => {
              mutate();
            }}
          >
            Попробовать еще раз
          </Button>
        )}
        {isLoading(status) && <Skeleton height={40} width={300} />}
      </PublicUserResultCard>
      {isFailed(status) && (
        <AppPublicLayoutLinkButton to={(x) => x.publicUser.authentication.login}>
          Вернуться ко входу
        </AppPublicLayoutLinkButton>
      )}
    </AppPublicLayoutBlockWrapper>
  );
};
