import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";

export type BankGuaranteeListItemDesktopDemandSkeletonProps = BaseCssProps;

export const BankGuaranteeListItemDesktopDemandSkeleton = ({
  className,
}: BankGuaranteeListItemDesktopDemandSkeletonProps) => {
  return <Skeleton className={className} height={27} />;
};
