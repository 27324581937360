import { useCallback } from "react";
import { RawApiResponse } from "../../../../common/api/types/RawApiResponse";
import { ChatDialogMessage as ChatDialogMessageType } from "../../types/ChatDialogMessage";
import { chatDialogHubNewMessageReceived } from "../../store/chatDialog.slice";
import { useHubEvent } from "../../../../common/hubs";
import { chatDialogHubConnection } from "../../api/chatDialogHubConnection";
import { useAppDispatch } from "../../../../common/store";

export const useChatDialogNewMessageDispatchEvent = () => {
  const dispatch = useAppDispatch();

  const onNewMessage = useCallback(
    (payload: RawApiResponse<{ data: ChatDialogMessageType }>) => {
      dispatch(chatDialogHubNewMessageReceived(payload.body.data));
    },
    [dispatch]
  );

  useHubEvent(chatDialogHubConnection, "NewMessage", onNewMessage);
};
