import { AppVersion } from "../types/AppVersion";

type CheckVersion = (version: string | undefined) => boolean;

const check =
  (appVersion: AppVersion, ...params: ReturnType<AppVersion["compare"]>[]): CheckVersion =>
  (version: string | undefined) => {
    const compareResult = appVersion.compare(version);

    return params.some((x) => x === compareResult);
  };

export class ApplicationUpdaterService {
  public readonly isOtherVersion: CheckVersion;

  public readonly isNeedForceUpdate: CheckVersion;

  constructor(appVersion: AppVersion) {
    this.isOtherVersion = check(appVersion, "older", "newer");
    this.isNeedForceUpdate = check(appVersion, "older", "same");
  }
}
