import {
  bankGuaranteeListItemClientAdapter,
  bankGuaranteeListItemDemandsAdapter,
  bankGuaranteeListItemsGuaranteesAdapter,
} from "./bankGuaranteeListItems.adapter";
import { RootState } from "../../../../common/store";
import { BankGuaranteeListItemsState } from "./bankGuaranteeListItems.types";

export const selectBankGuaranteeListItems = (state: RootState): BankGuaranteeListItemsState =>
  state.domains.bankGuarantee.list.items;

export const {
  selectIds: selectBankGuaranteeListItemsGuaranteesIds,
  selectById: selectBankGuaranteeListItemsGuaranteeById,
} = bankGuaranteeListItemsGuaranteesAdapter.getSelectors(
  (state: RootState) => selectBankGuaranteeListItems(state).guarantees
);

export const { selectIds: selectBankGuaranteeListItemsDemandsIds, selectById: selectBankGuaranteeListItemsDemandById } =
  bankGuaranteeListItemDemandsAdapter.getSelectors((state: RootState) => selectBankGuaranteeListItems(state).demands);

export const { selectIds: selectBankGuaranteeListItemsClientsIds, selectById: selectBankGuaranteeListItemsClientById } =
  bankGuaranteeListItemClientAdapter.getSelectors((state: RootState) => selectBankGuaranteeListItems(state).clients);
