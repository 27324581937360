import { useCallback, useRef } from "react";
import { FormSubmitHandler, formValidationFieldService } from "../../../common/form";
import { UserChangePasswordFormValues } from "../types/UserChangePasswordFormValues";
import { useApiMutationAction } from "../../../common/hooks";
import { useAppDispatch } from "../../../common/store";
import { userChangePasswordValidationSchema } from "../validation/userChangePasswordValidationSchema";
import { postUserChangePassword } from "../store/userChangePassword.thunks";
import { isBaseApiError } from "../../../common/api/types";
import { pushErrorGlobalToast, pushSuccessGlobalToast } from "../../../global/toasts";

export interface UseUserChangePasswordOnSubmitArgs {
  onSuccess?: () => void | Promise<void>;
}

export const useUserChangePasswordOnSubmit = (
  onSuccess?: UseUserChangePasswordOnSubmitArgs["onSuccess"]
): FormSubmitHandler<UserChangePasswordFormValues> => {
  const dispatch = useAppDispatch();
  const { mutate } = useApiMutationAction();

  const onSuccessRef = useRef<UseUserChangePasswordOnSubmitArgs["onSuccess"] | null>(onSuccess);
  onSuccessRef.current = onSuccess;

  return useCallback(
    async (values, { setErrors }) => {
      const { oldPassword, newPassword } = userChangePasswordValidationSchema.cast(values);
      const result = await mutate(
        postUserChangePassword,
        { oldPassword, newPassword },
        {
          errorHandler: async (e) => {
            const validationErrors = formValidationFieldService.tryGetValidationErrorsNullable(e);
            if (validationErrors !== undefined) {
              setErrors(validationErrors);
            }

            let message = "Во время обработки запроса произошла ошибка. Пожалуйста, попробуйте ещё раз позже.";
            if (isBaseApiError(e)) {
              message = e.message ?? message;
            }

            dispatch(pushErrorGlobalToast(message));

            return message;
          },
        }
      );

      if (result?.body !== undefined) {
        dispatch(pushSuccessGlobalToast("Пароль успешно изменен"));
        onSuccessRef.current?.();
      }
    },
    [dispatch, mutate]
  );
};
