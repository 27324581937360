import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../types";
import { BaseFormElementError } from "../../../form-controls/common/components";
import { ButtonLoading, ButtonLoadingProps } from "../../../form-controls/button";
import { Box } from "../../../containers/box";

export interface BlockResponsiveSubmitButtonProps
  extends BaseCssProps,
    Omit<ButtonLoadingProps, "className" | "ref" | "variant" | "color" | "size" | "isFullWidth" | "alignSelf"> {
  error?: string;
}

export const BlockResponsiveSubmitButton = ({ error, className, ...buttonProps }: BlockResponsiveSubmitButtonProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Box display="flex" flexDirection="column" gap="xs" className={className}>
      <ButtonLoading
        loaderPosition="replace"
        alignSelf="flex-start"
        isFullWidth={{ xs: true, [desktopBreakpoint]: false }}
        height={{ xs: "3rem", [desktopBreakpoint]: "auto" }}
        size="default"
        variant="contained"
        color="primary"
        {...buttonProps}
      />
      <BaseFormElementError error={error} />
    </Box>
  );
};
