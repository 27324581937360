import styled from "@emotion/styled";
import { CheckboxSize, CheckboxSizeType } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";

const checkboxSizes: CheckboxSizeType<"width"> = {
  small: "16px",
  default: "20px",
};

const checkboxOutlineSizes: CheckboxSizeType<"outline"> = {
  small: "4px",
  default: "6px",
};

const checkboxOutlineFocusSizes: CheckboxSizeType<"outline"> = {
  small: "2px",
  default: "4px",
};

const checkboxIconCircleCxCySizes: CheckboxSizeType<"scale"> = {
  small: "8px",
  default: "10px",
};

const checkboxIconOuterCircleRadiusSizes: CheckboxSizeType<"scale"> = {
  small: "7.25px",
  default: "9.25px",
};

const checkboxIconInnerCircleRadiusSizes: CheckboxSizeType<"scale"> = {
  small: "4px",
  default: "5px",
};

export const Label = styled.label<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.sm};
  align-items: center;
  justify-content: flex-start;
  vertical-align: middle;
  outline: none;

  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
`;

export const RadioCheckboxInputContainer = styled.span<{ checkboxSize: CheckboxSize }>`
  height: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  width: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  line-height: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  position: relative;
  z-index: 1;
  border-radius: ${({ theme }) => theme.radii.control};

  @supports selector(:has(*)) {
    &:has(input:focus-visible) {
      outline: 1px solid ${({ theme }) => theme.palette.light.primary.fill.pressed};
      outline-offset: ${({ checkboxSize }) => checkboxOutlineFocusSizes[checkboxSize]};
    }
  }

  @supports not selector(:has(*)) {
    &:focus-within {
      outline: 1px solid ${({ theme }) => theme.palette.light.primary.fill.pressed};
      outline-offset: ${({ checkboxSize }) => checkboxOutlineFocusSizes[checkboxSize]};
    }
  }
`;

export const RadioCheckboxInput = styled.input<{ checkboxSize: CheckboxSize }>`
  appearance: none;
  border: 1.5px solid ${({ theme }) => theme.palette.light.neutral.text.secondary};
  border-radius: 50%;
  height: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  width: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  left: 0;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: -1;

  &:checked {
    appearance: none;
    border: none;
  }

  &:checked ~ svg {
    display: flex;
  }

  &:hover:enabled {
    outline: ${({ checkboxSize }) => checkboxOutlineSizes[checkboxSize]} solid
      ${({ theme }) => theme.palette.light.primary.tonal.default};
    outline-offset: 0;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.light.neutral.tonal};
    border-color: ${({ theme }) => theme.palette.light.neutral.outline.secondary};
    cursor: default;
  }

  &:disabled:checked {
    cursor: default;
  }

  &:disabled:hover {
    outline: none;
  }
`;

export const Icon = styled.svg`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
`;

export const IconOuterCircle = styled.circle<{ isDisabled?: boolean; outerCircleSizes: CheckboxSize }>`
  fill: ${({ isDisabled, theme }) =>
    isDisabled ? theme.palette.light.neutral.tonal : theme.palette.light.primary.text.inverse};

  stroke: ${({ isDisabled, theme }) =>
    isDisabled ? theme.palette.light.neutral.outline.secondary : theme.palette.light.primary.text.default};

  cx: ${({ outerCircleSizes }) => checkboxIconCircleCxCySizes[outerCircleSizes]};
  cy: ${({ outerCircleSizes }) => checkboxIconCircleCxCySizes[outerCircleSizes]};

  r: ${({ outerCircleSizes }) => checkboxIconOuterCircleRadiusSizes[outerCircleSizes]};
`;

export const IconInnerCircle = styled.circle<{ isDisabled?: boolean; innerCircleSizes: CheckboxSize }>`
  fill: ${({ isDisabled, theme }) =>
    isDisabled ? theme.palette.light.neutral.outline.primary : theme.palette.light.primary.text.default};

  cx: ${({ innerCircleSizes }) => checkboxIconCircleCxCySizes[innerCircleSizes]};
  cy: ${({ innerCircleSizes }) => checkboxIconCircleCxCySizes[innerCircleSizes]};

  r: ${({ innerCircleSizes }) => checkboxIconInnerCircleRadiusSizes[innerCircleSizes]};
`;
