import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { bankGuaranteeDocumentsAdapter } from "./bankGuaranteeDocuments.adapter";
import { BankGuaranteeDocumentSliceState } from "./bankGuaranteeDocuments.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import {
  BANK_GUARANTEE_DOCUMENTS_SLICE_NAME,
  getBankGuaranteeDocuments,
  getBanksBankGuaranteeDocuments,
} from "./bankGuaranteeDocuments.thunks";
import { bankGuaranteeDocumentsMapper } from "./bankGuaranteeDocuments.mapper";

const initialState = bankGuaranteeDocumentsAdapter.getInitialState<BankGuaranteeDocumentSliceState>({
  status: LoadingStatusEnum.Idle,
  banks: {
    status: LoadingStatusEnum.Idle,
    data: undefined,
  },
});

const slice = createSlice({
  name: BANK_GUARANTEE_DOCUMENTS_SLICE_NAME,
  initialState,
  reducers: {
    removeDocuments: (state) => {
      bankGuaranteeDocumentsAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankGuaranteeDocuments.fulfilled, (state, action) => {
        bankGuaranteeDocumentsAdapter.setAll(state, bankGuaranteeDocumentsMapper.mapMany(action.payload.body));
      })
      .addCase(getBankGuaranteeDocuments.rejected, (state) => {
        bankGuaranteeDocumentsAdapter.removeAll(state);
      })
      .addCase(getBanksBankGuaranteeDocuments.fulfilled, (state, action) => {
        state.banks.data = bankGuaranteeDocumentsMapper.mapBanks(action.payload.body);
      })
      .addMatcher(isAsyncThunkAction(getBankGuaranteeDocuments), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      })
      .addMatcher(isAsyncThunkAction(getBanksBankGuaranteeDocuments), (state, action) => {
        state.banks.status = getLoadingStatusFromAction(action);
      });
  },
});

export const bankGuaranteeDocumentsReducer = slice.reducer;

export const { removeDocuments: removeDocumentsBankGuaranteeDocuments } = slice.actions;
