
interface DateAsNumbers {
    day: number
    month: number
    year: number
}

class DateHelper {

    static applyMask(value: string, dv: string = "."): string {
        let v = value.replace(/\D/g, '').slice(0, 8)

        if (v.length >= 5) {
            return `${v.slice(0, 2)}${dv}${v.slice(2, 4)}${dv}${v.slice(4)}`
        } else if (v.length >= 3) {
            return `${v.slice(0, 2)}${dv}${v.slice(2)}`
        }

        return v
    }

    static isValid(d: string): boolean {
        const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/

        return !!(d.match(dateRegex))
    }

    static getNumbersFromString(dateString: string): null | DateAsNumbers {
        if (!this.isValid(dateString)) 
            return null

        const d = dateString.replace(/\D/g, '')

        const day = Number(d.slice(0, 2))
        const month = Number(d.slice(2, 4))
        const year = Number(d.slice(4, 8))

        return {day, month, year}
    }

    static exists(d: string) {
        if (!this.isValid(d)) 
            return false

        const { day, month, year } = this.getNumbersFromString(d) as DateAsNumbers

        if (day > 31 || month > 12 || year > 2100)
            return false

        const leapYear = this.isLeapYear(year)
        const months = this.getMonths(leapYear)

        if (months[month - 1] < day)
            return false

        return true
    }

    static isLeapYear(year: number): boolean {
        return !((year % 4) || (!(year % 100) && (year % 400)))
    }

    static getMonths(leap: boolean): number[] {
        const daysInFebruary = leap ? 29 : 28

        return [
            31,             // январь
            daysInFebruary, // февраль
            31,             // март
            30,             // апрель
            31,             // май
            30,             // июнь
            31,             // июль
            31,             // август
            30,             // сентябрь
            31,             // октябрь
            30,             // ноябрь
            31              // декабрь
        ]
    }

    static asObject(d: string): Date | null {
        if (!this.isValid(d))
            return null

        const { day, month, year } = this.getNumbersFromString(d) as DateAsNumbers
        const dateObject = new Date(year, month - 1, day)

        return dateObject
    }
}

export default DateHelper