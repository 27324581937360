import { useState } from "react";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { CreditWorkingAssetsDetailsNavigationHeaderMobile } from "./CreditWorkingAssetsDetailsNavigationHeader.mobile";
import { CreditWorkingAssetsDetailsNavigationHeaderDesktop } from "./CreditWorkingAssetsDetailsNavigationHeader.desktop";
import { useNavigationStateFromWithFallback } from "../../../../common/navigation/navigation-state";
import { generatePath } from "../../../../common/urls/internal/utils";

export const CreditWorkingAssetsDetailsNavigationHeader = () => {
  const { from } = useNavigationStateFromWithFallback(
    generatePath((x) => x.creditWorkingAssets.list.root),
    "Мои заявки",
  );

  const [initialFrom] = useState(from);

  return (
    <ThemeMobileDesktopView
      mobile={<CreditWorkingAssetsDetailsNavigationHeaderMobile from={initialFrom} />}
      desktop={<CreditWorkingAssetsDetailsNavigationHeaderDesktop from={initialFrom} />}
    />
  );
};
