import {
  AuthFetchClientConfig,
  createAxiosJwtInstance,
  createJwtClient,
  FetchRequestOptions,
  StatusCodeEnum,
} from "../../services/fetchClient";
import { authenticationService } from "../../authentication";
import { ApiVersionType } from "../types/ApiVersionType";
import { ApiFetchClient } from "../types/ApiFetchClient";
import { apiConfig } from "../configs";
import { ApiResponse } from "../types";
import { apiTransformers } from "../transformers/apiTransformers";

const isAuthorizationFailed = (status: StatusCodeEnum | undefined) => status === StatusCodeEnum.Unauthorized;

const authConfig: Omit<AuthFetchClientConfig, keyof FetchRequestOptions> = {
  isTokenExpired: authenticationService.isExpired,
  isAuthorizationFailed,
  isAlwaysSuccessAuthorizationFailed: (response: ApiResponse<undefined> | undefined) =>
    isAuthorizationFailed(response?.status),
  getAuthorizationHeaders: authenticationService.getAuthorizationHeaders,
  updateAuthorizationData: authenticationService.refresh,
  onRequestAuthorizationFailed: authenticationService.removeAuthentication,
};

export const createApiClient = (version: ApiVersionType): ApiFetchClient =>
  createJwtClient({
    baseUrl: `${apiConfig.baseUrl ?? ""}/api/${version}`,
    ...apiTransformers,
    ...authConfig,
  });

/**
 * @deprecated Обратная совместимость. Нужно использовать клиенты из common/api
 */
export const createRawJwtClient = (options?: FetchRequestOptions) =>
  createAxiosJwtInstance({ ...options, ...authConfig });
