import styled from "@emotion/styled";
import { CheckboxSize } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";
import { BaseInput } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseInput";
import { getRadioButtonGroupPalette } from "@sideg/ui-kit/atoms/checkbox/utils/getRadioButtonGroupPaletteType";
import { makeShouldForwardProp } from "@sideg/ui-kit/common/utils";
import { Typography } from "@sideg/ui-kit/atoms/typography";

export interface RadioButtonGroupOptionState {
  isSelected?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  radioSize?: CheckboxSize;
}

export const RadioButtonLabel = styled("label")`
  appearance: none;
  text-decoration: none;
  display: flex;
  position: relative;
  border-radius: ${({ theme }) => theme.radii.control};

  @supports selector(:has(*)) {
    &:has(input:focus-visible) {
      outline: 1px solid ${({ theme }) => theme.palette.light.primary.fill.pressed};
    }
  }

  @supports not selector(:has(*)) {
    &:focus-within {
      outline: 1px solid ${({ theme }) => theme.palette.light.primary.fill.pressed};
    }
  }
`;

export const RadioButton = styled(BaseInput.withComponent("input"))`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const RadioButtonBody = styled(Typography, {
  shouldForwardProp: makeShouldForwardProp<RadioButtonGroupOptionState>((prop) => {
    return prop !== "isSelected" && prop !== "isDisabled" && prop !== "radioSize" && prop !== "isError";
  }),
})<RadioButtonGroupOptionState>`
  width: 100%;
  text-align: center;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.radii.control};
  padding: ${({ theme, radioSize }) =>
    radioSize === "small" ? theme.spacing.makeCssSpaces("xs", "m") : theme.spacing.makeCssSpaces("s", "m")};
  background-color: ${({ theme, isDisabled, isSelected }) =>
    getRadioButtonGroupPalette.background(theme, isDisabled, false, isSelected)};
  z-index: 1;

  &:hover {
    background-color: ${({ theme, isSelected, isDisabled }) =>
      getRadioButtonGroupPalette.background(theme, isDisabled, true, isSelected)};
  }

  input:disabled + &:hover {
    background-color: ${({ isSelected }) => !isSelected && "transparent"};
  }

  input:disabled + & {
    color: ${({ theme, isSelected, isError }) => isSelected && !isError && theme.palette.light.primary.text.inverse};
    background-color: ${({ theme, isSelected }) => isSelected && theme.palette.light.neutral.bgr.quarternary};
  }

  input:not(:disabled) + & {
    cursor: pointer;
  }
`;
