import { DemandQueueTaskActive } from "../../../types/active/DemandQueueTaskActive";
import { DemandQueueTaskActiveFormValues } from "../../../types/active-form/DemandQueueTaskActiveFormValues";
import { demandQueueTaskActiveValidationSchema } from "../../../validation/demandQueueTaskActiveValidationSchema";

export const useDemandQueueTaskActiveFormInitialValue = (
  task: DemandQueueTaskActive
): DemandQueueTaskActiveFormValues => {
  if (task.decisions?.length === 1) {
    return demandQueueTaskActiveValidationSchema.cast({
      decision: task.decisions[0].code,
    }) as DemandQueueTaskActiveFormValues;
  }

  return demandQueueTaskActiveValidationSchema.getDefault() as DemandQueueTaskActiveFormValues;
};
