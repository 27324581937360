import { useState } from "react";
import { entityHelper } from "@sideg/helpers";
import { ShortFile } from "../../../../common/types/files";
import * as S from "./DemandQueueTaskAttachedFilesCollapse.styled";
import { ChevronRightIcon, Icon } from "../../../../common/ui-kit/icons";
import { DemandQueueTaskAttachedFile } from "./DemandQueueTaskAttachedFile";
import { Box } from "../../../../common/ui-kit/containers/box";

export interface DemandQueueTaskAttachedFilesCollapseProps {
  files: ShortFile[];
  className?: string;
}

export const DemandQueueTaskAttachedFilesCollapse = ({
  files,
  className,
}: DemandQueueTaskAttachedFilesCollapseProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const isButtonShown = files.length > 3;

  return (
    <Box px="md" pt="sm" pb="md" className={className}>
      {isButtonShown && (
        <S.Button
          type="button"
          onClick={() => {
            setIsOpened((prev) => !prev);
          }}
        >
          <span>
            {entityHelper.getWordDeclension(files.length, "прикрепленны", ["й", "х", "х"], true)}{" "}
            {entityHelper.getWordDeclension(files.length, "файл", ["", "а", "ов"], false)}
          </span>
          <Icon as={ChevronRightIcon} flexShrink={0} flexGrow={0} />
        </S.Button>
      )}
      {(isOpened || !isButtonShown) && (
        <Box display="flex" gap="sm" flexDirection="column" pt={isButtonShown ? "md" : undefined}>
          {files.map((x) => (
            <DemandQueueTaskAttachedFile file={x} key={x.fileId + x.size + x.privateKey} />
          ))}
        </Box>
      )}
    </Box>
  );
};
