import Downshift from "downshift";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { SelectPopupContainer } from "../../../../../domains/common/ui-kit/form-controls/select";
import { usePopup } from "../../../../../domains/common/ui-kit/pop-up";
import { ReactComponent as ArrowDownIcon } from "../../../../../images/icons/controls-down.svg";
import styles from "./ClientFormMultiSelect.module.scss";
import { ClientFormSelectOptionWrapper } from "./select-wrapper/ClientFormSelectOptionWrapper";
import { ClientFormSingleSelect } from "./single-select/ClientFormSingleSelect";

export interface ClientFormSelectOpt {
  title: string;
  value: string;
}

export interface ClientFormSelectMultipleOpt {
  singleChoose: ClientFormSelectOpt[][];
  multipleChoose: ClientFormSelectOpt[][];
}

interface ClientFormMultiSelectProps {
  label?: string;
  options: ClientFormSelectMultipleOpt;
  name: string;
}

export const ClientFormMultiSelect = ({ label, options, name }: ClientFormMultiSelectProps) => {
  const [multiSelected, setMultiSelected] = useState(new Set(""));
  const [field] = useField<string[]>(name);

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(name, Array.from(new Set([...Array.from(multiSelected), ...Array.from(field.value)])));
  }, [multiSelected, setFieldValue]);

  //используем setFieldValue, потому что useEffect выше каждый раз добавляет в 'scoringItems' и multiSelected и текущий scoringItems
  const deleteValueInScoringItems = (value: string, newSet: Set<string>) => {
    newSet.delete(value);
    setFieldValue(
      "scoringItems",
      Array.from(field.value).filter((item: string) => {
        return item !== value;
      }),
    );
    setMultiSelected(newSet);
  };

  const handleOptionSelection = (newValue: string, prevValue?: string) => {
    const newSet = new Set(multiSelected);
    if (prevValue) {
      deleteValueInScoringItems(prevValue, newSet);
    }
    if (multiSelected.has(newValue)) {
      deleteValueInScoringItems(newValue, newSet);
    } else {
      newSet.add(newValue);
      setMultiSelected(newSet);
    }
  };

  const { referenceElementProps, popupProps } = usePopup({ position: "bottom" });

  return (
    <Downshift>
      {({ getInputProps, getMenuProps, isOpen, getToggleButtonProps, inputValue }) => (
        <div className={styles["wrapper"]}>
          {label && <div className={styles["label"]}>{label}</div>}
          <div className={styles["container"]} ref={referenceElementProps.ref}>
            <button {...getToggleButtonProps()}>
              <ArrowDownIcon />
            </button>
            <input
              className={styles["input"]}
              {...getInputProps()}
              placeholder={multiSelected.size ? "Вы выбрали варианты из списка" : "Выберите из списка"}
            />
          </div>
          <SelectPopupContainer
            open={isOpen}
            {...getMenuProps({ ref: popupProps.ref })}
            style={{ ...popupProps.style, width: referenceElementProps.width }}
          >
            {isOpen ? (
              <div className={styles["options"]}>
                {!!options.singleChoose.length && (
                  <div>
                    {options.singleChoose.map((singleChooseOptions, index) => {
                      return (
                        <ClientFormSingleSelect
                          key={index}
                          singleChooseOptions={singleChooseOptions}
                          handleOptionSelection={handleOptionSelection}
                          multiSelected={multiSelected}
                          inputValue={inputValue}
                        />
                      );
                    })}
                  </div>
                )}
                {!!options.multipleChoose.length && (
                  <ClientFormSelectOptionWrapper
                    title={"Возможен выбор нескольких вариантов из списка ниже"}
                    options={options.multipleChoose.flatMap((item) => item)}
                    multiSelected={multiSelected}
                    onChange={handleOptionSelection}
                    inputValue={inputValue}
                  />
                )}
              </div>
            ) : null}
          </SelectPopupContainer>
        </div>
      )}
    </Downshift>
  );
};
