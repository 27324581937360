import { useState } from "react";
import { entityHelper } from "@sideg/helpers";
import { AsideBlockCollapseButton, AsideBlockMobile } from "../../../../common/ui/layout/sidebar";
import { useAppSelector } from "../../../../common/store";
import { selectUserDetails } from "../../store/userDetails.selectors";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { Box } from "../../../../common/ui-kit/containers/box";
import { ChatIcon, HeadphonesIcon, MailIcon, PhoneIcon } from "../../../../common/ui-kit/icons";
import * as S from "./UserDetailsManagerSidebarBlock.mobile.styled";
import { useChatDialogAppLayoutOverlayPanel } from "../../../../chat/dialog";

export const UserDetailsManagerSidebarBlockMobile = () => {
  const [open, setOpen] = useState(false);
  const { manager } = useAppSelector(selectUserDetails);

  const { openChat } = useChatDialogAppLayoutOverlayPanel();

  if (!manager) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  return (
    <AsideBlockMobile>
      <S.ButtonWrapper>
        <AsideBlockCollapseButton active={false} open={open} onClick={() => setOpen((prev) => !prev)}>
          <StackPanel gap="sm" direction="row" alignItems="center">
            <S.IconContainer>
              <HeadphonesIcon />
            </S.IconContainer>
            <span>Связаться с менеджером</span>
          </StackPanel>
        </AsideBlockCollapseButton>
      </S.ButtonWrapper>
      {open && (
        <S.Ul>
          <li>
            <S.ManagerName>{manager.userName}</S.ManagerName>
          </li>

          {manager.phone && (
            <S.Li>
              <a href={`tel:+${manager.phone}`}>
                <StackPanel gap="md" direction="row">
                  <Box as={PhoneIcon} flexGrow={0} flexShrink={0} />
                  <span>{entityHelper.formatPhoneNumber(manager.phone)}</span>
                </StackPanel>
              </a>
            </S.Li>
          )}
          {manager.email && (
            <S.Li>
              <a href={`mailto:${manager.email}`}>
                <StackPanel gap="md" direction="row">
                  <Box as={MailIcon} flexGrow={0} flexShrink={0} />
                  <span>{manager.email}</span>
                </StackPanel>
              </a>
            </S.Li>
          )}

          <S.Li>
            <button type="button" onClick={openChat}>
              <StackPanel gap="md" direction="row">
                <Box as={ChatIcon} flexGrow={0} flexShrink={0} />
                <span>Написать в чате</span>
              </StackPanel>
            </button>
          </S.Li>
        </S.Ul>
      )}
    </AsideBlockMobile>
  );
};
