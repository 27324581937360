/* eslint-disable class-methods-use-this */
import { BaseAuthenticationStoredTokenService } from "./BaseAuthenticationStoredTokenService";
import { JwtTokenInfo } from "../../types/jwt/JwtTokenInfo";
import { AuthenticationUserTokenDetails } from "../../types/AuthenticationUserTokenDetails";
import { ApiAuthenticationOutputDto } from "../../api/dto/output/ApiAuthenticationOutputDto";
import { LocalStorageService } from "../../../services/local-storage";
import { authenticationJwtService } from "../authenticationJwtService";
import { ApiTokenPayload } from "../../types/jwt/ApiTokenPayload";
import { UserRoleEnum } from "../../../types/user";

const API_TOKEN_INFO_KEY = "ati";

export class AuthenticationApiStoredTokenService extends BaseAuthenticationStoredTokenService<ApiAuthenticationOutputDto> {
  public constructor(localStorage = new LocalStorageService(API_TOKEN_INFO_KEY)) {
    super(localStorage);
  }

  public mapUserTokenDetails(apiResponse: ApiAuthenticationOutputDto): AuthenticationUserTokenDetails {
    const payload = authenticationJwtService.decryptTokenPayload<ApiTokenPayload>(apiResponse.accessToken.token);

    return {
      id: payload.id,
      login: payload.login,
      role: payload.role as UserRoleEnum,
      username: payload.username,
      featureFlags: apiResponse.properties.featureFlags,
    };
  }

  protected mapTokenInfoFromResponse(value: ApiAuthenticationOutputDto): JwtTokenInfo {
    return {
      accessToken: value.accessToken.token,
      tokenType: "Bearer",
      refreshToken: undefined,
      expiresIn: Number(new Date(value.accessToken.dateExpired)),
    };
  }

  public getExpiresTime(tokenInfo: ApiAuthenticationOutputDto): Date {
    return new Date(tokenInfo.accessToken.dateExpired);
  }
}
