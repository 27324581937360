import * as React from "react";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { Typography } from "../../../../../ui-kit/typography";

export interface AppPublicLayoutH2Props extends BaseCssProps {
  children: React.ReactNode;
}

export const AppPublicLayoutH2 = ({ className, children }: AppPublicLayoutH2Props) => {
  return (
    <Typography
      className={className}
      fontWeight={700}
      lineHeight="defaultLineHeight"
      fontSize={1.5}
      fontColor="typography.primary"
      textAlign="center"
    >
      {children}
    </Typography>
  );
};
