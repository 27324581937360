import { ChangeEvent, useCallback, useMemo } from "react";
import { useField } from "formik";
import { FilteringArrayKeys } from "../../types/FilteringArrayKeys";

export const useFiltersCheckboxesArrayFormField = <TFormValues>(fieldName: FilteringArrayKeys<TFormValues>) => {
  const [{ value }, , { setValue, setTouched }] = useField<string[]>(fieldName as string);

  const onChange = useCallback(
    (item: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setTouched(true, true);
      if (e.target.checked) {
        setValue([...(value ?? []), item], true);
      } else {
        setValue(value?.filter((x) => x !== item) ?? []);
      }
    },
    [setTouched, setValue, value]
  );

  const isChecked = useCallback(
    (item: string) => {
      return value?.includes(item) === true;
    },
    [value]
  );

  return useMemo(() => ({ onChange, isChecked }), [isChecked, onChange]);
};
