import * as S from "./AppSidebar.desktop.styled";
import { NavigationMenuDesktop } from "../../../../../../navigation/menu";
import { WidgetsNews } from "../../../../../../widgets/news";
import { FeatureAppUrlPermission } from "../../../../../../feature-manager/components/FeatureAppUrlPermission";

export const AppSidebarDesktop = () => {
  return (
    <S.Container>
      <NavigationMenuDesktop />
      <FeatureAppUrlPermission selector={(x) => x.news.list.root}>
        <WidgetsNews />
      </FeatureAppUrlPermission>
    </S.Container>
  );
};
