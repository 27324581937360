import { CreditWorkingAssetsCreationPageHeader } from "../components/header/CreditWorkingAssetsCreationPageHeader";
import { useAppSelector } from "../../../common/store";
import { selectCreditWorkingAssetsCreation } from "../store/creditWorkingAssetsCreation.selectors";
import { CreditWorkingAssetsCreationForm } from "../components/creating/form/CreditWorkingAssetsCreationForm";
import { CreditWorkingAssetsCreationScoringCreateNewForm } from "../components/scoring/create-new/CreditWorkingAssetsCreationScoringCreateNewForm";
import { useCreditWorkingAssetsCreationChangeScoringStageEffect } from "../hooks/stages/useCreditWorkingAssetsCreationChangeScoringStageEffect";

export const CreditWorkingAssetsCreationPage = () => {
  const { activeStage } = useAppSelector(selectCreditWorkingAssetsCreation);

  useCreditWorkingAssetsCreationChangeScoringStageEffect();

  return (
    <>
      <CreditWorkingAssetsCreationPageHeader />
      {activeStage === "creation" && <CreditWorkingAssetsCreationForm />}
      {activeStage === "scoring" && <CreditWorkingAssetsCreationScoringCreateNewForm />}
    </>
  );
};
