import { ContainerCommonProps } from "@sideg/ui-kit/atoms/containers/types/ContainerCommonProps";
import { makeResponsiveStylesBuilder } from "@sideg/ui-kit/common/utils";
import { getValueByPath } from "@sideg/ui-kit/core/theme/utils/getValueByPath";
import { BuildersMap, makeStyleBuilders } from "@sideg/ui-kit/atoms/containers/utils/makeStyleBuilders";

const responsive = makeResponsiveStylesBuilder<ContainerCommonProps>();

const builders: BuildersMap<ContainerCommonProps> = {
  display: responsive("display"),
  overflow: responsive("overflow"),
  position: responsive("position"),
  borderRadius: responsive("borderRadius", (radius, theme) => {
    return radius ? { borderRadius: getValueByPath(theme.radii, radius) } : {};
  }),
  effect: responsive("effect", (effect, theme) => {
    return effect ? { boxShadow: getValueByPath(theme.effects, effect) } : {};
  }),
  isFullWidth: responsive("isFullWidth", (isFullWidth) => (isFullWidth ? { width: "100%" } : {})),
  isFullHeight: responsive("isFullHeight", (isFullHeight) => (isFullHeight ? { height: "100%" } : {})),
  minWidth: responsive("minWidth", (minWidth) => (minWidth ? { minWidth } : {})),
  backgroundColor: responsive("backgroundColor", (color, theme) => {
    return color ? { backgroundColor: getValueByPath(theme.palette, color) } : {};
  }),
  isDisplayNoneOnEmpty: responsive("isDisplayNoneOnEmpty", (isDisplayNoneOnEmpty) =>
    isDisplayNoneOnEmpty
      ? {
          ":empty": {
            display: "none",
          },
        }
      : {},
  ),
};

export const getContainerCommonPropsStyleBuilders = makeStyleBuilders(builders);
