import styled from "@emotion/styled";

export const Container = styled.div<{ isOnNewLine: boolean }>`
  display: flex;
  flex-direction: ${({ isOnNewLine }) => (isOnNewLine ? "column" : "row")};
  gap: ${({ theme, isOnNewLine }) => (isOnNewLine ? "unset" : theme.spaces.md)};

  & > *:first-of-type {
    flex-basis: ${({ isOnNewLine }) => (isOnNewLine ? "unset" : "210px")};
  }
`;
