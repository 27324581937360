import { AnySchema } from "yup";
import { useCallback } from "react";
import { useSetActualPathnameQuerySearch } from "./useSetActualPathnameQuerySearch";

export const useSetDefaultQuerySearch = <TSchema extends AnySchema>(schema: TSchema) => {
  const setQuery = useSetActualPathnameQuerySearch();

  return useCallback(() => {
    setQuery(schema.getDefault());
  }, [setQuery, schema]);
};
