import { CreditExecutionConditionsTable } from "../components/CreditExecutionConditionsTable";
import { usePageTitle } from "../../../common/navigation/page-title";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";

export const CreditExecutionConditionsPage = () => {
  usePageTitle("Условия получения кредита на исполнение контракта");

  return (
    <AppLayoutResponsiveListPage backgroundColor="light">
      <AppLayoutResponsiveListPage.Header>
        <AppLayoutResponsiveListPage.Title>Условия банков</AppLayoutResponsiveListPage.Title>
      </AppLayoutResponsiveListPage.Header>
      <CreditExecutionConditionsTable />
    </AppLayoutResponsiveListPage>
  );
};
