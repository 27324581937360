import { useSelect, UseSelectProps, UseSelectReturnValue } from "downshift";
import { useMemo } from "react";
import { usePopup, UsePopupArgs, UsePopupReturnType } from "../../../pop-up";
import { withSelectCircularNavigation } from "../utils/withSelectCircularNavigation";

export type UseSelectPopupArgs<TItem> = UseSelectProps<TItem> & UsePopupArgs & { circularNavigation?: boolean };

export type UseSelectPopupReturnType<TItem> = UseSelectReturnValue<TItem> & UsePopupReturnType<HTMLElement>;

interface UseSelectPopupInterface {
  <TItem>(args: UseSelectPopupArgs<TItem>): UseSelectPopupReturnType<TItem>;
}

export const useSelectPopup: UseSelectPopupInterface = <TItem>({
  position,
  offset,
  ...useSelectArgs
}: UseSelectPopupArgs<TItem>) => {
  const { isOpen, ...useSelectReturn } = useSelect(withSelectCircularNavigation(useSelectArgs));
  const { ...usePopupReturn } = usePopup({ position, offset });

  return { isOpen, ...useSelectReturn, ...usePopupReturn };
};

// https://github.com/downshift-js/downshift/issues/1540
export const useSelectPopupMemo = <TItem>(options: UseSelectPopupArgs<TItem>) => {
  const { isOpen, getToggleButtonProps, getMenuProps, referenceElementProps, popupProps, ...rest } =
    useSelectPopup(options);

  const referenceProps = useMemo(
    () => getToggleButtonProps({ ref: referenceElementProps.ref }),
    [getToggleButtonProps, referenceElementProps.ref],
  );

  const floatingProps = useMemo(
    () => getMenuProps({ ref: popupProps.ref, style: popupProps.style }),
    [getMenuProps, popupProps.ref, popupProps.style],
  );

  return { isOpen, ...rest, referenceElementProps, referenceProps, floatingProps };
};
