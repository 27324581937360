import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../common/store";
import { chatDialogAdapter } from "./chatDialog.adapter";
import { ChatDialogMessageId } from "../types/ChatDialogMessageId";
import { isNewMessage } from "../utils/isNewMessage";
import { ChatDialog } from "./chatDialog.types";

export const selectChatDialog = (state: RootState): ChatDialog => state.domains.chat.dialog;

export const {
  selectById: selectChatDialogMessageById,
  selectIds: selectChatDialogMessagesIds,
  selectAll: selectChatDialogAllMessages,
} = chatDialogAdapter.getSelectors(selectChatDialog);

export const selectChatDialogLastMessageId = (state: RootState): ChatDialogMessageId | undefined => {
  const sliceState = selectChatDialog(state);

  return sliceState.ids.length > 0 ? (sliceState.ids[0] as ChatDialogMessageId) : undefined;
};

export const selectChatDialogUnreadMessages = createSelector([selectChatDialogAllMessages], (messages) => {
  return messages.reduce((accum, message) => {
    return accum + (isNewMessage(message) ? 1 : 0);
  }, 0);
});
