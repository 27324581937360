import QuestionnaireFlLiModel from "../../../entity/models/QuestionnaireFiLiModel"
import QuestionnairePersonFounderModel from "../../../entity/models/QuestionnairePersonFounderModel"

export const FI_LI_LIST_FETCHING = 'FI_LI_LIST_FETCHING'
export const FI_LI_LIST_FETCHED = 'FI_LI_LIST_FETCHED'
export const FI_LI_LIST_ERROR = 'FI_LI_LIST_ERROR'
export const FI_LI_LIST_RESET = 'FI_LI_LIST_RESET'

export const FI_LI_ITEM_FETCHING = 'FI_LI_ITEM_FETCHING'
export const FI_LI_ITEM_FETCHED = 'FI_LI_ITEM_FETCHED'
export const FI_LI_ITEM_ERROR = 'FI_LI_ITEM_ERROR'
export const FI_LI_ITEM_RESET = 'FI_LI_ITEM_RESET'

export const FI_LI_DELETE_PROCESSING = 'FI_LI_DELETE_PROCESSING'
export const FI_LI_DELETE_SUCCESS = 'FI_LI_DELETE_SUCCESS'
export const FI_LI_DELETE_ERROR = 'FI_LI_DELETE_ERROR'
export const FI_LI_DELETE_RESET = 'FI_LI_DELETE_RESET'

export const FI_LI_ADD_PROCESSING = 'FI_LI_ADD_PROCESSING'
export const FI_LI_ADD_SUCCESS = 'FI_LI_ADD_SUCCESS'
export const FI_LI_ADD_ERROR = 'FI_LI_ADD_ERROR'
export const FI_LI_ADD_RESET = 'FI_LI_ADD_RESET'

export const FI_LI_UPDATE_PROCESSING = 'FI_LI_UPDATE_PROCESSING'
export const FI_LI_UPDATE_SUCCESS = 'FI_LI_UPDATE_SUCCESS'
export const FI_LI_UPDATE_ERROR = 'FI_LI_UPDATE_ERROR'
export const FI_LI_UPDATE_RESET = 'FI_LI_UPDATE_RESET'

export interface FiLiListFetching {
    type: typeof FI_LI_LIST_FETCHING
}

export interface FiLiListFetched {
    type: typeof FI_LI_LIST_FETCHED
    payload: QuestionnaireFlLiModel[]
}

export interface FiLiListError {
    type: typeof FI_LI_LIST_ERROR
    error: any
}

export interface FiLiListReset {
    type: typeof FI_LI_LIST_RESET
}

export interface FiLiItemFetching {
    type: typeof FI_LI_ITEM_FETCHING
}

export interface FiLiItemFetched {
    type: typeof FI_LI_ITEM_FETCHED
    payload: QuestionnairePersonFounderModel
}

export interface FiLiItemError {
    type: typeof FI_LI_ITEM_ERROR
    error: any
}

export interface FiLiItemReset {
    type: typeof FI_LI_ITEM_RESET
}

export interface FiLiDeleteProcessing {
    type: typeof FI_LI_DELETE_PROCESSING
}

export interface FiLiDeleteSuccess {
    type: typeof FI_LI_DELETE_SUCCESS
    payload: boolean
}

export interface FiLiDeleteError {
    type: typeof FI_LI_DELETE_ERROR
    error: any
}

export interface FiLiDeleteReset {
    type: typeof FI_LI_DELETE_RESET
}

export interface FiLiAddProcessing {
    type: typeof FI_LI_ADD_PROCESSING
}

export interface FiLiAddSuccess {
    type: typeof FI_LI_ADD_SUCCESS
    payload: boolean
}

export interface FiLiAddError {
    type: typeof FI_LI_ADD_ERROR
    error: any
}

export interface FiLiAddReset {
    type: typeof FI_LI_ADD_RESET
}

export interface FiLiUpdateProcessing {
    type: typeof FI_LI_UPDATE_PROCESSING
}

export interface FiLiUpdateSuccess {
    type: typeof FI_LI_UPDATE_SUCCESS
    payload: boolean
}

export interface FiLiUpdateError {
    type: typeof FI_LI_UPDATE_ERROR
    error: any
}

export interface FiLiUpdateReset {
    type: typeof FI_LI_UPDATE_RESET
}

export type FiLiDispatchType =
    FiLiListFetching |
    FiLiListFetched |
    FiLiListError |
    FiLiListReset |
    FiLiItemFetching |
    FiLiItemFetched |
    FiLiItemError |
    FiLiItemReset |
    FiLiDeleteProcessing |
    FiLiDeleteSuccess |
    FiLiDeleteError |
    FiLiDeleteReset |
    FiLiAddProcessing |
    FiLiAddSuccess |
    FiLiAddError |
    FiLiAddReset |
    FiLiUpdateProcessing |
    FiLiUpdateSuccess |
    FiLiUpdateError |
    FiLiUpdateReset