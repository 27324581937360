import { BaseCssProps } from "../../../../../../../ui-kit/types";
import * as S from "./ProductListCardTasksCountBadge.mobile.styled";
import { Tooltip, useTooltip } from "../../../../../../../ui-kit/pop-up/tooltip";
import { Icon, InfoIcon } from "../../../../../../../ui-kit/icons";
import { declensionHelper } from "../../../../../../../helpers/entity";

export interface ProductListCardTasksCountBadgeMobileProps extends BaseCssProps {
  count: number;
}

export const ProductListCardTasksCountBadgeMobile = ({
  count,
  className,
}: ProductListCardTasksCountBadgeMobileProps) => {
  const { ref, popupProps, onClick, arrow, placement, isOpened } = useTooltip({ position: "bottom", type: "click" });

  return (
    <>
      <S.Badge className={className} onClick={onClick} ref={ref}>
        <Icon stroke="typography.primaryLight" height={13.33} as={InfoIcon} />
        <span>{count}</span>
      </S.Badge>
      {isOpened && (
        <Tooltip popUpProps={popupProps} placement={placement} arrowData={arrow.data} arrowRef={arrow.ref}>
          {declensionHelper.commonDeclensions.activeTasks.nominative(count, true)}
        </Tooltip>
      )}
    </>
  );
};
