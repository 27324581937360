import { useCallback, useMemo, useState } from "react";
import { PublicUserResetPasswordFormValues } from "../types/PublicUserResetPasswordFormValues";
import { useApiMutationAction } from "../../../common/hooks";
import { initializePublicUserResetPassword } from "../store/publicUserResetPassword.thunks";
import { PublicUserResetPasswordCommonOutputDto } from "../api/dto/output/PublicUserResetPasswordCommonOutputDto";
import { isBaseApiError } from "../../../common/api/types";
import { FormSubmitHandler, useGetFieldName } from "../../../common/form";
import { publicUserResetPasswordFormValidationSchema } from "../validation/publicUserResetPasswordFormValidationSchema";

export const usePublicUserResetPasswordInitial = () => {
  const [data, setData] = useState<PublicUserResetPasswordCommonOutputDto | undefined>(undefined);
  const getFieldName = useGetFieldName<PublicUserResetPasswordFormValues>();

  const { mutate } = useApiMutationAction();
  const onSubmit: FormSubmitHandler<PublicUserResetPasswordFormValues> = useCallback(
    async (values, helpers) => {
      const result = await mutate(
        initializePublicUserResetPassword,
        publicUserResetPasswordFormValidationSchema.cast(values),
        {
          errorHandler: async (e): Promise<string> => {
            let error = "Не удалось установить соединение с сервером";
            if (isBaseApiError(e)) {
              error = e.message ?? error;
              helpers.setFieldError(getFieldName("login"), error);
            }

            return error;
          },
        },
      );

      setData(result?.body);
    },
    [getFieldName, mutate],
  );

  return useMemo(() => ({ onSubmit, data }), [onSubmit, data]);
};
