import { useContext } from "react";
import {
  CreditWorkingAssetsDetailsContext,
  CreditWorkingAssetsDetailsContextType,
} from "../../store/context/CreditWorkingAssetsDetailsContext";

export const useCreditWorkingAssetsDetailsContext = (): CreditWorkingAssetsDetailsContextType => {
  const value = useContext(CreditWorkingAssetsDetailsContext);

  if (value === undefined) {
    throw new Error("Попытка получить значение контекста вне контекста");
  }

  return value;
};
