import { useCallback, useMemo } from "react";
import * as React from "react";
import { isFailed, isLoading } from "../../../../../common/types/state";
import { useClientSearchGetNameOnChange } from "../../../../../client-search";
import { useBankGuaranteeCreationField } from "./useBankGuaranteeCreationField";

export const useBankGuaranteeCreationBeneficiaryField = () => {
  const [{ onChange: onFieldChange, onBlur, error, value, name }] =
    useBankGuaranteeCreationField("beneficiaryTaxpayerNumber");
  const [, { setValue: setBeneficiaryNameValue }] = useBankGuaranteeCreationField("beneficiaryName");

  const { status, onChange: onFieldChangeForFetch } = useClientSearchGetNameOnChange(setBeneficiaryNameValue);

  const onChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      onFieldChange(e);
      await onFieldChangeForFetch(e);
    },
    [onFieldChange, onFieldChangeForFetch]
  );

  return useMemo(
    () => ({
      status,
      isLoading: isLoading(status),
      isFailed: isFailed(status),
      fieldProps: { onChange, onBlur, error, value, name },
    }),
    [error, name, onBlur, onChange, status, value]
  );
};
