import * as React from "react";
import { Box } from "../../../../../ui-kit/containers/box";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { FullPageContainerHeaderCenteredWrapper } from "./FullPageContainerHeaderCenteredWrapper";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";

export interface FullPageContainerHeaderDesktopProps extends BaseCssProps {
  leftSide?: React.ReactNode;
  children?: React.ReactNode;
}

export const FullPageContainerHeaderDesktop = ({
  leftSide,
  children,
  className,
}: FullPageContainerHeaderDesktopProps) => {
  return (
    <FullPageContainerHeaderCenteredWrapper className={className}>
      <Box flexGrow={1} flexShrink={1} flexBasis="0%">
        {leftSide}
      </Box>
      <StackPanel gap="none" direction="column" alignItems="center" justifyContent="center">
        {children}
      </StackPanel>
    </FullPageContainerHeaderCenteredWrapper>
  );
};
