import { bankGuaranteeRequestOriginalCreationApi } from "../api/bankGuaranteeRequestOriginalCreationApi";
import { combineSliceNames } from "../../../common/store/utils";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { BANK_GUARANTEE_SLICE_NAME } from "../../common/store";

export const BANK_GUARANTEE_REQUEST_ORIGINAL_SLICE_NAME = combineSliceNames(
  BANK_GUARANTEE_SLICE_NAME,
  "requestOriginal"
);

export const {
  create: createBankGuaranteeRequestOriginal,
  getDeliveryCompaniesList: getRequestOriginalDeliveryCompaniesList,
} = createApiThunks(BANK_GUARANTEE_REQUEST_ORIGINAL_SLICE_NAME, bankGuaranteeRequestOriginalCreationApi);
