import styled from "@emotion/styled";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.sm};

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    flex-direction: row;
    align-items: flex-start;
    max-width: 720px;
    max-width: max(720px, 100%);
    gap: ${({ theme }) => theme.spaces.xxl};

    & > * {
      flex-basis: 50%;
    }
  }
`;
