import * as React from "react";
import PaginatedList from "../../models/PaginatedList";
import AgentReportModel from "../../models/AgentReportModel";
import { formatterDayMonthYear, formatterMonthNameYear } from "../../essentials/helpers";
import ButtonInternalLink from "../../components/controls-old/button/ButtonInternalLink";
import { ButtonColor } from "../../components/controls-old/button/Button";

interface IAgentReportButton {
  id: number;
  color: ButtonColor;
  title: string;
}

interface IAgentReportButtonProps {
  btn: IAgentReportButton;
}

const AgentReportButton: React.FC<IAgentReportButtonProps> = (props) => {
  return (
    <ButtonInternalLink href={`/agent-report/${props.btn.id}`} color={props.btn.color}>
      {props.btn.title}
    </ButtonInternalLink>
  );
};

interface IAgentReportListProps {
  data: PaginatedList<AgentReportModel>;
}

const AgentReportList: React.FC<IAgentReportListProps> = (props) => {
  const formatPeriodName = (d: string) => {
    const date = new Date(d);
    return formatterMonthNameYear.format(date);
  };

  const formatPeriod = (d: string, end: boolean) => {
    const date = new Date(d);
    if (end) {
      date.setDate(date.getDate() - 1);
    }
    return formatterDayMonthYear.format(date);
  };

  const formatCreateDate = (d: string) => {
    const date = new Date(d);
    return formatterDayMonthYear.format(date);
  };

  const getButtonProps = (id: number, code: string, agentSide: boolean): IAgentReportButton => {
    switch (code) {
      case "Completed":
        return {
          id: id,
          color: ButtonColor.Green,
          title: "Выполнено",
        };
      case "Check":
        return {
          id: id,
          color: ButtonColor.Gray,
          title: "Акт проверяется",
        };
      case "Processing": {
        if (agentSide) {
          return {
            id: id,
            color: ButtonColor.Blue,
            title: "Согласовать акт",
          };
        } else {
          return {
            id: id,
            color: ButtonColor.Gray,
            title: "На согласовании",
          };
        }
      }
      case "Aggreed": {
        if (agentSide) {
          return {
            id: id,
            color: ButtonColor.BlueLight,
            title: "Подписать акт",
          };
        } else {
          return {
            id: id,
            color: ButtonColor.Gray,
            title: "Подписание акта",
          };
        }
      }
      case "Terminated":
        return {
          id: id,
          color: ButtonColor.Gray,
          title: "Отменен",
        };
      default:
        return {
          id: id,
          color: ButtonColor.Gray,
          title: "В работе",
        };
    }
  };

  const getRowStyle = (item: AgentReportModel): string => {
    const style = ["table__row"];

    if (item.newChange) {
      style.push("agent-report__unread");
    }

    return style.join(" ");
  };

  return (
    <table className="table table_bordered agent-report__table">
      <thead className="table__head">
        <tr className="table__head-row">
          <td className="table__head-row-item">Период</td>
          <td className="table__head-row-item">Дата создания</td>
          <td className="table__head-row-item">Статус</td>
        </tr>
      </thead>
      <tbody className="table__body">
        {props.data.viewList.map((item) => {
          return (
            <tr className={getRowStyle(item)} key={item.id}>
              <td className="table__row-item">
                <div className="agent-report__table-period">{formatPeriodName(item.dateFrom)}</div>
                <div className="agent-report__table-period-full">
                  {formatPeriod(item.dateFrom, false)} - {formatPeriod(item.dateTo, true)}
                </div>
              </td>
              <td className="table__row-item">{formatCreateDate(item.createDate)}</td>
              <td className="table__row-item agent-report__table-button">
                <AgentReportButton btn={getButtonProps(item.id, item.status.code, item.agentSide)} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AgentReportList;
