import { dateTimeHelper } from "@sideg/helpers";
import { QueueTasksListFilterParams } from "../types/filter";
import {
  FilteringItemsConverterDictionary,
  getFilteringValueTextByEntitiesCodeWithTitle,
  useFilteringItemsConverterDictionary,
} from "../../../common/filters";
import { useAppSelector } from "../../../common/store";
import { selectQueueTasksListFilters } from "../store/queueTasksList.selectors";

export const useQueueTasksListFilteringItemsConverter = () => {
  const { data } = useAppSelector(selectQueueTasksListFilters);

  const dictionary: FilteringItemsConverterDictionary<QueueTasksListFilterParams> = {
    page: null,
    tab: null,
    ordering: null,
    search: null,
    demandId: null,
    productId: null,
    clientNameOrTaxpayerNumber: null,
    taskTypes: getFilteringValueTextByEntitiesCodeWithTitle(data?.taskTypes),
    banks: getFilteringValueTextByEntitiesCodeWithTitle(data?.banks),
    products: getFilteringValueTextByEntitiesCodeWithTitle(data?.products),
    dateAddedFrom: (value) => (value === undefined ? null : `с ${dateTimeHelper.format(value, "dayFullMonthYear")}`),
    dateAddedTo: (value) => (value === undefined ? null : `по ${dateTimeHelper.format(value, "dayFullMonthYear")}`),
  };

  return useFilteringItemsConverterDictionary(dictionary);
};
