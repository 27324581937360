import { StackPanel } from "../../../ui-kit/containers/stack-panel";
import { Text } from "../../../ui-kit/typography";
import { ButtonLink } from "../../../ui-kit/buttons/ButtonLink";
import { ProductCreationPageHeaderProps } from "./ProductCreationPageHeaderProps";
import { productCreationStages } from "../../types/ProductCreationStages";
import { FullPageContainerHeaderTitle } from "../../../ui/containers/full-page";
import { useBackLinkHeaderAction } from "../../../ui/layout/header";

interface ProductCreationPageHeaderMobileProps extends ProductCreationPageHeaderProps {
  onBackToPreviousStage: () => void | Promise<void>;
  canBackToPreviousStage: boolean;
}

export const ProductCreationPageHeaderMobile = ({
  from,
  onBackToPreviousStage,
  activeStage,
  canBackToPreviousStage,
  title,
}: ProductCreationPageHeaderMobileProps) => {
  const activeStageIndex = productCreationStages.values.indexOf(activeStage) + 1;

  useBackLinkHeaderAction({ url: from.url });

  return (
    <StackPanel gap="lg" direction="column" alignItems="center" justifyContent="center">
      <FullPageContainerHeaderTitle text={title} />
      <StackPanel gap="xs" direction="column">
        <Text fontSize="fz4" fontColor="colors.dark" fontWeight={500} textAlign="center">
          Шаг {activeStageIndex} из {productCreationStages.values.length}.&nbsp;
          {productCreationStages.getTitle(activeStage)}
        </Text>
        {canBackToPreviousStage && (
          <Text as="div" textAlign="center" fontSize="fz2">
            <ButtonLink type="button" onClick={onBackToPreviousStage}>
              Вернуться на предыдущий шаг
            </ButtonLink>
          </Text>
        )}
      </StackPanel>
    </StackPanel>
  );
};
