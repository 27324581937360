import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { RootState, useAppSelector } from "../../../../common/store";
import { NavigationState } from "../../../../common/navigation/navigation-state";
import { QueueTaskId } from "../../../../common/types/demand";
import { QueueTasksListItemState } from "../../store/queueTasksList.types";
import * as S from "./QueueTasksListItem.styled";
import { useQueueTasksListProductUrl } from "../../../common/hooks";
import { QueueTasksListItemCard } from "./card/QueueTasksListItemCard";
import { CircleIndicator } from "../../../../common/ui-kit/notifications/circle-indicator";
import { QueueTasksListItemProductColumnWrapper } from "./QueueTasksListItemProductColumnWrapper";
import { ProductListItemColumn } from "../../../../common/ui/product/list/product-list-item";
import { QueueTasksListItemComment } from "./QueueTasksListItemComment";
import { QueueTasksListItemRequiredDocument } from "./QueueTasksListItemRequiredDocument";
import { StyledRouterLink } from "../../../../common/ui/links";
import { QueueTasksListItemActionButton } from "./QueueTasksListItemActionButton";

export interface QueueTasksListItemProps {
  id: QueueTaskId;
  entitySelector: (state: RootState, queueTaskId: QueueTaskId) => QueueTasksListItemState | undefined;
  className?: string;
  from?: NavigationState;
}

export const QueueTasksListItem = ({ id, entitySelector, className, from }: QueueTasksListItemProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const entity = useAppSelector((state) => entitySelector(state, id))!;
  const demandUrl = useQueueTasksListProductUrl(entity.product);

  return (
    <QueueTasksListItemCard
      className={className}
      title={entity.title}
      details={
        <>
          {entity.isNew && (
            <S.Indicator>
              <CircleIndicator size={9} color={(x) => x.secondaryColors.notificationBackground} />
            </S.Indicator>
          )}
          <S.Time
            dateTime={entity.dateAdded}
            title={dateTimeHelper.format(
              new Date(entity.dateAdded),
              specialFormatters.dayFullMonthYearIfDifferentAndTime,
            )}
          >
            {dateTimeHelper.getDistanceToNowStrict(new Date(entity.dateAdded))}
          </S.Time>
        </>
      }
      footer={
        <ThemeMobileDesktopView
          mobile={
            <S.DemandLink to={demandUrl} state={from}>
              Перейти к заявке
            </S.DemandLink>
          }
          desktop={
            <ProductListItemColumn>
              {entity.comment && <QueueTasksListItemComment comment={entity.comment} />}
              {entity.documents && <QueueTasksListItemRequiredDocument documents={entity.documents} />}
              <S.ProductListItemRow>
                <StyledRouterLink to={demandUrl} state={from}>
                  Перейти к заявке
                </StyledRouterLink>
                {entity.actions &&
                  entity.actions.map((x) => (
                    <QueueTasksListItemActionButton
                      entitySelector={entitySelector}
                      queueTaskId={entity.id}
                      action={x}
                      key={x.code}
                    />
                  ))}
              </S.ProductListItemRow>
            </ProductListItemColumn>
          }
        />
      }
    >
      <QueueTasksListItemProductColumnWrapper product={entity.product} />
    </QueueTasksListItemCard>
  );
};
