import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { CreditWorkingAssetsDetailsContextType } from "../../store/context/CreditWorkingAssetsDetailsContext";
import { selectCreditWorkingAssetsDetailsTransfer } from "../../store/transfer/creditWorkingAssetsDetailsTransfer.selectors";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";
import { useAppUrlMatch } from "../../../../common/urls/internal/hooks";
import { clearCreditWorkingAssetsDetailsTransfer } from "../../store/transfer/creditWorkingAssetsDetailsTransfer.slice";
import {
  creditWorkingAssetsDetailsActiveDemandChanged,
  creditWorkingAssetsDetailsActiveProductChanged,
} from "../../store/creditWorkingAssetsDetails.actions";
import { selectCreditWorkingAssetsDetailsActiveDemand } from "../../store/active-demand/creditWorkingAssetsDetailsActiveDemand.selectors";

export const useCreditWorkingAssetsDetailsMatchListener = (match: CreditWorkingAssetsDetailsContextType) => {
  const dispatch = useAppDispatch();
  const productDetails = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails);
  const transfer = useAppSelector(selectCreditWorkingAssetsDetailsTransfer);
  const { activeDemandId } = useAppSelector(selectCreditWorkingAssetsDetailsActiveDemand);

  const { isExact: isDocumentsPage } = useAppUrlMatch((x) => x.creditWorkingAssets.details.documents, {
    creditWorkingAssetsId: match.creditWorkingAssetsId,
  });

  useEffect(() => {
    if (productDetails?.data?.creditWorkingAssetsId !== match.creditWorkingAssetsId) {
      dispatch(creditWorkingAssetsDetailsActiveProductChanged(transfer?.data));
      dispatch(clearCreditWorkingAssetsDetailsTransfer());
    }
  }, [productDetails?.data?.creditWorkingAssetsId, dispatch, match?.creditWorkingAssetsId, transfer?.data]);

  useEffect(() => {
    return () => {
      dispatch(creditWorkingAssetsDetailsActiveProductChanged());
    };
  }, [dispatch]);

  useEffect(() => {
    if (match?.demandId && activeDemandId !== match.demandId) {
      dispatch(creditWorkingAssetsDetailsActiveDemandChanged(match.demandId));
    }
  }, [activeDemandId, match?.demandId, dispatch]);

  useEffect(() => {
    if (isDocumentsPage) {
      dispatch(creditWorkingAssetsDetailsActiveDemandChanged(undefined));
    }
  }, [dispatch, isDocumentsPage]);
};
