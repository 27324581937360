import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { BankGuaranteeDocumentsListMobile } from "./mobile/BankGuaranteeDocumentsList.mobile";
import { BankGuaranteeDocumentsListDesktop } from "./desktop/BankGuaranteeDocumentsList.desktop";
import Loader from "../../../../components/common/Loader";
import { useAppSelector } from "../../../common/store";
import { selectBankGuaranteeDocuments } from "../store/bankGuaranteeDocuments.selectors";
import { useDebounce } from "../../../common/hooks/useDebounce";
import { isLoading } from "../../../common/types/state";

export const BankGuaranteeDocumentsList = () => {
  const { status } = useAppSelector(selectBankGuaranteeDocuments);
  const [debounceStatus] = useDebounce(status, 300);

  return (
    <AppLayoutResponsiveListPage.List>
      {isLoading(debounceStatus) && isLoading(status) && <Loader />}
      <ThemeMobileDesktopView
        mobile={<BankGuaranteeDocumentsListMobile />}
        desktop={<BankGuaranteeDocumentsListDesktop />}
      />
    </AppLayoutResponsiveListPage.List>
  );
};
