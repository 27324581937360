import styled from "@emotion/styled";

export const ActionButtonsContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spaces.md} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  margin-top: ${({ theme }) => theme.spaces.md};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spaces.md};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    align-items: center;
    flex-direction: row;
    gap: ${({ theme }) => theme.spaces.xl};
    border: none;
    margin-top: 0;
  }
`;
