import { dateTimeHelper } from "@sideg/helpers";
import { LocalStorageExpiresTimeService } from "../../services/local-storage";

export class WebPushesPromptService {
  static readonly STORAGE_KEY = "web-pushes-next-prompt";

  private readonly expiresTimeService: LocalStorageExpiresTimeService;

  constructor(expiresTimeService: LocalStorageExpiresTimeService) {
    this.expiresTimeService = expiresTimeService;
  }

  public setTimeAfterDismiss() {
    this.expiresTimeService.setExpiresTime(+dateTimeHelper.simpleModify("addMonths", new Date(), 1));
  }

  public setTimeAfterUnsubscribe() {
    this.expiresTimeService.setExpiresTime(+dateTimeHelper.simpleModify("addMonths", new Date(), 6));
  }

  public isTimeExpired() {
    return this.expiresTimeService.isTimeExpired();
  }
}

export const webPushesPromptService = Object.freeze(
  new WebPushesPromptService(new LocalStorageExpiresTimeService(WebPushesPromptService.STORAGE_KEY)),
);
