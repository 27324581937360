import { ReactNode } from "react";
import { CSSObject } from "@emotion/react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Typography } from "../../../../common/ui-kit/typography";

export interface PublicUserDescriptionTypographyProps extends BaseCssProps {
  children?: ReactNode;
  textAlign?: CSSObject["textAlign"];
}

export const PublicUserDescriptionTypography = ({
  children,
  textAlign,
  className,
}: PublicUserDescriptionTypographyProps) => {
  return (
    <Typography
      className={className}
      fontColor="typography.primary"
      fontSize="fz3"
      fontWeight={500}
      lineHeight="defaultLineHeight"
      textAlign={textAlign}
    >
      {children}
    </Typography>
  );
};
