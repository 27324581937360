import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Icon as IconComponent } from "../../../../../../../common/ui-kit/icons";
import { BankGuaranteeDetailsDemandStatusType } from "../../../../../types/demand/BankGuaranteeDetailsDemandStatusType";
import { getThemePaletteByStatusType } from "../../../../../utils/demand/getThemePaletteByStatusType";

export const Icon = styled(IconComponent)``;

export const AlertWrapper = styled.div<{ variant: BankGuaranteeDetailsDemandStatusType }>(
  ({ theme }) => css`
    flex-direction: column;
    padding: ${theme.spaces.md};
    border-radius: 8px;
  `,
  ({ theme, variant }) => {
    const { text, background } = getThemePaletteByStatusType(theme, variant);

    return {
      color: text,
      backgroundColor: background,
      [`& ${Icon} > path`]: {
        stroke: text,
      },
    };
  },
);

export const AlertTitle = styled.div<{ variant: BankGuaranteeDetailsDemandStatusType }>`
  color: ${({ theme, variant }) => {
    return getThemePaletteByStatusType(theme, variant).text;
  }};
  font-weight: 500;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-size: ${({ theme }) => theme.font.fz3};
`;
