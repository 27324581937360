import { SendCommentDemandQueueTaskInputDto } from "./input/SendCommentDemandQueueTaskInputDto";
import { clientV4 } from "../../../common/api";
import { SendCommentDemandQueueTaskOutputDto } from "./output/SendCommentDemandQueueTaskOutputDto";
import { SendDecisionDemandQueueTaskInputDto } from "./input/SendDecisionDemandQueueTaskInputDto";
import { SendDecisionDemandQueueTaskOutputDto } from "./output/SendDecisionDemandQueueTaskOutputDto";
import { GetHistoryDemandQueueTaskInputDto } from "./input/GetHistoryDemandQueueTaskInputDto";
import { GetHistoryDemandQueueTaskOutputDto } from "./output/GetHistoryDemandQueueTaskOutputDto";
import { UploadDocumentDemandQueueTaskInputDto } from "./input/UploadDocumentDemandQueueTaskInputDto";
import { UploadDocumentDemandQueueTaskOutputDto } from "./output/UploadDocumentDemandQueueTaskOutputDto";

const CONTROLLER_NAME = "/queueTasks";

export const demandQueueTasksApi = {
  sendComment: ({ demandId, ...rest }: SendCommentDemandQueueTaskInputDto) =>
    clientV4.postForm<Omit<SendCommentDemandQueueTaskInputDto, "demandId">, SendCommentDemandQueueTaskOutputDto>(
      `${CONTROLLER_NAME}/comment/${demandId}`,
      rest,
    ),
  sendDecision: (model: SendDecisionDemandQueueTaskInputDto) =>
    clientV4.putForm<SendDecisionDemandQueueTaskInputDto, SendDecisionDemandQueueTaskOutputDto>(
      `${CONTROLLER_NAME}/send`,
      model,
    ),
  getHistory: (model: GetHistoryDemandQueueTaskInputDto, abortSignal?: AbortSignal) =>
    clientV4.get<GetHistoryDemandQueueTaskInputDto, GetHistoryDemandQueueTaskOutputDto>(
      `${CONTROLLER_NAME}/history`,
      model,
      {
        abortSignal,
      },
    ),
  uploadDocument: async ({ queueTaskId, ...sendModel }: UploadDocumentDemandQueueTaskInputDto) =>
    clientV4.postForm<
      Omit<UploadDocumentDemandQueueTaskInputDto, "queueTaskId">,
      UploadDocumentDemandQueueTaskOutputDto
    >(`${CONTROLLER_NAME}/upload-document/${queueTaskId}`, sendModel),
};
