import * as S from "./WidgetsNewsArticle.styled";
import { useAppSelector } from "../../../../store";
import { selectWidgetsNewsItemById } from "../../store/widgetsNews.selectors";
import { formatDateWithShortMonthNameFullYear } from "../../../../../../essentials/helpers";
import { AppLink } from "../../../../urls/internal/components";
import { NewsId } from "../../../../../news/common/types";
import { BaseCssProps } from "../../../../ui-kit/types";

export interface WidgetsNewsArticleProps extends BaseCssProps {
  id: NewsId;
}

export const WidgetsNewsArticle = ({ id, className }: WidgetsNewsArticleProps) => {
  const article = useAppSelector((state) => selectWidgetsNewsItemById(state, id));

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {article && (
        <S.Article className={className}>
          <AppLink to={(x) => x.news.details.item} params={{ newsId: id }}>
            {article.title}
          </AppLink>
          <p>{article.description}</p>
          <time dateTime={article.creationDate}>{formatDateWithShortMonthNameFullYear(article.creationDate)}</time>
        </S.Article>
      )}
    </>
  );
};
