import { useCallback, useMemo } from "react";
import { BankGuaranteeListItemGuarantee } from "../../../types/item/BankGuaranteeListItemGuarantee";
import { useAppNavigation } from "../../../../../common/urls/internal/hooks";
import { NavigationState } from "../../../../../common/navigation/navigation-state";
import { bankGuaranteeListActions } from "../../../types/item/action/bankGuaranteeListActions";
import { useAppDispatch } from "../../../../../common/store";
import { onRejectGuaranteeBankGuaranteeList } from "../../../store/item-modification/bankGuaranteeListItemModification.slice";
import { ProductListCardMoreActionWithHandler } from "../../../../../common/ui/product/list/card";

export const useBankGuaranteeListActions = (guarantee: BankGuaranteeListItemGuarantee, from?: NavigationState) => {
  const navigator = useAppNavigation();
  const dispatch = useAppDispatch();

  const onRemoveItemClick = useCallback(() => {
    dispatch(onRejectGuaranteeBankGuaranteeList({ guaranteeId: guarantee.guaranteeId }));
  }, [dispatch, guarantee.guaranteeId]);

  return useMemo(() => {
    const actions: ProductListCardMoreActionWithHandler[] = [];

    if (guarantee.canSendMore) {
      actions.push({
        ...bankGuaranteeListActions.sendMore,
        handler: () => {
          navigator(
            (x) => x.bankGuarantee.creation.createByGuarantee,
            { guaranteeId: guarantee.guaranteeId },
            { state: from }
          );
        },
      });
    }

    if (guarantee.canRejectAll) {
      actions.push({
        ...bankGuaranteeListActions.rejectAll,
        handler: onRemoveItemClick,
      });
    }

    return actions;
  }, [from, guarantee.canRejectAll, guarantee.canSendMore, guarantee.guaranteeId, navigator, onRemoveItemClick]);
};
