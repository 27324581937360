import { forwardRef } from "react";
import { TransitionGroup } from "react-transition-group";
import { SimpleOpacityTransition } from "../../../../common/ui-kit/animations/transitions";
import { ChatDialogMainMessageGroup } from "./ChatDialogMainMessageGroup";
import { useAppSelector } from "../../../../common/store";
import { selectChatDialogMessagesIds } from "../../store/chatDialog.selectors";
import { ChatDialogMessageId } from "../../types/ChatDialogMessageId";

export interface ChatDialogMainMessagesProps {
  previousLastMessageId: ChatDialogMessageId | undefined;
}

export const ChatDialogMainMessages = forwardRef<HTMLDivElement, ChatDialogMainMessagesProps>(
  ({ previousLastMessageId }, previousLastMessageRef) => {
    const ids = useAppSelector(selectChatDialogMessagesIds) as ChatDialogMessageId[];

    return (
      <TransitionGroup component={null}>
        {ids.map((messageId, index, collection) => (
          <SimpleOpacityTransition timeout={350} key={messageId}>
            <ChatDialogMainMessageGroup
              index={index}
              messageId={messageId}
              previousMessageId={collection[index - 1]}
              ref={messageId === previousLastMessageId ? previousLastMessageRef : null}
            />
          </SimpleOpacityTransition>
        ))}
      </TransitionGroup>
    );
  }
);
