import { BaseCssProps } from "../../../ui-kit/types";
import * as S from "./ProductDetailsEmptyCircle.styled";
import { Typography } from "../../../ui-kit/typography";
import { declensionHelper } from "../../../helpers/entity";

export interface ProductDetailsDemandEmptyCircleProps extends BaseCssProps {
  banksCount: number;
  gradient: string;
}

export const ProductDetailsEmptyCircle = ({
  className,
  gradient,
  banksCount,
}: ProductDetailsDemandEmptyCircleProps) => {
  return (
    <S.OuterCircle className={className} gradient={gradient}>
      <S.InnerCircle>
        <Typography fontSize={3.5}>{banksCount}</Typography>
        <Typography fontSize={1.5}>{declensionHelper.commonDeclensions.bank.nominative(banksCount, false)}</Typography>
      </S.InnerCircle>
    </S.OuterCircle>
  );
};
