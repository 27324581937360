import BalanceFromProviderModel from "../../../entity/models/BalanceFromProviderModel"
import BalancesModel from "../../../entity/models/BalancesModel"

export const BALANCE_FETCHING = 'BALANCE_FETCHING'
export const BALANCE_FETCHED = 'BALANCE_FETCHED'
export const BALANCE_ERROR = 'BALANCE_ERROR'
export const BALANCE_RESET = 'BALANCE_RESET'

export const BALANCE_FROM_PROVIDER_FETCHING = 'BALANCE_FROM_PROVIDER_FETCHING'
export const BALANCE_FROM_PROVIDER_FETCHED = 'BALANCE_FROM_PROVIDER_FETCHED'
export const BALANCE_FROM_PROVIDER_ERROR = 'BALANCE_FROM_PROVIDER_ERROR'
export const BALANCE_FROM_PROVIDER_RESET = 'BALANCE_FROM_PROVIDER_RESET'

export const BALANCE_SAVE_PROCESSING = 'BALANCE_SAVE_PROCESSING'
export const BALANCE_SAVE_SUCCESS = 'BALANCE_SAVE_SUCCESS'
export const BALANCE_SAVE_ERROR = 'BALANCE_SAVE_ERROR'
export const BALANCE_SAVE_RESET = 'BALANCE_SAVE_RESET'

export interface BalanceFetching {
    type: typeof BALANCE_FETCHING
}

export interface BalanceFetched {
    type: typeof BALANCE_FETCHED
    payload: BalancesModel
}

export interface BalanceError {
    type: typeof BALANCE_ERROR
    error: any
}

export interface BalanceReset {
    type: typeof BALANCE_RESET
}

export interface BalanceFromProviderFetching {
    type: typeof BALANCE_FROM_PROVIDER_FETCHING
}

export interface BalanceFromProviderFetched {
    type: typeof BALANCE_FROM_PROVIDER_FETCHED
    payload: BalanceFromProviderModel
}

export interface BalanceFromProviderError {
    type: typeof BALANCE_FROM_PROVIDER_ERROR
    error: any
}

export interface BalanceFromProviderReset {
    type: typeof BALANCE_FROM_PROVIDER_RESET
}

export interface BalanceSaveProcessing {
    type: typeof BALANCE_SAVE_PROCESSING
}

export interface BalanceSaveSuccess {
    type: typeof BALANCE_SAVE_SUCCESS
    payload: boolean
}

export interface BalanceSaveError {
    type: typeof BALANCE_SAVE_ERROR
    error: any
}

export interface BalanceSaveReset {
    type: typeof BALANCE_SAVE_RESET
}

export type BalanceDispatchType =
    BalanceFetching |
    BalanceFetched |
    BalanceError |
    BalanceReset |
    BalanceFromProviderFetching |
    BalanceFromProviderFetched |
    BalanceFromProviderError |
    BalanceFromProviderReset |
    BalanceSaveProcessing |
    BalanceSaveSuccess |
    BalanceSaveError |
    BalanceSaveReset