import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { BANK_GUARANTEE_CONDITIONS_SLICE_NAME, getBankGuaranteeConditions } from "./bankGuaranteeConditions.thunks";
import { BankGuaranteeConditionsState } from "./bankGuaranteeConditions.types";

const initialState: BankGuaranteeConditionsState = {
  status: LoadingStatusEnum.Idle,
  conditions: undefined,
};

const slice = createSlice({
  initialState,
  name: BANK_GUARANTEE_CONDITIONS_SLICE_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBankGuaranteeConditions.fulfilled, (state, { payload }) => {
        state.conditions = payload.body;
      })
      .addMatcher(isAsyncThunkAction(getBankGuaranteeConditions), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const bankGuaranteeConditionsReducer = slice.reducer;
