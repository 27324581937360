import { useFormikContext } from "formik";
import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import { QueueTasksListMobileFiltersFormValues } from "../../../../types/filter/QueueTasksListMobileFiltersFormValues";
import { queueTasksListOrdering } from "../../../../types/filter";
import { useCustomFieldOnChange, useGetFieldError, useGetFieldName } from "../../../../../../common/form";
import { useAppSelector } from "../../../../../../common/store";
import { selectQueueTasksListFilters } from "../../../../store/queueTasksList.selectors";
import { FormSelectMobile } from "../../../../../../common/ui-kit/form-controls/select";
import { Grid } from "../../../../../../common/ui-kit/containers/responsive-grid";
import { QueueTasksListFiltersBanksWithSearchContainer } from "./QueueTasksListFiltersBanksWithSearchContainer";
import * as S from "./QueueTasksListFiltersMobileFormContent.styled";
import {
  FilterFormMobileCheckboxesWithCollapse,
  FilterMobileCollapseUncontrolledContainer,
} from "../../../../../../common/filters/use-cases";

export const QueueTasksListFiltersMobileFormContent = () => {
  const getFieldName = useGetFieldName<QueueTasksListMobileFiltersFormValues>();
  const getFieldError = useGetFieldError<QueueTasksListMobileFiltersFormValues>();
  const form = useFormikContext<QueueTasksListMobileFiltersFormValues>();
  const onCustomChange = useCustomFieldOnChange<QueueTasksListMobileFiltersFormValues>();

  const { data } = useAppSelector(selectQueueTasksListFilters);

  return (
    <S.Wrapper>
      <FormSelectMobile label="Сортировка" {...form.getFieldProps(getFieldName("ordering"))}>
        {queueTasksListOrdering.values.map((x) => (
          <option value={x} key={x}>
            {queueTasksListOrdering.getTitle(x)}
          </option>
        ))}
      </FormSelectMobile>

      {data?.products && (
        <FilterFormMobileCheckboxesWithCollapse<QueueTasksListMobileFiltersFormValues>
          title="Продукты"
          fieldName="products"
          items={data.products.codeWithTitleList}
        />
      )}
      {data?.taskTypes && (
        <FilterFormMobileCheckboxesWithCollapse<QueueTasksListMobileFiltersFormValues>
          title="Типы задач"
          fieldName="taskTypes"
          items={data.taskTypes.codeWithTitleList}
        />
      )}
      <FilterMobileCollapseUncontrolledContainer title="Дата">
        <Grid container gap="md">
          <Grid
            item
            as={DateFormInput}
            xs={6}
            {...form.getFieldProps(getFieldName("dateAddedFrom"))}
            onChange={(e: string) => onCustomChange(form.setFieldValue, "dateAddedFrom", e, true)}
            error={getFieldError("dateAddedFrom", form.touched.dateAddedFrom, form.errors.dateAddedFrom)}
            autoComplete="off"
          />
          <Grid
            item
            as={DateFormInput}
            xs={6}
            {...form.getFieldProps(getFieldName("dateAddedTo"))}
            onChange={(e: string) => onCustomChange(form.setFieldValue, "dateAddedTo", e, true)}
            error={getFieldError("dateAddedTo", form.touched.dateAddedTo, form.errors.dateAddedTo)}
            autoComplete="off"
          />
        </Grid>
      </FilterMobileCollapseUncontrolledContainer>
      {data?.banks && <QueueTasksListFiltersBanksWithSearchContainer />}
    </S.Wrapper>
  );
};
