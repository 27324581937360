import { isAnyOf } from "@reduxjs/toolkit";
import { queueTaskClosedDemandQueueTasks, uploadDocumentDemandQueueTasks } from "../../../demand/queue-tasks";
import {
  creditWorkingAssetsDetailsActiveDemandChanged,
  creditWorkingAssetsDetailsActiveProductChanged,
  creditWorkingAssetsDetailsReloadActiveDemand,
  creditWorkingAssetsDetailsReloadActiveProduct,
} from "./creditWorkingAssetsDetails.actions";

export const isCreditWorkingAssetsDetailsNeedReloadMatcher = isAnyOf(
  creditWorkingAssetsDetailsActiveDemandChanged,
  uploadDocumentDemandQueueTasks.fulfilled,
  queueTaskClosedDemandQueueTasks,
  creditWorkingAssetsDetailsReloadActiveProduct,
  creditWorkingAssetsDetailsReloadActiveDemand
);

export const isCreditWorkingAssetsDetailsProductChangedMatcher = isAnyOf(
  creditWorkingAssetsDetailsActiveDemandChanged,
  creditWorkingAssetsDetailsActiveProductChanged
);
