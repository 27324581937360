import { createClient } from "../../services/fetchClient";
import { ApplicationUpdaterGetVersionOutputDto } from "./dto/output/ApplicationUpdaterGetVersionOutputDto";

const client = createClient({});

export const applicationUpdaterApi = {
  getVersion: async (): Promise<ApplicationUpdaterGetVersionOutputDto> => {
    if (!import.meta.env.PROD) {
      return {
        currentVersion: import.meta.env.VITE_VERSION,
      };
    }

    return client.get<unknown, ApplicationUpdaterGetVersionOutputDto>("/version.json");
  },
};
