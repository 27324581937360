import { useEffect, useRef } from "react";
import { useCreditExecutionDetailsContext } from "./context/useCreditExecutionDetailsContext";
import { getDemandCreditExecutionDetails } from "../store/creditExecutionDetails.thunks";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { AsyncThunkActionRequest } from "../../../common/types/thunks";
import { DemandId } from "../../../common/types/demand";
import { isIdle } from "../../../common/types/state";
import { selectCreditExecutionDetailsActiveDemand } from "../store/active-demand/creditExecutionDetailsActiveDemand.selectors";

export const useCreditExecutionDetailsDemandInitialFetch = () => {
  const dispatch = useAppDispatch();
  const { creditExecutionId } = useCreditExecutionDetailsContext();
  const { activeDemandId, fetch } = useAppSelector(selectCreditExecutionDetailsActiveDemand);

  const requestRef = useRef<AsyncThunkActionRequest<{ demandId: DemandId }> | undefined>(undefined);

  useEffect(() => {
    if (requestRef.current && requestRef.current.arg.demandId !== activeDemandId) {
      requestRef.current.abort();
      requestRef.current = undefined;
    }
  }, [activeDemandId]);

  useEffect(() => {
    if (isIdle(fetch.status) && activeDemandId !== undefined) {
      requestRef.current = dispatch(
        getDemandCreditExecutionDetails({
          creditExecutionId,
          demandId: activeDemandId,
        })
      );
    }
  }, [activeDemandId, creditExecutionId, dispatch, fetch.status]);
};
