import { Theme } from "@sideg/ui-kit";
import { Color } from "../theme";

type PaletteColors<TPrefix extends string, TColors extends string> = `${TPrefix}.${TColors}`;

type PaletteSelector<TColors extends string> = (theme: Theme) => { [key in TColors]: Color };
type Palette<TPrefix extends string, TColors extends string> = {
  prefix: TPrefix;
  prefixWithDot: `${TPrefix}.`;
  paletteSelector: PaletteSelector<TColors>;
  colorSelector(theme: Theme, color: PaletteColors<TPrefix, TColors>): Color;
  isColorFromPalette(color: PaletteColors<string, string>): color is PaletteColors<TPrefix, TColors>;
};

type GetPaletteColors<TPalette extends Palette<string, string>> = TPalette extends Palette<infer TPrefix, infer TColors>
  ? PaletteColors<TPrefix, TColors>
  : never;

const makePalette = <TPrefix extends string, TColors extends string>(
  paletteSelector: PaletteSelector<TColors>,
  prefix: TPrefix,
): Palette<TPrefix, TColors> => {
  const prefixWithDot = `${prefix}.` as const;
  const colorSelector: Palette<TPrefix, TColors>["colorSelector"] = (theme, color) => {
    const palette = paletteSelector(theme);
    const paletteColor = color.replace(prefixWithDot, "") as TColors;

    return palette[paletteColor];
  };

  const isColorFromPalette: Palette<TPrefix, TColors>["isColorFromPalette"] = (
    color,
  ): color is PaletteColors<TPrefix, TColors> => {
    return color.startsWith(prefixWithDot);
  };

  return { paletteSelector, prefix, prefixWithDot, colorSelector, isColorFromPalette };
};

const typography = makePalette((theme) => theme.palette.typography, "typography");
const status = makePalette((theme) => theme.palette.status, "status");
const secondary = makePalette((theme) => theme.secondaryColors, "secondary");
const control = makePalette((theme) => theme.palette.control, "control");
const background = makePalette((theme) => theme.palette.background, "background");

const palettes = [typography, status, secondary, control, background] as const;

type BaseColor = keyof Theme["colors"];

export type ElementColor = GetPaletteColors<(typeof palettes)[number]> | BaseColor;

const isBaseColor = (color: ElementColor): color is BaseColor => {
  return color.indexOf(".") === -1;
};

const getPaletteByColor = <TPrefix extends string, TColors extends string>(
  color: PaletteColors<TPrefix, TColors>,
): Palette<TPrefix, TColors> | undefined => {
  return palettes.find((x) => x.isColorFromPalette(color)) as Palette<TPrefix, TColors> | undefined;
};

const getPaletteColor = (color: Exclude<ElementColor, BaseColor>, theme: Theme): Color | undefined => {
  const palette = getPaletteByColor(color);
  if (palette?.isColorFromPalette(color)) {
    return palette?.colorSelector(theme, color);
  }

  return undefined;
};

export const getElementColor = (color: ElementColor | undefined, theme: Theme): Color | undefined => {
  if (!color) {
    return undefined;
  }

  return isBaseColor(color) ? theme.colors[color] : getPaletteColor(color, theme);
};
