import Skeleton from "react-loading-skeleton";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";

import {
  ProductCreationScoringResultSuccessRowContainer,
  ProductCreationScoringSumLimitRangeSkeleton,
} from "../../../../../common/product-creation";

export type CreditWorkingAssetsCreationScoringResultSuccessRowSkeletonProps = BaseCssProps;

export const CreditWorkingAssetsCreationScoringResultSuccessRowSkeleton = ({
  className,
}: CreditWorkingAssetsCreationScoringResultSuccessRowSkeletonProps) => {
  return (
    <ProductCreationScoringResultSuccessRowContainer
      className={className}
      bank={
        <StackPanel direction="row" gap="md" alignItems="center">
          <ThemeMobileDesktopView desktop={<Skeleton inline height={32} width={32} />} mobile={undefined} />
          <Skeleton inline width={90} />
        </StackPanel>
      }
      sumLimit={<ProductCreationScoringSumLimitRangeSkeleton />}
      sumInput={undefined}
      mobileCheck={<Skeleton height={32} width={100} borderRadius={18} />}
    />
  );
};
