import { memo, PropsWithChildren } from "react";
import * as S from "./AppLayoutContent.styled";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";
import { useAppLayout } from "../../../app-layout-containers";
import { useAppLayoutOverflow } from "../../../app-layout-containers/hooks/useAppLayoutOverflow";
import { AppLayoutGlobalStyles } from "../AppLayoutGlobalStyles";
import { GlobalToastsContainer } from "../../../../../../global/toasts";
import { GlobalNetworkState } from "../../../../../../global/network-state";
import { useAppLayoutClearfix } from "../../hooks/useAppLayoutClearfix";
import { AppLayoutContentAside } from "./AppLayoutContentAside";
import { useAppLayoutIsFixedHeader } from "../../hooks/useAppLayoutIsFixedHeader";
import { AppLayoutContentHeader } from "./AppLayoutContentHeader";
import { BaseCssProps } from "../../../../../ui-kit/types";

export interface AppLayoutContentProps extends PropsWithChildren<BaseCssProps> {}

export const AppLayoutContent = memo(({ children, className }: AppLayoutContentProps) => {
  const { activeOverlayPanel, isMenuOpened } = useAppLayout();

  const isFixed = useAppLayoutIsFixedHeader(isMenuOpened.value, activeOverlayPanel.value);

  useAppLayoutOverflow();
  useAppLayoutClearfix();

  return (
    <>
      <AppLayoutGlobalStyles />
      <StackPanel gap="none" direction="column" className={className}>
        <AppLayoutContentHeader />
        <S.MainWrapper>
          <AppLayoutContentAside />
          <S.Main isFixedHeader={isFixed}>{children}</S.Main>
        </S.MainWrapper>
        <GlobalToastsContainer />
        <GlobalNetworkState />
      </StackPanel>
    </>
  );
});
