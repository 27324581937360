import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { FullPageContainerHeaderContainer } from "../../../../common/ui/containers/full-page";
import { SettlementAndCashCreationHeaderDesktop } from "./SettlementAndCashCreationHeader.desktop";
import { SettlementAndCashCreationHeaderMobile } from "./SettlementAndCashCreationHeader.mobile";

export type SettlementAndCashCreationHeaderProps = BaseCssProps;

export const SettlementAndCashCreationHeader = ({ className }: SettlementAndCashCreationHeaderProps) => {
  return (
    <FullPageContainerHeaderContainer className={className}>
      <ThemeMobileDesktopView
        mobile={<SettlementAndCashCreationHeaderMobile />}
        desktop={<SettlementAndCashCreationHeaderDesktop />}
      />
    </FullPageContainerHeaderContainer>
  );
};
