import styled from "@emotion/styled";
import { AppLayoutResponsiveListPage } from "../../../../../common/ui/layout/app-layout-containers";

export const Container = styled(AppLayoutResponsiveListPage.List)<{ isFixed: boolean }>`
  position: ${({ isFixed }) => (isFixed ? "fixed" : "static")};
  top: 0;
  z-index: ${({ theme }) => theme.layout.header.zIndex};
  background-color: ${({ theme }) => theme.palette.background.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding-bottom: ${({ theme }) => theme.spaces.lg};
    border-bottom: 0;
  }
`;
