import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { FormSearchInput } from "../../../../../../common/ui-kit/form-controls/search-input";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { BankGuaranteeListShowFiltersButton } from "./BankGuaranteeListShowFiltersButton";
import { useProductFilterSearchInput } from "../../../../../../common/filters/use-cases";
import { useBankGuaranteeListFilterContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterContext";

export type BankGuaranteeListFiltersMobileProps = BaseCssProps;

export const BankGuaranteeListFiltersMobile = ({ className }: BankGuaranteeListFiltersMobileProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const { value, onChange } = useProductFilterSearchInput(filteringObject);

  return (
    <Box pt="md">
      <FormSearchInput
        placeholder="Номер заявки, клиент, ИНН"
        className={className}
        afterInput={<BankGuaranteeListShowFiltersButton />}
        value={value}
        onChange={onChange}
        spellCheck={false}
        autoComplete="off"
        autoCorrect="off"
      />
    </Box>
  );
};
