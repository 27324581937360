import { useCallback, useRef } from "react";
import { RawApiResponse } from "../../../../common/api/types/RawApiResponse";
import { ChatDialogMessage as ChatDialogMessageType } from "../../types/ChatDialogMessage";
import { useHubEvent } from "../../../../common/hubs";
import { chatDialogHubConnection } from "../../api/chatDialogHubConnection";

export const useChatDialogNewMessageScrollEvent = (scroller: () => void) => {
  const scrollerRef = useRef(scroller);

  const onNewMessage = useCallback((payload: RawApiResponse<{ data: ChatDialogMessageType }>) => {
    if (!payload.body.data.isFromManager) {
      scrollerRef.current();
    }
  }, []);

  useHubEvent(chatDialogHubConnection, "NewMessage", onNewMessage);
};
