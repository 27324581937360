import { useEffect } from "react";
import { AnyAction } from "redux";
import { isFulfilled, ListenerEffectAPI } from "@reduxjs/toolkit";
import { addAppListener, removeAppListener, RootState, useAppDispatch } from "../../store";
import { HubConnectionStatus } from "../types/HubConnectionStatus";
import { hubUpdate } from "../store/hubs.slice";
import { AppDispatch } from "../../store/types";
import { HubState } from "../store/hubs.types";

const isNeedUpdateHub = (hub: HubState<string> | undefined) => {
  return (
    hub !== undefined &&
    (hub.status === HubConnectionStatus.ConnectionError ||
      hub.status === HubConnectionStatus.DisconnectedAfterRetries ||
      hub.status === HubConnectionStatus.Disconnected)
  );
};

export const useHubUpdateListener = <THubId extends string>(hubId: THubId) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const predicate = (action: AnyAction, state: RootState) => {
      const hub = state.domains.hubs.entities[hubId];

      return isFulfilled(action) && isNeedUpdateHub(hub);
    };

    const effect = (action: AnyAction, api: ListenerEffectAPI<RootState, AppDispatch>) => {
      api.dispatch(hubUpdate({ hubId }));
    };

    if (hubId !== undefined) {
      dispatch(addAppListener({ predicate, effect }));
    }

    return () => {
      if (hubId !== undefined) {
        dispatch(removeAppListener({ predicate, effect, cancelActive: true }));
      }
    };
  }, [dispatch, hubId]);
};
