import Skeleton from "react-loading-skeleton";
import { DemandActualDocumentContainer } from "./DemandActualDocumentContainer";

export interface DemandActualDocumentSkeletonProps {
  className?: string;
}

export const DemandActualDocumentSkeleton = ({ className }: DemandActualDocumentSkeletonProps) => {
  return (
    <DemandActualDocumentContainer
      className={className}
      documentTypeTitle={<Skeleton count={2} />}
      file={<Skeleton width={200} />}
    />
  );
};
