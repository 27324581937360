import styled from "@emotion/styled";
import { ProductDetailsContentLayoutContainer } from "../layout/ProductDetailsContentLayoutContainer";

export const Container = styled(ProductDetailsContentLayoutContainer.withComponent("section"))`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spaces.xxl};
  padding-top: ${({ theme }) => theme.spaces.xxl};
  padding-bottom: ${({ theme }) => theme.spaces.xxl};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    background-color: #fff;
    padding-top: ${({ theme }) => theme.layout.borderPadding};
    padding-bottom: ${({ theme }) => theme.layout.borderPadding};
  }
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.5;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    font-size: 1.75rem;
    padding-left: ${({ theme }) => theme.spaces.md};
  }
`;
