import { ProductDetailsDocumentsDocumentItem } from "../../../../common/product-details";
import { useBankGuaranteeDetailsDocumentsHandleDrop } from "../../hooks/documents/useBankGuaranteeDetailsDocumentsHandleDrop";
import { DocumentTypeId } from "../../../../common/types/documents";

export interface BankGuaranteeDetailsDocumentsItemProps {
  documentId: DocumentTypeId;
}

export const BankGuaranteeDetailsDocumentsItem = ({ documentId }: BankGuaranteeDetailsDocumentsItemProps) => {
  const { document, status, error, handleDrop } = useBankGuaranteeDetailsDocumentsHandleDrop(documentId);

  return (
    <ProductDetailsDocumentsDocumentItem document={document} handleDrop={handleDrop} error={error} status={status} />
  );
};
