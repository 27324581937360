import { useFormikContext } from "formik";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { FormInputPassword } from "@sideg/ui-kit/atoms/form-controls/password-input";
import {
  userChangePasswordFieldGetAutocomplete,
  userChangePasswordFieldType,
} from "../../types/UserChangePasswordFieldType";
import { useGetFieldName, useGetFormFieldError } from "../../../../common/form";
import { UserChangePasswordFormValues } from "../../types/UserChangePasswordFormValues";
import { ButtonLoading } from "../../../../common/ui-kit/form-controls/button";
import { Box } from "../../../../common/ui-kit/containers/box";
import { UserChangePasswordFormUsernameHiddenInput } from "./UserChangePasswordFormUsernameHiddenInput";

export const UserChangePasswordFormContent = () => {
  const isDesktop = useThemeIsDesktop();

  const form = useFormikContext<UserChangePasswordFormValues>();
  const getFieldError = useGetFormFieldError<UserChangePasswordFormValues>();
  const getFieldName = useGetFieldName<UserChangePasswordFormValues>();

  return (
    <>
      <UserChangePasswordFormUsernameHiddenInput />
      {userChangePasswordFieldType.values.map((x) => (
        <FormInputPassword
          key={x}
          name={getFieldName(x)}
          label={userChangePasswordFieldType.getTitle(x)}
          disabled={form.isSubmitting}
          error={getFieldError(form, x)}
          value={form.values[x]}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          autoComplete={userChangePasswordFieldGetAutocomplete(x)}
        />
      ))}
      <Box padding="xs" />
      <ButtonLoading size="medium" isLoading={form.isSubmitting} isFullWidth={!isDesktop} type="submit">
        Изменить пароль
      </ButtonLoading>
    </>
  );
};
