import { useCallback, useEffect, useState } from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectCreditExecutionDetailsActiveDemand } from "../../store/active-demand/creditExecutionDetailsActiveDemand.selectors";
import { useCreditExecutionDetailsContext } from "../../hooks/context/useCreditExecutionDetailsContext";
import {
  DemandQueueTaskCompleted,
  DemandQueueTasksNewComment,
  useDemandQueueTasksNewCommentOnSubmit,
} from "../../../../demand/queue-tasks";
import { creditExecutionDetailsReloadActiveDemand } from "../../store/creditExecutionDetails.actions";
import { pushSuccessGlobalToast } from "../../../../global/toasts";
import { addNewCompletedTaskCreditExecutionDetailsTasksHistory } from "../../store/tasks-history/creditExecutionDetailsTasksHistory.slice";

export type CreditExecutionDetailsDemandNewCommentProps = BaseCssProps;

export const CreditExecutionDetailsDemandNewComment = ({ className }: CreditExecutionDetailsDemandNewCommentProps) => {
  const dispatch = useAppDispatch();

  const { canSendComment } = useAppSelector(selectCreditExecutionDetailsActiveDemand);
  const { demandId } = useCreditExecutionDetailsContext();

  const [isCommentFormOpened, setIsCommentFormOpened] = useState(false);

  useEffect(() => {
    setIsCommentFormOpened(false);
  }, [demandId]);

  const onSuccess = useCallback(
    (completedQueueTask: DemandQueueTaskCompleted) => {
      dispatch(addNewCompletedTaskCreditExecutionDetailsTasksHistory(completedQueueTask));
      dispatch(creditExecutionDetailsReloadActiveDemand());
      dispatch(pushSuccessGlobalToast("Сообщение отправлено"));
      setIsCommentFormOpened(false);
    },
    [dispatch]
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const onSubmit = useDemandQueueTasksNewCommentOnSubmit(demandId!, onSuccess);

  return (
    <DemandQueueTasksNewComment
      canSendComment={canSendComment === true && !!demandId}
      className={className}
      isCommentFormOpened={isCommentFormOpened}
      onSendCommentButtonClick={() => setIsCommentFormOpened(true)}
      onSubmit={onSubmit}
      onCancel={() => setIsCommentFormOpened(false)}
    />
  );
};
