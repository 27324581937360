import * as React from "react";
import { ReactNode, useEffect, useRef } from "react";
import "./ModalWrapper.scss";
import { createPortal } from "react-dom";

interface IModalWrapperProps {
  onClose: Function;
  children?: ReactNode;
}

interface ModalWrapperState {
  a?: string;
}

const body = document.getElementsByTagName("body").item(0);
const modalWrapperRoot = document.getElementById("modal-wrapper-root");

const Modal: React.FC<IModalWrapperProps> = (props) => {
  return (
    <div className="modal-wrapper__content">
      <div className="modal-wrapper__close" onClick={() => props.onClose()}>
        <span aria-hidden="true">×</span>
      </div>
      {props.children}
    </div>
  );
};

export const ModalWrapper = ({ onClose, children }: IModalWrapperProps) => {
  const elementRef = useRef<HTMLElement>(document.createElement("div"));

  useEffect(() => {
    elementRef.current.classList.add("modal-wrapper");

    body?.classList.add("modal-open");
    modalWrapperRoot?.appendChild(elementRef.current);

    return () => {
      body?.classList.remove("modal-open");
      if (elementRef.current) {
        modalWrapperRoot?.removeChild(elementRef.current);
      }
    };
  }, []);

  return createPortal(<Modal onClose={onClose}>{children}</Modal>, elementRef.current);
};
