import * as React from "react";
import { StackPanel } from "../../../common/ui-kit/containers/stack-panel";
import { Text } from "../../../common/ui-kit/typography";

export interface DemandActualDocumentProps {
  documentTypeTitle: React.ReactNode;
  file?: React.ReactNode;
  className?: string;
}

export const DemandActualDocumentContainer = ({ documentTypeTitle, file, className }: DemandActualDocumentProps) => {
  return (
    <StackPanel gap="xs" direction="column" className={className}>
      <Text fontWeight={700} lineHeight="defaultLineHeight" fontSize="fz3" fontColor="primary">
        {documentTypeTitle}
      </Text>
      <div>{file}</div>
    </StackPanel>
  );
};
