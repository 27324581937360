import { Form, useFormikContext } from "formik";
import { TransitionGroup } from "react-transition-group";
import { useThemeDesktopBreakpoint, useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { BaseCssProps } from "../../ui-kit/types";
import { CommentFormFormValues } from "../types/CommentFormFormValues";
import { useGetFieldError, useGetFieldName } from "../../form";
import { useHandleFileFieldChange } from "../../../files";
import { FileUpload, FileUploadDropzoneInputBlock } from "../../../files/upload";
import { SimpleOpacityTransition } from "../../ui-kit/animations/transitions";
import { Text } from "../../ui-kit/typography";
import { StackPanel } from "../../ui-kit/containers/stack-panel";
import { ButtonLink } from "../../ui-kit/buttons/ButtonLink";
import { BlockResponsiveSubmitButton } from "../../ui-kit/buttons/block-responsive-submit";
import * as S from "./CommentFormFields.styled";

export interface CommentFormFieldsProps extends BaseCssProps {
  onCancel: () => void;
  submitError?: string;
}

export const CommentFormFields = ({ className, onCancel, submitError }: CommentFormFieldsProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();
  const isDesktop = useThemeIsDesktop();

  const { touched, errors, getFieldProps, isSubmitting } = useFormikContext<CommentFormFormValues>();

  const getFieldName = useGetFieldName<CommentFormFormValues>();
  const getFieldError = useGetFieldError<CommentFormFormValues>();

  const { handleFileChange, removeFile, files } = useHandleFileFieldChange(getFieldName("files"));

  return (
    <S.Container as={Form} className={className}>
      <FormTextarea
        minRows={2}
        maxRows={isDesktop ? 20 : 15}
        placeholder="Комментарий"
        error={getFieldError("comment", touched.comment, errors.comment)}
        {...getFieldProps(getFieldName("comment"))}
      />
      {files && files.length > 0 && (
        <TransitionGroup component={null}>
          {files.map((x) => (
            <SimpleOpacityTransition timeout={250} key={x.id}>
              <FileUpload
                fileName={x.file.name}
                date={x.dateAdded}
                canRemove={!isSubmitting}
                onRemove={() => removeFile(x)}
              />
            </SimpleOpacityTransition>
          ))}
        </TransitionGroup>
      )}

      <FileUploadDropzoneInputBlock
        text={!files || files.length === 0 ? "Прикрепить файлы" : "Прикрепить еще"}
        handleFileChange={handleFileChange}
        isMultiple
        isDisabled={isSubmitting}
      />
      <StackPanel direction={{ xs: "column", [desktopBreakpoint]: "row" }} gap="lg">
        <BlockResponsiveSubmitButton type="submit" isLoading={isSubmitting} error={submitError}>
          Отправить
        </BlockResponsiveSubmitButton>
        {isDesktop && !isSubmitting && (
          <ButtonLink type="button" onClick={onCancel}>
            <Text fontSize="fz3" fontColor="colors.primary">
              Отмена
            </Text>
          </ButtonLink>
        )}
      </StackPanel>
    </S.Container>
  );
};
