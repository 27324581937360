import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { BankGuaranteeId } from "../../../../../common/types/demand";
import { BankGuaranteeListItemMobile } from "./mobile/BankGuaranteeListItem.mobile";
import { NavigationState } from "../../../../../common/navigation/navigation-state";
import { BankGuaranteeListItemDesktop } from "./desktop/BankGuaranteeListItem.desktop";

export interface BankGuaranteeListItemProps extends BaseCssProps {
  id: BankGuaranteeId;
  from?: NavigationState;
}

export const BankGuaranteeListItem = ({ id, className, from }: BankGuaranteeListItemProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={<BankGuaranteeListItemMobile guaranteeId={id} className={className} from={from} />}
      desktop={<BankGuaranteeListItemDesktop guaranteeId={id} className={className} from={from} />}
    />
  );
};
