import { BaseCssProps } from "../../../../common/ui-kit/types";
import { DemandId } from "../../../../common/types/demand";
import { BankGuaranteeFileRegistryStatusType } from "../../../common/types";
import { canDownloadBankGuaranteeExtractFromRegistry } from "../../utils/canDownloadBankGuaranteeExtractFromRegistry";
import { BankGuaranteeExtractFromRegistryFileLabel } from "../BankGuaranteeExtractFromRegistryFileLabel";

export interface BankGuaranteeExtractFromRegistryFileLabelMobileProps extends BaseCssProps {
  demandId: DemandId;
  statusType: BankGuaranteeFileRegistryStatusType;
}

export const BankGuaranteeExtractFromRegistryFileLabelMobile = ({
  demandId,
  statusType,
  className,
}: BankGuaranteeExtractFromRegistryFileLabelMobileProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {canDownloadBankGuaranteeExtractFromRegistry(statusType) && (
        <BankGuaranteeExtractFromRegistryFileLabel statusType={statusType} demandId={demandId} className={className} />
      )}
    </>
  );
};
