import { useNavigate } from "react-router-dom";
import { FormikHelpers } from "formik";
import { useCallback } from "react";
import { useNavigationStateFrom } from "../../../common/navigation/navigation-state";
import { useGetFieldName } from "../../../common/form";
import { PublicUserAuthenticationFromValues } from "../types/PublicUserAuthenticationFromValues";
import { publicUserAuthenticationValidationSchema } from "../validation/publicUserAuthenticationValidationSchema";
import { authenticationService } from "../../../common/authentication";
import { generatePath } from "../../../common/urls/internal/utils";
import { isBaseApiError } from "../../../common/api/types";
import { StatusCodeEnum } from "../../../common/services/fetchClient";
import { useAppDispatch } from "../../../common/store";
import { pushErrorGlobalToast } from "../../../global/toasts";

export const usePublicUserAuthenticationFormSubmitHandler = () => {
  const dispatch = useAppDispatch();
  const state = useNavigationStateFrom();
  const navigator = useNavigate();
  const getFieldName = useGetFieldName<PublicUserAuthenticationFromValues>();

  return useCallback(
    async (
      values: PublicUserAuthenticationFromValues,
      { setFieldError }: FormikHelpers<PublicUserAuthenticationFromValues>
    ) => {
      try {
        const { login, password } = publicUserAuthenticationValidationSchema.cast(values);
        await authenticationService.authenticate(login, password);

        navigator(state?.from?.url ?? generatePath((x) => x.root));
      } catch (err: unknown) {
        if (isBaseApiError(err) && err.status === StatusCodeEnum.InternalServerError) {
          dispatch(pushErrorGlobalToast("Ошибка авторизации. Пожалуйста, попробуйте ещё раз позже"));
        } else {
          setFieldError(getFieldName("password"), "Неверный пароль");
        }
      }
    },
    [dispatch, getFieldName, navigator, state?.from?.url]
  );
};
