import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { agencyDemandAdapter } from "./agencyDemand.adapter";
import { AgencyDemandSliceState } from "./agencyDemand.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { AGENCY_DEMAND_SLICE_NAME, getAgencyDemand } from "./agencyDemand.thunks";
import { agencyDemandMapper } from "./agencyDemand.mapper";
import { uploadDocumentDemandQueueTasks } from "../../../demand/queue-tasks";

const initialState = agencyDemandAdapter.getInitialState<AgencyDemandSliceState>({
  status: LoadingStatusEnum.Idle,
  data: undefined,
});

const slice = createSlice({
  name: AGENCY_DEMAND_SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgencyDemand.fulfilled, (state, action) => {
        const { model, documents } = agencyDemandMapper.fromResponse(action.payload.body);
        state.data = model;
        agencyDemandAdapter.setAll(state, documents ?? []);
      })
      .addCase(uploadDocumentDemandQueueTasks.fulfilled, (state, action) => {
        const { queueTaskId, id, requestDocType } = action.meta.arg;
        if (state.data !== undefined && queueTaskId === state.data.id) {
          const uniqueId = agencyDemandMapper.getUniqueId(id, requestDocType);
          const document = state.entities[uniqueId];

          if (document !== undefined && document.requestedDocType === requestDocType) {
            const { files } = action.payload.body;
            if (files.length) {
              if (document.isMultiFile) {
                document.oldFiles = [...(document.oldFiles ?? []), ...files];
              } else {
                // eslint-disable-next-line prefer-destructuring
                document.oldFile = files[0];
              }
            }
          }
        }
      })
      .addMatcher(isAsyncThunkAction(getAgencyDemand), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const agencyDemandReducer = slice.reducer;

export const { reset: resetAgencyDemand } = slice.actions;
