import { useEffect, useState } from "react";
import { useMediaQuery } from "@sideg/ui-kit";

export const useCanSetPwa = (): boolean => {
  const isStandalone = useMediaQuery("(display-mode: standalone)");
  const [isUserSetPwa, setIsUserSetPwa] = useState(isStandalone);

  useEffect(() => {
    const isStandaloneIosSafari = () =>
      "standalone" in navigator && (navigator as unknown as { standalone: boolean }).standalone;

    setIsUserSetPwa(isStandaloneIosSafari() || isStandalone);
  }, [isStandalone]);

  return isUserSetPwa;
};
