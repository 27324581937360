import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { getAsideListItemCommonStyles } from "../../../../ui/layout/sidebar/components/aside-block-mobile/AsideBlockListItem.styled";

export const Ul = styled.ul<{ nested?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.lg};
  padding: ${({ theme, nested }) => (nested ? theme.spaces.md : 0)} 0
    ${({ theme, nested }) => (nested ? theme.spaces.md : 0)} ${({ theme, nested }) => (nested ? theme.spaces.xl : 0)};
  margin-top: ${({ theme, nested }) => (nested ? theme.spaces.xl : 0)};

  border: ${({ nested }) => (nested ? 1 : 0)}px solid ${({ theme }) => theme.layout.sidebar.mobile.dividerColor};
  border-left: none;
  border-right: none;
`;

export const Li = styled.li<{ active: boolean; nestedLevel: 0 | 1 | 2 }>(
  ({ theme }) => getAsideListItemCommonStyles(theme),
  ({ theme, active }) => css`& > span,
  & > button,
  & > a,
  & > a span{
    color: ${active ? theme.layout.sidebar.menu.activeLinkColor : theme.layout.sidebar.menu.linkColor};
  }

    & > svg > path {
      stroke: ${active ? theme.layout.sidebar.menu.activeLinkColor : theme.layout.sidebar.menu.linkColor};
    }
  }`
);

export const Counter = styled.div`
  background-color: ${({ theme }) => theme.layout.sidebar.mobile.counterBackgroundColor};
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  padding: 2px 10px;
  border-radius: 12px;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: 1.25;
`;
