import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { NavigationStateFrom } from "../../../../../common/navigation/navigation-state/types/NavigationStateFrom";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { Text } from "../../../../../common/ui-kit/typography";
import { FullPageContainerHeaderTitle } from "../../../../../common/ui/containers/full-page";
import { useBackLinkHeaderAction } from "../../../../../common/ui/layout/header";
import {
  creditWorkingAssetsCreationCreateByExistsPageType,
  CreditWorkingAssetsCreationCreateByExistsPageType,
} from "../../../types/creation/CreditWorkingAssetsCreationCreateByExistsPageType";
import { ButtonLink } from "../../../../../common/ui-kit/buttons/ButtonLink";

export interface CreditWorkingAssetsCreationCreateByExistsHeaderMobileProps extends BaseCssProps {
  from: NavigationStateFrom;
  activePage: CreditWorkingAssetsCreationCreateByExistsPageType;
  onPageTypeClick: (type: CreditWorkingAssetsCreationCreateByExistsPageType) => void | Promise<void>;
}

export const CreditWorkingAssetsCreationCreateByExistsHeaderMobile = ({
  from,
  activePage,
  onPageTypeClick,
  className,
}: CreditWorkingAssetsCreationCreateByExistsHeaderMobileProps) => {
  useBackLinkHeaderAction({ url: from.url, text: from.backTitle, withBackIcon: true });

  return (
    <StackPanel gap="lg" direction="column" alignItems="center" justifyContent="center" className={className}>
      <FullPageContainerHeaderTitle
        text={
          <span>
            Выбор банков
            <br />
            для доотправки заявки
          </span>
        }
      />
      <StackPanel gap="xs" direction="column">
        <Text as="div" textAlign="center" fontSize="fz2">
          {creditWorkingAssetsCreationCreateByExistsPageType.values
            .filter((x) => x !== activePage)
            .map((x) => (
              <ButtonLink onClick={() => onPageTypeClick(x)} type="button" key={x}>
                {creditWorkingAssetsCreationCreateByExistsPageType.getTitle(x)}
              </ButtonLink>
            ))}
        </Text>
      </StackPanel>
    </StackPanel>
  );
};
