import { FetchClientHeaders } from "../../services/fetchClient/types/FetchClientHeaders";
import { apiResponseTransformer, blobResponseTransformer } from "./apiResponseTransformer";
import { apiRejectTransformer } from "./apiRejectTransformer";
import { FetchRequestOptionsTransformers } from "../../services/fetchClient";

export const apiTransformers: Required<FetchRequestOptionsTransformers> = {
  transformResponse: (data, headers?: FetchClientHeaders) => {
    // Axios обрабатывает все ответы, в том числе rejected,
    // поэтому игнорируем просто строку, так как отказ обработаем позже
    if (typeof data === "string") {
      return data;
    }

    if (data instanceof Blob) {
      return blobResponseTransformer(data, headers);
    }

    return apiResponseTransformer(data);
  },
  transformReject: apiRejectTransformer,
};
