import styled from "@emotion/styled";
import { BlinkedCircleIndicator } from "../../../common/ui-kit/notifications/circle-indicator";
import { ButtonLink } from "../../../common/ui-kit/buttons/ButtonLink";

const StyledContainer = styled.div`
  display: inline-flex;
  gap: ${({ theme }) => theme.spaces.md};
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary};
`;

export interface QueueTasksListHasRealtimeUpdatesProps {
  onUpdateClick: () => void | Promise<void>;
}

export const QueueTasksListHasRealtimeUpdates = ({ onUpdateClick }: QueueTasksListHasRealtimeUpdatesProps) => {
  return (
    <StyledContainer>
      <BlinkedCircleIndicator size={9} color={(theme) => theme.secondaryColors.notificationBackground} />
      <div>
        <span>Появились новые задачи. </span>
        <ButtonLink type="button" onClick={onUpdateClick}>
          Обновить список
        </ButtonLink>
      </div>
    </StyledContainer>
  );
};
