import styled from "@emotion/styled";
import { useShowMoreText } from "../../../../common/ui-kit/typography";
import { ButtonLink } from "../../../../common/ui-kit/buttons/ButtonLink";

const Styled = styled.div`
  background-color: #ffffff;
  padding: ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.sm};
  box-shadow: inset 2px 0 0 #000000;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: 20px;
  margin-top: ${({ theme }) => theme.spaces.sm};
`;

export interface QueueTasksNotificationCommentProps {
  comment: string;
}

export const QueueTasksNotificationComment = ({ comment }: QueueTasksNotificationCommentProps) => {
  const { text, isOpened, trimmed, setIsOpened } = useShowMoreText(comment, 100);

  return (
    <Styled>
      <p>{text}</p>
      {trimmed && (
        <ButtonLink type="button" onClick={() => setIsOpened(!isOpened)}>
          {isOpened ? "Скрыть сообщение" : "Все сообщение"}
        </ButtonLink>
      )}
    </Styled>
  );
};
