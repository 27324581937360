import styled from "@emotion/styled";
import { AppLinkButton } from "../../../../links";

export const Link = styled(AppLinkButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.mqDown(theme.breakpoints.desktopBreakpoint)} {
    border-radius: 50%;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
