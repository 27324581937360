import { widgetsNewsAdapter } from "./widgetsNews.adapter";
import { RootState } from "../../../store";
import { WidgetsNewsState } from "./widgetsNews.types";

export const selectWidgetsNewsState = (state: RootState): WidgetsNewsState => state.domains.news.widget;

export const {
  selectById: selectWidgetsNewsItemById,
  selectIds: selectWidgetsNewsIds,
  selectTotal: selectWidgetsNewsTotal,
} = widgetsNewsAdapter.getSelectors(selectWidgetsNewsState);
