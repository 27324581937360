import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import { CSSProperties } from "react";
import { ControlSize } from "../../../types";

export type CheckboxSizeType<TProperty extends keyof CSSProperties> = Record<ControlSize, CSSObject[TProperty]>;

const checkboxSizes: CheckboxSizeType<"width"> = {
  small: "12px",
  default: "16px",
  medium: "20px",
};

const checkboxOutlineSizes: CheckboxSizeType<"outline"> = {
  small: "2px",
  default: "4px",
  medium: "6px",
};

const checkboxOutlineFocusSizes: CheckboxSizeType<"outline"> = {
  small: "1px",
  default: "2px",
  medium: "4px",
};

const checkboxIconCircleCxCySizes: CheckboxSizeType<"scale"> = {
  small: "6px",
  default: "8px",
  medium: "10px",
};

const checkboxIconOuterCircleRadiusSizes: CheckboxSizeType<"scale"> = {
  small: "5.25px",
  default: "7.25px",
  medium: "9.25px",
};

const checkboxIconInnerCircleRadiusSizes: CheckboxSizeType<"scale"> = {
  small: "3px",
  default: "4px",
  medium: "5px",
};

export const Label = styled.label<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.sm};
  align-items: center;
  justify-content: flex-start;
  vertical-align: middle;
  outline: none;

  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
`;

export const RadioCheckboxInputContainer = styled.span<{ checkboxSize: ControlSize }>`
  height: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  width: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  line-height: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  position: relative;
  z-index: 1;

  &:focus-within {
    border-radius: 4px;
    outline: 1px solid ${({ theme }) => theme.palette.checkbox.focus};
    outline-offset: ${({ checkboxSize }) => checkboxOutlineFocusSizes[checkboxSize]};
  }
`;

export const RadioCheckboxInput = styled.input<{ checkboxSize: ControlSize }>`
  appearance: none;
  border: 1.5px solid ${({ theme }) => theme.palette.checkbox.label};
  border-radius: 50%;
  height: 100%;
  width: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  left: 0;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: -1;

  &:checked {
    appearance: none;
    border: none;
  }

  &:checked ~ svg {
    display: flex;
  }

  &:hover:enabled {
    outline: ${({ checkboxSize }) => checkboxOutlineSizes[checkboxSize]} solid ${({ theme }) => theme.colors.info};
    outline-offset: 0;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.control.secondaryLight};
    border-color: ${({ theme }) => theme.palette.control.secondaryMedium};
    cursor: default;
  }

  &:disabled:checked {
    cursor: default;
  }

  &:disabled:hover {
    outline: none;
  }
`;

export const Icon = styled.svg`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
`;

export const IconOuterCircle = styled.circle<{ isDisabled?: boolean; outerCircleSizes: ControlSize }>`
  fill: ${({ isDisabled, theme }) =>
    isDisabled ? theme.palette.control.secondaryLight : theme.palette.background.light};

  stroke: ${({ isDisabled, theme }) => (isDisabled ? theme.palette.control.secondaryMedium : theme.colors.primary)};

  cx: ${({ outerCircleSizes }) => checkboxIconCircleCxCySizes[outerCircleSizes]};
  cy: ${({ outerCircleSizes }) => checkboxIconCircleCxCySizes[outerCircleSizes]};

  r: ${({ outerCircleSizes }) => checkboxIconOuterCircleRadiusSizes[outerCircleSizes]};
`;

export const IconInnerCircle = styled.circle<{ isDisabled?: boolean; innerCircleSizes: ControlSize }>`
  fill: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.secondary : theme.colors.primary)};

  cx: ${({ innerCircleSizes }) => checkboxIconCircleCxCySizes[innerCircleSizes]};
  cy: ${({ innerCircleSizes }) => checkboxIconCircleCxCySizes[innerCircleSizes]};

  r: ${({ innerCircleSizes }) => checkboxIconInnerCircleRadiusSizes[innerCircleSizes]};
`;
