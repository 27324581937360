import { BANK_GUARANTEE_ROOT_URL } from "../../common/urls";
import { makeAppUrl } from "../../../common/urls/internal/utils";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const bankGuaranteeListUrls = {
  root: makeAppUrl(
    BANK_GUARANTEE_ROOT_URL,
    BANK_GUARANTEE_ROOT_URL,
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
};
