import { forwardRef, ReactNode } from "react";
import { PinSignIcon } from "projects/agent/src/domains/common/ui-kit/icons";
import { DemandQueueTaskVariant } from "../../types/DemandQueueTaskVariant";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import * as S from "./DemandQueueTaskCard.styled";
import { getFormattedCommentDateDemandQueueTasks } from "../../utils/getFormattedCommentDateDemandQueueTasks";

export interface DemandQueueTaskCardProps extends BaseCssProps {
  title: ReactNode;
  date?: Date;
  variant: DemandQueueTaskVariant;
  children?: ReactNode;
  isFocused?: boolean;
  isPinned?: boolean;
}

export const DemandQueueTaskCard = forwardRef<HTMLDivElement, DemandQueueTaskCardProps>(
  ({ title, date, variant, children, className, isFocused, isPinned }, ref) => {
    return (
      <S.Article variant={variant} className={className} ref={ref} isFocused={isFocused}>
        <S.Header variant={variant}>
          <S.Title>{title}</S.Title>
          {date && <S.Time dateTime={date.toString()}>{getFormattedCommentDateDemandQueueTasks(date)}</S.Time>}
          {isPinned && <PinSignIcon />}
        </S.Header>
        {children}
      </S.Article>
    );
  },
);
