import { QueueTasksListCreationButtonProps } from "./QueueTasksListCreationButtonProps";
import * as S from "./QueueTasksListCreationButton.mobile.styled";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { Text } from "../../../../common/ui-kit/typography";
import { PlusIcon } from "../../../../common/ui-kit/icons";
import { useQueueTasksCreationProductsDescription } from "../../hooks/creation/useQueueTasksCreationProductsDescription";

export const QueueTasksListCreationButtonMobile = ({ state }: QueueTasksListCreationButtonProps) => {
  const creationProductsDescription = useQueueTasksCreationProductsDescription();

  return (
    <S.Link variant="contained" color="primary" to={(x) => x.queueTasks.creation.create} state={state}>
      <StackPanel gap="md" direction="row" alignItems="center" justifyContent="space-between">
        <StackPanel gap="xs" direction="column">
          <Text fontWeight={500} fontSize={1.31} lineHeight="defaultLineHeight" fontColor="primaryLight">
            Новая заявка
          </Text>
          {creationProductsDescription && (
            <Text fontSize="fz2" lineHeight="1.4" fontColor="primaryLight" opacity={0.6}>
              {creationProductsDescription}
            </Text>
          )}
        </StackPanel>
        <PlusIcon />
      </StackPanel>
    </S.Link>
  );
};
