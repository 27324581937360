import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { ProductListItemStageCountersContainer } from "../../../../../common/ui/product/stage";
import { creditWorkingAssetsDemandStageType } from "../../../../common/types";

export type CreditWorkingAssetsListItemStageCountersSkeletonProps = BaseCssProps;

export const CreditWorkingAssetsListItemStageCountersSkeleton = ({
  className,
}: CreditWorkingAssetsListItemStageCountersSkeletonProps) => {
  return (
    <ProductListItemStageCountersContainer className={className}>
      {creditWorkingAssetsDemandStageType.values.map((x) => (
        <Skeleton width={30} height={24} key={x} />
      ))}
    </ProductListItemStageCountersContainer>
  );
};
