import styled from "@emotion/styled";
import { ButtonOutline } from "../../ui-kit/buttons";

export const AppButtonOutline = styled(ButtonOutline)`
  height: 40px;
  padding: 0 ${({ theme }) => theme.spaces.md};
  font-size: ${({ theme }) => theme.font.fz4};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-weight: 500;
`;
