import { useRef, ReactNode } from "react";
import { Formik, FormikHelpers } from "formik";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useScrollToTopOnMount } from "../../../../common/hooks/scroll";
import { FormBox, FormScrollToFirstErrorAfterSubmit } from "../../../../common/form";
import { CreditExecutionCreationScoringFormValues } from "../../types/scoring/CreditExecutionCreationScoringFormValues";
import { creditExecutionCreationScoringValidationSchema } from "../../validation/creditExecutionCreationScoringValidationSchema";

export interface CreditExecutionCreationScoringFormProps extends BaseCssProps {
  children: ReactNode;
  onSubmit: (
    values: CreditExecutionCreationScoringFormValues,
    helpers: FormikHelpers<CreditExecutionCreationScoringFormValues>
  ) => void | Promise<void>;
}

export const CreditExecutionCreationScoringForm = ({
  children,
  onSubmit,
  className,
}: CreditExecutionCreationScoringFormProps) => {
  const ref = useRef<HTMLFormElement | null>(null);
  useScrollToTopOnMount("smooth");

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={creditExecutionCreationScoringValidationSchema.getDefault()}
      validationSchema={creditExecutionCreationScoringValidationSchema}
    >
      <FormBox ref={ref} flexGrow={1} flexShrink={1} display="flex" flexDirection="column" className={className}>
        <FormScrollToFirstErrorAfterSubmit containerRef={ref} />
        {children}
      </FormBox>
    </Formik>
  );
};
