import { createAsyncThunk } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../store/utils";
import { BaseApiError, createApiError } from "../../api/types";
import { ApplicationUpdaterGetVersionOutputDto } from "../api/dto/output/ApplicationUpdaterGetVersionOutputDto";
import { applicationUpdaterApi } from "../api/applicationUpdaterApi";
import { loggingService } from "../../logging/services/loggingService";

export const APPLICATION_UPDATER_SLICE_NAME = combineSliceNames("applicationUpdater");

export const getCurrentVersionApplicationUpdater = createAsyncThunk<
  ApplicationUpdaterGetVersionOutputDto,
  undefined,
  { rejectValue: BaseApiError }
>(combineSliceNames(APPLICATION_UPDATER_SLICE_NAME, "getCurrentAppVersion"), async (_, { rejectWithValue }) => {
  try {
    return await applicationUpdaterApi.getVersion();
  } catch (err: unknown) {
    loggingService.logError(err);

    return rejectWithValue(createApiError("Не удалось получить текущую версию"));
  }
});
