import { ReactNode } from "react";
import { useServiceWorkerRefresher } from "../hooks/useServiceWorkerRefresher";
import { serviceWorkerNeedUpdateContext } from "../store/serviceWorkerNeedUpdateContext";
import { serviceWorkerUpdateApiContext } from "../store/serviceWorkerUpdateApiContext";
import { useServiceWorkerContextState } from "../hooks/useServiceWorkerContextState";
import { serviceWorkerNeedForceUpdateContext } from "../store/serviceWorkerNeedForceUpdateContext";

export interface ServiceWorkerProviderProps {
  children: ReactNode;
}

export const ServiceWorkerProvider = ({ children }: ServiceWorkerProviderProps): JSX.Element => {
  const [needUpdateContextValue, needForceUpdateContextValue, updateApiContextValue] = useServiceWorkerContextState();

  useServiceWorkerRefresher();

  return (
    <serviceWorkerNeedUpdateContext.Provider value={needUpdateContextValue}>
      <serviceWorkerNeedForceUpdateContext.Provider value={needForceUpdateContextValue}>
        <serviceWorkerUpdateApiContext.Provider value={updateApiContextValue}>
          {children}
        </serviceWorkerUpdateApiContext.Provider>
      </serviceWorkerNeedForceUpdateContext.Provider>
    </serviceWorkerNeedUpdateContext.Provider>
  );
};
