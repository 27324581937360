import { useCallback, useMemo, useState } from "react";
import { useApiMutationAction } from "../../../common/hooks";
import { FormSubmitHandler, formValidationFieldService } from "../../../common/form";
import { PublicUserRegistrationFormValues } from "../types/PublicUserRegistrationFormValues";
import { publicUserRegistrationFormValidationSchema } from "../validation/publicUserRegistrationFormValidationSchema";
import { registerClientPublicUserRegistration } from "../store/publicUserRegistration.thunks";
import { isBaseApiError } from "../../../common/api/types";
import { CompleteCommonPublicUserRegistrationOutputDto } from "../api/dto/output/CompleteCommonPublicUserRegistrationOutputDto";

export interface UsePublicUserRegistrationRegisterClientReturnType {
  isError: boolean;
  isSuccess: boolean;
  error: string | undefined;
  data: CompleteCommonPublicUserRegistrationOutputDto | undefined;
  onSubmit: FormSubmitHandler<PublicUserRegistrationFormValues>;
}

export const usePublicUserRegistrationRegisterClient = (): UsePublicUserRegistrationRegisterClientReturnType => {
  const [data, setData] = useState<UsePublicUserRegistrationRegisterClientReturnType["data"]>(undefined);
  const { error: innerError, mutate } = useApiMutationAction();

  const onSubmit: FormSubmitHandler<PublicUserRegistrationFormValues> = useCallback(
    async (values, helpers) => {
      const castedValues = publicUserRegistrationFormValidationSchema.cast(values);
      const result = await mutate(registerClientPublicUserRegistration, castedValues, {
        errorHandler: async (e) => {
          const validationErrors = formValidationFieldService.tryGetValidationErrorsNullable(e);
          if (validationErrors !== undefined) {
            helpers.setErrors(validationErrors);

            return "";
          }

          const placeholder = "Во время обработки запроса произошла ошибка. Пожалуйста, попробуйте ещё раз позже.";

          return isBaseApiError(e) ? e.message ?? placeholder : placeholder;
        },
      });

      setData(result?.body);
    },
    [mutate],
  );

  return useMemo(() => {
    const error = innerError === "" ? undefined : innerError;

    return {
      isError: error !== undefined,
      isSuccess: data !== undefined,
      error,
      onSubmit,
      data,
    };
  }, [data, innerError, onSubmit]);
};
