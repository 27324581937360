import { CreditWorkingAssetsId } from "../../../../common/types/demand";
import { useCreditWorkingAssetsRejectionSubmitHandler } from "../hooks/useCreditWorkingAssetsRejectionSubmitHandler";
import { ProductRejection } from "../../../../common/product-rejection";
import { AppLayoutOverlayPanelKey } from "../../../../common/ui/layout/app-layout/types";

export interface CreditWorkingAssetsRejectionProductProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
  afterSuccess: () => Promise<void>;
  isOpen: boolean;
  onClose: () => void | Promise<void>;
  groupProductName: string;
  panelKey?: AppLayoutOverlayPanelKey;
}

export const CreditWorkingAssetsRejectionProduct = ({
  creditWorkingAssetsId,
  afterSuccess,
  isOpen,
  onClose,
  groupProductName,
  panelKey,
}: CreditWorkingAssetsRejectionProductProps) => {
  const { error, handleSubmit } = useCreditWorkingAssetsRejectionSubmitHandler(
    "product",
    { creditWorkingAssetsId },
    afterSuccess
  );

  return (
    <ProductRejection
      panelKey={panelKey}
      error={error}
      isOpen={isOpen}
      product="CreditWorkingAssets"
      onClose={onClose}
      title="Отмена заявки"
      description={`Будет отменена заявка № ${groupProductName} во все банки`}
      submitHandler={handleSubmit}
    />
  );
};
