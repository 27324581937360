import * as React from "react";
import { ThemeMobileDesktopView, useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../ui-kit/types";
import { Box } from "../../../../ui-kit/containers/box";
import { ProductCreationScoringResultRow } from "./ProductCreationScoringResultRow";

const BoxItem = ProductCreationScoringResultRow.Item.withComponent(Box);

export interface ProductCreationScoringResultSuccessRowContainerProps extends BaseCssProps {
  bank: React.ReactNode;
  sumLimit: React.ReactNode;
  sumInput: React.ReactNode;
  mobileCheck: React.ReactNode;
}

export const ProductCreationScoringResultSuccessRowContainer = ({
  className,
  bank,
  sumInput,
  sumLimit,
  mobileCheck,
}: ProductCreationScoringResultSuccessRowContainerProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <ProductCreationScoringResultRow.Container
      columnGap={{ xs: "none", [desktopBreakpoint]: "xs" }}
      rowGap="xs"
      className={className}
      minHeight={{ [desktopBreakpoint]: "3.5rem" }}
      isSelected={false}
    >
      <ProductCreationScoringResultRow.Item
        gridRow={1}
        gridColumn={{ xs: "1/span 11", [desktopBreakpoint]: "1/span 6" }}
      >
        {bank}
      </ProductCreationScoringResultRow.Item>
      <ProductCreationScoringResultRow.Item
        gridRow={{ xs: 2, [desktopBreakpoint]: 1 }}
        gridColumn={{ xs: "1/span 11", [desktopBreakpoint]: "7/span 6" }}
      >
        {sumLimit}
      </ProductCreationScoringResultRow.Item>
      <ThemeMobileDesktopView
        mobile={
          <ProductCreationScoringResultRow.Item justifySelf="end" gridRow="1/span 2" gridColumn="12/span 7">
            {mobileCheck}
          </ProductCreationScoringResultRow.Item>
        }
        desktop={undefined}
      />
      <BoxItem
        pt={{ xs: "sm", [desktopBreakpoint]: "none" }}
        gridRow={{ xs: 3, [desktopBreakpoint]: 1 }}
        gridColumn={{ xs: "span 18", [desktopBreakpoint]: "14/span 5" }}
        justifySelf={{ xs: "stretch", [desktopBreakpoint]: "stretch" }}
      >
        {sumInput}
      </BoxItem>
    </ProductCreationScoringResultRow.Container>
  );
};
