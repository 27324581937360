import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsListItemsIds } from "../../store/items/creditWorkingAssetsListItems.selectors";
import { ProductListItemsContainer } from "../../../../common/ui/product/list/container";
import { CreditWorkingAssetsListItem } from "./CreditWorkingAssetsListItem";

export type CreditWorkingAssetsListItemsProps = BaseCssProps;

export const CreditWorkingAssetsListItems = ({ className }: CreditWorkingAssetsListItemsProps) => {
  const ids = useAppSelector(selectCreditWorkingAssetsListItemsIds);

  return (
    <ProductListItemsContainer className={className}>
      {ids.map((x) => (
        <CreditWorkingAssetsListItem key={x} creditWorkingAssetsId={x} />
      ))}
    </ProductListItemsContainer>
  );
};
