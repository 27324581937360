import { useEffect, useState } from "react";
import * as React from "react";
import "./Tabs.scss";
import TabsItem from "./TabsModel";
import TabType from "../../../models/TabType";

interface TabsProps {
  items: TabsItem[];
  onChangeItem: (tab: TabType) => void;
}

const Tabs: React.FC<TabsProps> = (props) => {
  const [items, setItems] = useState<TabsItem[]>([]);

  useEffect(() => {
    setItems(props.items);
  }, props.items);

  const handleChangeItem = (event: React.MouseEvent, tab: TabType) => {
    event.preventDefault();
    changeItem(tab);
  };

  const changeItem = (tab: TabType) => {
    const activeItem = items.find((item) => item.isActive);

    if (activeItem && activeItem.tab === tab) {
      return;
    }

    const it = items.slice();
    changeSelfItem(it, tab);
  };

  const changeSelfItem = (items: TabsItem[], tab: TabType) => {
    items?.forEach((item) => {
      item.isActive = item.tab === tab;
    });
    setItems(items);
    props.onChangeItem(tab);
  };

  const getClass = (item: TabsItem): string => {
    const cls = ["tabs__button"];

    if (item.isActive) {
      cls.push("tabs__button_active");
    }

    return cls.join(" ");
  };

  return (
    <div className="tabs">
      <ul className="tabs__list">
        {items.map((item, index) => {
          return (
            <li className="tabs__item" key={index}>
              <button className={getClass(item)} onClick={(e) => handleChangeItem(e, item.tab)}>
                {item.title}
                {item.counter !== undefined && item.counter > 0 && (
                  <span className="tabs__item-counter">{item.counter}</span>
                )}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
