import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  currentMonth: "текущий месяц",
  previousMonth: "предыдущий месяц",
  last3Months: "три месяца",
  last6Months: "шесть месяцев",
};

export const reportsDemandPresetType = makeEnumLikeLiteral(
  ["currentMonth", "previousMonth", "last3Months", "last6Months"] as const,
  titles
);

export type ReportsDemandPresetType = EnumLikeLiteralType<typeof reportsDemandPresetType>;
