import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { widgetsNewsAdapter } from "./widgetsNews.adapter";
import { BaseState, getLoadingStatusFromAction, LoadingStatusEnum } from "../../../types/state";
import { getWidgetsNews, WIDGET_NEWS_SLICE_NAME } from "./widgetsNews.thunks";
import { widgetsNewsStorageService } from "../services/widgetsNewsStorageService";

const cachedNews = widgetsNewsStorageService.getNews();
let initialState = widgetsNewsAdapter.getInitialState<BaseState>({
  status: cachedNews?.news ? LoadingStatusEnum.Succeeded : LoadingStatusEnum.Idle,
});

if (cachedNews?.news !== undefined) {
  initialState = widgetsNewsAdapter.setAll(initialState, cachedNews.news);
}

const slice = createSlice({
  name: WIDGET_NEWS_SLICE_NAME,
  initialState,
  reducers: {
    update: (state) => {
      state.status = LoadingStatusEnum.Idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWidgetsNews.fulfilled, (state, action) => {
        widgetsNewsAdapter.setAll(state, action.payload.body.data);
      })
      .addCase(getWidgetsNews.rejected, (state, action) => {
        state.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(getWidgetsNews), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const { update: updateWidgetNews } = slice.actions;

export const newsWidgetReducer = slice.reducer;
