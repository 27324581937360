import { BankGuaranteeDetailsComplicatedTaskBillDecisionType } from "../../types/BankGuaranteeDetailsComplicatedTaskBillDecisionType";

const isExist = (decision: BankGuaranteeDetailsComplicatedTaskBillDecisionType) => {
  return (
    decision !== BankGuaranteeDetailsComplicatedTaskBillDecisionType.BillAgreed &&
    decision !== BankGuaranteeDetailsComplicatedTaskBillDecisionType.ConfirmPayment
  );
};

export const bankGuaranteeDetailsComplicatedTaskBillFormAmountFieldsRulesRules = {
  isExist,
};
