import { useCallback, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { federalLawType, FederalLawType } from "../../../../common/types/demand";
import { CreditExecutionCreationFormValues } from "../../types/creation/CreditExecutionCreationFormValues";
import { useCreditExecutionCreationField } from "./fields/useCreditExecutionCreationField";
import { federalLawRules } from "../../validation/rules/federalLaw.rules";

export const useCreditExecutionCreationFederalLawTypes = () => {
  const [types, setTypes] = useState<FederalLawType[]>(federalLawType.values);

  const {
    values: { federalLaw, isClosedAuction },
  } = useFormikContext<CreditExecutionCreationFormValues>();

  const [, { setValue }] = useCreditExecutionCreationField("federalLaw");

  const setAvailableValue = useCallback(
    async (values: FederalLawType[]) => {
      if (!values.includes(federalLaw)) {
        await setValue(values[0]);
      }
    },
    [federalLaw, setValue],
  );

  useEffect(() => {
    if (isClosedAuction) {
      setTypes(federalLawRules.closedAuctionCollection);
      setAvailableValue(federalLawRules.closedAuctionCollection);
    } else {
      setTypes(federalLawType.values);
    }
  }, [isClosedAuction, setAvailableValue]);

  return types;
};
