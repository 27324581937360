import { useCallback } from "react";
import { PurchaseDetails } from "../../../../../common/purchase";
import { useBankGuaranteeCreationForm } from "../useBankGuaranteeCreationForm";

export const useBankGuaranteeCreationPurchaseMutator = () => {
  const { setValues } = useBankGuaranteeCreationForm();

  return useCallback(
    (data: PurchaseDetails) => {
      setValues(
        (prev) => ({
          ...prev,
          purchaseLink: data.purchaseLink ?? prev.purchaseLink,
          lotNumber: data.lotNumber ?? prev.lotNumber,
          federalLaw: data.fzTypeCode ?? prev.federalLaw,
          purchaseIdentificationCode: data.purchaseIdentificationCode ?? prev.purchaseIdentificationCode,
          isClosedAuction: data.isClosedAuction === true,
          isSingleSupplier: data.isSingleSupplier === true,
          contractFirstSum: data.sumFirst ?? prev.contractFirstSum,
          contractFinalSum: data.sumFinal ?? prev.contractFinalSum,
          contractPrepaidSum: data.sumPrepaid ?? prev.contractPrepaidSum,
          guaranteeSum: data.sumByGuaranteeType?.[prev.guaranteeType] ?? prev.guaranteeSum,
          enforcementSum: data.sumEnforcement?.[prev.guaranteeType] ?? prev.enforcementSum,
          contractSubject: data.subject ?? prev.contractSubject,
          dateStart: data.dateStart ? new Date(data.dateStart) : prev.dateStart,
          dateEnd: data.dateEnd ? new Date(data.dateEnd) : prev.dateEnd,
          dateEvent: data.dateEvent?.[prev.guaranteeType]
            ? new Date(data.dateEvent[prev.guaranteeType])
            : prev.dateEvent,
          beneficiaryTaxpayerNumber: data.beneficiaryTaxpayerNumber ?? prev.taxpayerNumber,
          beneficiaryName: data.beneficiaryName ?? prev.beneficiaryName,
        }),
        true
      );
    },
    [setValues]
  );
};
