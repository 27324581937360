import { useFormikContext } from "formik";
import { IssuingCompanyId } from "../../../../../../common/types/companies";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { ButtonLink } from "../../../../../../common/ui-kit/buttons/ButtonLink";
import { PublicUserRegistrationAgentDocumentApproveCountdown } from "./PublicUserRegistrationAgentDocumentApproveCountdown";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import {
  changeDocumentSendTimePublicUserRegistrationAgent,
  changeDocumentSignStatePublicUserRegistrationAgent,
} from "../../../../store/agent/publicUserRegistrationAgent.slice";
import { useAppDispatch } from "../../../../../../common/store";
import { usePublicUserRegistrationAgentDocumentApproveSendCodeMutation } from "../../../../hooks/agent/document-approve/usePublicUserRegistrationAgentDocumentApproveSendCodeMutation";
import { isLoading } from "../../../../../../common/types/state";
import { usePublicUserRegistrationAgentDocumentApproveCodeTime } from "../../../../hooks/agent/document-approve/usePublicUserRegistrationAgentDocumentApproveCodeTime";

export interface PublicUserRegistrationAgentDocumentApproveFormActionsProps extends BaseCssProps {
  issuingCompanyId: IssuingCompanyId;
}

export const PublicUserRegistrationAgentDocumentApproveFormActions = ({
  issuingCompanyId,
}: PublicUserRegistrationAgentDocumentApproveFormActionsProps) => {
  const dispatch = useAppDispatch();
  const { status, sendCode } = usePublicUserRegistrationAgentDocumentApproveSendCodeMutation(issuingCompanyId);
  const { isSubmitting } = useFormikContext();

  const timeInSeconds = usePublicUserRegistrationAgentDocumentApproveCodeTime(issuingCompanyId);
  const canSendMore = timeInSeconds === 0;

  const onCancelClick = () => {
    dispatch(changeDocumentSignStatePublicUserRegistrationAgent({ issuingCompanyId, signState: "initial" }));
  };

  const onTimerExpires = () => {
    dispatch(changeDocumentSendTimePublicUserRegistrationAgent({ issuingCompanyId, time: undefined }));
  };

  return (
    <Box
      display="flex"
      alignSelf="stretch"
      gap="xs"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <ButtonLink type="button" disabled={!canSendMore || isSubmitting || isLoading(status)} onClick={sendCode}>
        Отправить повторно{" "}
        {!canSendMore && (
          <PublicUserRegistrationAgentDocumentApproveCountdown
            onTimerExpires={onTimerExpires}
            timeInSeconds={timeInSeconds}
          />
        )}
      </ButtonLink>
      <ButtonLink type="button" disabled={isSubmitting} onClick={onCancelClick}>
        Отмена
      </ButtonLink>
    </Box>
  );
};
