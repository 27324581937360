import { string, boolean, object } from "yup";
import { getFormattedPhoneSchema } from "../../../common/validation";

export const userProfileFormValidationSchema = object({
  userName: string().default(""),
  role: string().default(""),
  email: string().email("Введите корректный адрес электронной почты").required("Не забудьте заполнить").default(""),
  phone: getFormattedPhoneSchema().required("Укажите номер телефона").default(""),
  unreadMessages: boolean().default(false),
  status: boolean().default(false),
  news: boolean().default(false),
});
