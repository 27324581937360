import styled from "@emotion/styled";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";

export const Container = styled(AppLayoutResponsiveListPage.List)`
  background-color: ${({ theme }) => theme.palette.background.light};
`;

export const Date = styled.p`
  margin-bottom: ${({ theme }) => theme.spaces.xxl};
  font-size: ${({ theme }) => theme.font.fz3};
  color: ${({ theme }) => theme.palette.typography.primaryCaption};
`;

export const Title = styled.h2`
  font-size: 1.75rem;
  line-height: 1.4;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    font-size: 2.25rem;
  }
`;

export const Text = styled.p`
  white-space: pre-line;
  word-break: break-word;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 1.6;
  font-weight: 400;
  margin-bottom: 50px;
`;
