import { BankGuaranteeRejectionRejectDemandResponsiveView } from "../../../../rejection";
import { BankGuaranteeId, DemandId } from "../../../../../common/types/demand";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeListItemsDemandById } from "../../../store/items/bankGuaranteeListItems.selectors";
import { useProductRejectionModalPanel } from "../../../../../common/product-rejection";
import { useBankGuaranteeListItemModificationView } from "../../../hooks/modification/useBankGuaranteeListItemModificationView";

export interface BankGuaranteeListItemRejectDemandViewProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
}

export const BankGuaranteeListItemRejectDemandView = ({
  guaranteeId,
  demandId,
}: BankGuaranteeListItemRejectDemandViewProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { bank } = useAppSelector((state) => selectBankGuaranteeListItemsDemandById(state, demandId))!;

  const modalState = useProductRejectionModalPanel();

  const {
    isModalOpen,
    closeModal,
    onSuccess: afterSuccess,
  } = useBankGuaranteeListItemModificationView(guaranteeId, modalState, "Заявка отменена");

  return (
    <BankGuaranteeRejectionRejectDemandResponsiveView
      isOpen={isModalOpen}
      guaranteeId={guaranteeId}
      onClose={closeModal}
      demandId={demandId}
      bankName={bank.title}
      afterSuccess={afterSuccess}
      panelKey={undefined}
    />
  );
};
