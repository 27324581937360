import * as React from "react";
import { BankGuaranteeDemandStageType } from "../types";
import { ClockIcon, FilePlusIcon, LikeIcon, XOctagonIcon } from "../../../common/ui-kit/icons";

export const getBankGuaranteeDemandStageIcon = (stage: BankGuaranteeDemandStageType): React.ElementType => {
  switch (stage) {
    case "AttachingDocuments":
      return FilePlusIcon;
    case "Confirmed":
      return LikeIcon;
    case "Rejected":
      return XOctagonIcon;
    case "Processing":
    default:
      return ClockIcon;
  }
};
