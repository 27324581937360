import { RootState } from "../../../../common/store";
import { creditExecutionDetailsActiveTasksAdapter } from "./creditExecutionDetailsActiveTasks.adapter";
import { CreditExecutionDetailsActiveTasksState } from "./creditExecutionDetailsActiveTasks.types";
import { selectCreditExecutionDetails } from "../creditExecutionDetails.selectors";

export const selectCreditExecutionDetailsActiveTasks = (state: RootState): CreditExecutionDetailsActiveTasksState =>
  selectCreditExecutionDetails(state).activeTasks;

export const {
  selectById: selectCreditExecutionDetailsActiveTaskById,
  selectIds: selectCreditExecutionDetailsActiveTasksIds,
  selectTotal: selectCreditExecutionDetailsActiveTasksTotal,
} = creditExecutionDetailsActiveTasksAdapter.getSelectors(selectCreditExecutionDetailsActiveTasks);
