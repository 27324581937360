import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../types/utils";

const titles = {
  creation: "Заполнение данных",
  scoring: "Выбор банков",
  documentsUpload: "Загрузка документов",
};

export const productCreationStages = makeEnumLikeLiteral(["creation", "scoring", "documentsUpload"] as const, titles);

export type ProductCreationStages = EnumLikeLiteralType<typeof productCreationStages>;
