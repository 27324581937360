import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../../common/types/utils";

const titles = {
  taskTypes: "Задачи",
  requestOriginalStatuses: "Запрос оригинала",
};

export const bankGuaranteeListFilterMoreOptions = makeEnumLikeLiteral(
  ["taskTypes", "requestOriginalStatuses"] as const,
  titles,
);

export type BankGuaranteeListFilterMoreOptionsType = EnumLikeLiteralType<typeof bankGuaranteeListFilterMoreOptions>;
