import { Hash, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { encodeQueryParams } from "../../../utils/encodeQueryParams";
import { AppUrlSelector } from "../types";
import { ExtractRouteParams, generatePath } from "../utils";

type GeneratePathOptions<TSearch extends Record<string, unknown>, TState> = {
  search?: TSearch;
  state?: TState;
  hash?: Hash;
};

export const useAppNavigation = () => {
  const navigator = useNavigate();

  return useCallback(
    <TUrl extends string, TSearch extends Record<string, unknown>, TState>(
      selector: AppUrlSelector<TUrl, never>,
      params?: ExtractRouteParams<TUrl>,
      options?: GeneratePathOptions<TSearch, TState>
    ) => {
      navigator(
        {
          pathname: generatePath(selector, params),
          search: options?.search ? encodeQueryParams(options?.search) : undefined,
          hash: options?.hash,
        },
        { state: options?.state }
      );
    },
    [navigator]
  );
};
