import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../common/store";
import { selectCreditExecutionDetailsProductDetails } from "../../store/product-details/creditExecutionDetailsProductDetails.selectors";
import { selectCreditExecutionDetailsDemandsTotal } from "../../store/demands/creditExecutionDetailsDemands.selectors";
import { ProductDetailsEmpty } from "../../../../common/product-details/components/empty/ProductDetailsEmpty";
import {
  getCreditExecutionDemandStageColorAttachingAsProcessing,
  getCreditExecutionStageBackgroundColor,
} from "../../../common/utils";
import { creditExecutionDemandStageType } from "../../../common/types";

export type CreditExecutionDetailsEmptyProps = BaseCssProps;

export const CreditExecutionDetailsEmpty = ({ className }: CreditExecutionDetailsEmptyProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { activeTasksCount, counters } = useAppSelector(selectCreditExecutionDetailsProductDetails).data!;
  const banksCount = useAppSelector(selectCreditExecutionDetailsDemandsTotal);

  return (
    <ProductDetailsEmpty
      className={className}
      counters={counters}
      getStageColor={getCreditExecutionDemandStageColorAttachingAsProcessing}
      getStageBackgroundColor={getCreditExecutionStageBackgroundColor}
      stageValues={creditExecutionDemandStageType.values}
      getShortTitle={creditExecutionDemandStageType.getShortTitle}
      activeTasksCount={activeTasksCount}
      banksCount={banksCount}
    />
  );
};
