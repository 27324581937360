import * as React from "react";
import {
  AppLayoutOverlayPanel,
  AppLayoutOverlayPanelContentWithHeader,
} from "../../../../ui/layout/app-layout-containers";
import { Typography } from "../../../../ui-kit/typography";
import { productCreationConfigs } from "../../../configs";
import { Box } from "../../../../ui-kit/containers/box";
import { declensionHelper } from "../../../../helpers/entity";

export interface ProductCreationScoringRejectsPanelProps {
  children: React.ReactNode;
  rejectsCount: number;
  onCloseClick: () => void | Promise<void>;
}

const BoxTypography = Box.withComponent(Typography);

export const ProductCreationScoringRejectsPanel = ({
  onCloseClick,
  rejectsCount,
  children,
}: ProductCreationScoringRejectsPanelProps) => {
  return (
    <AppLayoutOverlayPanel panelKey={productCreationConfigs.scoringRejectsOverlayPanelKey}>
      <AppLayoutOverlayPanelContentWithHeader
        onCloseClick={onCloseClick}
        title={
          <BoxTypography
            pl="md"
            fontColor="typography.primaryLight"
            lineHeight="defaultLineHeight"
            fontWeight={500}
            fontSize="fz4"
          >
            Отказ в {declensionHelper.commonDeclensions.bank.prepositional(rejectsCount, true)}
          </BoxTypography>
        }
      >
        <Box padding="lg">{children}</Box>
      </AppLayoutOverlayPanelContentWithHeader>
    </AppLayoutOverlayPanel>
  );
};
