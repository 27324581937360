import { combineReducers, createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { legalServicesCreationAdapter } from "./legalServicesCreation.adapter";
import { LegalServicesCreationSliceState } from "./legalServicesCreation.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { LEGAL_SERVICES_CREATION_SLICE_NAME, legalServicesCreationThunks } from "./legalServicesCreation.thunks";
import { legalServicesCreationMapper } from "./legalServicesCreation.mapper";
import { getClientSearchReducer } from "../../../client-search";
import { combineSliceNames } from "../../../common/store/utils";
import { newBankGuaranteeCreation } from "../../../bank-guarantee/creation/store/bankGuaranteeCreation.slice";

const initialState = legalServicesCreationAdapter.getInitialState<LegalServicesCreationSliceState>({
  status: LoadingStatusEnum.Idle,
});

const slice = createSlice({
  name: LEGAL_SERVICES_CREATION_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(legalServicesCreationThunks.getProducts.fulfilled, (state, action) => {
        legalServicesCreationAdapter.setAll(state, legalServicesCreationMapper.fromResponse(action.payload.body));
      })
      .addCase(legalServicesCreationThunks.getProducts.rejected, (state, action) => {
        state.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(legalServicesCreationThunks.getProducts), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const legalServicesCreationReducer = combineReducers({
  list: slice.reducer,
  clientSearch: getClientSearchReducer({
    key: "LegalServices",
    caseExtraReducers: (builder, clientSearchInitialState) => {
      builder.addCase(newBankGuaranteeCreation, () => clientSearchInitialState);
    },
    sliceName: combineSliceNames(LEGAL_SERVICES_CREATION_SLICE_NAME, "clientSearch"),
  }),
});
