import { InferType } from "yup";
import { publicUserRegistrationAgentUrlValidationSchema } from "../validation/publicUserRegistrationAgentUrlValidationSchema";
import { withValidUrlParams } from "../../../common/urls/internal/HOCs";
import { Redirect } from "../../../common/urls/internal/components";
import { PublicUserRegistrationAgent } from "../components/agent/PublicUserRegistrationAgent";

interface PageProps {
  match: InferType<typeof publicUserRegistrationAgentUrlValidationSchema>;
}

const Page = ({ match }: PageProps) => {
  return <PublicUserRegistrationAgent agencyId={match.agencyId} />;
};

export const PublicUserRegistrationAgentPage = withValidUrlParams(
  Page,
  (x) => x.publicUser.registration.agent,
  publicUserRegistrationAgentUrlValidationSchema,
  <Redirect to={(x) => x.root} />
);
