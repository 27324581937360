import * as React from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { Text } from "../../../../../common/ui-kit/typography";
import * as S from "./BankGuaranteeDetailsRequestOriginalHeaderDetailsItem.styled";

export interface BankGuaranteeDetailsRequestOriginalHeaderDetailsItemProps {
  term: React.ReactNode;
  details: React.ReactNode;
  isOnNewLine: boolean;
}

export const BankGuaranteeDetailsRequestOriginalHeaderDetailsItem = ({
  term,
  details,
  isOnNewLine,
}: BankGuaranteeDetailsRequestOriginalHeaderDetailsItemProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <S.Container isOnNewLine={isOnNewLine}>
      <Text lineHeight="1.4" fontWeight={400} fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}>
        {term}
      </Text>
      <Text
        lineHeight="1.4"
        fontWeight={500}
        fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}
        whiteSpace="break-spaces"
        wordBreak="break-word"
      >
        {details}
      </Text>
    </S.Container>
  );
};
