import { useEffect, useState } from "react";
import { AnySchema, InferType } from "yup";
import { FilteringObjectType } from "../types/FilteringObjectType";
import { FilteringItem } from "../types/FilteringItem";

const isFilteringItemsEquals = <TSchema extends FilteringObjectType>(
  a: FilteringItem<TSchema>,
  b: FilteringItem<TSchema>,
) => a.uniqKey === b.uniqKey;

enum ChangesType {
  NoChanges,
  ItemDeleted,
  ItemAdded,
  ManyItemsChanged,
}

const getChangesType = (previousValues: ArrayLike<unknown>, actualValues: ArrayLike<unknown>) => {
  const diff = actualValues.length - previousValues.length;
  switch (diff) {
    case -1:
      return ChangesType.ItemDeleted;
    case 0:
      return ChangesType.NoChanges;
    case 1:
      return ChangesType.ItemAdded;
    default:
      return ChangesType.ManyItemsChanged;
  }
};

const getFilteringItems = <TSchema extends FilteringObjectType>(
  previousValues: FilteringItem<TSchema>[],
  actualValues: FilteringItem<TSchema>[],
): FilteringItem<TSchema>[] => {
  const changesType = getChangesType(previousValues, actualValues);

  const getItemAddedResult = () => {
    const diffValue = actualValues.filter((x) => !previousValues.some((y) => isFilteringItemsEquals(x, y)));

    return [...previousValues, ...diffValue];
  };

  switch (changesType) {
    case ChangesType.ItemDeleted:
      return previousValues.filter((x) => actualValues.some((y) => isFilteringItemsEquals(x, y)));
    case ChangesType.NoChanges:
      return previousValues;
    case ChangesType.ItemAdded:
      return getItemAddedResult();
    case ChangesType.ManyItemsChanged:
      return actualValues;
    default:
      throw new Error("Не удалось определить тип изменений в фильтрах");
  }
};

export const useSearchFilteringItems = <TSchema extends AnySchema, TObject extends InferType<TSchema>>(
  validationSchema: TSchema,
  filteringObject: TObject,
  converter: (filteringObject: TObject) => FilteringItem<TObject>[],
): FilteringItem<TObject>[] => {
  const [filteringItems, setFilteringItems] = useState(converter(filteringObject));

  useEffect(() => {
    const newValues = converter(filteringObject);
    let diffArray = filteringItems.filter((x) => newValues.some((y) => isFilteringItemsEquals(x, y)));

    if (newValues.length - diffArray.length === 0) {
      diffArray = [...filteringItems];
    }

    if (newValues.length - diffArray.length !== 0) {
      setFilteringItems(getFilteringItems(diffArray, newValues));
    }
  }, [filteringObject, converter, filteringItems]);

  return filteringItems;
};
