import { memo } from "react";
import Skeleton from "react-loading-skeleton";
import { BankGuaranteeListItemMobileDemandsContainer } from "./container/BankGuaranteeListItemMobileDemandsContainer";
import { BankGuaranteeListItemMobileDemandListSkeleton } from "./BankGuaranteeListItemMobileDemandList.skeleton";

export const BankGuaranteeListItemMobileDemandsSkeleton = memo(() => {
  return (
    <BankGuaranteeListItemMobileDemandsContainer
      banksCount={<Skeleton inline width={100} />}
      activeTasksBadge={undefined}
      counters={<Skeleton inline width={90} />}
      list={<BankGuaranteeListItemMobileDemandListSkeleton demandsCount={4} />}
    />
  );
});
