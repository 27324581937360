import {
  isUserSideWebPushesSubscriptionState,
  useWebPushesSubscriptionStateContext,
  WebPushesPromptCheckboxDesktop,
} from "../../../common/web-pushes";
import { Box } from "../../../common/ui-kit/containers/box";
import * as S from "./QueueTasksNotificationsNavigationWebPushesSubscription.styled";

export interface QueueTasksNotificationsNavigationWebPushesSubscriptionProps {
  isSubscribed: boolean;
  onSwitchChange: () => Promise<void> | void;
  onHelpButtonClick: () => Promise<void> | void;
}

export const QueueTasksNotificationsNavigationWebPushesSubscription = ({
  isSubscribed,
  onSwitchChange,
  onHelpButtonClick,
}: QueueTasksNotificationsNavigationWebPushesSubscriptionProps) => {
  const subscriptionState = useWebPushesSubscriptionStateContext();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isUserSideWebPushesSubscriptionState(subscriptionState) && (
        <>
          <Box pl="lg" pr="md">
            <WebPushesPromptCheckboxDesktop
              onHelpButtonClick={onHelpButtonClick}
              isChecked={isSubscribed}
              onChange={onSwitchChange}
            />
          </Box>
          <S.Separator />
        </>
      )}
    </>
  );
};
