import { QueueTasksListFiltersProps } from "../QueueTasksListFiltersProps";
import { isLoading } from "../../../../../common/types/state";
import { DotsLoader } from "../../../../../common/ui-kit/loaders/DotsLoader";
import { QueueTasksListProductsFilter } from "./QueueTasksListProductsFilter";
import { QueueTasksListBanksFilter } from "./QueueTasksListBanksFilter";
import { QueueTasksListTaskTypesFilter } from "./QueueTasksListTaskTypesFilter";
import { useAppSelector } from "../../../../../common/store";
import { selectQueueTasksListFilters } from "../../../store/queueTasksList.selectors";
import { FiltersWrapperWithSearchInput } from "../../../../../common/filters/use-cases/components/desktop/FiltersWrapperWithSearchInput.desktop";
import {
  DateRangeFilter,
  usePaginatedQueryStateMutator,
  useProductFilterSearchInput,
} from "../../../../../common/filters/use-cases";

export const QueueTasksListFiltersDesktop = ({ filteringObject, className }: QueueTasksListFiltersProps) => {
  const mutate = usePaginatedQueryStateMutator();
  const { value, onChange } = useProductFilterSearchInput(filteringObject);
  const { status, data: filters } = useAppSelector(selectQueueTasksListFilters);

  return (
    <FiltersWrapperWithSearchInput className={className} value={value} onChange={onChange}>
      {!filters && isLoading(status) && <DotsLoader />}
      {!!filters && (
        <>
          <QueueTasksListProductsFilter filteringObject={filteringObject} />
          <QueueTasksListBanksFilter filteringObject={filteringObject} />
          <QueueTasksListTaskTypesFilter filteringObject={filteringObject} />
          <DateRangeFilter
            dateMutator={(dateCreateFrom?: Date, dateCreateTo?: Date) =>
              mutate(filteringObject, (filter, { setValues }) =>
                setValues(filter, { dateAddedFrom: dateCreateFrom, dateAddedTo: dateCreateTo })
              )
            }
            dateCreateFrom={filteringObject.dateAddedFrom}
            dateCreateTo={filteringObject.dateAddedTo}
          />
        </>
      )}
    </FiltersWrapperWithSearchInput>
  );
};
