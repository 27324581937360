import styled from "@emotion/styled";

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.xs};
  padding: ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.sm};
  user-select: none;
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  font-weight: 700;
  line-height: 1;
  font-size: ${({ theme }) => theme.font.fz2};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 16px;
`;
