import styled from "@emotion/styled";
import { ProductDetailsContentLayoutContainer } from "./ProductDetailsContentLayoutContainer";

export const ProductDetailsDemandBody = styled(ProductDetailsContentLayoutContainer.withComponent("section"))`
  padding-top: ${({ theme }) => theme.spaces.xxl};
  padding-bottom: ${({ theme }) => theme.spaces.xxl};
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
