import { useEffect, useState } from "react";
import * as React from "react";
import { usePageTitle } from "../../../common/navigation/page-title";
import { FullPageLayoutContainer } from "../../../common/ui/layout";
import { BankGuaranteeCreationCreateByGuaranteeHeader } from "../components/header/create-by-guarantee/BankGuaranteeCreationCreateByGuaranteeHeader";
import { useNavigationStateFromWithFallback } from "../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../common/urls/internal/utils";
import { useBankGuaranteeCreationCreateByGuaranteePathMatch } from "../hooks/creation/create-by-guarantee/useBankGuaranteeCreationCreateByGuaranteePathMatch";
import { BankGuaranteeId } from "../../../common/types/demand";
import { make } from "../../../common/types/utils";
import { bankGuaranteeCreationCreateByGuaranteeContext } from "../store/create-by-guarantee-context/bankGuaranteeCreationCreateByGuaranteeContext";
import { newBankGuaranteeCreation } from "../store/bankGuaranteeCreation.slice";
import { useAppDispatch } from "../../../common/store";
import { ErrorBoundary, ErrorBoundaryDefaultFallbackAlert } from "../../../common/ui/containers/error-boundary";
import { FullPageContainerMainContentWrapper } from "../../../common/ui/containers/full-page";
import { useScrollToTopOnMountOrLocationPathChange } from "../../../common/hooks/scroll";
import { ProductCreationCreateByExistsMainContentWrapper } from "../../../common/product-creation";

export interface BankGuaranteeCreationFromExistLayoutProps {
  children?: React.ReactNode;
}

export const BankGuaranteeCreationCreateByGuaranteeLayout = ({
  children,
}: BankGuaranteeCreationFromExistLayoutProps) => {
  usePageTitle("Создание заявки на банковскую гарантию");
  useScrollToTopOnMountOrLocationPathChange();

  const dispatch = useAppDispatch();

  const { from } = useNavigationStateFromWithFallback(selectAppUrl((x) => x.bankGuarantee.list.root).url, "Мои заявки");
  const [initialFrom] = useState(from);

  const { guaranteeId: guaranteeIdParam, pageType } = useBankGuaranteeCreationCreateByGuaranteePathMatch();
  const guaranteeNumber = parseInt(guaranteeIdParam ?? "", 10);

  useEffect(() => {
    return () => {
      dispatch(newBankGuaranteeCreation());
    };
  }, [dispatch]);

  // TODO: заглушка о ненайденной заявке
  if (Number.isNaN(guaranteeNumber) || guaranteeNumber < 0) {
    return <FullPageLayoutContainer>Заявка не найдена</FullPageLayoutContainer>;
  }

  const guaranteeId = make<BankGuaranteeId>()(guaranteeNumber);

  return (
    <FullPageLayoutContainer>
      <BankGuaranteeCreationCreateByGuaranteeHeader from={initialFrom} guaranteeId={guaranteeId} pageType={pageType} />
      <FullPageContainerMainContentWrapper>
        <ProductCreationCreateByExistsMainContentWrapper>
          <bankGuaranteeCreationCreateByGuaranteeContext.Provider value={guaranteeId}>
            <ErrorBoundary fallback={<ErrorBoundaryDefaultFallbackAlert />}>{children}</ErrorBoundary>
          </bankGuaranteeCreationCreateByGuaranteeContext.Provider>
        </ProductCreationCreateByExistsMainContentWrapper>
      </FullPageContainerMainContentWrapper>
    </FullPageLayoutContainer>
  );
};
