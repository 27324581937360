import { useMemo } from "react";
import { isNeedToHideProductDetailsDocuments } from "../../../../common/product-details";
import { useAppSelector } from "../../../../common/store";
import { useAppUrlMatch } from "../../../../common/urls/internal/hooks";
import { selectCreditExecutionDetailsDocumentsIds } from "../../store/documents/creditExecutionDetailsDocuments.selectors";
import { selectCreditExecutionDetailsProductDetails } from "../../store/product-details/creditExecutionDetailsProductDetails.selectors";
import { useCreditExecutionDetailsContext } from "../context/useCreditExecutionDetailsContext";

export const useCreditExecutionDetailsDocuments = () => {
  const ids = useAppSelector(selectCreditExecutionDetailsDocumentsIds);
  const { data, status } = useAppSelector(selectCreditExecutionDetailsProductDetails);
  const { creditExecutionId } = useCreditExecutionDetailsContext();
  const { isExact, path } = useAppUrlMatch((x) => x.creditExecution.details.documents, {
    creditExecutionId,
  });

  return useMemo(() => {
    const isNeedToHide = isNeedToHideProductDetailsDocuments(ids, data, status, isExact);

    return {
      ids,
      path,
      isExact,
      data,
      isNeedToHide,
      status,
    };
  }, [data, ids, isExact, path, status]);
};
