import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { BankGuaranteeCalculator } from "../components/BankGuaranteeCalculator";
import { usePageTitle } from "../../../common/navigation/page-title";

export const BankGuaranteeCalculatorPage = () => {
  usePageTitle("Калькулятор");

  return (
    <AppLayoutResponsiveListPage backgroundColor="light">
      <AppLayoutResponsiveListPage.Header>
        <AppLayoutResponsiveListPage.Title>Калькулятор</AppLayoutResponsiveListPage.Title>
      </AppLayoutResponsiveListPage.Header>
      <AppLayoutResponsiveListPage.List>
        <BankGuaranteeCalculator />
      </AppLayoutResponsiveListPage.List>
    </AppLayoutResponsiveListPage>
  );
};
