import { isAnyOf } from "@reduxjs/toolkit";
import {
  bankGuaranteeDetailsActiveDemandChanged,
  bankGuaranteeDetailsActiveDemandOriginalRequested,
  bankGuaranteeDetailsActiveDemandReissued,
  bankGuaranteeDetailsActiveDemandRejected,
  bankGuaranteeDetailsGuaranteeChanged,
  bankGuaranteeDetailsGuaranteeRejected,
  bankGuaranteeDetailsReloadActiveDemand,
  bankGuaranteeDetailsReloadActiveGuarantee,
} from "./bankGuaranteeDetails.actions";
import { queueTaskClosedDemandQueueTasks, uploadDocumentDemandQueueTasks } from "../../../demand/queue-tasks";

export const isBankGuaranteeDetailsNeedReloadMatcher = isAnyOf(
  bankGuaranteeDetailsGuaranteeRejected,
  bankGuaranteeDetailsActiveDemandRejected,
  bankGuaranteeDetailsActiveDemandReissued,
  bankGuaranteeDetailsActiveDemandOriginalRequested,
  bankGuaranteeDetailsActiveDemandChanged,
  uploadDocumentDemandQueueTasks.fulfilled,
  queueTaskClosedDemandQueueTasks,
  bankGuaranteeDetailsReloadActiveGuarantee,
  bankGuaranteeDetailsReloadActiveDemand
);

export const isBankGuaranteeDetailsGuaranteeChangedMatcher = isAnyOf(
  bankGuaranteeDetailsActiveDemandChanged,
  bankGuaranteeDetailsGuaranteeChanged
);
