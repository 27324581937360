import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { Typography } from "../../../../../common/ui-kit/typography";
import * as S from "./PublicUserRegistrationCommonFormNote.styled";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { publicUserRegistrationConfig } from "../../../configs/publicUserRegistrationConfig";

export type PublicUserRegistrationCommonFormNoteProps = BaseCssProps;

export const PublicUserRegistrationCommonFormNote = ({ className }: PublicUserRegistrationCommonFormNoteProps) => {
  return (
    <S.Note className={className}>
      <Typography as="p">
        Если забыли пароль — воспользуйтесь формой{" "}
        <S.AppLink to={(x) => x.publicUser.resetPassword.forgotPassword}> восстановления пароля.</S.AppLink>
      </Typography>
      <Typography as="p">Если возникли какие-то трудности — свяжитесь с нами по телефону: </Typography>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {publicUserRegistrationConfig.phoneNumbers.map((x) => (
          <S.PhoneLink key={x.raw} href={`tel:${x.raw}`}>
            {x.formatted}
          </S.PhoneLink>
        ))}
      </Box>
    </S.Note>
  );
};
