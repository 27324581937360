import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { BankGuaranteeId, DemandId } from "../../../../../../../common/types/demand";
import * as S from "./BankGuaranteeListItemMobileDemand.styled";
import { useAppSelector } from "../../../../../../../common/store";
import { selectBankGuaranteeListItemsDemandById } from "../../../../../store/items/bankGuaranteeListItems.selectors";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import { StackPanel } from "../../../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeListItemBankLabel } from "../../bank-label/BankGuaranteeListItemBankLabel";
import { useBankGuaranteeListItemDemandLink } from "../../../../../hooks/list/useBankGuaranteeListItemDemandLink";
import { CircleIndicator } from "../../../../../../../common/ui-kit/notifications/circle-indicator";
import { BankGuaranteeDemandStageIcon } from "../../../../../../common/components";

export interface BankGuaranteeListItemMobileDemandProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  from?: NavigationState;
}

export const BankGuaranteeListItemMobileDemand = ({
  className,
  demandId,
  guaranteeId,
  from,
}: BankGuaranteeListItemMobileDemandProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectBankGuaranteeListItemsDemandById(state, demandId))!;
  const linkProps = useBankGuaranteeListItemDemandLink(guaranteeId, demandId);

  return (
    <S.Li className={className}>
      <S.Container {...linkProps} state={from} isWithRequest={demand.countActiveTasks > 0}>
        <StackPanel direction="row" alignItems="center" gap="xs">
          <BankGuaranteeDemandStageIcon stage={demand.stage.code} />
          <BankGuaranteeListItemBankLabel stage={demand.stage.code}>{demand.bank.title}</BankGuaranteeListItemBankLabel>
          {demand.isUpdated && <CircleIndicator color={(theme) => theme.colors.primary} size={4} />}
        </StackPanel>
        <S.StatusText isWithRequest={demand.countActiveTasks > 0}>{demand.status.title}</S.StatusText>
      </S.Container>
    </S.Li>
  );
};
