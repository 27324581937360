import { ReactNode } from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { AlertTriangleBigIcon } from "../../../../../common/ui-kit/icons";
import { AppLinkButton } from "../../../../../common/ui/links";
import { LegalServicesCreationCard } from "./LegalServicesCreationCard";

export interface LegalServicesCreationErrorCardProps extends BaseCssProps {
  error: ReactNode;
}

export const LegalServicesCreationErrorCard = ({ error, className }: LegalServicesCreationErrorCardProps) => {
  return (
    <LegalServicesCreationCard
      icon={<AlertTriangleBigIcon />}
      title="Что то пошло не так"
      description={error}
      className={className}
    >
      <AppLinkButton to={(x) => x.legalServices.creation.list}>Вернуться к списку услуг</AppLinkButton>
    </LegalServicesCreationCard>
  );
};
