import { BaseCssProps } from "../../../common/ui-kit/types";
import * as S from "./ClientCheckReport.styled";
import { ClientCheckReportType } from "../types/ClientCheckReportType";

export interface ClientCheckReportProps extends BaseCssProps {
  content: string;
  reportType: ClientCheckReportType;
}

export const ClientCheckReport = ({ content, className, reportType }: ClientCheckReportProps) => {
  return (
    <S.Container
      isTableContent={reportType === "contracts"}
      className={className}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};
