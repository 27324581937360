import styled from "@emotion/styled";

export const DocumentUploadCommonBankDocumentCardSeparator = styled.hr`
  height: 1px;
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  padding: 0;
  margin: 0;
`;
