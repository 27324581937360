import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { DemandId } from "../../../../common/types/demand";
import { BankGuaranteeRequestOriginalCreationModal } from "./desktop/BankGuaranteeRequestOriginalCreationModal";
import { BankGuaranteeRequestOriginalCreationOverlayPanel } from "./mobile/BankGuaranteeRequestOriginalCreationOverlayPanel";

interface BankGuaranteeRequestOriginalCreationResponsiveViewProps {
  demandId: DemandId;
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

export const BankGuaranteeRequestOriginalCreationResponsiveView = ({
  demandId,
  isOpen,
  onSuccess,
  onClose,
}: BankGuaranteeRequestOriginalCreationResponsiveViewProps) => {
  return (
    <ThemeMobileDesktopView
      desktop={
        <BankGuaranteeRequestOriginalCreationModal
          demandId={demandId}
          onSuccess={onSuccess}
          onClose={onClose}
          isOpen={isOpen}
        />
      }
      mobile={
        <BankGuaranteeRequestOriginalCreationOverlayPanel demandId={demandId} onSuccess={onSuccess} onClose={onClose} />
      }
    />
  );
};
