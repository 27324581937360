import cn from "classnames";
import { Field, useFormikContext } from "formik";
import PriceHelper from "../../../../../essentials/helpers/PriceHelper";
import styles from "./ClientFormInput.module.css";
import { ReactNode } from "react";

export interface ClientFormInputProps {
  name: string;
  label: string;
  disabled?: boolean;
  maxlength?: number;
  number?: boolean;
  passportCode?: boolean;
  price?: boolean;
  errors?: any;
  touched?: any;
  length?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  valueAdapter?: (value: string) => string;
  className?: string;
  type?: string;
  autoComplete?: string;
  children?: ReactNode;
}

const ClientFormInput: React.FC<ClientFormInputProps> = ({
  name,
  label,
  disabled,
  number,
  passportCode,
  price,
  errors,
  touched,
  maxlength,
  onKeyDown,
  onChange,
  valueAdapter,
  className,
  type,
  autoComplete,
  children,
}) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    if (valueAdapter) {
      setFieldValue(name, valueAdapter(value));
      return;
    }
    if (price) {
      setFieldValue(name, PriceHelper.formatLocale(value));
    } else if (number) {
      setFieldValue(name, value.replace(/\D+/g, ""));
    } else if (passportCode) {
      let formatString = value.replace(/\D+/g, "");
      setFieldValue(
        name,
        formatString.length > 3
          ? formatString
              .split("")
              .map((x, i) => (i === 3 ? `-${x}` : x))
              .join("")
          : formatString
      );
    } else {
      setFieldValue(name, value);
    }
  };

  return (
    <div className={styles["wrapper"]}>
      <label
        className={cn({
          [styles["label"]]: true,
          [styles["label-disabled"]]: disabled,
          [styles["label-error"]]: touched && errors,
        })}
      >
        {label}
      </label>
      <Field
        name={name}
        type={type ?? "text"}
        disabled={disabled}
        maxLength={maxlength}
        autoComplete={autoComplete ?? "off"}
        className={cn(className, {
          [styles["input"]]: true,
          [styles["input-disabled"]]: disabled,
          [styles["input-error"]]: touched && errors,
        })}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(e);
          onChange && onChange(e);
        }}
        onKeyDown={onKeyDown}
      />
      {children}
      {touched && errors && <p className={styles["error"]}>{errors}</p>}
    </div>
  );
};

export default ClientFormInput;
