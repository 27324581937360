import { useResponsiveContext } from "@sideg/ui-kit";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { FormSelectDesktop, FormSelectDesktopProps } from "./FormSelect.desktop";
import { FormSelectMobile } from "./FormSelect.mobile";

export type FormSelectResponsiveProps<TItem extends string> = FormSelectDesktopProps<TItem>;

/**
 * @deprecated Use from @sideg/ui-kit
 */
export const FormSelectResponsive = <TItem extends string>({
  getItemTitle,
  selectedItem,
  id,
  error,
  name,
  className,
  items,
  label,
  onChange,
  onBlur,
  isItemDisabled,
  disabled,
}: FormSelectResponsiveProps<TItem>) => {
  const isDesktop = useThemeIsDesktop();
  const { isProbablyMobile } = useResponsiveContext();

  if (isProbablyMobile || !isDesktop) {
    return (
      <FormSelectMobile
        error={error}
        label={label}
        className={className}
        id={id}
        name={name}
        value={selectedItem}
        onChange={(e) => {
          onChange(e.target.value as TItem);
        }}
        onBlur={onBlur}
        disabled={disabled}
      >
        {items.map((x) => (
          <option value={x} key={x} disabled={isItemDisabled?.(x)}>
            {getItemTitle(x)}
          </option>
        ))}
      </FormSelectMobile>
    );
  }

  return (
    <FormSelectDesktop
      items={items}
      selectedItem={selectedItem}
      getItemTitle={getItemTitle}
      onChange={onChange}
      label={label}
      className={className}
      name={name}
      id={id}
      error={error}
      onBlur={onBlur}
      isItemDisabled={isItemDisabled}
      disabled={disabled}
    />
  );
};
