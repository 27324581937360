import { Form, Formik } from "formik";
import { Box } from "../../../../common/ui-kit/containers/box";
import { factoringCreationValidationSchema } from "../../validation/factoringCreationValidationSchema";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { FactoringCreationFromContent } from "./FactoringCreationFromContent";
import { FactoringCreationFormValues } from "../../types/FactoringCreationFormValues";
import { useSubmitFactoringCreationForm } from "../../hooks/useSubmitFactoringCreationForm";

export interface FactoringCreationFormProps extends BaseCssProps {}

export const FactoringCreationForm = ({ className }: FactoringCreationFormProps) => {
  const onSubmit = useSubmitFactoringCreationForm();

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={factoringCreationValidationSchema.getDefault() as FactoringCreationFormValues}
      validationSchema={factoringCreationValidationSchema}
    >
      {() => (
        <Box as={Form} display="flex" flexDirection="column" flexShrink={1} flexGrow={1} className={className}>
          <FactoringCreationFromContent />
        </Box>
      )}
    </Formik>
  );
};
