import { forwardRef } from "react";
import { BankGuaranteeDetailsDemandCompletedTaskCommonProps } from "../../../types/demand/tasks/BankGuaranteeDetailsDemandTaskCommonProps";
import { DemandQueueTaskCompletedCard } from "../../../../../demand/queue-tasks";
import { getBankGuaranteeDetailsTaskTitle } from "../../../utils/demand/tasks/getBankGuaranteeDetailsTaskTitle";

export const BankGuaranteeDetailsCompletedTaskCard = forwardRef<
  HTMLDivElement,
  BankGuaranteeDetailsDemandCompletedTaskCommonProps
>(({ task, isFocused, className }, ref) => {
  return (
    <DemandQueueTaskCompletedCard
      className={className}
      title={getBankGuaranteeDetailsTaskTitle(task.title, task.groupType)}
      managerComment={task.managerComment}
      agentComment={task.agentComment}
      ref={ref}
      isFocused={isFocused}
    />
  );
});
