import styled from "@emotion/styled";
import { AppPublicLayoutLinkButton } from "../button/AppPublicLayoutLinkButton";

export const AppPublicLayoutBlockWrapper = styled.div`
  width: 100%;
  max-width: min(100%, 480px);
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.layout.publicPage.cardPaddingBase.mobile}rem;

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    gap: ${({ theme }) => theme.layout.publicPage.cardPaddingBase.desktop}rem;

    & > ${AppPublicLayoutLinkButton} {
      width: max-content;
      margin: 0 auto;
    }
  }
`;
