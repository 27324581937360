import { dateTimeHelper } from "@sideg/helpers";
import { useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsFiltersData } from "../../store/filters/creditWorkingAssetsListFilters.selectors";
import {
  FilteringItemsConverterDictionary,
  getFilteringValueTextByEntitiesCodeWithTitle,
  useFilteringItemsConverterDictionary,
} from "../../../../common/filters";
import { CreditWorkingAssetsListFilterContextValue } from "../../types/filters/CreditWorkingAssetsListFilterContextValue";

export const useCreditWorkingAssetsListFilteringItemsConverter = () => {
  const data = useAppSelector(selectCreditWorkingAssetsFiltersData);

  const dictionary: FilteringItemsConverterDictionary<CreditWorkingAssetsListFilterContextValue> = {
    page: null,
    tab: null,
    search: null,
    clientNameOrTaxpayerNumber: null,
    productId: null,
    demandIdOrProductId: null,
    bankIds: getFilteringValueTextByEntitiesCodeWithTitle(data?.bankIds),
    status: (value, context) =>
      value === undefined ? null : getFilteringValueTextByEntitiesCodeWithTitle(data?.statuses)(value, context),
    taskTypes: getFilteringValueTextByEntitiesCodeWithTitle(data?.taskTypes),
    dateCreateFrom: (value) => (value === undefined ? null : `с ${dateTimeHelper.format(value, "dayFullMonthYear")}`),
    dateCreateTo: (value) => (value === undefined ? null : `по ${dateTimeHelper.format(value, "dayFullMonthYear")}`),
  };

  return useFilteringItemsConverterDictionary(dictionary);
};
