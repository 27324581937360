import { BankGuaranteeDetailsDemandStatusType } from "../../types/demand/BankGuaranteeDetailsDemandStatusType";
import { BankGuaranteeDetailsDemand } from "../../types/demand/BankGuaranteeDetailsDemand";

export const useBankGuaranteeDetailsDemandStatusType = (
  demand: BankGuaranteeDetailsDemand | undefined
): BankGuaranteeDetailsDemandStatusType | undefined => {
  if (!demand) {
    return undefined;
  }

  if (demand.stage.code === "AttachingDocuments") {
    return "attachDocuments";
  }

  if (!demand.isActive) {
    return "rejected";
  }

  if (demand.countActiveTasks > 0) {
    return "active";
  }

  return "inProcess";
};
