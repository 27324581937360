import { RootState } from "../../../common/store";
import { LegalServicesCreationState } from "./legalServicesCreation.types";
import { legalServicesCreationAdapter } from "./legalServicesCreation.adapter";
import { ClientSearchState } from "../../../client-search";

export const selectLegalServicesCreation = (state: RootState): LegalServicesCreationState =>
  state.domains.legalServices.creation.list;

export const selectLegalServicesCreationClientSearch = (state: RootState): ClientSearchState =>
  state.domains.legalServices.creation.clientSearch;

export const { selectIds: selectLegalServicesCreationIds, selectById: selectLegalServicesCreationById } =
  legalServicesCreationAdapter.getSelectors(selectLegalServicesCreation);
