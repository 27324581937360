import { memo } from "react";
import { BaseFile } from "../../../../../../../common/types/files";
import { BankGuaranteeDetailsDemandReconciliationContent } from "../../content/BankGuaranteeDetailsDemandReconciliationContent";
import {
  BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus,
  isBankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatusWithTitle,
} from "../../../../../complicated-tasks/bill/types/BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus";
import { BankGuaranteeDetailsDemandReconciliationConfirmPaymentResponsiveView } from "./BankGuaranteeDetailsDemandReconciliationConfirmPaymentResponsiveView";
import { FileDownloadLabel } from "../../../../../../../files/download";
import { BankGuaranteeDetailsDemandReconciliationConfirmPaymentStatusBadge } from "./BankGuaranteeDetailsDemandReconciliationConfirmPaymentStatusBadge";

export interface BankGuaranteeDetailsDemandReconciliationConfirmPaymentProps {
  confirmationPaymentFile?: BaseFile;
  confirmationPaymentStatus?: BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus;
}

export const BankGuaranteeDetailsDemandReconciliationConfirmPayment = memo(
  ({
    confirmationPaymentStatus,
    confirmationPaymentFile,
  }: BankGuaranteeDetailsDemandReconciliationConfirmPaymentProps) => {
    return (
      <BankGuaranteeDetailsDemandReconciliationContent.Block alignItems="flex-start">
        {confirmationPaymentStatus !==
          BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.ConfirmationRejected &&
          confirmationPaymentFile && (
            <>
              <BankGuaranteeDetailsDemandReconciliationContent.Typography
                fontColor="typography.primary"
                fontWeight={500}
              >
                Платежное поручение
              </BankGuaranteeDetailsDemandReconciliationContent.Typography>
              <FileDownloadLabel
                fileId={confirmationPaymentFile.fileId}
                privateKey={confirmationPaymentFile.privateKey}
                fileName={confirmationPaymentFile.fileName}
              />
            </>
          )}
        {confirmationPaymentStatus ===
          BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.AwaitingConfirmation && (
          <BankGuaranteeDetailsDemandReconciliationConfirmPaymentResponsiveView />
        )}
        {isBankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatusWithTitle(confirmationPaymentStatus) && (
          <BankGuaranteeDetailsDemandReconciliationConfirmPaymentStatusBadge status={confirmationPaymentStatus} />
        )}
      </BankGuaranteeDetailsDemandReconciliationContent.Block>
    );
  },
);
