import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { MapToResponsiveStyleValue, PickCssObjectProps, ResponsiveStyleValue } from "@sideg/ui-kit/common/types";
import { ThemePaletteColors } from "@sideg/ui-kit/core/theme/types/colors/ThemePalette";
import { makeResponsiveStylesBuilder } from "@sideg/ui-kit/common/utils";
import { getValueByPath } from "@sideg/ui-kit/core/theme/utils/getValueByPath";
import { CSSObject } from "@emotion/react";

export interface IconProps
  extends MapToResponsiveStyleValue<PickCssObjectProps<"width" | "height" | "flexShrink" | "flexGrow">> {
  fill?: ResponsiveStyleValue<ThemePaletteColors>;
  stroke?: ResponsiveStyleValue<ThemePaletteColors>;
  title?: string;
}

const responsive = makeResponsiveStylesBuilder<IconProps>();

export const Icon = styled("svg", { shouldForwardProp: isPropValid })<IconProps>(
  responsive("fill", (fill, theme) =>
    fill ? { "& > path": { fill: getValueByPath(theme.palette, fill) as CSSObject["fill"] } } : {},
  ),
  responsive("stroke", (stroke, theme) =>
    stroke ? { "& path": { stroke: getValueByPath(theme.palette, stroke) } } : {},
  ),
  responsive("width"),
  responsive("height"),
  responsive("flexShrink"),
  responsive("flexGrow"),
);
