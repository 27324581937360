import styled from "@emotion/styled";
import { FilterExpandButton } from "../../../../filters/inputs/buttons/FilterExpandButton";
import { StyledContainerWithCustomScroll } from "../../../../containers/components/StyledContainerWithCustomScroll";
import { FormSearchInput } from "../../../search-input";
import { SelectPopupContainer } from "../SelectPopupContainer";

export const Wrapper = styled.div`
  position: relative;
`;

export const Container = styled(SelectPopupContainer)`
  display: ${({ open }) => (open ? "flex" : "none")};
  padding: 0;
`;

export const Button = styled(FilterExpandButton)`
  width: 100%;
`;

export const SearchContainer = styled.div`
  position: sticky;
  top: 0;
  padding-bottom: ${({ theme }) => theme.spaces.xs};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  padding: ${({ theme }) => theme.spaces.xs};
`;

export const Input = styled(FormSearchInput)`
  // TODO разные размеры для FormSearchInput
  & input {
    font-size: ${({ theme }) => theme.font.fz2};
    line-height: ${({ theme }) => theme.font.fz2};
  }
`;

export const Ul = styled(StyledContainerWithCustomScroll.withComponent("ul"))`
  height: 100%;
  overflow: auto;
  margin-top: ${({ theme }) => theme.spaces.xs};
  padding: ${({ theme }) => theme.spaces.xs};
`;
