import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { BANK_GUARANTEE_LIST_SLICE_NAME } from "../bankGuaranteeList.thunks";
import { BankGuaranteeListItemModificationState } from "./bankGuaranteeListItemModification.types";
import { BankGuaranteeId, DemandId } from "../../../../common/types/demand";

const SLICE_NAME = combineSliceNames(BANK_GUARANTEE_LIST_SLICE_NAME, "itemModification");

const initialState: BankGuaranteeListItemModificationState = {};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    cancelModification: () => initialState,
    onRejectGuarantee: (state, { payload: { guaranteeId } }: PayloadAction<{ guaranteeId: BankGuaranteeId }>) => {
      return {
        guaranteeId,
        type: "reject-guarantee",
      };
    },
    onRejectDemand: (
      state,
      { payload: { guaranteeId, demandId } }: PayloadAction<{ guaranteeId: BankGuaranteeId; demandId: DemandId }>,
    ) => {
      return {
        guaranteeId,
        demandId,
        type: "reject-demand",
      };
    },
    onReissue: (
      state,
      { payload: { guaranteeId, demandId } }: PayloadAction<{ guaranteeId: BankGuaranteeId; demandId: DemandId }>,
    ) => {
      return {
        guaranteeId,
        demandId,
        type: "reissue-guarantee",
      };
    },
    onRequestOriginal: (
      state,
      { payload: { guaranteeId, demandId } }: PayloadAction<{ guaranteeId: BankGuaranteeId; demandId: DemandId }>,
    ) => {
      return {
        guaranteeId,
        demandId,
        type: "request-original-guarantee",
      };
    },
  },
});

export const {
  cancelModification: cancelModificationBankGuaranteeList,
  onRejectGuarantee: onRejectGuaranteeBankGuaranteeList,
  onRejectDemand: onRejectDemandBankGuaranteeList,
  onReissue: onReissueBankGuaranteeList,
  onRequestOriginal: onRequestOriginalBankGuaranteeList,
} = slice.actions;

export const bankGuaranteeListItemModificationReducer = slice.reducer;
