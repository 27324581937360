import { DemandId } from "../../../../../common/types/demand";
import { BankGuaranteeRequestOriginalCreationForm } from "../form/BankGuaranteeRequestOriginalCreationForm";
import { Modal } from "../../../../../common/ui/containers/modal";

interface BankGuaranteeRequestOriginalCreationModalProps {
  demandId: DemandId;
  onSuccess: () => void;
  onClose: () => void;
  isOpen: boolean;
}

export const BankGuaranteeRequestOriginalCreationModal = ({
  demandId,
  onSuccess,
  onClose,
  isOpen,
}: BankGuaranteeRequestOriginalCreationModalProps) => {
  return (
    <Modal
      onClose={onClose}
      isShown={isOpen}
      title="Запрос оригинала"
      background={(x) => x.palette.background.contrastLight}
      width="100%"
      noCloseOnClickOutside
    >
      <BankGuaranteeRequestOriginalCreationForm demandId={demandId} onSuccess={onSuccess} />
    </Modal>
  );
};
