import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import * as S from "./UserDetailsUserSidebarBlock.mobile.styled";
import { AppLink } from "../../../../common/urls/internal/components";
import { UserDetailsUserAvatar } from "../user-avatar/UserDetailsUserAvatar";
import { CancelIcon, DoneIcon, LogoutIcon } from "../../../../common/ui-kit/icons";
import { useUserDetails } from "../../hooks/useUserDetails";
import { AsideBlockMobile } from "../../../../common/ui/layout/sidebar";
import { Box } from "../../../../common/ui-kit/containers/box";
import {
  isUserSideWebPushesSubscriptionState,
  useWebPushesSubscriptionStateContext,
} from "../../../../common/web-pushes";

export const UserDetailsUserSidebarBlockMobile = () => {
  const subscriptionState = useWebPushesSubscriptionStateContext();
  const { user } = useUserDetails();

  return (
    <AsideBlockMobile>
      <Box as="ul" display="flex" flexDirection="column" gap="xl">
        <S.Li>
          <AppLink to={(x) => x.user.profile.details}>
            <StackPanel alignItems="center" gap="md" direction="row">
              <UserDetailsUserAvatar fontSize={14} userName={user?.userName} />
              <span>Профиль</span>
            </StackPanel>
          </AppLink>
        </S.Li>
        <S.Li>
          <AppLink to={(x) => x.user.changePassword.root}>Изменить пароль</AppLink>
        </S.Li>
        {isUserSideWebPushesSubscriptionState(subscriptionState) && (
          <S.Li>
            <AppLink to={(x) => x.user.webPushes.root}>
              <Box display="flex" gap="md" alignItems="center" justifyContent="space-between" flexDirection="row">
                <span>Уведомления в браузере</span>
                <S.SubscriptionIcon state={subscriptionState}>
                  {subscriptionState === "available" ? <CancelIcon /> : <DoneIcon />}
                </S.SubscriptionIcon>
              </Box>
            </AppLink>
          </S.Li>
        )}
        <S.Li>
          <AppLink to={(x) => x.publicUser.authentication.logout}>
            <Box display="flex" gap="md" alignItems="center" justifyContent="space-between" flexDirection="row">
              <span>Выйти</span>
              <LogoutIcon />
            </Box>
          </AppLink>
        </S.Li>
      </Box>
    </AsideBlockMobile>
  );
};
