import { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import {
  bankGuaranteeDetailsActiveDemandChanged,
  bankGuaranteeDetailsGuaranteeChanged,
} from "../../store/bankGuaranteeDetails.actions";
import { selectBankGuaranteeDetailsActiveDemand } from "../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { selectAppUrl } from "../../../../common/urls/internal/utils";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { selectBankGuaranteeTransfer } from "../../store/transfer/bankGuaranteeDetailsTransfer.selectors";
import { clearBankGuaranteeTransfer } from "../../store/transfer/bankGuaranteeDetailsTransfer.slice";
import { BankGuaranteeDetailsLayoutContext } from "../../types/layout/BankGuaranteeDetailsLayoutContext";

export const useBankGuaranteeDetailsMatchListener = (match: BankGuaranteeDetailsLayoutContext) => {
  const dispatch = useAppDispatch();
  const guaranteeDetails = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);
  const transfer = useAppSelector(selectBankGuaranteeTransfer);
  const { activeDemandId } = useAppSelector(selectBankGuaranteeDetailsActiveDemand);

  const location = useLocation();

  useEffect(() => {
    if (guaranteeDetails?.data?.guaranteeId !== match?.guaranteeId) {
      dispatch(bankGuaranteeDetailsGuaranteeChanged(transfer?.data));
      dispatch(clearBankGuaranteeTransfer());
    }
  }, [guaranteeDetails?.data?.guaranteeId, dispatch, match?.guaranteeId, transfer?.data]);

  useEffect(() => {
    return () => {
      dispatch(bankGuaranteeDetailsGuaranteeChanged());
    };
  }, [dispatch]);

  useEffect(() => {
    if (match?.demandId && activeDemandId !== match.demandId) {
      dispatch(bankGuaranteeDetailsActiveDemandChanged(match.demandId));
    }
  }, [activeDemandId, match?.demandId, dispatch]);

  useEffect(() => {
    if (matchPath(selectAppUrl((x) => x.bankGuarantee.details.documents).url, location.pathname)) {
      dispatch(bankGuaranteeDetailsActiveDemandChanged(undefined));
    }
  }, [dispatch, location.pathname]);
};
