import * as React from "react";
import { getEmptyArray } from "@sideg/helpers";
import { isLoading, LoadingStatusEnum } from "../../../types/state";
import { DemandQueueTasksActiveCardSkeleton } from "../../../../demand/queue-tasks";
import { StackPanel } from "../../../ui-kit/containers/stack-panel";
import { BaseCssProps } from "../../../ui-kit/types";

export interface ProductDetailsDemandActiveTasksSkeletonWrapperProps extends BaseCssProps {
  demandTasksCount: number | undefined;
  fetchedTasksCount: number | undefined;
  status: LoadingStatusEnum;
  children: React.ReactNode;
}

export const ProductDetailsDemandActiveTasksSkeletonWrapper = ({
  children,
  status,
  demandTasksCount,
  fetchedTasksCount,
  className,
}: ProductDetailsDemandActiveTasksSkeletonWrapperProps) => {
  const isEmptyLoading =
    demandTasksCount !== undefined && demandTasksCount > 0 && isLoading(status) && fetchedTasksCount === 0;

  const isLoaded = fetchedTasksCount !== undefined && fetchedTasksCount > 0;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(isEmptyLoading || isLoaded) && (
        <StackPanel gap="md" direction="column" className={className}>
          {isEmptyLoading &&
            getEmptyArray(demandTasksCount ?? 0).map((x) => <DemandQueueTasksActiveCardSkeleton key={x} />)}
          {isLoaded && children}
        </StackPanel>
      )}
    </>
  );
};
