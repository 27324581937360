import styled from "@emotion/styled";
import { WidgetsNewsSliderDotButtons } from "./slider-button/WidgetsNewsSliderDotButtons";

export const Section = styled.section`
  min-height: 180px;
  background: ${({ theme }) => theme.layout.sidebar.newsWidget.background};
  margin: ${({ theme }) => `0 ${theme.layout.borderPaddingNumber - 10}px`};
  border-radius: 4px;
  position: relative;
`;

export const Slider = styled(WidgetsNewsSliderDotButtons)`
  position: absolute;
  right: 10px;
  bottom: 15px;
`;
