import { useMemo } from "react";
import { useAppSelector } from "../../../../common/store";
import { AgencyId } from "../../../../common/types/agencies";
import { selectPublicUserRegistrationAgent } from "../../store/agent/publicUserRegistrationAgent.selectors";
import { publicUserRegistrationFormValidationSchemaGetDefault } from "../../validation/publicUserRegistrationFormValidationSchema";
import { usePublicUserRegistrationFormInitialState } from "../usePublicUserRegistrationFormInitialState";
import { PublicUserRegistrationFormInitialState } from "../../types/PublicUserRegistrationFormInitialState";

export const usePublicUserRegistrationRegisterAgentFormInitial = (
  agencyId: AgencyId | undefined,
): PublicUserRegistrationFormInitialState => {
  const initialStateFromQuery = usePublicUserRegistrationFormInitialState("Agent");
  const { data } = useAppSelector(selectPublicUserRegistrationAgent);

  return useMemo(() => {
    if (data?.agencyId !== undefined && agencyId !== undefined && data.agencyId === agencyId) {
      const initialValues = publicUserRegistrationFormValidationSchemaGetDefault("Agent", data.formValues);

      return { initialValues, initialTouched: undefined, isNeedToValidateOnMount: false };
    }

    return initialStateFromQuery;
  }, [agencyId, data?.agencyId, data?.formValues, initialStateFromQuery]);
};
