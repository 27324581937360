import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { useBankGuaranteeCreationContractFinalSumField } from "../../../hooks/creation/fields/useBankGuaranteeCreationContractFinalSumField";

export interface BankGuaranteeCreationContractFinalSumFieldProps {
  disabled?: boolean;
}

export const BankGuaranteeCreationContractFinalSumField = ({
  disabled,
}: BankGuaranteeCreationContractFinalSumFieldProps) => {
  const isExists = useBankGuaranteeCreationContractFinalSumField();
  const [{ name, value, onChange, onBlur, error }] = useBankGuaranteeCreationField("contractFinalSum");

  return (
    <SimpleOpacityTransition timeout={300} in={isExists} unmountOnExit mountOnEnter>
      <FormInputPrice
        autoComplete="off"
        label="Итоговая цена контракта, ₽"
        size="medium"
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        disabled={disabled}
      />
    </SimpleOpacityTransition>
  );
};
