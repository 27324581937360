import styled from "@emotion/styled";
import { makeResponsiveStyles, ResponsiveStyleValue } from "@sideg/ui-kit";
import { css } from "@emotion/react";
import { SpaceType } from "../../types";

type MarginDirection = "t" | "r" | "b" | "l";
export type LineSeparatorProps = { [K in `m${MarginDirection}`]?: ResponsiveStyleValue<SpaceType> };

export const LineSeparator = styled.hr<LineSeparatorProps>(
  ({ theme }) => css`
    display: block;
    height: 1px;
    background-color: ${theme.palette.control.secondaryMedium};
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
  `,
  ({ theme, mt }) =>
    makeResponsiveStyles(theme, mt, (value) => (value === undefined ? {} : { marginTop: theme.spaces[value] })),
  ({ theme, mr }) =>
    makeResponsiveStyles(theme, mr, (value) => (value === undefined ? {} : { marginTop: theme.spaces[value] })),
  ({ theme, mb }) =>
    makeResponsiveStyles(theme, mb, (value) => (value === undefined ? {} : { marginTop: theme.spaces[value] })),
  ({ theme, ml }) =>
    makeResponsiveStyles(theme, ml, (value) => (value === undefined ? {} : { marginTop: theme.spaces[value] })),
);
