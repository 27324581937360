import { ReactNode } from "react";
import { ProductListItemColumnTitle } from "../../../../common/ui/product/list/product-list-item";
import { QueueTasksListItemProductBase } from "../../../../common/types/queue-tasks";
import { DemandId, GroupProductName, ProductId } from "../../../../common/types/demand";
import { ProductListItemDemandDateBank } from "../../../../common/ui/product/list/product-list-item/ProductListItemDemandDateBank";
import { QueueTasksListItemClientTooltip } from "./QueueTasksListItemClientTooltip";
import { Box } from "../../../../common/ui-kit/containers/box";

interface QueueTasksListItemProductColumnProps extends QueueTasksListItemProductBase {
  id: DemandId | ProductId | GroupProductName;
  dateAdded: string;
  title: string;
  children?: ReactNode;
}

export const QueueTasksListItemProductColumn = ({
  bankName,
  dateAdded,
  clientName,
  clientTaxpayerNumber,
  title,
  id,
  children,
}: QueueTasksListItemProductColumnProps) => {
  return (
    <Box display="inline-flex" flexDirection="column" alignItems="flex-start" overflow="hidden">
      <ProductListItemColumnTitle>{title}</ProductListItemColumnTitle>
      <QueueTasksListItemClientTooltip name={clientName} taxpayerNumber={clientTaxpayerNumber} />
      <ProductListItemDemandDateBank id={id} dateTime={dateAdded} bankName={bankName} />
      {children}
    </Box>
  );
};
