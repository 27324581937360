import * as React from "react";
import { Modal } from "../Modal";

interface IMessageModalProps {
  title: string;
  message: string;
}

const MessageModal: React.FC<IMessageModalProps> = (props) => {
  return <Modal title={props.title} message={props.message} />;
};

export default MessageModal;
