import Skeleton from "react-loading-skeleton";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { LegalServicesCreationCard } from "../../card/LegalServicesCreationCard";

export const LegalServicesCreationSkeletonItem = () => {
  return (
    <ThemeMobileDesktopView
      mobile={
        <LegalServicesCreationCard
          title={<Skeleton count={1.8} height={30} containerTestId="title" />}
          description={<Skeleton count={2.7} height={12} />}
          price={<Skeleton height={28} width={150} />}
          addNote={<Skeleton />}
          linkButton={<Skeleton height={30} />}
        />
      }
      desktop={
        <LegalServicesCreationCard
          title={<Skeleton height={30} count={1.8} containerTestId="title" />}
          description={<Skeleton count={3} height={10} />}
          price={<Skeleton height={30} width={270} />}
          addNote={<Skeleton count={1.8} height={14} width={270} />}
          linkButton={<Skeleton height={30} width={270} />}
        />
      }
    />
  );
};
