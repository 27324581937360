import { useRef } from "react";
import { Formik } from "formik";
import { userChangePasswordValidationSchema } from "../../validation/userChangePasswordValidationSchema";
import { FormBox, FormScrollToFirstErrorAfterSubmit } from "../../../../common/form";
import { UserChangePasswordFormContent } from "./UserChangePasswordFormContent";
import {
  useUserChangePasswordOnSubmit,
  UseUserChangePasswordOnSubmitArgs,
} from "../../hooks/useUserChangePasswordOnSubmit";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export interface UserChangePasswordFormProps extends UseUserChangePasswordOnSubmitArgs, BaseCssProps {}

export const UserChangePasswordForm = ({ onSuccess, className }: UserChangePasswordFormProps) => {
  const onSubmit = useUserChangePasswordOnSubmit(onSuccess);
  const formRef = useRef<HTMLFormElement | null>(null);

  return (
    <Formik
      initialValues={userChangePasswordValidationSchema.getDefault()}
      validationSchema={userChangePasswordValidationSchema}
      onSubmit={onSubmit}
    >
      <FormBox
        ref={formRef}
        className={className}
        display="flex"
        gap="lg"
        flexDirection="column"
        alignItems="flex-start"
      >
        <FormScrollToFirstErrorAfterSubmit containerRef={formRef} />
        <UserChangePasswordFormContent />
      </FormBox>
    </Formik>
  );
};
