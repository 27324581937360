import styled from "@emotion/styled";
import { Link } from "../../../../../common/ui-kit/links";

export const ItemWrapper = styled.li`
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: ${({ theme }) => theme.spaces.xl};
`;

export const Item = styled(Link)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
`;
