import { Form, Formik } from "formik";
import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import { useCustomFieldOnChange, useGetFieldName, useGetFormFieldError } from "../../../../form";
import { Box } from "../../../../ui-kit/containers/box";
import { CreationBlockCard } from "../../../../ui-kit/cards/creation-block-card";
import { DateRangeFilterFormValues } from "../../types/DateRangeFilterFormValues";
import { dateRangeFilterFormValidationSchema } from "../../validation/dateRangeFilterFormValidationSchema";
import { ButtonLoading } from "../../../../ui-kit/form-controls/button";

interface DateRangeFilterFormProps {
  initialValues: { dateCreateFrom?: Date; dateCreateTo?: Date };
  onSuccess: (dateCreateFrom?: Date, dateCreateTo?: Date) => void;
}

export const DateRangeFilterForm = ({ initialValues, onSuccess }: DateRangeFilterFormProps) => {
  const getFieldError = useGetFormFieldError<DateRangeFilterFormValues>();
  const getFieldName = useGetFieldName<DateRangeFilterFormValues>();
  const onCustomChange = useCustomFieldOnChange<DateRangeFilterFormValues>();

  const handleFormSubmit = (values: DateRangeFilterFormValues) => {
    const model = dateRangeFilterFormValidationSchema.cast(values, {
      assert: false,
    });
    onSuccess(model.dateCreateFrom, model.dateCreateTo);
  };

  return (
    <Formik
      initialValues={dateRangeFilterFormValidationSchema.cast(initialValues, {
        stripUnknown: true,
      })}
      validationSchema={dateRangeFilterFormValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {(form) => (
        <Box as={Form} display="flex" flexDirection="column" gap="xxl">
          <Box as={CreationBlockCard} padding="md" display="flex" flexDirection="column" gap="lg">
            <DateFormInput
              placeholder="дд.мм.гггг"
              label="Дата начала"
              {...form.getFieldProps(getFieldName("dateCreateFrom"))}
              onChange={(e: string) => onCustomChange(form.setFieldValue, "dateCreateFrom", e, true)}
              error={getFieldError(form, "dateCreateFrom")}
              autoComplete="off"
              disabled={form.isSubmitting}
            />
            <DateFormInput
              placeholder="дд.мм.гггг"
              label="Дата окончания"
              {...form.getFieldProps(getFieldName("dateCreateTo"))}
              onChange={(e: string) => onCustomChange(form.setFieldValue, "dateCreateTo", e, true)}
              error={getFieldError(form, "dateCreateTo")}
              autoComplete="off"
              disabled={form.isSubmitting}
            />
          </Box>
          <ButtonLoading isFullWidth variant="contained" color="primary" type="submit" isLoading={form.isSubmitting}>
            Применить
          </ButtonLoading>
        </Box>
      )}
    </Formik>
  );
};
