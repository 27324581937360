import * as React from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import * as S from "../QueueTasksListItem.styled";

export interface QueueTasksListItemDesktopProps extends BaseCssProps {
  title: React.ReactNode;
  details: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
}

export const QueueTasksListItemCard = ({
  className,
  title,
  details,
  footer,
  children,
}: QueueTasksListItemDesktopProps) => {
  return (
    <S.Article className={className}>
      <S.TitleColumn>
        <S.H3>{title}</S.H3>
        <S.Details>{details}</S.Details>
      </S.TitleColumn>
      {children}
      {footer}
    </S.Article>
  );
};
