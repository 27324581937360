import {
  combineReducers,
  createAction,
  createSlice,
  isAnyOf,
  isAsyncThunkAction,
  PayloadAction,
} from "@reduxjs/toolkit";
import { BankGuaranteeCreationState } from "./bankGuaranteeCreation.types";
import {
  BANK_GUARANTEE_CREATION_SLICE_NAME,
  createDraftBankGuaranteeCreation,
  scoringBankGuaranteeCreation,
  scoringByGuaranteeIdBankGuaranteeCreation,
} from "./bankGuaranteeCreation.thunks";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { getClientSearchReducer } from "../../../client-search";
import { combineSliceNames } from "../../../common/store/utils";
import { ProductCreationStages } from "../../../common/product-creation";
import { getPurchaseReducer } from "../../../common/purchase";

export const newBankGuaranteeCreation = createAction(combineSliceNames(BANK_GUARANTEE_CREATION_SLICE_NAME, "new"));

const initialState: BankGuaranteeCreationState = {
  activeStage: "creation",
  scoring: { status: LoadingStatusEnum.Idle, data: undefined },
  creating: { status: LoadingStatusEnum.Idle, data: undefined },
  isFormSameAsInitial: false,
};

const slice = createSlice({
  name: BANK_GUARANTEE_CREATION_SLICE_NAME,
  initialState,
  reducers: {
    changeStage: (state, action: PayloadAction<ProductCreationStages>) => {
      state.activeStage = action.payload;
    },
    setNextStage: (state) => {
      state.activeStage = state.activeStage === "creation" ? "scoring" : "creation";
    },
    setIsFormSameAsInitial: (state, action: PayloadAction<boolean>) => {
      state.isFormSameAsInitial = action.payload;

      if (!action.payload) {
        state.scoring = initialState.scoring;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newBankGuaranteeCreation, () => initialState)
      .addCase(createDraftBankGuaranteeCreation.pending, (state) => {
        state.creating.error = undefined;
      })
      .addCase(createDraftBankGuaranteeCreation.fulfilled, (state, action) => {
        state.creating.data = action.meta.arg;
      })
      .addCase(createDraftBankGuaranteeCreation.rejected, (state, action) => {
        state.creating.error = action.payload?.message;
      })
      .addMatcher(
        isAnyOf(scoringBankGuaranteeCreation.fulfilled, scoringByGuaranteeIdBankGuaranteeCreation.fulfilled),
        (state, action) => {
          state.scoring.data = action.payload.body.groups;
        }
      )
      .addMatcher(isAsyncThunkAction(createDraftBankGuaranteeCreation), (state, action) => {
        state.creating.status = getLoadingStatusFromAction(action);
      })
      .addMatcher(
        isAsyncThunkAction(scoringBankGuaranteeCreation, scoringByGuaranteeIdBankGuaranteeCreation),
        (state, action) => {
          state.scoring.status = getLoadingStatusFromAction(action);
        }
      );
  },
});

export const bankGuaranteeCreationReducer = combineReducers({
  form: slice.reducer,
  clientSearch: getClientSearchReducer({
    key: "BankGuarantee",
    caseExtraReducers: (builder, clientSearchInitialState) => {
      builder.addCase(newBankGuaranteeCreation, () => clientSearchInitialState);
    },
    sliceName: combineSliceNames(BANK_GUARANTEE_CREATION_SLICE_NAME, "clientSearch"),
  }),
  purchase: getPurchaseReducer({
    key: "BankGuarantee",
    sliceName: combineSliceNames(BANK_GUARANTEE_CREATION_SLICE_NAME, "purchase"),
  }),
});

export const {
  changeStage: changeStageBankGuaranteeCreation,
  setIsFormSameAsInitial: setIsFormSameAsInitialBankGuaranteeCreation,
  setNextStage: setNextStageBankGuaranteeCreation,
} = slice.actions;
