import styled from "@emotion/styled";

export const FullPageContainerCommonCenteredWrapper = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spaces.xl};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    width: 750px;
    padding: 0;
  }
`;
