import { ReactNode } from "react";
import { ControlSize } from "../../../types";
import { Typography } from "../../../typography";
import * as S from "./FormRadioCheckbox.styled";

const getSvgViewBox = (radioCheckboxSize?: ControlSize) => {
  switch (radioCheckboxSize) {
    case "small":
      return "0 0 12 12";
    case "default":
      return "0 0 16 16";
    case "medium":
      return "0 0 20 20";
    default:
      return "0 0 16 16";
  }
};

export interface FormRadioCheckboxProps
  extends Omit<JSX.IntrinsicElements["input"], "type" | "ref" | "size" | "disabled"> {
  label: ReactNode;
  radioCheckboxSize?: ControlSize;
  isDisabled?: boolean;
}

export const FormRadioCheckbox = ({
  label,
  isDisabled,
  radioCheckboxSize = "default",
  ...rest
}: FormRadioCheckboxProps) => {
  return (
    <S.Label isDisabled={isDisabled}>
      <S.RadioCheckboxInputContainer checkboxSize={radioCheckboxSize}>
        <S.RadioCheckboxInput type="radio" disabled={isDisabled} checkboxSize={radioCheckboxSize} {...rest} />
        <S.Icon xmlns="http://www.w3.org/2000/svg" viewBox={getSvgViewBox(radioCheckboxSize)} fill="none">
          <S.IconOuterCircle isDisabled={isDisabled} outerCircleSizes={radioCheckboxSize} strokeWidth="1.5" />
          <S.IconInnerCircle isDisabled={isDisabled} innerCircleSizes={radioCheckboxSize} />
        </S.Icon>
      </S.RadioCheckboxInputContainer>
      <Typography fontSize="fz3" fontWeight={500} lineHeight="defaultLineHeight">
        {label}
      </Typography>
    </S.Label>
  );
};
