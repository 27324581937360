import { DemandQueueTaskComment as DemandQueueTaskCommentType } from "../../types/DemandQueueTaskComment";
import { DemandQueueTaskSeparator } from "../separator/DemandQueueTaskSeparator";
import { DemandQueueTaskCommentWithFiles } from "../comment/DemandQueueTaskCommentWithFiles";

export interface DemandQueueTaskTwoCommentsCardProps {
  managerComment: DemandQueueTaskCommentType;
  agentComment: DemandQueueTaskCommentType;
}

export const DemandQueueTaskCompletedTwoCommentsCard = ({
  managerComment,
  agentComment,
}: DemandQueueTaskTwoCommentsCardProps) => {
  return (
    <>
      <DemandQueueTaskCommentWithFiles
        author={managerComment.author}
        text={managerComment.body}
        date={new Date(managerComment.date)}
        files={managerComment.files}
      />
      <DemandQueueTaskSeparator />
      <DemandQueueTaskCommentWithFiles
        author={agentComment.author}
        text={agentComment.body}
        date={new Date(agentComment.date)}
        files={agentComment.files}
      />
    </>
  );
};
