import { useFormikContext } from "formik";
import { BaseCssProps } from "../../../../ui-kit/types";
import { CreationSubmitButton } from "../../../../ui/buttons/creation-submit-button";
import { useGetFormFieldError } from "../../../../form";
import { ProductCreationScoringFormValues } from "../../../types/scoring/ProductCreationScoringFormValues";
import { useCreationScoringSubmitButtonText } from "../../../hooks/scoring/useCreationScoringSubmitButtonText";

export type ProductCreationScoringFormSubmitButtonProps = BaseCssProps;

export const ProductCreationScoringFormSubmitButton = <T extends ProductCreationScoringFormValues>({
  className,
}: ProductCreationScoringFormSubmitButtonProps) => {
  const {
    values: { banks },
    isSubmitting,
    ...rest
  } = useFormikContext<T>();

  const submitButtonText = useCreationScoringSubmitButtonText(Object.keys(banks ?? {}).length);
  const getFieldError = useGetFormFieldError<ProductCreationScoringFormValues>();

  return (
    <CreationSubmitButton
      isLoading={isSubmitting}
      className={className}
      type="submit"
      error={getFieldError(rest, "banksCount")}
    >
      {submitButtonText}
    </CreationSubmitButton>
  );
};
