import { useRef } from "react";
import { Formik } from "formik";
import { CreditExecutionCreationFormContent } from "./CreditExecutionCreationFormContent";
import { useCreditExecutionCreationFormSubmit } from "../../hooks/creation/useCreditExecutionCreationFormSubmit";
import { getCreditExecutionCreationFromValidationSchema } from "../../validation/creditExecutionCreationValidationSchema";
import { FormBox, FormScrollToFirstErrorAfterSubmit } from "../../../../common/form";
import { useCreditExecutionCreationFromInitialValues } from "../../hooks/creation/useCreditExecutionCreationFromInitialValues";

export const CreditExecutionCreationForm = () => {
  const ref = useRef<HTMLFormElement | null>(null);
  const initialValues = useCreditExecutionCreationFromInitialValues();
  const onSubmit = useCreditExecutionCreationFormSubmit(initialValues);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={getCreditExecutionCreationFromValidationSchema()}
    >
      <FormBox ref={ref} display="flex" flexDirection="column" flexShrink={1} flexGrow={1}>
        <FormScrollToFirstErrorAfterSubmit containerRef={ref} />
        <CreditExecutionCreationFormContent />
      </FormBox>
    </Formik>
  );
};
