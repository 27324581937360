import { Box } from "../../../../../../../ui-kit/containers/box";
import { Typography } from "../../../../../../../ui-kit/typography";

export interface ProductListCardItemStageCountersWithBanksLabelContainerProps {
  banksLabel: string | React.ReactNode;
  children: React.ReactNode;
}

export const ProductListCardItemStageCountersWithBanksLabelContainerMobile = ({
  banksLabel,
  children,
}: ProductListCardItemStageCountersWithBanksLabelContainerProps) => {
  return (
    <Box gap="lg" display="flex" flexDirection="row" alignItems="center">
      <Typography fontSize="fz3" lineHeight="defaultLineHeight" fontWeight={700} fontColor="typography.primary">
        {banksLabel}
      </Typography>
      {children}
    </Box>
  );
};
