import { createAppEntityAdapter } from "../../../../common/store/utils";
import { QueueTaskId } from "../../../../common/types/demand";
import { BankGuaranteeDetailsDemandQueueTaskActive } from "../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskActive";

export const bankGuaranteeDetailsActiveTasksAdapter = createAppEntityAdapter<
  BankGuaranteeDetailsDemandQueueTaskActive,
  QueueTaskId
>({
  selectId: (x) => x.id,
});
