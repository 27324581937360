import { useAppSelector } from "../../../../../common/store";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { selectCreditExecutionDetailsActiveTasksIds } from "../../../store/active-tasks/creditExecutionDetailsActiveTasks.selectors";
import { CreditExecutionDetailsActiveTask } from "./CreditExecutionDetailsActiveTask";
import { ProductDetailsDemandActiveTasksSkeletonWrapper } from "../../../../../common/product-details";
import { selectCreditExecutionDetailsDemandById } from "../../../store/demands/creditExecutionDetailsDemands.selectors";
import { selectCreditExecutionDetailsActiveDemand } from "../../../store/active-demand/creditExecutionDetailsActiveDemand.selectors";

export type CreditExecutionDetailsActiveTasksProps = BaseCssProps;

export const CreditExecutionDetailsActiveTasks = ({ className }: CreditExecutionDetailsActiveTasksProps) => {
  const {
    activeDemandId,
    fetch: { status: fetchStatus },
  } = useAppSelector(selectCreditExecutionDetailsActiveDemand);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectCreditExecutionDetailsDemandById(state, activeDemandId!));
  const tasksIds = useAppSelector(selectCreditExecutionDetailsActiveTasksIds);

  return (
    <ProductDetailsDemandActiveTasksSkeletonWrapper
      demandTasksCount={demand?.countActiveTasks}
      status={fetchStatus}
      fetchedTasksCount={tasksIds.length}
      className={className}
    >
      {tasksIds.map((x) => (
        <CreditExecutionDetailsActiveTask queueTaskId={x} key={x} />
      ))}
    </ProductDetailsDemandActiveTasksSkeletonWrapper>
  );
};
