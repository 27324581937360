import { GetFiltrationParametersCreditExecutionListOutputDto } from "../../api/dto/output/GetFiltrationParametersCreditExecutionListOutputDto";
import { getEntitiesCodeWithTitle } from "../../../../common/types/objects";
import { CreditExecutionListFilters } from "../../types/filters/CreditExecutionListFilters";

const mapFilters = ({
  bankIds,
  taskTypes,
  statuses,
}: GetFiltrationParametersCreditExecutionListOutputDto): CreditExecutionListFilters => {
  return {
    bankIds: getEntitiesCodeWithTitle(bankIds),
    statuses: getEntitiesCodeWithTitle(statuses),
    taskTypes: getEntitiesCodeWithTitle(taskTypes),
  };
};

export const creditExecutionListFiltersMapper = { mapFilters };
