import { useEffect } from "react";
import { Form, Formik } from "formik";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { FormNumberFormatInput } from "@sideg/ui-kit/atoms/form-controls/number-format-input";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { FormCheckbox } from "@sideg/ui-kit/atoms/checkbox";
import * as S from "./UserProfileInfoForm.styled";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { isFailed, isLoading, isSucceeded } from "../../../common/types/state";
import { selectUserDetails } from "../store/userDetails.selectors";
import { postUserDetails } from "../store/userDetails.thunks";
import { Text, Typography } from "../../../common/ui-kit/typography";
import { useGetFieldName, useGetFormFieldError } from "../../../common/form";
import { SimpleOpacityTransition } from "../../../common/ui-kit/animations/transitions";
import { AlertLoadingToResult } from "../../../common/ui-kit/notifications/alert-with-indicator";
import { userDetailsPostUpdate } from "../store/userDetails.slice";
import { UserDetails } from "../types/UserDetails";
import { UserProfileFromValues } from "../types/UserProfileFromValues";
import { userProfileFormValidationSchema } from "../validation/userProfileValidationSchema";
import { StackPanel } from "../../../common/ui-kit/containers/stack-panel";
import { useUserDetailsTitle } from "../hooks/useUserDetailsTitle";
import { ButtonLoading } from "../../../common/ui-kit/form-controls/button";

interface UserProfileInfoFormProps {
  user: UserDetails;
}

export const UserProfileInfoForm = ({ user }: UserProfileInfoFormProps) => {
  const {
    update: { status },
  } = useAppSelector(selectUserDetails);

  const dispatch = useAppDispatch();
  const title = useUserDetailsTitle();
  const isDesktop = useThemeIsDesktop();

  useEffect(() => {
    dispatch(userDetailsPostUpdate());
  }, [dispatch]);

  const getFieldError = useGetFormFieldError<UserProfileFromValues>();
  const getFieldName = useGetFieldName<UserProfileFromValues>();

  const handleFormSubmit = async (values: UserProfileFromValues) => {
    const model = userProfileFormValidationSchema.cast(values);

    dispatch(
      postUserDetails({
        phone: model.phone,
        email: model.email,
        notifyDemand: model.status,
        notifyNews: model.news,
        notifyMessage: model.unreadMessages,
      }),
    );
  };

  return (
    <Formik
      initialValues={userProfileFormValidationSchema.cast(user)}
      validationSchema={userProfileFormValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {(form) => (
        <StackPanel as={Form} gap="lg" direction="column" alignItems="flex-start">
          <Typography as="h3" fontWeight={500} fontSize="fz4" lineHeight="defaultLineHeight">
            {title}
          </Typography>
          <FormNumberFormatInput
            name={getFieldName("phone")}
            type="tel"
            label="Телефон"
            error={getFieldError(form, "phone")}
            autoComplete="tel"
            value={form.values.phone}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            disabled={form.isSubmitting}
          />
          <FormInput
            name={getFieldName("email")}
            type="email"
            label="Email"
            error={getFieldError(form, "email")}
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            disabled={form.isSubmitting}
          />
          <S.CheckBoxWrapper>
            <Text fontSize="fz4" fontColor="colors.dark" fontWeight={500}>
              Получать уведомления:
            </Text>
            <ul>
              <li>
                <FormCheckbox
                  label="По изменениям заявок"
                  name={getFieldName("status")}
                  onChange={form.handleChange}
                  checked={form.values.status}
                  isDisabled={form.isSubmitting}
                />
              </li>
              <li>
                <FormCheckbox
                  label="О непрочитанных сообщениях"
                  name={getFieldName("unreadMessages")}
                  onChange={form.handleChange}
                  checked={form.values.unreadMessages}
                  isDisabled={form.isSubmitting}
                />
              </li>
              <li>
                <FormCheckbox
                  label="Об актуальных новостях"
                  name={getFieldName("news")}
                  onChange={form.handleChange}
                  checked={form.values.news}
                  isDisabled={form.isSubmitting}
                />
              </li>
            </ul>
          </S.CheckBoxWrapper>
          <SimpleOpacityTransition
            timeout={250}
            in={isLoading(status) || isFailed(status) || isSucceeded(status)}
            mountOnEnter
            unmountOnExit
          >
            <AlertLoadingToResult
              isLoading={isLoading(status)}
              loadingText="Изменяем информацию профиля"
              isError={isFailed(status)}
              errorText="Не удалось обновить контактные данные"
              isSuccess={isSucceeded(status)}
              successText="Контактные данные успешно обновлены"
            />
          </SimpleOpacityTransition>
          <ButtonLoading
            size={isDesktop ? "default" : "medium"}
            isFullWidth={!isDesktop}
            type="submit"
            isLoading={isLoading(status)}
            loaderPosition="replace"
          >
            Изменить
          </ButtonLoading>
        </StackPanel>
      )}
    </Formik>
  );
};
