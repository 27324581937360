import { ProductRejectionForm } from "./form/ProductRejectionForm";
import { ProductRejectionCommonProps } from "./ProductRejectionCommonProps";
import { AppLayoutOverlayPanel, AppLayoutOverlayPanelContentWithHeader } from "../../ui/layout/app-layout-containers";
import { Box } from "../../ui-kit/containers/box";
import { Text } from "../../ui-kit/typography";
import { productRejectionConfigs } from "../configs";

export const ProductRejectionMobile = ({
  product,
  title,
  description,
  submitHandler,
  onClose,
  error,
  panelKey,
}: Omit<ProductRejectionCommonProps, "isOpen">) => {
  return (
    <AppLayoutOverlayPanel panelKey={panelKey ?? productRejectionConfigs.productRejectionOverlayPanel}>
      <AppLayoutOverlayPanelContentWithHeader onCloseClick={onClose}>
        <Box display="flex" flexDirection="column" flexGrow={1} gap="lg" padding="xxl">
          <Text fontWeight={500} fontSize={2.25}>
            {title}
          </Text>
          <Text fontSize="fz4" fontWeight={500}>
            {description}
          </Text>
          <ProductRejectionForm handleFormSubmit={submitHandler} error={error} product={product} />
        </Box>
      </AppLayoutOverlayPanelContentWithHeader>
    </AppLayoutOverlayPanel>
  );
};
