type EnumLike<T> = Record<keyof T, string | number>;

export const makeWrappedEnum = <TEnum extends EnumLike<TEnum>>(
  enumObject: TEnum,
  titles: Record<keyof TEnum, string>,
) => {
  const enumKeys = Object.keys(enumObject) as Array<keyof TEnum>;
  const enumValues = enumKeys.map((key) => enumObject[key]);

  return {
    keys: enumKeys,
    values: enumValues,
    getTitle: (key: keyof TEnum) => titles[key],
    getTitleByValue: (value: TEnum[keyof TEnum]): string => {
      const titleKey = enumKeys.find((key) => enumObject[key] === value);
      if (titleKey !== undefined) {
        return titles[titleKey];
      }

      return value.toString();
    },
  };
};

export type WrappedEnumType = ReturnType<typeof makeWrappedEnum>;
