import { useEffect } from "react";
import { useAppNavigation } from "../../../../common/urls/internal/hooks";
import { authenticationService } from "../../../../common/authentication";

export const PublicUserAuthenticationLogout = () => {
  const navigator = useAppNavigation();

  useEffect(() => {
    const logout = async () => {
      await authenticationService.logout();
      navigator((x) => x.publicUser.authentication.login);
    };

    logout();
  }, [navigator]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
