import { useEffect, useState } from "react";
import { CreditWorkingAssetsId } from "../../../common/types/demand";
import { useProductDetailsQueueTasksNeedUpdateListener } from "../../../common/product-details";
import { creditWorkingAssetsDetailsReloadActiveProduct } from "../store/creditWorkingAssetsDetails.actions";
import { useAppSelector } from "../../../common/store";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";

export const useCreditWorkingAssetsDetailsQueueTasksNeedUpdateListener = (
  creditWorkingAssetsId: CreditWorkingAssetsId
) => {
  const [lastProductUpdateTime, setLastProductUpdateTime] = useState<number>(0);
  const { lastSuccessRequestId } = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails);

  useEffect(() => {
    if (lastSuccessRequestId !== undefined) {
      setLastProductUpdateTime(Date.now());
    }
  }, [lastSuccessRequestId]);

  useProductDetailsQueueTasksNeedUpdateListener(
    creditWorkingAssetsId,
    "CreditWorkingAssets",
    creditWorkingAssetsDetailsReloadActiveProduct,
    lastProductUpdateTime
  );
};
