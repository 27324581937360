import { useFormikContext } from "formik";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { FormResponsiveRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox/components/radio-group/FormResponsiveRadioButtonGroup";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { useGetFieldName, useGetFormFieldError } from "../../../../../common/form";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditExecutionCreationFormValues } from "../../../types/creation/CreditExecutionCreationFormValues";
import { CreditExecutionCreationCheckboxField } from "../fields/CreditExecutionCreationCheckboxField";
import { federalLawType } from "../../../../../common/types/demand";
import { useCreditExecutionCreationFederalLawTypes } from "../../../hooks/creation/useCreditExecutionCreationFederalLawTypes";
import { CreditExecutionCreationPurchaseField } from "../fields/CreditExecutionCreationPurchaseField";

export interface CreditExecutionCreationContractBlockProps extends BaseCssProps {
  isDisabled?: boolean;
  isPurchaseNumberDisabled?: boolean;
}

export const CreditExecutionCreationContractBlock = ({
  isDisabled,
  isPurchaseNumberDisabled,
  className,
}: CreditExecutionCreationContractBlockProps) => {
  const form = useFormikContext<CreditExecutionCreationFormValues>();
  const federalLawTypes = useCreditExecutionCreationFederalLawTypes();

  const getFieldName = useGetFieldName<CreditExecutionCreationFormValues>();
  const getFieldError = useGetFormFieldError<CreditExecutionCreationFormValues>();

  return (
    <CreationBlockWithTitle title="Данные контракта" className={className}>
      <StackPanel direction="column" gap="md">
        <StackPanel direction="column" gap="md">
          <CreditExecutionCreationPurchaseField isDisabled={isPurchaseNumberDisabled} />
          <CreditExecutionCreationCheckboxField disabled={isDisabled} />
          <FormResponsiveRadioButtonGroup
            value={form.values.federalLaw}
            onChange={(newValue) => {
              form.setFieldValue(getFieldName("federalLaw"), newValue, true);
            }}
            onBlur={() => {
              form.setFieldTouched(getFieldName("federalLaw"), true, true);
            }}
            radioSize="default"
            label="ФЗ"
            itemsPlacement="start"
            types={federalLawTypes}
            name={getFieldName("federalLaw")}
            getTitle={federalLawType.getTitle}
            error={getFieldError(form, "federalLaw")}
            disabled={isDisabled}
          />
        </StackPanel>
        <StackPanel direction="column" gap="md">
          <FormInput
            type="text"
            label="Ссылка на закупку"
            size="medium"
            inputMode="url"
            {...form.getFieldProps(getFieldName("purchaseLink"))}
            error={getFieldError(form, "purchaseLink")}
            spellCheck={false}
            autoComplete="off"
            autoCorrect="off"
          />
          <FormTextarea
            label="Предмет контракта"
            size="medium"
            minRows={2}
            {...form.getFieldProps(getFieldName("contractSubject"))}
            error={getFieldError(form, "contractSubject")}
            autoComplete="off"
            disabled={isDisabled}
          />
        </StackPanel>
      </StackPanel>
    </CreationBlockWithTitle>
  );
};
