import { dateTimeHelper } from "@sideg/helpers";
import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import { useDateField } from "../../../../../common/form";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { Grid } from "../../../../../common/ui-kit/containers/responsive-grid";
import { BaseCssProps } from "../../../../../common/ui-kit/types";

export interface CreditExecutionCreationFormTermBlockProps extends BaseCssProps {
  isDisabled?: boolean;
}

export const CreditExecutionCreationFormTermBlock = ({
  isDisabled,
  className,
}: CreditExecutionCreationFormTermBlockProps) => {
  const [dateStartProps] = useDateField("dateStart");
  const [dateEndProps] = useDateField("dateEnd");

  return (
    <Grid as={CreationBlockWithTitle} title="Даты кредита" container gap="md" className={className}>
      <Grid
        item
        as={DateFormInput}
        placeholder="дд.мм.гггг"
        label="Дата начала"
        size="medium"
        xs={12}
        md={6}
        min={new Date()}
        {...dateStartProps}
        autoComplete="off"
        disabled={isDisabled}
      />
      <Grid
        item
        as={DateFormInput}
        placeholder="дд.мм.гггг"
        label="Дата окончания"
        size="medium"
        xs={12}
        md={6}
        min={dateTimeHelper.simpleModify("addDays", new Date(), 1)}
        {...dateEndProps}
        autoComplete="off"
        disabled={isDisabled}
      />
    </Grid>
  );
};
