import { BaseCssProps } from "@sideg/ui-kit/common/types";
import { BaseFormElementErrorProps } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormElementError";
import { CheckboxSize } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";
import * as S from "@sideg/ui-kit/atoms/checkbox/components/radio-group/option/FormRadioButtonGroupOption.styled";
import { ThemeTypographyVariantType } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypography";
import { getRadioButtonGroupPalette } from "@sideg/ui-kit/atoms/checkbox/utils/getRadioButtonGroupPaletteType";

export interface FormRadioButtonGroupOptionProps
  extends BaseCssProps,
    BaseFormElementErrorProps,
    Omit<JSX.IntrinsicElements["input"], "type" | "value" | "defaultValue" | "placeholder" | "className"> {
  label: string;
  value: string;
  isSelected?: boolean;
  radioSize?: CheckboxSize;
}

export const FormRadioButtonGroupOption = ({
  label,
  isSelected = false,
  disabled,
  value,
  className,
  error,
  radioSize,
  ...rest
}: FormRadioButtonGroupOptionProps) => {
  const isError = error !== undefined;
  const buttonTextVariant: ThemeTypographyVariantType =
    radioSize === "default" ? "mobile.text18Medium" : "desktop.text16Medium";

  return (
    <S.RadioButtonLabel className={className}>
      <S.RadioButton {...rest} type="radio" value={value} disabled={disabled} />
      <S.RadioButtonBody
        variant={buttonTextVariant}
        fontColor={getRadioButtonGroupPalette.text(isError, disabled, isSelected)}
        radioSize={radioSize}
        isDisabled={disabled}
        isSelected={isSelected}
        isError={isError}
      >
        {label}
      </S.RadioButtonBody>
    </S.RadioButtonLabel>
  );
};
