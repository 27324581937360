import { useEffect, useState } from "react";
import { WidgetsNewsHeader } from "./header/WidgetsNewsHeader";
import { useAppSelector } from "../../../store";
import { selectWidgetsNewsIds, selectWidgetsNewsTotal } from "../store/widgetsNews.selectors";
import { widgetsNewsConfig } from "../configs";
import { WidgetsNewsArticle } from "./article/WidgetsNewsArticle";
import { useWidgetsNewsCacheUpdater } from "../hooks/useWidgetsNewsCacheUpdater";
import { useWidgetsNewsSlider } from "../hooks/useWidgetsNewsSlider";
import { BaseCssProps } from "../../../ui-kit/types";
import * as S from "./WidgetsNews.styled";
import { NewsId } from "../../../../news/common/types";
import { useWidgetsNewsFetch } from "../hooks/useWidgetsNewsFetch";

export type WidgetsNewsProps = BaseCssProps;

export const WidgetsNews = ({ className }: WidgetsNewsProps) => {
  const totalNews = useAppSelector(selectWidgetsNewsTotal);
  const ids = useAppSelector(selectWidgetsNewsIds);

  const { count, canNavigateNext, canNavigatePrevious, activeItemIndex, setActiveItemIndex, onNavigationClick } =
    useWidgetsNewsSlider(totalNews, widgetsNewsConfig.maxNewsCount);
  const [activeNewsItemId, setActiveNewsItemId] = useState<NewsId | undefined>(undefined);

  useWidgetsNewsFetch();
  useWidgetsNewsCacheUpdater();

  useEffect(() => {
    if (activeItemIndex <= ids.length) {
      setActiveNewsItemId(ids[activeItemIndex]);
    }
  }, [ids, activeItemIndex]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {activeNewsItemId !== undefined && (
        <S.Section className={className}>
          <WidgetsNewsHeader
            onNavigationClick={onNavigationClick}
            canNavigatePrevious={canNavigatePrevious}
            canNavigateNext={canNavigateNext}
          />
          <WidgetsNewsArticle id={activeNewsItemId} />
          <S.Slider count={count} activeIndex={activeItemIndex} onButtonClick={setActiveItemIndex} />
        </S.Section>
      )}
    </>
  );
};
