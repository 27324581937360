import { BaseCssProps } from "../../../../common/ui-kit/types";
import { PublicUserResultCard } from "../../../common/components";
import { AlertTriangleBigIcon, Icon } from "../../../../common/ui-kit/icons";
import { AppLinkButton } from "../../../../common/ui/links";

export interface PublicUserRegistrationFailResultCardProps extends BaseCssProps {
  message: string;
}

export const PublicUserRegistrationFailResultCard = ({
  message,
  className,
}: PublicUserRegistrationFailResultCardProps) => {
  return (
    <PublicUserResultCard
      className={className}
      title="Ошибка регистрации"
      description={message}
      icon={<Icon as={AlertTriangleBigIcon} stroke="status.error" />}
    >
      <AppLinkButton isFullWidth size="medium" to={(x) => x.publicUser.registration.new}>
        Попробовать еще раз
      </AppLinkButton>
    </PublicUserResultCard>
  );
};
