import { ReactNode } from "react";
import { useThemeDeviceResponsiveType } from "@sideg/ui-kit/helpers/responsive/hooks/useThemeDeviceResponsiveType";

export interface AppLayoutMobileDesktopViewProps {
  mobile: ReactNode;
  desktop: ReactNode;
}

export const ThemeMobileDesktopView = ({ mobile, desktop }: AppLayoutMobileDesktopViewProps) => {
  const type = useThemeDeviceResponsiveType();

  return (
    <>
      {type === "desktop" && desktop}
      {type === "mobile" && mobile}
    </>
  );
};
