import { Box } from "../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { CreditExecutionDetailsAsideProductDetails } from "./CreditExecutionDetailsAsideProductDetails";
import { CreditExecutionDetailsAsideNumberTitle } from "./CreditExecutionDetailsAsideNumberTitle";
import { CreditExecutionDetailsAsideDemandsList } from "./demands/CreditExecutionDetailsAsideDemandsList";
import { CreditExecutionDetailsAsideActions } from "./CreditExecutionDetailsAsideActions";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { CreditExecutionDetailsNavigationCardDocuments } from "./navigation-card/CreditExecutionDetailsNavigationCardDocuments";

export type CreditExecutionDetailsAsideProps = BaseCssProps;

export const CreditExecutionDetailsAside = ({ className }: CreditExecutionDetailsAsideProps) => {
  return (
    <Box display="flex" overflow="hidden" flexDirection="column" gap="lg" className={className}>
      <CreditExecutionDetailsAsideProductDetails />
      <CreditExecutionDetailsNavigationCardDocuments />
      <StackPanel gap="sm" direction="column">
        <CreditExecutionDetailsAsideNumberTitle />
        <CreditExecutionDetailsAsideActions />
      </StackPanel>
      <CreditExecutionDetailsAsideDemandsList />
    </Box>
  );
};
