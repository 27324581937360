import { useMemo } from "react";
import { useSearchParamsOnce } from "../../../../common/filters";
import { useAppSelector } from "../../../../common/store";
import { selectCreditExecutionCreation } from "../../store/creditExecutionCreation.selectors";
import { CreditExecutionCreationFormValues } from "../../types/creation/CreditExecutionCreationFormValues";
import {
  creditExecutionCreationFromValidationSchema,
  getCreditExecutionCreationFromValidationSchema,
} from "../../validation/creditExecutionCreationValidationSchema";

export const useCreditExecutionCreationFromInitialValues = (): CreditExecutionCreationFormValues => {
  const queryParams = useSearchParamsOnce(creditExecutionCreationFromValidationSchema);

  const {
    creating: { data: formValues },
  } = useAppSelector(selectCreditExecutionCreation);

  return useMemo(() => {
    return getCreditExecutionCreationFromValidationSchema().cast(formValues ?? queryParams, {
      assert: false,
    }) as CreditExecutionCreationFormValues;
  }, [formValues, queryParams]);
};
