import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { NavigationState, useNavigationStateFrom } from "../../navigation/navigation-state";
import { AppUrlTypeEnum } from "../../urls/internal/types";
import { useAppSelector } from "../../store";
import { selectAppUrl } from "../../urls/internal/utils";
import { selectAuthorizedUser } from "../../authorized-user";
import { routeAccessService } from "../services";
import { AppUrlCheckPermission } from "../../urls/internal/types/IndexAppUrl";

export interface UseAppRouteSecureResult<TResult extends boolean> {
  isAllowed: TResult;
  redirectTo: TResult extends false ? string : undefined;
  state?: TResult extends false ? NavigationState | undefined : undefined;
}

export const useAppRouteAccess = (
  urlType: AppUrlTypeEnum,
  checkPermission: AppUrlCheckPermission,
): UseAppRouteSecureResult<boolean> => {
  const { isAuthenticated, user } = useAppSelector(selectAuthorizedUser);
  const location = useLocation();

  const initialNavigation = useNavigationStateFrom();
  const stateFrom: NavigationState | undefined = useMemo(() => {
    return location.pathname === selectAppUrl((x) => x.publicUser.authentication.logout).url
      ? undefined
      : { from: { url: location.pathname + location.search } };
  }, [location.pathname, location.search]);

  return useMemo(() => {
    const isAllowed = routeAccessService.canAccess(isAuthenticated, user?.featureFlags ?? [], urlType, checkPermission);

    if (isAllowed) {
      return { isAllowed, redirectTo: undefined };
    }

    if (isAuthenticated) {
      // Редиректим пользователя на последний известный путь
      return { isAllowed, redirectTo: initialNavigation?.from?.url ?? selectAppUrl((x) => x.root).url };
    }

    return {
      isAllowed: false,
      redirectTo: selectAppUrl((x) => x.publicUser.authentication.login).url,
      state: stateFrom,
    };
  }, [checkPermission, initialNavigation?.from?.url, isAuthenticated, stateFrom, urlType, user?.featureFlags]);
};
