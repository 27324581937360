import { forwardRef } from "react";
import {
  FormNumberFormatInput,
  FormNumberFormatInputProps,
} from "@sideg/ui-kit/atoms/form-controls/number-format-input";

const MAX_PHONE_ADDITIONAL_NUMBER_DIGITS_COUNT = 6;

export interface PhoneAdditionalNumberInputProps
  extends Omit<FormNumberFormatInputProps, "type" | "value" | "defaultValue"> {
  value: string | undefined;
}

export const PhoneAdditionalNumberInput = forwardRef<HTMLInputElement, PhoneAdditionalNumberInputProps>(
  ({ value, ...rest }, ref) => {
    return (
      <FormNumberFormatInput
        ref={ref}
        value={value}
        type="tel"
        autoComplete="tel-extension"
        isAllowed={({ formattedValue }) => formattedValue.length <= MAX_PHONE_ADDITIONAL_NUMBER_DIGITS_COUNT}
        {...rest}
      />
    );
  },
);
