import * as React from "react";
import { Box } from "../../../../../../../../common/ui-kit/containers/box";
import { StackPanel } from "../../../../../../../../common/ui-kit/containers/stack-panel";
import { Text } from "../../../../../../../../common/ui-kit/typography";

export interface BankGuaranteeListItemMobileDemandsContainerProps {
  banksCount: React.ReactNode;
  activeTasksBadge: React.ReactNode;
  counters: React.ReactNode;
  list: React.ReactNode;
}

export const BankGuaranteeListItemMobileDemandsContainer = ({
  banksCount,
  list,
  activeTasksBadge,
  counters,
}: BankGuaranteeListItemMobileDemandsContainerProps) => {
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <StackPanel gap="sm" direction="row">
          <Text fontWeight={700} lineHeight="defaultLineHeight" fontSize="fz3">
            {banksCount}
          </Text>
          {activeTasksBadge}
        </StackPanel>
        {counters}
      </Box>
      {list}
    </>
  );
};
