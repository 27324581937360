import styled from "@emotion/styled";

export const Button = styled.button`
  appearance: none;
  cursor: pointer;
  border-radius: 50%;
  font-family: inherit;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;

  &:hover {
    background-color: ${({ theme }) => theme.palette.status.errorBackground};
  }

  &:focus-visible > svg > path {
    stroke: ${({ theme }) => theme.colors.primary};
  }

  & > svg {
    width: 20px;
    height: 20px;

    & > path {
      stroke: ${({ theme }) => theme.palette.status.error};
    }
  }
`;
