import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDetailsActiveDemand } from "../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { isIdle } from "../../../../common/types/state";
import { getBankGuaranteeDetailsDemand } from "../../store/bankGuaranteeDetails.thunks";
import { useBankGuaranteeDetailsLayoutContext } from "../layout/useBankGuaranteeDetailsLayoutContext";
import { DemandId } from "../../../../common/types/demand";
import { AsyncThunkActionRequest } from "../../../../common/types/thunks";
import {
  useBankGuaranteeDetailsNeedReloadListener,
  UseBankGuaranteeDetailsNeedReloadListenerEffect,
} from "../useBankGuaranteeDetailsNeedReloadListener";
import { bankGuaranteeDetailsActiveDemandActions } from "../../store/active-demand/bankGuaranteeDetailsActiveDemand.slice";

export const useBankGuaranteeDetailsDemandInitialFetch = () => {
  const dispatch = useAppDispatch();
  const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();
  const { activeDemandId, fetch } = useAppSelector(selectBankGuaranteeDetailsActiveDemand);

  const onReload: UseBankGuaranteeDetailsNeedReloadListenerEffect = useCallback((_, api) => {
    api.dispatch(bankGuaranteeDetailsActiveDemandActions.reload());
  }, []);

  useBankGuaranteeDetailsNeedReloadListener(onReload);

  const requestRef = useRef<AsyncThunkActionRequest<{ demandId: DemandId }> | undefined>(undefined);

  useEffect(() => {
    if (requestRef.current && requestRef.current.arg.demandId !== activeDemandId) {
      requestRef.current.abort();
      requestRef.current = undefined;
    }
  }, [activeDemandId]);

  useEffect(() => {
    if (isIdle(fetch.status) && activeDemandId !== undefined) {
      requestRef.current = dispatch(getBankGuaranteeDetailsDemand({ guaranteeId, demandId: activeDemandId }));
    }
  }, [activeDemandId, dispatch, fetch.status, guaranteeId]);
};
