import { useCallback, useMemo } from "react";
import { CreditWorkingAssetsId } from "../../../../common/types/demand";
import { useAppDispatch } from "../../../../common/store";
import { pushSuccessGlobalToast } from "../../../../global/toasts";
import { useCreditWorkingAssetsListFetchSingleItem } from "./useCreditWorkingAssetsListFetchSingleItem";
import { useProductListItemModificationOnClose } from "../../../../common/ui/product/list/item-modification";
import { useCreditWorkingAssetsListFilterContext } from "../filters/useCreditWorkingAssetsListFilterContext";
import { cancelModificationCreditWorkingAssetsListModification } from "../../store/item-modification/creditWorkingAssetsListItemModification.slice";

export const useCreditWorkingAssetsListItemModificationView = (
  creditWorkingAssetsId: CreditWorkingAssetsId,
  modalState: readonly [boolean, (x: boolean) => void],
  successMessage: string
) => {
  const dispatch = useAppDispatch();
  const [isModalOpen] = modalState;

  const afterClose = useCallback(() => {
    dispatch(cancelModificationCreditWorkingAssetsListModification());
  }, [dispatch]);

  const onClose = useProductListItemModificationOnClose(modalState, afterClose);
  const fetchItem = useCreditWorkingAssetsListFetchSingleItem();
  const filteringObject = useCreditWorkingAssetsListFilterContext();

  return useMemo(() => {
    const onSuccess = async () => {
      onClose();
      dispatch(pushSuccessGlobalToast(successMessage));
      await fetchItem(creditWorkingAssetsId, filteringObject);
    };

    return {
      isModalOpen,
      closeModal: onClose,
      onSuccess,
    };
  }, [creditWorkingAssetsId, dispatch, fetchItem, filteringObject, isModalOpen, onClose, successMessage]);
};
