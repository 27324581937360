import { BankGuaranteeBriefDescriptionList, useBankGuaranteeBriefFetcher } from "../../../../../../brief";
import { BankGuaranteeId } from "../../../../../../../common/types/demand";

export interface BankGuaranteeListItemDesktopBriefProps {
  guaranteeId: BankGuaranteeId;
}

export const BankGuaranteeListItemDesktopBrief = ({ guaranteeId }: BankGuaranteeListItemDesktopBriefProps) => {
  const { status, data } = useBankGuaranteeBriefFetcher(guaranteeId);

  return <BankGuaranteeBriefDescriptionList status={status} data={data} isWithSeparators />;
};
