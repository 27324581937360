import { useCallback } from "react";
import { dateTimeHelper } from "@sideg/helpers";
import { IssuingCompanyId } from "../../../../../common/types/companies";
import { useApiMutation } from "../../../../../common/hooks";
import { sendCodePublicUserRegistration } from "../../../store/publicUserRegistration.thunks";
import { useAppDispatch, useAppSelector } from "../../../../../common/store";
import { changeDocumentSendTimePublicUserRegistrationAgent } from "../../../store/agent/publicUserRegistrationAgent.slice";
import {
  selectPublicUserRegistrationAgent,
  selectPublicUserRegistrationAgentDocumentById,
} from "../../../store/agent/publicUserRegistrationAgent.selectors";
import { getTimeInSecondsToNowPublicUserRegistrationAgent } from "../../../utils/getTimeInSecondsToNowPublicUserRegistrationAgent";

const SECONDS_TO_RESEND_FALLBACK = 60;

export const usePublicUserRegistrationAgentDocumentApproveSendCodeMutation = (issuingCompanyId: IssuingCompanyId) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { agencyId } = useAppSelector(selectPublicUserRegistrationAgent).data!;
  const { status, mutate } = useApiMutation(sendCodePublicUserRegistration, { errorMessagePlaceholder: "" });
  const document = useAppSelector((state) => selectPublicUserRegistrationAgentDocumentById(state, issuingCompanyId));

  const sendCode = useCallback(async () => {
    if (getTimeInSecondsToNowPublicUserRegistrationAgent(document?.canSendCodeAfterTime) === 0) {
      const result = await mutate({ agencyId, issuingCompanyId });

      // Если что-то пошло не так - пропускаем дальше и даем попробовать отправить еще раз через хардкод-интервал
      const secondsToResend = result?.body.secondsToResend ?? SECONDS_TO_RESEND_FALLBACK;
      dispatch(
        changeDocumentSendTimePublicUserRegistrationAgent({
          issuingCompanyId,
          time: +dateTimeHelper.simpleModify("addSeconds", Date.now(), secondsToResend),
        }),
      );
    }
  }, [agencyId, dispatch, document?.canSendCodeAfterTime, issuingCompanyId, mutate]);

  return { status, sendCode };
};
