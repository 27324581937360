import { ChangeEvent } from "react";
import "./Textarea.scss";

export interface ITextareaProps {
  defaultValue?: string;
  errorMessage?: string;
  name: string;
  placeholder: string;
  maxLength: number;
  onChange: (value: string) => void;
}

const Textarea: React.FC<ITextareaProps> = ({ defaultValue, errorMessage, name, placeholder, maxLength, onChange }) => {
  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const target = e.target;
    const value = target.value.trim();

    onChange(value);
  };

  return (
    <div className="form-group">
      <textarea
        name={name}
        className="form-control textarea"
        rows={4}
        maxLength={maxLength}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(e) => handleOnChange(e)}
      ></textarea>
      {errorMessage && <span className="text-danger form-group__error">{errorMessage}</span>}
    </div>
  );
};

export default Textarea;
