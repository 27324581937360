import { EntitiesCodeWithTitle } from "../../types/objects";
import { FilteringObjectType } from "../types/FilteringObjectType";

export const getFilteringValueTextByEntitiesCodeWithTitle = <
  TFilter extends FilteringObjectType,
  TValue extends string,
>(
  entitiesCodeWithTitle: EntitiesCodeWithTitle<TValue> | undefined,
) => {
  return (value: TValue, _context: TFilter) => entitiesCodeWithTitle?.titles[value] ?? null;
};
