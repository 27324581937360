import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { BankGuaranteeDetailsDemandStatusType } from "../../../../types/demand/BankGuaranteeDetailsDemandStatusType";
import { getThemePaletteByStatusType } from "../../../../utils/demand/getThemePaletteByStatusType";

export const BankGuaranteeDetailsDemandHeaderStatusChip = styled.div<{ variant: BankGuaranteeDetailsDemandStatusType }>(
  ({ theme }) => css`
    padding: ${theme.spaces.xs} ${theme.spaces.sm};
    border-radius: 8px;
    font-weight: 500;
    line-height: ${theme.font.defaultLineHeight};
    font-size: ${theme.font.fz2};

    ${theme.breakpoints.mqUpDesktop()} {
      border-radius: 4px;
    }
  `,
  ({ theme, variant }) => {
    const { text, background } = getThemePaletteByStatusType(theme, variant);

    return { color: text, backgroundColor: background };
  },
);
