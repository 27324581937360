import { useEffect } from "react";
import { ApiResponse } from "../../api/types";
import { useAppDispatch } from "../../store";
import { ApiAsyncThunk } from "../../store/utils";
import { isIdle, LoadingStatusEnum } from "../../types/state";
import { useIsFirstRender } from "../useIsFirstRender";

export const useFirstRenderThunkDispatch = (
  status: LoadingStatusEnum,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thunk: ApiAsyncThunk<ApiResponse<any>, undefined>,
) => {
  const dispatch = useAppDispatch();
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (isFirstRender || isIdle(status)) {
      dispatch(thunk());
    }
  }, [dispatch, isFirstRender, status, thunk]);
};
