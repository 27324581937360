import { PropsWithChildren } from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { CreationBlockCard } from "../../../../common/ui-kit/cards/creation-block-card";
import { Typography } from "../../../../common/ui-kit/typography";
import { Box } from "../../../../common/ui-kit/containers/box";

const BoxCard = Box.withComponent(CreationBlockCard);

export interface LegalServicesCreationFormCardProps extends PropsWithChildren<BaseCssProps> {
  title: string;
}

export const LegalServicesCreationFormCard = ({ title, className, children }: LegalServicesCreationFormCardProps) => {
  return (
    <BoxCard display="flex" flexDirection="column" gap="lg" padding="lg" className={className}>
      <Typography fontWeight={700} fontSize="fz4" lineHeight="defaultLineHeight">
        {title}
      </Typography>
      {children}
    </BoxCard>
  );
};
