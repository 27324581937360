import { useEffect } from "react";
import { widgetsNewsService } from "../../../../widgets/news";

export const useAppLayoutClearfix = () => {
  useEffect(() => {
    return () => {
      widgetsNewsService.removeCache();
    };
  }, []);
};
