import { useCallback, useEffect, useRef } from "react";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { ProductId, ProductType } from "../../types/demand";
import { useAppDispatch } from "../../store";
import { RawApiResponse } from "../../api/types/RawApiResponse";
import { QueueTasksNeedUpdatePayload } from "../../types/queue-tasks";
import { useHubEvent } from "../../hubs";
import { queueTasksHubConnection } from "../../hubs-use-cases/queue-tasks";

export const useProductDetailsQueueTasksNeedUpdateListener = (
  productId: ProductId,
  productType: ProductType,
  action: ActionCreatorWithoutPayload,
  lastProductUpdateTime: number | undefined,
  ignoreIfDelayLessThanInMs = 500,
) => {
  const dispatch = useAppDispatch();
  const lastUpdateRef = useRef(lastProductUpdateTime ?? 0);

  useEffect(() => {
    if (lastProductUpdateTime !== undefined) {
      lastUpdateRef.current = Math.max(lastProductUpdateTime, lastUpdateRef.current ?? 0);
    }
  }, [lastProductUpdateTime]);

  const handler = useCallback(
    (payload: RawApiResponse<QueueTasksNeedUpdatePayload>) => {
      const now = Date.now();
      if (
        payload.body.groupProductId === productId &&
        payload.body.productType === productType &&
        (lastUpdateRef.current === null || now - lastUpdateRef.current > ignoreIfDelayLessThanInMs)
      ) {
        lastUpdateRef.current = now;
        dispatch(action());
      }
    },
    [action, dispatch, ignoreIfDelayLessThanInMs, productId, productType],
  );

  useHubEvent(queueTasksHubConnection, "NeedUpdate", handler);
};
