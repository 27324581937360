import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { publicUserPrivacyPoliciesAdapter } from "./publicUserPrivacyPolicies.adapter";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { PublicUserPrivacyPoliciesSliceState, PublicUserPrivacyPoliciesState } from "./publicUserPrivacyPolicies.types";
import {
  getListPublicUserPrivacyPolicies,
  PUBLIC_USER_PRIVACY_POLICIES_SLICE_NAME,
} from "./publicUserPrivacyPolicies.thunks";

const initialState: PublicUserPrivacyPoliciesState =
  publicUserPrivacyPoliciesAdapter.getInitialState<PublicUserPrivacyPoliciesSliceState>({
    status: LoadingStatusEnum.Idle,
  });

const slice = createSlice({
  name: PUBLIC_USER_PRIVACY_POLICIES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListPublicUserPrivacyPolicies.fulfilled, (state, action) => {
        publicUserPrivacyPoliciesAdapter.setAll(state, action.payload.body.privacyPolicies);
      })
      .addMatcher(isAsyncThunkAction(getListPublicUserPrivacyPolicies), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const publicUserPrivacyPoliciesReducer = slice.reducer;
