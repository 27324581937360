import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeListFiltersBanks } from "./BankGuaranteeListFiltersBanks";
import { BankGuaranteeListFiltersGuaranteeTypes } from "./BankGuaranteeListFiltersGuaranteeTypes";
import { BankGuaranteeListFiltersStatus } from "./BankGuaranteeListFiltersStatus";
import { useAppSelector } from "../../../../../../common/store";
import { selectBankGuaranteeListFilters } from "../../../../store/filters/bankGuaranteeListFilters.selectors";
import { BankGuaranteeListFiltersTaskTypes } from "./BankGuaranteeListFiltersTaskTypes";
import { BankGuaranteeListFiltersDates } from "./BankGuaranteeListFiltersDates";
import { useBankGuaranteeListFilterContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterContext";
import { FiltersWrapperWithSearchInput } from "../../../../../../common/filters/use-cases/components/desktop/FiltersWrapperWithSearchInput.desktop";
import { BankGuaranteeListFiltersRequestOriginalStatuses } from "./BankGuaranteeListFiltersRequestOriginalStatuses";
import { BankGuaranteeListFiltersMoreOptions } from "./BankGuaranteeListFiltersMoreOptions";
import { useProductFilterSearchInput } from "../../../../../../common/filters/use-cases";

export type BankGuaranteeListFiltersDesktopProps = BaseCssProps;

export const BankGuaranteeListFiltersDesktop = ({ className }: BankGuaranteeListFiltersDesktopProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const { value, onChange } = useProductFilterSearchInput(filteringObject);
  const { data } = useAppSelector(selectBankGuaranteeListFilters);

  return (
    <FiltersWrapperWithSearchInput className={className} value={value} onChange={onChange}>
      {data && (
        <>
          <BankGuaranteeListFiltersBanks />
          <BankGuaranteeListFiltersGuaranteeTypes />
          {filteringObject.tab === "Active" && (
            <>
              <BankGuaranteeListFiltersTaskTypes />
              <BankGuaranteeListFiltersDates />
            </>
          )}
          {filteringObject.tab === "Completed" && (
            <>
              <BankGuaranteeListFiltersStatus />
              <BankGuaranteeListFiltersRequestOriginalStatuses />
              <BankGuaranteeListFiltersDates />
            </>
          )}
          {filteringObject.tab === "All" && (
            <>
              <BankGuaranteeListFiltersStatus />
              <BankGuaranteeListFiltersDates />
              <BankGuaranteeListFiltersMoreOptions />
            </>
          )}
        </>
      )}
    </FiltersWrapperWithSearchInput>
  );
};
