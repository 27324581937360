import { BankGuaranteeId, DemandId } from "../../../common/types/demand";
import { ProductRejection } from "../../../common/product-rejection";
import { AppLayoutOverlayPanelKey } from "../../../common/ui/layout/app-layout/types";
import { useBankGuaranteeRejectionSubmitHandler } from "../hooks/useBankGuaranteeRejectionSubmitHandler";

export interface BankGuaranteeDemandRejectionResponsiveViewProps {
  isOpen: boolean;
  afterSuccess: () => Promise<void>;
  demandId: DemandId;
  guaranteeId: BankGuaranteeId;
  bankName: string;
  onClose: () => void | Promise<void>;
  panelKey: AppLayoutOverlayPanelKey | undefined;
}

export const BankGuaranteeRejectionRejectDemandResponsiveView = ({
  isOpen,
  onClose,
  afterSuccess,
  demandId,
  guaranteeId,
  bankName,
  panelKey,
}: BankGuaranteeDemandRejectionResponsiveViewProps) => {
  const { handleFormSubmit, error } = useBankGuaranteeRejectionSubmitHandler(
    "demand",
    { guaranteeId, demandId },
    afterSuccess,
  );

  return (
    <ProductRejection
      error={error}
      isOpen={isOpen}
      product="BankGuarantee"
      onClose={onClose}
      title="Отмена заявки на БГ"
      description={`Будет отменена заявка № ${guaranteeId} в ${bankName}`}
      submitHandler={handleFormSubmit}
      panelKey={panelKey}
    />
  );
};
