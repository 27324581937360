import { AppUrlTypeEnum } from "./types";
import { queueTasksListUrls } from "../../../queue-tasks/list/urls";
import { queueTasksCreationUrls } from "../../../queue-tasks/creation/urls";
import { publicUserAuthenticationUrls } from "../../../public-user/authentication/urls";
import { userProfileUrls } from "../../../user/details/urls";
import { userChangePasswordUrls } from "../../../user/change-password/urls";
import { bankGuaranteeCalculatorUrls } from "../../../bank-guarantee/calculator/urls";
import { bankGuaranteeCalculatorUdaUrls } from "../../../bank-guarantee/calculator/urls/bankGuaranteeCalculatorUdaUrls";
import { bankGuaranteeConditionsUrls } from "../../../bank-guarantee/conditions/urls";
import { bankGuaranteeCreationUrls } from "../../../bank-guarantee/creation/urls";
import { bankGuaranteeDetailsUrls } from "../../../bank-guarantee/details/urls";
import { bankGuaranteeDocumentsUrls } from "../../../bank-guarantee/documents/urls";
import { bankGuaranteeListUrls } from "../../../bank-guarantee/list/urls";
import { bankGuaranteeRewardsUrls } from "../../../bank-guarantee/rewards/urls";
import { creditExecutionConditionsUrls } from "../../../credit-execution/conditions/urls";
import { creditExecutionCreationUrls } from "../../../credit-execution/creation/urls";
import { creditExecutionDetailsUrls } from "../../../credit-execution/details/urls";
import { creditExecutionListUrls } from "../../../credit-execution/list/urls";
import { settlementAndCashCreationUrls } from "../../../settlement-and-cash/creation/urls";
import { factoringCreationUrls } from "../../../factoring/creation/urls";
import { clientCheckUrls } from "../../../client/check/urls";
import { reportsAgentDetailsUrls } from "../../../reports/agent/details/urls";
import { reportsAgentListUrls } from "../../../reports/agent/list/urls";
import { reportsDemandUrls } from "../../../reports/demand/urls";
import { newsListUrls } from "../../../news/list/urls";
import { newsDetailsUrls } from "../../../news/details/urls";
import { creditWorkingAssetsListUrls } from "../../../credit-working-assets/list/urls";
import { creditWorkingAssetsDetailsUrls } from "../../../credit-working-assets/details/urls";
import { creditWorkingAssetsCreationUrls } from "../../../credit-working-assets/creation/urls";
import { publicUserRegistrationUrls } from "../../../public-user/registration/urls";
import { publicUserResetPasswordUrls } from "../../../public-user/reset-password/urls";
import { publicUserOffersUrls } from "../../../public-user/offers/urls";
import { agencyDemandUrls } from "../../../agency/demand/urls";
import { publicUserPrivacyPoliciesUrls } from "../../../public-user/privacy-policies/urls";
import { userWebPushesUrls } from "../../../user/web-pushes/urls";
import { makePublicOrCommonUrl } from "./utils/makeAppUrl";
import { legalServicesCreationUrls } from "../../../legal-services/creation/urls";

export const appUrls = {
  root: makePublicOrCommonUrl("/", "/", AppUrlTypeEnum.Common),
  home: queueTasksListUrls.root,
  queueTasks: {
    list: queueTasksListUrls,
    creation: queueTasksCreationUrls,
  },
  publicUser: {
    authentication: publicUserAuthenticationUrls,
    registration: publicUserRegistrationUrls,
    resetPassword: publicUserResetPasswordUrls,
    offers: publicUserOffersUrls,
    privacyPolicies: publicUserPrivacyPoliciesUrls,
  },
  user: {
    profile: userProfileUrls,
    changePassword: userChangePasswordUrls,
    webPushes: userWebPushesUrls,
  },
  bankGuarantee: {
    calculator: bankGuaranteeCalculatorUrls,
    calculatorUda: bankGuaranteeCalculatorUdaUrls,
    conditions: bankGuaranteeConditionsUrls,
    creation: bankGuaranteeCreationUrls,
    details: bankGuaranteeDetailsUrls,
    documents: bankGuaranteeDocumentsUrls,
    list: bankGuaranteeListUrls,
    rewards: bankGuaranteeRewardsUrls,
  },
  creditExecution: {
    conditions: creditExecutionConditionsUrls,
    creation: creditExecutionCreationUrls,
    details: creditExecutionDetailsUrls,
    list: creditExecutionListUrls,
  },
  creditWorkingAssets: {
    list: creditWorkingAssetsListUrls,
    details: creditWorkingAssetsDetailsUrls,
    creation: creditWorkingAssetsCreationUrls,
  },
  settlementAndCash: {
    creation: settlementAndCashCreationUrls,
  },
  factoring: {
    creation: factoringCreationUrls,
  },
  client: {
    check: clientCheckUrls,
  },
  reports: {
    agent: {
      details: reportsAgentDetailsUrls,
      list: reportsAgentListUrls,
    },
    demand: reportsDemandUrls,
  },
  news: {
    list: newsListUrls,
    details: newsDetailsUrls,
  },
  agency: {
    demand: agencyDemandUrls,
  },
  legalServices: {
    creation: legalServicesCreationUrls,
  },
};

export type AppUrls = typeof appUrls;
