import { useField } from "formik";
import { useEffect, useState } from "react";
import { DemandQueueTasksActiveRequestedDocument } from "../../../types/active/DemandQueueTasksActiveRequestedDocument";
import { DemandQueueTaskActiveDocumentFormValues } from "../../../types/active-form/DemandQueueTaskActiveDocumentFormValues";
import { getDocumentMinLengthDemandQueueTasks } from "../../../utils/getDocumentMinLengthDemandQueueTasks";
import { getFilesErrorDemandQueueTasks } from "../../../utils/getFilesErrorDemandQueueTasks";

export const useDemandQueueTaskRequestedDocumentWithRealTime = (document: DemandQueueTasksActiveRequestedDocument) => {
  const [, { touched, error }, { setValue }] = useField(`documents.d${document.id}`);

  const [memoDocument, setMemoDocument] = useState<DemandQueueTasksActiveRequestedDocument | undefined>();

  useEffect(() => {
    if (document !== memoDocument) {
      const documentValue: DemandQueueTaskActiveDocumentFormValues = {
        id: document.id,
        minLength: getDocumentMinLengthDemandQueueTasks(document, []),
        files: [],
        requestDocType: document.requestedDocType,
      };
      setValue(documentValue);
      setMemoDocument(document);
    }
  }, [document, memoDocument, setValue]);

  return getFilesErrorDemandQueueTasks(error, touched);
};
