/* eslint-disable no-nested-ternary */
import styled from "@emotion/styled";
import { StyledNavigationButton } from "./StyledNavigationButton";

export const StyledNavigationButtonWithCounter = styled(StyledNavigationButton)<{ count?: number }>`
  &::after {
    content: "${(props) => props.count}";
    display: ${(props) => (props.count ? "block" : "none")};
    position: absolute;
    top: -6px;
    left: ${({ count }) => (count !== undefined ? (count < 10 ? 60 : count < 100 ? 40 : 20) : 0)}%;
    line-height: 1;
    color: ${({ theme }) => theme.layout.header.button.notificationCountColor};
    background-color: ${({ theme }) => theme.secondaryColors.notificationBackground};
    border-radius: 8px;
    padding: 2px 4px;
    font-size: ${({ theme }) => theme.font.fz1};
    border: 2px solid ${({ theme }) => theme.layout.header.backgroundColor};
  }
`;
