import { useCallback, useMemo } from "react";
import * as React from "react";
import { useBankGuaranteeCreationField } from "./useBankGuaranteeCreationField";
import { contractPrepaidSumRules } from "../../../validation/rules/contractPrepaidSum.rules";

export const useBankGuaranteeCreationContractPrepaidSumField = () => {
  const [{ value, onBlur, onChange, name, error, initialValue }, { setValue }] =
    useBankGuaranteeCreationField("contractPrepaidSum");

  const onFieldBlur = useCallback(
    async (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value.trim() === "") {
        await setValue(initialValue ?? 0, true);
      }

      onBlur(e);
    },
    [initialValue, onBlur, setValue],
  );

  const [{ value: guaranteeType }] = useBankGuaranteeCreationField("guaranteeType");
  const isExists = useMemo(() => contractPrepaidSumRules.isExists(guaranteeType), [guaranteeType]);

  return useMemo(
    () => ({ value, onBlur: onFieldBlur, onChange, name, error, isExists }),
    [error, name, onChange, onFieldBlur, value, isExists],
  );
};
