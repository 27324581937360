import { BankGuaranteeDetailsDemandHeaderTabs } from "./BankGuaranteeDetailsDemandHeaderTabs";
import {
  bankGuaranteeDetailsDemandHeaderTab,
  BankGuaranteeDetailsDemandHeaderTab,
} from "../../../../types/demand/BankGuaranteeDetailsDemandHeaderTab";
import { ProductDetailsContentLayoutContainer } from "../../../../../../common/product-details";

export interface BankGuaranteeDetailsDemandHeaderTabsProps {
  tabs: BankGuaranteeDetailsDemandHeaderTab[];
  activeTab: BankGuaranteeDetailsDemandHeaderTab;
  onTabClick: (tab: BankGuaranteeDetailsDemandHeaderTab) => void | Promise<void>;
  className?: string;
}

export const BankGuaranteeDetailsDemandHeaderTabsContainer = ({
  tabs,
  activeTab,
  onTabClick,
  className,
}: BankGuaranteeDetailsDemandHeaderTabsProps) => {
  return (
    <ProductDetailsContentLayoutContainer className={className}>
      <BankGuaranteeDetailsDemandHeaderTabs.Container
        className={className}
        activeTab={activeTab}
        onTabChange={onTabClick}
      >
        {tabs.map((x, _, array) => (
          <BankGuaranteeDetailsDemandHeaderTabs.Tab
            key={x}
            tab={x}
            text={bankGuaranteeDetailsDemandHeaderTab.getTitle(x)}
            totalCount={array.length}
          />
        ))}
      </BankGuaranteeDetailsDemandHeaderTabs.Container>
    </ProductDetailsContentLayoutContainer>
  );
};
