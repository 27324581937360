import styled from "@emotion/styled";
import { CSSProperties } from "react";
import { CSSObject } from "@emotion/react";
import { ControlSize } from "@sideg/ui-kit/common/types";
import { BaseButton } from "@sideg/ui-kit/atoms/button/components/common/BaseButton";

export type SelectSize<TProperty extends keyof CSSProperties> = Record<ControlSize, CSSObject[TProperty]>;

// TODO: поправит на общую функцию когда будут готовы инпуты
export const expandIconSizes: SelectSize<"width"> = {
  small: "16px",
  default: "20px",
  medium: "24px",
};

export const StyledButton = styled(BaseButton.withComponent("button"))<{
  error: boolean;
  opened: boolean;
  disabled: boolean;
  expandIconSize: ControlSize;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.m};
  width: 100%;

  padding: ${({ theme }) => theme.spacing.makeCssSpaces("sm", "xs", "sm", "sm")};
  background-color: ${({ theme, disabled, error }) => {
    if (error) {
      return theme.palette.light.primary.text.inverse;
    }
    if (disabled) {
      return theme.palette.light.neutral.bgr.secondary;
    }

    return theme.palette.light.neutral.tonal;
  }};

  border: 1px solid
    ${({ theme, error, opened }) => {
      if (opened) {
        return theme.palette.light.primary.text.default;
      }
      if (error) {
        return theme.palette.light.signal.text.error;
      }

      return "transparent";
    }};
  border-radius: ${({ theme }) => theme.radii.control};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.light.primary.tonal.hover};
  }

  &:is(:focus, :focus-visible) {
    border: 1px solid ${({ theme }) => theme.palette.light.primary.text.default};
    outline: none;
  }

  &:disabled {
    cursor: auto;
    border-color: ${({ theme }) => theme.palette.light.neutral.outline.secondary};
    background-color: ${({ theme }) => theme.palette.light.neutral.bgr.secondary};
  }

  & > svg {
    width: ${({ expandIconSize }) => expandIconSizes[expandIconSize]};
    height: ${({ expandIconSize }) => expandIconSizes[expandIconSize]};
    transform: ${({ opened }) => `rotate(${opened ? 90 : -90}deg)`};
    transition: transfrom 0.5s ease-in-out;

    & > path {
      stroke: ${({ theme }) => theme.palette.light.neutral.text.tertiary};
    }
  }
`;
