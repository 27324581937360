import { ReactNode } from "react";
import { QueueTaskId } from "../../../../../../common/types/demand";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useBankGuaranteeDetailsFilterOnScrollToQueueTaskClickHandler } from "../../../../hooks/filters/useBankGuaranteeDetailsFilterOnScrollToQueueTaskClickHandler";
import { BankGuaranteeDetailsDemandReconciliationContent } from "./BankGuaranteeDetailsDemandReconciliationContent";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { ButtonLink } from "../../../../../../common/ui-kit/buttons/ButtonLink";
import { GuaranteeQueueTasksGroupType } from "../../../../../../common/types/queue-tasks";
import { BankGuaranteeDetailsDemandReconciliationStateType } from "../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationStateType";

export interface BankGuaranteeDetailsDemandReconciliationContentOfferProps extends BaseCssProps {
  children?: ReactNode;
  queueTaskId: QueueTaskId;
  groupType: GuaranteeQueueTasksGroupType;
  state?: BankGuaranteeDetailsDemandReconciliationStateType;
}

export const BankGuaranteeDetailsDemandReconciliationContentOffer = ({
  children,
  queueTaskId,
  groupType,
  className,
  state,
}: BankGuaranteeDetailsDemandReconciliationContentOfferProps) => {
  const onClick = useBankGuaranteeDetailsFilterOnScrollToQueueTaskClickHandler(queueTaskId, groupType);

  return (
    <BankGuaranteeDetailsDemandReconciliationContent.Block withLineSeparator className={className}>
      <BankGuaranteeDetailsDemandReconciliationContent.Typography fontColor="typography.primary" fontWeight={700}>
        Актуальное предложение от банка
      </BankGuaranteeDetailsDemandReconciliationContent.Typography>
      {children}
      <Box>
        <ButtonLink type="button" onClick={onClick}>
          <BankGuaranteeDetailsDemandReconciliationContent.Typography>
            {state === "Agreement" ? "Перейти к согласованию" : "Перейти к сообщению"}
          </BankGuaranteeDetailsDemandReconciliationContent.Typography>
        </ButtonLink>
      </Box>
    </BankGuaranteeDetailsDemandReconciliationContent.Block>
  );
};
