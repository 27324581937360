import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useBackLinkHeaderAction } from "../../../../common/ui/layout/header";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { FullPageContainerHeaderTitle } from "../../../../common/ui/containers/full-page";

export type FactoringCreationHeaderMobileProps = BaseCssProps;

export const FactoringCreationHeaderMobile = ({ className }: FactoringCreationHeaderMobileProps) => {
  useBackLinkHeaderAction({ url: "/" });

  return (
    <StackPanel gap="lg" direction="column" alignItems="center" className={className} justifyContent="center">
      <FullPageContainerHeaderTitle text="Новая заявка на факторинг" />
    </StackPanel>
  );
};
