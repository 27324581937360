import { useMemo } from "react";
import { useTypedField } from "../../../common/form";
import { FileTemp } from "../types/FileTemp";
import { generateFileTempId } from "../types/FileTempId";

export const useHandleSingleFileFieldChange = (fieldName: string) => {
  const [{ value }, { setValue }] = useTypedField<FileTemp | null | undefined>(fieldName);

  return useMemo(
    () => ({
      file: value,
      handleFileChange: async (files: File[]) => {
        if (files.length === 1) {
          await setValue({ id: generateFileTempId(), file: files[0], dateAdded: new Date() });
        }
      },
      removeFile: async () => {
        await setValue(null);
      },
    }),
    [value, setValue],
  );
};
