import { GetAgencyDemandOutputDto } from "../api/dto/output/GetAgencyDemandOutputDto";
import { AgencyDemand } from "../types/AgencyDemand";
import { AgencyDemandRequestedDocument } from "../types/AgencyDemandRequestedDocument";
import { AgencyDemandDocumentId } from "../types/AgencyDemandDocumentId";
import { make } from "../../../common/types/utils";
import { DocumentTypeId } from "../../../common/types/documents";

const getUniqueId = (id: DocumentTypeId, type: string | undefined): AgencyDemandDocumentId => {
  return make<AgencyDemandDocumentId>()(`${id}_${type}`);
};

const fromResponse = ({
  id,
  canRealTimeUpload,
  action,
  taskTitle,
  taskType,
  decisions,
}: GetAgencyDemandOutputDto): { model: AgencyDemand; documents?: AgencyDemandRequestedDocument[] } => {
  const model: AgencyDemand = {
    id,
    canRealTimeUpload,
    message: action.message,
    decisions,
    taskTitle,
    taskType,
  };

  return {
    model,
    documents: action.requestedFiles?.map((x) => ({
      ...x,
      uniqueId: getUniqueId(x.id, x.requestedDocType),
    })),
  };
};

export const agencyDemandMapper = { fromResponse, getUniqueId };
