import { useMemo } from "react";
import { IssuingCompanyId } from "../../../../../common/types/companies";
import { useAppSelector } from "../../../../../common/store";
import { selectPublicUserRegistrationAgentDocumentById } from "../../../store/agent/publicUserRegistrationAgent.selectors";
import { getTimeInSecondsToNowPublicUserRegistrationAgent } from "../../../utils/getTimeInSecondsToNowPublicUserRegistrationAgent";

export const usePublicUserRegistrationAgentDocumentApproveCodeTime = (issuingCompanyId: IssuingCompanyId) => {
  const document = useAppSelector((state) => selectPublicUserRegistrationAgentDocumentById(state, issuingCompanyId));

  return useMemo(
    () => getTimeInSecondsToNowPublicUserRegistrationAgent(document?.canSendCodeAfterTime),
    [document?.canSendCodeAfterTime]
  );
};
