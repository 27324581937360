import { Theme } from "@sideg/ui-kit";
import { CreditExecutionDemandStageType } from "../types";
import { ElementColor } from "../../../common/ui-kit/types";
import { Color } from "../../../common/ui-kit/theme";

export const getCreditExecutionDemandStageColor = (stage: CreditExecutionDemandStageType): ElementColor => {
  switch (stage) {
    case "AttachingDocuments":
      return "primary";
    case "Confirmed":
      return "status.success";
    case "Rejected":
      return "status.warning";
    case "Processing":
    default:
      return "darkLight";
  }
};

export const getCreditExecutionDemandStageColorAttachingAsProcessing = (
  stage: CreditExecutionDemandStageType,
): ElementColor => {
  return getCreditExecutionDemandStageColor(stage === "AttachingDocuments" ? "Processing" : stage);
};

export const getCreditExecutionStageBackgroundColor = (stage: CreditExecutionDemandStageType, theme: Theme): Color => {
  switch (stage) {
    case "Confirmed":
      return theme.palette.status.successSecondaryBackground;
    case "Rejected":
      return theme.palette.status.warningBackground;
    case "AttachingDocuments":
    case "Processing":
    default:
      return theme.palette.control.secondaryLight;
  }
};
