import { useRef } from "react";
import { Formik } from "formik";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { AgencyType } from "../../../../common/types/agencies";
import { FormBox, FormScrollToFirstErrorAfterSubmit, FormSubmitHandler } from "../../../../common/form";
import { PublicUserRegistrationCommonFormContent } from "./PublicUserRegistrationCommonFormContent";
import {
  publicUserRegistrationFormValidationSchema,
  publicUserRegistrationFormValidationSchemaGetDefault,
} from "../../validation/publicUserRegistrationFormValidationSchema";
import { PublicUserRegistrationFormValues } from "../../types/PublicUserRegistrationFormValues";
import { PublicUserRegistrationFormInitialState } from "../../types/PublicUserRegistrationFormInitialState";

export interface PublicUserRegistrationCommonFormProps
  extends BaseCssProps,
    Partial<PublicUserRegistrationFormInitialState> {
  agencyType: AgencyType;
  onSubmit: FormSubmitHandler<PublicUserRegistrationFormValues>;
}

export const PublicUserRegistrationCommonForm = ({
  agencyType,
  onSubmit,
  initialValues,
  initialTouched,
  isNeedToValidateOnMount,
}: PublicUserRegistrationCommonFormProps) => {
  const formRef = useRef<HTMLFormElement | null>(null);

  return (
    <Formik
      initialValues={publicUserRegistrationFormValidationSchemaGetDefault(agencyType, initialValues)}
      onSubmit={onSubmit}
      validationSchema={publicUserRegistrationFormValidationSchema}
      validateOnMount={isNeedToValidateOnMount}
      initialTouched={initialTouched}
    >
      <FormBox role="form" display="contents" ref={formRef} noValidate>
        <FormScrollToFirstErrorAfterSubmit containerRef={formRef} />
        <PublicUserRegistrationCommonFormContent agencyType={agencyType} />
      </FormBox>
    </Formik>
  );
};
