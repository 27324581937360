import { DemandQueueTaskActiveDocumentFormValues } from "../types/active-form/DemandQueueTaskActiveDocumentFormValues";
import { SendDocumentDemandQueueTaskInputDto } from "../api/input/SendDocumentDemandQueueTaskInputDto";
import { make } from "../../../common/types/utils";
import { DocumentTypeId } from "../../../common/types/documents";

export const mapRequestedDocumentDemandQueueTasks = (
  documents: DemandQueueTaskActiveDocumentFormValues[]
): SendDocumentDemandQueueTaskInputDto[] => {
  return documents
    .filter((x) => x.files?.length)
    .map((x) => ({
      id: make<DocumentTypeId>()(x.id),
      files: x.files?.map((y) => y.file),
      requestDocType: x.requestDocType,
    }));
};
