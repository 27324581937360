import styled from "@emotion/styled";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ResponsiveStyleValue } from "../../../types";

export const LineSeparatedContainer = styled.div<{ borderWidth: ResponsiveStyleValue<number> }>(
  ({ theme, borderWidth }) =>
    makeResponsiveStyles(theme, borderWidth, (x) => {
      return {
        "& > * + *": {
          borderTop: `${x}px solid ${theme.colors.secondaryLight}`,
        },
      };
    }),
);
