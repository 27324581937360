import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useCreditExecutionListFilterContext } from "../../../../hooks/filters/useCreditExecutionListFilterContext";
import { useCreditExecutionListFilterApiContext } from "../../../../hooks/filters/useCreditExecutionListFilterApiContext";
import { DateRangeFilter } from "../../../../../../common/filters/use-cases";

export type CreditExecutionListHeaderFiltersDatesDesktopProps = BaseCssProps;

export const CreditExecutionListHeaderFiltersDatesDesktop = ({
  className,
}: CreditExecutionListHeaderFiltersDatesDesktopProps) => {
  const filteringObject = useCreditExecutionListFilterContext();
  const mutator = useCreditExecutionListFilterApiContext();

  return (
    <DateRangeFilter
      className={className}
      dateMutator={(dateCreateFrom?: Date, dateCreateTo?: Date) =>
        mutator(filteringObject, (filter, service) => service.setValues(filter, { dateCreateFrom, dateCreateTo }))
      }
      dateCreateFrom={filteringObject.dateCreateFrom}
      dateCreateTo={filteringObject.dateCreateTo}
    />
  );
};
