/* eslint-disable react/destructuring-assignment */
import { Component } from "react";

import * as React from "react";
import { logger } from "../../../../logging";

export interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback: React.ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error) {
    logger.log("critical", error);
  }

  render() {
    return this.state.hasError ? this.props.fallback : this.props.children;
  }
}
