import { Key } from "react";
import { CheckIcon, ChevronLeftIcon, Icon } from "../../../../icons";
import { SelectPopupContainer } from "../SelectPopupContainer";
import { SelectPopupContainerItemJustified } from "../SelectPopupContainerItemJustified";
import { useSelectPopupMemo } from "../../hooks/useSelectPopup";
import { useFilterSelectContainerMinWidth } from "../../hooks/useFilterSelectContainerMinWidth";
import { BaseCssProps, ControlSize } from "../../../../types";
import { Typography } from "../../../../typography";
import * as S from "./FilterDropdownButton.styled";

export interface FilterDropdownButtonProps<TItem> extends BaseCssProps {
  selected?: TItem | null;
  getItemTitle: (item: TItem) => string;
  onSelectedChanged: (selectedItem: TItem) => void | Promise<void>;
  items: TItem[];
  text: string;
  maxItemsLength?: number;
  iconPosition?: "left" | "right";
  selectTextSize?: "fz2" | "fz3";
  buttonSize?: ControlSize;
}

export const FilterDropdownButton = <TItem,>({
  selected,
  getItemTitle,
  onSelectedChanged,
  items,
  text,
  maxItemsLength,
  iconPosition = "left",
  selectTextSize = "fz3",
  buttonSize,
  className,
}: FilterDropdownButtonProps<TItem>) => {
  const { isOpen, selectedItem, highlightedIndex, getItemProps, referenceElementProps, referenceProps, floatingProps } =
    useSelectPopupMemo({
      items,
      selectedItem: selected,
      onSelectedItemChange: (x) => {
        x.selectedItem && onSelectedChanged(x.selectedItem);
      },
      circularNavigation: true,
      position: "bottom-start",
      offset: [0, 4],
    });

  const minWidth = useFilterSelectContainerMinWidth(referenceElementProps, maxItemsLength);

  return (
    <>
      <S.StyledButton
        {...referenceProps}
        isOpen={isOpen}
        startIcon={iconPosition === "left" ? ChevronLeftIcon : undefined}
        endIcon={iconPosition === "right" ? ChevronLeftIcon : undefined}
        variant="contained"
        color="light"
        size={buttonSize ?? "small"}
        paddingSize={buttonSize ?? "small"}
        className={className}
      >
        <Typography fontSize={selectTextSize} lineHeight="defaultLineHeight">
          {text}
        </Typography>
      </S.StyledButton>

      <SelectPopupContainer {...floatingProps} open={isOpen} minWidth={minWidth}>
        {isOpen &&
          items.map((item, index) => {
            return (
              <SelectPopupContainerItemJustified
                key={item as Key}
                active={item === selectedItem}
                highlighted={index === highlightedIndex}
                {...getItemProps({ item, index })}
              >
                <span>{getItemTitle(item)}</span>
                {item === selectedItem && <Icon as={CheckIcon} stroke="success" />}
              </SelectPopupContainerItemJustified>
            );
          })}
      </SelectPopupContainer>
    </>
  );
};
