import { Box } from "../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { CreditWorkingAssetsDetailsAsideProductDetails } from "./CreditWorkingAssetsDetailsAsideProductDetails";
import { CreditWorkingAssetsDetailsAsideNumberTitle } from "./CreditWorkingAssetsDetailsAsideNumberTitle";
import { CreditWorkingAssetsDetailsAsideDemandsList } from "./demands/CreditWorkingAssetsDetailsAsideDemandsList";
import { CreditWorkingAssetsDetailsAsideActions } from "./CreditWorkingAssetsDetailsAsideActions";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { CreditWorkingAssetsDetailsNavigationCardDocuments } from "./navigation-card/CreditWorkingAssetsDetailsNavigationCardDocuments";

export type CreditWorkingAssetsDetailsAsideProps = BaseCssProps;

export const CreditWorkingAssetsDetailsAside = ({ className }: CreditWorkingAssetsDetailsAsideProps) => {
  return (
    <Box display="flex" overflow="hidden" flexDirection="column" gap="lg" className={className}>
      <CreditWorkingAssetsDetailsAsideProductDetails />

      <CreditWorkingAssetsDetailsNavigationCardDocuments />

      <StackPanel gap="sm" direction="column">
        <CreditWorkingAssetsDetailsAsideNumberTitle />
        <CreditWorkingAssetsDetailsAsideActions />
      </StackPanel>
      <CreditWorkingAssetsDetailsAsideDemandsList />
    </Box>
  );
};
