import { BankGuaranteeDetailsRequestOriginal } from "../../types/request-original/BankGuaranteeDetailsRequestOriginal";
import { GetBankGuaranteeDetailsRequestOriginalOutputDto } from "../../api/dto/output/GetBankGuaranteeDetailsOutputDto";

const mapResponse = (
  response: GetBankGuaranteeDetailsRequestOriginalOutputDto,
): BankGuaranteeDetailsRequestOriginal => {
  return {
    recipientName: response.recipient,
    status: response.status,
    additionalInformation: response.additionally,
    contactEmail: response.contactEmail,
    contactPhoneNumber: response.contactPhone,
    contactFullName: response.contactPerson,
    hasNewChange: response.hasNewChange,
    deliveryType: response.receivingWay,
    officeRecipientType: response.recipientOfficeType,
    canSendComment: response.canSendComment === true,
    address: response.address,
  };
};

export const bankGuaranteeDetailsRequestOriginalMapper = { mapResponse };
