import { ReactNode } from "react";
import { Theme } from "@sideg/ui-kit";
import { SimpleOpacityTransition } from "../../../animations/transitions";
import { CircleIndicator } from "../../circle-indicator";
import { CircularLoader } from "../../../animations/loaders";
import { BaseAlertWithIndicator } from "./BaseAlertWithIndicator";
import * as S from "./AlertLoadingWithToIndicatorTransition.styled";

export interface AlertLoadingWithToIndicatorTransitionProps {
  children: ReactNode;
  className?: string;
  result?: "error" | "success";
  isLoading: boolean;
  timeout?: number;
}

const getColorByResult = (result: AlertLoadingWithToIndicatorTransitionProps["result"], theme: Theme) => {
  if (result === undefined) {
    return "transparent";
  }

  return result === "error" ? theme.palette.status.error : theme.palette.status.success;
};

export const AlertLoadingWithToIndicatorTransition = ({
  children,
  isLoading,
  result,
  className,
  timeout = 300,
}: AlertLoadingWithToIndicatorTransitionProps) => {
  return (
    <BaseAlertWithIndicator
      className={className}
      indicator={
        <S.Indicator>
          <SimpleOpacityTransition timeout={timeout} in={isLoading} unmountOnExit>
            <CircularLoader size={16} />
          </SimpleOpacityTransition>
          <SimpleOpacityTransition timeout={timeout} in={!isLoading && result !== undefined} unmountOnExit>
            <CircleIndicator color={(theme) => getColorByResult(result, theme)} size={16} />
          </SimpleOpacityTransition>
        </S.Indicator>
      }
    >
      {children}
    </BaseAlertWithIndicator>
  );
};
