import { FilteringItem, FilteringObjectType } from "../../../../filters";
import * as S from "./FilterBadgesList.styled";
import { FilterBadge } from "./FilterBadge";
import { FilterBadgesClearButton } from "./FilterBadgesClearButton";
import { BaseCssProps } from "../../../types";

export interface FilterBadgesListProps<TSchema extends FilteringObjectType> extends BaseCssProps {
  filteringItems: FilteringItem<TSchema>[];
  onClearFiltersClick?: () => void | Promise<void>;
  onCancelClick: (onRemove: (actualFilteringObject: TSchema) => Promise<void> | void) => () => void | Promise<void>;
}

export const FilterBadgesList = <TSchema extends FilteringObjectType>({
  filteringItems,
  onClearFiltersClick,
  onCancelClick,
  className,
}: FilterBadgesListProps<TSchema>) => {
  return (
    <S.Container className={className}>
      {filteringItems.map((x) => (
        <FilterBadge key={x.uniqKey} text={x.text} onCancelClick={onCancelClick(x.onRemove)} />
      ))}
      {filteringItems.length > 1 && onClearFiltersClick && (
        <FilterBadgesClearButton onClick={onClearFiltersClick} type="button">
          Сбросить все фильтры
        </FilterBadgesClearButton>
      )}
    </S.Container>
  );
};
