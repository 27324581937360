import { useMemo } from "react";
import { useBankGuaranteeCreationForm } from "../useBankGuaranteeCreationForm";
import { contractFinalSumRules } from "../../../validation/rules/contractFinalSum.rules";

export const useBankGuaranteeCreationContractFinalSumField = () => {
  const {
    values: { guaranteeType },
  } = useBankGuaranteeCreationForm();

  return useMemo(() => {
    return contractFinalSumRules.isExists(guaranteeType);
  }, [guaranteeType]);
};
