import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  AttachingDocuments: "Прикрепить документы, чтобы отправить заявку в банк",
  Processing: "Заявка рассматривается",
  Confirmed: "Одобрена",
  Rejected: "Отказ или отмена",
};

const types = ["AttachingDocuments", "Processing", "Confirmed", "Rejected"] as const;

export const bankGuaranteeDemandStageType = makeEnumLikeLiteral(types, titles);

export type BankGuaranteeDemandStageType = EnumLikeLiteralType<typeof bankGuaranteeDemandStageType>;
