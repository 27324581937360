import * as React from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { createTabs } from "../../../../../../common/ui-kit/containers/tabs";
import { BankGuaranteeDetailsDemandHeaderTab } from "../../../../types/demand/BankGuaranteeDetailsDemandHeaderTab";

const Tabs = createTabs<BankGuaranteeDetailsDemandHeaderTab>();

const Container = ({
  className,
  activeTab,
  onTabChange,
  children,
}: Omit<React.ComponentProps<typeof Tabs.Container>, "gap" | "isGrow">) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Tabs.Container
      gap={{ xs: "none", [desktopBreakpoint]: "md" }}
      isGrow={{ xs: true, [desktopBreakpoint]: false }}
      activeTab={activeTab}
      onTabChange={onTabChange}
      className={className}
    >
      {children}
    </Tabs.Container>
  );
};

type TabProps = Pick<React.ComponentProps<typeof Tabs.Tab>, "tab" | "className" | "text"> & {
  counterValue?: string | number;
  totalCount: number;
};

const Tab = ({ tab, className, text, counterValue, totalCount }: TabProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Tabs.Tab
      fontSize="fz3"
      isGrow={{ xs: true, [desktopBreakpoint]: false }}
      px={{ xs: "none", [desktopBreakpoint]: "lg" }}
      py="md"
      tab={tab}
      className={className}
      text={text}
      flexBasis={{ xs: `${100 / totalCount}px`, [desktopBreakpoint]: "unset" }}
    >
      {counterValue && <Tabs.Counter>{counterValue}</Tabs.Counter>}
    </Tabs.Tab>
  );
};

export const BankGuaranteeDetailsDemandHeaderTabs = { Container, Tab };
