import styled from "@emotion/styled";

export const ProductDetailsContentLayoutContainer = styled.div`
  padding-left: calc(${({ theme }) => theme.layout.borderPadding} / 1.5);
  padding-right: calc(${({ theme }) => theme.layout.borderPadding} / 1.5);

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding-left: calc(3 * ${({ theme }) => theme.spaces.xxl});
    padding-right: calc(3 * ${({ theme }) => theme.spaces.xxl});
  }
`;
