import { useMemo } from "react";
import { QueueTaskId } from "../../../../common/types/demand";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDetailsTasksHistoryIds } from "../../store/tasks-history/bankGuaranteeDetailsTasksHistory.selectors";
import { selectBankGuaranteeDetailsActiveTasksIds } from "../../store/active-tasks/bankGuaranteeDetailsActiveTasks.selectors";

export const useBankGuaranteeDetailsFilterIsQueueTaskIdOnPage = (queueTaskId: QueueTaskId | undefined) => {
  const historyIds = useAppSelector(selectBankGuaranteeDetailsTasksHistoryIds);
  const activeIds = useAppSelector(selectBankGuaranteeDetailsActiveTasksIds);

  return useMemo(
    () => queueTaskId !== undefined && (activeIds.includes(queueTaskId) || historyIds.includes(queueTaskId)),
    [activeIds, queueTaskId, historyIds],
  );
};
