import { TransitionGroup } from "react-transition-group";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { BankGuaranteeId } from "../../../../../../../common/types/demand";
import { BankGuaranteeListItemMobileDemand } from "../demand-list-item/BankGuaranteeListItemMobileDemand";
import { useAppSelector } from "../../../../../../../common/store";
import { selectBankGuaranteeListItemsGuaranteeById } from "../../../../../store/items/bankGuaranteeListItems.selectors";
import * as S from "./BankGuaranteeListItemMobileDemandList.styled";
import { BankGuaranteeListItemMobileDemandListShowButton } from "./show-button/BankGuaranteeListItemMobileDemandListShowButton";
import { ChevronLeftIcon } from "../../../../../../../common/ui-kit/icons";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import { useListTrimMoreThan } from "../../../../../../../common/hooks";
import { ProductListCardDemandListItemAppearanceTransition } from "../../../../../../../common/ui/product/list/card";
import { declensionHelper } from "../../../../../../../common/helpers/entity";

export interface BankGuaranteeListItemMobileDemandListProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  from?: NavigationState;
}

export const BankGuaranteeListItemMobileDemandList = ({
  guaranteeId,
  className,
  from,
}: BankGuaranteeListItemMobileDemandListProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const guarantee = useAppSelector((state) => selectBankGuaranteeListItemsGuaranteeById(state, guaranteeId))!;
  const { isShown, setIsShown, trimmedCount, needTrim, items } = useListTrimMoreThan(guarantee.demandIds, 3);

  return (
    <>
      <TransitionGroup component={S.Ul} className={className}>
        {items.map((x) => (
          <ProductListCardDemandListItemAppearanceTransition key={x} timeout={250}>
            <BankGuaranteeListItemMobileDemand demandId={x} from={from} guaranteeId={guaranteeId} />
          </ProductListCardDemandListItemAppearanceTransition>
        ))}
      </TransitionGroup>
      {needTrim && (
        <BankGuaranteeListItemMobileDemandListShowButton
          isShown={isShown}
          type="button"
          onClick={() => setIsShown(!isShown)}
        >
          <span>
            {isShown ? "Свернуть" : `Еще ${declensionHelper.commonDeclensions.bank.nominative(trimmedCount, true)}`}
          </span>
          <ChevronLeftIcon />
        </BankGuaranteeListItemMobileDemandListShowButton>
      )}
    </>
  );
};
