import { useIsIOSSafari } from "../hooks/useIsIOSSafari";
import { useCanSetPwa } from "../hooks/useCanSetPwa";
import { SetPwaPortal } from "./SetPwaPortal";

interface SetPwaPortalWrapperProps {
  onClose: () => void;
}

export const SetPwaPortalWrapper = ({ onClose }: SetPwaPortalWrapperProps) => {
  const isIosSafari = useIsIOSSafari();
  const isSetPwa = useCanSetPwa();

  return isIosSafari && !isSetPwa ? <SetPwaPortal onClose={onClose} /> : null;
};
