import { RefObject, useEffect } from "react";
import { useChatDialogMessages } from "./messages/useChatDialogMessages";
import { useIntersectionObserver } from "../../../common/ui-kit/infinite-scroll";

export interface UseChatDialogIntersectionEffectArgs<T extends HTMLElement | null> {
  rootRef: RefObject<T>;
}

export const useChatDialogIntersectionEffect = <T extends HTMLElement | null>({
  rootRef,
}: UseChatDialogIntersectionEffectArgs<T>) => {
  const { onNextPageFetching, hasMore } = useChatDialogMessages();
  const [observerRef, entry] = useIntersectionObserver({ root: rootRef.current });

  useEffect(() => {
    if (hasMore && entry?.isIntersecting) {
      onNextPageFetching();
    }
  }, [entry?.isIntersecting, hasMore, onNextPageFetching]);

  return observerRef;
};
