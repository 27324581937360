import styled from "@emotion/styled";
import { SpaceType } from "../../../../common/ui-kit/types";

export const DemandQueueTaskCardContainer = styled.div<{ padding?: SpaceType }>`
  width: 100%;
  padding: ${({ theme, padding }) => theme.spaces[padding ?? "md"]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.lg};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    width: 70%;
    width: min(100%, 720px);
  }
`;
