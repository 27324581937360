import { useAppSelector } from "../../../../../../common/store";
import { CreditExecutionListItemRejectProduct } from "./CreditExecutionListItemRejectProduct";
import { CreditExecutionListItemRejectDemand } from "./CreditExecutionListItemRejectDemand";
import { selectCreditExecutionListItemModification } from "../../../../store/item-modification/creditExecutionListItemModification.selectors";
import {
  isCreditExecutionListItemRemoveDemandModification,
  isCreditExecutionListItemRemoveProductModification,
} from "../../../../store/item-modification/creditExecutionListItemModification.types";

export const CreditExecutionListModification = () => {
  const state = useAppSelector(selectCreditExecutionListItemModification);

  return (
    <>
      {isCreditExecutionListItemRemoveProductModification(state) && (
        <CreditExecutionListItemRejectProduct creditExecutionId={state.creditExecutionId} />
      )}
      {isCreditExecutionListItemRemoveDemandModification(state) && (
        <CreditExecutionListItemRejectDemand creditExecutionId={state.creditExecutionId} demandId={state.demandId} />
      )}
    </>
  );
};
