import * as S from "./SetPwaPortal.styled";
import { Icon, LongArrowDownIcon, RectangleWithArrowIcon } from "../../ui-kit/icons";
import { Text } from "../../ui-kit/typography";
import { Box } from "../../ui-kit/containers/box";
import { PortalContainer } from "../../ui/containers/portal";

interface SetPwaPortalProps {
  onClose: () => void;
}

export const SetPwaPortal = ({ onClose }: SetPwaPortalProps) => {
  return (
    <PortalContainer htmlElementId={import.meta.env.VITE_SET_PWA_CONTAINER_ID ?? ""}>
      <S.Container>
        <S.CloseIcon onClick={onClose} />
        <Text fontColor="primaryLight" lineHeight="1.5" fontWeight={500} fontSize="fz4" textAlign="center">
          Заходите в сервис с экрана «Домой»
        </Text>
        <Text fontColor="primaryLight" lineHeight="1.5" textAlign="center">
          Нажмите на <Icon as={RectangleWithArrowIcon} width={15} height={19} /> и выберите в меню
        </Text>
        <Text fontColor="primaryLight" lineHeight="1.5">
          «На экран „Домой“»
        </Text>
        <Box padding="sm">
          <Icon as={LongArrowDownIcon} width={15} height={19} />
        </Box>
      </S.Container>
    </PortalContainer>
  );
};
