import { useCallback } from "react";
import { CreditWorkingAssetsId } from "../../../../common/types/demand";
import { useAppDispatch } from "../../../../common/store";
import { CreditWorkingAssetsListFilterContextValue } from "../../types/filters/CreditWorkingAssetsListFilterContextValue";
import { GetCreditWorkingAssetsListInputDto } from "../../api/dto/input/GetCreditWorkingAssetsListInputDto";
import { loggingService } from "../../../../common/logging/services/loggingService";
import { getCreditWorkingAssetsSingleListItem } from "../../store/creditWorkingAssetsList.thunks";
import { changeItemCreditWorkingAssetsListItems } from "../../store/items/creditWorkingAssetsListItems.slice";

export const useCreditWorkingAssetsListFetchSingleItem = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    async (creditWorkingAssetsId: CreditWorkingAssetsId, actualFilters: CreditWorkingAssetsListFilterContextValue) => {
      const inputModel: GetCreditWorkingAssetsListInputDto = {
        ...actualFilters,
        page: 1,
        productId: creditWorkingAssetsId,
      };

      try {
        const result = await dispatch(getCreditWorkingAssetsSingleListItem(inputModel)).unwrap();
        dispatch(
          changeItemCreditWorkingAssetsListItems({
            id: creditWorkingAssetsId,
            item: result?.creditWorkingAssetsId === creditWorkingAssetsId ? result : undefined,
          })
        );
      } catch (e) {
        loggingService.logWarn(new Error("Не удалось обновить элемент в списке заявок"));
      }
    },
    [dispatch]
  );
};
