import { AxiosPromise } from "axios";
import ApiResponseModel from "../../../entity/models/ApiResponseModel";
import ApiResultModel from "../../../entity/models/ApiResultModel";
import { lkApiV3 } from "../../../essentials/configs";
import { axiosJWT } from "../apiConfig";

export class FileApiV3 {
  public getUserSign(id: bigint, privateKey?: string): AxiosPromise<ApiResponseModel<ApiResultModel<string>>> {
    return axiosJWT.get(`${lkApiV3()}/File/user-sign/${id}`, {
      params: {
        privateKey: privateKey,
      },
    });
  }

  public getManagerSign(id: bigint, privateKey?: string): AxiosPromise<ApiResponseModel<ApiResultModel<string>>> {
    return axiosJWT.get(`${lkApiV3()}/File/manager-sign/${id}`, {
      params: {
        privateKey: privateKey,
      },
    });
  }
}
