import { useAppSelector } from "../../../../../common/store";
import { useCreditWorkingAssetsCreationScoring } from "../../../hooks/scoring/useCreditWorkingAssetsCreationScoring";
import { selectCreditWorkingAssetsCreationCreating } from "../../../store/creditWorkingAssetsCreation.selectors";
import {
  createCreditWorkingAssetsCreation,
  scoringCreditWorkingAssetsCreation,
} from "../../../store/creditWorkingAssetsCreation.thunks";
import { CreditWorkingAssetsCreationScoringCreateNewFormContent } from "./CreditWorkingAssetsCreationScoringCreateNewFormContent";
import { useCreditWorkingAssetsCreationSubmitAfterScoring } from "../../../hooks/creation/useCreditWorkingAssetsCreationSubmitAfterScoring";
import { CreditWorkingAssetsCreationScoringForm } from "../CreditWorkingAssetsCreationScoringForm";

export const CreditWorkingAssetsCreationScoringCreateNewForm = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const creationData = useAppSelector(selectCreditWorkingAssetsCreationCreating).data!;
  useCreditWorkingAssetsCreationScoring(scoringCreditWorkingAssetsCreation, creationData);

  const { onSubmit } = useCreditWorkingAssetsCreationSubmitAfterScoring(
    createCreditWorkingAssetsCreation,
    creationData
  );

  return (
    <CreditWorkingAssetsCreationScoringForm onSubmit={onSubmit}>
      <CreditWorkingAssetsCreationScoringCreateNewFormContent />
    </CreditWorkingAssetsCreationScoringForm>
  );
};
