import { makeSizeHooks } from "@sideg/ui-kit/core/responsive/hooks/makeSizeHooks";

export { ResponsiveProvider } from "./components/ResponsiveProvider";
export type { ResponsiveProviderProps } from "./components/ResponsiveProvider";

export const { useIsMd, useIsSm, useIsXl, useIsXs, useIsXxl, useIsLg } = makeSizeHooks();
export { useMediaQuery } from "./hooks/useMediaQuery";
export { useResponsiveRange } from "./hooks/useResponsiveRange";
export { useResponsiveContext } from "./hooks/useResponsiveContext";

export { getClosestBreakpointValue } from "./utils/getClosestBreakpointValue";
