import * as S from "./ProductListCardMoreActionsButton.styled";
import { BaseCssProps } from "../../../../../../ui-kit/types";
import { ProductListCardMoreActionWithHandler } from "../../types/ProductListCardActionWithHandler";
import {
  SelectPopupContainer,
  SelectPopupContainerItem,
  useSelectPopupMemo,
} from "../../../../../../ui-kit/form-controls/select";
import { Icon, MoreVertical } from "../../../../../../ui-kit/icons";
import { useProductListCardMoreActionsButtonChangeItem } from "../../hooks/useProductListCardMoreActionsButtonChangeItem";

export interface ProductListCardMoreActionsButtonProps extends BaseCssProps {
  availableActions: ProductListCardMoreActionWithHandler[];
}

export const ProductListCardMoreActionsButton = ({
  className,
  availableActions,
}: ProductListCardMoreActionsButtonProps) => {
  const { onSelectedItemChange, onIsOpenChange } = useProductListCardMoreActionsButtonChangeItem();

  const { isOpen, highlightedIndex, getItemProps, referenceElementProps, referenceProps, floatingProps } =
    useSelectPopupMemo({
      items: availableActions,
      selectedItem: null,
      onSelectedItemChange,
      circularNavigation: true,
      position: "bottom-end",
      offset: [0, 8],
      onIsOpenChange,
    });

  return (
    <S.Container className={className}>
      <S.Button type="button" {...referenceProps}>
        <MoreVertical />
      </S.Button>
      <SelectPopupContainer open={isOpen} {...floatingProps} minWidth={`${referenceElementProps.width}px`}>
        {isOpen &&
          availableActions.map((item, index) => (
            <SelectPopupContainerItem
              active={false}
              highlighted={highlightedIndex === index}
              key={item.key}
              {...getItemProps({ item, index })}
            >
              <Icon stroke="typography.primary" as={item.icon} />
              <span>{item.title}</span>
            </SelectPopupContainerItem>
          ))}
      </SelectPopupContainer>
    </S.Container>
  );
};
