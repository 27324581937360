import * as S from "./ProductCreationPageHeader.desktop.styled";
import { Box } from "../../../ui-kit/containers/box";
import { ProductCreationPageHeaderProps } from "./ProductCreationPageHeaderProps";
import { ProductCreationPageHeaderStage } from "./ProductCreationPageHeaderStage";
import { FullPageContainerHeaderDesktop, FullPageContainerHeaderTitle } from "../../../ui/containers/full-page";
import { BackButton } from "../../../ui/buttons/BackButton";
import { BackLinkButton } from "../../../ui/buttons/BackLinkButton";
import { productCreationStages } from "../../types/ProductCreationStages";

interface ProductCreationPageHeaderDesktopProps extends Required<ProductCreationPageHeaderProps> {
  onBackToPreviousStage: () => void | Promise<void>;
  canBackToPreviousStage: boolean;
}

export const ProductCreationPageHeaderDesktop = ({
  activeStage,
  onBackToPreviousStage,
  from,
  canBackToPreviousStage,
  onPrevStageClick,
  title,
}: ProductCreationPageHeaderDesktopProps) => {
  return (
    <FullPageContainerHeaderDesktop
      leftSide={
        <Box flexGrow={1} flexShrink={1} flexBasis="0%">
          {canBackToPreviousStage ? (
            <BackButton onClick={onBackToPreviousStage} />
          ) : (
            <BackLinkButton linkTo={from.url} text={from.backTitle} />
          )}
        </Box>
      }
    >
      <FullPageContainerHeaderTitle text={title} />
      <S.StagesContainer>
        {productCreationStages.values.map((x) => (
          <ProductCreationPageHeaderStage key={x} stage={x} activeStage={activeStage} onClick={onPrevStageClick} />
        ))}
      </S.StagesContainer>
    </FullPageContainerHeaderDesktop>
  );
};
