import { memo } from "react";
import Skeleton from "react-loading-skeleton";
import { Typography } from "../../../../common/ui-kit/typography";
import { useAppSelector } from "../../../../common/store";
import { isLoading } from "../../../../common/types/state";
import { selectCreditExecutionDetailsDemandsTotal } from "../../store/demands/creditExecutionDetailsDemands.selectors";
import { selectCreditExecutionDetailsProductDetails } from "../../store/product-details/creditExecutionDetailsProductDetails.selectors";
import { declensionHelper } from "../../../../common/helpers/entity";

export interface CreditExecutionDetailsAsideNumberTitleProps {
  className?: string;
}

export const CreditExecutionDetailsAsideNumberTitle = memo(
  ({ className }: CreditExecutionDetailsAsideNumberTitleProps) => {
    const { status, isTransferred, data } = useAppSelector(selectCreditExecutionDetailsProductDetails);
    const totalCount = useAppSelector(selectCreditExecutionDetailsDemandsTotal);

    const showSkeleton = isLoading(status) && (totalCount === 0 || isTransferred);

    return (
      <Typography className={className} fontSize="fz4" lineHeight="defaultLineHeight" fontWeight={700}>
        Заявка № {data?.groupProductName ?? <Skeleton inline width={80} />}&nbsp;
        {showSkeleton && <Skeleton width={50} />}
        {!isTransferred &&
          totalCount > 0 &&
          `в ${declensionHelper.commonDeclensions.bank.nominative(totalCount, true)}`}
      </Typography>
    );
  },
);
