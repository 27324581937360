import styled from "@emotion/styled";

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.sm};
  padding: ${({ theme }) => theme.spaces.sm};
  border-radius: 12px 12px 0 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  max-width: 90%;
  align-self: flex-end;
`;
