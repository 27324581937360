import { createSlice, isAnyOf, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { entityHelper } from "@sideg/helpers";
import { combineSliceNames } from "../../../../common/store/utils";
import { AgencyId } from "../../../../common/types/agencies";
import { IssuingCompanyId } from "../../../../common/types/companies";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import { PublicUserRegistrationAgentDocumentSignState } from "../../types/agent/PublicUserRegistrationAgentDocumentSignState";
import { PublicUserRegistrationAgentStageType } from "../../types/agent/PublicUserRegistrationAgentStageType";
import {
  completeAgencyRegistrationPublicUserRegistration,
  getAgencyPublicUserRegistration,
  PUBLIC_USER_REGISTRATION_SLICE_NAME,
  registerAgentPublicUserRegistration,
} from "../publicUserRegistration.thunks";
import { publicUserRegistrationAgentAdapter } from "./publicUserRegistrationAgent.adapter";
import { publicUserRegistrationAgentMapper } from "./publicUserRegistrationAgent.mapper";
import { PublicUserRegistrationAgentState } from "./publicUserRegistrationAgent.types";

const initialState: PublicUserRegistrationAgentState = {
  status: LoadingStatusEnum.Idle,
  stage: "form",
  data: undefined,
  documents: publicUserRegistrationAgentAdapter.getInitialState(),
};

const slice = createSlice({
  name: combineSliceNames(PUBLIC_USER_REGISTRATION_SLICE_NAME, "agent"),
  reducers: {
    changeStage: (state, action: PayloadAction<PublicUserRegistrationAgentStageType>) => {
      state.stage = action.payload;
    },
    ensureIsActualAgency: (state, action: PayloadAction<AgencyId | undefined>) => {
      if (
        action.payload === undefined ||
        (state.data?.agencyId !== undefined && state.data.agencyId !== action.payload)
      ) {
        return initialState;
      }

      return state;
    },
    changeDocumentSignState: (
      state,
      action: PayloadAction<{
        issuingCompanyId: IssuingCompanyId;
        signState: PublicUserRegistrationAgentDocumentSignState;
      }>,
    ) => {
      const document = state.documents.entities[action.payload.issuingCompanyId];
      if (document !== undefined) {
        document.signState = action.payload.signState;
      }
    },
    changeDocumentSendTime: (
      state,
      action: PayloadAction<{ issuingCompanyId: IssuingCompanyId; time: number | undefined }>,
    ) => {
      const document = state.documents.entities[action.payload.issuingCompanyId];
      if (document !== undefined) {
        document.canSendCodeAfterTime = action.payload.time;
      }
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(completeAgencyRegistrationPublicUserRegistration.fulfilled, (state, action) => {
        if (state.data !== undefined) {
          state.data.email = action.payload.body.email;
          state.data.phoneNumber = entityHelper.getDigitsFromString(action.payload.body.phone);
        }
      })
      .addMatcher(
        isAnyOf(getAgencyPublicUserRegistration.fulfilled, registerAgentPublicUserRegistration.fulfilled),
        (state, { payload }) => {
          state.data = publicUserRegistrationAgentMapper.fromResponse(payload.body);

          publicUserRegistrationAgentAdapter.setAll(
            state.documents,
            publicUserRegistrationAgentMapper.mapDocuments(payload.body),
          );
        },
      )
      .addMatcher(isAsyncThunkAction(getAgencyPublicUserRegistration), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const publicUserRegistrationAgentReducer = slice.reducer;

export const {
  changeStage: changeStagePublicUserRegistrationAgent,
  ensureIsActualAgency: ensureIsActualAgencyPublicUserRegistrationAgent,
  changeDocumentSignState: changeDocumentSignStatePublicUserRegistrationAgent,
  changeDocumentSendTime: changeDocumentSendTimePublicUserRegistrationAgent,
} = slice.actions;
