import { string, StringSchema } from "yup";

export const getFormattedPhoneSchema = (lengthMessage = "Укажите корректный номер телефона"): StringSchema =>
  string()
    .transform((value, initialValue) => {
      return initialValue.replace(/\+|\D/g, "");
    })
    .test("length", lengthMessage, (value) => {
      return value !== undefined && value.length === 11;
    })
    .test("format", "Номер телефона должен начинаться на +7", (value) => {
      return value !== undefined && value.slice(0, 1) === "7";
    })
    .required("Укажите номер телефона");
