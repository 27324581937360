import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import { Theme } from "@sideg/ui-kit";
import { DemandQueueTaskVariant } from "../../types/DemandQueueTaskVariant";
import { Color } from "../../../../common/ui-kit/theme";

type TaskVariant = { variant: DemandQueueTaskVariant };

const getPrimaryColorByVariant = (variant: DemandQueueTaskVariant, theme: Theme): Color => {
  switch (variant) {
    case "active":
      return theme.colors.primary;
    case "completed":
      return theme.colors.secondary;
    case "successful":
      return theme.palette.status.success;
    default:
      return theme.colors.secondary;
  }
};

const getBlockFilterByVariant = (variant: DemandQueueTaskVariant): CSSObject["filter"] => {
  switch (variant) {
    case "active":
    case "successful":
      return "0px 4px 24px rgba(45, 113, 187, 0.22)";
    case "completed":
      return "0px 4px 24px rgba(144, 144, 144, 0.22)";
    default:
      return "unset";
  }
};

export const Article = styled.article<TaskVariant & { isFocused: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${({ theme, variant }) => getPrimaryColorByVariant(variant, theme)};
  box-shadow: ${({ variant }) => getBlockFilterByVariant(variant)};
  background-color: ${({ theme }) => theme.palette.background.light};
  outline-offset: ${({ theme }) => theme.spaces.xs};
  outline: ${({ theme, isFocused }) =>
    isFocused === true ? `4px solid ${theme.palette.status.warning}` : `0 solid transparent`};
  transition: outline 0.5s ease-in-out;
`;

export const Header = styled.header<TaskVariant>`
  background-color: ${({ theme, variant }) => getPrimaryColorByVariant(variant, theme)};
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.lg};
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spaces.md};
  min-height: 2rem;
`;

export const Title = styled.span`
  flex: 1;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Time = styled.time`
  opacity: 0.5;
  flex-grow: 0;
  flex-shrink: 0;
`;
