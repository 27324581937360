export class AppVersion {
  public readonly version: string;

  private static partsSeparator = ".";

  private static partsCount = 3;

  private readonly parts: number[];

  constructor(version: string | undefined) {
    if (!AppVersion.isValid(version)) {
      throw new Error(`Невалидная версия приложения - ${version}`);
    }

    this.version = version;
    this.parts = AppVersion.getParts(version);
  }

  public compare = (version: string | undefined): "older" | "same" | "newer" | "invalid" => {
    if (!AppVersion.isValid(version)) {
      return "invalid";
    }

    const parts = AppVersion.getParts(version);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.parts.length; i++) {
      if (this.parts[i] > parts[i]) {
        return "newer";
      }

      if (this.parts[i] < parts[i]) {
        return "older";
      }
    }

    return "same";
  };

  private static getParts = (version: string): number[] => {
    return version.split(AppVersion.partsSeparator).map(Number);
  };

  public static isValid = (version: string | undefined): version is string => {
    if (version === undefined) {
      return false;
    }

    const parts = version.split(AppVersion.partsSeparator);

    return parts.length === AppVersion.partsCount && parts.every((x) => Number(x).toString() === x);
  };

  public toString = () => {
    return this.version;
  };
}
