import Skeleton from "react-loading-skeleton";
import { currencyHelper, isValidUrl } from "@sideg/helpers";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../../../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { Text } from "../../../../../common/ui-kit/typography";
import { Link } from "../../../../../common/ui-kit/links";
import { DotSeparatedRowContainer } from "../../../../../common/ui-kit/containers/dot-separated-row";
import { getFederalLawTypeTitle } from "../../../../../common/types/demand";

export const BankGuaranteeDetailsShortDetails = () => {
  const { data } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);

  return (
    <StackPanel direction="column" gap="sm">
      <Text lineHeight="1.4" fontWeight={700} fontSize={1.375} fontColor="primary">
        {data?.guaranteeType.title ?? <Skeleton width={300} />}
      </Text>
      <StackPanel gap="none" direction="column">
        <DotSeparatedRowContainer dotColor="typography.primary">
          <Text fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight">
            {data?.federalLaw ? getFederalLawTypeTitle(data.federalLaw.code) : <Skeleton width={80} inline />}
          </Text>
          {(!data || data.purchaseLink || data.purchaseNumber) && (
            <Text fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight">
              {data ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {isValidUrl(data.purchaseLink) ? (
                    <Link href={data.purchaseLink} target="_blank" rel="noreferrer noopener">
                      {data.purchaseNumber ? `№ ${data.purchaseNumber}` : "Ссылка на закупку"}
                    </Link>
                  ) : (
                    `${data.purchaseNumber ? `№ ${data.purchaseNumber}` : ""}`
                  )}
                </>
              ) : (
                <Skeleton width={90} inline />
              )}
            </Text>
          )}
        </DotSeparatedRowContainer>
        <Text fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight">
          {data?.guaranteeSum !== undefined ? (
            `на ${currencyHelper.getCurrencyString(data.guaranteeSum)}`
          ) : (
            <Skeleton inline width={100} />
          )}
        </Text>
      </StackPanel>
      <DotSeparatedRowContainer dotColor="typography.primary">
        <Text fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight">
          {data ? data.client?.name : <Skeleton width={100} inline />}
        </Text>
        <Text fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="primaryCaption">
          {data ? `ИНН ${data.client?.taxpayerNumber}` : <Skeleton width={100} inline />}
        </Text>
      </DotSeparatedRowContainer>
    </StackPanel>
  );
};
