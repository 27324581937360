import { DemandQueueTaskProvidedFile } from "./DemandQueueTaskProvidedFile";
import { DemandQueueTasksActiveRequestedDocument } from "./DemandQueueTasksActiveRequestedDocument";

export interface DemandQueueTaskActiveAction {
  message?: string;
  providedFiles?: DemandQueueTaskProvidedFile[];
  requestedFiles?: DemandQueueTasksActiveRequestedDocument[];
}

export const isDemandQueueTaskActiveActionHasMessageOrProvidedFiles = (
  action: DemandQueueTaskActiveAction | undefined,
): action is DemandQueueTaskActiveAction => {
  return action !== undefined && ((action.providedFiles?.length ?? 0) > 0 || Boolean(action.message));
};
