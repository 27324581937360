import { useCallback, useMemo } from "react";
import { getBankGuaranteeExtractFromRegistry } from "../store/bankGuaranteeExtractFromRegistry.thunks";
import { isLoading } from "../../../common/types/state";
import { useApiFileSaver, useApiMutation } from "../../../common/hooks";
import { DemandId } from "../../../common/types/demand";
import { BankGuaranteeFileRegistryStatusType } from "../../common/types";
import { useAppDispatch } from "../../../common/store";
import { onBankGuaranteeExtractFromRegistryStatusChanged } from "../store/bankGuaranteeExtractFromRegistry.actions";

export const useBankGuaranteeExtractFromRegistry = (
  demandId: DemandId,
  statusType: Extract<BankGuaranteeFileRegistryStatusType, "AvailableForDownload" | "Downloaded">
) => {
  const dispatch = useAppDispatch();
  const saveFile = useApiFileSaver();
  const { error, mutate, status } = useApiMutation(getBankGuaranteeExtractFromRegistry, {
    errorMessagePlaceholder:
      "Произошла непредвиденная ошибка. Попробуйте еще раз или свяжитесь с персональным менеджером",
  });

  const extractFromRegistry = useCallback(async () => {
    if (isLoading(status)) {
      return;
    }

    const result = await mutate({ demandId });

    if (result?.body) {
      saveFile(result.body);

      if (statusType === "AvailableForDownload") {
        dispatch(onBankGuaranteeExtractFromRegistryStatusChanged({ demandId, newStatus: "Downloaded" }));
      }
    }
  }, [demandId, dispatch, saveFile, mutate, status, statusType]);

  return useMemo(() => [status, extractFromRegistry, error] as const, [status, extractFromRegistry, error]);
};
