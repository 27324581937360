import { BankGuaranteeId, DemandId } from "../../../../../common/types/demand";
import { useBankGuaranteeListReissueModal } from "../../../hooks/modification/useBankGuaranteeListReissueModal";
import { BankGuaranteeReissueResponsiveView } from "../../../../reissue";

export interface BankGuaranteeListItemReissueGuaranteeViewProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
}

export const BankGuaranteeListItemReissueGuaranteeView = ({
  guaranteeId,
  demandId,
}: BankGuaranteeListItemReissueGuaranteeViewProps) => {
  const { isModalOpen, closeModal, onSuccess } = useBankGuaranteeListReissueModal(guaranteeId);

  return (
    <BankGuaranteeReissueResponsiveView
      guaranteeId={guaranteeId}
      demandId={demandId}
      onSuccess={onSuccess}
      isOpen={isModalOpen}
      onClose={closeModal}
    />
  );
};
