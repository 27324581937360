import * as React from "react";
import { EntityId } from "@reduxjs/toolkit";
import styled from "@emotion/styled";
import { InfiniteScroll } from "../../../../../ui-kit/infinite-scroll";
import { DotsLoader } from "../../../../../ui-kit/loaders/DotsLoader";
import { ButtonLink } from "../../../../../ui-kit/buttons/ButtonLink";
import { RootState, useAppSelector } from "../../../../../store";
import { BasePaginatedState } from "../../../../../types/state";
import { lightenDarkenColor } from "../../../../../ui-kit/utils";
import { useAppPagination } from "../hooks/useAppPagination";

const StyledInformationDiv = styled.div`
  padding-top: ${({ theme }) => theme.spaces.xxl};
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
  font-size: ${({ theme }) => theme.font.fz1};
`;

const StyledShowFirstPageButton = styled.button`
  cursor: pointer;
  border: none;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  padding: 4px 10px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.info};
  border-radius: 7px;
  width: auto;
  margin: 0 auto ${({ theme }) => theme.spaces.lg};

  &:hover {
    background-color: ${({ theme }) => lightenDarkenColor(theme.colors.info, 5)};
  }
`;

export interface AppInfinityScrollTitles {
  end: string;
  hasNoLoadedItems: string;
  error?: string;
  navigateToFirstPage?: string;
  nextPageLoading?: string;
}

export interface AppInfinityScrollProps {
  page: number;
  paginatedStateSelector: (state: RootState) => BasePaginatedState;
  idsSelector: (state: RootState) => EntityId[];
  titles: AppInfinityScrollTitles;
  hasPreviousPage?: boolean;
  children: React.ReactNode;
  onPreviousPageButtonClick?: () => void | Promise<void>;
  onPageChanged: () => void | Promise<void>;
  onErrorReloadButtonClick: () => void | Promise<void>;
  emptyLoader: React.ReactNode;
}

export const AppInfinityScroll = ({
  page,
  hasPreviousPage,
  children,
  paginatedStateSelector,
  idsSelector,
  titles,
  onPreviousPageButtonClick,
  onPageChanged,
  onErrorReloadButtonClick,
  emptyLoader,
}: AppInfinityScrollProps) => {
  const { status, pagination } = useAppSelector(paginatedStateSelector);
  const ids = useAppSelector(idsSelector);
  const { isEmptyLoading, isFailed, isEmptyList, isLoading, hasNoLoadedItems, hasMore } = useAppPagination(
    status,
    ids,
    pagination,
  );

  return (
    <>
      {hasPreviousPage === true && (
        <StyledShowFirstPageButton onClick={onPreviousPageButtonClick}>
          {titles.navigateToFirstPage ?? "Перейти к началу"}
        </StyledShowFirstPageButton>
      )}
      {isEmptyLoading && emptyLoader}
      {!isEmptyList && (
        <InfiniteScroll
          hasMore={hasMore}
          loading={isLoading}
          initialPage={page}
          loader={
            <StyledInformationDiv>
              {titles.nextPageLoading ?? "Загружаем следующую страницу"} <DotsLoader />
            </StyledInformationDiv>
          }
          endMessage={
            !isEmptyList && !isFailed && page > 1 && <StyledInformationDiv>{titles.end}</StyledInformationDiv>
          }
          onPageChanged={onPageChanged}
        >
          {children}
        </InfiniteScroll>
      )}
      {hasNoLoadedItems && titles.hasNoLoadedItems && (
        <StyledInformationDiv>{titles.hasNoLoadedItems}</StyledInformationDiv>
      )}
      {isFailed && (
        <StyledInformationDiv>
          {titles.error ?? "Ошибка при загрузке данных"}
          <div>
            <ButtonLink type="button" onClick={onErrorReloadButtonClick}>
              Повторить
            </ButtonLink>
          </div>
        </StyledInformationDiv>
      )}
    </>
  );
};
