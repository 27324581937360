import { useCallback } from "react";
import { useCreateFormElementErrorDataAttribute } from "@sideg/ui-kit/atoms/form-controls/common/hooks";

export const useSelectFormElementErrorElements = () => {
  const [attribute, value] = useCreateFormElementErrorDataAttribute(true);

  return useCallback(
    (parent: HTMLElement) => {
      return parent.querySelectorAll(`[${attribute}="${value}"]`);
    },
    [attribute, value],
  );
};
