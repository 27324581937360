import * as React from "react";
import { useEffect, useState } from "react";
import "./AgentReport.scss";
import Tabs from "../../components/controls-old/tabs/Tabs";
import Button, { ButtonColor, ButtonType } from "../../components/controls-old/button/Button";
import { connect } from "react-redux";
import TabType, { parseTabType } from "../../models/TabType";
import { IAgentReportListState } from "../../store/reducers/agentReportReducer";
import AgentReportList from "./AgentReportList";
import { AgentReportStatus } from "../../store/actions/agentReportActionsTypes";
import Loader from "../../components/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { tabsModel } from "./AgentReportData";
import { agentReportListInitial, agentReportListLoad } from "../../store/actions/agentReportActions";
import AgentReportCreateModalWrapper from "../../components/modal/pages/agent-report/create/AgentReportCreateModalWrapper";
import TabsItem from "../../components/controls-old/tabs/TabsModel";
import Pagination from "../../components/controls-old/pagination/Pagination";
import { AgentReportListCounterDefaultState, AgentReportListStatus } from "./AgentReportListTypes";
import { agentReportListLoadCounter } from "./AgentReportAction";
import DemandCounterModel from "../../models/DemandCounterModel";
import { usePageTitle } from "../../domains/common/navigation/page-title";
import { AppLayoutPageWithFooterContainer } from "../../domains/common/ui/layout/app-layout-containers";
import { useAppDispatch } from "../../domains/common/store";

interface IAgentReportProps {
  state?: IAgentReportListState;
}

interface IPageState {
  tab: TabType;
  page: number;
}

const AgentReport: React.FC<IAgentReportProps> = (props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigator = useNavigate();

  const itemsPerPage = 20;
  const [viewCreate, setViewCreate] = useState(false);
  const [tabs, setTabs] = useState<TabsItem[]>();
  const [pageState, setPageState] = useState<IPageState>();
  const [counter, setCounter] = useState(AgentReportListCounterDefaultState());

  usePageTitle("Отчёты об оказанных услугах");

  useEffect(() => {
    dispatch(agentReportListInitial());
  }, []);

  useEffect(() => {
    dispatch(agentReportListLoadCounter(setCounter));
  }, [location]);

  useEffect(() => {
    if (counter.status === AgentReportListStatus.Success && counter.data) {
      setTabItems(counter.data);
    }
  }, [counter]);

  useEffect(() => {
    loadList();
  }, [pageState]);

  const setTabItems = (counter?: DemandCounterModel) => {
    const tbs = tabsModel(counter);
    let currentTab = TabType.Active;
    let currentPage = 1;
    const query = new URLSearchParams(location.search);
    const tabQuery = query.get("tab");
    const pageQuery = query.get("page");

    if (tabQuery) {
      currentTab = parseTabType(tabQuery);
    }

    if (pageQuery) {
      currentPage = parseInt(pageQuery);
    }

    tbs.forEach((tbs) => {
      tbs.isActive = tbs.tab === currentTab;
    });

    setTabs(tbs);
    setPageState({
      tab: currentTab,
      page: currentPage,
    });
  };

  const handleChangeTab = (tab: TabType) => {
    setHistory(tab);
  };

  const handleChangePage = (page: number) => {
    const currentPageState = pageState!!;
    setHistory(currentPageState.tab, page);
  };

  const setHistory = (tab: TabType, page?: number) => {
    const query = new URLSearchParams();
    query.append("tab", tab);
    if (page) {
      query.append("page", page.toString());
    }
    navigator("/agent-report?" + query.toString());
  };

  const loadList = () => {
    if (pageState) {
      dispatch(agentReportListLoad(pageState.tab, pageState.page, itemsPerPage));
    }
  };

  const handleReloadPage = () => {
    handleChangeTab(TabType.Active);
  };

  return (
    <AppLayoutPageWithFooterContainer>
      {
        <div className="agent-report">
          <div className="agent-report__title">
            <i className="fal fa-info-circle agent-report__title-icon" />
            Акт сверки может быть согласован только после подтверждения о выпуске со стороны банка.
          </div>

          {tabs && (
            <div className="agent-report__header">
              <div className="agent-report__header-tabs">
                <Tabs items={tabs} onChangeItem={handleChangeTab} />
              </div>
              <div className="agent-report__header-create">
                <Button
                  type={ButtonType.Submit}
                  isDisabled={false}
                  color={ButtonColor.Green}
                  onClick={() => setViewCreate(true)}
                >
                  <i className="fal fa-plus" />
                  Запросить акт сверки
                </Button>
              </div>
            </div>
          )}
        </div>
      }
      {(props.state?.status === AgentReportStatus.Processing ||
        counter.status === AgentReportListStatus.Processing) && <Loader />}
      {counter.status === AgentReportListStatus.Failed && counter.errorMessage && (
        <div className="alert alert_danger">{counter.errorMessage}</div>
      )}
      {props.state?.status === AgentReportStatus.Failed && (
        <div className="alert alert_danger">{props.state.errorMessage}</div>
      )}
      {props.state?.status === AgentReportStatus.Success && props.state.data && (
        <AgentReportList data={props.state.data} />
      )}
      {props.state?.status === AgentReportStatus.Success && props.state.data && (
        <div className="agent-report__pagination">
          <Pagination data={props.state.data} onChangePage={handleChangePage} />
        </div>
      )}
      {viewCreate && <AgentReportCreateModalWrapper onClose={() => setViewCreate(false)} onReload={handleReloadPage} />}
    </AppLayoutPageWithFooterContainer>
  );
};

const mapStateToProps = (state: any, ownState: any): IAgentReportProps => {
  return {
    ...ownState,
    state: state.agentReportReducer.list,
  };
};

export default connect(mapStateToProps)(AgentReport);
