import { dateTimeHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { CreditExecutionId } from "../../../../../../common/types/demand";
import { NavigationState } from "../../../../../../common/navigation/navigation-state";
import { useAppSelector } from "../../../../../../common/store";
import { CreditExecutionListItemDesktopCard } from "./card/CreditExecutionListItemDesktopCard";
import {
  selectCreditExecutionListItemsById,
  selectCreditExecutionListItemsClientById,
} from "../../../../store/items/creditExecutionListItems.selectors";
import { CreditExecutionListItemDetails } from "../details/CreditExecutionListItemDetails";
import { CreditExecutionListItemDemandsDesktop } from "./demands/CreditExecutionListItemDemands.desktop";
import { CreditExecutionListItemIssueDesktop } from "./demands/issue/CreditExecutionListItemIssueDesktop";

export interface CreditExecutionListItemDesktopProps extends BaseCssProps {
  creditExecutionId: CreditExecutionId;
  from: NavigationState;
}

export const CreditExecutionListItemDesktop = ({ creditExecutionId, from }: CreditExecutionListItemDesktopProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const item = useAppSelector((state) => selectCreditExecutionListItemsById(state, creditExecutionId))!;
  const client = useAppSelector((state) => selectCreditExecutionListItemsClientById(state, item.clientId));

  return (
    <CreditExecutionListItemDesktopCard
      clientName={client?.name}
      clientTaxpayerNumber={client?.taxpayerNumber}
      numberAndDate={`Заявка № ${item.groupProductName} • ${dateTimeHelper.format(
        new Date(item.dateAdded),
        "dayShortMonthYear",
      )}`}
      details={<CreditExecutionListItemDetails item={item} />}
    >
      {item.issuedDemandId === undefined && (
        <CreditExecutionListItemDemandsDesktop creditExecutionId={creditExecutionId} from={from} />
      )}
      {item.issuedDemandId !== undefined && (
        <CreditExecutionListItemIssueDesktop
          creditExecutionId={creditExecutionId}
          demandId={item.issuedDemandId}
          from={from}
        />
      )}
    </CreditExecutionListItemDesktopCard>
  );
};
