import { createEntityAdapter } from "@reduxjs/toolkit";
import { BankGuaranteeListItemGuarantee } from "../../types/item/BankGuaranteeListItemGuarantee";
import { BankGuaranteeListItemDemand } from "../../types/item/BankGuaranteeListItemDemand";
import { BankGuaranteeListItemClient } from "../../types/item/BankGuaranteeListItemClient";

export const bankGuaranteeListItemsGuaranteesAdapter = createEntityAdapter<BankGuaranteeListItemGuarantee>({
  selectId: (x) => x.guaranteeId,
});

export const bankGuaranteeListItemDemandsAdapter = createEntityAdapter<BankGuaranteeListItemDemand>({
  selectId: (x) => x.demandId,
});

export const bankGuaranteeListItemClientAdapter = createEntityAdapter<BankGuaranteeListItemClient>({
  selectId: (x) => x.id,
});
