import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditExecutionRejectionDemand } from "../../../../common/rejection";
import { RejectButton } from "../../../../../common/ui/buttons/reject-button";
import { CreditExecutionId, DemandId } from "../../../../../common/types/demand";
import { useCreditExecutionDetailsRejection } from "../../../hooks/useCreditExecutionDetailsRejection";

export interface CreditExecutionDetailsDemandHeaderRejectionButtonProps extends BaseCssProps {
  creditExecutionId: CreditExecutionId;
  demandId: DemandId;
  bankName: string;
  groupProductName: string | number;
}

export const CreditExecutionDetailsDemandHeaderRejectionButton = ({
  creditExecutionId,
  demandId,
  bankName,
  groupProductName,
  className,
}: CreditExecutionDetailsDemandHeaderRejectionButtonProps) => {
  const { isModalOpen, openModal, closeModal, afterSuccess, panelKey } = useCreditExecutionDetailsRejection(
    creditExecutionId,
    demandId,
  );

  return (
    <>
      <RejectButton className={className} onClick={openModal} size="default">
        <ThemeMobileDesktopView mobile="Отменить" desktop="Отменить заявку" />
      </RejectButton>
      <CreditExecutionRejectionDemand
        creditExecutionId={creditExecutionId}
        demandId={demandId}
        afterSuccess={afterSuccess}
        isOpen={isModalOpen}
        onClose={closeModal}
        groupProductName={groupProductName}
        bankName={bankName}
        panelKey={panelKey}
      />
    </>
  );
};
