import * as React from "react";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { StackPanel } from "../../../../../../../common/ui-kit/containers/stack-panel";
import { Text } from "../../../../../../../common/ui-kit/typography";
import { ProductListCardContainerMobile } from "../../../../../../../common/ui/product/list/card";

export interface BankGuaranteeListItemMobileCardProps extends BaseCssProps {
  numberAndDate: React.ReactNode;
  actionsButton: React.ReactNode;
  clientName: React.ReactNode;
  guaranteeType: React.ReactNode;
  children?: React.ReactNode;
  footer: React.ReactNode;
}

export const BankGuaranteeListItemMobileCard = ({
  numberAndDate,
  guaranteeType,
  footer,
  actionsButton,
  clientName,
  children,
  className,
}: BankGuaranteeListItemMobileCardProps) => {
  return (
    <ProductListCardContainerMobile
      header={
        <>
          <StackPanel gap="xs" direction="row" alignItems="center" justifyContent="space-between">
            <Text as="h3" fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight">
              {numberAndDate}
            </Text>
            {actionsButton}
          </StackPanel>
          <StackPanel gap="xs" direction="column">
            <Text fontSize="fz4" lineHeight="defaultLineHeight" fontWeight={700}>
              {clientName}
            </Text>
            <Text fontWeight={500} fontSize="fz3" lineHeight="1.4">
              {guaranteeType}
            </Text>
          </StackPanel>
        </>
      }
      footer={footer}
      className={className}
    >
      {children}
    </ProductListCardContainerMobile>
  );
};
