import { useCallback } from "react";
import { useApiMutation } from "../../../../common/hooks";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { DocumentTypeId } from "../../../../common/types/documents";
import { pushSuccessGlobalToast } from "../../../../global/toasts";
import { uploadGroupDocumentBankGuaranteeDetailsDemand } from "../../store/bankGuaranteeDetails.thunks";
import { selectBankGuaranteeDetailsDocumentById } from "../../store/documents/bankGuaranteeDetailsDocuments.selectors";
import { markAsUploadedBankGuaranteeDetailsDocuments } from "../../store/documents/bankGuaranteeDetailsDocuments.slice";
import { useBankGuaranteeDetailsLayoutContext } from "../layout/useBankGuaranteeDetailsLayoutContext";

export const useBankGuaranteeDetailsDocumentsHandleDrop = (documentId: DocumentTypeId) => {
  const dispatch = useAppDispatch();

  const document = useAppSelector((state) => selectBankGuaranteeDetailsDocumentById(state, documentId));

  const { status, error, mutate } = useApiMutation(uploadGroupDocumentBankGuaranteeDetailsDemand, {
    errorMessagePlaceholder: "Файл не загружен. Повторите попытку",
  });

  const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (document?.id !== undefined && document?.requestedDocType !== undefined && acceptedFiles.length === 1) {
        const result = await mutate({
          guaranteeId,
          id: document.id,
          content: acceptedFiles[0],
          requestedDocType: document.requestedDocType,
        });

        if (result !== undefined) {
          dispatch(pushSuccessGlobalToast("Файл прикреплен", 2_000));
          dispatch(markAsUploadedBankGuaranteeDetailsDocuments(document.id));
        }
      }
    },
    [dispatch, document?.id, document?.requestedDocType, guaranteeId, mutate]
  );

  return {
    document,
    status,
    error,
    handleDrop,
  };
};
