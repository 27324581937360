import { CreateSettlementAndCashCreationInputDto } from "./dto/input/CreateSettlementAndCashCreationInputDto";
import { clientV2, clientV3 } from "../../../common/api";
import { CreateSettlementAndCashCreationOutputDto } from "./dto/output/CreateSettlementAndCashCreationOutputDto";
import { GetBanksSettlementAndCashCreationOutputDto } from "./dto/output/GetBanksSettlementAndCashCreationOutputDto";

export const settlementAndCashCreationApi = {
  create: (model: CreateSettlementAndCashCreationInputDto) =>
    clientV3.postForm<CreateSettlementAndCashCreationInputDto, CreateSettlementAndCashCreationOutputDto>(
      "MiscProduct/SettlementAndCash",
      model,
    ),
  getBanks: () =>
    clientV2.post<undefined, GetBanksSettlementAndCashCreationOutputDto>(
      "/Information/GetBanksForProduct?productType=7",
      undefined,
    ),
};
