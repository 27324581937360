import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useBankGuaranteeDetailsLayoutContext } from "../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { ProductDetailsDocumentsWrapper } from "../../../../common/product-details";
import { Redirect } from "../../../../common/urls/internal/components";
import { useBankGuaranteeDetailsDocuments } from "../../hooks/documents/useBankGuaranteeDetailsDocuments";
import { BankGuaranteeDetailsDocumentsItem } from "./BankGuaranteeDetailsDocumentsItem";

export type BankGuaranteeDetailsDocumentsProps = BaseCssProps;

export const BankGuaranteeDetailsDocuments = ({ className }: BankGuaranteeDetailsDocumentsProps) => {
  const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();
  const { ids, isNeedToHide, status } = useBankGuaranteeDetailsDocuments();

  if (isNeedToHide) {
    return <Redirect to={(x) => x.bankGuarantee.details.guarantee} params={{ guaranteeId }} withSearch />;
  }

  return (
    <ProductDetailsDocumentsWrapper className={className} ids={ids} status={status}>
      {(x) => <BankGuaranteeDetailsDocumentsItem key={x} documentId={x} />}
    </ProductDetailsDocumentsWrapper>
  );
};
