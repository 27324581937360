import { useEffect } from "react";
import { Form, Formik } from "formik";
import { ProductRejectionFormContent } from "./ProductRejectionFormContent";
import { productRejectionFormValidationSchema } from "../../validation/productRejectionFormValidationSchema";
import { Box } from "../../../ui-kit/containers/box";
import { useAppDispatch } from "../../../store";
import { RejectionProductType } from "../../types/RejectionProductType";
import { getRejectionReasonsProductRejection } from "../../store/productRejection.thunks";
import { ProductRejectionSubmitHandler } from "../../types/ProductRejectionSubmitHandler";

interface ProductRejectionFormProps {
  product: RejectionProductType;
  handleFormSubmit: ProductRejectionSubmitHandler;
  error?: string;
}

export const ProductRejectionForm = ({ product, handleFormSubmit, error }: ProductRejectionFormProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRejectionReasonsProductRejection({ productId: product }));
  }, [dispatch, product]);

  return (
    <Formik
      initialValues={productRejectionFormValidationSchema.getDefault()}
      validationSchema={productRejectionFormValidationSchema}
      onSubmit={handleFormSubmit}
    >
      <Box display="flex" flexDirection="column" flexGrow={1} gap="xxl" as={Form}>
        <ProductRejectionFormContent product={product} error={error} />
      </Box>
    </Formik>
  );
};
