import { ReactNode } from "react";
import { ProductListItemEllipsisSpan } from "../../../../common/ui/product/list/product-list-item";
import { Typography } from "../../../../common/ui-kit/typography";
import { BaseCssProps } from "../../../../common/ui-kit/types";

const ProductListItemColumnInfo = Typography.withComponent(ProductListItemEllipsisSpan);

export interface QueueTasksListItemProductColumnInfoWrapperProps extends BaseCssProps {
  children: ReactNode;
}

export const QueueTasksListItemProductColumnInfoWrapper = ({
  children,
  className,
}: QueueTasksListItemProductColumnInfoWrapperProps) => {
  return (
    <ProductListItemColumnInfo
      className={className}
      fontWeight={500}
      fontColor="typography.medium"
      fontFeatureSettings={`"tnum"`}
    >
      {children}
    </ProductListItemColumnInfo>
  );
};
