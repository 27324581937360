import styled from "@emotion/styled";
import { CommonInputIcon } from "@sideg/ui-kit/atoms/form-controls/input/common/components/CommonInput";
import { CancelIcon, SearchIcon } from "@sideg/ui-kit/atoms/icons";
import { InputBoxProps } from "@sideg/ui-kit/atoms/form-controls/common/types";

export const SearchInputIcon = CommonInputIcon.withComponent(SearchIcon);

export const CancelInputIcon = CommonInputIcon.withComponent(CancelIcon);

export const IconButton = styled.button<InputBoxProps>`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  border: none;
  margin: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.sm} 0 ${({ theme }) => theme.spacing.sm};
`;
