import { createAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../common/store/utils";
import { GLOBAL_SLICE_NAME } from "../../common/store";
import { ToastType, toastTypes } from "../../../common/ui-kit/containers/toast";
import { globalToastConfig } from "../configs";
import { generateToastId } from "../types/ToastId";
import { GlobalToastState } from "./globalToasts.types";
import { LoadingStatusEnum } from "../../../common/types/state";

export const GLOBAL_TOASTS_SLICE_NAME = combineSliceNames(GLOBAL_SLICE_NAME, "toasts");

const prepareGlobalToast = (type: ToastType, text: string, duration?: number): GlobalToastState => ({
  type,
  text,
  duration: duration ?? globalToastConfig.defaultDuration,
  id: generateToastId(),
  status: LoadingStatusEnum.Idle,
});

const makePushToastAction = (type: ToastType) =>
  createAction(combineSliceNames(GLOBAL_TOASTS_SLICE_NAME, "push", type), (text: string, duration?: number) => {
    return { payload: prepareGlobalToast(type, text, duration) };
  });

export const {
  error: pushErrorGlobalToast,
  warning: pushWarningGlobalToast,
  custom: pushCustomGlobalToast,
  success: pushSuccessGlobalToast,
} = toastTypes.reduce(
  (accum, type) => {
    accum[type] = makePushToastAction(type);

    return accum;
  },
  {} as { [K in ToastType]: ReturnType<typeof makePushToastAction> },
);
