import styled from "@emotion/styled";
import { getInputBoxPalette } from "@sideg/ui-kit/atoms/form-controls/input/common/utils/getInputBoxPalette";
import { mapThemeTypographyVariantToCssObject } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypographyVariantTokenValue";
import { InputBoxProps } from "@sideg/ui-kit/atoms/form-controls/common/types";
import { ControlSize } from "@sideg/ui-kit/common/types";

export const Container = styled.div`
  word-wrap: break-word;
  width: 100%;
`;

export const Label = styled.label<{ size: ControlSize }>`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.palette.dark.neutral};
  ${({ theme, size }) =>
    size === "medium"
      ? mapThemeTypographyVariantToCssObject("desktop.text16Medium", theme)
      : mapThemeTypographyVariantToCssObject("desktop.small14Medium", theme)};

  & > span {
    color: ${({ theme }) => theme.palette.light.signal.text.error};
  }
`;

export const InputElement = styled.div`
  display: block;
  position: relative;
`;

export const InputBox = styled.div<InputBoxProps>`
  word-wrap: break-word;
  letter-spacing: 0;
  align-items: center;
  background-clip: padding-box;
  background-color: ${({ error, theme, disabled }) =>
    getInputBoxPalette(theme, error, disabled, false, false).background};
  border: 1px solid ${({ error, theme, disabled }) => getInputBoxPalette(theme, error, disabled, false, false).border};
  border-radius: 4px;
  display: flex;
  position: relative;
  text-align: left;
  transition-duration: 0.25s;
  transition-property: background-color, border-color;
  transition-timing-function: ease;
  width: 100%;

  &:focus-within {
    background-color: ${({ error, theme, disabled }) =>
      getInputBoxPalette(theme, error, disabled, true, false).background};
    border-color: ${({ error, theme, disabled }) => getInputBoxPalette(theme, error, disabled, true, false).border};
  }

  &:hover:not(:focus-within) {
    background-color: ${({ error, theme, disabled }) =>
      getInputBoxPalette(theme, error, disabled, false, true).background};
    border-color: ${({ error, theme, disabled }) => getInputBoxPalette(theme, error, disabled, false, true).border};
  }
`;

export const InputBoxMain = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 100%;
`;
