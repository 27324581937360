import { string } from "yup";
import { taxpayerNumberValidator } from "../utils/taxpayerNumberValidator";

export const getTaxpayerNumberSchema = (length: 10 | 12 | undefined = undefined) => {
  const base =
    length === undefined
      ? string().test(
          "len",
          "ИНН должен содержать 10 или 12 цифр",
          (value) => !value || value.length === 10 || value.length === 12
        )
      : string().length(length, `ИНН должен содержать ${length} цифр`);

  return base.test("valid", "Укажите корректный ИНН", (value) => !value || taxpayerNumberValidator(value));
};
