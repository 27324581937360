import styled from "@emotion/styled";

export const ProductDetailsAsideNavigationCardCounter = styled.div<{ variant: "info" | "accent" }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;
  color: ${({ theme, variant }) =>
    variant === "accent" ? theme.palette.typography.primaryLight : theme.colors.primary};
  background-color: ${({ theme, variant }) =>
    variant === "accent" ? theme.palette.status.error : theme.palette.control.primaryLight};
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
