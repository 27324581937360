import styled from "@emotion/styled";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";

export const SlideFromBottomToTopTransition = styled(CSSTransition)<CSSTransitionProps>`
  &.enter {
    transform: translateY(100%);
  }

  &.enter-active {
    transform: translateY(0px);
    transition: transform ${({ timeout }) => timeout}ms ease-in;
  }

  &.exit {
    transform: translateY(0px);
  }

  &.exit-active {
    transform: translateY(100%);
    transition: transform ${({ timeout }) => timeout}ms ease-in;
  }

  &.exit-done {
    transform: translateY(-100%);
  }
`;
