import { Form, Formik } from "formik";
import { widgetsCalculatorValidationSchema } from "../../validation/widgetsCalculatorValidationSchema";
import { Box } from "../../../../ui-kit/containers/box";
import { WidgetsCalculatorFormPeriodBlock } from "./WidgetsCalculatorFormPeriodBlock";
import { WidgetsCalculatorFormAmountBlock } from "./WidgetsCalculatorFormAmountBlock";

export const WidgetsCalculatorForm = () => {
  const handleFormSubmit = () => {
    //
  };

  return (
    <Formik
      initialValues={widgetsCalculatorValidationSchema.getDefault()}
      validationSchema={widgetsCalculatorValidationSchema}
      onSubmit={handleFormSubmit}
    >
      <Box display="flex" flexDirection="column" gap="xxl" as={Form}>
        <WidgetsCalculatorFormPeriodBlock />
        <WidgetsCalculatorFormAmountBlock />
      </Box>
    </Formik>
  );
};
