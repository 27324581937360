import { ReactNode } from "react";
import { useIsAvailableFeatureFlagsFeature } from "../hooks/useIsAvailableFeatureFlagsFeature";
import { UserFeatureFlagPermission } from "../../types/user";

export interface FeatureFlagFeatureProps {
  flag: UserFeatureFlagPermission;
  children: ReactNode;
}

export const FeatureFlagFeature = ({ flag, children }: FeatureFlagFeatureProps) => {
  const isAvailable = useIsAvailableFeatureFlagsFeature(flag);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isAvailable && children}</>;
};
