import { useState } from "react";
import { FormikValues } from "formik";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useBankGuaranteeListFilterContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterContext";
import { FilterSelect } from "../../../../../../common/ui-kit/form-controls/select";
import { useBankGuaranteeListFilterApiContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterApiContext";
import {
  bankGuaranteeListFilterMoreOptions,
  BankGuaranteeListFilterMoreOptionsType,
} from "../../../../types/filters/BankGuaranteeMoreOptionsType";
import { BankGuaranteeListFiltersMoreOptionsModal } from "../modal/BankGuaranteeListFiltersMoreOptionsModal";

type BankGuaranteeListFiltersMoreOptionsProps = BaseCssProps;

export const BankGuaranteeListFiltersMoreOptions = ({ className }: BankGuaranteeListFiltersMoreOptionsProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const mutator = useBankGuaranteeListFilterApiContext();

  const [openedFiltration, setOpenedFiltration] = useState<BankGuaranteeListFilterMoreOptionsType | undefined>();

  const applyFilters = (selectedItems: FormikValues) => {
    if (openedFiltration) {
      mutator(filteringObject, (filter, { setValue }) =>
        setValue(filter, openedFiltration, selectedItems[openedFiltration])
      );
    }
    setOpenedFiltration(undefined);
  };

  return (
    <>
      <FilterSelect
        className={className}
        text={`Еще ${bankGuaranteeListFilterMoreOptions.values.length}`}
        selected={null}
        getItemTitle={bankGuaranteeListFilterMoreOptions.getTitle}
        onSelectedChanged={(selectedItems) => setOpenedFiltration(selectedItems)}
        items={bankGuaranteeListFilterMoreOptions.values}
      />
      <BankGuaranteeListFiltersMoreOptionsModal
        applyFilters={applyFilters}
        openedFiltration={openedFiltration}
        filteringObject={filteringObject}
        onClose={() => setOpenedFiltration(undefined)}
      />
    </>
  );
};
