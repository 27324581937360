import { clientV4 } from "../../../common/api";
import { DemandId } from "../../../common/types/demand";
import { CreateBankGuaranteeRequestOriginalCreationInputDto } from "./dto/input/СreateBankGuaranteeRequestOriginalCreationInputDto";
import { EmptyOutputDto } from "../../../common/api/types";

export const bankGuaranteeRequestOriginalCreationApi = {
  create: ({ demandId, ...model }: CreateBankGuaranteeRequestOriginalCreationInputDto) => {
    return clientV4.post<typeof model, EmptyOutputDto>(`RequestOriginals/${demandId}`, model);
  },
  getDeliveryCompaniesList: (demandId: DemandId) => {
    return clientV4.get<undefined, string[]>(`RequestOriginals/${demandId}/delivery-types`);
  },
};
