import { useField } from "formik";
import { nanoid } from "@reduxjs/toolkit";
import { FileUpload } from "../../../../../files/upload";
import { DocumentUploadActiveDocument } from "../../../../../document/upload";
import { BaseCssProps } from "../../../../../common/ui-kit/types";

interface BankGuaranteeReissueFormAttachFileBlockProps extends BaseCssProps {
  name: string;
  isDisabled: boolean;
  error?: string;
}

export const BankGuaranteeReissueFormAttachFileBlock = ({
  name,
  error,
  isDisabled,
  className,
}: BankGuaranteeReissueFormAttachFileBlockProps) => {
  const [{ value }, , { setValue }] = useField(name);

  return (
    <DocumentUploadActiveDocument
      title="Проект банковской гарантии"
      canUpload
      isRequired
      error={error}
      uploadLabelText={value?.file ? "Прикрепить другой файл" : "Прикрепить файл"}
      onDrop={async (files) => {
        if (files.length) {
          await setValue({ id: nanoid(), file: files[0], date: Date.now() });
        }
      }}
      className={className}
    >
      {value && value.file && (
        <FileUpload
          fileName={value.file.name}
          date={value.date}
          canRemove={isDisabled}
          onRemove={async () => {
            await setValue(null);
          }}
        />
      )}
    </DocumentUploadActiveDocument>
  );
};
