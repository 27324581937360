import { array, object, string } from "yup";
import { getFormattedPhoneSchema, getTaxpayerNumberSchema } from "../../../common/validation";

export const settlementAndCashCreationValidationSchema = object({
  customerTaxpayerNumber: getTaxpayerNumberSchema().default("").required("Не забудьте заполнить"),
  customerName: string().trim("Не забудьте заполнить").default("").required("Не забудьте заполнить"),
  customerAddress: string().trim("Не забудьте заполнить").default("").required("Не забудьте заполнить"),
  contactFullName: string().trim("Не забудьте заполнить").default("").required("Не забудьте заполнить"),
  contactPhoneNumber: getFormattedPhoneSchema().default("").required("Не забудьте заполнить"),
  contactEmail: string()
    .email("Введите адрес электронной почты в правильном формате")
    .required("Не забудьте заполнить")
    .default(""),
  comment: string().trim("Не забудьте заполнить").default(""),
  banks: array().of(string().required()).default([]).min(1, "Необходимо выбрать хотя бы один банк"),
});
