import { useEffect, useRef } from "react";
import { useField } from "formik";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { BaseCssProps } from "../../../../ui-kit/types";
import { useGetFieldError } from "../../../../form";

export interface ProductCreationScoringResultSuccessRowInputProps extends BaseCssProps {
  name: string;
  autoFocus?: boolean;
}

export const ProductCreationScoringResultSuccessRowInput = ({
  name,
  autoFocus,
  className,
}: ProductCreationScoringResultSuccessRowInputProps) => {
  const [fieldProps, { error, touched }] = useField<number>(name);
  const isDesktop = useThemeIsDesktop();
  const getFieldError = useGetFieldError();

  const fieldError = getFieldError(name, touched, error);
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  return (
    <FormInputPrice
      placeholder="Сумма, ₽"
      ref={ref}
      className={className}
      {...fieldProps}
      error={Boolean(fieldError) && isDesktop ? " " : fieldError}
    />
  );
};
