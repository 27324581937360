import { ClientFormSelectOpt } from "../ClientFormSelect";
import styles from "./ClientFormSelectOption.module.css";
import { ReactComponent as Check } from "../../../../../../images/icons/check.svg";

interface ClientFormSelectOptionProps {
  onChange: (value: string) => void;
  option: ClientFormSelectOpt;
  index?: number;
  selected: boolean;
}

const ClientFormSelectOption: React.FC<ClientFormSelectOptionProps> = ({ onChange, option, index, selected }) => {
  const { title, value } = option;

  return (
    <li key={index} className={styles["option"]} onClick={() => onChange(value)}>
      {selected && <Check className={styles["check"]} />}
      {title}
    </li>
  );
};

export default ClientFormSelectOption;
