import { BaseClient, ClientId, CreditExecutionId, DemandId } from "../../../../common/types/demand";
import { CreditExecutionDemandBase } from "../../../common/types";
import { createAppEntityAdapter } from "../../../../common/store/utils";
import { CreditExecutionListItem } from "../../types/items/CreditExecutionListItem";

export const creditExecutionListItemsClientsAdapter = createAppEntityAdapter<BaseClient, ClientId>({
  selectId: (x) => x.id,
});

export const creditExecutionListItemsAdapter = createAppEntityAdapter<CreditExecutionListItem, CreditExecutionId>({
  selectId: (x) => x.creditExecutionId,
});

export const creditExecutionListItemsDemandsAdapter = createAppEntityAdapter<CreditExecutionDemandBase, DemandId>({
  selectId: (x) => x.demandId,
});
