import { useCallback, useEffect, useState } from "react";
import { entityHelper } from "@sideg/helpers";
import { DemandQueueTasksActiveRequestedDocument } from "../../../types/active/DemandQueueTasksActiveRequestedDocument";
import { useApiMutationAction } from "../../../../../common/hooks";
import { uploadDocumentDemandQueueTasks } from "../../../store/demandQueueTasks.thunks";
import { FileTemp, generateFileTempId } from "../../../../../files/temp";
import { isLoading } from "../../../../../common/types/state";
import { useDemandQueueTaskRequestedDocumentWithRealTime } from "./useDemandQueueTaskRequestedDocumentWithRealTime";
import { QueueTaskId } from "../../../../../common/types/demand";
import { isMultipleDocumentUploadRequestedDocument } from "../../../../../document/upload";

export const useDemandQueueTaskActiveRequestedDocumentOnDropWithRealTime = (
  queueTaskId: QueueTaskId,
  document: DemandQueueTasksActiveRequestedDocument,
) => {
  const [files, setFiles] = useState<FileTemp[]>([]);
  const { status, error: mutationError, mutate } = useApiMutationAction();
  const formError = useDemandQueueTaskRequestedDocumentWithRealTime(document);
  const [error, setError] = useState(mutationError);

  const isMultiple = isMultipleDocumentUploadRequestedDocument(document);

  useEffect(() => {
    setError(formError);
  }, [formError]);

  useEffect(() => {
    setError(mutationError);
  }, [mutationError]);

  const onDrop = useCallback(
    async (droppedFiles: File[]) => {
      if (isLoading(status) || (droppedFiles.length > 1 && !isMultiple) || droppedFiles.length === 0) {
        return;
      }

      setFiles(
        droppedFiles.map((x) => ({
          dateAdded: new Date(),
          id: generateFileTempId(),
          file: x,
        })),
      );

      await mutate(
        uploadDocumentDemandQueueTasks,
        { queueTaskId, id: document.id, requestDocType: document.requestedDocType, files: droppedFiles },
        {
          errorHandler: async () => {
            return `${entityHelper.getWordDeclension(droppedFiles.length, "Файл", ["", "ы", "ы"], false)} ${droppedFiles
              .map((x) => x.name)
              .join(", ")} не ${entityHelper.getWordDeclension(
              droppedFiles.length,
              "прикреплен",
              ["", "ы", "ы"],
              false,
            )}`;
          },
        },
      );

      setFiles([]);
    },
    [document.id, document.requestedDocType, isMultiple, mutate, queueTaskId, status],
  );

  return { isLoading: isLoading(status), error, onDrop, files, isMultiple };
};
