import { useMemo } from "react";
import { useMatch } from "react-router-dom";
import { AppUrlSelector } from "../types";
import { ExtractRouteParams, generatePath } from "../utils";

export const useAppUrlMatch = <TUrl extends string>(
  urlSelector: AppUrlSelector<TUrl, never>,
  params?: ExtractRouteParams<TUrl>,
) => {
  const path = generatePath(urlSelector, params);
  const match = useMatch(path);

  return useMemo(() => ({ path, isExact: match?.pathname === path }), [match?.pathname, path]);
};
