import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  full: "Полный отчет",
  contracts: "Завершенные контракты",
  brief: "Экспресс-отчет",
};

export const clientCheckReportType = makeEnumLikeLiteral(["full", "contracts", "brief"] as const, titles);

export type ClientCheckReportType = EnumLikeLiteralType<typeof clientCheckReportType>;
