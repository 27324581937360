import styled from "@emotion/styled";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ControlSize, ResponsiveStyleValue } from "../../../../ui-kit/types";

export interface ButtonProps {
  size: ResponsiveStyleValue<Exclude<ControlSize, "medium">>;
}

export const Button = styled.button<ButtonProps>`
  font-family: inherit;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.typography.primaryCaption};
  appearance: none;
  background: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: none;
  padding: 2px;
  padding-right: 4px;
  margin: 0;
  gap: ${({ theme }) => theme.spaces.xs};
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.palette.typography.error};

    & > svg > path {
      stroke: ${({ theme }) => theme.palette.typography.error};
    }
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.primary};
    outline-offset: -2px;
    border-radius: 4px;
  }

  & > svg {
    & > path {
      stroke: ${({ theme }) => theme.palette.typography.primaryCaption};
    }
  }

  ${({ theme, size }) =>
    makeResponsiveStyles(theme, size, (x) => ({
      fontSize: theme.font[x === "small" ? "fz2" : "fz3"],
      "& > svg": {
        height: x === "small" ? "16px" : "20px",
        width: x === "small" ? "16px" : "20px",
      },
    }))}
`;
