import { useEffect } from "react";

export const useServiceWorkerRefresher = () => {
  useEffect(() => {
    const callback = () => {
      window.location.reload();
    };

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("controllerchange", callback);
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("controllerchange", callback);
      }
    };
  }, []);
};
