import { BaseCssProps } from "../../../common/ui-kit/types";
import { ToastId } from "../types/ToastId";
import { useToast } from "../hooks/useToast";
import { Toast } from "../../../common/ui-kit/containers/toast";

export interface GlobalToastProps extends BaseCssProps {
  id: ToastId;
}

export const GlobalToast = ({ id, className }: GlobalToastProps) => {
  const toastProps = useToast(id);

  return <Toast {...toastProps} className={className} />;
};
