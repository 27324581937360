import { useCallback, useMemo } from "react";
import { cancelModificationBankGuaranteeList } from "../../store/item-modification/bankGuaranteeListItemModification.slice";
import { useAppDispatch } from "../../../../common/store";
import { useBankGuaranteeListFetchSingleItem } from "../list/useBankGuaranteeListFetchSingleItem";
import { BankGuaranteeId } from "../../../../common/types/demand";
import { pushSuccessGlobalToast } from "../../../../global/toasts";
import { useProductListItemModificationOnClose } from "../../../../common/ui/product/list/item-modification";

export const useBankGuaranteeListItemModificationView = (
  guaranteeId: BankGuaranteeId,
  modalState: readonly [boolean, (x: boolean) => void],
  successMessage: string,
) => {
  const dispatch = useAppDispatch();
  const [isModalOpen] = modalState;

  const afterClose = useCallback(() => {
    dispatch(cancelModificationBankGuaranteeList());
  }, [dispatch]);

  const onClose = useProductListItemModificationOnClose(modalState, afterClose);
  const fetchItem = useBankGuaranteeListFetchSingleItem(guaranteeId);

  return useMemo(() => {
    const onSuccess = async () => {
      onClose();
      dispatch(pushSuccessGlobalToast(successMessage));
      await fetchItem();
    };

    return {
      isModalOpen,
      closeModal: onClose,
      onSuccess,
    };
  }, [dispatch, fetchItem, isModalOpen, onClose, successMessage]);
};
