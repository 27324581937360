import { checkNeverType } from "@sideg/helpers";
import { ElementType } from "react";
import { BankGuaranteeDetailsDemandReconciliationStateType } from "../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationStateType";
import { ClockIcon, InfoIcon, LikeIcon } from "../../../../common/ui-kit/icons";

export const getBankGuaranteeDetailsDemandIconByReconciliationState = (
  state: BankGuaranteeDetailsDemandReconciliationStateType,
): ElementType => {
  switch (state) {
    case "Agreement":
      return InfoIcon;
    case "Approved":
      return LikeIcon;
    case "Bank":
      return ClockIcon;
    default:
      return checkNeverType(state);
  }
};
