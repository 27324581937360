import { AppLayoutRoute, AppRoute } from "./types/AppRoute";
import { makeAppLayoutRoute } from "./utils/makeAppLayoutRoute";
import { AppLayout } from "../ui/layout/app-layout";
import { UserProfilePage } from "../../user/details";
import { UserChangePasswordPage, UserChangePasswordWellKnownPage } from "../../user/change-password";
import { QueueTasksListPage } from "../../queue-tasks/list";
import { QueueTasksProductCreationPage } from "../../queue-tasks/creation";
import { GuaranteeCalculatorForManagingDirectorOfAgent } from "../../../pages/guarantee/calculator/GuaranteeCalculatorForManagingDirectorOfAgent/GuaranteeCalculatorForManagingDirectorOfAgent";
import {
  BankGuaranteeCreationCreateByGuaranteeDetailsPage,
  BankGuaranteeCreationCreateByGuaranteeLayout,
  BankGuaranteeCreationCreateByGuaranteePage,
  BankGuaranteeCreationPage,
} from "../../bank-guarantee/creation";
import {
  BankGuaranteeDetailsDemandPage,
  BankGuaranteeDetailsDemandPageLayout,
  BankGuaranteeDetailsDocumentsPage,
  BankGuaranteeDetailsPage,
  BankGuaranteeDetailsPageLayout,
  BankGuaranteeDetailsReconciliationBillPage,
  BankGuaranteeDetailsReconciliationProjectPage,
  BankGuaranteeDetailsRequestOriginalPage,
  BankGuaranteeDetailsRootPage,
} from "../../bank-guarantee/details";
import AgentReport from "../../../pages/agent-report/AgentReport";
import AgentReportItem from "../../../pages/agent-report/item/AgentReportItem";
import { HomePageRedirect } from "./components/HomePageRedirect";
import { AppUrlTypeEnum } from "../urls/internal/types";
import { NotFound } from "./components/NotFound";
import { makeAppRoute } from "./utils/makeAppRoute";
import { Redirect } from "../urls/internal/components";
import { BankGuaranteeListPage } from "../../bank-guarantee/list";
import { NewsListPage } from "../../news/list";
import { NewsDetailsPage } from "../../news/details";
import { BankGuaranteeDocumentsPage } from "../../bank-guarantee/documents";
import { BankGuaranteeCalculatorPage } from "../../bank-guarantee/calculator";
import { ClientCheckPage } from "../../client/check/pages/ClientCheckPage";
import { CreditExecutionConditionsPage } from "../../credit-execution/conditions";
import { BankGuaranteeConditionsPage } from "../../bank-guarantee/conditions";
import { ReportsDemandPage } from "../../reports/demand";
import { BankGuaranteeRewardsPage } from "../../bank-guarantee/rewards";
import { SettlementAndCashCreationPage } from "../../settlement-and-cash/creation";
import { FactoringCreationPage } from "../../factoring/creation";
import { CreditWorkingAssetsListPage } from "../../credit-working-assets/list";
import {
  CreditWorkingAssetsCreationCreateByExistsPage,
  CreditWorkingAssetsCreationPage,
  CreditWorkingAssetsCreationPageLayout,
} from "../../credit-working-assets/creation";
import {
  CreditWorkingAssetsDetailsDemandPage,
  CreditWorkingAssetsDetailsDocumentsPage,
  CreditWorkingAssetsDetailsPageLayout,
  CreditWorkingAssetsDetailsProductPage,
} from "../../credit-working-assets/details";
import { CreditExecutionListPage } from "../../credit-execution/list";
import {
  CreditExecutionCreationCreateByExistsPage,
  CreditExecutionCreationPage,
  CreditExecutionCreationPageLayout,
} from "../../credit-execution/creation";
import { AppPublicLayout } from "../ui/layout/app-public-layout";
import {
  PublicUserAuthenticationLoginPage,
  PublicUserAuthenticationLogoutPage,
} from "../../public-user/authentication";
import {
  PublicUserRegistrationAgentPage,
  PublicUserRegistrationClientPage,
  PublicUserRegistrationNewLegacyPage,
  PublicUserRegistrationNewPage,
  PublicUserRegistrationPageLayout,
} from "../../public-user/registration";
import {
  PublicUserResetPasswordApproveLegacyPage,
  PublicUserResetPasswordForgotPasswordPage,
  PublicUserResetPasswordInitLegacyPage,
} from "../../public-user/reset-password";
import { PublicUserOffersPage } from "../../public-user/offers";
import { AgencyDemandPage } from "../../agency/demand";
import {
  CreditExecutionDetailsDemandPage,
  CreditExecutionDetailsDocumentsPage,
  CreditExecutionDetailsPage,
  CreditExecutionDetailsPageLayout,
  CreditExecutionDetailsProductPage,
} from "../../credit-execution/details";
import { PublicUserPrivacyPoliciesPage } from "../../public-user/privacy-policies";
import { UserWebPushesPageMobile } from "../../user/web-pushes";
import { LegalServicesCreationListPage, LegalServicesCreationProductPage } from "../../legal-services/creation";

export const appRoutes: (AppRoute | AppLayoutRoute)[] = [
  makeAppLayoutRoute(
    AppPublicLayout,
    [
      makeAppRoute((x) => x.publicUser.authentication.login, <PublicUserAuthenticationLoginPage />),
      makeAppRoute((x) => x.publicUser.registration.client, <PublicUserRegistrationClientPage />),
      makeAppRoute((x) => x.publicUser.registration.agent, <PublicUserRegistrationAgentPage />),
      makeAppLayoutRoute(
        PublicUserRegistrationPageLayout,
        [
          makeAppRoute((x) => x.publicUser.registration.newLegacy, <PublicUserRegistrationNewLegacyPage />),
          makeAppRoute((x) => x.publicUser.registration.new, <PublicUserRegistrationNewPage />),
        ],
        AppUrlTypeEnum.Public,
      ),
      makeAppRoute((x) => x.publicUser.resetPassword.forgotPassword, <PublicUserResetPasswordForgotPasswordPage />),
      makeAppRoute((x) => x.publicUser.resetPassword.resetPasswordLegacy, <PublicUserResetPasswordInitLegacyPage />),
      makeAppRoute(
        (x) => x.publicUser.resetPassword.resetPasswordApproveLegacy,
        <PublicUserResetPasswordApproveLegacyPage />,
      ),
    ],
    AppUrlTypeEnum.Public,
  ),
  makeAppLayoutRoute(
    AppPublicLayout,
    [
      makeAppRoute((x) => x.publicUser.offers.root, <PublicUserOffersPage />),
      makeAppRoute((x) => x.publicUser.privacyPolicies.root, <PublicUserPrivacyPoliciesPage />),
    ],
    AppUrlTypeEnum.Common,
  ),
  makeAppRoute((x) => x.publicUser.authentication.logout, <PublicUserAuthenticationLogoutPage />),
  makeAppLayoutRoute(AppLayout, [
    makeAppRoute((x) => x.user.profile.details, <UserProfilePage />),
    makeAppRoute((x) => x.user.changePassword.root, <UserChangePasswordPage />),
    makeAppRoute((x) => x.user.changePassword.wellKnown, <UserChangePasswordWellKnownPage />),
    makeAppRoute((x) => x.user.webPushes.root, <UserWebPushesPageMobile />),
    makeAppRoute((x) => x.queueTasks.list.root, <QueueTasksListPage />),
    makeAppRoute((x) => x.queueTasks.creation.create, <QueueTasksProductCreationPage />),
    makeAppRoute((x) => x.bankGuarantee.list.root, <BankGuaranteeListPage />),
    makeAppRoute((x) => x.bankGuarantee.conditions.root, <BankGuaranteeConditionsPage />),
    makeAppRoute((x) => x.bankGuarantee.calculator.root, <BankGuaranteeCalculatorPage />),
    makeAppRoute((x) => x.bankGuarantee.calculatorUda.root, <GuaranteeCalculatorForManagingDirectorOfAgent />),
    makeAppRoute((x) => x.bankGuarantee.rewards.root, <BankGuaranteeRewardsPage />),
    makeAppRoute((x) => x.bankGuarantee.documents.root, <BankGuaranteeDocumentsPage />),
    makeAppRoute((x) => x.bankGuarantee.creation.create, <BankGuaranteeCreationPage />),
    makeAppRoute(
      (x) => x.bankGuarantee.creation.createLegacy,
      <Redirect to={(x) => x.bankGuarantee.creation.create} withSearch />,
    ),
    makeAppLayoutRoute(BankGuaranteeCreationCreateByGuaranteeLayout, [
      makeAppRoute((x) => x.bankGuarantee.creation.createByGuarantee, <BankGuaranteeCreationCreateByGuaranteePage />),
      makeAppRoute(
        (x) => x.bankGuarantee.creation.createByGuaranteeDetails,
        <BankGuaranteeCreationCreateByGuaranteeDetailsPage />,
      ),
    ]),
    makeAppLayoutRoute(BankGuaranteeDetailsPageLayout, [
      makeAppRoute((x) => x.bankGuarantee.details.details, <BankGuaranteeDetailsPage />),
      makeAppRoute((x) => x.bankGuarantee.details.guarantee, <BankGuaranteeDetailsRootPage />),
      makeAppRoute((x) => x.bankGuarantee.details.documents, <BankGuaranteeDetailsDocumentsPage />),
      makeAppRoute((x) => x.bankGuarantee.details.requestOriginal, <BankGuaranteeDetailsRequestOriginalPage />),
      makeAppLayoutRoute(BankGuaranteeDetailsDemandPageLayout, [
        makeAppRoute((x) => x.bankGuarantee.details.demand, <BankGuaranteeDetailsDemandPage />),
        makeAppRoute((x) => x.bankGuarantee.details.bill, <BankGuaranteeDetailsReconciliationBillPage />),
        makeAppRoute((x) => x.bankGuarantee.details.project, <BankGuaranteeDetailsReconciliationProjectPage />),
      ]),
    ]),
    makeAppLayoutRoute(CreditExecutionCreationPageLayout, [
      makeAppRoute((x) => x.creditExecution.creation.create, <CreditExecutionCreationPage />),
      makeAppRoute(
        (x) => x.creditExecution.creation.createByCreditExecution,
        <CreditExecutionCreationCreateByExistsPage />,
      ),
    ]),
    makeAppLayoutRoute(CreditExecutionDetailsPageLayout, [
      makeAppRoute((x) => x.creditExecution.details.details, <CreditExecutionDetailsPage />),
      makeAppRoute((x) => x.creditExecution.details.creditExecution, <CreditExecutionDetailsProductPage />),
      makeAppRoute((x) => x.creditExecution.details.demand, <CreditExecutionDetailsDemandPage />),
      makeAppRoute((x) => x.creditExecution.details.documents, <CreditExecutionDetailsDocumentsPage />),
    ]),
    makeAppRoute((x) => x.creditExecution.list.root, <CreditExecutionListPage />),
    makeAppRoute((x) => x.creditExecution.conditions.root, <CreditExecutionConditionsPage />),
    makeAppRoute((x) => x.creditWorkingAssets.list.root, <CreditWorkingAssetsListPage />),
    makeAppLayoutRoute(CreditWorkingAssetsCreationPageLayout, [
      makeAppRoute((x) => x.creditWorkingAssets.creation.create, <CreditWorkingAssetsCreationPage />),
      makeAppRoute(
        (x) => x.creditWorkingAssets.creation.createByCreditWorkingAssets,
        <CreditWorkingAssetsCreationCreateByExistsPage />,
      ),
    ]),
    makeAppLayoutRoute(CreditWorkingAssetsDetailsPageLayout, [
      makeAppRoute((x) => x.creditWorkingAssets.details.product, <CreditWorkingAssetsDetailsProductPage />),
      makeAppRoute((x) => x.creditWorkingAssets.details.demand, <CreditWorkingAssetsDetailsDemandPage />),
      makeAppRoute((x) => x.creditWorkingAssets.details.documents, <CreditWorkingAssetsDetailsDocumentsPage />),
    ]),
    makeAppRoute((x) => x.legalServices.creation.list, <LegalServicesCreationListPage />),
    makeAppRoute((x) => x.legalServices.creation.product, <LegalServicesCreationProductPage />),
    makeAppRoute((x) => x.client.check.root, <ClientCheckPage />),
    makeAppRoute((x) => x.reports.agent.list.root, <AgentReport />),
    makeAppRoute((x) => x.reports.agent.details.root, <AgentReportItem />),
    makeAppRoute((x) => x.reports.demand.root, <ReportsDemandPage />),
    makeAppRoute((x) => x.reports.demand.rootLegacy, <Redirect to={(x) => x.reports.demand.root} withSearch />),
    makeAppRoute((x) => x.news.list.root, <NewsListPage />),
    makeAppRoute((x) => x.news.details.item, <NewsDetailsPage />),
    makeAppRoute((x) => x.factoring.creation.create, <FactoringCreationPage />),
    makeAppRoute((x) => x.settlementAndCash.creation.create, <SettlementAndCashCreationPage />),
    makeAppRoute((x) => x.agency.demand.root, <AgencyDemandPage />),
    makeAppRoute((x) => x.root, <HomePageRedirect />),
    makeAppRoute(() => ({ url: "*", part: "*", urlType: AppUrlTypeEnum.Private, checkPermission: true }), <NotFound />),
  ]),
] satisfies (AppRoute | AppLayoutRoute)[];
