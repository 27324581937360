import * as React from "react";
import { BaseCssProps, ElementColor } from "../../../../../ui-kit/types";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";
import { Icon } from "../../../../../ui-kit/icons";
import { Typography } from "../../../../../ui-kit/typography";

export interface ProductDetailsAsideNavigationCardItemPriceProps extends BaseCssProps {
  icon: React.ElementType;
  color: ElementColor;
  text: string;
}

export const ProductDetailsAsideNavigationCardItemPrice = ({
  icon,
  color,
  text,
  className,
}: ProductDetailsAsideNavigationCardItemPriceProps) => {
  return (
    <StackPanel gap="sm" direction="row" alignItems="center" className={className}>
      <Icon as={icon} width={13} height={13} stroke={color} />
      <Typography fontWeight={500} lineHeight="1" fontSize="fz2" fontColor={color}>
        {text}
      </Typography>
    </StackPanel>
  );
};
