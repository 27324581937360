import {
  AppLayoutOverlayPanel,
  useAppLayoutActiveOverlayPanel,
} from "../../../../../../common/ui/layout/app-layout-containers";
import { QueueTasksListFiltersProps } from "../../QueueTasksListFiltersProps";
import { queueTasksListConfigs } from "../../../../configs";
import { FiltersMobilePanel, FiltersSlidersIconButton } from "../../../../../../common/filters/use-cases";
import { queueTasksListMobileFiltersValidationSchema } from "../../../../validation/queueTasksListMobileFiltersValidationSchema";
import { QueueTasksListFiltersMobileFormContent } from "../form/QueueTasksListFiltersMobileFormContent";

export const QueueTasksListShowFiltersButton = ({ filteringObject }: QueueTasksListFiltersProps) => {
  const { openPanel, closePanel } = useAppLayoutActiveOverlayPanel(queueTasksListConfigs.filtersOverlayPanelKey);

  return (
    <>
      <FiltersSlidersIconButton onClick={openPanel} />
      <AppLayoutOverlayPanel panelKey={queueTasksListConfigs.filtersOverlayPanelKey}>
        <FiltersMobilePanel
          schema={queueTasksListMobileFiltersValidationSchema}
          filteringObject={filteringObject}
          onCloseClick={closePanel}
        >
          <QueueTasksListFiltersMobileFormContent />
        </FiltersMobilePanel>
      </AppLayoutOverlayPanel>
    </>
  );
};
