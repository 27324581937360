import styled from "@emotion/styled";

export const Container = styled.div`
  display: block;
  text-align: center;
  font-weight: 500;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-size: ${({ theme }) => theme.font.fz3};
  padding: ${({ theme }) => theme.spaces.md} 0 0 0;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -${({ theme }) => theme.spaces.md};
    right: -${({ theme }) => theme.spaces.md};
    height: 1px;
    background-color: ${({ theme }) => theme.colors.secondaryLight};
  }
`;
