export interface ScoringFormValues {
    productType: string
    fzType: string
    guaranteeType: string
    guaranteeSum: string
    guaranteeTimeDays: string
    guaranteePeriod: {
        startDate: Date | null
        endDate: Date | null
    }
    scoringItems: string[]
}

export const initialValues: ScoringFormValues = {
    productType: 'BankGuarantee',
    fzType: '',
    guaranteeType: '',
    guaranteeSum: '',
    guaranteeTimeDays: '',
    guaranteePeriod: {
        startDate: null,
        endDate: null,
    },
    scoringItems: []
}