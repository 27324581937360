import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  Approved: "Оригинал отправлен",
  Bank: "Запрос оригинала отправлен в банк",
  Request: "Запрос оригинала отправлен",
  Rework: "Требуется доработка запроса оригинала",
  Rejected: "Запрос оригинала отклонён",
};

const types = ["Approved", "Bank", "Request", "Rework", "Rejected"] as const;

export const bankGuaranteeRequestOriginalStatus = makeEnumLikeLiteral(types, titles);

export type BankGuaranteeRequestOriginalStatus = EnumLikeLiteralType<typeof bankGuaranteeRequestOriginalStatus>;
