import { BaseCssProps } from "../../../../ui-kit/types";
import { ProductDetailsContentHeaderLayout } from "../../content-header/ProductDetailsContentHeaderLayout";

export type ProductDetailsDocumentsHeaderProps = BaseCssProps;

export const ProductDetailsDocumentsHeader = ({ className }: ProductDetailsDocumentsHeaderProps) => {
  return (
    <ProductDetailsContentHeaderLayout className={className} withPaddingBottom>
      <ProductDetailsContentHeaderLayout.Title title=" Запрашиваемые банками документы" fontWeight={700} />
    </ProductDetailsContentHeaderLayout>
  );
};
