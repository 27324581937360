import { FileId } from "../../../common/types/files";
import { FileLabel } from "../../../common/ui-kit/labels";
import { useFilesDownload } from "../hooks/useFilesDownload";

export interface FileDownloadLabelProps {
  fileId: FileId;
  privateKey: string;
  fileName: string;
  isWithNotification?: boolean;
  className?: string;
}

export const FileDownloadLabel = ({
  fileId,
  fileName,
  privateKey,
  isWithNotification,
  className,
}: FileDownloadLabelProps) => {
  const [status, download] = useFilesDownload(fileId, privateKey);

  return (
    <FileLabel
      status={status}
      download={download}
      fileName={fileName}
      isWithNotification={isWithNotification}
      className={className}
    />
  );
};
