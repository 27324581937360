import { dateTimeHelper, currencyHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { BankGuaranteeDetailsGuaranteeDetails } from "../../types/guarantee-details/BankGuaranteeDetailsGuaranteeDetails";
import {
  ProductDetailsInformationListBlock,
  ProductDetailsInformationListItem,
} from "../../../../common/product-details";

export interface BankGuaranteeDetailsGuaranteeDetailsAuctionBlockProps extends BaseCssProps {
  briefAndFounders: BankGuaranteeDetailsGuaranteeDetails;
}

export const BankGuaranteeDetailsGuaranteeDetailsAuctionBlock = ({
  className,
  briefAndFounders,
}: BankGuaranteeDetailsGuaranteeDetailsAuctionBlockProps) => {
  return (
    <ProductDetailsInformationListBlock title="Аукцион" className={className}>
      <ProductDetailsInformationListItem
        title="Аукцион"
        value={briefAndFounders.isClosedAuction ? "Закрытый" : "Открытый"}
      />
      <ProductDetailsInformationListItem title="Номер лота закупки" value={briefAndFounders.lotNumber} />
      <ProductDetailsInformationListItem
        title="Срок"
        value={`${dateTimeHelper.format(new Date(briefAndFounders.dateStart), "dateOnly")} – ${dateTimeHelper.format(
          new Date(briefAndFounders.dateEnd),
          "dateOnly",
        )}`}
      />
      <ProductDetailsInformationListItem
        title="Начальная цена контракта"
        value={currencyHelper.getCurrencyString(briefAndFounders.contractFirstSum)}
      />
      {briefAndFounders.contractFinalSum && (
        <ProductDetailsInformationListItem
          title="Итоговая цена контракта"
          value={currencyHelper.getCurrencyString(briefAndFounders.contractFinalSum)}
        />
      )}
      <ProductDetailsInformationListItem
        title="Размер обеспечения"
        value={currencyHelper.getCurrencyString(briefAndFounders.guaranteeSum)}
      />
      {briefAndFounders.contractPrepaidSum > 0 && (
        <ProductDetailsInformationListItem
          title="Сумма аванса"
          value={currencyHelper.getCurrencyString(briefAndFounders.contractPrepaidSum)}
        />
      )}
      {briefAndFounders.isSingleSupplier && (
        <ProductDetailsInformationListItem value="Закупка у единственного поставщика" />
      )}
      {briefAndFounders.contractPrepaidSum === undefined ||
        (briefAndFounders.contractPrepaidSum === 0 && (
          <ProductDetailsInformationListItem value="Наличие аванса не требуется" />
        ))}
      <ProductDetailsInformationListItem
        value={
          briefAndFounders.isWriteOffRequest
            ? "Есть требование о бесспорном списании"
            : "Нет требования о бесспорном списании"
        }
      />
      <ProductDetailsInformationListItem
        value={briefAndFounders.isReprovision ? "Есть переобеспечение" : "Переобеспечения нет"}
      />
    </ProductDetailsInformationListBlock>
  );
};
