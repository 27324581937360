import { useMemo } from "react";
import { PaginationInformation } from "../../../../../types/pagination";
import {
  isFailed as isStatusFailed,
  isLoading as isStatusLoading,
  isSucceeded,
  LoadingStatusEnum,
} from "../../../../../types/state";

export const useAppPagination = (
  status: LoadingStatusEnum,
  items: unknown[],
  pagination: PaginationInformation | undefined
) => {
  return useMemo(
    () => ({
      hasMore:
        !isStatusLoading(status) &&
        !isStatusFailed(status) &&
        (!pagination || pagination.pageNumber < pagination.pageCount),
      isLoading: isStatusLoading(status),
      isFailed: isStatusFailed(status),
      isEmptyList: items.length === 0,
      hasNoLoadedItems: isSucceeded(status) && items.length === 0,
      isEmptyLoading: isStatusLoading(status) && items.length === 0,
    }),
    [items.length, pagination, status]
  );
};
