import styled from "@emotion/styled";

export const TextContainer = styled.p`
  padding: 0;
  margin: ${({ theme }) => theme.spaces.md} 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xs};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    width: max(70%, 550px);
    display: block;
    margin: 0;
  }
`;
