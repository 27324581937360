import { useAppSelector } from "../../../common/store";
import { selectUserDetails } from "../store/userDetails.selectors";

export const useUserDetailsTitle = () => {
  const { user } = useAppSelector(selectUserDetails);

  if (user === undefined) {
    return undefined;
  }

  return user.clientName === undefined ? user.userName : `${user.clientName} / ${user.userName}`;
};
