/* eslint-disable class-methods-use-this */
import { BaseAuthenticationStoredTokenService } from "./BaseAuthenticationStoredTokenService";
import { AuthServiceAuthenticationOutputDto } from "../../api/dto/output/AuthServiceAuthenticationOutputDto";
import { JwtTokenInfo } from "../../types/jwt/JwtTokenInfo";
import { make } from "../../../types/utils";
import { AccessToken } from "../../types/jwt/AccessToken";
import { RefreshToken } from "../../types/jwt/RefreshToken";
import { AuthenticationUserTokenDetails } from "../../types/AuthenticationUserTokenDetails";
import { LocalStorageService } from "../../../services/local-storage";
import { authenticationJwtService } from "../authenticationJwtService";
import { TokenPayload } from "../../types/jwt/TokenPayload";
import { UserFeatureFlagEnum, UserRoleEnum } from "../../../types/user";

const AUTH_SERVICE_TOKEN_INFO_KEY = "ti";

export class AuthenticationAuthServiceStoredTokenService extends BaseAuthenticationStoredTokenService<AuthServiceAuthenticationOutputDto> {
  public constructor(localStorage = new LocalStorageService(AUTH_SERVICE_TOKEN_INFO_KEY)) {
    super(localStorage);
  }

  protected mapTokenInfoFromResponse(value: AuthServiceAuthenticationOutputDto): JwtTokenInfo {
    return {
      accessToken: make<AccessToken>()(value.access_token),
      tokenType: value.token_type,
      refreshToken: make<RefreshToken>()(value.refresh_token),
      expiresIn: value.expires_in,
    };
  }

  public mapUserTokenDetails(tokenInfo: AuthServiceAuthenticationOutputDto): AuthenticationUserTokenDetails {
    const payload = authenticationJwtService.decryptTokenPayload<TokenPayload>(tokenInfo.access_token as AccessToken);

    return {
      id: payload.id,
      login: payload.login,
      role: payload.role as UserRoleEnum,
      username: payload.username,
      featureFlags: (payload.featureFlags?.split(",") ?? []) as UserFeatureFlagEnum[],
    };
  }

  public getExpiresTime(tokenInfo: AuthServiceAuthenticationOutputDto): Date {
    return new Date(Date.now() + tokenInfo.expires_in * 1000);
  }
}
