import styled from "@emotion/styled";

export const Span = styled.span`
  display: inline-flex;
  padding: 0;
  margin: 0;
  border: 2px solid ${({ theme }) => theme.palette.control.secondaryLight};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-color: ${({ theme }) => theme.palette.background.light};
  cursor: default;

  & > svg {
    width: 12px;
    height: 12px;

    & > path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;
