import { OuterTheme, Theme } from "@sideg/ui-kit/core/theme";
import { createPrivateTheme, CreatePrivateThemeOptions } from "@sideg/ui-kit/core/theme/utils/createPrivateTheme";
import deepmerge from "deepmerge";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateThemeOptions extends CreatePrivateThemeOptions {}

export const createTheme = (theme: OuterTheme, options: CreateThemeOptions = {}): Theme => {
  const privateTheme = createPrivateTheme(options);

  return deepmerge(privateTheme, theme);
};
