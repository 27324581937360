import { GetCreditExecutionDetailsInputDto } from "./dto/input/GetCreditExecutionDetailsInputDto";
import { clientV4 } from "../../../common/api";
import { GetCreditExecutionDetailsOutputDto } from "./dto/output/GetCreditExecutionDetailsOutputDto";
import { GetDemandCreditExecutionDetailsInputDto } from "./dto/input/GetDemandCreditExecutionDetailsInputDto";
import { GetDemandCreditExecutionDetailsOutputDto } from "./dto/output/GetDemandCreditExecutionDetailsOutputDto";
import { UploadGroupDocumentCreditExecutionDetailsInputDto } from "./dto/input/UploadGroupDocumentCreditExecutionDetailsInputDto";
import { ApiObject } from "../../../common/api/types";

const CONTROLLER_NAME = "creditExecutions";

export const creditExecutionDetailsApi = {
  get: ({ creditExecutionId }: GetCreditExecutionDetailsInputDto) =>
    clientV4.get<GetCreditExecutionDetailsInputDto, GetCreditExecutionDetailsOutputDto>(
      `${CONTROLLER_NAME}/${creditExecutionId}`,
    ),
  getDemand: ({ creditExecutionId, demandId }: GetDemandCreditExecutionDetailsInputDto) =>
    clientV4.get<GetDemandCreditExecutionDetailsInputDto, GetDemandCreditExecutionDetailsOutputDto>(
      `${CONTROLLER_NAME}/${creditExecutionId}/demand/${demandId}`,
    ),
  uploadGroupDocument: ({ creditExecutionId, ...model }: UploadGroupDocumentCreditExecutionDetailsInputDto) => {
    return clientV4.postForm<typeof model, GetCreditExecutionDetailsOutputDto>(
      `${CONTROLLER_NAME}/${creditExecutionId}/group-upload-document`,
      model,
    );
  },
} satisfies ApiObject;
