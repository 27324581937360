import { useAppSelector } from "../../../../../common/store";
import { useCreditExecutionCreationScoring } from "../../../hooks/scoring/useCreditExecutionCreationScoring";
import { CreditExecutionCreationScoringCreateNewFormContent } from "./CreditExecutionCreationScoringCreateNewFormContent";
import { useCreditExecutionCreationSubmitAfterScoring } from "../../../hooks/creation/useCreditExecutionCreationSubmitAfterScoring";
import { selectCreditExecutionCreationCreating } from "../../../store/creditExecutionCreation.selectors";
import {
  createCreditExecutionCreation,
  scoringCreditExecutionCreation,
} from "../../../store/creditExecutionCreation.thunks";
import { CreditExecutionCreationScoringForm } from "../CreditExecutionCreationScoringForm";

export const CreditExecutionCreationScoringCreateNewForm = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const creationData = useAppSelector(selectCreditExecutionCreationCreating).data!;
  useCreditExecutionCreationScoring(scoringCreditExecutionCreation, creationData);

  const { onSubmit } = useCreditExecutionCreationSubmitAfterScoring(createCreditExecutionCreation, creationData);

  return (
    <CreditExecutionCreationScoringForm onSubmit={onSubmit}>
      <CreditExecutionCreationScoringCreateNewFormContent />
    </CreditExecutionCreationScoringForm>
  );
};
