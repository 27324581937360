import { clientV3 } from "../../../common/api";
import { GetNewsListInputDto } from "./dto/input/GetNewsListInputDto";
import { GetNewsListOutputDto } from "./dto/output/GetNewsListOutputDto";

export const newsListApi = {
  get: (model?: GetNewsListInputDto) =>
    clientV3.get<GetNewsListInputDto, GetNewsListOutputDto>("/news", {
      page: model?.page ?? 1,
      pageSize: model?.pageSize ?? 10,
    }),
};
