import { useMemo } from "react";
import { useBankGuaranteeCreationForm } from "../useBankGuaranteeCreationForm";
import { dateEventRules } from "../../../validation/rules/dateEvent.rules";
import { useBankGuaranteeDateEventTitle } from "../../../../common/hooks";

export const useBankGuaranteeCreationDateEventField = () => {
  const {
    values: { guaranteeType },
  } = useBankGuaranteeCreationForm();

  const label = useBankGuaranteeDateEventTitle(guaranteeType);

  return useMemo(() => ({ isExists: dateEventRules.isExists(guaranteeType), label }), [guaranteeType, label]);
};
