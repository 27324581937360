import Skeleton from "react-loading-skeleton";
import { useFormikContext } from "formik";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { FullPageContainerCenteredMainWithFooter } from "../../../../../common/ui/containers/full-page";
import {
  ProductCreationScoringFormSubmitButton,
  ProductCreationScoringFormTotalLabel,
  ProductCreationScoringLoadingResultCard,
  ProductCreationScoringRejectsPanel,
  ProductCreationScoringResultCard,
  ProductCreationScoringResultRowDuplicate,
  ProductCreationScoringResultRowReject,
  ProductCreationScoringResultRowRejectSkeleton,
  ProductCreationScoringResultSummary,
  useCreationScoringTotalSum,
  useProductCreationScoringRejectPanel,
} from "../../../../../common/product-creation";
import { useAppSelector } from "../../../../../common/store";
import { isLoading } from "../../../../../common/types/state";
import { LineSeparatedContainer } from "../../../../../common/ui-kit/containers/separated";
import {
  selectCreditExecutionCreationCreating,
  selectCreditExecutionCreationScoring,
} from "../../../store/creditExecutionCreation.selectors";
import { CreditExecutionCreationScoringFormValues } from "../../../types/scoring/CreditExecutionCreationScoringFormValues";
import { CreditExecutionCreationScoringResultSuccessRowSkeleton } from "../result-row/CreditExecutionCreationScoringResultSuccessRow.skeleton";
import { CreditExecutionCreationScoringResultSuccessRow } from "../result-row/CreditExecutionCreationScoringResultSuccessRow";

export const CreditExecutionCreationScoringCreateNewFormContent = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { creditSum } = useAppSelector(selectCreditExecutionCreationCreating).data!;
  const { data, status } = useAppSelector(selectCreditExecutionCreationScoring);
  const { openPanel, closePanel } = useProductCreationScoringRejectPanel();

  const {
    values: { banks },
  } = useFormikContext<CreditExecutionCreationScoringFormValues>();

  const actualSum = useCreationScoringTotalSum(banks);

  return (
    <FullPageContainerCenteredMainWithFooter
      footer={
        data?.success?.length !== undefined &&
        data.success.length > 0 && <ProductCreationScoringFormSubmitButton<CreditExecutionCreationScoringFormValues> />
      }
    >
      <ProductCreationScoringResultSummary
        isSkeleton={isLoading(status)}
        successCount={data?.success?.length}
        rejectCount={data?.reject?.length}
        duplicateCount={data?.duplicate?.length}
        onShowRejectsClick={openPanel}
      />

      <ProductCreationScoringLoadingResultCard
        isLoading={isLoading(status)}
        title="Выберите банки, в которые хотите отправить заявку"
        description={
          <>
            {!isLoading(status) && (
              <ProductCreationScoringFormTotalLabel requestedSum={creditSum} actualSum={actualSum} />
            )}
            {isLoading(status) && <Skeleton width={300} />}
          </>
        }
        skeleton={CreditExecutionCreationScoringResultSuccessRowSkeleton}
        skeletonElementsCount={5}
        items={data?.success}
      >
        {(x) => <CreditExecutionCreationScoringResultSuccessRow scoringResult={x} creditSum={creditSum} />}
      </ProductCreationScoringLoadingResultCard>
      <ThemeMobileDesktopView
        mobile={
          data?.reject?.length !== undefined &&
          data.reject.length > 0 && (
            <ProductCreationScoringRejectsPanel rejectsCount={data.reject.length} onCloseClick={closePanel}>
              <LineSeparatedContainer borderWidth={1}>
                {data.reject.map((x) => (
                  <ProductCreationScoringResultRowReject key={x.bank.code} bankName={x.bank.title} text={x.message} />
                ))}
              </LineSeparatedContainer>
            </ProductCreationScoringRejectsPanel>
          )
        }
        desktop={
          <ProductCreationScoringLoadingResultCard
            isLoading={isLoading(status)}
            title="Отказ банка"
            skeleton={ProductCreationScoringResultRowRejectSkeleton}
            skeletonElementsCount={2}
            items={data?.reject}
          >
            {(x) => <ProductCreationScoringResultRowReject bankName={x.bank.title} text={x.message} />}
          </ProductCreationScoringLoadingResultCard>
        }
      />
      {data?.duplicate?.length !== undefined && data.duplicate.length > 0 && (
        <ProductCreationScoringResultCard title="Банки, в которые уже была отправлена заявка">
          {data.duplicate.map((x) => (
            <ProductCreationScoringResultRowDuplicate
              key={x.bank.code}
              bankName={x.bank.title}
              productType="CreditExecution"
              productId={x.existingDemand?.productId}
              demandId={x.existingDemand?.demandId}
            />
          ))}
        </ProductCreationScoringResultCard>
      )}
    </FullPageContainerCenteredMainWithFooter>
  );
};
