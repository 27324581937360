import styled from "@emotion/styled";
import { css, CSSObject } from "@emotion/react";
import { BankGuaranteeDemandStageType } from "../../../../../common/types";

export const BankGuaranteeListItemBankLabel = styled.span<{ stage: BankGuaranteeDemandStageType }>(
  ({ theme }) => css`
    font-weight: 500;
    font-size: ${theme.font.fz2};
    line-height: ${theme.font.defaultLineHeight};
    color: ${theme.palette.typography.primary};
    flex-grow: 0;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  ({ theme, stage }): CSSObject => {
    if (stage === "Confirmed") {
      return { color: theme.palette.typography.success };
    }

    if (stage === "Rejected") {
      return { textDecoration: "line-through", color: theme.palette.typography.medium };
    }

    if (stage === "AttachingDocuments") {
      return { color: theme.palette.typography.primaryCaption };
    }

    return {};
  },
);
