import { RawWebApiResponseError } from "./RawWebApiResponseError";

/**
 * Обертка над ответом от апи версии v4 и выше.
 * Не содержит code со статусом ответа, так как в случае ошибки апи не отдает Status Code === 200
 */
export interface RawWebApiResponse<TResponse, TRequest = unknown> {
  isError: boolean;
  body: TResponse;
  errors?: RawWebApiResponseError<TRequest>;
}

export const isRawWebApiResponse = <TResponse, TRequest = unknown>(
  data: unknown,
): data is RawWebApiResponse<TResponse, TRequest> => {
  return (data as RawWebApiResponse<TResponse, TRequest>)?.isError !== undefined;
};
