import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../common/store";
import { BankGuaranteeId } from "../../../common/types/demand";
import { BankGuaranteeTransferPayload } from "../../common/store";
import { BankGuaranteeListItemDemand } from "../types/item/BankGuaranteeListItemDemand";

export const selectBankGuaranteeList = (state: RootState) => state.domains.bankGuarantee.list;
const selectBankGuaranteeListItems = (state: RootState) => selectBankGuaranteeList(state).items;
export const selectBankGuaranteeListTransferPayload = createSelector(
  [selectBankGuaranteeListItems, (_state: RootState, guaranteeId: BankGuaranteeId) => guaranteeId],
  (items, guaranteeId): BankGuaranteeTransferPayload | undefined => {
    const guarantee = items.guarantees.entities[guaranteeId];
    if (guarantee) {
      const demands = guarantee.demandIds
        .map((x) => items.demands.entities[x])
        .filter((x): x is BankGuaranteeListItemDemand => x !== undefined);

      const client = items.clients.entities[guarantee.clientId];
      if (client) {
        const { guaranteeSum, guaranteeType, dateAdded, federalLaw, purchaseLink, purchaseNumber } = guarantee;

        return {
          guarantee: {
            client,
            guaranteeId,
            guaranteeSum,
            guaranteeType,
            dateAdded,
            federalLaw,
            purchaseLink,
            purchaseNumber,
          },
          demands,
        };
      }
    }

    return undefined;
  },
);
