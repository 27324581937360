import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { useBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler } from "../../../../../hooks/reconciliation/useBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler";
import { BankGuaranteeDetailsDemandReconciliationConfirmPaymentDesktop } from "./desktop/BankGuaranteeDetailsDemandReconciliationConfirmPaymentDesktop";
import { BankGuaranteeDetailsDemandReconciliationConfirmPaymentMobile } from "./mobile/BankGuaranteeDetailsDemandReconciliationConfirmPaymentMobile";
import { ButtonLink } from "../../../../../../../common/ui-kit/buttons/ButtonLink";
import { Typography } from "../../../../../../../common/ui-kit/typography";
import { useBankGuaranteeDetailsLayoutContext } from "../../../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { useBankGuaranteeDetailsDemandReconciliationConfirmPayment } from "../../../../../hooks/reconciliation/useBankGuaranteeDetailsDemandReconciliationConfirmPayment";

export const BankGuaranteeDetailsDemandReconciliationConfirmPaymentResponsiveView = () => {
  const { demandId } = useBankGuaranteeDetailsLayoutContext();
  const { isModalOpen, openModal, closeModal, afterSuccess, panelKey } =
    useBankGuaranteeDetailsDemandReconciliationConfirmPayment(demandId!);
  const { handleFormSubmit, status } = useBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler({
    demandId: demandId!,
    onSuccess: afterSuccess,
  });

  return (
    <>
      <ButtonLink type="button" onClick={openModal}>
        <Typography lineHeight="defaultLineHeight" fontWeight={500}>
          Подтвердить оплату
        </Typography>
      </ButtonLink>

      <ThemeMobileDesktopView
        desktop={
          <BankGuaranteeDetailsDemandReconciliationConfirmPaymentDesktop
            onClose={closeModal}
            isModalOpen={isModalOpen}
            handleSubmit={handleFormSubmit}
            status={status}
          />
        }
        mobile={
          <BankGuaranteeDetailsDemandReconciliationConfirmPaymentMobile
            panelKey={panelKey}
            handleSubmit={handleFormSubmit}
            onClose={closeModal}
            status={status}
          />
        }
      />
    </>
  );
};
