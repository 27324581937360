import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ResponsiveStyleValue, SpaceType } from "../../../types";

export interface ShadowCardProps {
  padding?: ResponsiveStyleValue<SpaceType>;
  margin?: ResponsiveStyleValue<SpaceType>;
}

export const ShadowCard = styled("div", {
  shouldForwardProp: isPropValid,
})<ShadowCardProps>(
  {
    background: "#ffffff",
    boxShadow: "0 4px 12px rgba(10, 71, 137, 0.2)",
    borderRadius: "8px",

    display: "flex",
    flexDirection: "column",
  },
  ({ theme, padding }) =>
    makeResponsiveStyles(theme, padding, (x) => ({ padding: x === undefined ? undefined : theme.spaces[x] })),
  ({ theme, margin }) =>
    makeResponsiveStyles(theme, margin, (x) => ({ margin: x === undefined ? undefined : theme.spaces[x] })),
);
