import styled from "@emotion/styled";
import { BaseInputField } from "../../../common/components/BaseFormInputElement.styled";
import { ChevronLeftIcon } from "../../../../icons";

export const Select = styled(BaseInputField.withComponent("select"))`
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz4};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.typography.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  appearance: none;
  padding-right: calc(24px + ${({ theme }) => theme.spaces.md});
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Icon = styled(ChevronLeftIcon)`
  & > path {
    stroke: ${({ theme }) => theme.palette.typography.primary};
  }

  position: absolute;
  width: 24px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  right: ${({ theme }) => theme.spaces.md};
  pointer-events: none;
`;
