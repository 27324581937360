import { useCallback, useEffect, useState } from "react";
import { useWebPushesSubscriptionStateContext } from "./context/useWebPushesSubscriptionStateContext";
import { useLocationPathEffect } from "../../navigation/menu/hooks/useLocationPathEffect";
import { webPushesPromptService } from "../services/webPushesPromptService";

export const useWebPushesPeriodicallyPrompt = (checkOnlyOnPathLocation = false) => {
  const [needShowPrompt, setNeedShowPrompt] = useState(false);
  const state = useWebPushesSubscriptionStateContext();

  const dismiss = useCallback(() => {
    setNeedShowPrompt(false);
    webPushesPromptService.setTimeAfterDismiss();
  }, []);

  const check = useCallback(() => {
    if (state === "available" && webPushesPromptService.isTimeExpired()) {
      setNeedShowPrompt(true);
    }
  }, [state]);

  useEffect(() => {
    if (!checkOnlyOnPathLocation) {
      check();
    }
  }, [check, checkOnlyOnPathLocation]);

  useLocationPathEffect(() => {
    check();
  }, [check]);

  return { needShowPrompt, dismiss };
};
