import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BankGuaranteeDetailsDemandHeaderReconciliationBillNavigationCard } from "./BankGuaranteeDetailsDemandHeaderReconciliationBillNavigationCard";
import { selectBankGuaranteeDetailsActiveDemandActualBill } from "../../../../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { useBankGuaranteeDetailsReconciliationState } from "../../../../../hooks/reconciliation/useBankGuaranteeDetailsReconciliationState";
import { BankGuaranteeDetailsDemandReconciliationBillContent } from "../../../reconciliation/bill/BankGuaranteeDetailsDemandReconciliationBillContent";
import { BankGuaranteeDetailsDemandReconciliationBillContentSkeleton } from "../../../reconciliation/bill/BankGuaranteeDetailsDemandReconciliationBillContent.skeleton";
import { BankGuaranteeDetailDemandHeaderReconciliationCardDesktop } from "../card/BankGuaranteeDetailDemandHeaderReconciliationCard.desktop";
import { BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardSkeleton } from "../card/BankGuaranteeDetailsDemandHeaderReconciliationNavigationCard.mobile.skeleton";

export const BankGuaranteeDetailsDemandHeaderReconciliationBill = () => {
  const { model, needShowSkeleton } = useBankGuaranteeDetailsReconciliationState(
    selectBankGuaranteeDetailsActiveDemandActualBill,
  );

  return (
    <ThemeMobileDesktopView
      mobile={
        <>
          {needShowSkeleton && <BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardSkeleton />}
          {!needShowSkeleton && <BankGuaranteeDetailsDemandHeaderReconciliationBillNavigationCard model={model} />}
        </>
      }
      desktop={
        <>
          {needShowSkeleton && (
            <BankGuaranteeDetailDemandHeaderReconciliationCardDesktop>
              <BankGuaranteeDetailsDemandReconciliationBillContentSkeleton />
            </BankGuaranteeDetailDemandHeaderReconciliationCardDesktop>
          )}
          {!needShowSkeleton && model !== undefined && (
            <BankGuaranteeDetailDemandHeaderReconciliationCardDesktop>
              <BankGuaranteeDetailsDemandReconciliationBillContent model={model} />
            </BankGuaranteeDetailDemandHeaderReconciliationCardDesktop>
          )}
        </>
      }
    />
  );
};
