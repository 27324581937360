import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../common/store";
import { CreditExecutionListFiltersState } from "./creditExecutionListFilters.types";
import { createFuseSearchSelector } from "../../../../common/store/utils";
import { CodeWithTitle } from "../../../../common/types/objects";
import { bankSearchTransformer } from "../../../../common/utils/bankSearchTransformer";

export const selectCreditExecutionFilters = (state: RootState): CreditExecutionListFiltersState =>
  state.domains.creditExecution.list.filters;

export const selectCreditExecutionFiltersData = (state: RootState): CreditExecutionListFiltersState["data"] =>
  selectCreditExecutionFilters(state).data;

export const selectCreditExecutionListFiltersBanks = createFuseSearchSelector<CodeWithTitle>({
  selector: (state) => selectCreditExecutionFiltersData(state)?.bankIds.codeWithTitleList ?? [],
  ignoreLessThan: 0.55,
  keys: ["title"],
  searchTransformer: bankSearchTransformer,
});

export const selectCreditExecutionListFiltersBanksCodes = createSelector(
  [(state) => state, (state, search: string) => search],
  (state, search) => {
    return selectCreditExecutionListFiltersBanks(state, search).map((x) => x.code);
  }
);
