import { useFormikContext } from "formik";
import { useEffect } from "react";
import { dateTimeHelper } from "@sideg/helpers";
import { WidgetsCalculatorFormValues } from "../types/WidgetsCalculatorFormValues";
import { useGetFieldName } from "../../../form";
import { widgetsCalculatorPeriodsCalculationService } from "../services/widgetsCalculatorPeriodsCalculationService";
import { periodsSchemaWithLocker } from "../validation/widgetsCalculatorValidationSchema";
import { isWidgetsCalculatorFormPartValid } from "../utils/isWidgetsCalculatorFormPartValid";

export const useWidgetsCalculatorPeriodMutationEffect = () => {
  const getFieldName = useGetFieldName<WidgetsCalculatorFormValues>();
  const { values, setFieldValue } = useFormikContext<WidgetsCalculatorFormValues>();

  useEffect(() => {
    if (isWidgetsCalculatorFormPartValid(values, periodsSchemaWithLocker, "periodLocker")) {
      const { dateTo, period, periodLocker, dateFrom } = periodsSchemaWithLocker.cast(values, {
        assert: false,
      });

      if (periodLocker === "period" && dateTo && dateFrom) {
        setFieldValue(
          getFieldName("period"),
          widgetsCalculatorPeriodsCalculationService.getPeriodInDays(dateFrom, dateTo),
          true,
        );
      } else if (periodLocker === "dateTo" && period !== undefined && !Object.is(period, NaN) && dateFrom) {
        setFieldValue(
          getFieldName("dateTo"),
          dateTimeHelper.format(widgetsCalculatorPeriodsCalculationService.getDateTo(dateFrom, period), "dateOnly"),
          true,
        );
      } else if (periodLocker === "dateFrom" && period !== undefined && !Object.is(period, NaN) && dateTo) {
        setFieldValue(
          getFieldName("dateFrom"),
          dateTimeHelper.format(widgetsCalculatorPeriodsCalculationService.getDateFrom(dateTo, period), "dateOnly"),
          true,
        );
      }
    }
  }, [getFieldName, setFieldValue, values]);
};
