import { CreditExecutionId, DemandId } from "../../../../../../common/types/demand";
import { useProductRejectionModalPanel } from "../../../../../../common/product-rejection";
import { useAppSelector } from "../../../../../../common/store";
import { CreditExecutionRejectionDemand } from "../../../../../common/rejection";
import {
  selectCreditExecutionListItemsById,
  selectCreditExecutionListItemsDemandById,
} from "../../../../store/items/creditExecutionListItems.selectors";
import { useCreditExecutionListItemModificationView } from "../../../../hooks/item-modification/useCreditExecutionListItemModificationView";

export interface CreditExecutionListItemRejectDemandProps {
  creditExecutionId: CreditExecutionId;
  demandId: DemandId;
}

export const CreditExecutionListItemRejectDemand = ({
  creditExecutionId,
  demandId,
}: CreditExecutionListItemRejectDemandProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const item = useAppSelector((state) => selectCreditExecutionListItemsById(state, creditExecutionId))!;
  const demand = useAppSelector((state) => selectCreditExecutionListItemsDemandById(state, demandId));

  const modalState = useProductRejectionModalPanel();
  const {
    isModalOpen,
    closeModal,
    onSuccess: afterSuccess,
  } = useCreditExecutionListItemModificationView(creditExecutionId, modalState, "Заявка успешно отменена");

  return (
    <CreditExecutionRejectionDemand
      isOpen={isModalOpen}
      onClose={closeModal}
      afterSuccess={afterSuccess}
      creditExecutionId={creditExecutionId}
      demandId={demandId}
      bankName={demand?.bank.title ?? ""}
      groupProductName={item.groupProductName}
    />
  );
};
