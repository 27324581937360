import styled from "@emotion/styled";
import { Box } from "../../../../common/ui-kit/containers/box";
import { Typography } from "../../../../common/ui-kit/typography";

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.xxl} ${({ theme }) => theme.spaces.none};
  width: 100%;
  gap: ${({ theme }) => theme.spaces.sm};
  border-top: 1px solid ${({ theme }) => theme.palette.background.contrast};
  align-items: stretch;

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spaces.xl};
  }
`;

export const PriceBlock = styled(Box)`
  width: 100%;
  align-items: stretch;

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    width: 270px;
    flex-shrink: 0;
    align-items: flex-start;
  }
`;

export const Note = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spaces.md};
`;
