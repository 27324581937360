import { EntityId } from "@reduxjs/toolkit";
import { QueueTaskId } from "../../../common/types/demand";
import { QueueTasksListItem } from "./list-item/QueueTasksListItem";
import { NavigationState } from "../../../common/navigation/navigation-state";
import { RootState, useAppSelector } from "../../../common/store";
import { QueueTasksListItem as QueueTasksListItemType } from "../../../common/types/queue-tasks/QueueTasksListItem";
import { QueueTasksListItemsContainer } from "./QueueTasksListItemsContainer";

export interface QueueTasksListContainerProps {
  idsSelector: (state: RootState) => EntityId[];
  entitySelector: (state: RootState, queueTaskId: QueueTaskId) => QueueTasksListItemType | undefined;
  from?: NavigationState;
}

export const QueueTasksListContainer = ({ idsSelector, entitySelector, from }: QueueTasksListContainerProps) => {
  const ids = useAppSelector(idsSelector);

  return (
    <QueueTasksListItemsContainer>
      {ids.map((x) => (
        <QueueTasksListItem key={x} id={x as QueueTaskId} from={from} entitySelector={entitySelector} />
      ))}
    </QueueTasksListItemsContainer>
  );
};
