import { BaseClient, ClientId, CreditWorkingAssetsId, DemandId } from "../../../../common/types/demand";
import { CreditWorkingAssetsListItem } from "../../types/items/CreditWorkingAssetsListItem";
import { CreditWorkingAssetsDemandBase } from "../../../common/types";
import { createAppEntityAdapter } from "../../../../common/store/utils";

export const creditWorkingAssetsListItemsClientsAdapter = createAppEntityAdapter<BaseClient, ClientId>({
  selectId: (x) => x.id,
});

export const creditWorkingAssetsListItemsAdapter = createAppEntityAdapter<
  CreditWorkingAssetsListItem,
  CreditWorkingAssetsId
>({
  selectId: (x) => x.creditWorkingAssetsId,
});

export const creditWorkingAssetsListItemsDemandsAdapter = createAppEntityAdapter<
  CreditWorkingAssetsDemandBase,
  DemandId
>({
  selectId: (x) => x.demandId,
});
