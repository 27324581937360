import { ReactNode } from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { AppUrlSelector } from "../../../../common/urls/internal/types";
import * as S from "./PublicUserRegistrationCardLink.styled";
import { Typography } from "../../../../common/ui-kit/typography";

export interface PublicUserRegistrationCardLinkProps<TUrl extends string> extends BaseCssProps {
  title: string;
  children: ReactNode;
  url: AppUrlSelector<TUrl, never>;
}

export const PublicUserRegistrationCardLink = <TUrl extends string>({
  title,
  children,
  url,
}: PublicUserRegistrationCardLinkProps<TUrl>) => {
  return (
    <S.Card to={url}>
      <Typography fontWeight={700} fontSize="fz4">
        {title}
      </Typography>
      {children}
    </S.Card>
  );
};
