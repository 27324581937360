import styled from "@emotion/styled";

export const Header = styled.header`
  flex: 0 0 ${({ theme }) => theme.layout.header.height};
  background-color: ${({ theme }) => theme.layout.header.backgroundColor};
  padding: ${({ theme }) => theme.spaces.md};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > *:only-child {
    margin-left: auto;
  }
`;
