import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const Aside = styled.aside`
  background-color: ${({ theme }) => theme.layout.demand.colors.leftSideBackgroundColor};
  width: 100%;
  padding: ${({ theme }) => theme.spaces.xl};
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 100%;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    width: 40vw;
    padding: ${({ theme }) => theme.layout.borderPadding};
  }
`;

export const Content = styled.section`
  display: flex;
  height: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.background.contrastLight};
  box-shadow: -4px 0 12px rgba(15, 37, 95, 0.04);
  overflow: hidden;
`;
