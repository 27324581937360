import { createSlice, isAnyOf, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import {
  BANK_GUARANTEE_DETAILS_SLICE_NAME,
  getBankGuaranteeDetails,
  uploadGroupDocumentBankGuaranteeDetailsDemand,
} from "../bankGuaranteeDetails.thunks";
import { BankGuaranteeDetailsGuaranteeDetailsState } from "./bankGuaranteeDetailsGuaranteeDetails.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import { bankGuaranteeDetailsGuaranteeChanged } from "../bankGuaranteeDetails.actions";
import { bankGuaranteeDetailsGuaranteeDetailsMapper } from "./bankGuaranteeDetailsGuaranteeDetails.mapper";

const SLICE_NAME = combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "guarantee");

const initialState: BankGuaranteeDetailsGuaranteeDetailsState = {
  data: undefined,
  status: LoadingStatusEnum.Idle,
  lastRequestTime: 0,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reload: (state) => {
      state.status = LoadingStatusEnum.Idle;
    },
    setLastRequestTime: (state, { payload }: PayloadAction<Date | number>) => {
      state.lastRequestTime = Number(payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bankGuaranteeDetailsGuaranteeChanged, (state, action) => {
        if (action.payload !== undefined) {
          return { ...initialState, data: action.payload.guarantee, isTransferred: true };
        }

        return initialState;
      })
      .addMatcher(
        isAnyOf(getBankGuaranteeDetails.fulfilled, uploadGroupDocumentBankGuaranteeDetailsDemand.fulfilled),
        (state, action) => {
          state.data = bankGuaranteeDetailsGuaranteeDetailsMapper.mapFromResponse(action.payload.body);
          state.isTransferred = false;
        },
      )
      .addMatcher(isAsyncThunkAction(getBankGuaranteeDetails), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const bankGuaranteeDetailsGuaranteeDetailsReducer = slice.reducer;
export const bankGuaranteeDetailsGuaranteeDetailsActions = slice.actions;
