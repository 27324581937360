import { KeyboardEventHandler } from "react";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { useDemandQueueTaskActiveField } from "../../hooks/active/form/useDemandQueueTaskActiveField";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export interface DemandQueueTaskActiveFormCommentFieldProps extends BaseCssProps {
  onKeyPress?: KeyboardEventHandler<HTMLTextAreaElement>;
  isDisabled?: boolean;
}

export const DemandQueueTaskActiveFormCommentField = ({
  className,
  onKeyPress,
  isDisabled,
}: DemandQueueTaskActiveFormCommentFieldProps) => {
  const isDesktop = useThemeIsDesktop();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ initialValue, ...fieldProps }] = useDemandQueueTaskActiveField("comment");

  return (
    <FormTextarea
      maxRows={isDesktop ? 20 : 15}
      minRows={2}
      placeholder="Ваш комментарий"
      {...fieldProps}
      className={className}
      onKeyDown={onKeyPress}
      disabled={isDisabled}
    />
  );
};
