import { useCallback } from "react";
import { AnyObjectSchema, InferType } from "yup";
import { useSetActualPathnameQuerySearch } from "../../hooks/useSetActualPathnameQuerySearch";
import { filtrationService } from "../../services/filtrationService";
import { PaginatedFilteringObjectType } from "../../types/PaginatedFilteringObjectType";

export const usePaginatedSubsetQueryMutator = () => {
  const setQuery = useSetActualPathnameQuerySearch();

  return useCallback(
    <
      TFilter extends PaginatedFilteringObjectType,
      TSchema extends AnyObjectSchema,
      TSubset extends Partial<TFilter> & InferType<TSchema>,
    >(
      filter: TFilter,
      schema: TSchema,
      subsetValues: TSubset,
    ) => {
      const castedValues = schema.cast(subsetValues, {
        assert: false,
      });

      // не удаляем undefined поля из мутации, чтобы пропавшие из фильтра поля были удалены
      const allValues: TFilter = {
        ...filter,
        ...castedValues,
      } as TFilter;

      setQuery(filtrationService.setValues(filter, { ...allValues, page: 1 }));
    },
    [setQuery],
  );
};
