import * as React from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../ui-kit/types";
import * as S from "./AppLayoutResponsiveListPage.styled";
import { AppLayoutFooter } from "../../../footer";
import { Text } from "../../../../../ui-kit/typography";

export interface AppLayoutResponsiveListPageProps extends BaseCssProps, S.WrapperProps {
  children?: React.ReactNode;
}

const Page = ({ className, children, backgroundColor }: AppLayoutResponsiveListPageProps) => {
  return (
    <S.Wrapper className={className} backgroundColor={backgroundColor}>
      {children}
      <AppLayoutFooter withLayoutBorder />
    </S.Wrapper>
  );
};

export interface AppLayoutResponsiveListPageTitleProps extends BaseCssProps {
  children?: React.ReactNode;
}

const Title = ({ className, children }: AppLayoutResponsiveListPageTitleProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Text
      as="h2"
      fontColor="primary"
      fontSize={{ xs: 1.75, [desktopBreakpoint]: 2.25 }}
      fontWeight={500}
      lineHeight="44px"
      className={className}
    >
      {children}
    </Text>
  );
};

Page.Header = S.Header;
Page.List = S.ListContainer;
Page.TabsContainer = S.TabsFilterContainer;
Page.Title = Title;

export const AppLayoutResponsiveListPage = Page;
