import { forwardRef, ReactNode } from "react";
import { TrashLightIcon } from "../../../../ui-kit/icons";
import * as S from "./RejectButton.styled";
import { BaseCssProps } from "../../../../ui-kit/types";

export interface RejectButtonProps extends BaseCssProps, S.ButtonProps {
  children: ReactNode;
  onClick: JSX.IntrinsicElements["button"]["onClick"];
}

export const RejectButton = forwardRef<HTMLButtonElement, RejectButtonProps>(
  ({ children, className, onClick, size }, ref) => {
    return (
      <S.Button type="button" size={size} onClick={onClick} className={className} ref={ref}>
        <TrashLightIcon /> <span>{children}</span>
      </S.Button>
    );
  }
);
