import * as React from "react";
import TextareaRequired from "../../../components/controls-old/textarea/TextareaRequired";
import Button, { ButtonColor, ButtonType } from "../../../components/controls-old/button/Button";
import CommentsWrapper from "../../../components/controls-old/comments/CommentsWrapper";
import CommentModel from "../../../models/CommentModel";
import IValue from "../../../components/controls-old/IValue";
import AgentReportStatusModel from "../../../models/AgentReportStatusModel";

interface IAgentReportItemCommentsProps {
  isAgentSide: boolean;
  status: AgentReportStatusModel;
  comments: CommentModel[];
  onCommentChange: (value: IValue) => void;
  onRework: () => void;
  onApproved: () => void;
}

const AgentReportItemComments: React.FC<IAgentReportItemCommentsProps> = (props) => {
  return (
    <div className="agent-report-item__comments">
      <div className="agent-report-item__header">Комментарии</div>

      {props.isAgentSide && (
        <TextareaRequired
          viewError={false}
          name={"comment"}
          placeholder={"Комментарий"}
          onChange={(value) => props.onCommentChange(value)}
        />
      )}

      <div className="agent-report-item__comments-buttons">
        {props.status.code === "Processing" && props.isAgentSide && (
          <>
            <div className="agent-report-item__comments-buttons-item">
              <Button
                onClick={() => props.onRework()}
                isDisabled={false}
                type={ButtonType.Button}
                color={ButtonColor.BlueLight}
              >
                На доработку
              </Button>
            </div>
            <div className="agent-report-item__comments-buttons-item">
              <Button
                onClick={() => props.onApproved()}
                isDisabled={false}
                type={ButtonType.Button}
                color={ButtonColor.Green}
              >
                Согласовать
              </Button>
            </div>
          </>
        )}
        {props.status.code === "Aggreed" && props.isAgentSide && (
          <div className="agent-report-item__comments-buttons-item">
            <Button
              onClick={() => props.onRework()}
              isDisabled={false}
              type={ButtonType.Button}
              color={ButtonColor.BlueLight}
            >
              Отправить
            </Button>
          </div>
        )}
      </div>

      {props.comments && props.comments.length > 0 && (
        <div className="agent-report-item__comments-wrapper">
          <CommentsWrapper comments={props.comments} />
        </div>
      )}
    </div>
  );
};

export default AgentReportItemComments;
