import { memo } from "react";
import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";
import { AppInfinityScroll } from "../../../../common/ui/product/list/infinite-scroll";
import { AppInfinityScrollTitles } from "../../../../common/ui/product/list/infinite-scroll/components/AppInfinityScroll";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { getBankGuaranteeList } from "../../store/bankGuaranteeList.thunks";
import { useBankGuaranteeListFilterContext } from "../../hooks/filter-context/useBankGuaranteeListFilterContext";
import {
  selectBankGuaranteeListItems,
  selectBankGuaranteeListItemsGuaranteesIds,
} from "../../store/items/bankGuaranteeListItems.selectors";
import { BankGuaranteeListItems } from "./BankGuaranteeListItems";
import { BankGuaranteeListItemSkeleton } from "./item/BankGuaranteeListItem.skeleton";
import { BankGuaranteeListModification } from "./modification/BankGuaranteeListModification";
import { useFilteredPaginatedListPage } from "../../../../common/filters";
import { ProductListItemsContainer } from "../../../../common/ui/product/list/container";

const titles: AppInfinityScrollTitles = {
  end: "Загружены все заявки",
  hasNoLoadedItems: "Нет заявок",
  navigateToFirstPage: "Показать предыдущие заявки",
  error: "При получении списка заявок возникла ошибка",
};

export interface BankGuaranteeListProps extends BaseCssProps {
  onErrorReloadButtonClick: () => void | Promise<void>;
}

export const BankGuaranteeList = memo(({ className, onErrorReloadButtonClick }: BankGuaranteeListProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const { onPreviousPageButtonClick, hasPreviousPage, onNextPageFetching } = useFilteredPaginatedListPage({
    filteringObject,
    action: getBankGuaranteeList,
  });

  return (
    <AppLayoutResponsiveListPage.List className={className}>
      <BankGuaranteeListModification />
      <AppInfinityScroll
        page={filteringObject.page}
        paginatedStateSelector={selectBankGuaranteeListItems}
        idsSelector={selectBankGuaranteeListItemsGuaranteesIds}
        titles={titles}
        onPreviousPageButtonClick={onPreviousPageButtonClick}
        onPageChanged={onNextPageFetching}
        onErrorReloadButtonClick={onErrorReloadButtonClick}
        hasPreviousPage={hasPreviousPage}
        emptyLoader={
          <ProductListItemsContainer>
            <BankGuaranteeListItemSkeleton />
            <BankGuaranteeListItemSkeleton />
            <BankGuaranteeListItemSkeleton />
            <BankGuaranteeListItemSkeleton />
          </ProductListItemsContainer>
        }
      >
        <BankGuaranteeListItems />
      </AppInfinityScroll>
    </AppLayoutResponsiveListPage.List>
  );
});
