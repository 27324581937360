import typographyVariants from "@sideg/ui-kit/core/theme/__generated__/typography.gen";
import { getPathsCollection } from "@sideg/ui-kit/core/theme/utils/getValueByPath";
import {
  isThemeTypographyTokenValue,
  ThemeTypographyVariantTokenValue,
  ThemeTypographyVariantValue,
} from "@sideg/ui-kit/core/theme/types/typography/ThemeTypographyVariantTokenValue";
import {
  ThemeTypographyFontSizes,
  ThemeTypographyFontWeights,
  ThemeTypographyLineHeights,
} from "@sideg/ui-kit/core/theme/types/typography/ThemeTypographySizes";
import { SizeInPixels, SizeInRems } from "@sideg/ui-kit/common/types/sizes/SizeWithUnit";
import { ExtractTypeFromConst } from "@sideg/ui-kit/core/theme/types/utils/ExtractTypeFromConst";

export const generatedTypographyVariants = typographyVariants satisfies ThemeTypographyVariantValue;
export type ThemeTypographyVariants = ExtractTypeFromConst<
  typeof generatedTypographyVariants,
  ThemeTypographyVariantTokenValue
>;

export const themeTypographyVariants = getPathsCollection<ThemeTypographyVariants, ThemeTypographyVariantTokenValue>(
  typographyVariants,
  isThemeTypographyTokenValue,
);

export const themeTypographyFontSizes = {
  fz12: "12px",
  fz14: "14px",
  fz16: "16px",
  fz18: "18px",
} satisfies Record<ThemeTypographyFontSizes, SizeInPixels>;

export const themeTypographyLineHeights = {
  default: "1.5rem",
} satisfies Record<ThemeTypographyLineHeights, SizeInRems>;

export const themeTypographyFontWeights = {
  400: 400,
  500: 500,
  700: 700,
} satisfies Record<ThemeTypographyFontWeights, number>;
