import * as React from "react";
import { ToastType } from "../types/ToastType";
import * as S from "./Toast.styled";
import { CancelIcon, SuccessIcon, XOctagonIcon } from "../../../icons";
import { BaseCssProps } from "../../../types";

export interface ToastProps extends BaseCssProps {
  type: ToastType;
  children?: React.ReactNode;
  onClose?: () => void | Promise<void>;
}

const getIconByType = (type: ToastType): React.ElementType => {
  switch (type) {
    case "success":
      return SuccessIcon;
    default:
      return XOctagonIcon;
  }
};

export const Toast = React.forwardRef<HTMLDivElement, ToastProps>(({ type, children, onClose, className }, ref) => {
  return (
    <S.Container ref={ref} className={className}>
      {type !== "custom" && <S.Icon type={type} as={getIconByType(type)} />}
      {children}
      <S.CancelButton type="button" onClick={onClose}>
        <CancelIcon />
      </S.CancelButton>
    </S.Container>
  );
});
