import { BankGuaranteeRejectionRejectGuaranteeResponsiveView } from "../../../../rejection";
import { BankGuaranteeId } from "../../../../../common/types/demand";
import { useProductRejectionModalPanel } from "../../../../../common/product-rejection";
import { useBankGuaranteeListItemModificationView } from "../../../hooks/modification/useBankGuaranteeListItemModificationView";

export interface BankGuaranteeListItemRejectGuaranteeViewProps {
  guaranteeId: BankGuaranteeId;
}

export const BankGuaranteeListItemRejectGuaranteeView = ({
  guaranteeId,
}: BankGuaranteeListItemRejectGuaranteeViewProps) => {
  const modalState = useProductRejectionModalPanel();
  const {
    isModalOpen,
    closeModal,
    onSuccess: afterSuccess,
  } = useBankGuaranteeListItemModificationView(guaranteeId, modalState, "Заявка отменена");

  return (
    <BankGuaranteeRejectionRejectGuaranteeResponsiveView
      isOpen={isModalOpen}
      guaranteeId={guaranteeId}
      onClose={closeModal}
      afterSuccess={afterSuccess}
    />
  );
};
