import { RootState } from "../../../../common/store";
import { BankGuaranteeDetailsTasksHistoryState } from "./bankGuaranteeDetailsTasksHistory.types";
import { bankGuaranteeDetailsTasksHistoryAdapter } from "./bankGuaranteeDetailsTasksHistory.adapter";

export const selectBankGuaranteeDetailsTasksHistory = (state: RootState): BankGuaranteeDetailsTasksHistoryState =>
  state.domains.bankGuarantee.details.tasksHistory;

export const {
  selectIds: selectBankGuaranteeDetailsTasksHistoryIds,
  selectById: selectBankGuaranteeDetailsTasksHistoryById,
} = bankGuaranteeDetailsTasksHistoryAdapter.getSelectors(selectBankGuaranteeDetailsTasksHistory);
