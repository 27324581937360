import { useCallback } from "react";
import { RawApiResponse } from "../../../common/api/types/RawApiResponse";
import { QueueTasksNeedUpdatePayload } from "../../../common/types/queue-tasks";
import { queueTasksNeedUpdate } from "../../common/store";
import { useAppDispatch } from "../../../common/store";
import { useHubEvent } from "../../../common/hubs";
import { queueTasksHubConnection } from "../../../common/hubs-use-cases/queue-tasks";

export const useQueueTasksNavigationHubNeedUpdateEvent = () => {
  const dispatch = useAppDispatch();
  const handler = useCallback(
    (payload: RawApiResponse<QueueTasksNeedUpdatePayload>) => {
      dispatch(queueTasksNeedUpdate(payload.body));
    },
    [dispatch]
  );

  useHubEvent(queueTasksHubConnection, "NeedUpdate", handler);
};
