import { lkApiV2 } from "../essentials/configs";
import TabType from "../models/TabType";
import { AgentReportCreateForm } from "../store/actions/agentReportCreateActionsTypes";
import { AgentReportUpdateForm } from "../store/actions/agentReportUpdateActionsType";
import { AgentReportSignCodeForm, AgentReportSignForm } from "../store/actions/agentReportActionsTypes";
import { axiosJWT } from "../data/api/apiConfig";

export const getAgentReportCounter = async (): Promise<{}> => {
  return axiosJWT.get(`${lkApiV2()}/AgentReport/count-active`);
};

export const agentReportGetList = async (tab: TabType, page: number, itemPerPage: number): Promise<{}> => {
  return axiosJWT.get(`${lkApiV2()}/AgentReport`, {
    params: {
      Tab: tab,
      Page: page,
      PageSize: itemPerPage,
    },
  });
};

export const agentReportGetItem = async (id: number): Promise<{}> => {
  return axiosJWT.get(`${lkApiV2()}/AgentReport/${id}`);
};

export const agentReportSendCreateForm = async (form: AgentReportCreateForm): Promise<{}> => {
  return axiosJWT.post(`${lkApiV2()}/AgentReport`, form);
};

export const agentReportSendUpdateForm = async (id: number, form: AgentReportUpdateForm): Promise<{}> => {
  const formData = new FormData();
  formData.append("approved", form.approved.toString());
  if (form.comment) {
    formData.append("comment", form.comment);
  }
  form.fileList?.forEach((item, index) => {
    formData.append(`fileList[${index}].IssuingCompanyId`, item.issuingCompanyId.toString());
    formData.append(`fileList[${index}].File`, item.file);
  });

  return axiosJWT.post(`${lkApiV2()}/AgentReport/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const agentReportGetSignCode = async (form: AgentReportSignCodeForm): Promise<{}> => {
  return axiosJWT.post(`${lkApiV2()}/AgentReport/SignCode`, form);
};

export const agentReportSendSignCode = async (form: AgentReportSignCodeForm): Promise<{}> => {
  return axiosJWT.post(`${lkApiV2()}/AgentReport/SignCodeCheck`, form);
};

export const agentReportSendSignFile = async (id: number, form: AgentReportSignForm): Promise<{}> => {
  return axiosJWT.post(`${lkApiV2()}/AgentReport/SignFile/${id}`, form);
};
