import { useMemo, useRef } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { useField } from "formik";
import { useFactoringCreationDebtorsField } from "./useFactoringCreationDebtorsField";
import { FactoringCreationDebtorId } from "../types/FactoringCreationDebtorId";
import { FactoringCreationDebtorDocument } from "../types/FactoringCreationDebtorDocument";
import { FactoringCreationDebtorFormValues } from "../types/FactoringCreationDebtorFormValues";
import { make } from "../../../common/types/utils";
import { factoringCreationDebtorValidationSchema } from "../validation/factoringCreationValidationSchema";
import { useGetFieldName } from "../../../common/form";
import { FactoringCreationFormValues } from "../types/FactoringCreationFormValues";
import { FactoringCreationDebtorDocumentFromValues } from "../types/FactoringCreationDebtorDocumentFromValues";

const MAX_DEBTORS_COUNT = 5;

export const useFactoringCreationDebtorsListActions = () => {
  const getFieldName = useGetFieldName<FactoringCreationFormValues>();
  const [{ value: debtorsIdsValue }, , { setValue: setDebtorsIdsValue }] = useField<FactoringCreationDebtorId[]>(
    getFieldName("debtorsIds"),
  );
  const [{ value }, , { setValue }] = useFactoringCreationDebtorsField();

  const valueRef = useRef(value);
  valueRef.current = value;

  return useMemo(() => {
    const canAdd = debtorsIdsValue.length < MAX_DEBTORS_COUNT;
    const canRemove = debtorsIdsValue.length > 1;

    const onRemove = async (debtorId: FactoringCreationDebtorId) => {
      if (canRemove) {
        const updatedValue = Object.fromEntries(Object.entries(valueRef.current).filter(([key]) => key !== debtorId));
        setValue(updatedValue, true);
        setDebtorsIdsValue(debtorsIdsValue.filter((x) => x !== debtorId));
      }
    };

    const onAdd = async (documents: FactoringCreationDebtorDocument[]) => {
      if (canAdd) {
        const newDebtor: Partial<FactoringCreationDebtorFormValues> = {
          debtorId: make<FactoringCreationDebtorId>()(nanoid()),
        };

        const debtorDocuments: Record<string, FactoringCreationDebtorDocumentFromValues> = {};
        const documentTempIds: FactoringCreationDebtorFormValues["documentTempIds"] = [];

        documents.forEach((x) => {
          const tempId = nanoid();
          documentTempIds.push(tempId);
          debtorDocuments[tempId] = {
            tempId,
            documentTypeId: x.documentTypeId,
            dateAdded: undefined,
            file: undefined,
          };
        });

        newDebtor.documentTempIds = documentTempIds;
        newDebtor.documents = debtorDocuments;

        const withDefaults = factoringCreationDebtorValidationSchema.cast(
          newDebtor,
        ) as FactoringCreationDebtorFormValues;

        const updatedValue = { ...valueRef.current, [withDefaults.debtorId]: withDefaults };
        setValue(updatedValue, true);
        setDebtorsIdsValue([...debtorsIdsValue, withDefaults.debtorId]);
      }
    };

    return { ids: debtorsIdsValue, canAdd, canRemove, onRemove, onAdd };
  }, [debtorsIdsValue, setDebtorsIdsValue, setValue]);
};
