import { PurchaseCreationField } from "../../../../../common/purchase";
import { useBankGuaranteeCreationPurchaseFieldsMutateEffect } from "../../../hooks/creation/purchase/useBankGuaranteeCreationPurchaseFieldsMutateEffect";
import { selectBankGuaranteeCreationPurchase } from "../../../store/bankGuaranteeCreation.selectors";
import { useBankGuaranteeCreationPurchaseMutator } from "../../../hooks/creation/purchase/useBankGuaranteeCreationPurchaseMutator";
import { useBankGuaranteeCreationFieldHelpers } from "../../../hooks/creation/fields/useBankGuaranteeCreationFieldHelpers";

export interface BankGuaranteeCreationContractPurchaseFieldProps {
  isDisabled?: boolean;
}

export const BankGuaranteeCreationContractPurchaseField = ({
  isDisabled,
}: BankGuaranteeCreationContractPurchaseFieldProps) => {
  const { getFieldName } = useBankGuaranteeCreationFieldHelpers();
  const formMutator = useBankGuaranteeCreationPurchaseMutator();

  useBankGuaranteeCreationPurchaseFieldsMutateEffect();

  return (
    <PurchaseCreationField
      isDisabled={isDisabled}
      fixedKey="BankGuarantee"
      purchaseStateSelector={selectBankGuaranteeCreationPurchase}
      formMutator={formMutator}
      name={getFieldName("purchaseNumber")}
    />
  );
};
