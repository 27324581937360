import styled from "@emotion/styled";
import { CSSObject } from "@emotion/styled/macro";
import { Button } from "../../../button";
import { ControlSize } from "../../../../types";

const buttonPaddingSizes: Record<ControlSize, CSSObject["width"]> = {
  small: "0.25rem 0.63rem",
  default: "0.63rem",
  medium: "0.63rem 0.58rem",
};

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isOpen" && prop !== "paddingSize",
})<{
  isOpen: boolean;
  paddingSize?: ControlSize;
}>`
  border: none;

  padding: ${({ paddingSize }) => paddingSize !== undefined && buttonPaddingSizes[paddingSize]};
  outline: ${({ theme, isOpen }) => (isOpen ? `1px solid ${theme.colors.primaryLight}` : "none")};

  & > svg {
    transform: rotate(${({ isOpen }) => (isOpen ? "90deg" : "-90deg")});
    transition: all 0.2s linear;
  }
`;
