import styled from "@emotion/styled";
import { CreditWorkingAssetsDemandStageType } from "../../../../common/types";
import { getElementColor } from "../../../../../common/ui-kit/types";
import { getCreditWorkingAssetsDemandStageColor } from "../../../../common/utils";
import { CreditWorkingAssetsStageIcon } from "../../../../common/components";

export const TaskCounter = styled.span`
  background-color: ${({ theme }) => theme.colors.info};
  padding: 4px ${({ theme }) => theme.spaces.sm};
  border-radius: 4px;
  margin-left: ${({ theme }) => theme.spaces.xs};
  letter-spacing: 2px;
`;

export const Icon = styled(CreditWorkingAssetsStageIcon)`
  width: 20px;
  height: 20px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Separator = styled.div<{ stage: CreditWorkingAssetsDemandStageType }>`
  align-self: stretch;
  width: 2px;
  background-color: ${({ theme, stage }) => getElementColor(getCreditWorkingAssetsDemandStageColor(stage), theme)};
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Status = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusWithCounter = styled.div<{
  hasActiveTasks: boolean;
  stage: CreditWorkingAssetsDemandStageType;
}>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.xs};
  align-items: center;
  justify-content: flex-start;
  font-size: ${({ theme }) => theme.font.fz2};
  font-weight: 500;
  line-height: 1;
  color: ${({ theme, hasActiveTasks, stage }) => {
    if (hasActiveTasks) {
      return theme.colors.primary;
    }

    switch (stage) {
      case "Approved":
        return theme.palette.status.success;
      case "Rejected":
        return theme.palette.status.warning;
      default:
        return theme.colors.dark;
    }
  }};
`;

export const BankName = styled.span<{ stage: CreditWorkingAssetsDemandStageType }>`
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 1;
  font-weight: 700;
  color: ${({ theme, stage }) => {
    if (stage === "AttachingDocuments" || stage === "Rejected") {
      return theme.palette.typography.medium;
    }

    if (stage === "Approved") {
      return theme.palette.typography.success;
    }

    return theme.palette.typography.primary;
  }};
`;
