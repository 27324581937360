import styled from "@emotion/styled";

export const Container = styled.section`
  background-color: ${({ theme }) => theme.palette.background.light};
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;

  & > * {
    max-width: 720px;
  }
`;
