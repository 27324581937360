import { dateTimeHelper } from "@sideg/helpers";
import { DateRangeFilterType } from "../types/DateRangeFilterType";

export const getDateCreateFrom = (interval: DateRangeFilterType) => {
  switch (interval) {
    case "week":
      return dateTimeHelper.unaryModify("startOfWeek", new Date());
    case "month":
      return dateTimeHelper.unaryModify("startOfMonth", new Date());
    case "threeMonth":
      return dateTimeHelper.simpleModify("subMonths", new Date(), 3);
    case "sixMonth":
      return dateTimeHelper.simpleModify("subMonths", new Date(), 6);
    default:
      return undefined;
  }
};
