import { AuthenticationService } from "./services/authenticationService";

export { AuthenticationUserService } from "./services/authenticationUserService";
export type {
  AuthenticationUserAuthenticatedCallback,
  AuthenticationRemovedCallback,
} from "./services/authenticationService";
export type { AuthenticationUserTokenDetails } from "./types/AuthenticationUserTokenDetails";

export const authenticationService: AuthenticationService = new AuthenticationService();
