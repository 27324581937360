import { useEffect, useState } from "react";
import { LoadingStatusEnum } from "../types/state";

enum EventType {
  Load = "load",
  Error = "error",
}

const STORED_STATUS_ATTRIBUTE = "data-status";

const getLoadingStatusByEventType = (event: Event) => {
  const type = event.type as EventType;

  return type === EventType.Load ? LoadingStatusEnum.Succeeded : LoadingStatusEnum.Failed;
};

const createScriptElement = (src: string): HTMLScriptElement => {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.setAttribute(STORED_STATUS_ATTRIBUTE, LoadingStatusEnum.Loading);

  document.body.appendChild(script);

  const setAttributeFromEvent = (event: Event) => {
    script?.setAttribute(STORED_STATUS_ATTRIBUTE, getLoadingStatusByEventType(event));
  };

  script.addEventListener(EventType.Load, setAttributeFromEvent);
  script.addEventListener(EventType.Error, setAttributeFromEvent);

  return script;
};

export const useScript = (src: string): LoadingStatusEnum => {
  const [status, setStatus] = useState<LoadingStatusEnum>(src ? LoadingStatusEnum.Loading : LoadingStatusEnum.Idle);

  useEffect(() => {
    if (!src) {
      setStatus(LoadingStatusEnum.Idle);

      return;
    }

    let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);

    if (!script) {
      script = createScriptElement(src);
    } else {
      setStatus(script.getAttribute(STORED_STATUS_ATTRIBUTE) as LoadingStatusEnum);
    }

    const setStateFromEvent = (event: Event) => {
      setStatus(getLoadingStatusByEventType(event));
    };

    script.addEventListener(EventType.Load, setStateFromEvent);
    script.addEventListener(EventType.Error, setStateFromEvent);

    // eslint-disable-next-line consistent-return
    return () => {
      if (script) {
        script.removeEventListener(EventType.Load, setStateFromEvent);
        script.removeEventListener(EventType.Error, setStateFromEvent);
      }
    };
  }, [src]);

  return status;
};
