import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { NavigationState } from "../../../../../navigation/navigation-state";
import { BaseCssProps, ElementColor } from "../../../../../ui-kit/types";
import { AppLink } from "../../../../../urls/internal/components";
import {
  ProductListCardDemandListItemStatusLinkCounterDesktop,
  ProductListCardDemandsListRejectTooltipIconDesktop,
  ProductListCardRejectButton,
} from "../../card";
import * as S from "./ProductListItemDemandItemLayout.desktop.styled";

export interface ProductListItemDemandItemLayoutDesktopProps extends BaseCssProps {
  children: React.ReactNode;
}

const DemandItem = ({ className, children }: ProductListItemDemandItemLayoutDesktopProps) => {
  return <S.Li className={className}>{children}</S.Li>;
};

export interface ProductListItemDemandItemLayoutDesktopIconProps {
  icon: React.ReactNode;
}

const Icon = ({ icon }: ProductListItemDemandItemLayoutDesktopIconProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{icon}</>;
};

export interface ProductListItemDemandItemLayoutDesktopBankNameWithIndicatorProps {
  bankTitle: string;
  linkProps: React.ComponentProps<typeof AppLink>;
  from: NavigationState;
  fontColor: ElementColor;
  isLineThrough: boolean;
  isUpdated: boolean;
}

const BankWithIndicator = ({
  bankTitle,
  linkProps,
  from,
  fontColor,
  isLineThrough,
  isUpdated,
}: ProductListItemDemandItemLayoutDesktopBankNameWithIndicatorProps) => {
  return (
    <S.BankNameWithIndicator>
      <S.BankLink fontColor={fontColor} isCrossedOut={isLineThrough} {...linkProps} state={from}>
        {bankTitle}
      </S.BankLink>
      {isUpdated && <S.Indicator color={(theme) => theme.colors.primary} size={4} />}
    </S.BankNameWithIndicator>
  );
};

export interface ProductListItemDemandItemLayoutDesktopSumProps {
  fontColor: ElementColor;
  sum: string;
}

const Sum = ({ fontColor, sum }: ProductListItemDemandItemLayoutDesktopSumProps) => {
  return <S.Sum fontColor={fontColor}>{sum}</S.Sum>;
};

export interface ProductListItemDemandItemLayoutDesktopStatusContainerProps {
  statusTitle: string;
  from?: NavigationState;
  fontColor: ElementColor;
  linkProps: React.ComponentProps<typeof AppLink>;
  children?: React.ReactNode;
}

const StatusContainer = ({
  statusTitle,
  linkProps,
  fontColor,
  from,
  children,
}: ProductListItemDemandItemLayoutDesktopStatusContainerProps) => {
  return (
    <S.StatusContainer title={statusTitle}>
      <S.StatusLink fontColor={fontColor} state={from} {...linkProps}>
        {children}
      </S.StatusLink>
    </S.StatusContainer>
  );
};

export interface ProductListItemDemandItemLayoutDesktopDateLabelProps {
  date: string;
}

const DateLabel = ({ date }: ProductListItemDemandItemLayoutDesktopDateLabelProps) => {
  return (
    <S.DateLabel>
      {dateTimeHelper.format(new Date(date), specialFormatters.dayAndShortMonthOrTimeOnlyIfSameDay)}
    </S.DateLabel>
  );
};

export interface ProductListItemDemandItemLayoutDesktopRemoveButtonProps {
  canRemove: boolean;
  onRemoveClick: () => void;
}

const RemoveButton = ({ canRemove, onRemoveClick }: ProductListItemDemandItemLayoutDesktopRemoveButtonProps) => {
  return <S.RemoveButton>{canRemove && <ProductListCardRejectButton onClick={onRemoveClick} />}</S.RemoveButton>;
};

export interface ProductListItemDemandItemLayoutDesktopStatusLinkCounterProps {
  countActiveTasks: number;
}

const StatusLinkCounter = ({ countActiveTasks }: ProductListItemDemandItemLayoutDesktopStatusLinkCounterProps) => {
  return (
    <ProductListCardDemandListItemStatusLinkCounterDesktop>
      + {countActiveTasks - 1}
    </ProductListCardDemandListItemStatusLinkCounterDesktop>
  );
};

export interface ProductListItemDemandItemLayoutDesktopRejectTooltipIconProps {
  reason: string;
  comment: string;
}

const RejectTooltipIcon = ({ reason, comment }: ProductListItemDemandItemLayoutDesktopRejectTooltipIconProps) => {
  return <ProductListCardDemandsListRejectTooltipIconDesktop reason={reason} comment={comment} />;
};

DemandItem.Container = S.Container;
DemandItem.Icon = Icon;
DemandItem.Bank = BankWithIndicator;
DemandItem.Sum = Sum;
DemandItem.StatusContainer = StatusContainer;
DemandItem.DateLabel = DateLabel;
DemandItem.RemoveButton = RemoveButton;
DemandItem.StatusLinkCounter = StatusLinkCounter;
DemandItem.RejectTooltipIcon = RejectTooltipIcon;
DemandItem.Skeleton = S.Skeleton;

export const ProductListItemDemandItemLayoutDesktop = DemandItem;
