import { useCallback, useMemo } from "react";
import { createByGuaranteeBankGuaranteeCreation } from "../../../store/bankGuaranteeCreation.thunks";
import { useAppNavigation } from "../../../../../common/urls/internal/hooks";
import { useApiMutation } from "../../../../../common/hooks";
import { BankGuaranteeCreationSubmitResult } from "../../../types/creation-submit/BankGuaranteeCreationSubmitResult";
import { BankGuaranteeId } from "../../../../../common/types/demand";

export const useBankGuaranteeCreationSubmitCreateByGuarantee = (
  guaranteeId: BankGuaranteeId
): BankGuaranteeCreationSubmitResult => {
  const { error, mutate, status } = useApiMutation(createByGuaranteeBankGuaranteeCreation, {
    errorMessagePlaceholder:
      "Произошла непредвиденная ошибка. Попробуйте еще раз или свяжитесь с персональным менеджером",
  });
  const navigator = useAppNavigation();

  const onSubmit = useCallback(
    async (bankIds: string[]) => {
      const result = await mutate({ guaranteeId, bankIds });
      if (result?.body.guaranteeId) {
        navigator((x) => x.bankGuarantee.details.documents, { guaranteeId: result.body.guaranteeId });
      }
    },
    [guaranteeId, mutate, navigator]
  );

  return useMemo(() => ({ status, error, onSubmit }), [error, onSubmit, status]);
};
