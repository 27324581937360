import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../common/store";
import { selectNewsListIds } from "../../store/newsList.selectors";
import { NewsListItem } from "../NewsListItem";
import { NewsId } from "../../../common/types";
import * as S from "./NewsListItems.styled";
import { useCreateNavigationStateFrom } from "../../../../common/navigation/navigation-state";

export type NewsListItemsProps = BaseCssProps;

export const NewsListItems = ({ className }: NewsListItemsProps) => {
  const ids = useAppSelector(selectNewsListIds);
  const from = useCreateNavigationStateFrom("Новости");

  return (
    <S.Container className={className}>
      {ids.map((x) => (
        <NewsListItem id={x as NewsId} key={x} from={from} />
      ))}
    </S.Container>
  );
};
