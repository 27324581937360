import { ProductDetailsDocumentsDocumentState } from "../../../../common/product-details";
import { GetCreditWorkingAssetsDetailsMissingDocumentOutputDto } from "../../api/dto/output/GetCreditWorkingAssetsDetailsOutputDto";

const mapOne = (
  response: GetCreditWorkingAssetsDetailsMissingDocumentOutputDto
): ProductDetailsDocumentsDocumentState => {
  return { ...response.document, banks: response.banks };
};

const mapAll = (
  response: GetCreditWorkingAssetsDetailsMissingDocumentOutputDto[]
): ProductDetailsDocumentsDocumentState[] => {
  return response.map(mapOne);
};

export const creditWorkingAssetsDetailsDocumentsMapper = {
  mapAll,
  mapOne,
};
