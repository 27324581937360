const analyticsConfigsGoals = [
  "HIT_NEWS_LINK",
  "QUEUE_TASKS_NOTIFICATION_BUTTON_CLICK",
  "QUEUE_TASKS_NOTIFICATIONS_MARK_AS_READ_BUTTON_CLICK",
  "QUEUE_TASKS_NOTIFICATIONS_SHOW_ALL_BUTTON_CLICK",
  "GLOBAL_CALCULATOR_WIDGET_BUTTON_CLICK",
  "GLOBAL_CHAT_WIDGET_BUTTON_CLICK",
] as const;

export type AnalyticGoalType = (typeof analyticsConfigsGoals)[number];

export const analyticsConfigs = {
  /**
   * Яндекс Метрика
   */
  ym: {
    isEnabled: import.meta.env.VITE_IS_YANDEX_ANALYTICS_ENABLED === "true",
    id: 42507464,
    options: {
      defer: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      ecommerce: "dataLayer",
    },
    goals: analyticsConfigsGoals,
  },
};
