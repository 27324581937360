import { BaseCssProps } from "../../../common/ui-kit/types";
import { IssuingCompanyId } from "../../../common/types/companies";
import { FileLabel } from "../../../common/ui-kit/labels";
import { useDownloadFileApiMutation } from "../../../common/hooks";
import { getFilePublicUserPrivacyPolicies } from "../store/publicUserPrivacyPolicies.thunks";

export interface PublicUserPrivacyPoliciesFileLabelProps extends BaseCssProps {
  fileName: string;
  issuingCompanyId: IssuingCompanyId;
}

export const PublicUserPrivacyPoliciesFileLabel = ({
  fileName,
  issuingCompanyId,
  className,
}: PublicUserPrivacyPoliciesFileLabelProps) => {
  const { download, status } = useDownloadFileApiMutation(getFilePublicUserPrivacyPolicies, { issuingCompanyId });

  return <FileLabel fileName={fileName} download={download} status={status} className={className} />;
};
