import { useEffect } from "react";
import { useFactoringCreationDebtorsDocumentsState } from "../../hooks/useFactoringCreationDebtorsDocumentsState";
import { useFactoringCreationDebtorsListActions } from "../../hooks/useFactoringCreationDebtorsListActions";
import { FactoringCreationFormDebtor } from "./FactoringCreationFormDebtor";
import { isFailed, isLoading } from "../../../../common/types/state";
import { Text } from "../../../../common/ui-kit/typography";
import { ButtonLink } from "../../../../common/ui-kit/buttons/ButtonLink";
import { Button } from "../../../../common/ui-kit/form-controls/button";
import { FactoringCreationFormDebtorSkeleton } from "./FactoringCreationFormDebtor.skeleton";
import { CreationBlockCard } from "../../../../common/ui-kit/cards/creation-block-card";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";

export interface FactoringCreationDebtorsListProps {
  isDisabled?: boolean;
}

export const FactoringCreationDebtorsList = ({ isDisabled }: FactoringCreationDebtorsListProps) => {
  const { documents, status, onReload, error } = useFactoringCreationDebtorsDocumentsState();
  const { ids, canAdd, canRemove, onRemove, onAdd } = useFactoringCreationDebtorsListActions();
  const shouldDisplayButton = canAdd && !isDisabled && documents;

  useEffect(() => {
    if (documents && ids.length === 0) {
      onAdd(documents);
    }
  }, [documents, ids.length, onAdd]);

  return (
    <>
      {!documents && (
        <>
          {isLoading(status) && <FactoringCreationFormDebtorSkeleton />}
          {isFailed(status) && (
            <CreationBlockCard padding={{ xs: "md", md: "lg" }}>
              <StackPanel gap="md" direction="column" alignItems="center">
                <Text fontColor="primaryCaption" fontSize="fz2">
                  {error}
                </Text>
                <ButtonLink onClick={onReload}>Попробовать еще раз</ButtonLink>
              </StackPanel>
            </CreationBlockCard>
          )}
        </>
      )}
      {documents &&
        ids.map((x, index) => (
          <FactoringCreationFormDebtor
            onRemove={() => onRemove(x)}
            key={x}
            debtorId={x}
            index={index + 1}
            canRemove={canRemove}
            isDisabled={isDisabled}
            documents={documents}
          />
        ))}
      {shouldDisplayButton && (
        <Button variant="contained" color="light" type="button" onClick={() => onAdd(documents)}>
          Добавить дебитора
        </Button>
      )}
    </>
  );
};
