import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import isPropValid from "@emotion/is-prop-valid";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ResponsiveStyleValue, SpaceType } from "../../../types";

export interface StackPanelProps {
  gap: ResponsiveStyleValue<SpaceType>;
  direction: ResponsiveStyleValue<CSSObject["flexDirection"]>;
  alignItems?: ResponsiveStyleValue<CSSObject["alignItems"]>;
  justifyContent?: ResponsiveStyleValue<CSSObject["justifyContent"]>;
  position?: ResponsiveStyleValue<CSSObject["position"]>;
}

export const StackPanel = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop as string) && prop !== "direction",
})<StackPanelProps>(
  { display: "flex" },
  ({ theme, direction }) => makeResponsiveStyles(theme, direction, (x) => ({ flexDirection: x })),
  ({ theme, gap }) => makeResponsiveStyles(theme, gap, (x) => ({ gap: theme.spaces[x] })),
  ({ theme, alignItems }) => makeResponsiveStyles(theme, alignItems, (x) => ({ alignItems: x })),
  ({ theme, justifyContent }) => makeResponsiveStyles(theme, justifyContent, (x) => ({ justifyContent: x })),
  ({ theme, position }) => makeResponsiveStyles(theme, position, (x) => ({ position: x })),
);
