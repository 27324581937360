import styled from "@emotion/styled";

export const StyledContainerWithCustomScroll = styled.div`
  &::-webkit-scrollbar {
    padding: 0;
    border-radius: 4px;
    border: 4px solid transparent;
    background-clip: content-box;
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.secondaryLight};
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;
