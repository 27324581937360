import { ChangeEvent } from "react";
import * as React from "react";
import "./Select.scss";

export interface ISelectProps {
  defaultValue?: ISelectValue;
  values: Array<ISelectValue>;
  value?: string;
  errorMessage?: string;
  name: string;
  onChange: (value: string) => void;
}

export interface ISelectValue {
  key: string;
  value: string;
}

const Select: React.FC<ISelectProps> = (props) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const target = e.target;
    const value = target.value.trim();

    props.onChange(value);
  };

  return (
    <div className="form-group">
      <select
        name={props.name}
        className="form-control select"
        value={props.value ?? props.defaultValue?.key}
        onChange={(e) => handleChange(e)}
      >
        {props.defaultValue && (
          <option value={props.defaultValue.key} disabled>
            {props.defaultValue.value}
          </option>
        )}
        {props.values.map((item, index) => {
          return (
            <option key={index} value={item.key}>
              {item.value}
            </option>
          );
        })}
      </select>
      {props.errorMessage && <span className="text-danger form-group__error">{props.errorMessage}</span>}
    </div>
  );
};

export default Select;
