import { useMatch } from "react-router-dom";
import { BankGuaranteeCreationCreateByGuaranteePageType } from "../../../types/creation/BankGuaranteeCreationCreateByGuaranteePageType";
import { selectAppUrl } from "../../../../../common/urls/internal/utils";
import { useAppUrlParams } from "../../../../../common/urls/internal/hooks";

export const useBankGuaranteeCreationCreateByGuaranteePathMatch = (): {
  guaranteeId: string | undefined;
  pageType: BankGuaranteeCreationCreateByGuaranteePageType;
} => {
  const { guaranteeId } = useAppUrlParams((x) => x.bankGuarantee.creation.createByGuaranteeDetails);
  const match = useMatch(selectAppUrl((x) => x.bankGuarantee.creation.createByGuaranteeDetails).url);

  return { guaranteeId, pageType: match ? "details" : "scoring" };
};
