import { RouteObject } from "react-router-dom";
import { createElement } from "react";
import { AppLayoutRoute, AppRoute } from "../types/AppRoute";
import { AppRouteElement } from "../components/AppRouteElement";
import { AppRoutePermissionElement } from "../components/AppRoutePermissionElement";
import { AnyAppUrl } from "../../urls/internal/types";
import { AppLayoutElement } from "../components/AppLayoutElement";

export const mapAppRoutes = (routes: (AppLayoutRoute | AppRoute)[]): RouteObject[] => {
  return routes.map((x): RouteObject => {
    const layout = x as AppLayoutRoute;
    if (layout.isLayout) {
      return {
        element: <AppLayoutElement route={layout} />,
        children: layout.nested ? mapAppRoutes(layout.nested) : undefined,
      };
    }

    const appRoute = x as AppRoute;

    const children = appRoute.permissionElement
      ? createElement(AppRoutePermissionElement, {
          baseElement: appRoute.element,
          permissionElement: appRoute.permissionElement,
        })
      : appRoute.element;

    const element = <AppRouteElement url={appRoute.url}>{children}</AppRouteElement>;

    const isIndexRoute = (appRoute.url as AnyAppUrl).part === undefined;
    if (isIndexRoute) {
      return {
        element,
        path: appRoute.url.url,
        index: true,
      };
    }

    return {
      element,
      path: appRoute.url.url,
      children: appRoute.nested ? mapAppRoutes(appRoute.nested) : undefined,
      index: false,
    };
  });
};
