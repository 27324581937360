import styled from "@emotion/styled";
import { BaseButton } from "../../../../ui-kit/form-controls/button";

export const Button = styled(BaseButton.withComponent("button"))`
  cursor: pointer;
  border-radius: 50%;

  & > svg {
    width: 24px;
    height: 24px;

    & > path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }

  &:hover {
    & > svg > path {
      stroke: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.colors.primaryDark};
  }
`;
