import styled from "@emotion/styled";

export interface ButtonProps {
  active: boolean;
  open: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  & > svg {
    flex-grow: 0;
    flex-shrink: 0;
    transform: rotate(${({ open }) => (open ? "90deg" : "0deg")});
    transition: transform 0.25s ease-in;
  }
`;
