import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useProductFilterSearchInput } from "../../../../../../common/filters/use-cases";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { FormSearchInput } from "../../../../../../common/ui-kit/form-controls/search-input";
import { CreditExecutionListHeaderFiltersOpenButtonMobile } from "./CreditExecutionListHeaderFiltersOpenButton.mobile";
import { useCreditExecutionListFilterContext } from "../../../../hooks/filters/useCreditExecutionListFilterContext";

export type CreditExecutionListHeaderFiltersMobileProps = BaseCssProps;

export const CreditExecutionListHeaderFiltersMobile = ({ className }: CreditExecutionListHeaderFiltersMobileProps) => {
  const filteringObject = useCreditExecutionListFilterContext();
  const { onChange, value } = useProductFilterSearchInput(filteringObject);

  return (
    <Box pt="md">
      <FormSearchInput
        placeholder="Номер заявки, клиент, ИНН"
        className={className}
        afterInput={<CreditExecutionListHeaderFiltersOpenButtonMobile />}
        value={value}
        onChange={onChange}
        spellCheck={false}
        autoComplete="off"
        autoCorrect="off"
      />
    </Box>
  );
};
