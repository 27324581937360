import { forwardRef } from "react";
import { ChatDialogMessageId } from "../../types/ChatDialogMessageId";
import { useAppSelector } from "../../../../common/store";
import { selectChatDialogMessageById } from "../../store/chatDialog.selectors";
import { ChatDialogLeftSideMessage } from "./ChatDialogLeftSideMessage";
import { ChatDialogRightSideMessage } from "./ChatDialogRightSideMessage";

export interface ChatDialogMessageProps {
  messageId: ChatDialogMessageId;
  className?: string;
}

export const ChatDialogMessage = forwardRef<HTMLDivElement, ChatDialogMessageProps>(({ messageId, className }, ref) => {
  const message = useAppSelector((state) => selectChatDialogMessageById(state, messageId));

  if (!message) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  return message.isFromManager ? (
    <ChatDialogLeftSideMessage
      message={message.text}
      userName={message.senderName}
      date={message.dateAdded}
      className={className}
      ref={ref}
    />
  ) : (
    <ChatDialogRightSideMessage ref={ref} message={message.text} date={message.dateAdded} className={className} />
  );
});
