import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { ProductRejectionState } from "./productRejection.types";
import { getRejectionReasonsProductRejection, PRODUCT_REJECTION_SLICE_NAME } from "./productRejection.thunks";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../types/state";
import { getEntitiesCodeWithTitle } from "../../types/objects";
import { UNSELECTED_ENUM_LITERAL } from "../../types/utils";

const initialState: ProductRejectionState = {
  BankGuarantee: {
    status: LoadingStatusEnum.Idle,
    data: undefined,
  },
  CreditExecution: {
    status: LoadingStatusEnum.Idle,
    data: undefined,
  },
  CreditWorkingAssets: {
    status: LoadingStatusEnum.Idle,
    data: undefined,
  },
  ReportAgent: {
    status: LoadingStatusEnum.Idle,
    data: undefined,
  },
};

const slice = createSlice({
  initialState,
  name: PRODUCT_REJECTION_SLICE_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRejectionReasonsProductRejection.fulfilled, (state, { meta, payload }) => {
        state[meta.arg.productId].data = getEntitiesCodeWithTitle([
          { code: UNSELECTED_ENUM_LITERAL, title: "Не указана" },
          ...payload.body.rejectionReasons,
        ]);
      })
      .addMatcher(isAsyncThunkAction(getRejectionReasonsProductRejection), (state, action) => {
        state[action.meta.arg.productId].status = getLoadingStatusFromAction(action);
      });
  },
});

export const productRejectionReducer = slice.reducer;
