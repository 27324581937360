import { memo } from "react";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { BankGuaranteeListHeader } from "../components/header/BankGuaranteeListHeader";
import { BankGuaranteeList } from "../components/list/BankGuaranteeList";
import { usePageTitle } from "../../../common/navigation/page-title";
import { useGetQueryParams } from "../../../common/filters";
import { BankGuaranteeListFilterContext } from "../store/filter-context/BankGuaranteeListFilterContext";
import { bankGuaranteeListFiltersValidationSchema } from "../validation/bankGuaranteeListFiltersValidationSchema";
import { usePaginatedQueryStateMutator } from "../../../common/filters/use-cases";
import { BankGuaranteeListFilterApiContext } from "../store/filter-context/BankGuaranteeListFilterApiContext";
import { selectBankGuaranteeListCounters } from "../store/counters/bankGuaranteeListCounters.selectors";
import { useAppSelector } from "../../../common/store";
import { useFirstRenderThunkDispatch } from "../../../common/hooks";
import { getCountersBankGuaranteeList } from "../store/bankGuaranteeList.thunks";

export const BankGuaranteeListPage = memo(() => {
  usePageTitle("Мои заявки");

  const [filteringObject, forceUpdate] = useGetQueryParams(bankGuaranteeListFiltersValidationSchema);
  const mutator = usePaginatedQueryStateMutator();
  const { status } = useAppSelector(selectBankGuaranteeListCounters);

  useFirstRenderThunkDispatch(status, getCountersBankGuaranteeList);

  return (
    <AppLayoutResponsiveListPage>
      <BankGuaranteeListFilterContext.Provider value={filteringObject}>
        <BankGuaranteeListFilterApiContext.Provider value={mutator}>
          <BankGuaranteeListHeader />
          <BankGuaranteeList onErrorReloadButtonClick={forceUpdate} />
        </BankGuaranteeListFilterApiContext.Provider>
      </BankGuaranteeListFilterContext.Provider>
    </AppLayoutResponsiveListPage>
  );
});
