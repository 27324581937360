import { PropsWithChildren } from "react";
import { useBankGuaranteeDetailsDemandInitialFetch } from "../../hooks/demand/useBankGuaranteeDetailsDemandInitialFetch";
import { BankGuaranteeDetailsFilterApiContext } from "../../store/filters/BankGuaranteeDetailsFilterApiContext";
import { BankGuaranteeDetailsFilterContext } from "../../store/filters/BankGuaranteeDetailsFilterContext";
import { useBankGuaranteeDetailsFilterMutator } from "../../hooks/filters/context/useBankGuaranteeDetailsFilterMutator";
import { useBankGuaranteeDetailsWithRequiredDemandIdLayoutContext } from "../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { useGetQueryParams } from "../../../../common/filters";
import { bankGuaranteeDetailsFilterValidationSchema } from "../../validation/bankGuaranteeDetailsFilterValidationSchema";

export const BankGuaranteeDetailsDemandPageLayout = ({ children }: PropsWithChildren) => {
  const { guaranteeId, demandId } = useBankGuaranteeDetailsWithRequiredDemandIdLayoutContext();
  useBankGuaranteeDetailsDemandInitialFetch();

  const [filteringObject] = useGetQueryParams(bankGuaranteeDetailsFilterValidationSchema);
  const mutator = useBankGuaranteeDetailsFilterMutator(guaranteeId, demandId);

  return (
    <BankGuaranteeDetailsFilterApiContext.Provider value={mutator}>
      <BankGuaranteeDetailsFilterContext.Provider value={filteringObject}>
        {children}
      </BankGuaranteeDetailsFilterContext.Provider>
    </BankGuaranteeDetailsFilterApiContext.Provider>
  );
};
