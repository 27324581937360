import { forwardRef, memo } from "react";
import Skeleton from "react-loading-skeleton";
import { CreationBlockCard } from "../../../../../ui-kit/cards/creation-block-card";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";
import { Typography } from "../../../../../ui-kit/typography";
import { ProductCreationScoringResultSummaryTextDesktop } from "./ProductCreationScoringResultSummaryText.desktop";
import { ProductCreationScoringResultSummaryCommonProps } from "../ProductCreationScoringResultSummaryCommonProps";
import { declensionHelper } from "../../../../../helpers/entity";

export type ProductCreationScoringResultSummaryDesktopProps = ProductCreationScoringResultSummaryCommonProps;

export const ProductCreationScoringResultSummaryDesktop = memo(
  forwardRef<HTMLDivElement, ProductCreationScoringResultSummaryDesktopProps>(
    ({ isSkeleton, successCount, duplicateCount, rejectCount, className }, ref) => {
      const getSkeletonOrValue = (count?: number) => {
        if (isSkeleton) {
          return <Skeleton inline width={40} />;
        }

        return declensionHelper.commonDeclensions.bank.nominative(count ?? 0, true);
      };

      return (
        <CreationBlockCard padding="lg" className={className} ref={ref}>
          <StackPanel gap="md" direction="column">
            <Typography
              lineHeight="defaultLineHeight"
              fontColor="typography.primary"
              as="h3"
              fontWeight={700}
              fontSize="fz3"
            >
              Результаты скоринга
            </Typography>
            <ProductCreationScoringResultSummaryTextDesktop
              resultType="success"
              count={getSkeletonOrValue(successCount)}
            />
            <ProductCreationScoringResultSummaryTextDesktop
              resultType="reject"
              count={getSkeletonOrValue(rejectCount)}
            />
            {duplicateCount !== undefined && duplicateCount > 0 && !isSkeleton && (
              <ProductCreationScoringResultSummaryTextDesktop
                resultType="duplicate"
                count={getSkeletonOrValue(duplicateCount)}
              />
            )}
          </StackPanel>
        </CreationBlockCard>
      );
    },
  ),
);
