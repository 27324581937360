type JoinedPath<
  T1 extends string,
  T2 extends string,
  T3 extends string | undefined = undefined,
  T4 extends string | undefined = undefined
> = `${T1}/${T2}${T3 extends string ? `/${T3}` : ""}${T4 extends string ? `/${T4}` : ""}`;

/**
 * Объединяет части url адреса, конкатенируя их через '/' и возвращая литеральный тип
 * @param part1 Часть url адреса
 * @param part2 Часть url адреса
 * @param part3 Часть url адреса
 * @param part4 Часть url адреса
 */
export const joinUrlParts = <
  T1 extends string,
  T2 extends string,
  T3 extends string | undefined = undefined,
  T4 extends string | undefined = undefined
>(
  part1: T1,
  part2: T2,
  part3?: T3,
  part4?: T4
): JoinedPath<T1, T2, T3, T4> =>
  [part1, part2, part3, part4].filter((x) => !!x).join("/") as JoinedPath<T1, T2, T3, T4>;
