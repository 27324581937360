import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsActiveTaskById } from "../../../store/active-tasks/bankGuaranteeDetailsActiveTasks.selectors";
import { useBankGuaranteeDetailsFilterScrollIntoContainer } from "../../../hooks/filters/useBankGuaranteeDetailsFilterScrollIntoContainer";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { QueueTaskId } from "../../../../../common/types/demand";
import { useBankGuaranteeDetailsTaskComponent } from "../../../hooks/active-tasks/useBankGuaranteeDetailsTaskComponent";

export interface BankGuaranteeActiveTaskProps extends BaseCssProps {
  queueTaskId: QueueTaskId;
}

export const BankGuaranteeActiveTask = ({ queueTaskId, className }: BankGuaranteeActiveTaskProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const activeTask = useAppSelector((state) => selectBankGuaranteeDetailsActiveTaskById(state, queueTaskId))!;
  const Component = useBankGuaranteeDetailsTaskComponent(activeTask.taskType, true);
  const { containerRef, isFocused } = useBankGuaranteeDetailsFilterScrollIntoContainer("task", queueTaskId);

  return <Component className={className} task={activeTask} ref={containerRef} isFocused={isFocused} />;
};
