import { GetCheckClientCommonInputDto } from "./input/GetCheckClientCommonInputDto";
import { clientV2 } from "../../../../common/api";
import { GetCheckClientCommonOutputDto } from "./output/GetCheckClientCommonOutputDto";

export const clientCheckApi = {
  getFullReport: (model: GetCheckClientCommonInputDto) => {
    return clientV2.get<GetCheckClientCommonInputDto, GetCheckClientCommonOutputDto>(
      `/ClientCheck/GetFullReportHtml`,
      model
    );
  },
  getContracts: (model: GetCheckClientCommonInputDto) => {
    return clientV2.get<GetCheckClientCommonInputDto, GetCheckClientCommonOutputDto>(
      `/ClientCheck/GetGovContracts`,
      model
    );
  },
  getBriefReport: (model: GetCheckClientCommonInputDto) => {
    return clientV2.get<GetCheckClientCommonInputDto, GetCheckClientCommonOutputDto>(
      `/ClientCheck/GetBriefReportHtml`,
      model
    );
  },
};
