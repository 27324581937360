import { FederalLawTypeWithCommerce } from "../../../../../common/types/demand";
import { federalLawRules } from "../../../../common/validation";

const BASE_PREFIX = "Ссылка на закупку";

export const useBankGuaranteeCreationPurchaseLinkFieldLabel = (
  federalLaw: FederalLawTypeWithCommerce,
  isSingleSupplier: boolean
) => {
  if (federalLawRules.isCommerce(federalLaw)) {
    return `${BASE_PREFIX} (необязательно)`;
  }

  if (isSingleSupplier) {
    return `${BASE_PREFIX} (план-график/контракт)`;
  }

  return BASE_PREFIX;
};
