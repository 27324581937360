import styled from "@emotion/styled";
import { AppLink } from "../../../../../../../common/urls/internal/components";
import { BankGuaranteeListItemBankLabel } from "../../bank-label/BankGuaranteeListItemBankLabel";

export const Li = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: ${({ theme }) => theme.spaces.sm};
  align-items: center;
  justify-content: space-between;
`;

export const BankAndIcon = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 260px;
`;

export const BankLink = styled(BankGuaranteeListItemBankLabel.withComponent(AppLink))`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const DateLabel = styled.span`
  color: ${({ theme }) => theme.palette.typography.medium};
  width: 7ch;
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-feature-settings:
    "tnum" on,
    "lnum" on;
`;

export const RemoveButton = styled.div`
  width: 27px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StatusContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: block;
  max-width: 220px;
`;
