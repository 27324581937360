import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../../common/types/utils";

const titles = {
  New: "Непросмотренные",
  All: "Все задачи",
};

export const queueTasksListFilterTab = makeEnumLikeLiteral(["All", "New"] as const, titles);

export type QueueTasksListFilterTabType = EnumLikeLiteralType<typeof queueTasksListFilterTab>;
