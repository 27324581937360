import { cssModuleMultipleClasses } from '../../../essentials/helpers'
import styles from './LoaderSmall.module.css'

interface ILoaderSmallProps {
    className?: string
}

const LoaderSmall: React.FC<ILoaderSmallProps> = ({ className = null }) => {
    const loaderSmallClass = 
        className
        ? cssModuleMultipleClasses([styles.ldsDualRing, className])
        : styles.ldsDualRing

    return (
        <div className={loaderSmallClass}></div>
    )
}

export default LoaderSmall
