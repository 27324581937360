import { ApiResponse, EmptyOutputDto } from "../../api/types";
import { RefreshToken } from "../types/jwt/RefreshToken";
import { ApiAuthenticationOutputDto } from "./dto/output/ApiAuthenticationOutputDto";
import { ApiRefreshTokenAuthenticationInputDto } from "./dto/input/ApiRefreshTokenAuthenticationInputDto";
import { createPublicApiClient } from "../../api/utils";
import { FetchClientHeaders } from "../../services/fetchClient/types/FetchClientHeaders";
import { ApiAuthenticateAuthenticationInputDto } from "./dto/input/ApiAuthenticateAuthenticationInputDto";
import { ApiRefreshByOldTokenAuthenticationInputDto } from "./dto/input/ApiRefreshByOldTokenAuthenticationInputDto";

const CONTROLLER_NAME = "Auth";
// Создаем новый клиент, чтобы не получить рекурсивные импорты
const client = createPublicApiClient("v4", { withCredentials: true });

export const authenticationApi = {
  refreshToken: (model: ApiRefreshTokenAuthenticationInputDto): Promise<ApiResponse<ApiAuthenticationOutputDto>> => {
    return client.post<ApiRefreshTokenAuthenticationInputDto, ApiAuthenticationOutputDto>(
      `${CONTROLLER_NAME}/get-access`,
      model,
    );
  },
  authenticate: (login: string, password: string): Promise<ApiResponse<ApiAuthenticationOutputDto>> => {
    return client.post<ApiAuthenticateAuthenticationInputDto, ApiAuthenticationOutputDto>(`${CONTROLLER_NAME}/login`, {
      login,
      password,
    });
  },
  refreshTokenByOldToken: (refreshToken: RefreshToken): Promise<ApiResponse<ApiAuthenticationOutputDto>> => {
    return client.post<ApiRefreshByOldTokenAuthenticationInputDto, ApiAuthenticationOutputDto>(
      `${CONTROLLER_NAME}/get-access-by-old-refresh-token`,
      { refreshToken },
    );
  },
  logout: (headers: FetchClientHeaders | undefined): Promise<ApiResponse<EmptyOutputDto>> => {
    return client.put<ApiRefreshTokenAuthenticationInputDto, EmptyOutputDto>(`${CONTROLLER_NAME}/logout`, undefined, {
      headers,
    });
  },
};
