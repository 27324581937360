import { useState } from "react";
import { ClientFormSelectOpt } from "../../select/ClientFormSelect";
import { ClientFormSelectOptionWrapper } from "../select-wrapper/ClientFormSelectOptionWrapper";

interface ClientFormSingleSelectProps {
  singleChooseOptions: ClientFormSelectOpt[];
  handleOptionSelection: (newValue: string, prevValue?: string) => void;
  multiSelected: Set<string>;
  inputValue: string | null;
}
export const ClientFormSingleSelect = ({
  singleChooseOptions,
  handleOptionSelection,
  multiSelected,
  inputValue,
}: ClientFormSingleSelectProps) => {
  const [prevValue, setPrevValue] = useState(() => {
    return singleChooseOptions.find((x) => multiSelected.has(x.value))?.value ?? "";
  });

  const onSelectedChange = (option: string) => {
    handleOptionSelection(option, prevValue);
    setPrevValue(option);
  };

  return (
    <ClientFormSelectOptionWrapper
      title={"Возможен выбор одного варианта из списка ниже"}
      options={singleChooseOptions}
      multiSelected={multiSelected}
      onChange={onSelectedChange}
      inputValue={inputValue}
    />
  );
};
