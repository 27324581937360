import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { ProductListCardDemandsListContainerDesktop } from "../../../../../../common/ui/product/list/card";
import { CreditWorkingAssetsListItemDemandsListDesktopSkeleton } from "./list/CreditWorkingAssetsListItemDemandsList.desktop.skeleton";
import { CreditWorkingAssetsListItemStageCountersSkeleton } from "../../counters/CreditWorkingAssetsListItemStageCounters.skeleton";

export type CreditWorkingAssetsListItemDemandsDesktopSkeletonProps = BaseCssProps;

export const CreditWorkingAssetsListItemDemandsDesktopSkeleton = ({
  className,
}: CreditWorkingAssetsListItemDemandsDesktopSkeletonProps) => {
  return (
    <ProductListCardDemandsListContainerDesktop
      banksCountTitle={<Skeleton inline width={60} height={28} />}
      counters={<CreditWorkingAssetsListItemStageCountersSkeleton />}
      activeTasksCountBadge={<Skeleton />}
      actionsButton={<Skeleton inline width={28} height={28} />}
      className={className}
    >
      <CreditWorkingAssetsListItemDemandsListDesktopSkeleton />
    </ProductListCardDemandsListContainerDesktop>
  );
};
