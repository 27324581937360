import { publicClientV4 } from "../../../common/api";
import { GetOfferPublicUserCommonInputDto } from "./dto/input/GetOfferPublicUserCommonInputDto";
import { ApiBlobResponse, ApiObject } from "../../../common/api/types";

export const publicUserCommonApi = {
  getOffer: ({ issuingCompanyId }: GetOfferPublicUserCommonInputDto) =>
    publicClientV4.get<GetOfferPublicUserCommonInputDto, ApiBlobResponse>(
      `registrations/offers/${issuingCompanyId}`,
      undefined,
      { responseType: "blob" }
    ),
} satisfies ApiObject;
