import spaces from "@sideg/ui-kit/core/theme/__generated__/spaces.gen";
import {
  getThemeSpacingValueWithMultiplier,
  isThemeSpacingValueWithMultiplier,
  ThemeExtendedSpacingType,
  ThemeGeneratedSpacesValues,
  ThemeSpaceValue,
  ThemeSpacingType,
  ThemeSpacingUtils,
  ThemeSpacingValues,
} from "@sideg/ui-kit/core/theme/types/spacing/ThemeSpacingType";
import { makeRemValues } from "@sideg/ui-kit/core/theme/utils/makeRemValues";

export const generatedSpaces = spaces satisfies ThemeGeneratedSpacesValues;

export type ThemeSpaces = ThemeGeneratedSpacesValues;
export type ThemeSpacing = ThemeSpacingValues & ThemeSpacingUtils;

export const makeThemeSpacing = (themeSpaces: ThemeSpaces): ThemeSpacing => {
  const spacesWithNone = { ...themeSpaces, none: 0 };
  const remValues: ThemeSpacingValues = makeRemValues(spacesWithNone);

  function withMultiple(value: ThemeExtendedSpacingType): ThemeSpaceValue;
  function withMultiple(value: ThemeSpacingType, multiplier: number): ThemeSpaceValue;
  function withMultiple(value: ThemeExtendedSpacingType, multiplier?: number) {
    const getValue = (spacing: ThemeSpacingType, ratio: number) => `${spacesWithNone[spacing] * ratio}rem`;

    if (isThemeSpacingValueWithMultiplier(value)) {
      return getValue(...getThemeSpacingValueWithMultiplier(value));
    }

    return getValue(value, multiplier ?? 1);
  }

  return {
    ...remValues,
    withMultiple,
    makeCssSpaces: (...args: ThemeSpacingType[]) => {
      return args.map((spacingType) => `${spacesWithNone[spacingType]}rem`).join(" ");
    },
  };
};
