import { ErrorCode, FileRejection } from "react-dropzone";
import { useMemo } from "react";
import { capitalize, entityHelper } from "@sideg/helpers";

const isManyFiles = (count: number) => count > 1;

const getDropzoneError = (fileErrorCode: ErrorCode | string, count: number, maxFileSizeInMegabytes: number): string => {
  switch (fileErrorCode) {
    case ErrorCode.TooManyFiles:
      return "можно прикрепить только один файл";
    case ErrorCode.FileTooSmall:
      return isManyFiles(count) ? "файлы не должны быть пустыми" : `файл не должен быть пустым`;
    case ErrorCode.FileInvalidType:
      return isManyFiles(count) ? "формат файлов не поддерживается" : "формат файла не поддерживается";
    case ErrorCode.FileTooLarge:
      return `размер ${entityHelper.getWordDeclension(
        count,
        "файл",
        ["a", "ов", "ов"],
        false,
      )} не должен превышать ${entityHelper.getWordDeclension(maxFileSizeInMegabytes, "мегабайт", ["а", "", ""])}`;
    default:
      return "";
  }
};

export const useDropzoneRejectionError = (
  fileRejections: FileRejection[],
  maxFileSizeInMegabytes: number,
): string | undefined => {
  return useMemo(() => {
    if (fileRejections.length === 0) {
      return undefined;
    }

    const errors = fileRejections.flatMap((x) => x.errors.map((y) => y.code));
    if (errors.length === 1) {
      return capitalize(getDropzoneError(errors[0], 1, maxFileSizeInMegabytes));
    }

    const distinctErrors = errors.filter((x, index) => errors.indexOf(x) === index);
    if (distinctErrors.length === 1) {
      return capitalize(getDropzoneError(distinctErrors[0], errors.length, maxFileSizeInMegabytes));
    }

    return `${entityHelper.getWordDeclension(errors.length, "файл", [
      "",
      "а",
      "ов",
    ])} не прикреплены: ${getDropzoneError(distinctErrors[0], 1, maxFileSizeInMegabytes)}`;
  }, [fileRejections, maxFileSizeInMegabytes]);
};
