import { combineSliceNames } from "../../../common/store/utils";
import { BANK_GUARANTEE_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { bankGuaranteeCreationApi } from "../api/bankGuaranteeCreationApi";

export const BANK_GUARANTEE_CREATION_SLICE_NAME = combineSliceNames(BANK_GUARANTEE_SLICE_NAME, "creation");

export const {
  createDraft: createDraftBankGuaranteeCreation,
  scoring: scoringBankGuaranteeCreation,
  scoringByGuaranteeId: scoringByGuaranteeIdBankGuaranteeCreation,
  create: createBankGuaranteeCreation,
  createByGuarantee: createByGuaranteeBankGuaranteeCreation,
} = createApiThunks(BANK_GUARANTEE_CREATION_SLICE_NAME, bankGuaranteeCreationApi);
