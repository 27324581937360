import { createSelector } from "@reduxjs/toolkit";
import { ProductDetailsDocumentsState } from "../../../../common/product-details";
import { RootState } from "../../../../common/store";
import { creditWorkingAssetsDetailsDocumentsAdapter } from "./creditWorkingAssetsDetailsDocuments.adapter";

export const selectCreditWorkingAssetsDetailsDocuments = (state: RootState): ProductDetailsDocumentsState =>
  state.domains.creditWorkingAssets.details.documents;

const { selectAll, selectById } = creditWorkingAssetsDetailsDocumentsAdapter.getSelectors(
  selectCreditWorkingAssetsDetailsDocuments,
);

export const selectCreditWorkingAssetsDetailsDocumentById = selectById;

export const selectCreditWorkingAssetsDetailsDocumentsIds = createSelector([selectAll], (items) => {
  return items.filter((x) => x.isUploaded !== true).map((x) => x.id);
});
