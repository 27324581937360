import { ReactNode } from "react";
import { FullPageContainerMainContentWrapper } from "../../../../../common/ui/containers/full-page";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import * as S from "./LegalServicesCreationFormContainer.styled";

export interface LegalServicesCreationFormContainerProps extends BaseCssProps {
  brief: ReactNode;
  children: ReactNode;
}

export const LegalServicesCreationFormContainer = ({
  className,
  children,
  brief,
}: LegalServicesCreationFormContainerProps) => {
  return (
    <FullPageContainerMainContentWrapper className={className}>
      <S.Container>
        {brief}
        {children}
      </S.Container>
    </FullPageContainerMainContentWrapper>
  );
};
