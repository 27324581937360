import { useMemo } from "react";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { Icon } from "../../../common/ui-kit/icons";
import { BankGuaranteeDemandStageType } from "../types";
import { getBankGuaranteeDemandStageColor, getBankGuaranteeDemandStageIcon } from "../utils";

export interface BankGuaranteeDemandStageIconProps extends BaseCssProps {
  stage: BankGuaranteeDemandStageType;
}

export const BankGuaranteeDemandStageIcon = ({ stage, className }: BankGuaranteeDemandStageIconProps) => {
  const icon = useMemo(() => getBankGuaranteeDemandStageIcon(stage), [stage]);
  const color = getBankGuaranteeDemandStageColor(stage);

  return <Icon as={icon} height={14} flexShrink={0} stroke={color} className={className} />;
};
