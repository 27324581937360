import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { Color } from "../../../../common/ui-kit/theme";
import { LabelRewardsType } from "../../types/LabelRewardsType";

const getRewardsLabelColors = (type: LabelRewardsType, theme: Theme): { color: Color; backgroundColor: Color } => {
  switch (type) {
    case "date":
      return {
        color: theme.colors.dark,
        backgroundColor: theme.palette.control.secondaryLight,
      };
    case "term":
    default:
      return {
        color: theme.palette.typography.primary,
        backgroundColor: theme.palette.control.primaryLight,
      };
  }
};

export const Container = styled.span<{ type: LabelRewardsType }>`
  display: flex;
  align-items: center;
  background-color: ${({ type, theme }) => getRewardsLabelColors(type, theme).backgroundColor};
  padding: ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.sm};
  white-space: nowrap;
  overflow: hidden;
  border-radius: 4px;
`;

export const Text = styled.span<{ type: LabelRewardsType }>`
  font-size: ${({ theme }) => theme.font.fz1};
  font-weight: 500;
  color: ${({ type, theme }) => getRewardsLabelColors(type, theme).color};
  line-height: 16px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
