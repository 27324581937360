import { AppMenuGroup } from "./types/AppMenuGroup";
import { AppMenuItem } from "./types/AppMenuItem";
import { selectAppUrl } from "../../urls/internal/utils";
import { getQueueTasksCountersSelector } from "../../../queue-tasks/counters";

export const appMenu: (AppMenuGroup | AppMenuItem)[] = [
  {
    title: "Активные задачи",
    url: selectAppUrl((x) => x.queueTasks.list.root),
    counterSelector: getQueueTasksCountersSelector(({ data }) => data?.countQueueTasksAll),
  },
  {
    title: "Банковские гарантии",
    items: [
      {
        url: selectAppUrl((x) => x.bankGuarantee.list.root),
        title: "Мои заявки",
        counterSelector: getQueueTasksCountersSelector(({ data }) => data?.countGuarantees),
        matches: [
          selectAppUrl((x) => x.bankGuarantee.list.root).url,
          selectAppUrl((x) => x.bankGuarantee.details.details).url,
          selectAppUrl((x) => x.bankGuarantee.details.demand).url,
        ],
      },
      {
        url: selectAppUrl((x) => x.bankGuarantee.conditions.root),
        title: "Условия банков",
      },
      {
        url: selectAppUrl((x) => x.bankGuarantee.documents.root),
        title: "Необходимые документы",
      },
      {
        url: selectAppUrl((x) => x.bankGuarantee.rewards.root),
        title: "Вознаграждения",
      },
      {
        url: selectAppUrl((x) => x.bankGuarantee.calculator.root),
        title: "Калькулятор",
      },
      {
        url: selectAppUrl((x) => x.bankGuarantee.calculatorUda.root),
        title: "Калькулятор УДА",
      },
    ],
  },
  {
    title: "Кредит на исп. контракта",
    items: [
      {
        url: selectAppUrl((x) => x.creditExecution.list.root),
        title: "Мои заявки",
        counterSelector: getQueueTasksCountersSelector(({ data }) => data?.countCreditExecutions),
      },
      {
        url: selectAppUrl((x) => x.creditExecution.conditions.root),
        title: "Условия банков",
      },
    ],
  },
  {
    url: selectAppUrl((x) => x.creditWorkingAssets.list.root),
    title: "Кредит на ПОС",
    counterSelector: getQueueTasksCountersSelector(({ data }) => data?.countCreditWorkingAssets),
  },
  {
    url: selectAppUrl((x) => x.legalServices.creation.list),
    title: "Юридические услуги",
    matches: [
      selectAppUrl((x) => x.legalServices.creation.list).url,
      selectAppUrl((x) => x.legalServices.creation.product).url,
    ],
  },
  {
    url: selectAppUrl((x) => x.factoring.creation.create),
    title: "Факторинг",
  },
  {
    url: selectAppUrl((x) => x.settlementAndCash.creation.create),
    title: "Обслуживание (РКО)",
  },
  {
    url: selectAppUrl((x) => x.client.check.root),
    title: "Проверить клиента",
  },
  {
    title: "Отчеты",
    items: [
      {
        url: selectAppUrl((x) => x.reports.agent.list.root),
        title: "Акт сверки",
        counterSelector: getQueueTasksCountersSelector(({ data }) => data?.countAgencyReports),
        matches: [
          selectAppUrl((x) => x.reports.agent.list.root).url,
          selectAppUrl((x) => x.reports.agent.details.root).url,
        ],
      },
      {
        url: selectAppUrl((x) => x.reports.demand.root),
        title: "Отчет по заявкам",
      },
    ],
  },
  {
    url: selectAppUrl((x) => x.news.list.root),
    title: "Новости",
    matches: [selectAppUrl((x) => x.news.list.root).url, selectAppUrl((x) => x.news.details.item).url],
  },
];
