import { BaseFile } from "../../../common/types/files";
import { DocumentUploadRequestedDocument } from "../types/DocumentUploadRequestedDocument";

export const getDocumentUploadRequestedDocumentOldFiles = <TFile extends BaseFile>(
  document: DocumentUploadRequestedDocument<TFile>
): TFile[] | undefined => {
  if (document.isMultiFile) {
    return document.oldFiles;
  }

  return document.oldFile === undefined ? undefined : [document.oldFile];
};
