import { useEffect, useRef, useMemo, useCallback } from "react";
import { useLocationHash } from "../../../common/filters";
import { useAppSelector } from "../../../common/store";
import { selectBankGuaranteeRewardsIds } from "../store/bankGuaranteeRewards.selectors";

const ELEMENT_POSITION_FROM_TOP = 80;

export const useScrollToElement = () => {
  const ids = useAppSelector(selectBankGuaranteeRewardsIds);

  const rewardRef = useRef<HTMLDivElement | null>(null);
  const hash = useLocationHash();

  const getElementPositionFromTop = (element: HTMLDivElement) => {
    return element.offsetTop - ELEMENT_POSITION_FROM_TOP;
  };

  const scrollToElementPosition = useCallback(() => {
    if (rewardRef.current) {
      window.scrollTo({
        top: getElementPositionFromTop(rewardRef.current),
        behavior: "smooth",
      });
    }
  }, []);

  const scrollToElement = useCallback(
    (value: string) => {
      if (value === hash) {
        scrollToElementPosition();
      }
    },
    [hash, scrollToElementPosition]
  );

  useEffect(() => {
    if (hash && ids.length) {
      scrollToElementPosition();
    }
  }, [hash, ids.length, scrollToElementPosition]);

  return useMemo(() => ({ scrollToElement, rewardRef }), [scrollToElement]);
};
