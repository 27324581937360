import { BaseCssProps } from "../../../ui-kit/types";
import {
  DocumentUploadCommonBankDocumentCard,
  getDocumentUploadRequestedDocumentOldFiles,
} from "../../../../document/upload";
import { isLoading, LoadingStatusEnum } from "../../../types/state";
import { ProductDetailsDocumentsDocumentState } from "../../types/ProductDetailsDocumentsDocumentState";

export interface ProductDetailsDocumentsDocumentItemProps<T extends ProductDetailsDocumentsDocumentState>
  extends BaseCssProps {
  handleDrop: (acceptedFiles: File[]) => void | Promise<void>;
  document?: T;
  error?: string;
  status: LoadingStatusEnum;
}

export const ProductDetailsDocumentsDocumentItem = <T extends ProductDetailsDocumentsDocumentState>({
  handleDrop,
  document,
  error,
  status,
  className,
}: ProductDetailsDocumentsDocumentItemProps<T>) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {document && (
        <DocumentUploadCommonBankDocumentCard
          banks={document.banks ?? []}
          canUpload
          onDrop={handleDrop}
          title={document.documentName}
          description={document.description}
          isMultiple={document.isMultiFile}
          isRequired={document.isRequired}
          oldFiles={getDocumentUploadRequestedDocumentOldFiles(document)}
          template={document.template}
          className={className}
          isLoading={isLoading(status)}
          error={error}
        />
      )}
    </>
  );
};
