import { forwardRef } from "react";
import * as S from "./BankGuaranteeRewardsFilterSelect.styled";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useAppNavigation } from "../../../../../common/urls/internal/hooks";
import { EntitiesCodeWithTitle } from "../../../../../common/types/objects";
import { BankGuaranteeRewardsFilterSelectCard } from "../card/BankGuaranteeRewardsFilterSelectCard";

export interface BankGuaranteeRewardsFilterSelectProps extends BaseCssProps {
  banks: EntitiesCodeWithTitle<string>;
  isFixed: boolean;
  onSelectedChanged: (value: string) => void;
}

export const BankGuaranteeRewardsFilterSelect = forwardRef<HTMLDivElement, BankGuaranteeRewardsFilterSelectProps>(
  ({ banks, onSelectedChanged, isFixed, className }, ref) => {
    const navigator = useAppNavigation();

    return (
      <BankGuaranteeRewardsFilterSelectCard className={className} isFixed={isFixed} ref={ref}>
        <S.CustomFilterSelect
          text="Выбрать банк"
          selected={null}
          getItemTitle={(code: string) => banks.titles[code]}
          onSelectedChanged={(value: string) => {
            navigator((x) => x.bankGuarantee.rewards.root, undefined, { hash: value });
            onSelectedChanged(value);
          }}
          items={banks.ids}
        />
      </BankGuaranteeRewardsFilterSelectCard>
    );
  }
);
