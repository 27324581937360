import {
  FederalLawTypeWithCommerce,
  federalLawTypeWithCommerce,
  GuaranteeTypeCode,
} from "../../../../common/types/demand";

const closedAuctionAndSingleSupplier = {
  condition: (isSingleSupplier: boolean, isClosedAuction: boolean) => isSingleSupplier || isClosedAuction,
  collection: federalLawTypeWithCommerce.values.filter((x) => x === "Fz223" || x === "Fz44"),
};

const paymentGuaranteeType = {
  condition: (type: GuaranteeTypeCode) => type === "PAYMENT",
  collection: federalLawTypeWithCommerce.values.filter((x) => x === "COMMERCE"),
};

const isCommerce = (federalLaw: FederalLawTypeWithCommerce) => federalLaw === "COMMERCE";

export const federalLawRules = {
  closedAuctionAndSingleSupplier,
  paymentGuaranteeType,
  isCommerce,
};
