import { CancelProductCreditWorkingAssetsInputDto } from "./dto/input/CancelProductCreditWorkingAssetsInputDto";
import { clientV4 } from "../../../../common/api";
import { EmptyOutputDto } from "../../../../common/api/types";
import { CancelDemandCreditWorkingAssetsInputDto } from "./dto/input/CancelDemandCreditWorkingAssetsInputDto";

const CONTROLLER_NAME = "creditWorkingAssets";

export const creditWorkingAssetsRejectionApi = {
  cancelProduct: ({ creditWorkingAssetsId, ...model }: CancelProductCreditWorkingAssetsInputDto) =>
    clientV4.put<typeof model, EmptyOutputDto>(`${CONTROLLER_NAME}/${creditWorkingAssetsId}/cancel`, model),
  cancelDemand: ({ creditWorkingAssetsId, demandId, ...model }: CancelDemandCreditWorkingAssetsInputDto) =>
    clientV4.put<typeof model, EmptyOutputDto>(`${CONTROLLER_NAME}/${creditWorkingAssetsId}/cancel/${demandId}`, model),
};
