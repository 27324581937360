/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentProps, JSXElementConstructor } from "react";
import * as S from "./Grid.styled";

export type GridProps<TComponent extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>> = {
  as?: TComponent;
  container?: boolean;
  item?: boolean;
} & S.ItemProps &
  S.ContainerProps &
  ComponentProps<TComponent>;

export const Grid = <TComponent extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>>({
  container,
  item,
  as,
  ...rest
}: GridProps<TComponent>) => {
  if (container && !item) {
    return <S.Container as={as} {...rest} />;
  }

  return <S.Item as={as} {...rest} />;
};
