import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { CSSObject } from "@emotion/react";
import { makeResponsiveStyles, Theme } from "@sideg/ui-kit";
import { ElementColor, getElementColor, ResponsiveStyleValue } from "../../../types";
import { SizeUnit } from "../../../theme";
import { FontSizeType } from "../../../types/FontSizeType";

type LineHeight = keyof Pick<Theme["font"], "defaultLineHeight">;

export interface TypographyProps {
  fontSize?: ResponsiveStyleValue<FontSizeType | SizeUnit | number>;
  lineHeight?: ResponsiveStyleValue<LineHeight | SizeUnit>;
  fontWeight?: ResponsiveStyleValue<number>;
  fontColor?: ResponsiveStyleValue<ElementColor>;
  textAlign?: ResponsiveStyleValue<CSSObject["textAlign"]>;
  whiteSpace?: ResponsiveStyleValue<CSSObject["whiteSpace"]>;
  wordBreak?: ResponsiveStyleValue<CSSObject["wordBreak"]>;
  userSelect?: ResponsiveStyleValue<CSSObject["userSelect"]>;
  opacity?: ResponsiveStyleValue<CSSObject["opacity"]>;
  fontFeatureSettings?: ResponsiveStyleValue<CSSObject["fontFeatureSettings"]>;
  withLineBreaks?: boolean;
  withTextOverflowEllipsis?: boolean;
}

export const Typography = styled("span", {
  shouldForwardProp: (propName: string) =>
    isPropValid(propName) && propName !== "fontSize" && propName !== "fontWeight",
})<TypographyProps>(
  { fontFamily: "inherit" },
  ({ theme, fontColor }) =>
    makeResponsiveStyles(theme, fontColor, (x, t) => {
      if (x) {
        return {
          color: getElementColor(x, t),
        };
      }

      return {};
    }),
  ({ theme, fontWeight }) => makeResponsiveStyles(theme, fontWeight, (x) => ({ fontWeight: x })),
  ({ theme, textAlign }) => makeResponsiveStyles(theme, textAlign, (x) => ({ textAlign: x })),
  ({ theme, whiteSpace }) => makeResponsiveStyles(theme, whiteSpace, (x) => ({ whiteSpace: x })),
  ({ theme, wordBreak }) => makeResponsiveStyles(theme, wordBreak, (x) => ({ wordBreak: x })),
  ({ theme, userSelect }) => makeResponsiveStyles(theme, userSelect, (x) => ({ userSelect: x })),
  ({ theme, opacity }) => makeResponsiveStyles(theme, opacity, (x) => ({ opacity: x })),
  ({ theme, fontFeatureSettings }) =>
    makeResponsiveStyles(theme, fontFeatureSettings, (x) => ({ fontFeatureSettings: x })),
  ({ theme, fontSize }) =>
    makeResponsiveStyles(theme, fontSize, (x, t) => {
      if (x !== undefined) {
        const fz = typeof x === "number" ? `${x}rem` : t.font[x as FontSizeType] ?? x;

        return { fontSize: fz };
      }

      return {};
    }),
  ({ theme, lineHeight }) =>
    makeResponsiveStyles(theme, lineHeight, (x, t) => {
      if (x !== undefined) {
        return { lineHeight: t.font[x as LineHeight] ?? x };
      }

      return {};
    }),
  ({ theme, withLineBreaks }) =>
    makeResponsiveStyles(theme, withLineBreaks, (x) => {
      if (x) {
        return {
          whiteSpace: "pre-line",
          wordBreak: "break-word",
        };
      }

      return {};
    }),
  ({ withTextOverflowEllipsis }) => {
    if (withTextOverflowEllipsis) {
      return {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      };
    }

    return {};
  },
);
