import { useFormikContext } from "formik";
import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { CreationBlockWithTitle } from "../../../../ui-kit/cards/creation-block-card";
import { useWidgetsCalculatorPeriodMutationEffect } from "../../hooks/useWidgetsCalculatorPeriodMutationEffect";
import { useCustomFieldOnChange, useGetFieldName } from "../../../../form";
import { WidgetsCalculatorFormValues } from "../../types/WidgetsCalculatorFormValues";
import * as S from "../grid-container/WidgetsCalculatorGridContainer.styled";
import { periodKeys } from "../../validation/widgetsCalculatorValidationSchema";
import { WidgetsCalculatorRadioButton } from "../radiobutton/WidgetsCalculatorRadioButton";
import { WidgetsCalculatorFormPeriodField } from "./WidgetsCalculatorFormPeriodField";
import { CreationNote } from "../../../../ui-kit/notifications/note";
import { StackPanel } from "../../../../ui-kit/containers/stack-panel";
import { WidgetCalculatorRadioButtonTextIcon } from "../radiobutton/WidgetCalculatorRadioButtonTextIcon";

const DateInput = S.Input.withComponent(DateFormInput);
const Card = StackPanel.withComponent(CreationBlockWithTitle);

export const WidgetsCalculatorFormPeriodBlock = () => {
  const form = useFormikContext<WidgetsCalculatorFormValues>();

  const getFieldName = useGetFieldName<WidgetsCalculatorFormValues>();
  const onCustomChange = useCustomFieldOnChange<WidgetsCalculatorFormValues>();

  const desktopBreakpoint = useThemeDesktopBreakpoint();

  useWidgetsCalculatorPeriodMutationEffect();

  return (
    <Card title="Сроки" direction="column" gap="lg">
      <S.Grid>
        <DateInput
          index={1}
          placeholder="дд.мм.гггг"
          label="Дата начала"
          {...form.getFieldProps(getFieldName("dateFrom"))}
          onChange={(e: string) => onCustomChange(form.setFieldValue, "dateFrom", e, true)}
          autoComplete="off"
          disabled={form.values.periodLocker === "dateFrom"}
        />
        <DateInput
          index={2}
          placeholder="дд.мм.гггг"
          label="Дата окончания"
          {...form.getFieldProps(getFieldName("dateTo"))}
          onChange={(e: string) => onCustomChange(form.setFieldValue, "dateTo", e, true)}
          autoComplete="off"
          disabled={form.values.periodLocker === "dateTo"}
        />
        <WidgetsCalculatorFormPeriodField index={3} isDisabled={form.values.periodLocker === "period"} />
        {periodKeys.map((x, i) => (
          <S.Checker index={i + 1} key={x}>
            <WidgetsCalculatorRadioButton
              {...form.getFieldProps(getFieldName("periodLocker"))}
              value={x}
              checked={x === form.values.periodLocker}
            />
          </S.Checker>
        ))}
      </S.Grid>
      <CreationNote as="p" fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz3" }} lineHeight="defaultLineHeight">
        Заблокируйте <WidgetCalculatorRadioButtonTextIcon /> любой из трех
        <br />
        параметров, чтобы рассчитать его
        <br />
        значение, изменяя другие
      </CreationNote>
    </Card>
  );
};
