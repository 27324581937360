import { CSSObject } from "@emotion/serialize";
import { useMemo, useState, useEffect, useCallback, useRef } from "react";

export const useGetResponsiveElementWidth = <T extends HTMLElement>() => {
  const ref = useRef<T | null>(null);

  const [responsiveWidth, setResponsiveWidth] = useState<CSSObject["width"]>();

  const onResize = useCallback(() => {
    if (ref.current) {
      const elementStyle = getComputedStyle(ref.current);
      setResponsiveWidth(`calc(${elementStyle.width} - ${elementStyle.paddingLeft} - ${elementStyle.paddingRight})`);
    }
  }, []);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return useMemo(
    () => ({
      ref,
      responsiveWidth,
    }),
    [responsiveWidth],
  );
};
