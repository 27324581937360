import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { bankGuaranteeDetailsDemandsAdapter } from "./bankGuaranteeDetailsDemands.adapter";
import {
  BANK_GUARANTEE_DETAILS_SLICE_NAME,
  getBankGuaranteeDetails,
  uploadGroupDocumentBankGuaranteeDetailsDemand,
} from "../bankGuaranteeDetails.thunks";
import { combineSliceNames } from "../../../../common/store/utils";
import { bankGuaranteeDetailsGuaranteeChanged } from "../bankGuaranteeDetails.actions";
import { onBankGuaranteeExtractFromRegistryStatusChanged } from "../../../extract-from-registry";
import { bankGuaranteeFileRegistryStatusType } from "../../../common/types";
import { bankGuaranteeDetailsDemandsMapper } from "./bankGuaranteeDetailsDemands.mapper";

const SLICE_NAME = combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "demands");

const initialState = bankGuaranteeDetailsDemandsAdapter.getInitialState();

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bankGuaranteeDetailsGuaranteeChanged, (state, action) => {
        if (action.payload === undefined) {
          bankGuaranteeDetailsDemandsAdapter.removeAll(state);

          return;
        }

        bankGuaranteeDetailsDemandsAdapter.setAll(
          state,
          bankGuaranteeDetailsDemandsMapper.mapDemands(action.payload.demands ?? []),
        );
      })
      .addCase(onBankGuaranteeExtractFromRegistryStatusChanged, (state, action) => {
        const item = state.entities[action.payload.demandId];
        if (item && item.approval?.fileRegistryStatus) {
          item.approval.fileRegistryStatus.code = action.payload.newStatus;
          item.approval.fileRegistryStatus.title = bankGuaranteeFileRegistryStatusType.getTitle(
            action.payload.newStatus,
          );
        }
      })
      .addMatcher(
        isAnyOf(getBankGuaranteeDetails.fulfilled, uploadGroupDocumentBankGuaranteeDetailsDemand.fulfilled),
        (state, action) => {
          bankGuaranteeDetailsDemandsAdapter.upsertMany(
            state,
            bankGuaranteeDetailsDemandsMapper.mapDemands(action.payload.body.demands),
          );
        },
      );
  },
});

export const bankGuaranteeDetailsDemandsReducer = slice.reducer;
