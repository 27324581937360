import styled from "@emotion/styled";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xl};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    gap: ${({ theme }) => theme.spaces.xxl};
  }

  & > * + * {
    border: none;
    position: relative;

    ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: -${({ theme }) => theme.spaces.md};
        height: 1px;
        background-color: ${({ theme }) => theme.colors.secondaryLight};
        width: 100%;
      }
    }
  }
`;
