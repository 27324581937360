import { forwardRef } from "react";
import { ClientId } from "../../../common/types/demand";
import { useAppSelector } from "../../../common/store";
import * as S from "./SearchClientListItem.styled";
import { ClientSearchClientStateSelector } from "../../types/ClientSearchClientStateSelector";
import { getClientSearchSelectors } from "../../store/clientSearch.selectors";
import { Text } from "../../../common/ui-kit/typography";

export interface BankGuaranteeSearchClientListItemProps extends Omit<JSX.IntrinsicElements["li"], "id"> {
  id: ClientId;
  highlighted?: boolean;
  clientStateSelector: ClientSearchClientStateSelector;
}

export const SearchClientListItem = forwardRef<HTMLLIElement, BankGuaranteeSearchClientListItemProps>(
  ({ id, highlighted, clientStateSelector, ...rest }, ref) => {
    const client = useAppSelector((state) => getClientSearchSelectors(clientStateSelector).selectById(state, id));

    if (!client) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return null!;
    }

    return (
      <S.Wrapper highlighted={highlighted === true} {...rest} ref={ref}>
        <S.Title>{client.name}</S.Title>
        <Text fontColor="primaryCaption" lineHeight="1.3" fontSize="fz1" fontWeight={500}>
          ИНН&nbsp;{client.taxpayerNumber}
        </Text>
      </S.Wrapper>
    );
  }
);
