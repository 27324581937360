import { TransitionGroup } from "react-transition-group";
import { NavigationState } from "../../../../../../../../common/navigation/navigation-state";
import { CreditExecutionId, DemandId } from "../../../../../../../../common/types/demand";
import { BaseCssProps } from "../../../../../../../../common/ui-kit/types";
import { ProductListCardDemandListItemAppearanceTransition } from "../../../../../../../../common/ui/product/list/card";
import { ProductListItemDemandItemLayoutDesktop } from "../../../../../../../../common/ui/product/list/product-list-item";
import { CreditExecutionListItemDemandDesktop } from "../item/CreditExecutionListItemDemand.desktop";

export interface CreditExecutionListItemDemandsListDesktopProps extends BaseCssProps {
  creditExecutionId: CreditExecutionId;
  demandIds: DemandId[];
  from: NavigationState;
}

export const CreditExecutionListItemDemandsListDesktop = ({
  className,
  demandIds,
  creditExecutionId,
  from,
}: CreditExecutionListItemDemandsListDesktopProps) => {
  return (
    <TransitionGroup component={ProductListItemDemandItemLayoutDesktop.Container} className={className}>
      {demandIds.map((x) => (
        <ProductListCardDemandListItemAppearanceTransition key={x} timeout={250}>
          <CreditExecutionListItemDemandDesktop creditExecutionId={creditExecutionId} demandId={x} from={from} />
        </ProductListCardDemandListItemAppearanceTransition>
      ))}
    </TransitionGroup>
  );
};
