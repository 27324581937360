import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  FullPageContainerHeaderContainer,
  FullPageContainerHeaderDesktop,
  FullPageContainerHeaderTitle,
} from "../../../../../common/ui/containers/full-page";
import { CreditExecutionCreationCreateByExistsHeaderMobile } from "./CreditExecutionCreationCreateByExistsHeader.mobile";
import { BackLinkButton } from "../../../../../common/ui/buttons/BackLinkButton";
import { CreditExecutionCreationCreateByExistsPageType } from "../../../types/creation/CreditExecutionCreationCreateByExistsPageType";
import { useNavigationStateFromWithFallback } from "../../../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../../../common/urls/internal/utils";

export interface CreditExecutionCreationCreateByExistsHeaderProps extends BaseCssProps {
  activePage: CreditExecutionCreationCreateByExistsPageType;
  onPageTypeClick: (type: CreditExecutionCreationCreateByExistsPageType) => void | Promise<void>;
}

export const CreditExecutionCreationCreateByExistsHeader = ({
  activePage,
  onPageTypeClick,
  className,
}: CreditExecutionCreationCreateByExistsHeaderProps) => {
  const { from } = useNavigationStateFromWithFallback(
    selectAppUrl((x) => x.creditExecution.list.root).url,
    "Мои заявки",
  );

  return (
    <FullPageContainerHeaderContainer className={className}>
      <ThemeMobileDesktopView
        mobile={
          <CreditExecutionCreationCreateByExistsHeaderMobile
            onPageTypeClick={onPageTypeClick}
            activePage={activePage}
            from={from}
          />
        }
        desktop={
          <FullPageContainerHeaderDesktop
            leftSide={<BackLinkButton linkTo={from.url} text={from.backTitle} />}
            className={className}
          >
            <FullPageContainerHeaderTitle text="Выбор банков для доотправки заявки" />
          </FullPageContainerHeaderDesktop>
        }
      />
    </FullPageContainerHeaderContainer>
  );
};
