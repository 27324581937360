import { useAppUrlMatch } from "../../../../common/urls/internal/hooks";
import { useBackLinkHeaderAction } from "../../../../common/ui/layout/header";
import { NavigationStateFrom } from "../../../../common/navigation/navigation-state/types/NavigationStateFrom";
import { useCreditExecutionDetailsContext } from "../../hooks/context/useCreditExecutionDetailsContext";

export interface CreditExecutionDetailsNavigationHeaderMobileProps {
  from: NavigationStateFrom;
}

export const CreditExecutionDetailsNavigationHeaderMobile = ({
  from,
}: CreditExecutionDetailsNavigationHeaderMobileProps) => {
  const { creditExecutionId } = useCreditExecutionDetailsContext();
  const { isExact, path } = useAppUrlMatch((x) => x.creditExecution.details.creditExecution, { creditExecutionId });

  useBackLinkHeaderAction({
    withBackIcon: true,
    url: isExact ? from.url : path,
    text: isExact ? from.backTitle : "Заявка на КИК",
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
