import styled from "@emotion/styled";

const COLUMN_WIDTH = "160px";
const COLUMN_COUNT = 4;

export const Container = styled.ul`
  width: ${({ theme }) => `calc(${COLUMN_WIDTH} * ${COLUMN_COUNT} + ${theme.spaces.xxl} * (${COLUMN_COUNT} - 1))`};
  columns: ${COLUMN_COUNT} ${COLUMN_WIDTH};
  column-gap: ${({ theme }) => theme.spaces.xxl};
  padding-top: ${({ theme }) => theme.spaces.sm};
`;
