/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { SignalrConnection } from "../types/SignalrConnection";

type Callbacks<T extends SignalrConnection<string, string, any>> = T extends SignalrConnection<string, string, infer U>
  ? U
  : never;

export const useHubEvent = <
  TConnection extends SignalrConnection<string, string, any>,
  TMethod extends Callbacks<TConnection>,
  TOutput = undefined
>(
  connection: TConnection,
  methodName: TMethod,
  handler: (arg: TOutput) => void
) => {
  useEffect(() => {
    connection.on(methodName, handler);

    return () => {
      connection.off(methodName, handler);
    };
  }, [methodName, handler, connection]);
};
