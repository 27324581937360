import { ReactNode } from "react";
import { Box } from "../../../../common/ui-kit/containers/box";
import * as S from "./DemandQueueTaskCommentHeader.styled";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export interface DemandQueueTaskCommentHeaderProps extends BaseCssProps {
  title?: ReactNode;
  date?: ReactNode;
}

export const DemandQueueTaskCommentHeader = ({ title, date, className }: DemandQueueTaskCommentHeaderProps) => {
  return (
    <Box as="header" display="flex" alignItems="center" justifyContent="space-between" className={className}>
      <S.Title>{title}</S.Title>
      {date && <S.Time>{date}</S.Time>}
    </Box>
  );
};
