import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { CreditExecutionDetailsContextType } from "../../store/context/CreditExecutionDetailsContext";
import { selectCreditExecutionDetailsTransfer } from "../../store/transfer/creditExecutionDetailsTransfer.selectors";
import { selectCreditExecutionDetailsProductDetails } from "../../store/product-details/creditExecutionDetailsProductDetails.selectors";
import { useAppUrlMatch } from "../../../../common/urls/internal/hooks";
import { clearCreditExecutionDetailsTransfer } from "../../store/transfer/creditExecutionDetailsTransfer.slice";
import {
  creditExecutionDetailsActiveDemandChanged,
  creditExecutionDetailsActiveProductChanged,
} from "../../store/creditExecutionDetails.actions";
import { selectCreditExecutionDetailsActiveDemand } from "../../store/active-demand/creditExecutionDetailsActiveDemand.selectors";

export const useCreditExecutionDetailsMatchListener = (match: CreditExecutionDetailsContextType) => {
  const dispatch = useAppDispatch();
  const productDetails = useAppSelector(selectCreditExecutionDetailsProductDetails);

  const transfer = useAppSelector(selectCreditExecutionDetailsTransfer);
  const { activeDemandId } = useAppSelector(selectCreditExecutionDetailsActiveDemand);

  const { isExact: isDocumentsPage } = useAppUrlMatch((x) => x.creditExecution.details.documents, {
    creditExecutionId: match.creditExecutionId,
  });

  useEffect(() => {
    if (productDetails?.data?.creditExecutionId !== match.creditExecutionId) {
      dispatch(creditExecutionDetailsActiveProductChanged(transfer?.data));
      dispatch(clearCreditExecutionDetailsTransfer());
    }
  }, [dispatch, match.creditExecutionId, transfer?.data, productDetails?.data]);

  useEffect(() => {
    return () => {
      dispatch(creditExecutionDetailsActiveProductChanged());
    };
  }, [dispatch]);

  useEffect(() => {
    if (match?.demandId && activeDemandId !== match.demandId) {
      dispatch(creditExecutionDetailsActiveDemandChanged(match.demandId));
    }
  }, [activeDemandId, match?.demandId, dispatch]);

  useEffect(() => {
    if (isDocumentsPage) {
      dispatch(creditExecutionDetailsActiveDemandChanged(undefined));
    }
  }, [dispatch, isDocumentsPage]);
};
