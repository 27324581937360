import {
  DemandQueueTasksRequestedDocumentField,
  DemandQueueTasksRequestedDocumentWithRealTime,
} from "../../../../demand/queue-tasks";
import { QueueTaskId } from "../../../../common/types/demand";
import { useAppSelector } from "../../../../common/store";
import { selectAgencyDemandDocumentById } from "../../store/agencyDemand.selectors";
import { AgencyDemandDocumentId } from "../../types/AgencyDemandDocumentId";

export interface AgencyDemandDocumentProps {
  documentId: AgencyDemandDocumentId;
  canRealTimeUpload: boolean;
  queueTaskId: QueueTaskId;
}

export const AgencyDemandDocument = ({ queueTaskId, documentId, canRealTimeUpload }: AgencyDemandDocumentProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const document = useAppSelector((state) => selectAgencyDemandDocumentById(state, documentId))!;

  return (
    <>
      {canRealTimeUpload && (
        <DemandQueueTasksRequestedDocumentWithRealTime queueTaskId={queueTaskId} document={document} />
      )}
      {!canRealTimeUpload && <DemandQueueTasksRequestedDocumentField document={document} />}
    </>
  );
};
