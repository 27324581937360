import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { StackPanel } from "../../../../ui-kit/containers/stack-panel";
import { BaseCssProps } from "../../../../ui-kit/types";

export interface ProductListItemStageCountersContainerProps extends BaseCssProps {
  children?: React.ReactNode;
}

export const ProductListItemStageCountersContainer = ({
  children,
  className,
}: ProductListItemStageCountersContainerProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <StackPanel direction="row" gap={{ xs: "md", [desktopBreakpoint]: "sm" }} className={className}>
      {children}
    </StackPanel>
  );
};
