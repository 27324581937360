import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useProductFilterSearchInput } from "../../../../../../common/filters/use-cases";
import { useCreditWorkingAssetsListFilterContext } from "../../../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { FormSearchInput } from "../../../../../../common/ui-kit/form-controls/search-input";
import { CreditWorkingAssetsListHeaderFiltersOpenButtonMobile } from "./CreditWorkingAssetsListHeaderFiltersOpenButton.mobile";

export type CreditWorkingAssetsListHeaderFiltersMobileProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFiltersMobile = ({
  className,
}: CreditWorkingAssetsListHeaderFiltersMobileProps) => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();
  const { onChange, value } = useProductFilterSearchInput(filteringObject);

  return (
    <Box pt="md">
      <FormSearchInput
        placeholder="Номер заявки, клиент, ИНН"
        className={className}
        afterInput={<CreditWorkingAssetsListHeaderFiltersOpenButtonMobile />}
        value={value}
        onChange={onChange}
        spellCheck={false}
        autoComplete="off"
        autoCorrect="off"
      />
    </Box>
  );
};
