import * as React from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../ui-kit/types";
import { Typography } from "../../../../ui-kit/typography";

export interface ProductCreationScoringSumLimitRangeContainerProps extends BaseCssProps {
  children?: React.ReactNode;
}

export const ProductCreationScoringSumLimitRangeContainer = ({
  children,
  className,
}: ProductCreationScoringSumLimitRangeContainerProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Typography
      as="div"
      className={className}
      fontSize={{ xs: "fz2", [desktopBreakpoint]: "fz3" }}
      fontWeight={500}
      lineHeight="1.2"
      fontColor="dark"
    >
      {children}
    </Typography>
  );
};
