import { useCallback } from "react";
import { filtrationService, useSetQuerySearch } from "../../../../../common/filters";
import { BankGuaranteeDetailsFilterContextType } from "../../../types/filters/BankGuaranteeDetailsFilterContextType";
import { BankGuaranteeId, DemandId } from "../../../../../common/types/demand";
import { generatePath } from "../../../../../common/urls/internal/utils";
import { bankGuaranteeDetailsPageUrlSelectors } from "../../../configs/bankGuaranteeDetailsPageUrlSelectors";
import { BankGuaranteeDetailsFilterApiContextType } from "../../../types/filters/BankGuaranteeDetailsFilterApiContextType";
import { BankGuaranteeDetailsFilterMutationService } from "../../../services/filters/bankGuaranteeDetailsFilterMutationService";

export const useBankGuaranteeDetailsFilterMutator = (
  guaranteeId: BankGuaranteeId,
  demandId: DemandId,
): BankGuaranteeDetailsFilterApiContextType => {
  const setQuery = useSetQuerySearch(
    generatePath(bankGuaranteeDetailsPageUrlSelectors.demand, { demandId, guaranteeId }),
  );

  return useCallback(
    (filter, getDiff): BankGuaranteeDetailsFilterContextType => {
      const service = new BankGuaranteeDetailsFilterMutationService(filter);
      const result = filtrationService.setValues(filter, getDiff(service));
      setQuery(result);

      return result;
    },
    [setQuery],
  );
};
