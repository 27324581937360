import { forwardRef } from "react";
import { FormInput, FormInputProps } from "@sideg/ui-kit/atoms/form-controls/input";
import { SearchIcon } from "../../../icons";
import * as S from "./FormSearchInput.styled";
import { useForwardedRef } from "../../common/hooks";

export type FormSearchInputProps = Omit<FormInputProps, "type" | "beforeInput">;

/**
 * @deprecated Use from @sideg/ui-kit
 */
export const FormSearchInput = forwardRef<HTMLInputElement, FormSearchInputProps>(({ disabled, ...rest }, ref) => {
  const forwardedRef = useForwardedRef(ref);

  return (
    <FormInput
      type="search"
      beforeInput={
        <S.SearchIconButton
          onClick={() => {
            forwardedRef?.current?.focus();
          }}
          disabled={disabled}
          type="button"
        >
          <SearchIcon />
        </S.SearchIconButton>
      }
      disabled={disabled}
      ref={forwardedRef}
      {...rest}
    />
  );
});
