import { useMemo } from "react";
import {
  cancelDemandBankGuaranteeRejection,
  cancelProductBankGuaranteeRejection,
} from "../store/bankGuaranteeRejection.thunks";
import { formValidationFieldService } from "../../../common/form";
import { isBaseApiError } from "../../../common/api/types";
import { CancelDemandBankGuaranteeInputDto } from "../api/dto/input/CancelDemandBankGuaranteeInputDto";
import { CancelProductBankGuaranteeInputDto } from "../api/dto/input/CancelProductBankGuaranteeInputDto";
import { useApiMutationAction } from "../../../common/hooks";
import { ProductRejectionSubmitHandler } from "../../../common/product-rejection";
import { ApiAsyncThunk } from "../../../common/store/utils";

type MutationType = "product" | "demand";
type InputDto<T extends MutationType> = T extends "demand"
  ? CancelDemandBankGuaranteeInputDto
  : CancelProductBankGuaranteeInputDto;

export const useBankGuaranteeRejectionSubmitHandler = <TMutation extends MutationType>(
  mutation: TMutation,
  args: Omit<InputDto<TMutation>, "rejectionReasonId" | "rejectionReasonComment">,
  onSuccess: () => Promise<void>,
) => {
  const { error, mutate } = useApiMutationAction();

  return useMemo(() => {
    const handleFormSubmit: ProductRejectionSubmitHandler = async (values, formikHelpers) => {
      const thunk = mutation === "product" ? cancelProductBankGuaranteeRejection : cancelDemandBankGuaranteeRejection;

      const result = await mutate(
        thunk as ApiAsyncThunk<unknown, InputDto<TMutation>>,
        { ...args, ...values } as InputDto<TMutation>,
        {
          errorHandler: async (e: unknown) => {
            if (isBaseApiError(e)) {
              formikHelpers.setErrors(formValidationFieldService.tryGetValidationErrors(e));

              return e.message ?? "";
            }

            return "Не удалось отменить заявку. Попробуйте позже или свяжитесь с персональным менеджером";
          },
        },
      );

      if (result) {
        await onSuccess();
      }
    };

    return { handleFormSubmit, error };
  }, [args, error, mutate, mutation, onSuccess]);
};
