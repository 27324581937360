export const getObjectKeysDeep = (obj: Record<string, undefined>, prefix = "") => {
  const keys: string[] = [];

  for (const key of Object.keys(obj)) {
    const path = prefix ? `${prefix}.${key}` : key;
    const value = obj[key];
    if (value && typeof value === "object" && !Array.isArray(value)) {
      keys.push(...getObjectKeysDeep(value, path));
    } else {
      keys.push(path);
    }
  }

  return keys;
};
