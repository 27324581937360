import { BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardMobile } from "../card/BankGuaranteeDetailsDemandHeaderReconciliationNavigationCard.mobile";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { useBankGuaranteeDetailsLayoutContext } from "../../../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { BankGuaranteeDetailsDemandActualBill } from "../../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandActualBill";
import { selectBankGuaranteeDetailsActiveTasksBillCount } from "../../../../../store/active-tasks/bankGuaranteeDetailsActiveTasks.selectors";
import { useAppSelector } from "../../../../../../../common/store";

export interface BankGuaranteeDetailsDemandReconciliationBillNavigationCardProps extends BaseCssProps {
  model: BankGuaranteeDetailsDemandActualBill | undefined;
}

export const BankGuaranteeDetailsDemandHeaderReconciliationBillNavigationCard = ({
  model,
  className,
}: BankGuaranteeDetailsDemandReconciliationBillNavigationCardProps) => {
  const { demandId, guaranteeId } = useBankGuaranteeDetailsLayoutContext();
  const counterValue = useAppSelector(selectBankGuaranteeDetailsActiveTasksBillCount);

  return (
    model !== undefined && (
      <BankGuaranteeDetailsDemandHeaderReconciliationNavigationCardMobile
        to={(urls) => urls.bankGuarantee.details.bill}
        params={{ demandId, guaranteeId }}
        title="Согласование стоимости БГ"
        state={model?.state.code}
        counter={counterValue}
        className={className}
      />
    )
  );
};
