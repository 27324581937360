import { useCallback } from "react";
import { FormikHelpers } from "formik";
import { CreditWorkingAssetsCreationRequest } from "../../types/creation/CreditWorkingAssetsCreationRequest";
import { useAppDispatch } from "../../../../common/store";
import { CreditWorkingAssetsCreationFormValues } from "../../types/creation/CreditWorkingAssetsCreationFormValues";
import {
  getCreditWorkingAssetsCreationFromValidationSchema,
  getCreditWorkingAssetsCreationRequestValidationSchema,
} from "../../validation/creditWorkingAssetsCreationValidationSchema";
import { shallowEqualObjects } from "../../../../common/utils/shallowEqualObjects";
import { formValidationFieldService } from "../../../../common/form";
import { createDraftCreditWorkingAssetsCreation } from "../../store/creditWorkingAssetsCreation.thunks";
import {
  setIsFormSameAsInitialCreditWorkingAssetCreation,
  setNextStageCreditWorkingAssetCreation,
} from "../../store/creditWorkingAssetsCreation.slice";

export const useCreditWorkingAssetsCreationFormSubmit = (initialValues: CreditWorkingAssetsCreationRequest) => {
  const dispatch = useAppDispatch();

  return useCallback(
    async (
      formValues: CreditWorkingAssetsCreationFormValues,
      formikHelpers: FormikHelpers<CreditWorkingAssetsCreationFormValues>
    ) => {
      try {
        const formCastedValues = getCreditWorkingAssetsCreationFromValidationSchema().cast(formValues);
        const actualCastedValues = getCreditWorkingAssetsCreationRequestValidationSchema().cast(formCastedValues, {
          assert: true,
          stripUnknown: true,
        }) as CreditWorkingAssetsCreationRequest;

        const initialCastedValues = getCreditWorkingAssetsCreationRequestValidationSchema().cast(initialValues);

        const isEquals = shallowEqualObjects(initialCastedValues, actualCastedValues);
        dispatch(setIsFormSameAsInitialCreditWorkingAssetCreation(isEquals));

        if (!isEquals) {
          await dispatch(createDraftCreditWorkingAssetsCreation(actualCastedValues)).unwrap();
        } else {
          dispatch(setNextStageCreditWorkingAssetCreation());
        }
      } catch (e: unknown) {
        formikHelpers.setErrors(formValidationFieldService.tryGetValidationErrors(e));
      }
    },
    [dispatch, initialValues]
  );
};
