import { numberHelper } from "@sideg/helpers";

const PX = "px";
const REM = "rem";
const PERCENT = "%";

const KNOWN_UNITS = [PX, REM, PERCENT] as const;

type Px = typeof PX;
type Rem = typeof REM;
type Percent = typeof PERCENT;

type SizeUnit = Px | Rem | Percent;

export type SizeWithUnit<TUnit extends SizeUnit, TSize extends number = number> = `${TSize}${TUnit}`;
export type SizeWithAnyUnit = SizeWithUnit<SizeUnit>;

export type SizeInPixels<TSize extends number = number> = SizeWithUnit<Px, TSize>;
export type SizeInRems<TSize extends number = number> = SizeWithUnit<Rem, TSize>;

export const tryGetSizeAndUnit = (size: unknown): undefined | { size: number; unit: SizeUnit } => {
  if (typeof size !== "string") {
    return undefined;
  }

  for (const unit of KNOWN_UNITS) {
    const replacedValue = size.replace(unit, "");
    if (replacedValue.length !== size.length) {
      const parsedValue = numberHelper.parseInt(replacedValue);

      return numberHelper.isValidNumber(parsedValue) ? { size: parsedValue, unit } : undefined;
    }
  }

  return undefined;
};

export const isSizeInPixels = (size: unknown): size is SizeInPixels => {
  return tryGetSizeAndUnit(size)?.unit === PX;
};

export const isSizeInRems = (size: unknown): size is SizeInRems => {
  return tryGetSizeAndUnit(size)?.unit === REM;
};
