import { useCallback } from "react";
import { FormikProps } from "formik";
import { FieldNames } from "../types/FieldNames";

export const useCustomFieldOnChange = <TFormValues extends Record<string, unknown>>() => {
  return useCallback(
    <TFieldValue>(
      setFieldValue: FormikProps<TFormValues>["setFieldValue"],
      field: FieldNames<TFormValues>,
      value: TFieldValue,
      shouldValidate?: boolean
    ) => {
      setFieldValue(field, value, shouldValidate);
    },
    []
  );
};
