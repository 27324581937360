import { useMemo, useRef } from "react";
import { ApiAsyncThunk } from "../../store/utils";
import { ApiBlobResponse, ApiResponse } from "../../api/types";
import { useApiMutation, UseApiMutationOptions } from "../useApiMutation/useApiMutation";
import { useApiFileSaver } from "../useApiFileSaver/useApiFileSaver";
import { isLoading } from "../../types/state";

export const useDownloadFileApiMutation = <TInput>(
  thunk: ApiAsyncThunk<ApiResponse<ApiBlobResponse>, TInput>,
  model: TInput,
  options: UseApiMutationOptions = { errorMessagePlaceholder: "" }
) => {
  const { status, mutate } = useApiMutation(thunk, options);
  const saveAs = useApiFileSaver();

  const modelRef = useRef<TInput | null>(model);
  modelRef.current = model;

  return useMemo(() => {
    const download = async () => {
      if (!isLoading(status)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const result = await mutate(modelRef.current!);
        if (result?.body.data) {
          saveAs(result.body);
        }
      }
    };

    return { status, download };
  }, [mutate, saveAs, status]);
};
