import { TransitionGroup } from "react-transition-group";
import { useAppSelector } from "../../../common/store";
import { selectWaitedToastIds } from "../store/globalToasts.selectors";
import { SimpleOpacityTransition } from "../../../common/ui-kit/animations/transitions";
import { GlobalToast } from "./GlobalToast";

export const GlobalToasts = () => {
  const ids = useAppSelector(selectWaitedToastIds);

  return (
    <TransitionGroup component={null}>
      {ids.map((x) => (
        <SimpleOpacityTransition key={x} timeout={300}>
          <GlobalToast id={x} />
        </SimpleOpacityTransition>
      ))}
    </TransitionGroup>
  );
};
