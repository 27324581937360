import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  Personal: "физического лица",
  Individual: "индивидуального предпринимателя",
  Legal: "юридического лица",
};

export const bankGuaranteeRewardsCompanyType = makeEnumLikeLiteral(
  ["Personal", "Individual", "Legal"] as const,
  titles,
);

export type BankGuaranteeRewardsCompanyType = EnumLikeLiteralType<typeof bankGuaranteeRewardsCompanyType>;
