import * as React from "react";
import { ComponentProps } from "react";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import * as S from "./ProductListCreationButton.styled";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { Icon, PlusIcon } from "../../../../../ui-kit/icons";
import { AppLink } from "../../../../../urls/internal/components";

export interface ProductListCreationButtonProps
  extends BaseCssProps,
    Omit<ComponentProps<typeof AppLink>, "ref" | "className" | "children" | "color"> {}

export const ProductListCreationButton = React.forwardRef<HTMLAnchorElement, ProductListCreationButtonProps>(
  (props, ref) => {
    return (
      <S.Link variant="contained" color="primary" {...props} ref={ref}>
        <ThemeMobileDesktopView
          mobile={<Icon as={PlusIcon} width={24} height={24} stroke="typography.primaryLight" />}
          desktop="Создать новую заявку"
        />
      </S.Link>
    );
  },
);
