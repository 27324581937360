import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { bankGuaranteeDetailsDocumentsAdapter } from "./bankGuaranteeDetailsDocuments.adapter";
import { combineSliceNames } from "../../../../common/store/utils";
import {
  BANK_GUARANTEE_DETAILS_SLICE_NAME,
  getBankGuaranteeDetails,
  uploadGroupDocumentBankGuaranteeDetailsDemand,
} from "../bankGuaranteeDetails.thunks";
import { bankGuaranteeDetailsDocumentsMapper } from "./bankGuaranteeDetailsDocuments.mapper";
import { bankGuaranteeDetailsGuaranteeChanged } from "../bankGuaranteeDetails.actions";
import { DocumentTypeId } from "../../../../common/types/documents";

const initialState = bankGuaranteeDetailsDocumentsAdapter.getInitialState();

const slice = createSlice({
  name: combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "documents"),
  initialState,
  reducers: {
    // Оставляем документы вместо удаления для анимации исчезновения.
    markAsUploaded: (state, action: PayloadAction<DocumentTypeId>) => {
      bankGuaranteeDetailsDocumentsAdapter.updateOne(state, { id: action.payload, changes: { isUploaded: true } });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bankGuaranteeDetailsGuaranteeChanged, () => initialState)
      .addMatcher(
        isAnyOf(getBankGuaranteeDetails.fulfilled, uploadGroupDocumentBankGuaranteeDetailsDemand.fulfilled),
        (state, action) => {
          if (!action.payload.body.missingDocuments) {
            bankGuaranteeDetailsDocumentsAdapter.removeAll(state);
          } else {
            bankGuaranteeDetailsDocumentsAdapter.setAll(
              state,
              bankGuaranteeDetailsDocumentsMapper.mapAll(action.payload.body.missingDocuments),
            );
          }
        },
      );
  },
});

export const { markAsUploaded: markAsUploadedBankGuaranteeDetailsDocuments } = slice.actions;

export const bankGuaranteeDetailsDocumentsReducer = slice.reducer;
