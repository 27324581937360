import { currencyHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { CreditWorkingAssetsId, DemandId } from "../../../../../../../common/types/demand";
import { useAppDispatch, useAppSelector } from "../../../../../../../common/store";
import { selectCreditWorkingAssetsListItemsDemandById } from "../../../../../store/items/creditWorkingAssetsListItems.selectors";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import { useCreditWorkingAssetsListItemLink } from "../../../../../hooks/useCreditWorkingAssetsListItemLink";
import { onDemandRejectCreditWorkingAssetsListItemModification } from "../../../../../store/item-modification/creditWorkingAssetsListItemModification.slice";
import { CreditWorkingAssetsStageIcon } from "../../../../../../common/components";
import { getCreditWorkingAssetsDemandStageColorStatus } from "../../../../../utils/getCreditWorkingAssetsDemandStageColorStatus";
import { ProductListItemDemandItemLayoutDesktop } from "../../../../../../../common/ui/product/list/product-list-item";
import { getCreditWorkingAssetsDemandStageColorBankLabel } from "../../../../../utils/getCreditWorkingAssetsDemandStageColorBankLabel";

export interface CreditWorkingAssetsListItemDemandDesktopProps extends BaseCssProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
  demandId: DemandId;
  from: NavigationState;
}

export const CreditWorkingAssetsListItemDemandDesktop = ({
  creditWorkingAssetsId,
  demandId,
  from,
  className,
}: CreditWorkingAssetsListItemDemandDesktopProps) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectCreditWorkingAssetsListItemsDemandById(state, demandId))!;
  const linkProps = useCreditWorkingAssetsListItemLink(creditWorkingAssetsId, demandId);

  const onRemoveClick = () => {
    dispatch(onDemandRejectCreditWorkingAssetsListItemModification({ creditWorkingAssetsId, demandId }));
  };

  return (
    <ProductListItemDemandItemLayoutDesktop className={className}>
      <ProductListItemDemandItemLayoutDesktop.Icon icon={<CreditWorkingAssetsStageIcon stage={demand.stage.code} />} />
      <ProductListItemDemandItemLayoutDesktop.Bank
        bankTitle={demand.bank.title}
        linkProps={linkProps}
        from={from}
        fontColor={getCreditWorkingAssetsDemandStageColorBankLabel(demand.stage.code)}
        isLineThrough={demand.stage.code === "Rejected"}
        isUpdated={demand.isUpdated}
      />

      <ProductListItemDemandItemLayoutDesktop.Sum
        fontColor={demand.isConfirmed ? "typography.successSecondary" : "typography.medium"}
        sum={currencyHelper.getCurrencyStringNullable(demand.confirmedCreditSum ?? demand.creditSum, "-")}
      />
      <ProductListItemDemandItemLayoutDesktop.StatusContainer
        statusTitle={demand.status.title}
        linkProps={linkProps}
        from={from}
        fontColor={getCreditWorkingAssetsDemandStageColorStatus(demand.stage.code, demand.countActiveTasks > 0)}
      >
        {!demand.isApproved && <span>{demand.status.title}</span>}
        {demand.isApproved && demand.approvedCreditSum !== undefined && (
          <span>Выдано {currencyHelper.getCurrencyString(demand.approvedCreditSum)}</span>
        )}
        {demand.countActiveTasks > 1 && (
          <ProductListItemDemandItemLayoutDesktop.StatusLinkCounter countActiveTasks={demand.countActiveTasks} />
        )}
        {demand.isTerminated && demand.termination && (
          <ProductListItemDemandItemLayoutDesktop.RejectTooltipIcon
            reason={demand.termination.reason.title}
            comment={demand.termination.comment}
          />
        )}
      </ProductListItemDemandItemLayoutDesktop.StatusContainer>
      <ProductListItemDemandItemLayoutDesktop.DateLabel date={demand.dateUpdated} />
      <ProductListItemDemandItemLayoutDesktop.RemoveButton onRemoveClick={onRemoveClick} canRemove={demand.isActive} />
    </ProductListItemDemandItemLayoutDesktop>
  );
};
