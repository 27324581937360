import { useCallback, useEffect, useState } from "react";
import { LegalServicesProductId } from "../../common/types";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectLegalServicesCreation, selectLegalServicesCreationById } from "../store/legalServicesCreation.selectors";
import { legalServicesCreationThunks } from "../store/legalServicesCreation.thunks";
import { isLoading, isSucceeded } from "../../../common/types/state";
import { deleteClientSearchFoundClients } from "../../../client-search";

export type UseLegalServicesCreationPageStateType = "form" | "failed" | "loading" | "succeeded";

export const useLegalServicesCreationPageState = (productId: LegalServicesProductId) => {
  const dispatch = useAppDispatch();

  const { status, error } = useAppSelector(selectLegalServicesCreation);
  const product = useAppSelector((state) => selectLegalServicesCreationById(state, productId));

  const [state, setState] = useState<UseLegalServicesCreationPageStateType>(product !== undefined ? "form" : "loading");

  useEffect(() => {
    return () => {
      dispatch(deleteClientSearchFoundClients({ key: "LegalServices" }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (product === undefined) {
      dispatch(legalServicesCreationThunks.getProducts());
    }
  }, [dispatch, product]);

  useEffect(() => {
    setState((prev) => {
      if (prev === "form" || isSucceeded(status)) {
        return "form";
      }

      return isLoading(status) ? "loading" : "failed";
    });
  }, [status]);

  const onFormSubmitted = useCallback(() => setState("succeeded"), []);

  return { state, onFormSubmitted, error };
};
