import { DemandId } from "../../../common/types/demand";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { useBankGuaranteeExtractFromRegistry } from "../hooks/useBankGuaranteeExtractFromRegistry";
import { BankGuaranteeFileRegistryStatusType } from "../../common/types";
import { FileLabel } from "../../../common/ui-kit/labels";

interface BankGuaranteeExtractFromRegistryFileLabelProps extends BaseCssProps {
  demandId: DemandId;
  statusType: Extract<BankGuaranteeFileRegistryStatusType, "AvailableForDownload" | "Downloaded">;
}

export const BankGuaranteeExtractFromRegistryFileLabel = ({
  demandId,
  statusType,
  className,
}: BankGuaranteeExtractFromRegistryFileLabelProps) => {
  const [status, extractFromRegistry] = useBankGuaranteeExtractFromRegistry(demandId, statusType);

  return (
    <FileLabel
      status={status}
      download={extractFromRegistry}
      fileName="Выписка из реестра БГ"
      isWithNotification={statusType === "AvailableForDownload"}
      className={className}
    />
  );
};
