import { useField } from "formik";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { FormCheckbox } from "@sideg/ui-kit/atoms/checkbox";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { Typography } from "../../../../../common/ui-kit/typography";
import { ScoringToggleBankButton } from "../../../../../common/ui/buttons/scoring-toogle";
import {
  ProductCreationScoringResultSuccessRowContainer,
  ProductCreationScoringResultSuccessRowInput,
  ProductCreationScoringResultSuccessRowInputError,
  ProductCreationScoringSumLimitRange,
  useCreationCreditScoringBankField,
} from "../../../../../common/product-creation";
import { CreditExecutionCreationBankScoringResult } from "../../../types/scoring/CreditExecutionCreationBankScoringResult";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { useGetFieldError } from "../../../../../common/form";

export interface CreditExecutionCreationScoringResultSuccessRowProps extends BaseCssProps {
  scoringResult: CreditExecutionCreationBankScoringResult;
  creditSum?: number;
}

export const CreditExecutionCreationScoringResultSuccessRow = ({
  className,
  scoringResult,
  creditSum,
}: CreditExecutionCreationScoringResultSuccessRowProps) => {
  const { isSelected, isAutoFocusOnSumInput, toggleBank, onLimitSet, getNestedFieldName } =
    useCreationCreditScoringBankField({ ...scoringResult, creditSum });

  const getFieldError = useGetFieldError();
  const [{ name }, { error, touched }] = useField(getNestedFieldName("creditSum"));
  const errorText = getFieldError(name, touched, error);

  return (
    <ProductCreationScoringResultSuccessRowContainer
      bank={
        <ThemeMobileDesktopView
          mobile={
            <Typography fontColor="typography.primary" fontWeight={500} lineHeight="defaultLineHeight" fontSize="fz4">
              {scoringResult.bank.title}
            </Typography>
          }
          desktop={
            <FormCheckbox
              checked={isSelected}
              onChange={toggleBank}
              label={
                <Typography
                  fontColor="typography.primary"
                  fontWeight={700}
                  lineHeight="defaultLineHeight"
                  fontSize="fz4"
                >
                  {scoringResult.bank.title}
                </Typography>
              }
            />
          }
        />
      }
      mobileCheck={<ScoringToggleBankButton isSelected={isSelected} onClick={toggleBank} />}
      sumLimit={
        <StackPanel direction="row" gap="md" alignItems="center">
          <ProductCreationScoringSumLimitRange
            onSumFromClick={onLimitSet}
            onSumToClick={onLimitSet}
            sumFrom={scoringResult.creditSumFrom}
            sumTo={scoringResult.creditSumTo}
          />
          <ThemeMobileDesktopView
            mobile={undefined}
            desktop={errorText && <ProductCreationScoringResultSuccessRowInputError error={errorText} />}
          />
        </StackPanel>
      }
      sumInput={
        isSelected && <ProductCreationScoringResultSuccessRowInput name={name} autoFocus={isAutoFocusOnSumInput} />
      }
      className={className}
    />
  );
};
