import { NavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { FullPageContainerCompound } from "../../../../common/ui/containers/full-page";

export interface UserChangePasswordPageHeaderProps {
  from: NavigationStateFrom;
}

export const UserChangePasswordPageHeader = ({ from }: UserChangePasswordPageHeaderProps) => {
  return (
    <FullPageContainerCompound.Header
      leftSide={<FullPageContainerCompound.HeaderBackLinkButton url={from.url} title={from.backTitle} />}
    >
      <FullPageContainerCompound.HeaderTypography>Изменить пароль</FullPageContainerCompound.HeaderTypography>
    </FullPageContainerCompound.Header>
  );
};
