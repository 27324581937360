import { CreditWorkingAssetsId, DemandId } from "../../../../common/types/demand";
import { useCreditWorkingAssetsRejectionSubmitHandler } from "../hooks/useCreditWorkingAssetsRejectionSubmitHandler";
import { ProductRejection } from "../../../../common/product-rejection";
import { AppLayoutOverlayPanelKey } from "../../../../common/ui/layout/app-layout/types";

export interface CreditWorkingAssetsRejectionDemandProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
  demandId: DemandId;
  afterSuccess: () => Promise<void>;
  isOpen: boolean;
  onClose: () => void | Promise<void>;
  groupProductName: string | number;
  bankName: string;
  panelKey?: AppLayoutOverlayPanelKey;
}

export const CreditWorkingAssetsRejectionDemand = ({
  creditWorkingAssetsId,
  demandId,
  afterSuccess,
  isOpen,
  onClose,
  groupProductName,
  bankName,
  panelKey,
}: CreditWorkingAssetsRejectionDemandProps) => {
  const { error, handleSubmit } = useCreditWorkingAssetsRejectionSubmitHandler(
    "demand",
    { creditWorkingAssetsId, demandId },
    afterSuccess,
  );

  return (
    <ProductRejection
      panelKey={panelKey}
      error={error}
      isOpen={isOpen}
      product="CreditWorkingAssets"
      onClose={onClose}
      title="Отмена заявки"
      description={`Будет отменена заявка № ${groupProductName} в ${bankName}`}
      submitHandler={handleSubmit}
    />
  );
};
