import { CreationNote } from "../../../../common/ui-kit/notifications/note";

export interface DemandQueueTaskActiveFormAsideCommentFieldProps {
  comment: string;
}

export const DemandQueueTaskActiveFormAsideCommentField = ({
  comment,
}: DemandQueueTaskActiveFormAsideCommentFieldProps) => {
  return (
    <CreationNote fontSize="fz2" fontWeight={500} lineHeight="1.25rem" withLineBreaks>
      {comment}
    </CreationNote>
  );
};
