import { currencyHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { BankGuaranteeDocumentsUniqueId } from "../../types/BankGuaranteeDocumentsUniqueId";
import { CreationBlockCard } from "../../../../common/ui-kit/cards/creation-block-card";
import { Box } from "../../../../common/ui-kit/containers/box";
import { Text } from "../../../../common/ui-kit/typography";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDocumentsItemById } from "../../store/bankGuaranteeDocuments.selectors";
import { FileDownloadLabel } from "../../../../files/download";
import {
  bankGuaranteeDocumentsClientTaxSystemType,
  BankGuaranteeDocumentsClientTaxSystemType,
} from "../../types/BankGuaranteeDocumentsClientTaxSystemType";
import { BankGuaranteeDocumentsListItemBadge } from "./BankGuaranteeDocumentsListItemBadge";

const CardBox = Box.withComponent(CreationBlockCard);

const getTaxSystemText = (taxSystems: BankGuaranteeDocumentsClientTaxSystemType[]): string => {
  return taxSystems.length === 1
    ? `${bankGuaranteeDocumentsClientTaxSystemType.getTitle(taxSystems[0])} система налогообложения`
    : `Система налогообложения: ${taxSystems
        .map((x) => bankGuaranteeDocumentsClientTaxSystemType.getTitle(x))
        .join(", ")}`;
};

export interface BankGuaranteeDocumentsListItemMobileProps extends BaseCssProps {
  id: BankGuaranteeDocumentsUniqueId;
}

export const BankGuaranteeDocumentsListItemMobile = ({ id, className }: BankGuaranteeDocumentsListItemMobileProps) => {
  const document = useAppSelector((state) => selectBankGuaranteeDocumentsItemById(state, id));

  return (
    <CardBox padding="md" display="flex" flexDirection="column" gap="sm" alignItems="flex-start" className={className}>
      {document?.isRequired && <BankGuaranteeDocumentsListItemBadge>Обязательный</BankGuaranteeDocumentsListItemBadge>}
      <Text fontWeight={700} lineHeight="defaultLineHeight" fontSize="fz4">
        {document?.name}
      </Text>
      {document?.taxSystems && <Text>{getTaxSystemText(document?.taxSystems)}</Text>}
      {(document?.sumGuaranteeFrom !== undefined || document?.sumGuaranteeTo !== undefined) && (
        <div>
          {document?.sumGuaranteeFrom !== undefined && (
            <Text as="div">Сумма БГ от {currencyHelper.getCurrencyString(document.sumGuaranteeFrom)}</Text>
          )}
          {document?.sumGuaranteeTo !== undefined && (
            <Text as="div">Сумма БГ до {currencyHelper.getCurrencyString(document.sumGuaranteeTo)}</Text>
          )}
        </div>
      )}
      {document?.template && (
        <FileDownloadLabel
          fileId={document.template.fileId}
          privateKey={document.template.privateKey}
          fileName="Шаблон"
        />
      )}
    </CardBox>
  );
};
