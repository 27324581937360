import styled from "@emotion/styled";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";

export const SimpleOpacityTransitionStyled = styled(CSSTransition)<CSSTransitionProps>`
  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
    transition: opacity ${({ timeout }) => timeout}ms ease-in;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity ${({ timeout }) => timeout}ms ease-in;
  }

  &.exit-done {
    opacity: 0;
  }
` as unknown as typeof CSSTransition;
