import { useMemo } from "react";
import { useAppSelector } from "../../../common/store";
import { selectBankGuaranteeDetailsDemandsAll } from "../store/demands/bankGuaranteeDetailsDemands.selectors";

export const useBankGuaranteeDetailsAllDemandsActions = () => {
  const demands = useAppSelector(selectBankGuaranteeDetailsDemandsAll);

  return useMemo(() => {
    const canSendMore = demands.every((x) => !x.isApproved) && demands.length > 0;
    const canRejectAll = canSendMore && demands.some((x) => x.isActive);

    return { canSendMore, canRejectAll };
  }, [demands]);
};
