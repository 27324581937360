import { useMemo } from "react";
import { useAppSelector } from "../../../../common/store";
import {
  bankGuaranteeCreationFromValidationSchema,
  getBankGuaranteeCreationFromValidationSchema,
} from "../../validation/bankGuaranteeCreationValidationSchema";
import { BankGuaranteeCreationFromValues } from "../../types/BankGuaranteeCreationFormValues";
import { selectBankGuaranteeCreation } from "../../store/bankGuaranteeCreation.selectors";
import { useSearchParamsOnce } from "../../../../common/filters";

export const useBankGuaranteeCreationFromInitialValues = (): BankGuaranteeCreationFromValues => {
  const queryParams = useSearchParamsOnce(bankGuaranteeCreationFromValidationSchema);

  const {
    creating: { data: formValues },
  } = useAppSelector(selectBankGuaranteeCreation);

  return useMemo(() => {
    return getBankGuaranteeCreationFromValidationSchema().cast(formValues ?? queryParams, {
      assert: false,
    }) as BankGuaranteeCreationFromValues;
  }, [formValues, queryParams]);
};
