import styled from "@emotion/styled";
import { FilterExpandButton } from "../../../../filters/inputs/buttons/FilterExpandButton";

export const Wrapper = styled.div`
  position: relative;
`;

export const Button = styled(FilterExpandButton)`
  width: 100%;
`;
