import Skeleton from "react-loading-skeleton";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { getEmptyArray } from "@sideg/helpers";
import { UserProfileInfoWrapper } from "./UserProfileInfoWrapper";
import {
  UserProfileInfoSkeletonPair,
  UserProfileInfoSkeletonPairSize,
} from "../../common/components/UserProfileInfoSkeletonPair";

const SKELETON_ROW = getEmptyArray(3);
const SKELETON_INPUT = getEmptyArray(2);

const ROW_SKELETONS_SIZE: UserProfileInfoSkeletonPairSize = {
  firstSkeletonWidth: 24,
  firstSkeletonHeight: 24,
  secondSkeletonWidth: 140,
  secondSkeletonHeight: 24,
};

const INPUT_SKELETONS_SIZE: UserProfileInfoSkeletonPairSize = {
  firstSkeletonWidth: 50,
  firstSkeletonHeight: 15,
  secondSkeletonWidth: "100%",
  secondSkeletonHeight: 35,
};

export const UserProfileInfoSkeletonForm = () => {
  const isDesktop = useThemeIsDesktop();
  const fullWidth = isDesktop ? 100 : "100%";

  return (
    <UserProfileInfoWrapper>
      <Skeleton width="15%" height={24} />
      {SKELETON_INPUT.map((x) => (
        <UserProfileInfoSkeletonPair {...INPUT_SKELETONS_SIZE} key={x} flexDirection="column" />
      ))}
      <Skeleton width={200} height={24} />
      {SKELETON_ROW.map((x) => (
        <UserProfileInfoSkeletonPair {...ROW_SKELETONS_SIZE} key={x} alignItems="center" />
      ))}
      <Skeleton width={fullWidth} height={40} />
    </UserProfileInfoWrapper>
  );
};
