import { clientV4 } from "../../../../common/api";
import { EmptyOutputDto } from "../../../../common/api/types";
import { CancelProductCreditExecutionInputDto } from "./dto/input/CancelProductCreditExecutionInputDto";
import { CancelDemandCreditExecutionInputDto } from "./dto/input/CancelDemandCreditExecutionInputDto";

const CONTROLLER_NAME = "CreditExecutions";

export const creditExecutionRejectionApi = {
  cancelProduct: ({ creditExecutionId, ...model }: CancelProductCreditExecutionInputDto) =>
    clientV4.put<typeof model, EmptyOutputDto>(`${CONTROLLER_NAME}/${creditExecutionId}/cancel`, model),
  cancelDemand: ({ creditExecutionId, demandId, ...model }: CancelDemandCreditExecutionInputDto) =>
    clientV4.put<typeof model, EmptyOutputDto>(`${CONTROLLER_NAME}/${creditExecutionId}/cancel/${demandId}`, model),
};
