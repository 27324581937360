import styled from "@emotion/styled";
import { ProductDetailsContentLayoutContainer } from "../../../../../../common/product-details";

export const ReconciliationContainer = styled(ProductDetailsContentLayoutContainer)`
  padding-bottom: ${({ theme }) => theme.spaces.md};
  margin-top: ${({ theme }) => theme.spaces.md};

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    padding-top: ${({ theme }) => theme.spaces.lg};
    border-top: 1px solid ${({ theme }) => theme.palette.control.secondaryLight};
  }
`;
