import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import * as S from "./BankGuaranteeDetailsDemandHeaderActiveReconciliation.styled";
import { BankGuaranteeDetailsDemandHeaderReconciliationProject } from "./project/BankGuaranteeDetailsDemandHeaderReconciliationProject";
import { BankGuaranteeDetailsDemandHeaderReconciliationBill } from "./bill/BankGuaranteeDetailsDemandHeaderReconciliationBill";

export interface BankGuaranteeDetailsDemandHeaderActiveReconciliationProps extends BaseCssProps {}

export const BankGuaranteeDetailsDemandHeaderActiveReconciliation = ({
  className,
}: BankGuaranteeDetailsDemandHeaderActiveReconciliationProps) => {
  return (
    <S.Container className={className}>
      <BankGuaranteeDetailsDemandHeaderReconciliationBill />
      <BankGuaranteeDetailsDemandHeaderReconciliationProject />
    </S.Container>
  );
};
