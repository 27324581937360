import { useState } from "react";
import { useAppSelector } from "../../../../../../common/store";
import { selectQueueTasksListFilterBanks } from "../../../../store/queueTasksList.selectors";
import { FormSearchInput } from "../../../../../../common/ui-kit/form-controls/search-input";
import { QueueTasksListMobileFiltersFormValues } from "../../../../types/filter/QueueTasksListMobileFiltersFormValues";
import { FilterFormMobileCheckboxesWithCollapse } from "../../../../../../common/filters/use-cases";

export const QueueTasksListFiltersBanksWithSearchContainer = () => {
  const [inputValue, setInputValue] = useState("");
  const banks = useAppSelector((state) => selectQueueTasksListFilterBanks(state, inputValue));

  return (
    <FilterFormMobileCheckboxesWithCollapse<QueueTasksListMobileFiltersFormValues>
      title="Банки"
      fieldName="banks"
      items={banks}
      beforeList={
        <FormSearchInput
          placeholder="Наименование банка"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          spellCheck={false}
          autoComplete="off"
          autoCorrect="off"
        />
      }
    />
  );
};
