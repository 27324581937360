import { number, object, ref, string } from "yup";
import {
  getEnumLikeLiteralTypeSchema,
  getFileArraySchema,
  getFormattedPhoneSchema,
  getLazyObjectWithDynamicKeysSchema,
  getTaxpayerNumberSchema,
} from "../../../common/validation";
import { clientTaxSystemType } from "../../../common/types/demand";

export const legalServicesCreationDocumentValidationSchema = object({
  minLength: number().required(),
  docType: string().required(),
  files: getFileArraySchema().min(ref("minLength"), "Документ обязательный — необходимо прикрепить файл"),
});

export const legalServicesCreationValidationSchema = object({
  clientId: number().required("Не забудьте заполнить"),
  // Для совместимости с фичей поиска
  clientTaxSystem: getEnumLikeLiteralTypeSchema(clientTaxSystemType).default("General"),
  taxpayerNumber: getTaxpayerNumberSchema().notRequired(),
  phoneNumber: getFormattedPhoneSchema().default(""),
  email: string()
    .default("")
    .trim("Укажите электронную почту")
    .email("Укажите корректную электронную почту")
    .required("Укажите электронную почту"),
  fullName: string().trim().default("").min(2, "Укажите фамилию, имя и отчество"),
  comment: string().trim().default("").notRequired(),
  documents: getLazyObjectWithDynamicKeysSchema(legalServicesCreationDocumentValidationSchema),
});
