import { createApiClient } from "./createApiClient";
import { createPublicApiClient } from "../utils";

export const clientV1 = createApiClient("v1");

export const clientV2 = createApiClient("v2");

export const clientV3 = createApiClient("v3");

export const clientV4 = createApiClient("v4");

export const publicClientV4 = createPublicApiClient("v4");
