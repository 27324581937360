import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../../common/types/utils";

const titles = {
  tasks: "Сообщения",
  documents: "Документы",
  reissues: "Перевыпуски",
};

export const bankGuaranteeDetailsDemandHeaderTab = makeEnumLikeLiteral(
  ["tasks", "documents", "reissues"] as const,
  titles
);

export type BankGuaranteeDetailsDemandHeaderTab = EnumLikeLiteralType<typeof bankGuaranteeDetailsDemandHeaderTab>;
