import { useCallback, useEffect, useState } from "react";
import { useBankGuaranteeCreationForm } from "./useBankGuaranteeCreationForm";
import { federalLawRules } from "../../../common/validation";
import { useBankGuaranteeCreationSetFederalField } from "./fields/useBankGuaranteeCreationSetFederalField";
import { federalLawType, FederalLawType, FederalLawTypeWithCommerce } from "../../../../common/types/demand";

export const useBankGuaranteeCreationFederalLawTypes = () => {
  const [types, setTypes] = useState<FederalLawType[]>(federalLawType.values);

  const {
    values: { federalLaw, isSingleSupplier, isClosedAuction, guaranteeType },
  } = useBankGuaranteeCreationForm();

  const [, { setValue }] = useBankGuaranteeCreationSetFederalField();

  const setAvailableValue = useCallback(
    async (values: FederalLawTypeWithCommerce[]) => {
      if (!values.includes(federalLaw)) {
        await setValue(values[0]);
      }
    },
    [federalLaw, setValue],
  );

  useEffect(() => {
    if (federalLawRules.closedAuctionAndSingleSupplier.condition(isSingleSupplier, isClosedAuction)) {
      setTypes(federalLawRules.closedAuctionAndSingleSupplier.collection as FederalLawType[]);
      setAvailableValue(federalLawRules.closedAuctionAndSingleSupplier.collection);
    } else if (federalLawRules.paymentGuaranteeType.condition(guaranteeType)) {
      setAvailableValue(federalLawRules.paymentGuaranteeType.collection);
    } else {
      setTypes(federalLawType.values);
    }
  }, [guaranteeType, isClosedAuction, isSingleSupplier, setAvailableValue]);

  return types;
};
