import * as React from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { DocumentUploadActiveDocumentSkeleton } from "../../active-document/DocumentUploadActiveDocument.skeleton";
import { DocumentUploadCommonBankDocumentCardBanksSkeleton } from "../banks/DocumentUploadCommonBankDocumentCardBanks.skeleton";
import { DocumentUploadCommonBankDocumentContainer } from "../DocumentUploadCommonBankDocumentContainer";

export interface DocumentUploadCommonBankDocumentCardSkeletonProps extends BaseCssProps {
  children?: React.ReactNode;
  isWithBadge?: boolean;
  banksCount: number;
}

export const DocumentUploadCommonBankDocumentCardSkeleton = ({
  children,
  className,
  banksCount,
  isWithBadge,
}: DocumentUploadCommonBankDocumentCardSkeletonProps) => {
  return (
    <DocumentUploadCommonBankDocumentContainer
      className={className}
      footer={<DocumentUploadCommonBankDocumentCardBanksSkeleton banksCount={banksCount} />}
    >
      <DocumentUploadActiveDocumentSkeleton isWithBadge={isWithBadge}>{children}</DocumentUploadActiveDocumentSkeleton>
    </DocumentUploadCommonBankDocumentContainer>
  );
};
