import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { AppUrlTypeEnum } from "../../../common/urls/internal/types";
import { PUBLIC_USER_URL_ROOT_PATH } from "../../common/urls";
import { makePublicOrCommonUrl } from "../../../common/urls/internal/utils/makeAppUrl";

export const publicUserAuthenticationUrls = {
  login: makePublicOrCommonUrl(
    joinUrlParts(PUBLIC_USER_URL_ROOT_PATH, "login"),
    "/account/login",
    AppUrlTypeEnum.Public,
  ),
  logout: makeAppUrl(joinUrlParts(PUBLIC_USER_URL_ROOT_PATH, "logout"), "/account/logout", true),
};
