import { ChatDialogMessageId } from "../../types/ChatDialogMessageId";
import { useAppSelector } from "../../../../common/store";
import { selectChatDialogMessageById } from "../../store/chatDialog.selectors";
import { ChatDialogMessagesGroupDate } from "./ChatDialogMessagesGroupDate";

export interface ChatDialogMessagesGroupDateForMessageProps {
  messageId: ChatDialogMessageId;
  className?: string;
}

export const ChatDialogMessagesGroupDateForMessage = ({
  messageId,
  className,
}: ChatDialogMessagesGroupDateForMessageProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const message = useAppSelector((state) => selectChatDialogMessageById(state, messageId))!;

  return <ChatDialogMessagesGroupDate date={message.dateAdded} className={className} />;
};
