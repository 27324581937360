import { useMemo } from "react";
import {
  makeProductListCardMoreAction,
  ProductListCardMoreActionWithHandler,
} from "../../../common/ui/product/list/card";
import { CreditExecutionListItem } from "../types/items/CreditExecutionListItem";
import { NavigationState } from "../../../common/navigation/navigation-state";
import { PlusIcon, TrashIcon } from "../../../common/ui-kit/icons";
import { useAppDispatch } from "../../../common/store";
import { useAppNavigation } from "../../../common/urls/internal/hooks";
import { onProductRejectCreditExecutionListItemModification } from "../store/item-modification/creditExecutionListItemModification.slice";

export const useCreditExecutionListItemMoreActions = (
  product: CreditExecutionListItem,
  from?: NavigationState
): ProductListCardMoreActionWithHandler[] => {
  const dispatch = useAppDispatch();
  const navigator = useAppNavigation();

  return useMemo(() => {
    const items: ProductListCardMoreActionWithHandler[] = [];

    items.push({
      ...makeProductListCardMoreAction("send-more", "Отправить в другие банки", PlusIcon),
      handler: () => {
        navigator(
          (x) => x.creditExecution.creation.createByCreditExecution,
          {
            creditExecutionId: product.creditExecutionId,
          },
          { state: from }
        );
      },
    });

    if (product.canRejectAll) {
      items.push({
        ...makeProductListCardMoreAction("remove-product", "Отменить заявку", TrashIcon),
        handler: () => {
          dispatch(
            onProductRejectCreditExecutionListItemModification({
              creditExecutionId: product.creditExecutionId,
            })
          );
        },
      });
    }

    return items;
  }, [dispatch, from, navigator, product]);
};
