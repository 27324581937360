import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeCreationCreating } from "../../store/bankGuaranteeCreation.selectors";
import { isSucceeded } from "../../../../common/types/state";
import { changeStageBankGuaranteeCreation } from "../../store/bankGuaranteeCreation.slice";

export const useBankGuaranteeCreationChangeScoringStageEffect = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectBankGuaranteeCreationCreating);

  useEffect(() => {
    if (isSucceeded(status)) {
      dispatch(changeStageBankGuaranteeCreation("scoring"));
    }
  }, [dispatch, status]);
};
