import { useMemo } from "react";
import { useResponsiveContext } from "@sideg/ui-kit/core/responsive/hooks/useResponsiveContext";
import { Breakpoints, breakpoints } from "@sideg/ui-kit/common/types/responsive/Breakpoints";

type ResultFunc = (size: Breakpoints, include?: boolean) => boolean;

export type UseResponsiveRangeReturnType = {
  higherThan: ResultFunc;
  lowerThan: ResultFunc;
};

interface UseSelectPopupInterface {
  (): UseResponsiveRangeReturnType;
}

export const useResponsiveRange: UseSelectPopupInterface = () => {
  const { actualSize } = useResponsiveContext();

  return useMemo(() => {
    const getResult = (size: Breakpoints, include: boolean, isLower: boolean) => {
      if (actualSize === "initial") {
        return false;
      }

      const sizeIndex = breakpoints.indexOf(size);
      const actualIndex = breakpoints.indexOf(actualSize);

      if (isLower) {
        return include ? actualIndex <= sizeIndex : actualIndex < sizeIndex;
      }

      return include ? actualIndex >= sizeIndex : actualIndex > sizeIndex;
    };

    return {
      higherThan: (size, include = false) => getResult(size, include, false),
      lowerThan: (size, include = false) => getResult(size, include, true),
    };
  }, [actualSize]);
};
