import { Link as RouterLink } from "react-router-dom";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { DemandId, ProductId, ProductType } from "../../../../types/demand";
import { useProductUrl } from "../../../../urls/internal/hooks";
import { BaseCssProps } from "../../../../ui-kit/types";
import { ScoringToggleButton } from "./ScoringToggleButton";

export interface ScoringToggleDemandLinkButtonProps extends BaseCssProps {
  productId: ProductId;
  demandId: DemandId;
  productType: ProductType;
}

const Link = ScoringToggleButton.withComponent(RouterLink);

export const ScoringToggleDemandLinkButton = ({
  productType,
  productId,
  demandId,
  className,
}: ScoringToggleDemandLinkButtonProps) => {
  const url = useProductUrl(productType, demandId, productId);

  return (
    <Link className={className} to={url}>
      <ThemeMobileDesktopView mobile="К заявке" desktop="Заявка →" />
    </Link>
  );
};
