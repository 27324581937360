import { useCallback } from "react";
import { RootState, useAppSelector } from "../../../store";
import { FilterTabCounters } from "../types/FilterTabCounters";
import { FilterTabType } from "../types/FilterTabType";

export type UseFiltersTabCounterGetValueStateSelector = (state: RootState) => FilterTabCounters | undefined;

export const useFiltersTabCounterGetValue = (selector: UseFiltersTabCounterGetValueStateSelector) => {
  const data = useAppSelector(selector);

  return useCallback(
    (tab: FilterTabType) => {
      if (!data) {
        return undefined;
      }

      switch (tab) {
        case "Active":
          return data.countActive > 0 ? data.countActive : undefined;
        case "Completed":
          return data.countCompleted > 0 ? data.countCompleted : undefined;
        default:
          return undefined;
      }
    },
    [data]
  );
};
