import styled from "@emotion/styled";
import { Flex } from "@sideg/ui-kit/atoms/containers";

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RadioButtonsWrapper = styled(Flex)<{
  itemsPlacement?: "center" | "start";
  isFullWidth?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  justify-content: ${({ itemsPlacement }) => (itemsPlacement === "start" ? "flex-start" : "center")};

  padding: ${({ theme }) => theme.spacing.xs};
  border: 1px solid ${({ theme }) => theme.palette.light.neutral.outline.secondary};
  border-radius: ${({ theme }) => theme.radii.control};

  background-color: ${({ theme, disabled }) => (disabled ? theme.palette.light.neutral.bgr.tertiary : "transparent")};

  & > * {
    flex: ${({ isFullWidth }) => (isFullWidth ? "1 1" : "0 0 auto")};
  }
`;
