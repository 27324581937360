import { StatusCodeEnum } from "../../services/fetchClient";
import { ValidationErrors } from "./ValidationErrors";
import { RawApiResponse } from "./RawApiResponse";
import { RawWebApiResponse } from "./RawWebApiResponse";

export interface ApiError<TRequest = unknown> extends BaseApiError {
  validationErrors?: ValidationErrors<TRequest>;
}

export interface BaseApiError {
  status?: StatusCodeEnum;
  rawText?: string;
  message?: string;
  isAborted?: boolean;
  isApiError: boolean;
}

export const createApiError = <TRequest>(
  data: RawApiResponse<unknown> | ApiError<TRequest> | BaseApiError | string,
): ApiError<TRequest> | BaseApiError => {
  if (typeof data === "string") {
    return { message: data, isApiError: false };
  }

  const error = data as ApiError<TRequest> | BaseApiError;
  if (error.isApiError !== undefined) {
    return error;
  }

  return { status: (data as BaseApiError).status ?? (data as RawApiResponse<unknown>).code, isApiError: true };
};

export const createApiErrorFromRawWebApiResponse = <TRequest>(
  response: RawWebApiResponse<unknown, TRequest>,
  status?: StatusCodeEnum,
  rawText?: string,
): ApiError<TRequest> => {
  return {
    status,
    rawText,
    isApiError: true,
    message: response.errors?.message,
    validationErrors: response.errors?.fields,
  };
};

export const isBaseApiError = (error: unknown): error is BaseApiError => {
  return (error as BaseApiError)?.isApiError === true;
};

export const isApiError = <TResponse>(error: unknown): error is ApiError<TResponse> => {
  return isBaseApiError(error) && (error as ApiError<TResponse>)?.validationErrors !== undefined;
};
