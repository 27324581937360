import { forwardRef, MouseEventHandler } from "react";
import { useQueueTasksCountersNewCount } from "../../../counters";
import { BellIcon, CancelIcon, DoneIcon, Icon } from "../../../../common/ui-kit/icons";
import * as S from "./QueueTasksNotificationsBellButton.styled";
import { useReachGoalAnalytics } from "../../../../common/analytics/hooks";
import {
  isUserSideWebPushesSubscriptionState,
  useWebPushesSubscriptionStateContext,
} from "../../../../common/web-pushes";
import { useQueueTasksNotificationsBellButtonNeedAnimate } from "../../hooks/useQueueTasksNotificationsBellButtonNeedAnimate";

export interface QueueTasksNotificationsBellButtonProps extends Omit<JSX.IntrinsicElements["button"], "ref"> {
  isActive: boolean;
}

export const QueueTasksNotificationsBellButton = forwardRef<HTMLButtonElement, QueueTasksNotificationsBellButtonProps>(
  ({ onClick, isActive, ...buttonProps }, ref) => {
    const count = useQueueTasksCountersNewCount();
    const subscriptionState = useWebPushesSubscriptionStateContext();
    const [needAnimate, setNeedAnimate] = useQueueTasksNotificationsBellButtonNeedAnimate(count);
    const reachGoal = useReachGoalAnalytics();

    const onButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
      reachGoal("QUEUE_TASKS_NOTIFICATION_BUTTON_CLICK");
      setNeedAnimate(false);
      onClick?.(e);
    };

    return (
      <S.Button
        onClick={onButtonClick}
        ref={ref}
        isActive={isActive}
        count={count}
        animate={needAnimate}
        {...buttonProps}
      >
        {isUserSideWebPushesSubscriptionState(subscriptionState) && (
          <S.IconContainer>
            {subscriptionState === "available" && <Icon as={CancelIcon} stroke="typography.primaryLight" />}
            {subscriptionState === "subscribed" && <Icon as={DoneIcon} stroke="status.success" />}
          </S.IconContainer>
        )}
        <BellIcon />
      </S.Button>
    );
  }
);
