import axios, {
  AxiosHeaderValue,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseTransformer,
  InternalAxiosRequestConfig,
} from "axios";
import { FetchClientInstance } from "./types/FetchClientInstance";
import { encodeQueryParams } from "../../utils/encodeQueryParams";
import { FetchRequestOptions } from "./types/FetchRequestOptions";
import { objectToFormData } from "../../utils/objectToFormData";
import { AuthFetchClientConfig } from "./types/AuthFetchClientConfig";
import { AuthRefreshResponseTypeEnum } from "./types/AuthRefreshResponseTypeEnum";

const reduceHeaders = (
  config: InternalAxiosRequestConfig,
  customHeaders?: Record<string, AxiosHeaderValue>,
): InternalAxiosRequestConfig => {
  // eslint-disable-next-line no-param-reassign
  config.headers = config.headers.concat(customHeaders);

  return config;
};

const concatResponseTransformers = (
  defaults: AxiosResponseTransformer | AxiosResponseTransformer[] | undefined,
  custom: AxiosResponseTransformer | undefined,
) => {
  return custom ? ([] as AxiosResponseTransformer[]).concat(defaults ?? [], custom) : defaults;
};

const mapRequestOptions = <TData>(
  axiosConfig: AxiosRequestConfig<TData>,
  options: FetchRequestOptions | undefined,
): AxiosRequestConfig<TData> => {
  if (!options) {
    return axiosConfig;
  }

  const result = { ...axiosConfig };
  const { headers, transformResponse, baseUrl, abortSignal, responseType, withCredentials } = options;
  result.baseURL = baseUrl;
  result.headers = { ...result.headers, ...headers };
  result.transformResponse = concatResponseTransformers(result.transformResponse, transformResponse);
  result.signal = abortSignal;
  result.responseType = responseType;
  result.withCredentials = withCredentials;

  return result;
};

const createRequestMethod = async <TRequest, TResponse>(
  instance: AxiosInstance,
  endpoint: string,
  axiosConfig: AxiosRequestConfig<TRequest>,
  fetchConfig: FetchRequestOptions | undefined,
): Promise<TResponse> => {
  const response = await instance.request({
    url: endpoint,
    ...mapRequestOptions(axiosConfig, fetchConfig),
  });

  return response.data;
};

const mapAxiosMethodsToFetchClient = (instance: AxiosInstance): Omit<FetchClientInstance, "baseUrl"> => {
  return {
    get: (endpoint, queryParams, options) =>
      createRequestMethod(
        instance,
        queryParams ? `${endpoint}?${encodeQueryParams(queryParams)}` : endpoint,
        { method: "get" },
        options,
      ),
    post: (endpoint, body, options) => createRequestMethod(instance, endpoint, { data: body, method: "post" }, options),
    postForm: (endpoint, body, options) =>
      createRequestMethod(
        instance,
        endpoint,
        {
          data: objectToFormData(body, { allowEmptyArrays: true, indices: true }),
          method: "post",
        },
        options,
      ),
    put: (endpoint, body, options) => createRequestMethod(instance, endpoint, { data: body, method: "put" }, options),
    putForm: (endpoint, body, options) =>
      createRequestMethod(
        instance,
        endpoint,
        {
          data: objectToFormData(body, { allowEmptyArrays: true, indices: true, decimalSeparator: "," }),
          method: "put",
        },
        options,
      ),
    delete: (endpoint, queryParams, options) =>
      createRequestMethod(
        instance,
        queryParams ? `${endpoint}?${encodeQueryParams(queryParams)}` : endpoint,
        { method: "delete" },
        options,
      ),
  };
};

const createAxiosInstance = (options: FetchRequestOptions): AxiosInstance => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { headers, ...rest } = axios.defaults;
  const instance = axios.create(mapRequestOptions(rest, options));

  instance.interceptors.response.use(undefined, async (error) => {
    const isAborted = axios.isCancel(error);

    return Promise.reject(options.transformReject ? options.transformReject(error, isAborted) : error);
  });

  return instance;
};

/**
 * @deprecated Экспорт сделан для обратной совместимости со старым решением.
 * Нужно использовать клиенты-обертки
 */
export const createAxiosJwtInstance = (options: AuthFetchClientConfig): AxiosInstance => {
  const { transformReject, ...rest } = options;
  const instance = createAxiosInstance(rest);

  instance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (options.isTokenExpired()) {
      await options.updateAuthorizationData();
    }

    return reduceHeaders(config, options.getAuthorizationHeaders());
  });

  type PatchedAxiosConfig = AxiosRequestConfig & { _isAuthRetry?: boolean };
  type PatchedAxiosResponse = AxiosResponse & { config: PatchedAxiosConfig };

  const isAuthRetry = async (config: PatchedAxiosConfig) => {
    // eslint-disable-next-line no-underscore-dangle
    if (!config?._isAuthRetry && (await options.updateAuthorizationData()) !== AuthRefreshResponseTypeEnum.AuthError) {
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      config._isAuthRetry = true;

      return true;
    }

    return false;
  };

  // Обработка 200 ответа, в теле которого может лежать 401.
  if (options.isAlwaysSuccessAuthorizationFailed !== undefined) {
    instance.interceptors.response.use(async (response: PatchedAxiosResponse) => {
      if (options.isAlwaysSuccessAuthorizationFailed?.(response.data)) {
        if (await isAuthRetry(response.config)) {
          return instance(response.config);
        }

        await options.onRequestAuthorizationFailed();
      }

      return response;
    });
  }

  instance.interceptors.response.use(undefined, async (error) => {
    if (options.isAuthorizationFailed(error.response?.status)) {
      if (await isAuthRetry(error.config)) {
        return instance(error.config);
      }

      await options.onRequestAuthorizationFailed();
    }

    const isAborted = axios.isCancel(error);

    return Promise.reject(transformReject ? transformReject(error, isAborted) : error);
  });

  return instance;
};

export const createJwtClient = (options: AuthFetchClientConfig): FetchClientInstance => {
  const instance = createAxiosJwtInstance(options);

  return {
    baseUrl: options.baseUrl,
    ...mapAxiosMethodsToFetchClient(instance),
  };
};

export const createClient = (options: FetchRequestOptions): FetchClientInstance => {
  const instance = createAxiosInstance(options);

  return {
    baseUrl: options.baseUrl,
    ...mapAxiosMethodsToFetchClient(instance),
  };
};
