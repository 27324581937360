import { useCallback, useMemo, useState } from "react";

export const useCreationScoringSelectBanksState = () => {
  const [selectedBanks, setSelectedBanks] = useState([] as string[]);

  const toggleBank = useCallback((bankId: string) => {
    setSelectedBanks((prevState) => {
      if (prevState.includes(bankId)) {
        return prevState.filter((x) => x !== bankId);
      }

      return [bankId, ...prevState];
    });
  }, []);

  return useMemo(
    () => ({
      selectedBanks,
      setSelectedBanks,
      toggleBank,
    }),
    [selectedBanks, toggleBank]
  );
};
