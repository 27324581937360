import { NavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { useBackLinkHeaderAction } from "../../../../common/ui/layout/header";

export interface UserChangePasswordPageHeaderProps {
  from: NavigationStateFrom;
}

export const UserWebPushesPageHeaderMobile = ({ from }: UserChangePasswordPageHeaderProps) => {
  useBackLinkHeaderAction({ url: from.url });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
