import * as React from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { ProductCreationScoringResultRow } from "../ProductCreationScoringResultRow";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { Typography } from "../../../../../ui-kit/typography";

export interface ProductCreationScoringResultRowDuplicateContainerProps extends BaseCssProps {
  bankName: React.ReactNode;
  action: React.ReactNode;
}

export const ProductCreationScoringResultRowDuplicateContainer = ({
  bankName,
  action,
  className,
}: ProductCreationScoringResultRowDuplicateContainerProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <ProductCreationScoringResultRow.Container
      gap={{ xs: "none", [desktopBreakpoint]: "xs" }}
      className={className}
      isSelected={false}
    >
      <ProductCreationScoringResultRow.Item
        gridRow={1}
        gridColumn={{ xs: "1/span 11", [desktopBreakpoint]: "1/span 7" }}
      >
        <Typography fontColor="typography.primary" fontWeight={500} lineHeight="defaultLineHeight" fontSize="fz4">
          {bankName}
        </Typography>
      </ProductCreationScoringResultRow.Item>
      <ProductCreationScoringResultRow.Item
        gridRow={{ xs: 2, [desktopBreakpoint]: 1 }}
        gridColumn={{ xs: "1/span 11", [desktopBreakpoint]: "8/span 7" }}
      >
        <Typography fontColor="typography.medium" fontWeight={500} lineHeight="defaultLineHeight" fontSize="fz3">
          Дубль
        </Typography>
      </ProductCreationScoringResultRow.Item>
      <ProductCreationScoringResultRow.Item
        gridRow={{ xs: "1/span 2", [desktopBreakpoint]: 1 }}
        gridColumn={{ xs: "12/span 7", [desktopBreakpoint]: "15/span 4" }}
        justifySelf="end"
      >
        {action}
      </ProductCreationScoringResultRow.Item>
    </ProductCreationScoringResultRow.Container>
  );
};
