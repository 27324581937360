import { MouseEventHandler } from "react";
import * as React from "react";
import { BaseCssProps } from "../../../../ui-kit/types";
import * as S from "./WidgetsNewsHeaderButton.styled";

export interface WidgetsNewsHeaderButtonProps extends BaseCssProps {
  icon: React.ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const WidgetsNewsHeaderButton = ({ icon, onClick, disabled, className }: WidgetsNewsHeaderButtonProps) => {
  return (
    <S.Button onClick={onClick} disabled={disabled} className={className}>
      {icon}
    </S.Button>
  );
};
