import * as S from "./ChatDialogHeader.styled";
import { Text } from "../../../../common/ui-kit/typography";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { CancelIcon } from "../../../../common/ui-kit/icons";

export interface ChatDialogHeaderProps {
  onCloseClick: () => void | Promise<void>;
}

export const ChatDialogHeader = ({ onCloseClick }: ChatDialogHeaderProps) => {
  return (
    <S.Wrapper>
      <StackPanel gap="sm" direction="column">
        <Text fontColor="primaryLight" fontWeight={500} fontSize="fz4">
          Чат с менеджером
        </Text>
      </StackPanel>
      <S.Button onClick={onCloseClick}>
        <CancelIcon />
      </S.Button>
    </S.Wrapper>
  );
};
