import { useCallback, useMemo } from "react";
import { CreditExecutionId } from "../../../../common/types/demand";
import { useAppDispatch } from "../../../../common/store";
import { pushSuccessGlobalToast } from "../../../../global/toasts";
import { useCreditExecutionListFetchSingleItem } from "./useCreditExecutionListFetchSingleItem";
import { useProductListItemModificationOnClose } from "../../../../common/ui/product/list/item-modification";
import { useCreditExecutionListFilterContext } from "../filters/useCreditExecutionListFilterContext";
import { cancelModificationCreditExecutionListModification } from "../../store/item-modification/creditExecutionListItemModification.slice";

export const useCreditExecutionListItemModificationView = (
  creditExecutionId: CreditExecutionId,
  modalState: readonly [boolean, (x: boolean) => void],
  successMessage: string
) => {
  const dispatch = useAppDispatch();
  const [isModalOpen] = modalState;

  const afterClose = useCallback(() => {
    dispatch(cancelModificationCreditExecutionListModification());
  }, [dispatch]);

  const onClose = useProductListItemModificationOnClose(modalState, afterClose);
  const fetchItem = useCreditExecutionListFetchSingleItem();
  const filteringObject = useCreditExecutionListFilterContext();

  return useMemo(() => {
    const onSuccess = async () => {
      onClose();
      dispatch(pushSuccessGlobalToast(successMessage));
      await fetchItem(creditExecutionId, filteringObject);
    };

    return {
      isModalOpen,
      closeModal: onClose,
      onSuccess,
    };
  }, [creditExecutionId, dispatch, fetchItem, filteringObject, isModalOpen, onClose, successMessage]);
};
