import { CreditExecutionListItemStageCounter } from "./CreditExecutionListItemStageCounter";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { ProductListItemStageCountersContainer } from "../../../../../common/ui/product/stage";
import { CreditExecutionBase, creditExecutionDemandStageType } from "../../../../common/types";

export interface CreditExecutionListItemStageCountersProps extends BaseCssProps {
  counters: CreditExecutionBase["counters"];
}

export const CreditExecutionListItemStageCounters = ({
  className,
  counters,
}: CreditExecutionListItemStageCountersProps) => {
  return (
    <ProductListItemStageCountersContainer className={className}>
      {creditExecutionDemandStageType.values
        .filter((x) => !!counters[x])
        .map((x) => (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <CreditExecutionListItemStageCounter key={x} stage={x} count={counters[x]!} />
        ))}
    </ProductListItemStageCountersContainer>
  );
};
