import { forwardRef, useState } from "react";
import { usePhoneNumberInput } from "@sideg/ui-kit/atoms/form-controls/phone-number-input/hooks/usePhoneNumberInput";
import * as S from "@sideg/ui-kit/atoms/form-controls/phone-number-input/components/PhoneNumberInput.styled";
import {
  FormNumberFormatInput,
  FormNumberFormatInputProps,
} from "@sideg/ui-kit/atoms/form-controls/number-format-input";

export interface PhoneNumberInputProps extends Omit<FormNumberFormatInputProps, "type" | "onValueChange"> {}

export const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  ({ onBlur, onFocus, size = "default", ...rest }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const { format, isAllowed, onValueChange, maskPlaceholder } = usePhoneNumberInput();

    const handleFocus: PhoneNumberInputProps["onFocus"] = (...args) => {
      setIsFocused(true);
      onFocus?.(...args);
    };

    const handleBlur: PhoneNumberInputProps["onBlur"] = (...args) => {
      setIsFocused(false);
      onBlur?.(...args);
    };

    return (
      <FormNumberFormatInput
        ref={ref}
        type="tel"
        format={format}
        size={size}
        beforeInput={isFocused && <S.MaskPlaceholder inputSize={size}>{maskPlaceholder}</S.MaskPlaceholder>}
        isAllowed={isAllowed}
        autoComplete="tel"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onValueChange={onValueChange}
        {...rest}
      />
    );
  },
);
