import { createSlice, isAnyOf, isAsyncThunkAction } from "@reduxjs/toolkit";
import { CreditWorkingAssetsDetailsProductDetailsState } from "./creditWorkingAssetsDetailsProductDetails.types";
import { getLoadingStatusFromAction, isAborted, LoadingStatusEnum } from "../../../../common/types/state";
import { combineSliceNames } from "../../../../common/store/utils";
import {
  CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME,
  creditWorkingAssetsDetailsActiveProductChanged,
} from "../creditWorkingAssetsDetails.actions";
import {
  getCreditWorkingAssetsDetails,
  uploadGroupDocumentCreditWorkingAssetsDetails,
} from "../creditWorkingAssetsDetails.thunks";
import { creditWorkingAssetsDetailsProductDetailsMapper } from "./creditWorkingAssetsDetailsProductDetails.mapper";
import { isCreditWorkingAssetsDetailsNeedReloadMatcher } from "../creditWorkingAssetsDetails.matchers";

const initialState: CreditWorkingAssetsDetailsProductDetailsState = {
  status: LoadingStatusEnum.Idle,
  data: undefined,
  isTransferred: false,
  lastSuccessRequestId: undefined,
};

const slice = createSlice({
  name: combineSliceNames(CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME, "product-details"),
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(creditWorkingAssetsDetailsActiveProductChanged, (state, action) => {
        if (action.payload !== undefined) {
          return {
            ...initialState,
            data: creditWorkingAssetsDetailsProductDetailsMapper.fromTransfer(action.payload),
            isTransferred: true,
          };
        }

        return initialState;
      })
      .addMatcher(
        isAnyOf(uploadGroupDocumentCreditWorkingAssetsDetails.fulfilled, getCreditWorkingAssetsDetails.fulfilled),
        (state, { payload, meta }) => {
          state.data = creditWorkingAssetsDetailsProductDetailsMapper.fromResponse(payload.body);
          state.isTransferred = false;
          state.lastSuccessRequestId = meta.requestId;
        }
      )
      .addMatcher(isCreditWorkingAssetsDetailsNeedReloadMatcher, (state) => {
        state.status = LoadingStatusEnum.Idle;
      })
      .addMatcher(isAsyncThunkAction(getCreditWorkingAssetsDetails), (state, action) => {
        const status = getLoadingStatusFromAction(action);
        if (!isAborted(status)) {
          state.status = status;
        }
      });
  },
});

export const creditWorkingAssetsDetailsProductDetailsReducer = slice.reducer;
