import { useState } from "react";
import { currencyHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { BankGuaranteeCalculatorBankResult } from "../types/BankGuaranteeCalculatorBankResult";
import * as S from "./BankGuaranteeCalculatorResultTable.styled";
import { useBankGuaranteeCalculatorOrderedResult } from "../hooks/useBankGuaranteeCalculatorOrderedResult";
import { BankGuaranteeCalculatorOrderFiled } from "../types/BankGuaranteeCalculatorOrderFiled";
import { FilteringBaseOrderType } from "../../../common/filters";
import { SortIcon } from "../../../common/ui-kit/icons";
import { Box } from "../../../common/ui-kit/containers/box";

export interface BankGuaranteeCalculatorResultTableProps extends BaseCssProps {
  values: BankGuaranteeCalculatorBankResult[];
}

export const BankGuaranteeCalculatorResultTable = ({ values }: BankGuaranteeCalculatorResultTableProps) => {
  const [orderField, setOrderField] = useState<BankGuaranteeCalculatorOrderFiled>("bankName");
  const [orderType, setOrderType] = useState<FilteringBaseOrderType>("asc");
  const collection = useBankGuaranteeCalculatorOrderedResult(values, orderField, orderType);

  const switchOrder = (field: BankGuaranteeCalculatorOrderFiled) => () => {
    if (orderField === field) {
      setOrderType((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setOrderType("asc");
      setOrderField(field);
    }
  };

  return (
    <S.Table>
      <thead>
        <tr>
          <th onClick={switchOrder("bankName")}>
            <Box display="flex" alignItems="center">
              Банк
              <S.Icon isActive={orderField === "bankName"} order={orderType}>
                <SortIcon />
              </S.Icon>
            </Box>
          </th>
          <th onClick={switchOrder("price")}>
            <Box display="flex" alignItems="center">
              Стоимость
              <S.Icon isActive={orderField === "price"} order={orderType}>
                <SortIcon />
              </S.Icon>
            </Box>
          </th>
        </tr>
      </thead>
      <tbody>
        {collection.map((x) => (
          <tr key={x.bankCode}>
            <td>{x.bankName}</td>
            <td>{currencyHelper.getCurrencyStringNullable(x.price, "-")}</td>
          </tr>
        ))}
      </tbody>
    </S.Table>
  );
};
