import * as React from "react";
import * as S from "./ProductListCardContainer.mobile.styled";
import { BaseCssProps } from "../../../../../../../ui-kit/types";

export interface ProductListCardContainerMobileProps extends BaseCssProps {
  header: React.ReactNode;
  children?: React.ReactNode;
  footer: React.ReactNode;
}

export const ProductListCardContainerMobile = ({
  header,
  children,
  footer,
  className,
}: ProductListCardContainerMobileProps) => {
  return (
    <S.Article className={className}>
      <S.Header>
        {header}
        <S.Separator />
      </S.Header>
      <S.Main>{children}</S.Main>
      <S.Footer>{footer}</S.Footer>
    </S.Article>
  );
};
