import { Key } from "react";
import { CheckIcon, Icon } from "../../../../icons";
import { BaseCssProps } from "../../../../types";
import { SelectPopupContainerItemJustified } from "../SelectPopupContainerItemJustified";
import { useFilterSelectContainerMinWidth } from "../../hooks/useFilterSelectContainerMinWidth";
import * as S from "./FilterSelect.styled";
import { SelectPopupContainer } from "../SelectPopupContainer";
import { useSelectPopupMemo } from "../../hooks/useSelectPopup";

export interface FilterSelectProps<TItem> extends BaseCssProps {
  selected?: TItem | null;
  getItemTitle: (item: TItem) => string;
  onSelectedChanged: (selectedItem: TItem) => void | Promise<void>;
  items: TItem[];
  text: string;
  maxItemsLength?: number;
}

export const FilterSelect = <TItem,>({
  selected,
  getItemTitle,
  onSelectedChanged,
  text,
  items,
  className,
  maxItemsLength,
}: FilterSelectProps<TItem>) => {
  const { isOpen, selectedItem, highlightedIndex, getItemProps, referenceElementProps, referenceProps, floatingProps } =
    useSelectPopupMemo({
      selectedItem: selected,
      items,
      circularNavigation: true,
      offset: [0, 2],
      position: "bottom-start",
      onSelectedItemChange: (x) => {
        x.selectedItem && onSelectedChanged(x.selectedItem);
      },
    });

  const minWidth = useFilterSelectContainerMinWidth(referenceElementProps, maxItemsLength);

  return (
    <S.Wrapper className={className}>
      <S.Button {...referenceProps} opened={isOpen}>
        {text}
      </S.Button>
      <SelectPopupContainer {...floatingProps} minWidth={minWidth} open={isOpen}>
        {isOpen &&
          items.map((item, index) => {
            return (
              <SelectPopupContainerItemJustified
                key={item as Key}
                active={item === selectedItem}
                highlighted={index === highlightedIndex}
                {...getItemProps({ item, index })}
              >
                <span>{getItemTitle(item)}</span>
                {item === selectedItem && <Icon as={CheckIcon} stroke="success" />}
              </SelectPopupContainerItemJustified>
            );
          })}
      </SelectPopupContainer>
    </S.Wrapper>
  );
};
