import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { CreditWorkingAssetsListHeaderFiltersDesktop } from "./desktop/CreditWorkingAssetsListHeaderFilters.desktop";
import { CreditWorkingAssetsListHeaderFiltersMobile } from "./mobile/CreditWorkingAssetsListHeaderFilters.mobile";
import { BaseCssProps } from "../../../../../common/ui-kit/types";

export type CreditWorkingAssetsListHeaderFiltersProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFilters = ({ className }: CreditWorkingAssetsListHeaderFiltersProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={<CreditWorkingAssetsListHeaderFiltersMobile className={className} />}
      desktop={<CreditWorkingAssetsListHeaderFiltersDesktop className={className} />}
    />
  );
};
