import { numberHelper } from "@sideg/helpers";
import LocalStorageService from "./LocalStorageService";

// eslint-disable-next-line import/no-default-export
export default class LocalStorageExpiresTimeService {
  private readonly localStorage: LocalStorageService;

  constructor(expiresTimeKey: string, localStorage = new LocalStorageService(expiresTimeKey)) {
    this.localStorage = localStorage;
  }

  getExpiresTime() {
    const expiresTimeString = this.localStorage.getValue();

    if (expiresTimeString && numberHelper.isPositiveInteger(expiresTimeString)) {
      return +expiresTimeString;
    }

    return undefined;
  }

  setExpiresTime(expiresTime: number) {
    this.localStorage.setValue(expiresTime.toString());
  }

  removeExpiresTime() {
    this.localStorage.removeItem();
  }

  isTimeExpired() {
    const expiresTime = this.getExpiresTime();

    return !expiresTime || Date.now() > expiresTime;
  }
}
