import { useEffect, useState } from "react";

export const useQueueTasksNotificationsBellButtonNeedAnimate = (notificationsCount: number | undefined) => {
  const ANIMATION_DURATION_MS = 7000;
  const [needAnimate, setNeedAnimate] = useState(false);
  const [prevCounterValue, setPrevCounterValue] = useState<number | undefined>(notificationsCount);

  useEffect(() => {
    if (!needAnimate && prevCounterValue !== notificationsCount) {
      setNeedAnimate(!!prevCounterValue && !!notificationsCount && prevCounterValue < notificationsCount);
    }
    setPrevCounterValue(notificationsCount);
  }, [notificationsCount, needAnimate, prevCounterValue]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (needAnimate) {
      timer = setTimeout(() => setNeedAnimate(false), ANIMATION_DURATION_MS);
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [needAnimate]);

  return [needAnimate, setNeedAnimate] as const;
};
