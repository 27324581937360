import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditWorkingAssetsRejectionDemand } from "../../../../common/rejection";
import { RejectButton } from "../../../../../common/ui/buttons/reject-button";
import { CreditWorkingAssetsId, DemandId } from "../../../../../common/types/demand";
import { useCreditWorkingAssetsDetailsRejection } from "../../../hooks/useCreditWorkingAssetsDetailsRejection";

export interface CreditWorkingAssetsDetailsDemandHeaderRejectionButtonProps extends BaseCssProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
  demandId: DemandId;
  bankName: string;
  groupProductName: string | number;
}

export const CreditWorkingAssetsDetailsDemandHeaderRejectionButton = ({
  creditWorkingAssetsId,
  demandId,
  bankName,
  groupProductName,
  className,
}: CreditWorkingAssetsDetailsDemandHeaderRejectionButtonProps) => {
  const { isModalOpen, openModal, closeModal, afterSuccess, panelKey } = useCreditWorkingAssetsDetailsRejection(
    creditWorkingAssetsId,
    demandId,
  );

  return (
    <>
      <RejectButton className={className} onClick={openModal} size="default">
        <ThemeMobileDesktopView mobile="Отменить" desktop="Отменить заявку" />
      </RejectButton>
      <CreditWorkingAssetsRejectionDemand
        panelKey={panelKey}
        creditWorkingAssetsId={creditWorkingAssetsId}
        demandId={demandId}
        afterSuccess={afterSuccess}
        isOpen={isModalOpen}
        onClose={closeModal}
        groupProductName={groupProductName}
        bankName={bankName}
      />
    </>
  );
};
