import * as React from "react";
import {
  ProductListCardContainerDesktop,
  ProductListCardDetailsClientDesktop,
} from "../../../../../../common/ui/product/list/card";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { Typography } from "../../../../../../common/ui-kit/typography";

export interface CreditWorkingAssetsListItemCardDesktopProps extends BaseCssProps {
  clientName: React.ReactNode;
  clientTaxpayerNumber: React.ReactNode;
  numberAndDate: React.ReactNode;
  details: React.ReactNode;
  children: React.ReactNode;
}

export const CreditWorkingAssetsListItemCardDesktop = ({
  className,
  clientName,
  clientTaxpayerNumber,
  numberAndDate,
  children,
  details,
}: CreditWorkingAssetsListItemCardDesktopProps) => {
  return (
    <ProductListCardContainerDesktop
      className={className}
      details={
        <>
          <ProductListCardDetailsClientDesktop name={clientName} taxpayerNumber={clientTaxpayerNumber} pb="md" />
          <Typography fontSize="fz3" fontWeight={700} lineHeight="defaultLineHeight" fontColor="typography.primary">
            {numberAndDate}
          </Typography>
          {details}
        </>
      }
    >
      {children}
    </ProductListCardContainerDesktop>
  );
};
