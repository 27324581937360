import * as S from "./AsideBlockCollapseButton.styled";
import { ButtonProps } from "./AsideBlockCollapseButton.styled";
import { ChevronRightIcon } from "../../../../../ui-kit/icons";

export const AsideBlockCollapseButton = ({
  children,
  ...rest
}: Omit<JSX.IntrinsicElements["button"], "type"> & ButtonProps) => {
  return (
    <S.Button {...rest} type="button">
      {children}
      <ChevronRightIcon />
    </S.Button>
  );
};
