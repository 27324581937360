import { createAction, createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../types/state";
import { PurchaseState } from "./purchase.types";
import { getPurchase, PURCHASE_SLICE_NAME } from "./purchase.thunks";
import { PurchaseFixedKey } from "../types/PurchaseFixedKey";
import { combineSliceNames } from "../../store/utils";

const isSameKey = (action: { meta: { arg: { key: PurchaseFixedKey } } }, key: PurchaseFixedKey) => {
  return action.meta.arg.key === key;
};

export const updatePurchaseStatus = createAction<{ key: PurchaseFixedKey; status: LoadingStatusEnum }>(
  combineSliceNames(PURCHASE_SLICE_NAME, "updatePurchaseStatus"),
);

export interface GetPurchaseReducerArgs {
  key: PurchaseFixedKey;
  sliceName: string;
}

export const getPurchaseReducer = ({ key, sliceName }: GetPurchaseReducerArgs) => {
  const initialState: PurchaseState = {
    status: LoadingStatusEnum.Idle,
    data: undefined,
  };

  return createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(updatePurchaseStatus, (state, action) => {
          if (action.payload.key === key) {
            state.status = action.payload.status;
            state.data = undefined;
          }
        })
        .addCase(getPurchase.fulfilled, (state, action) => {
          if (isSameKey(action, key)) {
            if (action.payload.body.purchases.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              state.data = action.payload.body.purchases[0];
              state.fetchedPurchaseNumber = action.meta.arg.purchaseNumber;
            }
          }
        })
        .addCase(getPurchase.rejected, (state, action) => {
          if (isSameKey(action, key)) {
            state.fetchedPurchaseNumber = undefined;
          }
        })
        .addMatcher(isAsyncThunkAction(getPurchase), (state, action) => {
          if (isSameKey(action, key)) {
            state.status = getLoadingStatusFromAction(action);
          }
        });
    },
  }).reducer;
};
