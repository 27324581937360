import { useMemo } from "react";
import { TransitionGroup } from "react-transition-group";
import { useAppSelector } from "../../../../../common/store";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { CreditWorkingAssetsDetailsAsideDemandsListItem } from "./CreditWorkingAssetsDetailsAsideDemandsListItem";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useCreditWorkingAssetsDetailsContext } from "../../../hooks/context/useCreditWorkingAssetsDetailsContext";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../../../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";
import { selectCreditWorkingAssetsDetailsDemandsIds } from "../../../store/demands/creditWorkingAssetsDetailsDemands.selectors";
import {
  ProductDetailsAsideNavigationCardSkeleton,
  getProductDetailsAsideNavigationCardSkeletonItems,
} from "../../../../../common/product-details";

export type CreditWorkingAssetsDetailsAsideDemandsListProps = BaseCssProps;

export const CreditWorkingAssetsDetailsAsideDemandsList = ({
  className,
}: CreditWorkingAssetsDetailsAsideDemandsListProps) => {
  const { creditWorkingAssetsId } = useCreditWorkingAssetsDetailsContext();
  const { status, isTransferred } = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails);
  const demandIds = useAppSelector(selectCreditWorkingAssetsDetailsDemandsIds);

  const skeletonItems: number[] = useMemo(() => {
    return getProductDetailsAsideNavigationCardSkeletonItems(status, isTransferred, demandIds.length);
  }, [demandIds.length, isTransferred, status]);

  return (
    <StackPanel gap="sm" direction="column" className={className}>
      <TransitionGroup component={null}>
        {demandIds.map((x) => (
          <SimpleOpacityTransition key={x} timeout={250}>
            <CreditWorkingAssetsDetailsAsideDemandsListItem
              creditWorkingAssetsId={creditWorkingAssetsId}
              demandId={x}
            />
          </SimpleOpacityTransition>
        ))}
      </TransitionGroup>
      {skeletonItems.map((x) => (
        <ProductDetailsAsideNavigationCardSkeleton height={90} key={x} />
      ))}
    </StackPanel>
  );
};
