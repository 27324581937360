import styled from "@emotion/styled";

export const LoaderContainer = styled.div<{ show: boolean }>`
  margin-top: ${({ theme, show }) => theme.spaces[show ? "md" : "sm"]};
  margin-bottom: ${({ theme, show }) => (show ? theme.spaces.lg : 0)};
  font-size: ${({ theme }) => theme.font.fz1};
  min-height: ${({ show, theme }) => (show ? theme.font.defaultLineHeight : 0)};
  color: ${({ theme }) => theme.palette.typography.primaryCaption};
  display: inline-flex;
  gap: ${({ theme }) => theme.spaces.sm};
  transition: height 300ms linear, margin-top, margin-bottom 150ms linear;
`;

export const IdleLabelContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.xs};
  align-items: center;
  margin-left: -${({ theme }) => theme.spaces.xl};
`;
