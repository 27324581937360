import { lkApiV2, lkApiV3 } from "../essentials/configs";
import { axiosJWT } from "../data/api/apiConfig";

export const getFileHash = async (fileId: bigint, privateKey: string | null, providerType: string): Promise<{}> => {
  return axiosJWT.get(`${lkApiV2()}/File/FileHash/${fileId}`, {
    params: {
      privateKey: privateKey,
      providerType: providerType,
    },
  });
};

export const getFileTempGuid = async (fileId: bigint, privateKey?: string) => {
  return axiosJWT.get(`${lkApiV3()}/file/get-temp-guid/${fileId}`, {
    params: {
      privateKey: privateKey,
    },
  });
};
