import * as React from "react";
import styles from "./FormLayout.module.css";

interface FormLayoutProps {
  children: React.ReactNode;
}

export const FormLayout = ({ children }: FormLayoutProps) => {
  return <div className={styles["wrapper"]}>{children}</div>;
};
