import { useMemo } from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { ProductStageCounter } from "../../../../../common/ui/product/stage";
import { creditExecutionDemandStageType, CreditExecutionDemandStageType } from "../../../../common/types";
import { getCreditExecutionDemandStageColor, getCreditExecutionDemandStageIcon } from "../../../../common/utils";

export interface CreditExecutionListItemStageCounterProps extends BaseCssProps {
  stage: CreditExecutionDemandStageType;
  count: number;
}

export const CreditExecutionListItemStageCounter = ({
  stage,
  count,
  className,
}: CreditExecutionListItemStageCounterProps) => {
  const icon = useMemo(() => getCreditExecutionDemandStageIcon(stage), [stage]);
  const color = getCreditExecutionDemandStageColor(stage);

  return (
    <ProductStageCounter
      color={color}
      count={count}
      className={className}
      icon={icon}
      tooltip={creditExecutionDemandStageType.getTitle(stage)}
    />
  );
};
