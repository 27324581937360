import * as React from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export interface BankGuaranteeDetailsIssueDetailsProps extends BaseCssProps {
  children?: React.ReactNode;
}

export const BankGuaranteeDetailsIssueDetails = ({ className, children }: BankGuaranteeDetailsIssueDetailsProps) => {
  return <dl className={className}>{children}</dl>;
};
