import { createSelector } from "@reduxjs/toolkit";
import { QueueTasksListFiltersState, QueueTasksListState } from "./queueTasksList.types";
import { queueTasksListAdapter } from "./queueTasksList.adapter";
import { RootState } from "../../../common/store";
import { CodeWithTitle } from "../../../common/types/objects";
import { createFuseSearchSelector } from "../../../common/store/utils";
import { QueueTasksListFilters } from "../types/filter";
import { bankSearchTransformer } from "../../../common/utils/bankSearchTransformer";

export const selectQueueTasksList = (state: RootState): QueueTasksListState => state.domains.queueTasks.list.items;

export const selectQueueTasksListFilters = (state: RootState): QueueTasksListFiltersState =>
  selectQueueTasksList(state).filters;

export const { selectIds: selectQueueTasksListItemIds, selectById: selectQueueTasksListItemById } =
  queueTasksListAdapter.getSelectors(selectQueueTasksList);

export const selectQueueTasksListFilterBanks = createFuseSearchSelector<CodeWithTitle>({
  selector: (state) => selectQueueTasksListFilters(state).data?.banks.codeWithTitleList ?? [],
  ignoreLessThan: 0.55,
  keys: ["title"],
  searchTransformer: bankSearchTransformer,
});

export const selectQueueTasksListFilterBanksCodes = createSelector(
  [(state) => state, (state, search: string) => search],
  (state, search) => {
    return selectQueueTasksListFilterBanks(state, search).map((x) => x.code);
  },
);

export const selectQueueTasksListFiltersMaxLength = createSelector(
  [(state) => state, (_, key: keyof Omit<QueueTasksListFilters, "productBanks">) => key],
  (state, key) => {
    const titles = selectQueueTasksListFilters(state).data?.[key].titles;
    const values = Object.values(titles ?? {});

    return Math.max(...values.map((x) => x.length));
  },
);
