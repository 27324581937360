import { ConditionTitleType } from "../../../common/ui/layout/conditions";

export const conditionsTitles: ConditionTitleType[] = [
  {
    title: "Стандарт / Превышение",
    size: "6ch",
  },
  {
    title: "Условия",
    size: "auto",
  },
];
