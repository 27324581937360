import { useEffect } from "react";
import { useFormikContext } from "formik";
import { ClientSearchClientStateSelector } from "../../types/ClientSearchClientStateSelector";
import { useAppSelector } from "../../../common/store";
import { useGetFieldName } from "../../../common/form";
import { FieldNames } from "../../../common/form/types/FieldNames";
import { ClientSearchCreationClientBlockFormValues } from "../../types/ClientSearchCreationClientBlockFormValues";

export const useClientSearchCreationClientIdFieldMutation = <
  TFormValues extends ClientSearchCreationClientBlockFormValues
>(
  clientStateSelector: ClientSearchClientStateSelector
) => {
  const {
    values: { clientId },
    setFieldValue,
  } = useFormikContext<TFormValues>();

  const getFieldName = useGetFieldName<TFormValues>();

  const { check } = useAppSelector(clientStateSelector);

  useEffect(() => {
    if (clientId !== check?.data?.id) {
      setFieldValue(getFieldName("clientId" as FieldNames<TFormValues>), check?.data?.id, true);
      setFieldValue(getFieldName("clientTaxSystem" as FieldNames<TFormValues>), check?.data?.taxSystem?.code, true);
    }
  }, [check?.data?.id, check?.data?.taxSystem?.code, clientId, getFieldName, setFieldValue]);

  return clientId;
};
