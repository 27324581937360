import { useState } from "react";
import { FilterMobileCollapseContainer, FilterMobileCollapseContainerProps } from "./FilterMobileCollapseContainer";

export type QueueTasksFilterCollapseUncontrolledContainerProps = Omit<
  FilterMobileCollapseContainerProps,
  "isOpened" | "onClick"
>;

export const FilterMobileCollapseUncontrolledContainer = (
  props: QueueTasksFilterCollapseUncontrolledContainerProps
) => {
  const [isOpened, setIsOpened] = useState(false);
  const onToggle = () => setIsOpened((prev) => !prev);

  return <FilterMobileCollapseContainer isOpened={isOpened} onClick={onToggle} {...props} />;
};
