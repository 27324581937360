import { BankGuaranteeDetailsDemandBody } from "../demand/body/BankGuaranteeDetailsDemandBody";
import { BankGuaranteeDetailsRequestOriginalComments } from "./comments/BankGuaranteeDetailsRequestOriginalComments";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { BankGuaranteeDetailsRequestOriginalNewComment } from "./comments/BankGuaranteeDetailsRequestOriginalNewComment";
import { Box } from "../../../../common/ui-kit/containers/box";

export type BankGuaranteeDetailsRequestOriginalBodyProps = BaseCssProps;

export const BankGuaranteeDetailsRequestOriginalBody = ({
  className,
}: BankGuaranteeDetailsRequestOriginalBodyProps) => {
  return (
    <Box as={BankGuaranteeDetailsDemandBody} gap="lg" className={className}>
      <BankGuaranteeDetailsRequestOriginalNewComment />
      <BankGuaranteeDetailsRequestOriginalComments />
    </Box>
  );
};
