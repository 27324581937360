import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";
import { selectCreditWorkingAssetsDetailsDemandsTotal } from "../../store/demands/creditWorkingAssetsDetailsDemands.selectors";
import { creditWorkingAssetsDemandStageType } from "../../../common/types";
import {
  getCreditWorkingAssetsDemandStageColorAttachingAsProcessing,
  getCreditWorkingAssetsStageBackgroundColor,
} from "../../../common/utils";
import { ProductDetailsEmpty } from "../../../../common/product-details";

export type CreditWorkingAssetsDetailsEmptyProps = BaseCssProps;

export const CreditWorkingAssetsDetailsEmpty = ({ className }: CreditWorkingAssetsDetailsEmptyProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { activeTasksCount, counters } = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails).data!;

  const banksCount = useAppSelector(selectCreditWorkingAssetsDetailsDemandsTotal);

  return (
    <ProductDetailsEmpty
      className={className}
      counters={counters}
      getStageColor={getCreditWorkingAssetsDemandStageColorAttachingAsProcessing}
      getStageBackgroundColor={getCreditWorkingAssetsStageBackgroundColor}
      stageValues={creditWorkingAssetsDemandStageType.values}
      getShortTitle={creditWorkingAssetsDemandStageType.getShortTitle}
      activeTasksCount={activeTasksCount}
      banksCount={banksCount}
    />
  );
};
