import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  selectCreditExecutionDetailsTasksHistory,
  selectCreditExecutionDetailsTasksHistoryIds,
} from "../../../store/tasks-history/creditExecutionDetailsTasksHistory.selectors";
import { useCreditExecutionDetailsContext } from "../../../hooks/context/useCreditExecutionDetailsContext";
import { CreditExecutionDetailsTasksHistoryListItem } from "./CreditExecutionDetailsTasksHistoryListItem";
import { DemandQueueTaskInfinityScroll } from "../../../../../demand/queue-tasks";

export type CreditExecutionDetailsTasksHistoryProps = BaseCssProps;

export const CreditExecutionDetailsTasksHistory = ({ className }: CreditExecutionDetailsTasksHistoryProps) => {
  const { demandId } = useCreditExecutionDetailsContext();

  return (
    <DemandQueueTaskInfinityScroll
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      demandId={demandId!}
      idsSelector={selectCreditExecutionDetailsTasksHistoryIds}
      paginatedStateSelector={selectCreditExecutionDetailsTasksHistory}
      className={className}
    >
      {(x) => <CreditExecutionDetailsTasksHistoryListItem queueTaskId={x} />}
    </DemandQueueTaskInfinityScroll>
  );
};
