import { ReactNode, useEffect, useRef, useState } from "react";
import FocusTrap from "focus-trap-react";
import { CSSTransition } from "react-transition-group";
import { PortalContainer } from "../../portal";
import * as S from "./Modal.styled";
import { Box } from "../../../../ui-kit/containers/box";
import { CancelButton } from "../../../buttons/cancel-button";
import { Text } from "../../../../ui-kit/typography";
import { ModalGlobalStyles } from "./ModalGlobalStyles";

export interface ModalProps extends S.ContentProps {
  onClose: () => void;
  isShown: boolean;
  title?: ReactNode;
  children?: ReactNode;
  noCloseOnClickOutside?: boolean;
}

// Разрешаем ловить фокус только после окончания анимации.
const checkCanFocusTrap = async (trapContainers: (HTMLElement | SVGElement)[]): Promise<void> => {
  const results = trapContainers.map((trapContainer) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (trapContainer.classList.contains("enter-done")) {
          resolve(undefined);
          clearInterval(interval);
        }
      }, 50);
    });
  });

  await Promise.all(results);
};

export const Modal = ({
  onClose,
  isShown,
  width,
  background,
  isWithoutControls,
  title,
  children,
  noCloseOnClickOutside,
  noClip,
}: ModalProps) => {
  // Особенности работы Transition - триггер анимации вызывается при смене пропса in.
  // Чтобы вызвать триггер при mount, нужно прокинуть пропс appear
  const [isInitialOpened] = useState(isShown);

  useEffect(() => {
    const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        onClose();
      }
    };

    document.body.addEventListener("keydown", closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [onClose]);

  const contentRef = useRef<HTMLDivElement | null>(null);

  return (
    <PortalContainer htmlElementId={import.meta.env.VITE_MODAL_CONTAINER_ID ?? ""}>
      <ModalGlobalStyles isModalOpened={isShown} />
      <CSSTransition
        nodeRef={contentRef}
        timeout={{ enter: 0, exit: 250 }}
        appear={isInitialOpened}
        in={isShown}
        unmountOnExit
      >
        <FocusTrap
          active={isWithoutControls !== true}
          focusTrapOptions={{
            checkCanFocusTrap,
          }}
        >
          <S.Container ref={contentRef} onClick={noCloseOnClickOutside ? undefined : onClose}>
            <S.Content
              width={width}
              background={background}
              isWithoutControls={isWithoutControls}
              noClip={noClip}
              onClick={(e) => e.stopPropagation()}
            >
              {!isWithoutControls && (
                <Box pb="lg" display="flex" alignItems="center" justifyContent="space-between">
                  <Text as="h4" fontWeight={500} lineHeight="1.5" fontSize={1.5}>
                    {title}
                  </Text>
                  <CancelButton stroke="typography.primary" onClick={onClose} />
                </Box>
              )}
              {children}
            </S.Content>
          </S.Container>
        </FocusTrap>
      </CSSTransition>
    </PortalContainer>
  );
};
