import { clientV4 } from "../../../common/api";
import { GetBankGuaranteeDetailsInputDto } from "./dto/input/GetBankGuaranteeDetailsInputDto";
import { GetBankGuaranteeDetailsOutputDto } from "./dto/output/GetBankGuaranteeDetailsOutputDto";
import { GetBankGuaranteeDetailsDemandInputDto } from "./dto/input/GetBankGuaranteeDetailsDemandInputDto";
import { GetBankGuaranteeDetailsDemandOutputDto } from "./dto/output/GetBankGuaranteeDetailsDemandOutputDto";
import { UploadGroupDocumentBankGuaranteeDetailsInputDto } from "./dto/input/UploadGroupDocumentBankGuaranteeDetailsInputDto";
import { GetRequestOriginalCommentsBankGuaranteeDetailsInputDto } from "./dto/input/GetRequestOriginalCommentsBankGuaranteeDetailsInputDto";
import { GetRequestOriginalCommentsBankGuaranteeDetailsOutputDto } from "./dto/output/GetRequestOriginalCommentsBankGuaranteeDetailsOutputDto";
import { SendRequestOriginalCommentBankGuaranteeDetailsInputDto } from "./dto/input/SendRequestOriginalCommentBankGuaranteeDetailsInputDto";
import { SendRequestOriginalCommentBankGuaranteeDetailsOutputDto } from "./dto/output/SendRequestOriginalCommentBankGuaranteeDetailsOutputDto";
import { SendBillChangesBankGuaranteeDetailsInputDto } from "./dto/input/SendBillChangesBankGuaranteeDetailsInputDto";
import { EmptyOutputDto } from "../../../common/api/types";
import { GetHistoryBankGuaranteeDetailsDemandInputDto } from "./dto/input/GetHistoryBankGuaranteeDetailsDemandInputDto";
import { GetHistoryBankGuaranteeDetailsDemandOutputDto } from "./dto/output/GetHistoryBankGuaranteeDetailsDemandOutputDto";
import { SendProjectChangesBankGuaranteeDetailsInputDto } from "./dto/input/SendProjectChangesBankGuaranteeDetailsInputDto";
import { SendPaymentConfirmationBankGuaranteeDetailsInputDto } from "./dto/input/SendPaymentConfirmationBankGuaranteeDetailsInputDto";

const GUARANTEE_CONTROLLER_NAME = "/Guarantees";
const REQUEST_ORIGINALS_CONTROLLER_NAME = "/RequestOriginals";
const QUEUE_TASKS_CONTROLLER_NAME = "/QueueTasks";

export const bankGuaranteeDetailsApi = {
  get: (query: GetBankGuaranteeDetailsInputDto, abortSignal?: AbortSignal) => {
    return clientV4.get<GetBankGuaranteeDetailsInputDto, GetBankGuaranteeDetailsOutputDto>(
      `${GUARANTEE_CONTROLLER_NAME}/${query.id}`,
      undefined,
      { abortSignal },
    );
  },
  getDemand: ({ demandId, guaranteeId }: GetBankGuaranteeDetailsDemandInputDto, abortSignal?: AbortSignal) => {
    return clientV4.get<GetBankGuaranteeDetailsDemandInputDto, GetBankGuaranteeDetailsDemandOutputDto>(
      `${GUARANTEE_CONTROLLER_NAME}/${guaranteeId}/demand/${demandId}`,
      undefined,
      { abortSignal },
    );
  },
  uploadGroupDocument: ({ guaranteeId, ...model }: UploadGroupDocumentBankGuaranteeDetailsInputDto) => {
    return clientV4.postForm<typeof model, GetBankGuaranteeDetailsOutputDto>(
      `${GUARANTEE_CONTROLLER_NAME}/${guaranteeId}/group-upload-document`,
      model,
    );
  },
  sendRequestOriginalComment: ({ demandId, ...formValues }: SendRequestOriginalCommentBankGuaranteeDetailsInputDto) =>
    clientV4.putForm<typeof formValues, SendRequestOriginalCommentBankGuaranteeDetailsOutputDto>(
      `${REQUEST_ORIGINALS_CONTROLLER_NAME}/${demandId}`,
      formValues,
    ),
  getRequestOriginalComments: ({ demandId, ...model }: GetRequestOriginalCommentsBankGuaranteeDetailsInputDto) =>
    clientV4.get<typeof model, GetRequestOriginalCommentsBankGuaranteeDetailsOutputDto>(
      `${REQUEST_ORIGINALS_CONTROLLER_NAME}/${demandId}`,
      model,
    ),
  sendBillChanges: (model: SendBillChangesBankGuaranteeDetailsInputDto) =>
    clientV4.putForm<SendBillChangesBankGuaranteeDetailsInputDto, EmptyOutputDto>(
      `${QUEUE_TASKS_CONTROLLER_NAME}/send-bill-changes`,
      model,
    ),
  sendProjectChanges: (model: SendProjectChangesBankGuaranteeDetailsInputDto) =>
    clientV4.putForm<SendProjectChangesBankGuaranteeDetailsInputDto, EmptyOutputDto>(
      `${QUEUE_TASKS_CONTROLLER_NAME}/send-project-changes`,
      model,
    ),
  getHistory: (model: GetHistoryBankGuaranteeDetailsDemandInputDto, abortSignal?: AbortSignal) =>
    clientV4.get<GetHistoryBankGuaranteeDetailsDemandInputDto, GetHistoryBankGuaranteeDetailsDemandOutputDto>(
      `${QUEUE_TASKS_CONTROLLER_NAME}/history`,
      model,
      {
        abortSignal,
      },
    ),
  sendConfirmPayment: ({ demandId, ...model }: SendPaymentConfirmationBankGuaranteeDetailsInputDto) =>
    clientV4.postForm<typeof model, EmptyOutputDto>(
      `${GUARANTEE_CONTROLLER_NAME}/send-confirm-payment/${demandId}`,
      model,
    ),
};
