import { combineReducers } from "redux";
import { creditWorkingAssetsListFiltersReducer } from "./filters/creditWorkingAssetsListFilters.slice";
import { creditWorkingAssetsListItemsReducer } from "./items/creditWorkingAssetsListItems.slice";
import { creditWorkingAssetsListCountersReducer } from "./counters/creditWorkingAssetsListCounters.slice";
import { creditWorkingAssetsListItemModificationReducer } from "./item-modification/creditWorkingAssetsListItemModification.slice";

export const creditWorkingAssetsListReducer = combineReducers({
  filters: creditWorkingAssetsListFiltersReducer,
  items: creditWorkingAssetsListItemsReducer,
  counters: creditWorkingAssetsListCountersReducer,
  itemModification: creditWorkingAssetsListItemModificationReducer,
});
