import { forwardRef } from "react";
import { BankGuaranteeDetailsComplicatedTaskBillCard } from "../task/BankGuaranteeDetailsComplicatedTaskBillCard";
import {
  DemandQueueTaskCompletedSingleCommentCard,
  DemandQueueTaskSeparator,
  isDemandQueueTaskCommentHaveFilesOrBody,
} from "../../../../../../demand/queue-tasks";
import { DemandQueueTaskComment } from "../../../../../../demand/queue-tasks/components/comment/DemandQueueTaskComment";
import { BankGuaranteeDetailsDemandCompletedTaskCommonProps } from "../../../../types/demand/tasks/BankGuaranteeDetailsDemandTaskCommonProps";
import {
  getUseBankGuaranteeDetailsComplicatedTaskDateCreatedOptionsByCompletedTask,
  useBankGuaranteeDetailsComplicatedTaskDateCreated,
} from "../../../common/hooks/useBankGuaranteeDetailsComplicatedTaskDateCreated";

export const BankGuaranteeDetailsComplicatedTaskBillCompleted = forwardRef<
  HTMLDivElement,
  BankGuaranteeDetailsDemandCompletedTaskCommonProps
>(({ isFocused, task, className }, ref) => {
  const { date, placement } = useBankGuaranteeDetailsComplicatedTaskDateCreated(
    getUseBankGuaranteeDetailsComplicatedTaskDateCreatedOptionsByCompletedTask(task),
  );

  return (
    <BankGuaranteeDetailsComplicatedTaskBillCard
      isFocused={isFocused}
      ref={ref}
      className={className}
      taskTitle={task.title}
      taskGroupType={task.groupType}
      variant={task.isApproved ? "successful" : "completed"}
      billInfo={task.billInfo}
      dateCreated={date}
      dateCreatedPlacement={placement}
      managerComment={
        isDemandQueueTaskCommentHaveFilesOrBody(task.managerComment) && (
          <>
            <DemandQueueTaskCompletedSingleCommentCard comment={task.managerComment} />
            <DemandQueueTaskSeparator />
          </>
        )
      }
    >
      {task.agentComment && <DemandQueueTaskCompletedSingleCommentCard comment={task.agentComment} />}
      {task.agentComment === undefined && task.isApproved === true && (
        <DemandQueueTaskComment author="Ваш ответ" text={task.acceptedDecision?.title} />
      )}
    </BankGuaranteeDetailsComplicatedTaskBillCard>
  );
});
