import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BankGuaranteeTransferPayload, transferBankGuarantee } from "../../../common/store";
import { combineSliceNames } from "../../../../common/store/utils";
import { BANK_GUARANTEE_DETAILS_SLICE_NAME } from "../bankGuaranteeDetails.thunks";
import { BankGuaranteeDetailsTransferState } from "./bankGuaranteeDetailsTransfer.types";
import { LoadingStatusEnum } from "../../../../common/types/state";

const initialState: BankGuaranteeDetailsTransferState = {
  data: undefined,
  status: LoadingStatusEnum.Idle,
};

const slice = createSlice({
  name: combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "transfer"),
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(transferBankGuarantee, (state, action: PayloadAction<BankGuaranteeTransferPayload>) => {
      state.data = action.payload;
    });
  },
});

export const bankGuaranteeDetailsTransferReducer = slice.reducer;

export const { clear: clearBankGuaranteeTransfer } = slice.actions;
