import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { CreditWorkingAssetsId } from "../../../../../../common/types/demand";
import { useAppSelector } from "../../../../../../common/store";
import { selectCreditWorkingAssetsListItemsById } from "../../../../store/items/creditWorkingAssetsListItems.selectors";
import { useListTrimMoreThan } from "../../../../../../common/hooks";
import {
  ProductListCardDemandsListContainerDesktop,
  ProductListCardMoreActionsButton,
  ProductListCardShowMoreButtonDesktop,
  ProductListCardTasksCountBadgeDesktop,
} from "../../../../../../common/ui/product/list/card";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { CreditWorkingAssetsListItemStageCounters } from "../../counters/CreditWorkingAssetsListItemStageCounters";
import { CreditWorkingAssetsListItemDemandsListDesktop } from "./list/CreditWorkingAssetsListItemDemandsList.desktop";
import { NavigationState } from "../../../../../../common/navigation/navigation-state";
import { useCreditWorkingAssetsListItemMoreActions } from "../../../../hooks/useCreditWorkingAssetsListItemMoreActions";
import { declensionHelper } from "../../../../../../common/helpers/entity";

export interface CreditWorkingAssetsListItemDemandsDesktopProps extends BaseCssProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
  from: NavigationState;
}

export const CreditWorkingAssetsListItemDemandsDesktop = ({
  className,
  creditWorkingAssetsId,
  from,
}: CreditWorkingAssetsListItemDemandsDesktopProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const item = useAppSelector((state) => selectCreditWorkingAssetsListItemsById(state, creditWorkingAssetsId))!;
  const { items, isShown, toggleIsShown, needTrim } = useListTrimMoreThan(item.demandIds, 3);

  const actions = useCreditWorkingAssetsListItemMoreActions(item, from);

  return (
    <ProductListCardDemandsListContainerDesktop
      className={className}
      banksCountTitle={declensionHelper.commonDeclensions.bank.nominative(item.demandIds.length, true)}
      counters={<CreditWorkingAssetsListItemStageCounters counters={item.counters} />}
      activeTasksCountBadge={
        item.activeTasksCount > 0 && <ProductListCardTasksCountBadgeDesktop count={item.activeTasksCount} />
      }
      actionsButton={actions.length > 0 && <ProductListCardMoreActionsButton availableActions={actions} />}
      footer={
        needTrim && (
          <Box pt="sm">
            <ProductListCardShowMoreButtonDesktop isShow={!isShown} onClick={toggleIsShown} />
          </Box>
        )
      }
    >
      <CreditWorkingAssetsListItemDemandsListDesktop
        creditWorkingAssetsId={creditWorkingAssetsId}
        demandIds={items}
        from={from}
      />
    </ProductListCardDemandsListContainerDesktop>
  );
};
