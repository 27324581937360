import AgentReportFileModel from "../../../models/AgentReportFileModel";
import { useState } from "react";
import AgentReportType from "../../../models/AgentReportType";
import AttachmentLink from "../../../components/controls-old/attachment/AttachmentLink";
import FileUpload from "../../../components/controls-old/file-upload/FileUpload";
import { AgentReportUpdateFile } from "../../../store/actions/agentReportUpdateActionsType";
import MySubmitButton from "../../../components/common/MySubmitButton";
import { fetchManagerSign, fetchUserSign } from "../../../store/actions/file/fileActions";
import { useAppDispatch } from "../../../domains/common/store";

export interface AgentReportItemFilesProps {
  files: AgentReportFileModel[];
  isLoadFile: boolean;
  onChangeFiles: (items: AgentReportUpdateFile[]) => void;
}

export const AgentReportItemFiles = ({ files, isLoadFile, onChangeFiles }: AgentReportItemFilesProps) => {
  const dispatch = useAppDispatch();
  const [items, setItems] = useState<AgentReportUpdateFile[]>([]);

  const getFileTitle = (item: AgentReportFileModel): string => {
    const title = [item.issuingCompany.name];

    if (item.typeReport === AgentReportType.Common) {
      title.push("общий");
    } else if (item.typeReport === AgentReportType.Other) {
      title.push("другие продукты");
    } else {
      title.push("банковские гарантии");
    }

    return title.join(" / ");
  };

  const handleFileChange = (id: number, file: File | null) => {
    const d = items.slice().filter((x) => x.issuingCompanyId !== id);
    if (file) {
      d.push({ issuingCompanyId: id, file: file });
    }
    setItems(d);
    onChangeFiles(d);
  };

  return (
    <div className="agent-report-item__files">
      <table className="table table_condensed table_bordered">
        <thead className="table__head">
          <tr className="table__head-row">
            <td className="table__head-row-item">Выпускающая компания</td>
            <td className="table__head-row-item">Акт сверки</td>
            {isLoadFile && (
              <td className="table__head-row-item  agent-report-item__files-row-upload">Ваш файл с правками</td>
            )}
            <td className="table__head-row-item">Ваша подпись</td>
            <td className="table__head-row-item">Подпись менеджера</td>
          </tr>
        </thead>
        <tbody className="table__body">
          {files.map((file, index) => {
            return (
              <tr className="table__row" key={index}>
                <td className="table__row-item">{getFileTitle(file)}</td>
                <td className="table__row-item">
                  <AttachmentLink file={file.fileManager} />
                </td>
                <td className="table__row-item">
                  {file.fileManager.signedByUser && (
                    <MySubmitButton
                      text={"Скачать"}
                      onClickFunction={() =>
                        dispatch(fetchUserSign(file.fileManager.fileId, file.fileManager.privateKey))
                      }
                    />
                  )}
                </td>
                <td className="table__row-item">
                  {file.fileManager.signedByIssuingCompany && (
                    <MySubmitButton
                      text={"Скачать"}
                      onClickFunction={() =>
                        dispatch(fetchManagerSign(file.fileManager.fileId, file.fileManager.privateKey))
                      }
                    />
                  )}
                </td>
                {isLoadFile && (
                  <td className="table__row-item">
                    <FileUpload id={file.issuingCompany.id} onChange={handleFileChange} />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
