import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { apiConfig } from "../../api/configs";
import { authenticationService } from "../../authentication";

export const createHubConnection = (hubName: string): HubConnection => {
  return new HubConnectionBuilder()
    .withUrl(`${apiConfig.baseUrl}/${hubName}`, {
      accessTokenFactory: authenticationService.makeHubConnectionAccessTokenFactory(),
    })
    .configureLogging(LogLevel.Warning)
    .build();
};
