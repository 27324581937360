import { ChangeEvent } from "react";
import * as React from "react";
import "./Input.scss";

export interface IInputProps {
  type?: string;
  defaultValue?: string;
  errorMessage?: string;
  name: string;
  placeholder: string;
  maxLength: number;
  onChange: (value: string) => void;
}

const Input: React.FC<IInputProps> = (props) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = target.value.trim();

    props.onChange(value);
  };

  return (
    <div className="form-group">
      <input
        type={props.type ?? "text"}
        name={props.name}
        className="form-control input"
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onChange={(e) => handleOnChange(e)}
      />
      {props.errorMessage && <span className="text-danger form-group__error">{props.errorMessage}</span>}
    </div>
  );
};

export default Input;
