import { currencyHelper, dateTimeHelper, isValidUrl } from "@sideg/helpers";
import { getFederalLawTypeTitle } from "../../../../../../common/types/demand";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { StackPanel } from "../../../../../../common/ui-kit/containers/stack-panel";
import { ExternalLinkIcon, Icon } from "../../../../../../common/ui-kit/icons";
import { Link } from "../../../../../../common/ui-kit/links";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { ProductListBriefButton } from "../../../../../../common/ui/product/list/card";
import { CreditExecutionCommonDetailsContainer } from "../../../../../common/components";
import { CreditExecutionListItem } from "../../../../types/items/CreditExecutionListItem";
import { CreditExecutionListItemDesktopBrief } from "../desktop/brief/CreditExecutionListItemDesktopBrief";

const BoxLink = Box.withComponent(Link);

export interface CreditExecutionListItemDetailsProps extends BaseCssProps {
  item: CreditExecutionListItem;
}

export const CreditExecutionListItemDetails = ({ item, className }: CreditExecutionListItemDetailsProps) => {
  return (
    <CreditExecutionCommonDetailsContainer
      className={className}
      federalLaw={getFederalLawTypeTitle(item.federalLaw.code)}
      purchaseTerms={
        <StackPanel gap="xs" direction="row" alignItems="center">
          <span>{item.purchaseNumber}</span>
          {isValidUrl(item.purchaseLink) && (
            <BoxLink
              flexGrow={0}
              flexShrink={0}
              href={item.purchaseLink}
              target="_blank"
              rel="noopener noreferrer"
              title="Ссылка на закупку"
            >
              <Icon as={ExternalLinkIcon} stroke="primary" />
            </BoxLink>
          )}
        </StackPanel>
      }
      requested={currencyHelper.getCurrencyStringNullable(item.contractSum, undefined)}
      confirmed={currencyHelper.getCurrencyStringNullable(item.confirmedCreditSum, undefined)}
      period={`${dateTimeHelper.format(new Date(item.dateStart), "dayShortMonthYear")} – ${dateTimeHelper.format(
        new Date(item.dateEnd),
        "dayShortMonthYear",
      )}`}
      briefButton={
        <ProductListBriefButton>
          <CreditExecutionListItemDesktopBrief creditExecutionId={item.creditExecutionId} />
        </ProductListBriefButton>
      }
    />
  );
};
