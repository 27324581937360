import { PlusIcon } from "../../../../common/ui-kit/icons";
import { QueueTasksListCreationButtonProps } from "./QueueTasksListCreationButtonProps";
import { AppLinkButton } from "../../../../common/ui/links";

export const QueueTasksListCreationButtonDesktop = ({ state }: QueueTasksListCreationButtonProps) => {
  return (
    <AppLinkButton variant="contained" color="primary" to={(x) => x.queueTasks.creation.create} state={state}>
      <PlusIcon />
      Новая заявка
    </AppLinkButton>
  );
};
