import { useQueueTasksCountersNewCount } from "../../../../../../queue-tasks/counters";
import { StyledNavigationButtonWithNotificationDot } from "../../../../navigation/components/buttons/StyledNavigationButtonWithNotificationDot";
import { CancelIcon, HamburgerIcon } from "../../../../../ui-kit/icons";

export interface LayoutHamburgerButtonProps {
  open: boolean;
  onClick: () => void;
}

export const AppLayoutHeaderHamburgerButton = ({ open, onClick }: LayoutHamburgerButtonProps) => {
  const queueTasksNewCount = useQueueTasksCountersNewCount();

  return (
    <StyledNavigationButtonWithNotificationDot
      isActive={false}
      withNotification={queueTasksNewCount !== undefined && queueTasksNewCount > 0 && !open}
      type="button"
      onClick={onClick}
    >
      {open ? <CancelIcon /> : <HamburgerIcon />}
    </StyledNavigationButtonWithNotificationDot>
  );
};
