import styled from "@emotion/styled";

// TODO: взять цвета из темы + насколько это в целом нужно
export const StyledContainerWithCustomScroll = styled.div`
  &::-webkit-scrollbar {
    padding: ${({ theme }) => theme.spacing.none};
    border-radius: ${({ theme }) => theme.radii.control};
    border: 4px solid transparent;
    background-clip: content-box;
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border: 4px solid transparent;
    border-radius: ${({ theme }) => theme.radii.block};
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #909090;
  }
`;
