import { useFormikContext } from "formik";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { DemandQueueTasksActiveRequestedDocument } from "../../types/active/DemandQueueTasksActiveRequestedDocument";
import { useDemandQueueTaskRequestedDocument } from "../../hooks/active/form/useDemandQueueTaskRequestedDocument";
import {
  DocumentUploadActiveDocument,
  getDocumentUploadRequestedDocumentOldFiles,
  useDocumentUploadLabelText,
} from "../../../../document/upload";
import { FileUpload } from "../../../../files/upload";
import { SimpleOpacityTransition } from "../../../../common/ui-kit/animations/transitions";
import { DemandQueueTaskActiveFormValues } from "../../types/active-form/DemandQueueTaskActiveFormValues";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";

export interface DemandQueueTasksRequestedDocumentFieldProps extends BaseCssProps {
  document: DemandQueueTasksActiveRequestedDocument;
}

export const DemandQueueTasksRequestedDocumentField = ({
  className,
  document,
}: DemandQueueTasksRequestedDocumentFieldProps) => {
  const { isSubmitting } = useFormikContext<DemandQueueTaskActiveFormValues>();
  const { files, onFileDelete, error, handleDrop, isMultiple } = useDemandQueueTaskRequestedDocument(document);
  const oldFiles = getDocumentUploadRequestedDocumentOldFiles(document);

  const uploadFileLabel = useDocumentUploadLabelText({
    isLoading: isSubmitting,
    attachedFilesCount: (oldFiles?.length ?? 0) + files.length,
    isMultiFile: document.isMultiFile,
    isReplaceableCollection: document.isReplaceableCollection === true,
    loadingFilesCount: files.length,
  });

  return (
    <DocumentUploadActiveDocument
      className={className}
      title={document.documentName}
      description={document.description}
      isRequired={document.isRequired}
      oldFiles={oldFiles}
      template={document.template}
      isMultiple={isMultiple}
      canUpload
      isLoading={isSubmitting}
      onDrop={handleDrop}
      error={error}
      uploadLabelText={uploadFileLabel}
    >
      {files && files.length > 0 && (
        <StackPanel direction="column" gap="md">
          {files.map((x) => (
            <SimpleOpacityTransition timeout={250} key={x.id}>
              <FileUpload
                fileName={x.file.name}
                date={x.dateAdded}
                canRemove={!isSubmitting}
                onRemove={() => onFileDelete(x.id)}
              />
            </SimpleOpacityTransition>
          ))}
        </StackPanel>
      )}
    </DocumentUploadActiveDocument>
  );
};
