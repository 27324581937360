import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../../../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { useBankGuaranteeDetailsLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { Text, TextProps } from "../../../../../common/ui-kit/typography";
import { selectBankGuaranteeDetailsRequestOriginal } from "../../../store/request-original/bankGuaranteeDetailsRequestOriginal.selectors";
import { BankGuaranteeRequestOriginalStatus, bankGuaranteeRequestOriginalStatus } from "../../../../common/types";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { useAppUrlMatch } from "../../../../../common/urls/internal/hooks";
import { ProductDetailsAsideNavigationCard } from "../../../../../common/product-details";

const getStatusColor = (status: BankGuaranteeRequestOriginalStatus): TextProps["fontColor"] => {
  if (status === "Approved") {
    return "success";
  }

  if (status === "Rejected") {
    return "medium";
  }

  if (status === "Rework") {
    return "colors.primary";
  }

  return "colors.dark";
};

export type BankGuaranteeDetailsNavigationCardRequestOriginalProps = BaseCssProps;

export const BankGuaranteeDetailsNavigationCardRequestOriginal = ({
  className,
}: BankGuaranteeDetailsNavigationCardRequestOriginalProps) => {
  const { data } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);
  const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();
  const { path, isExact } = useAppUrlMatch((x) => x.bankGuarantee.details.requestOriginal, {
    guaranteeId,
    demandId: data?.requestOriginalDemandId ?? -1,
  });

  const { details } = useAppSelector(selectBankGuaranteeDetailsRequestOriginal);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {data?.requestOriginalDemandId !== undefined && (
        <ProductDetailsAsideNavigationCard to={path} isActive={isExact} className={className}>
          <Box
            gap="sm"
            display="flex"
            flexDirection="column"
            flexShrink={1}
            flexGrow={1}
            justifyContent="space-between"
          >
            <Text fontSize="fz2" fontWeight={500}>
              Запрос оригинала
            </Text>
            {details && (
              <Text fontSize="fz2" fontWeight={500} lineHeight="1" fontColor={getStatusColor(details.status.code)}>
                {bankGuaranteeRequestOriginalStatus.getTitle(details.status.code)}
              </Text>
            )}
          </Box>
        </ProductDetailsAsideNavigationCard>
      )}
    </>
  );
};
