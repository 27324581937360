import { bankGuaranteeListFiltersValidationSchema } from "./bankGuaranteeListFiltersValidationSchema";

export const bankGuaranteeListMobileFiltersValidationSchema = bankGuaranteeListFiltersValidationSchema.pick([
  "guaranteeTypes",
  "status",
  "taskTypes",
  "bankIds",
  "dateCreateFrom",
  "dateCreateTo",
  "requestOriginalStatuses",
]);
