import { useEffect } from "react";
import { entityHelper, enToRuLayout } from "@sideg/helpers";
import { ClientSearchFixedKey } from "../types/ClientSearchFixedKey";
import { useAppDispatch } from "../../common/store";
import { findSearchClient } from "../store/clientSearch.thunks";

export const useSearchClientInputFindEffect = (searchValue: string | undefined, fixedKey: ClientSearchFixedKey) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const find = (value: string) => {
      let clientNameOrTaxpayerNumber = value;
      if (entityHelper.isOnlyLatinSpacesDots(value)) {
        clientNameOrTaxpayerNumber = enToRuLayout(value);
      }

      return dispatch(findSearchClient({ clientNameOrTaxpayerNumber, key: fixedKey }));
    };

    const request = searchValue !== undefined ? find(searchValue) : undefined;

    return () => {
      request?.abort();
    };
  }, [dispatch, searchValue, fixedKey]);
};
