import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Theme } from "@sideg/ui-kit";
import { ControlColors } from "../../../theme";
import { FormChipRadioButtonGroupVariant } from "../types/FormChipRadioButtonGroupVariant";

export const Wrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
  padding: 0;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  letter-spacing: 0;
  width: 100%;

  & > legend {
    margin-bottom: ${({ theme }) => theme.spaces.md};
    color: ${({ theme }) => theme.palette.typography.primary};
    font-weight: 700;
  }
`;

const getVariantPalette = (theme: Theme, variant: FormChipRadioButtonGroupVariant): Omit<ControlColors, "border"> => {
  switch (variant) {
    case "success":
      return {
        text: theme.palette.typography.success,
        background: theme.palette.status.successBackground,
      };
    case "negative":
      return {
        text: theme.palette.typography.error,
        background: theme.palette.status.errorBackground,
      };
    default:
      return {
        text: theme.colors.dark,
        background: theme.palette.control.secondaryMedium,
      };
  }
};

const getHoverVariantTextColor = (theme: Theme, variant: FormChipRadioButtonGroupVariant) => {
  switch (variant) {
    case "success":
      return theme.palette.typography.success;
    case "negative":
      return theme.palette.typography.error;
    default:
      return theme.colors.dark;
  }
};

export const RadioButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.sm};
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;

    gap: ${({ theme }) => theme.spaces.md};
  }
`;

export const Label = styled.label<{ variant: FormChipRadioButtonGroupVariant }>(
  ({ theme, variant }) => css`
    position: relative;
    display: block;

    & > input[type="radio"] {
      position: absolute;
      z-index: -1;
      opacity: 0;
      flex: 0 0;

      & + span {
        font-size: ${theme.font.fz3};
        font-weight: 500;
        line-height: ${theme.font.defaultLineHeight};
        padding: ${theme.spaces.sm} ${theme.spaces.md};
        border-radius: 4px;
        border: 1px solid ${theme.secondaryColors.secondaryBorder};
        cursor: pointer;
        user-select: none;
        display: block;
      }

      &:focus-visible + span {
        border-color: ${theme.palette.input.active.border};
      }

      &:disabled + span {
        cursor: default;
        color: ${theme.palette.input.disabled.text};
      }

      &:disabled:checked + span {
        background-color: ${theme.palette.input.disabled.background};
      }

      &:checked:not(:disabled) + span {
        background-color: ${getVariantPalette(theme, variant).background};
        color: ${getVariantPalette(theme, variant).text};
      }

      &:checked:not(:disabled, :focus-visible) + span {
        border-color: ${getVariantPalette(theme, variant).background};
      }

      &:not(:disabled):hover + span {
        background-color: ${theme.palette.control.secondaryLight};
        color: ${getHoverVariantTextColor(theme, variant)};
      }
    }
  `,
);
