import { dateTimeHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { CreditExecutionId } from "../../../../../../common/types/demand";
import { useAppSelector } from "../../../../../../common/store";
import { NavigationState } from "../../../../../../common/navigation/navigation-state";
import {
  ProductListCardFooterDemandLinkMobile,
  ProductListCardItemStageCountersWithBanksLabelContainerMobile,
  ProductListCardMoreActionsButton,
  ProductListItemBadgeMobile,
} from "../../../../../../common/ui/product/list/card";
import {
  selectCreditExecutionListItemsById,
  selectCreditExecutionListItemsClientById,
} from "../../../../store/items/creditExecutionListItems.selectors";
import { useCreditExecutionListItemLink } from "../../../../hooks/useCreditExecutionListItemLink";
import { useCreditExecutionListItemMoreActions } from "../../../../hooks/useCreditExecutionListItemMoreActions";
import { CreditExecutionListItemCardMobile } from "./card/CreditExecutionListItemCard.mobile";
import { CreditExecutionListItemStageCounters } from "../../counters/CreditExecutionListItemStageCounters";
import { CreditExecutionListItemDetails } from "../details/CreditExecutionListItemDetails";
import { CreditExecutionListItemIssueMobile } from "./issue/CreditExecutionListItemIssue.mobile";
import { declensionHelper } from "../../../../../../common/helpers/entity";

export interface CreditExecutionListItemMobileProps extends BaseCssProps {
  creditExecutionId: CreditExecutionId;
  from: NavigationState;
}

export const CreditExecutionListItemMobile = ({
  creditExecutionId,
  from,
  className,
}: CreditExecutionListItemMobileProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const item = useAppSelector((state) => selectCreditExecutionListItemsById(state, creditExecutionId))!;
  const client = useAppSelector((state) => selectCreditExecutionListItemsClientById(state, item.clientId));

  const linkProps = useCreditExecutionListItemLink(creditExecutionId);
  const actions = useCreditExecutionListItemMoreActions(item, from);

  const banksLabel = declensionHelper.commonDeclensions.bank.nominative(item.demandIds.length, true);

  return (
    <CreditExecutionListItemCardMobile
      className={className}
      numberAndDate={`№ ${item.groupProductName} • ${dateTimeHelper.format(
        new Date(item.dateAdded),
        "dayShortMonthYear",
      )}`}
      actionButton={actions.length > 0 && <ProductListCardMoreActionsButton availableActions={actions} />}
      clientName={client?.name}
      footer={
        <ProductListCardFooterDemandLinkMobile {...linkProps} state={from}>
          Перейти к заявке
        </ProductListCardFooterDemandLinkMobile>
      }
      statusBadge={
        <>
          {item.issuedDemandId !== undefined && (
            <ProductListItemBadgeMobile backgroundColor="status.success">Завершена</ProductListItemBadgeMobile>
          )}
          {item.isActive && item.activeTasksCount > 0 && (
            <ProductListItemBadgeMobile backgroundColor="primary">
              {declensionHelper.commonDeclensions.tasks.nominative(item.activeTasksCount, true)}
            </ProductListItemBadgeMobile>
          )}
        </>
      }
      details={<CreditExecutionListItemDetails item={item} />}
    >
      <>
        {item.issuedDemandId !== undefined && <CreditExecutionListItemIssueMobile demandId={item.issuedDemandId} />}
        {item.issuedDemandId === undefined && (
          <ProductListCardItemStageCountersWithBanksLabelContainerMobile banksLabel={banksLabel}>
            <CreditExecutionListItemStageCounters counters={item.counters} />
          </ProductListCardItemStageCountersWithBanksLabelContainerMobile>
        )}
      </>
    </CreditExecutionListItemCardMobile>
  );
};
