import { useRef } from "react";
import { Formik } from "formik";
import { FormBox, FormScrollToFirstErrorAfterSubmit } from "../../../../common/form";
import { AgencyDemandFormContent } from "./AgencyDemandFormContent";
import { agencyDemandFormValidationSchema } from "../../validation/agencyDemandFormValidationSchema";
import { useAgencyDemandFormOnSubmit } from "../../hooks/useAgencyDemandFormOnSubmit";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { NavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { AgencyDemandFormValues } from "../../types/AgencyDemandFormValues";

export interface AgencyDemandFormProps extends BaseCssProps {
  from: NavigationStateFrom;
}

export const AgencyDemandForm = ({ from, className }: AgencyDemandFormProps) => {
  const ref = useRef<HTMLFormElement | null>(null);

  const onSubmit = useAgencyDemandFormOnSubmit(from);

  return (
    <Formik
      initialValues={agencyDemandFormValidationSchema.getDefault() as AgencyDemandFormValues}
      validationSchema={agencyDemandFormValidationSchema}
      onSubmit={onSubmit}
    >
      <FormBox className={className} ref={ref} display="flex" flexDirection="column" flexShrink={1} flexGrow={1}>
        <FormScrollToFirstErrorAfterSubmit containerRef={ref} />
        <AgencyDemandFormContent />
      </FormBox>
    </Formik>
  );
};
