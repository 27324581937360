import { GetQueueTasksNotificationsOutputDto } from "./dto/output/GetQueueTasksNotificationsOutputDto";
import { invokeHubMethod } from "../../../common/hubs";
import { ApiResponse } from "../../../common/api/types";
import { clientV4 } from "../../../common/api";
import { QueueTaskId } from "../../../common/types/demand";
import { QUEUE_TASKS_CONTROLLER_NAME } from "../../common/api/queueTasksApi";
import { queueTasksHubConnection } from "../../../common/hubs-use-cases/queue-tasks";

export const queueTasksNotificationsApi = {
  get: async (): Promise<ApiResponse<GetQueueTasksNotificationsOutputDto>> =>
    invokeHubMethod(queueTasksHubConnection, "GetQueueTasks"),
  read: async (queueTaskId: QueueTaskId) => {
    return clientV4.put(`${QUEUE_TASKS_CONTROLLER_NAME}/read`, { queueTaskId });
  },
};
