import { forwardRef } from "react";
import { CSSObject } from "@emotion/styled";
import { getEmptyArray } from "@sideg/helpers";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { selectBankGuaranteeRewards } from "../store/bankGuaranteeRewards.selectors";
import { useAppSelector } from "../../../common/store";
import { BankGuaranteeRewardsItem } from "./BankGuaranteeRewardsItem";
import * as S from "./BankGuaranteeRewards.styled";
import { isLoading } from "../../../common/types/state";
import { BankGuaranteeRewardsSkeleton } from "./BankGuaranteeRewards.skeleton";
import { BankId } from "../../../common/types/banks";
import { useLocationHash } from "../../../common/filters";

export interface BankGuaranteeRewardsProps extends BaseCssProps {
  paddingTop: CSSObject["paddingTop"];
}

const skeletonItems = getEmptyArray(10);

export const BankGuaranteeRewards = forwardRef<HTMLDivElement, BankGuaranteeRewardsProps>(
  ({ paddingTop, className }, ref) => {
    const { ids, status } = useAppSelector(selectBankGuaranteeRewards);
    const hash = useLocationHash();

    const getRewardsItemRef = (bankId: string) => {
      return hash === bankId ? ref : undefined;
    };

    return (
      <S.Container paddingTop={paddingTop} className={className}>
        <S.ItemsWrapper>
          {isLoading(status) && skeletonItems.map((x) => <BankGuaranteeRewardsSkeleton key={x} />)}
          {ids.map((x) => (
            <BankGuaranteeRewardsItem
              className={className}
              id={x as BankId}
              key={x}
              ref={getRewardsItemRef(String(x))}
            />
          ))}
        </S.ItemsWrapper>
      </S.Container>
    );
  },
);
