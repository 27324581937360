import styled from "@emotion/styled";

export const FullPageContainerHeaderContainer = styled.header`
  background-color: ${({ theme }) => theme.layout.demand.colors.rightSideBackgroundColor};
  padding: ${({ theme }) => theme.spaces.xl};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    background-color: ${({ theme }) => theme.palette.background.light};
    padding: ${({ theme }) => theme.spaces.lg} ${({ theme }) => theme.layout.borderPadding};
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  }
`;
