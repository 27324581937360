import { useAppUrlMatch } from "../../../../common/urls/internal/hooks";
import { useBackLinkHeaderAction } from "../../../../common/ui/layout/header";
import { NavigationStateFrom } from "../../../../common/navigation/navigation-state/types/NavigationStateFrom";
import { useCreditWorkingAssetsDetailsContext } from "../../hooks/context/useCreditWorkingAssetsDetailsContext";

export interface CreditWorkingAssetsDetailsNavigationHeaderMobileProps {
  from: NavigationStateFrom;
}

export const CreditWorkingAssetsDetailsNavigationHeaderMobile = ({
  from,
}: CreditWorkingAssetsDetailsNavigationHeaderMobileProps) => {
  const { creditWorkingAssetsId } = useCreditWorkingAssetsDetailsContext();
  const { isExact, path } = useAppUrlMatch((x) => x.creditWorkingAssets.details.product, { creditWorkingAssetsId });

  useBackLinkHeaderAction({
    withBackIcon: true,
    url: isExact ? from.url : path,
    text: isExact ? from.backTitle : "Заявка на ПОС",
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
