import { useFormikContext } from "formik";
import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import * as S from "./BankGuaranteeListFiltersMobileFormContent.styled";
import { BankGuaranteeListMobileFilterParams } from "../../../../../types/filters/BankGuaranteeListMobileFilterParams";
import { useCustomFieldOnChange, useGetFieldError, useGetFieldName } from "../../../../../../../common/form";
import { useAppSelector } from "../../../../../../../common/store";
import { selectBankGuaranteeListFilters } from "../../../../../store/filters/bankGuaranteeListFilters.selectors";
import {
  FilterFormMobileCheckboxesWithCollapse,
  FilterMobileCollapseUncontrolledContainer,
} from "../../../../../../../common/filters/use-cases";
import { Grid } from "../../../../../../../common/ui-kit/containers/responsive-grid";
import { BankGuaranteeListFiltersBanksWithSearchContainer } from "./BankGuaranteeListFiltersBanksWithSearchContainer";
import { useBankGuaranteeListFilterContext } from "../../../../../hooks/filter-context/useBankGuaranteeListFilterContext";
import { FormSelectMobile } from "../../../../../../../common/ui-kit/form-controls/select";
import { UNSELECTED_ENUM_LITERAL } from "../../../../../../../common/types/utils";
import { bankGuaranteeRequestOriginalStatus } from "../../../../../../common/types";

export const BankGuaranteeListFiltersMobileFormContent = () => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const getFieldName = useGetFieldName<BankGuaranteeListMobileFilterParams>();
  const getFieldError = useGetFieldError<BankGuaranteeListMobileFilterParams>();
  const form = useFormikContext<BankGuaranteeListMobileFilterParams>();
  const onCustomChange = useCustomFieldOnChange<BankGuaranteeListMobileFilterParams>();

  const { data } = useAppSelector(selectBankGuaranteeListFilters);

  return (
    <S.Wrapper>
      {data?.statuses && filteringObject.tab !== "Active" && (
        <FormSelectMobile label="Статус" {...form.getFieldProps(getFieldName("status"))}>
          <option value={UNSELECTED_ENUM_LITERAL}>Все статусы</option>
          {data.statuses.codeWithTitleList.map((x) => (
            <option value={x.code} key={x.code}>
              {x.title}
            </option>
          ))}
        </FormSelectMobile>
      )}
      {data?.guaranteeTypes && (
        <FilterFormMobileCheckboxesWithCollapse<BankGuaranteeListMobileFilterParams>
          title="Типы БГ"
          fieldName="guaranteeTypes"
          items={data.guaranteeTypes.codeWithTitleList}
        />
      )}
      {data?.taskTypes && filteringObject.tab !== "Completed" && (
        <FilterFormMobileCheckboxesWithCollapse<BankGuaranteeListMobileFilterParams>
          title="Задачи"
          fieldName="taskTypes"
          items={data.taskTypes.codeWithTitleList}
        />
      )}
      {filteringObject.tab !== "Active" && (
        <FilterFormMobileCheckboxesWithCollapse<BankGuaranteeListMobileFilterParams>
          title="Запрос Оригинала"
          fieldName="requestOriginalStatuses"
          items={bankGuaranteeRequestOriginalStatus.values.map((value) => ({
            code: value,
            title: bankGuaranteeRequestOriginalStatus.getTitle(value),
          }))}
        />
      )}
      <FilterMobileCollapseUncontrolledContainer title="Дата создания">
        <Grid container gap="md">
          <Grid
            item
            as={DateFormInput}
            label="Дата начала"
            xs={6}
            {...form.getFieldProps(getFieldName("dateCreateFrom"))}
            onChange={(e: string) => onCustomChange(form.setFieldValue, "dateCreateFrom", e, true)}
            error={getFieldError("dateCreateFrom", form.touched.dateCreateFrom, form.errors.dateCreateFrom)}
            autoComplete="off"
          />
          <Grid
            item
            as={DateFormInput}
            label="Дата окончания"
            xs={6}
            {...form.getFieldProps(getFieldName("dateCreateTo"))}
            onChange={(e: string) => onCustomChange(form.setFieldValue, "dateCreateTo", e, true)}
            error={getFieldError("dateCreateTo", form.touched.dateCreateTo, form.errors.dateCreateTo)}
            autoComplete="off"
          />
        </Grid>
      </FilterMobileCollapseUncontrolledContainer>
      {data?.banks && <BankGuaranteeListFiltersBanksWithSearchContainer />}
    </S.Wrapper>
  );
};
