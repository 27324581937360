import { useCallback } from "react";
import { useApiMutation } from "../../../../common/hooks";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { DocumentTypeId } from "../../../../common/types/documents";
import { pushSuccessGlobalToast } from "../../../../global/toasts";
import { uploadGroupDocumentCreditExecutionDetails } from "../../store/creditExecutionDetails.thunks";
import { selectCreditExecutionDetailsDocumentById } from "../../store/documents/creditExecutionDetailsDocuments.selectors";
import { markAsUploadedCreditExecutionDetailsDocuments } from "../../store/documents/creditExecutionDetailsDocuments.slice";
import { useCreditExecutionDetailsContext } from "../context/useCreditExecutionDetailsContext";

export const useCreditExecutionDetailsDocumentsHandleDrop = (documentId: DocumentTypeId) => {
  const dispatch = useAppDispatch();
  const { creditExecutionId } = useCreditExecutionDetailsContext();

  const document = useAppSelector((state) => selectCreditExecutionDetailsDocumentById(state, documentId));

  const { status, error, mutate } = useApiMutation(uploadGroupDocumentCreditExecutionDetails, {
    errorMessagePlaceholder: "Файл не загружен. Повторите попытку",
  });

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (document?.id !== undefined && document?.requestedDocType !== undefined && acceptedFiles.length === 1) {
        const result = await mutate({
          creditExecutionId,
          id: document.id,
          content: acceptedFiles[0],
          requestedDocType: document.requestedDocType,
        });

        if (result !== undefined) {
          dispatch(pushSuccessGlobalToast("Файл прикреплен", 2_000));
          dispatch(markAsUploadedCreditExecutionDetailsDocuments(document.id));
        }
      }
    },
    [creditExecutionId, dispatch, document?.id, document?.requestedDocType, mutate]
  );

  return {
    document,
    status,
    error,
    handleDrop,
  };
};
