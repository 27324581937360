import { Fragment } from "react";
import { ConditionItem, ConditionItemTitle } from "../../../common/ui/layout/conditions";
import { CreditExecutionCondition } from "../api/dto/output/GetCreditExecutionConditionsOutputDto";

export interface CreditExecutionConditionsTableBodyProps {
  conditions: CreditExecutionCondition[];
}

export const CreditExecutionConditionsTableBody = ({ conditions }: CreditExecutionConditionsTableBodyProps) => {
  return (
    <>
      {conditions.map((item) => (
        <Fragment key={item.id}>
          <ConditionItemTitle>{item.bank.shortName}</ConditionItemTitle>
          <ConditionItem>{item.maxSum}</ConditionItem>
          <ConditionItem>{item.maxTerm}</ConditionItem>
          <ConditionItem>{item.creditAvailabilityBg ? "Да" : "-"}</ConditionItem>
          <ConditionItem>{item.companyLifeTime}</ConditionItem>
          <ConditionItem>{item.creditExperience}</ConditionItem>
          <ConditionItem>{item.stopFactors}</ConditionItem>
          <ConditionItem>{item.creditRatePerIssue}</ConditionItem>
          <ConditionItem>{item.creditRateAnnual}</ConditionItem>
          <ConditionItem>{item.creditAgentReward}</ConditionItem>
        </Fragment>
      ))}
    </>
  );
};
