import { Form, Formik } from "formik";
import { useRef } from "react";
import { QueueTaskId } from "../../../../../common/types/demand";
import { CodeWithTitle } from "../../../../../common/types/objects";
import { FormScrollToFirstErrorAfterSubmit } from "../../../../../common/form";
import { useDemandQueueTaskRequestOptionsFormOnSubmit } from "../../../hooks/active/form/useDemandQueueTaskRequestOptionsFormOnSubmit";
import { DemandQueueTaskRequestOptionsBlock } from "../request-options-block/DemandQueueTaskRequestOptionsBlock";
import { DemandQueueTaskActiveDecision } from "../../../index";
import { demandQueueTaskRequestOptionValidationSchema } from "../../../validation/demandQueueTaskRequestOptionValidationSchema";

export interface BankGuaranteeDetailsComplicatedTaskRequestOptionsFormProps {
  queueTaskId: QueueTaskId;
  requestOptions: CodeWithTitle[];
  decision: DemandQueueTaskActiveDecision;
}

export const DemandQueueTaskRequestOptionsForm = ({
  queueTaskId,
  requestOptions,
  decision,
}: BankGuaranteeDetailsComplicatedTaskRequestOptionsFormProps) => {
  const validationSchema = demandQueueTaskRequestOptionValidationSchema;
  const initialValues = validationSchema.getDefault();

  const onSubmit = useDemandQueueTaskRequestOptionsFormOnSubmit(queueTaskId, decision.code);

  const formRef = useRef<HTMLFormElement | null>(null);

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      <Form ref={formRef}>
        <FormScrollToFirstErrorAfterSubmit containerRef={formRef} />
        <DemandQueueTaskRequestOptionsBlock requestOptions={requestOptions} decision={decision} />
      </Form>
    </Formik>
  );
};
