import { useMemo } from "react";
import { isNeedToHideProductDetailsDocuments } from "../../../../common/product-details";
import { useAppSelector } from "../../../../common/store";
import { useAppUrlMatch } from "../../../../common/urls/internal/hooks";
import { selectCreditWorkingAssetsDetailsDocumentsIds } from "../../store/documents/creditWorkingAssetsDetailsDocuments.selectors";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";
import { useCreditWorkingAssetsDetailsContext } from "../context/useCreditWorkingAssetsDetailsContext";

export const useCreditWorkingAssetsDetailsDocuments = () => {
  const ids = useAppSelector(selectCreditWorkingAssetsDetailsDocumentsIds);
  const { data, status } = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails);
  const { creditWorkingAssetsId } = useCreditWorkingAssetsDetailsContext();
  const { isExact, path } = useAppUrlMatch((x) => x.creditWorkingAssets.details.documents, {
    creditWorkingAssetsId,
  });

  return useMemo(() => {
    const isNeedToHide = isNeedToHideProductDetailsDocuments(ids, data, status, isExact);

    return {
      ids,
      path,
      isExact,
      data,
      isNeedToHide,
      status,
    };
  }, [data, ids, isExact, path, status]);
};
