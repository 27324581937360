import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { DemandId } from "../../../../../../../common/types/demand";
import { useAppSelector } from "../../../../../../../common/store";
import { Typography } from "../../../../../../../common/ui-kit/typography";
import { StackPanel } from "../../../../../../../common/ui-kit/containers/stack-panel";
import { CircleIndicator } from "../../../../../../../common/ui-kit/notifications/circle-indicator";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { FileDownloadLabel } from "../../../../../../../files/download";
import { selectCreditExecutionListItemsDemandById } from "../../../../../store/items/creditExecutionListItems.selectors";

export interface CreditExecutionListItemIssueMobileProps {
  demandId: DemandId;
}

export const CreditExecutionListItemIssueMobile = ({ demandId }: CreditExecutionListItemIssueMobileProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectCreditExecutionListItemsDemandById(state, demandId))!;

  return (
    <>
      <StackPanel direction="row" gap="sm" alignItems="center">
        <Typography fontSize="fz3" fontWeight={700} lineHeight="defaultLineHeight" fontColor="success">
          Заявка выпущена
        </Typography>
        {demand.isUpdated && <CircleIndicator color={(x) => x.secondaryColors.notificationBackground} size={8} />}
      </StackPanel>
      {demand.issue && (
        <>
          <Typography fontSize="fz3" fontWeight={500} lineHeight="defaultLineHeight" fontColor="typography.primary">
            {demand.bank.title}&nbsp;—&nbsp;
            {demand.issue.dateIssue &&
              dateTimeHelper.format(new Date(demand.issue.dateIssue), specialFormatters.dayFullMonthYearIfDifferent)}
          </Typography>
          <Box py="sm" display="flex" flexDirection="column" gap="sm" alignItems="flex-start">
            {demand.issue.original && (
              <FileDownloadLabel
                fileId={demand.issue.original.fileId}
                privateKey={demand.issue.original.privateKey}
                fileName="Скан-копия оригинала"
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};
