import { entityHelper } from "@sideg/helpers";
import * as S from "./UserDetailsUserMenuManagerBlock.desktop.styled";
import { UserManager } from "../../../types/UserManager";
import { Text } from "../../../../../common/ui-kit/typography";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { CircleIndicator } from "../../../../../common/ui-kit/notifications/circle-indicator";

interface UserDetailsUserMenuManagerBlockProps {
  manager: UserManager;
}

export const UserDetailsUserMenuManagerBlock = ({ manager }: UserDetailsUserMenuManagerBlockProps) => {
  return (
    <S.ManagerDetailsContainer>
      {manager.isReplaced && (
        <StackPanel direction="row" gap="sm" alignItems="center">
          <CircleIndicator size={9} color={(x) => x.secondaryColors.notificationBackground} />
          <Text fontWeight={500} fontSize="fz2" fontColor="colors.infoLight">
            У вас новый менеджер
          </Text>
        </StackPanel>
      )}
      <Text fontWeight={700} fontSize="fz3">
        {manager.userName}
      </Text>
      {manager.phone && (
        <S.ManagerDetailsLink href={`tel:+${manager.phone}`}>
          {entityHelper.formatPhoneNumber(manager.phone)}
        </S.ManagerDetailsLink>
      )}
      {manager.email && <S.ManagerDetailsLink href={`mailto:${manager.email}`}>{manager.email}</S.ManagerDetailsLink>}
    </S.ManagerDetailsContainer>
  );
};
