import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { ProductListItemDemandItemLayoutDesktop } from "../../../../../../../common/ui/product/list/product-list-item";
import { CreditWorkingAssetsListItemDemandDesktopSkeleton } from "../item/CreditWorkingAssetsListItemDemand.desktop.skeleton";

export type CreditWorkingAssetsListItemDemandsListDesktopSkeletonProps = BaseCssProps;

export const CreditWorkingAssetsListItemDemandsListDesktopSkeleton = ({
  className,
}: CreditWorkingAssetsListItemDemandsListDesktopSkeletonProps) => {
  return (
    <ProductListItemDemandItemLayoutDesktop.Container className={className}>
      <CreditWorkingAssetsListItemDemandDesktopSkeleton />
      <CreditWorkingAssetsListItemDemandDesktopSkeleton />
      <CreditWorkingAssetsListItemDemandDesktopSkeleton />
      <CreditWorkingAssetsListItemDemandDesktopSkeleton />
    </ProductListItemDemandItemLayoutDesktop.Container>
  );
};
