import { Theme } from "@sideg/ui-kit";
import { Color } from "../../../ui-kit/theme";

export type ProductCreationScoringPopupVariant = "primary" | "error";

export const getProductCreationScoringPopupColorByVariant = (
  theme: Theme,
  type: ProductCreationScoringPopupVariant,
): { border: Color; background: Color } => {
  if (type === "error") {
    return { border: theme.palette.status.error, background: theme.colors.dangerLight };
  }

  return { border: theme.colors.primary, background: theme.secondaryColors.primaryBackground };
};
