import { Breakpoints, BreakpointsValues } from "@sideg/ui-kit/common/types";
import { themeDefaultBreakpoints } from "@sideg/ui-kit/core/theme/static/themeDefaultBreakpoints";
import { PrivateTheme, PrivateThemeBreakpoints } from "@sideg/ui-kit/core/theme/types/PrivateTheme";
import { generatedPalette, ThemePalette } from "@sideg/ui-kit/core/theme/types/colors/ThemePalette";
import { generatedEffects, ThemeEffects } from "@sideg/ui-kit/core/theme/types/effects/ThemeEffects";
import { generatedSpaces, makeThemeSpacing, ThemeSpaces } from "@sideg/ui-kit/core/theme/types/spacing/ThemeSpacing";
import { generatedRadii, ThemeRadiiValue } from "@sideg/ui-kit/core/theme/types/radii/ThemeRadii";
import {
  generatedTypographyVariants,
  themeTypographyFontSizes,
  themeTypographyFontWeights,
  themeTypographyLineHeights,
  ThemeTypographyVariants,
} from "@sideg/ui-kit/core/theme/types/typography/ThemeTypography.static";

// Why subtract .02px? Browsers don’t currently support range context queries, so we work around
// the limitations of min- and max- prefixes and viewports with fractional widths
// (which can occur under certain conditions on high-dpi devices, for instance) by using values
// with higher precision.
const calculateMaxWidth = (width: number) => {
  return width <= 0 ? width : width - 0.02;
};

export interface CreatePrivateThemeOptions {
  breakpoints?: BreakpointsValues;
  palette?: ThemePalette;
  effects?: ThemeEffects;
  typographyVariants?: ThemeTypographyVariants;
  spaces?: ThemeSpaces;
  radii?: ThemeRadiiValue;
  desktopBreakpoint?: Breakpoints;
}

export const createPrivateTheme = ({
  breakpoints = themeDefaultBreakpoints,
  palette = generatedPalette,
  effects = generatedEffects,
  typographyVariants = generatedTypographyVariants,
  spaces = generatedSpaces,
  radii = generatedRadii,
  desktopBreakpoint = "xl",
}: CreatePrivateThemeOptions): PrivateTheme => {
  return {
    palette,
    effects,
    typography: {
      variants: typographyVariants,
      lineHeights: themeTypographyLineHeights,
      sizes: themeTypographyFontSizes,
      weights: themeTypographyFontWeights,
    },
    spacing: makeThemeSpacing(spaces),
    radii,
    breakpoints: {
      values: breakpoints,
      desktopBreakpoint,
      up(this: PrivateThemeBreakpoints, size: Breakpoints) {
        return `(min-width: ${this.values[size]}px)`;
      },
      mqUp(this: PrivateThemeBreakpoints, size: Breakpoints) {
        return `@media ${this.up(size)}`;
      },
      mqUpDesktop(this: PrivateThemeBreakpoints) {
        return this.mqUp(this.desktopBreakpoint);
      },
      mqDown(this: PrivateThemeBreakpoints, size: Breakpoints) {
        return `@media (max-width: ${calculateMaxWidth(this.values[size])}px)`;
      },
      mqBetween(this: PrivateThemeBreakpoints, min: Breakpoints, max: Breakpoints) {
        return `@media (min-width: ${this.values[min]}px) and (max-width: ${calculateMaxWidth(this.values[max])}px)`;
      },
    },
    zIndex: {
      tooltip: 50,
    },
  };
};
