import { BaseCssProps } from "../../../../common/ui-kit/types";
import { UseLegalServicesCreationPageStateType } from "../../hooks/useLegalServicesCreationPageState";
import { LegalServicesCreationDoneCard } from "./card/LegalServicesCreationDoneCard";
import * as S from "./LegalServicesCreationResult.styled";
import { LegalServicesCreationErrorCard } from "./card/LegalServicesCreationErrorCard";

export interface LegalServicesCreationResultProps extends BaseCssProps {
  state: Extract<UseLegalServicesCreationPageStateType, "failed" | "succeeded">;
  error?: string;
}

export const LegalServicesCreationResult = ({ state, error, className }: LegalServicesCreationResultProps) => {
  return (
    <S.Container className={className}>
      {state === "succeeded" && <LegalServicesCreationDoneCard />}
      {state === "failed" && <LegalServicesCreationErrorCard error={error} />}
    </S.Container>
  );
};
