import { Form, Formik, FormikProps } from "formik";
import { FormResponsiveRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox/components/radio-group/FormResponsiveRadioButtonGroup";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { ClientCheckFormValues } from "../../types/ClientCheckFormValues";
import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";
import { clientCheckValidationSchema } from "../../validation/clientCheckValidationSchema";
import { useGetFieldName, useGetFormFieldError } from "../../../../common/form";
import { clientCheckReportType } from "../../types/ClientCheckReportType";
import { TaxpayerFormInput } from "../../../../common/ui/form-controls/taxpayer-input";
import { ClientHeaderFormAutoSubmit } from "./ClientHeaderFormAutoSubmit";
import * as S from "./ClientCheckHeader.styled";

export interface ClientCheckHeaderProps extends BaseCssProps {
  onFormSubmit: (values: ClientCheckFormValues) => Promise<void>;
}

export const ClientCheckHeader = ({ className, onFormSubmit }: ClientCheckHeaderProps) => {
  const getFieldName = useGetFieldName<ClientCheckFormValues>();
  const getFieldError = useGetFormFieldError<ClientCheckFormValues>();

  return (
    <AppLayoutResponsiveListPage.Header className={className}>
      <AppLayoutResponsiveListPage.Title>Проверка клиента</AppLayoutResponsiveListPage.Title>
      <Formik
        initialValues={clientCheckValidationSchema.getDefault()}
        validationSchema={clientCheckValidationSchema}
        onSubmit={onFormSubmit}
        initialErrors={{ taxpayerNumber: "" }}
      >
        {(form: FormikProps<ClientCheckFormValues>) => (
          <S.FormContainer as={Form}>
            <ClientHeaderFormAutoSubmit />
            <FormResponsiveRadioButtonGroup
              value={form.values.reportType}
              onChange={(newValue) => {
                form.setFieldValue(getFieldName("reportType"), newValue, true);
              }}
              onBlur={() => {
                form.setFieldTouched(getFieldName("reportType"), true, true);
              }}
              label="Вид отчета"
              radioSize="default"
              itemsPlacement="start"
              types={clientCheckReportType.values}
              getTitle={clientCheckReportType.getTitle}
              name={getFieldName("reportType")}
              error={getFieldError(form, "reportType")}
              disabled={form.isSubmitting}
            />
            <TaxpayerFormInput
              label="ИНН клиента"
              autoComplete="off"
              {...form.getFieldProps(getFieldName("taxpayerNumber"))}
              disabled={form.isSubmitting}
              error={getFieldError(form, "taxpayerNumber")}
            />
          </S.FormContainer>
        )}
      </Formik>
    </AppLayoutResponsiveListPage.Header>
  );
};
