import { useMemo } from "react";
import { NavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { useBankGuaranteeDetailsWithRequiredDemandIdLayoutContext } from "./useBankGuaranteeDetailsLayoutContext";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDetailsDemandById } from "../../store/demands/bankGuaranteeDetailsDemands.selectors";
import { useBankGuaranteeDetailsRootPathMatch } from "../useBankGuaranteeDetailsRootPathMatch";
import { useBankGuaranteeDetailsIsDemandNestedPath } from "../useBankGuaranteeDetailsIsDemandNestedPath";
import { generatePath } from "../../../../common/urls/internal/utils";
import { bankGuaranteeDetailsPageUrlSelectors } from "../../configs/bankGuaranteeDetailsPageUrlSelectors";

export const useBankGuaranteeDetailsHeaderNavigationMobileFrom = (
  initialFrom: NavigationStateFrom,
): NavigationStateFrom => {
  const { guaranteeId, demandId } = useBankGuaranteeDetailsWithRequiredDemandIdLayoutContext();
  const demand = useAppSelector((state) => selectBankGuaranteeDetailsDemandById(state, demandId));

  const { isExact: isRootPage, path } = useBankGuaranteeDetailsRootPathMatch(guaranteeId);
  const isDemandNestedPath = useBankGuaranteeDetailsIsDemandNestedPath(guaranteeId, demandId);

  return useMemo(() => {
    if (isRootPage) {
      return initialFrom;
    }

    if (isDemandNestedPath) {
      return {
        url: generatePath(bankGuaranteeDetailsPageUrlSelectors.demand, { demandId, guaranteeId }),
        backTitle: demand?.bank.title ?? "Заявка в банк",
      };
    }

    return { url: path, backTitle: "Заявка на БГ" };
  }, [demand?.bank.title, demandId, guaranteeId, initialFrom, isDemandNestedPath, isRootPage, path]);
};
