import { useMemo } from "react";
import { useBankGuaranteeCreationForm } from "../useBankGuaranteeCreationForm";
import { enforcementSumRules } from "../../../validation/rules/enforcementSum.rules";

export const useBankGuaranteeCreationEnforcementSumField = () => {
  const {
    values: { guaranteeType },
  } = useBankGuaranteeCreationForm();

  return useMemo(() => {
    return enforcementSumRules.isExists(guaranteeType);
  }, [guaranteeType]);
};
