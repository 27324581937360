import { DocumentTypeId } from "../../../../common/types/documents";
import { ProductDetailsDocumentsDocumentItem } from "../../../../common/product-details";
import { useCreditExecutionDetailsDocumentsHandleDrop } from "../../hooks/documents/useCreditExecutionDetailsDocumentsHandleDrop";

export interface CreditExecutionDetailsDocumentsItemProps {
  documentId: DocumentTypeId;
}

export const CreditExecutionDetailsDocumentsItem = ({ documentId }: CreditExecutionDetailsDocumentsItemProps) => {
  const { document, status, error, handleDrop } = useCreditExecutionDetailsDocumentsHandleDrop(documentId);

  return (
    <ProductDetailsDocumentsDocumentItem document={document} handleDrop={handleDrop} error={error} status={status} />
  );
};
