import * as React from "react";
import * as S from "./WidgetsNewsSliderDotButton.styled";

export interface WidgetsNewsSliderDotButtonProps {
  isActive?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const WidgetsNewsSliderDotButton = ({ isActive, onClick }: WidgetsNewsSliderDotButtonProps) => {
  return <S.Button isActive={isActive} onClick={onClick} />;
};
