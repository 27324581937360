import * as S from "@sideg/ui-kit/atoms/form-controls/date-input/components/DateFormInputPickerButton.styled";
import { ChangeEventHandler, useMemo, useRef } from "react";
import { ControlSize } from "@sideg/ui-kit/common/types";
import { dateTimeHelper } from "@sideg/helpers/date";

export interface DateFormInputPickerButtonProps {
  disabled?: boolean;
  size: ControlSize;
  min?: Date;
  max?: Date;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const DateFormInputPickerButton = ({
  disabled,
  size,
  min,
  max,
  value,
  onChange,
}: DateFormInputPickerButtonProps) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const minValue = useMemo(() => (min ? dateTimeHelper.format(min, "yearMonthDayDashed") : undefined), [min]);
  const maxValue = useMemo(() => (max ? dateTimeHelper.format(max, "yearMonthDayDashed") : undefined), [max]);

  return (
    <S.PickerButtonWrapper>
      <S.PickerButton
        type="button"
        disabled={disabled}
        onClick={() => {
          if (ref?.current && "showPicker" in ref.current) {
            (ref.current as { showPicker: () => void }).showPicker();
          }
        }}
      >
        <S.ControlCalendarIcon size={size} />
      </S.PickerButton>
      <S.DatePickerInput
        disabled={disabled}
        ref={ref}
        tabIndex={-1}
        min={minValue}
        max={maxValue}
        value={value}
        type="date"
        onInput={(e) => {
          const { target } = e.nativeEvent;

          const iosClearDefault = () => {
            if (target !== null && "defaultValue" in target) {
              (target as { defaultValue: string }).defaultValue = "";
            }
          };

          setTimeout(iosClearDefault, 0);
        }}
        onChange={onChange}
      />
    </S.PickerButtonWrapper>
  );
};
