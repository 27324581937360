export interface EnumLikeLiteral<T extends string | boolean | number> {
  values: T[];
  getTitle: (value: T) => string;
}

export function makeEnumLikeLiteral<TValues extends string | number>(
  titles: Record<TValues, string>,
): EnumLikeLiteral<TValues>;
export function makeEnumLikeLiteral<T extends string | number>(
  values: readonly T[],
  titles: Record<T, string>,
): EnumLikeLiteral<T>;
// eslint-disable-next-line func-style
export function makeEnumLikeLiteral<T extends string | number>(
  values: readonly T[] | Record<T, string>,
  titles?: Record<T, string>,
): EnumLikeLiteral<T> {
  const literalValues: T[] = Array.isArray(values) ? Array.from(values) : (Object.keys(values) as T[]);
  const literalTitles: Record<T, string> = titles ?? (values as Record<T, string>);

  return {
    values: literalValues,
    getTitle: (value) => literalTitles[value],
  };
}

export const makeBooleanEnumLikeLiteral = <T extends boolean>(
  values: readonly T[],
  titles: Record<"true" | "false", string>,
): EnumLikeLiteral<T> => {
  return {
    values: Array.from(values),
    getTitle: (value) => titles[value ? "true" : "false"],
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EnumLikeLiteralType<T extends EnumLikeLiteral<any>> = T extends EnumLikeLiteral<infer U> ? U : never;
