import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeCreation } from "../../store/bankGuaranteeCreation.selectors";
import { useNavigationStateFromWithFallback } from "../../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../../common/urls/internal/utils";
import { changeStageBankGuaranteeCreation } from "../../store/bankGuaranteeCreation.slice";
import { ProductCreationStages, ProductCreationPageHeader } from "../../../../common/product-creation";

export const BankGuaranteeCreationPageHeader = () => {
  const dispatch = useAppDispatch();

  const { activeStage } = useAppSelector(selectBankGuaranteeCreation);

  const { from } = useNavigationStateFromWithFallback(selectAppUrl((x) => x.bankGuarantee.list.root).url, "Мои заявки");

  const onPrevStageClick = (stage: ProductCreationStages) => {
    dispatch(changeStageBankGuaranteeCreation(stage));
  };

  return (
    <ProductCreationPageHeader
      activeStage={activeStage}
      from={from}
      onPrevStageClick={onPrevStageClick}
      title="Новая заявка на банковскую гарантию"
    />
  );
};
