import { useEffect, useRef } from "react";
import { BankGuaranteeId } from "../../../common/types/demand";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { AsyncThunkActionRequest } from "../../../common/types/thunks";
import { isIdle } from "../../../common/types/state";
import { getBankGuaranteeBrief } from "../store/bankGuaranteeBrief.thunks";
import { actualizeBankGuaranteeBrief } from "../store/bankGuaranteeBrief.slice";
import { selectBankGuaranteeBrief } from "../store/bankGuaranteeBrief.selectors";

export const useBankGuaranteeBriefFetcher = (guaranteeId: BankGuaranteeId) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectBankGuaranteeBrief);

  const requestRef = useRef<AsyncThunkActionRequest<{ guaranteeId: BankGuaranteeId }> | undefined>(undefined);

  useEffect(() => {
    dispatch(actualizeBankGuaranteeBrief(guaranteeId));
  }, [dispatch, guaranteeId]);

  useEffect(() => {
    if (isIdle(state.status)) {
      requestRef.current = dispatch(getBankGuaranteeBrief({ guaranteeId }));
    }

    return () => {
      if (requestRef.current && guaranteeId !== requestRef.current.arg.guaranteeId) {
        requestRef.current.abort();
        requestRef.current = undefined;
      }
    };
  }, [dispatch, guaranteeId, state.status]);

  return state;
};
