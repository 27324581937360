import { useFormikContext } from "formik";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { FormInputPassword } from "@sideg/ui-kit/atoms/form-controls/password-input";
import { Box } from "../../../../common/ui-kit/containers/box";
import { StyledAppLink } from "../../../../common/ui/links";
import { PublicUserAuthenticationFromValues } from "../../types/PublicUserAuthenticationFromValues";
import { useGetFieldName, useGetFormFieldError } from "../../../../common/form";
import { ButtonLoading } from "../../../../common/ui-kit/form-controls/button";

const BoxAppLink = Box.withComponent(StyledAppLink);

export const PublicUserAuthenticationFormContent = () => {
  const getFieldName = useGetFieldName<PublicUserAuthenticationFromValues>();
  const getFieldError = useGetFormFieldError<PublicUserAuthenticationFromValues>();

  const { errors, touched, isSubmitting, getFieldProps } = useFormikContext<PublicUserAuthenticationFromValues>();

  return (
    <>
      <Box gap="lg" flexDirection="column" display="flex">
        <FormInput
          type="text"
          {...getFieldProps(getFieldName("login"))}
          error={getFieldError({ errors, touched }, getFieldName("login"))}
          label="Логин"
          autoComplete="username"
          autoCorrect="off"
          autoCapitalize="off"
          disabled={isSubmitting}
        />
        <Box gap="sm" flexDirection="column" display="flex">
          <FormInputPassword
            label="Пароль"
            {...getFieldProps(getFieldName("password"))}
            error={getFieldError({ errors, touched }, getFieldName("password"))}
            autoComplete="current-password"
            autoCorrect="off"
            autoCapitalize="off"
            disabled={isSubmitting}
          />
          <BoxAppLink alignSelf="flex-start" to={(x) => x.publicUser.resetPassword.forgotPassword}>
            Забыли пароль?
          </BoxAppLink>
        </Box>
      </Box>
      <ButtonLoading isFullWidth size="medium" loaderPosition="replace" type="submit" isLoading={isSubmitting}>
        Войти
      </ButtonLoading>
    </>
  );
};
