import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../common/store";
import { PublicUserRegistrationAgentState } from "./publicUserRegistrationAgent.types";
import { publicUserRegistrationAgentAdapter } from "./publicUserRegistrationAgent.adapter";

export const selectPublicUserRegistrationAgent = (state: RootState): PublicUserRegistrationAgentState =>
  state.domains.public.registration.agent;

const {
  selectAll,
  selectById: selectPublicUserRegistrationAgentDocumentById,
  selectIds: selectPublicUserRegistrationAgentIds,
} = publicUserRegistrationAgentAdapter.getSelectors(
  (state: RootState) => selectPublicUserRegistrationAgent(state).documents
);

export { selectPublicUserRegistrationAgentDocumentById, selectPublicUserRegistrationAgentIds };

export const selectPublicUserRegistrationAgentIsAnyDocumentSigning = createSelector(selectAll, (documents) => {
  return documents.some((x) => x.signState === "signing");
});

export const selectPublicUserRegistrationAgentIsAllDocumentsSigned = createSelector(selectAll, (documents) => {
  return documents.every((x) => x.signState === "signed");
});
