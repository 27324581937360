import { useField } from "formik";
import { useMemo } from "react";
import { useGetFieldName } from "../../../common/form";
import { SettlementAndCashCreationFormValues } from "../types/SettlementAndCashCreationFormValues";
import { BankId } from "../../../common/types/banks";

export const useSettlementsAndCashCreationBanksItemFieldState = (bankId: BankId) => {
  const getFieldName = useGetFieldName<SettlementAndCashCreationFormValues>();
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<string[]>(getFieldName("banks"));

  return useMemo(() => {
    const isSelected = value.includes(bankId);

    const toggleBank = async () => {
      await setTouched(true, false);

      if (isSelected) {
        await setValue(
          value.filter((x) => x !== bankId),
          true,
        );

        return;
      }

      await setValue([...value, bankId], true);
    };

    return { value, error: touched ? error : undefined, toggleBank, isSelected };
  }, [bankId, error, setTouched, setValue, touched, value]);
};
