import { forwardRef } from "react";
import { useElementId } from "@sideg/ui-kit/atoms/form-controls/common/hooks";
import { BaseFormElementErrorProps, BaseFormInputElement } from "../../../common/components";
import * as S from "./FormSelect.mobile.styled";
import { BaseCssProps } from "../../../../types";

export interface FormSelectMobileProps
  extends BaseCssProps,
    BaseFormElementErrorProps,
    Omit<JSX.IntrinsicElements["select"], "className" | "ref"> {
  label?: string;
}

export const FormSelectMobile = forwardRef<HTMLSelectElement, FormSelectMobileProps>(
  ({ label, id, error, errorDataTestId, name, className, ...rest }, ref) => {
    const elementId = useElementId("select", id, name);

    return (
      <BaseFormInputElement
        error={error}
        errorDataTestId={errorDataTestId}
        label={label}
        labelFor={elementId}
        className={className}
      >
        <S.Select ref={ref} id={elementId} name={name} {...rest} />
        <S.Icon />
      </BaseFormInputElement>
    );
  },
);
