import { UserFeatureFlagEnum, UserRoleEnum } from "../../../types/user";
import { AppMenuItem } from "../types/AppMenuItem";
import { AppMenuGroup } from "../types/AppMenuGroup";
import { appMenu } from "../appMenu";
import { RootState } from "../../../store";
import { IndexAppUrl } from "../../../urls/internal/types";
import { routeAccessService } from "../../../routes/services";

export type MenuItemNestingLevel = 0 | 1 | 2;

export interface MenuItem {
  title: string;
  nestingLevel: MenuItemNestingLevel;
  url?: IndexAppUrl<string>;
  isGroupingItem: boolean;
  matches: string[];
  items?: MenuItem[];
  counterSelector?: (state: RootState) => number | string | undefined;
}

const concatMenuMatches = (items: MenuItem[]): string[] => {
  return items.flatMap((x) => (x.items && x.items.length > 0 ? concatMenuMatches(x.items) : x.matches));
};

const getMenuItemMatches = ({ matches, url: { url } }: AppMenuItem): string[] => {
  if (typeof matches === "string") {
    return [matches];
  }

  if (Array.isArray(matches)) {
    return matches;
  }

  return [url];
};

const createGroupMenuItem = (title: string, items: MenuItem[], nestingLevel: MenuItemNestingLevel) => {
  return {
    title,
    items,
    nestingLevel,
    isGroupingItem: true,
    matches: concatMenuMatches(items),
  };
};

const createMenuItem = (item: AppMenuItem, nestingLevel: MenuItemNestingLevel) => {
  return {
    title: item.title,
    isGroupingItem: false,
    url: item.url,
    matches: getMenuItemMatches(item),
    nestingLevel,
    counterSelector: item.counterSelector,
  };
};

const filterMenu = (
  menuItems: (AppMenuItem | AppMenuGroup)[],
  role: UserRoleEnum,
  featureFlags: UserFeatureFlagEnum[],
  nestingLevel: MenuItemNestingLevel,
): MenuItem[] => {
  if (nestingLevel > 2) {
    throw new Error("Превышен максимальный уровень вложенности в меню");
  }

  return menuItems
    .map((x): MenuItem | undefined => {
      const { items, title } = x as AppMenuGroup;
      if (items?.length && items.length > 0) {
        const nestedItems = filterMenu(items, role, featureFlags, (nestingLevel + 1) as MenuItemNestingLevel);

        return nestedItems.length > 0 ? createGroupMenuItem(title, nestedItems, nestingLevel) : undefined;
      }

      const item = x as AppMenuItem;

      return item.url?.url &&
        routeAccessService.canAccess(true, featureFlags, item.url.urlType, item.url.checkPermission)
        ? createMenuItem(item, nestingLevel)
        : undefined;
    })
    .filter((x): x is MenuItem => x !== undefined);
};

export const getUserMenu = (role: UserRoleEnum, featureFlags: UserFeatureFlagEnum[]) =>
  filterMenu(appMenu, role, featureFlags, 0);
