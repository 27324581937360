import { useCallback, useMemo } from "react";
import { useField } from "formik";
import { useGetFieldError } from "./useGetFieldError";

export const useDateField = (fieldName: string) => {
  const [{ value, onBlur }, { error, touched, initialValue }, helpers] = useField(fieldName);
  const getFieldError = useGetFieldError();

  const onCustomChange: (date: string) => void = useCallback(
    (e) => {
      helpers.setValue(e, true);
    },
    [helpers]
  );

  return useMemo(
    () => [
      {
        value,
        onChange: onCustomChange,
        onBlur,
        name: fieldName,
        initialValue,
        error: getFieldError(fieldName, touched, error),
      },
      helpers,
    ],
    [error, fieldName, getFieldError, helpers, initialValue, onBlur, onCustomChange, touched, value]
  );
};
