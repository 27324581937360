import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const ItemsUl = styled.ul`
  padding: 0;
  margin: 0;
`;
