import styled from "@emotion/styled";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";
import { CSSObject } from "@emotion/react";

export type MaxHeightAppearanceTransitionProps = CSSTransitionProps & {
  maxHeightEnter: CSSObject["maxHeight"];
  maxHeightExit: CSSObject["maxHeight"];
  timeoutEnter: number;
  timeoutExit: number;
};

export const MaxHeightAppearanceTransition = styled(CSSTransition)<MaxHeightAppearanceTransitionProps>`
  &.enter {
    max-height: ${({ maxHeightEnter }) => maxHeightEnter};
    overflow: hidden;
  }

  &.enter-active {
    max-height: ${({ maxHeightExit }) => maxHeightExit};
    overflow: unset;
    transition: max-height ${({ timeoutEnter }) => timeoutEnter}ms linear;
  }

  &.exit {
    max-height: ${({ maxHeightExit }) => maxHeightExit};
    overflow: unset;
  }

  &.exit-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height ${({ timeoutExit }) => timeoutExit}ms linear;
  }
`;
