import { forwardRef } from "react";
import { BankGuaranteeDetailsDemandActiveTaskCommonProps } from "../../../../types/demand/tasks/BankGuaranteeDetailsDemandTaskCommonProps";
import { BankGuaranteeDetailsComplicatedTaskBillCard } from "../task/BankGuaranteeDetailsComplicatedTaskBillCard";
import { DemandQueueTaskComment } from "../../../../../../demand/queue-tasks/components/comment/DemandQueueTaskComment";
import {
  DemandQueueTaskSeparator,
  isDemandQueueTaskActiveActionHasMessageOrProvidedFiles,
} from "../../../../../../demand/queue-tasks";
import { BankGuaranteeDetailsComplicatedTaskBillForm } from "./form/BankGuaranteeDetailsComplicatedTaskBillForm";
import { DemandQueueTaskAttachedFilesCollapse } from "../../../../../../demand/queue-tasks/components/attached-file/DemandQueueTaskAttachedFilesCollapse";
import {
  getUseBankGuaranteeDetailsComplicatedTaskDateCreatedOptionsByActiveTask,
  useBankGuaranteeDetailsComplicatedTaskDateCreated,
} from "../../../common/hooks/useBankGuaranteeDetailsComplicatedTaskDateCreated";

export const BankGuaranteeDetailsComplicatedTaskBillActive = forwardRef<
  HTMLDivElement,
  BankGuaranteeDetailsDemandActiveTaskCommonProps
>(({ task, isFocused, className }, ref) => {
  const { date, placement } = useBankGuaranteeDetailsComplicatedTaskDateCreated(
    getUseBankGuaranteeDetailsComplicatedTaskDateCreatedOptionsByActiveTask(task),
  );

  return (
    <BankGuaranteeDetailsComplicatedTaskBillCard
      variant="active"
      billInfo={task.billInfo}
      taskTitle={task.taskTitle}
      taskGroupType={task.groupType}
      dateCreated={date}
      dateCreatedPlacement={placement}
      managerComment={
        isDemandQueueTaskActiveActionHasMessageOrProvidedFiles(task.action) && (
          <>
            <DemandQueueTaskComment
              date={placement === "comment" ? date : undefined}
              author="Менеджер банка"
              text={task.action.message}
            />
            {task.action.providedFiles && (
              <DemandQueueTaskAttachedFilesCollapse
                files={task.action.providedFiles.map((x) => ({ title: "", ...x.file }))}
              />
            )}
            <DemandQueueTaskSeparator />
          </>
        )
      }
      isFocused={isFocused}
      isPinned={task.isPinned}
      className={className}
      ref={ref}
    >
      <BankGuaranteeDetailsComplicatedTaskBillForm task={task} />
    </BankGuaranteeDetailsComplicatedTaskBillCard>
  );
});
