import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { CreditWorkingAssetsListItemDesktopSkeleton } from "./desktop/CreditWorkingAssetsListItem.desktop.skeleton";
import { CreditWorkingAssetsListItemMobileSkeleton } from "./mobile/CreditWorkingAssetsListItem.mobile.skeleton";

export type CreditWorkingAssetsListItemSkeletonProps = BaseCssProps;

export const CreditWorkingAssetsListItemSkeleton = ({ className }: CreditWorkingAssetsListItemSkeletonProps) => {
  return (
    <ThemeMobileDesktopView
      desktop={<CreditWorkingAssetsListItemDesktopSkeleton className={className} />}
      mobile={<CreditWorkingAssetsListItemMobileSkeleton className={className} />}
    />
  );
};
