import { memo } from "react";
import { CSSObject, Global } from "@emotion/react";
import { useResponsiveContext } from "@sideg/ui-kit";
import { useAppLayout } from "../../app-layout-containers";

export const AppLayoutGlobalStyles = memo(() => {
  const { isFixedOverflow } = useAppLayout();
  const { isProbablyMobile } = useResponsiveContext();

  return (
    <Global
      styles={
        isFixedOverflow.value
          ? {
              "html, body": {
                position: "relative",
                overflow: "hidden",
                height: isProbablyMobile ? "100%" : "unset",
              },
            }
          : ({} as CSSObject)
      }
    />
  );
});
