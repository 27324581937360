import { createAsyncThunk } from "@reduxjs/toolkit";
import { combineSliceNames, createAppAsyncThunk } from "../../../common/store/utils";
import { CREDIT_WORKING_ASSETS_SLICE_NAME, transferCreditWorkingAssets } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { creditWorkingAssetsListApi } from "../api/creditWorkingAssetsListApi";
import { ApiError } from "../../../common/api/types";
import { GetCreditWorkingAssetsListItemOutputDto } from "../api/dto/output/GetCreditWorkingAssetsListOutputDto";
import { GetCreditWorkingAssetsListInputDto } from "../api/dto/input/GetCreditWorkingAssetsListInputDto";
import { CreditWorkingAssetsId } from "../../../common/types/demand";
import { selectCreditWorkingAssetsListTransferPayload } from "./creditWorkingAssetsList.selectors";

export const CREDIT_WORKING_ASSETS_LIST_SLICE_NAME = combineSliceNames(CREDIT_WORKING_ASSETS_SLICE_NAME, "list");

export const {
  getFiltrationParameters: getFiltrationParametersCreditWorkingAssetsList,
  get: getCreditWorkingAssetsList,
  getCounters: getCountersCreditWorkingAssetsList,
} = createApiThunks(CREDIT_WORKING_ASSETS_LIST_SLICE_NAME, creditWorkingAssetsListApi);

export const getCreditWorkingAssetsSingleListItem = createAsyncThunk<
  GetCreditWorkingAssetsListItemOutputDto | null,
  GetCreditWorkingAssetsListInputDto,
  { rejectValue: ApiError<GetCreditWorkingAssetsListInputDto> }
>(
  combineSliceNames(CREDIT_WORKING_ASSETS_LIST_SLICE_NAME, "getSingleListItem"),
  async (model, { rejectWithValue, signal }) => {
    try {
      const response = await creditWorkingAssetsListApi.get(model, signal);

      return response.body.data.length === 1 ? response.body.data[0] : null;
    } catch (err) {
      return rejectWithValue(err as ApiError<GetCreditWorkingAssetsListInputDto>);
    }
  },
);

export const transferCreditWorkingAssetsItem = createAppAsyncThunk(
  combineSliceNames(transferCreditWorkingAssets.type, "thunk"),
  (creditWorkingAssetsId: CreditWorkingAssetsId, { dispatch, getState }) => {
    const transferPayload = selectCreditWorkingAssetsListTransferPayload(getState(), creditWorkingAssetsId);
    if (transferPayload !== undefined) {
      dispatch(transferCreditWorkingAssets(transferPayload));
    }
  },
  {},
);
