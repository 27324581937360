import { CreditExecutionDetailsDemand } from "../../types/CreditExecutionDetailsDemand";
import { CreditExecutionDemandBase } from "../../../common/types";

const mapDemands = (demands: CreditExecutionDemandBase[]): CreditExecutionDetailsDemand[] => {
  return demands.map((x, i) => ({ ...x, index: i + 1 }));
};

export const creditExecutionDetailsDemandsMapper = {
  mapDemands,
};
