import { useCallback } from "react";
import { useCreditWorkingAssetsListFilterContext } from "./useCreditWorkingAssetsListFilterContext";
import { useCreditWorkingAssetsListFilterApiContext } from "./useCreditWorkingAssetsListFilterApiContext";
import { FilterTabType } from "../../../../common/filters/use-cases";
import { isFilterAvailableForTabCreditWorkingAssets } from "../../validation/rules/isFilterAvailableForTabCreditWorkingAssets";

export const useCreditWorkingAssetsListFilterOnTabChange = () => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();
  const mutator = useCreditWorkingAssetsListFilterApiContext();

  return useCallback(
    (tab: FilterTabType) => {
      mutator(filteringObject, (filter, { setValues }) =>
        setValues(filter, {
          tab,
          status: isFilterAvailableForTabCreditWorkingAssets(tab, "status") ? filteringObject.status : undefined,
          taskTypes: isFilterAvailableForTabCreditWorkingAssets(tab, "taskTypes")
            ? filteringObject.taskTypes
            : undefined,
        })
      );
    },
    [filteringObject, mutator]
  );
};
