import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  legal: "Юридическое лицо",
  individual: "Индивидуальный предприниматель",
};

export const bankGuaranteeDocumentsClientLegalFormType = makeEnumLikeLiteral(["legal", "individual"] as const, titles);

export type BankGuaranteeDocumentsClientLegalFormType = EnumLikeLiteralType<
  typeof bankGuaranteeDocumentsClientLegalFormType
>;
