import { AxiosPromise } from "axios"
import ApiResponseModel from "../../../entity/models/ApiResponseModel"
import ApiResultModel from "../../../entity/models/ApiResultModel"
import { ScoringBankModel } from "../../../entity/models/ScoringBankModel"
import { ScoringModel } from "../../../entity/models/ScoringModel"
import { lkApiV3 } from "../../../essentials/configs"
import { RequestScoringModel } from "../../../pages/guarantee/calculator/GuaranteeCalculatorForManagingDirectorOfAgent/GuaranteeCalculatorForManagingDirectorOfAgentForm/data/mappers"
import { axiosJWT } from "../apiConfig"

export class ScoringApiV3 {
    public getScoringOptions(): AxiosPromise<ApiResponseModel<ApiResultModel<ScoringModel>>> {
        return axiosJWT.get(`${lkApiV3()}/Scoring`)
    }

    public postScoringData(form: RequestScoringModel): AxiosPromise<ApiResponseModel<ApiResultModel<ScoringBankModel>>> {
        const data = JSON.stringify(form)

        return axiosJWT.post(`${lkApiV3()}/Scoring/Score-for-manager`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}