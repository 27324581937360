import styles from './GuaranteeCalculatorForManagingDirectorOfAgentBankResults.module.css'
import cn from 'classnames'
import GuaranteeListItemDemandComment, { CommentType } from '../../../../../components/guarantees/bank-scoring/scoring-comment/GuaranteeListItemDemandComment'
import { ScoringBankResultModel } from '../../../../../entity/models/ScoringBankModel'

interface GuaranteeCalculatorForManagingDirectorOfAgentBankResultsProps {
    banks: ScoringBankResultModel[]
}

export const GuaranteeCalculatorForManagingDirectorOfAgentBankResults = ({ banks }: GuaranteeCalculatorForManagingDirectorOfAgentBankResultsProps) => {
    return (
        <div className={styles.tableWrapper}>
            <table className={styles.bankScoringTable}>
                <tbody>
                    <tr className={styles.tableRowTitle}>
                        <td className={styles.tableTitleFirstCell}>Банк</td>
                        <td className={styles.tableSecondCell}>
                            <span className={styles.tableTitleSecondCell}>Предварительный статус</span>
                        </td>
                    </tr>
                    {banks.map((bank, index) => (
                        <tr className={cn(styles.tableRow, {
                            [styles.tableRowBackgroundRejected]: !bank.isScoringSucceed
                        })}
                            key={index}>
                            <td className={styles.tableFirstCell}>
                                {bank.bankTitle}
                            </td>
                            <td className={styles.tableSecondCell}>
                                {
                                    !bank.isScoringSucceed ? (
                                        <div className={styles.buttonCommentWrapper}>
                                            <GuaranteeListItemDemandComment
                                                title={'Комменарий банка'}
                                                comment={bank.blockingParameterGroups.join()}
                                                commentType={CommentType.BankScoringRejected}
                                            />
                                            <span className={styles.rejectedText}>Отказ банка</span>
                                        </div>
                                    ) : (
                                        <div className={styles.buttonCommentWrapper}>
                                            <div className={styles.successText} >
                                                Одобрено
                                            </div>
                                        </div>
                                    )
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}