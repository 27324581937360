import { useFormikContext } from "formik";
import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { useCreditWorkingAssetsListFilterContext } from "../../../../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { CreditWorkingAssetsListFiltersMobile } from "../../../../../types/filters/CreditWorkingAssetsListFiltersMobile";
import { useCustomFieldOnChange, useGetFieldError, useGetFieldName } from "../../../../../../../common/form";
import { useAppSelector } from "../../../../../../../common/store";
import { selectCreditWorkingAssetsFiltersData } from "../../../../../store/filters/creditWorkingAssetsListFilters.selectors";
import {
  FilterFormMobileCheckboxesWithCollapse,
  FilterMobileCollapseUncontrolledContainer,
} from "../../../../../../../common/filters/use-cases";
import { Grid } from "../../../../../../../common/ui-kit/containers/responsive-grid";
import { CreditWorkingAssetsListHeaderFiltersBanksMobile } from "./CreditWorkingAssetsListHeaderFiltersBanks.mobile";
import * as S from "./CreditWorkingAssetsListHeaderFiltersFormContent.mobile.styled";
import { isFilterAvailableForTabCreditWorkingAssets } from "../../../../../validation/rules/isFilterAvailableForTabCreditWorkingAssets";
import { FormSelectMobile } from "../../../../../../../common/ui-kit/form-controls/select";
import { UNSELECTED_ENUM_LITERAL } from "../../../../../../../common/types/utils";

export type CreditWorkingAssetsListHeaderFiltersFormContentMobileProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFiltersFormContentMobile = ({
  className,
}: CreditWorkingAssetsListHeaderFiltersFormContentMobileProps) => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();
  const getFieldName = useGetFieldName<CreditWorkingAssetsListFiltersMobile>();
  const getFieldError = useGetFieldError<CreditWorkingAssetsListFiltersMobile>();
  const form = useFormikContext<CreditWorkingAssetsListFiltersMobile>();
  const onCustomChange = useCustomFieldOnChange<CreditWorkingAssetsListFiltersMobile>();

  const data = useAppSelector(selectCreditWorkingAssetsFiltersData);

  const isForTab = (filter: keyof CreditWorkingAssetsListFiltersMobile) =>
    isFilterAvailableForTabCreditWorkingAssets(filteringObject.tab, filter);

  return (
    <S.Wrapper className={className}>
      {data !== undefined && (
        <>
          {data?.statuses && isForTab("status") && (
            <FormSelectMobile label="Статус" {...form.getFieldProps(getFieldName("status"))}>
              <option value={UNSELECTED_ENUM_LITERAL}>Все статусы</option>
              {data.statuses.codeWithTitleList.map((x) => (
                <option value={x.code} key={x.code}>
                  {x.title}
                </option>
              ))}
            </FormSelectMobile>
          )}
          {data?.taskTypes && isForTab("taskTypes") && (
            <FilterFormMobileCheckboxesWithCollapse<CreditWorkingAssetsListFiltersMobile>
              title="Задачи"
              fieldName="taskTypes"
              items={data.taskTypes.codeWithTitleList}
            />
          )}
        </>
      )}
      {isForTab("dateCreateFrom") && isForTab("dateCreateTo") && (
        <FilterMobileCollapseUncontrolledContainer title="Дата создания">
          <Grid container gap="md">
            <Grid
              item
              as={DateFormInput}
              label="Дата начала"
              xs={6}
              {...form.getFieldProps(getFieldName("dateCreateFrom"))}
              onChange={(e: string) => onCustomChange(form.setFieldValue, "dateCreateFrom", e, true)}
              error={getFieldError("dateCreateFrom", form.touched.dateCreateFrom, form.errors.dateCreateFrom)}
              autoComplete="off"
            />
            <Grid
              item
              as={DateFormInput}
              label="Дата окончания"
              xs={6}
              {...form.getFieldProps(getFieldName("dateCreateTo"))}
              onChange={(e: string) => onCustomChange(form.setFieldValue, "dateCreateTo", e, true)}
              error={getFieldError("dateCreateTo", form.touched.dateCreateTo, form.errors.dateCreateTo)}
              autoComplete="off"
            />
          </Grid>
        </FilterMobileCollapseUncontrolledContainer>
      )}
      {data?.bankIds && isForTab("bankIds") && <CreditWorkingAssetsListHeaderFiltersBanksMobile />}
    </S.Wrapper>
  );
};
