import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { hubsAdapter } from "./hubs.adapter";
import { HubConnectionStatus } from "../types/HubConnectionStatus";

const initialState = hubsAdapter.getInitialState();

const slice = createSlice({
  name: "hubs",
  initialState,
  reducers: {
    createHub: (state, { payload }: PayloadAction<{ hubId: string }>) => {
      hubsAdapter.upsertOne(state, { hubId: payload.hubId, status: HubConnectionStatus.Idle });
    },
    onHubConnectionError: (state, { payload }: PayloadAction<{ hubId: string; error: string }>) => {
      hubsAdapter.updateOne(state, {
        id: payload.hubId,
        changes: { error: payload.error, status: HubConnectionStatus.ConnectionError },
      });
    },
    hubConnecting: (state, { payload }: PayloadAction<{ hubId: string }>) => {
      hubsAdapter.updateOne(state, {
        id: payload.hubId,
        changes: { error: undefined, status: HubConnectionStatus.Connecting },
      });
    },
    hubConnected: (state, { payload }: PayloadAction<{ hubId: string }>) => {
      hubsAdapter.updateOne(state, { id: payload.hubId, changes: { status: HubConnectionStatus.Connected } });
    },
    hubDisconnected: (state, { payload }: PayloadAction<{ hubId: string; isDisconnectedAfterRetries: boolean }>) => {
      hubsAdapter.updateOne(state, {
        id: payload.hubId,
        changes: {
          status: payload.isDisconnectedAfterRetries
            ? HubConnectionStatus.DisconnectedAfterRetries
            : HubConnectionStatus.Disconnected,
        },
      });
    },
    hubUpdate: (state, { payload }: PayloadAction<{ hubId: string }>) => {
      hubsAdapter.updateOne(state, {
        id: payload.hubId,
        changes: { error: undefined, status: HubConnectionStatus.Idle },
      });
    },
  },
});

export const { createHub, onHubConnectionError, hubDisconnected, hubConnected, hubConnecting, hubUpdate } =
  slice.actions;

export const hubsReducer = slice.reducer;
