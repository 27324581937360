import { useAppSelector } from "../../../../common/store";
import { selectAuthorizedUser } from "../../../../common/authorized-user";
import { Box } from "../../../../common/ui-kit/containers/box";

const BoxInput = Box.withComponent("input");
// eslint-disable-next-line @typescript-eslint/no-empty-function
const plug = () => {};

// Render скрытого поля с именем пользователя.
// Необходимо для менеджера паролей браузера - чтобы при изменении пароля браузер понимал с каким логином нужно связать
// новый пароль.
export const UserChangePasswordFormUsernameHiddenInput = () => {
  const { user } = useAppSelector(selectAuthorizedUser);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {user?.login !== undefined && (
        <BoxInput display="none" value={user.login} onChange={plug} autoComplete="username" />
      )}
    </>
  );
};
