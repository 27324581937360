import { makeWrappedEnum } from "../../../../common/types/utils";

export enum DemandQueueTaskActiveProjectGuaranteePrintType {
  Physical = "Physical",
  Digital = "Digital",
}

const GuaranteePrintTypeTitles = {
  Physical: "На бумаге",
  Digital: "Электронная",
};

export const demandQueueTaskActiveProjectGuaranteePrintTypeWrapped = makeWrappedEnum(
  DemandQueueTaskActiveProjectGuaranteePrintType,
  GuaranteePrintTypeTitles,
);
