import { combineSliceNames } from "../../../common/store/utils";
import { DEMAND_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { demandQueueTasksApi } from "../api/demandQueueTasksApi";

export const DEMAND_QUEUE_TASKS_SLICE_NAME = combineSliceNames(DEMAND_SLICE_NAME, "queueTasks");

export const {
  sendComment: sendCommentDemandQueueTasks,
  sendDecision: sendDecisionDemandQueueTasks,
  getHistory: getHistoryDemandQueueTasks,
  uploadDocument: uploadDocumentDemandQueueTasks,
} = createApiThunks(DEMAND_QUEUE_TASKS_SLICE_NAME, demandQueueTasksApi);
