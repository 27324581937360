import { clientV4 } from "../../../../common/api";
import { GetBriefCreditExecutionInputDto } from "./dto/input/GetBriefCreditExecutionInputDto";
import { GetBriefCreditExecutionOutputDto } from "./dto/output/GetBriefCreditExecutionOutputDto";

const CONTROLLER_NAME = "CreditExecutions";

export const creditExecutionBriefApi = {
  getBrief: ({ creditExecutionId }: GetBriefCreditExecutionInputDto) =>
    clientV4.get<GetBriefCreditExecutionInputDto, GetBriefCreditExecutionOutputDto>(
      `${CONTROLLER_NAME}/${creditExecutionId}/brief`
    ),
};
