import { FullPageLayoutContainer } from "../../../common/ui/layout";
import { usePageTitle } from "../../../common/navigation/page-title";
import { ErrorBoundary, ErrorBoundaryDefaultFallbackAlert } from "../../../common/ui/containers/error-boundary";
import { SettlementAndCashCreationHeader } from "../components/header/SettlementAndCashCreationHeader";
import { SettlementAndCashCreationForm } from "../components/form/SettlementAndCashCreationForm";

export const SettlementAndCashCreationPage = () => {
  usePageTitle("Новая заявка на РКО");

  return (
    <FullPageLayoutContainer>
      <ErrorBoundary fallback={<ErrorBoundaryDefaultFallbackAlert />}>
        <SettlementAndCashCreationHeader />
        <SettlementAndCashCreationForm />
      </ErrorBoundary>
    </FullPageLayoutContainer>
  );
};
