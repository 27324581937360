import { array, date, mixed, number, object, string } from "yup";
import {
  getFormattedNumberTransformer,
  getLazyObjectWithDynamicKeysSchema,
  getTaxpayerNumberSchema,
} from "../../../common/validation";

export const factoringDebtorDocumentValidationSchema = object({
  documentTypeId: number(),
  tempId: string(),
  dateAdded: date().nullable().notRequired(),
  file: mixed().nullable(true).required("Приложите необходимый документ"),
});

export const factoringCreationDebtorValidationSchema = object({
  debtorId: string(),
  taxpayerNumber: getTaxpayerNumberSchema().required("Не забудьте заполнить"),
  fundingLimit: number()
    .transform(getFormattedNumberTransformer())
    .typeError("Не забудьте заполнить")
    .required("Не забудьте заполнить"),
  documentTempIds: array().of(string().required()).default([]),
  documents: getLazyObjectWithDynamicKeysSchema(factoringDebtorDocumentValidationSchema),
});

export const factoringCreationValidationSchema = object({
  customerTaxpayerNumber: getTaxpayerNumberSchema().required("Не забудьте заполнить"),
  revenue: number()
    .transform(getFormattedNumberTransformer())
    .typeError("Укажите корректную сумму")
    .min(0, "Сумма должна быть больше нуля")
    .required("Не забудьте заполнить"),
  creditLoad: number()
    .transform(getFormattedNumberTransformer())
    .typeError("Укажите корректную сумму")
    .min(0, "Сумма должна быть больше нуля")
    .required("Не забудьте заполнить"),
  averageMonthly: number()
    .transform(getFormattedNumberTransformer())
    .typeError("Укажите корректную сумму")
    .min(0, "Сумма должна быть больше нуля")
    .max(999_999_999, "Сумма не должна быть больше 999 999 999")
    .required("Не забудьте заполнить"),
  delay: number()
    .transform(getFormattedNumberTransformer())
    .min(0, "Значение должно быть больше нуля")
    .typeError("Не забудьте заполнить")
    .required("Не забудьте заполнить")
    .default(0),
  debtorsIds: array().of(string().required()).default([]).min(1, "Необходимо указать хотя бы одного дебитора"),
  debtors: getLazyObjectWithDynamicKeysSchema(factoringCreationDebtorValidationSchema),
});
