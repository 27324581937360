import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { CircleIndicator } from "./CircleIndicator";

const blink = keyframes`
  50% {
    background-color: transparent;
  }`;

export const BlinkedCircleIndicator = styled(CircleIndicator)`
  animation: ${blink} 3s linear infinite;
`;
