import { useMemo } from "react";
import { ClientSearchCheckedModel } from "../types/ClientSearchCheckedModel";
import { clientRules } from "../validation/rules/client.rules";

const getError = (data: ClientSearchCheckedModel | undefined): string | undefined => {
  if (data) {
    if (!clientRules.isClientPassing(data)) {
      return data.isActive
        ? "Ненадежный клиент. К сожалению, мы не сможем создать заявку"
        : "Недействующий клиент. К сожалению, мы не сможем создать заявку";
    }

    if (data.isInRnp) {
      return "Клиент в РНП";
    }

    if (data.isBankrupt) {
      return "Клиент находится в стадии банкротства";
    }
  }

  return undefined;
};

export const useSearchClientErrorLabel = (data: ClientSearchCheckedModel | undefined) => {
  return useMemo(() => {
    const error = getError(data);

    return { isError: error !== undefined, errorText: error };
  }, [data]);
};
