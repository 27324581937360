import { CreditWorkingAssetsId, DemandId } from "../../../../common/types/demand";

export type CreditWorkingAssetsListItemModificationType = "remove-product" | "remove-demand";

export interface CreditWorkingAssetsListItemModificationState {
  type?: CreditWorkingAssetsListItemModificationType;
  creditWorkingAssetsId?: CreditWorkingAssetsId;
  demandId?: DemandId;
}

export const isCreditWorkingAssetsListItemRemoveProductModification = (
  state: CreditWorkingAssetsListItemModificationState
): state is { type: "remove-product"; creditWorkingAssetsId: CreditWorkingAssetsId } => {
  return state.type === "remove-product" && state.creditWorkingAssetsId !== undefined;
};

export const isCreditWorkingAssetsListItemRemoveDemandModification = (
  state: CreditWorkingAssetsListItemModificationState
): state is { type: "remove-demand"; creditWorkingAssetsId: CreditWorkingAssetsId; demandId: DemandId } => {
  return state.type === "remove-demand" && state.creditWorkingAssetsId !== undefined && state.demandId !== undefined;
};
