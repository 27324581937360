import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectChatDialog, selectChatDialogLastMessageId } from "../../store/chatDialog.selectors";
import { isLoading, isSucceeded } from "../../../../common/types/state";
import { getMessagesChatDialog } from "../../store/chatDialog.thunks";
import { chatDialogConfigs } from "../../configs";

export const useChatDialogMessages = () => {
  const [pageSize] = useState(chatDialogConfigs.pageSize);
  const dispatch = useAppDispatch();
  const { status, pagination } = useAppSelector(selectChatDialog);
  const lastMessageId = useAppSelector(selectChatDialogLastMessageId);
  const [hasMore, setHasMore] = useState(false);

  const [fetchNextPage, setFetchNextPage] = useState(false);

  const onNextPageFetching = useCallback(() => {
    setFetchNextPage(true);
  }, []);

  useEffect(() => {
    setHasMore(isSucceeded(status) && pagination !== undefined && pagination.pageCount !== pagination.pageNumber);
  }, [pagination, status]);

  useEffect(() => {
    if (hasMore && fetchNextPage) {
      dispatch(getMessagesChatDialog({ pageSize, lastMessageId }));
      setFetchNextPage(false);
    }
  }, [dispatch, hasMore, lastMessageId, fetchNextPage, pageSize]);

  return useMemo(
    () => ({ hasMore, onNextPageFetching, isLoading: isLoading(status), lastMessageId }),
    [hasMore, onNextPageFetching, status, lastMessageId]
  );
};
