import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../types/utils";

const titles = {
  week: "На этой неделе",
  month: "В этом месяце",
  threeMonth: "За 3 месяца",
  sixMonth: "За полгода",
  choosePeriod: "Выбрать период",
};

export const dateRangeFilter = makeEnumLikeLiteral(
  ["week", "month", "threeMonth", "sixMonth", "choosePeriod"] as const,
  titles,
);

export type DateRangeFilterType = EnumLikeLiteralType<typeof dateRangeFilter>;
