import { CreditExecutionCreationPageHeader } from "../components/header/CreditExecutionCreationPageHeader";
import { useAppSelector } from "../../../common/store";
import { CreditExecutionCreationForm } from "../components/creation/CreditExecutionCreationForm";
import { selectCreditExecutionCreation } from "../store/creditExecutionCreation.selectors";
import { useCreditExecutionCreationChangeScoringStageEffect } from "../hooks/stages/useCreditExecutionCreationChangeScoringStageEffect";
import { CreditExecutionCreationScoringCreateNewForm } from "../components/scoring/create-new/CreditExecutionCreationScoringCreateNewForm";

export const CreditExecutionCreationPage = () => {
  const { activeStage } = useAppSelector(selectCreditExecutionCreation);

  useCreditExecutionCreationChangeScoringStageEffect();

  return (
    <>
      <CreditExecutionCreationPageHeader />
      {activeStage === "creation" && <CreditExecutionCreationForm />}
      {activeStage === "scoring" && <CreditExecutionCreationScoringCreateNewForm />}
    </>
  );
};
