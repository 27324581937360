import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { CreditExecutionCommonDetailsSkeleton } from "../../../../../common/components";
import { CreditExecutionListItemDesktopCard } from "./card/CreditExecutionListItemDesktopCard";
import { CreditExecutionListItemDesktopDemandsSkeleton } from "./demands/CreditExecutionListItemDesktopDemands.skeleton";

export type CreditExecutionListItemDesktopSkeletonProps = BaseCssProps;

export const CreditExecutionListItemDesktopSkeleton = ({ className }: CreditExecutionListItemDesktopSkeletonProps) => {
  return (
    <CreditExecutionListItemDesktopCard
      className={className}
      clientName={<Skeleton />}
      clientTaxpayerNumber={<Skeleton inline width={100} />}
      numberAndDate={<Skeleton width={320} height={25} />}
      details={<CreditExecutionCommonDetailsSkeleton />}
    >
      <CreditExecutionListItemDesktopDemandsSkeleton />
    </CreditExecutionListItemDesktopCard>
  );
};
