import { Typography } from "../../../../common/ui-kit/typography";

export interface PublicUserDescriptionSuccessTextProps {
  startText: string;
  email?: string;
  phone?: string;
}

export const PublicUserDescriptionSuccessText = ({
  startText,
  phone,
  email,
}: PublicUserDescriptionSuccessTextProps) => {
  return (
    <>
      {startText}{" "}
      {email !== undefined && (
        <>
          на{" "}
          <Typography as="strong" fontWeight={700}>
            {email}
          </Typography>
        </>
      )}
      {email !== undefined && phone !== undefined && " и "}
      {phone !== undefined && (
        <>
          на{" "}
          <Typography as="strong" fontWeight={700}>
            {phone}
          </Typography>
        </>
      )}
    </>
  );
};
