import { useFormikContext } from "formik";
import Skeleton from "react-loading-skeleton";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { ProductRejectionFormValues } from "../../types/ProductRejectionFormValues";
import { useAppSelector } from "../../../store";
import { RejectionProductType } from "../../types/RejectionProductType";
import { selectProductRejectionReasons } from "../../store/productRejection.selectors";
import { useGetFieldName, useGetFormFieldError } from "../../../form";
import { isFailed } from "../../../types/state";
import { AlertLoadingToResult } from "../../../ui-kit/notifications/alert-with-indicator";
import { Box } from "../../../ui-kit/containers/box";
import { BlockResponsiveSubmitButton } from "../../../ui-kit/buttons/block-responsive-submit";
import { UNSELECTED_ENUM_LITERAL } from "../../../types/utils";
import { FormSelectResponsive } from "../../../ui-kit/form-controls/select";
import * as S from "./ProductRejectionFormContent.styled";

interface BankGuaranteeRejectionFormProps {
  product: RejectionProductType;
  error?: string;
}

export const ProductRejectionFormContent = ({ product, error }: BankGuaranteeRejectionFormProps) => {
  const { data, status } = useAppSelector((state) => selectProductRejectionReasons(state, product));

  const form = useFormikContext<ProductRejectionFormValues>();
  const getFieldError = useGetFormFieldError<ProductRejectionFormValues>();
  const getFieldName = useGetFieldName<ProductRejectionFormValues>();

  const isRejectionReasonsFailed = isFailed(status) && !data;

  return (
    <>
      {isRejectionReasonsFailed && <AlertLoadingToResult isError errorText="Не удалось загрузить причины отказа" />}
      {!isRejectionReasonsFailed && (
        <>
          <S.Container>
            {data && (
              <FormSelectResponsive
                label="Причина отмены"
                name={getFieldName("rejectionReasonId")}
                items={data.ids}
                getItemTitle={(item) => data.titles[item]}
                selectedItem={form.values.rejectionReasonId}
                isItemDisabled={(x) => x === UNSELECTED_ENUM_LITERAL}
                onChange={(newValue) => {
                  form.setFieldValue(getFieldName("rejectionReasonId"), newValue);
                }}
                error={getFieldError(form, "rejectionReasonId")}
                disabled={form.isSubmitting}
              />
            )}
            {data === undefined && (
              <Box>
                <Skeleton width={110} height={16} />
                <Skeleton height={40} />
              </Box>
            )}
            <FormTextarea
              label="Комментарий"
              minRows={2}
              {...form.getFieldProps(getFieldName("rejectionReasonComment"))}
              error={getFieldError(form, "rejectionReasonComment")}
              autoComplete="off"
              disabled={form.isSubmitting}
            />
          </S.Container>
          <BlockResponsiveSubmitButton type="submit" isLoading={form.isSubmitting} error={error}>
            Применить
          </BlockResponsiveSubmitButton>
        </>
      )}
    </>
  );
};
