import { CommentForm, CommentFormProps } from "../../../../common/product-comment";
import { DemandQueueTaskCard } from "../card/DemandQueueTaskCard";
import { DemandQueueTaskCardContainer } from "../card/DemandQueueTaskCardContainer";

const Container = DemandQueueTaskCardContainer.withComponent(CommentForm);

export type DemandQueueTasksCommentCardFormProps = CommentFormProps;
export const DemandQueueTasksCommentCardForm = ({ className, ...formProps }: DemandQueueTasksCommentCardFormProps) => {
  return (
    <DemandQueueTaskCard title="Новое сообщение" className={className} variant="active">
      <Container {...formProps} />
    </DemandQueueTaskCard>
  );
};
