import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import { combineSliceNames } from "../../../../common/store/utils";
import { CREDIT_EXECUTION_LIST_SLICE_NAME, getCountersCreditExecutionList } from "../creditExecutionList.thunks";
import { CreditExecutionListCountersState } from "./creditExecutionListCounters.types";

const initialState: CreditExecutionListCountersState = {
  status: LoadingStatusEnum.Idle,
  data: undefined,
};

const SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_LIST_SLICE_NAME, "counters");

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountersCreditExecutionList.fulfilled, (state, { payload }) => {
        state.data = payload.body;
      })
      .addMatcher(isAsyncThunkAction(getCountersCreditExecutionList), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const creditExecutionListCountersReducer = slice.reducer;
