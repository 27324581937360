import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { AgencyId } from "../../../../common/types/agencies";
import { PublicUserRegistrationPageLayout } from "../../pages/PublicUserRegistrationPageLayout";
import { PublicUserCardWithTitle, PublicUserDescriptionTypography } from "../../../common/components";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import {
  selectPublicUserRegistrationAgent,
  selectPublicUserRegistrationAgentIds,
  selectPublicUserRegistrationAgentIsAllDocumentsSigned,
} from "../../store/agent/publicUserRegistrationAgent.selectors";
import {
  completeAgencyRegistrationPublicUserRegistration,
  getAgencyPublicUserRegistration,
} from "../../store/publicUserRegistration.thunks";
import { isIdle, isLoading, isSucceeded } from "../../../../common/types/state";
import { Box } from "../../../../common/ui-kit/containers/box";
import { PublicUserRegistrationControlButtonsContainer } from "../common-form/PublicUserRegistrationControlButtonsContainer";
import { PublicUserRegistrationAgentDocumentCard } from "./document-card/PublicUserRegistrationAgentDocumentCard";
import { changeStagePublicUserRegistrationAgent } from "../../store/agent/publicUserRegistrationAgent.slice";
import { useApiMutation } from "../../../../common/hooks";
import { Button, ButtonLoading } from "../../../../common/ui-kit/form-controls/button";

export interface PublicUserRegistrationAgentDocumentsProps {
  agencyId: AgencyId;
}

export const PublicUserRegistrationAgentDocuments = ({ agencyId }: PublicUserRegistrationAgentDocumentsProps) => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectPublicUserRegistrationAgent);
  const documents = useAppSelector(selectPublicUserRegistrationAgentIds);
  const isAllDocumentsSigned = useAppSelector(selectPublicUserRegistrationAgentIsAllDocumentsSigned);

  const { status: completeStatus, mutate } = useApiMutation(completeAgencyRegistrationPublicUserRegistration, {
    errorMessagePlaceholder: "",
  });

  useEffect(() => {
    if (isIdle(status)) {
      dispatch(getAgencyPublicUserRegistration({ agencyId }));
    }
  }, [agencyId, dispatch, status]);

  const onBackButtonClick = () => {
    dispatch(changeStagePublicUserRegistrationAgent("form"));
  };

  const onNextButtonClick = async () => {
    const result = await mutate({ agencyId });
    if (result?.ok) {
      dispatch(changeStagePublicUserRegistrationAgent("success"));
    }
  };

  return (
    <PublicUserRegistrationPageLayout>
      <PublicUserCardWithTitle title="Подписание заявлений">
        <Box display="flex" flexDirection="column" gap="xxl">
          <PublicUserDescriptionTypography>
            Необходимо ознакомиться со всеми документами и подтвердить каждое заявление кодом из смс
          </PublicUserDescriptionTypography>
          {isSucceeded(status) &&
            documents.map((x) => <PublicUserRegistrationAgentDocumentCard key={x} issuingCompanyId={x} />)}
          {isLoading(status) && (
            <>
              <Skeleton containerTestId="skeleton" height={150} />
              <Skeleton containerTestId="skeleton" height={150} />
            </>
          )}
        </Box>
        {(documents.length > 0 || isSucceeded(status)) && (
          <PublicUserRegistrationControlButtonsContainer>
            <Button size="medium" isFullWidth color="secondary" type="button" onClick={onBackButtonClick}>
              Назад
            </Button>
            <ButtonLoading
              size="medium"
              isLoading={isLoading(completeStatus)}
              onClick={onNextButtonClick}
              type="button"
              disabled={!isAllDocumentsSigned}
              isFullWidth
            >
              Далее
            </ButtonLoading>
          </PublicUserRegistrationControlButtonsContainer>
        )}
      </PublicUserCardWithTitle>
    </PublicUserRegistrationPageLayout>
  );
};
