import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { FontSizeType } from "../../../../../common/ui-kit/types/FontSizeType";
import { BankGuaranteeCreationScoringRoundedReward } from "../../../types/BankGuaranteeCreationScoringRoundedReward";
import { Color } from "../../../../../common/ui-kit/theme";

const MAX_CHARS_COUNT = "КВ 100%".length;

const rewardColors: Record<BankGuaranteeCreationScoringRoundedReward, Color> = {
  "25": "#0CB502",
  "20": "#5BA620",
  "15": "#86AE15",
  "10": "#ADA712",
  "5": "#B88F40",
  "0": "#957E62",
};

export const BankGuaranteeCreationScoringRewardText = styled("span", { shouldForwardProp: isPropValid })<{
  variant: BankGuaranteeCreationScoringRoundedReward;
}>(
  ({ theme, variant }) => ({
    fontWeight: 500,
    width: `${MAX_CHARS_COUNT}ch`,
    lineHeight: theme.font.defaultLineHeight,
    color: rewardColors[variant],
    fontFeatureSettings: `'tnum'`,
  }),
  ({ theme }) =>
    makeResponsiveStyles<FontSizeType>(theme, { xs: "fz1", md: "fz3" }, (x) => ({
      fontSize: theme.font[x],
    })),
);
