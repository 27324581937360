import { FilteringObjectType } from "../types/FilteringObjectType";
import { FilteringArrayKeys } from "../types/FilteringArrayKeys";

const setValue = <
  TFilteringObject extends FilteringObjectType,
  TKey extends keyof TFilteringObject,
  TValue extends TFilteringObject[TKey],
>(
  filteringObject: TFilteringObject,
  key: TKey,
  value: TValue,
) => ({ ...filteringObject, [key]: value });

const setValues = <
  TFilteringObject extends FilteringObjectType,
  TValue extends Partial<TFilteringObject> = Partial<TFilteringObject>,
>(
  filteringObject: TFilteringObject,
  value: TValue,
) => ({ ...filteringObject, ...value });

const removeValue = <TFilteringObject extends FilteringObjectType, TKey extends keyof TFilteringObject>(
  filteringObject: TFilteringObject,
  key: TKey,
) => ({ ...filteringObject, [key]: undefined });

const removeValues = <TFilteringObject extends FilteringObjectType, TKey extends keyof TFilteringObject>(
  filteringObject: TFilteringObject,
  keys: TKey[],
) => ({ ...filteringObject, ...Object.fromEntries(keys.map((x) => [x, undefined])) });

const addArrayValue = <TFilteringObject extends FilteringObjectType, TKey extends FilteringArrayKeys<TFilteringObject>>(
  filteringObject: TFilteringObject,
  key: TKey,
  value: string,
) => {
  const currentValue = filteringObject[key];
  const newValue =
    Array.isArray(currentValue) && currentValue.length > 0
      ? currentValue.filter((x) => x !== value).concat(value)
      : [value];

  return { ...filteringObject, [key]: newValue };
};

const removeArrayValue = <
  TFilteringObject extends FilteringObjectType,
  TKey extends FilteringArrayKeys<TFilteringObject>,
>(
  filteringObject: TFilteringObject,
  key: TKey,
  value: string,
) => {
  const array = filteringObject[key];
  const newValue = Array.isArray(array) && array.length > 0 ? array.filter((x) => x !== value) : undefined;

  if (newValue && newValue.length > 0) {
    return { ...filteringObject, [key]: newValue };
  }

  return removeValue(filteringObject, key);
};

export const filtrationService = {
  setValue,
  setValues,
  removeValue,
  removeValues,
  addArrayValue,
  removeArrayValue,
};
