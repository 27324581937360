import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { StackPanel } from "../../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeDetailsDemandHeaderDemandNumberAndStatus } from "../BankGuaranteeDetailsDemandHeaderDemandNumberAndStatus";
import { BankGuaranteeDetailsDemandHeaderRejectionButton } from "../action-buttons/BankGuaranteeDetailsDemandHeaderRejectionButton";
import { BankGuaranteeDetailsDemandHeaderAlert } from "../alerts/BankGuaranteeDetailsDemandHeaderAlert";
import { useBankGuaranteeDetailsDemandStatusType } from "../../../../hooks/demand/useBankGuaranteeDetailsDemandStatusType";
import { BankGuaranteeDetailsDemand } from "../../../../types/demand/BankGuaranteeDetailsDemand";
import { BankGuaranteeId } from "../../../../../../common/types/demand";
import { ProductDetailsContentLayoutContainer } from "../../../../../../common/product-details";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import * as S from "./BankGuaranteeDetailsDemandHeaderActive.styled";
import { BankGuaranteeDetailsDemandHeaderActiveReconciliation } from "../reconciliation/BankGuaranteeDetailsDemandHeaderActiveReconciliation";

const BoxContainer = ProductDetailsContentLayoutContainer.withComponent(Box);

export interface BankGuaranteeDetailsDemandHeaderActiveProps {
  guaranteeId: BankGuaranteeId;
  demand: BankGuaranteeDetailsDemand | undefined;
}

export const BankGuaranteeDetailsDemandHeaderActive = ({
  guaranteeId,
  demand,
}: BankGuaranteeDetailsDemandHeaderActiveProps) => {
  const statusType = useBankGuaranteeDetailsDemandStatusType(demand);
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  const projectBillProcessType = demand?.projectBillProcess?.code;

  return (
    <>
      <BoxContainer gap="md" display="flex" flexDirection="column">
        <StackPanel
          gap="sm"
          direction="row"
          justifyContent="space-between"
          alignItems={{ xs: "flex-end", [desktopBreakpoint]: "flex-start" }}
        >
          <BankGuaranteeDetailsDemandHeaderDemandNumberAndStatus
            bankTitle={demand?.bank.title}
            statusType={statusType}
            statusText={demand?.status.title}
            agentRewardRate={demand?.agentRewardRate}
          />
          {demand?.isActive && (
            <BankGuaranteeDetailsDemandHeaderRejectionButton
              guaranteeId={guaranteeId}
              demandId={demand.demandId}
              bankName={demand.bank.title}
            />
          )}
        </StackPanel>
        <BankGuaranteeDetailsDemandHeaderAlert statusType={statusType} demand={demand} />
      </BoxContainer>
      {projectBillProcessType === "ConfirmedWithHistory" && (
        <S.ReconciliationContainer>
          <BankGuaranteeDetailsDemandHeaderActiveReconciliation />
        </S.ReconciliationContainer>
      )}
    </>
  );
};
