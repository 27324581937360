import LotModel from "../../entity/models/LotModel"
import RequestData from "../utils/RequestData"
import RequestStatus from "../../entity/models/enums/RequestStatus"
import produce from 'immer'
import {
    LOT_FETCHING,
    LOT_FETCHED,
    LOT_ERROR,
    LOT_RESET,

    LOT_UPDATE_PROCESSING,
    LOT_UPDATE_SUCCESS,
    LOT_UPDATE_ERROR,
    LOT_UPDATE_RESET,

    LotDispatchType
} from "../actions/lot/lotActionTypes"

interface LotState {
    lot: RequestData<LotModel>
    update: RequestData<boolean>
}

const initialState: LotState = {
    lot: {
        data: null,
        error: null,
        status: RequestStatus.Initial
    },
    update: {
        data: null,
        error: null,
        status: RequestStatus.Initial
    }
}

const lotReducer = (state: LotState = initialState, action: LotDispatchType) => {
    switch (action.type) {
        case LOT_FETCHING:
            return produce(state, (draft: LotState) => {
                draft.lot.status = RequestStatus.Request
                draft.lot.error = null
            })
        case LOT_FETCHED:
            return produce(state, (draft: LotState) => {
                draft.lot.status = RequestStatus.Success
                draft.lot.data = action.payload
            })
        case LOT_ERROR:
            return produce(state, (draft: LotState) => {
                draft.lot.status = RequestStatus.Failure
                draft.lot.error = action.error
            })
        case LOT_RESET:
            return produce(state, (draft: LotState) => {
                draft.lot = initialState.lot
            })
        case LOT_UPDATE_PROCESSING:
            return produce(state, (draft: LotState) => {
                draft.update.status = RequestStatus.Request
                draft.update.error = null
            })
        case LOT_UPDATE_SUCCESS:
            return produce(state, (draft: LotState) => {
                draft.update.status = RequestStatus.Success
                draft.update.data = action.payload
            })
        case LOT_UPDATE_ERROR:
            return produce(state, (draft: LotState) => {
                draft.update.status = RequestStatus.Failure
                draft.update.error = action.error
            })
        case LOT_UPDATE_RESET:
            return produce(state, (draft: LotState) => {
                draft.update = initialState.update
            })
        default:
            return state
    }
}

export default lotReducer
