const PARAMETER_SPLITTER = /\s*;\s*/;
const HEADER_SPLITTER = /\s*:\s*/;
const PROPERTY_SPLITTER = /\s*=\s*(.+)/;
const ENCODING_SPLITTER = /\s*'[^']*'\s*(.*)/;
const QUOTES_TRIM = /^["'\s]*|["'\s]*$/g;

const parser = (data: string): undefined | Record<string, string> => {
  const headerParts = data
    .split(PARAMETER_SPLITTER)
    .map((item) => item.trim())
    .filter((item) => !!item);

  let type = headerParts.shift();
  if (!type) {
    return undefined;
  }

  const typeVariants = type.toLowerCase().split(HEADER_SPLITTER);
  type = typeVariants[1] || typeVariants[0];

  return headerParts
    .map((prop) => prop.split(PROPERTY_SPLITTER))
    .reduce(
      (accum, [key, value]) => {
        if (!value) {
          return accum;
        }

        if (key.slice(-1) === "*") {
          // eslint-disable-next-line prefer-const
          let [encoding, encodingValue] = value.split(ENCODING_SPLITTER);
          if (encodingValue) {
            try {
              encodingValue = decodeURIComponent(encodingValue);
              // eslint-disable-next-line no-empty
            } catch (e) {}
            accum[key.slice(0, -1).toLowerCase()] = encodingValue;
          }
          accum.encoding = encoding.toLowerCase();
        } else if (!(key in accum)) {
          accum[key.toLowerCase()] = value.replace(QUOTES_TRIM, "");
        }

        return accum;
      },
      { type } as Record<string, string>,
    );
};

export const CONTENT_DISPOSITION_HEADER = "content-disposition";

export const getFileNameFromContentDisposition = (value: string): string | undefined => {
  return parser(value)?.filename;
};
