import { date, number, object, string } from "yup";
import { dateTimeHelper } from "@sideg/helpers";
import {
  getEnumLikeLiteralTypeSchema,
  getDateOnlyTransformer,
  getFormattedNumberTransformer,
} from "../../../common/validation";
import { federalLawRules } from "../../common/validation";
import { GuaranteeTypeCode, guaranteeTypeCode, federalLawTypeWithCommerce } from "../../../common/types/demand";

export const bankGuaranteeCalculatorValidationSchema = object({
  guaranteeType: getEnumLikeLiteralTypeSchema(guaranteeTypeCode).required("Не забудьте заполнить"),
  dateStart: date()
    .transform(getDateOnlyTransformer())
    .typeError("Укажите корректную дату")
    .min(dateTimeHelper.unaryModify("startOfDay", new Date()), `Дата начала должна быть не ранее сегодняшнего дня`)
    .required("Не забудьте заполнить"),
  guaranteeSum: number()
    .transform(getFormattedNumberTransformer())
    .typeError("Укажите корректную сумму")
    .min(0, "Сумма должна быть больше нуля")
    .required("Не забудьте заполнить"),
  federalLaw: getEnumLikeLiteralTypeSchema(federalLawTypeWithCommerce)
    .default("Fz223")
    .required("Не забудьте заполнить")
    .when("guaranteeType", (guaranteeType: GuaranteeTypeCode, schema) => {
      if (federalLawRules.paymentGuaranteeType.condition(guaranteeType)) {
        return string()
          .required("Не забудьте заполнить")
          .oneOf(federalLawRules.paymentGuaranteeType.collection, "Для типа БГ возможна только коммерческая закупка");
      }

      return schema;
    }),
  dateEnd: date()
    .transform(getDateOnlyTransformer())
    .when("dateStart", (dateStart: Date | null | undefined, schema) => {
      return dateTimeHelper.isValid(dateStart)
        ? schema.min(
            dateTimeHelper.simpleModify("addDays", dateStart, 1),
            "Дата окончания не должна быть ранее даты начала",
          )
        : schema.min(
            dateTimeHelper.simpleModify("addDays", new Date(), 1),
            "Дата окончания должна быть не ранее завтрашнего дня",
          );
    })
    .typeError("Укажите корректную дату")
    .required("Не забудьте заполнить"),
});
