import { useAppSelector } from "../../../../../common/store";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { selectCreditWorkingAssetsDetailsActiveTasksIds } from "../../../store/active-tasks/creditWorkingAssetsDetailsActiveTasks.selectors";
import { CreditWorkingAssetsDetailsActiveTask } from "./CreditWorkingAssetsDetailsActiveTask";
import { ProductDetailsDemandActiveTasksSkeletonWrapper } from "../../../../../common/product-details";
import { selectCreditWorkingAssetsDetailsDemandById } from "../../../store/demands/creditWorkingAssetsDetailsDemands.selectors";
import { selectCreditWorkingAssetsDetailsActiveDemand } from "../../../store/active-demand/creditWorkingAssetsDetailsActiveDemand.selectors";

export type CreditWorkingAssetsDetailsActiveTasksProps = BaseCssProps;

export const CreditWorkingAssetsDetailsActiveTasks = ({ className }: CreditWorkingAssetsDetailsActiveTasksProps) => {
  const {
    activeDemandId,
    fetch: { status: fetchStatus },
  } = useAppSelector(selectCreditWorkingAssetsDetailsActiveDemand);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectCreditWorkingAssetsDetailsDemandById(state, activeDemandId!));
  const tasksIds = useAppSelector(selectCreditWorkingAssetsDetailsActiveTasksIds);

  return (
    <ProductDetailsDemandActiveTasksSkeletonWrapper
      demandTasksCount={demand?.countActiveTasks}
      status={fetchStatus}
      fetchedTasksCount={tasksIds.length}
      className={className}
    >
      {tasksIds.map((x) => (
        <CreditWorkingAssetsDetailsActiveTask queueTaskId={x} key={x} />
      ))}
    </ProductDetailsDemandActiveTasksSkeletonWrapper>
  );
};
