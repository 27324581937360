import { useEffect } from "react";
import { useAppDispatch } from "../../store";
import {
  AuthenticationRemovedCallback,
  authenticationService,
  AuthenticationUserAuthenticatedCallback,
} from "../../authentication";
import { authorizedUserAuthenticated, authorizedUserRemoved } from "../store/authorizedUser.slice";

export const AuthorizedUserAuthenticationListener = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onLogin: AuthenticationUserAuthenticatedCallback = (payload) =>
      dispatch(authorizedUserAuthenticated(payload));
    const onLogout: AuthenticationRemovedCallback = () => dispatch(authorizedUserRemoved());

    authenticationService.onAuthenticationRemoved(onLogout);
    authenticationService.onUserAuthenticated(onLogin);

    return () => {
      authenticationService.offAuthenticationRemoved(onLogout);
      authenticationService.offUserAuthenticated(onLogin);
    };
  }, [dispatch]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
