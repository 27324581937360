import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import {
  AppLayoutOverlayPanel,
  useAppLayoutActiveOverlayPanel,
} from "../../../../../../common/ui/layout/app-layout-containers";
import { FiltersMobilePanel, FiltersSlidersIconButton } from "../../../../../../common/filters/use-cases";
import { CreditExecutionListHeaderFiltersFormContentMobile } from "./form/CreditExecutionListHeaderFiltersFormContent.mobile";
import { useCreditExecutionListFilterContext } from "../../../../hooks/filters/useCreditExecutionListFilterContext";
import { creditExecutionListConfigs } from "../../../../configs";
import { creditExecutionListFiltersMobileValidationSchema } from "../../../../validation/creditExecutionListFiltersMobileValidationSchema";

export type CreditExecutionListHeaderFiltersOpenButtonMobileProps = BaseCssProps;

export const CreditExecutionListHeaderFiltersOpenButtonMobile = ({
  className,
}: CreditExecutionListHeaderFiltersOpenButtonMobileProps) => {
  const filteringObject = useCreditExecutionListFilterContext();

  const { openPanel, closePanel } = useAppLayoutActiveOverlayPanel(creditExecutionListConfigs.filtersOverlayPanelKey);

  return (
    <>
      <FiltersSlidersIconButton onClick={openPanel} className={className} />
      <AppLayoutOverlayPanel panelKey={creditExecutionListConfigs.filtersOverlayPanelKey}>
        <FiltersMobilePanel
          filteringObject={filteringObject}
          schema={creditExecutionListFiltersMobileValidationSchema}
          onCloseClick={closePanel}
        >
          <CreditExecutionListHeaderFiltersFormContentMobile />
        </FiltersMobilePanel>
      </AppLayoutOverlayPanel>
    </>
  );
};
