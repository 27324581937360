/* eslint-disable @typescript-eslint/ban-types */
import styled, { StyledComponent } from "@emotion/styled";

export const withDefaultProps = <TProps extends {}, TSpecificComponentProps extends {} = {}, TJSXProps extends {} = {}>(
  component: StyledComponent<TProps, TSpecificComponentProps, TJSXProps>,
  props: TProps,
) => {
  const result = styled(component)();
  result.defaultProps = props;

  return result as StyledComponent<TProps, TSpecificComponentProps, TJSXProps>;
};
