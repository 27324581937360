import { combineSliceNames } from "../../../common/store/utils";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { CREDIT_EXECUTION_SLICE_NAME } from "../../common/store";
import { creditExecutionConditionsApi } from "../api/creditExecutionConditionsApi";

export const CREDIT_EXECUTION_CONDITIONS_SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_SLICE_NAME, "conditions");

export const { get: getCreditExecutionConditions } = createApiThunks(
  CREDIT_EXECUTION_CONDITIONS_SLICE_NAME,
  creditExecutionConditionsApi
);
