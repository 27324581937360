import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useCreditWorkingAssetsListFilterContext } from "../../../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { useCreditWorkingAssetsListFilterApiContext } from "../../../../hooks/filters/useCreditWorkingAssetsListFilterApiContext";
import { DateRangeFilter } from "../../../../../../common/filters/use-cases";

export type CreditWorkingAssetsListHeaderFiltersDatesDesktopProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFiltersDatesDesktop = ({
  className,
}: CreditWorkingAssetsListHeaderFiltersDatesDesktopProps) => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();
  const mutator = useCreditWorkingAssetsListFilterApiContext();

  return (
    <DateRangeFilter
      className={className}
      dateMutator={(dateCreateFrom?: Date, dateCreateTo?: Date) =>
        mutator(filteringObject, (filter, service) => service.setValues(filter, { dateCreateFrom, dateCreateTo }))
      }
      dateCreateFrom={filteringObject.dateCreateFrom}
      dateCreateTo={filteringObject.dateCreateTo}
    />
  );
};
