import { createSlice } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { BANK_GUARANTEE_DETAILS_SLICE_NAME, getBankGuaranteeDetailsDemand } from "../bankGuaranteeDetails.thunks";
import { bankGuaranteeDetailsReissuesAdapter } from "./BankGuaranteeDetailsReissues.adapter";
import { isBankGuaranteeDetailsGuaranteeChangedMatcher } from "../bankGuaranteeDetails.matchers";

const SLICE_NAME = combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "reissues");

const initialState = bankGuaranteeDetailsReissuesAdapter.getInitialState();

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBankGuaranteeDetailsDemand.fulfilled, (state, action) => {
        if (!action.payload.body.reissues) {
          bankGuaranteeDetailsReissuesAdapter.removeAll(state);
        } else {
          bankGuaranteeDetailsReissuesAdapter.setAll(
            state,
            action.payload.body.reissues.map((x, index) => ({ index, ...x }))
          );
        }
      })
      .addMatcher(isBankGuaranteeDetailsGuaranteeChangedMatcher, () => initialState);
  },
});

export const bankGuaranteeDetailsReissuesReducer = slice.reducer;
