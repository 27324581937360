import { useAppSelector } from "../../../../common/store";
import { selectGroupedBankGuaranteeDocumentsIds } from "../../store/bankGuaranteeDocuments.selectors";
import { Box } from "../../../../common/ui-kit/containers/box";
import { BankGuaranteeDocumentsListGroup } from "./BankGuaranteeDocumentsListGroup";

export const BankGuaranteeDocumentsListDesktop = () => {
  const { requiredDocuments, notRequiredDocuments } = useAppSelector(selectGroupedBankGuaranteeDocumentsIds);

  return (
    <Box pt="xl" display="flex" flexDirection="column" gap="xxl">
      {requiredDocuments.length > 0 && (
        <BankGuaranteeDocumentsListGroup title="Предварительный пакет документов" ids={requiredDocuments} />
      )}
      {notRequiredDocuments.length > 0 && (
        <BankGuaranteeDocumentsListGroup title="Полный пакет документов" ids={notRequiredDocuments} />
      )}
    </Box>
  );
};
