import styled from "@emotion/styled";

export const Wrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.sm};
  padding: ${({ theme }) => theme.spaces.sm};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.secondaryColors.primaryBackground : "transparent"};
  transition: background-color 0.2s ease-in;

  ${({ theme }) => theme.breakpoints.mqUp("md")} {
    border-radius: 4px;
  }
`;
