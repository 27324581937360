import { FullPageContainerMainContentWrapper } from "../main/FullPageContainerMainContentWrapper";
import { FullPageContainerMainContentCenteredContainer } from "../main/FullPageContainerMainContentCenteredContainer";
import { FullPageContainerCenteredFooter } from "../footer/FullPageContainerCenteredFooter";
import { FullPageContainerHeaderTitle } from "../header/FullPageContainerHeaderTitle";
import { FullPageContainerHeaderContainer } from "../header/FullPageContainerHeaderContainer";
import { FullPageContainerHeaderTitleTypography } from "../header/FullPageContainerHeaderTitleTypography";
import { FullPageContainerHeader } from "../header/FullPageContainerHeader";
import { FullPageContainerHeaderBackLinkButton } from "../header/back-link/FullPageContainerHeaderBackLinkButton";

export const FullPageContainerCompound = {
  MainWrapper: FullPageContainerMainContentWrapper,
  MainCenteredContainer: FullPageContainerMainContentCenteredContainer,
  Footer: FullPageContainerCenteredFooter,
  MobileDesktopHeaderContainer: FullPageContainerHeaderContainer,
  Header: FullPageContainerHeader,
  HeaderTypography: FullPageContainerHeaderTitleTypography,
  HeaderTitle: FullPageContainerHeaderTitle,
  HeaderBackLinkButton: FullPageContainerHeaderBackLinkButton,
};
