import { REPORTS_AGENT_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../../common/types/user";
import { makeAppUrl } from "../../../../common/urls/internal/utils";
import { makeUserCheckFeatureFlagPermission } from "../../../../common/urls/internal/utils/makeAppUrl";

export const reportsAgentListUrls = {
  root: makeAppUrl(
    REPORTS_AGENT_ROOT_URL,
    REPORTS_AGENT_ROOT_URL,
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsReportAgent),
  ),
};
