import { useCallback, useMemo } from "react";
import { saveAs } from "file-saver";
import { FileId } from "../../../common/types/files";
import { getFilesDownload } from "../store/filesDownload.thunks";
import { isLoading } from "../../../common/types/state";
import { useApiMutation } from "../../../common/hooks";

export const useFilesDownload = (fileId: FileId, privateKey: string) => {
  const { error, mutate, status } = useApiMutation(getFilesDownload, {
    errorMessagePlaceholder:
      "Произошла непредвиденная ошибка. Попробуйте еще раз или свяжитесь с персональным менеджером",
  });

  const download = useCallback(async () => {
    if (isLoading(status)) {
      return;
    }
    const result = await mutate({ fileId, privateKey });
    if (result?.body.data && result?.body.fileName) {
      saveAs(result.body.data, result.body.fileName);
    }
  }, [fileId, mutate, privateKey, status]);

  return useMemo(() => [status, download, error] as const, [status, download, error]);
};
