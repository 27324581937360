import { AppLayoutOverlayPanel } from "../../../../ui/layout/app-layout-containers";
import { Box } from "../../../../ui-kit/containers/box";
import { Text } from "../../../../ui-kit/typography";
import { widgetsCalculatorConfig } from "../../configs/widgetsCalculator.config";
import { WidgetsCalculatorForm } from "../form/WidgetsCalculatorForm";
import * as S from "./WidgetsCalculatorOverlayPanel.styled";
import { CancelButton } from "../../../../ui/buttons/cancel-button";
import { StackPanel } from "../../../../ui-kit/containers/stack-panel";

export interface WidgetsCalculatorMobileProps {
  onClose: () => void;
}

export const WidgetsCalculatorOverlayPanel = ({ onClose }: WidgetsCalculatorMobileProps) => {
  return (
    <AppLayoutOverlayPanel panelKey={widgetsCalculatorConfig.panelKey}>
      <S.Container>
        <S.Header>
          <StackPanel direction="column" gap="xs">
            <Text fontSize="fz4" fontWeight={700} fontColor="primaryLight" lineHeight="defaultLineHeight">
              Калькулятор
            </Text>
            <Text fontSize="fz2" fontWeight={500} fontColor="primaryLight" lineHeight="defaultLineHeight">
              Рассчитайте количество дней и комиссию за выдачу
            </Text>
          </StackPanel>
          <CancelButton type="button" onClick={onClose} />
        </S.Header>
        <Box px="md" py="lg">
          <WidgetsCalculatorForm />
        </Box>
      </S.Container>
    </AppLayoutOverlayPanel>
  );
};
