import { useCallback } from "react";
import { queueTaskClosedDemandQueueTasks } from "../../../../../demand/queue-tasks";
import { apiMutationDefaultErrorHandler, useApiMutationAction } from "../../../../../common/hooks";
import { pushErrorGlobalToast } from "../../../../../global/toasts";
import { FormSubmitHandler } from "../../../../../common/form";
import { BankGuaranteeDetailsComplicatedTaskBillFormValues } from "../types/BankGuaranteeDetailsComplicatedTaskBillFormValues";
import { QueueTaskId } from "../../../../../common/types/demand";
import { bankGuaranteeDetailsComplicatedTaskBillValidationSchema } from "../validation/bankGuaranteeDetailsComplicatedTaskBillValidationSchema";
import { useAppDispatch } from "../../../../../common/store";
import { sendBillChangesBankGuaranteeDetailsDemand } from "../../../store/bankGuaranteeDetails.thunks";
import { BankGuaranteeDetailsComplicatedTaskBillDecisionType } from "../types/BankGuaranteeDetailsComplicatedTaskBillDecisionType";

export const useBankGuaranteeDetailsComplicatedTaskBillFormOnSubmit = (
  queueTaskId: QueueTaskId,
): FormSubmitHandler<BankGuaranteeDetailsComplicatedTaskBillFormValues> => {
  const dispatch = useAppDispatch();
  const { mutate } = useApiMutationAction();

  return useCallback(
    async (values) => {
      const castedValues = bankGuaranteeDetailsComplicatedTaskBillValidationSchema.cast(
        values,
      ) as BankGuaranteeDetailsComplicatedTaskBillFormValues;

      const generateFileParamsBasedOnDecision = (decision: BankGuaranteeDetailsComplicatedTaskBillDecisionType) => {
        if (decision === BankGuaranteeDetailsComplicatedTaskBillDecisionType.ChangesOffer) {
          return { otherBankOfferFile: castedValues.file?.file };
        }
        if (decision === BankGuaranteeDetailsComplicatedTaskBillDecisionType.ConfirmPayment) {
          return { confirmationPaymentFile: castedValues.file?.file };
        }

        return undefined;
      };

      const result = await mutate(
        sendBillChangesBankGuaranteeDetailsDemand,
        {
          queueTaskId,
          decision: castedValues.decision,
          comment: castedValues.comment,
          costAgent: castedValues.sum,
          costAgentRate: castedValues.percent,
          ...generateFileParamsBasedOnDecision(values.decision),
        },
        {
          errorHandler: async (e) => {
            const error = apiMutationDefaultErrorHandler(e) ?? "Не удалось отправить решение по задаче";
            dispatch(pushErrorGlobalToast(error));

            return error;
          },
        },
      );

      if (result) {
        dispatch(queueTaskClosedDemandQueueTasks(queueTaskId));
      }
    },
    [dispatch, mutate, queueTaskId],
  );
};
