import { useCreateFormElementErrorDataAttribute } from "@sideg/ui-kit/atoms/form-controls/common/hooks/useCreateFormElementErrorDataAttribute";
import * as S from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormElementError.styled";

export const BASE_FORM_ELEMENT_ERROR_DATA_TEST_ID = "form-control-error";

export interface BaseFormElementErrorProps {
  error?: string;
  errorDataTestId?: string;
}

export const BaseFormElementError = ({
  error,
  errorDataTestId = BASE_FORM_ELEMENT_ERROR_DATA_TEST_ID,
}: BaseFormElementErrorProps) => {
  const isError = Boolean(error);
  const [attribute, value] = useCreateFormElementErrorDataAttribute(isError);

  return (
    <S.ErrorWrapper error={isError || undefined}>
      <S.Error
        error={isError || undefined}
        {...{ [attribute]: value }}
        {...(isError ? { "data-testid": errorDataTestId } : undefined)}
      >
        {error}
      </S.Error>
    </S.ErrorWrapper>
  );
};
