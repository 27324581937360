import { boolean, number, object, ref, string, StringSchema } from "yup";
import { getFileArraySchema, getLazyObjectWithDynamicKeysSchema } from "../../../common/validation";

export const demandQueueTaskActiveDocumentValidationSchema = object({
  id: number().required(),
  minLength: number(),
  requestDocType: string(),
  files: getFileArraySchema().min(ref("minLength"), "Документ обязательный — необходимо прикрепить файл"),
});

export const demandQueueTaskActiveValidationSchema = object({
  isCommentRequired: boolean().required().default(false),
  comment: string().when("isCommentRequired", (isCommentRequired: boolean, schema: StringSchema) => {
    if (isCommentRequired) {
      return schema.trim("Комментарий не может быть пустым").required("Комментарий обязателен");
    }

    return schema;
  }),
  decision: string().default("").required("Выберите короткий ответ или действие"),
  documents: getLazyObjectWithDynamicKeysSchema(demandQueueTaskActiveDocumentValidationSchema),
});
