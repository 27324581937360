import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { AgencyDetailsState } from "./agencyDetails.types";
import { AGENCY_DETAILS_SLICE_NAME, getAgencyDetails } from "./agencyDetails.thunks";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";

const initialState: AgencyDetailsState = {
  status: LoadingStatusEnum.Idle,
};

const slice = createSlice({
  initialState,
  name: AGENCY_DETAILS_SLICE_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgencyDetails.fulfilled, (state, { payload }) => {
        const { address, company, contact } = payload.body;
        state.address = address;
        state.company = company;
        state.contact = contact
      })
      .addCase(getAgencyDetails.rejected, (state, action) => {
        state.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(getAgencyDetails), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const agencyDetailsReducer = slice.reducer;