import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BankGuaranteeCreationBriefBlock } from "../components/brief/BankGuaranteeCreationBriefBlock";
import { useBankGuaranteeCreationCreateByGuaranteeContext } from "../hooks/creation/create-by-guarantee/useBankGuaranteeCreationCreateByGuaranteeContext";
import { BankGuaranteeCreationCreateByGuaranteePage } from "./BankGuaranteeCreationCreateByGuaranteePage";

export const BankGuaranteeCreationCreateByGuaranteeDetailsPage = () => {
  const guaranteeId = useBankGuaranteeCreationCreateByGuaranteeContext();

  return (
    <ThemeMobileDesktopView
      mobile={<BankGuaranteeCreationBriefBlock guaranteeId={guaranteeId} />}
      desktop={<BankGuaranteeCreationCreateByGuaranteePage />}
    />
  );
};
