import { useTheme } from "@sideg/ui-kit";
import { AvatarInitial, getInitialsFromUserName } from "../../../../common/ui-kit/avatar/AvatarInitial";

export interface UserDetailsUserAvatarProps {
  fontSize: number;
  userName: string | undefined;
}

export const UserDetailsUserAvatar = ({ fontSize, userName }: UserDetailsUserAvatarProps) => {
  const theme = useTheme();

  return (
    <AvatarInitial
      size={theme.layout.header.button.size}
      fontSize={`${fontSize}px`}
      color="#fff"
      backGround={theme.layout.header.userAvatarBackground}
      initials={getInitialsFromUserName(userName)}
    />
  );
};
