import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsCreationScoring } from "../../store/creditWorkingAssetsCreation.selectors";
import { ApiAsyncThunk } from "../../../../common/store/utils";
import { ApiResponse } from "../../../../common/api/types";
import { ScoringCreditWorkingAssetsCreationOutputDto } from "../../api/dto/output/ScoringCreditWorkingAssetsCreationOutputDto";

export const useCreditWorkingAssetsCreationScoring = <TInput>(
  thunk: ApiAsyncThunk<ApiResponse<ScoringCreditWorkingAssetsCreationOutputDto>, TInput>,
  inputModel: TInput
) => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectCreditWorkingAssetsCreationScoring);

  useEffect(() => {
    if (data === undefined) {
      dispatch(thunk(inputModel));
    }
  }, [data, dispatch, inputModel, thunk]);
};
