import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../../../common/types/utils";
import { GuaranteeQueueTasksGroupType } from "../../../../../common/types/queue-tasks";

const billTitles = {
  PartialConfirmed: "Ваше предложение частично согласовано",
  Agreement: "Вы отправили запрос на согласование",
  Confirmed: "Банк одобрил ваше предложение",
  Refused: "Банк отклонил ваше предложение",
};

const projectTitles = {
  PartialConfirmed: "Проект частично согласован банком",
  Agreement: "Вы отправили проект на согласование",
  Confirmed: "Проект согласован банком",
  Refused: "Банк отклонил ваши правки",
};

const enumLikeLiteral = makeEnumLikeLiteral(billTitles);
export type BankGuaranteeDetailsDemandReconciliationDecisionType = EnumLikeLiteralType<typeof enumLikeLiteral>;

export const bankGuaranteeDetailsDemandReconciliationDecisionType = {
  values: enumLikeLiteral.values,
  getTitleByGroup: (
    group: Extract<GuaranteeQueueTasksGroupType, "Bill" | "Project">,
    value: BankGuaranteeDetailsDemandReconciliationDecisionType,
  ) => {
    const titles = group === "Project" ? projectTitles : billTitles;

    return titles[value];
  },
};
