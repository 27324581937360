export const isValidUrl = (url: string | undefined) => {
  if (!url) {
    return false;
  }

  try {
    // eslint-disable-next-line no-new
    new URL(url);
  } catch {
    return false;
  }

  return true;
};
