import { Formik, FormikHelpers } from "formik";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { publicUserAuthenticationValidationSchema } from "../../validation/publicUserAuthenticationValidationSchema";
import { PublicUserAuthenticationFormContent } from "./PublicUserAuthenticationFormContent";
import { FormBox } from "../../../../common/form";
import { PublicUserAuthenticationFromValues } from "../../types/PublicUserAuthenticationFromValues";

export interface PublicUserAuthenticationFormProps extends BaseCssProps {
  onSubmit: (
    values: PublicUserAuthenticationFromValues,
    { setFieldError }: FormikHelpers<PublicUserAuthenticationFromValues>
  ) => Promise<void>;
}

export const PublicUserAuthenticationForm = ({ className, onSubmit }: PublicUserAuthenticationFormProps) => {
  return (
    <Formik
      initialValues={publicUserAuthenticationValidationSchema.getDefault()}
      validationSchema={publicUserAuthenticationValidationSchema}
      onSubmit={onSubmit}
    >
      <FormBox display="contents" className={className}>
        <PublicUserAuthenticationFormContent />
      </FormBox>
    </Formik>
  );
};
