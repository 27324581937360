import { useCallback } from "react";
import { useFormikContext } from "formik";
import { DemandQueueTaskActiveDecision } from "../../types/active/DemandQueueTaskActiveDecision";
import { DemandQueueTaskActiveFormDecisionsField } from "./DemandQueueTaskActiveFormDecisionsField";
import { DemandQueueTaskActiveFormValues } from "../../types/active-form/DemandQueueTaskActiveFormValues";
import { useGetFieldName } from "../../../../common/form";
import { DemandQueueTaskActiveFormCommentField } from "./DemandQueueTaskActiveFormCommentField";

export interface DemandQueueTaskActiveFormDecisionWithCommentFieldsProps {
  decisions: DemandQueueTaskActiveDecision[];
}

export const DemandQueueTaskActiveFormDecisionWithCommentFields = ({
  decisions,
}: DemandQueueTaskActiveFormDecisionWithCommentFieldsProps) => {
  const getFieldValue = useGetFieldName<DemandQueueTaskActiveFormValues>();
  const form = useFormikContext<DemandQueueTaskActiveFormValues>();

  const onDecisionChanged = useCallback(
    (decision: DemandQueueTaskActiveDecision) => {
      form.setFieldValue(getFieldValue("isCommentRequired"), decision.isRequiredComment, true);
    },
    [form, getFieldValue],
  );

  return (
    <>
      {decisions.length > 1 && (
        <DemandQueueTaskActiveFormDecisionsField
          decisions={decisions}
          onDecisionChanged={onDecisionChanged}
          isDisabled={form.isSubmitting}
        />
      )}
      <DemandQueueTaskActiveFormCommentField isDisabled={form.isSubmitting} />
    </>
  );
};
