import { date, number, object, string } from "yup";
import {
  getDateOnlyTransformer,
  getDemandIdSchema,
  getEnumLikeLiteralTypeSchema,
  getFilterArraySchema,
} from "../../../common/validation";
import { bankGuaranteeRequestOriginalStatus } from "../../common/types";
import { filterTabType } from "../../../common/filters/use-cases";

export const bankGuaranteeListFiltersValidationSchema = object({
  search: string(),
  demandIdOrProductId: getDemandIdSchema(),
  clientNameOrTaxpayerNumber: string(),
  productId: number(),
  guaranteeTypes: getFilterArraySchema(string().required()),
  bankIds: getFilterArraySchema(string().required()),
  dateCreateFrom: date().transform(getDateOnlyTransformer()).typeError("Укажите корректную дату"),
  dateCreateTo: date().transform(getDateOnlyTransformer()).typeError("Укажите корректную дату"),
  status: string(),
  taskTypes: getFilterArraySchema(string().required()),
  requestOriginalStatuses: getFilterArraySchema(
    getEnumLikeLiteralTypeSchema(bankGuaranteeRequestOriginalStatus).required()
  ),
  tab: getEnumLikeLiteralTypeSchema(filterTabType).default("Active"),
  page: number().default(1),
});
