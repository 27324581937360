import styled from "@emotion/styled";
import { ElementColor, getElementColor } from "../../../../ui-kit/types";
import { Color } from "../../../../ui-kit/theme";

export const Stage = styled.div<{ color: ElementColor; backgroundColor: Color }>`
  color: ${({ theme, color }) => getElementColor(color, theme)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.sm};
  padding: ${({ theme }) => theme.spaces.sm};
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 100px;
  text-align: center;
`;
