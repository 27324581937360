import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { Text } from "../../../../common/ui-kit/typography";

export interface ChatDialogMessagesGroupDateProps {
  date: string;
  className?: string;
}

export const ChatDialogMessagesGroupDate = ({ date, className }: ChatDialogMessagesGroupDateProps) => {
  return (
    <Text as="div" fontColor="primaryCaption" textAlign="center" className={className}>
      {dateTimeHelper.format(new Date(date), specialFormatters.dayFullMonthYearIfDifferent)}
    </Text>
  );
};
