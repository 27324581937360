import { BaseClient } from "../../../../common/types/demand";
import { CreditExecutionDemandBase } from "../../../common/types";
import { GetCreditExecutionListItemOutputDto } from "../../api/dto/output/GetCreditExecutionListOutputDto";
import { CreditExecutionListItem } from "../../types/items/CreditExecutionListItem";

export const mapItem = (
  item: GetCreditExecutionListItemOutputDto
): {
  client: BaseClient;
  product: CreditExecutionListItem;
  demands: CreditExecutionDemandBase[];
} => {
  const { demands, client, ...rest } = item;

  const product: CreditExecutionListItem = {
    ...rest,
    clientId: client.id,
    demandIds: demands.map((x) => x.demandId),
    canRejectAll: item.isActive,
  };

  return { client, demands, product };
};

const mapList = (
  list: GetCreditExecutionListItemOutputDto[]
): {
  clients: BaseClient[];
  creditExecution: CreditExecutionListItem[];
  demands: CreditExecutionDemandBase[];
} => {
  const clients: BaseClient[] = [];
  const creditExecution: CreditExecutionListItem[] = [];
  const demands: CreditExecutionDemandBase[] = [];

  list.forEach((x) => {
    const mappedValue = mapItem(x);

    clients.push(mappedValue.client);
    creditExecution.push(mappedValue.product);
    demands.push(...mappedValue.demands);
  });

  return { clients, demands, creditExecution };
};

export const creditExecutionListItemsMapper = {
  mapItem,
  mapList,
};
