import * as S from "./CreditExecutionDetailsProductPage.styled";
import { useAppSelector } from "../../../common/store";
import { selectCreditExecutionDetailsProductDetails } from "../store/product-details/creditExecutionDetailsProductDetails.selectors";
import { CreditExecutionDetailsEmpty } from "../component/empty/CreditExecutionDetailsEmpty";

export const CreditExecutionDetailsProductPage = () => {
  const { data } = useAppSelector(selectCreditExecutionDetailsProductDetails);

  return <S.Container>{data !== undefined && <CreditExecutionDetailsEmpty />}</S.Container>;
};
