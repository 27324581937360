import { clientV4 } from "../../../common/api";
import { GetReportsDemandInputDto } from "./dto/input/GetReportsDemandInputDto";
import { GetReportsDemandOutputDto } from "./dto/output/GetReportsDemandOutputDto";

export const reportsDemandApi = {
  get: (model: GetReportsDemandInputDto) =>
    clientV4.get<GetReportsDemandInputDto, GetReportsDemandOutputDto>(`/users/report-demands/octet-stream`, model, {
      responseType: "blob",
    }),
};
