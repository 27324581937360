import * as React from "react";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { ButtonColor } from "./Button";

export interface IButtonInternalLinkProps {
  href: string;
  color: ButtonColor;
  minWidth?: string;
}

const ButtonInternalLink: React.FC<PropsWithChildren<IButtonInternalLinkProps>> = ({
  href,
  color,
  minWidth,
  children,
}) => {
  return (
    <Link className={"button " + color} to={href} style={{ minWidth }}>
      {children}
    </Link>
  );
};

export default ButtonInternalLink;
