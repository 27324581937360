import { Fragment, ReactNode } from "react";
import { ThemeMobileDesktopView, useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { StackPanel } from "../../../ui-kit/containers/stack-panel";
import { BaseCssProps, ResponsiveStyleValue } from "../../../ui-kit/types";
import { Typography } from "../../../ui-kit/typography";
import { Skeleton } from "../../../ui/product/list/product-list-item/demand/ProductListItemDemandItemLayout.desktop.styled";
import * as S from "./ProductDetailsContentHeaderLayout.styled";
import { ProductDetailsDemandHeaderDetailsItem } from "../../types/ProductDetailsDemandHeaderDetailsItem";

export interface ProductDetailsContentHeaderLayoutTitleProps extends BaseCssProps {
  title?: ReactNode;
  fontWeight?: ResponsiveStyleValue<number>;
}

const Title = ({ title, fontWeight, className }: ProductDetailsContentHeaderLayoutTitleProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Typography
      as="h3"
      fontWeight={fontWeight ?? { xs: 500, [desktopBreakpoint]: 700 }}
      fontSize={{ xs: 1.375, [desktopBreakpoint]: 1.5 }}
      lineHeight="defaultLineHeight"
      fontColor="typography.primary"
      className={className}
    >
      {title}
    </Typography>
  );
};

export interface ProductDetailsContentHeaderLayoutBankNameProps {
  bankName?: ReactNode;
}

const BankName = ({ bankName }: ProductDetailsContentHeaderLayoutBankNameProps) => {
  return (
    <ThemeMobileDesktopView
      desktop={<>Заявка в {bankName ?? <Skeleton width={120} inline />}</>}
      mobile={bankName ?? <Skeleton width={120} inline />}
    />
  );
};

export interface ProductDetailsContentHeaderLayoutHeaderChipsProps extends BaseCssProps {
  children: ReactNode;
}

const HeaderChips = ({ children, className }: ProductDetailsContentHeaderLayoutHeaderChipsProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <StackPanel
      gap={{ xs: "xs", [desktopBreakpoint]: "md" }}
      direction={{ xs: "column", [desktopBreakpoint]: "row" }}
      alignItems="flex-start"
      className={className}
    >
      {children}
    </StackPanel>
  );
};

export interface ProductDetailsContentHeaderLayoutDetailsItemsProps extends BaseCssProps {
  items: ProductDetailsDemandHeaderDetailsItem[];
}
const getMaxItemLength = (items: ProductDetailsDemandHeaderDetailsItem[]): number =>
  Math.max(...items.map((x) => x.title.length));

const DetailsItems = ({ items, className }: ProductDetailsContentHeaderLayoutDetailsItemsProps) => {
  return (
    <S.DetailsItemsDl className={className} maxTermLength={getMaxItemLength(items)}>
      {items.map((x) => (
        <Fragment key={x.title}>
          <Typography as="dt" fontColor="dark" fontWeight={400} lineHeight="2rem" fontSize="fz2" className={className}>
            {x.title}
          </Typography>
          <Typography
            className={className}
            as="dd"
            fontColor="typography.primary"
            fontWeight={500}
            lineHeight="2rem"
            fontSize="fz2"
          >
            {x.data}
          </Typography>
        </Fragment>
      ))}
    </S.DetailsItemsDl>
  );
};

export interface ProductDetailsContentHeaderLayoutDemandHeaderDetailsProps extends BaseCssProps {
  left: ProductDetailsDemandHeaderDetailsItem[];
  right: ProductDetailsDemandHeaderDetailsItem[];
}

const HeaderDetails = ({ left, right, className }: ProductDetailsContentHeaderLayoutDemandHeaderDetailsProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={<DetailsItems className={className} items={[...left, ...right]} />}
      desktop={
        <StackPanel gap="xxl" direction="row" alignItems="flex-start">
          <DetailsItems className={className} items={left} />
          {right.length > 0 && (
            <>
              <div />
              <DetailsItems className={className} items={right} />
            </>
          )}
        </StackPanel>
      }
    />
  );
};

export interface ProductDetailsContentHeaderLayoutTitleAndRejectionButtonWrapperProps extends BaseCssProps {
  children: ReactNode;
}

const TitleAndRejectionButtonWrapper = ({
  children,
  className,
}: ProductDetailsContentHeaderLayoutTitleAndRejectionButtonWrapperProps) => {
  return (
    <StackPanel gap="sm" direction="row" justifyContent="space-between" alignItems="center" className={className}>
      {children}
    </StackPanel>
  );
};

const ContentHeader = S.ContentHeaderContainer as typeof S.ContentHeaderContainer & {
  HeaderChips: typeof HeaderChips;
  DetailsItems: typeof DetailsItems;
  HeaderChip: typeof S.HeaderChip;
  TitleAndRejectionButtonWrapper: typeof TitleAndRejectionButtonWrapper;
  HeaderDetails: typeof HeaderDetails;
  Title: typeof Title;
  BankName: typeof BankName;
};

ContentHeader.HeaderChips = HeaderChips;
ContentHeader.DetailsItems = DetailsItems;
ContentHeader.HeaderChip = S.HeaderChip;
ContentHeader.TitleAndRejectionButtonWrapper = TitleAndRejectionButtonWrapper;
ContentHeader.HeaderDetails = HeaderDetails;
ContentHeader.Title = Title;
ContentHeader.BankName = BankName;

export const ProductDetailsContentHeaderLayout = ContentHeader;
