import * as React from "react";
import { Navigate } from "react-router-dom";
import { AnyAppUrl, AnyIndexAppUrl } from "../../urls/internal/types";
import { useAppRouteAccess } from "../hooks/useAppRouteAccess";

export interface AppRouteElementProps {
  children: React.ReactNode;
  url: AnyAppUrl | AnyIndexAppUrl;
}

export const AppRouteElement = ({ children, url }: AppRouteElementProps) => {
  const { redirectTo, state, isAllowed } = useAppRouteAccess(url.urlType, url.checkPermission);

  return (
    <>
      {isAllowed && children}
      {!isAllowed && <Navigate to={redirectTo!} state={state} replace />}
    </>
  );
};
