import styled from "@emotion/styled";

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.sm};
  padding: ${({ theme }) => theme.spaces.sm};
  border-radius: 2px 12px 12px 0;
  background-color: ${({ theme }) => theme.colors.secondaryDisabled};
  max-width: 90%;
  align-self: flex-start;
`;
