import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeDetailsDemandReconciliationContent } from "./BankGuaranteeDetailsDemandReconciliationContent";
import { Button } from "../../../../../../common/ui-kit/form-controls/button";
import { ButtonLink } from "../../../../../../common/ui-kit/buttons/ButtonLink";
import { GuaranteeQueueTasksGroupType } from "../../../../../../common/types/queue-tasks";
import { useBankGuaranteeDetailsFilterContext } from "../../../../hooks/filters/context/useBankGuaranteeDetailsFilterContext";
import { useBankGuaranteeDetailsFilterApiContext } from "../../../../hooks/filters/context/useBankGuaranteeDetailsFilterApiContext";

export interface BankGuaranteeDetailsDemandReconciliationContentFooterProps extends BaseCssProps {
  groupType: GuaranteeQueueTasksGroupType;
  canSendComment?: boolean;
}

export const BankGuaranteeDetailsDemandReconciliationContentFooter = ({
  groupType,
  canSendComment,
  className,
}: BankGuaranteeDetailsDemandReconciliationContentFooterProps) => {
  const filteringObject = useBankGuaranteeDetailsFilterContext();
  const mutate = useBankGuaranteeDetailsFilterApiContext();

  const onHistoryClick = () => {
    mutate(filteringObject, (service) => service.scrollToHistory(groupType));
  };

  const onNewMessageClick = () => {
    mutate(filteringObject, (service) => service.scrollToNewComment(groupType));
  };

  return (
    <ThemeMobileDesktopView
      mobile={
        <Box display="flex" flexDirection="row" gap="sm" className={className} overflow="hidden">
          <Button type="button" variant="contained" color="secondary" onClick={onHistoryClick}>
            <BankGuaranteeDetailsDemandReconciliationContent.Typography>
              История согласования
            </BankGuaranteeDetailsDemandReconciliationContent.Typography>
          </Button>
          {canSendComment && (
            <Button type="button" variant="contained" color="secondary" onClick={onNewMessageClick}>
              <BankGuaranteeDetailsDemandReconciliationContent.Typography>
                Написать сообщение
              </BankGuaranteeDetailsDemandReconciliationContent.Typography>
            </Button>
          )}
        </Box>
      }
      desktop={
        <Box display="flex" flexDirection="row" gap="lg" className={className}>
          <ButtonLink type="button" onClick={onHistoryClick}>
            <BankGuaranteeDetailsDemandReconciliationContent.Typography>
              История согласования
            </BankGuaranteeDetailsDemandReconciliationContent.Typography>
          </ButtonLink>
          {canSendComment && (
            <ButtonLink type="button" onClick={onNewMessageClick}>
              <BankGuaranteeDetailsDemandReconciliationContent.Typography>
                Написать сообщение
              </BankGuaranteeDetailsDemandReconciliationContent.Typography>
            </ButtonLink>
          )}
        </Box>
      }
    />
  );
};
