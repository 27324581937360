import styled from "@emotion/styled";

export const Card = styled.section`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.md};
  background-color: ${({ theme }) => theme.layout.demand.colors.elementAccentBackgroundColor};
  border-radius: 4px;
  overflow: hidden;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: 1.2;

  & > span {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }

  &:focus-within {
    background-color: ${({ theme }) => theme.palette.input.hover.background};
  }
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spaces.sm};
`;

export const RemoveButton = styled.button`
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;

  & > svg > path {
    stroke: ${({ theme }) => theme.palette.typography.primaryCaption};
    transition: all 0.25s linear;
  }

  &:hover,
  &:focus-visible {
    & > svg > path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;
