import * as React from "react";
import styled from "@emotion/styled";
import { useFullPageLayout } from "../app-layout-containers";

type ContainerProps = { withPadding?: boolean };

export const StyledContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - ${({ theme }) => theme.layout.header.height});
  padding: ${(props) => (props.withPadding ? props.theme.layout.borderPadding : 0)};
  overflow: hidden;
  width: 100%;
`;

export const FullPageLayoutContainer = (
  props: { as?: React.ElementType } & ContainerProps & JSX.IntrinsicElements["div"],
) => {
  useFullPageLayout();

  return <StyledContainer {...props} />;
};
