import { useCallback, useEffect, useState } from "react";
import { numberHelper } from "@sideg/helpers";
import { AnyOutputApiAsyncThunk } from "../../store/utils";
import { useAppDispatch } from "../../store";
import { useSingleSearchParam } from "./useSingleSearchParam";

const FIRST_PAGE = "1";
const FILTER_PAGE_KEY = "page";

type FilterPageType = { [FILTER_PAGE_KEY]: number };

// Случай, когда в url нет страницы, но в фильтре она есть как значение по умолчанию.
const isProbablyInitialRender = (queryPage: string | null, filterPage: number) => {
  return queryPage === null && filterPage === 1;
};

// Следующая страница может быть только > 1, поэтому, если queryPage = null, возвращается вторая страница, а не первая
const getNextQueryPage = (queryPage: string | null): string =>
  (numberHelper.getFromString(queryPage ?? FIRST_PAGE) + 1).toString();

export interface UseFilteredPaginatedListPageWithMapperArgs<TFilter extends FilterPageType, TInput> {
  filteringObject: TFilter;
  action: AnyOutputApiAsyncThunk<TInput>;
  comparer: (filter: TFilter, prevObj: TInput) => boolean;
  mapper: (filter: TFilter) => TInput;
}

export const useFilteredPaginatedListPageWithMapper = <TFilter extends FilterPageType, TInput>({
  filteringObject,
  action,
  mapper,
  comparer,
}: UseFilteredPaginatedListPageWithMapperArgs<TFilter, TInput>) => {
  const dispatch = useAppDispatch();
  const [, setPage] = useSingleSearchParam(FILTER_PAGE_KEY);

  const [hasPreviousPage, setHasPreviousPage] = useState<boolean | undefined>(undefined);
  const [prevArg, setPrevArg] = useState(() => mapper(filteringObject));
  const [areArgsEqual, setAreArgsEqual] = useState(() => comparer(filteringObject, prevArg));

  useEffect(() => {
    setAreArgsEqual(comparer(filteringObject, prevArg));
  }, [prevArg, filteringObject, comparer]);

  useEffect(() => {
    setHasPreviousPage((prev) => {
      if (prev === undefined) {
        return filteringObject.page > 1;
      }

      if (filteringObject.page === 1 && prev) {
        return false;
      }

      return prev;
    });
  }, [filteringObject.page]);

  useEffect(() => {
    setPage((prev) => {
      return isProbablyInitialRender(prev, filteringObject.page) ? null : filteringObject.page.toString();
    });
  }, [filteringObject.page, setPage]);

  useEffect(() => {
    if (!areArgsEqual) {
      setPrevArg(mapper(filteringObject));
    }
  }, [filteringObject, mapper, areArgsEqual]);

  useEffect(() => {
    const request = dispatch(action(prevArg));

    return () => {
      request?.abort();
    };
  }, [dispatch, action, prevArg]);

  const onNextPageFetching = useCallback(() => {
    setPage(getNextQueryPage);
  }, [setPage]);

  const onPreviousPageButtonClick = useCallback(() => setPage(FIRST_PAGE), [setPage]);

  const forceFetch = useCallback(() => {
    setAreArgsEqual(false);
  }, []);

  const forceFetchFirstPage = useCallback(() => {
    let needForce = false;
    setPage((prev) => {
      // Избавляемся от лишней зависимости - page
      if (prev === FIRST_PAGE) {
        needForce = true;
      }

      return FIRST_PAGE;
    });

    if (needForce) {
      forceFetch();
    }
  }, [forceFetch, setPage]);

  return {
    hasPreviousPage,
    onNextPageFetching,
    onPreviousPageButtonClick,
    forceFetch,
    forceFetchFirstPage,
  };
};
