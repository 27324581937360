import * as S from "./UserProfileInfoModalWrapper.styled";
import { UserProfileFormWrapper } from "./UserProfileFormWrapper";
import { isLoading, isSucceeded } from "../../../common/types/state";
import { UserProfileInfoForm } from "./UserProfileInfoForm";
import { UserProfileInfoAgent } from "./UserProfileInfoAgent";
import { useAppSelector } from "../../../common/store";
import { selectUserDetails } from "../store/userDetails.selectors";
import { selectAgencyDetails } from "../../../agency/details/store/agencyDetails.selectors";
import { Modal } from "../../../common/ui/containers/modal";
import { Text } from "../../../common/ui-kit/typography";
import { Box } from "../../../common/ui-kit/containers/box";
import { UserProfileInfoSkeletonForm } from "./UserProfileInfoForm.skeleton";
import { UserProfileInfoAgentSkeletonForm } from "./UserProfileInfoAgent.skeleton";

const BoxText = Box.withComponent(Text);

interface UserProfileInfoModalWrapperProps {
  onClose: () => void;
  isOpened: boolean;
}

export const UserProfileInfoModalWrapper = ({ onClose, isOpened }: UserProfileInfoModalWrapperProps) => {
  const {
    user,
    get: { status },
  } = useAppSelector(selectUserDetails);

  const { company, bankAccount, address, contact, status: agencyStatus } = useAppSelector(selectAgencyDetails);

  return (
    <Modal isShown={isOpened} title="Профиль пользователя" onClose={onClose} width="100%">
      <UserProfileFormWrapper status={status}>
        {isLoading(status) && (
          <Box display="flex" flexDirection="column" gap="xxl">
            <UserProfileInfoSkeletonForm />
            <UserProfileInfoAgentSkeletonForm />
          </Box>
        )}
        {isSucceeded(status) && user && <UserProfileInfoForm user={user} />}
        {isSucceeded(agencyStatus) && (
          <S.UserProfileInfoAgentWrapper>
            <BoxText as="h3" pb="xxl" fontWeight={500} fontSize="fz4">
              Профиль агента {company?.typeTitle ?? ""} - {company?.name ?? ""}
            </BoxText>
            <UserProfileInfoAgent company={company} bankAccount={bankAccount} address={address} contact={contact} />
          </S.UserProfileInfoAgentWrapper>
        )}
      </UserProfileFormWrapper>
    </Modal>
  );
};
