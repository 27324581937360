import { QueueTasksCreationHeaderProps } from "./QueueTasksCreationHeaderProps";
import { StyledH2 } from "../../../../common/ui-kit/typography/StyledH2";
import { CancelIcon } from "../../../../common/ui-kit/icons";
import * as S from "./QueueTasksCreationHeader.desktop.styled";

export const QueueTasksCreationHeaderDesktop = ({ from }: QueueTasksCreationHeaderProps) => {
  return (
    <S.Header>
      <StyledH2>Новая заявка</StyledH2>
      <S.LinkContainer>
        <S.Link to={from.url}>
          <CancelIcon /> <span>Отменить</span>
        </S.Link>
      </S.LinkContainer>
    </S.Header>
  );
};
