import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectUserDetails } from "../store/userDetails.selectors";
import { useLocationPathEffect } from "../../../common/navigation/menu/hooks/useLocationPathEffect";
import { LoadingStatusEnum } from "../../../common/types/state";
import { getUserDetails } from "../store/userDetails.thunks";
import { userDetailsGetUpdate, updateLastPulledDetailsTime } from "../store/userDetails.slice";

const MILLISECONDS_IN_ONE_HOUR = 60 * 60 * 1000;

export const useUserDetails = () => {
  const dispatch = useAppDispatch();

  const {
    user,
    manager,
    get: { status },
    lastPulledDetailsTime,
  } = useAppSelector(selectUserDetails);

  useLocationPathEffect(() => {
    const now = Date.now();
    const isNeedForceUpdate = now - (lastPulledDetailsTime ?? 0) > MILLISECONDS_IN_ONE_HOUR;

    if (isNeedForceUpdate) {
      dispatch(userDetailsGetUpdate());
    }
  }, [dispatch, lastPulledDetailsTime]);

  useLocationPathEffect(() => {
    status === LoadingStatusEnum.Failed && dispatch(userDetailsGetUpdate());
  }, [dispatch, status]);

  useEffect(() => {
    if (status === LoadingStatusEnum.Idle) {
      dispatch(getUserDetails());
      dispatch(updateLastPulledDetailsTime(Date.now()));
    }
  }, [dispatch, status]);

  return { user, manager };
};
