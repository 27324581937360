import ym from "react-yandex-metrika";
import { useCallback } from "react";
import { AnalyticGoalType, analyticsConfigs } from "../configs";

export const useReachGoalAnalytics = () => {
  return useCallback((goal: AnalyticGoalType) => {
    if (analyticsConfigs.ym.isEnabled) {
      ym("reachGoal", goal);
    }
  }, []);
};
