import styled from "@emotion/styled";

export const Button = styled.button<{ isOpen: boolean }>`
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  & > svg {
    width: 24px;
    height: 24px;
    transform: rotate(${({ isOpen }) => (isOpen ? 90 : -90)}deg);

    & > path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const BULLET_WIDTH = 1.5;

export const Ul = styled.ul`
  position: relative;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  margin-left: -${BULLET_WIDTH}ch;

  &::before {
    content: "\\00a0•\\00a0";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: ${BULLET_WIDTH}ch;
    height: 100%;
    background: white;
    color: transparent;
    z-index: 1;
  }

  & > li {
    display: inline-block;

    &::before {
      content: "\\00a0•\\00a0";
      display: inline-block;
      width: ${BULLET_WIDTH}ch;
    }
  }
`;
