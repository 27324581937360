import { ReactElement, ReactNode } from "react";
import { AnySchema, InferType } from "yup";
import { ExtractRouteParams } from "../utils";
import { AppUrlSelector } from "../types";
import { useAppUrlTypedMatch } from "../hooks";

export const withValidUrlParams =
  <
    TUrl extends string,
    TSchema extends AnySchema<{ [P in keyof ExtractRouteParams<TUrl>]: unknown }>,
    TComponentProps extends { match: InferType<TSchema> },
  >(
    Component: (props: TComponentProps) => ReactElement,
    urlSelector: AppUrlSelector<TUrl, never>,
    schema: TSchema,
    fallback: ReactNode,
  ) =>
  // eslint-disable-next-line react/function-component-definition
  (props: Omit<TComponentProps, "match">): ReactElement => {
    const match = useAppUrlTypedMatch(urlSelector, schema);

    return (
      <>
        {match === undefined && fallback}
        {match !== undefined && <Component {...(props as TComponentProps)} match={match} />}
      </>
    );
  };
