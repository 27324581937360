import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { FullPageLayoutContainer } from "../../../common/ui/layout";
import { UserChangePasswordForm } from "../components/form/UserChangePasswordForm";
import { UserChangePasswordPageHeader } from "../components/header/UserChangePasswordPageHeader";
import { CreationBlockCard } from "../../../common/ui-kit/cards/creation-block-card";
import { FullPageContainerCompound } from "../../../common/ui/containers/full-page";
import { useNavigationStateFromWithFallback } from "../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../common/urls/internal/utils";
import { Box } from "../../../common/ui-kit/containers/box";

const MainWrapperBox = Box.withComponent(FullPageContainerCompound.MainWrapper);

export const UserChangePasswordPage = () => {
  const { from } = useNavigationStateFromWithFallback(selectAppUrl((x) => x.home).url, "Назад");
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    navigate(from.url);
  }, [from.url, navigate]);

  return (
    <FullPageLayoutContainer>
      <UserChangePasswordPageHeader from={from} />
      <MainWrapperBox flexGrow={1} flexShrink={1}>
        <ThemeMobileDesktopView mobile={<Box padding="lg" />} desktop={undefined} />
        <FullPageContainerCompound.MainCenteredContainer gap="xxl" gapMultiplier={1.5}>
          <CreationBlockCard padding="xl">
            <UserChangePasswordForm onSuccess={onSuccess} />
          </CreationBlockCard>
        </FullPageContainerCompound.MainCenteredContainer>
      </MainWrapperBox>
    </FullPageLayoutContainer>
  );
};
