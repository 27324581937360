import { useState } from "react";
import * as S from "./ProductListCardDemandsListRejectTooltipIcon.desktop.styled";
import { BaseCssProps } from "../../../../../../../../ui-kit/types";
import { usePopup } from "../../../../../../../../ui-kit/pop-up";
import { CommentIcon } from "../../../../../../../../ui-kit/icons";
import { Text } from "../../../../../../../../ui-kit/typography";

export interface ProductListCardRejectTooltipIconProps extends BaseCssProps {
  reason: string;
  comment: string;
}

export const ProductListCardDemandsListRejectTooltipIconDesktop = ({
  reason,
  comment,
  className,
}: ProductListCardRejectTooltipIconProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    referenceElementProps: { ref },
    popupProps,
  } = usePopup({ position: "top-end", offset: [0, 6] });

  return (
    <>
      <S.Icon
        className={className}
        ref={ref}
        onMouseEnter={() => {
          setIsOpen(true);
        }}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
      >
        <CommentIcon />
      </S.Icon>
      {isOpen && (
        <S.PopUp {...popupProps}>
          <Text fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight">
            {reason}
          </Text>
          <Text fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight">
            {comment}
          </Text>
        </S.PopUp>
      )}
    </>
  );
};
