import { ThemeTypographyVariantType } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypography";
import { Theme } from "@sideg/ui-kit/core/theme";
import { PickCssObjectProps } from "@sideg/ui-kit/common/types";
import { getValueByPath } from "@sideg/ui-kit/core/theme/utils/getValueByPath";

export interface ThemeTypographyVariantTokenValue {
  fontWeight: number;
  fontSize: number;
  letterSpacing: number;
  lineHeightPx: number;
}

export type ThemeTypographyVariantValue =
  | ThemeTypographyVariantTokenValue
  | { [key: string]: ThemeTypographyVariantValue };

export const isThemeTypographyTokenValue = (value: unknown): value is ThemeTypographyVariantTokenValue => {
  if (typeof value === "object" && value !== null && !Array.isArray(value)) {
    const keys: (keyof ThemeTypographyVariantTokenValue)[] = [
      "fontSize",
      "fontWeight",
      "letterSpacing",
      "lineHeightPx",
    ];

    return keys.every((key) => {
      if (key in value) {
        const typedValue = value as ThemeTypographyVariantTokenValue;

        return typeof typedValue[key] === "number";
      }

      return false;
    });
  }

  return false;
};

export const mapThemeTypographyVariantToCssObject = (
  variant: ThemeTypographyVariantType,
  theme: Theme,
): PickCssObjectProps<"fontWeight" | "fontSize" | "lineHeight" | "letterSpacing"> => {
  const { fontWeight, fontSize, lineHeightPx, letterSpacing } = getValueByPath<ThemeTypographyVariantTokenValue>(
    theme.typography.variants,
    variant,
  );

  return { fontWeight, fontSize: `${fontSize}px`, lineHeight: `${lineHeightPx}px`, letterSpacing };
};
