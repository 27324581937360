import { useFormikContext } from "formik";
import {
  DemandQueueTaskActiveFormCommentField,
  DemandQueueTaskActiveFormDecisionsField,
} from "../../../../../../../demand/queue-tasks";
import { BankGuaranteeDetailsComplicatedTaskBillAmountFields } from "./BankGuaranteeDetailsComplicatedTaskBillAmountFields";
import { BankGuaranteeDetailsDemandQueueTaskActive } from "../../../../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskActive";
import { BankGuaranteeDetailsComplicatedTaskBillFormValues } from "../../../types/BankGuaranteeDetailsComplicatedTaskBillFormValues";
import { BlockResponsiveSubmitButton } from "../../../../../../../common/ui-kit/buttons/block-responsive-submit";
import { BankGuaranteeDetailsComplicatedTaskBillFileInput } from "./BankGuaranteeDetailsComplicatedTaskBillFileInput";
import { bankGuaranteeDetailsComplicatedTaskBillFormAmountFieldsRulesRules } from "../../../validation/rules/bankGuaranteeDetailsComplicatedTaskBillFormAmountFieldsRules.rules";
import { bankGuaranteeDetailsComplicatedTaskBillFormDocumentFieldsRules } from "../../../validation/rules/bankGuaranteeDetailsComplicatedTaskBillFormDocumentFields.rules";
import { bankGuaranteeDetailsComplicatedTaskBillFormCommentFieldsRules } from "../../../validation/rules/bankGuaranteeDetailsComplicatedTaskBillFormCommentFields.rules";
import { DEFAULT_DECISION } from "../../../validation/bankGuaranteeDetailsComplicatedTaskBillValidationSchema";

export interface BankGuaranteeDetailsComplicatedTaskBillFormContentProps {
  task: BankGuaranteeDetailsDemandQueueTaskActive;
}

export const BankGuaranteeDetailsComplicatedTaskBillFormContent = ({
  task: { billInfo, decisions },
}: BankGuaranteeDetailsComplicatedTaskBillFormContentProps) => {
  const {
    values: { decision },
    isSubmitting,
  } = useFormikContext<BankGuaranteeDetailsComplicatedTaskBillFormValues>();

  const isDisabled = isSubmitting;
  const isDecisionSelected = decision && !DEFAULT_DECISION;

  return (
    <>
      {decisions && <DemandQueueTaskActiveFormDecisionsField decisions={decisions} isDisabled={isDisabled} />}
      {isDecisionSelected && (
        <>
          {bankGuaranteeDetailsComplicatedTaskBillFormAmountFieldsRulesRules.isExist(decision) && billInfo && (
            <BankGuaranteeDetailsComplicatedTaskBillAmountFields billInfo={billInfo} isDisabled={isDisabled} />
          )}

          {bankGuaranteeDetailsComplicatedTaskBillFormCommentFieldsRules.isExist(decision) && (
            <DemandQueueTaskActiveFormCommentField isDisabled={isDisabled} />
          )}

          {bankGuaranteeDetailsComplicatedTaskBillFormDocumentFieldsRules.isExist(decision) && (
            <BankGuaranteeDetailsComplicatedTaskBillFileInput decision={decision} isDisabled={isDisabled} />
          )}

          <BlockResponsiveSubmitButton type="submit" isLoading={isSubmitting}>
            Отправить
          </BlockResponsiveSubmitButton>
        </>
      )}
    </>
  );
};
