import { useFormik } from "formik";
import { useCallback } from "react";
import { FieldNames } from "../types/FieldNames";

type Form = Pick<ReturnType<typeof useFormik>, "touched" | "errors">;

export const useGetFormFieldError = <TFormValues extends Record<string, unknown>>() => {
  return useCallback(({ touched, errors }: Form, field: FieldNames<TFormValues>): string | undefined => {
    if (touched[field]) {
      const error = errors[field];
      if (typeof error === "string") {
        return error;
      }
    }

    return undefined;
  }, []);
};
