import styled from "@emotion/styled";
import { colorWithAlpha } from "../../../../../ui-kit/utils";
import { AppLink } from "../../../../../urls/internal/components";

export const AppPublicLayoutLinkButton = styled(AppLink)`
  appearance: none;
  background: none;
  border: 1px solid ${({ theme }) => colorWithAlpha(theme.palette.background.light, Math.floor(255 * 0.24))};
  border-radius: 0.75rem;
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz4};
  font-weight: 500;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme }) => theme.layout.publicPage.button.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spaces.md};
  text-decoration: none;
  user-select: none;

  :hover {
    color: ${({ theme }) => theme.palette.control.secondaryHover};
    -webkit-tap-highlight-color: ${({ theme }) =>
      colorWithAlpha(theme.palette.background.light, Math.floor(255 * 0.24))};
  }

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    padding: ${({ theme }) => theme.spaces.xs};
    border: 1px solid transparent;
    border-radius: 0;

    :focus-visible {
      border-color: ${({ theme }) => theme.layout.publicPage.button.color};
    }
  }
`;
