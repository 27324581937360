import { ElementType } from "react";
import { usePublicUserResetPasswordPageType } from "../../hooks/usePublicUserResetPasswordPageType";
import { PublicUserResetPasswordApprove } from "../../types/PublicUserResetPasswordApprove";

export interface PublicUserResetPasswordConditionalProps {
  initialElement: ElementType;
  approveElement: ElementType<PublicUserResetPasswordApprove>;
}

export const PublicUserResetPasswordConditional = ({
  initialElement: Initial,
  approveElement: Approve,
}: PublicUserResetPasswordConditionalProps) => {
  const { type, token } = usePublicUserResetPasswordPageType();

  return (
    <>
      {type === "initial" && <Initial />}
      {type === "approve" && token !== undefined && <Approve token={token} />}
    </>
  );
};
