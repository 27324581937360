import { useCallback, useEffect, useState } from "react";

export type NavigationType = "previous" | "next";

export const useWidgetsNewsSlider = (totalCount: number, maxCount: number) => {
  const [count, setCount] = useState(0);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [canNavigatePrevious, setCanNavigatePrevious] = useState(false);
  const [canNavigateNext, setCanNavigateNext] = useState(true);

  useEffect(() => {
    setCanNavigatePrevious(activeItemIndex > 0);
    setCanNavigateNext(activeItemIndex < count - 1);
  }, [activeItemIndex, count]);

  useEffect(() => {
    setCount(Math.min(totalCount, maxCount));
    if (activeItemIndex >= totalCount) {
      setActiveItemIndex(0);
    }
  }, [activeItemIndex, totalCount, maxCount]);

  const onNavigationClick = useCallback(
    (type: NavigationType): void => {
      if (type === "previous" && canNavigatePrevious) {
        setActiveItemIndex((prev) => prev - 1);
      }
      if (type === "next" && canNavigateNext) {
        setActiveItemIndex((prev) => prev + 1);
      }
    },
    [canNavigateNext, canNavigatePrevious]
  );

  return { count, canNavigatePrevious, canNavigateNext, activeItemIndex, setActiveItemIndex, onNavigationClick };
};
