import { FormRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox";
import { useCreationFormFieldTouchedEffect, useGetFieldName, useTypedField } from "../../../../../../../../common/form";
import {
  DemandQueueTaskActiveProjectGuaranteePrintType,
  demandQueueTaskActiveProjectGuaranteePrintTypeWrapped,
} from "../../../../../../../../demand/queue-tasks";
import { BankGuaranteeDetailsComplicatedTaskProjectFormValues } from "../../../../types/BankGuaranteeDetailsComplicatedTaskProjectFormValues";
import * as S from "./BankGuaranteeDetailsComplicatedTaskProjectActiveGuaranteePrintTypeBlock.styled";

export const BankGuaranteeDetailsComplicatedTaskProjectActiveGuaranteePrintTypeBlock = () => {
  const getFieldName = useGetFieldName<BankGuaranteeDetailsComplicatedTaskProjectFormValues>();
  const [{ name, value, error }, { setValue, setTouched }] =
    useTypedField<DemandQueueTaskActiveProjectGuaranteePrintType>(getFieldName("guaranteePrintType"));

  useCreationFormFieldTouchedEffect();

  return (
    <S.RadioButtonGroupContainer>
      <FormRadioButtonGroup label="Тип БГ" error={error}>
        {demandQueueTaskActiveProjectGuaranteePrintTypeWrapped.values.map((type) => (
          <FormRadioButtonGroup.Option
            key={type}
            value={value}
            checked={type === value}
            isSelected={type === value}
            label={demandQueueTaskActiveProjectGuaranteePrintTypeWrapped.getTitleByValue(type)}
            onChange={() => {
              setValue(type, true);
            }}
            onBlur={() => {
              setTouched(true);
            }}
            name={name}
          />
        ))}
      </FormRadioButtonGroup>
    </S.RadioButtonGroupContainer>
  );
};
