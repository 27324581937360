import { DemandQueueTaskActiveBillInfo } from "../../../../../../demand/queue-tasks";
import { BankGuaranteeDetailsBillComplicatedTaskBillInfoCostData } from "./BankGuaranteeDetailsBillComplicatedTaskBillInfoCostData";

export interface BankGuaranteeDetailsBillComplicatedTaskBillInfoCostsProps
  extends Pick<DemandQueueTaskActiveBillInfo, "costBase" | "discountBank" | "discountAgent" | "excessAgent"> {}

export const BankGuaranteeDetailsBillComplicatedTaskBillInfoCosts = ({
  costBase,
  discountBank,
  discountAgent,
  excessAgent,
}: BankGuaranteeDetailsBillComplicatedTaskBillInfoCostsProps) => {
  return (
    <>
      <BankGuaranteeDetailsBillComplicatedTaskBillInfoCostData title="Базовый тариф банка" price={costBase} />
      <BankGuaranteeDetailsBillComplicatedTaskBillInfoCostData title="Скидка за счет банка" price={discountBank} />
      <BankGuaranteeDetailsBillComplicatedTaskBillInfoCostData title="Скидка за счет агента" price={discountAgent} />
      <BankGuaranteeDetailsBillComplicatedTaskBillInfoCostData title="Превышение агента" price={excessAgent} />
    </>
  );
};
