/* eslint-disable react/no-children-prop */
import { Text } from "../../../../common/ui-kit/typography";
import { FontColor } from "../../../../common/ui-kit/typography/text/Text";

export interface ChatDialogMessageTextProps {
  message: string;
  fontColor: FontColor;
}

export const ChatDialogMessageText = ({ fontColor, message }: ChatDialogMessageTextProps) => {
  return (
    <Text
      as="p"
      fontColor={fontColor}
      fontSize="fz2"
      fontWeight={500}
      lineHeight="1.4"
      whiteSpace="break-spaces"
      wordBreak="break-word"
      children={message}
    />
  );
};
