import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../../../common/types/utils";

const titles = {
  Agreement: "Запрос на согласование",
  Bank: "На рассмотрении в банке",
  Approved: "Согласовано",
};

const types = ["Agreement", "Bank", "Approved"] as const;

export const bankGuaranteeDetailsDemandReconciliationStateType = makeEnumLikeLiteral(types, titles);

export type BankGuaranteeDetailsDemandReconciliationStateType = EnumLikeLiteralType<
  typeof bankGuaranteeDetailsDemandReconciliationStateType
>;
