import { forwardRef } from "react";
import { DemandQueueTaskComment } from "../../types/DemandQueueTaskComment";
import { DemandQueueTaskCard } from "../card/DemandQueueTaskCard";
import { DemandQueueTaskCompletedTwoCommentsCard } from "./DemandQueueTaskCompletedTwoCommentsCard";
import { DemandQueueTaskCompletedSingleCommentCard } from "./DemandQueueTaskCompletedSingleCommentCard";

export interface DemandQueueTaskCardProps {
  title: string;
  managerComment?: DemandQueueTaskComment;
  agentComment?: DemandQueueTaskComment;
  className?: string;
  isFocused?: boolean;
}

export const DemandQueueTaskCompletedCard = forwardRef<HTMLDivElement, DemandQueueTaskCardProps>(
  ({ title, agentComment, managerComment, className, isFocused }, ref) => {
    if (!agentComment && !managerComment) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return null!;
    }

    return (
      <DemandQueueTaskCard variant="completed" title={title} className={className} ref={ref} isFocused={isFocused}>
        {agentComment && managerComment ? (
          <DemandQueueTaskCompletedTwoCommentsCard managerComment={managerComment} agentComment={agentComment} />
        ) : (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <DemandQueueTaskCompletedSingleCommentCard comment={agentComment ?? managerComment!} />
        )}
      </DemandQueueTaskCard>
    );
  },
);
