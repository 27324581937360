import { ResponsiveStyleValue } from "@sideg/ui-kit/common/types";
import { CSSObject } from "@emotion/react";
import { ThemeExtendedSpacingType } from "@sideg/ui-kit/core/theme/types/spacing/ThemeSpacingType";

export type ExtendedSpacingType = ThemeExtendedSpacingType;

const PADDING_SHORT = "p" as const;
const MARGIN_SHORT = "m" as const;

const CSS_SPACING_PROPS = ["margin", "padding", "gap", "rowGap"] as const satisfies readonly (keyof CSSObject)[];

const CSS_SPACE_DIRECTION = ["t", "l", "b", "r"] as const;
const AXES_SPACES = ["x", "y"] as const;

type CssSpaceDirection = (typeof CSS_SPACE_DIRECTION)[number];
type AxesSpaces = (typeof AXES_SPACES)[number];

type CssSpacingProps = (typeof CSS_SPACING_PROPS)[number];

type MakeSpacesType<TPrefix extends string> = {
  [P in `${TPrefix}${CssSpaceDirection | AxesSpaces}`]?: ResponsiveStyleValue<ExtendedSpacingType>;
};

export type SpacesProps = {
  [P in CssSpacingProps]?: ResponsiveStyleValue<ExtendedSpacingType>;
} & MakeSpacesType<typeof PADDING_SHORT> &
  MakeSpacesType<typeof MARGIN_SHORT>;

const getCssSpacingPropByPrefix = (prefix: string): Exclude<CssSpacingProps, "gap"> | undefined => {
  switch (prefix) {
    case PADDING_SHORT:
      return "padding";
    case MARGIN_SHORT:
      return "margin";
    default:
      return undefined;
  }
};

const cssSpaceFullDirections = {
  t: "Top",
  r: "Right",
  b: "Bottom",
  l: "Left",
} satisfies { [K in CssSpaceDirection]: string };

const MAX_SPACE_PROP_LENGTH = 2;

const includes = <TArray extends readonly string[]>(array: TArray, value: string): value is TArray[number] =>
  array.includes(value);

export const getCssPropsForSpaceProp = (key: keyof SpacesProps): (keyof CSSObject)[] => {
  if (includes(CSS_SPACING_PROPS, key)) {
    return [key];
  }

  if (key.length !== MAX_SPACE_PROP_LENGTH) {
    return [];
  }

  const [prefix, specifier] = Array.from(key);

  const prop = getCssSpacingPropByPrefix(prefix);
  if (prop) {
    if (includes(CSS_SPACE_DIRECTION, specifier)) {
      return [`${prop}${cssSpaceFullDirections[specifier]}`];
    }

    if (includes(AXES_SPACES, specifier)) {
      switch (specifier) {
        case "x":
          return [`${prop}${cssSpaceFullDirections.l}`, `${prop}${cssSpaceFullDirections.r}`];
        case "y":
          return [`${prop}${cssSpaceFullDirections.t}`, `${prop}${cssSpaceFullDirections.b}`];
        default:
          return [];
      }
    }
  }

  return [];
};
