import { RootState } from "../../../../common/store";
import { CreditExecutionDetailsTasksHistoryState } from "./creditExecutionDetailsTasksHistory.types";
import { selectCreditExecutionDetails } from "../creditExecutionDetails.selectors";
import { demandQueueTasksAdapter } from "../../../../demand/queue-tasks";

export const selectCreditExecutionDetailsTasksHistory = (state: RootState): CreditExecutionDetailsTasksHistoryState =>
  selectCreditExecutionDetails(state).tasksHistory;

export const {
  selectIds: selectCreditExecutionDetailsTasksHistoryIds,
  selectById: selectCreditExecutionDetailsTasksHistoryById,
  selectTotal: selectCreditExecutionDetailsTasksHistoryTotal,
} = demandQueueTasksAdapter.getSelectors(selectCreditExecutionDetailsTasksHistory);
