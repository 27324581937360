import { useCallback } from "react";
import { useApiMutation } from "../../../../common/hooks";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { DocumentTypeId } from "../../../../common/types/documents";
import { pushSuccessGlobalToast } from "../../../../global/toasts";
import { uploadGroupDocumentCreditWorkingAssetsDetails } from "../../store/creditWorkingAssetsDetails.thunks";
import { selectCreditWorkingAssetsDetailsDocumentById } from "../../store/documents/creditWorkingAssetsDetailsDocuments.selectors";
import { markAsUploadedCreditWorkingAssetsDetailsDocuments } from "../../store/documents/creditWorkingAssetsDetailsDocuments.slice";
import { useCreditWorkingAssetsDetailsContext } from "../context/useCreditWorkingAssetsDetailsContext";

export const useCreditWorkingAssetsDetailsDocumentsHandleDrop = (documentId: DocumentTypeId) => {
  const dispatch = useAppDispatch();
  const { creditWorkingAssetsId } = useCreditWorkingAssetsDetailsContext();

  const document = useAppSelector((state) => selectCreditWorkingAssetsDetailsDocumentById(state, documentId));

  const { status, error, mutate } = useApiMutation(uploadGroupDocumentCreditWorkingAssetsDetails, {
    errorMessagePlaceholder: "Файл не загружен. Повторите попытку",
  });

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (document?.id !== undefined && document?.requestedDocType !== undefined && acceptedFiles.length === 1) {
        const result = await mutate({
          creditWorkingAssetsId,
          id: document.id,
          content: acceptedFiles[0],
          requestedDocType: document.requestedDocType,
        });

        if (result !== undefined) {
          dispatch(pushSuccessGlobalToast("Файл прикреплен", 2_000));
          dispatch(markAsUploadedCreditWorkingAssetsDetailsDocuments(document.id));
        }
      }
    },
    [creditWorkingAssetsId, dispatch, document?.id, document?.requestedDocType, mutate]
  );

  return {
    document,
    status,
    error,
    handleDrop,
  };
};
