import styled from "@emotion/styled";

const CIRCLE_WIDTH_PX = 240;

export const OuterCircle = styled.div<{ gradient: string }>`
  width: ${CIRCLE_WIDTH_PX}px;
  height: ${CIRCLE_WIDTH_PX}px;
  border-radius: 50%;
  background: repeating-conic-gradient(${({ gradient }) => gradient});
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InnerCircle = styled.div`
  background-color: ${({ theme }) => theme.palette.background.light};
  width: ${CIRCLE_WIDTH_PX - 20}px;
  height: ${CIRCLE_WIDTH_PX - 20}px;
  border-radius: 50%;
  line-height: 3rem;
  color: ${({ theme }) => theme.palette.typography.primary};
  font-weight: 500;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
