import styled from "@emotion/styled";
import { Link } from "../../../../../common/ui-kit/links";

export const ManagerDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.sm};
  margin-top: ${({ theme }) => theme.spaces.xxl};
  background-color: ${({ theme }) => theme.layout.header.popup.userMenuBackgroundColor};
  padding: ${({ theme }) => theme.makePadding("sm", "md")};
  border-radius: 8px;
`;

export const ManagerDetailsLink = styled(Link)`
  font-size: ${({ theme }) => theme.font.fz3};
`;
