import * as React from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Grid } from "../../../../common/ui-kit/containers/responsive-grid";

export interface BankGuaranteeDocumentsListItemContainerDesktopProps extends BaseCssProps {
  name: React.ReactNode;
  taxSystem: React.ReactNode;
  amounts: React.ReactNode;
  template: React.ReactNode;
}

export const BankGuaranteeDocumentsListItemContainerDesktop = ({
  className,
  name,
  amounts,
  template,
  taxSystem,
}: BankGuaranteeDocumentsListItemContainerDesktopProps) => {
  return (
    <Grid container gap="xxl" className={className}>
      <Grid item xs={5}>
        {name}
      </Grid>
      <Grid item xs={2}>
        {taxSystem}
      </Grid>
      <Grid item xs={3}>
        {amounts}
      </Grid>
      <Grid item xs={2}>
        {template}
      </Grid>
    </Grid>
  );
};
