import { useMemo } from "react";
import { LegalServicesCreationProductDocument } from "../../types/LegalServicesCreationProductDocument";
import { useAppSelector } from "../../../../common/store";
import {
  selectLegalServicesCreationById,
  selectLegalServicesCreationClientSearch,
} from "../../store/legalServicesCreation.selectors";
import { LegalServicesProductId } from "../../../common/types";

export const useLegalServicesCreationFormDocuments = (
  productId: LegalServicesProductId,
): LegalServicesCreationProductDocument[] | undefined => {
  const product = useAppSelector((state) => selectLegalServicesCreationById(state, productId));
  const client = useAppSelector(selectLegalServicesCreationClientSearch);

  return useMemo(() => {
    switch (client.check?.data?.companyType.code) {
      case "Individual":
        return product?.individualDocuments ?? product?.legalDocuments;
      case "Legal":
        return product?.legalDocuments;
      default:
        return undefined;
    }
  }, [client.check?.data?.companyType.code, product?.individualDocuments, product?.legalDocuments]);
};
