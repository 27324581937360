import { memo } from "react";
import { BaseCssProps } from "../../../ui-kit/types";
import { isLoading, isSucceeded, LoadingStatusEnum } from "../../../types/state";
import { StackPanel } from "../../../ui-kit/containers/stack-panel";
import {
  DemandActualDocument,
  DemandActualDocumentSkeleton,
  DemandActualDocumentType,
} from "../../../../demand/actual-documents";
import { Box } from "../../../ui-kit/containers/box";
import { ProductDetailsDemandRoundedCard } from "../layout/ProductDetailsDemandRoundedCard";
import { Text } from "../../../ui-kit/typography";

const skeletonDocuments: number[] = Array.from({ length: 6 }).map((_, i) => i);

export interface ProductDetailsDemandActualDocumentsListProps extends BaseCssProps {
  loadingStatus: LoadingStatusEnum;
  documents: DemandActualDocumentType[];
}

export const ProductDetailsDemandActualDocumentsList = memo(
  ({ className, documents, loadingStatus }: ProductDetailsDemandActualDocumentsListProps) => {
    return (
      <StackPanel direction="column" gap="md" as={ProductDetailsDemandRoundedCard} className={className}>
        {isSucceeded(loadingStatus) && documents.length === 0 && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Text lineHeight="defaultLineHeight" fontSize="fz2" fontColor="primaryCaption">
              Нет документов
            </Text>
          </Box>
        )}
        {isLoading(loadingStatus) &&
          documents.length === 0 &&
          skeletonDocuments.map((x) => <DemandActualDocumentSkeleton key={x} />)}
        {documents.map((x) => (
          <DemandActualDocument documentTypeTitle={x.title} key={x.id} file={x.file} />
        ))}
      </StackPanel>
    );
  }
);
