import { useEffect, useState } from "react";
import { clientRules, ClientSearchCheckedModel } from "../../../../client-search";
import { useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsCreationClientSearch } from "../../store/creditWorkingAssetsCreation.selectors";
import { CreditWorkingAssetsCreationFormStages } from "../../types/creation/CreditWorkingAssetsCreationFormStages";

const getActualStage = (clientCheck: ClientSearchCheckedModel | undefined): CreditWorkingAssetsCreationFormStages => {
  return clientCheck !== undefined && clientRules.isClientPassing(clientCheck) ? "terms" : "client";
};

export const useCreditWorkingAssetsCreationCreatingFormStage = () => {
  const { check } = useAppSelector(selectCreditWorkingAssetsCreationClientSearch);

  const [stage, setStage] = useState<CreditWorkingAssetsCreationFormStages>(getActualStage(check?.data));

  useEffect(() => {
    setStage(getActualStage(check?.data));
  }, [check]);

  return stage;
};
