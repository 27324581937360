import { useCallback } from "react";
import { useFormikContext } from "formik";
import { ReportsDemandFormValues } from "../types/ReportsDemandFormValues";
import { ReportsDemandPresetType } from "../types/ReportsDemandPresetType";

export const useReportsDemandPresetOnClick = () => {
  const { setValues } = useFormikContext<ReportsDemandFormValues>();

  return useCallback(
    (presetType: ReportsDemandPresetType) => () => {
      let month = new Date().getMonth();
      let dateEnd = new Date();
      switch (presetType) {
        case "previousMonth":
          month -= 1;
          dateEnd = new Date(new Date().setDate(0));
          break;
        case "last3Months":
          month -= 2;
          break;
        case "last6Months":
          month -= 5;
          break;
        default:
          break;
      }

      const dateStart = new Date(new Date().setMonth(month, 1));

      setValues({ dateStart, dateEnd });
    },
    [setValues]
  );
};
