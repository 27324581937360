import { memo } from "react";
import { BankGuaranteeDetailsDemandsList } from "./demands/BankGuaranteeDetailsDemandsList";
import { BankGuaranteeDetailsShortDetails } from "./short-details/BankGuaranteeDetailsShortDetails";
import { BankGuaranteeDetailsAsideNumberTitle } from "./BankGuaranteeDetailsAsideNumberTitle";
import { BankGuaranteeDetailsAsideActions } from "./actions/BankGuaranteeDetailsAsideActions";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeDetailsNavigationCardGuaranteeDetails } from "./navigation-card/BankGuaranteeDetailsNavigationCardGuaranteeDetails";
import { Box } from "../../../../common/ui-kit/containers/box";
import { BankGuaranteeDetailsNavigationCardDocuments } from "./navigation-card/BankGuaranteeDetailsNavigationCardDocuments";
import { BankGuaranteeDetailsNavigationCardRequestOriginal } from "./navigation-card/BankGuaranteeDetailsNavigationCardRequestOriginal";

export interface DemandDetailsAsideProps {
  className?: string;
}

export const BankGuaranteeDetailsAside = memo(({ className }: DemandDetailsAsideProps) => {
  return (
    <Box display="flex" overflow="hidden" flexDirection="column" gap="lg" className={className}>
      <BankGuaranteeDetailsShortDetails />

      <StackPanel gap="sm" direction="column">
        <BankGuaranteeDetailsNavigationCardGuaranteeDetails />
        <BankGuaranteeDetailsNavigationCardDocuments />
        <BankGuaranteeDetailsNavigationCardRequestOriginal />
      </StackPanel>

      <StackPanel gap="sm" direction="column">
        <BankGuaranteeDetailsAsideNumberTitle />
        <BankGuaranteeDetailsAsideActions />
      </StackPanel>
      <BankGuaranteeDetailsDemandsList />
    </Box>
  );
});
