import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { BANK_GUARANTEE_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

const demandUrl = makeAppUrl(
  joinUrlParts(BANK_GUARANTEE_ROOT_URL, ":guaranteeId", "demand", ":demandId"),
  "/guarantee/:guaranteeId/demand/:demandId",
  makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
);

export const bankGuaranteeDetailsUrls = {
  guarantee: makeAppUrl(
    joinUrlParts(BANK_GUARANTEE_ROOT_URL, ":guaranteeId"),
    "/guarantee/:guaranteeId",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
  details: makeAppUrl(
    joinUrlParts(BANK_GUARANTEE_ROOT_URL, ":guaranteeId", "details"),
    "/guarantee/:guaranteeId/details",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
  documents: makeAppUrl(
    joinUrlParts(BANK_GUARANTEE_ROOT_URL, ":guaranteeId", "documents"),
    "/guarantee/:guaranteeId/documents",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
  demand: demandUrl,
  requestOriginal: makeAppUrl(
    joinUrlParts(demandUrl.url, "request-original"),
    "/guarantee/:guaranteeId/demand/:demandId/request-original",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
  bill: makeAppUrl(
    joinUrlParts(demandUrl.url, "bill"),
    "/guarantee/:guaranteeId/demand/:demandId/bill",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
  project: makeAppUrl(
    joinUrlParts(demandUrl.url, "project"),
    "/guarantee/:guaranteeId/demand/:demandId/project",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
};
