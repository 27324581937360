import styled from "@emotion/styled";

export const Content = styled.div<{ isLoading?: boolean }>`
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "unset")};
`;

export const CenteredLoader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
