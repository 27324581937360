/* eslint-disable react/no-children-prop */
import { forwardRef } from "react";
import { dateTimeHelper } from "@sideg/helpers";
import * as S from "./ChatDialogRightSideMessage.styled";
import { Text } from "../../../../common/ui-kit/typography";
import { ChatDialogMessageText } from "./ChatDialogMessageText";

export interface ChatDialogRightSideMessageProps {
  message: string;
  date?: string;
  className?: string;
}

export const ChatDialogRightSideMessage = forwardRef<HTMLDivElement, ChatDialogRightSideMessageProps>(
  ({ message, date, className }, ref) => {
    return (
      <S.Message className={className} ref={ref}>
        <ChatDialogMessageText message={message} fontColor="primaryLight" />
        {date && (
          <Text as="span" fontColor="colors.secondaryLight" fontSize="fz1">
            {dateTimeHelper.format(new Date(date), "timeOnly")}
          </Text>
        )}
      </S.Message>
    );
  },
);
