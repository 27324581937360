import { useEffect } from "react";
import { chatDialogConfigs } from "../../configs";
import { isIdle } from "../../../../common/types/state";
import { getMessagesChatDialog } from "../../store/chatDialog.thunks";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectChatDialog } from "../../store/chatDialog.selectors";
import { useHubState } from "../../../../common/hubs";
import { chatDialogHubConnection } from "../../api/chatDialogHubConnection";

export const useChatDialogFetchFirstPage = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectChatDialog);
  const { isConnected } = useHubState(chatDialogHubConnection);

  useEffect(() => {
    if (isIdle(status) && isConnected) {
      dispatch(getMessagesChatDialog({ pageSize: chatDialogConfigs.firstPageSize }));
    }
  }, [dispatch, isConnected, status]);
};
