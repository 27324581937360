import styled from "@emotion/styled";
import { ElementColor, getElementColor } from "../../../../ui-kit/types";

export const Button = styled.button<{ stroke?: ElementColor }>`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg > path {
    stroke: ${({ theme, stroke }) => (stroke ? getElementColor(stroke, theme) : theme.palette.typography.primaryLight)};
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;
