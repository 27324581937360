import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useCreditExecutionListFilterContext } from "../../../../hooks/filters/useCreditExecutionListFilterContext";
import { useProductFilterArray } from "../../../../../../common/filters/use-cases";
import { selectCreditExecutionFiltersData } from "../../../../store/filters/creditExecutionListFilters.selectors";
import { FilterMultipleSelectList } from "../../../../../../common/ui-kit/form-controls/select";

export type CreditExecutionListHeaderFiltersTaskTypesDesktopProps = BaseCssProps;

export const CreditExecutionListHeaderFiltersTaskTypesDesktop = ({
  className,
}: CreditExecutionListHeaderFiltersTaskTypesDesktopProps) => {
  const filteringObject = useCreditExecutionListFilterContext();

  const { getItems, selectedItems, onSelectedChange, maxItemLength, getItemTitle } = useProductFilterArray({
    filteringObject,
    key: "taskTypes",
    availableFiltersSelector: selectCreditExecutionFiltersData,
  });

  return (
    <FilterMultipleSelectList
      className={className}
      text="Задачи"
      selectedItems={selectedItems}
      getItemTitle={getItemTitle}
      onSelectedChanged={onSelectedChange}
      getItems={getItems}
      maxItemsLength={maxItemLength}
    />
  );
};
