import { Form, Formik } from "formik";
import Button, { ButtonColor, ButtonType } from "../../../../../components/controls-old/button/Button";
import { ScoringFormValues, initialValues } from "./data/initialValues";
import { CalculatorPurchaseInfo } from "./purchase-info/CalculatorPurchaseInfo";
import styles from "./GuaranteeCalculatorForManagingDirectorOfAgentForm.module.css";
import { CalculatorGuaranteeInfo } from "./guarantee-Info/CalculatorGuaranteeInfo";
import { CalculatorCompanyInfo } from "./client-info/CalculatorCompanyInfo";
import { ScoringModel } from "../../../../../entity/models/ScoringModel";
import { useSelector } from "react-redux";
import { GroupTypeOptions } from "./data/GroupTypeOptions";
import LoaderSmall from "../../../../../components/common/loader-small/LoaderSmall";
import RequestStatus from "../../../../../entity/models/enums/RequestStatus";
import SubmitBlockError from "../../../../../ui/components/common/submit-block/error/SubmitBlockError";
import { CalculatorValidationSchema } from "./data/CalculatorValidationSchema";
import { RootState } from "../../../../../domains/common/store";

interface GuaranteeCalculatorForManagingDirectorOfAgentFormProps {
  onSend: (form: ScoringFormValues) => void;
}

export const GuaranteeCalculatorForManagingDirectorOfAgentForm = ({
  onSend,
}: GuaranteeCalculatorForManagingDirectorOfAgentFormProps) => {
  const { scoringOptions, scoringDataProcessingStatus } = useSelector((state: RootState) => state.scoring);

  const getOptionsGroup = (groupType: GroupTypeOptions, scoringOptionsData: ScoringModel) => {
    const item = scoringOptionsData.groupedParameters.find((item) => item.groupType === groupType);
    return item?.items;
  };

  return (
    <Formik initialValues={initialValues} validationSchema={CalculatorValidationSchema} onSubmit={onSend}>
      {({ errors, touched, values, dirty }) => {
        return (
          scoringOptions && (
            <Form>
              <CalculatorPurchaseInfo
                values={values}
                fzOptions={getOptionsGroup(GroupTypeOptions.TypeFederalLaw, scoringOptions)}
                tenderInfoOptions={getOptionsGroup(GroupTypeOptions.Tender, scoringOptions)}
              />
              <CalculatorGuaranteeInfo
                values={values}
                guaranteeTypesOptions={getOptionsGroup(GroupTypeOptions.TypeGuarantee, scoringOptions)}
                guaranteeInfoOptions={getOptionsGroup(GroupTypeOptions.Addition, scoringOptions)}
                errors={errors}
                touched={touched}
              />
              <CalculatorCompanyInfo
                values={values}
                title="Информация о Клиенте"
                additionalText="Дополнительно о Клиенте"
                clientOptions={getOptionsGroup(GroupTypeOptions.Client, scoringOptions)}
              />
              <CalculatorCompanyInfo
                values={values}
                title="Информация о Заказчике"
                additionalText="Дополнительно о Заказчике"
                clientOptions={getOptionsGroup(GroupTypeOptions.Customer, scoringOptions)}
              />

              <div className={styles.submitButtonWrapper}>
                <Button isDisabled={!dirty} type={ButtonType.Submit} color={ButtonColor.Green}>
                  Рассчитать
                </Button>
                {scoringDataProcessingStatus === RequestStatus.Request && <LoaderSmall className={styles.loader} />}
                {scoringDataProcessingStatus === RequestStatus.Failure && (
                  <div className={styles.loader}>
                    <SubmitBlockError>Не удалось отправить данные на сервер. Повторите попытку позже.</SubmitBlockError>
                  </div>
                )}
              </div>
            </Form>
          )
        );
      }}
    </Formik>
  );
};
