import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LoadingStatusEnum } from "../../types/state";
import { AsyncThunkActionRequest } from "../../types/thunks";
import { useAppDispatch, useAppSelector } from "../../store";
import { purchaseNumberRules } from "../validation/rules/purchaseNumber.rules";
import { useDebounce } from "../../hooks/useDebounce";
import { loggingService } from "../../logging/services/loggingService";
import { getPurchase } from "../store/purchase.thunks";
import { PurchaseStateSelector } from "../types/PurchaseStateSelector";
import { PurchaseFixedKey } from "../types/PurchaseFixedKey";
import { PurchaseDetails } from "../types/PurchaseDetails";
import { updatePurchaseStatus } from "../store/purchase.slice";

const getNewStatus = (isValueEmpty: boolean, isValueParsable: boolean): LoadingStatusEnum => {
  if (isValueEmpty) {
    return LoadingStatusEnum.Idle;
  }

  if (isValueParsable) {
    return LoadingStatusEnum.Loading;
  }

  return LoadingStatusEnum.Failed;
};

export interface UsePurchaseFetcherResult {
  status: LoadingStatusEnum;
  abort: () => void;
}

export const usePurchaseFetcher = (
  key: PurchaseFixedKey,
  fieldValue: string,
  purchaseStateSelector: PurchaseStateSelector,
  formMutator: (data: PurchaseDetails) => void,
): UsePurchaseFetcherResult => {
  const dispatch = useAppDispatch();

  const { fetchedPurchaseNumber, status } = useAppSelector(purchaseStateSelector);

  const [debounceValue] = useDebounce(fieldValue, 500);

  const [valueToFetch, setValueToFetch] = useState<string | undefined>(undefined);

  const requestRef = useRef<AsyncThunkActionRequest<unknown> | undefined>(undefined);

  const abort = useCallback(() => {
    requestRef.current?.abort();
    requestRef.current = undefined;
  }, []);

  useEffect(() => {
    abort();
  }, [dispatch, debounceValue, abort]);

  useEffect(() => {
    if (debounceValue !== fetchedPurchaseNumber) {
      const isEmpty = debounceValue === "";
      const isParsable = purchaseNumberRules.isParsableNumber(debounceValue);

      setValueToFetch(isParsable ? debounceValue : undefined);

      dispatch(updatePurchaseStatus({ key, status: getNewStatus(isEmpty, isParsable) }));
    }
  }, [debounceValue, dispatch, fetchedPurchaseNumber, key]);

  useEffect(() => {
    const handler = async (purchaseNumber: string) => {
      try {
        const action = dispatch(getPurchase({ purchaseNumber, key }));
        requestRef.current = action;

        const data = await action.unwrap();
        if (data.body.purchases.length > 0) {
          formMutator(data.body.purchases[0]);
        }
      } catch (e) {
        loggingService.logWarn(e);
      }
    };

    if (valueToFetch !== undefined && fetchedPurchaseNumber !== valueToFetch) {
      handler(valueToFetch);
    }
  }, [dispatch, fetchedPurchaseNumber, formMutator, key, valueToFetch]);

  return useMemo(() => ({ status, abort }), [abort, status]);
};
