import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  }
`;
