import Skeleton from "react-loading-skeleton";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { Text } from "../../../../../../common/ui-kit/typography";
import * as S from "./BankGuaranteeDetailsDemandHeaderAlert.styled";
import { BankGuaranteeDetailsHeaderMobileAlert } from "./mobile/BankGuaranteeDetailsHeaderMobileAlert";
import { BankGuaranteeDetailsDemandStatusType } from "../../../../types/demand/BankGuaranteeDetailsDemandStatusType";
import { BankGuaranteeDetailsHeaderMobileAlertSkeleton } from "./mobile/BankGuaranteeDetailsHeaderMobileAlert.skeleton";
import { BankGuaranteeBaseDemand } from "../../../../../common/types";
import { isBankGuaranteeDetailsStatusTypeWithDescription } from "../../../../utils/demand/isBankGuaranteeDetailsStatusTypeWithDescription";
import { BankGuaranteeDetailsDemandHeaderStatusChip } from "../status/BankGuaranteeDetailsDemandHeaderStatusChip";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";

const BoxChip = Box.withComponent(BankGuaranteeDetailsDemandHeaderStatusChip);

const ATTACH_DOCUMENTS_TEXT = "Приложите необходимые для рассмотрения заявки документы";
const DEFAULT_TEXT =
  "Мы рассматриваем вашу заявку. Если нам понадобятся дополнительные документы или данные, мы отправим запрос. После рассмотрения отправим вам проект и счет для согласования.";

const getText = (
  statusType?: BankGuaranteeDetailsDemandStatusType,
  demand?: BankGuaranteeBaseDemand,
  className?: string,
) => {
  return (
    isBankGuaranteeDetailsStatusTypeWithDescription(statusType, demand?.stage.code) && (
      <Text
        as={S.TextContainer}
        fontColor="colors.dark"
        fontWeight={500}
        lineHeight="defaultLineHeight"
        fontSize="fz3"
        className={className}
      >
        {statusType === undefined && <Skeleton count={3} />}
        {statusType === "attachDocuments" && ATTACH_DOCUMENTS_TEXT}
        {statusType === "inProcess" && DEFAULT_TEXT}
        {statusType === "rejected" && demand?.termination && (
          <>
            <Text fontColor="colors.dark" fontWeight={700} lineHeight="defaultLineHeight" fontSize="fz3">
              {demand.termination.reason.title}:&nbsp;
            </Text>
            {demand.termination.comment}
          </>
        )}
      </Text>
    )
  );
};

export interface BakGuaranteeDetailsDemandHeaderAlertProps extends BaseCssProps {
  statusType: BankGuaranteeDetailsDemandStatusType | undefined;
  demand: BankGuaranteeBaseDemand | undefined;
}

export const BankGuaranteeDetailsDemandHeaderAlert = ({
  className,
  demand,
  statusType,
}: BakGuaranteeDetailsDemandHeaderAlertProps) => {
  return (
    <ThemeMobileDesktopView
      mobile={
        <>
          {!statusType && <BankGuaranteeDetailsHeaderMobileAlertSkeleton />}
          {statusType && !isBankGuaranteeDetailsStatusTypeWithDescription(statusType, demand?.stage.code) && (
            <BoxChip alignSelf="flex-start" variant={statusType}>
              {demand?.status.title}
            </BoxChip>
          )}
          {statusType && isBankGuaranteeDetailsStatusTypeWithDescription(statusType, demand?.stage.code) && (
            <BankGuaranteeDetailsHeaderMobileAlert
              className={className}
              variant={statusType}
              title={demand?.status.title}
            >
              {getText(statusType, demand)}
            </BankGuaranteeDetailsHeaderMobileAlert>
          )}
        </>
      }
      desktop={getText(statusType, demand, className)}
    />
  );
};
