import { useState } from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { NavigationStateFrom } from "../../../../../common/navigation/navigation-state/types/NavigationStateFrom";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { Text } from "../../../../../common/ui-kit/typography";
import { BankGuaranteeCreationCreateByGuaranteePageType } from "../../../types/creation/BankGuaranteeCreationCreateByGuaranteePageType";
import { StyledAppLink } from "../../../../../common/ui/links";
import { BankGuaranteeId } from "../../../../../common/types/demand";
import { generatePath } from "../../../../../common/urls/internal/utils";
import { FullPageContainerHeaderTitle } from "../../../../../common/ui/containers/full-page";
import { useBackLinkHeaderAction } from "../../../../../common/ui/layout/header";

export interface BankGuaranteeCreationCreateByGuaranteeHeaderMobileProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  from: NavigationStateFrom;
  pageType: BankGuaranteeCreationCreateByGuaranteePageType;
}

export const BankGuaranteeCreationCreateByGuaranteeHeaderMobile = ({
  guaranteeId,
  from,
  pageType,
  className,
}: BankGuaranteeCreationCreateByGuaranteeHeaderMobileProps) => {
  const [scoringLink] = useState(generatePath((x) => x.bankGuarantee.creation.createByGuarantee, { guaranteeId }));
  useBackLinkHeaderAction(
    pageType === "scoring" ? { url: from.url } : { url: scoringLink, text: "Банки", withBackIcon: true }
  );

  return (
    <StackPanel gap="lg" direction="column" alignItems="center" justifyContent="center" className={className}>
      <FullPageContainerHeaderTitle
        text={
          <span>
            Выбор банков
            <br />
            для доотправки заявки
          </span>
        }
      />
      <StackPanel gap="xs" direction="column">
        <Text as="div" textAlign="center" fontSize="fz2">
          {pageType === "details" && (
            <StyledAppLink to={(x) => x.bankGuarantee.creation.createByGuarantee} params={{ guaranteeId }}>
              Выбор банков
            </StyledAppLink>
          )}
          {pageType === "scoring" && (
            <StyledAppLink to={(x) => x.bankGuarantee.creation.createByGuaranteeDetails} params={{ guaranteeId }}>
              Данные заявки
            </StyledAppLink>
          )}
        </Text>
      </StackPanel>
    </StackPanel>
  );
};
