/* eslint-disable @typescript-eslint/no-unused-vars */
import { generatePath as pathGenerator } from "react-router-dom";
import { AppUrlSelector } from "../types";
import { selectAppUrl } from "./selectAppUrl";

export type ExtractRouteOptionalParam<
  T extends string,
  TParam = string | number | boolean,
> = T extends `${infer Param}?`
  ? { [k in Param]?: TParam }
  : T extends `${infer Param}*`
  ? { [k in Param]?: TParam }
  : T extends `${infer Param}+`
  ? { [k in Param]: TParam }
  : { [k in T]: TParam };

export type ExtractRouteParams<T extends string, TParam = string | number | boolean> = string extends T
  ? { [k in string]?: TParam }
  : T extends `${infer _Start}:${infer ParamWithOptionalRegExp}/${infer Rest}`
  ? ParamWithOptionalRegExp extends `${infer Param}(${infer _RegExp})`
    ? ExtractRouteOptionalParam<Param, TParam> & ExtractRouteParams<Rest, TParam>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, TParam> & ExtractRouteParams<Rest, TParam>
  : T extends `${infer _Start}:${infer ParamWithOptionalRegExp}`
  ? ParamWithOptionalRegExp extends `${infer Param}(${infer _RegExp})`
    ? ExtractRouteOptionalParam<Param, TParam>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, TParam>
  : // eslint-disable-next-line @typescript-eslint/ban-types
    {};

export const generatePath = <TUrl extends string>(
  urlSelector: AppUrlSelector<TUrl, never>,
  params?: ExtractRouteParams<TUrl>,
): string => {
  const stringParams =
    params !== undefined
      ? Object.entries(params).reduce(
          (accum, [key, value]) => {
            if (value !== undefined) {
              // eslint-disable-next-line @typescript-eslint/ban-types
              accum[key] = (value as Object).toString();
            }

            return accum;
          },
          {} as Record<string, string>,
        )
      : undefined;

  // так как роутер не экспортирует ожидаемый тип - руками кастим к never.
  return pathGenerator(selectAppUrl(urlSelector).url, stringParams as never);
};
