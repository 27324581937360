import { useFormikContext } from "formik";
import { DemandQueueTaskActiveBillInfo } from "../../../../../demand/queue-tasks";
import { bankGuaranteeDetailsComplicatedTaskBillAmountValidationSchema } from "../validation/bankGuaranteeDetailsComplicatedTaskBillValidationSchema";
import { guaranteeCommissionCalculationHelper } from "../../../../../common/helpers/guarantee";
import { BankGuaranteeDetailsComplicatedTaskBillAmountFormValues } from "../types/BankGuaranteeDetailsComplicatedTaskBillAmountFormValues";

export const useBankGuaranteeDetailsComplicatedTaskBillAmountOnChange = (billInfo: DemandQueueTaskActiveBillInfo) => {
  const { setFieldValue, setFieldTouched } =
    useFormikContext<BankGuaranteeDetailsComplicatedTaskBillAmountFormValues>();

  return async (field: keyof BankGuaranteeDetailsComplicatedTaskBillAmountFormValues, value: string) => {
    const objectToCast = { sum: value, percent: 0 };
    if (!(await bankGuaranteeDetailsComplicatedTaskBillAmountValidationSchema.isValid(objectToCast))) {
      setFieldValue(field, value);

      return;
    }

    const { sum: castedValue } = bankGuaranteeDetailsComplicatedTaskBillAmountValidationSchema.cast(objectToCast, {
      stripUnknown: true,
    }) as BankGuaranteeDetailsComplicatedTaskBillAmountFormValues;

    if (castedValue !== undefined) {
      const sum =
        field === "sum"
          ? value
          : guaranteeCommissionCalculationHelper.calculateCommission(
              billInfo.periodInDays,
              billInfo.guaranteeSum,
              castedValue,
            );
      const percent =
        field === "percent"
          ? value
          : guaranteeCommissionCalculationHelper.calculatePercent(
              castedValue,
              billInfo.periodInDays,
              billInfo.guaranteeSum,
            );

      // Не дожидаемся валидации для первых изменений, чтобы курсор при вводе оставался в нужном поле
      setFieldTouched(field, true);
      setFieldValue("percent", percent, true);
      await setFieldValue("sum", sum);
    }
  };
};
