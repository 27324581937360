import { createAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../common/store/utils";
import { CREDIT_WORKING_ASSETS_SLICE_NAME, CreditWorkingAssetsTransferPayload } from "../../common/store";
import { DemandId } from "../../../common/types/demand";

export const CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME = combineSliceNames(CREDIT_WORKING_ASSETS_SLICE_NAME, "details");

export const creditWorkingAssetsDetailsActiveProductChanged = createAction<
  CreditWorkingAssetsTransferPayload | undefined
>(combineSliceNames(CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME, "activeProductChanged"));

export const creditWorkingAssetsDetailsActiveDemandChanged = createAction<DemandId | undefined>(
  combineSliceNames(CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME, "activeDemandChanged")
);

export const creditWorkingAssetsDetailsReloadActiveDemand = createAction<DemandId | undefined>(
  combineSliceNames(CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME, "reloadActiveDemand")
);

export const creditWorkingAssetsDetailsReloadActiveProduct = createAction(
  combineSliceNames(CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME, "reloadActiveProduct")
);
