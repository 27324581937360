import QuestionnaireCompanyFounderModel from "../../../entity/models/QuestionnaireCompanyFounderModel"
import QuestionnaireUlLiModel from "../../../entity/models/QuestionnaireUlLiModel"

export const UR_LI_LIST_FETCHING = 'UR_LI_LIST_FETCHING'
export const UR_LI_LIST_FETCHED = 'UR_LI_LIST_FETCHED'
export const UR_LI_LIST_ERROR = 'UR_LI_LIST_ERROR'
export const UR_LI_LIST_RESET = 'UR_LI_LIST_RESET'

export const UR_LI_ITEM_FETCHING = 'UR_LI_ITEM_FETCHING'
export const UR_LI_ITEM_FETCHED = 'UR_LI_ITEM_FETCHED'
export const UR_LI_ITEM_ERROR = 'UR_LI_ITEM_ERROR'
export const UR_LI_ITEM_RESET = 'UR_LI_ITEM_RESET'

export const UR_LI_DELETE_PROCESSING = 'UR_LI_DELETE_PROCESSING'
export const UR_LI_DELETE_SUCCESS = 'UR_LI_DELETE_SUCCESS'
export const UR_LI_DELETE_ERROR = 'UR_LI_DELETE_ERROR'
export const UR_LI_DELETE_RESET = 'UR_LI_DELETE_RESET'

export const UR_LI_ADD_PROCESSING = 'UR_LI_ADD_PROCESSING'
export const UR_LI_ADD_SUCCESS = 'UR_LI_ADD_SUCCESS'
export const UR_LI_ADD_ERROR = 'UR_LI_ADD_ERROR'
export const UR_LI_ADD_RESET = 'UR_LI_ADD_RESET'

export const UR_LI_UPDATE_PROCESSING = 'UR_LI_UPDATE_PROCESSING'
export const UR_LI_UPDATE_SUCCESS = 'UR_LI_UPDATE_SUCCESS'
export const UR_LI_UPDATE_ERROR = 'UR_LI_UPDATE_ERROR'
export const UR_LI_UPDATE_RESET = 'UR_LI_UPDATE_RESET'

export interface UrLiListFetching {
    type: typeof UR_LI_LIST_FETCHING
}

export interface UrLiListFetched {
    type: typeof UR_LI_LIST_FETCHED
    payload: QuestionnaireUlLiModel[]
}

export interface UrLiListError {
    type: typeof UR_LI_LIST_ERROR
    error: any
}

export interface UrLiListReset {
    type: typeof UR_LI_LIST_RESET
}

export interface UrLiItemFetching {
    type: typeof UR_LI_ITEM_FETCHING
}

export interface UrLiItemFetched {
    type: typeof UR_LI_ITEM_FETCHED
    payload: QuestionnaireCompanyFounderModel
}

export interface UrLiItemError {
    type: typeof UR_LI_ITEM_ERROR
    error: any
}

export interface UrLiItemReset {
    type: typeof UR_LI_ITEM_RESET
}

export interface UrLiDeleteProcessing {
    type: typeof UR_LI_DELETE_PROCESSING
}

export interface UrLiDeleteSuccess {
    type: typeof UR_LI_DELETE_SUCCESS
    payload: boolean
}

export interface UrLiDeleteError {
    type: typeof UR_LI_DELETE_ERROR
    error: any
}

export interface UrLiDeleteReset {
    type: typeof UR_LI_DELETE_RESET
}

export interface UrLiAddProcessing {
    type: typeof UR_LI_ADD_PROCESSING
}

export interface UrLiAddSuccess {
    type: typeof UR_LI_ADD_SUCCESS
    payload: boolean
}

export interface UrLiAddError {
    type: typeof UR_LI_ADD_ERROR
    error: any
}

export interface UrLiAddReset {
    type: typeof UR_LI_ADD_RESET
}

export interface UrLiUpdateProcessing {
    type: typeof UR_LI_UPDATE_PROCESSING
}

export interface UrLiUpdateSuccess {
    type: typeof UR_LI_UPDATE_SUCCESS
    payload: boolean
}

export interface UrLiUpdateError {
    type: typeof UR_LI_UPDATE_ERROR
    error: any
}

export interface UrLiUpdateReset {
    type: typeof UR_LI_UPDATE_RESET
}

export type UrLiDispatchType =
    UrLiListFetching |
    UrLiListFetched |
    UrLiListError |
    UrLiListReset |
    UrLiItemFetching |
    UrLiItemFetched |
    UrLiItemError |
    UrLiItemReset |
    UrLiDeleteProcessing |
    UrLiDeleteSuccess |
    UrLiDeleteError |
    UrLiDeleteReset |
    UrLiAddProcessing |
    UrLiAddSuccess |
    UrLiAddError |
    UrLiAddReset |
    UrLiUpdateProcessing |
    UrLiUpdateSuccess |
    UrLiUpdateError |
    UrLiUpdateReset