import { createRoot } from "react-dom/client";
import "@sideg/font-pt-root-ui/index.css";
import "./index.css";
import "./styles/common.scss";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { loggingSentryInitService } from "./domains/common/logging/providers/sentry";
import { serviceWorkerRegistrationService } from "./domains/common/service-worker";

loggingSentryInitService.init();
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

serviceWorkerRegistrationService.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
