import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { PhoneNumberInput } from "@sideg/ui-kit/atoms/form-controls/phone-number-input";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { useRef } from "react";
import { useField, useFormikContext } from "formik";
import { ClientSearch, useClientSearchCreationClientIdFieldMutation } from "../../../../client-search";
import { selectLegalServicesCreationClientSearch } from "../../store/legalServicesCreation.selectors";
import { LegalServicesCreationFormCard } from "./LegalServicesCreationFormCard";
import { LineSeparator } from "../../../../common/ui-kit/separators";
import { Grid } from "../../../../common/ui-kit/containers/responsive-grid";
import { ButtonLoading } from "../../../../common/ui-kit/form-controls/button";
import { LegalServicesCreationFormDocuments } from "./LegalServicesCreationFormDocuments";
import { LegalServicesProductId } from "../../../common/types";
import { Box } from "../../../../common/ui-kit/containers/box";
import { LegalServicesCreationFormValues } from "../../types/LegalServicesCreationFormValues";
import { useGetFieldName, useGetFormFieldError } from "../../../../common/form";
import { SimpleOpacityTransition } from "../../../../common/ui-kit/animations/transitions";

export interface LegalServicesCreationFormContentProps {
  productId: LegalServicesProductId;
}

export const LegalServicesCreationFormContent = ({ productId }: LegalServicesCreationFormContentProps) => {
  const transitionContainerRef = useRef<HTMLDivElement | null>(null);
  const desktopBreakpoint = useThemeDesktopBreakpoint();
  const clientId = useClientSearchCreationClientIdFieldMutation<LegalServicesCreationFormValues>(
    selectLegalServicesCreationClientSearch,
  );

  const getFieldName = useGetFieldName<LegalServicesCreationFormValues>();
  const getFieldError = useGetFormFieldError<LegalServicesCreationFormValues>();
  const { getFieldProps, isSubmitting, ...form } = useFormikContext<LegalServicesCreationFormValues>();
  const isDisabled = isSubmitting;

  const [{ value: taxpayerNumber }] = useField<string>(getFieldName("taxpayerNumber"));

  return (
    <>
      <LegalServicesCreationFormCard title="Заказчик">
        <ClientSearch
          canRemove={!isDisabled}
          clientStateSelector={selectLegalServicesCreationClientSearch}
          fixedKey="LegalServices"
          initialSearchValue={taxpayerNumber}
          isWithAddress
          isWithPassingAlert={false}
        />
      </LegalServicesCreationFormCard>

      <SimpleOpacityTransition
        nodeRef={transitionContainerRef}
        timeout={300}
        in={clientId !== undefined}
        unmountOnExit
        mountOnEnter
      >
        <Box display="contents" ref={transitionContainerRef}>
          <LegalServicesCreationFormCard title="Контактное лицо">
            <FormInput
              type="text"
              label="Фамилия, имя и отчество"
              size="medium"
              disabled={isDisabled}
              autoComplete="name"
              {...getFieldProps(getFieldName("fullName"))}
              error={getFieldError(form, "fullName")}
            />
            <Grid container gap="lg">
              <Grid
                item
                as={PhoneNumberInput}
                {...{ xs: 12, [desktopBreakpoint]: 6 }}
                label="Номер телефона"
                size="medium"
                disabled={isDisabled}
                {...getFieldProps(getFieldName("phoneNumber"))}
                error={getFieldError(form, "phoneNumber")}
              />
              <Grid
                item
                as={FormInput}
                {...{ xs: 12, [desktopBreakpoint]: 6 }}
                type="email"
                label="Электронная почта"
                size="medium"
                disabled={isDisabled}
                {...getFieldProps(getFieldName("email"))}
                error={getFieldError(form, "email")}
              />
            </Grid>
            <FormTextarea
              maxRows={10}
              minRows={2}
              label="Дополнительная информация (по желанию)"
              size="medium"
              disabled={isDisabled}
              {...getFieldProps(getFieldName("comment"))}
              error={getFieldError(form, "comment")}
            />
          </LegalServicesCreationFormCard>

          <LegalServicesCreationFormCard title="Необходимые документы">
            <LineSeparator />
            <LegalServicesCreationFormDocuments
              productId={productId}
              isDisabled={isDisabled}
              isLoading={isSubmitting}
            />
          </LegalServicesCreationFormCard>

          <Grid container as={Box} pb="xxl">
            <Grid
              item
              as={ButtonLoading}
              type="submit"
              loaderPosition="replace"
              alignSelf="flex-start"
              isFullWidth
              size="medium"
              variant="contained"
              color="primary"
              isLoading={isSubmitting}
              {...{ xs: 12, [desktopBreakpoint]: 6 }}
            >
              Отправить заявку
            </Grid>
          </Grid>
        </Box>
      </SimpleOpacityTransition>
    </>
  );
};
