import { ReactNode } from "react";
import { Box } from "../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Typography } from "../../../../common/ui-kit/typography";
import { PublicUserCardWithTitle } from "../card/PublicUserCardWithTitle";
import { PublicUserDescriptionTypography } from "../description/PublicUserDescriptionTypography";

export interface PublicUserResultCardProps extends BaseCssProps {
  icon?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
}

export const PublicUserResultCard = ({ icon, title, children, description, className }: PublicUserResultCardProps) => {
  return (
    <PublicUserCardWithTitle className={className}>
      <Box display="flex" flexDirection="column" alignItems="center" gap="xxl">
        {icon}
        {title && (
          <Typography as="h2" fontSize={1.5} fontWeight={700} fontColor="typography.primary">
            {title}
          </Typography>
        )}
        {description && (
          <PublicUserDescriptionTypography textAlign="center">{description}</PublicUserDescriptionTypography>
        )}
        {children}
      </Box>
    </PublicUserCardWithTitle>
  );
};
