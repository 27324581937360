import styled from "@emotion/styled";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ResponsiveStyleValue, ResponsiveValue, SpaceType } from "../../../types";

export type GridColumns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type ItemProps = ResponsiveValue<GridColumns>;

export interface ContainerProps {
  gap?: SpaceType;
  columnGap?: ResponsiveStyleValue<SpaceType>;
  rowGap?: ResponsiveStyleValue<SpaceType>;
}

export const Container = styled.div<ContainerProps>(
  { display: "grid", gridTemplateColumns: "repeat(12, 1fr)" },
  ({ theme, columnGap, gap }) =>
    makeResponsiveStyles(theme, columnGap, (x) => ({ columnGap: theme.spaces[x ?? gap ?? "none"] })),
  ({ theme, rowGap, gap }) =>
    makeResponsiveStyles(theme, rowGap, (x) => ({ rowGap: theme.spaces[x ?? gap ?? "none"] })),
);

export const Item = styled.div<ItemProps>(({ theme, ...rest }) => ({
  ...makeResponsiveStyles(theme, rest, (value) => ({ gridColumn: `span ${value}` })),
}));
