import { useState } from "react";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { CreditExecutionDetailsNavigationHeaderMobile } from "./CreditExecutionDetailsNavigationHeader.mobile";
import { CreditExecutionDetailsNavigationHeaderDesktop } from "./CreditExecutionDetailsNavigationHeader.desktop";
import { useNavigationStateFromWithFallback } from "../../../../common/navigation/navigation-state";
import { generatePath } from "../../../../common/urls/internal/utils";

export const CreditExecutionDetailsNavigationHeader = () => {
  const { from } = useNavigationStateFromWithFallback(
    generatePath((x) => x.creditExecution.list.root),
    "Мои заявки",
  );

  const [initialFrom] = useState(from);

  return (
    <ThemeMobileDesktopView
      mobile={<CreditExecutionDetailsNavigationHeaderMobile from={initialFrom} />}
      desktop={<CreditExecutionDetailsNavigationHeaderDesktop from={initialFrom} />}
    />
  );
};
