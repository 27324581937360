import { AppInfinityScroll, AppInfinityScrollTitles } from "../../../../../common/ui/product/list/infinite-scroll";
import {
  selectBankGuaranteeDetailsTasksHistory,
  selectBankGuaranteeDetailsTasksHistoryIds,
} from "../../../store/tasks-history/bankGuaranteeDetailsTasksHistory.selectors";
import { useAppSelector } from "../../../../../common/store";
import { BankGuaranteeDetailsTasksHistoryListItem } from "./BankGuaranteeDetailsTasksHistoryListItem";
import { QueueTaskId } from "../../../../../common/types/demand";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { DemandQueueTaskCompletedCardSkeleton } from "../../../../../demand/queue-tasks";
import { useBankGuaranteeDetailsFilterScrollToQueueTask } from "../../../hooks/filters/useBankGuaranteeDetailsFilterScrollToQueueTask";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useBankGuaranteeDetailsDemandTasksHistory } from "../../../hooks/completed-tasks/useBankGuaranteeDetailsDemandTasksHistory";

export interface BankGuaranteeDetailsTasksHistoryProps extends BaseCssProps {}

const titles: AppInfinityScrollTitles = {
  end: "Загружены все сообщения по заявке",
  hasNoLoadedItems: "",
  error: "При получении сообщений возникла ошибка",
  nextPageLoading: "Загружаем сообщения",
};

export const BankGuaranteeDetailsTasksHistory = ({ className }: BankGuaranteeDetailsTasksHistoryProps) => {
  const ids = useAppSelector(selectBankGuaranteeDetailsTasksHistoryIds);
  const { bottomAnchorRef } = useBankGuaranteeDetailsFilterScrollToQueueTask();
  const { onNextPageFetching } = useBankGuaranteeDetailsDemandTasksHistory();

  return (
    <AppInfinityScroll
      page={1}
      paginatedStateSelector={selectBankGuaranteeDetailsTasksHistory}
      idsSelector={selectBankGuaranteeDetailsTasksHistoryIds}
      titles={titles}
      onPageChanged={onNextPageFetching}
      onErrorReloadButtonClick={onNextPageFetching}
      emptyLoader={
        <>
          <DemandQueueTaskCompletedCardSkeleton />
          <DemandQueueTaskCompletedCardSkeleton />
          <DemandQueueTaskCompletedCardSkeleton />
          <DemandQueueTaskCompletedCardSkeleton />
        </>
      }
    >
      <StackPanel className={className} gap="xl" direction="column">
        {ids.map((id) => (
          <BankGuaranteeDetailsTasksHistoryListItem queueTaskId={id as QueueTaskId} key={id} />
        ))}
        <div ref={bottomAnchorRef} />
      </StackPanel>
    </AppInfinityScroll>
  );
};
