import { checkNeverType } from "@sideg/helpers";
import { BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus } from "../../../../../complicated-tasks/bill/types/BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { Badge } from "../../../../../../../common/ui-kit/containers/badge";
import { ClockIcon, Icon, LikeIcon } from "../../../../../../../common/ui-kit/icons";
import { BankGuaranteeDetailsDemandReconciliationContent } from "../../content/BankGuaranteeDetailsDemandReconciliationContent";
import { BadgeVariant } from "../../../../../../../common/ui-kit/containers/badge/types/BadgeVariant";

type StatusEnum =
  | BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.Confirmed
  | BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.ManagerConfirmation;

const getIcon = (status: StatusEnum): typeof LikeIcon => {
  switch (status) {
    case BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.Confirmed:
      return LikeIcon;
    case BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.ManagerConfirmation:
      return ClockIcon;
    default:
      return checkNeverType(status);
  }
};

const getVariant = (status: StatusEnum): BadgeVariant => {
  switch (status) {
    case BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.Confirmed:
      return "success";
    case BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.ManagerConfirmation:
      return "info";
    default:
      return checkNeverType(status);
  }
};

const getTitle = (status: StatusEnum): string => {
  switch (status) {
    case BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.Confirmed:
      return "Счет оплачен";
    case BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.ManagerConfirmation:
      return "Проверяем факт оплаты";
    default:
      return checkNeverType(status);
  }
};

export interface BankGuaranteeDetailsDemandReconciliationConfirmPaymentStatusBadgeProps extends BaseCssProps {
  status: StatusEnum;
}

export const BankGuaranteeDetailsDemandReconciliationConfirmPaymentStatusBadge = ({
  status,
}: BankGuaranteeDetailsDemandReconciliationConfirmPaymentStatusBadgeProps) => {
  return (
    <Badge variant={getVariant(status)} borderRadius="14px" py="none" px="sm">
      <Icon as={getIcon(status)} width={17} height={17} />
      <BankGuaranteeDetailsDemandReconciliationContent.Typography fontColor="typography.primary">
        {getTitle(status)}
      </BankGuaranteeDetailsDemandReconciliationContent.Typography>
    </Badge>
  );
};
