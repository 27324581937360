import { ChatDialogHeader } from "./header/ChatDialogHeader";
import { ChatDialogMain } from "./main/ChatDialogMain";
import { ChatDialogFooter } from "./footer/ChatDialogFooter";
import { useHubState } from "../../../common/hubs";
import { chatDialogHubConnection } from "../api/chatDialogHubConnection";
import { useAppDispatch } from "../../../common/store";
import { sendMessageChatDialog } from "../store/chatDialog.thunks";
import { Box } from "../../../common/ui-kit/containers/box";

export interface ChatDialogProps {
  onCloseClick: () => void | Promise<void>;
}

export const ChatDialog = ({ onCloseClick }: ChatDialogProps) => {
  const dispatch = useAppDispatch();
  const { isConnected } = useHubState(chatDialogHubConnection);

  const onSubmit = (text: string) => {
    dispatch(sendMessageChatDialog(text));
  };

  if (!isConnected) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <ChatDialogHeader onCloseClick={onCloseClick} />
      <ChatDialogMain />
      <ChatDialogFooter onSubmit={onSubmit} />
    </Box>
  );
};
