import { ThemeColor } from "@sideg/ui-kit/core/theme/types/colors/ThemeColor";
import palette from "@sideg/ui-kit/core/theme/__generated__/palette.gen";
import { ExtractTypeFromConst } from "@sideg/ui-kit/core/theme/types/utils/ExtractTypeFromConst";
import { ExtractPaths } from "@sideg/ui-kit/core/theme/types/utils/ExtractPaths";

export type ThemePaletteValue = ThemeColor | { [key: string]: ThemePaletteValue };

export const generatedPalette = palette satisfies ThemePaletteValue;

export type ThemePalette = ExtractTypeFromConst<typeof generatedPalette, ThemeColor>;
export type ThemePaletteColors = ExtractPaths<ThemePalette, ThemeColor>;
