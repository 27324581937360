import { CreditExecutionId } from "../../../../../../../common/types/demand";
import { isLoading } from "../../../../../../../common/types/state";
import { CreditExecutionBriefDescriptionList } from "../../../../../../common/brief/components/CreditExecutionBriefDescriptionList";
import { useCreditExecutionBriefData } from "../../../../../../common/brief/hooks/useCreditExecutionBriefData";

export interface CreditExecutionListItemDesktopBriefProps {
  creditExecutionId: CreditExecutionId;
}

export const CreditExecutionListItemDesktopBrief = ({
  creditExecutionId,
}: CreditExecutionListItemDesktopBriefProps) => {
  const { status, data } = useCreditExecutionBriefData(creditExecutionId);

  return <CreditExecutionBriefDescriptionList isLoading={isLoading(status)} data={data} />;
};
