import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { useNavigationStateFromWithFallback } from "../../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../../common/urls/internal/utils";
import { selectCreditExecutionCreation } from "../../store/creditExecutionCreation.selectors";
import { ProductCreationStages, ProductCreationPageHeader } from "../../../../common/product-creation";
import { changeStageCreditExecutionCreation } from "../../store/creditExecutionCreation.slice";

export const CreditExecutionCreationPageHeader = () => {
  const dispatch = useAppDispatch();
  const isDesktop = useThemeIsDesktop();

  const { activeStage } = useAppSelector(selectCreditExecutionCreation);

  const onPrevStageClick = (stage: ProductCreationStages) => {
    dispatch(changeStageCreditExecutionCreation(stage));
  };

  const { from } = useNavigationStateFromWithFallback(
    selectAppUrl((x) => x.creditExecution.list.root).url,
    "Мои заявки",
  );

  return (
    <ProductCreationPageHeader
      activeStage={activeStage}
      onPrevStageClick={onPrevStageClick}
      from={from}
      title={isDesktop ? "Новая заявка на кредит на исполнение контракта" : "Новая заявка на КИК"}
    />
  );
};
