import { useMemo, ComponentProps } from "react";
import { AppLink } from "../../../common/urls/internal/components";
import { CreditExecutionId, DemandId } from "../../../common/types/demand";
import { useCreditExecutionListItemTransferAction } from "./useCreditExecutionListItemTransferAction";

export const useCreditExecutionListItemLink = (
  creditExecutionId: CreditExecutionId,
  demandId?: DemandId
): ComponentProps<typeof AppLink> => {
  const onClick = useCreditExecutionListItemTransferAction(creditExecutionId);

  return useMemo(
    () => ({
      to: (x) =>
        demandId === undefined ? x.creditExecution.details.creditExecution : x.creditExecution.details.demand,
      params: { creditExecutionId, demandId },
      onClick,
    }),
    [creditExecutionId, demandId, onClick]
  );
};
