import { AnyAction, combineReducers } from "redux";
import agentReportReducer from "../../../store/reducers/agentReportReducer";
import clientFormReducer from "../../../store/reducers/clientFormReducer";
import urLiReducer from "../../../store/reducers/urLiReducer";
import fiLiReducer from "../../../store/reducers/fiLiReducer";
import lotReducer from "../../../store/reducers/lotReducer";
import balanceReducer from "../../../store/reducers/balanceReducer";
import { queueTasksCountersReducer } from "../../queue-tasks/counters";
import { userDetailsReducer } from "../../user/details";
import { newsWidgetReducer } from "../widgets/news";
import { queueTasksListReducer, queueTasksListSuggestionsReducer } from "../../queue-tasks/list";
import { queueTasksNotificationsReducer } from "../../queue-tasks/notifications";
import { agencyDetailsReducer } from "../../agency/details";
import { scoringReducer } from "../../../store/reducers/scoringReducer";
import { creditExecutionConditionsReducer } from "../../credit-execution/conditions";
import { bankGuaranteeConditionsReducer } from "../../bank-guarantee/conditions";
import { chatDialogReducer } from "../../chat/dialog";
import { hubsReducer } from "../hubs";
import { bankGuaranteeCreationReducer } from "../../bank-guarantee/creation";
import { bankGuaranteeDetailsReducer } from "../../bank-guarantee/details";
import { bankGuaranteeListReducer } from "../../bank-guarantee/list";
import { bankGuaranteeBriefReducer } from "../../bank-guarantee/brief";
import { globalToastReducer } from "../../global/toasts";
import { newsListReducer } from "../../news/list";
import { newsDetailsReducer } from "../../news/details";
import { bankGuaranteeDocumentsReducer } from "../../bank-guarantee/documents";
import { bankGuaranteeRewardsReducer } from "../../bank-guarantee/rewards";
import { creditWorkingAssetsListReducer } from "../../credit-working-assets/list";
import { creditWorkingAssetsCreationReducer } from "../../credit-working-assets/creation";
import { productRejectionReducer } from "../product-rejection";
import { creditWorkingAssetsDetailsReducer } from "../../credit-working-assets/details";
import { creditExecutionCreationReducer } from "../../credit-execution/creation";
import { creditExecutionListReducer } from "../../credit-execution/list";
import { publicUserOffersReducer } from "../../public-user/offers";
import { publicUserRegistrationAgentReducer } from "../../public-user/registration";
import { authorizedUserReducer, authorizedUserRemoved } from "../authorized-user";
import { agencyDemandReducer } from "../../agency/demand";
import { creditExecutionDetailsReducer } from "../../credit-execution/details";
import { publicUserPrivacyPoliciesReducer } from "../../public-user/privacy-policies";
import { legalServicesCreationReducer } from "../../legal-services/creation";

const reducer = combineReducers({
  agentReportReducer,
  clientForm: clientFormReducer,
  urLi: urLiReducer,
  fiLi: fiLiReducer,
  lot: lotReducer,
  balance: balanceReducer,
  scoring: scoringReducer,
  domains: combineReducers({
    global: combineReducers({
      toasts: globalToastReducer,
    }),
    hubs: hubsReducer,
    bankGuarantee: combineReducers({
      details: bankGuaranteeDetailsReducer,
      creation: bankGuaranteeCreationReducer,
      list: bankGuaranteeListReducer,
      brief: bankGuaranteeBriefReducer,
      documents: bankGuaranteeDocumentsReducer,
      conditions: bankGuaranteeConditionsReducer,
      rewards: bankGuaranteeRewardsReducer,
    }),
    user: combineReducers({
      authorizedUser: authorizedUserReducer,
      details: userDetailsReducer,
    }),
    public: combineReducers({
      offers: publicUserOffersReducer,
      privacyPolicies: publicUserPrivacyPoliciesReducer,
      registration: combineReducers({
        agent: publicUserRegistrationAgentReducer,
      }),
    }),
    agency: combineReducers({
      details: agencyDetailsReducer,
      demand: agencyDemandReducer,
    }),
    queueTasks: combineReducers({
      counters: queueTasksCountersReducer,
      list: combineReducers({
        items: queueTasksListReducer,
        suggestions: queueTasksListSuggestionsReducer,
      }),
      notifications: queueTasksNotificationsReducer,
    }),
    creditExecution: combineReducers({
      list: creditExecutionListReducer,
      conditions: creditExecutionConditionsReducer,
      creation: creditExecutionCreationReducer,
      details: creditExecutionDetailsReducer,
    }),
    creditWorkingAssets: combineReducers({
      list: creditWorkingAssetsListReducer,
      creation: creditWorkingAssetsCreationReducer,
      details: creditWorkingAssetsDetailsReducer,
    }),
    news: combineReducers({
      list: newsListReducer,
      details: newsDetailsReducer,
      widget: newsWidgetReducer,
    }),
    chat: combineReducers({
      dialog: chatDialogReducer,
    }),
    product: combineReducers({
      rejection: productRejectionReducer,
    }),
    legalServices: combineReducers({
      creation: legalServicesCreationReducer,
    }),
  }),
});

export type RootState = ReturnType<typeof reducer>;

// обертка над reducer, чтобы очищать состояние после logout пользователя
export const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === authorizedUserRemoved.toString()) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
