import { useMemo, useState } from "react";

export const useListTrimMoreThan = <TItem>(demands: TItem[], trimMoreThan: number) => {
  const [isShown, setIsShown] = useState(false);

  return useMemo(() => {
    const needTrim = demands.length > trimMoreThan + 1;
    const trimmedCount = needTrim && !isShown ? demands.length - trimMoreThan : 0;
    const items = needTrim && !isShown ? demands.slice(0, trimMoreThan) : demands;

    return {
      needTrim,
      items,
      trimmedCount,
      isShown,
      setIsShown,
      toggleIsShown: () => setIsShown((prev) => !prev),
    };
  }, [demands, trimMoreThan, isShown]);
};
