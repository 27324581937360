import { useEffect } from "react";
import { usePageTitle } from "../../../common/navigation/page-title";
import { useGetQueryParams } from "../../../common/filters";
import { bankGuaranteeDocumentsValidationSchema } from "../validation/bankGuaranteeDocumentsValidationSchema";
import { BankGuaranteeDocumentsFilters } from "../components/BankGuaranteeDocumentsFilters";
import { useAppDispatch } from "../../../common/store";
import { getBanksBankGuaranteeDocuments } from "../store/bankGuaranteeDocuments.thunks";
import { useBankGuaranteeDocumentsFetch } from "../hooks/useBankGuaranteeDocumentsFetch";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { BankGuaranteeDocumentsList } from "../components/BankGuaranteeDocumentsList";
import { removeDocumentsBankGuaranteeDocuments } from "../store/bankGuaranteeDocuments.slice";

export const BankGuaranteeDocumentsPage = () => {
  const dispatch = useAppDispatch();
  const [filteringObject] = useGetQueryParams(bankGuaranteeDocumentsValidationSchema);

  usePageTitle("Необходимые документы");

  useEffect(() => {
    dispatch(getBanksBankGuaranteeDocuments());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(removeDocumentsBankGuaranteeDocuments());
    };
  }, [dispatch]);

  useBankGuaranteeDocumentsFetch(filteringObject);

  return (
    <AppLayoutResponsiveListPage>
      <BankGuaranteeDocumentsFilters filteringObject={filteringObject} />
      <BankGuaranteeDocumentsList />
    </AppLayoutResponsiveListPage>
  );
};
