import { mixed, number, object, string } from "yup";
import { demandQueueTaskActiveValidationSchema } from "../../../../../demand/queue-tasks";
import { getFormattedNumberTransformer } from "../../../../../common/validation";
import { BankGuaranteeDetailsComplicatedTaskBillDecisionType } from "../types/BankGuaranteeDetailsComplicatedTaskBillDecisionType";
import { bankGuaranteeDetailsComplicatedTaskBillFormAmountFieldsRulesRules } from "./rules/bankGuaranteeDetailsComplicatedTaskBillFormAmountFieldsRules.rules";
import { bankGuaranteeDetailsComplicatedTaskBillFormDocumentFieldsRules } from "./rules/bankGuaranteeDetailsComplicatedTaskBillFormDocumentFields.rules";
import { bankGuaranteeDetailsComplicatedTaskBillFormCommentFieldsRules } from "./rules/bankGuaranteeDetailsComplicatedTaskBillFormCommentFields.rules";

export const DEFAULT_DECISION = "";

export const bankGuaranteeDetailsComplicatedTaskBillAmountValidationSchema = object({
  sum: number().when("decision", (decision: BankGuaranteeDetailsComplicatedTaskBillDecisionType, schema) => {
    return bankGuaranteeDetailsComplicatedTaskBillFormAmountFieldsRulesRules.isExist(decision)
      ? schema
          .transform(getFormattedNumberTransformer())
          .typeError("Укажите корректное значение")
          .min(0, "Сумма должна быть больше нуля")
          .required("Укажите итоговую стоимость")
      : schema.strip();
  }),
  percent: number().when("decision", (decision: BankGuaranteeDetailsComplicatedTaskBillDecisionType, schema) => {
    return bankGuaranteeDetailsComplicatedTaskBillFormAmountFieldsRulesRules.isExist(decision)
      ? schema
          .transform(getFormattedNumberTransformer())
          .typeError("Укажите корректное значение")
          .required("Укажите итоговую стоимость")
      : schema.strip();
  }),
});

export const bankGuaranteeDetailsComplicatedTaskBillValidationSchema = demandQueueTaskActiveValidationSchema
  .concat(bankGuaranteeDetailsComplicatedTaskBillAmountValidationSchema)
  .shape({
    documents: string().strip(),
    file: mixed().when("decision", (decision: BankGuaranteeDetailsComplicatedTaskBillDecisionType, schema) => {
      return bankGuaranteeDetailsComplicatedTaskBillFormDocumentFieldsRules.isExist(decision)
        ? schema.nullable(true).notRequired()
        : schema.strip();
    }),
    comment: string().when(
      "decision",
      (decision: BankGuaranteeDetailsComplicatedTaskBillDecisionType, commentSchema) => {
        return bankGuaranteeDetailsComplicatedTaskBillFormCommentFieldsRules.isExist(decision)
          ? commentSchema.trim("Комментарий не может быть пустым")
          : commentSchema.strip();
      },
    ),
    decision: string().default(DEFAULT_DECISION).required("Выберите короткий ответ или действие"),
  });
