import { createListenerMiddleware } from "@reduxjs/toolkit";
import { AppStartListening, AppStopListening } from "../types";

const middleware = createListenerMiddleware();

export const { middleware: listenerMiddleware, clearListeners } = middleware;

export const startAppListening = middleware.startListening as AppStartListening;

export const stopAppListening = middleware.stopListening as AppStopListening;
