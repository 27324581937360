import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { useResponsiveContext } from "@sideg/ui-kit/core/responsive";
import {
  FormSelectDesktop,
  FormSelectDesktopProps,
  FormSelectMobile,
  FormSelectMobileProps,
} from "@sideg/ui-kit/atoms/form-controls/select";

export type FormSelectResponsiveProps<TItem extends string> = Omit<FormSelectDesktopProps<TItem>, "ref"> &
  Omit<FormSelectMobileProps, "onChange">;

export const FormSelectResponsive = <TItem extends string>({
  getItemTitle,
  selectedItem,
  id,
  error,
  name,
  className,
  items,
  label,
  onChange,
  onBlur,
  isItemDisabled,
  disabled,
  selectSize,
  ...rest
}: FormSelectResponsiveProps<TItem>) => {
  const isDesktop = useThemeIsDesktop();
  const { isProbablyMobile } = useResponsiveContext();
  const isNeedMobile = isProbablyMobile || !isDesktop;

  return isNeedMobile ? (
    <FormSelectMobile
      error={error}
      label={label}
      className={className}
      id={id}
      name={name}
      value={selectedItem}
      onChange={(e) => {
        onChange(e.target.value as TItem);
      }}
      onBlur={onBlur}
      disabled={disabled}
      {...rest}
    >
      {items.map((x) => (
        <option value={x} key={x} disabled={isItemDisabled?.(x)}>
          {getItemTitle(x)}
        </option>
      ))}
    </FormSelectMobile>
  ) : (
    <FormSelectDesktop
      selectSize={selectSize}
      items={items}
      selectedItem={selectedItem}
      getItemTitle={getItemTitle}
      onChange={onChange}
      label={label}
      className={className}
      name={name}
      id={id}
      error={error}
      onBlur={onBlur}
      isItemDisabled={isItemDisabled}
      disabled={disabled}
      {...rest}
    />
  );
};
