import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.md};
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 1.5;
  font-weight: 500;

  & > *:first-of-type {
    flex-shrink: 0;
  }

  & > *:last-of-type {
    flex-shrink: 1;
  }
`;
