import { clientV4 } from "../../../common/api";
import { GetProductsLegalServicesCreationOutputDto } from "./dto/output/GetProductsLegalServicesCreationOutputDto";
import { ApiObject, EmptyOutputDto } from "../../../common/api/types";
import { SendLegalServicesCreationInputDto } from "./dto/input/SendLegalServicesCreationInputDto";

const CONTROLLER_NAME = "LegalServices";

export const legalServicesCreationApi = {
  getProducts: (_model: undefined) =>
    clientV4.get<undefined, GetProductsLegalServicesCreationOutputDto>(`${CONTROLLER_NAME}/list-all`),
  send: (model: SendLegalServicesCreationInputDto) =>
    clientV4.postForm<SendLegalServicesCreationInputDto, EmptyOutputDto>(`${CONTROLLER_NAME}/send`, model),
} satisfies ApiObject;
