import { joinUrlParts } from "../../../common/urls/internal/utils";
import { AppUrlTypeEnum } from "../../../common/urls/internal/types";
import { PUBLIC_USER_PUBLIC_PAGE_URL_ROOT_PATH } from "../../common/urls";
import { makePublicOrCommonUrl } from "../../../common/urls/internal/utils/makeAppUrl";

export const publicUserOffersUrls = {
  root: makePublicOrCommonUrl(
    joinUrlParts(PUBLIC_USER_PUBLIC_PAGE_URL_ROOT_PATH, "offers"),
    "/public/offers",
    AppUrlTypeEnum.Common,
  ),
};
