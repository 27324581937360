import { AnyAction } from "redux";
import { isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";

export enum LoadingStatusEnum {
  Idle = "idle",
  Loading = "loading",
  Failed = "failed",
  Aborted = "aborted",
  Succeeded = "succeeded",
}

export const getLoadingStatusFromAction = (action: AnyAction): LoadingStatusEnum => {
  if (isFulfilled(action)) {
    return LoadingStatusEnum.Succeeded;
  }
  if (isRejected(action)) {
    return action.meta.aborted ? LoadingStatusEnum.Aborted : LoadingStatusEnum.Failed;
  }
  if (isPending(action)) {
    return LoadingStatusEnum.Loading;
  }

  return LoadingStatusEnum.Idle;
};

const isStatusEquals = (referenceStatus: LoadingStatusEnum) => (status: LoadingStatusEnum | undefined) =>
  status === referenceStatus;

export const isIdle = isStatusEquals(LoadingStatusEnum.Idle);
export const isLoading = isStatusEquals(LoadingStatusEnum.Loading);
export const isFailed = isStatusEquals(LoadingStatusEnum.Failed);
export const isAborted = isStatusEquals(LoadingStatusEnum.Aborted);
export const isFailedOrAborted = (status: LoadingStatusEnum | undefined) => isFailed(status) || isAborted(status);
export const isSucceeded = isStatusEquals(LoadingStatusEnum.Succeeded);
