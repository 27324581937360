import { useCallback } from "react";
import { FileTemp, FileTempId, generateFileTempId } from "../../../../files/temp";
import { useEffectOnce } from "../../../../common/hooks";
import { LegalServicesCreationProductDocument } from "../../types/LegalServicesCreationProductDocument";
import {
  LegalServicesCreationDocumentFormValue,
  LegalServicesCreationFormValues,
} from "../../types/LegalServicesCreationFormValues";
import { useTypedField } from "../../../../common/form";

const key: keyof LegalServicesCreationFormValues = "documents";

export const useLegalServiceCreationFormDocumentField = (document: LegalServicesCreationProductDocument) => {
  const [{ value, error }, { setValue, setTouched }] = useTypedField<
    LegalServicesCreationDocumentFormValue | undefined
  >(`${key}.${document.docType}`);

  const getValue = useCallback(
    (files: FileTemp[]): LegalServicesCreationDocumentFormValue => ({
      docType: document.docType,
      minLength: document.isRequired ? 1 : 0,
      files,
    }),
    [document],
  );

  useEffectOnce(() => {
    if (!value) {
      setValue(getValue([]), false);
    }
  });

  const handleDrop = useCallback(
    async (droppedFiles: File[]) => {
      if (droppedFiles.length === 0) {
        return;
      }

      const attachedFiles = [
        ...(value?.files ?? []),
        ...droppedFiles.map((x): FileTemp => ({ id: generateFileTempId(), dateAdded: new Date(), file: x })),
      ];

      // Важен порядок. В противном случае будет некорректная ошибка валидации
      await setTouched(true);
      await setValue(getValue(attachedFiles), true);
    },
    [getValue, setTouched, setValue, value?.files],
  );

  const onFileDelete = useCallback(
    async (id: FileTempId) => {
      const files = (value?.files ?? []).filter((x) => x.id !== id);
      await setValue(getValue(files));
    },
    [getValue, setValue, value?.files],
  );

  return {
    files: value?.files ?? ([] as FileTemp[]),
    handleDrop,
    error: (error as unknown as Record<"files", string>)?.files,
    onFileDelete,
  };
};
