import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { BankGuaranteeCreationScoringCreateByGuarantee } from "../components/scoring/create-by-guarantee/BankGuaranteeCreationScoringCreateByGuarantee";
import { BankGuaranteeCreationBriefBlock } from "../components/brief/BankGuaranteeCreationBriefBlock";
import { useBankGuaranteeCreationCreateByGuaranteeContext } from "../hooks/creation/create-by-guarantee/useBankGuaranteeCreationCreateByGuaranteeContext";
import { ProductCreationCreateByExistsBriefContainer } from "../../../common/product-creation";

export const BankGuaranteeCreationCreateByGuaranteePage = () => {
  const guaranteeId = useBankGuaranteeCreationCreateByGuaranteeContext();
  const isDesktop = useThemeIsDesktop();

  return (
    <>
      <BankGuaranteeCreationScoringCreateByGuarantee />
      {isDesktop && (
        <ProductCreationCreateByExistsBriefContainer>
          <BankGuaranteeCreationBriefBlock guaranteeId={guaranteeId} />
        </ProductCreationCreateByExistsBriefContainer>
      )}
    </>
  );
};
