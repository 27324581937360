import styled from "@emotion/styled";

export const ProductListCardDemandListItemStatusLinkCounterDesktop = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.palette.fileLabel.default};
  border-radius: 4px;
  padding: 0 ${({ theme }) => theme.spaces.sm};
  flex-grow: 0;
  flex-shrink: 0;
  text-decoration: none;
`;
