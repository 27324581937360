import styled from "@emotion/styled";
import { ResponsiveStyleValue } from "@sideg/ui-kit/common/types";
import isPropValid from "@emotion/is-prop-valid";
import { getContainerCommonPropsStyleBuilders } from "@sideg/ui-kit/atoms/containers/utils/getContainerCommonPropsStyleBuilders";
import { FlexPropsWithoutPrefix, FlexRestProps } from "@sideg/ui-kit/atoms/containers/types/FlexboxProps";
import { SpacesProps } from "@sideg/ui-kit/atoms/containers/types/SpacesProps";
import { ContainerCommonProps } from "@sideg/ui-kit/atoms/containers/types/ContainerCommonProps";
import { getSpacesPropsStyleBuilders } from "@sideg/ui-kit/atoms/containers/utils/getSpacesPropsStyleBuilders";
import { getFlexboxPropsStyleBuilders } from "@sideg/ui-kit/atoms/containers/utils/getFlexboxPropsStyleBuilders";
import { withDefaultProps } from "@sideg/ui-kit/core/utils";

export interface FlexProps
  extends FlexPropsWithoutPrefix,
    FlexRestProps,
    SpacesProps,
    Omit<ContainerCommonProps, "display"> {
  display?: ResponsiveStyleValue<"flex" | "inline-flex">;
}

export const Flex = withDefaultProps(
  styled("div", {
    shouldForwardProp: isPropValid,
  })<FlexProps>(
    ...getContainerCommonPropsStyleBuilders(),
    ...getSpacesPropsStyleBuilders(),
    getFlexboxPropsStyleBuilders("simplified"),
  ),
  { display: "flex" },
);
