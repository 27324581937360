import styled from "@emotion/styled";

export const RequestedFiles = styled.div`
  padding: ${({ theme }) => theme.spaces.md} ${({ theme }) => theme.spaces.md} 0 ${({ theme }) => theme.spaces.md};
  width: 100%;

  & > * {
    &::after {
      display: block;
      height: 1px;
      content: "";
      width: 100%;
      background-color: ${({ theme }) => theme.palette.control.secondaryMedium};
      margin: ${({ theme }) => theme.spaces.md} 0 ${({ theme }) => theme.spaces.sm} 0;
    }
  }

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    width: 90%;

    & > * + * {
      margin-top: ${({ theme }) => theme.spaces.xxl};
    }

    & > * {
      &::after {
        content: unset;
      }
    }
  }
`;
