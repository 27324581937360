import { RootState } from "../../../../common/store";
import { BankGuaranteeDetailsActiveDemandState } from "./bankGuaranteeDetailsActiveDemand.types";
import { bankGuaranteeDetailsActiveDemandDocumentsAdapter } from "./bankGuaranteeDetailsActiveDemandDocuments.adapter";
import { BankGuaranteeDetailsDemandActualBill } from "../../types/demand/reconciliation/BankGuaranteeDetailsDemandActualBill";
import { BankGuaranteeDetailsDemandActualProject } from "../../types/demand/reconciliation/BankGuaranteeDetailsDemandActualProject";

export const selectBankGuaranteeDetailsActiveDemand = (state: RootState): BankGuaranteeDetailsActiveDemandState =>
  state.domains.bankGuarantee.details.activeDemand;

export const { selectAll: selectBankGuaranteeDetailsActiveDemandDocuments } =
  bankGuaranteeDetailsActiveDemandDocumentsAdapter.getSelectors(
    (state: RootState) => selectBankGuaranteeDetailsActiveDemand(state).documents,
  );

export const selectBankGuaranteeDetailsActiveDemandActualBill = (
  state: RootState,
): BankGuaranteeDetailsDemandActualBill | undefined => selectBankGuaranteeDetailsActiveDemand(state).actualBill;

export const selectBankGuaranteeDetailsActiveDemandActualProject = (
  state: RootState,
): BankGuaranteeDetailsDemandActualProject | undefined => selectBankGuaranteeDetailsActiveDemand(state).actualProject;
