import styled from "@emotion/styled";

export const Badge = styled.div`
  background-color: ${({ theme }) => theme.palette.fileLabel.default};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.sm};
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  padding: 2px ${({ theme }) => theme.spaces.sm};
  border-radius: 4px;
`;
