import TabsItem from "../../components/controls-old/tabs/TabsModel";
import TabType from "../../models/TabType";
import DemandCounterModel from "../../models/DemandCounterModel";

export const tabsModel = (counter?: DemandCounterModel): TabsItem[] => [
    {
        title: 'Активные',
        tab: TabType.Active,
        isActive: true,
        counter: counter?.countActive ?? 0
    },
    {
        title: 'Завершенные',
        tab: TabType.Canceled,
        isActive: false,
        counter: counter?.countCompleted ?? 0
    },
    {
        title: 'Все',
        tab: TabType.All,
        isActive: false
    }
]