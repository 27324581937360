import styled from "@emotion/styled";
import { QueueTaskId } from "../../../../common/types/demand";
import { CodeWithTitle } from "../../../../common/types/objects";
import { ButtonOutline } from "../../../../common/ui-kit/buttons";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectQueueTasksNotificationById } from "../../store/queueTasksNotifications.selectors";
import { isLoading } from "../../../../common/types/state";
import { completeQueueTask } from "../../../common/store";
import { DotsLoader } from "../../../../common/ui-kit/loaders/DotsLoader";

const StyledOutlineButton = styled(ButtonOutline)`
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
`;

export interface QueueTasksNotificationActionButtonProps {
  queueTaskId: QueueTaskId;
  action: CodeWithTitle;
}

export const QueueTasksNotificationActionButton = ({
  queueTaskId,
  action: { code, title },
}: QueueTasksNotificationActionButtonProps) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { action } = useAppSelector((state) => selectQueueTasksNotificationById(state, queueTaskId))!;
  const isActionLoading = isLoading(action?.status);

  const onClick = () => {
    dispatch(completeQueueTask({ queueTaskId, decisionCode: code }));
  };

  return (
    <StyledOutlineButton onClick={onClick} type="button" disabled={isActionLoading}>
      {title}
      {isActionLoading && <DotsLoader />}
    </StyledOutlineButton>
  );
};
