import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { CreditWorkingAssetsId } from "../../../../../common/types/demand";
import { NavigationState } from "../../../../../common/navigation/navigation-state";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  ProductListCardFooterDemandLinkMobile,
  ProductListCardMoreActionsButton,
  ProductListItemBadgeMobile,
} from "../../../../../common/ui/product/list/card";
import { useAppSelector } from "../../../../../common/store";
import {
  selectCreditWorkingAssetsListItemsById,
  selectCreditWorkingAssetsListItemsClientById,
} from "../../../store/items/creditWorkingAssetsListItems.selectors";
import { CreditWorkingAssetsListItemStageCounters } from "../counters/CreditWorkingAssetsListItemStageCounters";
import { useCreditWorkingAssetsListItemLink } from "../../../hooks/useCreditWorkingAssetsListItemLink";
import { CreditWorkingAssetsListItemCardMobile } from "./card/CreditWorkingAssetsListItemCard.mobile";
import { CreditWorkingAssetsListItemDetails } from "../details/CreditWorkingAssetsListItemDetails";
import { useCreditWorkingAssetsListItemMoreActions } from "../../../hooks/useCreditWorkingAssetsListItemMoreActions";
import { declensionHelper } from "../../../../../common/helpers/entity";

export interface CreditWorkingAssetsListItemMobileProps extends BaseCssProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
  from: NavigationState;
}

export const CreditWorkingAssetsListItemMobile = ({
  className,
  from,
  creditWorkingAssetsId,
}: CreditWorkingAssetsListItemMobileProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const item = useAppSelector((state) => selectCreditWorkingAssetsListItemsById(state, creditWorkingAssetsId))!;
  const client = useAppSelector((state) => selectCreditWorkingAssetsListItemsClientById(state, item.clientId));

  const linkProps = useCreditWorkingAssetsListItemLink(creditWorkingAssetsId);
  const actions = useCreditWorkingAssetsListItemMoreActions(item, from);

  return (
    <CreditWorkingAssetsListItemCardMobile
      footer={
        <ProductListCardFooterDemandLinkMobile {...linkProps} state={from}>
          Перейти к заявке
        </ProductListCardFooterDemandLinkMobile>
      }
      banksLabel={declensionHelper.commonDeclensions.bank.nominative(item.demandIds.length, true)}
      counters={<CreditWorkingAssetsListItemStageCounters counters={item.counters} />}
      className={className}
      numberAndDate={`№ ${item.groupProductName} • ${dateTimeHelper.format(
        new Date(item.dateAdded),
        specialFormatters.dayShortMonthYearIfDifferent,
      )}`}
      statusBadge={
        <>
          {!item.isActive && (
            <ProductListItemBadgeMobile backgroundColor="status.success">Завершена</ProductListItemBadgeMobile>
          )}
          {item.isActive && item.activeTasksCount > 0 && (
            <ProductListItemBadgeMobile backgroundColor="primary">
              {declensionHelper.commonDeclensions.tasks.nominative(item.activeTasksCount, true)}
            </ProductListItemBadgeMobile>
          )}
        </>
      }
      actionButton={actions.length > 0 && <ProductListCardMoreActionsButton availableActions={actions} />}
      clientName={client?.name}
      details={<CreditWorkingAssetsListItemDetails item={item} />}
    />
  );
};
