import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsReissuesIds } from "../../../store/reissues/BankGuaranteeDetailsReissues.selectors";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeDetailsDemandReissue } from "./BankGuaranteeDetailsDemandReissue";

export type BankGuaranteeDetailsDemandReissuesProps = BaseCssProps;

export const BankGuaranteeDetailsDemandReissues = ({ className }: BankGuaranteeDetailsDemandReissuesProps) => {
  const reissueIds = useAppSelector(selectBankGuaranteeDetailsReissuesIds);

  return (
    <StackPanel direction="column" gap="xxl" className={className}>
      {reissueIds.map((x) => (
        <BankGuaranteeDetailsDemandReissue id={x as number} key={x} />
      ))}
    </StackPanel>
  );
};
