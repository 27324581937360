import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { useNavigationStateFromWithFallback } from "../../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../../common/urls/internal/utils";
import { UserProfilePageHeaderMobile } from "./UserProfilePageHeaderMobile.mobile";
import { UserProfilePageHeaderDesktop } from "./UserProfilePageHeaderDesktop.desktop";

export const UserProfilePageHeader = () => {
  const { from } = useNavigationStateFromWithFallback(selectAppUrl((x) => x.home).url, "Назад");

  return (
    <ThemeMobileDesktopView
      mobile={<UserProfilePageHeaderMobile from={from} />}
      desktop={<UserProfilePageHeaderDesktop from={from} />}
    />
  );
};
