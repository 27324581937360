import { DemandQueueTaskCard } from "../../../../../../demand/queue-tasks";
import { DemandQueueTaskCardContainer } from "../../../../../../demand/queue-tasks/components/card/DemandQueueTaskCardContainer";
import {
  BankGuaranteeDetailsDemandNewCommentCardFormWithFilters,
  BankGuaranteeDetailsDemandNewCommentCardFormWithFiltersProps,
} from "./BankGuaranteeDetailsDemandNewCommentCardFormWithFilters";
import { useBankGuaranteeDetailsFilterScrollIntoContainer } from "../../../../hooks/filters/useBankGuaranteeDetailsFilterScrollIntoContainer";

const Container = DemandQueueTaskCardContainer.withComponent(BankGuaranteeDetailsDemandNewCommentCardFormWithFilters);

export interface BankGuaranteeDetailsDemandNewCommentCardFormProps
  extends BankGuaranteeDetailsDemandNewCommentCardFormWithFiltersProps {}
export const BankGuaranteeDetailsDemandNewCommentCardForm = ({
  className,
  ...formProps
}: BankGuaranteeDetailsDemandNewCommentCardFormProps) => {
  const { containerRef, isFocused } = useBankGuaranteeDetailsFilterScrollIntoContainer("comment");

  return (
    <DemandQueueTaskCard
      title="Новое сообщение"
      className={className}
      variant="active"
      isFocused={isFocused}
      ref={containerRef}
    >
      <Container {...formProps} />
    </DemandQueueTaskCard>
  );
};
