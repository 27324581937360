import * as React from "react";
import { Typography, TypographyProps } from "../../../typography";

export interface CreationBlockCardDescriptionSecondaryProps
  extends Omit<TypographyProps, "fontSize" | "lineHeight" | "fontWeight" | "fontColor"> {
  children?: React.ReactNode;
}

export const CreationBlockCardDescriptionSecondary = (props: CreationBlockCardDescriptionSecondaryProps) => {
  return <Typography fontSize="fz2" lineHeight="1.5" fontWeight={500} fontColor="dark" {...props} />;
};
