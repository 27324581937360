import { FormInput, FormInputProps } from "@sideg/ui-kit/atoms/form-controls/input";
import { forwardRef } from "react";
import * as S from "@sideg/ui-kit/atoms/form-controls/search-input/components/FormSearchInput.styled";
import { useForwardedRef } from "@sideg/ui-kit/helpers/global";

export interface FormSearchInputProps extends Omit<FormInputProps, "type" | "beforeInput" | "afterInput"> {
  handleClean?: () => void;
  onSearch?: () => void;
}

export const FormSearchInput = forwardRef<HTMLInputElement, FormSearchInputProps>(
  ({ disabled, onSearch, value, handleClean, size = "default", ...rest }, ref) => {
    const forwardedRef = useForwardedRef(ref);

    return (
      <FormInput
        value={value}
        type="search"
        size={size}
        beforeInput={
          <S.IconButton
            onKeyDown={(e) => e.key === "Enter" && onSearch?.()}
            onClick={() => {
              forwardedRef?.current?.focus();
              onSearch?.();
            }}
            disabled={disabled}
            type="button"
          >
            <S.SearchInputIcon size={size} />
          </S.IconButton>
        }
        afterInput={
          value && (
            <S.IconButton onClick={handleClean} disabled={disabled} type="button">
              <S.CancelInputIcon size={size} />
            </S.IconButton>
          )
        }
        disabled={disabled}
        ref={forwardedRef}
        {...rest}
      />
    );
  },
);
