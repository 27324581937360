import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { QueueTasksCountersState } from "./queueTasksCounters.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { getQueueTasksCounters, QUEUE_TASK_COUNTERS_SLICE_NAME } from "./queueTasksCounters.thunks";
import { getQueueTasksListCounters } from "../../list/store/queueTasksList.thunks";
import { getQueueTasksNotifications } from "../../notifications/store/queueTasksNotifications.thunks";
import { QueueTasksCounters } from "../types/QueueTasksCounters";

const getEmptyState = (): QueueTasksCounters => ({
  countGuarantees: 0,
  countTenders: 0,
  countCreditExecutions: 0,
  countCreditWorkingAssets: 0,
  countRequestOriginals: 0,
  countAgencyReports: 0,
  countQueueTasksAll: 0,
  countQueueTasksNew: 0,
});

const initialState: QueueTasksCountersState = {
  status: LoadingStatusEnum.Idle,
  data: undefined,
};

const slice = createSlice({
  name: QUEUE_TASK_COUNTERS_SLICE_NAME,
  initialState,
  reducers: {
    queueTasksCountersWaitingReload: (state) => {
      state.status = state.status !== LoadingStatusEnum.Loading ? LoadingStatusEnum.Idle : state.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQueueTasksCounters.fulfilled, (state, action) => {
        state.data = action.payload.body.data;
      })
      .addCase(getQueueTasksListCounters.fulfilled, (state, action) => {
        if (!state.data) {
          state.data = getEmptyState();
        }

        state.data.countQueueTasksNew = action.payload.body.newCounter;
        state.data.countQueueTasksAll = action.payload.body.allCounter;
      })
      .addCase(getQueueTasksNotifications.fulfilled, (state, action) => {
        if (!state.data) {
          state.data = getEmptyState();
        }

        state.data.countQueueTasksNew =
          action.payload.body.paginationInformation?.totalCount ?? state.data.countQueueTasksNew;
      })
      .addMatcher(isAsyncThunkAction(getQueueTasksCounters), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const { queueTasksCountersWaitingReload } = slice.actions;

export const queueTasksCountersReducer = slice.reducer;
