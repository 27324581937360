import { NavigationStateFrom } from "../../../../../common/navigation/navigation-state/types/NavigationStateFrom";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { BackLinkButton } from "../../../../../common/ui/buttons/BackLinkButton";
import {
  FullPageContainerHeaderDesktop,
  FullPageContainerHeaderTitle,
} from "../../../../../common/ui/containers/full-page";

export interface BankGuaranteeCreationCreateByGuaranteeHeaderDesktopProps extends BaseCssProps {
  from: NavigationStateFrom;
}

export const BankGuaranteeCreationCreateByGuaranteeHeaderDesktop = ({
  from,
  className,
}: BankGuaranteeCreationCreateByGuaranteeHeaderDesktopProps) => {
  return (
    <FullPageContainerHeaderDesktop
      leftSide={<BackLinkButton linkTo={from.url} text={from.backTitle} />}
      className={className}
    >
      <FullPageContainerHeaderTitle text="Выбор банков для доотправки заявки" />
    </FullPageContainerHeaderDesktop>
  );
};
