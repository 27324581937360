import { createTabsContext } from "./store/createTabsContext";
import { createUseTabsContext } from "./hooks/createUseTabsContext";
import { createTabComponent } from "./components/createTabComponent";
import { createTabsComponent } from "./components/createTabsComponent";
import { TabCounter } from "./components/TabCounter";

export const createTabs = <TTab extends string>() => {
  const Context = createTabsContext<TTab>();
  const hook = createUseTabsContext(Context);

  return { Container: createTabsComponent(Context.Provider), Tab: createTabComponent(hook), Counter: TabCounter };
};
