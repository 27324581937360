import { QueueTaskId } from "../../../../../common/types/demand";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsTasksHistoryById } from "../../../store/tasks-history/bankGuaranteeDetailsTasksHistory.selectors";
import { useBankGuaranteeDetailsFilterScrollIntoContainer } from "../../../hooks/filters/useBankGuaranteeDetailsFilterScrollIntoContainer";
import { useBankGuaranteeDetailsTaskComponent } from "../../../hooks/active-tasks/useBankGuaranteeDetailsTaskComponent";

export interface BankGuaranteeDetailsTasksHistoryListItemProps {
  queueTaskId: QueueTaskId;
  className?: string;
}

export const BankGuaranteeDetailsTasksHistoryListItem = ({
  queueTaskId,
  className,
}: BankGuaranteeDetailsTasksHistoryListItemProps) => {
  const queueTask = useAppSelector((state) => selectBankGuaranteeDetailsTasksHistoryById(state, queueTaskId));
  const Component = useBankGuaranteeDetailsTaskComponent(queueTask?.taskType, false);
  const { containerRef, isFocused } = useBankGuaranteeDetailsFilterScrollIntoContainer("task", queueTaskId);

  return queueTask && <Component className={className} task={queueTask} ref={containerRef} isFocused={isFocused} />;
};
