import { theme } from "../../../common/ui-kit";
import { Color } from "../../../common/ui-kit/theme";
import { BankGuaranteeRequestOriginalStatus } from "../../common/types";

export const getRequestOriginalLabelColors = (
  type: BankGuaranteeRequestOriginalStatus
): { color: Color; backgroundColor: Color } => {
  switch (type) {
    case "Approved":
      return {
        color: theme.palette.status.success,
        backgroundColor: theme.palette.status.successBackground,
      };
    case "Bank":
    case "Request":
      return {
        color: theme.colors.dark,
        backgroundColor: theme.palette.control.secondaryLight,
      };
    case "Rejected":
      return {
        color: theme.palette.status.error,
        backgroundColor: theme.palette.status.errorBackground,
      };
    case "Rework":
      return {
        color: theme.palette.status.warning,
        backgroundColor: theme.palette.status.warningBackground,
      };
    default:
      return {
        color: theme.colors.dark,
        backgroundColor: theme.palette.control.secondaryLight,
      };
  }
};
