import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../common/store";
import { ProductListItemsContainer } from "../../../../common/ui/product/list/container";
import { selectCreditExecutionListItemsIds } from "../../store/items/creditExecutionListItems.selectors";
import { CreditExecutionListItem } from "./item/CreditExecutionListItem";

export type CreditExecutionListItemsProps = BaseCssProps;

export const CreditExecutionListItems = ({ className }: CreditExecutionListItemsProps) => {
  const ids = useAppSelector(selectCreditExecutionListItemsIds);

  return (
    <ProductListItemsContainer className={className}>
      {ids.map((x) => (
        <CreditExecutionListItem key={x} creditExecutionId={x} />
      ))}
    </ProductListItemsContainer>
  );
};
