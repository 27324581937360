import { forwardRef, ReactNode } from "react";
import { BaseFormElementErrorProps } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormElementError";
import { useElementId } from "@sideg/ui-kit/atoms/form-controls/common/hooks/useElementId";
import { BaseFormInputElement } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormInputElement";
import { BaseCssProps, ControlSize } from "@sideg/ui-kit/common/types";
import { CommonInput } from "@sideg/ui-kit/atoms/form-controls/input/common/components/CommonInput";

export interface FormInputProps
  extends BaseFormElementErrorProps,
    BaseCssProps,
    Omit<JSX.IntrinsicElements["input"], "ref" | "type" | "className" | "size"> {
  label?: string;
  afterInput?: ReactNode;
  beforeInput?: ReactNode;
  size?: ControlSize;
  type: "email" | "number" | "password" | "search" | "tel" | "text" | "url";
  status?: ReactNode;
}

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      label,
      size = "default",
      error,
      errorDataTestId,
      afterInput,
      beforeInput,
      type,
      id,
      name,
      className,
      status,
      // Достаем children из пропсов так как composeStories пропускает его и тесты не работают
      children: _children,
      ...rest
    },
    ref,
  ) => {
    const elementId = useElementId("input", id, name);

    return (
      <BaseFormInputElement
        size={size}
        label={label}
        labelFor={elementId}
        afterInput={afterInput}
        beforeInput={beforeInput}
        error={error}
        errorDataTestId={errorDataTestId}
        disabled={rest.disabled}
        className={className}
        status={status}
      >
        <CommonInput inputSize={size} type={type} ref={ref} id={elementId} name={name} {...rest} />
      </BaseFormInputElement>
    );
  },
);
