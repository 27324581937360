import { CSSObject } from "@emotion/react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ElementColor, FlexboxProps, getElementColor, ResponsiveStyleValue, SpaceType } from "../../../types";

export interface BoxProps extends FlexboxProps {
  display?: ResponsiveStyleValue<CSSObject["display"]>;
  overflow?: ResponsiveStyleValue<CSSObject["overflow"]>;
  padding?: ResponsiveStyleValue<SpaceType>;
  pt?: ResponsiveStyleValue<SpaceType>;
  pr?: ResponsiveStyleValue<SpaceType>;
  pb?: ResponsiveStyleValue<SpaceType>;
  pl?: ResponsiveStyleValue<SpaceType>;
  px?: ResponsiveStyleValue<SpaceType>;
  py?: ResponsiveStyleValue<SpaceType>;
  isFullWidth?: ResponsiveStyleValue<boolean>;
  backgroundColor?: ResponsiveStyleValue<ElementColor>;
  borderRadius?: ResponsiveStyleValue<CSSObject["borderRadius"]>;
}

export const Box = styled("div", {
  shouldForwardProp: (propName: string) => isPropValid(propName) && propName !== "overflow",
})<BoxProps>(
  ({ theme, display }) => makeResponsiveStyles(theme, display, (x) => ({ display: x })),
  ({ theme, flexDirection }) => makeResponsiveStyles(theme, flexDirection, (x) => ({ flexDirection: x })),
  ({ theme, flexGrow }) => makeResponsiveStyles(theme, flexGrow, (x) => ({ flexGrow: x })),
  ({ theme, flexWrap }) => makeResponsiveStyles(theme, flexWrap, (x) => ({ flexWrap: x })),
  ({ theme, flexShrink }) => makeResponsiveStyles(theme, flexShrink, (x) => ({ flexShrink: x })),
  ({ theme, flexBasis }) => makeResponsiveStyles(theme, flexBasis, (x) => ({ flexBasis: x })),
  ({ theme, gap }) => makeResponsiveStyles(theme, gap, (x) => ({ gap: x ? theme.spaces[x] : undefined })),
  ({ theme, alignSelf }) => makeResponsiveStyles(theme, alignSelf, (x) => ({ alignSelf: x })),
  ({ theme, alignItems }) => makeResponsiveStyles(theme, alignItems, (x) => ({ alignItems: x })),
  ({ theme, justifyContent }) => makeResponsiveStyles(theme, justifyContent, (x) => ({ justifyContent: x })),
  ({ theme, overflow }) => makeResponsiveStyles(theme, overflow, (x) => ({ overflow: x })),
  ({ theme, padding }) => makeResponsiveStyles(theme, padding, (x) => ({ padding: x ? theme.spaces[x] : undefined })),
  ({ theme, pt }) => makeResponsiveStyles(theme, pt, (x) => ({ paddingTop: x ? theme.spaces[x] : undefined })),
  ({ theme, pr }) => makeResponsiveStyles(theme, pr, (x) => ({ paddingRight: x ? theme.spaces[x] : undefined })),
  ({ theme, pb }) => makeResponsiveStyles(theme, pb, (x) => ({ paddingBottom: x ? theme.spaces[x] : undefined })),
  ({ theme, pl }) => makeResponsiveStyles(theme, pl, (x) => ({ paddingLeft: x ? theme.spaces[x] : undefined })),
  ({ theme, isFullWidth }) => makeResponsiveStyles(theme, isFullWidth, (x) => (x ? { width: "100%" } : {})),
  ({ theme, px }) =>
    makeResponsiveStyles(theme, px, (x) => ({
      paddingLeft: x ? theme.spaces[x] : undefined,
      paddingRight: x ? theme.spaces[x] : undefined,
    })),
  ({ theme, py }) =>
    makeResponsiveStyles(theme, py, (x) => ({
      paddingTop: x ? theme.spaces[x] : undefined,
      paddingBottom: x ? theme.spaces[x] : undefined,
    })),
  ({ theme, backgroundColor }) =>
    makeResponsiveStyles(theme, backgroundColor, (x, t) => {
      if (x) {
        return {
          backgroundColor: getElementColor(x, t),
        };
      }

      return {};
    }),
  ({ theme, borderRadius }) => makeResponsiveStyles(theme, borderRadius, (x) => ({ borderRadius: x })),
);
