import { BankGuaranteeDetailsTasksHistory } from "../tasks/history/BankGuaranteeDetailsTasksHistory";
import { Box } from "../../../../common/ui-kit/containers/box";
import { BankGuaranteeDetailsDemandNewComment } from "./new-comment/BankGuaranteeDetailsDemandNewComment";
import { BankGuaranteeActiveTasks } from "../tasks/active/BankGuaranteeActiveTasks";
import { BankGuaranteeDetailsDemandEmptyState } from "./BankGuaranteeDetailsDemandEmptyState";
import { useBankGuaranteeDetailsFilterScrollTimeout } from "../../hooks/filters/useBankGuaranteeDetailsFilterScrollTimeout";
import { useBankGuaranteeDetailsFilterScrollIntoContainer } from "../../hooks/filters/useBankGuaranteeDetailsFilterScrollIntoContainer";

export const BankGuaranteeDetailsDemand = () => {
  useBankGuaranteeDetailsFilterScrollTimeout();
  const { containerRef } = useBankGuaranteeDetailsFilterScrollIntoContainer("history", undefined, "start");

  return (
    <Box display="flex" flexDirection="column" gap="lg">
      <BankGuaranteeDetailsDemandNewComment />
      <Box ref={containerRef} display="flex" flexDirection="column" gap="xxl">
        <BankGuaranteeDetailsDemandEmptyState />
        <BankGuaranteeActiveTasks />
        <BankGuaranteeDetailsTasksHistory />
      </Box>
    </Box>
  );
};
