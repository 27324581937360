import styled from "@emotion/styled";
import { CancelIcon } from "../../ui-kit/icons";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: ${({ theme }) => theme.layout.global.pwa.zIndex};
  min-height: 154px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spaces.lg};
  background-color: ${({ theme }) => theme.layout.sidebar.backgroundColor};
`;

export const CloseIcon = styled(CancelIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  cursor: pointer;
`;
