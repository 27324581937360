import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { BANK_GUARANTEE_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const bankGuaranteeCreationUrls = {
  create: makeAppUrl(
    joinUrlParts(BANK_GUARANTEE_ROOT_URL, "create"),
    "/guarantee/create",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
  createByGuarantee: makeAppUrl(
    joinUrlParts(BANK_GUARANTEE_ROOT_URL, "create", ":guaranteeId"),
    "/guarantee/create/:guaranteeId",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
  createByGuaranteeDetails: makeAppUrl(
    joinUrlParts(BANK_GUARANTEE_ROOT_URL, "create", ":guaranteeId", "details"),
    "/guarantee/create/:guaranteeId/details",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
  /**
   * @deprecated использовать create
   */
  createLegacy: makeAppUrl(
    joinUrlParts(BANK_GUARANTEE_ROOT_URL, "create-new-demand"),
    "/guarantee/create-new-demand",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBG),
  ),
};
