import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../common/store";
import {
  selectBankGuaranteeCreationCreating,
  selectBankGuaranteeCreationScoring,
} from "../../../store/bankGuaranteeCreation.selectors";
import { scoringBankGuaranteeCreation } from "../../../store/bankGuaranteeCreation.thunks";

export const useBankGuaranteeCreationScoringCreateNew = () => {
  const dispatch = useAppDispatch();
  const { data: creationData } = useAppSelector(selectBankGuaranteeCreationCreating);
  const { data, status } = useAppSelector(selectBankGuaranteeCreationScoring);

  useEffect(() => {
    if (creationData && data === undefined) {
      dispatch(scoringBankGuaranteeCreation(creationData));
    }
  }, [creationData, data, dispatch]);

  return useMemo(() => ({ data, status }), [data, status]);
};
