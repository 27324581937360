import styled from "@emotion/styled";
import { AppPublicLayoutBoxShadowCard } from "../../../../common/ui/layout/app-public-layout-containers";

export const Card = styled(AppPublicLayoutBoxShadowCard)`
  padding: ${({ theme }) => theme.layout.publicPage.cardPaddingBase.mobile}rem ${({ theme }) => theme.spaces.lg}
    1.125rem;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.layout.publicPage.cardPaddingBase.mobile}rem;

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    padding: ${({ theme }) => theme.layout.publicPage.cardPaddingBase.desktop}rem;
    gap: ${({ theme }) => theme.layout.publicPage.cardPaddingBase.desktop}rem;
  }
`;
