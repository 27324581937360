import * as React from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { LabelRewardsType } from "../../types/LabelRewardsType";
import * as S from "./BankGuaranteeRewardsLabel.styled";

export interface BankGuaranteeRewardsLabelProps extends BaseCssProps {
  type: LabelRewardsType;
  children: React.ReactNode;
}

export const BankGuaranteeRewardsLabel = ({ type, children, className }: BankGuaranteeRewardsLabelProps) => {
  return (
    <S.Container className={className} type={type}>
      <S.Text type={type}>{children}</S.Text>
    </S.Container>
  );
};
