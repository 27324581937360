/*
 * Этот файл сгенерирован автоматически. Пожалуйста, не изменяйте его.
 * Дата генерации: Thu Apr 11 2024 11:43:35 GMT+0300 (Москва, стандартное время)
 */

export default {
  dark: {
    neutral: {
      bgr: {
        tertiary: "#2f4583",
        primary: "#0a173a",
        secondary: "#22356a",
      },
    },
  },
  light: {
    primary: {
      text: {
        default: "#3173b9",
        hover: "#127aea",
        pressed: "#194eb1",
        inverse: "#ffffff",
      },
      tonal: {
        hover: "#e4f3ff",
        default: "#e4f1fa",
        pressed: "#d1ebff",
      },
      fill: {
        default: "#3173b9",
        hover: "#127aea",
        pressed: "#194eb1",
      },
      outline: {
        "10": "#95c7eb1a",
        "60": "#95c7eb99",
        "100": "#95c7eb",
      },
    },
    signal: {
      text: {
        error: "#ef0000",
        warning: "#ec8812",
        success: "#249f05",
      },
      tonal: {
        error: "#fdeaea",
        success: "#e7fbe5",
        warning: "#fff0de",
      },
      transperent: {
        warning: "#f5c388",
        success: "#91cf82",
        error: "#f78080",
      },
    },
    neutral: {
      text: {
        primary: "#111415",
        tertiary: "#8f9193",
        secondary: "#444749",
        quarternary: "#a9abad",
      },
      bgr: {
        secondary: "#f7f8fa",
        tertiary: "#edf0f5",
        primary: "#ffffff",
        quarternary: "#a9abad",
      },
      tonal: "#f2f4f8",
      outline: {
        secondary: "#e8ebee",
        primary: "#a9abad",
      },
    },
  },
} as const;
