import { Theme } from "@sideg/ui-kit";
import { CreditWorkingAssetsDemandStageType } from "../types";
import { ElementColor } from "../../../common/ui-kit/types";
import { Color } from "../../../common/ui-kit/theme";

export const getCreditWorkingAssetsDemandStageColor = (stage: CreditWorkingAssetsDemandStageType): ElementColor => {
  switch (stage) {
    case "AttachingDocuments":
      return "primary";
    case "Confirmed":
      return "typography.successSecondary";
    case "Approved":
      return "status.success";
    case "Rejected":
      return "status.warning";
    case "Processing":
    default:
      return "typography.medium";
  }
};

export const getCreditWorkingAssetsDemandStageColorAttachingAsProcessing = (
  stage: CreditWorkingAssetsDemandStageType,
): ElementColor => {
  return getCreditWorkingAssetsDemandStageColor(stage === "AttachingDocuments" ? "Processing" : stage);
};

export const getCreditWorkingAssetsStageBackgroundColor = (
  stage: CreditWorkingAssetsDemandStageType,
  theme: Theme,
): Color => {
  switch (stage) {
    case "Confirmed":
      return theme.palette.status.successSecondaryBackground;
    case "Approved":
      return theme.palette.status.successBackground;
    case "Rejected":
      return theme.palette.status.warningBackground;
    case "AttachingDocuments":
    case "Processing":
    default:
      return theme.palette.control.secondaryLight;
  }
};
