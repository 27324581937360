import { createAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../common/store/utils";
import { BANK_GUARANTEE_DETAILS_SLICE_NAME } from "./bankGuaranteeDetails.thunks";
import { DemandId } from "../../../common/types/demand";
import { BankGuaranteeTransferPayload } from "../../common/store";

export const bankGuaranteeDetailsGuaranteeChanged = createAction<BankGuaranteeTransferPayload | undefined>(
  combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "guaranteeChanged")
);

export const bankGuaranteeDetailsActiveDemandChanged = createAction<DemandId | undefined>(
  combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "activeDemandChanged")
);

export const bankGuaranteeDetailsReloadActiveDemand = createAction<DemandId | undefined>(
  combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "reloadActiveDemand")
);

export const bankGuaranteeDetailsReloadActiveGuarantee = createAction(
  combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "reloadActiveGuarantee")
);

export const bankGuaranteeDetailsGuaranteeRejected = createAction(
  combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "guaranteeRejected")
);

export const bankGuaranteeDetailsActiveDemandRejected = createAction(
  combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "activeDemandRejected")
);

export const bankGuaranteeDetailsActiveDemandReissued = createAction(
  combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "activeDemandReissued")
);

export const bankGuaranteeDetailsActiveDemandOriginalRequested = createAction(
  combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "originalRequested")
);
