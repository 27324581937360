import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FilteringObjectType } from "../../types/FilteringObjectType";
import { usePaginatedQueryStateMutator } from "./usePaginatedQueryStateMutator";
import { useStateDebounce } from "../../../hooks/useDebounce";

export type ProductFilterWithSearch = FilteringObjectType & {
  search?: string;
  clientNameOrTaxpayerNumber?: string;
  demandIdOrProductId?: number;
  page: number;
};

export const useProductFilterSearchInput = <TFilteringObject extends ProductFilterWithSearch>(
  filteringObject: TFilteringObject,
) => {
  const mutator = usePaginatedQueryStateMutator();

  const [inputValue, setInputValue] = useState("");
  const filterSearchValueRef = useRef<string | null | undefined>(undefined);

  useEffect(() => {
    if (filteringObject.demandIdOrProductId !== undefined) {
      setInputValue(filteringObject.demandIdOrProductId.toString());
    } else if (filteringObject.clientNameOrTaxpayerNumber !== undefined) {
      setInputValue(filteringObject.clientNameOrTaxpayerNumber);
    }
  }, [filteringObject.demandIdOrProductId, filteringObject.clientNameOrTaxpayerNumber]);

  useEffect(() => {
    if (filterSearchValueRef.current !== (filteringObject.search ?? "")) {
      setInputValue(filteringObject.search ?? "");
    }
  }, [filteringObject.search]);

  const setDebounceValue = useCallback(
    (value: string) => {
      if (value !== (filteringObject.search ?? "")) {
        mutator(filteringObject, (filter, { setValues }) => {
          const filterPart: Partial<TFilteringObject> = {
            search: value,
            demandIdOrProductId: undefined,
            clientNameOrTaxpayerNumber: undefined,
          } as TFilteringObject;

          filterSearchValueRef.current = filterPart.search;

          return setValues(filter, filterPart);
        });
      }
    },
    [filteringObject, mutator],
  );

  useStateDebounce(inputValue, setDebounceValue, 500);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);

  return useMemo(() => ({ value: inputValue, onChange }), [inputValue, onChange]);
};
