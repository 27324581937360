import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { LineSeparator } from "../../../../../../../common/ui-kit/separators";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { Typography } from "../../../../../../../common/ui-kit/typography";
import { declensionHelper } from "../../../../../../../common/helpers/entity";
import { ButtonLink } from "../../../../../../../common/ui-kit/buttons/ButtonLink";
import { BankGuaranteeDetailsDemandHeaderReconciliationAdditionalCondition } from "./BankGuaranteeDetailsDemandHeaderReconciliationAdditionalCondition";
import { BankGuaranteeDetailsDemandReconciliationAdditionalCondition } from "../../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationAdditionalCondition";
import { useListTrimMoreThan } from "../../../../../../../common/hooks";

const BoxButtonLink = Box.withComponent(ButtonLink);

export interface BankGuaranteeDetailsDemandHeaderReconciliationCardAdditionalConditionsBlockProps extends BaseCssProps {
  terms: BankGuaranteeDetailsDemandReconciliationAdditionalCondition[];
}

export const BankGuaranteeDetailsDemandHeaderReconciliationCardAdditionalConditionsBlock = ({
  terms,
  className,
}: BankGuaranteeDetailsDemandHeaderReconciliationCardAdditionalConditionsBlockProps) => {
  const { isShown, setIsShown, trimmedCount, needTrim, items } = useListTrimMoreThan(terms, 3);

  return (
    <Box display="flex" flexDirection="column" gap="md" className={className}>
      <Typography
        fontColor="typography.primary"
        fontWeight={700}
        fontSize={{ xs: "fz3", xl: "fz2" }}
        lineHeight="defaultLineHeight"
      >
        Дополнительные условия
      </Typography>
      <Box display="flex" flexDirection="column" gap="sm" className={className}>
        {items.map((condition, index) => (
          <BankGuaranteeDetailsDemandHeaderReconciliationAdditionalCondition
            key={condition.queueTaskId}
            indexNumber={index + 1}
            title={condition.title}
            state={condition.state.code}
            queueTaskId={condition.queueTaskId}
          />
        ))}
        {needTrim && !isShown && (
          <BoxButtonLink alignSelf="flex-start" type="button" onClick={() => setIsShown(true)}>
            <Typography fontColor="primary" fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight">
              Еще {declensionHelper.commonDeclensions.condition.nominative(trimmedCount, true)}
            </Typography>
          </BoxButtonLink>
        )}
      </Box>
      <LineSeparator />
    </Box>
  );
};
