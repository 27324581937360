import { AnySchema, object, string } from "yup";
import { getEnumLikeLiteralTypeSchema, getFormattedPhoneSchema } from "../../../common/validation";
import { deliveryTypeRules } from "./rules/deliveryTypeRules";
import {
  bankGuaranteeRequestOriginalDeliveryType,
  BankGuaranteeRequestOriginalDeliveryType,
  bankGuaranteeRequestOriginalOfficeRecipientType,
} from "../../common/types";

export const requestOriginalCreationFieldMaxLength = {
  emailContact: 50,
  destination: 100,
};

export const getDeliveryTypeSchema = <TSchema extends AnySchema>(
  schema: TSchema,
  mutation?: (schema: TSchema) => TSchema,
  isReverse = false,
): TSchema =>
  schema.when("deliveryType", (deliveryType: BankGuaranteeRequestOriginalDeliveryType, customSchema: TSchema) => {
    if (deliveryTypeRules.isDelivery(deliveryType) !== isReverse) {
      return mutation?.(customSchema) ?? customSchema;
    }

    return customSchema.notRequired().strip();
  });

export const bankGuaranteeRequestOriginalCreationFormValidationSchema = object({
  deliveryType: getEnumLikeLiteralTypeSchema(bankGuaranteeRequestOriginalDeliveryType)
    .required("Не забудьте заполнить")
    .default("Office"),
  officeRecipientType: getDeliveryTypeSchema(
    getEnumLikeLiteralTypeSchema(bankGuaranteeRequestOriginalOfficeRecipientType)
      .required("Не забудьте заполнить")
      .default("Director"),
    undefined,
    true,
  ),
  surname: getDeliveryTypeSchema(string().required("Укажите фамилию").default("")),
  name: getDeliveryTypeSchema(string().required("Укажите имя").default("")),
  patronymic: getDeliveryTypeSchema(string().notRequired().default("")),
  phone: getDeliveryTypeSchema(string().required("").default(""), () => getFormattedPhoneSchema()),
  email: getDeliveryTypeSchema(
    string()
      .default("")
      .email("Введите корректный адрес электронной почты")
      .max(requestOriginalCreationFieldMaxLength.emailContact, (params) => `Не больше ${params.max} символов`)
      .required("Не забудьте заполнить"),
  ),
  recipient: getDeliveryTypeSchema(string().default("").required("Укажите название юридического лица")),
  address: getDeliveryTypeSchema(
    string()
      .default("")
      .max(requestOriginalCreationFieldMaxLength.destination, (params) => `Не больше ${params.max} символов`)
      .required("Укажите полный адрес"),
  ),
  comment: string().default(""),
});
