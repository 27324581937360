import { ChevronLeftIcon } from "../../ui-kit/icons";
import { AppButtonOutline } from "./AppButtonOutline";

export interface BackButtonProps {
  text?: string;
  onClick: () => void;
}

export const BackButton = ({ text = "Назад", onClick }: BackButtonProps) => {
  return (
    <AppButtonOutline onClick={onClick}>
      <ChevronLeftIcon />
      <span>{text}</span>
    </AppButtonOutline>
  );
};
