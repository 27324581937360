import { useContext } from "react";
import { ServiceWorkerUpdateApiContextType } from "../types/ServiceWorkerUpdateApiContextType";
import { serviceWorkerUpdateApiContext } from "../store/serviceWorkerUpdateApiContext";

export const useServiceWorkerUpdateApiContext = (): ServiceWorkerUpdateApiContextType => {
  const context = useContext(serviceWorkerUpdateApiContext);
  if (context === undefined) {
    throw new Error("Вызов хука вне контекста");
  }

  return context;
};
