import { useCallback } from "react";
import { IssuingCompanyId } from "../../../../../common/types/companies";
import { FormSubmitHandler, useGetFieldName } from "../../../../../common/form";
import { PublicUserRegistrationAgentDocumentApproveFormValues } from "../../../types/agent/PublicUserRegistrationAgentDocumentApproveFormValues";
import { useAppDispatch, useAppSelector } from "../../../../../common/store";
import { selectPublicUserRegistrationAgent } from "../../../store/agent/publicUserRegistrationAgent.selectors";
import { isBaseApiError } from "../../../../../common/api/types";
import { signPublicUserRegistration } from "../../../store/publicUserRegistration.thunks";
import { publicUserRegistrationAgentDocumentApproveValidationSchema } from "../../../validation/publicUserRegistrationAgentDocumentApproveValidationSchema";
import { changeDocumentSignStatePublicUserRegistrationAgent } from "../../../store/agent/publicUserRegistrationAgent.slice";

export const usePublicUserRegistrationAgentDocumentApproveOnSubmit = (
  issuingCompanyId: IssuingCompanyId
): FormSubmitHandler<PublicUserRegistrationAgentDocumentApproveFormValues> => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { agencyId } = useAppSelector(selectPublicUserRegistrationAgent).data!;
  const getFieldName = useGetFieldName<PublicUserRegistrationAgentDocumentApproveFormValues>();

  return useCallback(
    async (values, { setFieldError }) => {
      try {
        const castedValue = publicUserRegistrationAgentDocumentApproveValidationSchema.cast(values);
        await dispatch(signPublicUserRegistration({ issuingCompanyId, code: castedValue.code, agencyId })).unwrap();
        dispatch(changeDocumentSignStatePublicUserRegistrationAgent({ issuingCompanyId, signState: "signed" }));
      } catch (err) {
        const fallback = "Неверный код";
        setFieldError(getFieldName("code"), isBaseApiError(err) ? err.message ?? fallback : fallback);
      }
    },
    [agencyId, dispatch, getFieldName, issuingCompanyId]
  );
};
