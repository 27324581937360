import { useMemo } from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  getCreditWorkingAssetsDemandStageColor,
  getCreditWorkingAssetsDemandStageIcon,
} from "../../../../common/utils";
import { creditWorkingAssetsDemandStageType, CreditWorkingAssetsDemandStageType } from "../../../../common/types";
import { ProductStageCounter } from "../../../../../common/ui/product/stage";

export interface CreditWorkingAssetsListItemStageCounterProps extends BaseCssProps {
  stage: CreditWorkingAssetsDemandStageType;
  count: number;
}

export const CreditWorkingAssetsListItemStageCounter = ({
  stage,
  count,
  className,
}: CreditWorkingAssetsListItemStageCounterProps) => {
  const icon = useMemo(() => getCreditWorkingAssetsDemandStageIcon(stage), [stage]);
  const color = getCreditWorkingAssetsDemandStageColor(stage);

  return (
    <ProductStageCounter
      color={color}
      count={count}
      className={className}
      icon={icon}
      tooltip={creditWorkingAssetsDemandStageType.getTitle(stage)}
    />
  );
};
