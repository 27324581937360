import { useMemo } from "react";
import { useUserCreationProducts } from "../../../../common/app-products";

export const useQueueTasksCreationProductsDescription = () => {
  const creationItems = useUserCreationProducts();

  return useMemo(
    () =>
      creationItems.length > 0
        ? `на ${creationItems
            .slice(0, 3)
            .map((x) => x.titleInGenitive)
            .join(", ")}`
        : undefined,
    [creationItems]
  );
};
