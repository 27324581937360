import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { useNavigationStateFromWithFallback } from "../../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../../common/urls/internal/utils";
import { selectCreditWorkingAssetsCreation } from "../../store/creditWorkingAssetsCreation.selectors";
import { changeStageCreditWorkingAssetCreation } from "../../store/creditWorkingAssetsCreation.slice";
import { ProductCreationStages, ProductCreationPageHeader } from "../../../../common/product-creation";

export const CreditWorkingAssetsCreationPageHeader = () => {
  const dispatch = useAppDispatch();

  const { activeStage } = useAppSelector(selectCreditWorkingAssetsCreation);

  const onPrevStageClick = (stage: ProductCreationStages) => {
    dispatch(changeStageCreditWorkingAssetCreation(stage));
  };

  const { from } = useNavigationStateFromWithFallback(
    selectAppUrl((x) => x.creditWorkingAssets.list.root).url,
    "Мои заявки"
  );

  return (
    <ProductCreationPageHeader
      activeStage={activeStage}
      onPrevStageClick={onPrevStageClick}
      from={from}
      title="Новая заявка на кредит на ПОС"
    />
  );
};
