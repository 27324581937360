import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xxl};
  min-height: 2rem;
  justify-content: flex-end;
  transition: min-height 0.15s 0.15s ease;
`;
