import * as React from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import * as S from "./PublicUserCardWithTitle.styled";
import { AppPublicLayoutH2 } from "../../../../common/ui/layout/app-public-layout-containers";

export interface PublicUserCardWithTitleProps extends BaseCssProps {
  title?: React.ReactNode;
  children: React.ReactNode;
}

export const PublicUserCardWithTitle = ({ title, className, children }: PublicUserCardWithTitleProps) => {
  return (
    <S.Card className={className}>
      {title && <AppPublicLayoutH2>{title}</AppPublicLayoutH2>}
      {children}
    </S.Card>
  );
};
