import { date, InferType, mixed, number, object } from "yup";
import { getDateOnlyTransformer, getFormattedNumberTransformer } from "../../../validation";
import { makeEnumLikeLiteral } from "../../../types/utils";

const periodsSchema = object({
  dateFrom: date().transform(getDateOnlyTransformer()).typeError("Укажите корректную дату").notRequired(),
  dateTo: date().transform(getDateOnlyTransformer()).typeError("Укажите корректную дату").notRequired(),
  period: number().transform(getFormattedNumberTransformer()).notRequired().typeError("").default(0),
});

export type PeriodSchemaKeys = keyof InferType<typeof periodsSchema>;

export const periodKeys: PeriodSchemaKeys[] = ["dateFrom", "dateTo", "period"];

export const periodsSchemaWithLocker = periodsSchema.shape({
  periodLocker: mixed<PeriodSchemaKeys>().oneOf(periodKeys).default("period"),
});

const amountsSchema = object({
  sum: number()
    .transform(getFormattedNumberTransformer())
    .typeError("")
    .min(0, "Сумма должна быть больше нуля")
    .notRequired(),
  commission: number()
    .transform(getFormattedNumberTransformer())
    .typeError("")
    .min(0, "Сумма должна быть больше нуля")
    .notRequired(),
  percent: number().transform(getFormattedNumberTransformer()).typeError("").notRequired(),
});

export type AmountSchemaKeys = keyof InferType<typeof amountsSchema>;

const amountTuple = ["sum", "commission", "percent"] as const;

export const amountFieldsEnumLike = makeEnumLikeLiteral(amountTuple, {
  sum: "Сумма",
  commission: "Комиссия",
  percent: "Ставка годовых",
});

export const amountsSchemaWithLocker = amountsSchema.shape({
  amountLocker: mixed<AmountSchemaKeys>().oneOf(amountFieldsEnumLike.values).default("percent"),
});

export const widgetsCalculatorValidationSchema = periodsSchemaWithLocker.concat(amountsSchemaWithLocker);
