import { useContext } from "react";
import { BankGuaranteeListFilterApiContextValue } from "../../types/filters/BankGuaranteeListFilterApiContextValue";
import { BankGuaranteeListFilterApiContext } from "../../store/filter-context/BankGuaranteeListFilterApiContext";

export const useBankGuaranteeListFilterApiContext = (): BankGuaranteeListFilterApiContextValue => {
  const value = useContext(BankGuaranteeListFilterApiContext);

  if (value === undefined) {
    throw new Error("Попытка получить значение контекста вне контекста");
  }

  return value;
};
