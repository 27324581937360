import { useApplicationUpdater } from "../hooks/useApplicationUpdater";
import {
  useServiceWorkerNeedForceUpdateContext,
  useServiceWorkerNeedUpdateContext,
  useServiceWorkerUpdateApiContext,
} from "../../service-worker";
import { ApplicationUpdaterAlert } from "./alerts/ApplicationUpdaterAlert";
import { ApplicationUpdaterForceAlert } from "./alerts/ApplicationUpdaterForceAlert";
import { applicationUpdaterConfigs } from "../configs/applicationUpdaterConfigs";
import { PortalContainer } from "../../ui/containers/portal";

export const ApplicationUpdater = () => {
  const isNeedUpdate = useServiceWorkerNeedUpdateContext();
  const isNeedForceUpdate = useServiceWorkerNeedForceUpdateContext();

  const { update, dismiss } = useServiceWorkerUpdateApiContext();

  useApplicationUpdater();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isNeedUpdate && (
        <PortalContainer htmlElementId={applicationUpdaterConfigs.htmlContainerId ?? ""}>
          {isNeedForceUpdate && <ApplicationUpdaterForceAlert onUpdate={update} />}
          {!isNeedForceUpdate && <ApplicationUpdaterAlert onUpdate={update} onDismiss={dismiss} />}
        </PortalContainer>
      )}
    </>
  );
};
