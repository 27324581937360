import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useCreditWorkingAssetsDetailsContext } from "../../../../hooks/context/useCreditWorkingAssetsDetailsContext";
import { useAppSelector } from "../../../../../../common/store";
import { selectCreditWorkingAssetsDetailsDemandById } from "../../../../store/demands/creditWorkingAssetsDetailsDemands.selectors";
import {
  getProductCreditsOnlyDetailsDemandHeaderDetailsItems,
  ProductDetailsContentHeaderLayout,
} from "../../../../../../common/product-details";

export type CreditWorkingAssetsDetailsDemandHeaderDetailsProps = BaseCssProps;

export const CreditWorkingAssetsDetailsDemandHeaderDetails = ({
  className,
}: CreditWorkingAssetsDetailsDemandHeaderDetailsProps) => {
  const { demandId } = useCreditWorkingAssetsDetailsContext();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectCreditWorkingAssetsDetailsDemandById(state, demandId!));

  const { main, approve } = getProductCreditsOnlyDetailsDemandHeaderDetailsItems(demand);

  return <ProductDetailsContentHeaderLayout.HeaderDetails left={main} right={approve} className={className} />;
};
