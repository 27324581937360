import { usePageTitle } from "../../../common/navigation/page-title";
import { FullPageLayoutContainer } from "../../../common/ui/layout";
import { ErrorBoundary, ErrorBoundaryDefaultFallbackAlert } from "../../../common/ui/containers/error-boundary";
import { FactoringCreationHeader } from "../components/header/FactoringCreationHeader";
import { FactoringCreationForm } from "../components/form/FactoringCreationForm";

export const FactoringCreationPage = () => {
  usePageTitle("Новая заявка на факторинг");

  return (
    <FullPageLayoutContainer>
      <ErrorBoundary fallback={<ErrorBoundaryDefaultFallbackAlert />}>
        <FactoringCreationHeader />
        <FactoringCreationForm />
      </ErrorBoundary>
    </FullPageLayoutContainer>
  );
};
