import styled from "@emotion/styled";

export const StyledNavigationButton = styled.button<{ isActive: boolean }>`
  height: ${({ theme }) => theme.layout.header.button.size};
  width: ${({ theme }) => theme.layout.header.button.size};
  background: ${({ isActive, theme }) => (isActive ? theme.layout.header.button.activeBackgroundColor : "transparent")};
  border: none;
  border-radius: ${({ theme }) => theme.layout.header.button.borderRadius};
  transition: background-color 200ms ease;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spaces.xs};
  margin: 0;

  & > svg {
    width: 20px;
    height: 20px;
  }

  & > svg > path {
    stroke: ${({ isActive, theme }) =>
      isActive ? theme.layout.header.button.backgroundColor : theme.layout.header.button.activeBackgroundColor};
  }

  &:hover {
    background: ${({ theme }) => theme.layout.header.button.hoverBackgroundColor};

    & > svg > path {
      stroke: ${({ theme }) => theme.layout.header.button.activeBackgroundColor};
    }
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.layout.header.button.activeBackgroundColor};
  }

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    background: ${({ isActive, theme }) =>
      isActive ? theme.layout.header.button.activeBackgroundColor : theme.layout.header.button.backgroundColor};

    &:hover {
      background: ${({ theme }) => theme.layout.header.button.hoverBackgroundColor};

      & > svg > path {
        stroke: ${({ theme }) => theme.layout.header.button.activeBackgroundColor};
      }
    }
  }
`;
