import { Formik } from "formik";
import { CommentFormFormValues } from "../types/CommentFormFormValues";
import { CommentFormFields } from "./CommentFormFields";
import { commentFormValidationSchema } from "../validation/CommentFormValidationSchema";

export interface CommentFormProps {
  onSubmit: (values: CommentFormFormValues) => void | Promise<void>;
  onCancel: () => void;
  submitError?: string;
  className?: string;
}

export const CommentForm = ({ className, onCancel, onSubmit, submitError }: CommentFormProps) => {
  return (
    <Formik<CommentFormFormValues>
      onSubmit={onSubmit}
      initialValues={commentFormValidationSchema.getDefault() as CommentFormFormValues}
      validationSchema={commentFormValidationSchema}
    >
      <CommentFormFields className={className} onCancel={onCancel} submitError={submitError} />
    </Formik>
  );
};
