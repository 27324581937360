import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  FullPageContainerHeaderContainer,
  FullPageContainerHeaderDesktop,
  FullPageContainerHeaderTitle,
} from "../../../../../common/ui/containers/full-page";
import { CreditWorkingAssetsCreationCreateByExistsHeaderMobile } from "./CreditWorkingAssetsCreationCreateByExistsHeader.mobile";
import { BackLinkButton } from "../../../../../common/ui/buttons/BackLinkButton";
import { CreditWorkingAssetsCreationCreateByExistsPageType } from "../../../types/creation/CreditWorkingAssetsCreationCreateByExistsPageType";
import { useNavigationStateFromWithFallback } from "../../../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../../../common/urls/internal/utils";

export interface CreditWorkingAssetsCreationCreateByExistsHeaderProps extends BaseCssProps {
  activePage: CreditWorkingAssetsCreationCreateByExistsPageType;
  onPageTypeClick: (type: CreditWorkingAssetsCreationCreateByExistsPageType) => void | Promise<void>;
}

export const CreditWorkingAssetsCreationCreateByExistsHeader = ({
  activePage,
  onPageTypeClick,
  className,
}: CreditWorkingAssetsCreationCreateByExistsHeaderProps) => {
  const { from } = useNavigationStateFromWithFallback(
    selectAppUrl((x) => x.creditWorkingAssets.list.root).url,
    "Мои заявки",
  );

  return (
    <FullPageContainerHeaderContainer className={className}>
      <ThemeMobileDesktopView
        mobile={
          <CreditWorkingAssetsCreationCreateByExistsHeaderMobile
            onPageTypeClick={onPageTypeClick}
            activePage={activePage}
            from={from}
          />
        }
        desktop={
          <FullPageContainerHeaderDesktop
            leftSide={<BackLinkButton linkTo={from.url} text={from.backTitle} />}
            className={className}
          >
            <FullPageContainerHeaderTitle text="Выбор банков для доотправки заявки" />
          </FullPageContainerHeaderDesktop>
        }
      />
    </FullPageContainerHeaderContainer>
  );
};
