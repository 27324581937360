import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  federalLaw: "По ФЗ",
  commercial: "Коммерческая",
};

export const bankGuaranteeCreationPurchaseType = makeEnumLikeLiteral(["federalLaw", "commercial"] as const, titles);

export type BankGuaranteeCreationPurchaseType = EnumLikeLiteralType<typeof bankGuaranteeCreationPurchaseType>;
