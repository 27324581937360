import { AgencyCommonPublicUserRegistrationOutputDto } from "../../api/dto/output/AgencyCommonPublicUserRegistrationOutputDto";
import { PublicUserRegistrationAgentSliceState } from "./publicUserRegistrationAgent.types";
import { PublicUserRegistrationAgentFormValues } from "../../types/agent/PublicUserRegistrationAgentFormValues";
import { PublicUserRegistrationAgentDocument } from "../../types/agent/PublicUserRegistrationAgentDocument";

const fromResponse = (response: AgencyCommonPublicUserRegistrationOutputDto): PublicUserRegistrationAgentSliceState => {
  const formValues: PublicUserRegistrationAgentFormValues = {
    companyType: response.companyType,
    email: response.email,
    phoneNumber: response.phone,
    taxpayerNumber: response.taxpayerNumber,
    fullName: response.fullName,
    isAgreeWithPrivacyPolicy: true,
  };

  return {
    agencyId: response.agencyId,
    phoneNumber: response.phone,
    email: response.email,
    formValues,
  };
};

const mapDocuments = (response: AgencyCommonPublicUserRegistrationOutputDto): PublicUserRegistrationAgentDocument[] => {
  return response.documents.map((x) => ({
    offerName: x.offerName,
    issuingCompanyId: x.issuingCompanyId,
    signState: x.isSigned ? "signed" : "initial",
    canSendCodeAfterTime: undefined,
  }));
};

export const publicUserRegistrationAgentMapper = { fromResponse, mapDocuments };
