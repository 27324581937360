import { currencyHelper, numberHelper } from "@sideg/helpers";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { DemandQueueTaskActiveBillInfo } from "../../../../../../demand/queue-tasks";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { Typography } from "../../../../../../common/ui-kit/typography";
import { DotSeparatedRowContainer } from "../../../../../../common/ui-kit/containers/dot-separated-row";
import * as S from "./BankGuaranteeDetailsBillComplicatedTaskBillInfo.styled";
import { BankGuaranteeDetailsBillComplicatedTaskBillInfoRewardAgent } from "./BankGuaranteeDetailsBillComplicatedTaskBillInfoRewardAgent";
import { BankGuaranteeDetailsBillComplicatedTaskBillInfoCosts } from "./BankGuaranteeDetailsBillComplicatedTaskBillInfoCosts";

export interface BankGuaranteeDetailsBillComplicatedTaskBillInfoProps
  extends Pick<
      DemandQueueTaskActiveBillInfo,
      "costBase" | "costFinal" | "discountBank" | "discountAgent" | "excessAgent" | "rewardAgent"
    >,
    BaseCssProps {}

export const BankGuaranteeDetailsBillComplicatedTaskBillInfo = ({
  className,
  discountBank,
  discountAgent,
  excessAgent,
  rewardAgent,
  costFinal,
  costBase,
}: BankGuaranteeDetailsBillComplicatedTaskBillInfoProps) => {
  return (
    <Box gap="sm" display="flex" flexDirection="column" className={className}>
      <ThemeMobileDesktopView
        mobile={
          <S.Container columnGap="xs" rowGap="sm" secondColumnWidth="auto" placeItems="flex-start">
            <BankGuaranteeDetailsBillComplicatedTaskBillInfoCosts
              costBase={costBase}
              discountBank={discountBank}
              discountAgent={discountAgent}
              excessAgent={excessAgent}
            />
            <Typography fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.primary">
              Итоговая стоимость
            </Typography>
            <Typography fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.primary">
              {currencyHelper.getCurrencyString(costFinal.sum)}
            </Typography>
            <Typography fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.primary">
              Ставка
            </Typography>
            <Typography fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.primary">
              {numberHelper.getFormattedDecimal(costFinal.rate, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
              % годовых
            </Typography>
            <BankGuaranteeDetailsBillComplicatedTaskBillInfoRewardAgent rewardAgent={rewardAgent} />
          </S.Container>
        }
        desktop={
          <>
            <S.Container columnGap="xs" rowGap="sm" secondColumnWidth="max-content">
              <BankGuaranteeDetailsBillComplicatedTaskBillInfoCosts
                costBase={costBase}
                discountBank={discountBank}
                discountAgent={discountAgent}
                excessAgent={excessAgent}
              />
            </S.Container>
            <S.Container columnGap="xs" rowGap="lg" pt="md" secondColumnWidth="auto" placeItems="flex-start">
              <Typography fontWeight={700} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.primary">
                Итоговая стоимость
              </Typography>
              <DotSeparatedRowContainer dotColor="typography.medium">
                <Typography
                  fontWeight={700}
                  fontSize="fz2"
                  lineHeight="defaultLineHeight"
                  fontColor="typography.primary"
                >
                  {currencyHelper.getCurrencyString(costFinal.sum)}
                </Typography>
                <Typography
                  fontWeight={700}
                  fontSize="fz2"
                  lineHeight="defaultLineHeight"
                  fontColor="typography.medium"
                >
                  ставка{" "}
                  {numberHelper.getFormattedDecimal(costFinal.rate, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                  % годовых
                </Typography>
              </DotSeparatedRowContainer>
              <BankGuaranteeDetailsBillComplicatedTaskBillInfoRewardAgent rewardAgent={rewardAgent} />
            </S.Container>
          </>
        }
      />
    </Box>
  );
};
