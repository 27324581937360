import { useEffect, ReactNode } from "react";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { useAppDispatch } from "../../../common/store";
import { usePageTitle } from "../../../common/navigation/page-title";
import { useScrollToTopOnMount } from "../../../common/hooks/scroll";
import { newCreditExecutionCreation } from "../store/creditExecutionCreation.slice";
import { ErrorBoundary, ErrorBoundaryDefaultFallbackAlert } from "../../../common/ui/containers/error-boundary";
import { FullPageLayoutContainer } from "../../../common/ui/layout";

export interface CreditExecutionCreationPageLayoutProps extends BaseCssProps {
  children?: ReactNode;
}

export const CreditExecutionCreationPageLayout = ({ children, className }: CreditExecutionCreationPageLayoutProps) => {
  const dispatch = useAppDispatch();

  usePageTitle("Создание заявки на КИК");
  useScrollToTopOnMount();

  useEffect(() => {
    return () => {
      dispatch(newCreditExecutionCreation());
    };
  }, [dispatch]);

  return (
    <FullPageLayoutContainer className={className}>
      <ErrorBoundary fallback={<ErrorBoundaryDefaultFallbackAlert />}>{children}</ErrorBoundary>
    </FullPageLayoutContainer>
  );
};
