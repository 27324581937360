import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import * as S from "./BankGuaranteeListItemMobileDemand.styled";

export type BankGuaranteeListItemMobileDemandSkeletonProps = BaseCssProps;

export const BankGuaranteeListItemMobileDemandSkeleton = ({
  className,
}: BankGuaranteeListItemMobileDemandSkeletonProps) => {
  return (
    <S.Li className={className}>
      <Skeleton height={32} />
    </S.Li>
  );
};
