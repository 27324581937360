import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "../../ui-kit/icons";
import { AppButtonOutline } from "./AppButtonOutline";

export interface BackLinkButtonProps {
  text?: string;
  linkTo: string;
}

const AppButtonLink = AppButtonOutline.withComponent(Link);

export const BackLinkButton = ({ text = "Назад", linkTo }: BackLinkButtonProps) => {
  return (
    <AppButtonLink to={linkTo}>
      <ChevronLeftIcon />
      <span>{text}</span>
    </AppButtonLink>
  );
};
