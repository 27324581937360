import { clientV4 } from "../../../common/api";
import { GetBankGuaranteeBriefInputDto } from "./dto/input/GetBankGuaranteeBriefInputDto";
import { GetBankGuaranteeBriefOutputDto } from "./dto/output/GetBankGuaranteeBriefOutputDto";

const GUARANTEE_CONTROLLER_NAME = "Guarantees";

export const bankGuaranteeBriefApi = {
  get: ({ guaranteeId }: GetBankGuaranteeBriefInputDto, abortSignal?: AbortSignal) =>
    clientV4.get<GetBankGuaranteeBriefInputDto, GetBankGuaranteeBriefOutputDto>(
      `${GUARANTEE_CONTROLLER_NAME}/${guaranteeId}/brief`,
      undefined,
      {
        abortSignal,
      }
    ),
};
