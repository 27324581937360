import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useCreditWorkingAssetsListFilterContext } from "../../../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { useCreditWorkingAssetsListFilterApiContext } from "../../../../hooks/filters/useCreditWorkingAssetsListFilterApiContext";
import { useAppSelector } from "../../../../../../common/store";
import { selectCreditWorkingAssetsFiltersData } from "../../../../store/filters/creditWorkingAssetsListFilters.selectors";
import { FilterSelect } from "../../../../../../common/ui-kit/form-controls/select";
import { UNSELECTED_ENUM_LITERAL } from "../../../../../../common/types/utils";

export type CreditWorkingAssetsListHeaderFiltersStatusDesktopProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFiltersStatusDesktop = ({
  className,
}: CreditWorkingAssetsListHeaderFiltersStatusDesktopProps) => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();
  const mutator = useCreditWorkingAssetsListFilterApiContext();

  const data = useAppSelector(selectCreditWorkingAssetsFiltersData);
  const getTitleFunc = (item: string) => data?.statuses.titles[item] ?? item;

  return (
    <FilterSelect
      className={className}
      text="Статус"
      selected={filteringObject.status ?? UNSELECTED_ENUM_LITERAL}
      getItemTitle={getTitleFunc}
      onSelectedChanged={(value) => {
        mutator(filteringObject, (filter, service) => service.setValue(filter, "status", value));
      }}
      items={data?.statuses.ids ?? []}
      maxItemsLength={data?.statuses.maxTitleLength}
    />
  );
};
