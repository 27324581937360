import Skeleton from "react-loading-skeleton";
import { useTheme } from "@sideg/ui-kit";
import * as S from "./ProductCreationScoringResultSummary.mobile.styled";
import { ProductCreationScoringResultSummaryCommonProps } from "../ProductCreationScoringResultSummaryCommonProps";
import { Typography } from "../../../../../ui-kit/typography";
import { ButtonLink } from "../../../../../ui-kit/buttons/ButtonLink";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";
import { ProductCreationScoringResultSummaryIconMobile } from "./ProductCreationScoringResultSummaryIcon.mobile";

export interface ProductCreationScoringResultSummaryMobileProps extends ProductCreationScoringResultSummaryCommonProps {
  onShowRejectsClick: () => void | Promise<void>;
}

export const ProductCreationScoringResultSummaryMobile = ({
  className,
  isSkeleton,
  successCount,
  duplicateCount,
  rejectCount,
  onShowRejectsClick,
}: ProductCreationScoringResultSummaryMobileProps) => {
  const isNeedRender = (value: number | undefined): value is number => !isSkeleton && value !== undefined && value > 0;
  const theme = useTheme();

  const iconSkeleton = isSkeleton && (
    <Skeleton
      width={48}
      baseColor={theme.palette.control.secondaryMedium}
      highlightColor={theme.palette.control.secondaryLight}
      height={24}
    />
  );

  return (
    <S.Block className={className}>
      <S.ResultsRow>
        <Typography fontWeight={700} lineHeight="1.2" fontSize="fz3" fontColor="typography.primary">
          Результаты
          <br />
          скоринга
        </Typography>
        <StackPanel gap="xl" direction="row" alignItems="center">
          {isNeedRender(successCount) && (
            <ProductCreationScoringResultSummaryIconMobile resultType="success" count={successCount} />
          )}
          {iconSkeleton}
          {isNeedRender(rejectCount) && (
            <ProductCreationScoringResultSummaryIconMobile resultType="reject" count={rejectCount} />
          )}
          {iconSkeleton}
          {isNeedRender(duplicateCount) && (
            <ProductCreationScoringResultSummaryIconMobile resultType="duplicate" count={duplicateCount} />
          )}
          {iconSkeleton}
        </StackPanel>
      </S.ResultsRow>
      {isNeedRender(rejectCount) && (
        <ButtonLink onClick={onShowRejectsClick} type="button">
          <Typography fontWeight={500} fontSize="fz3">
            Какие банки отказали и почему?
          </Typography>
        </ButtonLink>
      )}
      {isSkeleton && (
        <Skeleton
          baseColor={theme.palette.control.secondaryMedium}
          highlightColor={theme.palette.control.secondaryLight}
        />
      )}
    </S.Block>
  );
};
