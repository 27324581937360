import * as React from "react";
import { FormEvent, useState } from "react";
import { connect } from "react-redux";
import { IAgentReportCreateState } from "../../../../../store/reducers/agentReportReducer";
import Button, { ButtonColor, ButtonType } from "../../../../controls-old/button/Button";
import { AgentReportStatus } from "../../../../../store/actions/agentReportActionsTypes";
import SelectRequired from "../../../../controls-old/select/SelectRequired";
import IValue from "../../../../controls-old/IValue";
import { months, years } from "./AgentReportCreateFormData";
import { agentReportCreateSend } from "../../../../../store/actions/agentReportActions";
import { useAppDispatch } from "../../../../../domains/common/store";

interface IAgentReportCreateFormProps {
  state: IAgentReportCreateState;
}

const defaultValue: IValue = {
  isValid: false,
};

const AgentReportCreateForm: React.FC<IAgentReportCreateFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const [viewErrors, setViewError] = useState(false);
  const [monthValue, setMonthValue] = useState<IValue>({ ...defaultValue });
  const [yearValue, setYearValue] = useState<IValue>({ ...defaultValue });

  const handleSendForm = (e: FormEvent) => {
    e.preventDefault();
    const result = validateForm();

    if (result) {
      dispatch(
        agentReportCreateSend({
          month: parseInt(monthValue.value!!) + 1,
          year: parseInt(yearValue.value!!),
        })
      );
    }
  };

  const validateForm = (): boolean => {
    setViewError(true);

    const validMonth = monthValue.isValid;
    const validYear = yearValue.isValid;

    return validMonth && validYear;
  };

  return (
    <form className="tender-support" onSubmit={(e) => handleSendForm(e)}>
      <div className="tender-support__form">
        {props.state?.status === AgentReportStatus.Failed && props.state?.errorMessage && (
          <div className="alert alert_danger">{props.state.errorMessage}</div>
        )}

        <SelectRequired
          values={months()}
          value={monthValue.value}
          placeholder={"Выберите месяц"}
          viewError={viewErrors}
          name={"month"}
          onChange={setMonthValue}
        />

        <SelectRequired
          values={years()}
          value={yearValue.value}
          placeholder={"Выберите год"}
          viewError={viewErrors}
          name={"year"}
          onChange={setYearValue}
        />
      </div>
      <div className="tender-support__buttons">
        <Button
          isDisabled={props.state?.status === AgentReportStatus.Processing}
          type={ButtonType.Submit}
          color={ButtonColor.Green}
        >
          {props.state?.status === AgentReportStatus.Processing ? "Отправка..." : "Отправить"}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any, ownState: any): IAgentReportCreateFormProps => {
  return {
    ...ownState,
    state: state.agentReportReducer.create,
  };
};

export default connect(mapStateToProps)(AgentReportCreateForm);
