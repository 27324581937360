import { publicClientV4 } from "../../../common/api";
import { CheckAgencyPublicUserRegistrationInputDto } from "./dto/input/CheckAgencyPublicUserRegistrationInputDto";
import { CheckAgencyPublicUserRegistrationOutputDto } from "./dto/output/CheckAgencyPublicUserRegistrationOutputDto";
import { RegisterClientPublicUserRegistrationInputDto } from "./dto/input/RegisterClientPublicUserRegistrationInputDto";
import { CompleteCommonPublicUserRegistrationOutputDto } from "./dto/output/CompleteCommonPublicUserRegistrationOutputDto";
import { AgencyIdCommonPublicUserRegistrationInputDto } from "./dto/input/AgencyIdCommonPublicUserRegistrationInputDto";
import { AgencyCommonPublicUserRegistrationOutputDto } from "./dto/output/AgencyCommonPublicUserRegistrationOutputDto";
import { RegisterAgentPublicUserRegistrationInputDto } from "./dto/input/RegisterAgentPublicUserRegistrationInputDto";
import { PublicUserRegistrationAgentDocumentsCommonInputDto } from "./dto/input/PublicUserRegistrationAgentDocumentsCommonInputDto";
import { ApiBlobResponse, ApiObject, EmptyOutputDto } from "../../../common/api/types";
import { SendCodePublicUserRegistrationOutputDto } from "./dto/output/SendCodePublicUserRegistrationOutputDto";
import { SignPublicUserRegistrationInputDto } from "./dto/input/SignPublicUserRegistrationInputDto";

const CONTROLLER_NAME = "Registrations";

export const publicUserRegistrationApi = {
  checkAgency: (model: CheckAgencyPublicUserRegistrationInputDto) =>
    publicClientV4.put<CheckAgencyPublicUserRegistrationInputDto, CheckAgencyPublicUserRegistrationOutputDto>(
      `${CONTROLLER_NAME}/check`,
      model,
    ),
  registerClient: (model: RegisterClientPublicUserRegistrationInputDto) =>
    publicClientV4.post<RegisterClientPublicUserRegistrationInputDto, CompleteCommonPublicUserRegistrationOutputDto>(
      `${CONTROLLER_NAME}/client`,
      model,
    ),
  registerAgent: (model: RegisterAgentPublicUserRegistrationInputDto) =>
    publicClientV4.post<RegisterAgentPublicUserRegistrationInputDto, AgencyCommonPublicUserRegistrationOutputDto>(
      `${CONTROLLER_NAME}/agency`,
      model,
    ),
  getAgency: ({ agencyId }: AgencyIdCommonPublicUserRegistrationInputDto) =>
    publicClientV4.get<AgencyIdCommonPublicUserRegistrationInputDto, AgencyCommonPublicUserRegistrationOutputDto>(
      `${CONTROLLER_NAME}/agency/${agencyId}`,
    ),
  completeAgencyRegistration: ({ agencyId }: AgencyIdCommonPublicUserRegistrationInputDto) =>
    publicClientV4.post<undefined, CompleteCommonPublicUserRegistrationOutputDto>(
      `${CONTROLLER_NAME}/agency/${agencyId}`,
      undefined,
    ),
  downloadStatement: (model: PublicUserRegistrationAgentDocumentsCommonInputDto) =>
    publicClientV4.post<PublicUserRegistrationAgentDocumentsCommonInputDto, ApiBlobResponse>(
      `${CONTROLLER_NAME}/statement`,
      model,
      { responseType: "blob" },
    ),
  sendCode: (model: PublicUserRegistrationAgentDocumentsCommonInputDto) =>
    publicClientV4.post<PublicUserRegistrationAgentDocumentsCommonInputDto, SendCodePublicUserRegistrationOutputDto>(
      `${CONTROLLER_NAME}/send-code`,
      model,
    ),
  sign: (model: SignPublicUserRegistrationInputDto) =>
    publicClientV4.post<SignPublicUserRegistrationInputDto, EmptyOutputDto>(`${CONTROLLER_NAME}/sign`, model),
} satisfies ApiObject;
