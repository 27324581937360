import { PersonFounder } from "../../../types/companies";

export const getProductDetailsInformationClientFounderRole = (founder: PersonFounder) => {
  if (founder.isSigner) {
    return "Подписант по доверенности";
  }

  if (founder.isDirector) {
    return "Руководитель";
  }

  return "Учредитель";
};
