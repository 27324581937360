import { useEffect, useMemo, useState } from "react";
import { useApiMutation } from "../../../../common/hooks";
import { getBriefCreditWorkingAssetsCreation } from "../../store/creditWorkingAssetsCreation.thunks";
import { CreditWorkingAssetsId } from "../../../../common/types/demand";
import { CreditWorkingAssetsBase } from "../../../common/types";

export const useCreditWorkingAssetsCreationScoringBriefData = (creditWorkingAssetsId: CreditWorkingAssetsId) => {
  const [data, setData] = useState<CreditWorkingAssetsBase | undefined>(undefined);
  const { status, mutate } = useApiMutation(getBriefCreditWorkingAssetsCreation, {
    errorMessagePlaceholder: "",
  });

  useEffect(() => {
    const func = async () => {
      const result = await mutate({ creditWorkingAssetsId });
      setData(result?.body);
    };

    func();
  }, [creditWorkingAssetsId, mutate]);

  return useMemo(() => ({ data, status }), [data, status]);
};
