import { useEffect, useMemo, useState } from "react";

export const useShowMoreText = (
  text: string,
  maxLength: number,
  endSymbol = "…"
): {
  text: string;
  trimmed: boolean;
  isOpened: boolean;
  setIsOpened: (isOpen: boolean) => void;
} => {
  const [isOpened, setIsOpened] = useState(false);
  const [trimmedText, setTrimmedText] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (text.length > maxLength) {
      setTrimmedText(text.slice(0, maxLength - 1) + endSymbol);
    } else {
      setTrimmedText(undefined);
    }
  }, [endSymbol, maxLength, text]);

  return useMemo(
    () => ({
      text: isOpened ? text : trimmedText ?? text,
      setIsOpened,
      isOpened,
      trimmed: trimmedText !== undefined,
    }),
    [isOpened, text, trimmedText]
  );
};
