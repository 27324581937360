import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { GuaranteeCalculatorForManagingDirectorOfAgentForm } from "./GuaranteeCalculatorForManagingDirectorOfAgentForm/GuaranteeCalculatorForManagingDirectorOfAgentForm";
import { useEffect } from "react";
import { ScoringFormValues } from "./GuaranteeCalculatorForManagingDirectorOfAgentForm/data/initialValues";
import { fetchScoringOptions, scoringDataProcessed } from "../../../../store/actions/scoring/scoringActions";
import { FormLayout } from "../../../../ui/layouts/form/FormLayout";
import { GuaranteeCalculatorForManagingDirectorOfAgentBankResults } from "./scoring-bank-results/GuaranteeCalculatorForManagingDirectorOfAgentBankResults";
import Loader from "../../../../components/common/Loader";
import RequestStatus from "../../../../entity/models/enums/RequestStatus";
import InfoBanner, { InfoBannerStyle } from "../../../../components/common/info-banner/InfoBanner";
import { calculatorModelMapper } from "./GuaranteeCalculatorForManagingDirectorOfAgentForm/data/mappers";
import { RootState, useAppDispatch } from "../../../../domains/common/store";
import { usePageTitle } from "../../../../domains/common/navigation/page-title";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const GuaranteeCalculatorForManagingDirectorOfAgent = () => {
  const dispatch = useAppDispatch();
  const { scoringData, scoringDataProcessingStatus, scoringOptionsStatus } = useSelector(
    (state: RootState) => state.scoring
  );

  usePageTitle("Калькулятор банковской гарантии для УДА");

  useEffect(() => {
    dispatch(fetchScoringOptions());
  }, [dispatch]);

  const onSend = (values: ScoringFormValues) => {
    const requestBody = calculatorModelMapper(values);
    dispatch(scoringDataProcessed(requestBody));
  };

  return (
    <Wrapper>
      {scoringOptionsStatus === RequestStatus.Request && <Loader />}
      {scoringOptionsStatus === RequestStatus.Failure && (
        <InfoBanner text="Не удалось загрузить список параметров" style={InfoBannerStyle.Default} fontSize={16} />
      )}
      <FormLayout>
        <GuaranteeCalculatorForManagingDirectorOfAgentForm onSend={onSend} />
      </FormLayout>
      {scoringData && scoringDataProcessingStatus === RequestStatus.Success && (
        <GuaranteeCalculatorForManagingDirectorOfAgentBankResults banks={scoringData.scoringResults} />
      )}
    </Wrapper>
  );
};
