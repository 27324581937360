import { enToRuLayout } from "@sideg/helpers";

export const bankSearchTransformer = (search: string): string => {
  const enToRu = enToRuLayout(search);
  if (enToRu === search) {
    return search;
  }

  const lowerSearch = search.toLowerCase();
  if (lowerSearch === "sbi" || lowerSearch === "sib") {
    return search;
  }

  return enToRu;
};
