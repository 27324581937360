import { DemandQueueTaskComment, DemandQueueTaskCommentProps } from "./DemandQueueTaskComment";
import { ShortFile } from "../../../../common/types/files";
import { DemandQueueTaskSeparator } from "../separator/DemandQueueTaskSeparator";
import { DemandQueueTaskAttachedFilesCollapse } from "../attached-file/DemandQueueTaskAttachedFilesCollapse";

export interface DemandQueueTaskCommentWithFilesProps extends DemandQueueTaskCommentProps {
  files?: ShortFile[];
}

export const DemandQueueTaskCommentWithFiles = ({
  files,
  text,
  date,
  author,
}: DemandQueueTaskCommentWithFilesProps) => {
  return (
    <>
      <DemandQueueTaskComment author={author} text={text} date={date} />
      {files && files.length > 0 && (
        <>
          <DemandQueueTaskSeparator />
          <DemandQueueTaskAttachedFilesCollapse files={files} />
        </>
      )}
    </>
  );
};
