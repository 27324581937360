import styled from "@emotion/styled";
import { FilterDropdownButton } from "projects/agent/src/domains/common/ui-kit/form-controls/select";
import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";

export const Header = styled(AppLayoutResponsiveListPage.Header)`
  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding-left: calc(${({ theme }) => theme.layout.borderPadding} + 40px);
  }
`;

export const DropdownButton = styled(FilterDropdownButton)`
  &:hover:not(:disabled) {
    box-shadow: none;
  }
` as typeof FilterDropdownButton;
