import styled from "@emotion/styled";
import { Box } from "../../box";

const Badge = styled.div`
  background-color: ${({ theme }) => theme.palette.fileLabel.default};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

export const BadgeContainer = Box.withComponent(Badge);
