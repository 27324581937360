import { combineSliceNames } from "../../../common/store/utils";
import { QUEUE_TASKS_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { queueTasksListApi } from "../api/dto/queueTasksListApi";

export const QUEUE_TASKS_LIST_SLICE_NAME = combineSliceNames(QUEUE_TASKS_SLICE_NAME, "list");

export const {
  get: getQueueTasksList,
  getFilters: getQueueTasksListFilters,
  getCounters: getQueueTasksListCounters,
} = createApiThunks(QUEUE_TASKS_LIST_SLICE_NAME, queueTasksListApi);
