import { memo, useMemo } from "react";
import { getEmptyArray } from "@sideg/helpers";
import { WidgetsNewsSliderDotButton } from "./WidgetsNewsSliderDotButton";
import { BaseCssProps } from "../../../../ui-kit/types";
import * as S from "./WidgetsNewsSliderDotButtons.styled";

export interface WidgetsNewsSliderDotButtonsProps extends BaseCssProps {
  count: number;
  activeIndex: number;
  onButtonClick: (index: number) => void | Promise<void>;
}

export const WidgetsNewsSliderDotButtons = memo(
  ({ count, activeIndex, onButtonClick, className }: WidgetsNewsSliderDotButtonsProps) => {
    const elements = useMemo(() => getEmptyArray(count), [count]);
    const onClick = (index: number) => () => {
      onButtonClick(index);
    };

    return (
      <S.Container className={className}>
        {elements.map((x) => (
          <WidgetsNewsSliderDotButton key={x} isActive={x === activeIndex} onClick={onClick(x)} />
        ))}
      </S.Container>
    );
  },
);
