import styled from "@emotion/styled";

export const Button = styled.button`
  appearance: none;
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: none;
  border: 2px solid transparent;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  padding: 0;
  margin: 0;

  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  & > svg {
    width: ${({ theme }) => theme.font.fz4};
    height: ${({ theme }) => theme.font.fz4};
    transform: rotate(90deg);

    & > path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;
