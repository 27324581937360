import { Form, Formik } from "formik";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { BankGuaranteeRequestOriginalCreationFormValues } from "../../../types/BankGuaranteeRequestOriginalCreationFormValues";
import { BlockResponsiveSubmitButton } from "../../../../../common/ui-kit/buttons/block-responsive-submit";
import { bankGuaranteeRequestOriginalCreationFormValidationSchema } from "../../../validation/bankGuaranteeRequestOriginalCreationFormValidationSchema";
import { DemandId } from "../../../../../common/types/demand";
import { createBankGuaranteeRequestOriginal } from "../../../store/bankGuaranteeRequestOriginalCreation.thunks";
import { useApiMutation } from "../../../../../common/hooks";
import { BankGuaranteeRequestOriginalCreationFormContent } from "./BankGuaranteeRequestOriginalCreationFormContent";
import {
  CreateDeliveryBankGuaranteeRequestOriginalCreationInputDto,
  CreateOfficeBankGuaranteeRequestOriginalCreationInputDto,
} from "../../../api/dto/input/СreateBankGuaranteeRequestOriginalCreationInputDto";

const getInputDto = <TForm extends BankGuaranteeRequestOriginalCreationFormValues>(
  formValues: TForm,
  demandId: DemandId
):
  | CreateOfficeBankGuaranteeRequestOriginalCreationInputDto
  | CreateDeliveryBankGuaranteeRequestOriginalCreationInputDto => {
  if (formValues.deliveryType === "Office") {
    return {
      receivingWay: "Office",
      recipientOfficeType: formValues.officeRecipientType,
      comment: formValues.comment,
      demandId,
    };
  }

  return {
    receivingWay: "Delivery",
    comment: formValues.comment,
    demandId,
    email: formValues.email,
    fullName: [formValues.surname, formValues.name, formValues.patronymic]
      .filter((x) => x !== undefined)
      .join(" ")
      .trim(),
    phone: formValues.phone ?? "",
    address: formValues.address,
    recipient: formValues.recipient,
  };
};

const BoxForm = Box.withComponent(Form);

interface BankGuaranteeRequestOriginalCreationFormProps {
  demandId: DemandId;
  onSuccess: () => void;
}

export const BankGuaranteeRequestOriginalCreationForm = ({
  demandId,
  onSuccess,
}: BankGuaranteeRequestOriginalCreationFormProps) => {
  const { error: createRequestOriginalError, mutate: createRequestOriginalMutate } = useApiMutation(
    createBankGuaranteeRequestOriginal,
    {
      errorMessagePlaceholder:
        "Произошла непредвиденная ошибка. Попробуйте еще раз или свяжитесь с персональным менеджером",
    }
  );

  const handleFormSubmit = async (values: BankGuaranteeRequestOriginalCreationFormValues) => {
    const castedValues = bankGuaranteeRequestOriginalCreationFormValidationSchema.cast(
      values
    ) as BankGuaranteeRequestOriginalCreationFormValues;

    const result = await createRequestOriginalMutate(getInputDto(castedValues, demandId));
    if (result?.body) {
      onSuccess();
    }
  };

  return (
    <Formik
      initialValues={
        bankGuaranteeRequestOriginalCreationFormValidationSchema.getDefault() as BankGuaranteeRequestOriginalCreationFormValues
      }
      validationSchema={bankGuaranteeRequestOriginalCreationFormValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {(form) => (
        <BoxForm
          display="flex"
          flexDirection="column"
          gap="lg"
          flexGrow={1}
          padding={{ xs: "lg", xl: "none" }}
          noValidate
        >
          <BankGuaranteeRequestOriginalCreationFormContent demandId={demandId} />
          <BlockResponsiveSubmitButton type="submit" isLoading={form.isSubmitting} error={createRequestOriginalError}>
            Отправить
          </BlockResponsiveSubmitButton>
        </BoxForm>
      )}
    </Formik>
  );
};
