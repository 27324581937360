import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { BankGuaranteeId, DemandId } from "../../../../../../../common/types/demand";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import { useAppSelector } from "../../../../../../../common/store";
import { selectBankGuaranteeListItemsDemandById } from "../../../../../store/items/bankGuaranteeListItems.selectors";
import { Text } from "../../../../../../../common/ui-kit/typography";
import { StackPanel } from "../../../../../../../common/ui-kit/containers/stack-panel";
import { CircleIndicator } from "../../../../../../../common/ui-kit/notifications/circle-indicator";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { FileDownloadLabel } from "../../../../../../../files/download";
import { BankGuaranteeListItemReissueButton } from "../../buttons/BankGuaranteeListItemReissueButton";
import { BankGuaranteeListItemRequestOriginalButton } from "../../buttons/BankGuaranteeListItemRequestOriginalButton";
import {
  BankGuaranteeExtractFromRegistryButtonMobile,
  BankGuaranteeExtractFromRegistryFileLabelMobile,
} from "../../../../../../extract-from-registry";
import { BankGuaranteeRequestOriginalLabelLink } from "../../../../../../request-original";
import { useBankGuaranteeListItemTransferPayloadOnClick } from "../../../../../hooks/list/useBankGuaranteeListItemTransferPayloadOnClick";

export interface BankGuaranteeListItemMobileIssueProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  from?: NavigationState;
}

export const BankGuaranteeListItemMobileIssue = ({
  guaranteeId,
  demandId,
  from,
}: BankGuaranteeListItemMobileIssueProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectBankGuaranteeListItemsDemandById(state, demandId))!;
  const onRequestOriginalLinkClick = useBankGuaranteeListItemTransferPayloadOnClick(guaranteeId);

  return (
    <>
      <StackPanel direction="row" gap="sm" alignItems="center">
        <Text fontSize="fz3" fontWeight={700} lineHeight="defaultLineHeight" fontColor="success">
          Банковская гарантия выпущена
        </Text>
        {demand.isUpdated && <CircleIndicator color={(x) => x.secondaryColors.notificationBackground} size={8} />}
      </StackPanel>
      {demand.approval && (
        <>
          <Text fontSize="fz3" fontWeight={500} lineHeight="defaultLineHeight" fontColor="primary">
            {demand.bank.title}&nbsp;—&nbsp;
            {demand.approval.issueDate &&
              dateTimeHelper.format(new Date(demand.approval.issueDate), specialFormatters.dayFullMonthYearIfDifferent)}
          </Text>
          <Box py="sm" display="flex" flexDirection="column" gap="sm" alignItems="flex-start">
            {demand.approval.original && (
              <FileDownloadLabel
                fileId={demand.approval.original.fileId}
                privateKey={demand.approval.original.privateKey}
                fileName="Скан-копия гарантии"
              />
            )}
            {demand.approval.bankDocument && (
              <FileDownloadLabel
                fileId={demand.approval.bankDocument.fileId}
                privateKey={demand.approval.bankDocument.privateKey}
                fileName="Доверенность / лицензия"
              />
            )}
            {demand.approval.fileRegistryStatus && (
              <BankGuaranteeExtractFromRegistryFileLabelMobile
                demandId={demandId}
                statusType={demand.approval.fileRegistryStatus.code}
              />
            )}
          </Box>
          <Box py="md" display="flex" flexDirection="column" gap="lg" alignItems="flex-start">
            {demand.approval.fileRegistryStatus && (
              <BankGuaranteeExtractFromRegistryButtonMobile
                guaranteeId={guaranteeId}
                demandId={demandId}
                statusType={demand.approval.fileRegistryStatus.code}
              />
            )}
            <BankGuaranteeListItemReissueButton guaranteeId={guaranteeId} demandId={demandId} />
            {demand.approval.requestOriginalStatus?.code !== undefined && (
              <BankGuaranteeRequestOriginalLabelLink
                demandId={demandId}
                guaranteeId={guaranteeId}
                requestOriginalType={demand.approval.requestOriginalStatus.code}
                from={from}
                onClick={onRequestOriginalLinkClick}
                isWithNotification={demand.approval.requestOriginalStatus.code === "Rework"}
              />
            )}
            {demand.approval.requestOriginalStatus?.code === undefined && (
              <BankGuaranteeListItemRequestOriginalButton guaranteeId={guaranteeId} demandId={demandId} />
            )}
          </Box>
        </>
      )}
    </>
  );
};
