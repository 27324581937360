import { useEffect } from "react";
import { Provider } from "react-redux";
import { ResponsiveProvider, ThemeProvider } from "@sideg/ui-kit";
import { checkRefQueryParams } from "./essentials/helpers";
import { AppRouter } from "./domains/common/routes";
import { YandexAnalyticsInitializer, YandexAnalyticsPageViewHitListener } from "./domains/common/analytics/components";
import { GlobalStyles } from "./domains/common/ui/layout/global";
import { ServiceWorkerProvider } from "./domains/common/service-worker";
import { ApplicationUpdater } from "./domains/common/application-updater";
import { store } from "./domains/common/store";
import { AuthorizedUserAuthenticationListener } from "./domains/common/authorized-user";
import { WebPushesSubscriptionProvider } from "./domains/common/web-pushes";
import { theme } from "./domains/common/ui-kit";

export const App = () => {
  useEffect(() => {
    checkRefQueryParams();
  }, []);

  return (
    <Provider store={store}>
      <AuthorizedUserAuthenticationListener />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ResponsiveProvider>
          <ServiceWorkerProvider>
            <WebPushesSubscriptionProvider>
              <AppRouter>
                <ApplicationUpdater />
                <YandexAnalyticsPageViewHitListener />
              </AppRouter>
            </WebPushesSubscriptionProvider>
          </ServiceWorkerProvider>
        </ResponsiveProvider>
      </ThemeProvider>
      <YandexAnalyticsInitializer />
    </Provider>
  );
};
