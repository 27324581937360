import { useAppLayoutAsideRenderState } from "../../../app-layout-containers/hooks/useAppLayoutAsideRenderState";
import { AppSidebar } from "../sidebar/AppSidebar";
import { useAppLayout } from "../../../app-layout-containers";
import { ManufacturerBrandLink } from "../../../../manufacturer/brand";
import * as S from "./AppLayoutContent.styled";

export const AppLayoutContentAside = () => {
  const { isMenuOpened } = useAppLayout();
  const needRenderAside = useAppLayoutAsideRenderState();

  return (
    needRenderAside && (
      <S.Aside sidebarOpened={isMenuOpened.value}>
        <AppSidebar />
        <S.AsideFooter>
          <ManufacturerBrandLink />
        </S.AsideFooter>
      </S.Aside>
    )
  );
};
