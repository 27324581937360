import { usePageTitle } from "../../../common/navigation/page-title";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { ReportsDemand } from "../components/ReportsDemand";

export const ReportsDemandPage = () => {
  usePageTitle("Отчёт по заявкам");

  return (
    <AppLayoutResponsiveListPage backgroundColor="light">
      <AppLayoutResponsiveListPage.Header>
        <AppLayoutResponsiveListPage.Title>Отчёт по заявкам</AppLayoutResponsiveListPage.Title>
      </AppLayoutResponsiveListPage.Header>
      <AppLayoutResponsiveListPage.List>
        <ReportsDemand />
      </AppLayoutResponsiveListPage.List>
    </AppLayoutResponsiveListPage>
  );
};
