import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getObjectKeysDeep } from "../../utils/getAllKeys";

/**
 * Помечаем поля формика как touched, если по ним есть ошибки при сабмите
 * @description https://github.com/jaredpalmer/formik/issues/445#issuecomment-677592750
 */
export const useCreationFormFieldTouchedEffect = () => {
  const { errors, setFieldTouched, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const errorKeys = getObjectKeysDeep(errors);
      errorKeys.forEach((key) => {
        setFieldTouched(key, true, false);
      });
    }
  }, [errors, isSubmitting, isValidating, setFieldTouched]);
};
