import { createSlice, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../../common/store/utils";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import {
  DemandQueueTaskCompleted,
  demandQueueTasksAdapter,
  getHistoryDemandQueueTasks,
} from "../../../../demand/queue-tasks";
import {
  CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME,
  creditWorkingAssetsDetailsActiveDemandChanged,
  creditWorkingAssetsDetailsActiveProductChanged,
} from "../creditWorkingAssetsDetails.actions";
import { CreditWorkingAssetsDetailsTasksHistorySliceState } from "./creditWorkingAssetsDetailsTasksHistory.types";
import { isCreditWorkingAssetsDetailsNeedReloadMatcher } from "../creditWorkingAssetsDetails.matchers";

const SLICE_NAME = combineSliceNames(CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME, "tasksHistory");

const initialState = demandQueueTasksAdapter.getInitialState<CreditWorkingAssetsDetailsTasksHistorySliceState>({
  status: LoadingStatusEnum.Idle,
  activeDemandId: undefined,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    addNewCompletedTask: (state, action: PayloadAction<DemandQueueTaskCompleted>) => {
      demandQueueTasksAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(creditWorkingAssetsDetailsActiveProductChanged, () => initialState)
      .addCase(creditWorkingAssetsDetailsActiveDemandChanged, (state, action) => {
        return { ...initialState, activeDemandId: action.payload };
      })
      .addCase(getHistoryDemandQueueTasks.fulfilled, (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          if (action.payload.body.paginationInformation?.pageNumber === 1) {
            demandQueueTasksAdapter.setAll(state, action.payload.body.data ?? []);
          } else {
            demandQueueTasksAdapter.upsertMany(state, action.payload.body.data ?? []);
          }
          state.pagination = action.payload.body.paginationInformation;
        }
      })
      .addMatcher(isCreditWorkingAssetsDetailsNeedReloadMatcher, (state) => {
        state.status = LoadingStatusEnum.Idle;
        state.pagination = undefined;
      })
      .addMatcher(isAsyncThunkAction(getHistoryDemandQueueTasks), (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          const status = getLoadingStatusFromAction(action);
          if (status !== LoadingStatusEnum.Aborted) {
            state.status = status;
          }
        }
      });
  },
});

export const creditWorkingAssetsDetailsTasksHistoryReducer = slice.reducer;

export const { addNewCompletedTask: addNewCompletedTaskCreditWorkingAssetsDetailsTasksHistory } = slice.actions;
