import { currencyHelper } from "@sideg/helpers";
import { ProductDetailsHeaderDemandChip } from "../../../common/product-details";
import { CreditWorkingAssetsDetailsDemand } from "../types/CreditWorkingAssetsDetailsDemand";

export const getCreditWorkingAssetsDemandChips = (
  demand: CreditWorkingAssetsDetailsDemand,
): ProductDetailsHeaderDemandChip[] => {
  if (demand.isTerminated) {
    return [{ type: "danger", text: demand.status.title ?? "" }];
  }

  const chips: ProductDetailsHeaderDemandChip[] = [];
  chips.push({
    type: "info",
    text: `Запрошенная сумма: ${currencyHelper.getCurrencyString(demand.creditSum)}`,
  });

  if (demand.confirmedCreditSum !== undefined) {
    chips.push({
      type: "success",
      text: `Одобрено ${currencyHelper.getCurrencyString(demand.confirmedCreditSum)}`,
    });
  }

  return chips;
};
