import * as React from "react";
import { InferType } from "yup";
import { ProductDetailsLayoutWrapper } from "../../../common/product-details";
import { useFullPageLayout } from "../../../common/ui/layout";
import { useScrollToTopOnMountOrLocationPathChange } from "../../../common/hooks/scroll";
import { CreditWorkingAssetsDetailsNavigationHeader } from "../component/navigation-header/CreditWorkingAssetsDetailsNavigationHeader";
import { CreditWorkingAssetsDetailsContext } from "../store/context/CreditWorkingAssetsDetailsContext";
import { useAppUrlMatch } from "../../../common/urls/internal/hooks";
import { CreditWorkingAssetsDetailsAside } from "../component/aside/CreditWorkingAssetsDetailsAside";
import { useCreditWorkingAssetsDetailsFetcher } from "../hooks/useCreditWorkingAssetsDetailsFetcher";
import { withValidUrlParams } from "../../../common/urls/internal/HOCs";
import { creditWorkingAssetsDetailsUrlValidationSchema } from "../validation/creditWorkingAssetsDetailsUrlValidationSchema";
import { Redirect } from "../../../common/urls/internal/components";
import { useCreditWorkingAssetsDetailsMatchListener } from "../hooks/context/useCreditWorkingAssetsDetailsMatchListener";
import { useCreditWorkingAssetsDetailsQueueTasksNeedUpdateListener } from "../hooks/useCreditWorkingAssetsDetailsQueueTasksNeedUpdateListener";

export interface CreditWorkingAssetsDetailsPageLayoutProps {
  children?: React.ReactNode;
  match: InferType<typeof creditWorkingAssetsDetailsUrlValidationSchema>;
}

const Layout = ({ children, match }: CreditWorkingAssetsDetailsPageLayoutProps) => {
  useFullPageLayout();
  useScrollToTopOnMountOrLocationPathChange();

  const { isExact: isRootPath } = useAppUrlMatch((x) => x.creditWorkingAssets.details.product, {
    creditWorkingAssetsId: match.creditWorkingAssetsId,
  });

  useCreditWorkingAssetsDetailsMatchListener(match);
  useCreditWorkingAssetsDetailsFetcher(match.creditWorkingAssetsId);
  useCreditWorkingAssetsDetailsQueueTasksNeedUpdateListener(match.creditWorkingAssetsId);

  return (
    <CreditWorkingAssetsDetailsContext.Provider value={match}>
      <CreditWorkingAssetsDetailsNavigationHeader />
      <ProductDetailsLayoutWrapper aside={<CreditWorkingAssetsDetailsAside />} isRootPath={isRootPath}>
        {children}
      </ProductDetailsLayoutWrapper>
    </CreditWorkingAssetsDetailsContext.Provider>
  );
};

export const CreditWorkingAssetsDetailsPageLayout = withValidUrlParams(
  Layout,
  (x) => x.creditWorkingAssets.details.demand,
  creditWorkingAssetsDetailsUrlValidationSchema,
  <Redirect to={(x) => x.creditWorkingAssets.list.root} />
);
