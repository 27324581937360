import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { updateWidgetNews } from "../store/widgetsNews.slice";
import { widgetsNewsStorageService } from "../services/widgetsNewsStorageService";

export const useWidgetsNewsCacheUpdater = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (widgetsNewsStorageService.isCacheExpired()) {
      dispatch(updateWidgetNews());
    }
  }, [dispatch, location]);
};
