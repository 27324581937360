/* eslint-disable react/no-children-prop */
import * as React from "react";
import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { DocumentUploadActiveDocumentContainer } from "./DocumentUploadActiveDocumentContainer";

export interface DocumentUploadActiveDocumentSkeletonProps extends BaseCssProps {
  children?: React.ReactNode;
  isWithBadge?: boolean;
  isWithTemplate?: boolean;
}

export const DocumentUploadActiveDocumentSkeleton = ({
  className,
  children,
  isWithBadge,
  isWithTemplate = true,
}: DocumentUploadActiveDocumentSkeletonProps) => {
  return (
    <DocumentUploadActiveDocumentContainer
      children={children}
      className={className}
      title={<Skeleton inline count={1.7} />}
      badge={
        isWithBadge && (
          <>
            &nbsp; &nbsp;
            <Skeleton inline width={40} />
          </>
        )
      }
      description={<Skeleton />}
      template={isWithTemplate ? <Skeleton width={120} /> : undefined}
      uploader={<Skeleton height={50} />}
    />
  );
};
