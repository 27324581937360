import styled from "@emotion/styled";
import { SpaceType } from "../../../types";

export const SELECT_POPUP_CONTAINER_ITEM_GAP: SpaceType = "sm";

export const SELECT_POPUP_CONTAINER_ITEM_ICON_SIZE_PX = 20;

export const SelectPopupContainerItem = styled.li<{ active: boolean; highlighted?: boolean }>`
  padding: ${({ theme }) => theme.spaces.sm};
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  background: ${({ theme, highlighted, active }) =>
    // eslint-disable-next-line no-nested-ternary
    highlighted
      ? !active
        ? theme.palette.control.secondaryLight
        : theme.palette.control.primaryLight
      : "transparent"};
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.typography.primary};
  font-weight: 500;
  white-space: nowrap;
  user-select: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces[SELECT_POPUP_CONTAINER_ITEM_GAP]};

  &:hover,
  &:focus {
    background-color: ${({ theme, active }) =>
      !active ? theme.palette.control.secondaryLight : theme.palette.control.primaryLight};
  }

  & > svg {
    height: ${SELECT_POPUP_CONTAINER_ITEM_ICON_SIZE_PX}px;
    width: ${SELECT_POPUP_CONTAINER_ITEM_ICON_SIZE_PX}px;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
