import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../../common/types/utils";

const titles = {
  details: "Данные заявки",
  scoring: "Выбор банков",
};

export const creditExecutionCreationCreateByExistsPageType = makeEnumLikeLiteral(
  ["details", "scoring"] as const,
  titles,
);

export type CreditExecutionCreationCreateByExistsPageType = EnumLikeLiteralType<
  typeof creditExecutionCreationCreateByExistsPageType
>;
