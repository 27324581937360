import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;

  &::after {
    content: "";
    flex: 1;
  }
`;

export const Header = styled.header`
  background-color: ${({ theme }) => theme.layout.demand.colors.rightSideBackgroundColor};
  padding: ${({ theme }) => theme.spaces.xl};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    background-color: #fff;
    padding: ${({ theme }) => theme.spaces.lg} ${({ theme }) => theme.layout.borderPadding};
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  }
`;
