import styled from "@emotion/styled";
import { ElementColor, getElementColor } from "../../../../../../../ui-kit/types";

export const ProductListItemBadgeMobile = styled.span<{ backgroundColor: ElementColor }>`
  padding: 0 0.625rem;
  border-radius: 1rem;
  font-weight: 700;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  background-color: ${({ theme, backgroundColor }) => getElementColor(backgroundColor, theme)};
`;
