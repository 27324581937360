import styled from "@emotion/styled";
import { CheckboxSize, CheckboxSizeType } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";

export const checkboxSizes: CheckboxSizeType<"width"> = {
  small: "18px",
  default: "24px",
};

const checkboxOutlineSizes: CheckboxSizeType<"outline"> = {
  small: "3px",
  default: "4px",
};

const checkboxOutlineFocusSizes: CheckboxSizeType<"outline"> = {
  small: "2px",
  default: "4px",
};

export const Label = styled.label<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.sm};
  align-items: center;
  justify-content: flex-start;
  vertical-align: middle;
  outline: none;

  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
`;

export const CheckboxInputContainer = styled.span<{ checkboxSize: CheckboxSize }>`
  min-height: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  min-width: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  line-height: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  position: relative;
  border-radius: 2px;
  z-index: 1;

  @supports selector(:has(*)) {
    &:has(input:focus-visible) {
      outline: 1px solid ${({ theme }) => theme.palette.light.primary.fill.pressed};
      outline-offset: ${({ checkboxSize }) => checkboxOutlineFocusSizes[checkboxSize]};
    }
  }

  @supports not selector(:has(*)) {
    &:focus-within {
      outline: 1px solid ${({ theme }) => theme.palette.light.primary.fill.pressed};
      outline-offset: ${({ checkboxSize }) => checkboxOutlineFocusSizes[checkboxSize]};
    }
  }
`;

export const CheckboxInput = styled.input<{ checkboxSize: CheckboxSize }>`
  appearance: none;
  border: 1.5px solid ${({ theme }) => theme.palette.light.neutral.text.secondary};
  border-radius: 2px;
  height: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  width: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  left: 0;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: -1;

  &:checked {
    appearance: none;
  }

  &:checked ~ svg {
    display: flex;
  }

  &:hover:enabled {
    outline: ${({ checkboxSize }) => checkboxOutlineSizes[checkboxSize]} solid
      ${({ theme }) => theme.palette.light.primary.tonal.default};
    outline-offset: 0;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.light.neutral.tonal};
    border-color: ${({ theme }) => theme.palette.light.neutral.outline.secondary};
    cursor: default;
  }

  &:disabled:checked {
    cursor: default;
  }

  &:disabled:hover {
    outline: none;
  }
`;

export const Icon = styled.svg<{ checkboxSize: CheckboxSize }>`
  height: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  width: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  display: none;
  position: absolute;
  left: 0;
  top: 0;
`;

export const Path = styled.path`
  fill: ${({ theme }) => theme.palette.light.primary.text.inverse};
`;

export const Rect = styled.rect<{ isDisabled?: boolean }>`
  height: 100%;
  width: 100%;
  fill: ${({ theme, isDisabled }) =>
    isDisabled ? theme.palette.light.neutral.outline.primary : theme.palette.light.primary.text.default};
`;
