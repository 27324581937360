import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.md};
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: 2;
  color: ${({ theme }) => theme.palette.typography.primary};

  & > dt {
    font-weight: 400;
    flex-basis: min(160px, 50%);
    flex-grow: 0;
    flex-shrink: 0;
  }

  & > dd {
    font-weight: 500;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;
