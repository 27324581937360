import styled from "@emotion/styled";
import { getAsideListItemCommonStyles } from "../../../../common/ui/layout/sidebar/components/aside-block-mobile/AsideBlockListItem.styled";

export const ButtonWrapper = styled.div(({ theme }) => getAsideListItemCommonStyles(theme));

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.layout.sidebar.mobile.counterBackgroundColor};
  border-radius: 4px;
`;

export const ManagerName = styled.p`
  color: ${({ theme }) => theme.layout.sidebar.mobile.textColor};
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.lg};
  margin-top: ${({ theme }) => theme.spaces.md};
  border-top: 1px solid ${({ theme }) => theme.layout.sidebar.mobile.dividerColor};
  padding-top: ${({ theme }) => theme.spaces.md};
`;

export const Li = styled.li(({ theme }) => getAsideListItemCommonStyles(theme));
