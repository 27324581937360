const isValidNumber = (number: number | undefined | null): number is number => {
  return number !== null && number !== undefined && !Object.is(NaN, number);
};

const getFromString = (value: string): number => Number(value);

const round = (value: number, decimalPlaces = 2): number => {
  return getFromString(value.toFixed(decimalPlaces));
};

const isPositiveInteger = (value: string) => {
  const number = Math.floor(getFromString(value));

  return number !== Infinity && String(number) === value && number >= 0;
};

const getFormattedDecimal = (
  value: number,
  options?: {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
  },
) => {
  return value.toLocaleString("ru-RU", {
    style: "decimal",
    minimumFractionDigits: options?.minimumFractionDigits,
    maximumFractionDigits: options?.maximumFractionDigits,
  });
};

const parseInt = (value: string) => {
  return Number.parseInt(value, 10);
};

export const numberHelper = {
  isValidNumber,
  round,
  isPositiveInteger,
  getFromString,
  getFormattedDecimal,
  parseInt,
};
