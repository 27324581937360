import * as React from "react";
import { selectAppUrl } from "../../urls/internal/utils";
import { AppLayoutRoute, AppRoute, AppRouteUserRoleElement } from "../types/AppRoute";
import { AppUrlSelector } from "../../urls/internal/types";

export const makeAppRoute = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selector: AppUrlSelector<any, any>,
  children: React.ReactNode,
  nested?: (AppRoute | AppLayoutRoute)[],
  permissionElement?: AppRouteUserRoleElement
): AppRoute => {
  const appUrl = selectAppUrl(selector);

  return {
    url: appUrl,
    element: children,
    nested,
    permissionElement,
  };
};
