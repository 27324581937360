/* eslint-disable class-methods-use-this */
import { BankGuaranteeDetailsFilterContextType } from "../../types/filters/BankGuaranteeDetailsFilterContextType";
import { QueueTaskId } from "../../../../common/types/demand";
import { GuaranteeQueueTasksGroupType } from "../../../../common/types/queue-tasks";
import { BankGuaranteeDetailsDemandHeaderTab } from "../../types/demand/BankGuaranteeDetailsDemandHeaderTab";

export class BankGuaranteeDetailsFilterMutationService {
  private filteringObject: BankGuaranteeDetailsFilterContextType;

  constructor(filteringObject: BankGuaranteeDetailsFilterContextType) {
    this.filteringObject = filteringObject;
  }

  public changeTab = (tab: BankGuaranteeDetailsDemandHeaderTab): Partial<BankGuaranteeDetailsFilterContextType> => {
    return { tab, queueTaskId: undefined };
  };

  public removeScroll = (): Partial<BankGuaranteeDetailsFilterContextType> => {
    return { containerToScroll: undefined };
  };

  /**
   * Задать id задачи, но не скроллить к ней. Скролл через
   * @see scrollToActiveQueueTaskId
   */
  public setQueueTaskId = (
    queueTaskId: QueueTaskId,
    taskGroupType: GuaranteeQueueTasksGroupType,
  ): Partial<BankGuaranteeDetailsFilterContextType> => {
    const isCorrectGroup =
      this.filteringObject.queueTaskGroup === taskGroupType || this.filteringObject.queueTaskGroup === "All";

    return {
      tab: "tasks",
      containerToScroll: "task",
      queueTaskId,
      queueTaskGroup: isCorrectGroup ? this.filteringObject.queueTaskGroup : "All",
    };
  };

  /**
   * Скролл к задаче, уже имеющейся в url
   * @see useBankGuaranteeDetailsFilterScrollToQueueTask
   */
  public scrollToActiveQueueTaskId = (): Partial<BankGuaranteeDetailsFilterContextType> => {
    if (this.filteringObject.queueTaskId !== undefined) {
      return { containerToScroll: "task" };
    }

    return {};
  };

  public changeGroupType = (
    groupType: GuaranteeQueueTasksGroupType,
  ): Partial<BankGuaranteeDetailsFilterContextType> => {
    return {
      queueTaskGroup: groupType,
      tab: "tasks",
      queueTaskId: undefined,
    };
  };

  public scrollToNewComment = (
    groupType: GuaranteeQueueTasksGroupType,
  ): Partial<BankGuaranteeDetailsFilterContextType> => {
    const diff = this.changeGroupType(groupType);
    diff.isNewCommentOpened = true;
    diff.containerToScroll = "comment";

    return diff;
  };

  public scrollToHistory = (
    groupType: GuaranteeQueueTasksGroupType,
  ): Partial<BankGuaranteeDetailsFilterContextType> => {
    const diff = this.changeGroupType(groupType);
    diff.containerToScroll = "history";

    return diff;
  };

  public setNewCommentFormOpen = (): Partial<BankGuaranteeDetailsFilterContextType> => {
    return {
      isNewCommentOpened: true,
    };
  };

  public setNewCommentFormClosed = (): Partial<BankGuaranteeDetailsFilterContextType> => {
    return {
      isNewCommentOpened: false,
    };
  };
}
