import * as React from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import * as S from "./BankGuaranteeRewardsBanksListFilterCard.desktop.styled";

export interface BankGuaranteeRewardsBanksListFilterCardDesktopProps extends BaseCssProps {
  children: React.ReactNode;
}

export const BankGuaranteeRewardsBanksListFilterCardDesktop = ({
  children,
  className,
}: BankGuaranteeRewardsBanksListFilterCardDesktopProps) => {
  return <S.Container className={className}>{children}</S.Container>;
};
