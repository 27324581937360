import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { ProductListItemEllipsisSpan } from "./ProductListItemEllipsisSpan";
import { DemandId, GroupProductName, ProductId } from "../../../../types/demand";

export interface ProductListItemDemandDateBankProps {
  id: DemandId | ProductId | GroupProductName;
  dateTime: string;
  bankName: string;
}

export const ProductListItemDemandDateBank = ({ id, dateTime, bankName }: ProductListItemDemandDateBankProps) => {
  return (
    <ProductListItemEllipsisSpan>
      Заявка&nbsp;{id}&nbsp;от&nbsp;
      <time
        dateTime={dateTime}
        title={dateTimeHelper.format(new Date(dateTime), specialFormatters.dayFullMonthYearIfDifferentAndTime)}
      >
        {dateTimeHelper.format(new Date(dateTime), "dayAndShortMonth")}
      </time>
      &nbsp;→&nbsp;{bankName}
    </ProductListItemEllipsisSpan>
  );
};
