import { useEffect, useMemo } from "react";
import { QueueTasksListFilterParams, QueueTasksListFilterTabType } from "../types/filter";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectQueueTasksList } from "../store/queueTasksList.selectors";
import { getQueueTasksListCounters } from "../store/queueTasksList.thunks";
import { isIdle } from "../../../common/types/state";
import { selectQueueTasksCounters } from "../../counters";

export const useQueueTasksTabCounter = (
  filteringObject: QueueTasksListFilterParams
): Record<QueueTasksListFilterTabType, number | undefined> => {
  const dispatch = useAppDispatch();
  const { pagination, isDefaultFiltering, countersStatus } = useAppSelector(selectQueueTasksList);
  const { data: counters } = useAppSelector(selectQueueTasksCounters);

  useEffect(() => {
    if (isIdle(countersStatus)) {
      dispatch(getQueueTasksListCounters());
    }
  }, [dispatch, countersStatus]);

  return useMemo(() => {
    const getTabPagination = (tab: QueueTasksListFilterTabType) => {
      return filteringObject.tab === tab ? pagination?.totalCount : undefined;
    };

    return {
      All: isDefaultFiltering ? counters?.countQueueTasksAll : getTabPagination("All"),
      New: isDefaultFiltering ? counters?.countQueueTasksNew : getTabPagination("New"),
    };
  }, [
    isDefaultFiltering,
    counters?.countQueueTasksAll,
    counters?.countQueueTasksNew,
    filteringObject.tab,
    pagination?.totalCount,
  ]);
};
