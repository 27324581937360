import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CodeWithTitle } from "../../../../../common/types/objects";
import { useDocumentUploadCommonBankDocumentCardBanksLabel } from "../../../hooks/useDocumentUploadCommonBankDocumentCardBanksLabel";
import { Text } from "../../../../../common/ui-kit/typography";

export interface DocumentUploadCommonBankDocumentCardBanksLabelProps extends BaseCssProps {
  banks: CodeWithTitle[];
}

export const DocumentUploadCommonBankDocumentCardBanksLabel = ({
  banks,
  className,
}: DocumentUploadCommonBankDocumentCardBanksLabelProps) => {
  const label = useDocumentUploadCommonBankDocumentCardBanksLabel(banks);
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Text
      className={className}
      fontWeight={{ xs: 500, [desktopBreakpoint]: 700 }}
      lineHeight="defaultLineHeight"
      fontSize="fz2"
    >
      {label}
    </Text>
  );
};
