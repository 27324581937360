import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";
import { LEGAL_SERVICES_ROOT_URL } from "../../common/urls";

export const legalServicesCreationUrls = {
  list: makeAppUrl(
    joinUrlParts(LEGAL_SERVICES_ROOT_URL, "create", "list"),
    "/legal-services/create/list",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductLegalServices),
  ),
  product: makeAppUrl(
    joinUrlParts(LEGAL_SERVICES_ROOT_URL, "create", ":productId"),
    "/legal-services/create/:productId",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductLegalServices),
  ),
};
