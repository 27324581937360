import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { FormRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox/components/radio-group/FormRadioButtonGroup";
import { BaseCssProps } from "@sideg/ui-kit/common/types";
import { FormEventHandler } from "react";
import { SimpleOpacityTransitionStyled } from "@sideg/ui-kit/atoms/animations/transitions";
import { FormSelectResponsive } from "@sideg/ui-kit/atoms/form-controls/select";
import { CheckboxSize } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";

interface FormResponsiveRadioButtonGroupProps<TItem extends string> extends BaseCssProps {
  value: TItem;
  onChange: (selectedItem: TItem) => void | Promise<void>;
  onBlur?: FormEventHandler;
  name: string;
  label: string;
  types: TItem[];
  getTitle: (value: TItem) => string;
  disabled?: boolean;
  error?: string;
  itemsPlacement?: "center" | "start";
  radioSize?: CheckboxSize;
}

export const FormResponsiveRadioButtonGroup = <TItem extends string>({
  value,
  onChange,
  onBlur,
  name,
  label,
  types,
  disabled,
  getTitle,
  error,
  className,
  itemsPlacement,
  radioSize,
}: FormResponsiveRadioButtonGroupProps<TItem>) => {
  return (
    <ThemeMobileDesktopView
      desktop={
        <FormRadioButtonGroup label={label} itemsPlacement={itemsPlacement} disabled={disabled}>
          {types.map((x) => (
            <SimpleOpacityTransitionStyled timeout={300} key={x}>
              <FormRadioButtonGroup.Option
                value={String(x)}
                label={getTitle(x)}
                checked={x === value}
                isSelected={x === value}
                radioSize={radioSize}
                onChange={(e) => {
                  onChange(e.target.value as TItem);
                }}
                onBlur={onBlur}
                name={name}
                className={className}
              />
            </SimpleOpacityTransitionStyled>
          ))}
        </FormRadioButtonGroup>
      }
      mobile={
        <FormSelectResponsive
          label={label}
          getItemTitle={getTitle}
          items={types}
          name={name}
          selectedItem={value}
          onBlur={onBlur}
          onChange={onChange}
          error={error}
          disabled={disabled}
          className={className}
        />
      }
    />
  );
};
