import { useEffect } from "react";
import { useAppDispatch } from "../../../common/store";
import { chatDialogWaitingUpdate } from "../store/chatDialog.slice";

export const useChatDialogUnmountEffect = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(chatDialogWaitingUpdate());
    };
  }, [dispatch]);
};
