import { BankGuaranteeDemandStageType } from "../types";
import { ElementColor } from "../../../common/ui-kit/types";

export const getBankGuaranteeDemandStageColor = (stage: BankGuaranteeDemandStageType): ElementColor => {
  switch (stage) {
    case "AttachingDocuments":
      return "primary";
    case "Confirmed":
      return "status.success";
    case "Rejected":
      return "status.warning";
    case "Processing":
    default:
      return "darkLight";
  }
};
