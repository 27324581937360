import { forwardRef, PropsWithChildren } from "react";
import { BaseCssProps, ControlSize } from "@sideg/ui-kit/common/types";
import { SelectPopupContainerItem, SelectPopupContainerItemJustified } from "@sideg/ui-kit/atoms/form-controls/select";
import { CheckIcon } from "@sideg/ui-kit/atoms/icons";

export interface SelectPopupItemWithIconProps extends PropsWithChildren, BaseCssProps {
  active: boolean;
  selected?: boolean;
  size?: ControlSize;
  highlighted?: boolean;
  iconStroke?: "success" | "neutral";
  iconPosition?: "before" | "after";
}

export const SelectPopupItemWithIcon = forwardRef<HTMLLIElement, SelectPopupItemWithIconProps>(
  ({ children, active, iconPosition = "before", className, ...rest }, ref) => {
    const Item = iconPosition === "before" ? SelectPopupContainerItem : SelectPopupContainerItemJustified;

    return (
      <Item ref={ref} active={active} {...rest} className={className}>
        {active && iconPosition === "before" && <CheckIcon />}
        {children}
        {active && iconPosition === "after" && <CheckIcon />}
      </Item>
    );
  },
);
