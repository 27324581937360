import { CancelDemandBankGuaranteeInputDto } from "./input/CancelDemandBankGuaranteeInputDto";
import { clientV4 } from "../../../../common/api";
import { CancelProductBankGuaranteeInputDto } from "./input/CancelProductBankGuaranteeInputDto";
import { EmptyOutputDto } from "../../../../common/api/types";

const CONTROLLER_NAME = "Guarantees";

export const bankGuaranteeRejectionApi = {
  cancelProduct: ({ guaranteeId, ...model }: CancelProductBankGuaranteeInputDto) => {
    return clientV4.put<typeof model, EmptyOutputDto>(`${CONTROLLER_NAME}/${guaranteeId}/cancel`, model);
  },
  cancelDemand: ({ guaranteeId, demandId, ...model }: CancelDemandBankGuaranteeInputDto) => {
    return clientV4.put<typeof model, EmptyOutputDto>(`${CONTROLLER_NAME}/${guaranteeId}/cancel/${demandId}`, model);
  },
};
