import styled from "@emotion/styled";
import { CancelIcon } from "../../../icons";

const StyledDiv = styled.div`
  padding: 0 0 0 8px;
  font-size: ${({ theme }) => theme.font.fz1};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: #ffffff;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.dark};
  display: inline-flex;
  align-items: center;
  gap: 2px;
  user-select: none;
`;

const StyledButton = styled.button`
  margin: 0;
  border: none;
  background: none;
  height: 24px;
  padding: 0 4px;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  appearance: none;
  user-select: none;

  & > svg {
    width: 12px;
    height: 12px;
    display: inherit;
  }

  & > svg > path {
    stroke: #ffffff;
  }

  &:hover {
    transform: rotate(90deg);
    transition: all 0.3s linear;
  }

  &:focus-visible {
    svg {
      outline: 1px solid #ffffff;
    }
  }
`;

export interface FilterBadgeProps {
  text: string;
  onCancelClick: () => void | Promise<void>;
}

export const FilterBadge = ({ text, onCancelClick }: FilterBadgeProps) => {
  return (
    <StyledDiv>
      <span>{text}</span>
      <StyledButton onClick={onCancelClick} type="button">
        <CancelIcon />
      </StyledButton>
    </StyledDiv>
  );
};
