import { useMemo } from "react";
import { useAppSelector } from "../../store";
import { selectAuthorizedUser } from "../../authorized-user";
import { AppUrlSelector } from "../../urls/internal/types";
import { selectAppUrl } from "../../urls/internal/utils";

export const useIsAvailableAppUrlPermission = (selector: AppUrlSelector<string, string>): boolean => {
  const { user } = useAppSelector(selectAuthorizedUser);

  return useMemo(() => {
    const { checkPermission } = selectAppUrl(selector);

    return checkPermission === true || checkPermission({ userFeatureFlags: user?.featureFlags ?? [] });
  }, [selector, user?.featureFlags]);
};
