import { Comparer, createEntityAdapter, EntityAdapter, EntityId, EntitySelectors, EntityState } from "@reduxjs/toolkit";

export type TypedEntitySelectors<T, TState, TEntityId extends EntityId = EntityId> = EntitySelectors<T, TState> & {
  selectIds: (state: TState) => TEntityId[];
  selectById: (state: TState, id: TEntityId) => T;
};

export type TypedEntityAdapter<TEntity, TEntityId extends EntityId = EntityId> = Omit<
  EntityAdapter<TEntity>,
  "getSelectors"
> & {
  getSelectors(): EntitySelectors<TEntity, EntityState<TEntity>>;
  getSelectors<TState>(
    selectState: (state: TState) => EntityState<TEntity>,
  ): TypedEntitySelectors<TEntity, TState, TEntityId>;
};

export const createAppEntityAdapter = <TEntity, TEntityId extends EntityId = EntityId>(
  options: {
    selectId?: (model: TEntity) => TEntityId;
    sortComparer?: false | Comparer<TEntity>;
  } = {},
): TypedEntityAdapter<TEntity, TEntityId> => {
  return createEntityAdapter<TEntity>(options) as TypedEntityAdapter<TEntity, TEntityId>;
};
