import { AnySchema, InferType, ValidationError } from "yup";
import { FilteringQueryObject } from "../types/FilteringQueryObject";

const getObjectFromQueryParams = (search: URLSearchParams): FilteringQueryObject => {
  return Array.from(search.entries()).reduce((accum: FilteringQueryObject, [key, value]) => {
    const accumValue = accum[key];
    if (accumValue) {
      if (Array.isArray(accumValue)) {
        accumValue.push(value);
      } else {
        accum[key] = [accumValue, value];
      }
    } else {
      accum[key] = value;
    }

    return accum;
  }, {});
};

const getObjectWithoutInvalidFields = (error: ValidationError, data: FilteringQueryObject) => {
  if (error.inner?.length) {
    const invalidKeys = error.inner.map((x) => x.path ?? "");

    return Object.fromEntries(Object.entries(data).filter(([key]) => !invalidKeys.includes(key)));
  }

  return {};
};

/**
 * Преобразует объект, полученный из строки запроса, используя переданную схему валидации.
 * Все поля, которые не получится преобразовать, будут проигнорированы
 */
const castQueryObjectForce = async <TValidationSchema extends AnySchema>(
  schema: TValidationSchema,
  data: FilteringQueryObject,
) => {
  let queryData = data;
  try {
    await schema.validate(data, { stripUnknown: true, abortEarly: false, strict: false });
  } catch (err: unknown) {
    queryData = getObjectWithoutInvalidFields(err as ValidationError, queryData);
  }

  return schema.cast(queryData, { stripUnknown: true });
};

/**
 * Преобразует объект, полученный из строки запроса, используя переданную схему валидации.
 * Все поля, которые не получится преобразовать, будут проигнорированы
 */
const castQueryObjectForceSync = <TValidationSchema extends AnySchema>(
  schema: TValidationSchema,
  data: FilteringQueryObject,
): InferType<TValidationSchema> => {
  let queryData = data;
  try {
    schema.validateSync(data, { stripUnknown: true, abortEarly: false, strict: false });
  } catch (err: unknown) {
    queryData = getObjectWithoutInvalidFields(err as ValidationError, queryData);
  }

  return schema.cast(queryData, { stripUnknown: true });
};

const isURLSearchParamsEquals = (a: URLSearchParams, b: URLSearchParams) => {
  const aValues = Array.from(a.values());
  const bValues = Array.from(b.values());

  if (aValues.length !== bValues.length) {
    return false;
  }

  return aValues.every((x) => b.has(x));
};

export const queryParamsService = {
  getObjectFromQueryParams,
  castQueryObjectForce,
  castQueryObjectForceSync,
  isURLSearchParamsEquals,
};
