import styled from "@emotion/styled";

export const PublicUserRegistrationControlButtonsContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spaces.lg};
  grid-template-columns: 1fr 2fr;

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    gap: ${({ theme }) => theme.spaces.xxl};
  }
`;
