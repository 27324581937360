import { PropsWithChildren, ReactNode } from "react";
import { Box } from "../../../../common/ui-kit/containers/box";
import { BaseCssProps, SpaceType } from "../../../../common/ui-kit/types";
import { DemandQueueTaskCommentHeader } from "./DemandQueueTaskCommentHeader";
import { BoxProps } from "../../../../common/ui-kit/containers/box/components/Box";

const DEFAULT_PADDING: SpaceType = "md";

export interface DemandQueueTaskCommentContainerProps
  extends PropsWithChildren<BaseCssProps>,
    Pick<BoxProps, "pt" | "pr" | "pb" | "pl" | "gap"> {
  title: ReactNode;
  date?: ReactNode;
}

export const DemandQueueTaskCommentContainer = ({
  title,
  date,
  children,
  className,
  pt = DEFAULT_PADDING,
  pr = DEFAULT_PADDING,
  pb = DEFAULT_PADDING,
  pl = DEFAULT_PADDING,
  gap = "sm",
}: DemandQueueTaskCommentContainerProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      as="article"
      pt={pt}
      pr={pr}
      pb={pb}
      pl={pl}
      gap={gap}
      className={className}
    >
      <DemandQueueTaskCommentHeader title={title} date={date} />
      {children}
    </Box>
  );
};
