import styled from "@emotion/styled";

export const CreationBlockCardTitle = styled.h3`
  display: block;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-size: ${({ theme }) => theme.font.defaultLineHeight};
  font-weight: 700;
  font-family: inherit;
  color: ${({ theme }) => theme.palette.typography.primary};
`;
