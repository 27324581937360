import { clientV4 } from "../../api";
import { GetPurchaseOutputDto } from "./dto/output/GetPurchaseOutputDto";
import { GetPurchaseInputDto } from "./dto/input/GetPurchaseInputDto";
import { PurchaseFixedKey } from "../types/PurchaseFixedKey";

type ProductField = {
  key: PurchaseFixedKey;
};

export const purchaseApi = {
  getPurchase: ({ purchaseNumber }: GetPurchaseInputDto & ProductField, abortSignal?: AbortSignal) => {
    return clientV4.get<GetPurchaseInputDto, GetPurchaseOutputDto>(
      `purchases`,
      { purchaseNumber },
      {
        abortSignal,
      }
    );
  },
};
