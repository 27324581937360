import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { declensionHelper } from "../../../helpers/entity";

export const useCreationScoringSubmitButtonText = (selectedBanksCount: number): string => {
  const isDesktop = useThemeIsDesktop();

  if (!isDesktop || selectedBanksCount < 1) {
    return "Сформировать заявку";
  }

  return `Сформировать заявку для ${
    selectedBanksCount > 10 ? selectedBanksCount : declensionHelper.getNumberUpToTenGenitive(selectedBanksCount)
  } ${declensionHelper.commonDeclensions.bank.genitive(selectedBanksCount)}`;
};
