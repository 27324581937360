import { date, object } from "yup";
import { dateTimeHelper } from "@sideg/helpers";
import { getDateOnlyTransformer } from "../../../common/validation";

export const reportsDemandValidationSchema = object({
  dateStart: date()
    .transform(getDateOnlyTransformer())
    .typeError("Укажите корректную дату")
    .required("Не забудьте заполнить"),
  dateEnd: date()
    .transform(getDateOnlyTransformer())
    .when("dateStart", (dateStart: Date | null | undefined, schema) => {
      return dateTimeHelper.isValid(dateStart)
        ? schema
            .min(
              dateTimeHelper.simpleModify("addDays", dateStart, 1),
              "Дата окончания не должна быть ранее даты начала",
            )
            .max(dateTimeHelper.simpleModify("addMonths", dateStart, 6), "Максимальный срок - 6 месяцев")
        : schema;
    })
    .typeError("Укажите корректную дату")
    .required("Не забудьте заполнить"),
});
