import { useCallback, useEffect, useMemo, useState } from "react";
import { BankGuaranteeCreationSubmitResult } from "../../types/creation-submit/BankGuaranteeCreationSubmitResult";
import { isLoading } from "../../../../common/types/state";

export const useBankGuaranteeCreationScoringSubmitState = (
  selectedBankIds: string[],
  { status, onSubmit, error: submitError }: BankGuaranteeCreationSubmitResult,
) => {
  const [error, setError] = useState<string | undefined>(undefined);

  const onClick = useCallback(async () => {
    if (selectedBankIds.length === 0) {
      setError("Нужно выбрать хотя бы один банк");

      return;
    }

    await onSubmit(selectedBankIds);
  }, [onSubmit, selectedBankIds]);

  useEffect(() => {
    setError(undefined);
  }, [status]);

  useEffect(() => {
    setError(submitError);
  }, [submitError]);

  return useMemo(() => ({ isSubmitting: isLoading(status), onClick, error }), [error, onClick, status]);
};
