import { useEffect, useState } from "react";
import * as React from "react";
import IValue from "../IValue";
import Textarea from "./Textarea";

export interface ITextareaRequiredProps {
  viewError: boolean;
  defaultValue?: string;
  name: string;
  placeholder: string;
  onChange: (value: IValue) => void;
}

const TextareaRequired: React.FC<ITextareaRequiredProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    validateValue(props.defaultValue);
  }, [props.defaultValue]);

  const handleChange = (value: string) => {
    validateValue(value);
  };

  const validateValue = (value?: string) => {
    const result = validate(value);
    props.onChange(result);
  };

  const validate = (value?: string): IValue => {
    let result;

    if (value === undefined || value.length === 0) {
      result = {
        value: value,
        isValid: false,
        errorMessage: "Заполните поле",
      };
    } else {
      result = {
        value: value,
        isValid: true,
        errorMessage: undefined,
      };
    }

    setErrorMessage(result.errorMessage);

    return result;
  };

  return (
    <Textarea
      errorMessage={props.viewError ? errorMessage : undefined}
      defaultValue={props.defaultValue}
      name={props.name}
      placeholder={props.placeholder}
      maxLength={1000}
      onChange={handleChange}
    />
  );
};

export default TextareaRequired;
