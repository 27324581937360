import { PublicUserOffersCard } from "../components/PublicUserOffersCard";
import {
  AppPublicLayoutLinkButton,
  AppPublicLayoutBlockWrapper,
} from "../../../common/ui/layout/app-public-layout-containers";
import { usePageTitle } from "../../../common/navigation/page-title";

export const PublicUserOffersPage = () => {
  usePageTitle("Правила и порядок работы");

  return (
    <AppPublicLayoutBlockWrapper>
      <PublicUserOffersCard />
      <AppPublicLayoutLinkButton to={(x) => x.root}>Вернуться на главную</AppPublicLayoutLinkButton>
    </AppPublicLayoutBlockWrapper>
  );
};
