import { QueueTasksListFilterParams } from "../../types/filter";
import { useQueueTasksListFilteringItemsConverter } from "../../hooks/useQueueTasksListFilteringItemsConverter";
import { queueTasksListFiltersValidationSchema } from "../../validation/queueTasksListFiltersValidationSchema";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { ProductFiltersBadges } from "../../../../common/filters/use-cases";

export interface QueueTasksHeaderFilterBadgesProps extends BaseCssProps {
  filteringObject: QueueTasksListFilterParams;
}

export const QueueTasksListHeaderFilterBadges = ({ filteringObject }: QueueTasksHeaderFilterBadgesProps) => {
  const converter = useQueueTasksListFilteringItemsConverter();

  return (
    <ProductFiltersBadges
      filteringObject={filteringObject}
      schema={queueTasksListFiltersValidationSchema}
      converter={converter}
    />
  );
};
