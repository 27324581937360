import styles from './LoaderCompact.module.css'
import cn from 'classnames'

interface LoaderCompactProps {
    className?: string
}

const LoaderCompact: React.FC<LoaderCompactProps> = ({
    className
}) => (
    <div className={cn(styles['spinner'], className)}>
        <div className={styles['bounce1']} />
        <div className={styles['bounce2']} />
        <div className={styles['bounce3']} />
    </div>
)


export default LoaderCompact
