import { productCreationStages, ProductCreationStages } from "../../types/ProductCreationStages";

export const useProductCreationBackToStage = (
  currentStage: ProductCreationStages,
  onPrevStageClick: (stage: ProductCreationStages) => void
) => {
  const activeIndex = productCreationStages.values.indexOf(currentStage);
  const canBackPrevious = activeIndex > 0;

  const onBackPrevious = () => {
    if (canBackPrevious) {
      const prevStageIndex = productCreationStages.values.indexOf(currentStage) - 1;
      const prevStage = productCreationStages.values[prevStageIndex];
      if (prevStage) {
        onPrevStageClick(prevStage);
      }
    }
  };

  return { canBackPrevious, onBackPrevious };
};
