import styled from "@emotion/styled";

export const Wrapper = styled.header`
  flex: 0 0 55px;
  background-color: ${({ theme }) => theme.layout.header.backgroundColor};
  padding: ${({ theme }) => theme.spaces.md};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: ${({ theme }) => theme.layout.zIndex};
`;

export const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  & > svg > path {
    stroke: ${({ theme }) => theme.palette.typography.primaryLight};
  }

  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;
