import { QueueTasksListFilterParams, queueTasksListFilterTab, QueueTasksListFilterTabType } from "../../types/filter";
import { useQueueTasksTabCounter } from "../../hooks/useQueueTasksTabCounter";
import { QueueTaskListTabs } from "./QueueTaskListTabs";

export interface QueueTaskTabFilterProps {
  filteringObject: QueueTasksListFilterParams;
  activeTab: QueueTasksListFilterTabType;
  onChange: (tab: QueueTasksListFilterTabType) => void | Promise<void>;
}

export const QueueTaskListTabFilter = ({ activeTab, onChange, filteringObject }: QueueTaskTabFilterProps) => {
  const counters = useQueueTasksTabCounter(filteringObject);

  return (
    <QueueTaskListTabs.Container activeTab={activeTab} onTabChange={onChange}>
      {queueTasksListFilterTab.values.map((x, _, array) => (
        <QueueTaskListTabs.Tab
          key={x}
          tab={x}
          text={queueTasksListFilterTab.getTitle(x)}
          counterValue={!counters[x] ? "••••" : counters[x]}
          xsFlexBasis={`${100 / array.length}%`}
        />
      ))}
    </QueueTaskListTabs.Container>
  );
};
