export enum BankGuaranteeDetailsComplicatedTaskProjectDecisionType {
  ProjectAgreed = "ProjectAgreed",
  ChangesOffer = "ChangesOffer",
  ConfirmPayment = "ConfirmPayment",
}

export const isBankGuaranteeDetailsComplicatedTaskProjectDecisionSelected = (
  decision: BankGuaranteeDetailsComplicatedTaskProjectDecisionType,
): decision is
  | BankGuaranteeDetailsComplicatedTaskProjectDecisionType.ProjectAgreed
  | BankGuaranteeDetailsComplicatedTaskProjectDecisionType.ChangesOffer => {
  return (
    decision === BankGuaranteeDetailsComplicatedTaskProjectDecisionType.ProjectAgreed ||
    decision === BankGuaranteeDetailsComplicatedTaskProjectDecisionType.ChangesOffer
  );
};

export const isBankGuaranteeDetailsComplicatedTaskProjectDecisionProjectAgreed = (
  decision: BankGuaranteeDetailsComplicatedTaskProjectDecisionType | undefined,
): decision is BankGuaranteeDetailsComplicatedTaskProjectDecisionType.ProjectAgreed => {
  return decision === BankGuaranteeDetailsComplicatedTaskProjectDecisionType.ProjectAgreed;
};

export const isBankGuaranteeDetailsComplicatedTaskProjectDecisionChangesOffer = (
  decision: BankGuaranteeDetailsComplicatedTaskProjectDecisionType | undefined,
): decision is BankGuaranteeDetailsComplicatedTaskProjectDecisionType.ChangesOffer => {
  return decision === BankGuaranteeDetailsComplicatedTaskProjectDecisionType.ChangesOffer;
};
