import Skeleton from "react-loading-skeleton";
import { DemandQueueTaskCard } from "../card/DemandQueueTaskCard";
import { DemandQueueTaskCommentSkeleton } from "../comment/DemandQueueTaskComment.skeleton";
import { DemandQueueTaskSeparator } from "../separator/DemandQueueTaskSeparator";

export interface DemandQueueTaskCompletedCardSkeletonProps {
  className?: string;
}

export const DemandQueueTaskCompletedCardSkeleton = ({ className }: DemandQueueTaskCompletedCardSkeletonProps) => {
  return (
    <DemandQueueTaskCard variant="completed" title={<Skeleton width={100} />} className={className}>
      <DemandQueueTaskCommentSkeleton authorLength={80} textLinesCount={1} />
      <DemandQueueTaskSeparator />
      <DemandQueueTaskCommentSkeleton authorLength={100} textLinesCount={2} />
    </DemandQueueTaskCard>
  );
};
