import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { FileUpload, FileUploadDropzoneInputBlock } from "../../../../../../../files/upload";
import { useGetFieldName } from "../../../../../../../common/form";
import { BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormValuesType } from "../../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormValuesType";
import { useHandleSingleFileFieldChange } from "../../../../../../../files/temp";
import * as S from "./BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormContent.styled";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { isLoading, LoadingStatusEnum } from "../../../../../../../common/types/state";

interface BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormContentProps {
  onClose?: () => void;
  status: LoadingStatusEnum;
}

export const BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormContent = ({
  onClose,
  status,
}: BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormContentProps) => {
  const getFieldName = useGetFieldName<BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormValuesType>();
  const { file, removeFile, handleFileChange } = useHandleSingleFileFieldChange(getFieldName("file"));
  const isDesktop = useThemeIsDesktop();
  const isShowRejectButton = isDesktop;
  const isFullWidthButton = !isDesktop;

  return (
    <>
      <S.Container>
        {file && <FileUpload fileName={file.file.name} date={file.dateAdded} canRemove onRemove={removeFile} />}
        <FileUploadDropzoneInputBlock
          handleFileChange={handleFileChange}
          isDisabled={isLoading(status)}
          text={file?.file ? "Заменить файл" : "Прикрепить файл (необязательно)"}
          isMultiple={false}
        />
      </S.Container>
      <Box display="flex" gap="xxl">
        <S.ConfirmButton isLoading={isLoading(status)} isFullWidth={isFullWidthButton} type="submit">
          Подтвердить оплату
        </S.ConfirmButton>

        {isShowRejectButton && (
          <S.RejectButton disabled={isLoading(status)} variant="outlined" onClick={onClose} type="button">
            Отмена
          </S.RejectButton>
        )}
      </Box>
    </>
  );
};
