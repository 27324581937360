import { useCallback, useEffect, useState } from "react";
import { useLocationPathEffect } from "../../../navigation/menu/hooks/useLocationPathEffect";
import { ApplicationUpdaterAlertContainer } from "../container/ApplicationUpdaterAlertContainer";
import { Typography } from "../../../ui-kit/typography";
import { Box } from "../../../ui-kit/containers/box";
import { ApplicationUpdaterAlertButton } from "../button/ApplicationUpdaterAlertButton";

export interface ApplicationUpdaterForceAlertProps {
  onUpdate: () => Promise<void>;
}

export const ApplicationUpdaterForceAlert = ({ onUpdate }: ApplicationUpdaterForceAlertProps) => {
  const [isOpened, setIsOpened] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isWaitingUpdate, setIsWaitingUpdate] = useState<boolean | undefined>(undefined);

  const update = useCallback(async () => {
    if (!isUpdating) {
      await onUpdate();
      setIsUpdating(true);
      setIsOpened(false);
    }
  }, [isUpdating, onUpdate]);

  useLocationPathEffect(() => {
    setIsWaitingUpdate((prev) => (prev === undefined ? true : prev));
  }, []);

  useEffect(() => {
    if (isWaitingUpdate) {
      update();
    }
  }, [isWaitingUpdate, update]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isOpened && (
        <ApplicationUpdaterAlertContainer>
          <Typography textAlign="center">
            Приложение будет обновлено при переходе <br />
            на следующую страницу
          </Typography>
          <Box display="flex" gap="sm" alignItems="center" justifyContent="center">
            <ApplicationUpdaterAlertButton variant="primary" onClick={update}>
              Обновить сейчас
            </ApplicationUpdaterAlertButton>
            <ApplicationUpdaterAlertButton variant="secondary" onClick={() => setIsOpened(false)}>
              Закрыть
            </ApplicationUpdaterAlertButton>
          </Box>
        </ApplicationUpdaterAlertContainer>
      )}
    </>
  );
};
