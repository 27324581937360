enum TabType {
    Active = 'active',
    Canceled = 'canceled',
    All = 'all'
}

export const parseTabType = (code: string): TabType => {
    switch (code) {
        case TabType.Canceled:
            return TabType.Canceled
        case TabType.All:
            return TabType.All
        default:
            return TabType.Active
    }
}

export default TabType