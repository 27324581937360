import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import {
  useWebPushesIsSubscribed,
  useWebPushesPeriodicallyPrompt,
  useWebPushesSubscriptionApiContext,
  WebPushesPromptMobile,
} from "../../../../common/web-pushes";

export const QueueTasksListHeaderWebPushesPrompt = () => {
  const { needShowPrompt, dismiss } = useWebPushesPeriodicallyPrompt();
  const [isSubscribed, setIsSubscribed] = useWebPushesIsSubscribed();
  const { subscribe } = useWebPushesSubscriptionApiContext();

  const onAllowClick = async () => {
    setIsSubscribed(true);
    await subscribe();
  };

  return (
    <ThemeMobileDesktopView
      mobile={
        needShowPrompt &&
        !isSubscribed && <WebPushesPromptMobile onAllowClick={onAllowClick} onDisallowClick={dismiss} />
      }
      desktop={undefined}
    />
  );
};
