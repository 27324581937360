import { useBackLinkHeaderAction } from "../../../../common/ui/layout/header";
import { NavigationStateFrom } from "../../../../common/navigation/navigation-state";

interface UserProfilePageHeaderMobileProps {
  from: NavigationStateFrom;
}

export const UserProfilePageHeaderMobile = ({ from: { url } }: UserProfilePageHeaderMobileProps) => {
  useBackLinkHeaderAction({ url });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};
