import Skeleton from "react-loading-skeleton";
import { Box } from "../../../../../../../ui-kit/containers/box";
import { BaseCssProps } from "../../../../../../../ui-kit/types";
import * as S from "./ProductListCardFooterDemandLink.mobile.styled";

export type ProductListCardFooterDemandLinkMobileSkeletonProps = BaseCssProps;

const Container = Box.withComponent(S.Container);

export const ProductListCardFooterDemandLinkMobileSkeleton = ({
  className,
}: ProductListCardFooterDemandLinkMobileSkeletonProps) => {
  return (
    <Container className={className} display="flex" alignItems="center" justifyContent="center" pt="md">
      <Skeleton width={120} />
    </Container>
  );
};
