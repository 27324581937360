import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  AvailableForRequest: "Доступен для запроса",
  Requested: "Запрошен",
  AvailableForDownload: "Доступен для скачивания",
  Downloaded: "Уже загружался",
};

export const bankGuaranteeFileRegistryStatusType = makeEnumLikeLiteral(
  ["AvailableForRequest", "Requested", "AvailableForDownload", "Downloaded"] as const,
  titles
);

export type BankGuaranteeFileRegistryStatusType = EnumLikeLiteralType<typeof bankGuaranteeFileRegistryStatusType>;
