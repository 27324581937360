import { useEffect } from "react";
import { useAppDispatch } from "../../../common/store";
import { BankGuaranteeDocumentsFilteringObject } from "../types/BankGuaranteeDocumentsFilteringObject";
import { getBankGuaranteeDocuments } from "../store/bankGuaranteeDocuments.thunks";

export const useBankGuaranteeDocumentsFetch = (filteringObject: BankGuaranteeDocumentsFilteringObject) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const find = (bankCode: string) => {
      return dispatch(getBankGuaranteeDocuments({ bankCode, legalForm: filteringObject.legalForm }));
    };

    const request =
      filteringObject.bank !== undefined && filteringObject.legalForm ? find(filteringObject.bank) : undefined;

    return () => {
      request?.abort();
    };
  }, [dispatch, filteringObject]);
};
