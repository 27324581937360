import { lkApiV3 } from "./configs";
import ApiResponseModel from "../entity/models/ApiResponseModel";
import { AxiosResponse } from "axios";
import ApiResponseErrorModel from "../entity/models/ApiResponseErrorModel";
import FzTitleEnum from "../entity/models/enums/FzTitleEnum";
import FzTypeCodeEnum from "../entity/models/enums/FzTypeCodeEnum";

export const dateStringFormatRegex = /(\d{2})\.(\d{2})\.(\d{4})/;

/**
 *
 * @param date
 * @returns example: 6 окт
 */
export const formatDateWithShortMonth = (date: string) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = shortenMonths[dateObj.getMonth()];

  return day + " " + month;
};

/**
 *
 * @param date
 * @returns example: 23 дек 2020
 */
export const formatDateWithShortMonthNameFullYear = (date: string) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const monthShortName = shortenMonths[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  return day + " " + monthShortName + " " + year;
};

const shortenMonths: Array<string> = [
  "янв",
  "фев",
  "мар",
  "апр",
  "мая",
  "июн",
  "июл",
  "авг",
  "сен",
  "окт",
  "ноя",
  "дек",
];

// рефакторнуть эту функцию, используя getUrlQueryParamsAsObject() 🛠
export const checkRefQueryParams = (): void => {
  let sourceIsReferral: boolean = false;
  let referral: string = "";

  const queryParamsString: string = window.location.search;
  const queryParamsArray: Array<string> = queryParamsString.slice(1).split("&");

  queryParamsArray.forEach((param: string) => {
    const paramKey = param.split("=")[0];
    const paramValue = param.split("=")[1];

    if (!paramKey || !paramValue) return;

    if (paramKey === "source" && paramValue === "referral") sourceIsReferral = true;

    if (paramKey === "referral") referral = paramValue;
  });

  if (sourceIsReferral && referral.length > 0) {
    localStorage.refSrc = referral;
    localStorage.refExpMs = Date.now() + 14 * 24 * 60 * 60 * 1000;
  }
};

// Январь 2020 г.
export const formatterMonthNameYear = new Intl.DateTimeFormat("ru", {
  year: "numeric",
  month: "long",
});

// 10.12.2021
export const formatterDayMonthYear = new Intl.DateTimeFormat("ru", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
});

// 10.12.2021 10:22
export const formatterDayMonthYearHourMinute = new Intl.DateTimeFormat("ru", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

export const generateFileLinkGuid = (guid: string): string => `${lkApiV3()}/file/get-file-by-guid?guid=${guid}`;

// +7 (999) 999-9999
export const applyPhoneMask = (value: string): string => {
  const x = value.replace(/\D/g, "").match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
  if (!x) return value;

  return "+7" + (!x[3] ? " (" + x[2] : " (" + x[2] + ") " + x[3] + (x[4] ? "-" + x[4] : ""));
};

export const cssModuleMultipleClasses = (classes: any[]) =>
  classes
    .slice()
    .filter((c) => typeof c === "string")
    .join(" ");

/**
 *
 * @param response
 * @returns example: "[ERROR!] [METHOD: PUT v3/Demand/{demandId}] code: 404; errors: 'not found', 'sorry :(';"
 */

export const createStringFromApiResponse = (response: AxiosResponse<ApiResponseModel<any>>): string => {
  let result: string[] = [];

  result.push(`[ERROR!][METHOD: ${response.config.method?.toUpperCase()} ${response.config.url}]`);
  result.push(`Code: ${response.data.code};`);
  result.push(`Error: ${response.data.error.toString()};`);
  result.push(`Errors: ${getErrorsString(response.data.errors)};`);

  if (response.data.body) {
    result.push(`Body: ${JSON.stringify(response.data.body)}`);
  }

  return result.join(" ");
};

const getErrorsString = (errors: ApiResponseErrorModel[]): string => {
  return errors.length > 0 ? errors.map((er, index) => `(${index + 1}) ${er.key} - ${er.message}`).join(", ") : "-";
};

export const getFullFzTitle = (code: FzTypeCodeEnum) => {
  if (code === FzTypeCodeEnum.Commerce) {
    return FzTitleEnum.Commerce;
  }

  return "Закупка по " + getShortFzTitle(code);
};

export const getShortFzTitle = (code: FzTypeCodeEnum) => {
  switch (code) {
    case FzTypeCodeEnum.N223:
      return FzTitleEnum.N223;

    case FzTypeCodeEnum.N44:
      return FzTitleEnum.N44;

    case FzTypeCodeEnum.N115:
      return FzTitleEnum.N115;

    case FzTypeCodeEnum.N185:
      return FzTitleEnum.N185;

    case FzTypeCodeEnum.N275:
      return FzTitleEnum.N275;

    case FzTypeCodeEnum.N615:
      return FzTitleEnum.N615;

    case FzTypeCodeEnum.Commerce:
      return FzTitleEnum.Commerce;
  }
};
