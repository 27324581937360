import { forwardRef, PropsWithChildren } from "react";
import { ChevronLeftIcon } from "@sideg/ui-kit/atoms/icons";
import { ButtonProps } from "@sideg/ui-kit/atoms/button";
import { ControlSize } from "@sideg/ui-kit/common/types";
import { Typography } from "@sideg/ui-kit/atoms/typography";
import { getSelectTextVariant } from "@sideg/ui-kit/atoms/form-controls/select/utils/getSelectTextVariant";
import * as S from "@sideg/ui-kit/atoms/form-controls/select/components/filter-expand-button/FilterExpandButton.styled";

export interface FilterExpandButtonProps extends Omit<ButtonProps, "error" | "disabled">, PropsWithChildren {
  opened: boolean;
  error?: boolean;
  disabled?: boolean;
  selectSize: ControlSize;
  expandIconPosition?: "left" | "right";
}

export const FilterExpandButton = forwardRef<HTMLButtonElement, FilterExpandButtonProps>(
  ({ children, error = false, disabled = false, selectSize = "small", expandIconPosition = "right", ...rest }, ref) => {
    return (
      <S.StyledButton {...rest} disabled={disabled} error={error} expandIconSize={selectSize} ref={ref}>
        {expandIconPosition === "left" && <ChevronLeftIcon />}
        <Typography variant={getSelectTextVariant(selectSize)} fontColor="light.neutral.text.primary">
          {children}
        </Typography>
        {expandIconPosition === "right" && <ChevronLeftIcon />}
      </S.StyledButton>
    );
  },
);
