import { PropsWithChildren } from "react";
import { AppUrlSelector } from "../../urls/internal/types";
import { useIsAvailableAppUrlPermission } from "../hooks/useIsAvailableAppUrlPermission";

export interface FeatureAppUrlPermissionProps extends PropsWithChildren {
  selector: AppUrlSelector<string, string>;
}

export const FeatureAppUrlPermission = ({ selector, children }: FeatureAppUrlPermissionProps) => {
  const isAvailable = useIsAvailableAppUrlPermission(selector);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isAvailable && children}</>;
};
