import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BankGuaranteeDetailComplicatedTaskProjectActiveFormContactFields } from "./BankGuaranteeDetailComplicatedTaskProjectActiveFormContactFields";
import { DemandQueueTaskActiveProjectInfoContacts } from "../../../../../../../../demand/queue-tasks";
import { Box } from "../../../../../../../../common/ui-kit/containers/box";

export interface BankGuaranteeDetailComplicatedTaskProjectActiveFromFieldsProps {
  isDisabled?: boolean;
  actualBeneficiaryContacts?: DemandQueueTaskActiveProjectInfoContacts;
  actualClientContacts?: DemandQueueTaskActiveProjectInfoContacts;
}

export const BankGuaranteeDetailComplicatedTaskProjectActiveContactsBlock = ({
  isDisabled,
  actualClientContacts,
  actualBeneficiaryContacts,
}: BankGuaranteeDetailComplicatedTaskProjectActiveFromFieldsProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Box display="flex" flexDirection="column" gap={{ xs: "lg", [desktopBreakpoint]: "xxl" }}>
      <BankGuaranteeDetailComplicatedTaskProjectActiveFormContactFields
        isDisabled={isDisabled}
        entityType="client"
        actualContacts={actualClientContacts}
      />
      <BankGuaranteeDetailComplicatedTaskProjectActiveFormContactFields
        isDisabled={isDisabled}
        entityType="beneficiary"
        actualContacts={actualBeneficiaryContacts}
      />
    </Box>
  );
};
