import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { CreditWorkingAssetsCreationBrief } from "../../brief/CreditWorkingAssetsCreationBrief";
import { CreditWorkingAssetsBase } from "../../../../common/types";

export interface CreditWorkingAssetsCreationScoringCreateByExistsBriefProps extends BaseCssProps {
  isLoading: boolean;
  data: CreditWorkingAssetsBase | undefined;
}

export const CreditWorkingAssetsCreationScoringCreateByExistsBrief = ({
  isLoading,
  data,
  className,
}: CreditWorkingAssetsCreationScoringCreateByExistsBriefProps) => {
  return (
    <CreationBlockWithTitle title="Информация о заявке" className={className}>
      <CreditWorkingAssetsCreationBrief isLoading={isLoading} data={data} />
    </CreationBlockWithTitle>
  );
};
