import { queueTasksListFiltersValidationSchema } from "./queueTasksListFiltersValidationSchema";

export const queueTasksListMobileFiltersValidationSchema = queueTasksListFiltersValidationSchema.pick([
  "products",
  "banks",
  "dateAddedFrom",
  "dateAddedTo",
  "taskTypes",
  "ordering",
]);
