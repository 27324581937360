import { Theme } from "@sideg/ui-kit";
import { BankGuaranteeDetailsDemandStatusType } from "../../types/demand/BankGuaranteeDetailsDemandStatusType";
import { Color } from "../../../../common/ui-kit/theme";

export const getThemePaletteByStatusType = (
  theme: Theme,
  variant: BankGuaranteeDetailsDemandStatusType,
): { text: Color; background: Color } => {
  switch (variant) {
    case "rejected":
      return {
        text: theme.palette.typography.error,
        background: theme.palette.status.errorBackground,
      };
    case "attachDocuments":
    case "active":
      return {
        text: theme.colors.primary,
        background: theme.secondaryColors.alertBackground,
      };
    default:
      return {
        text: theme.colors.dark,
        background: theme.palette.control.secondaryMedium,
      };
  }
};
