import * as React from "react";
import { Text, Typography } from "../../../../../ui-kit/typography";
import {
  productCreationScoringResultType,
  ProductCreationScoringResultType,
} from "../../../../types/ProductCreationScoringResultType";
import { BaseCssProps, ElementColor } from "../../../../../ui-kit/types";

export interface BankGuaranteeCreationScoringResultSummaryTextProps extends BaseCssProps {
  count: React.ReactNode;
  resultType: ProductCreationScoringResultType;
}

const getColorByResultType = (resultType: ProductCreationScoringResultType): ElementColor => {
  switch (resultType) {
    case "success":
      return "status.success";
    case "reject":
      return "status.error";
    case "duplicate":
    default:
      return "typography.medium";
  }
};

export const ProductCreationScoringResultSummaryTextDesktop = ({
  count,
  className,
  resultType,
}: BankGuaranteeCreationScoringResultSummaryTextProps) => {
  return (
    <div className={className}>
      <Typography
        lineHeight="defaultLineHeight"
        fontColor={getColorByResultType(resultType)}
        fontWeight={700}
        fontSize="fz3"
      >
        {count}
      </Typography>
      <Text>&nbsp;—&nbsp;{productCreationScoringResultType.getTitle(resultType)}</Text>
    </div>
  );
};
