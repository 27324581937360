import { combineSliceNames } from "../../../common/store/utils";
import { BANK_GUARANTEE_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { bankGuaranteeDocumentsApi } from "../api/bankGuaranteeDocumentsApi";

export const BANK_GUARANTEE_DOCUMENTS_SLICE_NAME = combineSliceNames(BANK_GUARANTEE_SLICE_NAME, "documents");

export const { get: getBankGuaranteeDocuments, getBanks: getBanksBankGuaranteeDocuments } = createApiThunks(
  BANK_GUARANTEE_DOCUMENTS_SLICE_NAME,
  bankGuaranteeDocumentsApi
);
