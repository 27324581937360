import { memo } from "react";
import Skeleton from "react-loading-skeleton";
import { Text } from "../../../../common/ui-kit/typography";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDetailsDemandsTotal } from "../../store/demands/bankGuaranteeDetailsDemands.selectors";
import { useBankGuaranteeDetailsLayoutContext } from "../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { isLoading } from "../../../../common/types/state";
import { declensionHelper } from "../../../../common/helpers/entity";

export interface BankGuaranteeDetailsAsideNumberTitleProps {
  className?: string;
}

export const BankGuaranteeDetailsAsideNumberTitle = memo(({ className }: BankGuaranteeDetailsAsideNumberTitleProps) => {
  const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();
  const { status, isTransferred } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);
  const totalCount = useAppSelector(selectBankGuaranteeDetailsDemandsTotal);

  const showSkeleton = isLoading(status) && (totalCount === 0 || isTransferred);

  return (
    <Text className={className} fontSize="fz4" lineHeight="defaultLineHeight" fontWeight={700}>
      Заявка № {guaranteeId}&nbsp;
      {showSkeleton && <Skeleton width={50} />}
      {!isTransferred && totalCount > 0 && `в ${declensionHelper.commonDeclensions.bank.nominative(totalCount, true)}`}
    </Text>
  );
});
