import { useRef } from "react";
import { Formik } from "formik";
import { CreditWorkingAssetsCreationFormContent } from "./CreditWorkingAssetsCreationFormContent";
import { useCreditWorkingAssetsCreationFromInitialValues } from "../../../hooks/creation/useCreditWorkingAssetsCreationFromInitialValues";
import { useCreditWorkingAssetsCreationFormSubmit } from "../../../hooks/creation/useCreditWorkingAssetsCreationFormSubmit";
import { getCreditWorkingAssetsCreationFromValidationSchema } from "../../../validation/creditWorkingAssetsCreationValidationSchema";
import { FormBox, FormScrollToFirstErrorAfterSubmit } from "../../../../../common/form";

export const CreditWorkingAssetsCreationForm = () => {
  const ref = useRef<HTMLFormElement | null>(null);
  const initialValues = useCreditWorkingAssetsCreationFromInitialValues();
  const onSubmit = useCreditWorkingAssetsCreationFormSubmit(initialValues);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={getCreditWorkingAssetsCreationFromValidationSchema()}
    >
      {() => (
        <FormBox ref={ref} display="flex" flexDirection="column" flexShrink={1} flexGrow={1}>
          <FormScrollToFirstErrorAfterSubmit containerRef={ref} />
          <CreditWorkingAssetsCreationFormContent />
        </FormBox>
      )}
    </Formik>
  );
};
