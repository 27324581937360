import { EnumLikeLiteral, makeEnumLikeLiteral } from "./EnumLikeLiteral";

export const UNSELECTED_ENUM_LITERAL = "__unselected" as const;

export type UnselectedEnumLiteral = typeof UNSELECTED_ENUM_LITERAL;

export const makeFormEnumLikeLiteral = <T extends string | number>(
  values: readonly T[],
  titles: Record<T | UnselectedEnumLiteral, string>
): EnumLikeLiteral<T | UnselectedEnumLiteral> => makeEnumLikeLiteral([UNSELECTED_ENUM_LITERAL, ...values], titles);
