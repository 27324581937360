import { ControlSize, PickCssObjectProps } from "@sideg/ui-kit/common/types";

import { mapThemeTypographyVariantToCssObject } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypographyVariantTokenValue";

interface InputSizeParams
  extends PickCssObjectProps<"width" | "height">,
    ReturnType<typeof mapThemeTypographyVariantToCssObject> {}

export const getIconSize = (size: ControlSize): InputSizeParams => {
  switch (size) {
    case "small":
      return {
        width: 16,
        height: 16,
      };
    case "medium":
      return {
        width: 24,
        height: 24,
      };
    case "default":
    default:
      return {
        width: 20,
        height: 20,
      };
  }
};
