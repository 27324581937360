import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { ReactNode } from "react";
import { Grid } from "../../../../../../../common/ui-kit/containers/responsive-grid";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { BankGuaranteeDetailsComplicatedTaskBillAmountFormValues } from "../../../types/BankGuaranteeDetailsComplicatedTaskBillAmountFormValues";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { Typography } from "../../../../../../../common/ui-kit/typography";
import { useTypedField } from "../../../../../../../common/form";
import { useBankGuaranteeDetailsComplicatedTaskBillAmountOnChange } from "../../../hooks/useBankGuaranteeDetailsComplicatedTaskBillAmountOnChange";
import { DemandQueueTaskActiveBillInfo } from "../../../../../../../demand/queue-tasks";

const BoxTypography = Box.withComponent(Typography);

export interface BankGuaranteeDetailsComplicatedTaskBillAmountFieldProps extends BaseCssProps {
  label?: string;
  isDisabled?: boolean;
  afterInput: ReactNode;
  billInfo: DemandQueueTaskActiveBillInfo;
  field: keyof BankGuaranteeDetailsComplicatedTaskBillAmountFormValues;
}

export const BankGuaranteeDetailsComplicatedTaskBillAmountField = ({
  label,
  afterInput,
  isDisabled,
  className,
  billInfo,
  field,
}: BankGuaranteeDetailsComplicatedTaskBillAmountFieldProps) => {
  const [{ error, name, value, onBlur }] = useTypedField<number>(field);
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  const onChange = useBankGuaranteeDetailsComplicatedTaskBillAmountOnChange(billInfo);

  return (
    <Grid
      item
      className={className}
      {...{ xs: 12, [desktopBreakpoint]: 5 }}
      as={FormInputPrice}
      autoComplete="off"
      label={label}
      error={error}
      disabled={isDisabled}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={async (e) => {
        await onChange(field, e.target.value);
      }}
      afterInput={
        <BoxTypography px="sm" fontWeight={500} fontSize="fz4" lineHeight="1.4" fontColor="typography.medium">
          {afterInput}
        </BoxTypography>
      }
    />
  );
};
