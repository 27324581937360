import { useEffect, useState } from "react";
import { useAppLayout } from "./useAppLayout";
import { useAppLayoutOverflowScrollReset } from "./useAppLayoutOverflowScrollReset";

export const useAppLayoutOverflowEffect = (isFixed: boolean) => {
  const {
    isFixedOverflow: { value, setValue },
  } = useAppLayout();
  const [initialValue] = useState(value);

  useAppLayoutOverflowScrollReset(isFixed);

  useEffect(() => {
    setValue(isFixed);

    return () => {
      setValue(initialValue);
    };
  }, [initialValue, isFixed, setValue]);
};
