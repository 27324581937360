import { useCallback } from "react";
import { useApiMutationAction } from "../../../../common/hooks";
import { sendConfirmPaymentBankGuaranteeDetailsDemand } from "../../store/bankGuaranteeDetails.thunks";
import { DemandId } from "../../../../common/types/demand";
import { UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler } from "../../types/demand/reconciliation/UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler";
import { useAppDispatch } from "../../../../common/store";
import { pushErrorGlobalToast } from "../../../../global/toasts";
import { isBaseApiError } from "../../../../common/api/types";

interface UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandlerArgs {
  demandId: DemandId;
  onSuccess: () => Promise<void>;
}

export const useBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler = ({
  demandId,
  onSuccess,
}: UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandlerArgs) => {
  const { mutate, status } = useApiMutationAction();
  const dispatch = useAppDispatch();

  const handleFormSubmit: UseBankGuaranteeDetailsDemandReconciliationConfirmPaymentSubmitHandler = useCallback(
    async (values) => {
      const result = await mutate(
        sendConfirmPaymentBankGuaranteeDetailsDemand,
        { demandId, confirmationPaymentFile: values.file?.file },
        {
          errorHandler: async (e: unknown) => {
            const message =
              "Не удалось отправить платежное поручение. Попробуйте позже или свяжитесь с персональным менеджером";

            const error = isBaseApiError(e) ? e.message ?? message : message;

            dispatch(pushErrorGlobalToast(error));

            return error;
          },
        },
      );

      if (result) {
        await onSuccess();
      }
    },
    [demandId, dispatch, mutate, onSuccess],
  );

  return { handleFormSubmit, status };
};
