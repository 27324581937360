import * as S from "./BankGuaranteeRewardsBanksListFilterDesktopWrapper.styled";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeRewards } from "../../../store/bankGuaranteeRewards.selectors";
import { BankGuaranteeRewardsBanksListFilterDesktop } from "./BankGuaranteeRewardsBanksListFilter.desktop";
import { BankGuaranteeRewardsBanksListFilterSkeleton } from "./BankGuaranteeRewardsBanksListFilter.skeleton";
import { useStickyLikeScrollElement } from "../../../../../common/hooks/scroll";
import { useGetResponsiveElementWidth } from "../../../../../common/ui/metrics";

interface BankGuaranteeRewardsBanksListFilterDesktopWrapperProps {
  onClick: (value: string) => void;
  bankListPaddingTop: number;
}

export const BankGuaranteeRewardsBanksListFilterDesktopWrapper = ({
  onClick,
  bankListPaddingTop,
}: BankGuaranteeRewardsBanksListFilterDesktopWrapperProps) => {
  const { banks } = useAppSelector(selectBankGuaranteeRewards);

  const { isFixed, ref: separatorRef } = useStickyLikeScrollElement<HTMLDivElement>(bankListPaddingTop);

  const { ref: bankListRef, responsiveWidth } = useGetResponsiveElementWidth();

  return (
    <S.Container ref={bankListRef} bankListPaddingTop={`${bankListPaddingTop}px`}>
      {banks ? (
        <BankGuaranteeRewardsBanksListFilterDesktop banks={banks} onClick={onClick} />
      ) : (
        <BankGuaranteeRewardsBanksListFilterSkeleton />
      )}
      <S.Separator
        width={responsiveWidth}
        ref={separatorRef}
        isFixed={isFixed}
        topPosition={`${bankListPaddingTop}px`}
      />
    </S.Container>
  );
};
