import styled from "@emotion/styled";
import { colorWithAlpha } from "../utils";

export const ButtonOutline = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => colorWithAlpha(theme.colors.primary, 128)};
  font-family: inherit;
  user-select: none;
  transition: border 0.2s;
  color: ${({ theme }) => colorWithAlpha(theme.colors.primary, 220)};
  text-decoration: none;
  cursor: pointer;

  path {
    stroke: ${({ theme }) => colorWithAlpha(theme.colors.primary, 220)};
  }

  &:focus,
  &:hover:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};

    path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.secondaryDisabled};
    cursor: default;

    path {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }
`;
