import { AccessToken } from "../types/jwt/AccessToken";
import { jsonService } from "../../services/json";

// https://stackoverflow.com/a/30106551
const decodeBase64Unicode = (base64: string) => {
  return decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(""),
  );
};

const decryptTokenPayload = <TPayload>(token: AccessToken): TPayload => {
  const tokenParts = token.split(".");
  if (tokenParts.length > 2) {
    const payload = tokenParts[1].replace(/-/g, "+").replace(/_/g, "/");

    return jsonService.deserialize<TPayload>(decodeBase64Unicode(payload));
  }

  throw Error("Сохранен некорректный токен");
};

export const authenticationJwtService = {
  decryptTokenPayload,
};
