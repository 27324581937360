import styled from "@emotion/styled";

export const Wrapper = styled.div`
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.xs};
  padding-bottom: ${({ theme }) => theme.spaces.md};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
`;

export const Main = styled.div`
  padding: ${({ theme }) => theme.spaces.md} 0 0 0;
`;
