import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../common/store";
import { BankGuaranteeCreationState } from "./bankGuaranteeCreation.types";
import { ClientSearchState } from "../../../client-search";
import { PurchaseState } from "../../../common/purchase";

export const selectBankGuaranteeCreation = (state: RootState): BankGuaranteeCreationState =>
  state.domains.bankGuarantee.creation.form;

export const selectBankGuaranteeCreationClientSearch = (state: RootState): ClientSearchState =>
  state.domains.bankGuarantee.creation.clientSearch;

export const selectBankGuaranteeCreationPurchase = (state: RootState): PurchaseState =>
  state.domains.bankGuarantee.creation.purchase;

export const selectBankGuaranteeCreationCreating = (state: RootState) => selectBankGuaranteeCreation(state).creating;

export const selectBankGuaranteeCreationScoring = (state: RootState) => selectBankGuaranteeCreation(state).scoring;

export const selectAllSuccessBanksBankGuaranteeCreationScoring = createSelector(
  selectBankGuaranteeCreationScoring,
  (scoringItems) => {
    return scoringItems.data?.success?.map((x) => x.bank.code) ?? [];
  },
);
