import PaginatedList from "../../models/PaginatedList";
import AgentReportModel from "../../models/AgentReportModel";
import { AgentReportAction, AgentReportActionPage, AgentReportStatus } from "../actions/agentReportActionsTypes";
import { AgentReportListActionDispatchTypes } from "../actions/agentReportListActionsTypes";
import { AgentReportCreateActionDispatchTypes } from "../actions/agentReportCreateActionsTypes";
import { AgentReportItemActionDispatchTypes } from "../actions/agentReportItemActionsTypes";
import { AgentReportUpdateActionDispatchTypes } from "../actions/agentReportUpdateActionsType";

export interface IAgentReportListState {
  status: AgentReportStatus;
  errorMessage?: string;
  data?: PaginatedList<AgentReportModel>;
}

export interface IAgentReportItemState {
  status: AgentReportStatus;
  errorMessage?: string;
  data?: AgentReportModel;
}

export interface IAgentReportCreateState {
  status: AgentReportStatus;
  errorMessage?: string;
}

export interface IAgentReportUpdateState {
  status: AgentReportStatus;
  errorMessage?: string;
}

export interface IAgentReportState {
  list?: IAgentReportListState;
  create?: IAgentReportCreateState;
  item?: IAgentReportItemState;
  update?: IAgentReportUpdateState;
}

const defaultState: IAgentReportState = {};

const agentReportReducer = (
  state: IAgentReportState = defaultState,
  action:
    | AgentReportListActionDispatchTypes
    | AgentReportCreateActionDispatchTypes
    | AgentReportItemActionDispatchTypes
    | AgentReportUpdateActionDispatchTypes
): IAgentReportState => {
  switch (action.page) {
    case AgentReportActionPage.List:
      return agentReportList(state, action);
    case AgentReportActionPage.Item:
      return agentReportItem(state, action);
    case AgentReportActionPage.Create:
      return agentReportCreate(state, action);
    case AgentReportActionPage.Update:
      return agentReportUpdate(state, action);
    default:
      return state;
  }
};

const agentReportList = (state: IAgentReportState, action: AgentReportListActionDispatchTypes): IAgentReportState => {
  switch (action.type) {
    case AgentReportAction.Initial:
      return {
        ...state,
        list: {
          status: AgentReportStatus.Initial,
        },
      };
    case AgentReportAction.Processing:
      return {
        ...state,
        list: {
          status: AgentReportStatus.Processing,
        },
      };
    case AgentReportAction.Failed:
      return {
        ...state,
        list: {
          status: AgentReportStatus.Failed,
          errorMessage: action.payload.errorMessage,
        },
      };
    case AgentReportAction.Success:
      return {
        ...state,
        list: {
          status: AgentReportStatus.Success,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

const agentReportItem = (state: IAgentReportState, action: AgentReportItemActionDispatchTypes): IAgentReportState => {
  switch (action.type) {
    case AgentReportAction.Initial:
      return {
        ...state,
        item: {
          status: AgentReportStatus.Initial,
        },
      };
    case AgentReportAction.Processing:
      return {
        ...state,
        item: {
          status: AgentReportStatus.Processing,
        },
      };
    case AgentReportAction.Failed:
      return {
        ...state,
        item: {
          status: AgentReportStatus.Failed,
          errorMessage: action.payload.errorMessage,
        },
      };
    case AgentReportAction.Success:
      return {
        ...state,
        item: {
          status: AgentReportStatus.Success,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

const agentReportCreate = (
  state: IAgentReportState,
  action: AgentReportCreateActionDispatchTypes
): IAgentReportState => {
  switch (action.type) {
    case AgentReportAction.Initial:
      return {
        ...state,
        create: {
          status: AgentReportStatus.Initial,
        },
      };
    case AgentReportAction.Processing:
      return {
        ...state,
        create: {
          status: AgentReportStatus.Processing,
        },
      };
    case AgentReportAction.Failed:
      return {
        ...state,
        create: {
          status: AgentReportStatus.Failed,
          errorMessage: action.payload.errorMessage,
        },
      };
    case AgentReportAction.Success:
      return {
        ...state,
        create: {
          status: AgentReportStatus.Success,
        },
      };
    default:
      return state;
  }
};

const agentReportUpdate = (
  state: IAgentReportState,
  action: AgentReportUpdateActionDispatchTypes
): IAgentReportState => {
  switch (action.type) {
    case AgentReportAction.Initial:
      return {
        ...state,
        update: {
          status: AgentReportStatus.Initial,
        },
      };
    case AgentReportAction.Processing:
      return {
        ...state,
        update: {
          status: AgentReportStatus.Processing,
        },
      };
    case AgentReportAction.Failed:
      return {
        ...state,
        update: {
          status: AgentReportStatus.Failed,
          errorMessage: action.payload.errorMessage,
        },
      };
    case AgentReportAction.Success:
      return {
        ...state,
        update: {
          status: AgentReportStatus.Success,
        },
      };
    default:
      return state;
  }
};

export default agentReportReducer;
