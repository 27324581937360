import styled, { ComponentSelector } from "@emotion/styled";
import { FilterSelect } from "../../../../../common/ui-kit/form-controls/select";

export const CustomFilterSelect = styled(FilterSelect)`
  > button {
    background-color: ${({ theme }) => theme.palette.control.secondaryLight};

    &:hover {
      background-color: ${({ theme }) => theme.palette.control.primaryLight};
    }
  }

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    max-width: 240px;
  }
` as typeof FilterSelect & ComponentSelector;
