import { memo } from "react";
import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";
import { AppInfinityScroll } from "../../../../common/ui/product/list/infinite-scroll";
import { AppInfinityScrollTitles } from "../../../../common/ui/product/list/infinite-scroll/components/AppInfinityScroll";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useFilteredPaginatedListPage } from "../../../../common/filters";
import { ProductListItemsContainer } from "../../../../common/ui/product/list/container";
import { getCreditExecutionList } from "../../store/creditExecutionList.thunks";
import { useCreditExecutionListFilterContext } from "../../hooks/filters/useCreditExecutionListFilterContext";
import {
  selectCreditExecutionListItems,
  selectCreditExecutionListItemsIds,
} from "../../store/items/creditExecutionListItems.selectors";
import { CreditExecutionListItemSkeleton } from "./item/CreditExecutionItem.skeleton";
import { CreditExecutionListItems } from "./CreditExecutionListItems";
import { CreditExecutionListModification } from "./item/modification/CreditExecutionListModification";

const titles: AppInfinityScrollTitles = {
  end: "Загружены все заявки",
  hasNoLoadedItems: "Нет заявок",
  navigateToFirstPage: "Показать предыдущие заявки",
  error: "При получении списка заявок возникла ошибка",
};

export interface CreditExecutionListProps extends BaseCssProps {
  onErrorReloadButtonClick: () => void | Promise<void>;
}

export const CreditExecutionList = memo(({ className, onErrorReloadButtonClick }: CreditExecutionListProps) => {
  const filteringObject = useCreditExecutionListFilterContext();
  const { onPreviousPageButtonClick, hasPreviousPage, onNextPageFetching } = useFilteredPaginatedListPage({
    filteringObject,
    action: getCreditExecutionList,
  });

  return (
    <AppLayoutResponsiveListPage.List className={className}>
      <CreditExecutionListModification />
      <AppInfinityScroll
        page={filteringObject.page}
        paginatedStateSelector={selectCreditExecutionListItems}
        idsSelector={selectCreditExecutionListItemsIds}
        titles={titles}
        onPreviousPageButtonClick={onPreviousPageButtonClick}
        onPageChanged={onNextPageFetching}
        onErrorReloadButtonClick={onErrorReloadButtonClick}
        hasPreviousPage={hasPreviousPage}
        emptyLoader={
          <ProductListItemsContainer>
            <CreditExecutionListItemSkeleton />
            <CreditExecutionListItemSkeleton />
            <CreditExecutionListItemSkeleton />
            <CreditExecutionListItemSkeleton />
          </ProductListItemsContainer>
        }
      >
        <CreditExecutionListItems />
      </AppInfinityScroll>
    </AppLayoutResponsiveListPage.List>
  );
});
