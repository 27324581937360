import styled from "@emotion/styled";

export const Container = styled.section`
  flex-grow: 0;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.palette.background.contrastLight};
  padding: ${({ theme }) => theme.spaces.md};
  border-radius: 0.5rem;
  display: flex;
  gap: ${({ theme }) => theme.spaces.md};
  flex-direction: column;
`;
