import { ActionReducerMapBuilder, createAction, createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { clientSearchAdapter } from "./clientSearch.adapter";
import { ClientSearchSliceState, ClientSearchState } from "./clientSearch.types";
import { BaseDataState, getLoadingStatusFromAction, LoadingStatusEnum } from "../../common/types/state";
import { checkSearchClient, CLIENT_SEARCH_SLICE_NAME, findSearchClient } from "./clientSearch.thunks";
import { ClientSearchCheckedModel } from "../types/ClientSearchCheckedModel";
import { ClientSearchFixedKey } from "../types/ClientSearchFixedKey";
import { combineSliceNames } from "../../common/store/utils";

const initialState = clientSearchAdapter.getInitialState<ClientSearchSliceState>({
  status: LoadingStatusEnum.Idle,
});

export interface GetClientSearchReducerArgs {
  key: ClientSearchFixedKey;
  sliceName: string;
  caseExtraReducers?: (builder: ActionReducerMapBuilder<ClientSearchState>, initialState: ClientSearchState) => void;
  matchExtraReducers?: (builder: ActionReducerMapBuilder<ClientSearchState>, initialState: ClientSearchState) => void;
}

const isSameKey = (action: { meta: { arg: { key: ClientSearchFixedKey } } }, key: ClientSearchFixedKey) => {
  return action.meta.arg.key === key;
};

export const deleteClientSearchFoundClients = createAction<{ key: ClientSearchFixedKey }>(
  combineSliceNames(CLIENT_SEARCH_SLICE_NAME, "delete")
);

export const removeCheckedClientSearch = createAction<{ key: ClientSearchFixedKey }>(
  combineSliceNames(CLIENT_SEARCH_SLICE_NAME, "removeChecked")
);

export const getClientSearchReducer = ({
  key,
  sliceName,
  matchExtraReducers,
  caseExtraReducers,
}: GetClientSearchReducerArgs) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(deleteClientSearchFoundClients, (state, action) => {
          if (action.payload.key === key) {
            return initialState;
          }

          return state;
        })
        .addCase(removeCheckedClientSearch, (state, action) => {
          if (action.payload.key === key) {
            state.check = initialState.check;
            clientSearchAdapter.removeAll(state);
          }
        })
        .addCase(findSearchClient.fulfilled, (state, action) => {
          if (isSameKey(action, key)) {
            if (action.payload.body.checkedClient) {
              state.check = {
                status: LoadingStatusEnum.Succeeded,
                data: action.payload.body.checkedClient,
              };

              clientSearchAdapter.removeAll(state);
            }
            if (action.payload.body.clients) {
              clientSearchAdapter.setAll(state, action.payload.body.clients);
            }
          }
        })
        .addCase(findSearchClient.rejected, (state, action) => {
          if (isSameKey(action, key) && state.check) {
            state.error = action.payload?.message;
          }
        })
        .addCase(checkSearchClient.fulfilled, (state, action) => {
          if (isSameKey(action, key) && state.check) {
            state.check.data = action.payload.body.checkedClient;
          }
        })
        .addCase(checkSearchClient.rejected, (state, action) => {
          if (isSameKey(action, key) && state.check) {
            state.check.error = action.payload?.message;
          }
        })
        .addCase(checkSearchClient.pending, (state, action) => {
          if (isSameKey(action, key) && state?.check?.data) {
            state.check.data = undefined;
          }
        });

      caseExtraReducers?.(builder, initialState);

      builder
        .addMatcher(isAsyncThunkAction(findSearchClient), (state, action) => {
          if (isSameKey(action, key)) {
            state.status = getLoadingStatusFromAction(action);
          }
        })
        .addMatcher(isAsyncThunkAction(checkSearchClient), (state, action) => {
          if (isSameKey(action, key)) {
            if (state.check === undefined) {
              state.check = {} as BaseDataState<ClientSearchCheckedModel>;
            }

            state.check.status = getLoadingStatusFromAction(action);
          }
        });

      matchExtraReducers?.(builder, initialState);
    },
  }).reducer;
