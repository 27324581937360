import {
  ScoringModelItemsType,
  ScoringModelItemType,
  ScoringModelItemParamType,
  ScoringModelItemsParamsType,
} from "../../../../../../entity/models/ScoringModel";
import { ClientFormSelectOpt } from "../../../../../../ui/components/client-form/parts/select/ClientFormSelect";
import { ScoringFormValues } from "./initialValues";
import PriceHelper from "../../../../../../essentials/helpers/PriceHelper";

export interface RequestScoringItemModel {
  parameterCode: string;
  valueBoolean: boolean;
}

export interface RequestScoringModel {
  productType: string;
  guaranteeSum: number;
  guaranteeTimeDays: number | null;
  scoringItems: RequestScoringItemModel[];
}

const mapItem = (item: ScoringModelItemParamType) => {
  return {
    title: item.parameterTitle,
    value: item.parameterCode,
  };
};

const transformOptions = (scoringSelectItems: ScoringModelItemsParamsType) => {
  return scoringSelectItems.map((scoringSelectItem) => mapItem(scoringSelectItem));
};

export const getOptions = (options: ScoringModelItemsType): ClientFormSelectOpt[] => {
  return options.flatMap((items: ScoringModelItemType) => {
    return transformOptions(items.parameters);
  });
};

export const getMultiOptions = (options: ScoringModelItemsType) => {
  let optionArray: ClientFormSelectOpt[][] = [];
  let optionMultiArray: ClientFormSelectOpt[][] = [];
  options.forEach((item: ScoringModelItemType) => {
    if (item.isAntagonist) {
      optionArray.push(transformOptions(item.parameters));
    } else {
      optionMultiArray.push(transformOptions(item.parameters));
    }
  });
  return {
    singleChoose: optionArray,
    multipleChoose: optionMultiArray,
  };
};

export const calculatorModelMapper = (values: ScoringFormValues): RequestScoringModel => {
  const generalScoringItem = [];
  values.fzType && generalScoringItem.push(values.fzType);
  values.guaranteeType && generalScoringItem.push(values.guaranteeType);
  generalScoringItem.push(...values.scoringItems);

  return {
    productType: values.productType,
    guaranteeSum: PriceHelper.stringToNumber(values.guaranteeSum),
    guaranteeTimeDays: values.guaranteeTimeDays ? +values.guaranteeTimeDays : null,
    scoringItems: generalScoringItem.map((item) => {
      return {
        parameterCode: item,
        valueBoolean: true,
      };
    }),
  };
};

export const convertOptions = (options: ClientFormSelectOpt[]) => {
  return options.map((option: ClientFormSelectOpt) => {
    return {
      title: option.title.trim().replace("Тип ФЗ - ", ""),
      value: option.value,
    };
  });
};
