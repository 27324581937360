export enum AgentReportActionPage {
    List = 'LIST',
    Item = 'ITEM',
    Create = 'CREATE',
    Update = 'UPDATE'
}

export enum AgentReportAction {
    Initial = 'AGENT_REPORT_INITIAL',
    Processing = 'AGENT_REPORT_PROCESSING',
    Failed = 'AGENT_REPORT_FAILED',
    Success = 'AGENT_REPORT_SUCCESS'
}

export enum AgentReportStatus {
    Initial = 'INITIAL',
    Processing = 'PROCESSING',
    Failed = 'FAILED',
    Success = 'SUCCESS',
}

export type AgentReportSignCodeForm = {
    reportId: number
    signId: string | null
    signCode: string | null
}

export type AgentReportSignForm = {
    fileId: bigint
    privateKey: string
    sign: string
}

export default {}