import { AlertWithBackground } from "../../../../ui-kit/notifications/alert-with-background";
import { Text } from "../../../../ui-kit/typography";

export const ErrorBoundaryDefaultFallbackAlert = () => {
  return (
    <AlertWithBackground>
      <Text fontWeight={700} fontSize="fz3">
        Что-то пошло не так
      </Text>
      <Text as="div" fontSize="fz3">
        Мы уже работаем над исправлением ошибки. Обновите страницу и попробуйте еще раз, или свяжитесь с Вашим
        персональным менеджером
      </Text>
    </AlertWithBackground>
  );
};
