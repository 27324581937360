import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { FormResponsiveRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox/components/radio-group/FormResponsiveRadioButtonGroup";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { BankGuaranteeCreationContractCheckboxFields } from "../fields/BankGuaranteeCreationContractCheckboxFields";
import { BankGuaranteeCreationPurchaseIdentificationCodeField } from "../fields/BankGuaranteeCreationPurchaseIdentificationCodeField";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { useBankGuaranteeCreationForm } from "../../../hooks/creation/useBankGuaranteeCreationForm";
import { useBankGuaranteeCreationFieldHelpers } from "../../../hooks/creation/fields/useBankGuaranteeCreationFieldHelpers";
import { useGetFormFieldError } from "../../../../../common/form";
import { BankGuaranteeCreationFromValues } from "../../../types/BankGuaranteeCreationFormValues";
import { useBankGuaranteeCreationFederalLawTypes } from "../../../hooks/creation/useBankGuaranteeCreationFederalLawTypes";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useBankGuaranteeCreationPurchaseLinkFieldLabel } from "../../../hooks/creation/fields/useBankGuaranteeCreationPurchaseLinkFieldLabel";
import { federalLawRules } from "../../../../common/validation";
import { BankGuaranteeCreationContractPurchaseField } from "../fields/BankGuaranteeCreationContractPurchaseField";
import { federalLawTypeWithCommerce } from "../../../../../common/types/demand";

export interface BankGuaranteeCreationContractBlockProps extends BaseCssProps {
  isDisabled?: boolean;
  isPurchaseNumberDisabled?: boolean;
}

export const BankGuaranteeCreationContractBlock = ({
  isDisabled,
  isPurchaseNumberDisabled,
  className,
}: BankGuaranteeCreationContractBlockProps) => {
  const form = useBankGuaranteeCreationForm();
  const federalLawTypes = useBankGuaranteeCreationFederalLawTypes();

  const { getFieldName } = useBankGuaranteeCreationFieldHelpers();
  const getFieldError = useGetFormFieldError<BankGuaranteeCreationFromValues>();

  const purchaseLinkLabel = useBankGuaranteeCreationPurchaseLinkFieldLabel(
    form.values.federalLaw,
    form.values.isSingleSupplier,
  );

  return (
    <CreationBlockWithTitle title="Данные контракта" className={className}>
      <StackPanel direction="column" gap="md">
        <SimpleOpacityTransition
          timeout={250}
          in={!federalLawRules.isCommerce(form.values.federalLaw)}
          unmountOnExit
          mountOnEnter
        >
          <StackPanel direction="column" gap="md">
            <BankGuaranteeCreationContractPurchaseField isDisabled={isPurchaseNumberDisabled} />
            <BankGuaranteeCreationContractCheckboxFields disabled={isDisabled} />
            <FormResponsiveRadioButtonGroup
              itemsPlacement="start"
              radioSize="default"
              value={form.values.federalLaw}
              onChange={(newValue) => {
                form.setFieldValue(getFieldName("federalLaw"), newValue, true);
              }}
              onBlur={() => {
                form.setFieldTouched(getFieldName("federalLaw"), true, true);
              }}
              label="ФЗ"
              types={federalLawTypes}
              name={getFieldName("federalLaw")}
              getTitle={federalLawTypeWithCommerce.getTitle}
              error={getFieldError(form, "federalLaw")}
              disabled={isDisabled}
            />
            <BankGuaranteeCreationPurchaseIdentificationCodeField disabled={isDisabled} />
          </StackPanel>
        </SimpleOpacityTransition>
        <StackPanel direction="column" gap="md">
          <FormInput
            type="text"
            label={purchaseLinkLabel}
            size="medium"
            inputMode="url"
            {...form.getFieldProps(getFieldName("purchaseLink"))}
            error={getFieldError(form, "purchaseLink")}
            spellCheck={false}
            autoComplete="off"
            autoCorrect="off"
            disabled={isDisabled}
          />
          <FormTextarea
            label="Предмет контракта"
            size="medium"
            minRows={2}
            {...form.getFieldProps(getFieldName("contractSubject"))}
            error={getFieldError(form, "contractSubject")}
            autoComplete="off"
            disabled={isDisabled}
          />
        </StackPanel>
      </StackPanel>
    </CreationBlockWithTitle>
  );
};
