import { NumberFormatValues, NumericFormatProps } from "react-number-format";
import { useRef, useState } from "react";
import { FormatInputValueFunction } from "react-number-format/types/types";

const INTERNATIONAL_CODE = "7";
const LOCAL_CODE = "8";

// можно обрезать первую цифру
// 88 -> 8800, но ни у одного региона нет 88Х кода
// 89 -> 89XX, 881–899 - зарезервированные коды, 89XX - может быть началом номера моб.
const PROBABLY_CODE_PREFIXES = ["89", "88"];

const INTERNATIONAL_IDENTIFIER = `+${INTERNATIONAL_CODE}`;
const MAX_RAW_PHONE_NUMBER_LENGTH = 11;

const OPERATOR_PLACEHOLDER = "___";
const SUBSCRIBER_PHONE_PART_PLACEHOLDER = "___ __ __";

const FORMATTED_PHONE_NUMBER_MASK = `${INTERNATIONAL_IDENTIFIER} (${OPERATOR_PLACEHOLDER}) ${SUBSCRIBER_PHONE_PART_PLACEHOLDER}`;
const FORMATTED_PHONE_NUMBER_MAX_SIZE = FORMATTED_PHONE_NUMBER_MASK.length;

const isStartsWithAny = (value: string, ...params: string[]): boolean => {
  return params.some((x) => value.startsWith(x));
};

const getFormatterSubscriberPhonePart = (subscriberPart: string): string => {
  let startIndex = 0;
  const parts = SUBSCRIBER_PHONE_PART_PLACEHOLDER.split(" ").map((value) => {
    const [from, to] = [startIndex, startIndex + value.length];
    startIndex = to;

    return [from, to];
  });

  return parts.map(([from, to]) => subscriberPart.slice(from, to)).join(" ");
};

const isDifferenceMoreThenOneChar = (left: string, right: string) => {
  const [bigger, smaller] = left.length > right.length ? [left, right] : [right, left];
  let i = 0;
  while (i < smaller.length && bigger[i] === smaller[i]) {
    i += 1;
  }

  return `${bigger.slice(0, i)}${bigger.slice(i + 1)}` !== smaller;
};

const isValueProbablyPasted = (previousValue: string, actualValue: string) => {
  if (previousValue.length - actualValue.length === 0) {
    return previousValue !== actualValue;
  }

  return isDifferenceMoreThenOneChar(previousValue, actualValue);
};

const isAllowed = (values: NumberFormatValues): boolean => {
  return values.formattedValue.length <= FORMATTED_PHONE_NUMBER_MAX_SIZE;
};

export const usePhoneNumberInput = () => {
  const [maskPlaceholder, setMaskPlaceholder] = useState("");
  const valueRef = useRef<string | null>("");

  const onValueChange: NumericFormatProps["onValueChange"] = (values) => {
    setMaskPlaceholder(values.formattedValue + FORMATTED_PHONE_NUMBER_MASK.slice(values.formattedValue.length));
  };

  const format: FormatInputValueFunction = (inputValue): string => {
    const previousValue = valueRef.current ?? "";
    valueRef.current = inputValue;

    let value = inputValue;
    if (value.length === 0) {
      return "";
    }

    if (value.length === 1) {
      if (isStartsWithAny(value, INTERNATIONAL_CODE, LOCAL_CODE)) {
        return INTERNATIONAL_IDENTIFIER;
      }

      return `${INTERNATIONAL_IDENTIFIER}} (${value}`;
    }

    if (isValueProbablyPasted(previousValue, value)) {
      // Если длина соответствует полному номеру, то проверяем не начинается ли он на международный/локальный префикс.
      // Если длина короче, то возможны два варианта:
      // 1. Вставлен номер телефона без префикса (например, 901 123 45 67, 800 123 45 67)
      // 2. Вставлено начало номера (неправильно скопированный), например 7 901 123 45 6, 8 800 123 45 6, 7 800 123 45 6.
      // Для второго варианта урезаем возможность ошибиться, обрезая первый символ в случаях, когда:
      // - начало строки равное 7: так как такой код зарезервирован под другую страну https://ru.wikipedia.org/wiki/%D0%A2%D0%B5%D0%BB%D0%B5%D1%84%D0%BE%D0%BD%D0%BD%D1%8B%D0%B9_%D0%BF%D0%BB%D0%B0%D0%BD_%D0%BD%D1%83%D0%BC%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8_%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8
      // - начало строки содержит зарезервированные коды, или не встречается ни в одном из регионов.
      if (value.length === MAX_RAW_PHONE_NUMBER_LENGTH) {
        value = isStartsWithAny(value, INTERNATIONAL_CODE, LOCAL_CODE) ? value.slice(1) : value;
      } else {
        value = isStartsWithAny(value, INTERNATIONAL_CODE, ...PROBABLY_CODE_PREFIXES) ? value.slice(1) : value;
      }
    } else {
      value = isStartsWithAny(value, INTERNATIONAL_CODE) ? value.slice(1) : value;
    }

    const operatorPart = `${INTERNATIONAL_IDENTIFIER} (${value.slice(0, OPERATOR_PLACEHOLDER.length)})`;
    if (value.length < OPERATOR_PLACEHOLDER.length) {
      return operatorPart.slice(0, -1);
    }

    return `${operatorPart} ${getFormatterSubscriberPhonePart(value.slice(OPERATOR_PLACEHOLDER.length))}`.trimEnd();
  };

  return { format, isAllowed, onValueChange, maskPlaceholder };
};
