import { MutableRefObject, useMemo, useState } from "react";
import { Placement } from "@floating-ui/react";
import { usePopover } from "../../pop-up";

export interface PopupButtonOptions {
  position: Placement;
  offset?: [number, number];
  stayOpenedWhenClickOutside?: boolean;
}

export const usePopupButton = <TRef extends HTMLElement = HTMLButtonElement>({
  position,
  offset: offsetValue,
  stayOpenedWhenClickOutside,
}: PopupButtonOptions) => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleOpened = () => setIsOpened((x) => !x);

  const { refs, arrowRef, middlewareData, placement, floatingStyles } = usePopover<TRef>({
    offset: offsetValue,
    placement: position,
    stayOpenedWhenClickOutside,
    initialOpen: isOpened,
    open: isOpened,
    onOpenChange: setIsOpened,
    modal: false,
  });

  return useMemo(
    () => ({
      state: [isOpened, setIsOpened] as const,
      buttonProps: {
        ref: refs.setReference,
        element: refs.reference as MutableRefObject<TRef | null>,
        onClick: toggleOpened,
      },
      popupProps: {
        ref: refs.setFloating,
        style: floatingStyles,
      },
      placement,
      arrow: {
        ref: arrowRef,
        data: middlewareData.arrow,
      },
    }),
    [
      arrowRef,
      floatingStyles,
      isOpened,
      middlewareData.arrow,
      placement,
      refs.reference,
      refs.setFloating,
      refs.setReference,
    ],
  );
};
