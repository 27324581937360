import { useCallback } from "react";
import { filtrationService } from "../../services/filtrationService";
import { useSetActualPathnameQuerySearch } from "../../hooks/useSetActualPathnameQuerySearch";
import { PaginatedFilteringObjectType } from "../../types/PaginatedFilteringObjectType";

export type UsePaginatedQueryStateMutatorResult = <TFilter extends PaginatedFilteringObjectType>(
  filter: TFilter,
  func: (filter: TFilter, service: typeof filtrationService) => TFilter,
) => TFilter;

export const usePaginatedQueryStateMutator = (): UsePaginatedQueryStateMutatorResult => {
  const setQuery = useSetActualPathnameQuerySearch();

  return useCallback(
    <TFilter extends PaginatedFilteringObjectType>(
      filter: TFilter,
      func: (filter: TFilter, service: typeof filtrationService) => TFilter,
    ) => {
      const result = func(filtrationService.setValue(filter, "page", 1), filtrationService);
      setQuery(result);

      return result;
    },
    [setQuery],
  );
};
