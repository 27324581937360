import { useCallback, useRef } from "react";

export const useChatDialogScrollIntoView = <T extends HTMLElement>() => {
  const ref = useRef<T | null>(null);

  const scroll = useCallback(() => {
    ref?.current?.scrollIntoView();
  }, []);

  return [ref, scroll] as const;
};
