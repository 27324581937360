import { Dispatch } from "redux";
import { ScoringApiV3 } from "../../../data/api/v3/ScoringApiV3";
import logger, { loggerForErrorApiResponse, LogLevelEnum } from "../../../essentials/logger";
import { RequestScoringModel } from "../../../pages/guarantee/calculator/GuaranteeCalculatorForManagingDirectorOfAgent/GuaranteeCalculatorForManagingDirectorOfAgentForm/data/mappers";
import {
  scoringOptionsFetching,
  scoringOptionsFetched,
  scoringOptionsFetchingError,
  scoringDataProcessing,
  scoringDataSuccess,
  scoringDataError,
} from "./scoringActionCreators";

export const fetchScoringOptions = () => async (dispatch: Dispatch) => {
  dispatch(scoringOptionsFetching());
  const scoringApiV3 = new ScoringApiV3();
  scoringApiV3
    .getScoringOptions()
    .then((response) => {
      if (response.data.code === 200 && response.data.body?.data) {
        dispatch(scoringOptionsFetched(response.data.body.data));
      } else {
        loggerForErrorApiResponse(LogLevelEnum.Error, response);
        dispatch(scoringOptionsFetchingError());
      }
    })
    .catch((err) => {
      logger(LogLevelEnum.Error, err);
      dispatch(scoringOptionsFetchingError());
    });
};

export const scoringDataProcessed = (form: RequestScoringModel) => async (dispatch: Dispatch) => {
  dispatch(scoringDataProcessing());

  const scoringApiV3 = new ScoringApiV3();
  scoringApiV3
    .postScoringData(form)
    .then((response) => {
      if (response.data.code === 200 && response.data.body?.data) {
        dispatch(scoringDataSuccess(response.data.body?.data));
      } else {
        loggerForErrorApiResponse(LogLevelEnum.Error, response);
        dispatch(scoringDataError());
      }
    })
    .catch((err) => {
      logger(LogLevelEnum.Error, err);
      dispatch(scoringDataError());
    });
};

// export const fetchClientInfo = (inn: string) => async (dispatch: Dispatch) => {
//     dispatch(clientInfoCompanyFetching())

//     const scoringApiV3 = new ScoringApiV3()
//     scoringApiV3.getClientInfo(inn)
//         .then(response => {
//             if (response.data.code === 200 && response.data.body?.data) {
//                 dispatch(clientInfoCompanyFetched(response.data.body.data))
//             } else {
//                 loggerForErrorApiResponse(LogLevelEnum.Error, response)
//                 dispatch(clientInfoCompanyFetchingError())
//             }
//         })
//         .catch(err => {
//             logger(LogLevelEnum.Error, err)
//             dispatch(clientInfoCompanyFetchingError())
//         })
// }

// export const fetchBeneficiaryInfo = (inn: string) => async (dispatch: Dispatch) => {
//     dispatch(beneficiaryInfoCompanyFetching())

//     const scoringApiV3 = new ScoringApiV3()
//     scoringApiV3.getBeneficiaryInfo(inn)
//         .then(response => {
//             if (response.data.code === 200 && response.data.body?.data) {
//                 dispatch(beneficiaryInfoCompanyFetched(response.data.body.data))
//             } else {
//                 loggerForErrorApiResponse(LogLevelEnum.Error, response)
//                 dispatch(beneficiaryInfoCompanyFetchingError())
//             }
//         })
//         .catch(err => {
//             logger(LogLevelEnum.Error, err)
//             dispatch(beneficiaryInfoCompanyFetchingError())
//         })
// }

// export const fetchTenderInfo = (tenderNumber: string) => async (dispatch: Dispatch) => {
//     dispatch(tenderInfoCompanyFetching())

//     const scoringApiV3 = new ScoringApiV3()
//     scoringApiV3.getTenderInfo(tenderNumber)
//         .then(response => {
//             if (response.data.code === 200 && response.data.body?.data) {
//                 dispatch(tenderInfoCompanyFetched(response.data.body.data))
//             } else {
//                 loggerForErrorApiResponse(LogLevelEnum.Error, response)
//                 dispatch(tenderInfoCompanyFetchingError())
//             }
//         })
//         .catch(err => {
//             logger(LogLevelEnum.Error, err)
//             dispatch(tenderInfoCompanyFetchingError())
//         })
// }
