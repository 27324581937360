import { BaseCssProps } from "../../../../common/ui-kit/types";
import { BankGuaranteeId } from "../../../../common/types/demand";
import { BankGuaranteeBriefDescriptionList, useBankGuaranteeBriefFetcher } from "../../../brief";
import { CreationBlockWithTitle } from "../../../../common/ui-kit/cards/creation-block-card";

export interface BankGuaranteeCreationBriefBlockProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
}

export const BankGuaranteeCreationBriefBlock = ({ guaranteeId, className }: BankGuaranteeCreationBriefBlockProps) => {
  const { status, data } = useBankGuaranteeBriefFetcher(guaranteeId);

  return (
    <CreationBlockWithTitle title="Информация о заявке" className={className}>
      <BankGuaranteeBriefDescriptionList status={status} data={data} isShowDateStartMovedToToday isWithSeparators />
    </CreationBlockWithTitle>
  );
};
