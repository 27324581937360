import { memo } from "react";
import { BaseCssProps } from "../../../../../../../../common/ui-kit/types";
import * as S from "./BankGuaranteeListItemDesktopDemandList.styled";
import { BankGuaranteeListItemDesktopDemandSkeleton } from "../../demand-list-item/BankGuaranteeListItemDesktopDemand.skeleton";

export interface BankGuaranteeListItemDesktopDemandListSkeletonProps extends BaseCssProps {
  itemsCount: number;
}

export const BankGuaranteeListItemDesktopDemandListSkeleton = memo(
  ({ itemsCount, className }: BankGuaranteeListItemDesktopDemandListSkeletonProps) => {
    return (
      <S.Ul className={className}>
        {Array.from({ length: itemsCount })
          .map((_, i) => i)
          .map((x) => (
            <BankGuaranteeListItemDesktopDemandSkeleton key={x} />
          ))}
      </S.Ul>
    );
  }
);
