import { GetBankGuaranteeDocumentsItemOutputDto } from "../api/output/GetBankGuaranteeDocumentsOutputDto";
import { BankGuaranteeDocumentsItem } from "../types/BankGuaranteeDocumentsItem";
import { BankGuaranteeDocumentsUniqueId } from "../types/BankGuaranteeDocumentsUniqueId";
import { make, UNSELECTED_ENUM_LITERAL } from "../../../common/types/utils";
import { BankGuaranteeDocumentsBanksState } from "./bankGuaranteeDocuments.types";
import { LegacyBankModel } from "../../../common/types/banks";

const mapOne = ({
  id,
  name,
  required,
  taxSystems,
  summBgFrom,
  summBgTo,
  template,
}: GetBankGuaranteeDocumentsItemOutputDto): BankGuaranteeDocumentsItem => {
  const uniqueId: BankGuaranteeDocumentsUniqueId = make<BankGuaranteeDocumentsUniqueId>()(
    `${id}-${required}-${taxSystems.join("")}`,
  );

  return {
    id: uniqueId,
    isRequired: required,
    name,
    taxSystems,
    template,
    sumGuaranteeTo: summBgTo,
    sumGuaranteeFrom: summBgFrom,
  };
};

const mapMany = (documentsItems: GetBankGuaranteeDocumentsItemOutputDto[]): BankGuaranteeDocumentsItem[] => {
  return documentsItems.map(mapOne);
};

const mapBanks = (banks: LegacyBankModel[]): BankGuaranteeDocumentsBanksState => {
  const ids: string[] = [UNSELECTED_ENUM_LITERAL as string].concat(banks.map((x) => x.code));

  const titles = banks.reduce(
    (accum, value) => {
      accum[value.code] = value.shortName;

      return accum;
    },
    { [UNSELECTED_ENUM_LITERAL]: "Банк не выбран" } as Record<string, string>,
  );

  return { ids, titles };
};

export const bankGuaranteeDocumentsMapper = {
  mapOne,
  mapMany,
  mapBanks,
};
