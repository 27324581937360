import { QueueTasksCreationHeaderProps } from "./QueueTasksCreationHeaderProps";
import { StyledH2 } from "../../../../common/ui-kit/typography/StyledH2";
import { useBackLinkHeaderAction } from "../../../../common/ui/layout/header";
import { Box } from "../../../../common/ui-kit/containers/box";

export const QueueTasksCreationHeaderMobile = ({ from }: QueueTasksCreationHeaderProps) => {
  useBackLinkHeaderAction({ url: from.url });

  return (
    <Box as="header" pb="lg">
      <StyledH2>Новая заявка</StyledH2>{" "}
    </Box>
  );
};
