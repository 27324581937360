import styled from "@emotion/styled";
import { ProductCreationCreateByExistsMainContentWrapper } from "../../../../../common/product-creation";

export const Container = styled(ProductCreationCreateByExistsMainContentWrapper)`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: ${({ theme }) => theme.spaces.xxl};
  grid-template-rows: auto 1fr;
  align-items: start;

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    grid-template-columns: 500px 1fr;
    gap: calc(3 * ${({ theme }) => theme.spaces.lg});
  }
`;
