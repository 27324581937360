import { ChangeEventHandler } from "react";
import { Box } from "../../../../ui-kit/containers/box";
import { Typography } from "../../../../ui-kit/typography";
import { Icon, QuestionCircleIcon } from "../../../../ui-kit/icons";
import { Switch } from "../../../../ui-kit/form-controls/switch";
import * as S from "./WebPushesPromptCheckbox.desktop.styled";

export const TypographyBox = Box.withComponent(Typography);

export const SwitchBox = Box.withComponent(Switch);

export interface WebPushesPromptCheckboxDesktopProps {
  onHelpButtonClick: JSX.IntrinsicElements["button"]["onClick"];
  onChange: ChangeEventHandler<HTMLInputElement>;
  isChecked: boolean;
}

export const WebPushesPromptCheckboxDesktop = ({
  onHelpButtonClick,
  onChange,
  isChecked,
}: WebPushesPromptCheckboxDesktopProps) => {
  return (
    <Box display="flex" flexDirection="column" gap="md">
      <Box display="flex" flexDirection="row" alignItems="center" gap="md">
        <SwitchBox
          isFullWidth
          onChange={onChange}
          checked={isChecked}
          labelPosition="before"
          label={
            <TypographyBox fontSize="fz3" lineHeight="defaultLineHeight" fontWeight={500} flexGrow={1} flexShrink={1}>
              Разрешить уведомления в браузере
            </TypographyBox>
          }
        />
        <S.Button aria-label="Подробнее" type="button" onClick={onHelpButtonClick}>
          <Icon as={QuestionCircleIcon} />
        </S.Button>
      </Box>
      {!isChecked && (
        <Typography fontSize="fz2" fontWeight={500} lineHeight="1.4" fontColor="typography.medium">
          Уведомления появляются в операционной системе, вы можете сразу перейти к нужному действию.
        </Typography>
      )}
    </Box>
  );
};
