import { CSSObject, useTheme } from "@emotion/react";
import { UsePopupReturnType } from "../../../pop-up";
import {
  SELECT_POPUP_CONTAINER_ITEM_GAP,
  SELECT_POPUP_CONTAINER_ITEM_ICON_SIZE_PX,
} from "../components/SelectPopupContainerItem";

export const useFilterSelectContainerMinWidth = <T extends Element>(
  popUpContainer: UsePopupReturnType<T>["referenceElementProps"],
  maxItemLength?: number,
): CSSObject["minWidth"] => {
  const theme = useTheme();

  return maxItemLength
    ? `calc(${maxItemLength}ch + ${SELECT_POPUP_CONTAINER_ITEM_ICON_SIZE_PX}px + ${theme.spaces[SELECT_POPUP_CONTAINER_ITEM_GAP]})`
    : `${popUpContainer.width}px`;
};
