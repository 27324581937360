import * as React from "react";
import { Form, Formik } from "formik";
import { queueTasksListMobileFiltersValidationSchema } from "../../../../../../queue-tasks/list/validation/queueTasksListMobileFiltersValidationSchema";
import { Box } from "../../../../../ui-kit/containers/box";
import { FilteringObjectType } from "../../../../types/FilteringObjectType";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { Button } from "../../../../../ui-kit/form-controls/button";

export interface FiltersMobileFormProps<TFiler extends FilteringObjectType> extends BaseCssProps {
  initialValues: TFiler;
  onSubmit: (values: TFiler) => void | Promise<void>;
  children?: React.ReactNode;
}

export const FiltersMobileForm = <TFilter extends FilteringObjectType>({
  initialValues,
  onSubmit,
  className,
  children,
}: FiltersMobileFormProps<TFilter>) => {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={queueTasksListMobileFiltersValidationSchema}
    >
      {() => (
        <Box
          as={Form}
          display="flex"
          flexDirection="column"
          flexShrink={1}
          flexGrow={1}
          padding="lg"
          gap="md"
          className={className}
        >
          <Box flexShrink={1} flexGrow={1}>
            {children}
          </Box>
          <Button variant="contained" color="primary" isFullWidth size="medium" type="submit">
            Применить фильтр
          </Button>
        </Box>
      )}
    </Formik>
  );
};
