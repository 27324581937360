import { BaseCssProps } from "../../../../ui-kit/types";
import { usePopupButton } from "../../../../ui-kit/buttons/hooks/usePopupButton";
import * as S from "./ProductCreationScoringResultSuccessRowInputError.styled";
import { InfoIcon } from "../../../../ui-kit/icons";
import { ProductCreationScoringPopup } from "../popup-button/ProductCreationScoringPopup";

export interface ProductCreationScoringResultSuccessRowInputErrorProps extends BaseCssProps {
  error: string;
}

export const ProductCreationScoringResultSuccessRowInputError = ({
  error,
  className,
}: ProductCreationScoringResultSuccessRowInputErrorProps) => {
  const {
    state: [isOpened],
    buttonProps,
    popupProps,
    arrow,
    placement,
  } = usePopupButton({ position: "left", offset: [0, 10] });

  return (
    <>
      <S.Button type="button" {...buttonProps} className={className}>
        <InfoIcon />
      </S.Button>
      {isOpened && (
        <ProductCreationScoringPopup
          variant="error"
          arrowData={arrow.data}
          arrowRef={arrow.ref}
          placement={placement}
          {...popupProps}
        >
          {error}
        </ProductCreationScoringPopup>
      )}
    </>
  );
};
