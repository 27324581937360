import { useHandleSingleFileFieldChange } from "../../../../../../../files/temp";
import { Typography } from "../../../../../../../common/ui-kit/typography";
import { FileUpload, FileUploadDropzoneInputBlock } from "../../../../../../../files/upload";
import { useGetFieldName } from "../../../../../../../common/form";
import { BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormValuesType } from "../../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormValuesType";
import { BankGuaranteeDetailsComplicatedTaskBillDecisionType } from "../../../types/BankGuaranteeDetailsComplicatedTaskBillDecisionType";

interface BankGuaranteeDetailsComplicatedTaskBillFileInputProps {
  isDisabled?: boolean;
  decision: BankGuaranteeDetailsComplicatedTaskBillDecisionType;
}

export const BankGuaranteeDetailsComplicatedTaskBillFileInput = ({
  isDisabled,
  decision,
}: BankGuaranteeDetailsComplicatedTaskBillFileInputProps) => {
  const getName = useGetFieldName<BankGuaranteeDetailsDemandReconciliationConfirmPaymentFormValuesType>();

  const { file, removeFile, handleFileChange } = useHandleSingleFileFieldChange(getName("file"));

  const changeFileText =
    decision === BankGuaranteeDetailsComplicatedTaskBillDecisionType.ChangesOffer
      ? "Заменить конкурентный счет"
      : "Заменить платежное поручение";

  const uploadFileText =
    decision === BankGuaranteeDetailsComplicatedTaskBillDecisionType.ChangesOffer
      ? "Прикрепить конкурентный счет"
      : "Прикрепить платежное поручение";

  const title =
    decision === BankGuaranteeDetailsComplicatedTaskBillDecisionType.ConfirmPayment
      ? "Если вы можете подтвердить оплату счета клиентом, отправьте нам платежное поручение"
      : undefined;

  return (
    <>
      {title && (
        <Typography lineHeight="defaultLineHeight" fontSize="fz2" fontWeight={500}>
          {title}
        </Typography>
      )}
      {file && <FileUpload fileName={file.file.name} date={file.dateAdded} canRemove onRemove={removeFile} />}
      <FileUploadDropzoneInputBlock
        handleFileChange={handleFileChange}
        text={file ? changeFileText : uploadFileText}
        isDisabled={isDisabled}
        isMultiple={false}
      />
    </>
  );
};
