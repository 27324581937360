import { useMemo } from "react";
import { BankGuaranteeDetailsComplicatedTaskBillFormValues } from "../types/BankGuaranteeDetailsComplicatedTaskBillFormValues";
import { DemandQueueTaskActiveBillInfo } from "../../../../../demand/queue-tasks";
import { bankGuaranteeDetailsComplicatedTaskBillValidationSchema } from "../validation/bankGuaranteeDetailsComplicatedTaskBillValidationSchema";

export const useBankGuaranteeDetailsComplicatedTaskBillFormInitialValues = (
  billInfo?: DemandQueueTaskActiveBillInfo,
): BankGuaranteeDetailsComplicatedTaskBillFormValues => {
  return useMemo(() => {
    const partialValues: Partial<BankGuaranteeDetailsComplicatedTaskBillFormValues> = {
      percent: billInfo?.costFinal.rate,
      sum: billInfo?.costFinal.sum,
    };

    return bankGuaranteeDetailsComplicatedTaskBillValidationSchema.cast(partialValues, {
      assert: false,
      stripUnknown: true,
    }) as BankGuaranteeDetailsComplicatedTaskBillFormValues;
  }, [billInfo?.costFinal.rate, billInfo?.costFinal.sum]);
};
