export const USER_SIGN_FETCHING = "USER_SIGN_FETCHING";
export const USER_SIGN_FETCHED = "USER_SIGN_FETCHED";
export const USER_SIGN_FETCHING_ERROR = "USER_SIGN_FETCHING_ERROR";

export const MANAGER_SIGN_FETCHING = "MANAGER_SIGN_FETCHING";
export const MANAGER_SIGN_FETCHED = "MANAGER_SIGN_FETCHED";
export const MANAGER_SIGN_FETCHING_ERROR = "MANAGER_SIGN_FETCHING_ERROR";

export interface UserSignFetching {
  type: typeof USER_SIGN_FETCHING;
}

export interface UserSignFetched {
  type: typeof USER_SIGN_FETCHED;
}

export interface UserSignFetchingError {
  type: typeof USER_SIGN_FETCHING_ERROR;
}

export interface ManagerSignFetching {
  type: typeof MANAGER_SIGN_FETCHING;
}

export interface ManagerSignFetched {
  type: typeof MANAGER_SIGN_FETCHED;
}

export interface ManagerSignFetchingError {
  type: typeof MANAGER_SIGN_FETCHING_ERROR;
}

export type FileDispatchTypes =
  | UserSignFetching
  | UserSignFetched
  | UserSignFetchingError
  | ManagerSignFetching
  | ManagerSignFetched
  | ManagerSignFetchingError;
