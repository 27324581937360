import * as React from "react";
import * as S from "./Tab.styled";
import { TabsContextType } from "../types/TabsContextType";

export interface TabProps<TTab extends string> extends Omit<S.TabProps, "isActive"> {
  tab: TTab;
  className?: string;
  children?: React.ReactNode;
  text?: string;
}

export const createTabComponent =
  <TTab extends string>(useContext: () => TabsContextType<TTab>) =>
  // eslint-disable-next-line react/function-component-definition
  ({ tab, children, className, text, ...rest }: TabProps<TTab>) => {
    const { activeTab, onTabChange } = useContext();

    return (
      <S.Tab
        className={className}
        isActive={tab === activeTab}
        onClick={() => {
          onTabChange(tab);
        }}
        {...rest}
      >
        {text && <span>{text}</span>}
        {children}
      </S.Tab>
    );
  };
