/* eslint-disable react/no-children-prop */
import * as React from "react";
import { BaseFile } from "../../../../common/types/files";
import { Box } from "../../../../common/ui-kit/containers/box";
import { Text } from "../../../../common/ui-kit/typography";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { DocumentUploadRequiredBadge } from "./DocumentUploadRequiredBadge";
import { FileDownloadLabel } from "../../../../files/download";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { FileUploadDropzoneInputBlock } from "../../../../files/upload";
import { DocumentUploadActiveDocumentContainer } from "./DocumentUploadActiveDocumentContainer";

export interface DocumentUploadActiveDocumentProps extends BaseCssProps {
  title: string;
  description?: string;
  isRequired?: boolean;
  template?: BaseFile;
  oldFiles?: BaseFile[];
  isMultiple?: boolean;
  canUpload: boolean;
  uploadLabelText?: string;
  isLoading?: boolean;
  error?: string;
  onDrop: (acceptedFiles: File[]) => void | Promise<void>;
  children?: React.ReactNode;
}

export const DocumentUploadActiveDocument = ({
  title,
  description,
  isRequired,
  template,
  oldFiles,
  className,
  isMultiple,
  error,
  onDrop,
  canUpload,
  uploadLabelText,
  isLoading,
  children,
}: DocumentUploadActiveDocumentProps) => {
  return (
    <DocumentUploadActiveDocumentContainer
      title={title}
      children={children}
      template={
        template && (
          <FileDownloadLabel fileId={template.fileId} privateKey={template.privateKey} fileName={template.fileName} />
        )
      }
      description={description}
      className={className}
      oldFile={
        oldFiles &&
        oldFiles.length > 0 && (
          <Box pt="md" display="flex" flexDirection="column" gap="md">
            {oldFiles.map((oldFile) => (
              <StackPanel
                key={oldFile.fileId}
                direction="row"
                gap="sm"
                justifyContent="space-between"
                alignItems="center"
              >
                <FileDownloadLabel
                  fileId={oldFile.fileId}
                  privateKey={oldFile.privateKey}
                  fileName={oldFile.fileName}
                />
                <Box flexGrow={0} flexShrink={0}>
                  <Text fontSize="fz2" lineHeight="defaultLineHeight" fontWeight={500} fontColor="primaryCaption">
                    Сохраненный файл
                  </Text>
                </Box>
              </StackPanel>
            ))}
          </Box>
        )
      }
      badge={
        isRequired && (
          <>
            &nbsp;&nbsp;
            <DocumentUploadRequiredBadge variant={oldFiles && oldFiles.length > 0 ? "disabled" : "default"}>
              Обязательный
            </DocumentUploadRequiredBadge>
          </>
        )
      }
      uploader={
        canUpload && (
          <FileUploadDropzoneInputBlock
            handleFileChange={onDrop}
            text={uploadLabelText ?? (isMultiple ? "Прикрепить файлы" : "Прикрепить файл")}
            isLoading={isLoading}
            isDisabled={isLoading}
            isMultiple={isMultiple}
            error={error}
          />
        )
      }
    />
  );
};
