import { useAppSelector } from "../../../../common/store";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { selectBankGuaranteeDocumentsIds } from "../../store/bankGuaranteeDocuments.selectors";
import { BankGuaranteeDocumentsListItemMobile } from "./BankGuaranteeDocumentsListItem.mobile";
import { BankGuaranteeDocumentsUniqueId } from "../../types/BankGuaranteeDocumentsUniqueId";

export const BankGuaranteeDocumentsListMobile = () => {
  const ids = useAppSelector(selectBankGuaranteeDocumentsIds);

  return (
    <StackPanel gap="xl" direction="column">
      {ids.map((x) => (
        <BankGuaranteeDocumentsListItemMobile id={x as BankGuaranteeDocumentsUniqueId} key={x} />
      ))}
    </StackPanel>
  );
};
