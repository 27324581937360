import { ElementColor } from "../../../common/ui-kit/types";
import { CreditExecutionDemandStageType } from "../../common/types";

export const getCreditExecutionDemandStageColorStatus = (
  stage: CreditExecutionDemandStageType,
  hasActiveTasks: boolean
): ElementColor => {
  if (stage === "Rejected") {
    return "typography.medium";
  }

  if (hasActiveTasks) {
    return "primary";
  }

  return "typography.primaryCaption";
};
