import { useMemo } from "react";
import { useAppDispatch } from "../../../common/store";
import { makeProductRejectionOverlayPanelKey, useProductRejectionModalPanel } from "../../../common/product-rejection";
import { pushSuccessGlobalToast } from "../../../global/toasts";
import { creditExecutionDetailsReloadActiveProduct } from "../store/creditExecutionDetails.actions";
import { CreditExecutionId, DemandId } from "../../../common/types/demand";

export const useCreditExecutionDetailsRejection = (creditExecutionId: CreditExecutionId, demandId?: DemandId) => {
  const dispatch = useAppDispatch();
  const panelKey = makeProductRejectionOverlayPanelKey("CreditExecution", creditExecutionId, demandId);
  const [isModalOpen, setIsModalOpen] = useProductRejectionModalPanel(panelKey);

  return useMemo(() => {
    const closeModal = () => setIsModalOpen(false);
    const openModal = () => setIsModalOpen(true);

    const afterSuccess = async (): Promise<void> => {
      closeModal();
      dispatch(pushSuccessGlobalToast("Заявка в банк отменена"));
      dispatch(creditExecutionDetailsReloadActiveProduct());
    };

    return { isModalOpen, openModal, closeModal, afterSuccess, panelKey };
  }, [dispatch, isModalOpen, panelKey, setIsModalOpen]);
};
