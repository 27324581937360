import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { NavigationState } from "../../../../../common/navigation/navigation-state";
import { useAppSelector } from "../../../../../common/store";
import { LegalServicesProductId } from "../../../../common/types";
import { selectLegalServicesCreationById } from "../../../store/legalServicesCreation.selectors";
import { LegalServicesCreationCard } from "../../card/LegalServicesCreationCard";
import { LegalServicesCardDescription } from "../../card/card-description/LegalServicesCardDescription";
import { AppLinkButton } from "../../../../../common/ui/links";

const PRODUCT_DESCRIPTION_LENGTH_DESKTOP = 400;
const PRODUCT_DESCRIPTION_LENGTH_MOBILE = 300;

export interface LegalServicesCreationItemProps {
  productId: LegalServicesProductId;
  from?: NavigationState;
}

export const LegalServicesCreationItem = ({ productId, from }: LegalServicesCreationItemProps) => {
  const product = useAppSelector((state) => selectLegalServicesCreationById(state, productId))!;
  const isDesktop = useThemeIsDesktop();

  return (
    <LegalServicesCreationCard
      title={product.productName}
      description={
        product.description !== undefined && (
          <LegalServicesCardDescription
            text={product.description}
            textLength={isDesktop ? PRODUCT_DESCRIPTION_LENGTH_DESKTOP : PRODUCT_DESCRIPTION_LENGTH_MOBILE}
          />
        )
      }
      price={product.productSum}
      addNote={product.sumConditions}
      linkButton={
        <AppLinkButton
          to={(x) => x.legalServices.creation.product}
          params={{ productId }}
          state={from}
          color={isDesktop ? "secondary" : "primary"}
          variant="contained"
        >
          Перейти к заявке
        </AppLinkButton>
      }
    />
  );
};
