import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import * as S from "./DemandQueueTasksNewComment.styled";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { DemandQueueTasksNewCommentButtonTransition } from "./DemandQueueTasksNewCommentButtonTransition";
import { DemandQueueTasksNewCommentTransition } from "./DemandQueueTasksNewCommentTransition";
import {
  DemandQueueTasksCommentCardForm,
  DemandQueueTasksCommentCardFormProps,
} from "../comment-form/DemandQueueTasksCommentCardForm";
import { PlusIcon } from "../../../../common/ui-kit/icons";
import { Button } from "../../../../common/ui-kit/form-controls/button";

const TIMEOUT = 400;

export interface BankGuaranteeDetailDemandNewCommentProps extends BaseCssProps, DemandQueueTasksCommentCardFormProps {
  canSendComment: boolean;
  isCommentFormOpened: boolean;
  onSendCommentButtonClick: () => void;
}

export const DemandQueueTasksNewComment = ({
  canSendComment,
  className,
  onCancel,
  onSubmit,
  isCommentFormOpened,
  onSendCommentButtonClick,
}: BankGuaranteeDetailDemandNewCommentProps) => {
  return (
    <S.Container className={className}>
      <ThemeMobileDesktopView
        mobile={
          !isCommentFormOpened &&
          canSendComment && (
            <Button variant="contained" color="light" onClick={onSendCommentButtonClick} type="button">
              Написать новое сообщение
            </Button>
          )
        }
        desktop={
          <DemandQueueTasksNewCommentButtonTransition
            timeout={!isCommentFormOpened ? TIMEOUT : TIMEOUT - 100}
            in={!isCommentFormOpened && canSendComment}
            mountOnEnter
            unmountOnExit
          >
            <Button
              alignSelf="flex-end"
              startIcon={PlusIcon}
              variant="contained"
              color="light"
              onClick={onSendCommentButtonClick}
              type="button"
              size="small"
            >
              Написать новое сообщение
            </Button>
          </DemandQueueTasksNewCommentButtonTransition>
        }
      />
      <DemandQueueTasksNewCommentTransition
        timeout={TIMEOUT}
        in={canSendComment && isCommentFormOpened}
        unmountOnExit
        mountOnEnter
      >
        <DemandQueueTasksCommentCardForm onSubmit={onSubmit} onCancel={onCancel} />
      </DemandQueueTasksNewCommentTransition>
    </S.Container>
  );
};
