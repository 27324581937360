import { dateTimeHelper } from "@sideg/helpers";
import { DateFormInput } from "@sideg/ui-kit/atoms/form-controls/date-input";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { Grid } from "../../../../../common/ui-kit/containers/responsive-grid";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useDateField, useGetFieldName } from "../../../../../common/form";
import { BankGuaranteeCreationFromValues } from "../../../types/BankGuaranteeCreationFormValues";

export interface BankGuaranteeCreationTermBlockProps extends BaseCssProps {
  isDisabled?: boolean;
}

export const BankGuaranteeCreationTermBlock = ({ isDisabled, className }: BankGuaranteeCreationTermBlockProps) => {
  const getFieldName = useGetFieldName<BankGuaranteeCreationFromValues>();

  const [dateStartProps] = useDateField(getFieldName("dateStart"));
  const [dateEndProps] = useDateField(getFieldName("dateEnd"));

  return (
    <Grid as={CreationBlockWithTitle} title="Начало и окончание БГ" container gap="md" className={className}>
      <Grid
        item
        as={DateFormInput}
        placeholder="дд.мм.гггг"
        label="Дата начала БГ"
        size="medium"
        xs={12}
        md={6}
        min={new Date()}
        {...dateStartProps}
        autoComplete="off"
        disabled={isDisabled}
      />
      <Grid
        item
        as={DateFormInput}
        placeholder="дд.мм.гггг"
        label="Дата окончания БГ"
        size="medium"
        xs={12}
        md={6}
        min={dateTimeHelper.simpleModify("addDays", new Date(), 1)}
        {...dateEndProps}
        autoComplete="off"
        disabled={isDisabled}
      />
    </Grid>
  );
};
