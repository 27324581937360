import { ProductCreationStages, productCreationStages } from "../../types/ProductCreationStages";

export const useProductCreationIsBeforeActiveStage = (
  currentStage: ProductCreationStages,
  activeStage: ProductCreationStages,
) => {
  const activeStageIndex = productCreationStages.values.indexOf(activeStage);

  return activeStageIndex > productCreationStages.values.indexOf(currentStage);
};
