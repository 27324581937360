import {
  BankGuaranteeDetailsComplicatedTaskProjectDecisionType,
  isBankGuaranteeDetailsComplicatedTaskProjectDecisionProjectAgreed,
} from "../types/BankGuaranteeDetailsComplicatedTaskProjectDecisionType";

const isExists = (
  decision: BankGuaranteeDetailsComplicatedTaskProjectDecisionType | undefined,
): decision is BankGuaranteeDetailsComplicatedTaskProjectDecisionType.ProjectAgreed =>
  isBankGuaranteeDetailsComplicatedTaskProjectDecisionProjectAgreed(decision);

export const bankGuaranteeDetailsComplicatedTaskProjectGuaranteePrintTypeFormRules = {
  isExists,
};
