import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { CreditWorkingAssetsListCountersState } from "./creditWorkingAssetsListCounters.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import { combineSliceNames } from "../../../../common/store/utils";
import {
  CREDIT_WORKING_ASSETS_LIST_SLICE_NAME,
  getCountersCreditWorkingAssetsList,
} from "../creditWorkingAssetsList.thunks";

const initialState: CreditWorkingAssetsListCountersState = {
  status: LoadingStatusEnum.Idle,
  data: undefined,
};

const SLICE_NAME = combineSliceNames(CREDIT_WORKING_ASSETS_LIST_SLICE_NAME, "counters");

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountersCreditWorkingAssetsList.fulfilled, (state, { payload }) => {
        state.data = payload.body;
      })
      .addMatcher(isAsyncThunkAction(getCountersCreditWorkingAssetsList), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const creditWorkingAssetsListCountersReducer = slice.reducer;
