import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { ProductListItemStageCountersContainer } from "../../../../../common/ui/product/stage";
import { creditExecutionDemandStageType } from "../../../../common/types";

export type CreditExecutionListItemStageCountersSkeletonProps = BaseCssProps;

export const CreditExecutionListItemStageCountersSkeleton = ({
  className,
}: CreditExecutionListItemStageCountersSkeletonProps) => {
  return (
    <ProductListItemStageCountersContainer className={className}>
      {creditExecutionDemandStageType.values.map((x) => (
        <Skeleton width={30} height={24} key={x} />
      ))}
    </ProductListItemStageCountersContainer>
  );
};
