import { FileDownloadLabel } from "../../../../../files/download";
import { CompanyFounder, PersonFounder } from "../../../../types/companies";
import { BaseClient } from "../../../../types/demand";
import { isArrayItemsExists } from "../../../../types/utils";
import { Box } from "../../../../ui-kit/containers/box";
import { StackPanel } from "../../../../ui-kit/containers/stack-panel";
import { BaseCssProps } from "../../../../ui-kit/types";
import { Typography } from "../../../../ui-kit/typography";
import { getProductDetailsInformationClientFounderRole } from "../../../utils/client/getProductDetailsInformationClientFounderRole";
import { ProductDetailsInformationListItem } from "../item/ProductDetailsInformationListItem";
import * as S from "./ProductDetailsInformationClientBlock.styled";
import { ProductDetailsInformationListBlock } from "./ProductDetailsInformationListBlock";

export interface ProductDetailsInformationClientBlockProps extends BaseCssProps {
  client: BaseClient;
  personFounders?: PersonFounder[];
  companyFounders?: CompanyFounder[];
}

export const ProductDetailsInformationClientBlock = ({
  client,
  personFounders,
  companyFounders,
  className,
}: ProductDetailsInformationClientBlockProps) => {
  return (
    <ProductDetailsInformationListBlock title="Клиент" className={className}>
      <ProductDetailsInformationListItem title={client.taxpayerNumber} value={client.name} reversed />
      {(isArrayItemsExists(personFounders) || isArrayItemsExists(companyFounders)) && (
        <>
          <ProductDetailsInformationListItem title="Руководитель и учредители" />
          <S.Ol>
            {isArrayItemsExists(personFounders) &&
              personFounders.map((founder) => (
                <li key={founder.id}>
                  <StackPanel direction="column" gap="none">
                    <Typography fontSize="fz4" fontWeight={500} fontColor="typography.primary" lineHeight="1.4">
                      {founder.fullName}
                    </Typography>
                    <Typography fontSize="fz4" fontWeight={500} fontColor="typography.primary" lineHeight="1.4">
                      {getProductDetailsInformationClientFounderRole(founder)}
                      {founder.sharePercent !== undefined &&
                        founder.sharePercent !== 0 &&
                        `, доля ${founder.sharePercent}%`}
                    </Typography>
                    {founder.files && (
                      <Box pt="md">
                        {founder.files.map((file) => {
                          return (
                            <FileDownloadLabel
                              key={file.fileId}
                              fileId={file.fileId}
                              privateKey={file.privateKey}
                              fileName={file.title ?? file.fileName}
                            />
                          );
                        })}
                      </Box>
                    )}
                  </StackPanel>
                </li>
              ))}
            {isArrayItemsExists(companyFounders) &&
              companyFounders.map((founder) => (
                <li key={founder.id}>
                  <StackPanel direction="column" gap="none">
                    <Typography fontSize="fz4" fontWeight={500} fontColor="typography.primary" lineHeight="1.4">
                      {founder.name}
                    </Typography>
                    <Typography fontSize="fz4" fontWeight={500} fontColor="typography.primary" lineHeight="1.4">
                      Учредитель, доля {founder.sharePercent}%
                    </Typography>
                    <Typography fontSize="fz4" fontWeight={400} fontColor="typography.primary" lineHeight="1.4">
                      ИНН&nbsp;{founder.taxpayerNumber}
                    </Typography>
                  </StackPanel>
                </li>
              ))}
          </S.Ol>
        </>
      )}
    </ProductDetailsInformationListBlock>
  );
};
