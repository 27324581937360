import { createSlice, isAnyOf, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { bankGuaranteeDetailsTasksHistoryAdapter } from "./bankGuaranteeDetailsTasksHistory.adapter";
import {
  BANK_GUARANTEE_DETAILS_SLICE_NAME,
  getHistoryBankGuaranteeDetailsDemand,
} from "../bankGuaranteeDetails.thunks";
import { combineSliceNames } from "../../../../common/store/utils";
import {
  bankGuaranteeDetailsActiveDemandChanged,
  bankGuaranteeDetailsGuaranteeChanged,
} from "../bankGuaranteeDetails.actions";
import {
  BankGuaranteeDetailsDemandQueueTaskCompletedState,
  BankGuaranteeDetailsTasksHistorySliceState,
} from "./bankGuaranteeDetailsTasksHistory.types";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../../common/types/state";
import { BankGuaranteeDetailsDemandQueueTaskCompleted } from "../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskCompleted";
import { bankGuaranteeDetailsTasksHistoryMapper } from "./bankGuaranteeDetailsTasksHistory.mapper";
import { isBankGuaranteeDetailsNeedReloadMatcher } from "../bankGuaranteeDetails.matchers";

const SLICE_NAME = combineSliceNames(BANK_GUARANTEE_DETAILS_SLICE_NAME, "tasksHistory");

const initialState =
  bankGuaranteeDetailsTasksHistoryAdapter.getInitialState<BankGuaranteeDetailsTasksHistorySliceState>({
    status: LoadingStatusEnum.Idle,
    activeDemandId: undefined,
    activeTaskGroupType: undefined,
    nextCommentSort: -1,
  });

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    addNewCompletedTask: (state, action: PayloadAction<BankGuaranteeDetailsDemandQueueTaskCompleted>) => {
      bankGuaranteeDetailsTasksHistoryAdapter.upsertOne(
        state,
        bankGuaranteeDetailsTasksHistoryMapper.mapOne(action.payload, state.nextCommentSort),
      );
      state.nextCommentSort -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bankGuaranteeDetailsActiveDemandChanged, (_state, action) => {
        return { ...initialState, activeDemandId: action.payload };
      })
      .addCase(getHistoryBankGuaranteeDetailsDemand.fulfilled, (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          const data = bankGuaranteeDetailsTasksHistoryMapper.formResponse(action.payload);
          if (action.payload.body.paginationInformation?.pageNumber === 1) {
            bankGuaranteeDetailsTasksHistoryAdapter.setAll(state, data);
          } else {
            bankGuaranteeDetailsTasksHistoryAdapter.upsertMany(state, data);
          }
          state.pagination = action.payload.body.paginationInformation;
        }
      })
      .addCase(getHistoryBankGuaranteeDetailsDemand.pending, (state, action) => {
        const actionGroupType = action.meta.arg.groupType;

        const isInitOrNextPageRequest =
          state.activeTaskGroupType === undefined || state.activeTaskGroupType === actionGroupType;

        state.activeTaskGroupType = actionGroupType;
        if (isInitOrNextPageRequest) {
          return;
        }

        if (actionGroupType === "All") {
          bankGuaranteeDetailsTasksHistoryAdapter.removeAll(state);
        } else {
          const entities = state.ids
            .map((id) => state.entities[id])
            .filter(
              (task): task is BankGuaranteeDetailsDemandQueueTaskCompletedState =>
                task?.groupType?.code === actionGroupType,
            );

          bankGuaranteeDetailsTasksHistoryAdapter.setAll(state, entities);
        }
      })
      .addMatcher(isBankGuaranteeDetailsNeedReloadMatcher, (state) => {
        state.status = LoadingStatusEnum.Idle;
        state.pagination = undefined;
      })
      .addMatcher(isAsyncThunkAction(getHistoryBankGuaranteeDetailsDemand), (state, action) => {
        if (action.meta.arg.demandId === state.activeDemandId) {
          const status = getLoadingStatusFromAction(action);
          if (status !== LoadingStatusEnum.Aborted) {
            state.status = status;
          }
        }
      })
      .addMatcher(isAnyOf(bankGuaranteeDetailsGuaranteeChanged), () => initialState);
  },
});

export const bankGuaranteeDetailsTasksHistoryReducer = slice.reducer;

export const { addNewCompletedTask: addNewCompletedTaskBankGuaranteeDetailsTasksHistory } = slice.actions;
