import { object, string } from "yup";
import { UNSELECTED_ENUM_LITERAL } from "../../types/utils";

export const productRejectionFormValidationSchema = object({
  rejectionReasonId: string()
    .required("Выберите причину отмены")
    .notOneOf([UNSELECTED_ENUM_LITERAL], "Выберите причину отмены")
    .default(UNSELECTED_ENUM_LITERAL),
  rejectionReasonComment: string().trim("Укажите детали отмены").required("Укажите детали отмены").default(""),
});
