import { Dispatch } from "redux";
import logger, { loggerForErrorApiResponse, LogLevelEnum } from "../../../essentials/logger";
import { FileApiV3 } from "../../../data/api/v3/FileApiV3";
import {
  managerSignFetched,
  managerSignFetching,
  managerSignFetchingError,
  userSignFetched,
  userSignFetching,
  userSignFetchingError,
} from "./fileActionCreators";
import { lkApiV3 } from "../../../essentials/configs";

export const fetchUserSign = (id: bigint, privateKey?: string) => async (dispatch: Dispatch) => {
  dispatch(userSignFetching());
  const fileApiV3 = new FileApiV3();
  fileApiV3
    .getUserSign(id, privateKey)
    .then((response) => {
      if (response.data.code === 200 && response.data.body) {
        dispatch(userSignFetched());
        window.open(`${lkApiV3()}/File/file-by-key?key=${response.data.body}`, "_blank");
      } else {
        loggerForErrorApiResponse(LogLevelEnum.Error, response);
        dispatch(userSignFetchingError());
      }
    })
    .catch((err) => {
      logger(LogLevelEnum.Error, err);
      dispatch(userSignFetchingError());
    });
};

export const fetchManagerSign = (id: bigint, privateKey?: string) => async (dispatch: Dispatch) => {
  dispatch(managerSignFetching());

  const fileApiV3 = new FileApiV3();
  fileApiV3
    .getManagerSign(id, privateKey)
    .then((response) => {
      if (response.data.code === 200 && response.data.body) {
        dispatch(managerSignFetched());
        window.open(`${lkApiV3()}/File/file-by-key?key=${response.data.body}`, "_blank");
      } else {
        loggerForErrorApiResponse(LogLevelEnum.Error, response);
        dispatch(managerSignFetchingError());
      }
    })
    .catch((err) => {
      logger(LogLevelEnum.Error, err);
      dispatch(managerSignFetchingError());
    });
};
