import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { CreditWorkingAssetsId } from "../../../../common/types/demand";
import { CreditWorkingAssetsListItemDesktop } from "./desktop/CreditWorkingAssetsListItem.desktop";
import { useCreateNavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { CreditWorkingAssetsListItemMobile } from "./mobile/CreditWorkingAssetsListItem.mobile";

export interface CreditWorkingAssetsListItemProps extends BaseCssProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
}

export const CreditWorkingAssetsListItem = ({ className, creditWorkingAssetsId }: CreditWorkingAssetsListItemProps) => {
  const from = useCreateNavigationStateFrom("Мои заявки");

  return (
    <ThemeMobileDesktopView
      desktop={
        <CreditWorkingAssetsListItemDesktop
          creditWorkingAssetsId={creditWorkingAssetsId}
          className={className}
          from={from}
        />
      }
      mobile={
        <CreditWorkingAssetsListItemMobile
          creditWorkingAssetsId={creditWorkingAssetsId}
          from={from}
          className={className}
        />
      }
    />
  );
};
