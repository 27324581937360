import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { useNavigationStateFromWithFallback } from "../../../common/navigation/navigation-state";
import { generatePath } from "../../../common/urls/internal/utils";
import { Redirect } from "../../../common/urls/internal/components";
import { UserWebPushesMobile } from "../components/mobile/UserWebPushes.mobile";
import { isUserSideWebPushesSubscriptionState, useWebPushesSubscriptionStateContext } from "../../../common/web-pushes";

export const UserWebPushesPageMobile = () => {
  const isDesktop = useThemeIsDesktop();
  const subscriptionState = useWebPushesSubscriptionStateContext();

  const { from } = useNavigationStateFromWithFallback(
    generatePath((x) => x.root),
    "На главную",
  );

  const canSubscribe = isUserSideWebPushesSubscriptionState(subscriptionState);

  return (
    <>
      {(isDesktop || !canSubscribe) && <Redirect to={from.url} />}
      {!isDesktop && canSubscribe && <UserWebPushesMobile from={from} />}
    </>
  );
};
