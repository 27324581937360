import { Fragment } from "react";
import { BaseCssProps, ElementColor } from "../../../../../ui-kit/types";
import { Typography } from "../../../../../ui-kit/typography";
import * as S from "./ProductListDetailsContainer.styled";

export interface CreditExecutionCommonDetailsContainerProps extends BaseCssProps {
  items: { term: React.ReactNode; value: React.ReactNode }[];
  termFontColor?: ElementColor;
}

export const ProductListDetailsContainer = ({
  items,
  termFontColor,
  className,
}: CreditExecutionCommonDetailsContainerProps) => {
  return (
    <S.Dl className={className}>
      {items
        .filter((x) => x.value !== undefined)
        .map((x, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            <Typography
              as="dt"
              fontColor={termFontColor ?? "typography.primaryCaption"}
              fontWeight={500}
              lineHeight="defaultLineHeight"
              fontSize="fz2"
            >
              {x.term}
            </Typography>
            <Typography
              as="dd"
              fontColor="typography.primary"
              fontWeight={500}
              lineHeight="defaultLineHeight"
              fontSize="fz2"
            >
              {x.value}
            </Typography>
          </Fragment>
        ))}
    </S.Dl>
  );
};
