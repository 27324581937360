import { AuthenticationAuthServiceStoredTokenService } from "./stored-token/AuthenticationAuthServiceStoredTokenService";
import { AuthenticationApiStoredTokenService } from "./stored-token/AuthenticationApiStoredTokenService";

export class AuthenticationUserService {
  private readonly authServiceToken: AuthenticationAuthServiceStoredTokenService;

  private readonly apiToken: AuthenticationApiStoredTokenService;

  constructor(
    authServiceToken = new AuthenticationAuthServiceStoredTokenService(),
    apiToken = new AuthenticationApiStoredTokenService(),
  ) {
    this.authServiceToken = authServiceToken;
    this.apiToken = apiToken;
  }

  public isAuthenticated() {
    return this.apiToken.isTokenExists() || this.authServiceToken.isTokenExists();
  }

  public getUser() {
    return this.apiToken.getUserTokenDetails() ?? this.authServiceToken.getUserTokenDetails();
  }
}
