import styled from "@emotion/styled";

export const Wrapper = styled.li<{ highlighted: boolean }>`
  width: 100%;
  display: inline-flex;
  gap: ${({ theme }) => theme.spaces.xs};
  flex-direction: column;
  background-color: ${({ theme, highlighted }) =>
    highlighted ? theme.layout.demand.colors.elementAccentBackgroundColor : "transparent"};
  border: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.xl};
  overflow: hidden;
  justify-content: center;
  user-select: none;

  &:hover,
  &:hover {
    background-color: ${({ theme }) => theme.layout.demand.colors.elementAccentBackgroundColor};
  }
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 1.3;
  color: ${({ theme }) => theme.palette.typography.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
`;
