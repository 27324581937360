import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useGetFieldName } from "../../../../../common/form";
import { CreditExecutionCreationFormValues } from "../../../types/creation/CreditExecutionCreationFormValues";
import { closedAuctionRules } from "../../../validation/rules/closedAuction.rules";

export const useCreditExecutionCreationCheckboxFieldsDisabledState = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const getFieldName = useGetFieldName<CreditExecutionCreationFormValues>();

  const {
    values: { federalLaw, isClosedAuction },
    setFieldValue,
  } = useFormikContext<CreditExecutionCreationFormValues>();

  useEffect(() => {
    const isAvailable = closedAuctionRules.isAvailable(federalLaw);
    setIsDisabled(!isAvailable);

    if (!isAvailable && isClosedAuction) {
      setFieldValue(getFieldName("isClosedAuction"), false, true);
    }
  }, [federalLaw, getFieldName, isClosedAuction, setFieldValue]);

  return isDisabled;
};
