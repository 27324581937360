import {
  DemandQueueTaskActive,
  DemandQueueTaskRequestOptionsCard,
  DemandQueueTasksActiveCard,
} from "projects/agent/src/domains/demand/queue-tasks";
import { ElementType, useMemo } from "react";
import { DemandQueueTaskActiveTaskCardComponentProps } from "../../../types/active/DemandQueueTaskActiveTaskCardComponentProps";

export const useDemandQueueTaskActiveTaskCardComponent = <TTask extends DemandQueueTaskActive>(task: TTask) => {
  return useMemo(() => {
    if (task.requestOptions !== undefined && task.decisions !== undefined && task.decisions.length === 1) {
      return DemandQueueTaskRequestOptionsCard as ElementType<DemandQueueTaskActiveTaskCardComponentProps>;
    }

    return DemandQueueTasksActiveCard as ElementType<DemandQueueTaskActiveTaskCardComponentProps>;
  }, [task.decisions, task.requestOptions]);
};
