import styled from "@emotion/styled";

const BUTTON_SIZE_PX = 38;

export const Grid = styled.div`
  display: grid;
  position: relative;
  gap: ${({ theme }) => theme.spaces.md};
  grid-template-columns: minmax(0, 1fr) ${BUTTON_SIZE_PX}px;
  align-items: end;
  grid-template-areas:
    "input1 checker1"
    "input2 checker2"
    "input3 checker3";

  &::after {
    grid-row: 2/4;
    display: block;
    width: 2px;
    background-color: ${({ theme }) => theme.palette.control.secondaryLight};
    content: " ";
    position: absolute;
    top: -${({ theme }) => theme.spaces.md};
    bottom: 0;
    right: ${BUTTON_SIZE_PX / 2}px;
    z-index: 0;
  }
`;

export const Checker = styled.div<{ index: number }>`
  grid-area: ${({ index }) => `checker${index}`};
  z-index: 1;
`;

export const Input = styled.div<{ index: number }>`
  grid-area: ${({ index }) => `input${index}`};
`;
