import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../common/store";
import { sortConditions } from "../../../common/ui/layout/conditions";
import { BankGuaranteeConditionsState } from "./bankGuaranteeConditions.types";
import { FilteringBaseOrderType } from "../../../common/filters";

export const selectBankGuaranteeConditions = (state: RootState): BankGuaranteeConditionsState =>
  state.domains.bankGuarantee.conditions;

export const selectBankGuaranteeFilterConditions = createSelector(
  (state: RootState) => selectBankGuaranteeConditions(state),
  (state: RootState, orderType: FilteringBaseOrderType) => orderType,
  ({ conditions, status }, orderType) => {
    return {
      conditions: conditions ? sortConditions(conditions, orderType) : [],
      status,
    };
  }
);
