import * as React from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Text } from "../../../../common/ui-kit/typography";

export interface BankGuaranteeDocumentsListHeaderTextDesktopProps extends BaseCssProps {
  children?: React.ReactNode;
}

export const BankGuaranteeDocumentsListHeaderTextDesktop = ({
  children,
  className,
}: BankGuaranteeDocumentsListHeaderTextDesktopProps) => {
  return (
    <Text fontWeight={700} fontSize="fz3" lineHeight="defaultLineHeight" className={className}>
      {children}
    </Text>
  );
};
