import * as React from "react";
import { AppLayoutFooter } from "../../../footer";
import * as S from "./AppLayoutPageWithFooterContainer.styled";

export interface AppLayoutPageWithFooterContainerProps {
  children?: React.ReactNode;
}

export const AppLayoutPageWithFooterContainer = ({ children }: AppLayoutPageWithFooterContainerProps) => {
  return (
    <S.Container>
      {children}
      <AppLayoutFooter />
    </S.Container>
  );
};
