import { forwardRef } from "react";
import { NumberFormatBase, NumberFormatBaseProps } from "react-number-format";
import { FormInput, FormInputProps } from "@sideg/ui-kit/atoms/form-controls/input";

export interface FormNumberFormatInputProps
  extends Omit<FormInputProps, "ref" | "type" | "value" | "defaultValue">,
    Omit<NumberFormatBaseProps, "customInput" | "ref" | "getInputRef" | "size"> {
  value?: string;
}

export const FormNumberFormatInput = forwardRef<HTMLInputElement, FormNumberFormatInputProps>((props, ref) => {
  return <NumberFormatBase customInput={FormInput} {...props} getInputRef={ref} />;
});
