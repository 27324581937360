import { combineSliceNames } from "../../../../common/store/utils";
import { createApiThunks } from "../../../../common/store/utils/createApiThunk";
import { CREDIT_EXECUTION_SLICE_NAME } from "../../store";
import { creditExecutionBriefApi } from "../api/creditExecutionBriefApi";

export const CREDIT_EXECUTION_BRIEF_SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_SLICE_NAME, "brief");

export const { getBrief: getBriefCreditExecution } = createApiThunks(
  CREDIT_EXECUTION_BRIEF_SLICE_NAME,
  creditExecutionBriefApi
);
