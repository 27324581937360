import styled from "@emotion/styled";
import { colorWithAlpha } from "../../../ui-kit/utils";

export const ApplicationUpdaterAlertButton = styled.button<{ variant: "primary" | "secondary" }>`
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-family: inherit;
  appearance: none;
  padding: ${({ theme }) => theme.spaces.sm} ${({ theme }) => theme.spaces.lg};
  border: 2px solid
    ${({ theme, variant }) =>
      variant === "primary"
        ? theme.colors.primary
        : colorWithAlpha(theme.palette.background.light, Math.round(255 * 0.25))};
  background-color: ${({ theme, variant }) => (variant === "primary" ? theme.colors.primary : "transparent")};
  border-radius: 8px;
  cursor: pointer;

  &:focus-visible {
    border-color: ${({ theme }) => theme.palette.control.secondaryLight};
  }

  &:hover {
    filter: brightness(0.85);
  }
`;
