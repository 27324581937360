import { ShortFile } from "../../../common/types/files";
import { Text } from "../../../common/ui-kit/typography";
import { DemandActualDocumentContainer } from "./DemandActualDocumentContainer";
import { FileDownloadLabel } from "../../../files/download";

export interface DemandActualDocumentProps {
  documentTypeTitle: string;
  file?: ShortFile;
  className?: string;
}

export const DemandActualDocument = ({ documentTypeTitle, file, className }: DemandActualDocumentProps) => {
  return (
    <DemandActualDocumentContainer
      documentTypeTitle={documentTypeTitle}
      className={className}
      file={
        file ? (
          <FileDownloadLabel fileId={file.fileId} privateKey={file.privateKey} fileName={file.fileName} />
        ) : (
          <Text fontWeight={400} fontColor="colors.dark" fontSize="fz2" lineHeight="defaultLineHeight">
            Нет файла
          </Text>
        )
      }
    />
  );
};
