import { clientV2 } from "../../../../common/api";
import { CalculateBankGuaranteeCalculatorOutputDto } from "./output/CalculateBankGuaranteeCalculatorOutputDto";
import { CalculateBankGuaranteeCalculatorInputDto } from "./input/CalculateBankGuaranteeCalculatorInputDto";

export const bankGuaranteeCalculatorApi = {
  calculate: (model: CalculateBankGuaranteeCalculatorInputDto) => {
    return clientV2.post<CalculateBankGuaranteeCalculatorInputDto, CalculateBankGuaranteeCalculatorOutputDto>(
      `/Demand/ScoreForAllBanks`,
      model
    );
  },
};
