import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { isSucceeded } from "../../../../common/types/state";
import { selectCreditWorkingAssetsCreationCreating } from "../../store/creditWorkingAssetsCreation.selectors";
import { changeStageCreditWorkingAssetCreation } from "../../store/creditWorkingAssetsCreation.slice";

export const useCreditWorkingAssetsCreationChangeScoringStageEffect = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectCreditWorkingAssetsCreationCreating);

  useEffect(() => {
    if (isSucceeded(status)) {
      dispatch(changeStageCreditWorkingAssetCreation("scoring"));
    }
  }, [dispatch, status]);
};
