import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { ProductCreationScoringResultRowDuplicateContainer } from "./ProductCreationScoringResultRowDuplicateContainer";

export type ProductCreationScoringResultRowDuplicateSkeletonProps = BaseCssProps;

export const ProductCreationScoringResultRowDuplicateSkeleton = ({
  className,
}: ProductCreationScoringResultRowDuplicateSkeletonProps) => {
  return (
    <ProductCreationScoringResultRowDuplicateContainer
      className={className}
      bankName={<Skeleton width={150} />}
      action={<Skeleton height={32} width={100} borderRadius={18} />}
    />
  );
};
