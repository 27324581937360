import { LegalServicesCreationForm } from "../components/form/LegalServicesCreationForm";
import { withValidUrlParams } from "../../../common/urls/internal/HOCs";
import { Redirect } from "../../../common/urls/internal/components";
import { legalServicesCreationProductUrlValidationSchema } from "../validation/legalServicesCreationProductUrlValidationSchema";
import { LegalServicesCreationProductUrlParams } from "../types/LegalServicesCreationProductUrlParams";
import { usePageTitle } from "../../../common/navigation/page-title";
import { useScrollToTopOnMount } from "../../../common/hooks/scroll";
import { FullPageLayoutContainer } from "../../../common/ui/layout";
import { ErrorBoundary, ErrorBoundaryDefaultFallbackAlert } from "../../../common/ui/containers/error-boundary";
import { LegalServicesCreationFormHeader } from "../components/form/LegalServicesCreationFormHeader";
import { useLegalServicesCreationPageState } from "../hooks/useLegalServicesCreationPageState";
import { LegalServicesCreationFormSkeleton } from "../components/form/LegalServicesCreationForm.skeleton";
import { LegalServicesCreationResult } from "../components/result/LegalServicesCreationResult";

export interface LegalServicesCreationProductPageProps {
  match: LegalServicesCreationProductUrlParams;
}

const Page = ({ match }: LegalServicesCreationProductPageProps) => {
  const { state, error, onFormSubmitted } = useLegalServicesCreationPageState(match.productId);

  usePageTitle("Новая заявка на юридическую услугу");
  useScrollToTopOnMount();

  return (
    <FullPageLayoutContainer>
      <ErrorBoundary fallback={<ErrorBoundaryDefaultFallbackAlert />}>
        <LegalServicesCreationFormHeader />
        {state === "loading" && <LegalServicesCreationFormSkeleton />}
        {state === "form" && <LegalServicesCreationForm onSubmitted={onFormSubmitted} productId={match.productId} />}
        {(state === "succeeded" || state === "failed") && <LegalServicesCreationResult error={error} state={state} />}
      </ErrorBoundary>
    </FullPageLayoutContainer>
  );
};

export const LegalServicesCreationProductPage = withValidUrlParams(
  Page,
  (urls) => urls.legalServices.creation.product,
  legalServicesCreationProductUrlValidationSchema,
  <Redirect to={(urls) => urls.legalServices.creation.list} />,
);
