import {
  AppLayoutOverlayPanel,
  useAppLayoutActiveOverlayPanel,
} from "../../../../../../common/ui/layout/app-layout-containers";
import { bankGuaranteeListConfigs } from "../../../../configs";
import { FiltersMobilePanel, FiltersSlidersIconButton } from "../../../../../../common/filters/use-cases";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useBankGuaranteeListFilterContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterContext";
import { bankGuaranteeListMobileFiltersValidationSchema } from "../../../../validation/bankGuaranteeListMobileFiltersValidationSchema";
import { BankGuaranteeListFiltersMobileFormContent } from "./form/BankGuaranteeListFiltersMobileFormContent";

export type BankGuaranteeListShowFiltersButtonProps = BaseCssProps;

export const BankGuaranteeListShowFiltersButton = ({ className }: BankGuaranteeListShowFiltersButtonProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const { openPanel, closePanel } = useAppLayoutActiveOverlayPanel(bankGuaranteeListConfigs.filtersOverlayPanelKey);

  return (
    <>
      <FiltersSlidersIconButton onClick={openPanel} className={className} />
      <AppLayoutOverlayPanel panelKey={bankGuaranteeListConfigs.filtersOverlayPanelKey}>
        <FiltersMobilePanel
          filteringObject={filteringObject}
          schema={bankGuaranteeListMobileFiltersValidationSchema}
          onCloseClick={closePanel}
        >
          <BankGuaranteeListFiltersMobileFormContent />
        </FiltersMobilePanel>
      </AppLayoutOverlayPanel>
    </>
  );
};
