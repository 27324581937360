import { forwardRef, Fragment } from "react";
import { DemandQueueTaskCard } from "../card/DemandQueueTaskCard";
import { DemandQueueTaskComment } from "../comment/DemandQueueTaskComment";
import { Box } from "../../../../common/ui-kit/containers/box";
import { DemandQueueTaskSeparator } from "../separator/DemandQueueTaskSeparator";
import { DemandQueueTaskActiveFormContainer } from "../active-form/DemandQueueTaskActiveFormContainer";
import { DemandQueueTaskActiveFormDecisionWithCommentFields } from "../active-form/DemandQueueTaskActiveFormDecisionWithCommentFields";
import { DemandQueueTaskAttachedFilesCollapse } from "../attached-file/DemandQueueTaskAttachedFilesCollapse";
import { DemandQueueTaskCardContainer } from "../card/DemandQueueTaskCardContainer";
import { DemandQueueTasksRequestedDocumentWithRealTime } from "../requested-document/DemandQueueTasksRequestedDocumentWithRealTime";
import { DemandQueueTasksRequestedDocumentField } from "../requested-document/DemandQueueTasksRequestedDocumentField";
import { DemandQueueTaskActiveFormSubmitButton } from "../active-form/DemandQueueTaskActiveFormSubmitButton";
import { DemandQueueTaskActiveTaskCardComponentProps } from "../../types/active/DemandQueueTaskActiveTaskCardComponentProps";
import * as S from "./DemandQueueTasksActiveCard.styled";

export const DemandQueueTasksActiveCard = forwardRef<HTMLDivElement, DemandQueueTaskActiveTaskCardComponentProps>(
  ({ className, task, isFocused, customTitle }, ref) => {
    return (
      <DemandQueueTaskCard
        className={className}
        variant="active"
        title={customTitle ?? task.taskTitle}
        date={new Date(task.dateCreated)}
        ref={ref}
        isFocused={isFocused}
        isPinned={task.isPinned}
      >
        {task.action.message && <DemandQueueTaskComment author="Менеджер банка" text={task.action.message} />}
        {task.action.providedFiles && (
          <DemandQueueTaskAttachedFilesCollapse
            files={task.action.providedFiles.map((x) => ({ title: x.documentName, ...x.file }))}
          />
        )}
        <DemandQueueTaskSeparator />
        <DemandQueueTaskActiveFormContainer task={task}>
          {task.action.requestedFiles && task.action.requestedFiles.length > 0 && (
            <S.RequestedFiles>
              {task.action.requestedFiles.map((x) => (
                <Fragment key={x.id}>
                  {task.canRealTimeUpload && (
                    <DemandQueueTasksRequestedDocumentWithRealTime queueTaskId={task.id} document={x} />
                  )}
                  {!task.canRealTimeUpload && <DemandQueueTasksRequestedDocumentField document={x} />}
                </Fragment>
              ))}
            </S.RequestedFiles>
          )}
          {task.decisions && (
            <Box as={DemandQueueTaskCardContainer} padding="md" display="flex" flexDirection="column" gap="lg">
              <DemandQueueTaskActiveFormDecisionWithCommentFields decisions={task.decisions} />
              <DemandQueueTaskActiveFormSubmitButton decisions={task.decisions} />
            </Box>
          )}
        </DemandQueueTaskActiveFormContainer>
      </DemandQueueTaskCard>
    );
  },
);
