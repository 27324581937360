import { forwardRef, useMemo } from "react";
import { CommonButtonChildrenWithIcons } from "../components/common/CommonButtonChildrenWithIcons";
import { ButtonTag } from "../types/ButtonTag";
import { ButtonHTMLElement, MakeButtonProps } from "../types/ButtonProps";
import { ButtonContained } from "../components/contained/ButtonContained";
import { ButtonOutlined } from "../components/outline/ButtonOutlined";

export const makeButtonElement = <TTag extends ButtonTag>(tag: TTag) => {
  return forwardRef<ButtonHTMLElement<TTag>, MakeButtonProps<TTag>>(
    ({ startIcon, endIcon, children, variant = "contained", size = "default", color = "primary", ...props }, ref) => {
      const Element = useMemo(() => {
        const component = variant === "contained" ? ButtonContained : ButtonOutlined;

        return component.withComponent(tag);
      }, [variant]);

      return (
        <Element size={size} color={color} ref={ref} {...props}>
          <CommonButtonChildrenWithIcons startIcon={startIcon} endIcon={endIcon}>
            {children}
          </CommonButtonChildrenWithIcons>
        </Element>
      );
    },
  );
};
