import * as S from "./UserProfileInfoAgent.styled";
import { AgencyCompany } from "../../../agency/details/types/AgencyCompany";
import { AgencyBankAccount } from "../../../agency/details/types/AgencyBankAccount";
import { AgencyAddress } from "../../../agency/details/types/AgencyAddress";
import { AgencyContact } from "../../../agency/details/types/AgencyContact";
import { Text } from "../../../common/ui-kit/typography";
import { CreationNote } from "../../../common/ui-kit/notifications/note";

interface UserProfileInfoAgentProps {
  company?: AgencyCompany;
  bankAccount?: AgencyBankAccount;
  address?: AgencyAddress;
  contact?: AgencyContact;
}

export const UserProfileInfoAgent = ({ company, bankAccount, address, contact }: UserProfileInfoAgentProps) => {
  return (
    <S.ProfileAgentWrapper>
      <S.InfoWrapper>
        {company && (
          <>
            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>Наименование</S.InfoAgentTitle>
              <span>{company.name ?? "—"}</span>
            </S.InfoAgentWrapper>
            <S.InfoAgentWrapper>
              <Text fontWeight={700} fontSize="fz3">
                ИНН
              </Text>
              <span>{company.inn ?? "—"}</span>
            </S.InfoAgentWrapper>
            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>КПП</S.InfoAgentTitle>
              <span>{company.kpp ?? "—"}</span>
            </S.InfoAgentWrapper>
            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>ОГРН</S.InfoAgentTitle>
              <span>{company.ogrn ?? "—"}</span>
            </S.InfoAgentWrapper>
          </>
        )}
        {address && (
          <>
            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>Юридический адрес</S.InfoAgentTitle>
              <span>{address.addressLegal ?? "—"}</span>
            </S.InfoAgentWrapper>
            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>Почтовый адрес</S.InfoAgentTitle>
              <span>{address.postAddress ?? "—"}</span>
            </S.InfoAgentWrapper>
          </>
        )}
      </S.InfoWrapper>
      {contact && (
        <>
          <Text fontSize={1.375} lineHeight="28px" fontWeight={700}>
            Контакты
          </Text>
          <S.InfoWrapper>
            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>Телефон</S.InfoAgentTitle>
              <span>{contact.phone}</span>
            </S.InfoAgentWrapper>
            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>Электронная почта</S.InfoAgentTitle>
              <span>{contact.email}</span>
            </S.InfoAgentWrapper>
          </S.InfoWrapper>
        </>
      )}
      {bankAccount && (
        <>
          <Text fontSize={1.375} lineHeight="28px" fontWeight={700}>
            Реквизиты
          </Text>
          <S.InfoWrapper>
            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>БИК банка</S.InfoAgentTitle>
              <span>{bankAccount.bik ?? "—"}</span>
            </S.InfoAgentWrapper>
            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>Наименование банка</S.InfoAgentTitle>
              <span>{bankAccount.bankName ?? "—"}</span>
            </S.InfoAgentWrapper>
            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>К/С</S.InfoAgentTitle>
              <span>{bankAccount.correspondentAccount ?? "—"}</span>
            </S.InfoAgentWrapper>

            <S.InfoAgentWrapper>
              <S.InfoAgentTitle>Р/С</S.InfoAgentTitle>
              <span>{bankAccount.paymentAccount ?? "—"}</span>
            </S.InfoAgentWrapper>
          </S.InfoWrapper>
        </>
      )}
      <CreationNote as="p" fontSize="fz2">
        Для изменения профиля агента или реквизитов Вам необходимо обратиться к персональному менеджеру.
      </CreationNote>
    </S.ProfileAgentWrapper>
  );
};
