import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  Delivery: "Доставка по адресу",
  Office: "В офисе банка",
};

export const bankGuaranteeRequestOriginalDeliveryType = makeEnumLikeLiteral(["Office", "Delivery"] as const, titles);

export type BankGuaranteeRequestOriginalDeliveryType = EnumLikeLiteralType<
  typeof bankGuaranteeRequestOriginalDeliveryType
>;
