import { useCallback } from "react";
import { useAppDispatch } from "../../../../common/store";
import { BankGuaranteeId } from "../../../../common/types/demand";
import { getBankGuaranteeSingleListItem } from "../../store/bankGuaranteeList.thunks";
import { changeBankGuaranteeListItem } from "../../store/items/bankGuaranteeListItems.slice";
import { useBankGuaranteeListFilterContext } from "../filter-context/useBankGuaranteeListFilterContext";
import logger, { LogLevelEnum } from "../../../../../essentials/logger";
import { pushErrorGlobalToast } from "../../../../global/toasts";

export const useBankGuaranteeListFetchSingleItem = (guaranteeId: BankGuaranteeId) => {
  const dispatch = useAppDispatch();
  const {
    bankIds,
    guaranteeTypes,
    dateCreateFrom,
    dateCreateTo,
    tab,
    status,
    search,
    taskTypes,
    requestOriginalStatuses,
  } = useBankGuaranteeListFilterContext();

  return useCallback(async () => {
    try {
      const result = await dispatch(
        getBankGuaranteeSingleListItem({
          productId: guaranteeId,
          bankIds,
          guaranteeTypes,
          dateCreateFrom,
          dateCreateTo,
          tab,
          status,
          search,
          taskTypes,
          requestOriginalStatuses,
          page: 1,
          demandIdOrProductId: undefined,
          clientNameOrTaxpayerNumber: undefined,
        })
      ).unwrap();

      dispatch(
        changeBankGuaranteeListItem({ id: guaranteeId, item: result?.guaranteeId === guaranteeId ? result : undefined })
      );
    } catch (e) {
      logger(LogLevelEnum.Error, e);
      dispatch(pushErrorGlobalToast("Не удалось обновить данные о заявке"));
    }
  }, [
    bankIds,
    dateCreateFrom,
    dateCreateTo,
    dispatch,
    guaranteeId,
    guaranteeTypes,
    requestOriginalStatuses,
    search,
    status,
    tab,
    taskTypes,
  ]);
};
