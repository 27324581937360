import styled from "@emotion/styled";
import { BaseInputField } from "../../../common/components/BaseFormInputElement.styled";
import { StyledContainerWithCustomScroll } from "../../../../containers/components/StyledContainerWithCustomScroll";

export const Button = styled(BaseInputField.withComponent("button"))`
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz4};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.typography.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > svg {
    flex-grow: 0;
    flex-shrink: 0;
    transform: rotate(-90deg);

    & > path {
      stroke: ${({ theme }) => theme.palette.typography.primary};
    }
  }
`;

export const Ul = styled(StyledContainerWithCustomScroll.withComponent("ul"))<{ isOpen: boolean }>`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: ${({ isOpen, theme }) => (isOpen ? `1px solid ${theme.palette.input.fieldsetBorder}` : "none")};
  box-shadow: 0 4px 12px rgba(10, 71, 137, 0.2);
  border-radius: 4px;
  width: 100%;
  z-index: 1;
  overflow: auto;
  max-height: max(400px, 50vh);
`;

export const Li = styled.li<{ selected: boolean; highlighted: boolean; disabled: boolean }>`
  font-weight: 500;
  position: relative;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 1.5;
  padding: ${({ theme }) => theme.spaces.md} ${({ theme }) => theme.spaces.md} ${({ theme }) => theme.spaces.md}
    calc(${({ theme }) => theme.spaces.md} + 16px);
  cursor: pointer;
  user-select: none;
  background-color: ${({ theme, highlighted, disabled }) =>
    // eslint-disable-next-line no-nested-ternary
    disabled
      ? theme.palette.input.disabled.background
      : highlighted
      ? theme.palette.input.hover.background
      : "transparent"};

  & > svg {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${({ theme }) => theme.spaces.sm};

    & > path {
      stroke: ${({ theme }) => theme.palette.typography.primary};
    }
  }
`;
