import cn from "classnames";
import { useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import Calendar, { CalendarProps } from "react-calendar";
import DateFormatter from "../../../../../essentials/helpers/DateFormatter";
import DateHelper from "../../../../../essentials/helpers/DateHelper";
import { ReactComponent as Icon } from "../../../../../images/icons/calendar.svg";
import styles from "./ClientFormCalendar.module.css";

interface ClientFormCalendarProps {
  label?: string;
  name: string;
  value: Date | null;
  minDate?: Date;
  fullwidth?: boolean;
  errors?: any;
  touched?: any;
  disabled?: boolean;
  isEmptyDateValue?: boolean;
}

const ClientFormCalendar: React.FC<ClientFormCalendarProps> = ({
  label = "Выбор даты",
  name,
  value,
  minDate,
  fullwidth,
  errors,
  touched,
  disabled,
  isEmptyDateValue,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const calendar = useRef<HTMLDivElement | null>(null);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    value && setInputValue(DateFormatter.getDateWithYear(value));
    isEmptyDateValue && setInputValue("");
  }, [value, isEmptyDateValue]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideCalendar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCalendar);
    };
  }, [calendar]);

  const handleClickOutsideCalendar = (e: any) => {
    if (calendar?.current && !calendar.current.contains(e.target)) closeCalendar();
  };

  const handleInputChange = (e: React.ChangeEvent<any>): void => {
    if (e.target.value === "") {
      setFieldValue(name, null);
    }

    const value = DateHelper.applyMask(e.target.value);

    if (DateHelper.exists(value)) {
      const newDate = DateHelper.asObject(value);
      setFieldValue(name, newDate);
    }

    setInputValue(value);
  };

  const handleCalendarDateSelection: CalendarProps["onChange"] = (date): void => {
    if (Array.isArray(date)) return;

    setFieldValue(name, date);
    closeCalendar();
  };

  const closeCalendar = (): void => {
    setShow(false);
  };

  const openCalendar = (): void => {
    if (!disabled) {
      setShow(true);
    }
  };

  return (
    <div className={styles[fullwidth ? "wrapper-fullwidth" : "wrapper"]}>
      {show && (
        <div className={styles["calendar-wrapper"]} ref={calendar}>
          <div className={styles["calendar-header"]}>
            <span className={styles["calendar-title"]}>Выберите дату</span>
            <span className={styles["calendar-close-button"]} onClick={closeCalendar}>
              ×
            </span>
          </div>
          <Calendar
            minDate={minDate}
            className={styles["react-calendar-redefine"]}
            tileClassName={styles["react-calendar-tile-redefine"]}
            value={value}
            onChange={handleCalendarDateSelection}
          />
        </div>
      )}
      <div
        className={cn({
          [styles["input-wrapper"]]: true,
          [styles["input-wrapper-error"]]: touched && errors,
          [styles["input-wrapper-disabled"]]: disabled,
        })}
      >
        <label
          className={cn({
            [styles["label"]]: true,
            [styles["label-error"]]: touched && errors,
            [styles["label-disabled"]]: disabled,
          })}
        >
          {label}
        </label>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          className={cn({
            [styles["input"]]: !fullwidth,
            [styles["input-fullwidth"]]: fullwidth,
            [styles["input-disabled"]]: disabled,
          })}
        />
        <Icon
          className={cn({
            [styles["icon"]]: true,
            [styles["icon-disabled"]]: disabled,
          })}
          onClick={openCalendar}
        />
      </div>
      {errors && touched && <div className={styles["error"]}>{errors}</div>}
    </div>
  );
};

export default ClientFormCalendar;
