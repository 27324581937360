import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { useNavigationStateFromWithFallback } from "../../../../common/navigation/navigation-state";
import { NewsDetailsHeaderMobile } from "./NewsDetailsHeader.mobile";
import { selectAppUrl } from "../../../../common/urls/internal/utils";

export const NewsDetailsHeader = () => {
  const { from } = useNavigationStateFromWithFallback(selectAppUrl((x) => x.news.list.root).url);

  return <ThemeMobileDesktopView mobile={<NewsDetailsHeaderMobile from={from} />} desktop={undefined} />;
};
