import * as React from "react";
import * as S from "./ProductCreationBriefItem.styled";
import { BaseCssProps } from "../../../ui-kit/types";

export interface ProductCreationBriefItemProps extends BaseCssProps {
  title: React.ReactNode;
  description: React.ReactNode;
}

export const ProductCreationBriefItem = ({ title, description, className }: ProductCreationBriefItemProps) => {
  return (
    <S.Wrapper className={className}>
      <dt>{title}</dt>
      <dd>{description}</dd>
    </S.Wrapper>
  );
};
