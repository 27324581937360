import styled from "@emotion/styled";
import { DownloadIcon, FileIcon } from "../../../icons";
import { Text } from "../../../typography";

export const IconFile = styled(FileIcon)``;

export const IconDownload = styled(DownloadIcon)``;

export const FileName = styled(Text)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Icon = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  flex-grow: 0;
  flex-shrink: 0;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Button = styled.button<{ showDefaultIcon: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spaces.sm};
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0.125rem 0.625rem;
  max-width: 100%;
  overflow: hidden;
  user-select: none;
  appearance: none;
  position: relative;
  background-color: ${({ theme }) => theme.palette.fileLabel.default};
  -webkit-tap-highlight-color: ${({ theme }) => theme.palette.fileLabel.hover};

  & > ${FileName} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:not(:hover) {
    & ${IconFile} {
      display: ${({ showDefaultIcon }) => (showDefaultIcon ? "block" : "none")};
    }

    & ${IconDownload} {
      display: none;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.fileLabel.hover};

    & ${IconFile} {
      display: none;
    }

    & ${IconDownload} {
      display: ${({ showDefaultIcon }) => (showDefaultIcon ? "block" : "none")};
    }
  }
`;
