import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BankGuaranteeId, DemandId } from "../../../common/types/demand";
import { BankGuaranteeReissueModal } from "./desktop/BankGuaranteeReissueModal";
import { BankGuaranteeReissueOverlayPanel } from "./mobile/BankGuaranteeReissueOverlayPanel";

interface BankGuaranteeReissueResponsiveViewProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

export const BankGuaranteeReissueResponsiveView = ({
  guaranteeId,
  demandId,
  isOpen,
  onSuccess,
  onClose,
}: BankGuaranteeReissueResponsiveViewProps) => {
  return (
    <ThemeMobileDesktopView
      desktop={
        <BankGuaranteeReissueModal
          guaranteeId={guaranteeId}
          demandId={demandId}
          onSuccess={onSuccess}
          onClose={onClose}
          isShown={isOpen}
        />
      }
      mobile={
        <BankGuaranteeReissueOverlayPanel
          guaranteeId={guaranteeId}
          demandId={demandId}
          onSuccess={onSuccess}
          onClose={onClose}
        />
      }
    />
  );
};
