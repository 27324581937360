import { Form, Formik, FormikProps } from "formik";
import { FormTextarea } from "@sideg/ui-kit/atoms/form-controls/textarea";
import { FormResponsiveRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox/components/radio-group/FormResponsiveRadioButtonGroup";
import { useGetFieldName, useGetFormFieldError } from "../../../../common/form";
import { isFailed } from "../../../../common/types/state";
import { SimpleOpacityTransition } from "../../../../common/ui-kit/animations/transitions";
import { AlertLoadingToResult } from "../../../../common/ui-kit/notifications/alert-with-indicator";
import { bankGuaranteeReissueFormValidationSchema } from "../../validation/bankGuaranteeReissueFormValidationSchema";
import { BankGuaranteeReissueFormValues } from "../../types/BankGuaranteeReissueFormValues";
import { reissueBankGuarantee } from "../../store/bankGuaranteeReissue.thunks";
import { BankGuaranteeId, DemandId } from "../../../../common/types/demand";
import { BankGuaranteeReissueFormAttachFileBlock } from "./attach-file-block/BankGuaranteeReissueFormAttachFileBlock";
import { BlockResponsiveSubmitButton } from "../../../../common/ui-kit/buttons/block-responsive-submit";
import { Box } from "../../../../common/ui-kit/containers/box";
import { useApiMutation } from "../../../../common/hooks";
import { bankGuaranteeReissueTypeString } from "../../types/BankGuaranteeReissueType";
import { EnumLikeLiteralType } from "../../../../common/types/utils";
import { CreationBlockCard } from "../../../../common/ui-kit/cards/creation-block-card";

interface BankGuaranteeReissueFormProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  onSuccess: () => void;
}

export const BankGuaranteeReissueForm = ({ guaranteeId, demandId, onSuccess }: BankGuaranteeReissueFormProps) => {
  const { status, error, mutate } = useApiMutation(reissueBankGuarantee, {
    errorMessagePlaceholder:
      "Произошла непредвиденная ошибка. Попробуйте еще раз или свяжитесь с персональным менеджером",
  });

  const getFieldError = useGetFormFieldError<BankGuaranteeReissueFormValues>();
  const getFieldName = useGetFieldName<BankGuaranteeReissueFormValues>();

  const handleFormSubmit = async (values: BankGuaranteeReissueFormValues) => {
    const castedValue = bankGuaranteeReissueFormValidationSchema.cast(values);
    const requestBody = {
      reasonId: castedValue.reissueReason,
      userProject: castedValue.file.file,
      comment: castedValue.comment,
    };

    const result = await mutate({ demandId, guaranteeId, ...requestBody });
    if (result?.body) {
      onSuccess();
    }
  };

  return (
    <Formik
      initialValues={bankGuaranteeReissueFormValidationSchema.getDefault()}
      validationSchema={bankGuaranteeReissueFormValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {(form: FormikProps<BankGuaranteeReissueFormValues>) => (
        <Box
          as={Form}
          display="flex"
          flexDirection="column"
          gap="lg"
          flexGrow={1}
          flexShrink={1}
          padding={{ xs: "lg", xl: "none" }}
        >
          <Box display="flex" flexDirection="column" flexGrow={1} gap="lg">
            <Box as={CreationBlockCard} padding="md" display="flex" gap="lg">
              <FormResponsiveRadioButtonGroup
                value={
                  form.values.reissueReason?.toString() as EnumLikeLiteralType<typeof bankGuaranteeReissueTypeString>
                }
                onChange={async (newValue) => {
                  await form.setFieldValue(getFieldName("reissueReason"), newValue, true);
                }}
                onBlur={async () => {
                  await form.setFieldTouched(getFieldName("reissueReason"), true, true);
                }}
                label="Причина перевыпуска"
                types={bankGuaranteeReissueTypeString.values}
                getTitle={bankGuaranteeReissueTypeString.getTitle}
                radioSize="default"
                name={getFieldName("reissueReason")}
                error={getFieldError(form, "reissueReason")}
                disabled={form.isSubmitting}
              />
              <FormTextarea
                label="Комментарий"
                minRows={2}
                {...form.getFieldProps(getFieldName("comment"))}
                error={getFieldError(form, "comment")}
                autoComplete="off"
                disabled={form.isSubmitting}
              />
            </Box>
            <CreationBlockCard padding="md">
              <BankGuaranteeReissueFormAttachFileBlock
                name={getFieldName("file")}
                error={getFieldError(form, "file")}
                isDisabled={!form.isSubmitting}
              />
            </CreationBlockCard>
          </Box>
          <SimpleOpacityTransition timeout={250} in={isFailed(status)} mountOnEnter unmountOnExit>
            <AlertLoadingToResult isError={isFailed(status)} errorText={error} />
          </SimpleOpacityTransition>
          <BlockResponsiveSubmitButton type="submit" isLoading={form.isSubmitting}>
            Отправить
          </BlockResponsiveSubmitButton>
        </Box>
      )}
    </Formik>
  );
};
