import { Key } from "react";
import { CheckIcon, Icon } from "../../../../icons";
import { BaseCssProps } from "../../../../types";
import { SelectPopupContainerItemJustified } from "../SelectPopupContainerItemJustified";
import { useFilterSelectContainerMinWidth } from "../../hooks/useFilterSelectContainerMinWidth";
import * as S from "./FilterMultipleSelectList.styled";
import { useMultiselectPopupMemo } from "../../hooks/useMultiselectPopup";
import { SelectPopupContainer } from "../SelectPopupContainer";

export interface FilterComboboxMultipleSelectListProps<TItem> extends BaseCssProps {
  selectedItems?: TItem[];
  getItemTitle: (item: TItem) => string;
  onSelectedChanged: (selectedItems: TItem[]) => void | Promise<void>;
  getItems: () => TItem[];
  text: string;
  maxItemsLength?: number;
}

export const FilterMultipleSelectList = <TItem,>({
  selectedItems,
  getItemTitle,
  onSelectedChanged,
  text,
  getItems,
  className,
  maxItemsLength,
}: FilterComboboxMultipleSelectListProps<TItem>) => {
  const { isOpen, highlightedIndex, getItemProps, popupReferenceElementProps, referenceProps, floatingProps } =
    useMultiselectPopupMemo({
      selectedItems,
      getItems,
      onSelectedChanged,
      popupOffset: [0, 2],
    });

  const minWidth = useFilterSelectContainerMinWidth(popupReferenceElementProps, maxItemsLength);

  return (
    <S.Wrapper className={className}>
      <S.Button opened={isOpen} {...referenceProps}>
        {text}
      </S.Button>
      <SelectPopupContainer open={isOpen} {...floatingProps} minWidth={minWidth}>
        {isOpen &&
          getItems().map((item, index) => (
            <SelectPopupContainerItemJustified
              highlighted={highlightedIndex === index}
              active={selectedItems?.includes(item) === true}
              key={item as Key}
              {...getItemProps({ item, index })}
            >
              <span>{getItemTitle(item)}</span>
              {selectedItems?.includes(item) && <Icon as={CheckIcon} stroke="success" />}
            </SelectPopupContainerItemJustified>
          ))}
      </SelectPopupContainer>
    </S.Wrapper>
  );
};
