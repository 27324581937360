import styled from "@emotion/styled";
import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { QueueTaskId } from "../../../../common/types/demand";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectQueueTasksNotificationById } from "../../store/queueTasksNotifications.selectors";
import { StyledRouterLink } from "../../../../common/ui/links";
import { XSquareIcon } from "../../../../common/ui-kit/icons";
import { lightenDarkenColor } from "../../../../common/ui-kit/utils";
import { ProductListItemEllipsisSpan } from "../../../../common/ui/product/list/product-list-item";
import { QueueTasksNotificationProductWrapper } from "./QueueTasksNotificationProductWrapper";
import { QueueTasksNotificationComment } from "./QueueTasksNotificationComment";
import { isFailed, isIdle, isLoading } from "../../../../common/types/state";
import { readQueueTasksNotification } from "../../store/queueTasksNotifications.thunks";
import { DotsLoader } from "../../../../common/ui-kit/loaders/DotsLoader";
import { QueueTasksNotificationActionButton } from "./QueueTasksNotificationActionButton";
import { useReachGoalAnalytics } from "../../../../common/analytics/hooks";
import { useQueueTasksListProductUrl } from "../../../common/hooks";

const StyledArticle = styled.article`
  background-color: ${({ theme }) => theme.layout.header.popup.accentItemColor};
  padding: 8px 12px;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  width: 100%;
`;

const StyledHeader = styled.header`
  display: grid;
  gap: ${({ theme }) => theme.spaces.sm};
  grid-template-columns: 1fr auto auto;
  margin-bottom: ${({ theme }) => theme.spaces.xs};

  & > * {
    overflow: hidden;
  }
`;

const StyledH4 = styled(ProductListItemEllipsisSpan.withComponent("h4"))`
  font-weight: 700;
`;

const StyledTime = styled.time`
  color: ${({ theme }) => theme.colors.secondary};
`;

const StyledMarkAsRead = styled.div`
  min-width: 24px;
`;

const StyledMarkAsReadButton = styled.button`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  appearance: none;

  & > svg > path {
    stroke: ${({ theme }) => theme.colors.secondary};
    transition: all 0.1s linear;
  }
  &:focus,
  &:hover {
    & > svg {
      & > path {
        stroke: ${({ theme }) => lightenDarkenColor(theme.colors.secondary, -30)};
      }
    }
  }
`;

const StyledActionsLine = styled.div`
  display: inline-flex;
  gap: ${({ theme }) => theme.spaces.xs};
  margin-top: ${({ theme }) => theme.spaces.sm};
`;

export interface QueueTasksNotificationsListItemProps {
  queueTaskId: QueueTaskId;
}

export const QueueTasksNotification = ({ queueTaskId }: QueueTasksNotificationsListItemProps) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const entity = useAppSelector((state) => selectQueueTasksNotificationById(state, queueTaskId))!;
  const demandUrl = useQueueTasksListProductUrl(entity.product);
  const reachGoal = useReachGoalAnalytics();

  const canMarkAsRead =
    entity.read?.status === undefined || isIdle(entity.read?.status) || isFailed(entity.read?.status);

  const onRead = () => {
    reachGoal("QUEUE_TASKS_NOTIFICATIONS_MARK_AS_READ_BUTTON_CLICK");
    dispatch(readQueueTasksNotification(queueTaskId));
  };

  return (
    <StyledArticle>
      <StyledHeader>
        <StyledH4>
          <StyledRouterLink title="Перейти к заявке" to={demandUrl}>
            {entity.title}
          </StyledRouterLink>
        </StyledH4>
        <StyledTime
          dateTime={entity.dateAdded}
          title={dateTimeHelper.format(
            new Date(entity.dateAdded),
            specialFormatters.dayFullMonthYearIfDifferentAndTime,
          )}
        >
          {dateTimeHelper.getDistanceToNowStrict(new Date(entity.dateAdded))}
        </StyledTime>
        <StyledMarkAsRead>
          {canMarkAsRead && (
            <StyledMarkAsReadButton onClick={onRead}>
              <XSquareIcon />
            </StyledMarkAsReadButton>
          )}
          {isLoading(entity.read?.status) && <DotsLoader />}
        </StyledMarkAsRead>
      </StyledHeader>
      <QueueTasksNotificationProductWrapper product={entity.product} />
      {entity.comment && <QueueTasksNotificationComment comment={entity.comment} />}
      {entity.actions && (
        <StyledActionsLine>
          {entity.actions.map((x) => (
            <QueueTasksNotificationActionButton queueTaskId={entity.id} action={x} key={x.code} />
          ))}
        </StyledActionsLine>
      )}
    </StyledArticle>
  );
};
