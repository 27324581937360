import { combineReducers } from "redux";
import { creditExecutionListCountersReducer } from "./counters/creditExecutionListCounters.slice";
import { creditExecutionListFiltersReducer } from "./filters/creditExecutionListFilters.slice";
import { creditExecutionListItemModificationReducer } from "./item-modification/creditExecutionListItemModification.slice";
import { creditExecutionListItemsReducer } from "./items/creditExecutionListItems.slice";

export const creditExecutionListReducer = combineReducers({
  filters: creditExecutionListFiltersReducer,
  counters: creditExecutionListCountersReducer,
  items: creditExecutionListItemsReducer,
  itemModification: creditExecutionListItemModificationReducer,
});
