import { CreditWorkingAssetsId, DemandId } from "../../../../../common/types/demand";
import { useProductRejectionModalPanel } from "../../../../../common/product-rejection";
import { useCreditWorkingAssetsListItemModificationView } from "../../../hooks/item-modification/useCreditWorkingAssetsListItemModificationView";
import { useAppSelector } from "../../../../../common/store";
import {
  selectCreditWorkingAssetsListItemsById,
  selectCreditWorkingAssetsListItemsDemandById,
} from "../../../store/items/creditWorkingAssetsListItems.selectors";
import { CreditWorkingAssetsRejectionDemand } from "../../../../common/rejection";

export interface CreditWorkingAssetsListItemRejectDemandProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
  demandId: DemandId;
}

export const CreditWorkingAssetsListItemRejectDemand = ({
  creditWorkingAssetsId,
  demandId,
}: CreditWorkingAssetsListItemRejectDemandProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const item = useAppSelector((state) => selectCreditWorkingAssetsListItemsById(state, creditWorkingAssetsId))!;
  const demand = useAppSelector((state) => selectCreditWorkingAssetsListItemsDemandById(state, demandId));

  const modalState = useProductRejectionModalPanel();
  const {
    isModalOpen,
    closeModal,
    onSuccess: afterSuccess,
  } = useCreditWorkingAssetsListItemModificationView(creditWorkingAssetsId, modalState, "Заявка успешно отменена");

  return (
    <CreditWorkingAssetsRejectionDemand
      isOpen={isModalOpen}
      onClose={closeModal}
      afterSuccess={afterSuccess}
      creditWorkingAssetsId={creditWorkingAssetsId}
      demandId={demandId}
      bankName={demand?.bank.title ?? ""}
      groupProductName={item.groupProductName}
    />
  );
};
