import Skeleton from "react-loading-skeleton";
import { currencyHelper, dateTimeHelper } from "@sideg/helpers";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Typography } from "../../../../common/ui-kit/typography";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { CreditWorkingAssetsCommonDetailsContainer } from "../../../common/components";
import { isLoading } from "../../../../common/types/state";
import { DotSeparatedRowContainer } from "../../../../common/ui-kit/containers/dot-separated-row";
import { useAppSelector } from "../../../../common/store";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";

export type CreditWorkingAssetsDetailsAsideProductDetailsProps = BaseCssProps;

export const CreditWorkingAssetsDetailsAsideProductDetails = ({
  className,
}: CreditWorkingAssetsDetailsAsideProductDetailsProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();
  const { data, status } = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails);

  return (
    <StackPanel direction="column" gap="sm" className={className}>
      <Typography
        lineHeight="1.4"
        fontWeight={700}
        fontSize={{ xs: 1.375, [desktopBreakpoint]: 1.5 }}
        fontColor="typography.primary"
      >
        Кредит на пополнение оборотных средств
      </Typography>
      <DotSeparatedRowContainer dotColor="typography.primary">
        <Typography fontWeight={500} fontSize="fz2" lineHeight="defaultLineHeight" fontColor="typography.primary">
          {data ? data.client?.name : <Skeleton width={100} inline />}
        </Typography>
        <Typography
          fontWeight={500}
          fontSize="fz2"
          lineHeight="defaultLineHeight"
          fontColor="typography.primaryCaption"
        >
          {data ? `ИНН ${data.client?.taxpayerNumber}` : <Skeleton width={100} inline />}
        </Typography>
      </DotSeparatedRowContainer>
      <CreditWorkingAssetsCommonDetailsContainer
        className={className}
        termFontColor="dark"
        approved={currencyHelper.getCurrencyStringNullable(data?.approvedCreditSum, undefined)}
        requested={
          isLoading(status) && data === undefined ? (
            <Skeleton width={150} />
          ) : (
            currencyHelper.getCurrencyStringNullable(data?.creditSum, undefined)
          )
        }
        confirmed={
          isLoading(status) && data === undefined ? (
            <Skeleton width={130} />
          ) : (
            currencyHelper.getCurrencyStringNullable(data?.confirmedCreditSum, undefined)
          )
        }
        period={
          (isLoading(status) && data === undefined) || data === undefined ? (
            <Skeleton width={200} />
          ) : (
            `${dateTimeHelper.format(new Date(data.dateStart), "dayShortMonthYear")} – ${dateTimeHelper.format(
              new Date(data.dateEnd),
              "dayShortMonthYear",
            )}`
          )
        }
      />
    </StackPanel>
  );
};
