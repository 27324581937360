import styled from "@emotion/styled";
import { CreationBlockWithTitle } from "../../../../common/ui-kit/cards/creation-block-card";

export const Container = styled(CreationBlockWithTitle)`
  & > * + * {
    border-top: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  }

  ${({ theme }) => theme.breakpoints.mqUp("md")} {
    & > * + * {
      border-top: none;
    }
  }
`;
