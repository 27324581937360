import { BankGuaranteeDetailsReconciliationPageLayout } from "./BankGuaranteeDetailsReconciliationPageLayout";
import { useBankGuaranteeDetailsReconciliationState } from "../../../hooks/reconciliation/useBankGuaranteeDetailsReconciliationState";
import { selectBankGuaranteeDetailsActiveDemandActualBill } from "../../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { BankGuaranteeDetailsDemandReconciliationBillContentSkeleton } from "../../../components/demand/reconciliation/bill/BankGuaranteeDetailsDemandReconciliationBillContent.skeleton";
import { BankGuaranteeDetailsDemandReconciliationBillContent } from "../../../components/demand/reconciliation/bill/BankGuaranteeDetailsDemandReconciliationBillContent";

export const BankGuaranteeDetailsReconciliationBillPage = () => {
  const { model, needShowSkeleton } = useBankGuaranteeDetailsReconciliationState(
    selectBankGuaranteeDetailsActiveDemandActualBill,
  );

  return (
    <BankGuaranteeDetailsReconciliationPageLayout>
      {needShowSkeleton && <BankGuaranteeDetailsDemandReconciliationBillContentSkeleton />}
      {!needShowSkeleton && <BankGuaranteeDetailsDemandReconciliationBillContent model={model} />}
    </BankGuaranteeDetailsReconciliationPageLayout>
  );
};
