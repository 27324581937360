import { forwardRef } from "react";
import * as S from "./FormFileInputLabel.styled";
import { FileUploadIcon, Icon, XCircleIcon } from "../../../../icons";
import { Text } from "../../../../typography";
import { CircularLoader } from "../../../../animations/loaders";
import { BaseCssProps } from "../../../../types";
import { FormFileInputVariant } from "../../types/FormFileInputVariant";

export interface FormFileInputLabelProps extends BaseCssProps {
  text: string;
  variant: FormFileInputVariant;
  inputProps?: Omit<JSX.IntrinsicElements["input"], "type">;
}

export const FormFileInputLabel = forwardRef<HTMLInputElement, FormFileInputLabelProps>(
  ({ className, text, variant, inputProps }, ref) => {
    return (
      <S.Label className={className} isDisabled={inputProps?.disabled}>
        <S.Input ref={ref} type="file" {...inputProps} />
        {variant === "default" && <Icon as={FileUploadIcon} stroke="primary" />}
        {variant === "error" && <Icon as={XCircleIcon} stroke="typography.error" />}
        {variant === "loading" && <CircularLoader size={16} />}
        <Text
          fontWeight={500}
          fontSize="fz3"
          lineHeight="defaultLineHeight"
          fontColor={variant === "error" ? "error" : "colors.primary"}
        >
          {text}
        </Text>
      </S.Label>
    );
  }
);
