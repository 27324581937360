import { Formik, FormikProps } from "formik";
import { useEffect, useRef } from "react";
import { entityHelper } from "@sideg/helpers";
import { PatternNumberFormatInput } from "@sideg/ui-kit/atoms/form-controls/number-format-input";
import { IssuingCompanyId } from "../../../../../../common/types/companies";
import { useAppSelector } from "../../../../../../common/store";
import { selectPublicUserRegistrationAgent } from "../../../../store/agent/publicUserRegistrationAgent.selectors";
import { PublicUserDescriptionTypography } from "../../../../../common/components";
import { publicUserRegistrationAgentDocumentApproveValidationSchema } from "../../../../validation/publicUserRegistrationAgentDocumentApproveValidationSchema";
import { FormBox, useGetFieldName, useGetFormFieldError } from "../../../../../../common/form";
import { PublicUserRegistrationAgentDocumentApproveFormValues } from "../../../../types/agent/PublicUserRegistrationAgentDocumentApproveFormValues";
import { PublicUserRegistrationAgentDocumentApproveFormSubmitListener } from "./PublicUserRegistrationAgentDocumentApproveFormSubmitListener";
import { PublicUserRegistrationAgentDocumentApproveFormActions } from "./PublicUserRegistrationAgentDocumentApproveFormActions";
import { usePublicUserRegistrationAgentDocumentApproveOnSubmit } from "../../../../hooks/agent/document-approve/usePublicUserRegistrationAgentDocumentApproveOnSubmit";

export interface PublicUserRegistrationAgentDocumentApproveFormProps {
  issuingCompanyId: IssuingCompanyId;
}

export const PublicUserRegistrationAgentDocumentApproveForm = ({
  issuingCompanyId,
}: PublicUserRegistrationAgentDocumentApproveFormProps) => {
  const { data } = useAppSelector(selectPublicUserRegistrationAgent);
  const getFieldName = useGetFieldName<PublicUserRegistrationAgentDocumentApproveFormValues>();
  const getFieldError = useGetFormFieldError<PublicUserRegistrationAgentDocumentApproveFormValues>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onSubmit = usePublicUserRegistrationAgentDocumentApproveOnSubmit(issuingCompanyId);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Formik
      initialValues={publicUserRegistrationAgentDocumentApproveValidationSchema.getDefault()}
      validationSchema={publicUserRegistrationAgentDocumentApproveValidationSchema}
      onSubmit={onSubmit}
    >
      {(form: FormikProps<PublicUserRegistrationAgentDocumentApproveFormValues>) => (
        <FormBox role="form" display="contents">
          <PublicUserRegistrationAgentDocumentApproveFormSubmitListener />
          {data?.phoneNumber !== undefined && (
            <PublicUserDescriptionTypography>
              Мы отправили вам код подтверждения операции на номер {entityHelper.formatPhoneNumber(data?.phoneNumber)}
            </PublicUserDescriptionTypography>
          )}
          <PatternNumberFormatInput
            type="text"
            label="Код из СМС"
            format="###-###"
            mask="_"
            placeholder="000-000"
            autoComplete="one-time-code"
            {...form.getFieldProps(getFieldName("code"))}
            error={getFieldError(form, "code")}
            disabled={form.isSubmitting}
            ref={inputRef}
          />
          <PublicUserRegistrationAgentDocumentApproveFormActions issuingCompanyId={issuingCompanyId} />
        </FormBox>
      )}
    </Formik>
  );
};
