import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { isLoading, isSucceeded } from "../../../../../common/types/state";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { BankGuaranteeCreationScoringSuccessResultRow } from "../result-row/BankGuaranteeCreationScoringSuccessResultRow";
import { useBankGuaranteeCreationSubmitCreateByGuarantee } from "../../../hooks/creation-submit/create-by-guarantee/useBankGuaranteeCreationSubmitCreateByGuarantee";
import { useBankGuaranteeCreationScoringIsBankSelected } from "../../../hooks/scoring/useBankGuaranteeCreationScoringIsBankSelected";
import { useBankGuaranteeCreationCreateByGuaranteeContext } from "../../../hooks/creation/create-by-guarantee/useBankGuaranteeCreationCreateByGuaranteeContext";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeCreationScoring } from "../../../store/bankGuaranteeCreation.selectors";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import {
  ProductCreationScoringLoadingResultCard,
  useCreationScoringSelectBanksState,
} from "../../../../../common/product-creation";
import { useBankGuaranteeCreationScoringSubmitState } from "../../../hooks/creation-submit/useBankGuaranteeCreationScoringSubmitState";
import { BankGuaranteeCreationScoringResultRowSkeleton } from "../result-row/BankGuaranteeCreationScoringResultRow.skeleton";
import { CreationSubmitButton } from "../../../../../common/ui/buttons/creation-submit-button";

const SUCCESS_BANKS_SKELETON_COUNT = 15;

export type BankGuaranteeCreationScoringCreateByGuaranteeSuccessBlockProps = BaseCssProps;

export const BankGuaranteeCreationScoringCreateByGuaranteeSuccessBlock = ({
  className,
}: BankGuaranteeCreationScoringCreateByGuaranteeSuccessBlockProps) => {
  const guaranteeId = useBankGuaranteeCreationCreateByGuaranteeContext();
  const { data, status } = useAppSelector(selectBankGuaranteeCreationScoring);

  const { toggleBank, selectedBanks } = useCreationScoringSelectBanksState();
  const isBankSelected = useBankGuaranteeCreationScoringIsBankSelected(selectedBanks);

  const submitState = useBankGuaranteeCreationSubmitCreateByGuarantee(guaranteeId);
  const { onClick, error, isSubmitting } = useBankGuaranteeCreationScoringSubmitState(selectedBanks, submitState);

  const isDesktop = useThemeIsDesktop();
  const isShowSubmitButton = isSucceeded(status);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(isLoading(status) || (data?.success && data.success.length > 0)) && (
        <StackPanel direction="column" gap="md" className={className}>
          <ProductCreationScoringLoadingResultCard
            isLoading={isLoading(status)}
            items={data?.success}
            skeletonElementsCount={SUCCESS_BANKS_SKELETON_COUNT}
            skeleton={BankGuaranteeCreationScoringResultRowSkeleton}
            title="Выберите банки, в которые хотите доотправить заявку"
            footer={
              isDesktop &&
              isShowSubmitButton && (
                <Box pt="md" pb="sm">
                  <CreationSubmitButton onClick={onClick} isLoading={isSubmitting} error={error}>
                    Отправить заявку
                  </CreationSubmitButton>
                </Box>
              )
            }
          >
            {(x) => (
              <BankGuaranteeCreationScoringSuccessResultRow
                bankName={x.bank.title}
                key={x.bank.code}
                reward={x.reward}
                price={x.price}
                onClick={() => toggleBank(x.bank.code)}
                isSelected={isBankSelected(x.bank.code)}
                isDisabled={isSubmitting}
              />
            )}
          </ProductCreationScoringLoadingResultCard>
          {!isDesktop && isShowSubmitButton && (
            <CreationSubmitButton onClick={onClick} isLoading={isSubmitting} error={error}>
              Отправить заявку
            </CreationSubmitButton>
          )}
        </StackPanel>
      )}
    </>
  );
};
