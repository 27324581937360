import { DemandId } from "../../../../../common/types/demand";
import { Text } from "../../../../../common/ui-kit/typography";
import {
  AppLayoutOverlayPanel,
  AppLayoutOverlayPanelContentWithHeader,
} from "../../../../../common/ui/layout/app-layout-containers";
import { bankGuaranteeRequestOriginalCreationConfigs } from "../../../configs";
import { BankGuaranteeRequestOriginalCreationForm } from "../form/BankGuaranteeRequestOriginalCreationForm";

interface BankGuaranteeRequestOriginalCreationOverlayPanelProps {
  demandId: DemandId;
  onSuccess: () => void;
  onClose: () => void;
}

export const BankGuaranteeRequestOriginalCreationOverlayPanel = ({
  demandId,
  onSuccess,
  onClose,
}: BankGuaranteeRequestOriginalCreationOverlayPanelProps) => {
  return (
    <AppLayoutOverlayPanel
      panelKey={bankGuaranteeRequestOriginalCreationConfigs.requestOriginalCreationOverlayPanelKey}
    >
      <AppLayoutOverlayPanelContentWithHeader
        onCloseClick={onClose}
        title={
          <Text fontColor="primaryLight" fontWeight={500} fontSize="fz4">
            Запрос оригинала
          </Text>
        }
      >
        <BankGuaranteeRequestOriginalCreationForm demandId={demandId} onSuccess={onSuccess} />
      </AppLayoutOverlayPanelContentWithHeader>
    </AppLayoutOverlayPanel>
  );
};
