import { CreditWorkingAssetsListItemStageCounter } from "./CreditWorkingAssetsListItemStageCounter";
import { CreditWorkingAssetsBase, creditWorkingAssetsDemandStageType } from "../../../../common/types";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { ProductListItemStageCountersContainer } from "../../../../../common/ui/product/stage";

export interface CreditWorkingAssetsListItemStageCountersProps extends BaseCssProps {
  counters: CreditWorkingAssetsBase["counters"];
}

export const CreditWorkingAssetsListItemStageCounters = ({
  className,
  counters,
}: CreditWorkingAssetsListItemStageCountersProps) => {
  return (
    <ProductListItemStageCountersContainer className={className}>
      {creditWorkingAssetsDemandStageType.values
        .filter((x) => !!counters[x])
        .map((x) => (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <CreditWorkingAssetsListItemStageCounter key={x} stage={x} count={counters[x]!} />
        ))}
    </ProductListItemStageCountersContainer>
  );
};
