import { AppExternalUrlSelector } from "../types/AppExternalUrlSelector";
import { selectExternalAppUrl } from "../utils/selectExternalAppUrl";

export interface AppExternalLinkProps extends Omit<JSX.IntrinsicElements["a"], "ref" | "href" | "target" | "rel"> {
  selector: AppExternalUrlSelector;
  isOpenInSameTab?: boolean;
  className?: string;
  skipRel?: boolean;
}

export const AppExternalLink = ({
  selector,
  isOpenInSameTab = false,
  skipRel = false,
  ...rest
}: AppExternalLinkProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content,react/jsx-no-target-blank
    <a
      href={selectExternalAppUrl(selector)}
      target={isOpenInSameTab ? "_self" : "_blank"}
      rel={skipRel ? undefined : "noopener noreferrer"}
      {...rest}
    />
  );
};
