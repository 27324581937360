import { useEffect } from "react";
import * as React from "react";
import { getAgencyDetails } from "../../../agency/details/store/agencyDetails.thunks";
import { useAppDispatch } from "../../../common/store";
import { isFailed, isLoading, isSucceeded, LoadingStatusEnum } from "../../../common/types/state";
import { getUserDetails } from "../store/userDetails.thunks";
import { SimpleOpacityTransition } from "../../../common/ui-kit/animations/transitions";
import { AlertLoadingToResult } from "../../../common/ui-kit/notifications/alert-with-indicator";

interface UserProfileFormWrapperProps {
  status: LoadingStatusEnum;
  children: React.ReactNode;
}

export const UserProfileFormWrapper = ({ status, children }: UserProfileFormWrapperProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAgencyDetails());
  }, [dispatch]);

  return (
    <>
      {children}
      <SimpleOpacityTransition
        timeout={250}
        in={isLoading(status) || isFailed(status) || isSucceeded(status)}
        mountOnEnter
        unmountOnExit
      >
        <AlertLoadingToResult isError={isFailed(status)} errorText="Не удалось загрузить информацию" />
      </SimpleOpacityTransition>
    </>
  );
};
