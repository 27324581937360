import { useMemo } from "react";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { LineSeparator } from "../../../../../../common/ui-kit/separators";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { Badge } from "../../../../../../common/ui-kit/containers/badge";
import { Icon } from "../../../../../../common/ui-kit/icons";
import { getBankGuaranteeDetailsDemandReconciliationDecisionIcon } from "../../../../utils/demand/getBankGuaranteeDetailsDemandReconciliationDecisionIcon";
import { QueueTaskId } from "../../../../../../common/types/demand";
import { useBankGuaranteeDetailsFilterOnScrollToQueueTaskClickHandler } from "../../../../hooks/filters/useBankGuaranteeDetailsFilterOnScrollToQueueTaskClickHandler";
import { getBankGuaranteeDetailsDemandBadgeVariantByReconciliationDecision } from "../../../../utils/demand/getBankGuaranteeDetailsDemandBadgeVariantByReconciliationDecision";
import {
  bankGuaranteeDetailsDemandReconciliationDecisionType,
  BankGuaranteeDetailsDemandReconciliationDecisionType,
} from "../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationDecisionType";
import { BankGuaranteeDetailsDemandReconciliationContent } from "./BankGuaranteeDetailsDemandReconciliationContent";
import { GuaranteeQueueTasksGroupType } from "../../../../../../common/types/queue-tasks";
import { ButtonLink } from "../../../../../../common/ui-kit/buttons/ButtonLink";

export interface BankGuaranteeDetailsDemandReconciliationContentBadgeProps extends BaseCssProps {
  decision: BankGuaranteeDetailsDemandReconciliationDecisionType;
  groupType: Extract<GuaranteeQueueTasksGroupType, "Bill" | "Project">;
  queueTaskId: QueueTaskId;
}

export const BankGuaranteeDetailsDemandReconciliationContentBadge = ({
  queueTaskId,
  groupType,
  decision,
  className,
}: BankGuaranteeDetailsDemandReconciliationContentBadgeProps) => {
  const variant = getBankGuaranteeDetailsDemandBadgeVariantByReconciliationDecision(decision);
  const icon = useMemo(() => getBankGuaranteeDetailsDemandReconciliationDecisionIcon(decision), [decision]);
  const onClick = useBankGuaranteeDetailsFilterOnScrollToQueueTaskClickHandler(queueTaskId, groupType);

  return (
    <Box display="flex" flexDirection="column" gap="sm" className={className} alignItems="flex-start">
      <Badge variant={variant} borderRadius="14px" py="none" px="sm">
        <Icon as={icon} width={17} height={17} />
        <BankGuaranteeDetailsDemandReconciliationContent.Typography fontColor="typography.primary">
          {bankGuaranteeDetailsDemandReconciliationDecisionType.getTitleByGroup(groupType, decision)}
        </BankGuaranteeDetailsDemandReconciliationContent.Typography>
      </Badge>
      <ButtonLink type="button" onClick={onClick}>
        <BankGuaranteeDetailsDemandReconciliationContent.Typography fontColor="primary">
          Перейти к сообщению
        </BankGuaranteeDetailsDemandReconciliationContent.Typography>
      </ButtonLink>
      <LineSeparator mt="xs" />
    </Box>
  );
};
