import { useCallback, useEffect, useState } from "react";

export const useProductListItemModificationOnClose = (
  modalState: readonly [boolean, (x: boolean) => void],
  afterClose: () => void | Promise<void>
) => {
  const [isModalOpen, setIsModalOpen] = modalState;

  const [isPrevOpen, setIsPrevOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  // Вызываем так, чтобы реакт успел поменять стейт isModalOpen,
  // так как иначе родительский компонент заанмаунтит текущий
  useEffect(() => {
    if (!isModalOpen && isPrevOpen) {
      afterClose();
    } else {
      setIsPrevOpen(isModalOpen);
    }
  }, [isPrevOpen, afterClose, isModalOpen]);

  return useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);
};
