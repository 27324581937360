import Skeleton from "react-loading-skeleton";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { BankGuaranteeCreationScoringSuccessResultRow } from "../result-row/BankGuaranteeCreationScoringSuccessResultRow";
import { useBankGuaranteeCreationScoringIsBankSelected } from "../../../hooks/scoring/useBankGuaranteeCreationScoringIsBankSelected";
import {
  ProductCreationScoringLoadingResultCard,
  ProductCreationScoringRejectsPanel,
  ProductCreationScoringResultCard,
  ProductCreationScoringResultRowDuplicate,
  ProductCreationScoringResultRowReject,
  ProductCreationScoringResultRowRejectSkeleton,
  ProductCreationScoringResultSummary,
  useProductCreationScoringRejectPanel,
} from "../../../../../common/product-creation";
import { isLoading } from "../../../../../common/types/state";
import { LineSeparatedContainer } from "../../../../../common/ui-kit/containers/separated";
import { useBankGuaranteeCreationScoringCreateNew } from "../../../hooks/scoring/creeate-new/useBankGuaranteeCreationScoringCreateNew";
import { BankGuaranteeCreationScoringResultRowSkeleton } from "../result-row/BankGuaranteeCreationScoringResultRow.skeleton";
import { CreationBlockCardDescriptionSecondary } from "../../../../../common/ui-kit/cards/creation-block-card";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { ScoringToggleAllBanksButton } from "../../../../../common/ui/buttons/scoring-toogle";
import { useCreationScoringSelectAllBanksState } from "../../../../../common/product-creation/hooks/scoring/useCreationScoringSelectAllBanksState";
import { FeatureFlagFeature } from "../../../../../common/feature-manager";
import { useAppSelector } from "../../../../../common/store";
import { selectAllSuccessBanksBankGuaranteeCreationScoring } from "../../../store/bankGuaranteeCreation.selectors";
import { UserFeatureFlagEnum } from "../../../../../common/types/user";

export interface BankGuaranteeCreationScoringBlocksProps {
  selectedBankIds: string[];
  onBankSelectToggle: (bankId: string) => void | Promise<void>;
  isDisabled?: boolean;
  setSelectedBanks: (bankIds: string[]) => void;
}

export const BankGuaranteeCreationScoringBlocks = ({
  onBankSelectToggle,
  selectedBankIds,
  isDisabled,
  setSelectedBanks,
}: BankGuaranteeCreationScoringBlocksProps) => {
  const isBankSelected = useBankGuaranteeCreationScoringIsBankSelected(selectedBankIds);

  const { data, status } = useBankGuaranteeCreationScoringCreateNew();
  const { openPanel, closePanel } = useProductCreationScoringRejectPanel();

  const allSuccessBankIds = useAppSelector(selectAllSuccessBanksBankGuaranteeCreationScoring);

  const { isAllBanksSelected, toggleAllBanks } = useCreationScoringSelectAllBanksState(
    selectedBankIds,
    allSuccessBankIds,
    setSelectedBanks,
  );

  return (
    <>
      <ProductCreationScoringResultSummary
        isSkeleton={isLoading(status)}
        successCount={data?.success?.length}
        rejectCount={data?.reject?.length}
        duplicateCount={data?.duplicate?.length}
        onShowRejectsClick={openPanel}
      />

      <ProductCreationScoringLoadingResultCard
        isLoading={isLoading(status)}
        items={data?.success}
        skeletonElementsCount={20}
        title="Выберите банки, в которые хотите отправить заявку"
        skeleton={BankGuaranteeCreationScoringResultRowSkeleton}
        description={
          <FeatureFlagFeature flag={UserFeatureFlagEnum.IsSendScoringToAllBanks}>
            {isLoading(status) && <Skeleton width={170} height={30} borderRadius={18} />}
            {!isLoading(status) && data?.success && (
              <Box>
                <ScoringToggleAllBanksButton
                  onClick={toggleAllBanks}
                  isDisabled={isDisabled}
                  isSelected={isAllBanksSelected}
                />
              </Box>
            )}
          </FeatureFlagFeature>
        }
      >
        {(x) => (
          <BankGuaranteeCreationScoringSuccessResultRow
            bankName={x.bank.title}
            key={x.bank.code}
            reward={x.reward}
            price={x.price}
            isSelected={isBankSelected(x.bank.code)}
            onClick={() => onBankSelectToggle(x.bank.code)}
            isDisabled={isDisabled}
          />
        )}
      </ProductCreationScoringLoadingResultCard>
      <ThemeMobileDesktopView
        mobile={
          data?.reject?.length !== undefined &&
          data.reject.length > 0 && (
            <ProductCreationScoringRejectsPanel rejectsCount={data.reject.length} onCloseClick={closePanel}>
              <LineSeparatedContainer borderWidth={1}>
                {data.reject.map((x) => (
                  <ProductCreationScoringResultRowReject key={x.bank.code} bankName={x.bank.title} text={x.message} />
                ))}
              </LineSeparatedContainer>
            </ProductCreationScoringRejectsPanel>
          )
        }
        desktop={
          <ProductCreationScoringLoadingResultCard
            isLoading={isLoading(status)}
            items={data?.reject}
            skeletonElementsCount={5}
            title="Отказ банка"
            skeleton={ProductCreationScoringResultRowRejectSkeleton}
          >
            {(x) => (
              <ProductCreationScoringResultRowReject bankName={x.bank.title} key={x.bank.code} text={x.message} />
            )}
          </ProductCreationScoringLoadingResultCard>
        }
      />
      {data?.duplicate !== undefined && data.duplicate.length > 0 && (
        <ProductCreationScoringResultCard
          title="Дубли"
          description={
            <CreationBlockCardDescriptionSecondary>
              У Вас уже есть заявка по данному лоту. Для восстановления заявки обратитесь к личному менеджеру, для
              изменения параметров напишите в комментарии к заявке
            </CreationBlockCardDescriptionSecondary>
          }
        >
          {data.duplicate.map((x) => (
            <ProductCreationScoringResultRowDuplicate
              bankName={x.bank.title}
              key={x.bank.code}
              demandId={x.existingDemand?.demandId}
              productId={x.existingDemand?.productId}
              productType="BankGuarantee"
            />
          ))}
        </ProductCreationScoringResultCard>
      )}
    </>
  );
};
