import { createSlice, isAsyncThunkAction, PayloadAction } from "@reduxjs/toolkit";
import { CreditWorkingAssetsListItemsState } from "./creditWorkingAssetsListItems.types";
import {
  creditWorkingAssetsListItemsAdapter,
  creditWorkingAssetsListItemsClientsAdapter,
  creditWorkingAssetsListItemsDemandsAdapter,
} from "./creditWorkingAssetsListItems.adapters";
import { getLoadingStatusFromAction, isAborted, LoadingStatusEnum } from "../../../../common/types/state";
import { combineSliceNames } from "../../../../common/store/utils";
import { CREDIT_WORKING_ASSETS_LIST_SLICE_NAME, getCreditWorkingAssetsList } from "../creditWorkingAssetsList.thunks";
import { CreditWorkingAssetsId } from "../../../../common/types/demand";
import { creditWorkingAssetsListItemsMapper } from "./creditWorkingAssetsListItems.mapper";
import { GetCreditWorkingAssetsListItemOutputDto } from "../../api/dto/output/GetCreditWorkingAssetsListOutputDto";

const initialState: CreditWorkingAssetsListItemsState = {
  pagination: undefined,
  demands: creditWorkingAssetsListItemsDemandsAdapter.getInitialState(),
  clients: creditWorkingAssetsListItemsClientsAdapter.getInitialState(),
  creditWorkingAssets: creditWorkingAssetsListItemsAdapter.getInitialState(),
  status: LoadingStatusEnum.Idle,
};

const slice = createSlice({
  name: combineSliceNames(CREDIT_WORKING_ASSETS_LIST_SLICE_NAME, "items"),
  initialState,
  reducers: {
    changeItem: (
      state,
      action: PayloadAction<{ id: CreditWorkingAssetsId; item: GetCreditWorkingAssetsListItemOutputDto | undefined }>,
    ) => {
      const actualValue = state.creditWorkingAssets.entities[action.payload.id];
      if (actualValue) {
        if (action.payload.item === undefined) {
          const { demandIds } = actualValue;
          creditWorkingAssetsListItemsDemandsAdapter.removeMany(state.demands, demandIds);
          creditWorkingAssetsListItemsAdapter.removeOne(state.creditWorkingAssets, action.payload.id);
        } else {
          const { product, demands, client } = creditWorkingAssetsListItemsMapper.mapItem(action.payload.item);
          creditWorkingAssetsListItemsAdapter.upsertOne(state.creditWorkingAssets, product);
          creditWorkingAssetsListItemsDemandsAdapter.upsertMany(state.demands, demands);
          creditWorkingAssetsListItemsClientsAdapter.upsertOne(state.clients, client);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCreditWorkingAssetsList.pending, (state, action) => {
        if (state.pagination && action.meta.arg && action.meta.arg.page <= state.pagination.pageNumber) {
          creditWorkingAssetsListItemsAdapter.removeAll(state.creditWorkingAssets);
          creditWorkingAssetsListItemsDemandsAdapter.removeAll(state.demands);
          creditWorkingAssetsListItemsClientsAdapter.removeAll(state.clients);
          state.pagination = undefined;
        }
      })
      .addCase(getCreditWorkingAssetsList.fulfilled, (state, action) => {
        const { clients, demands, creditWorkingAssets } = creditWorkingAssetsListItemsMapper.mapList(
          action.payload.body.data,
        );
        if (action.payload.body.paginationInformation?.pageNumber === 1) {
          creditWorkingAssetsListItemsAdapter.setAll(state.creditWorkingAssets, creditWorkingAssets);
          creditWorkingAssetsListItemsDemandsAdapter.setAll(state.demands, demands);
          creditWorkingAssetsListItemsClientsAdapter.setAll(state.clients, clients);
        } else {
          creditWorkingAssetsListItemsAdapter.upsertMany(state.creditWorkingAssets, creditWorkingAssets);
          creditWorkingAssetsListItemsDemandsAdapter.upsertMany(state.demands, demands);
          creditWorkingAssetsListItemsClientsAdapter.upsertMany(state.clients, clients);
        }

        state.pagination = action.payload.body.paginationInformation;
      })
      .addCase(getCreditWorkingAssetsList.rejected, (state, action) => {
        state.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(getCreditWorkingAssetsList), (state, action) => {
        const status = getLoadingStatusFromAction(action);
        if (!isAborted(status)) {
          state.status = getLoadingStatusFromAction(action);
        }
      });
  },
});

export const creditWorkingAssetsListItemsReducer = slice.reducer;

export const { changeItem: changeItemCreditWorkingAssetsListItems } = slice.actions;
