import { Typography, useShowMoreText } from "../../../../../common/ui-kit/typography";
import { ButtonLink } from "../../../../../common/ui-kit/buttons/ButtonLink";
import { Box } from "../../../../../common/ui-kit/containers/box";

const BoxButton = Box.withComponent(ButtonLink);

export interface LegalServicesCardDescriptionProps {
  text: string;
  textLength: number;
}

export const LegalServicesCardDescription = ({ text, textLength }: LegalServicesCardDescriptionProps) => {
  const { text: trimmedText, setIsOpened, isOpened, trimmed } = useShowMoreText(text, textLength);

  return (
    <>
      <Typography withLineBreaks fontColor="typography.primary" fontSize={1} lineHeight="1.5rem">
        {trimmedText}
      </Typography>
      {trimmed && (
        <BoxButton type="button" onClick={() => setIsOpened(!isOpened)} alignSelf="flex-start">
          {isOpened ? "Свернуть" : "Показать полностью"}
        </BoxButton>
      )}
    </>
  );
};
