import styled from "@emotion/styled";
import { colorWithAlpha } from "../../../utils";
import { Icon } from "../../../icons";

const DATA_ICON_ATTRIBUTE_NAME = "data-icon-type";
type DataIconAttributeValue = "checked" | "unchecked";

export const getDataIconAttribute = (value: DataIconAttributeValue) => {
  return { [DATA_ICON_ATTRIBUTE_NAME]: value };
};

const getDataIconAttributeSelector = (value: DataIconAttributeValue) => {
  return `[${DATA_ICON_ATTRIBUTE_NAME}="${value}"]`;
};

const THUMB_SIZE_REM = 1.25;
const TRACK_SIZE_REM = 3;
const PADDING_PX = 2;

export const StateIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  & > path {
    stroke: ${({ theme }) => theme.palette.switch.iconColor};
  }
`;

export const SwitchContainer = styled.div`
  flex-shrink: 0;
  position: relative;
`;

export const Input = styled.input<{ isError?: boolean }>`
  padding: ${PADDING_PX}px;
  background: ${({ theme, isError }) =>
    isError ? theme.palette.switch.errorTrackColor : theme.palette.switch.trackInactive};
  width: calc(${TRACK_SIZE_REM}rem + ${PADDING_PX * 2}px);
  height: calc(${THUMB_SIZE_REM}rem + ${PADDING_PX * 2}px);
  border-radius: ${TRACK_SIZE_REM}rem;
  margin: 0;

  appearance: none;
  pointer-events: none;
  touch-action: pan-y;
  border: none;
  outline-offset: 1px;

  display: grid;
  align-items: center;
  grid: [track] 1fr / [track] 1fr;

  transition: background-color 0.25s ease;

  &::before {
    content: "";
    cursor: pointer;
    pointer-events: auto;
    grid-area: track;
    width: ${THUMB_SIZE_REM}rem;
    height: ${THUMB_SIZE_REM}rem;
    background: ${({ theme }) => theme.palette.switch.thumb};
    box-shadow:
      0 2.2px 6px rgba(0, 0, 0, 0.15),
      0 2.2px 0.74px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    transform: translateX(0%);
    transition:
      transform 0.25s ease,
      box-shadow 0.25s ease;
  }

  &:not(:disabled):hover::before {
    box-shadow: 0 0 0 0.2rem
      ${({ theme }) => colorWithAlpha(theme.palette.control.secondaryHover, Math.floor(255 * 0.35))};
  }

  &:checked {
    background: ${({ theme, isError }) =>
      isError ? theme.palette.switch.errorTrackColor : theme.palette.switch.trackActive};

    &::before {
      transform: translateX(calc(${TRACK_SIZE_REM}rem - 100%));
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.palette.switch.trackDisabled};

    &::before {
      background-color: transparent;
      cursor: not-allowed;
      box-shadow: inset 0 0 0 2px hsl(0 0% 100% / 50%);
    }
  }

  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &:not(:checked) ~ ${StateIcon}${getDataIconAttributeSelector("unchecked")} {
    display: block;
    right: 10%;
  }

  &:checked ~ ${StateIcon}${getDataIconAttributeSelector("checked")} {
    display: block;
    left: 10%;
  }
`;

export const Label = styled.label<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 2ch;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const LabelText = styled.span`
  word-wrap: break-word;
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  letter-spacing: 0;
  color: ${({ theme }) => theme.palette.checkbox.label};
  font-weight: 500;
`;
