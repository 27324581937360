/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { FieldHelperProps, FormikHandlers, useField } from "formik";
import { FieldNames } from "../types/FieldNames";
import { useGetFieldError } from "../hooks/useGetFieldError";
import { BooleanString } from "../types/TypedFieldProps";

export interface TypedFormFieldProps<
  TFormValues extends Record<string, any>,
  TField extends FieldNames<TFormValues>,
  TValue = TFormValues[TField] extends boolean ? BooleanString : TFormValues[TField]
> {
  name: TField;
  value: TValue;
  initialValue: TValue | undefined;
  onChange: FormikHandlers["handleChange"];
  onBlur: FormikHandlers["handleBlur"];
  error: string | undefined;
  checked: boolean | undefined;
}

// TODO: перенести логику на использование useTypedField
export const makeTypedFieldHook =
  <TFormValues extends Record<string, any>>() =>
  <
    TField extends FieldNames<TFormValues>,
    TValue = TFormValues[TField] extends boolean ? BooleanString : TFormValues[TField]
  >(
    field: TField
  ): [TypedFormFieldProps<TFormValues, TField, TValue>, FieldHelperProps<TValue>] => {
    const getFieldError = useGetFieldError<TFormValues>();
    const [{ value, onBlur, onChange }, { touched, error, initialValue }, helpers] = useField<TValue>(field);

    return useMemo(
      () => [
        {
          value: (typeof value === "boolean" ? value.toString() : value) as TValue,
          checked: typeof value === "boolean" ? value === true : undefined,
          name: field,
          onBlur,
          onChange,
          error: getFieldError(field, touched, error),
          initialValue,
        },
        helpers,
      ],
      [error, field, getFieldError, helpers, initialValue, onBlur, onChange, touched, value]
    );
  };
