import * as React from "react";
import * as S from "./AppLayoutOverlayPanel.styled";
import { useAppLayoutActiveOverlayPanel } from "../../hooks/useAppLayoutActiveOverlayPanel";
import { SlideFromBottomToTopTransition } from "../../../../../ui-kit/animations/transitions";
import { AppLayoutOverlayPanelKey } from "../../../app-layout/types";
import { PortalContainer } from "../../../../containers/portal";

export interface AppLayoutOverlayPanelProps {
  panelKey: AppLayoutOverlayPanelKey;
  children?: React.ReactNode;
  className?: string;
}

export const AppLayoutOverlayPanel = React.memo(({ panelKey, children, className }: AppLayoutOverlayPanelProps) => {
  const { isOpened } = useAppLayoutActiveOverlayPanel(panelKey);

  return (
    <PortalContainer htmlElementId={import.meta.env.VITE_OVERLAY_PANEL_ID ?? ""}>
      <SlideFromBottomToTopTransition timeout={150} in={isOpened} mountOnEnter unmountOnExit>
        <S.Container opened={isOpened} className={className}>
          {children}
        </S.Container>
      </SlideFromBottomToTopTransition>
    </PortalContainer>
  );
});
