import { useMemo } from "react";
import { GuaranteeTypeCode } from "../../../common/types/demand";

export const useBankGuaranteeDateEventTitle = (guaranteeType?: GuaranteeTypeCode) => {
  return useMemo(() => {
    switch (guaranteeType) {
      case "EXEC":
        return "Дата проведения аукциона";
      case "PART":
        return "Дата окончания подачи заявок";
      default:
        return undefined;
    }
  }, [guaranteeType]);
};
