import Skeleton from "react-loading-skeleton";
import { getEmptyArray } from "@sideg/helpers";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { Box } from "../../../common/ui-kit/containers/box";
import { UserProfileInfoWrapper } from "./UserProfileInfoWrapper";
import {
  UserProfileInfoSkeletonPair,
  UserProfileInfoSkeletonPairSize,
} from "../../common/components/UserProfileInfoSkeletonPair";

const ROWS = getEmptyArray(6);

export const UserProfileInfoAgentSkeletonForm = () => {
  const isDesktop = useThemeIsDesktop();

  const SIZE: UserProfileInfoSkeletonPairSize = {
    firstSkeletonWidth: isDesktop ? 300 : 150,
    firstSkeletonHeight: 30,
    secondSkeletonWidth: isDesktop ? 300 : 150,
    secondSkeletonHeight: 30,
  };

  return (
    <UserProfileInfoWrapper>
      <Skeleton width={250} height={24} />
      {ROWS.map((x) => (
        <UserProfileInfoSkeletonPair
          {...SIZE}
          key={x}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="start"
        />
      ))}
      <Box display="flex" flexDirection="column" gap="xs">
        <Skeleton width={150} height={24} />
        <UserProfileInfoSkeletonPair {...SIZE} flexDirection="row" justifyContent="space-between" alignItems="start" />
      </Box>
      <Skeleton width="90%" height={30} />
    </UserProfileInfoWrapper>
  );
};
