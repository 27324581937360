import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeListItemMobileCard } from "./card/BankGuaranteeListItemMobileCard";
import { BankGuaranteeListItemMobileDemandsSkeleton } from "./demand-list/BankGuaranteeListItemMobileDemands.skeleton";
import { ProductListCardFooterDemandLinkMobileSkeleton } from "../../../../../../common/ui/product/list/card";

export type BankGuaranteeListItemMobileSkeletonProps = BaseCssProps;

export const BankGuaranteeListItemMobileSkeleton = ({ className }: BankGuaranteeListItemMobileSkeletonProps) => {
  return (
    <BankGuaranteeListItemMobileCard
      className={className}
      numberAndDate={<Skeleton inline width={200} />}
      actionsButton={<Skeleton inline width={28} height={28} />}
      clientName={<Skeleton inline width={220} />}
      guaranteeType={<Skeleton />}
      footer={<ProductListCardFooterDemandLinkMobileSkeleton />}
    >
      <BankGuaranteeListItemMobileDemandsSkeleton />
    </BankGuaranteeListItemMobileCard>
  );
};
