import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  AttachingDocuments: "Прикрепить документы, чтобы отправить заявку в банк",
  Processing: "Заявка рассматривается",
  Confirmed: "Одобрено",
  Approved: "Выдано",
  Rejected: "Отказ или отмена",
};

const shortTitles = {
  AttachingDocuments: "Прикрепить документы",
  Processing: "На рассмотрении",
  Confirmed: "Одобрено",
  Approved: "Выдано",
  Rejected: "Отказ",
};

const types = ["AttachingDocuments", "Processing", "Confirmed", "Approved", "Rejected"] as const;

export const creditWorkingAssetsDemandStageType = {
  ...makeEnumLikeLiteral(types, titles),
  getShortTitle: (type: typeof types[number]) => shortTitles[type],
};

export type CreditWorkingAssetsDemandStageType = EnumLikeLiteralType<typeof creditWorkingAssetsDemandStageType>;
