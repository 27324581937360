import { boolean, InferType, object, string } from "yup";
import {
  getEnumLikeLiteralTypeSchema,
  getFormattedPhoneSchema,
  getTaxpayerNumberSchema,
} from "../../../common/validation";
import { AgencyType, agencyType } from "../../../common/types/agencies";
import { CompanyType, companyTypeWithShortTitle } from "../../../common/types/companies";
import { publicUserRegistrationFromRules } from "./rules/publicUserRegistrationFromRules";

export const publicUserRegistrationFormValidationSchema = object({
  consumerType: getEnumLikeLiteralTypeSchema(agencyType).required().default("Agent"),
  companyType: getEnumLikeLiteralTypeSchema(companyTypeWithShortTitle)
    .default("Legal")
    .when("consumerType", (consumerType: AgencyType, schema) => {
      if (publicUserRegistrationFromRules.canSetCompanyType(consumerType)) {
        return schema;
      }

      return schema.strip();
    }),
  taxpayerNumber: string()
    .default("")
    .when("companyType", (companyType: CompanyType | undefined) => {
      const length = publicUserRegistrationFromRules.taxpayerNumberLength(companyType);

      return getTaxpayerNumberSchema(length).default("").required("Укажите ИНН");
    }),
  phoneNumber: getFormattedPhoneSchema().default(""),
  email: string()
    .default("")
    .trim("Укажите электронную почту")
    .email("Укажите корректную электронную почту")
    .required("Укажите электронную почту"),
  fullName: string().trim().default("").min(2, "Укажите фамилию, имя и отчество"),
  isAgreeWithPrivacyPolicy: boolean()
    .notOneOf([false], "Необходимо дать согласие на обработку персональных данных")
    .default(false)
    .required(""),
});

export const publicUserRegistrationFormValidationSchemaGetDefault = (
  consumerType: AgencyType,
  initialValues?: Partial<Omit<InferType<typeof publicUserRegistrationFormValidationSchema>, "consumerType">>
) => {
  return publicUserRegistrationFormValidationSchema.cast({ ...initialValues, consumerType });
};
