import { forwardRef } from "react";
import { dateTimeHelper } from "@sideg/helpers";
import * as S from "./BankGuaranteeRewardsItem.styled";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { useAppSelector } from "../../../common/store";
import { selectBankGuaranteeRewardsById } from "../store/bankGuaranteeRewards.selectors";
import { BankGuaranteeRewardsLabel } from "./labels/BankGuaranteeRewardsLabel";
import { BankGuaranteeRewardsItemCard } from "./card/BankGuaranteeRewardsItemCard";
import { BankGuaranteeRewardsTermItem } from "./BankGuaranteeRewardsTermItem";
import { BankId } from "../../../common/types/banks";

export interface BankGuaranteeRewardsItemProps extends BaseCssProps {
  id: BankId;
}

export const BankGuaranteeRewardsItem = forwardRef<HTMLDivElement, BankGuaranteeRewardsItemProps>(
  ({ id, className }, ref) => {
    const reward = useAppSelector((state) => selectBankGuaranteeRewardsById(state, id));

    return (
      <BankGuaranteeRewardsItemCard bankName={reward?.bank.title} ref={ref} className={className}>
        {reward?.terms.map((term, index) => (
          <BankGuaranteeRewardsTermItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            rate={
              <S.RowTitle>
                {term.standardRate ?? "—"}
                <S.TitleSeparator> / </S.TitleSeparator>
                {term.excessRate ?? "—"}
              </S.RowTitle>
            }
            dateStart={
              <BankGuaranteeRewardsLabel type="date">
                Дата начала {dateTimeHelper.format(new Date(term.dateStart), "dateOnly")}
              </BankGuaranteeRewardsLabel>
            }
            dateEnd={
              term.dateEnd && (
                <BankGuaranteeRewardsLabel type="date">
                  Действ. до {dateTimeHelper.format(new Date(term.dateEnd), "dateOnly")}
                </BankGuaranteeRewardsLabel>
              )
            }
          >
            {term.termsTitles.map((title, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <BankGuaranteeRewardsLabel key={i} type="term">
                {title}
              </BankGuaranteeRewardsLabel>
            ))}
          </BankGuaranteeRewardsTermItem>
        ))}
      </BankGuaranteeRewardsItemCard>
    );
  },
);
