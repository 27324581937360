import { useEffect, useRef, useState } from "react";
import { dateTimeHelper } from "@sideg/helpers";

export const usePublicUserRegistrationAgentDocumentApproveCountdown = (
  timeInSeconds: number,
  onTimerExpires: () => void,
) => {
  const [secondsCountdown, setSecondsCountdown] = useState<number | undefined>(timeInSeconds);
  const [label, setLabel] = useState<string>(
    secondsCountdown !== undefined ? dateTimeHelper.secondsToMinutesAndSecondsString(secondsCountdown) : "",
  );

  const onTimerExpiresRef = useRef(onTimerExpires);
  useEffect(() => {
    let interval: NodeJS.Timer;
    if (secondsCountdown !== undefined) {
      interval = setInterval(() => {
        if (secondsCountdown > 0) {
          setSecondsCountdown(secondsCountdown - 1);
          setLabel(dateTimeHelper.secondsToMinutesAndSecondsString(secondsCountdown - 1));
        }
        if (secondsCountdown === 0) {
          clearInterval(interval);
          onTimerExpiresRef.current();
          setSecondsCountdown(undefined);
          setLabel("");
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [secondsCountdown]);

  return label;
};
