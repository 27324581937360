import { ShortFile } from "../../../../common/types/files";
import { Text } from "../../../../common/ui-kit/typography";
import { FileDownloadLabel } from "../../../../files/download";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export interface DemandQueueTaskAttachedFileProps extends BaseCssProps {
  file: ShortFile;
}

export const DemandQueueTaskAttachedFile = ({ file, className }: DemandQueueTaskAttachedFileProps) => {
  return (
    <div className={className}>
      {file.title && (
        <Text as="div" fontSize="fz2" fontWeight={400} fontColor="colors.dark" lineHeight="defaultLineHeight">
          {file.title}
        </Text>
      )}
      <FileDownloadLabel fileId={file.fileId} fileName={file.fileName} privateKey={file.privateKey} />
    </div>
  );
};
