import { TransitionGroup } from "react-transition-group";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { CreditWorkingAssetsId, DemandId } from "../../../../../../../common/types/demand";
import { ProductListCardDemandListItemAppearanceTransition } from "../../../../../../../common/ui/product/list/card";
import { CreditWorkingAssetsListItemDemandDesktop } from "../item/CreditWorkingAssetsListItemDemand.desktop";
import { NavigationState } from "../../../../../../../common/navigation/navigation-state";
import { ProductListItemDemandItemLayoutDesktop } from "../../../../../../../common/ui/product/list/product-list-item";

export interface CreditWorkingAssetsListItemDemandsListDesktopProps extends BaseCssProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
  demandIds: DemandId[];
  from: NavigationState;
}

export const CreditWorkingAssetsListItemDemandsListDesktop = ({
  className,
  demandIds,
  creditWorkingAssetsId,
  from,
}: CreditWorkingAssetsListItemDemandsListDesktopProps) => {
  return (
    <TransitionGroup component={ProductListItemDemandItemLayoutDesktop.Container} className={className}>
      {demandIds.map((x) => (
        <ProductListCardDemandListItemAppearanceTransition key={x} timeout={250}>
          <CreditWorkingAssetsListItemDemandDesktop
            creditWorkingAssetsId={creditWorkingAssetsId}
            demandId={x}
            from={from}
          />
        </ProductListCardDemandListItemAppearanceTransition>
      ))}
    </TransitionGroup>
  );
};
