import { Global } from "@emotion/react";

export const GlobalStyles = () => {
  return (
    <Global
      styles={(theme) => ({
        body: { fontFamily: theme.font.fontFamily, padding: 0, margin: 0, backgroundColor: "#fff" },
        "*": { boxSizing: "border-box", outline: "none" },
        html: {
          overflowX: "inherit",
          backgroundColor: theme.layout.header.backgroundColor,
          marginRight: 0,
          [theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)]: {
            overflowX: "hidden",
            marginRight: "calc(-1 * (100vw - 100%))",
          },
        },
      })}
    />
  );
};
