import * as React from "react";
import { Link } from "react-router-dom";
import { encodeQueryParams } from "../../../utils/encodeQueryParams";
import { AppUrlSelector } from "../types";
import { ExtractRouteParams, generatePath } from "../utils";

export const AppLink = <TUrl extends string, TSearch extends Record<string, unknown> | undefined, TState = unknown>({
  to,
  params,
  state,
  search,
  hash,
  ...rest
}: Omit<React.ComponentProps<typeof Link>, "to"> & {
  to: string | AppUrlSelector<TUrl, never>;
  params?: ExtractRouteParams<TUrl>;
  state?: TState;
  search?: TSearch;
  hash?: string;
}) => {
  const pathname = typeof to === "string" ? to : generatePath(to, params);
  const url =
    search !== undefined
      ? {
          pathname,
          hash,
          search: encodeQueryParams(search),
        }
      : { pathname, hash };

  return <Link to={url} state={state} {...rest} />;
};
