import { useState } from "react";
import { usePageTitle } from "../../../common/navigation/page-title";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { ClientCheckFormValues } from "../types/ClientCheckFormValues";
import { ClientCheckHeader } from "../components/header/ClientCheckHeader";
import { ClientCheckReport } from "../components/ClientCheckReport";
import { isLoading, isSucceeded } from "../../../common/types/state";
import Loader from "../../../../components/common/Loader";
import { useApiMutationAction } from "../../../common/hooks";
import { ClientCheckReportType } from "../types/ClientCheckReportType";
import {
  getBriefReportClientCheck,
  getContractsClientCheck,
  getFullReportClientCheck,
} from "../store/clientCheck.thunks";
import { make } from "../../../common/types/utils";
import { TaxpayerNumber } from "../../../common/types/demand";

const getThunkByReportType = (reportType: ClientCheckReportType) => {
  switch (reportType) {
    case "contracts":
      return getContractsClientCheck;
    case "brief":
      return getBriefReportClientCheck;
    default:
      return getFullReportClientCheck;
  }
};

export const ClientCheckPage = () => {
  usePageTitle("Проверка клиента");
  const [content, setContent] = useState<string | undefined>(undefined);
  const [reportType, setReportType] = useState<ClientCheckReportType>("full");
  const { status, mutate } = useApiMutationAction();

  const submitHandler = async (values: ClientCheckFormValues): Promise<void> => {
    const result = await mutate(
      getThunkByReportType(values.reportType),
      {
        inn: make<TaxpayerNumber>()(values.taxpayerNumber),
      },
      { errorMessagePlaceholder: "" }
    );

    if (result?.body) {
      setReportType(values.reportType);
      setContent(result.body);
    }
  };

  return (
    <AppLayoutResponsiveListPage backgroundColor="light">
      <ClientCheckHeader onFormSubmit={submitHandler} />
      <AppLayoutResponsiveListPage.List>
        {isLoading(status) && <Loader />}
        {isSucceeded(status) && content && <ClientCheckReport reportType={reportType} content={content} />}
      </AppLayoutResponsiveListPage.List>
    </AppLayoutResponsiveListPage>
  );
};
