import { BankGuaranteeDetailsDemandQueueTaskCompletedState } from "./bankGuaranteeDetailsTasksHistory.types";
import { ApiResponse } from "../../../../common/api/types";
import { GetHistoryBankGuaranteeDetailsDemandOutputDto } from "../../api/dto/output/GetHistoryBankGuaranteeDetailsDemandOutputDto";
import { BankGuaranteeDetailsDemandQueueTaskCompleted } from "../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskCompleted";

export const bankGuaranteeDetailsTasksHistoryMapper = {
  formResponse: (
    response: ApiResponse<GetHistoryBankGuaranteeDetailsDemandOutputDto>,
  ): BankGuaranteeDetailsDemandQueueTaskCompletedState[] => {
    const { pageSize, pageNumber } = response.body.paginationInformation ?? { pageNumber: 1, pageSize: 10 };

    return response.body.data.map((task, index) => ({ ...task, sort: pageNumber * pageSize + index }));
  },
  mapOne: (
    data: BankGuaranteeDetailsDemandQueueTaskCompleted,
    sort: number,
  ): BankGuaranteeDetailsDemandQueueTaskCompletedState => {
    return { ...data, sort };
  },
};
