import { AppUrlTypeEnum } from "../../urls/internal/types";
import { UserFeatureFlagEnum, UserFeatureFlagPermission, UserRoleEnum } from "../../types/user";
import { AppUrlCheckPermission } from "../../urls/internal/types/IndexAppUrl";

const canAccessRole = (role: UserRoleEnum | undefined, urlType: AppUrlTypeEnum, permission: UserRoleEnum[] = []) => {
  if (role === undefined) {
    return urlType === AppUrlTypeEnum.Public || urlType === AppUrlTypeEnum.Common;
  }

  return (
    (urlType === AppUrlTypeEnum.Private || urlType === AppUrlTypeEnum.Common) &&
    (permission.length === 0 || permission.includes(role))
  );
};

const canAccessByFeatureFlag = (
  isAuthenticated: boolean,
  userFeatureFlags: UserFeatureFlagEnum[],
  urlType: AppUrlTypeEnum,
  featureFlag: UserFeatureFlagPermission,
) => {
  if (!isAuthenticated) {
    return urlType === AppUrlTypeEnum.Public || urlType === AppUrlTypeEnum.Common;
  }

  return (
    (urlType === AppUrlTypeEnum.Private || urlType === AppUrlTypeEnum.Common) &&
    (featureFlag === true || userFeatureFlags.includes(featureFlag))
  );
};

const canAccess = (
  isAuthenticated: boolean,
  userFeatureFlags: UserFeatureFlagEnum[],
  urlType: AppUrlTypeEnum,
  checkPermission: AppUrlCheckPermission,
) => {
  if (!isAuthenticated) {
    return urlType === AppUrlTypeEnum.Public || urlType === AppUrlTypeEnum.Common;
  }

  return (
    (urlType === AppUrlTypeEnum.Private || urlType === AppUrlTypeEnum.Common) &&
    (checkPermission === true || checkPermission({ userFeatureFlags }))
  );
};

export const routeAccessService = {
  canAccessRole,
  canAccessByFeatureFlag,
  canAccess,
};
