import { GetCreditWorkingAssetsDetailsOutputDto } from "../../api/dto/output/GetCreditWorkingAssetsDetailsOutputDto";
import { CreditWorkingAssetsDetailsProductDetails } from "../../types/CreditWorkingAssetsDetailsProductDetails";
import { CreditWorkingAssetsDemandBase } from "../../../common/types";
import { CreditWorkingAssetsTransferPayload } from "../../../common/store";

const canRejectAll = (demands: CreditWorkingAssetsDemandBase[]): boolean => demands.some((x) => x.isActive);

const fromResponse = (response: GetCreditWorkingAssetsDetailsOutputDto): CreditWorkingAssetsDetailsProductDetails => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { missingDocuments, demands, ...rest } = response;

  return { ...rest, canRejectAll: canRejectAll(demands) };
};

const fromTransfer = (transfer: CreditWorkingAssetsTransferPayload): CreditWorkingAssetsDetailsProductDetails => {
  return { ...transfer.product, canRejectAll: canRejectAll(transfer.demands) };
};

export const creditWorkingAssetsDetailsProductDetailsMapper = {
  fromResponse,
  fromTransfer,
};
