import { CodeWithTitle } from "../../../../../common/types/objects";
import { BaseFormElementError } from "../../../../../common/ui-kit/form-controls/common/components";
import { useGetFieldName, useTypedField } from "../../../../../common/form";
import { BlockResponsiveSubmitButton } from "../../../../../common/ui-kit/buttons/block-responsive-submit";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { FormRadioCheckbox } from "../../../../../common/ui-kit/form-controls/radio-checkbox";
import { Typography } from "../../../../../common/ui-kit/typography";
import { DemandQueueTaskRequestOptionsFormValues } from "../../../types/active/DemandQueueTaskRequestOptionsFormValues";
import { DemandQueueTaskActiveDecision } from "../../../index";

export interface DemandQueueTaskRequestOptionsBlockProps {
  requestOptions: CodeWithTitle[];
  decision: DemandQueueTaskActiveDecision;
}

export const DemandQueueTaskRequestOptionsBlock = ({
  requestOptions,
  decision,
}: DemandQueueTaskRequestOptionsBlockProps) => {
  const getFieldName = useGetFieldName<DemandQueueTaskRequestOptionsFormValues>();
  const [{ name, error }, registryRequestCodeField] = useTypedField<string>(getFieldName("requestOption"));

  return (
    <Box display="flex" flexDirection="column" gap="md" padding="md">
      <Box display="flex" flexDirection="column" gap="xs">
        <Typography fontSize="fz2" fontWeight={700} lineHeight="defaultLineHeight">
          Выберете короткий ответ
        </Typography>
        {error && <BaseFormElementError error={error} />}
      </Box>

      {requestOptions.map((requestOption) => (
        <FormRadioCheckbox
          label={requestOption.title}
          value={requestOption.code}
          onChange={() => {
            registryRequestCodeField.setValue(requestOption.code);
          }}
          name={name}
          key={requestOption.code}
          radioCheckboxSize="medium"
        />
      ))}
      <BlockResponsiveSubmitButton type="submit">{decision.title}</BlockResponsiveSubmitButton>
    </Box>
  );
};
