import styled from "@emotion/styled";
import { makeResponsiveStyles, Theme } from "@sideg/ui-kit";
import { Icon } from "../../../../icons";
import { ButtonCommonProps } from "../../types/ButtonCommonProps";
import { BaseButton } from "./BaseButton";
import { SizeUnit } from "../../../../theme";
import { ControlSize } from "../../../../types";
import { makeShouldForwardProp } from "../../../../utils";

interface ButtonSizeParams {
  padding: SizeUnit;
  gap: SizeUnit;
  fontSize: SizeUnit;
  lineHeight: SizeUnit;
}

const getSizes = (theme: Theme, size: ControlSize): ButtonSizeParams => {
  switch (size) {
    case "small":
      return {
        fontSize: theme.font.fz2,
        padding: `0.438rem ${theme.spaces.md}`,
        gap: "0.375rem",
        lineHeight: theme.spaces.lg,
      };
    case "medium":
      return {
        padding: `0.938rem ${theme.spaces.xl}`,
        gap: theme.spaces.sm,
        fontSize: theme.font.fz4,
        lineHeight: theme.spaces.xl,
      };
    case "default":
    default:
      return {
        padding: `0.625rem ${theme.spaces.lg}`,
        gap: theme.spaces.sm,
        fontSize: theme.font.fz3,
        lineHeight: "1.125rem",
      };
  }
};

export const CommonButtonIcon = styled(Icon)``;

export const CommonButton = styled(BaseButton, {
  shouldForwardProp: makeShouldForwardProp<ButtonCommonProps>((propName) => {
    return (
      propName !== "color" &&
      propName !== "size" &&
      propName !== "isDisabledAsDefaultState" &&
      propName !== "isFullWidth" &&
      propName !== "height" &&
      propName !== "width" &&
      propName !== "alignSelf"
    );
  }),
})<ButtonCommonProps>(
  ({ theme, size }) => {
    const { padding, gap, fontSize, lineHeight } = getSizes(theme, size);

    return {
      padding,
      gap,
      fontSize,
      lineHeight,
      svg: {
        width: lineHeight,
        height: lineHeight,
      },
    };
  },
  ({ theme, isFullWidth }) =>
    makeResponsiveStyles(theme, isFullWidth, (x) => {
      return {
        width: x === true ? "100%" : "unset",
      };
    }),
  ({ theme, alignSelf }) => makeResponsiveStyles(theme, alignSelf, (x) => ({ alignSelf: x })),
  ({ theme, width }) => makeResponsiveStyles(theme, width, (x) => ({ width: x })),
  ({ theme, height }) => makeResponsiveStyles(theme, height, (x) => ({ height: x })),
  () => ({
    svg: {
      "& > path, & > circle": {
        stroke: "currentColor",
      },
    },
  }),
);
