import { useEffect, useMemo, useState } from "react";
import { useApiMutation } from "../../../../common/hooks";
import { CreditExecutionId } from "../../../../common/types/demand";
import { getBriefCreditExecution } from "../store/creditExecutionCreation.thunks";
import { CreditExecutionBrief } from "../types/CreditExecutionBrief";

export const useCreditExecutionBriefData = (creditExecutionId: CreditExecutionId) => {
  const [data, setData] = useState<CreditExecutionBrief | undefined>(undefined);
  const { status, mutate } = useApiMutation(getBriefCreditExecution, {
    errorMessagePlaceholder: "",
  });

  useEffect(() => {
    const func = async () => {
      const result = await mutate({ creditExecutionId });
      setData(result?.body);
    };

    func();
  }, [creditExecutionId, mutate]);

  return useMemo(() => ({ data, status }), [data, status]);
};
