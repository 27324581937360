import { useFormikContext } from "formik";
import { useState } from "react";
import { DemandQueueTaskActiveProjectInfoContacts } from "../../../../../demand/queue-tasks";
import { useGetFieldName } from "../../../../../common/form";
import { BankGuaranteeDetailsComplicatedTaskProjectFormValues } from "../types/BankGuaranteeDetailsComplicatedTaskProjectFormValues";

export interface UseBankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualOnUpdateOptions
  extends DemandQueueTaskActiveProjectInfoContacts {
  entityType: "client" | "beneficiary";
}

export const useBankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualOnUpdate = ({
  phone,
  additionalNumber,
  email,
  entityType,
}: UseBankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualOnUpdateOptions) => {
  const [isUpdated, setIsUpdated] = useState(false);

  const getFieldName = useGetFieldName<BankGuaranteeDetailsComplicatedTaskProjectFormValues>();
  const form = useFormikContext<BankGuaranteeDetailsComplicatedTaskProjectFormValues>();

  return {
    isUpdated,
    onUpdate: () => {
      form.setFieldValue(getFieldName(`${entityType}PhoneNumber`), phone, true);
      form.setFieldValue(getFieldName(`${entityType}AdditionalNumber`), additionalNumber, true);
      form.setFieldValue(getFieldName(`${entityType}Email`), email, true);

      setIsUpdated(true);
    },
  };
};
