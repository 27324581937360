import { forwardRef } from "react";
import { ChatDialogMessageId } from "../../types/ChatDialogMessageId";
import { useAppSelector } from "../../../../common/store";
import { selectChatDialogMessageById } from "../../store/chatDialog.selectors";
import { ChatDialogMessagesGroupDate } from "./ChatDialogMessagesGroupDate";
import { ChatDialogMessage } from "./ChatDialogMessage";

export interface ChatDialogMessageWithDateProps {
  messageId: ChatDialogMessageId;
  previousMessageId: ChatDialogMessageId;
  className?: string;
}

export const ChatDialogMessageWithDate = forwardRef<HTMLDivElement, ChatDialogMessageWithDateProps>(
  ({ messageId, previousMessageId, className }, ref) => {
    const message = useAppSelector((state) => selectChatDialogMessageById(state, messageId));
    const previousMessage = useAppSelector((state) => selectChatDialogMessageById(state, previousMessageId));

    const isEquals = previousMessage?.dateAdded.startsWith(message?.dateAdded.substring(0, 10) ?? "");

    return (
      <>
        {!isEquals && message !== undefined && <ChatDialogMessagesGroupDate date={message.dateAdded} />}
        <ChatDialogMessage ref={ref} messageId={messageId} className={className} />
      </>
    );
  }
);
