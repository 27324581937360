import styled from "@emotion/styled";

export const Label = styled.label<{ isDisabled?: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spaces.sm};
  align-items: center;
  justify-content: flex-start;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
  user-select: none;
`;

export const Input = styled.input`
  display: none;
`;
