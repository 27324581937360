import { BaseCssProps } from "../../../../ui-kit/types";
import * as S from "./ManufacturerBrandLink.styled";
import { ManufacturerBrandLogo } from "./ManufacturerBrandLogo";

export type ManufacturerBrandLinkProps = BaseCssProps;

export const ManufacturerBrandLink = ({ className }: ManufacturerBrandLinkProps) => {
  return (
    <S.Link className={className} selector={(x) => x.manufacturer.home}>
      <ManufacturerBrandLogo width={95} height={45} />
    </S.Link>
  );
};
