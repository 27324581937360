import styled from "@emotion/styled";
import { FullPageContainerCommonCenteredWrapper } from "../common/FullPageContainerCommonCenteredWrapper";
import { SpaceType } from "../../../../../ui-kit/types";

export interface FullPageContainerMainContentCenteredContainerProps {
  gap: SpaceType;
  gapMultiplier?: number;
}

export const FullPageContainerMainContentCenteredContainer = styled(
  FullPageContainerCommonCenteredWrapper,
)<FullPageContainerMainContentCenteredContainerProps>`
  margin: ${({ theme }) => theme.spaces.sm} 0;
  gap: calc(${({ gapMultiplier }) => gapMultiplier ?? 1} * ${({ theme, gap }) => theme.spaces[gap]});
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-flow: row;
  grid-template-rows: min-content;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    margin: calc(2 * ${({ theme }) => theme.spaces.xxl}) 0;
  }
`;
