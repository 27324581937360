import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { getNewsDetails, NEWS_DETAILS_SLICE_NAME } from "./newsDetails.thunks";
import { NewsDetailsState } from "./newsDetails.types";

const initialState: NewsDetailsState = {
  status: LoadingStatusEnum.Idle,
  data: undefined,
};

const slice = createSlice({
  name: NEWS_DETAILS_SLICE_NAME,
  initialState,
  reducers: {
    update: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsDetails.fulfilled, (state, { payload }) => {
        state.data = payload.body.data;
      })
      .addCase(getNewsDetails.rejected, (state, action) => {
        state.error = action.payload?.message;
      })
      .addMatcher(isAsyncThunkAction(getNewsDetails), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const { update: updateDetailsNews } = slice.actions;

export const newsDetailsReducer = slice.reducer;
