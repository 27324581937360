import { getTaxpayerNumberSchema } from "../schemas/getTaxpayerNumberSchema";
import { getDemandIdSchema } from "../schemas/getDemandIdSchema";

export const valueValidationService = {
  isValidTaxpayerNumber: (value: string) => {
    return getTaxpayerNumberSchema().required().isValid(value);
  },
  isValidTaxpayerNumberSync: (value: string) => {
    return getTaxpayerNumberSchema().isValidSync(value);
  },
  isValidDemandId: (value: string) => {
    return getDemandIdSchema().isValid(value);
  },
};
