import { useCallback } from "react";
import { FormikHelpers } from "formik";
import {
  getBankGuaranteeCreationFromValidationSchema,
  getBankGuaranteeCreationRequestValidationSchema,
} from "../../validation/bankGuaranteeCreationValidationSchema";
import { BankGuaranteeCreationFromValues } from "../../types/BankGuaranteeCreationFormValues";
import { formValidationFieldService } from "../../../../common/form";
import { useAppDispatch } from "../../../../common/store";
import { createDraftBankGuaranteeCreation } from "../../store/bankGuaranteeCreation.thunks";
import { shallowEqualObjects } from "../../../../common/utils/shallowEqualObjects";
import {
  setIsFormSameAsInitialBankGuaranteeCreation,
  setNextStageBankGuaranteeCreation,
} from "../../store/bankGuaranteeCreation.slice";
import { BankGuaranteeCreationRequest } from "../../types/BankGuaranteeCreationRequest";

export const useBankGuaranteeCreationFormSubmit = (initialValues: BankGuaranteeCreationRequest) => {
  const dispatch = useAppDispatch();

  return useCallback(
    async (
      formValues: BankGuaranteeCreationFromValues,
      formikHelpers: FormikHelpers<BankGuaranteeCreationFromValues>
    ) => {
      try {
        const formCastedValues = getBankGuaranteeCreationFromValidationSchema().cast(formValues);
        const actualCastedValues = getBankGuaranteeCreationRequestValidationSchema().cast(formCastedValues, {
          assert: true,
          stripUnknown: true,
        }) as BankGuaranteeCreationRequest;

        const initialCastedValues = getBankGuaranteeCreationRequestValidationSchema().cast(initialValues);

        const isEquals = shallowEqualObjects(initialCastedValues, actualCastedValues);
        dispatch(setIsFormSameAsInitialBankGuaranteeCreation(isEquals));

        if (!isEquals) {
          await dispatch(createDraftBankGuaranteeCreation(actualCastedValues)).unwrap();
        } else {
          dispatch(setNextStageBankGuaranteeCreation());
        }
      } catch (e: unknown) {
        formikHelpers.setErrors(formValidationFieldService.tryGetValidationErrors(e));
      }
    },
    [dispatch, initialValues]
  );
};
