import { BankGuaranteeId, DemandId } from "../../../../common/types/demand";
import { Text } from "../../../../common/ui-kit/typography";
import {
  AppLayoutOverlayPanel,
  AppLayoutOverlayPanelContentWithHeader,
} from "../../../../common/ui/layout/app-layout-containers";
import { bankGuaranteeReissueConfigs } from "../../configs";
import { BankGuaranteeReissueForm } from "../form/BankGuaranteeReissueForm";

interface BankGuaranteeReissueOverlayPanelProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
  onSuccess: () => void;
  onClose: () => void;
}

export const BankGuaranteeReissueOverlayPanel = ({
  guaranteeId,
  demandId,
  onSuccess,
  onClose,
}: BankGuaranteeReissueOverlayPanelProps) => {
  return (
    <AppLayoutOverlayPanel panelKey={bankGuaranteeReissueConfigs.reissueBankGuaranteeOverlayPanelKey}>
      <AppLayoutOverlayPanelContentWithHeader
        onCloseClick={onClose}
        title={
          <Text fontColor="primaryLight" fontWeight={500} fontSize="fz4">
            Перевыпуск
          </Text>
        }
      >
        <BankGuaranteeReissueForm guaranteeId={guaranteeId} demandId={demandId} onSuccess={onSuccess} />
      </AppLayoutOverlayPanelContentWithHeader>
    </AppLayoutOverlayPanel>
  );
};
