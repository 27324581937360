import styled from "@emotion/styled";

export const Body = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.sm};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const Terms = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.sm};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    flex-wrap: wrap;
  }
`;
