import styled from "@emotion/styled";
import { AppLink } from "../../../../common/urls/internal/components";

export const PopUp = styled.div`
  box-shadow: ${({ theme }) => theme.layout.header.popup.boxShadow};
  border-radius: ${({ theme }) => theme.layout.header.popup.borderRadius};
  background: ${({ theme }) => theme.layout.header.popup.backgroundColor};
  overflow: hidden;
`;

export const TooltipButton = styled.button`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  appearance: none;
  -webkit-appearance: none;
  color: currentColor;
  font: inherit;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    margin: ${({ theme }) => theme.spaces.sm} 0;
  }
`;

export const ActionButton = styled(AppLink)`
  color: ${({ theme }) => theme.palette.typography.primaryLight};
  text-decoration: none;
  display: inline-flex;
  gap: ${({ theme }) => theme.spaces.xs};
  padding: ${({ theme }) => theme.spaces.xs};
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.secondaryColors.secondaryBorder};

    & > svg > path {
      stroke: ${({ theme }) => theme.secondaryColors.secondaryBorder};
    }
  }

  &:focus-visible {
    outline: 1px solid currentColor;
    border-radius: 2px;
  }
`;
