import { useCallback, useState } from "react";
import { dateTimeHelper } from "@sideg/helpers";
import { FilterSelect } from "../../../../ui-kit/form-controls/select";
import { BaseCssProps } from "../../../../ui-kit/types";
import { getDateCreateFrom } from "../../utils/getDateCreateFrom";
import { DateRangeFilterModal } from "../modal/DateRangeFilterModal";
import { dateRangeFilter, DateRangeFilterType } from "../../types/DateRangeFilterType";

export interface DateRangeFilterProps extends BaseCssProps {
  dateCreateFrom?: Date;
  dateCreateTo?: Date;
  dateMutator: (dateCreateFrom?: Date, dateCreateTo?: Date) => void;
}

export const DateRangeFilter = ({ dateMutator, dateCreateFrom, dateCreateTo, className }: DateRangeFilterProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSelectedChanged = (value: DateRangeFilterType) => {
    if (value === "choosePeriod") {
      setIsModalOpen(true);
    } else {
      dateMutator(getDateCreateFrom(value), undefined);
    }
  };

  const onSuccess = useCallback(
    (dateFrom?: Date, dateTo?: Date) => {
      setIsModalOpen(false);
      if (dateFrom && dateTo && dateTimeHelper.compareDates(dateFrom, dateTo) === 1) {
        dateMutator(dateTo, dateFrom);
      } else {
        dateMutator(dateFrom, dateTo);
      }
    },
    [dateMutator],
  );

  return (
    <>
      <FilterSelect
        className={className}
        text="Дата создания"
        selected={null}
        getItemTitle={dateRangeFilter.getTitle}
        onSelectedChanged={onSelectedChanged}
        items={dateRangeFilter.values}
      />

      <DateRangeFilterModal
        isShown={isModalOpen}
        initialValues={{ dateCreateFrom, dateCreateTo }}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onSuccess={onSuccess}
      />
    </>
  );
};
