import { useCallback } from "react";
import { useApiMutationAction } from "projects/agent/src/domains/common/hooks";
import { isBaseApiError } from "projects/agent/src/domains/common/api/types";
import { pushErrorGlobalToast } from "projects/agent/src/domains/global/toasts";
import { DemandId } from "../../../../common/types/demand";
import { useAppDispatch } from "../../../../common/store";
import { BankGuaranteeDetailsDemandQueueTaskCompleted } from "../../types/demand/tasks/BankGuaranteeDetailsDemandQueueTaskCompleted";
import { CommentFormFormValues } from "../../../../common/product-comment";
import { sendCommentDemandQueueTasks } from "../../../../demand/queue-tasks";
import { bankGuaranteeDetailsDemandNewCommentFormWithFiltersValidationSchema } from "../../validation/bankGuaranteeDetailsNewCommentValidationSchema";

export const useBankGuaranteeDetailsDemandNewCommentOnSubmit = (
  demandId: DemandId,
  onSuccess?: (completedQueueTask: BankGuaranteeDetailsDemandQueueTaskCompleted) => void | Promise<void>,
) => {
  const dispatch = useAppDispatch();
  const { mutate } = useApiMutationAction();

  return useCallback(
    async ({ comment, files, groupType }: CommentFormFormValues) => {
      const castedValues = bankGuaranteeDetailsDemandNewCommentFormWithFiltersValidationSchema.cast({
        comment,
        groupType,
        files: files.map((x) => x.file),
      });
      const result = await mutate(
        sendCommentDemandQueueTasks,
        { demandId, ...castedValues },
        {
          errorHandler: async (e) => {
            const placeholder =
              "При отправке комментария возникла ошибка. Попробуйте еще раз или свяжитесь с персональным менеджером.";
            const error = isBaseApiError(e) ? e.message : undefined;
            dispatch(pushErrorGlobalToast(error ?? placeholder));

            return error ?? placeholder;
          },
        },
      );
      if (result?.body.queueTaskCard) {
        onSuccess?.(result.body.queueTaskCard as BankGuaranteeDetailsDemandQueueTaskCompleted);
      }
    },
    [demandId, dispatch, onSuccess, mutate],
  );
};
