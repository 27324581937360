import { useMemo } from "react";
import { Badge } from "../../../../../../../common/ui-kit/containers/badge";
import { Box } from "../../../../../../../common/ui-kit/containers/box";
import { CircleIndicator } from "../../../../../../../common/ui-kit/notifications/circle-indicator";
import { Typography } from "../../../../../../../common/ui-kit/typography";
import { Icon } from "../../../../../../../common/ui-kit/icons";
import { QueueTaskId } from "../../../../../../../common/types/demand";
import { useBankGuaranteeDetailsFilterOnScrollToQueueTaskClickHandler } from "../../../../../hooks/filters/useBankGuaranteeDetailsFilterOnScrollToQueueTaskClickHandler";
import { BankGuaranteeDetailsDemandReconciliationStateType } from "../../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationStateType";
import { getBankGuaranteeDetailsDemandBadgeVariantByReconciliationState } from "../../../../../utils/demand/getBankGuaranteeDetailsDemandBadgeVariantByReconciliationState";
import { getBankGuaranteeDetailsDemandIconByReconciliationState } from "../../../../../utils/demand/getBankGuaranteeDetailsDemandIconByReconciliationState";
import { ButtonLink } from "../../../../../../../common/ui-kit/buttons/ButtonLink";

export interface BankGuaranteeDetailsDemandHeaderReconciliationAdditionalConditionProps {
  state: BankGuaranteeDetailsDemandReconciliationStateType;
  title: string;
  indexNumber: number;
  queueTaskId: QueueTaskId;
}

export const BankGuaranteeDetailsDemandHeaderReconciliationAdditionalCondition = ({
  state,
  indexNumber,
  title,
  queueTaskId,
}: BankGuaranteeDetailsDemandHeaderReconciliationAdditionalConditionProps) => {
  const onClick = useBankGuaranteeDetailsFilterOnScrollToQueueTaskClickHandler(queueTaskId, "Bill");
  const variant = getBankGuaranteeDetailsDemandBadgeVariantByReconciliationState(state);
  const icon = useMemo(() => getBankGuaranteeDetailsDemandIconByReconciliationState(state), [state]);

  return (
    <Badge variant={variant} py="none" px="sm" borderRadius="14px" justifyContent="space-between">
      <Box display="flex" flexDirection="row" gap="sm" alignItems="center" overflow="hidden">
        <Box flexShrink={0}>
          <CircleIndicator size={15} color="transparent" borderColor="typography.primary">
            <Typography fontColor="typography.primary" fontWeight={700} fontSize="fz1" lineHeight="defaultLineHeight">
              {indexNumber}
            </Typography>
          </CircleIndicator>
        </Box>
        <Typography
          withTextOverflowEllipsis
          fontColor="typography.primary"
          fontWeight={500}
          fontSize="fz2"
          lineHeight="defaultLineHeight"
        >
          {title}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap="lg" alignItems="center">
        <Icon as={icon} width={16} height={16} />
        <ButtonLink type="button" onClick={onClick}>
          <Typography
            fontColor="primary"
            fontWeight={500}
            fontSize="fz2"
            lineHeight="defaultLineHeight"
            whiteSpace="nowrap"
          >
            К сообщению
          </Typography>
        </ButtonLink>
      </Box>
    </Badge>
  );
};
