import { RootState } from "../../../common/store";
import { PublicUserPrivacyPoliciesState } from "./publicUserPrivacyPolicies.types";
import { publicUserPrivacyPoliciesAdapter } from "./publicUserPrivacyPolicies.adapter";

export const selectPublicUserPrivacyPolicies = (state: RootState): PublicUserPrivacyPoliciesState =>
  state.domains.public.privacyPolicies;

export const { selectAll: selectPublicUserPrivacyPoliciesAll } = publicUserPrivacyPoliciesAdapter.getSelectors(
  selectPublicUserPrivacyPolicies
);
