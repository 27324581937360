import { useAppLayoutModalOrOverlayPanelState } from "../../../ui/layout/app-layout-containers";
import { StyledNavigationButtonWithCounter } from "../../../ui/navigation/components/buttons/StyledNavigationButtonWithCounter";
import { widgetsCalculatorConfig } from "../configs/widgetsCalculator.config";
import { CalculatorIcon } from "../../../ui-kit/icons";
import { WidgetsCalculatorOverlayPanel } from "./overlay-panel/WidgetsCalculatorOverlayPanel";
import { useReachGoalAnalytics } from "../../../analytics/hooks";

export const WidgetsCalculatorAppLayoutButton = () => {
  const [isOpen, setIsOpen] = useAppLayoutModalOrOverlayPanelState(widgetsCalculatorConfig.panelKey);
  const reachGoal = useReachGoalAnalytics();

  const handleClick = () => {
    if (!isOpen) {
      reachGoal("GLOBAL_CALCULATOR_WIDGET_BUTTON_CLICK");
    }

    setIsOpen(!isOpen);
  };

  return (
    <>
      <StyledNavigationButtonWithCounter isActive={isOpen} type="button" onClick={handleClick}>
        <CalculatorIcon />
      </StyledNavigationButtonWithCounter>
      <WidgetsCalculatorOverlayPanel onClose={() => setIsOpen(false)} />
    </>
  );
};
