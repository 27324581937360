import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../utils";

const titles = {
  Fz223: "223-ФЗ",
  Fz44: "44-ФЗ",
  Fz115: "115-ПП",
  Fz185: "185-ФЗ",
  Fz275: "275-ФЗ",
  Fz615: "615-ПП",
  COMMERCE: "Коммерческая закупка",
};

export const federalLawTypeWithCommerce = makeEnumLikeLiteral(
  ["Fz223", "Fz44", "Fz115", "Fz185", "Fz275", "Fz615", "COMMERCE"] as const,
  titles
);

export type FederalLawTypeWithCommerce = EnumLikeLiteralType<typeof federalLawTypeWithCommerce>;

export const federalLawType = makeEnumLikeLiteral(
  ["Fz223", "Fz44", "Fz115", "Fz185", "Fz275", "Fz615"] as const,
  titles
);

export type FederalLawType = EnumLikeLiteralType<typeof federalLawType>;

export const getFederalLawTypeTitle = (type: FederalLawTypeWithCommerce) => {
  return type === "COMMERCE" ? titles.COMMERCE : `Закупка по ${titles[type]}`;
};
