import { ComponentProps, forwardRef, memo } from "react";
import { useTheme } from "@sideg/ui-kit";
import * as S from "./UserDetailsNavigationPopupUserButton.desktop.styled";
import { getInitialsFromUserName } from "../../../../common/ui-kit/avatar/AvatarInitial";
import { useAppSelector } from "../../../../common/store";
import { selectUserDetails } from "../../store/userDetails.selectors";
import { UserProfileInfoModalWrapper } from "../UserProfileInfoModalWrapper";
import { useSelectPopupMemo } from "../../../../common/ui-kit/form-controls/select";
import { useUserDetailsMenuItems } from "../../hooks/useUserDetailsMenuItems";
import { UserDetailsUserMenuManagerBlock } from "./user-menu-manager-block/UserDetailsUserMenuManagerBlock.desktop";
import { Box } from "../../../../common/ui-kit/containers/box";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { HeadphonesIcon } from "../../../../common/ui-kit/icons";
import { Text } from "../../../../common/ui-kit/typography";
import { useUserDetails } from "../../hooks/useUserDetails";

const Button = forwardRef<HTMLButtonElement, ComponentProps<typeof S.UserButton>>(
  ({ isActive, isNewManager, ...rest }, ref) => {
    const { user } = useAppSelector(selectUserDetails);
    const theme = useTheme();

    return (
      <S.UserButton ref={ref} {...rest} isActive={isActive} isNewManager={isNewManager}>
        <S.UserAvatarInitial
          size={theme.layout.header.button.size}
          fontSize="14px"
          color="#fff"
          backGround={theme.layout.header.userAvatarBackground}
          initials={getInitialsFromUserName(user?.userName)}
        />
      </S.UserButton>
    );
  },
);

export const UserDetailsNavigationPopupUserButton = memo(() => {
  const { manager } = useUserDetails();

  const { items, modalUserInfoProfileOpen, handleCloseUserInfoProfileModal } = useUserDetailsMenuItems();

  const { isOpen, highlightedIndex, getItemProps, referenceProps, floatingProps } = useSelectPopupMemo({
    items,
    selectedItem: null,
    onSelectedItemChange: (x) => {
      x.selectedItem?.onClick();
    },
    circularNavigation: true,
    position: "bottom-end",
    offset: [0, 8],
  });

  return (
    <>
      <Button {...referenceProps} isActive={isOpen} isNewManager={manager?.isReplaced === true} />
      <Box {...floatingProps}>
        {isOpen && (
          <S.UserMenuItemsWrapper>
            <Box pb="lg" display="flex" flexDirection="column">
              {items.map((item, index) => {
                return (
                  <S.UserMenuItem
                    active={false}
                    key={item.key}
                    highlighted={index === highlightedIndex}
                    {...getItemProps({ item, index })}
                  >
                    <S.IconContainer>{item.icon}</S.IconContainer>
                    {item.title}
                  </S.UserMenuItem>
                );
              })}
            </Box>
            <S.UserMenuItemWithDetails>
              <StackPanel gap="sm" direction="row" alignItems="center">
                <S.IconContainer>
                  <HeadphonesIcon />
                </S.IconContainer>
                <Text fontSize="fz3" fontWeight={500}>
                  Связаться с менеджером
                </Text>
              </StackPanel>
              {manager && <UserDetailsUserMenuManagerBlock manager={manager} />}
            </S.UserMenuItemWithDetails>
          </S.UserMenuItemsWrapper>
        )}
      </Box>
      <UserProfileInfoModalWrapper isOpened={modalUserInfoProfileOpen} onClose={handleCloseUserInfoProfileModal} />
    </>
  );
});
