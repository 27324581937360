import styled from "@emotion/styled";
import * as React from "react";

export const TitleHeaderModelStyled = styled.div`
  font-size: ${({ theme }) => theme.font.fz4};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.colors.infoDisabled};
  margin-bottom: ${({ theme }) => theme.spaces.lg};
  padding-bottom: ${({ theme }) => theme.spaces.sm};
`;

const TextHeaderModelStyled = styled(TitleHeaderModelStyled)`
  font-size: ${({ theme }) => theme.font.fz3};
  border-bottom: none;
  font-weight: normal;
`;

interface ModalProps {
  title: string;
  message: any;
  children?: React.ReactNode;
}

export const Modal = ({ title, message, children }: ModalProps) => {
  return (
    <>
      <TitleHeaderModelStyled>{title}</TitleHeaderModelStyled>
      <TextHeaderModelStyled>{message}</TextHeaderModelStyled>
      {children}
    </>
  );
};
