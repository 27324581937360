import { FormNumberFormatInput } from "@sideg/ui-kit/atoms/form-controls/number-format-input";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { useBankGuaranteeCreationPurchaseIdentificationCodeField } from "../../../hooks/creation/fields/useBankGuaranteeCreationPurchaseIdentificationCodeField";
import { useBankGuaranteeCreationField } from "../../../hooks/creation/fields/useBankGuaranteeCreationField";

export interface BankGuaranteeCreationPurchaseIdentificationCodeFieldProps {
  disabled?: boolean;
}

export const BankGuaranteeCreationPurchaseIdentificationCodeField = ({
  disabled,
}: BankGuaranteeCreationPurchaseIdentificationCodeFieldProps) => {
  const isExists = useBankGuaranteeCreationPurchaseIdentificationCodeField();
  const [{ value, name, onBlur, onChange, error }] = useBankGuaranteeCreationField("purchaseIdentificationCode");

  return (
    <SimpleOpacityTransition timeout={300} in={isExists} unmountOnExit mountOnEnter>
      <FormNumberFormatInput
        isAllowed={({ formattedValue }) => formattedValue.length <= 36}
        type="text"
        label="Идентификационный код закупки"
        size="medium"
        inputMode="numeric"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        spellCheck={false}
        autoComplete="off"
        autoCorrect="off"
        disabled={disabled}
      />
    </SimpleOpacityTransition>
  );
};
