import { GetCreditWorkingAssetsListItemOutputDto } from "../../api/dto/output/GetCreditWorkingAssetsListOutputDto";
import { BaseClient } from "../../../../common/types/demand";
import { CreditWorkingAssetsListItem } from "../../types/items/CreditWorkingAssetsListItem";
import { CreditWorkingAssetsDemandBase } from "../../../common/types";

export const mapItem = (
  item: GetCreditWorkingAssetsListItemOutputDto
): {
  client: BaseClient;
  product: CreditWorkingAssetsListItem;
  demands: CreditWorkingAssetsDemandBase[];
} => {
  const { demands, client, ...rest } = item;

  const product: CreditWorkingAssetsListItem = {
    ...rest,
    clientId: client.id,
    demandIds: demands.map((x) => x.demandId),
    canRejectAll: item.isActive,
  };

  return { client, demands, product };
};

const mapList = (
  list: GetCreditWorkingAssetsListItemOutputDto[]
): {
  clients: BaseClient[];
  creditWorkingAssets: CreditWorkingAssetsListItem[];
  demands: CreditWorkingAssetsDemandBase[];
} => {
  const clients: BaseClient[] = [];
  const creditWorkingAssets: CreditWorkingAssetsListItem[] = [];
  const demands: CreditWorkingAssetsDemandBase[] = [];

  list.forEach((x) => {
    const mappedValue = mapItem(x);

    clients.push(mappedValue.client);
    creditWorkingAssets.push(mappedValue.product);
    demands.push(...mappedValue.demands);
  });

  return { clients, demands, creditWorkingAssets };
};

export const creditWorkingAssetsListItemsMapper = {
  mapItem,
  mapList,
};
