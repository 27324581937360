import { AxiosResponse } from "axios";
import { getFileTempGuid } from "../../../api/fileApi";
import { generateFileLinkGuid } from "../../../essentials/helpers";

import logger, { LogLevelEnum } from "../../../essentials/logger";

export const processGettingFile = async (fileId: bigint, privateKey?: string) => {
  getFileTempGuid(fileId, privateKey)
    .then(getFilePromiseResolve)
    .catch((err) => {
      logger(LogLevelEnum.Error, err);
    });
};

const getFilePromiseResolve = (response: AxiosResponse<any>) => {
  if (response.data.code === 200) {
    const guid = response.data.body.data;
    const link = generateFileLinkGuid(guid);
    window.open(link, "_blank");
  } else {
    const errorResponse = response.data;
    logger(LogLevelEnum.Error, errorResponse);
  }
};

export default processGettingFile;
