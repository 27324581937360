import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { Grid } from "../../../../../../../common/ui-kit/containers/responsive-grid";
import { Typography } from "../../../../../../../common/ui-kit/typography";
import { DemandQueueTaskActiveBillInfo } from "../../../../../../../demand/queue-tasks";
import { BankGuaranteeDetailsComplicatedTaskBillAmountField } from "./BankGuaranteeDetailsComplicatedTaskBillAmountField";

const NON_BREAKING_SPACE_CHAR = "ㅤ";

export interface BankGuaranteeDetailsComplicatedTaskBillAmountFieldsProps {
  isDisabled?: boolean;
  billInfo: DemandQueueTaskActiveBillInfo;
}

export const BankGuaranteeDetailsComplicatedTaskBillAmountFields = ({
  isDisabled,
  billInfo,
}: BankGuaranteeDetailsComplicatedTaskBillAmountFieldsProps) => {
  const isDesktop = useThemeIsDesktop();

  return (
    <>
      <Typography fontSize={isDesktop ? "fz3" : "fz2"} fontWeight={700} lineHeight="defaultLineHeight">
        Ваше предложение стоимости
      </Typography>
      <Grid container gap="xs">
        <BankGuaranteeDetailsComplicatedTaskBillAmountField
          field="sum"
          billInfo={billInfo}
          afterInput="₽"
          label="Итоговая стоимость"
          isDisabled={isDisabled}
        />
        <BankGuaranteeDetailsComplicatedTaskBillAmountField
          field="percent"
          billInfo={billInfo}
          afterInput="%"
          isDisabled={isDisabled}
          label={isDesktop ? NON_BREAKING_SPACE_CHAR : undefined}
        />
      </Grid>
    </>
  );
};
