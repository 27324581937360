import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { useNavigationStateFromWithFallback } from "../../../../common/navigation/navigation-state";
import { selectAppUrl } from "../../../../common/urls/internal/utils";
import { QueueTasksCreationHeaderMobile } from "./QueueTasksCreationHeader.mobile";
import { QueueTasksCreationHeaderDesktop } from "./QueueTasksCreationHeader.desktop";

export const QueueTasksCreationHeader = () => {
  const { from } = useNavigationStateFromWithFallback(selectAppUrl((x) => x.queueTasks.list.root).url);

  return (
    <ThemeMobileDesktopView
      mobile={<QueueTasksCreationHeaderMobile from={from} />}
      desktop={<QueueTasksCreationHeaderDesktop from={from} />}
    />
  );
};
