import { ShortFile } from "../../../common/types/files";

export interface DemandQueueTaskComment {
  author: string;
  date: string;
  body?: string;
  files?: ShortFile[];
}

export const isDemandQueueTaskCommentHaveFilesOrBody = (
  comment: DemandQueueTaskComment | undefined,
): comment is DemandQueueTaskComment => {
  return comment !== undefined && ((comment.files !== undefined && comment.files.length > 0) || !!comment.body);
};
