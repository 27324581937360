import { useState, useEffect } from "react";
import { useLocationPathEffect } from "../../navigation/menu/hooks/useLocationPathEffect";
import { localStorageSetPwaExpiresTime } from "../store/setPwa.storage";
import { SetPwaPortalWrapper } from "./SetPwaPortalWrapper";

const MILLISECONDS_IN_SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;

export const PeriodicSetPwaPromotion = () => {
  const [isOpenPortal, setIsOpenPortal] = useState(false);

  useEffect(() => {
    if (!localStorageSetPwaExpiresTime.getExpiresTime()) {
      setIsOpenPortal(true);
      localStorageSetPwaExpiresTime.setExpiresTime(Date.now() + MILLISECONDS_IN_SEVEN_DAYS);
    }
  }, []);

  useLocationPathEffect(() => {
    if (localStorageSetPwaExpiresTime.isTimeExpired()) {
      localStorageSetPwaExpiresTime.setExpiresTime(Date.now() + MILLISECONDS_IN_SEVEN_DAYS);
      setIsOpenPortal(true);
    }
  }, []);

  return isOpenPortal ? <SetPwaPortalWrapper onClose={() => setIsOpenPortal(false)} /> : null;
};
