import { createAsyncThunk } from "@reduxjs/toolkit";
import { combineSliceNames, createAppAsyncThunk } from "../../../common/store/utils";
import { BANK_GUARANTEE_SLICE_NAME, transferBankGuarantee } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { bankGuaranteeListApi } from "../api/bankGuaranteeListApi";
import { ApiError } from "../../../common/api/types";
import { GetBankGuaranteeListInputDto } from "../api/dto/input/GetBankGuaranteeListInputDto";
import { GetBankGuaranteeListItemOutputDto } from "../api/dto/output/GetBankGuaranteeListOutputDto";
import { BankGuaranteeId } from "../../../common/types/demand";
import { selectBankGuaranteeListTransferPayload } from "./bankGuaranteeList.selectors";

export const BANK_GUARANTEE_LIST_SLICE_NAME = combineSliceNames(BANK_GUARANTEE_SLICE_NAME, "list");

export const {
  getAvailableFilters: getAvailableFiltersBankGuaranteeList,
  getCounters: getCountersBankGuaranteeList,
  get: getBankGuaranteeList,
} = createApiThunks(BANK_GUARANTEE_LIST_SLICE_NAME, bankGuaranteeListApi);

export const getBankGuaranteeSingleListItem = createAsyncThunk<
  GetBankGuaranteeListItemOutputDto | null,
  GetBankGuaranteeListInputDto,
  { rejectValue: ApiError<GetBankGuaranteeListInputDto> }
>(
  combineSliceNames(BANK_GUARANTEE_LIST_SLICE_NAME, "getSingleListItem"),
  async (model, { rejectWithValue, signal }) => {
    try {
      const response = await bankGuaranteeListApi.get(model, signal);

      return response.body.data.length === 1 ? response.body.data[0] : null;
    } catch (err) {
      return rejectWithValue(err as ApiError<GetBankGuaranteeListInputDto>);
    }
  },
);

export const transferBankGuaranteeItem = createAppAsyncThunk(
  combineSliceNames(transferBankGuarantee.type, "thunk"),
  (guaranteeId: BankGuaranteeId, { dispatch, getState }) => {
    const transferPayload = selectBankGuaranteeListTransferPayload(getState(), guaranteeId);
    if (transferPayload !== undefined) {
      dispatch(transferBankGuarantee(transferPayload));
    }
  },
  {},
);
