import { dateTimeHelper } from "@sideg/helpers";

const INVALID_DATE = new Date("");

/**
 * Возвращает трансформер для преобразования в объект даты
 * @example "10.12.2021" -> Date
 */
export const getDateOnlyTransformer = () => (value: unknown, originalValue: unknown) => {
  if (typeof originalValue === "string" && dateTimeHelper.isDateMatch(originalValue, "dateOnly")) {
    return dateTimeHelper.parseDateFromString(originalValue, "dateOnly");
  }

  if (value instanceof Date && dateTimeHelper.isValid(value)) {
    return value;
  }

  if (typeof originalValue === "string" && originalValue.trim().length === 0) {
    return undefined;
  }

  return INVALID_DATE;
};
