import styled from "@emotion/styled";
import { Link as RouterLink } from "react-router-dom";

export const Header = styled.header`
  padding: 0 0 ${({ theme }) => theme.layout.borderPadding} 0;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;

  &::before {
    display: block;
    content: "";
    flex: 1;
  }
`;

export const LinkContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
`;

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  & > svg > path {
    stroke: ${({ theme }) => theme.colors.secondary};
  }

  &:hover,
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  }
`;
