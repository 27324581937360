import { useCallback, useMemo, useState } from "react";
import { useApiMutation } from "../../../common/hooks";
import { approvePublicUserResetPassword } from "../store/publicUserResetPassword.thunks";
import { PublicUserResetPasswordToken } from "../types/PublicUserResetPasswordToken";
import { PublicUserResetPasswordCommonOutputDto } from "../api/dto/output/PublicUserResetPasswordCommonOutputDto";
import { LoadingStatusEnum } from "../../../common/types/state";

export interface UsePublicUserResetPasswordApproveResult {
  data?: PublicUserResetPasswordCommonOutputDto;
  error?: string;
  status: LoadingStatusEnum;
  mutate: () => Promise<void>;
}

export const usePublicUserResetPasswordApprove = (
  token: PublicUserResetPasswordToken
): UsePublicUserResetPasswordApproveResult => {
  const [data, setData] = useState<PublicUserResetPasswordCommonOutputDto | undefined>(undefined);
  const {
    error,
    status,
    mutate: action,
  } = useApiMutation(approvePublicUserResetPassword, {
    errorMessagePlaceholder: "Что-то пошло не так. Попробуйте еще раз или свяжитесь с персональным менеджером",
  });

  const mutate = useCallback(async () => {
    const result = await action({ token });
    setData(result?.body);
  }, [action, token]);

  return useMemo(
    () => ({
      data,
      error,
      status,
      mutate,
    }),
    [data, error, mutate, status]
  );
};
