import styled from "@emotion/styled";
import { DemandQueueTaskCard, DemandQueueTaskSeparator } from "../../../../../demand/queue-tasks";

export const BankGuaranteeDetailsComplicatedTaskCard = styled(DemandQueueTaskCard)`
  // Есть кейсы, когда нужно отрендерить разделитель, но после него может не быть блока.
  // Решить это при помощи & > * + * селектора, и рамки сверху не получается, так как размер вложенных блоков
  // меньше размера всей карточки, поэтому явно делаем display:none для таких элементов
  & > ${DemandQueueTaskSeparator}:last-of-type:last-child {
    display: none;
  }
`;
