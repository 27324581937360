import styled from "@emotion/styled";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.layout.demand.colors.rightSideBackgroundColor};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.layout.borderPadding};

  ${({ theme }) => theme.breakpoints.mqUp("md")} {
    background-color: #fff;
  }
`;
