import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import { StyledContainerWithCustomScroll } from "@sideg/ui-kit/atoms/containers/components/custom-scroll/StyledContainerWithCustomScroll";

export const SelectPopupContainer = styled(StyledContainerWithCustomScroll.withComponent("ul"))<{
  maxWidth?: CSSObject["maxWidth"];
  minWidth?: CSSObject["minWidth"];
  open: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s};
  overflow: auto;

  max-width: ${({ maxWidth }) => maxWidth ?? undefined};
  min-width: ${({ minWidth }) => minWidth ?? `300px`};
  max-height: 400px;
  min-height: ${({ open, theme }) => (open ? theme.spacing.m : "0px")};

  box-shadow: ${({ open }) => (open ? "0px 12px 24px rgba(89, 111, 136, 0.4)" : "unset")};
  border-radius: ${({ theme }) => theme.radii.control};
  background: ${({ theme }) => theme.palette.light.primary.text.inverse};
  padding: ${({ theme }) => theme.spacing.makeCssSpaces("xs", "s")};
  z-index: ${({ open, theme }) => (open ? theme.zIndex.tooltip : -1)};
`;
