import { useBankGuaranteeCreationCreateByGuaranteeContext } from "../../../hooks/creation/create-by-guarantee/useBankGuaranteeCreationCreateByGuaranteeContext";
import { isLoading } from "../../../../../common/types/state";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { useBankGuaranteeCreationScoringCreateByGuarantee } from "../../../hooks/scoring/create-by-guarantee/useBankGuaranteeCreationScoringCreateByGuarantee";
import { BankGuaranteeCreationScoringCreateByGuaranteeSuccessBlock } from "./BankGuaranteeCreationScoringCreateByGuaranteeSuccessBlock";
import {
  ProductCreationScoringLoadingResultCard,
  ProductCreationScoringResultCard,
  ProductCreationScoringResultRowDuplicate,
  ProductCreationScoringResultRowDuplicateSkeleton,
  ProductCreationScoringResultRowReject,
} from "../../../../../common/product-creation";

const EXIST_BANKS_SKELETON_COUNT = 4;

export const BankGuaranteeCreationScoringCreateByGuarantee = () => {
  const guaranteeId = useBankGuaranteeCreationCreateByGuaranteeContext();
  const { status, data } = useBankGuaranteeCreationScoringCreateByGuarantee(guaranteeId);

  return (
    <Box flexGrow={1} flexShrink={1} display="flex" flexDirection="column" gap="xxl">
      <BankGuaranteeCreationScoringCreateByGuaranteeSuccessBlock />
      <ProductCreationScoringLoadingResultCard
        isLoading={isLoading(status)}
        items={data?.duplicate}
        skeletonElementsCount={EXIST_BANKS_SKELETON_COUNT}
        title="Банки, в которые уже была отправлена заявка"
        skeleton={ProductCreationScoringResultRowDuplicateSkeleton}
      >
        {(x) => (
          <ProductCreationScoringResultRowDuplicate
            bankName={x.bank.title}
            key={x.bank.code}
            demandId={x.existingDemand?.demandId}
            productId={x.existingDemand?.productId}
            productType="BankGuarantee"
          />
        )}
      </ProductCreationScoringLoadingResultCard>
      {data?.reject && data.reject.length > 0 && (
        <ProductCreationScoringResultCard title="Отказ банка">
          {data.reject.map((x) => (
            <ProductCreationScoringResultRowReject bankName={x.bank.title} key={x.bank.code} text={x.message} />
          ))}
        </ProductCreationScoringResultCard>
      )}
    </Box>
  );
};
