const dictionary: [eng: string, rus: string][] = [
  ["q", "й"],
  ["w", "ц"],
  ["e", "у"],
  ["r", "к"],
  ["t", "е"],
  ["y", "н"],
  ["u", "г"],
  ["i", "ш"],
  ["o", "щ"],
  ["p", "з"],
  ["[", "х"],
  ["{", "Х"],
  ["]", "ъ"],
  ["}", "Ъ"],
  ["|", "/"],
  ["`", "ё"],
  ["~", "Ё"],
  ["a", "ф"],
  ["s", "ы"],
  ["d", "в"],
  ["f", "а"],
  ["g", "п"],
  ["h", "р"],
  ["j", "о"],
  ["k", "л"],
  ["l", "д"],
  [";", "ж"],
  [":", "Ж"],
  ["'", "э"],
  ['"', "Э"],
  ["z", "я"],
  ["x", "ч"],
  ["c", "с"],
  ["v", "м"],
  ["b", "и"],
  ["n", "т"],
  ["m", "ь"],
  [",", "б"],
  ["<", "Б"],
  [".", "ю"],
  [">", "Ю"],
  ["/", "."],
  ["?", ","],
  ["@", '"'],
  ["#", "№"],
  ["$", ";"],
  ["^", ":"],
  ["&", "?"],
];

const getFullDictionary = () => {
  return dictionary.reduce(
    (accum, [eng, rus]) => {
      accum[eng.toUpperCase()] = rus.toUpperCase();
      accum[eng] = rus;

      return accum;
    },
    {} as Record<string, string>,
  );
};

const fullDictionary = getFullDictionary();

export const enToRuLayout = (value: string) => {
  return value.replace(/./g, (char) => fullDictionary[char] || char);
};
