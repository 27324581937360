import { useCallback } from "react";
import { useAppDispatch } from "../../../common/store";
import { postBankGuaranteeExtractFromRegistry } from "../store/bankGuaranteeExtractFromRegistry.thunks";
import { isLoading } from "../../../common/types/state";
import { useApiMutation } from "../../../common/hooks";
import { BankGuaranteeId, DemandId } from "../../../common/types/demand";
import { pushErrorGlobalToast } from "../../../global/toasts";
import { onBankGuaranteeExtractFromRegistryStatusChanged } from "../store/bankGuaranteeExtractFromRegistry.actions";

export const useRequestBankGuaranteeExtractFromRegistry = (guaranteeId: BankGuaranteeId, demandId: DemandId) => {
  const dispatch = useAppDispatch();
  const { mutate, status } = useApiMutation(postBankGuaranteeExtractFromRegistry, {
    errorMessagePlaceholder: "",
  });

  return useCallback(async () => {
    if (isLoading(status)) {
      return;
    }

    dispatch(onBankGuaranteeExtractFromRegistryStatusChanged({ demandId, newStatus: "Requested" }));
    const result = await mutate({ guaranteeId, demandId });

    if (!result?.body.data) {
      dispatch(
        pushErrorGlobalToast(
          `При запросе выписки произошла ошибка. Попробуйте еще раз или свяжитесь с персональным менеджером`
        )
      );
      dispatch(onBankGuaranteeExtractFromRegistryStatusChanged({ demandId, newStatus: "AvailableForRequest" }));
    }
  }, [demandId, dispatch, guaranteeId, mutate, status]);
};
