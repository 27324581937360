import { ReactNode } from "react";
import { useTheme } from "@sideg/ui-kit";
import * as S from "./ProductDetailsInformation.styled";
import { StackPanel } from "../../../ui-kit/containers/stack-panel";
import { Grid } from "../../../ui-kit/containers/responsive-grid";
import { ProductDetailsInformationFederalLawBlock } from "./block/ProductDetailsInformationFederalLawBlock";
import { ProductDetailsInformationClientBlock } from "./block/ProductDetailsInformationClientBlock";
import { ProductDetailsInformationType } from "../../types/ProductDetailsInformationType";
import { ProductDetailsInformationListBlock } from "./block/ProductDetailsInformationListBlock";
import { ProductDetailsInformationListItem } from "./item/ProductDetailsInformationListItem";

interface ProductDetailsInformationProps<T extends ProductDetailsInformationType> {
  data?: T;
  children: ReactNode;
}

export const ProductDetailsInformation = <T extends ProductDetailsInformationType>({
  data,
  children,
}: ProductDetailsInformationProps<T>) => {
  const theme = useTheme();

  return (
    <S.Container>
      <S.H2>Информация о закупке</S.H2>

      {data && (
        <Grid
          container
          rowGap={{ xs: "xxl", [theme.breakpoints.desktopBreakpoint]: "none" }}
          columnGap={{ xs: "none", [theme.breakpoints.desktopBreakpoint]: "xxl" }}
        >
          <Grid item xs={12} {...{ [theme.breakpoints.desktopBreakpoint]: 6 }}>
            <StackPanel gap={{ xs: "xxl", [theme.breakpoints.desktopBreakpoint]: "none" }} direction="column">
              <ProductDetailsInformationFederalLawBlock data={data} />
              {data.briefAndFounders?.beneficiary && (
                <ProductDetailsInformationListBlock title="Заказчик">
                  <ProductDetailsInformationListItem
                    title={`ИНН ${data.briefAndFounders.beneficiary.taxpayerNumber}`}
                    value={data.briefAndFounders.beneficiary.name}
                    reversed
                  />
                </ProductDetailsInformationListBlock>
              )}
              {children}
            </StackPanel>
          </Grid>
          <Grid item xs={12} {...{ [theme.breakpoints.desktopBreakpoint]: 6 }}>
            <ProductDetailsInformationClientBlock
              client={data.client}
              personFounders={data.briefAndFounders?.personFounders}
              companyFounders={data.briefAndFounders?.companyFounders}
            />
          </Grid>
        </Grid>
      )}
    </S.Container>
  );
};
