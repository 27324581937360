import { ApiVersionType } from "../types/ApiVersionType";
import { ApiFetchClient } from "../types/ApiFetchClient";
import { createClient, FetchRequestOptions, FetchRequestOptionsTransformers } from "../../services/fetchClient";
import { apiConfig } from "../configs";
import { apiTransformers } from "../transformers/apiTransformers";

export const createPublicApiClient = (
  version: ApiVersionType,
  options?: Omit<FetchRequestOptions, "baseUrl" | keyof FetchRequestOptionsTransformers>,
): ApiFetchClient =>
  createClient({
    baseUrl: `${apiConfig.baseUrl ?? ""}/api/${version}`,
    ...apiTransformers,
    ...options,
  });
