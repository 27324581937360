import { useMemo } from "react";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";

export const useQueueTasksListItemSkeleton = () => {
  const isDesktop = useThemeIsDesktop();

  return useMemo(
    () =>
      isDesktop
        ? { title: 200, details: 90, footer: 115, children: [270, 120, 240, 280] }
        : { title: 200, details: 100, footer: 130, children: [270, 110, 250, 270] },

    [isDesktop],
  );
};
