import { combineSliceNames } from "../../../../common/store/utils";
import { CREDIT_EXECUTION_SLICE_NAME } from "../../store";
import { createApiThunks } from "../../../../common/store/utils/createApiThunk";
import { creditExecutionRejectionApi } from "../api/creditExecutionRejectionApi";

const CREDIT_EXECUTION_REJECTION_SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_SLICE_NAME, "rejection");

export const {
  cancelProduct: cancelProductCreditExecutionRejection,
  cancelDemand: cancelDemandCreditExecutionRejection,
} = createApiThunks(CREDIT_EXECUTION_REJECTION_SLICE_NAME, creditExecutionRejectionApi);
