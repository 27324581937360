import { RootState } from "../../../../common/store";
import { CreditExecutionDetailsActiveDemandState } from "./creditExecutionDetailsActiveDemand.types";
import { creditExecutionDetailsActiveDemandDocumentsAdapter } from "./creditExecutionDetailsActiveDemandDocuments.adapter";
import { selectCreditExecutionDetails } from "../creditExecutionDetails.selectors";

export const selectCreditExecutionDetailsActiveDemand = (state: RootState): CreditExecutionDetailsActiveDemandState =>
  selectCreditExecutionDetails(state).activeDemand;

export const { selectAll: selectCreditExecutionDetailsActiveDemandDocuments } =
  creditExecutionDetailsActiveDemandDocumentsAdapter.getSelectors(
    (state: RootState) => selectCreditExecutionDetailsActiveDemand(state).documents
  );
