import { Segment } from "../types/Segment";
import { AppUrl, AppUrlTypeEnum } from "../types";
import { UserFeatureFlagPermission } from "../../../types/user";
import { AppUrlCheckPermission } from "../types/IndexAppUrl";

export const makeAppUrl = <TUrl extends string, TUrlPart extends Segment<TUrl>>(
  url: TUrl,
  part: TUrlPart,
  checkPermission: AppUrlCheckPermission,
  urlType: AppUrlTypeEnum = AppUrlTypeEnum.Private,
): AppUrl<TUrl, TUrlPart> => ({
  url,
  part,
  urlType,
  checkPermission,
});

export const makePublicOrCommonUrl = <TUrl extends string, TUrlPart extends Segment<TUrl>>(
  url: TUrl,
  part: TUrlPart,
  urlType: AppUrlTypeEnum,
): AppUrl<TUrl, TUrlPart> => makeAppUrl(url, part, true, urlType);

export const makeUserCheckFeatureFlagPermission =
  (featureFlag: UserFeatureFlagPermission): AppUrlCheckPermission =>
  ({ userFeatureFlags }) => {
    return userFeatureFlags.includes(featureFlag);
  };
