import * as React from "react";
import { BaseCssProps, ElementColor } from "../../../../common/ui-kit/types";
import { ProductListDetailsContainer } from "../../../../common/ui/product/list/container";

export interface CreditWorkingAssetsCommonDetailsContainerProps extends BaseCssProps {
  requested: React.ReactNode;
  confirmed: React.ReactNode;
  approved: React.ReactNode;
  period: React.ReactNode;
  termFontColor?: ElementColor;
}

const terms = ["Запрашивается", "Одобрено", "Выдано", "Даты кредита"] as const;
type TermsType = (typeof terms)[number];

export const CreditWorkingAssetsCommonDetailsContainer = React.memo(
  ({
    requested,
    period,
    approved,
    confirmed,
    className,
    termFontColor,
  }: CreditWorkingAssetsCommonDetailsContainerProps) => {
    const items: { term: TermsType; value: React.ReactNode }[] = [
      { term: "Запрашивается", value: requested },
      { term: "Одобрено", value: confirmed },
      { term: "Выдано", value: approved },
      { term: "Даты кредита", value: period },
    ];

    return <ProductListDetailsContainer className={className} items={items} termFontColor={termFontColor} />;
  }
);
