import { Typography } from "../../../../../../../common/ui-kit/typography";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import {
  ProductListCardContainerDesktop,
  ProductListCardDetailsClientDesktop,
} from "../../../../../../../common/ui/product/list/card";

export interface CreditExecutionListItemDesktopCardProps extends BaseCssProps {
  clientName: React.ReactNode;
  clientTaxpayerNumber: React.ReactNode;
  numberAndDate: React.ReactNode;
  children?: React.ReactNode;
  details?: React.ReactNode;
}

export const CreditExecutionListItemDesktopCard = ({
  clientName,
  clientTaxpayerNumber,
  numberAndDate,
  children,
  details,
  className,
}: CreditExecutionListItemDesktopCardProps) => {
  return (
    <ProductListCardContainerDesktop
      className={className}
      details={
        <>
          <ProductListCardDetailsClientDesktop name={clientName} taxpayerNumber={clientTaxpayerNumber} pb="md" />
          <Typography fontSize="fz3" fontWeight={700} lineHeight="defaultLineHeight" fontColor="typography.primary">
            {numberAndDate}
          </Typography>
          {details}
        </>
      }
    >
      {children}
    </ProductListCardContainerDesktop>
  );
};
