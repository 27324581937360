import Skeleton from "react-loading-skeleton";
import { CreditWorkingAssetsCommonDetailsContainer } from "./CreditWorkingAssetsCommonDetailsContainer";
import { BaseCssProps } from "../../../../common/ui-kit/types";

export type CreditWorkingAssetsCommonDetailsSkeletonProps = BaseCssProps;

export const CreditWorkingAssetsCommonDetailsSkeleton = ({
  className,
}: CreditWorkingAssetsCommonDetailsSkeletonProps) => {
  return (
    <CreditWorkingAssetsCommonDetailsContainer
      requested={<Skeleton width={150} />}
      confirmed={<Skeleton width={130} />}
      period={<Skeleton width={230} />}
      approved={undefined}
      className={className}
    />
  );
};
