import { useEffect, useMemo, useState } from "react";

export const useDebounce = <T>(value: T, delay?: number): [value: T, setValue: (value: T) => void] => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return useMemo(() => [debouncedValue, setDebouncedValue], [debouncedValue]);
};
