import styled from "@emotion/styled";
import { SimplePopUp } from "../../../../../../../containers/popups";

export const Icon = styled.span`
  background-color: ${({ theme }) => theme.palette.control.secondaryLight};
  padding: ${({ theme }) => theme.spaces.xs};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.palette.control.secondaryMedium};
  }

  & > svg {
    & > path {
      stroke: ${({ theme }) => theme.palette.typography.medium};
    }
  }
`;

export const PopUp = styled(SimplePopUp)`
  padding: ${({ theme }) => theme.spaces.sm};
  min-width: 200px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  z-index: ${({ theme }) => theme.layout.zIndex};
`;
