import { useMemo } from "react";
import { getBankGuaranteeDetailsComplicatedTasksProjectValidationSchema } from "../validation/bankGuaranteeDetailsComplicatedTasksProjectValidationSchema";
import { BankGuaranteeDetailsComplicatedTaskProjectFormValues } from "../types/BankGuaranteeDetailsComplicatedTaskProjectFormValues";
import { DemandQueueTaskActiveProjectInfo } from "../../../../../demand/queue-tasks";

export const useBankGuaranteeDetailsComplicatedTaskProjectForm = (projectInfo?: DemandQueueTaskActiveProjectInfo) => {
  return useMemo(() => {
    const validationSchema = getBankGuaranteeDetailsComplicatedTasksProjectValidationSchema(
      projectInfo?.isNeedContacts,
      projectInfo?.isNeedGuaranteePrintType,
    );

    const objectToCast: Partial<Record<keyof BankGuaranteeDetailsComplicatedTaskProjectFormValues, string | boolean>> =
      {
        clientAdditionalNumber: projectInfo?.clientContacts?.additionalNumber,
        clientPhoneNumber: projectInfo?.clientContacts?.phone,
        clientEmail: projectInfo?.clientContacts?.email,
        beneficiaryAdditionalNumber: projectInfo?.beneficiaryContacts?.additionalNumber,
        beneficiaryPhoneNumber: projectInfo?.beneficiaryContacts?.phone,
        beneficiaryEmail: projectInfo?.beneficiaryContacts?.email,
        isNeedContacts: projectInfo?.isNeedContacts,
        guaranteePrintType: projectInfo?.guaranteePrintType,
      };

    const initialValues = validationSchema.cast(objectToCast, {
      assert: false,
      stripUnknown: true,
    }) as BankGuaranteeDetailsComplicatedTaskProjectFormValues;

    return { validationSchema, initialValues };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectInfo?.isNeedContacts]);
};
