import { ReactNode } from "react";
import { Box } from "../../../../../ui-kit/containers/box";
import { StackPanel } from "../../../../../ui-kit/containers/stack-panel";
import { BaseCssProps } from "../../../../../ui-kit/types";
import { Text } from "../../../../../ui-kit/typography";

const BoxText = Box.withComponent(Text);

export interface ProductListItemDetailsItemContainerProps extends BaseCssProps {
  term: ReactNode;
  description: ReactNode;
}

export const ProductListItemDetailsItemContainer = ({
  term,
  description,
  className,
}: ProductListItemDetailsItemContainerProps) => {
  return (
    <StackPanel direction="row" gap="xs" className={className}>
      <BoxText
        flexGrow={0}
        flexShrink={0}
        fontSize="fz2"
        fontWeight={500}
        lineHeight="defaultLineHeight"
        fontColor="primaryCaption"
      >
        {term}
      </BoxText>
      <BoxText
        overflow="hidden"
        fontSize="fz2"
        fontWeight={500}
        lineHeight="defaultLineHeight"
        fontColor="primary"
        withLineBreaks={false}
      >
        {description}
      </BoxText>
    </StackPanel>
  );
};
