import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FormSubmitHandler } from "../../../common/form";
import { AgencyDemandFormValues } from "../types/AgencyDemandFormValues";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectAgencyDemand } from "../store/agencyDemand.selectors";
import { useApiMutationAction } from "../../../common/hooks";
import { mapRequestedDocumentDemandQueueTasks, sendDecisionDemandQueueTasks } from "../../../demand/queue-tasks";
import { isBaseApiError } from "../../../common/api/types";
import { pushErrorGlobalToast, pushSuccessGlobalToast } from "../../../global/toasts";
import { NavigationStateFrom } from "../../../common/navigation/navigation-state";
import { agencyDemandFormValidationSchema } from "../validation/agencyDemandFormValidationSchema";

export const useAgencyDemandFormOnSubmit = (from: NavigationStateFrom): FormSubmitHandler<AgencyDemandFormValues> => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data } = useAppSelector(selectAgencyDemand);
  const { mutate } = useApiMutationAction();

  return useCallback(
    async (values) => {
      if (data?.decisions?.length === 1) {
        const castedValues = agencyDemandFormValidationSchema.cast(values);
        const documents = mapRequestedDocumentDemandQueueTasks(
          castedValues.documents ? Object.values(castedValues.documents) : []
        );

        const result = await mutate(
          sendDecisionDemandQueueTasks,
          {
            decision: data.decisions[0].code,
            queueTaskId: data.id,
            documents,
          },
          {
            errorHandler: async (e) => {
              let message = "Во время обработки запроса произошла ошибка. Пожалуйста, попробуйте ещё раз позже.";
              if (isBaseApiError(e)) {
                message = e.message ?? message;
              }

              dispatch(pushErrorGlobalToast(message));

              return message;
            },
          }
        );

        if (result?.body !== undefined) {
          dispatch(pushSuccessGlobalToast("Документы успешно отправлены"));
          navigate(from.url);
        }
      }
    },
    [data?.decisions, data?.id, dispatch, from.url, mutate, navigate]
  );
};
