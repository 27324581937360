import { useState } from "react";
import { TelegramIcon } from "../../../../ui-kit/icons";
import { FeatureFlagFeature } from "../../../../feature-manager";
import { UserFeatureFlagEnum } from "../../../../types/user";
import { StackPanel } from "../../../../ui-kit/containers/stack-panel";
import * as S from "./AppLayoutFooter.styled";
import { StyledExternalAppLink } from "../../../links/StyledExternalAppLink";
import { StyledAppLink } from "../../../links";

export interface AppLayoutFooterProps {
  withLayoutBorder?: boolean;
}

export const AppLayoutFooter = ({ withLayoutBorder }: AppLayoutFooterProps) => {
  const [year] = useState(new Date().getFullYear());

  return (
    <S.Footer withLayoutBorder={withLayoutBorder}>
      <S.Container>
        <StackPanel gap="sm" direction="column">
          <div>
            <StyledExternalAppLink selector={(x) => x.social.telegram}>
              <TelegramIcon /> Мы в Telegram
            </StyledExternalAppLink>
          </div>

          <div>
            &copy;&nbsp;
            <StyledExternalAppLink selector={(x) => x.home.homePage}>
              {import.meta.env.VITE_FOOTER_COMPANY_TITLE}.
            </StyledExternalAppLink>
            &nbsp;Все права защищены 2012&nbsp;&mdash;&nbsp;{year} г.
          </div>
        </StackPanel>

        <StackPanel gap="sm" direction="column">
          <FeatureFlagFeature flag={UserFeatureFlagEnum.IsOffer}>
            <StyledAppLink to={(x) => x.publicUser.offers.root}>Правила и порядок работы</StyledAppLink>
          </FeatureFlagFeature>
          <StyledAppLink to={(x) => x.publicUser.privacyPolicies.root}>
            Политика обработки персональных данных
          </StyledAppLink>
        </StackPanel>
      </S.Container>
    </S.Footer>
  );
};
