import styled from "@emotion/styled";
import { StyledRouterLink } from "../../../../common/ui/links";
import { ProductListItemColumn } from "../../../../common/ui/product/list/product-list-item";

export const Article = styled.article`
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(10, 71, 137, 0.25);
  border-radius: 8px;
  padding: ${({ theme }) => theme.spaces.md};
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spaces.md};
  width: 100%;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    grid-template-columns: 3fr 4fr 5fr;
    gap: 30px;
  }
`;

export const TitleColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.sm};
  justify-content: center;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    overflow: unset;
  }
`;

export const H3 = styled.h3`
  font-size: ${({ theme }) => theme.font.fz4};
  font-weight: 700;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  flex: 1;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > a {
    text-decoration: none;
    color: black;
  }

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    white-space: unset;
    flex: unset;
  }
`;

export const Time = styled.time`
  color: ${({ theme }) => theme.palette.typography.primaryCaption};
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Indicator = styled.div`
  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    position: absolute;
    left: -20px;
    top: calc(${({ theme }) => theme.font.fz3} / 2);
  }
`;

export const Details = styled.div`
  display: inline-flex;
  gap: ${({ theme }) => theme.spaces.sm};
  align-items: center;
`;

export const DemandLink = styled(StyledRouterLink)`
  display: block;
  text-align: center;
  font-weight: 500;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-size: ${({ theme }) => theme.font.fz3};
  padding: ${({ theme }) => theme.spaces.md} 0 0 0;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -${({ theme }) => theme.spaces.md};
    right: -${({ theme }) => theme.spaces.md};
    height: 1px;
    background-color: ${({ theme }) => theme.colors.secondaryLight};
  }
`;

export const ProductListItemRow = styled(ProductListItemColumn)`
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.xl};
`;
