import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { NavigationState } from "../types/NavigationState";

export const useCreateNavigationStateFrom = (backTitle?: string) => {
  const location = useLocation();

  return useMemo(
    (): NavigationState => ({
      from: { url: location.pathname + location.search, backTitle },
    }),

    [location, backTitle]
  );
};
