import styled from "@emotion/styled";
import { Link } from "../../../../../common/ui-kit/links";
import { StyledAppLink } from "../../../../../common/ui/links";

export const Note = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.md};
  font-size: ${({ theme }) => theme.font.fz3};
  font-weight: 500;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  border-left: 1px solid ${({ theme }) => theme.palette.status.warning};
  padding-left: ${({ theme }) => theme.spaces.lg};
`;

export const PhoneLink = styled(Link)`
  font-size: ${({ theme }) => theme.font.fz3};
`;

export const AppLink = styled(StyledAppLink)`
  font-size: ${({ theme }) => theme.font.fz3};
`;
