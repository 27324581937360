import { CSSObject } from "@emotion/react";
import { PropsWithChildren } from "react";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { BaseCssProps, ElementColor } from "../../../../../../common/ui-kit/types";
import { LineSeparator } from "../../../../../../common/ui-kit/separators";
import { Typography } from "../../../../../../common/ui-kit/typography";

const Article = ({ children, className }: PropsWithChildren<BaseCssProps>) => {
  return (
    <Box
      as="article"
      display="flex"
      flexDirection="column"
      gap="md"
      padding="lg"
      className={className}
      overflow="hidden"
    >
      {children}
    </Box>
  );
};

interface BlockProps extends PropsWithChildren<BaseCssProps> {
  withLineSeparator?: boolean;
  alignItems?: CSSObject["alignItems"];
}

const Block = ({ className, children, withLineSeparator, alignItems }: BlockProps) => {
  return (
    <Box display="flex" flexDirection="column" gap="md" className={className} alignItems={alignItems} overflow="hidden">
      {children}
      {withLineSeparator && <LineSeparator />}
    </Box>
  );
};

interface ContentTypographyProps extends PropsWithChildren<BaseCssProps> {
  fontColor?: ElementColor;
  fontWeight?: number;
}

export const ContentTypography = ({ children, className, fontColor, fontWeight = 500 }: ContentTypographyProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  return (
    <Typography
      className={className}
      fontWeight={fontWeight}
      fontSize={{ xs: "fz3", [desktopBreakpoint]: "fz2" }}
      lineHeight="defaultLineHeight"
      fontColor={fontColor}
    >
      {children}
    </Typography>
  );
};

export const BankGuaranteeDetailsDemandReconciliationContent = {
  Article,
  Block,
  Typography: ContentTypography,
};
