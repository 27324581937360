import { memo } from "react";
import { StackPanel } from "../../../../ui-kit/containers/stack-panel";
import { Typography } from "../../../../ui-kit/typography";

export interface ProductDetailsInformationListItemProps {
  title?: string;
  value?: string;
  reversed?: boolean;
}

export const ProductDetailsInformationListItem = memo(
  ({ title, value, reversed }: ProductDetailsInformationListItemProps) => {
    return (
      <StackPanel gap="none" direction={reversed ? "column-reverse" : "column"}>
        {title && (
          <Typography fontColor="dark" fontWeight={400} fontSize="fz3" lineHeight="defaultLineHeight">
            {title}
          </Typography>
        )}
        {value && (
          <Typography
            fontColor="typography.primary"
            fontWeight={500}
            fontSize="fz4"
            lineHeight="defaultLineHeight"
            withLineBreaks
          >
            {value}
          </Typography>
        )}
      </StackPanel>
    );
  }
);
