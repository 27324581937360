import { PublicUserCardWithTitle } from "../../../common/components";
import { usePublicUserRegistrationRegisterClient } from "../../hooks/usePublicUserRegistrationRegisterClient";
import { PublicUserRegistrationSuccessResultCard } from "../result-card/PublicUserRegistrationSuccessResultCard";
import { PublicUserRegistrationPageLayout } from "../../pages/PublicUserRegistrationPageLayout";
import { PublicUserRegistrationFailResultCard } from "../result-card/PublicUserRegistrationFailResultCard";
import {
  PublicUserRegistrationCommonForm,
  PublicUserRegistrationCommonFormProps,
} from "../common-form/PublicUserRegistrationCommonForm";
import { PublicUserRegistrationFormInitialState } from "../../types/PublicUserRegistrationFormInitialState";
import { usePageTitle } from "../../../../common/navigation/page-title";

export type PublicUserRegistrationClientProps = Pick<
  PublicUserRegistrationCommonFormProps,
  keyof PublicUserRegistrationFormInitialState
>;

export const PublicUserRegistrationClient = ({
  initialValues,
  initialTouched,
  isNeedToValidateOnMount,
}: PublicUserRegistrationClientProps) => {
  usePageTitle("Регистрация клиента");

  const { isError, isSuccess, error, data, onSubmit } = usePublicUserRegistrationRegisterClient();

  return (
    <>
      {!isError && !isSuccess && (
        <PublicUserRegistrationPageLayout>
          <PublicUserCardWithTitle title="Регистрация клиента">
            <PublicUserRegistrationCommonForm
              agencyType="Client"
              onSubmit={onSubmit}
              initialValues={initialValues}
              initialTouched={initialTouched}
              isNeedToValidateOnMount={isNeedToValidateOnMount}
            />
          </PublicUserCardWithTitle>
        </PublicUserRegistrationPageLayout>
      )}
      {isSuccess && data !== undefined && (
        <PublicUserRegistrationSuccessResultCard email={data.email} phone={data.phone} />
      )}
      {isError && error !== undefined && <PublicUserRegistrationFailResultCard message={error} />}
    </>
  );
};
