import { useEffect, useRef } from "react";
import { useBankGuaranteeDetailsFilterContext } from "./context/useBankGuaranteeDetailsFilterContext";
import { useBankGuaranteeDetailsFilterApiContext } from "./context/useBankGuaranteeDetailsFilterApiContext";

const SCROLL_CONTAINER_FOCUS_DURATION_MS = 1_500;

export const useBankGuaranteeDetailsFilterScrollTimeout = () => {
  const filteringObject = useBankGuaranteeDetailsFilterContext();
  const mutator = useBankGuaranteeDetailsFilterApiContext();

  const prevContainerValueRef = useRef<string | null | undefined>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const isSameContainer = prevContainerValueRef.current === filteringObject.containerToScroll;
    if (!isSameContainer) {
      prevContainerValueRef.current = filteringObject.containerToScroll;
      timeoutId = setTimeout(() => {
        mutator(filteringObject, (service) => service.removeScroll());
      }, SCROLL_CONTAINER_FOCUS_DURATION_MS);
    }

    return () => {
      if (!isSameContainer) {
        clearTimeout(timeoutId);
        prevContainerValueRef.current = undefined;
      }
    };
  }, [filteringObject, mutator]);
};
