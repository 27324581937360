// eslint-disable-next-line import/no-default-export
export default class LocalStorageService {
  private readonly key: string;

  constructor(key: string) {
    this.key = key;
  }

  getValue(): string | undefined {
    return localStorage.getItem(this.key) ?? undefined;
  }

  setValue(value: string) {
    localStorage.setItem(this.key, value);
  }

  removeItem() {
    localStorage.removeItem(this.key);
  }
}
