/*
 * Этот файл сгенерирован автоматически. Пожалуйста, не изменяйте его.
 * Дата генерации: Mon Feb 05 2024 17:36:11 GMT+0300 (Москва, стандартное время)
 */

export default {
  card: "0px 2px 2px 0px #0000001f",
  regShadow: "0px 35px 240px 0px #0a76ecb3",
  formBlock: "0px 4px 12px 0px #0a478933",
  cardShadow: "0px 4px 12px 0px #0a478933",
} as const;
