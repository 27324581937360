let isAsync = true;
const CAPICOM_ENCODE_BASE64 = 0;
const OID_CP_GOST_R3410EL = "1.2.643.2.2.19";
const OID_CP_GOST_R3410_12_256 = "1.2.643.7.1.1.1.1";
const OID_CP_GOST_R3410_12_512 = "1.2.643.7.1.1.1.2";

const certPropNames = ["Version", "Thumbprint", "SerialNumber", "IssuerName", "SubjectName", "ValidFromDate", "ValidToDate"
    , "HasPrivateKey", ["PrivateKey", "KeySpec", "UniqueContainerName", "ContainerName", "ProviderName", "ProviderType"]
    , ["KeyUsage", "IsCritical", "IsPresent"
        , "IsCRLSignEnabled", "IsDataEnciphermentEnabled", "IsDecipherOnlyEnabled", "IsDigitalSignatureEnabled"
        , "IsEncipherOnlyEnabled", "IsKeyAgreementEnabled", "IsKeyCertSignEnabled", "IsKeyEnciphermentEnabled", "IsNonRepudiationEnabled"]
    , ["ExtendedKeyUsage", "IsCritical", "IsPresent", ["EKUs", "Name", "OID"]]
    , ["PublicKey", "Length", ["EncodedParameters", "Value"], ["Algorithm", "Name", "Value", "FriendlyName"], ["EncodedKey", "Value"]]
    , ["IsValid", "Result"]
    //, "", "", "", "", "", ""]
];

try {
    eval('async () => {}');
} catch (e) {
    if (e instanceof SyntaxError)
        isAsync = false;
    else
        //throw e; // throws CSP error
        alert(e);
}

export const gost = {
    OID_CP_GOST_R3410EL: OID_CP_GOST_R3410EL,
    OID_CP_GOST_R3410_12_256: OID_CP_GOST_R3410_12_256,
    OID_CP_GOST_R3410_12_512: OID_CP_GOST_R3410_12_512
}

export function doesPluginWork(onReady, onError, checkPluginPeriod, checkPluginMaxTime) {
    try {
        if (isAsync)
            doesPluginWorkAsync(onReady, onError, checkPluginPeriod, checkPluginMaxTime);
        else {
            var oAbout = cadesplugin.CreateObject("CAdESCOM.About"); // eslint-disable-line no-undef
            onReady(typeof (oAbout.Version) != "undefined");
        }
    } catch (ex) {
        onReady(false);
    }
}

function doesPluginWorkAsync(onReady, onError, checkPluginPeriod, checkPluginMaxTime) {
    if (typeof (checkPluginPeriod) != "number")
        checkPluginPeriod = 100;

    if (typeof (checkPluginMaxTime) != "number")
        checkPluginMaxTime = 10000;

    window.cadesplugin_load_timeout = checkPluginMaxTime;

    new Promise(function (resolve, reject) {
        // eslint-disable-next-line no-undef
        cadesplugin.async_spawn(function* () {
            try {
                var oAbout = yield cadesplugin.CreateObjectAsync("CAdESCOM.About"); // eslint-disable-line no-undef
                var version = yield oAbout.Version;
                resolve(typeof (version) != "undefined");
            } catch (ex) {
                console.info(ex);
                if ((checkPluginMaxTime -= checkPluginPeriod) <= 0)
                    resolve(false);
                else
                    sleep(checkPluginPeriod, function () {
                        doesPluginWorkAsync(onReady, onError, checkPluginPeriod, checkPluginMaxTime);
                    }, onError);
            }
        });
    }).then(onReady, onError);
}

export function getCerificates(onReady, onError, certProperties, validTimeFilterFunc, keyAlgFilterFunc) {
    try {
        if (isAsync)
            getCerificatesAsync(onReady, onError, certProperties, validTimeFilterFunc, keyAlgFilterFunc);
        else {
            var myStoreExists = true;
            try {
                var oStore = cadesplugin.CreateObject("CAdESCOM.Store"); // eslint-disable-line no-undef
                oStore.Open();
            } catch (ex) {
                myStoreExists = false;
                console.warn("Открытие личного системного хранилища: " + ex);
            }
            var certCnt;
            if (myStoreExists) {
                certCnt = oStore.Certificates.Count;
                var oCerts = [];
                for (var i = 1; i <= certCnt; i++) {
                    var cert;
                    try {
                        cert = oStore.Certificates.Item(i);
                    } catch (ex) {
                        onError("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
                        return;
                    }
                    if (!certSatisfy(cert, validTimeFilterFunc, keyAlgFilterFunc))
                        continue;
                    oCerts.push(fillCerificate(cert, certProperties));
                }
                oStore.Close();
            }

            //В версии плагина 2.0.13292+ есть возможность получить сертификаты из
            //закрытых ключей и не установленных в хранилище
            try {
                oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE); // eslint-disable-line no-undef
                certCnt = oStore.Certificates.Count;
                for (var i = 1; i <= certCnt; i++) {
                    var cert = oStore.Certificates.Item(i);
                    //Проверяем не добавляли ли мы такой сертификат уже?
                    var found = false;
                    for (var j = 0; j < oCerts.length; j++) {
                        if (oCerts[j].Thumbprint === cert.Thumbprint) {
                            found = true;
                            break;
                        }
                    }
                    if (found)
                        continue;
                    if (!certSatisfy(cert, validTimeFilterFunc, keyAlgFilterFunc))
                        continue;
                    oCerts.push(fillCerificate(cert, certProperties));
                }
                oStore.Close();
            } catch (ex) {
                console.error("Ошибка получения сертификатов из ключевых контейнеров: " + ex);
            }
            onReady(oCerts);
        }
    } catch (ex) {
        onError("Ошибка получения сертификатов: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
    }
}

function getCerificatesAsync(onReady, onError, certProperties, validTimeFilterFunc, keyAlgFilterFunc) {
    new Promise(function (resolve, reject) {
        // eslint-disable-next-line no-undef
        cadesplugin.async_spawn(function* (args) {
            var MyStoreExists = true;
            var oStore = null;
            try {
                oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store"); // eslint-disable-line no-undef
                if (!oStore) {
                    reject("Create store failed");
                    return;
                }
                yield oStore.Open();
            } catch (ex) {
                MyStoreExists = false;
            }

            var oCerts = [];
            var certCnt;
            var certs;
            if (MyStoreExists) {
                try {
                    certs = yield oStore.Certificates;
                    certCnt = yield certs.Count;
                } catch (ex) {
                    reject("Ошибка при получении Certificates или Count: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
                    return;
                }
                for (var i = 1; i <= certCnt; i++) {
                    var cert;
                    try {
                        cert = yield certs.Item(i);
                    } catch (ex) {
                        reject("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
                        return;
                    }
                    if (!(yield* certSatisfyAsync(cert, validTimeFilterFunc, keyAlgFilterFunc)))
                        continue;
                    var oCert = yield* getPropertiesAsync(cert, certProperties);
                    if (oCert.ValidFromDate)
                        oCert.ValidFromDate = new Date(oCert.ValidFromDate);
                    if (oCert.ValidToDate)
                        oCert.ValidToDate = new Date(oCert.ValidToDate);
                    oCerts.push(oCert);
                }
                yield oStore.Close();
            }

            //В версии плагина 2.0.13292+ есть возможность получить сертификаты из
            //закрытых ключей и не установленных в хранилище
            try {
                yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE); // eslint-disable-line no-undef
                certs = yield oStore.Certificates;
                certCnt = yield certs.Count;
                for (var i = 1; i <= certCnt; i++) {
                    var cert = yield certs.Item(i);
                    //Проверяем не добавляли ли мы такой сертификат уже?
                    var found = false;
                    for (var j = 0; j < oCerts.length; j++) {
                        if ((yield oCerts[j].Thumbprint) === (yield cert.Thumbprint)) {
                            found = true;
                            break;
                        }
                    }
                    if (found)
                        continue;
                    if (!(yield* certSatisfyAsync(cert, validTimeFilterFunc, keyAlgFilterFunc)))
                        continue;
                    var oCert = yield* getPropertiesAsync(cert, certProperties);
                    if (oCert.ValidFromDate)
                        oCert.ValidFromDate = new Date(oCert.ValidFromDate);
                    if (oCert.ValidToDate)
                        oCert.ValidToDate = new Date(oCert.ValidToDate);
                    oCerts.push(oCert);
                }
                yield oStore.Close();
            } catch (ex) {
            }
            resolve(oCerts);
        });
    }).then(onReady, onError);
}

export function getCerificateInfo(onReady, onError, certThumbprint) {
    var oCert = null;
    try {
        if (isAsync)
            getCerificateInfoAsync(onReady, onError, certThumbprint);
        else {
            var cert = findCerificate(certThumbprint, onError);
            if (cert === null)
                return;
            oCert = getPropertiesSync(cert, certPropNames);
            if (typeof oCert.ValidFromDate !== 'undefined')
                oCert.ValidFromDate = new Date(oCert.ValidFromDate);
            if (typeof oCert.ValidToDate !== 'undefined')
                oCert.ValidToDate = new Date(oCert.ValidToDate);
            oCert.Content = cert.Export(CAPICOM_ENCODE_BASE64);
            onReady(oCert);
        }
    } catch (ex) {
        onError("Ошибка получения информации о сертификате: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
    }
}

function getCerificateInfoAsync(onReady, onError, certThumbprint) {
    new Promise(function (resolve, reject) {
        // eslint-disable-next-line no-undef
        cadesplugin.async_spawn(function* () {
            try {
                var cert = yield* findCerificateAsync(certThumbprint, reject);
                if (cert == null)
                    return;
                var oCert = yield* getPropertiesAsync(cert, certPropNames);
                if (oCert.ValidFromDate)
                    oCert.ValidFromDate = new Date(oCert.ValidFromDate);
                if (oCert.ValidToDate)
                    oCert.ValidToDate = new Date(oCert.ValidToDate);
                resolve(oCert);
            } catch (ex) {
                reject("Ошибка: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
            }
        });
    }).then(onReady, onError);
}

export function signHash(onReady, onError, hashValue, certThumbprint, checkCertificate, keyPin, tsaAddress) {
    try {
        if (isAsync)
            signHashAsync(onReady, onError, hashValue, certThumbprint, checkCertificate, keyPin, tsaAddress);
        else {
            var oCert = findCerificate(certThumbprint, onError);
            if (oCert === null)
                return;
            var hashAlg = cadescomHashAlgFromPubKeyOId(oCert.PublicKey().Algorithm.Value);
            var oHashedData = cadesplugin.CreateObject("CAdESCOM.HashedData"); // eslint-disable-line no-undef

            // Инициализируем объект заранее вычисленным хэш-значением
            // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
            oHashedData.Algorithm = hashAlg;
            try {
                oHashedData.SetHashValue(hashValue);
            } catch (ex) {
                onError("Не верный хэш данных: " + cadesplugin.getLastError(ex) + "\r\nПроверьте длину и соответствие на шестнадцатеричный формат."); // eslint-disable-line no-undef
                return;
            }
            var oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner"); // eslint-disable-line no-undef
            oSigner.CheckCertificate = checkCertificate;
            if (!checkCertificate)
                oSigner.Options = cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY; // eslint-disable-line no-undef
            oSigner.Certificate = oCert;
            if (keyPin)
                oSigner.KeyPin = keyPin;
            if (tsaAddress)
                oSigner.TSAAddress = tsaAddress;
            var oSignedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData"); // eslint-disable-line no-undef
            var sSignedMessage = null;
            // Вычисляем значение подписи
            try {
                sSignedMessage = oSignedData.SignHash(oHashedData, oSigner, tsaAddress ? cadesplugin.CADESCOM_CADES_X_LONG_TYPE_1 : cadesplugin.CADESCOM_CADES_BES); // eslint-disable-line no-undef
            } catch (err) {
                onError("Ошибка создания подписи: " + cadesplugin.getLastError(err)); // eslint-disable-line no-undef
                return;
            }
            onReady(sSignedMessage);
        }
    } catch (ex) {
        onError("Ошибка подписывания хэша: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
    }
}

function signHashAsync(onReady, onError, hashValue, certThumbprint, checkCertificate, keyPin, tsaAddress) {
    new Promise(function (resolve, reject) {
        // eslint-disable-next-line no-undef
        cadesplugin.async_spawn(function* () {
            try {
                var oCert = yield* findCerificateAsync(certThumbprint, reject);
                if (oCert === null)
                    return;
                var hashAlg = cadescomHashAlgFromPubKeyOId(yield (yield (yield oCert.PublicKey()).Algorithm).Value);
                var oHashedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.HashedData"); // eslint-disable-line no-undef

                // Инициализируем объект заранее вычисленным хэш-значением
                // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
                yield oHashedData.propset_Algorithm(hashAlg);
                try {
                    yield oHashedData.SetHashValue(hashValue);
                } catch (ex) {
                    reject("Не верный хэш данных: " + cadesplugin.getLastError(ex) + "\r\nПроверьте длину и соответствие на шестнадцатеричный формат."); // eslint-disable-line no-undef
                    return;
                }
                var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner"); // eslint-disable-line no-undef
                yield oSigner.propset_CheckCertificate(checkCertificate);
                if (!checkCertificate)
                    yield oSigner.propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY); // eslint-disable-line no-undef
                yield oSigner.propset_Certificate(oCert);
                if (keyPin)
                    yield oSigner.propset_KeyPin(keyPin);
                if (tsaAddress)
                    yield oSigner.propset_TSAAddress(tsaAddress);
                var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData"); // eslint-disable-line no-undef
                var sSignedMessage = null;
                // Вычисляем значение подписи
                try {
                    sSignedMessage = yield oSignedData.SignHash(oHashedData, oSigner, tsaAddress ? cadesplugin.CADESCOM_CADES_X_LONG_TYPE_1 : cadesplugin.CADESCOM_CADES_BES); // eslint-disable-line no-undef
                } catch (err) {
                    reject("Ошибка создания подписи: " + cadesplugin.getLastError(err)); // eslint-disable-line no-undef
                    return;
                }
                return resolve(sSignedMessage);
            } catch (ex) {
                reject("Ошибка: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
            }
        });
    }).then(onReady, onError);
}

export function signData(onReady, onError, dataToSign, certThumbprint, detached, checkCertificate, keyPin, tsaAddress) {
    try {
        if (isAsync)
            signDataAsync(onReady, onError, dataToSign, certThumbprint, detached, checkCertificate, keyPin, tsaAddress);
        else {
            var oCert = findCerificate(certThumbprint, onError);
            if (oCert === null)
                return;
            var oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner"); // eslint-disable-line no-undef
            oSigner.CheckCertificate = checkCertificate;
            if (!checkCertificate)
                oSigner.Options = cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY; // eslint-disable-line no-undef
            oSigner.Certificate = oCert;
            if (keyPin)
                oSigner.KeyPin = keyPin;
            if (tsaAddress)
                oSigner.TSAAddress = tsaAddress;
            var oSignedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData"); // eslint-disable-line no-undef
            oSignedData.Content = dataToSign;
            var sSignedMessage = null;
            // Вычисляем значение подписи
            try {
                sSignedMessage = oSignedData.SignCades(oSigner, tsaAddress ? cadesplugin.CADESCOM_CADES_X_LONG_TYPE_1 : cadesplugin.CADESCOM_CADES_BES, detached); // eslint-disable-line no-undef
            } catch (err) {
                onError("Ошибка создания подписи: " + cadesplugin.getLastError(err)); // eslint-disable-line no-undef
                return;
            }
            onReady(sSignedMessage);
        }
    } catch (ex) {
        onError("Ошибка подписывания данных: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
    }
}

function signDataAsync(onReady, onError, dataToSign, certThumbprint, detached, checkCertificate, keyPin, tsaAddress) {
    new Promise(function (resolve, reject) {
        // eslint-disable-next-line no-undef
        cadesplugin.async_spawn(function* () {
            try {
                var oCert = yield* findCerificateAsync(certThumbprint, reject);
                if (oCert === null)
                    return;
                var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner"); // eslint-disable-line no-undef
                yield oSigner.propset_CheckCertificate(checkCertificate);
                if (!checkCertificate)
                    yield oSigner.propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY); // eslint-disable-line no-undef
                yield oSigner.propset_Certificate(oCert);
                if (keyPin)
                    yield oSigner.propset_KeyPin(keyPin);
                if (tsaAddress)
                    yield oSigner.propset_TSAAddress(tsaAddress);
                var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData"); // eslint-disable-line no-undef
                yield oSignedData.propset_Content(dataToSign);
                var sSignedMessage = null;
                // Вычисляем значение подписи
                try {
                    sSignedMessage = yield oSignedData.SignCades(oSigner, tsaAddress ? cadesplugin.CADESCOM_CADES_X_LONG_TYPE_1 : cadesplugin.CADESCOM_CADES_BES, detached); // eslint-disable-line no-undef
                } catch (err) {
                    reject("Ошибка создания подписи: " + cadesplugin.getLastError(err)); // eslint-disable-line no-undef
                    return;
                }
                return resolve(sSignedMessage);
            } catch (ex) {
                reject("Ошибка: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
            }
        });
    }).then(onReady, onError);
}


///

function sleep(ms, onReady, onError) {
    new Promise(function (resolve) {
        setTimeout(resolve, ms)
    }).then(onReady, onError);
}

function certSatisfy(cert, validTimeFilterFunc, keyAlgFilterFunc) {
    if (validTimeFilterFunc && !validTimeFilterFunc(new Date(cert.ValidFromDate), new Date(cert.ValidToDate)))
        return false;
    if (keyAlgFilterFunc && !keyAlgFilterFunc(cert.PublicKey().Algorithm.Value))
        return false;
    return true;
}

function* certSatisfyAsync(cert, validTimeFilterFunc, keyAlgFilterFunc) {
    if (validTimeFilterFunc && !validTimeFilterFunc(new Date(yield cert.ValidFromDate), new Date(yield cert.ValidToDate)))
        return false;
    if (keyAlgFilterFunc && !keyAlgFilterFunc((yield (yield (yield cert.PublicKey()).Algorithm).Value)))
        return false;
    return true;
}

function fillCerificate(cert, certProperties) {
    var oCert = getPropertiesSync(cert, certProperties);
    if (typeof oCert.ValidFromDate !== 'undefined')
        oCert.ValidFromDate = new Date(oCert.ValidFromDate);
    if (typeof oCert.ValidToDate !== 'undefined')
        oCert.ValidToDate = new Date(oCert.ValidToDate);
    return oCert;
}

function getPropertiesSync(obj, propNames) {
    if (Array.isArray(obj)) {
        var ret = [];
        for (var i = 0; i < obj.length; ++i)
            ret.push(getPropertiesSync(obj[i], propNames));
    } else {
        var ret = {};
        for (var i = 0; i < propNames.length; ++i)
            try {
                var prop = propNames[i];
                if (Array.isArray(prop))
                    ret[prop[0]] = getPropertiesSync(getValue(obj, prop[0]), prop.slice(1));
                else
                    ret[prop] = getValue(obj, prop);
            } catch (ex) {
                console.error("Ошибка при получении свойства " + prop + " сертификата: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
            }
    }
    return ret;
}

function* getPropertiesAsync(obj, propNames) {
    if (Array.isArray(obj)) {
        var ret = [];
        for (var i = 0; i < obj.length; ++i)
            ret.push(yield* getPropertiesAsync(obj[i], propNames));
    } else {
        var ret = {};
        for (var i = 0; i < propNames.length; ++i)
            try {
                var prop = propNames[i];
                if (Array.isArray(prop))
                    ret[prop[0]] = yield* getPropertiesAsync(yield* getValueAsync(obj, prop[0]), prop.slice(1));
                else
                    ret[prop] = yield* getValueAsync(obj, prop);
            } catch (ex) {
                console.error("Ошибка при получении свойства " + prop + " сертификата: " + cadesplugin.getLastError(ex)); // eslint-disable-line no-undef
            }
    }
    return ret;
}

function getValue(obj, prop) {
    var val = obj[prop];
    if (typeof val == 'object' && typeof val.Count == "number") {
        var ret = [];
        for (var i = 1; i <= val.Count; ++i)
            ret.push(val.Item(i));
        return ret;
    }
    return (typeof val == "undefined") ? obj[prop]() : val;
}

function* getValueAsync(obj, prop) {
    var val = yield obj[prop];
    if (typeof val == 'object' && typeof (yield val.Count) == "number") {
        var ret = [];
        for (var i = 1; i <= (yield val.Count); ++i)
            ret.push(yield val.Item(i));
        return ret;
    }
    return (typeof val == "function") ? (yield obj[prop]()) : val;
}

function findCerificate(certThumbprint, onError) {
    var myStoreExists = true;
    var oStore = cadesplugin.CreateObject("CAdESCOM.Store"); // eslint-disable-line no-undef
    if (!oStore) {
        onError("Create CAdESCOM.Store failed");
        return null;
    }
    try {
        oStore.Open();
    } catch (ex) {
        myStoreExists = false;
        console.warn("Открытие личного системного хранилища: " + ex);
    }
    var certCnt = 0;
    if (myStoreExists) {
        var certs = oStore.Certificates;
        certs = certs.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, certThumbprint); // eslint-disable-line no-undef
        certCnt = certs.Count;
    }
    if (certCnt == 0) {
        if (myStoreExists)
            oStore.Close();
        //В версии плагина 2.0.13292+ есть возможность получить сертификаты из
        //закрытых ключей и не установленных в хранилище
        oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE); // eslint-disable-line no-undef
        certs = oStore.Certificates;
        certs = certs.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, certThumbprint); // eslint-disable-line no-undef
        certCnt = certs.Count;
    }
    oStore.Close();
    if (certCnt > 1)
        console.warn("Более одного (" + certCnt + ") сертификата с отпечатком " + certThumbprint);
    if (certCnt == 0 && typeof (onError) != "undefined")
        onError("Сертификат с отпечатком " + certThumbprint + " не найден ни в личном системном хранилище сертификатов ни в ключевых контейнерах.");
    return certCnt > 0 ? certs.Item(1) : null;
}

function* findCerificateAsync(certThumbprint, onError) {
    var myStoreExists = true;
    var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store"); // eslint-disable-line no-undef
    if (!oStore) {
        onError("Create CAdESCOM.Store failed");
        return null;
    }
    try {
        yield oStore.Open();
    } catch (ex) {
        myStoreExists = false;
        console.warn("Открытие личного системного хранилища: " + ex);
    }
    var certCnt = 0;
    if (myStoreExists) {
        var certs = yield oStore.Certificates;
        certs = yield certs.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, certThumbprint); // eslint-disable-line no-undef
        certCnt = yield certs.Count;
    }
    if (certCnt == 0) {
        if (myStoreExists)
            yield oStore.Close();
        //В версии плагина 2.0.13292+ есть возможность получить сертификаты из
        //закрытых ключей и не установленных в хранилище
        yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE); // eslint-disable-line no-undef
        certs = yield oStore.Certificates;
        certs = yield certs.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, certThumbprint); // eslint-disable-line no-undef
        certCnt = yield certs.Count;
    }
    yield oStore.Close();
    if (certCnt > 1)
        console.warn("Более одного (" + certCnt + ") сертификата с отпечатком " + certThumbprint);
    if (certCnt == 0 && typeof (onError) != "undefined")
        onError("Сертификат с отпечатком " + certThumbprint + " не найден ни в личном системном хранилище сертификатов ни в ключевых контейнерах.");
    return certCnt > 0 ? (yield certs.Item(1)) : null;
}

function cadescomHashAlgFromPubKeyOId(pubKeyOId) {
    switch (pubKeyOId) {
        case OID_CP_GOST_R3410EL:
            return cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411; // eslint-disable-line no-undef
        case OID_CP_GOST_R3410_12_256:
            return cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256; // eslint-disable-line no-undef
        case OID_CP_GOST_R3410_12_512:
            return cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512; // eslint-disable-line no-undef
        default:
            throw new Error("Не поддерживаемый алгоритм ключа " + pubKeyOId);
    }
}


// module.exports = {
//     doesPluginWork,
//     getCerificates,
//     getCerificateInfo,
//     signHash,
//     signData,
//     gost: {
//         OID_CP_GOST_R3410EL,
//         OID_CP_GOST_R3410_12_256,
//         OID_CP_GOST_R3410_12_512
//     },
// }
