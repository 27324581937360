import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import { useAppDispatch } from "../../common/store";
import { LoadingStatusEnum } from "../../common/types/state";
import { valueValidationService } from "../../common/validation";
import { getNameClientSearch } from "../store/clientSearch.thunks";

export const useClientSearchGetNameOnChange = (handler: (name: string) => void | string | Promise<void | string>) => {
  const dispatch = useAppDispatch();
  const [prevValue, setPrevValue] = useState<string>("");
  const [status, setStatus] = useState<LoadingStatusEnum>(LoadingStatusEnum.Idle);

  const onChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.trim();

      if (prevValue !== value) {
        setPrevValue(value);
        setStatus(LoadingStatusEnum.Idle);
      }

      if (await valueValidationService.isValidTaxpayerNumber(value)) {
        setStatus(LoadingStatusEnum.Loading);

        try {
          const result = await dispatch(getNameClientSearch({ taxpayerNumber: value })).unwrap();
          handler(result.body.data);
          setStatus(LoadingStatusEnum.Succeeded);
        } catch (e: unknown) {
          setStatus(LoadingStatusEnum.Failed);
        }
      }
    },
    [dispatch, prevValue, handler],
  );

  return useMemo(() => ({ status, onChange }), [status, onChange]);
};
