import { entityHelper } from "@sideg/helpers";
import { ThemeMobileDesktopView, useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { Box } from "../../../../../../../../../common/ui-kit/containers/box";
import { Typography } from "../../../../../../../../../common/ui-kit/typography";
import { ButtonLink } from "../../../../../../../../../common/ui-kit/buttons/ButtonLink";
import { DotSeparatedRowContainer } from "../../../../../../../../../common/ui-kit/containers/dot-separated-row";
import { useBankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualOnUpdate } from "../../../../../hooks/useBankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualOnUpdate";
import { BaseCssProps } from "../../../../../../../../../common/ui-kit/types";
import { BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualTooltip } from "./BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualTooltip";

const BoxTypography = Typography.withComponent(Box);

export interface BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualProps extends BaseCssProps {
  phone: string;
  additionalNumber?: string;
  email: string;
  entityType: "client" | "beneficiary";
}

export const BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActual = ({
  phone,
  additionalNumber,
  email,
  entityType,
  className,
}: BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  const { onUpdate, isUpdated } = useBankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualOnUpdate({
    phone,
    additionalNumber,
    email,
    entityType,
  });

  const updateButton = (
    <Box display="flex" alignItems="center" justifyContent="flex-end" flexGrow={1}>
      <ButtonLink type="button" onClick={onUpdate}>
        Обновить
      </ButtonLink>
    </Box>
  );

  return (
    !isUpdated && (
      <Box
        className={className}
        display="flex"
        flexDirection={{ xs: "column", [desktopBreakpoint]: "row" }}
        alignItems={{ xs: "shrink", [desktopBreakpoint]: "center" }}
        justifyContent="flex-start"
        backgroundColor="control.primaryLight"
        px="sm"
        py="xs"
        borderRadius="4px"
        gap={{ xs: "sm", [desktopBreakpoint]: "lg" }}
      >
        <BoxTypography
          flexShrink={0}
          fontSize="fz2"
          lineHeight="defaultLineHeight"
          fontWeight={500}
          fontColor="typography.primary"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="sm"
        >
          <span>Указать новые данные</span>
          <BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualTooltip>
            В последней заявке указаны другие данные {entityType === "client" ? "клиента" : "заказчика"}. Обновите их,
            если требуется
          </BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActualTooltip>
          <ThemeMobileDesktopView desktop={undefined} mobile={updateButton} />
        </BoxTypography>
        <BoxTypography
          flexShrink={0}
          fontSize="fz2"
          lineHeight="defaultLineHeight"
          fontWeight={500}
          fontColor="typography.secondary"
        >
          <DotSeparatedRowContainer gap="xs">
            <span>{entityHelper.formatPhoneNumber(phone)}</span>
            {additionalNumber && <span>{additionalNumber}</span>}
          </DotSeparatedRowContainer>
        </BoxTypography>

        <Typography
          withTextOverflowEllipsis
          fontSize="fz2"
          lineHeight="defaultLineHeight"
          fontWeight={500}
          fontColor="typography.secondary"
        >
          {email}
        </Typography>
        <ThemeMobileDesktopView mobile={undefined} desktop={updateButton} />
      </Box>
    )
  );
};
