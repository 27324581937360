import styled from "@emotion/styled";

export const Wrapper = styled.footer`
  gap: ${({ theme }) => theme.spaces.md};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spaces.md};
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  flex: 0 0 90px;
  background-color: #fff;
`;

export const Button = styled.button`
  border: 2px solid transparent;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  padding: ${({ theme }) => theme.spaces.xs};
  width: 40px;
  height: 40px;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;

  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.primaryDark};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }

  & > svg > path {
    stroke: ${({ theme }) => theme.palette.typography.primaryLight};
  }
`;
