import * as React from "react";
import * as S from "./ButtonLinkWithIcon.styled";

export interface ButtonLinkWithIconProps extends Omit<JSX.IntrinsicElements["button"], "ref" | "children"> {
  icon: React.ElementType;
  children: React.ReactNode;
}

export const ButtonLinkWithIcon = React.forwardRef<HTMLButtonElement, ButtonLinkWithIconProps>(
  ({ icon, children, ...rest }, ref) => {
    return (
      <S.Button {...rest} ref={ref}>
        <S.Icon as={icon} />
        {children}
      </S.Button>
    );
  }
);
