import { useEffect, useRef, useState } from "react";
import { QueueTaskId } from "../../../../common/types/demand";
import { useBankGuaranteeDetailsFilterContext } from "./context/useBankGuaranteeDetailsFilterContext";
import { BankGuaranteeDetailsFilterContainerToScrollType } from "../../types/filters/BankGuaranteeDetailsFilterContainerToScrollTypeValues";

export const useBankGuaranteeDetailsFilterScrollIntoContainer = (
  type: BankGuaranteeDetailsFilterContainerToScrollType,
  containerQueueTaskId?: QueueTaskId,
  scrollPosition: ScrollLogicalPosition = "center",
) => {
  const [isFocused, setIsFocused] = useState(false);
  const { containerToScroll, queueTaskId } = useBankGuaranteeDetailsFilterContext();

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const isCurrentContainer =
      containerRef.current !== null &&
      type === containerToScroll &&
      (containerToScroll !== "task" || queueTaskId === containerQueueTaskId);

    setIsFocused(isCurrentContainer);
    if (isCurrentContainer) {
      containerRef.current?.scrollIntoView({ behavior: "smooth", block: scrollPosition });
    }
  }, [containerQueueTaskId, containerToScroll, queueTaskId, scrollPosition, type]);

  return { containerRef, isFocused };
};
