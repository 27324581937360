import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { BANK_GUARANTEE_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const bankGuaranteeRewardsUrls = {
  root: makeAppUrl(
    joinUrlParts(BANK_GUARANTEE_ROOT_URL, "reward"),
    "/guarantee/reward",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductBGReward),
  ),
};
