import { RootState } from "../../../../common/store";
import { CreditWorkingAssetsDetailsDemandsState } from "./creditWorkingAssetsDetailsDemands.types";
import { creditWorkingAssetsDetailsDemandsAdapter } from "./creditWorkingAssetsDetailsDemands.adapter";
import { selectCreditWorkingAssetsDetails } from "../creditWorkingAssetsDetails.selectors";

export const selectCreditWorkingAssetsDetailsDemands = (state: RootState): CreditWorkingAssetsDetailsDemandsState =>
  selectCreditWorkingAssetsDetails(state).demands;

export const {
  selectIds: selectCreditWorkingAssetsDetailsDemandsIds,
  selectById: selectCreditWorkingAssetsDetailsDemandById,
  selectTotal: selectCreditWorkingAssetsDetailsDemandsTotal,
} = creditWorkingAssetsDetailsDemandsAdapter.getSelectors(selectCreditWorkingAssetsDetailsDemands);
