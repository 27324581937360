import styled, { CSSObject } from "@emotion/styled";

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.section<{
  columnsSizes: CSSObject["gridTemplateColumns"][];
}>`
  display: grid;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  grid-template-columns: ${({ columnsSizes }) => {
    return columnsSizes.join(" ");
  }};
  overflow-x: auto;
  gap: ${({ theme }) => theme.spaces.lg};
  padding-bottom: ${({ theme }) => theme.spaces.xs};
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.lg};
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz1};
  color: ${({ theme }) => theme.colors.dark};
  padding-top: ${({ theme }) => theme.spaces.lg};
  padding-bottom: ${({ theme }) => theme.spaces.xl};
`;

export const BankTitle = styled.h3`
  font-weight: 700;
  font-size: 21px;
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
`;

export const TableCaption = styled.div`
  line-height: 12px;
`;
