import { useBankGuaranteeListFilteringItemsConverter } from "../../../hooks/filters/useBankGuaranteeListFilteringItemsConverter";
import { useBankGuaranteeListFilterContext } from "../../../hooks/filter-context/useBankGuaranteeListFilterContext";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { bankGuaranteeListFiltersValidationSchema } from "../../../validation/bankGuaranteeListFiltersValidationSchema";
import { ProductFiltersBadges } from "../../../../../common/filters/use-cases";

export type BankGuaranteeListFiltersBadgesProps = BaseCssProps;

export const BankGuaranteeListFiltersBadges = ({ className }: BankGuaranteeListFiltersBadgesProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const converter = useBankGuaranteeListFilteringItemsConverter();

  return (
    <ProductFiltersBadges
      filteringObject={filteringObject}
      schema={bankGuaranteeListFiltersValidationSchema}
      converter={converter}
      className={className}
    />
  );
};
