import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { DemandQueueTaskCommentContainer } from "./DemandQueueTaskCommentContainer";
import { DemandQueueTaskCommentTextBody } from "./DemandQueueTaskCommentTextBody";

export interface DemandQueueTaskCommentSkeletonProps extends BaseCssProps {
  textLinesCount?: number;
  authorLength?: number;
}

export const DemandQueueTaskCommentSkeleton = ({
  authorLength = 100,
  textLinesCount = 2,
  className,
}: DemandQueueTaskCommentSkeletonProps) => {
  return (
    <DemandQueueTaskCommentContainer title={<Skeleton width={authorLength} />} className={className}>
      <DemandQueueTaskCommentTextBody>
        <Skeleton count={textLinesCount} />
      </DemandQueueTaskCommentTextBody>
    </DemandQueueTaskCommentContainer>
  );
};
