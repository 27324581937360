import { ReactNode } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "react-calendar/dist/Calendar.css";
import { LayoutContext } from "../store/LayoutContext";
import { useAppGlobalHubs } from "../hooks/useAppGlobalHubs";
import { AppLayoutContent } from "./content/AppLayoutContent";
import { useAppLayoutContextProvider } from "../hooks/useAppLayoutContextProvider";
import { PeriodicSetPwaPromotion } from "../../../../pwa/portal/PeriodicSetPwaPromotion";

export interface AppLayoutProps {
  children: ReactNode;
}

export const AppLayout = ({ children }: AppLayoutProps) => {
  useAppGlobalHubs();
  const contextValue = useAppLayoutContextProvider();

  return (
    <LayoutContext.Provider value={contextValue}>
      <AppLayoutContent>
        {children}
        <PeriodicSetPwaPromotion />
      </AppLayoutContent>
    </LayoutContext.Provider>
  );
};
