import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.dark};

  & > dt {
    font-weight: 400;
  }

  & > dd {
    font-weight: 500;
  }

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spaces.md};

    & > dt {
      flex-basis: 35%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    & > dd {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
`;
