import { CheckboxSize } from "@sideg/ui-kit/atoms/checkbox/types/CheckboxSizeTypes";
import { ThemeTypographyVariantType } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypography";
import { ThemePaletteColors } from "@sideg/ui-kit/core/theme/types/colors/ThemePalette";

export const getCheckboxLabelVariant = (checkboxSize?: CheckboxSize): ThemeTypographyVariantType => {
  switch (checkboxSize) {
    case "small":
      return "mobile.secText16Medium";
    case "default":
      return "mobile.text18Medium";
    default:
      return "mobile.text18Medium";
  }
};

export const getCheckboxLabelColor = (isDisabled?: boolean): ThemePaletteColors =>
  isDisabled ? "light.neutral.text.quarternary" : "light.neutral.text.primary";
