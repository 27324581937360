import { useContext } from "react";
import { BankGuaranteeListFilterContext } from "../../store/filter-context/BankGuaranteeListFilterContext";
import { BankGuaranteeListFilterContextValue } from "../../types/filters/BankGuaranteeListFilterContextValue";

export const useBankGuaranteeListFilterContext = (): BankGuaranteeListFilterContextValue => {
  const value = useContext(BankGuaranteeListFilterContext);

  if (value === undefined) {
    throw new Error("Попытка получить значение контекста вне контекста");
  }

  return value;
};
