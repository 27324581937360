import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

const titles = {
  1: "Обычная",
  2: "Упрощенная",
};

export const bankGuaranteeDocumentsClientTaxSystemType = makeEnumLikeLiteral([1, 2] as const, titles);

export type BankGuaranteeDocumentsClientTaxSystemType = EnumLikeLiteralType<
  typeof bankGuaranteeDocumentsClientTaxSystemType
>;
