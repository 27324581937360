import styled from "@emotion/styled";
import { ForwardedRef } from "react";
import * as React from "react";
import { ChevronLeftIcon } from "../../../icons";

const StyledButton = styled.button<{ opened: boolean }>`
  font-size: ${({ theme }) => theme.font.fz2};
  padding: ${({ theme }) => theme.spaces.sm} ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.sm}
    ${({ theme }) => theme.spaces.sm};
  border: none;
  color: ${({ theme }) => theme.colors.dark};
  background-color: ${({ theme, opened }) =>
    opened ? theme.palette.control.primaryLight : theme.palette.background.light};
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.md};
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.palette.control.secondaryLight};
  }

  & > svg {
    width: 18px;
    height: 18px;
    transform: ${({ opened }) => `rotate(${opened ? 90 : -90}deg)`};
    transition: transfrom 0.1s linear;

    & > path {
      stroke: ${({ theme }) => theme.colors.dark};
    }
  }
`;

const Button = (
  { children, ...rest }: React.ComponentProps<typeof StyledButton>,
  ref?: ForwardedRef<HTMLButtonElement>
) => {
  return (
    <StyledButton {...rest} ref={ref}>
      {children} <ChevronLeftIcon />
    </StyledButton>
  );
};

export const FilterExpandButton = React.forwardRef(Button);
