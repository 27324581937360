import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { creditWorkingAssetsDetailsDemandsAdapter } from "./creditWorkingAssetsDetailsDemands.adapter";
import { combineSliceNames } from "../../../../common/store/utils";
import { creditWorkingAssetsDetailsDemandsMapper } from "./creditWorkingAssetsDetailsDemands.mapper";
import {
  CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME,
  creditWorkingAssetsDetailsActiveProductChanged,
} from "../creditWorkingAssetsDetails.actions";
import {
  getCreditWorkingAssetsDetails,
  uploadGroupDocumentCreditWorkingAssetsDetails,
} from "../creditWorkingAssetsDetails.thunks";

const SLICE_NAME = combineSliceNames(CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME, "demands");

const initialState = creditWorkingAssetsDetailsDemandsAdapter.getInitialState();

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(creditWorkingAssetsDetailsActiveProductChanged, (state, action) => {
        if (action.payload === undefined) {
          creditWorkingAssetsDetailsDemandsAdapter.removeAll(state);

          return;
        }

        creditWorkingAssetsDetailsDemandsAdapter.setAll(
          state,
          creditWorkingAssetsDetailsDemandsMapper.mapDemands(action.payload.demands ?? []),
        );
      })
      .addMatcher(
        isAnyOf(getCreditWorkingAssetsDetails.fulfilled, uploadGroupDocumentCreditWorkingAssetsDetails.fulfilled),
        (state, action) => {
          creditWorkingAssetsDetailsDemandsAdapter.upsertMany(
            state,
            creditWorkingAssetsDetailsDemandsMapper.mapDemands(action.payload.body.demands),
          );
        },
      );
  },
});

export const creditWorkingAssetsDetailsDemandsReducer = slice.reducer;
