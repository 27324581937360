import { combineReducers } from "redux";
import { bankGuaranteeDetailsGuaranteeDetailsReducer } from "./guarantee-details/bankGuaranteeDetailsGuaranteeDetails.slice";
import { bankGuaranteeDetailsDemandsReducer } from "./demands/bankGuaranteeDetailsDemands.slice";
import { bankGuaranteeDetailsTasksHistoryReducer } from "./tasks-history/bankGuaranteeDetailsTasksHistory.slice";
import { bankGuaranteeDetailsActiveDemandReducer } from "./active-demand/bankGuaranteeDetailsActiveDemand.slice";
import { bankGuaranteeDetailsActiveTasksReducer } from "./active-tasks/bankGuaranteeDetailsActiveTasks.slice";
import { bankGuaranteeDetailsReissuesReducer } from "./reissues/BankGuaranteeDetailsReissues.slice";
import { bankGuaranteeDetailsDocumentsReducer } from "./documents/bankGuaranteeDetailsDocuments.slice";
import { bankGuaranteeDetailsTransferReducer } from "./transfer/bankGuaranteeDetailsTransfer.slice";
import { bankGuaranteeDetailsRequestOriginalReducer } from "./request-original/bankGuaranteeDetailsRequestOriginal.slice";

export const bankGuaranteeDetailsReducer = combineReducers({
  guarantee: bankGuaranteeDetailsGuaranteeDetailsReducer,
  demands: bankGuaranteeDetailsDemandsReducer,
  documents: bankGuaranteeDetailsDocumentsReducer,
  tasksHistory: bankGuaranteeDetailsTasksHistoryReducer,
  activeDemand: bankGuaranteeDetailsActiveDemandReducer,
  activeTasks: bankGuaranteeDetailsActiveTasksReducer,
  reissues: bankGuaranteeDetailsReissuesReducer,
  requestOriginal: bankGuaranteeDetailsRequestOriginalReducer,
  transfer: bankGuaranteeDetailsTransferReducer,
});
