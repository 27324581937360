import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { CREDIT_WORKING_ASSETS_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const creditWorkingAssetsCreationUrls = {
  create: makeAppUrl(
    joinUrlParts(CREDIT_WORKING_ASSETS_ROOT_URL, "create"),
    "/credit-working-assets/create",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCwa),
  ),
  createByCreditWorkingAssets: makeAppUrl(
    joinUrlParts(CREDIT_WORKING_ASSETS_ROOT_URL, "create/:creditWorkingAssetsId"),
    "/credit-working-assets/create/:creditWorkingAssetsId",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCwa),
  ),
};
