import * as React from "react";
import { PropsWithChildren } from "react";
import "./Button.scss";

export interface IButtonProps {
  isDisabled: boolean;
  type: ButtonType;
  color: ButtonColor;
  onClick?: Function;
}

export enum ButtonType {
  Submit = "submit",
  Button = "button",
  Reset = "reset",
}

export enum ButtonColor {
  Green = "button_green",
  Gray = "button_gray",
  Blue = "button_blue",
  BlueLight = "button_blue-light",
  Red = "button_red",
  RedFill = "button_red-fill",
}

const Button: React.FC<PropsWithChildren<IButtonProps>> = (props) => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <button
      type={props.type}
      className={"button " + props.color}
      disabled={props.isDisabled}
      onClick={() => handleClick()}
    >
      {props.children}
    </button>
  );
};

export default Button;
