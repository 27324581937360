import { RootState } from "../../../../common/store";
import { BankGuaranteeDetailsDemandsState } from "./bankGuaranteeDetailsDemands.types";
import { bankGuaranteeDetailsDemandsAdapter } from "./bankGuaranteeDetailsDemands.adapter";

export const selectBankGuaranteeDetailsDemands = (state: RootState): BankGuaranteeDetailsDemandsState =>
  state.domains.bankGuarantee.details.demands;

export const {
  selectIds: selectBankGuaranteeDetailsDemandsIds,
  selectById: selectBankGuaranteeDetailsDemandById,
  selectTotal: selectBankGuaranteeDetailsDemandsTotal,
  selectAll: selectBankGuaranteeDetailsDemandsAll,
} = bankGuaranteeDetailsDemandsAdapter.getSelectors(selectBankGuaranteeDetailsDemands);
