import { useMemo } from "react";
import * as React from "react";
import { TabsContextType } from "../types/TabsContextType";
import * as S from "./Tabs.styled";

export interface TabsProps<TTab extends string> extends TabsContextType<TTab>, S.TabsContainerProps {
  className?: string;
  children?: React.ReactNode;
}

export const createTabsComponent =
  <TTab extends string>(Provider: React.Provider<TabsContextType<TTab>>) =>
  // eslint-disable-next-line react/function-component-definition
  ({ children, className, onTabChange, activeTab, gap, isGrow }: TabsProps<TTab>) => {
    const contextValue = useMemo(() => ({ onTabChange, activeTab }), [onTabChange, activeTab]);

    return (
      <Provider value={contextValue}>
        <S.Container className={className} gap={gap} isGrow={isGrow}>
          {children}
        </S.Container>
      </Provider>
    );
  };
