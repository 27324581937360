import * as React from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { Text } from "../../../../../common/ui-kit/typography";
import * as S from "./SettlementAndCashCreationFromBankContainer.styled";

export interface SettlementAndCashCreationFromBankContainerProps extends BaseCssProps {
  bankName: React.ReactNode;
  button: React.ReactNode;
  isSelected: boolean;
}

export const SettlementAndCashCreationFromBankContainer = ({
  bankName,
  button,
  isSelected,
  className,
}: SettlementAndCashCreationFromBankContainerProps) => {
  return (
    <S.Wrapper isSelected={isSelected} className={className}>
      <Text fontColor="primary" fontWeight={500} fontSize="fz4" lineHeight="defaultLineHeight">
        {bankName}
      </Text>
      {button}
    </S.Wrapper>
  );
};
