import { useCallback, useMemo, useRef } from "react";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";

const getSearchParamValue = (search: URLSearchParamsInit, name: string) =>
  new URLSearchParams(search.toString()).get(name);

/**
 * Возвращает состояние из queryParams, и функцию, позволяющую задать значение этому параметру, сохраняя другие параметры.
 * При изменении параметров, экземпляр функции не меняется. Если нужно другое поведение - см. see
 * @see useSearchParams
 */
export const useSingleSearchParam = (name: string) => {
  const [params, setParams] = useSearchParams();
  const value = useMemo(() => getSearchParamValue(params, name), [name, params]);

  const paramsRef = useRef(params);
  paramsRef.current = params;

  const setParamsRef = useRef(setParams);
  setParamsRef.current = setParams;

  const setParamValue = useCallback(
    (setValue: string | null | ((prev: string | null) => string | null)) => {
      const updatedSearchParams = new URLSearchParams(paramsRef.current.toString());
      const newValue =
        typeof setValue === "function" ? setValue(getSearchParamValue(paramsRef.current, name)) : setValue;

      if (newValue === null) {
        updatedSearchParams.delete(name);
      } else {
        updatedSearchParams.set(name, newValue);
      }

      setParamsRef.current(updatedSearchParams.toString(), { replace: true });
    },
    [name],
  );

  return [value, setParamValue] as const;
};
