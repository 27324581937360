import { dateTimeHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeId } from "../../../../../../common/types/demand";
import { useAppSelector } from "../../../../../../common/store";
import {
  selectBankGuaranteeListItemsClientById,
  selectBankGuaranteeListItemsGuaranteeById,
} from "../../../../store/items/bankGuaranteeListItems.selectors";
import { NavigationState } from "../../../../../../common/navigation/navigation-state";
import { BankGuaranteeListItemMobileDemands } from "./demand-list/BankGuaranteeListItemMobileDemands";
import { BankGuaranteeListItemMobileIssue } from "./issue/BankGuaranteeListItemMobileIssue";
import { useBankGuaranteeListItemDemandLink } from "../../../../hooks/list/useBankGuaranteeListItemDemandLink";
import { useBankGuaranteeListActions } from "../../../../hooks/list/actions/useBankGuaranteeListActions";
import { BankGuaranteeListItemMobileCard } from "./card/BankGuaranteeListItemMobileCard";
import {
  ProductListCardFooterDemandLinkMobile,
  ProductListCardMoreActionsButton,
} from "../../../../../../common/ui/product/list/card";

export interface BankGuaranteeListItemMobileProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  from?: NavigationState;
}

export const BankGuaranteeListItemMobile = ({ guaranteeId, from, className }: BankGuaranteeListItemMobileProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const guarantee = useAppSelector((state) => selectBankGuaranteeListItemsGuaranteeById(state, guaranteeId))!;
  const client = useAppSelector((state) => selectBankGuaranteeListItemsClientById(state, guarantee.clientId));
  const actions = useBankGuaranteeListActions(guarantee, from);

  const linkProps = useBankGuaranteeListItemDemandLink(guaranteeId, guarantee?.issuedDemandId);

  return (
    <BankGuaranteeListItemMobileCard
      className={className}
      numberAndDate={`Заявка № ${guaranteeId} • ${dateTimeHelper.format(
        new Date(guarantee.dateAdded),
        "dayShortMonthYear",
      )}`}
      actionsButton={actions.length > 0 && <ProductListCardMoreActionsButton availableActions={actions} />}
      clientName={client?.name}
      guaranteeType={guarantee.guaranteeType.title}
      footer={
        <ProductListCardFooterDemandLinkMobile {...linkProps} state={from}>
          Перейти к заявке
        </ProductListCardFooterDemandLinkMobile>
      }
    >
      {!guarantee.issuedDemandId && <BankGuaranteeListItemMobileDemands from={from} guaranteeId={guaranteeId} />}
      {guarantee.issuedDemandId !== undefined && (
        <BankGuaranteeListItemMobileIssue from={from} guaranteeId={guaranteeId} demandId={guarantee.issuedDemandId} />
      )}
    </BankGuaranteeListItemMobileCard>
  );
};
