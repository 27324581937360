import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { ProductListCreationButton } from "../../../../common/ui/product/list/creation-button";
import { FilterBadgesListEmpty } from "../../../../common/ui-kit/filters/badges";
import { useCreateNavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { selectCreditExecutionFilters } from "../../store/filters/creditExecutionListFilters.selectors";
import { useAppSelector } from "../../../../common/store";
import { useFirstRenderThunkDispatch } from "../../../../common/hooks";
import { getFiltrationParametersCreditExecutionList } from "../../store/creditExecutionList.thunks";
import { CreditExecutionListHeaderFilters } from "./filters/CreditExecutionListHeaderFilters";
import { CreditExecutionListHeaderFiltersBadges } from "./CreditExecutionListHeaderFiltersBadges";
import { CreditExecutionListTabFilter } from "./tabs/CreditExecutionListTabFilter";

export const CreditExecutionListHeader = () => {
  const state = useCreateNavigationStateFrom("Мои заявки");
  const { data, status } = useAppSelector(selectCreditExecutionFilters);

  useFirstRenderThunkDispatch(status, getFiltrationParametersCreditExecutionList);

  return (
    <AppLayoutResponsiveListPage.Header>
      <StackPanel gap="lg" direction="row" justifyContent="space-between">
        <AppLayoutResponsiveListPage.Title>Мои заявки на КИК</AppLayoutResponsiveListPage.Title>
        <ProductListCreationButton to={(x) => x.creditExecution.creation.create} state={state} />
      </StackPanel>
      <CreditExecutionListHeaderFilters />
      {!data && <FilterBadgesListEmpty />}
      {data && <CreditExecutionListHeaderFiltersBadges />}
      <AppLayoutResponsiveListPage.TabsContainer>
        <CreditExecutionListTabFilter />
      </AppLayoutResponsiveListPage.TabsContainer>
    </AppLayoutResponsiveListPage.Header>
  );
};
