import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { isIdle } from "../../../common/types/state";
import { AsyncThunkActionRequest } from "../../../common/types/thunks";
import { CreditWorkingAssetsId } from "../../../common/types/demand";
import { getCreditWorkingAssetsDetails } from "../store/creditWorkingAssetsDetails.thunks";
import { selectCreditWorkingAssetsDetailsProductDetails } from "../store/product-details/creditWorkingAssetsDetailsProductDetails.selectors";

export const useCreditWorkingAssetsDetailsFetcher = (creditWorkingAssetsId: CreditWorkingAssetsId) => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectCreditWorkingAssetsDetailsProductDetails);

  const requestRef = useRef<AsyncThunkActionRequest<{ creditWorkingAssetsId: CreditWorkingAssetsId }> | undefined>(
    undefined
  );

  useEffect(() => {
    requestRef.current?.abort();
    requestRef.current = undefined;
  }, [creditWorkingAssetsId]);

  useEffect(() => {
    if (isIdle(status)) {
      requestRef.current = dispatch(getCreditWorkingAssetsDetails({ creditWorkingAssetsId }));
    }
  }, [creditWorkingAssetsId, dispatch, status]);
};
