import { FieldInputProps, useField } from "formik";
import { useMemo } from "react";
import { FormNumberInputProps } from "@sideg/ui-kit/atoms/form-controls/number-input";

export const useFormControlsNumberInputField = (
  name: string,
): FieldInputProps<number | undefined> & Pick<FormNumberInputProps, "onStepDownClick" | "onStepUpClick"> => {
  const [fieldProps, { error, touched }, { setValue }] = useField<number | undefined>(name);

  return useMemo(() => {
    const onStepUpClick = async () => {
      await setValue((fieldProps.value ?? 0) + 1);
    };

    const onStepDownClick = async () => {
      await setValue((fieldProps.value ?? 0) - 1);
    };

    return { ...fieldProps, onStepUpClick, onStepDownClick, error: touched ? error : undefined };
  }, [error, fieldProps, setValue, touched]);
};
