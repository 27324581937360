import { ButtonLinkWithIcon } from "../../../../../../common/ui-kit/buttons/link-with-icon";
import { RepeatIcon } from "../../../../../../common/ui-kit/icons";
import { BankGuaranteeId, DemandId } from "../../../../../../common/types/demand";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useAppDispatch } from "../../../../../../common/store";
import { onReissueBankGuaranteeList } from "../../../../store/item-modification/bankGuaranteeListItemModification.slice";

export interface BankGuaranteeListItemReissueButtonProps extends BaseCssProps {
  guaranteeId: BankGuaranteeId;
  demandId: DemandId;
}

export const BankGuaranteeListItemReissueButton = ({
  demandId,
  guaranteeId,
  className,
}: BankGuaranteeListItemReissueButtonProps) => {
  const dispatch = useAppDispatch();
  const onClick = () => {
    dispatch(onReissueBankGuaranteeList({ demandId, guaranteeId }));
  };

  return (
    <ButtonLinkWithIcon type="button" icon={RepeatIcon} className={className} onClick={onClick}>
      Перевыпустить
    </ButtonLinkWithIcon>
  );
};
