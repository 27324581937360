import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { DoneCircleBigIcon } from "../../../../../common/ui-kit/icons";
import { AppLinkButton } from "../../../../../common/ui/links";
import { LegalServicesCreationCard } from "./LegalServicesCreationCard";

export const LegalServicesCreationDoneCard = ({ className }: BaseCssProps) => {
  return (
    <LegalServicesCreationCard
      icon={<DoneCircleBigIcon />}
      title="Заявка принята"
      description="Наш менеджер перезвонит на указанный в заявке номер телефона в течение рабочего дня"
      className={className}
    >
      <AppLinkButton to={(x) => x.home}>Вернуться на главную</AppLinkButton>
    </LegalServicesCreationCard>
  );
};
