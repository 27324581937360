import * as React from "react";
import {
  AlertLoadingWithToIndicatorTransition,
  AlertLoadingWithToIndicatorTransitionProps,
} from "./AlertLoadingWithToIndicatorTransition";
import { BaseCssProps } from "../../../types";

export interface AlertLoadingToResultProps extends BaseCssProps {
  isLoading?: boolean;
  loadingText?: React.ReactNode;
  isError?: boolean;
  errorText?: React.ReactNode;
  isSuccess?: boolean;
  successText?: React.ReactNode;
  isIdle?: boolean;
  idleText?: React.ReactNode;
}

export const AlertLoadingToResult = ({
  loadingText,
  successText,
  isSuccess,
  errorText,
  isError,
  isLoading,
  isIdle,
  idleText,
  className,
}: AlertLoadingToResultProps) => {
  const getResult = (): AlertLoadingWithToIndicatorTransitionProps["result"] => {
    if (isError) {
      return "error";
    }

    if (isSuccess) {
      return "success";
    }

    return undefined;
  };

  return (
    <AlertLoadingWithToIndicatorTransition className={className} isLoading={isLoading === true} result={getResult()}>
      {isLoading === true && loadingText !== undefined && loadingText}
      {isError === true && errorText !== undefined && errorText}
      {isSuccess === true && successText !== undefined && successText}
      {isIdle === true && idleText !== undefined && idleText}
    </AlertLoadingWithToIndicatorTransition>
  );
};
