import { useMemo } from "react";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { CreditWorkingAssetsDemandStageType } from "../types";
import { ProductStageIcon } from "../../../common/ui/product/stage";
import { getCreditWorkingAssetsDemandStageColor, getCreditWorkingAssetsDemandStageIcon } from "../utils";

export interface CreditWorkingAssetsStageIconProps extends BaseCssProps {
  stage: CreditWorkingAssetsDemandStageType;
}

export const CreditWorkingAssetsStageIcon = ({ stage, className }: CreditWorkingAssetsStageIconProps) => {
  const color = getCreditWorkingAssetsDemandStageColor(stage);
  const icon = useMemo(() => getCreditWorkingAssetsDemandStageIcon(stage), [stage]);

  return <ProductStageIcon icon={icon} color={color} className={className} />;
};
