import { useResponsiveRange, useTheme } from "@sideg/ui-kit";
import { useAppLayout } from "./useAppLayout";

export const useAppLayoutAsideRenderState = (): boolean => {
  const theme = useTheme();
  const { lowerThan } = useResponsiveRange();

  const { isFullSize } = useAppLayout();

  return lowerThan(theme.breakpoints.desktopBreakpoint, false) || !isFullSize.value;
};
