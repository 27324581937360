import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { FilteringObjectType } from "../types/FilteringObjectType";
import { encodeQueryParams } from "../../utils/encodeQueryParams";

export const useSetQuerySearch = (pathname: string) => {
  const navigator = useNavigate();

  return useCallback(
    (filteringObject: FilteringObjectType) => {
      const search = encodeQueryParams(filteringObject);
      navigator({ pathname, search: search !== "" ? `?${search}` : "" }, { replace: true });
    },
    [navigator, pathname],
  );
};
