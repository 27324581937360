import * as React from "react";
import * as S from "./BaseFormInputElement.styled";
import { BaseFormElementError, BaseFormElementErrorProps } from "./BaseFormElementError";

export interface BaseFormInputElementProps extends BaseFormElementErrorProps {
  label?: string;
  labelFor?: string;
  children: React.ReactNode;
  disabled?: boolean;
  afterInput?: React.ReactNode;
  beforeInput?: React.ReactNode;
  className?: string;
}

export const BaseFormInputElement = ({
  label,
  children,
  disabled,
  labelFor,
  error,
  errorDataTestId,
  afterInput,
  beforeInput,
  className,
}: BaseFormInputElementProps) => {
  return (
    <S.Container className={className}>
      {label && (
        <S.LabelWrapper>
          <S.Label htmlFor={labelFor}>{label}</S.Label>
        </S.LabelWrapper>
      )}
      <S.InputElement role="button">
        <S.InputBox error={!!error} disabled={disabled}>
          {beforeInput && <S.InputBeforeContainer>{beforeInput}</S.InputBeforeContainer>}
          <S.InputBoxMain>{children}</S.InputBoxMain>
          {afterInput && <S.InputAfterContainer>{afterInput}</S.InputAfterContainer>}
        </S.InputBox>
      </S.InputElement>
      <BaseFormElementError errorDataTestId={errorDataTestId} error={error} />
    </S.Container>
  );
};
