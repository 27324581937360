import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { currencyHelper, numberHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { BankGuaranteeDetailsDemandReconciliationContentBadge } from "../content/BankGuaranteeDetailsDemandReconciliationContentBadge";
import { BankGuaranteeDetailsDemandHeaderReconciliationCardAdditionalConditionsBlock } from "./additional-conditions/BankGuaranteeDetailsDemandHeaderReconciliationCardAdditionalConditionsBlock";
import { BankGuaranteeDetailsDemandReconciliationBillFile } from "./BankGuaranteeDetailsDemandReconciliationBillFile";
import { Box } from "../../../../../../common/ui-kit/containers/box";
import { BankGuaranteeDetailsDemandReconciliationBillDataItem } from "./BankGuaranteeDetailsDemandReconciliationBillDataItem";
import { BankGuaranteeDetailsDemandActualBill } from "../../../../types/demand/reconciliation/BankGuaranteeDetailsDemandActualBill";
import { BankGuaranteeDetailsDemandReconciliationContent } from "../content/BankGuaranteeDetailsDemandReconciliationContent";
import { BankGuaranteeDetailsDemandReconciliationContentTitleWithState } from "../content/BankGuaranteeDetailsDemandReconciliationContentTitleWithState";
import { BankGuaranteeDetailsDemandReconciliationContentFooter } from "../content/BankGuaranteeDetailsDemandReconciliationContentFooter";
import { BankGuaranteeDetailsDemandReconciliationContentOffer } from "../content/BankGuaranteeDetailsDemandReconciliationContentOffer";
import { selectBankGuaranteeDetailsActiveDemand } from "../../../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { BankGuaranteeDetailsDemandReconciliationConfirmPayment } from "./confirm-payment/BankGuaranteeDetailsDemandReconciliationConfirmPayment";
import { useAppSelector } from "../../../../../../common/store";
import { BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus } from "../../../../complicated-tasks/bill/types/BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus";

export interface BankGuaranteeDetailsDemandReconciliationBillCardProps extends BaseCssProps {
  model: BankGuaranteeDetailsDemandActualBill | undefined;
}

export const BankGuaranteeDetailsDemandReconciliationBillContent = ({
  className,
  model,
}: BankGuaranteeDetailsDemandReconciliationBillCardProps) => {
  const isDesktop = useThemeIsDesktop();
  const { canSendComment } = useAppSelector(selectBankGuaranteeDetailsActiveDemand);

  return (
    model !== undefined && (
      <BankGuaranteeDetailsDemandReconciliationContent.Article className={className}>
        <BankGuaranteeDetailsDemandReconciliationContent.Block withLineSeparator>
          <BankGuaranteeDetailsDemandReconciliationContentTitleWithState
            title={isDesktop ? "Стоимость БГ" : "Согласование стоимости БГ"}
            state={model.state.code}
          />
        </BankGuaranteeDetailsDemandReconciliationContent.Block>
        {model.decision && model.decisionOfferQueueTaskId !== undefined && (
          <BankGuaranteeDetailsDemandReconciliationContentBadge
            queueTaskId={model.decisionOfferQueueTaskId}
            decision={model.decision.code}
            groupType={model.groupType.code}
          />
        )}
        <BankGuaranteeDetailsDemandReconciliationContentOffer
          queueTaskId={model.actualOfferQueueTaskId}
          groupType={model.groupType.code}
          state={model.state.code}
        >
          <Box display="flex" flexDirection="column" gap="sm">
            <BankGuaranteeDetailsDemandReconciliationBillDataItem
              term="Итоговая стоимость"
              description={currencyHelper.getCurrencyString(model.costFinal.sum)}
            />
            <BankGuaranteeDetailsDemandReconciliationBillDataItem
              term="Ставка"
              description={`${currencyHelper.getCurrencyStringWithoutRubleSign(model.costFinal.rate, true)}% годовых`}
            />
          </Box>
          {model.rewardAgent !== undefined && (
            <BankGuaranteeDetailsDemandReconciliationBillDataItem
              term="Вознаграждение агента"
              description={
                <>
                  {currencyHelper.getCurrencyString(model.rewardAgent.sum)} (
                  {numberHelper.getFormattedDecimal(model.rewardAgent.rate, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                  })}
                  %)
                </>
              }
            />
          )}
        </BankGuaranteeDetailsDemandReconciliationContentOffer>
        {model.additionalConditions !== undefined && model.additionalConditions.length > 0 && (
          <BankGuaranteeDetailsDemandHeaderReconciliationCardAdditionalConditionsBlock
            terms={model.additionalConditions}
          />
        )}
        {model.file && (
          <BankGuaranteeDetailsDemandReconciliationContent.Block withLineSeparator>
            <BankGuaranteeDetailsDemandReconciliationBillFile file={model.file} />
            {model.confirmationPaymentStatus !==
              BankGuaranteeDetailsComplicatedTaskBillDecisionConfirmPaymentStatus.ConfirmationRejected && (
              <BankGuaranteeDetailsDemandReconciliationConfirmPayment
                confirmationPaymentStatus={model.confirmationPaymentStatus}
                confirmationPaymentFile={model.confirmationPaymentFile}
              />
            )}
          </BankGuaranteeDetailsDemandReconciliationContent.Block>
        )}
        <BankGuaranteeDetailsDemandReconciliationContentFooter
          groupType={model.groupType.code}
          canSendComment={canSendComment}
        />
      </BankGuaranteeDetailsDemandReconciliationContent.Article>
    )
  );
};
