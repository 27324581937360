import { ReactNode, useState } from "react";
import FocusTrap from "focus-trap-react";
import { useAppSelector } from "../../../../common/store";
import {
  selectQueueTasksNotifications,
  selectQueueTasksNotificationsIds,
} from "../../store/queueTasksNotifications.selectors";
import { QueueTasksNotification } from "../list-item/QueueTasksNotification";
import { QueueTaskId } from "../../../../common/types/demand";
import { QueueTasksNotificationsLoadingStatus } from "./QueueTasksNotificationsLoadingStatus";
import { QueueTasksListFilterParams } from "../../../list/types/filter";
import { QueueTasksNotificationsListH3 } from "./QueueTasksNotificationsListH3";
import { QueueTasksNotificationsEmptyListState } from "./QueueTasksNotificationsEmptyListState";
import { useReachGoalAnalytics } from "../../../../common/analytics/hooks";
import { useAppNavigation } from "../../../../common/urls/internal/hooks";
import { isFailed } from "../../../../common/types/state";
import { Box } from "../../../../common/ui-kit/containers/box";
import { ButtonLink } from "../../../../common/ui-kit/buttons/ButtonLink";

const LinkBox = Box.withComponent(ButtonLink);

export interface QueueTasksNotificationsListProps {
  allowOutsideClick: (event: MouseEvent | TouchEvent) => boolean;
  before?: ReactNode;
}

export const QueueTasksNotificationsList = ({ allowOutsideClick, before }: QueueTasksNotificationsListProps) => {
  const { status } = useAppSelector(selectQueueTasksNotifications);
  const ids = useAppSelector(selectQueueTasksNotificationsIds);
  const reachGoal = useReachGoalAnalytics();
  const navigator = useAppNavigation();

  const [queueTasksListFilter] = useState({
    tab: "New",
    ordering: "DateAddedDesc",
  } as QueueTasksListFilterParams);

  const onShowAllButtonClick = () => {
    reachGoal("QUEUE_TASKS_NOTIFICATIONS_SHOW_ALL_BUTTON_CLICK");
    navigator((x) => x.queueTasks.list.root, undefined, { search: queueTasksListFilter });
  };

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick }}>
      <Box pt="xl" pb="lg" display="flex" flexDirection="column">
        {before}
        <Box pl="lg" pr="md">
          {ids.length > 0 && (
            <>
              <QueueTasksNotificationsListH3>Нужно действие с вашей стороны</QueueTasksNotificationsListH3>
              <QueueTasksNotificationsLoadingStatus />
              <Box display="flex" isFullWidth flexDirection="column" gap="sm">
                {ids.map((x) => (
                  <QueueTasksNotification queueTaskId={x as QueueTaskId} key={x} />
                ))}
                <LinkBox alignSelf="flex-start" type="button" onClick={onShowAllButtonClick}>
                  Открыть все задачи
                </LinkBox>
              </Box>
            </>
          )}
          {ids.length === 0 && !isFailed(status) && <QueueTasksNotificationsEmptyListState />}
        </Box>
      </Box>
    </FocusTrap>
  );
};
