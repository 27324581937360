import { useCallback, useMemo } from "react";
import * as React from "react";
import { useBankGuaranteeCreationSetFederalField } from "./useBankGuaranteeCreationSetFederalField";
import { BankGuaranteeCreationPurchaseType } from "../../../types/BankGuaranteeCreationPurchaseType";
import { federalLawRules } from "../../../../common/validation";
import { useBankGuaranteeCreationForm } from "../useBankGuaranteeCreationForm";
import { federalLawTypeWithCommerce } from "../../../../../common/types/demand";

export const useBankGuaranteeCreationPurchaseTypeField = () => {
  const [{ value }, { setValue, setTouched }] = useBankGuaranteeCreationSetFederalField();
  const {
    values: { guaranteeType },
  } = useBankGuaranteeCreationForm();

  const isChecked = useCallback(
    (purchaseType: BankGuaranteeCreationPurchaseType) => {
      return (
        (purchaseType === "commercial" && value === "COMMERCE") ||
        (purchaseType === "federalLaw" && value !== "COMMERCE")
      );
    },
    [value],
  );

  const onChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      await setValue(
        (event.target.value as BankGuaranteeCreationPurchaseType) === "commercial"
          ? "COMMERCE"
          : federalLawTypeWithCommerce.values[0],
        true,
      );
    },
    [setValue],
  );

  const onBlur = useCallback(async () => {
    await setTouched(true, true);
  }, [setTouched]);

  const isDisabled = useCallback(
    (purchaseType: BankGuaranteeCreationPurchaseType) => {
      return purchaseType === "federalLaw" && federalLawRules.paymentGuaranteeType.condition(guaranteeType);
    },
    [guaranteeType],
  );

  return useMemo(() => ({ isChecked, onChange, onBlur, isDisabled }), [isChecked, isDisabled, onBlur, onChange]);
};
