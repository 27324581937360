import styled from "@emotion/styled";

export const Counter = styled.span`
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: normal;
  margin-left: 4px;
  min-width: 35px;
  display: inline-block;
`;
