import { memo } from "react";
import { Text } from "../../../../../common/ui-kit/typography";
import { useBankGuaranteeDetailsLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../../../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { isLoading } from "../../../../../common/types/state";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useAppUrlMatch } from "../../../../../common/urls/internal/hooks";
import {
  ProductDetailsAsideNavigationCard,
  ProductDetailsAsideNavigationCardSkeleton,
} from "../../../../../common/product-details";

export type BankGuaranteeDetailsNavigationCardGuaranteeDetailsProps = BaseCssProps;

export const BankGuaranteeDetailsNavigationCardGuaranteeDetails = memo(
  ({ className }: BankGuaranteeDetailsNavigationCardGuaranteeDetailsProps) => {
    const { data, status } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);
    const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();
    const { path, isExact } = useAppUrlMatch((x) => x.bankGuarantee.details.details, {
      guaranteeId,
    });

    return (
      <>
        {!data && isLoading(status) && <ProductDetailsAsideNavigationCardSkeleton height={40} />}
        {data && (
          <ProductDetailsAsideNavigationCard to={path} isActive={isExact} className={className}>
            <Text fontSize="fz2" fontWeight={500}>
              Информация о закупке
            </Text>
          </ProductDetailsAsideNavigationCard>
        )}
      </>
    );
  }
);
