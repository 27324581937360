import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsRequestOriginalCommentById } from "../../../store/request-original/bankGuaranteeDetailsRequestOriginal.selectors";
import { DemandQueueTaskCompletedSingleCommentCard, DemandQueueTaskCard } from "../../../../../demand/queue-tasks";

export interface BankGuaranteeDetailsRequestOriginalCommentProps extends BaseCssProps {
  id: number;
}

export const BankGuaranteeDetailsRequestOriginalComment = ({
  id,
  className,
}: BankGuaranteeDetailsRequestOriginalCommentProps) => {
  const comment = useAppSelector((state) => selectBankGuaranteeDetailsRequestOriginalCommentById(state, id));

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {comment && (
        <DemandQueueTaskCard variant="completed" title="Комментарий" className={className}>
          <DemandQueueTaskCompletedSingleCommentCard comment={comment} />
        </DemandQueueTaskCard>
      )}
    </>
  );
};
