import { useMemo } from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditWorkingAssetsId } from "../../../../../common/types/demand";
import { useCreditWorkingAssetsCreationScoring } from "../../../hooks/scoring/useCreditWorkingAssetsCreationScoring";
import {
  createByExistsCreditWorkingAssetsCreation,
  scoringByExistsCreditWorkingAssetsCreation,
} from "../../../store/creditWorkingAssetsCreation.thunks";
import { useCreditWorkingAssetsCreationSubmitAfterScoring } from "../../../hooks/creation/useCreditWorkingAssetsCreationSubmitAfterScoring";
import { CreditWorkingAssetsCreationScoringForm } from "../CreditWorkingAssetsCreationScoringForm";
import { CreditWorkingAssetsCreationScoringCreateByExistsFormContent } from "./CreditWorkingAssetsCreationScoringCreateByExistsFormContent";

export interface CreditWorkingAssetsCreationScoringCreateByExistsFormProps extends BaseCssProps {
  creditWorkingAssetsId: CreditWorkingAssetsId;
  creditSum: number | undefined;
}

export const CreditWorkingAssetsCreationScoringCreateByExistsForm = ({
  creditWorkingAssetsId,
  creditSum,
  className,
}: CreditWorkingAssetsCreationScoringCreateByExistsFormProps) => {
  const inputModel = useMemo(() => ({ creditWorkingAssetsId }), [creditWorkingAssetsId]);
  useCreditWorkingAssetsCreationScoring(scoringByExistsCreditWorkingAssetsCreation, inputModel);

  const { onSubmit } = useCreditWorkingAssetsCreationSubmitAfterScoring(
    createByExistsCreditWorkingAssetsCreation,
    inputModel
  );

  return (
    <CreditWorkingAssetsCreationScoringForm className={className} onSubmit={onSubmit}>
      <CreditWorkingAssetsCreationScoringCreateByExistsFormContent creditSum={creditSum} />
    </CreditWorkingAssetsCreationScoringForm>
  );
};
