import { ReactNode, useEffect } from "react";
import { useAppLayout } from "./useAppLayout";

export const useAppLayoutHeaderActionEffect = (node: ReactNode) => {
  const {
    headerAction: { setValue },
  } = useAppLayout();
  useEffect(() => {
    setValue(node);

    return () => {
      setValue(undefined);
    };
  }, [node, setValue]);
};
