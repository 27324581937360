import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { AppLink } from "../../../../../../../common/urls/internal/components";
import { makeShouldForwardProp } from "../../../../../../../common/ui-kit/utils";

interface LinkProps {
  isRejected: boolean;
  hasActiveTasks: boolean;
}

export const Link = styled(AppLink, {
  shouldForwardProp: makeShouldForwardProp<LinkProps>((prop) => prop !== "isRejected" && prop !== "hasActiveTasks"),
})<LinkProps>(
  ({ theme }) => css`
    text-decoration: none;
    font-weight: 500;
    font-size: ${theme.font.fz2};
    line-height: ${theme.font.defaultLineHeight};
    color: ${theme.palette.typography.primaryCaption};
    display: flex;
    align-items: center;
    gap: ${theme.spaces.sm};

    &:hover,
    &:focus-visible {
      & > span:first-of-type {
        text-decoration: underline;
      }
    }

    & > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `,
  ({ theme, hasActiveTasks, isRejected }) => {
    if (isRejected) {
      return { color: theme.palette.typography.medium };
    }

    if (hasActiveTasks) {
      return { color: theme.colors.primary };
    }

    return {};
  },
);
