import { useEffect } from "react";
import { PublicUserRegistrationAgentStageType } from "../../types/agent/PublicUserRegistrationAgentStageType";
import { AgencyId } from "../../../../common/types/agencies";
import {
  changeStagePublicUserRegistrationAgent,
  ensureIsActualAgencyPublicUserRegistrationAgent,
} from "../../store/agent/publicUserRegistrationAgent.slice";
import { useAppDispatch, useAppSelector } from "../../../../common/store";
import { selectPublicUserRegistrationAgent } from "../../store/agent/publicUserRegistrationAgent.selectors";

export const usePublicUserRegistrationsRegisterAgentStageType = (
  agencyId: AgencyId | undefined
): PublicUserRegistrationAgentStageType => {
  const dispatch = useAppDispatch();
  const { stage } = useAppSelector(selectPublicUserRegistrationAgent);

  useEffect(() => {
    dispatch(ensureIsActualAgencyPublicUserRegistrationAgent(agencyId));
  }, [agencyId, dispatch]);

  useEffect(() => {
    dispatch(changeStagePublicUserRegistrationAgent(agencyId === undefined ? "form" : "documents"));
  }, [agencyId, dispatch]);

  return stage;
};
