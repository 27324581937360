import * as React from "react";
import * as S from "./FiltersSlidersIconButton.styled";
import { SlidersIcon } from "../../../../ui-kit/icons";

export type FiltersSlidersIconButtonProps = Omit<React.ComponentProps<typeof S.Button>, "children" | "type">;

export const FiltersSlidersIconButton = (props: FiltersSlidersIconButtonProps) => {
  return (
    <S.Button type="button" {...props}>
      <SlidersIcon />
    </S.Button>
  );
};
