import { useFormikContext } from "formik";
import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { useCreationFormFieldTouchedEffect, useGetFieldError, useGetFieldName } from "../../../../../common/form";
import { useAppSelector } from "../../../../../common/store";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { FullPageContainerCenteredMainWithFooter } from "../../../../../common/ui/containers/full-page";
import { useCreditWorkingAssetsCreationCreatingFormStage } from "../../../hooks/creation/useCreditWorkingAssetsCreationCreatingFormStage";
import {
  selectCreditWorkingAssetsCreation,
  selectCreditWorkingAssetsCreationClientSearch,
} from "../../../store/creditWorkingAssetsCreation.selectors";
import { CreditWorkingAssetsCreationFormValues } from "../../../types/creation/CreditWorkingAssetsCreationFormValues";
import { CreditWorkingAssetsCreationFormTermBlock } from "./CreditWorkingAssetsCreationFormTermBlock";
import { CreationSubmitButton } from "../../../../../common/ui/buttons/creation-submit-button";
import { isLoading } from "../../../../../common/types/state";
import { ClientSearchCreationClientBlock } from "../../../../../client-search";

export const CreditWorkingAssetsCreationFormContent = () => {
  const stage = useCreditWorkingAssetsCreationCreatingFormStage();

  const {
    creating: { error, status },
  } = useAppSelector(selectCreditWorkingAssetsCreation);

  const form = useFormikContext<CreditWorkingAssetsCreationFormValues>();

  const getFieldName = useGetFieldName<CreditWorkingAssetsCreationFormValues>();
  const getFieldError = useGetFieldError<CreditWorkingAssetsCreationFormValues>();

  useCreationFormFieldTouchedEffect();

  return (
    <FullPageContainerCenteredMainWithFooter
      footer={
        stage === "terms" && (
          <CreationSubmitButton type="submit" isLoading={isLoading(status)} disabled={form.isSubmitting} error={error}>
            Перейти к выбору банков
          </CreationSubmitButton>
        )
      }
    >
      <ClientSearchCreationClientBlock<CreditWorkingAssetsCreationFormValues>
        clientStateSelector={selectCreditWorkingAssetsCreationClientSearch}
        fixedKey="CreditWorkingAssets"
        isDisabled={form.isSubmitting}
      />
      {stage === "terms" && (
        <>
          <CreditWorkingAssetsCreationFormTermBlock isDisabled={form.isSubmitting} />
          <CreationBlockWithTitle title="Сумма кредита">
            <FormInputPrice
              autoComplete="off"
              label="Сумма кредита, ₽"
              size="medium"
              {...form.getFieldProps(getFieldName("creditSum"))}
              error={getFieldError("creditSum", form.touched.creditSum, form.errors.creditSum)}
              disabled={form.isSubmitting}
            />
          </CreationBlockWithTitle>
        </>
      )}
    </FullPageContainerCenteredMainWithFooter>
  );
};
