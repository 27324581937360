import styled from "@emotion/styled";
import { entityHelper } from "@sideg/helpers";
import { useAppSelector } from "../../../../common/store";
import { getQueueTasksCountersSelector } from "../../../counters";
import { QueueTasksNotificationsListH3 } from "./QueueTasksNotificationsListH3";
import { StyledAppLink } from "../../../../common/ui/links";

const StyledH3 = styled(QueueTasksNotificationsListH3)`
  padding-bottom: ${({ theme }) => theme.spaces.md};
  margin-bottom: ${({ theme }) => theme.spaces.sm};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryLight};
`;

const StyledDiv = styled.div`
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const QueueTasksNotificationsEmptyListState = () => {
  const countQueueTasksAll = useAppSelector(getQueueTasksCountersSelector((x) => x.data?.countQueueTasksAll));

  return (
    <>
      <StyledH3>Нет непрочитанных уведомлений</StyledH3>
      <StyledDiv>
        {!!countQueueTasksAll && countQueueTasksAll > 0 && (
          <>
            <span>
              У вас есть {entityHelper.getWordDeclension(countQueueTasksAll, "активн", ["ая", "ые", "ых"])}{" "}
              {entityHelper.getWordDeclension(countQueueTasksAll, "задач", ["а", "и", ""], false)}.
            </span>
            <StyledAppLink to={(x) => x.queueTasks.list.root}>Перейти к списку задач</StyledAppLink>
          </>
        )}
        {countQueueTasksAll === undefined ||
          (countQueueTasksAll === 0 && (
            <span>
              Вы можете <StyledAppLink to={(x) => x.queueTasks.creation.create}>оформить новую заявку</StyledAppLink>, а
              уведомления об изменениях по заявке появятся в этом окне.
            </span>
          ))}
      </StyledDiv>
    </>
  );
};
