/**
 * Трансформер для преобразования форматированного числа-строки в number
 * @example "1 000 000,01" => 1_000_000.01
 */
export const getFormattedNumberTransformer =
  () =>
  (value: number, originalValue: unknown): number => {
    if (
      originalValue !== undefined &&
      originalValue !== null &&
      originalValue !== value.toString() &&
      typeof originalValue === "string"
    ) {
      const numberValue = Number.parseFloat(originalValue.replace(/ /g, "").replace(/,/, "."));

      return Object.is(NaN, numberValue) ? value : numberValue;
    }

    return value;
  };
