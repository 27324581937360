import { dateTimeHelper, specialFormatters } from "@sideg/helpers";
import { NavigationState } from "../../../../../../../../common/navigation/navigation-state";
import { useAppSelector } from "../../../../../../../../common/store";
import { CreditExecutionId, DemandId } from "../../../../../../../../common/types/demand";
import { Box } from "../../../../../../../../common/ui-kit/containers/box";
import { StackPanel } from "../../../../../../../../common/ui-kit/containers/stack-panel";
import { CircleIndicator } from "../../../../../../../../common/ui-kit/notifications/circle-indicator";
import { BaseCssProps } from "../../../../../../../../common/ui-kit/types";
import { Text } from "../../../../../../../../common/ui-kit/typography";
import { IssueLink } from "../../../../../../../../common/ui/product/list/product-list-item";
import { FileDownloadLabel } from "../../../../../../../../files/download";
import { useCreditExecutionListItemTransferAction } from "../../../../../../hooks/useCreditExecutionListItemTransferAction";
import { selectCreditExecutionListItemsDemandById } from "../../../../../../store/items/creditExecutionListItems.selectors";

const BoxText = Box.withComponent(Text.withComponent("div"));

export interface CreditExecutionListItemIssueDesktopProps extends BaseCssProps {
  creditExecutionId: CreditExecutionId;
  demandId: DemandId;
  from?: NavigationState;
}

export const CreditExecutionListItemIssueDesktop = ({
  creditExecutionId,
  demandId,
  from,
  className,
}: CreditExecutionListItemIssueDesktopProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectCreditExecutionListItemsDemandById(state, demandId))!;

  const onDemandLinkClick = useCreditExecutionListItemTransferAction(creditExecutionId);

  return (
    <Box className={className} display="flex" flexDirection="column" gap="sm">
      <StackPanel gap="sm" direction="row" alignItems="center">
        <IssueLink
          to={(x) => x.creditExecution.details.demand}
          onClick={onDemandLinkClick}
          params={{ demandId, creditExecutionId }}
          state={from}
        >
          Заявка выпущена
        </IssueLink>
        {demand.isUpdated && (
          <CircleIndicator color={(theme) => theme.secondaryColors.notificationBackground} size={8} />
        )}
      </StackPanel>
      <BoxText display="flex" gap="sm" fontWeight={500} lineHeight="defaultLineHeight" fontSize="fz2">
        <span>{demand.bank.title}</span>
        {demand.issue?.dateIssue && (
          <span>
            {dateTimeHelper.format(new Date(demand.issue.dateIssue), specialFormatters.dayFullMonthYearIfDifferent)}
          </span>
        )}
      </BoxText>
      <Box py="sm" display="flex" flexDirection="column" gap="md" alignItems="flex-start">
        {demand.issue?.original && (
          <FileDownloadLabel
            fileId={demand.issue.original.fileId}
            privateKey={demand.issue.original.privateKey}
            fileName="Скан-копия договора"
          />
        )}
      </Box>
    </Box>
  );
};
