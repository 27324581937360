import { DemandQueueTasksActiveRequestedDocument } from "../types/active/DemandQueueTasksActiveRequestedDocument";
import { FileTemp } from "../../../files/temp";
import { getDocumentUploadRequestedDocumentOldFiles } from "../../../document/upload";

export const getDocumentMinLengthDemandQueueTasks = (
  document: DemandQueueTasksActiveRequestedDocument,
  attachedFiles: FileTemp[],
) => {
  if (document.isRequired) {
    const oldFiles = getDocumentUploadRequestedDocumentOldFiles(document);

    return (oldFiles === undefined || oldFiles.length === 0) && attachedFiles.length === 0 ? 1 : 0;
  }

  return 0;
};
