import { NavigationStateFrom } from "../../../../../common/navigation/navigation-state";
import { useBackLinkHeaderAction } from "../../../../../common/ui/layout/header";
import { useBankGuaranteeDetailsHeaderNavigationMobileFrom } from "../../../hooks/layout/useBankGuaranteeDetailsHeaderNavigationMobileFrom";

export interface BankGuaranteeDetailsHeaderNavigationMobileProps {
  from: NavigationStateFrom;
}

export const BankGuaranteeDetailsHeaderNavigationMobile = ({
  from,
}: BankGuaranteeDetailsHeaderNavigationMobileProps) => {
  const actualFrom = useBankGuaranteeDetailsHeaderNavigationMobileFrom(from);

  useBackLinkHeaderAction({
    withBackIcon: true,
    url: actualFrom.url,
    text: actualFrom.backTitle,
  });

  return undefined;
};
