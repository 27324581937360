import { RootState } from "../../../../common/store";
import { BankGuaranteeDetailsReissuesTypes } from "./BankGuaranteeDetailsReissues.types";
import { bankGuaranteeDetailsReissuesAdapter } from "./BankGuaranteeDetailsReissues.adapter";

export const selectBankGuaranteeDetailsReissues = (state: RootState): BankGuaranteeDetailsReissuesTypes =>
  state.domains.bankGuarantee.details.reissues;

export const {
  selectById: selectBankGuaranteeDetailsReissueById,
  selectIds: selectBankGuaranteeDetailsReissuesIds,
  selectTotal: selectBankGuaranteeDetailsReissuesTotal,
} = bankGuaranteeDetailsReissuesAdapter.getSelectors(selectBankGuaranteeDetailsReissues);
