import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { TaxpayerFormInput } from "../../../../../common/ui/form-controls/taxpayer-input";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { AlertLoadingToResult } from "../../../../../common/ui-kit/notifications/alert-with-indicator";
import { useCreditExecutionCreationField } from "../../../hooks/creation/fields/useCreditExecutionCreationField";
import { useCreditExecutionCreationBeneficiaryField } from "../../../hooks/creation/fields/useCreditExecutionCreationBeneficiaryField";

export interface CreditExecutionCreationBeneficiaryBlockProps {
  isDisabled: boolean;
}

export const CreditExecutionCreationBeneficiaryBlock = ({
  isDisabled,
}: CreditExecutionCreationBeneficiaryBlockProps) => {
  const [fieldBeneficiaryNameProps] = useCreditExecutionCreationField("beneficiaryName");

  const { isLoading, isFailed, fieldProps: fieldTaxpayerNumberProps } = useCreditExecutionCreationBeneficiaryField();

  return (
    <CreationBlockWithTitle title="Заказчик">
      <StackPanel direction="column" gap="md">
        <TaxpayerFormInput
          label="ИНН"
          autoComplete="off"
          size="medium"
          {...fieldTaxpayerNumberProps}
          disabled={isDisabled || isLoading}
        />
        <SimpleOpacityTransition timeout={250} in={isLoading || isFailed} unmountOnExit>
          <AlertLoadingToResult
            isLoading={isLoading}
            loadingText="Получаем наименование заказчика"
            isError={isFailed}
            errorText="Мы не смогли получить наименование. Укажите его самостоятельно"
          />
        </SimpleOpacityTransition>
        <FormInput
          type="text"
          label="Наименование"
          size="medium"
          spellCheck={false}
          autoComplete="off"
          autoCorrect="off"
          disabled={isDisabled || isLoading}
          {...fieldBeneficiaryNameProps}
        />
      </StackPanel>
    </CreationBlockWithTitle>
  );
};
