import styled from "@emotion/styled";

export const Container = styled.section`
  display: inline-flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spaces.lg};

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    min-height: 24px;
    margin-top: 0;
  }
`;
