import styled from "@emotion/styled";

export const Dl = styled.dl`
  display: grid;
  grid-template-columns: minmax(40px, max-content) auto;
  column-gap: ${({ theme }) => theme.spaces.lg};

  & > * {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
