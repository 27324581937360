import RequestStatus from '../../entity/models/enums/RequestStatus'
import QuestionnaireClientModel from '../../entity/models/QuestionnaireClientModel'
import RequestData from '../utils/RequestData'
import produce from 'immer'
import BankAccountModel from '../../entity/models/BankAccountModel'
import CompanyModel from '../../entity/models/CompanyModel'
import DadataAddressModel from '../../entity/models/DadataAddressModel'
import {
    CLIENT_FORM_CLIENT_FETCHING,
    CLIENT_FORM_CLIENT_FETCHED,
    CLIENT_FORM_CLIENT_RESET,
    CLIENT_FORM_CLIENT_ERROR,

    CLIENT_FORM_ACCOUNT_FETCHING,
    CLIENT_FORM_ACCOUNT_FETCHED,
    CLIENT_FORM_ACCOUNT_ERROR,
    CLIENT_FORM_ACCOUNT_RESET,

    CLIENT_FORM_COMPANY_FETCHING,
    CLIENT_FORM_COMPANY_FETCHED,
    CLIENT_FORM_COMPANY_ERROR,
    CLIENT_FORM_COMPANY_RESET,

    CLIENT_FORM_UPDATE_PROCESSING,
    CLIENT_FORM_UPDATE_SUCCESS,
    CLIENT_FORM_UPDATE_ERROR,
    CLIENT_FORM_UPDATE_RESET,

    CLIENT_FORM_ADDRESS_FETCHING,
    CLIENT_FORM_ADDRESS_FETCHED,
    CLIENT_FORM_ADDRESS_ERROR,
    CLIENT_FORM_ADDRESS_RESET,

    CLIENT_FORM_SET_SRC,
    CLIENT_FORM_RESET_SRC,

    ClientFormDispatchType,
} from '../actions/client-form/clientFormActionTypes'

interface ClientDataState {
    client: RequestData<QuestionnaireClientModel>
    update: RequestData<any>
    account: RequestData<BankAccountModel>
    company: RequestData<CompanyModel>
    address: RequestData<DadataAddressModel>
    src: string | null
}

const initialState: ClientDataState = {
    client: {
        status: RequestStatus.Initial,
        data: null,
        error: null
    },
    update: {
        status: RequestStatus.Initial,
        data: null,
        error: null
    },
    account: {
        status: RequestStatus.Initial,
        data: null,
        error: null
    },
    company: {
        status: RequestStatus.Initial,
        data: null,
        error: null
    },
    address: {
        status: RequestStatus.Initial,
        data: null,
        error: null
    },
    src: null
}

const clientFormReducer = (state = initialState, action: ClientFormDispatchType) => {
    switch (action.type) {
        case CLIENT_FORM_CLIENT_FETCHING:
            return produce(state, (draft: ClientDataState) => {
                draft.client.status = RequestStatus.Request
                draft.client.error = null
            })
        case CLIENT_FORM_CLIENT_FETCHED:
            return produce(state, (draft: ClientDataState) => {
                draft.client.status = RequestStatus.Success
                draft.client.data = action.payload
            })
        case CLIENT_FORM_CLIENT_ERROR:
            return produce(state, (draft: ClientDataState) => {
                draft.client.status = RequestStatus.Failure
                draft.client.error = action.error
            })
        case CLIENT_FORM_CLIENT_RESET:
            return produce(state, (draft: ClientDataState) => {
                draft.client = initialState.client
            })
        case CLIENT_FORM_ACCOUNT_FETCHING:
            return produce(state, (draft: ClientDataState) => {
                draft.account.status = RequestStatus.Request
                draft.account.error = null
            })
        case CLIENT_FORM_ACCOUNT_FETCHED:
            return produce(state, (draft: ClientDataState) => {
                draft.account.status = RequestStatus.Success
                draft.account.data = action.payload
            })
        case CLIENT_FORM_ACCOUNT_ERROR:
            return produce(state, (draft: ClientDataState) => {
                draft.account.status = RequestStatus.Failure
                draft.account.error = action.error
            })
        case CLIENT_FORM_ACCOUNT_RESET:
            return produce(state, (draft: ClientDataState) => {
                draft.account = initialState.account
            })
        case CLIENT_FORM_COMPANY_FETCHING:
            return produce(state, (draft: ClientDataState) => {
                draft.company.status = RequestStatus.Request
                draft.company.error = null
            })
        case CLIENT_FORM_COMPANY_FETCHED:
            return produce(state, (draft: ClientDataState) => {
                draft.company.status = RequestStatus.Success
                draft.company.data = action.payload
            })
        case CLIENT_FORM_COMPANY_ERROR:
            return produce(state, (draft: ClientDataState) => {
                draft.company.status = RequestStatus.Failure
                draft.company.error = action.error
            })
        case CLIENT_FORM_COMPANY_RESET:
            return produce(state, (draft: ClientDataState) => {
                draft.company = initialState.company
            })
        case CLIENT_FORM_UPDATE_PROCESSING:
            return produce(state, (draft: ClientDataState) => {
                draft.update.status = RequestStatus.Request
                draft.update.error = null
            })
        case CLIENT_FORM_UPDATE_SUCCESS:
            return produce(state, (draft: ClientDataState) => {
                draft.update.status = RequestStatus.Success
                draft.update.data = true
            })
        case CLIENT_FORM_UPDATE_ERROR:
            return produce(state, (draft: ClientDataState) => {
                draft.update.status = RequestStatus.Failure
                draft.update.error = action.error
            })
        case CLIENT_FORM_UPDATE_RESET:
            return produce(state, (draft: ClientDataState) => {
                draft.update = initialState.update
            })
        case CLIENT_FORM_ADDRESS_FETCHING:
            return produce(state, (draft: ClientDataState) => {
                draft.address.status = RequestStatus.Request
                draft.address.error = null
            })
        case CLIENT_FORM_ADDRESS_FETCHED:
            return produce(state, (draft: ClientDataState) => {
                draft.address.status = RequestStatus.Success
                draft.address.data = action.payload
            })
        case CLIENT_FORM_ADDRESS_ERROR:
            return produce(state, (draft: ClientDataState) => {
                draft.address.status = RequestStatus.Failure
                draft.address.error = action.error
            })
        case CLIENT_FORM_ADDRESS_RESET:
            return produce(state, (draft: ClientDataState) => {
                draft.address = initialState.address
            })
        case CLIENT_FORM_SET_SRC:
            return produce(state, (draft: ClientDataState) => {
                draft.src = action.payload
            })
        case CLIENT_FORM_RESET_SRC:
            return produce(state, (draft: ClientDataState) => {
                draft.src = null
            })
        default:
            return state
    }
}

export default clientFormReducer
