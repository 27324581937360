import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useCreditWorkingAssetsListFilterContext } from "../../../../hooks/filters/useCreditWorkingAssetsListFilterContext";
import { useProductFilterArray } from "../../../../../../common/filters/use-cases";
import { selectCreditWorkingAssetsFiltersData } from "../../../../store/filters/creditWorkingAssetsListFilters.selectors";
import { FilterMultipleSelectList } from "../../../../../../common/ui-kit/form-controls/select";

export type CreditWorkingAssetsListHeaderFiltersTaskTypesDesktopProps = BaseCssProps;

export const CreditWorkingAssetsListHeaderFiltersTaskTypesDesktop = ({
  className,
}: CreditWorkingAssetsListHeaderFiltersTaskTypesDesktopProps) => {
  const filteringObject = useCreditWorkingAssetsListFilterContext();

  const { getItems, selectedItems, onSelectedChange, maxItemLength, getItemTitle } = useProductFilterArray({
    filteringObject,
    key: "taskTypes",
    availableFiltersSelector: selectCreditWorkingAssetsFiltersData,
  });

  return (
    <FilterMultipleSelectList
      className={className}
      text="Задачи"
      selectedItems={selectedItems}
      getItemTitle={getItemTitle}
      onSelectedChanged={onSelectedChange}
      getItems={getItems}
      maxItemsLength={maxItemLength}
    />
  );
};
