import styled from "@emotion/styled";
import { colorWithAlpha } from "../../../../ui-kit/utils";

export const Container = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.layout.header.backgroundColor};
  min-height: 100vh;
  min-height: 100dvh;
  overflow: clip;
  grid-template-rows: auto 1fr auto;
  grid-auto-columns: minmax(0, 1fr);
`;

export const Header = styled.header`
  padding: 24px;
  background-color: ${({ theme }) => theme.layout.header.backgroundColor};
  border-bottom: 1px solid ${({ theme }) => colorWithAlpha(theme.palette.background.light, Math.round(255 * 0.3))};
  z-index: 1;

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    background-color: transparent;
    padding: 30px;
    border: none;
    z-index: unset;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2.5rem ${({ theme }) => theme.spaces.md};
  gap: ${({ theme }) => theme.layout.publicPage.cardPaddingBase.mobile}rem;

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    padding: ${({ theme }) => theme.layout.publicPage.cardPaddingBase.desktop}rem;
    gap: ${({ theme }) => theme.layout.publicPage.cardPaddingBase.desktop}rem;
  }
`;
