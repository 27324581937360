import { currencyHelper } from "@sideg/helpers";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeCreationScoringRewardText } from "./BankGuaranteeCreationScoringRewardText";
import { Text } from "../../../../../common/ui-kit/typography";
import { useBankGuaranteeScoringRoundedReward } from "../../../hooks/scoring/useBankGuaranteeScoringRoundedReward";

export interface BankGuaranteeCreationScoringRewardAndPriceProps {
  className?: string;
  reward?: number;
  price?: number;
}

export const BankGuaranteeCreationScoringRewardAndPrice = ({
  className,
  price,
  reward,
}: BankGuaranteeCreationScoringRewardAndPriceProps) => {
  const roundedReward = useBankGuaranteeScoringRoundedReward(reward ?? 0);

  return (
    <StackPanel
      gap={{ xs: "xs", md: "sm" }}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={className}
    >
      {reward !== undefined && (
        <BankGuaranteeCreationScoringRewardText variant={roundedReward}>
          КВ {reward}%
        </BankGuaranteeCreationScoringRewardText>
      )}
      {price !== undefined && (
        <Text
          fontColor="primaryCaption"
          fontWeight={500}
          fontSize={{ xs: "fz2", md: "fz3" }}
          fontFeatureSettings={`"tnum"`}
        >
          Цена: {currencyHelper.getCurrencyString(price)}
        </Text>
      )}
    </StackPanel>
  );
};
