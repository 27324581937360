import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { creditWorkingAssetsDetailsDocumentsAdapter } from "./creditWorkingAssetsDetailsDocuments.adapter";
import { combineSliceNames } from "../../../../common/store/utils";
import {
  getCreditWorkingAssetsDetails,
  uploadGroupDocumentCreditWorkingAssetsDetails,
} from "../creditWorkingAssetsDetails.thunks";
import {
  CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME,
  creditWorkingAssetsDetailsActiveProductChanged,
} from "../creditWorkingAssetsDetails.actions";
import { creditWorkingAssetsDetailsDocumentsMapper } from "./creditWorkingAssetsDetailsDocuments.mapper";
import { DocumentTypeId } from "../../../../common/types/documents";

const initialState = creditWorkingAssetsDetailsDocumentsAdapter.getInitialState();

const slice = createSlice({
  name: combineSliceNames(CREDIT_WORKING_ASSETS_DETAILS_SLICE_NAME, "documents"),
  initialState,
  reducers: {
    // Оставляем документы вместо удаления для анимации исчезновения.
    markAsUploaded: (state, action: PayloadAction<DocumentTypeId>) => {
      creditWorkingAssetsDetailsDocumentsAdapter.updateOne(state, {
        id: action.payload,
        changes: { isUploaded: true },
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(creditWorkingAssetsDetailsActiveProductChanged, () => initialState)
      .addMatcher(
        isAnyOf(getCreditWorkingAssetsDetails.fulfilled, uploadGroupDocumentCreditWorkingAssetsDetails.fulfilled),
        (state, action) => {
          if (!action.payload.body.missingDocuments) {
            creditWorkingAssetsDetailsDocumentsAdapter.removeAll(state);
          } else {
            creditWorkingAssetsDetailsDocumentsAdapter.setAll(
              state,
              creditWorkingAssetsDetailsDocumentsMapper.mapAll(action.payload.body.missingDocuments),
            );
          }
        },
      );
  },
});

export const { markAsUploaded: markAsUploadedCreditWorkingAssetsDetailsDocuments } = slice.actions;

export const creditWorkingAssetsDetailsDocumentsReducer = slice.reducer;
