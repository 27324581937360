import { currencyHelper, isValidUrl } from "@sideg/helpers";
import {
  ProductCreationBriefItem,
  ProductCreationBriefItemOrSkeleton,
  ProductCreationBriefSeparator,
} from "../../../../common/product-creation";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { Link } from "../../../../common/ui-kit/links";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { CreditExecutionBrief } from "../types/CreditExecutionBrief";
import { CreditExecutionBriefDescriptionDateField } from "./CreditExecutionBriefDescriptionDateField";

export interface CreditExecutionBriefDescriptionListProps extends BaseCssProps {
  isLoading: boolean;
  isShowDateStartMovedToToday?: boolean;
  data: CreditExecutionBrief | undefined;
}

export const CreditExecutionBriefDescriptionList = ({
  isLoading,
  isShowDateStartMovedToToday,
  data,
  className,
}: CreditExecutionBriefDescriptionListProps) => {
  return (
    <StackPanel direction="column" gap="md" as="dl" className={className}>
      <ProductCreationBriefItemOrSkeleton isLoading={isLoading} title="Клиент" description={data?.client.name} />
      <ProductCreationBriefItemOrSkeleton isLoading={isLoading} title="ИНН" description={data?.client.taxpayerNumber} />
      <ProductCreationBriefSeparator />
      <ProductCreationBriefItemOrSkeleton isLoading={isLoading} title="ФЗ" description={data?.federalLaw.title} />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isLoading}
        title="Аукцион"
        description={
          // eslint-disable-next-line no-nested-ternary
          data?.isClosedAuction === true ? "Закрытый" : data?.isClosedAuction === false ? "Открытый" : undefined
        }
      />
      <ProductCreationBriefSeparator />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isLoading}
        title="Номер закупки"
        lines={2}
        description={
          data?.purchaseNumber && (
            <>
              <span>{data.purchaseNumber}</span>
              {data.purchaseLink && isValidUrl(data.purchaseLink) && (
                <>
                  <br />
                  <Link href={data.purchaseLink} target="_blank" rel="noopener noopener">
                    Ссылка на закупку
                  </Link>
                </>
              )}
            </>
          )
        }
      />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isLoading}
        title="Предмет контракта"
        lines={3}
        description={data?.subject}
      />
      <ProductCreationBriefSeparator />
      <ProductCreationBriefItemOrSkeleton isLoading={isLoading} title="Заказчик" description={data?.beneficiary.name} />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isLoading}
        title="ИНН"
        description={data?.beneficiary.taxpayerNumber}
      />
      <ProductCreationBriefSeparator />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isLoading}
        title="Сумма контракта"
        description={currencyHelper.getCurrencyStringNullable(data?.contractSum, undefined)}
      />
      {data?.confirmedCreditSum !== undefined && (
        <ProductCreationBriefItem
          title="Одобрено"
          description={currencyHelper.getCurrencyString(data.confirmedCreditSum)}
        />
      )}
      <ProductCreationBriefSeparator />
      <ProductCreationBriefItemOrSkeleton
        isLoading={isLoading}
        title="Срок кредита"
        description={
          data && (
            <CreditExecutionBriefDescriptionDateField
              data={data}
              isShowDateStartMovedToToday={isShowDateStartMovedToToday}
            />
          )
        }
      />
    </StackPanel>
  );
};
