export interface UseDocumentUploadLabelTextArg {
  isMultiFile?: boolean;
  isReplaceableCollection?: boolean;
  isLoading: boolean;
  attachedFilesCount: number;
  loadingFilesCount: number;
}

export const useDocumentUploadLabelText = ({
  attachedFilesCount,
  isLoading,
  isMultiFile,
  isReplaceableCollection,
  loadingFilesCount,
}: UseDocumentUploadLabelTextArg) => {
  if (isLoading) {
    return loadingFilesCount > 1 ? "Загружаем файлы" : "Загружаем файл";
  }

  if (isMultiFile) {
    return attachedFilesCount > 0 ? "Прикрепить еще" : "Прикрепить файлы";
  }

  if (isReplaceableCollection) {
    return attachedFilesCount === 0 ? "Прикрепить файлы" : "Прикрепить другие файлы";
  }

  return attachedFilesCount === 0 ? "Прикрепить файл" : "Прикрепить другой файл";
};
