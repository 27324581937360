import { BankGuaranteeBaseDemand } from "../../../common/types";
import { BankGuaranteeDetailsDemand } from "../../types/demand/BankGuaranteeDetailsDemand";

const mapDemands = (demands: BankGuaranteeBaseDemand[]): BankGuaranteeDetailsDemand[] => {
  return demands.map((x, i) => ({ ...x, index: i + 1 }));
};

export const bankGuaranteeDetailsDemandsMapper = {
  mapDemands,
};
