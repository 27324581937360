import * as React from "react";
import { useState } from "react";
import { useFullPageLayout } from "../../../common/ui/layout";
import { useBankGuaranteeDetailsFetcher } from "../hooks/useBankGuaranteeDetailsFetcher";
import { useNavigationStateFromWithFallback } from "../../../common/navigation/navigation-state";
import { generatePath } from "../../../common/urls/internal/utils";
import { BankGuaranteeDetailsHeaderNavigation } from "../components/aside/header-navigation/BankGuaranteeDetailsHeaderNavigation";
import { BankGuaranteeDetailsPageLayoutContext } from "../store/layout-context/BankGuaranteeDetailsPageLayoutContext";
import { useBankGuaranteeDetailsMatchListener } from "../hooks/layout/useBankGuaranteeDetailsMatchListener";
import { useScrollToTopOnMountOrLocationPathChange } from "../../../common/hooks/scroll";
import { useBankGuaranteeDetailsQueueTasksNeedUpdateListener } from "../hooks/useBankGuaranteeDetailsQueueTasksNeedUpdateListener";
import { useBankGuaranteeDetailsRootPathMatch } from "../hooks/useBankGuaranteeDetailsRootPathMatch";
import { ProductDetailsLayoutWrapper } from "../../../common/product-details";
import { BankGuaranteeDetailsAside } from "../components/aside/BankGuaranteeDetailsAside";
import { withValidUrlParams } from "../../../common/urls/internal/HOCs";
import { bankGuaranteeDetailsUrlValidationSchema } from "../validation/bankGuaranteeDetailsUrlValidationSchema";
import { Redirect } from "../../../common/urls/internal/components";
import { BankGuaranteeDetailsLayoutContext } from "../types/layout/BankGuaranteeDetailsLayoutContext";
import { ErrorBoundary, ErrorBoundaryDefaultFallbackAlert } from "../../../common/ui/containers/error-boundary";

export interface BankGuaranteeDetailsLayoutProps {
  children?: React.ReactNode;
  match: BankGuaranteeDetailsLayoutContext;
}

const Layout = ({ children, match }: BankGuaranteeDetailsLayoutProps) => {
  useFullPageLayout();
  useScrollToTopOnMountOrLocationPathChange();

  const { from } = useNavigationStateFromWithFallback(
    generatePath((x) => x.bankGuarantee.list.root),
    "Мои заявки",
  );
  const [initialFrom] = useState(from);

  useBankGuaranteeDetailsMatchListener(match);

  useBankGuaranteeDetailsFetcher(match.guaranteeId);
  useBankGuaranteeDetailsQueueTasksNeedUpdateListener(match.guaranteeId);

  const { isExact: isRootPath } = useBankGuaranteeDetailsRootPathMatch(match.guaranteeId);

  return (
    <BankGuaranteeDetailsPageLayoutContext.Provider value={match}>
      <BankGuaranteeDetailsHeaderNavigation initialFrom={initialFrom} />
      <ErrorBoundary fallback={<ErrorBoundaryDefaultFallbackAlert />}>
        <BankGuaranteeDetailsHeaderNavigation initialFrom={initialFrom} />
        <ProductDetailsLayoutWrapper isRootPath={isRootPath} aside={<BankGuaranteeDetailsAside />}>
          {children}
        </ProductDetailsLayoutWrapper>
      </ErrorBoundary>
    </BankGuaranteeDetailsPageLayoutContext.Provider>
  );
};

export const BankGuaranteeDetailsPageLayout = withValidUrlParams(
  Layout,
  (x) => x.bankGuarantee.details.demand,
  bankGuaranteeDetailsUrlValidationSchema,
  <Redirect to={(x) => x.bankGuarantee.list.root} />,
);
