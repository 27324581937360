import { useCallback, useMemo, useRef } from "react";
import { ApiAsyncThunk } from "../../store/utils";
import { useApiMutationAction } from "../useApiMutationAction/useApiMutationAction";

export interface UseApiMutationOptions {
  errorHandler?: (e: unknown) => Promise<string>;
  errorMessagePlaceholder?: string;
}

export const useApiMutation = <TOutput, TInput>(
  thunk: ApiAsyncThunk<TOutput, TInput>,
  options: UseApiMutationOptions
) => {
  const { error, mutate, status } = useApiMutationAction();
  const optionsRef = useRef(options);

  const thunkMutate = useCallback(
    async (inputModel: TInput): Promise<TOutput | undefined> => mutate(thunk, inputModel, optionsRef.current),
    [mutate, thunk]
  );

  return useMemo(() => ({ status, error, mutate: thunkMutate }), [error, status, thunkMutate]);
};
