import { BreakpointsValues } from "@sideg/ui-kit/common/types";

export const themeDefaultBreakpoints: BreakpointsValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};
