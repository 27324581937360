import { forwardRef } from "react";
import { useElementId } from "@sideg/ui-kit/atoms/form-controls/common/hooks";
import { BaseFormInputElement } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormInputElement";
import { BaseFormElementErrorProps } from "@sideg/ui-kit/atoms/form-controls/input/common/components/BaseFormElementError";
import { BaseCssProps } from "@sideg/ui-kit/common/types";
import * as S from "@sideg/ui-kit/atoms/form-controls/select/components/form-select/mobile/FormSelect.mobile.styled";

export interface FormSelectMobileProps
  extends Omit<JSX.IntrinsicElements["select"], "className" | "ref">,
    BaseFormElementErrorProps,
    BaseCssProps {
  label?: string;
}

export const FormSelectMobile = forwardRef<HTMLSelectElement, FormSelectMobileProps>(
  ({ label, id, error, errorDataTestId, name, className, disabled, ...rest }, ref) => {
    const elementId = useElementId("select", id, name);

    return (
      <BaseFormInputElement
        error={error}
        errorDataTestId={errorDataTestId}
        label={label}
        labelFor={elementId}
        className={className}
      >
        <S.Select ref={ref} id={elementId} name={name} disabled={disabled} {...rest} />
        <S.Icon />
      </BaseFormInputElement>
    );
  },
);
