import { BaseCssProps } from "../../../../common/ui-kit/types";
import { PublicUserDescriptionSuccessText, PublicUserResultCard } from "../../../common/components";
import { DoneCircleBigIcon, Icon } from "../../../../common/ui-kit/icons";
import { AppLinkButton } from "../../../../common/ui/links";

export interface PublicUserRegistrationSuccessResultCardProps extends BaseCssProps {
  email: string;
  phone: string;
}

export const PublicUserRegistrationSuccessResultCard = ({
  email,
  phone,
  className,
}: PublicUserRegistrationSuccessResultCardProps) => {
  return (
    <PublicUserResultCard
      className={className}
      title="Вы зарегистрированы"
      description={
        <PublicUserDescriptionSuccessText
          email={email}
          phone={phone}
          startText="Отправили сообщение с логином и паролем для входа"
        />
      }
      icon={<Icon as={DoneCircleBigIcon} stroke="status.success" />}
    >
      <AppLinkButton isFullWidth size="medium" to={(x) => x.publicUser.authentication.login}>
        Войти с логином
      </AppLinkButton>
    </PublicUserResultCard>
  );
};
