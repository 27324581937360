import { combineSliceNames } from "../../../common/store/utils";
import { QUEUE_TASKS_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { queueTasksNotificationsApi } from "../api/queueTaskskNotificationsApi";

export const QUEUE_TASKS_NOTIFICATIONS_SLICE_NAME = combineSliceNames(QUEUE_TASKS_SLICE_NAME, "notifications");

export const { get: getQueueTasksNotifications, read: readQueueTasksNotification } = createApiThunks(
  QUEUE_TASKS_NOTIFICATIONS_SLICE_NAME,
  queueTasksNotificationsApi
);
