import { useEffect, useState } from "react";
import { Box } from "../../../common/ui-kit/containers/box";
import { ProductDetailsDemandBody } from "../../../common/product-details";
import { useCreditWorkingAssetsDetailsDemandInitialFetch } from "../hooks/useCreditWorkingAssetsDetailsDemandInitialFetch";
import { CreditWorkingAssetsDetailsDemand } from "../component/demand/CreditWorkingAssetsDetailsDemand";
import { CreditWorkingAssetsDetailsDemandHeader } from "../component/demand/header/CreditWorkingAssetsDetailsDemandHeader";
import { CreditWorkingAssetsDetailsDemandHeaderTab } from "../types/demand/CreditWorkingAssetsDetailsDemandHeaderTab";
import { useCreditWorkingAssetsDetailsContext } from "../hooks/context/useCreditWorkingAssetsDetailsContext";
import { CreditWorkingAssetsDetailsDemandActualDocumentsList } from "../component/demand/actual-documents/CreditWorkingAssetsDetailsDemandActualDocumentsList";

export const CreditWorkingAssetsDetailsDemandPage = () => {
  const { demandId } = useCreditWorkingAssetsDetailsContext();
  const [activeTab, setActiveTab] = useState<CreditWorkingAssetsDetailsDemandHeaderTab>("tasks");
  useCreditWorkingAssetsDetailsDemandInitialFetch();

  useEffect(() => {
    setActiveTab("tasks");
  }, [demandId]);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} flexShrink={1} overflow="hidden">
      <CreditWorkingAssetsDetailsDemandHeader activeTab={activeTab} onTabClick={setActiveTab} />
      <ProductDetailsDemandBody>
        {activeTab === "tasks" && <CreditWorkingAssetsDetailsDemand />}
        {activeTab === "documents" && <CreditWorkingAssetsDetailsDemandActualDocumentsList />}
      </ProductDetailsDemandBody>
    </Box>
  );
};
