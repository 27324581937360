import { useMemo } from "react";
import { useAppSelector } from "../../store";
import { getUserProductsCreation } from "../utils/getUserProductsCreation";
import { selectAuthorizedUser } from "../../authorized-user";

export const useUserCreationProducts = () => {
  const { user } = useAppSelector(selectAuthorizedUser);

  return useMemo(
    () => (user?.role ? getUserProductsCreation(user.role, user.featureFlags) : []),
    [user?.featureFlags, user?.role],
  );
};
