import { checkNeverType } from "@sideg/helpers";
import { BankGuaranteeDetailsDemandReconciliationDecisionType } from "../../types/demand/reconciliation/BankGuaranteeDetailsDemandReconciliationDecisionType";
import { BadgeVariant } from "../../../../common/ui-kit/containers/badge/types/BadgeVariant";

export const getBankGuaranteeDetailsDemandBadgeVariantByReconciliationDecision = (
  decision: BankGuaranteeDetailsDemandReconciliationDecisionType,
): BadgeVariant => {
  switch (decision) {
    case "PartialConfirmed":
      return "warning";
    case "Agreement":
      return "info";
    case "Confirmed":
      return "success";
    case "Refused":
      return "danger";
    default:
      return checkNeverType(decision, "Не удалось распознать тип решения по запросу");
  }
};
