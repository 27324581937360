import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../utils";

const titles = {
  Simplified: "Упрощенная",
  General: "Общая",
};

export const clientTaxSystemType = makeEnumLikeLiteral(["Simplified", "General"] as const, titles);

export type ClientTaxSystemType = EnumLikeLiteralType<typeof clientTaxSystemType>;
