import { createSelector } from "@reduxjs/toolkit";
import { ProductDetailsDocumentsState } from "../../../../common/product-details";
import { RootState } from "../../../../common/store";
import { bankGuaranteeDetailsDocumentsAdapter } from "./bankGuaranteeDetailsDocuments.adapter";

export const selectBankGuaranteeDetailsDocuments = (state: RootState): ProductDetailsDocumentsState =>
  state.domains.bankGuarantee.details.documents;

const { selectAll, selectById } = bankGuaranteeDetailsDocumentsAdapter.getSelectors(
  selectBankGuaranteeDetailsDocuments,
);

export const selectBankGuaranteeDetailsDocumentById = selectById;

export const selectBankGuaranteeDetailsDocumentsIds = createSelector([selectAll], (items) => {
  return items.filter((x) => x.isUploaded !== true).map((x) => x.id);
});
