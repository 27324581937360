import { PropsWithChildren, useMemo } from "react";
import { useMediaQuery } from "@sideg/ui-kit/core/responsive/hooks/useMediaQuery";
import { ResponsiveContextType } from "@sideg/ui-kit/core/responsive/types/ResponsiveContextType";
import { useActualSizeMediaQuery } from "@sideg/ui-kit/core/responsive/hooks/useActualSizeMediaQuery";
import { ResponsiveContext } from "@sideg/ui-kit/core/responsive/context/ResponsiveContext";

export interface ResponsiveProviderProps extends PropsWithChildren {}

export const ResponsiveProvider = ({ children }: ResponsiveProviderProps) => {
  const actualSize = useActualSizeMediaQuery();
  const isProbablyMobile = useMediaQuery("(hover: none) and (pointer: coarse)");

  const value = useMemo<ResponsiveContextType>(
    () => ({ actualSize: actualSize ?? "initial", isProbablyMobile }),
    [actualSize, isProbablyMobile],
  );

  return <ResponsiveContext.Provider value={value}>{children}</ResponsiveContext.Provider>;
};
