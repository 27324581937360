import { DateRangeFilter } from "../../../../../../common/filters/use-cases";
import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useBankGuaranteeListFilterApiContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterApiContext";
import { useBankGuaranteeListFilterContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterContext";

export type BankGuaranteeListFiltersDatesProps = BaseCssProps;

export const BankGuaranteeListFiltersDates = ({ className }: BankGuaranteeListFiltersDatesProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const mutator = useBankGuaranteeListFilterApiContext();

  return (
    <DateRangeFilter
      className={className}
      dateMutator={(dateCreateFrom?: Date, dateCreateTo?: Date) =>
        mutator(filteringObject, (filter, service) => service.setValues(filter, { dateCreateFrom, dateCreateTo }))
      }
      dateCreateFrom={filteringObject.dateCreateFrom}
      dateCreateTo={filteringObject.dateCreateTo}
    />
  );
};
