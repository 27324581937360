import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { selectNewsList, selectNewsListIds } from "../store/newsList.selectors";
import { getNewsList } from "../store/newsList.thunks";
import { AppInfinityScroll } from "../../../common/ui/product/list/infinite-scroll";
import { useFilteredPaginatedListPage, useGetQueryParams } from "../../../common/filters";
import { newsListFiltersValidationSchema } from "../validation/newsListFiltersValidationSchema";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { AppInfinityScrollTitles } from "../../../common/ui/product/list/infinite-scroll/components/AppInfinityScroll";
import { NewsListItems } from "./items/NewsListItems";
import { NewsListItemsSkeleton } from "./items/NewsListItems.skeleton";

const titles: AppInfinityScrollTitles = {
  end: "Загружены все новости",
  hasNoLoadedItems: "Нет новостей",
  navigateToFirstPage: "Показать предыдущие новости",
  error: "При получении новостей возникла ошибка",
};

export type NewsListProps = BaseCssProps;

export const NewsList = ({ className }: NewsListProps) => {
  const [filteringObject, forceUpdate] = useGetQueryParams(newsListFiltersValidationSchema);
  const { onPreviousPageButtonClick, onNextPageFetching, hasPreviousPage } = useFilteredPaginatedListPage({
    filteringObject,
    action: getNewsList,
  });

  return (
    <AppLayoutResponsiveListPage.List className={className}>
      <AppInfinityScroll
        page={filteringObject.page}
        paginatedStateSelector={selectNewsList}
        idsSelector={selectNewsListIds}
        titles={titles}
        onPreviousPageButtonClick={onPreviousPageButtonClick}
        onPageChanged={onNextPageFetching}
        onErrorReloadButtonClick={forceUpdate}
        hasPreviousPage={hasPreviousPage}
        emptyLoader={<NewsListItemsSkeleton />}
      >
        <NewsListItems />
      </AppInfinityScroll>
    </AppLayoutResponsiveListPage.List>
  );
};
