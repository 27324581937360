import { useFileInputDropzone } from "../hooks/useFileInputDropzone";
import { FormFileInputBlock } from "../../../common/ui-kit/form-controls/file-input";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { useFileInputError } from "../hooks/useFileInputError";

export interface FileUploadDropzoneInputBlockProps extends BaseCssProps {
  handleFileChange: (acceptedFiles: File[]) => void | Promise<void>;
  text: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  error?: string;
}

export const FileUploadDropzoneInputBlock = ({
  handleFileChange,
  className,
  text,
  isLoading,
  isDisabled,
  isMultiple,
  error: fieldError,
}: FileUploadDropzoneInputBlockProps) => {
  const {
    variant: variantDropZone,
    error: errorDropZone,
    getInputProps,
    getRootProps,
  } = useFileInputDropzone({
    onDrop: handleFileChange,
    isMultiple,
    isDisabled,
    isLoading,
  });

  const { variant, error } = useFileInputError(variantDropZone, fieldError, errorDropZone);

  return (
    <FormFileInputBlock
      className={className}
      variant={variant}
      error={error}
      text={text}
      inputProps={getInputProps({ disabled: isDisabled })}
      wrapperProps={getRootProps({ onClick: (evt) => evt.preventDefault() })}
    />
  );
};
