import { QueueTasksListFiltersProps } from "../QueueTasksListFiltersProps";
import { QueueTasksListShowFiltersButton } from "./filters-button/QueueTasksListShowFiltersButton";
import { FormSearchInput } from "../../../../../common/ui-kit/form-controls/search-input";
import { useProductFilterSearchInput } from "../../../../../common/filters/use-cases";

export const QueueTasksListFiltersMobile = ({ filteringObject }: QueueTasksListFiltersProps) => {
  const { value, onChange } = useProductFilterSearchInput(filteringObject);

  return (
    <FormSearchInput
      placeholder="Поиск по клиенту или номеру заявки"
      value={value}
      onChange={onChange}
      spellCheck={false}
      autoComplete="off"
      autoCorrect="off"
      afterInput={<QueueTasksListShowFiltersButton filteringObject={filteringObject} />}
    />
  );
};
