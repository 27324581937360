import { useMemo } from "react";
import { TransitionGroup } from "react-transition-group";
import { getEmptyArray } from "@sideg/helpers";
import { DemandId } from "../../../../../common/types/demand";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeDetailsDemandsIds } from "../../../store/demands/bankGuaranteeDetailsDemands.selectors";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeDetailsDemandsListItem } from "./BankGuaranteeDetailsDemandsListItem";
import { SimpleOpacityTransition } from "../../../../../common/ui-kit/animations/transitions";
import { useBankGuaranteeDetailsLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../../../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { isLoading } from "../../../../../common/types/state";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { ProductDetailsAsideNavigationCardSkeleton } from "../../../../../common/product-details";

const AVERAGE_DEMAND_COUNT = 4;

export type BankGuaranteeDetailsDemandsListProps = BaseCssProps;

export const BankGuaranteeDetailsDemandsList = ({ className }: BankGuaranteeDetailsDemandsListProps) => {
  const { guaranteeId } = useBankGuaranteeDetailsLayoutContext();
  const { status, isTransferred } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);
  const demandIds = useAppSelector(selectBankGuaranteeDetailsDemandsIds);

  const skeletonItems: number[] = useMemo(() => {
    if (isTransferred && isLoading(status)) {
      return getEmptyArray(Math.max(AVERAGE_DEMAND_COUNT - demandIds.length, 1));
    }

    if (isLoading(status) && demandIds.length === 0) {
      return getEmptyArray(AVERAGE_DEMAND_COUNT);
    }

    return [];
  }, [demandIds.length, isTransferred, status]);

  return (
    <StackPanel gap="sm" direction="column" className={className}>
      <TransitionGroup component={null}>
        {demandIds.map((x) => (
          <SimpleOpacityTransition key={x} timeout={250}>
            <BankGuaranteeDetailsDemandsListItem guaranteeId={guaranteeId} demandId={x as DemandId} />
          </SimpleOpacityTransition>
        ))}
      </TransitionGroup>
      {skeletonItems.map((x) => (
        <ProductDetailsAsideNavigationCardSkeleton key={x} />
      ))}
    </StackPanel>
  );
};
