import { ReactNode } from "react";
import { Theme, useTheme } from "@sideg/ui-kit";
import { Color } from "../../../theme";
import * as S from "./CircleIndicator.styled";
import { ElementColor, ResponsiveStyleValue } from "../../../types";

export interface CircleIndicatorProps {
  color: Color | ((theme: Theme) => Color);
  size: ResponsiveStyleValue<number>;
  className?: string;
  borderColor?: ElementColor;
  children?: ReactNode;
}

export const CircleIndicator = ({ color, size, borderColor, children, className }: CircleIndicatorProps) => {
  const theme = useTheme();
  const indicatorColor: Color = typeof color === "function" ? color(theme) : color;

  return (
    <S.Indicator className={className} size={size} indicatorColor={indicatorColor} borderColor={borderColor}>
      {children}
    </S.Indicator>
  );
};
