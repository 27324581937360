import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useCreditExecutionDetailsContext } from "../../../../hooks/context/useCreditExecutionDetailsContext";
import { useAppSelector } from "../../../../../../common/store";
import { selectCreditExecutionDetailsDemandById } from "../../../../store/demands/creditExecutionDetailsDemands.selectors";
import {
  getProductCreditsOnlyDetailsDemandHeaderDetailsItems,
  ProductDetailsContentHeaderLayout,
} from "../../../../../../common/product-details";

export type CreditExecutionDetailsDemandHeaderDetailsProps = BaseCssProps;

export const CreditExecutionDetailsDemandHeaderDetails = ({
  className,
}: CreditExecutionDetailsDemandHeaderDetailsProps) => {
  const { demandId } = useCreditExecutionDetailsContext();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectCreditExecutionDetailsDemandById(state, demandId!));

  const { main, approve } = getProductCreditsOnlyDetailsDemandHeaderDetailsItems(demand);

  return <ProductDetailsContentHeaderLayout.HeaderDetails left={main} right={approve} className={className} />;
};
