import styled from "@emotion/styled";
import { MenuItemNestingLevel } from "../../utils/getUserMenu";

interface StyledLiProps {
  nestedLevel: MenuItemNestingLevel;
  active: boolean;
}

export const Li = styled.li<StyledLiProps>`
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: 16px;
  padding: 0 8px 0 0;
  margin: 16px 0;
  user-select: none;

  & > span,
  & > a {
    display: block;
    color: ${(props) =>
      props.active ? props.theme.layout.sidebar.menu.activeLinkColor : props.theme.layout.sidebar.menu.linkColor};
    text-decoration: none;
    padding-left: calc(${(props) => `${props.theme.layout.borderPadding} * (1 + ${props.nestedLevel}*0.65)`});
    top: ${(props) => props.nestedLevel};

    &:hover {
      color: ${({ theme }) => theme.layout.sidebar.menu.activeLinkColor};
    }

    &:focus-visible {
      text-decoration: underline;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  & > ul {
    padding-top: 16px;
  }

  & > span {
    cursor: default;
  }
`;

export const Counter = styled.span<{ active: boolean }>`
  font-size: ${({ theme }) => theme.font.fz1};
  background: ${(props) =>
    props.active ? props.theme.layout.sidebar.menu.activeLinkColor : props.theme.layout.sidebar.menu.linkColor};
  padding: 1px 5px;
  color: ${({ theme }) => theme.layout.sidebar.menu.counterTextColor};
  border-radius: 4px;
  margin-left: 6px;
`;
