import Skeleton from "react-loading-skeleton";
import { BankGuaranteeCreationScoringResultRow } from "./BankGuaranteeCreationScoringResultRow";
import { BaseCssProps } from "../../../../../common/ui-kit/types";

export type BankGuaranteeCreationScoringResultRowSkeletonProps = BaseCssProps;

export const BankGuaranteeCreationScoringResultRowSkeleton = ({
  className,
}: BankGuaranteeCreationScoringResultRowSkeletonProps) => {
  return (
    <BankGuaranteeCreationScoringResultRow
      bankName={<Skeleton width={150} />}
      details={<Skeleton />}
      className={className}
      action={<Skeleton height={32} width={100} borderRadius={18} />}
    />
  );
};
