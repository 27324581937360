import Skeleton from "react-loading-skeleton";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreditWorkingAssetsListItemCardDesktop } from "./card/CreditWorkingAssetsListItemCard.desktop";
import { CreditWorkingAssetsListItemDemandsDesktopSkeleton } from "./demands/CreditWorkingAssetsListItemDemands.desktop.skeleton";
import { CreditWorkingAssetsCommonDetailsSkeleton } from "../../../../common/components";

export type CreditWorkingAssetsListItemDesktopSkeletonProps = BaseCssProps;

export const CreditWorkingAssetsListItemDesktopSkeleton = ({
  className,
}: CreditWorkingAssetsListItemDesktopSkeletonProps) => {
  return (
    <CreditWorkingAssetsListItemCardDesktop
      clientName={<Skeleton />}
      clientTaxpayerNumber={<Skeleton inline width={100} />}
      className={className}
      numberAndDate={<Skeleton />}
      details={<CreditWorkingAssetsCommonDetailsSkeleton />}
    >
      <CreditWorkingAssetsListItemDemandsDesktopSkeleton />
    </CreditWorkingAssetsListItemCardDesktop>
  );
};
