import * as React from "react";
import styles from "./CalculatorCompanyInfo.module.css";
import ClientFormBlock from "../../../../../../ui/components/client-form/parts/block/ClientFormBlock";
import { ClientFormMultiSelect } from "../../../../../../ui/components/client-form/parts/multi-select/ClientFormMultiSelect";
import { getMultiOptions } from "../data/mappers";
import { ScoringModelItemsType } from "../../../../../../entity/models/ScoringModel";
import { ScoringFormValues } from "../data/initialValues";

interface CalculatorCompanyInfoProps {
  clientOptions?: ScoringModelItemsType;
  title: string;
  additionalText: string;
  values: ScoringFormValues;
}

export const CalculatorCompanyInfo: React.FC<CalculatorCompanyInfoProps> = ({
  title,
  additionalText,
  clientOptions,
  values,
}) => {
  return (
    <ClientFormBlock title={title}>
      <div className={styles.wrapper}>
        <div className={styles.clientInfo}>
          {/* <div className={styles['clientInfo__inn']}>
                        <ClientFormInput
                            label={innTitle}
                            name={`${name}.inn`}
                            number
                            errors={errors?.inn}
                            touched={touched?.inn}
                            maxlength={12}
                        />
                    </div> */}
          <div className={styles.clientInfo__additionalAboutClient}>
            {clientOptions && (
              <ClientFormMultiSelect
                label={additionalText}
                options={getMultiOptions(clientOptions)}
                name={"scoringItems"}
              />
            )}
          </div>
        </div>
      </div>
    </ClientFormBlock>
  );
};
