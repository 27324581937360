import { forwardRef } from "react";
import { ChatDialogMessageId } from "../../types/ChatDialogMessageId";
import { ChatDialogMessagesGroupDateForMessage } from "../message/ChatDialogMessagesGroupDateForMessage";
import { ChatDialogMessage } from "../message/ChatDialogMessage";
import { ChatDialogMessageWithDate } from "../message/ChatDialogMessageWithDate";

export interface ChatDialogMainMessageGroupProps {
  index: number;
  messageId: ChatDialogMessageId;
  previousMessageId?: ChatDialogMessageId;
  className?: string;
}

export const ChatDialogMainMessageGroup = forwardRef<HTMLDivElement, ChatDialogMainMessageGroupProps>(
  ({ index, messageId, previousMessageId, className }, ref) => {
    if (index === 0) {
      return (
        <>
          <ChatDialogMessagesGroupDateForMessage messageId={messageId} className={className} />
          <ChatDialogMessage ref={ref} messageId={messageId} className={className} />
        </>
      );
    }

    if (!previousMessageId) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return null!;
    }

    return (
      <ChatDialogMessageWithDate
        ref={ref}
        messageId={messageId}
        previousMessageId={previousMessageId}
        className={className}
      />
    );
  }
);
