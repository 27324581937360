import styled from "@emotion/styled";
import {
  getProductCreationScoringPopupColorByVariant,
  ProductCreationScoringPopupVariant,
} from "../../../types/scoring/ProductCreationScoringPopupVariant";

export const PopUp = styled.div<{ variant: ProductCreationScoringPopupVariant }>`
  border-radius: 4px;
  background: #ffffff;
  max-width: 240px;
  padding: ${({ theme }) => theme.spaces.sm};
  position: relative;
  border: 1px solid ${({ theme, variant }) => getProductCreationScoringPopupColorByVariant(theme, variant).border};
  box-shadow: 0 8px 16px rgba(89, 111, 136, 0.6);
  white-space: pre-line;
`;
