import { memo } from "react";
import { useAppSelector } from "../../../../../common/store";
import {
  selectBankGuaranteeDetailsActiveDemand,
  selectBankGuaranteeDetailsActiveDemandDocuments,
} from "../../../store/active-demand/bankGuaranteeDetailsActiveDemand.selectors";
import { useBankGuaranteeDetailsDemandDocumentsFetch } from "../../../hooks/demand/useBankGuaranteeDetailsDemandDocumentsFetch";
import { ProductDetailsDemandActualDocumentsList } from "../../../../../common/product-details";
import { BaseCssProps } from "../../../../../common/ui-kit/types";

export type BankGuaranteeDetailsDemandActualDocumentsListProps = BaseCssProps;

export const BankGuaranteeDetailsDemandActualDocumentsList = memo(
  ({ className }: BankGuaranteeDetailsDemandActualDocumentsListProps) => {
    const {
      documents: { status },
    } = useAppSelector(selectBankGuaranteeDetailsActiveDemand);
    const documents = useAppSelector(selectBankGuaranteeDetailsActiveDemandDocuments);

    useBankGuaranteeDetailsDemandDocumentsFetch();

    return (
      <ProductDetailsDemandActualDocumentsList loadingStatus={status} documents={documents} className={className} />
    );
  }
);
