import { useField } from "formik";
import { FormRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox";
import { publicUserRegistrationFromRules } from "../../../validation/rules/publicUserRegistrationFromRules";
import { companyTypeWithShortTitle } from "../../../../../common/types/companies";
import { AgencyType } from "../../../../../common/types/agencies";
import { PublicUserRegistrationFormValues } from "../../../types/PublicUserRegistrationFormValues";
import { useGetFieldError } from "../../../../../common/form";

export interface PublicUserRegistrationFormCompanyTypeFieldProps {
  agencyType: AgencyType;
  isDisabled: boolean;
  fieldName: string;
}

export const PublicUserRegistrationFormCompanyTypeField = ({
  isDisabled,
  fieldName,
  agencyType,
}: PublicUserRegistrationFormCompanyTypeFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ checked, value, ...fieldProps }, { error, touched }] =
    useField<PublicUserRegistrationFormValues["companyType"]>(fieldName);
  const getFieldError = useGetFieldError();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {publicUserRegistrationFromRules.canSetCompanyType(agencyType) && (
        <FormRadioButtonGroup
          error={getFieldError(fieldName, touched, error)}
          itemsPlacement="start"
          disabled={isDisabled}
          isFullWidth
        >
          {companyTypeWithShortTitle.values.map((x) => (
            <FormRadioButtonGroup.Option
              key={x}
              {...fieldProps}
              value={x}
              disabled={isDisabled}
              checked={x === value}
              isSelected={x === value}
              radioSize="default"
              label={companyTypeWithShortTitle.getTitle(x)}
            />
          ))}
        </FormRadioButtonGroup>
      )}
    </>
  );
};
