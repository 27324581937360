import { Box } from "../../../../../../ui-kit/containers/box";
import {
  UserDetailsManagerSidebarBlockMobile,
  UserDetailsUserSidebarBlockMobile,
} from "../../../../../../../user/details";
import { NavigationMenuMobile } from "../../../../../../navigation/menu";
import { useLocationPathEffect } from "../../../../../../navigation/menu/hooks/useLocationPathEffect";
import { useAppLayout } from "../../../../app-layout-containers";
import { useIsIOSSafari } from "../../../../../../pwa/hooks/useIsIOSSafari";
import { useCanSetPwa } from "../../../../../../pwa/hooks/useCanSetPwa";
import { SetPwaSidebar } from "../../../../../../pwa/sidebar/SetPwa.sidebar";

export const AppSidebarMobile = () => {
  const { isMenuOpened } = useAppLayout();
  const isIosSafari = useIsIOSSafari();
  const isSetPwa = useCanSetPwa();

  useLocationPathEffect(() => {
    isMenuOpened.setValue(false);
  }, []);

  return (
    <Box py="md" px="lg" display="flex" flexDirection="column" gap="md" flexGrow={1} flexShrink={1}>
      {isIosSafari && !isSetPwa && <SetPwaSidebar />}
      <NavigationMenuMobile />
      <UserDetailsManagerSidebarBlockMobile />
      <UserDetailsUserSidebarBlockMobile />
    </Box>
  );
};
