import { PropsWithChildren } from "react";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { Box } from "../../../../common/ui-kit/containers/box";

interface FactoringCreationFormContainerProps extends PropsWithChildren<BaseCssProps> {}

export const FactoringCreationFormDebtorContainer = ({ children, className }: FactoringCreationFormContainerProps) => {
  return (
    <Box display="flex" flexDirection="column" gap="md" className={className}>
      {children}
    </Box>
  );
};
