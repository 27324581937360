import { clientV4 } from "../../../common/api";
import { EmptyOutputDto } from "../../../common/api/types";
import { CreateCreditWorkingAssetsCreationInputDto } from "./dto/input/CreateCreditWorkingAssetsCreationInputDto";
import { CreateDraftCreditWorkingAssetsCreationInputDto } from "./dto/input/CreateDraftCreditWorkingAssetsCreationInputDto";
import { ScoringCreditWorkingAssetsCreationInputDto } from "./dto/input/ScoringCreditWorkingAssetsCreationInputDto";
import { CreateCreditWorkingAssetsCreationOutputDto } from "./dto/output/CreateCreditWorkingAssetsCreationOutputDto";
import { ScoringCreditWorkingAssetsCreationOutputDto } from "./dto/output/ScoringCreditWorkingAssetsCreationOutputDto";
import { ScoringByExistsCreditWorkingAssetsCreationInputDto } from "./dto/input/ScoringByExistsCreditWorkingAssetsCreationInputDto";
import { CreateByExistsCreditWorkingAssetsCreationInputDto } from "./dto/input/CreateByExistsCreditWorkingAssetsCreationInputDto";
import { GetBriefCreditWorkingAssetsCreationInputDto } from "./dto/input/GetBriefCreditWorkingAssetsCreationInputDto";
import { GetBriefCreditWorkingAssetsCreationOutputDto } from "./dto/output/GetBriefCreditWorkingAssetsCreationOutputDto";

const CONTROLLER_NAME = "CreditWorkingAssets";

export const creditWorkingAssetsCreationApi = {
  createDraft: (model: CreateDraftCreditWorkingAssetsCreationInputDto) =>
    clientV4.post<CreateDraftCreditWorkingAssetsCreationInputDto, EmptyOutputDto>(
      `${CONTROLLER_NAME}/create-draft`,
      model,
    ),
  scoring: (model: ScoringCreditWorkingAssetsCreationInputDto) =>
    clientV4.post<ScoringCreditWorkingAssetsCreationInputDto, ScoringCreditWorkingAssetsCreationOutputDto>(
      `${CONTROLLER_NAME}/scoring`,
      model,
    ),
  scoringByExists: ({ creditWorkingAssetsId }: ScoringByExistsCreditWorkingAssetsCreationInputDto) =>
    clientV4.post<ScoringByExistsCreditWorkingAssetsCreationInputDto, ScoringCreditWorkingAssetsCreationOutputDto>(
      `${CONTROLLER_NAME}/${creditWorkingAssetsId}/scoring`,
      undefined,
    ),
  create: (model: CreateCreditWorkingAssetsCreationInputDto) =>
    clientV4.post<CreateCreditWorkingAssetsCreationInputDto, CreateCreditWorkingAssetsCreationOutputDto>(
      `${CONTROLLER_NAME}/create`,
      model,
    ),
  createByExists: ({ creditWorkingAssetsId, ...model }: CreateByExistsCreditWorkingAssetsCreationInputDto) =>
    clientV4.post<
      Omit<CreateByExistsCreditWorkingAssetsCreationInputDto, "creditWorkingAssetsId">,
      CreateCreditWorkingAssetsCreationOutputDto
    >(`${CONTROLLER_NAME}/${creditWorkingAssetsId}/create`, model),
  getBrief: ({ creditWorkingAssetsId }: GetBriefCreditWorkingAssetsCreationInputDto) =>
    clientV4.get<GetBriefCreditWorkingAssetsCreationInputDto, GetBriefCreditWorkingAssetsCreationOutputDto>(
      `${CONTROLLER_NAME}/${creditWorkingAssetsId}/brief`,
    ),
};
