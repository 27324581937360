import { useMemo } from "react";
import { useAppDispatch } from "../../../common/store";
import { bankGuaranteeDetailsReloadActiveGuarantee } from "../store/bankGuaranteeDetails.actions";
import { pushSuccessGlobalToast } from "../../../global/toasts";
import { makeProductRejectionOverlayPanelKey, useProductRejectionModalPanel } from "../../../common/product-rejection";
import { BankGuaranteeId, DemandId } from "../../../common/types/demand";

export const useBankGuaranteeDetailsGuaranteeRejection = (guaranteeId: BankGuaranteeId, demandId?: DemandId) => {
  const dispatch = useAppDispatch();
  const panelKey = makeProductRejectionOverlayPanelKey("BankGuarantee", guaranteeId, demandId);
  const [isModalOpen, setIsModalOpen] = useProductRejectionModalPanel(panelKey);

  return useMemo(() => {
    const closeModal = () => setIsModalOpen(false);
    const openModal = () => setIsModalOpen(true);

    const afterSuccess = async (): Promise<void> => {
      closeModal();
      dispatch(pushSuccessGlobalToast("Заявка отменена"));
      dispatch(bankGuaranteeDetailsReloadActiveGuarantee());
    };

    return { isModalOpen, openModal, closeModal, afterSuccess, panelKey };
  }, [dispatch, isModalOpen, panelKey, setIsModalOpen]);
};
