import { useFormikContext } from "formik";
import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { FormNumberInput } from "@sideg/ui-kit/atoms/form-controls/number-input";
import { FullPageContainerCenteredMainWithFooter } from "../../../../common/ui/containers/full-page";
import { FactoringCreationFormValues } from "../../types/FactoringCreationFormValues";
import { useCreationFormFieldTouchedEffect, useGetFieldName, useGetFormFieldError } from "../../../../common/form";
import { CreationBlockWithTitle } from "../../../../common/ui-kit/cards/creation-block-card";
import { TaxpayerFormInput } from "../../../../common/ui/form-controls/taxpayer-input";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";
import { useFormControlsNumberInputField } from "../../../../common/ui/form-controls/number-input-field";
import { FactoringCreationDebtorsList } from "./FactoringCreationDebtorsList";
import { CreationSubmitButton } from "../../../../common/ui/buttons/creation-submit-button";

export const FactoringCreationFromContent = () => {
  const getFieldName = useGetFieldName<FactoringCreationFormValues>();
  const getFieldError = useGetFormFieldError<FactoringCreationFormValues>();
  const form = useFormikContext<FactoringCreationFormValues>();

  const fieldDelayProps = useFormControlsNumberInputField(getFieldName("delay"));
  const isDisabled = form.isSubmitting;
  const isMinusDisabled = (fieldDelayProps.value ?? 0) <= 0;

  useCreationFormFieldTouchedEffect();

  return (
    <FullPageContainerCenteredMainWithFooter
      footer={
        <CreationSubmitButton
          type="submit"
          isLoading={isDisabled}
          error={form.touched.debtorsIds ? (form.errors.debtorsIds as string) : undefined}
        >
          Отправить заявку
        </CreationSubmitButton>
      }
    >
      <CreationBlockWithTitle title="Клиент (поставщик)">
        <StackPanel gap="md" direction="column">
          <TaxpayerFormInput
            label="ИНН"
            size="medium"
            autoComplete="off"
            {...form.getFieldProps(getFieldName("customerTaxpayerNumber"))}
            error={getFieldError(form, "customerTaxpayerNumber")}
            disabled={isDisabled}
          />
          <FormInputPrice
            autoComplete="off"
            label="Выручка (без НДС), ₽"
            size="medium"
            {...form.getFieldProps(getFieldName("revenue"))}
            error={getFieldError(form, "revenue")}
            disabled={isDisabled}
          />
          <FormInputPrice
            autoComplete="off"
            label="Кредитная нагрузка (до 1 года), тыс. ₽"
            size="medium"
            {...form.getFieldProps(getFieldName("creditLoad"))}
            error={getFieldError(form, "creditLoad")}
            disabled={isDisabled}
          />
          <FormInputPrice
            autoComplete="off"
            label="Среднемесячная отгрузка, тыс. ₽"
            size="medium"
            {...form.getFieldProps(getFieldName("averageMonthly"))}
            error={getFieldError(form, "averageMonthly")}
            disabled={isDisabled}
          />
          <FormNumberInput
            isMinusDisabled={isMinusDisabled}
            label="Договорная отсрочка, дней"
            size="medium"
            {...fieldDelayProps}
            disabled={isDisabled}
          />
        </StackPanel>
      </CreationBlockWithTitle>
      <FactoringCreationDebtorsList isDisabled={isDisabled} />
    </FullPageContainerCenteredMainWithFooter>
  );
};
