import { useEffect, useState } from "react";
import * as React from "react";
import CommentModel from "../../../models/CommentModel";
import "./Comments.scss";
import AttachmentLink from "../attachment/AttachmentLink";
import { formatterDayMonthYearHourMinute } from "../../../essentials/helpers";

interface ICommentItemProps {
  comment: CommentModel;
}

const CommentItem: React.FC<ICommentItemProps> = (props) => {
  const formatDate = (d: string): string => {
    const date = new Date(d);
    return formatterDayMonthYearHourMinute.format(date);
  };

  return (
    <div className="comment">
      <div className="comment__header">
        <div className="comment__author">{props.comment.author}</div>
        <div className="comment__date">{formatDate(props.comment.createDate)}</div>
      </div>
      {props.comment.text && <div className="comment__body">{props.comment.text}</div>}
      {props.comment.files && props.comment.files.length > 0 && (
        <div className="comment__files">
          {props.comment.files.map((item, index) => {
            return (
              <div className="comment__files-item" key={index}>
                <AttachmentLink file={item} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

interface ICommentsWrapperProps {
  comments: CommentModel[];
  countView?: number;
}

interface ShowOtherState {
  count: number;
  view: boolean;
}

const CommentsWrapper: React.FC<ICommentsWrapperProps> = (props) => {
  const countCommentsShow = props.countView ?? 2;
  const [showOther, setShowOther] = useState<ShowOtherState>();

  useEffect(() => {
    const diff = props.comments.length - countCommentsShow;
    if (diff > 0) {
      setShowOther({ count: diff, view: false });
    }
  }, []);

  const changeShowOther = () => {
    setShowOther((prev) => {
      return { ...prev!, view: !prev!.view };
    });
  };

  return (
    <div className="comment-wrapper">
      <>
        {props.comments.length === 0 && <div className="comment-wrapper__empty">Комментарии отсутствуют</div>}
        {props.comments.slice(0, countCommentsShow).map((item, index) => {
          return <CommentItem comment={item} key={index} />;
        })}
        {showOther && (
          <>
            {showOther.view &&
              props.comments.slice(countCommentsShow).map((item, index) => {
                return <CommentItem comment={item} key={index} />;
              })}
            <div className="comment-wrapper__show-others">
              <span className="comment-wrapper__show-others-link" onClick={changeShowOther}>
                {showOther.view ? <i className="fal fa-arrow-up" /> : <i className="fal fa-arrow-down" />}
                {showOther.view ? " Скрыть " : " Показать "}
                предыдущие ({showOther.count})
              </span>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default CommentsWrapper;
