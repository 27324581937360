import Skeleton from "react-loading-skeleton";
import { BankGuaranteeDetailsDemandReconciliationBillDataItem } from "./BankGuaranteeDetailsDemandReconciliationBillDataItem";
import { BankGuaranteeDetailsDemandReconciliationContentSkeleton } from "../content/BankGuaranteeDetailsDemandReconciliationContent.skeleton";
import { BankGuaranteeDetailsDemandReconciliationContent } from "../content/BankGuaranteeDetailsDemandReconciliationContent";

export interface BankGuaranteeDetailsDemandReconciliationBillContentSkeletonProps {
  containerTestId?: string;
}

export const BankGuaranteeDetailsDemandReconciliationBillContentSkeleton = ({
  containerTestId,
}: BankGuaranteeDetailsDemandReconciliationBillContentSkeletonProps) => {
  return (
    <BankGuaranteeDetailsDemandReconciliationContentSkeleton containerTestId={containerTestId}>
      <BankGuaranteeDetailsDemandReconciliationContent.Block withLineSeparator>
        <BankGuaranteeDetailsDemandReconciliationBillDataItem
          term={<Skeleton width={120} />}
          description={<Skeleton width={100} />}
        />
        <BankGuaranteeDetailsDemandReconciliationBillDataItem
          term={<Skeleton width={50} />}
          description={<Skeleton width={80} />}
        />
        <BankGuaranteeDetailsDemandReconciliationBillDataItem
          term={<Skeleton width={160} />}
          description={<Skeleton width={120} />}
        />
      </BankGuaranteeDetailsDemandReconciliationContent.Block>
      <BankGuaranteeDetailsDemandReconciliationContent.Block withLineSeparator>
        <Skeleton width={80} />
        <Skeleton width={180} height={24} />
      </BankGuaranteeDetailsDemandReconciliationContent.Block>
    </BankGuaranteeDetailsDemandReconciliationContentSkeleton>
  );
};
