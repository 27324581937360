import { useField } from "formik";
import { PropsWithChildren } from "react";
import { FormRadioButtonGroup, FormRadioButtonGroupOption } from "@sideg/ui-kit/atoms/checkbox";
import { ClientSearchClientStateSelector } from "../../types/ClientSearchClientStateSelector";
import { ClientSearchFixedKey } from "../../types/ClientSearchFixedKey";
import { useGetFieldError, useGetFieldName } from "../../../common/form";
import { useAppSelector } from "../../../common/store";
import { ClientTaxSystemType, clientTaxSystemType } from "../../../common/types/demand";
import { SimpleOpacityTransition } from "../../../common/ui-kit/animations/transitions";
import { CreationBlockWithTitle } from "../../../common/ui-kit/cards/creation-block-card";
import { StackPanel } from "../../../common/ui-kit/containers/stack-panel";
import { BaseCssProps } from "../../../common/ui-kit/types";
import { ClientSearchCreationClientBlockFormValues } from "../../types/ClientSearchCreationClientBlockFormValues";
import { useClientSearchCreationClientIdFieldMutation } from "../../hooks/creation/useClientSearchCreationClientIdFieldMutation";
import { ClientSearch } from "../ClientSearch";
import { clientRules } from "../../validation/rules/client.rules";

export interface CreationClientBlockProps extends BaseCssProps, PropsWithChildren {
  clientStateSelector: ClientSearchClientStateSelector;
  fixedKey: ClientSearchFixedKey;
  isDisabled?: boolean;
}

export const ClientSearchCreationClientBlock = <TFormValues extends ClientSearchCreationClientBlockFormValues>({
  isDisabled,
  clientStateSelector,
  fixedKey,
  className,
  children,
}: CreationClientBlockProps) => {
  const clientId = useClientSearchCreationClientIdFieldMutation<TFormValues>(clientStateSelector);
  const { check } = useAppSelector(clientStateSelector);

  const getFieldName = useGetFieldName<ClientSearchCreationClientBlockFormValues>();
  const getFieldError = useGetFieldError<ClientSearchCreationClientBlockFormValues>();

  const [{ value: taxpayerNumber }] = useField<string>(getFieldName("taxpayerNumber"));

  const [{ value, onBlur, onChange }, { error, touched }] = useField<ClientTaxSystemType>(
    getFieldName("clientTaxSystem"),
  );

  return (
    <CreationBlockWithTitle title="Клиент" className={className}>
      <StackPanel direction="column" gap="md">
        <ClientSearch
          canRemove={!isDisabled}
          fixedKey={fixedKey}
          clientStateSelector={clientStateSelector}
          initialSearchValue={taxpayerNumber}
          isWithPassingAlert
          isWithAddress={false}
        />
        <SimpleOpacityTransition
          timeout={300}
          in={clientId !== undefined && check?.data !== undefined && clientRules.isClientPassing(check.data)}
          unmountOnExit
          mountOnEnter
        >
          <>
            <FormRadioButtonGroup
              label="Система налогообложения"
              itemsPlacement="start"
              error={getFieldError("clientTaxSystem", touched, error)}
            >
              {clientTaxSystemType.values.map((x) => (
                <FormRadioButtonGroupOption
                  key={x}
                  value={x}
                  label={clientTaxSystemType.getTitle(x)}
                  name={getFieldName("clientTaxSystem")}
                  radioSize="default"
                  isSelected={x === value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              ))}
            </FormRadioButtonGroup>
            {children}
          </>
        </SimpleOpacityTransition>
      </StackPanel>
    </CreationBlockWithTitle>
  );
};
