import { forwardRef } from "react";
import * as S from "./ProductListCardRejectButton.styled";
import { BaseCssProps } from "../../../../../../ui-kit/types";
import { TrashIcon } from "../../../../../../ui-kit/icons";

export interface ProductListCardRejectButtonProps
  extends BaseCssProps,
    Omit<JSX.IntrinsicElements["button"], "type" | "className" | "ref"> {}

export const ProductListCardRejectButton = forwardRef<HTMLButtonElement, ProductListCardRejectButtonProps>(
  (props, ref) => {
    return (
      <S.Button ref={ref} {...props} type="button">
        <TrashIcon />
      </S.Button>
    );
  }
);
