import * as React from "react";
import * as S from "./BankGuaranteeRewardsFilterSelectCard.styled";
import { BaseCssProps } from "../../../../../common/ui-kit/types";

export interface BankGuaranteeRewardsFilterSelectCardProps extends BaseCssProps {
  children: React.ReactNode;
  isFixed: boolean;
}

export const BankGuaranteeRewardsFilterSelectCard = React.forwardRef<
  HTMLDivElement,
  BankGuaranteeRewardsFilterSelectCardProps
>(({ isFixed, children, className }, ref) => {
  return (
    <S.Container ref={ref} isFixed={isFixed} className={className}>
      {children}
    </S.Container>
  );
});
