import { useParams } from "react-router-dom";
import { AppUrlSelector } from "../types";
import { ExtractRouteParams } from "../utils";

type MappedUrlParams<TParams extends ExtractRouteParams<string>> = { [P in keyof TParams]?: string };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useAppUrlParams = <TUrl extends string>(_: AppUrlSelector<TUrl, never>) => {
  return useParams<MappedUrlParams<ExtractRouteParams<TUrl>>>();
};
