import { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { entityHelper } from "@sideg/helpers";
import { ButtonLink } from "../../../../common/ui-kit/buttons/ButtonLink";

const StyledListContainer = styled.div`
  padding-left: ${({ theme }) => theme.spaces.xs};
`;

const StyledSpan = styled.span`
  font-weight: 500;
`;

const StyledOl = styled.ol`
  list-style: decimal inside;
  line-height: 20px;
`;

const StyledSection = styled.section``;

export interface QueueTasksListItemRequiredDocumentProps {
  documents: string[];
}

export const QueueTasksListItemRequiredDocument = ({ documents }: QueueTasksListItemRequiredDocumentProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const [hideMoreThan, setHideMoreThan] = useState(1);
  const trimmedList = useMemo(() => {
    const orderedDocuments = [...documents].sort((a, b) => a.length - b.length);
    const shownCount = orderedDocuments.slice(0, 2).some((x) => x.length < 60) ? 2 : 1;

    setHideMoreThan(shownCount);

    return orderedDocuments.slice(0, documents.length > shownCount && isOpened ? documents.length : shownCount);
  }, [documents, isOpened]);

  return (
    <StyledSection>
      <StyledSpan>
        {`${entityHelper.getWordDeclension(
          documents.length,
          "Запрашива",
          ["ется", "ется", "ются"],
          false,
        )} ${entityHelper.getWordDeclension(documents.length, "документ", ["", "а", "ов"])}`}
      </StyledSpan>
      <StyledListContainer>
        <StyledOl>
          {trimmedList.map((x, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={x + i}>{x}</li>
          ))}
        </StyledOl>
        {documents.length > hideMoreThan && (
          <ButtonLink type="button" onClick={() => setIsOpened(!isOpened)}>
            {isOpened ? "Скрыть все документы" : "Все документы"}
          </ButtonLink>
        )}
      </StyledListContainer>
    </StyledSection>
  );
};
