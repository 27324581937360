import { combineSliceNames } from "../../../common/store/utils";
import { CREDIT_WORKING_ASSETS_SLICE_NAME } from "../../common/store";
import { createApiThunks } from "../../../common/store/utils/createApiThunk";
import { creditWorkingAssetsCreationApi } from "../api/creditWorkingAssetsCreationApi";

export const CREDIT_WORKING_ASSETS_CREATION_SLICE_NAME = combineSliceNames(
  CREDIT_WORKING_ASSETS_SLICE_NAME,
  "creation"
);

export const {
  createDraft: createDraftCreditWorkingAssetsCreation,
  scoring: scoringCreditWorkingAssetsCreation,
  scoringByExists: scoringByExistsCreditWorkingAssetsCreation,
  create: createCreditWorkingAssetsCreation,
  createByExists: createByExistsCreditWorkingAssetsCreation,
  getBrief: getBriefCreditWorkingAssetsCreation,
} = createApiThunks(CREDIT_WORKING_ASSETS_CREATION_SLICE_NAME, creditWorkingAssetsCreationApi);
