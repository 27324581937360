import { ComponentProps, MutableRefObject, ReactNode } from "react";
import * as S from "./Tooltip.styled";
import { TooltipArrow, TooltipArrowBaseProps } from "./arrow/TooltipArrow";

export interface TooltipProps extends TooltipArrowBaseProps {
  children?: ReactNode;
  popUpProps: Omit<ComponentProps<typeof S.PopUp>, "children">;
  arrowRef: MutableRefObject<HTMLElement | null>;
}

export const Tooltip = ({ children, popUpProps, placement, arrowData, arrowRef }: TooltipProps) => {
  return (
    <S.PopUp {...popUpProps}>
      <TooltipArrow
        ref={arrowRef as MutableRefObject<HTMLDivElement>}
        backgroundColor={(theme) => theme.layout.header.backgroundColor}
        borderColor={(theme) => theme.layout.header.backgroundColor}
        placement={placement}
        arrowData={arrowData}
      />
      {children}
    </S.PopUp>
  );
};
