import { UserChangePasswordFormValues } from "./UserChangePasswordFormValues";
import { EnumLikeLiteralType, makeEnumLikeLiteral } from "../../../common/types/utils";

type Keys = keyof Pick<UserChangePasswordFormValues, "oldPassword" | "newPassword" | "confirmPassword">;

const getValue = <T1 extends Keys, T2 extends Keys, T3 extends Keys>(t1: T1, t2: T2, t3: T3) => [t1, t2, t3] as const;
const titles = {
  oldPassword: "Ваш текущий пароль",
  newPassword: "Новый пароль",
  confirmPassword: "Повторите пароль, чтобы не ошибиться",
};

export const userChangePasswordFieldType = makeEnumLikeLiteral(
  getValue("oldPassword", "newPassword", "confirmPassword"),
  titles
);

export const userChangePasswordFieldGetAutocomplete = (
  type: EnumLikeLiteralType<typeof userChangePasswordFieldType>
): JSX.IntrinsicElements["input"]["autoComplete"] => {
  if (type === "oldPassword") {
    return "current-password";
  }

  return "new-password";
};
