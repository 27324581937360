import { DateRangeFilterForm } from "./DateRangeFilterForm";
import { Modal } from "../../../../ui/containers/modal";

interface BankGuaranteeListFiltersDatesModalProps {
  onClose: () => void;
  onSuccess: (dateCreateFrom?: Date, dateCreateTo?: Date) => void;
  initialValues: { dateCreateFrom?: Date; dateCreateTo?: Date };
  isShown: boolean;
}

export const DateRangeFilterModal = ({
  initialValues,
  onClose,
  onSuccess,
  isShown,
}: BankGuaranteeListFiltersDatesModalProps) => {
  return (
    <Modal
      isShown={isShown}
      title="Выберите период"
      onClose={onClose}
      background={(x) => x.palette.background.contrastLight}
    >
      <DateRangeFilterForm initialValues={initialValues} onSuccess={onSuccess} />
    </Modal>
  );
};
