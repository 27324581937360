import { useEffect, useState } from "react";
import * as React from "react";
import Input from "./Input";
import IValue from "../IValue";

export interface IInputRequiredProps {
  viewError: boolean;
  defaultValue?: string;
  name: string;
  placeholder: string;
  maxLength: number;
  onChange: (value: IValue) => void;
}

const InputRequired: React.FC<IInputRequiredProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [internalValue, setInternalValue] = useState<IValue>();

  useEffect(() => {
    validateValue(props.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    if (internalValue) {
      props.onChange(internalValue);
    }
  }, [internalValue]);

  const handleChange = (value: string) => {
    validateValue(value);
  };

  const validateValue = (value?: string) => {
    const result = validate(value);
    setInternalValue(result);
  };

  const validate = (value?: string): IValue => {
    let result;
    if (value === undefined || value.length === 0) {
      result = {
        value: value,
        isValid: false,
        errorMessage: "Заполните поле",
      };
    } else {
      result = {
        value: value,
        isValid: true,
        errorMessage: undefined,
      };
    }

    setErrorMessage(result.errorMessage);

    return result;
  };

  return (
    <Input
      errorMessage={props.viewError ? errorMessage : undefined}
      defaultValue={props.defaultValue}
      name={props.name}
      placeholder={props.placeholder}
      maxLength={props.maxLength}
      onChange={handleChange}
    />
  );
};

export default InputRequired;
