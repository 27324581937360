import { CSSObject } from "@emotion/react";
import { UsePopupReturnType } from "@sideg/ui-kit/atoms/pop-up";
import { ControlSize } from "@sideg/ui-kit/common/types";
import {
  SELECT_POPUP_CONTAINER_ITEM_GAP,
  SelectPopupContainerItemIconSize,
} from "@sideg/ui-kit/atoms/form-controls/select/components/select-popup/item/SelectPopupContainerItem";

export const useFilterSelectContainerMinWidth = <T extends Element>(
  popUpContainer: UsePopupReturnType<T>["referenceElementProps"],
  selectSize: ControlSize,
  maxItemLength?: number,
): CSSObject["minWidth"] => {
  return maxItemLength
    ? `calc(${maxItemLength}ch + ${SelectPopupContainerItemIconSize[selectSize]} + ${SELECT_POPUP_CONTAINER_ITEM_GAP})`
    : `${popUpContainer.width}px`;
};
