import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { CREDIT_EXECUTION_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const creditExecutionCreationUrls = {
  create: makeAppUrl(
    joinUrlParts(CREDIT_EXECUTION_ROOT_URL, "new"),
    "/credit/new",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCe),
  ),
  createByCreditExecution: makeAppUrl(
    joinUrlParts(CREDIT_EXECUTION_ROOT_URL, "new", ":creditExecutionId"),
    "/credit/new/:creditExecutionId",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCe),
  ),
};
