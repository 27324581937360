import { FormInput, FormInputProps } from "@sideg/ui-kit/atoms/form-controls/input";
import { forwardRef, useCallback } from "react";
import { Flex } from "@sideg/ui-kit/atoms/containers";
import * as S from "@sideg/ui-kit/atoms/form-controls/number-input/components/FormNumberInput.styled";
import { useForwardedRef } from "@sideg/ui-kit/helpers/global";

export const FORM_NUMBER_INPUT_PLUS_BUTTON_DATA_TEST_ID = "plusButton";
export const FORM_NUMBER_INPUT_MINUS_BUTTON_DATA_TEST_ID = "minusButton";

export interface FormNumberInputProps extends Omit<FormInputProps, "afterInput" | "defaultValue" | "type"> {
  onStepUpClick?: () => void | Promise<void>;
  onStepDownClick?: () => void | Promise<void>;
  isMinusDisabled?: boolean;
  isPlusDisabled?: boolean;
}

export const FormNumberInput = forwardRef<HTMLInputElement, FormNumberInputProps>(
  ({ isMinusDisabled, size = "default", isPlusDisabled, disabled, onStepUpClick, onStepDownClick, ...rest }, ref) => {
    const innerRef = useForwardedRef(ref);

    const stepUpClickHandler = useCallback(() => {
      if (innerRef.current && "stepUp" in innerRef.current) {
        innerRef.current?.stepUp();
        onStepUpClick?.();
      }
    }, [innerRef, onStepUpClick]);

    const stepDownClickHandler = useCallback(() => {
      if (innerRef.current && "stepDown" in innerRef.current) {
        innerRef.current?.stepDown();
        onStepDownClick?.();
      }
    }, [innerRef, onStepDownClick]);

    return (
      <FormInput
        ref={innerRef}
        type="number"
        inputMode="numeric"
        disabled={disabled}
        size={size}
        afterInput={
          !disabled && (
            <Flex display="flex" alignItems="center" justifyContent="center" gap="sm" px="m">
              <S.ControlButton
                data-testid={FORM_NUMBER_INPUT_MINUS_BUTTON_DATA_TEST_ID}
                type="button"
                disabled={isMinusDisabled}
                onClick={stepDownClickHandler}
                tabIndex={-1}
              >
                <S.MinusButtonIcon size={size} />
              </S.ControlButton>
              <S.ControlButton
                data-testid={FORM_NUMBER_INPUT_PLUS_BUTTON_DATA_TEST_ID}
                type="button"
                disabled={isPlusDisabled}
                onClick={stepUpClickHandler}
                tabIndex={-1}
              >
                <S.PlusButtonIcon size={size} />
              </S.ControlButton>
            </Flex>
          )
        }
        {...rest}
      />
    );
  },
);
