import { RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import * as React from "react";
import { useAppSelector } from "../../../common/store";
import { selectChatDialog, selectChatDialogLastMessageId } from "../store/chatDialog.selectors";
import { useChatDialogIntersectionEffect } from "./useChatDialogIntersectionEffect";
import { useDebounce } from "../../../common/hooks/useDebounce";
import { isLoading } from "../../../common/types/state";

const OBSERVER_Y_OFFSET = -100;
const LAST_MESSAGE_Y_OFFSET = -30;
const OBSERVER_SHOW_DEBOUNCE_MS = 800;

export interface UseChatDialogScrollArgs {
  rootRef: RefObject<HTMLElement | null>;
}

export const useChatDialogScroll = ({ rootRef }: UseChatDialogScrollArgs) => {
  const { status } = useAppSelector(selectChatDialog);

  const lastMessageId = useAppSelector(selectChatDialogLastMessageId);
  const [previousLastMessageId, setPreviousLastMessageId] = useState(lastMessageId);
  const previousLastMessageRef = useRef<HTMLDivElement | null>(null);

  const observerRef = useChatDialogIntersectionEffect({ rootRef });
  const [showObserver, setShowObserver] = useState(true);
  const [showObserverDebounced] = useDebounce(showObserver, OBSERVER_SHOW_DEBOUNCE_MS);

  useEffect(() => {
    if (isLoading(status)) {
      setShowObserver(false);
    }
  }, [status]);

  const calculateScrollPosition = useCallback((root: HTMLElement, childElem: HTMLElement, offset: number) => {
    return childElem.offsetTop - root.offsetTop + offset;
  }, []);

  const isScrollOverOffset = useCallback((currentPosition: number, maxValue: number) => currentPosition < maxValue, []);

  // Двигаем скролл чуть ниже крайнего положения, чтобы он не "прилипал" и не вызывал бесконечную загрузку
  const onRootScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      const target = e.target as HTMLElement;

      if (previousLastMessageRef.current) {
        const scrollPosition = calculateScrollPosition(target, previousLastMessageRef.current, OBSERVER_Y_OFFSET);
        if (isScrollOverOffset(target.scrollTop, scrollPosition)) {
          target.scrollTo({ top: scrollPosition });
        }
      }
    },
    [calculateScrollPosition, isScrollOverOffset],
  );

  // После получения новой страницы двигаем скролл к предыдущему сообщению
  useLayoutEffect(() => {
    if (previousLastMessageId !== lastMessageId) {
      if (previousLastMessageRef.current && rootRef.current) {
        const messageScrollPosition = calculateScrollPosition(
          rootRef.current,
          previousLastMessageRef.current,
          LAST_MESSAGE_Y_OFFSET,
        );
        rootRef.current?.scrollTo({ top: messageScrollPosition });
      }

      setPreviousLastMessageId(lastMessageId);
      setShowObserver(true);
    }
  }, [calculateScrollPosition, lastMessageId, previousLastMessageId, rootRef]);

  return useMemo(
    () => ({
      onRootScroll,
      observerRef,
      showObserver: showObserver && showObserverDebounced,
      previousLastMessageId,
      previousLastMessageRef,
    }),
    [observerRef, onRootScroll, previousLastMessageId, showObserver, showObserverDebounced],
  );
};
