import styled from "@emotion/styled";

export const Card = styled.section`
  border: 1px solid ${({ theme }) => theme.palette.control.secondaryMedium};
  border-radius: 4px;
`;

export const Part = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.md};
  padding: ${({ theme }) => theme.spaces.md};
  align-items: flex-start;
`;
