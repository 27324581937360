export type Brand<TBase, TBranding, TReservedName extends string = "__type__"> = TBase & {
  [K in TReservedName]: TBranding;
} & { __witness__: TBase };

export type AnyBrand = Brand<unknown, unknown>;

export type BaseOf<TBase extends AnyBrand> = TBase["__witness__"];

export type Brander<TBase extends AnyBrand> = (underlying: BaseOf<TBase>) => TBase;

export const identity = <TBase extends AnyBrand>(underlying: BaseOf<TBase>): TBase => underlying as TBase;

export const make = <TBase extends AnyBrand>(): Brander<TBase> => identity;
