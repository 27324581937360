import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/store";
import { selectBankGuaranteeFilterConditions } from "../store/bankGuaranteeConditions.selectors";
import { getBankGuaranteeConditions } from "../store/bankGuaranteeConditions.thunks";
import { isFailed, isLoading, isSucceeded } from "../../../common/types/state";
import { BankGuaranteeConditionsTableBody } from "./BankGuaranteeConditionsTableBody";
import { conditionsTitles } from "../utils/conditionsTitles";
import Loader from "../../../../components/common/Loader";
import InfoBanner, { InfoBannerStyle } from "../../../../components/common/info-banner/InfoBanner";
import { ConditionsAppResponsiveLayout, ConditionsTable } from "../../../common/ui/layout/conditions";
import { FilteringBaseOrderType } from "../../../common/filters";

export const BankGuaranteeConditionsTable = () => {
  const dispatch = useAppDispatch();

  const [orderType, setOrderType] = useState<FilteringBaseOrderType>("asc");
  const { conditions, status } = useAppSelector((state) => selectBankGuaranteeFilterConditions(state, orderType));

  useEffect(() => {
    dispatch(getBankGuaranteeConditions());
  }, [dispatch]);

  return (
    <ConditionsAppResponsiveLayout>
      {isLoading(status) && <Loader />}
      {isFailed(status) && <InfoBanner text="Загрузить условия банков не удалось" style={InfoBannerStyle.Error} />}
      {isSucceeded(status) &&
        conditions &&
        (conditions.length ? (
          <ConditionsTable
            order={orderType}
            titles={conditionsTitles}
            sortHandler={() => setOrderType(orderType === "asc" ? "desc" : "asc")}
          >
            <BankGuaranteeConditionsTableBody conditions={conditions} />
          </ConditionsTable>
        ) : (
          <InfoBanner text="Условия банков не найдены" style={InfoBannerStyle.Default} />
        ))}
    </ConditionsAppResponsiveLayout>
  );
};
