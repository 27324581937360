import styled from "@emotion/styled";
import { Fragment } from "react";

export const BaseButton = styled(Fragment)`
  margin: 0;
  appearance: none;
  font-family: inherit;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  border: none;
  background-color: transparent;
  position: relative;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  user-select: none;

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.primaryDark};
    outline-offset: 1px;
  }

  &:disabled {
    cursor: default;
  }
`;
