import { QueueTasksListItemProduct } from "../../../../common/types/queue-tasks";
import { QueueTasksListItemProductColumn } from "./QueueTasksListItemProductColumn";
import {
  ProductListItemColumn,
  ProductListItemColumnTitle,
} from "../../../../common/ui/product/list/product-list-item";
import { getFederalLawTypeTitle } from "../../../../common/types/demand";
import { getQueueTasksListItemSumTitle } from "../../utils/getQueueTasksListItemSumTitle";
import { QueueTasksListItemProductColumnInfoWrapper } from "./QueueTasksListItemProductColumnInfoWrapper";

interface QueueTasksListItemProductColumnWrapperProps {
  product: QueueTasksListItemProduct;
}

export const QueueTasksListItemProductColumnWrapper = ({ product }: QueueTasksListItemProductColumnWrapperProps) => {
  if (product.guarantee) {
    return (
      <QueueTasksListItemProductColumn
        id={product.productId}
        dateAdded={product.dateAdded}
        title={product.guarantee.guaranteeType.title}
        clientTaxpayerNumber={product.guarantee.clientTaxpayerNumber}
        clientName={product.guarantee.clientName}
        bankName={product.guarantee.bankName}
      >
        <QueueTasksListItemProductColumnInfoWrapper>
          {getQueueTasksListItemSumTitle(product.guarantee.sum, "Сумма")}
          {product.guarantee.sum !== undefined && <br />}
          {getFederalLawTypeTitle(product.guarantee.federalLaw.code)}
          {product.guarantee.purchaseNumber && `: ${product.guarantee.purchaseNumber}`}
        </QueueTasksListItemProductColumnInfoWrapper>
      </QueueTasksListItemProductColumn>
    );
  }

  if (product.creditExecution) {
    return (
      <QueueTasksListItemProductColumn
        id={product.groupProductName ?? product.demandId}
        dateAdded={product.dateAdded}
        title={product.title}
        clientTaxpayerNumber={product.creditExecution.clientTaxpayerNumber}
        clientName={product.creditExecution.clientName}
        bankName={product.creditExecution.bankName}
      >
        <QueueTasksListItemProductColumnInfoWrapper>
          {getQueueTasksListItemSumTitle(product.creditExecution.sum, "Сумма контракта")}
          {product.creditExecution.sum !== undefined && <br />}
          {getFederalLawTypeTitle(product.creditExecution.federalLaw.code)}
          {product.creditExecution.purchaseNumber && `: ${product.creditExecution.purchaseNumber}`}
        </QueueTasksListItemProductColumnInfoWrapper>
      </QueueTasksListItemProductColumn>
    );
  }

  if (product.creditWorkingAssets) {
    return (
      <QueueTasksListItemProductColumn
        id={product.groupProductName ?? product.productId}
        dateAdded={product.dateAdded}
        title={product.title}
        clientTaxpayerNumber={product.creditWorkingAssets.clientTaxpayerNumber}
        clientName={product.creditWorkingAssets.clientName}
        bankName={product.creditWorkingAssets.bankName}
      >
        <QueueTasksListItemProductColumnInfoWrapper>
          {getQueueTasksListItemSumTitle(product.creditWorkingAssets.sum, "Сумма")}
        </QueueTasksListItemProductColumnInfoWrapper>
      </QueueTasksListItemProductColumn>
    );
  }

  if (product.productType.code === "AgencyRegistration") {
    return (
      <ProductListItemColumn>
        <ProductListItemColumnTitle>{product.productType.title}</ProductListItemColumnTitle>
      </ProductListItemColumn>
    );
  }

  return <ProductListItemColumn />;
};
