import { LoggerProvider } from "../../types/LoggerProvider";
import { loggingSentryConfigs } from "./configs";
import { loggingSentryService } from "./services/loggingSentryService";

export { loggingSentryInitService } from "./services/loggingSentryInitService";

export const loggingSentryProvider: LoggerProvider = {
  isEnabled: loggingSentryConfigs.isEnabled,
  logger: loggingSentryService,
};
