import { makeEnumLikeLiteral } from "../utils";

const companyTypes = ["Legal", "Individual", "Personal"] as const;

const titlesShort = {
  Legal: "Юр. лицо",
  Individual: "ИП",
  Personal: "Физ.лицо",
};

export type CompanyType = (typeof companyTypes)[number];

export const companyTypeWithShortTitle = makeEnumLikeLiteral(companyTypes, titlesShort);
