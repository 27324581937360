import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../store/utils";
import { LoadingStatusEnum } from "../../types/state";
import { AuthenticationUserService, AuthenticationUserTokenDetails } from "../../authentication";
import { AuthorizedUserState } from "./authorizedUser.types";

export const AUTHORIZED_USER_SLICE_NAME = combineSliceNames("authorizedUser");

const authenticationUserService = new AuthenticationUserService();

const initialState: AuthorizedUserState = {
  isAuthenticated: authenticationUserService.isAuthenticated(),
  status: LoadingStatusEnum.Idle,
  user: authenticationUserService.getUser(),
};

const slice = createSlice({
  name: AUTHORIZED_USER_SLICE_NAME,
  initialState,
  reducers: {
    authorizedUserRemoved(state) {
      state.isAuthenticated = false;
      state.user = undefined;
    },
    authorizedUserAuthenticated(state, { payload }: PayloadAction<AuthenticationUserTokenDetails>) {
      state.isAuthenticated = true;
      state.user = payload;
    },
  },
});

export const { authorizedUserRemoved, authorizedUserAuthenticated } = slice.actions;

export const authorizedUserReducer = slice.reducer;
