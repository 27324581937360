import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useCreditExecutionListFilterContext } from "../../../../hooks/filters/useCreditExecutionListFilterContext";
import { useCreditExecutionListFilterApiContext } from "../../../../hooks/filters/useCreditExecutionListFilterApiContext";
import { useAppSelector } from "../../../../../../common/store";
import { selectCreditExecutionFiltersData } from "../../../../store/filters/creditExecutionListFilters.selectors";
import { FilterSelect } from "../../../../../../common/ui-kit/form-controls/select";
import { UNSELECTED_ENUM_LITERAL } from "../../../../../../common/types/utils";

export type CreditExecutionListHeaderFiltersStatusDesktopProps = BaseCssProps;

export const CreditExecutionListHeaderFiltersStatusDesktop = ({
  className,
}: CreditExecutionListHeaderFiltersStatusDesktopProps) => {
  const filteringObject = useCreditExecutionListFilterContext();
  const mutator = useCreditExecutionListFilterApiContext();

  const data = useAppSelector(selectCreditExecutionFiltersData);
  const getTitleFunc = (item: string) => data?.statuses.titles[item] ?? item;

  return (
    <FilterSelect
      className={className}
      text="Статус"
      selected={filteringObject.status ?? UNSELECTED_ENUM_LITERAL}
      getItemTitle={getTitleFunc}
      onSelectedChanged={(value) => {
        mutator(filteringObject, (filter, service) => service.setValue(filter, "status", value));
      }}
      items={data?.statuses.ids ?? []}
      maxItemsLength={data?.statuses.maxTitleLength}
    />
  );
};
