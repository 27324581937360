import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { SpacesProps } from "@sideg/ui-kit/atoms/containers/types/SpacesProps";
import { ContainerCommonProps } from "@sideg/ui-kit/atoms/containers/types/ContainerCommonProps";
import { getContainerCommonPropsStyleBuilders } from "@sideg/ui-kit/atoms/containers/utils/getContainerCommonPropsStyleBuilders";
import { getSpacesPropsStyleBuilders } from "@sideg/ui-kit/atoms/containers/utils/getSpacesPropsStyleBuilders";
import { getFlexboxPropsStyleBuilders } from "@sideg/ui-kit/atoms/containers/utils/getFlexboxPropsStyleBuilders";
import { FlexPropsWithPrefix, FlexRestProps } from "@sideg/ui-kit/atoms/containers/types/FlexboxProps";

export interface BoxProps extends FlexPropsWithPrefix, FlexRestProps, SpacesProps, ContainerCommonProps {}

export const Box = styled("div", {
  shouldForwardProp: isPropValid,
})<BoxProps>(
  ...getContainerCommonPropsStyleBuilders(),
  ...getSpacesPropsStyleBuilders(),
  getFlexboxPropsStyleBuilders("prefixed"),
);
