import { ElementType, memo, ReactNode } from "react";
import { CommonButtonIcon } from "./CommonButton";

export interface CommonButtonChildrenWithIconsProps {
  startIcon?: ElementType;
  endIcon?: ElementType;
  children?: ReactNode;
}

export const CommonButtonChildrenWithIcons = memo(
  ({ children, startIcon, endIcon }: CommonButtonChildrenWithIconsProps) => {
    return (
      <>
        {startIcon && <CommonButtonIcon as={startIcon} />}
        {children}
        {endIcon && <CommonButtonIcon as={endIcon} />}
      </>
    );
  }
);
