import styled from "@emotion/styled";

export const Button = styled.button<{ isActive?: boolean }>`
  border: none;
  width: 6px;
  height: 6px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  background: ${({ isActive, theme }) =>
    isActive ? theme.layout.sidebar.menu.linkColor : theme.layout.sidebar.backgroundColor};
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.layout.sidebar.menu.linkColor};
  }
`;
