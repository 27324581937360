import styles from './InfoBanner.module.css'

export enum InfoBannerStyle {
    Default = 'Default',
    Error = 'Error',
    Success = 'Success',
    Blue = 'Blue',
    Light = 'Light'
}

interface IInfoBannerProps {
    text: string
    style?: InfoBannerStyle | null
    fontSize?: number
}

const InfoBanner: React.FC<IInfoBannerProps> = ({ text, style = null, fontSize = 14 }) => {
    const getBannerClass = (style: InfoBannerStyle | null): string => {
        switch(style) {
            case InfoBannerStyle.Default:
                return styles.infoBanner + ' ' + styles.defaultStyle
            case InfoBannerStyle.Error:
                return styles.infoBanner + ' ' + styles.errorStyle
            case InfoBannerStyle.Success:
                return styles.infoBanner + ' ' + styles.successStyle
            case InfoBannerStyle.Blue:
                return styles.infoBanner + ' ' + styles.blueStyle
            case InfoBannerStyle.Light:
                return styles.infoBanner + ' ' + styles.lightStyle
            default:
                return styles.infoBanner + ' ' + styles.defaultStyle
        }
        return ''
    }

    const bannerClass = getBannerClass(style)

    const infoBannerStyle = {
        fontSize: `${fontSize}px`
    }

    return (
        <p className={bannerClass} style={infoBannerStyle}>
            {text}
        </p>
    )
}

export default InfoBanner
