import { DocumentTypeId } from "../../../../common/types/documents";
import { useCreditWorkingAssetsDetailsDocumentsHandleDrop } from "../../hooks/documents/useCreditWorkingAssetsDetailsDocumentsHandleDrop";
import { ProductDetailsDocumentsDocumentItem } from "../../../../common/product-details";

export interface CreditWorkingAssetsDetailsDocumentsItemProps {
  documentId: DocumentTypeId;
}

export const CreditWorkingAssetsDetailsDocumentsItem = ({
  documentId,
}: CreditWorkingAssetsDetailsDocumentsItemProps) => {
  const { document, status, error, handleDrop } = useCreditWorkingAssetsDetailsDocumentsHandleDrop(documentId);

  return (
    <ProductDetailsDocumentsDocumentItem document={document} handleDrop={handleDrop} error={error} status={status} />
  );
};
