import { ThemeColor } from "@sideg/ui-kit/core/theme/types/colors/ThemeColor";
import { Theme } from "@sideg/ui-kit/core/theme";

export interface InputControlColors {
  border: ThemeColor;
  background: ThemeColor;
  text: ThemeColor;
}

export const getInputBoxPalette = (
  theme: Theme,
  error: boolean | undefined,
  disabled: boolean | undefined,
  focus: boolean | undefined,
  hover: boolean | undefined,
): InputControlColors => {
  if (disabled) {
    return {
      background: theme.palette.light.neutral.bgr.secondary,
      text: theme.palette.light.neutral.text.tertiary,
      border: theme.palette.light.neutral.outline.secondary,
    };
  }

  if (focus) {
    if (error) {
      return {
        background: theme.palette.light.neutral.bgr.primary,
        text: theme.palette.light.neutral.text.primary,
        border: theme.palette.light.signal.text.error,
      };
    }

    return {
      background: theme.palette.light.neutral.bgr.primary,
      text: theme.palette.light.neutral.text.primary,
      border: theme.palette.light.primary.text.default,
    };
  }

  if (error) {
    return {
      background: theme.palette.light.neutral.bgr.primary,
      text: theme.palette.light.neutral.text.primary,
      border: theme.palette.light.signal.text.error,
    };
  }

  if (hover) {
    return {
      background: theme.palette.light.primary.tonal.hover,
      text: theme.palette.light.neutral.text.primary,
      border: theme.palette.light.primary.tonal.hover,
    };
  }

  return {
    background: theme.palette.light.neutral.tonal,
    text: theme.palette.light.neutral.text.primary,
    border: theme.palette.light.neutral.tonal,
  };
};
