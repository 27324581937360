import { useState } from "react";
import * as S from "./SetPwa.sidebar.styled";
import { BrandIcon, Icon } from "../../ui-kit/icons";
import { SetPwaPortalWrapper } from "../portal/SetPwaPortalWrapper";
import { BaseCssProps } from "../../ui-kit/types";

type SetPwaSidebarProps = BaseCssProps;

export const SetPwaSidebar = ({ className }: SetPwaSidebarProps) => {
  const [isOpenPortal, setIsOpenPortal] = useState(false);

  return (
    <>
      <S.Container onClick={() => setIsOpenPortal(true)} className={className}>
        <S.Text>Установить мобильную версию </S.Text>
        <Icon as={BrandIcon} width={32} height={32} />
      </S.Container>
      {isOpenPortal && <SetPwaPortalWrapper onClose={() => setIsOpenPortal(false)} />}
    </>
  );
};
