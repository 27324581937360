import { ReactNode } from "react";
import { Typography } from "../../../../common/ui-kit/typography";
import { Box } from "../../../../common/ui-kit/containers/box";
import * as S from "./LegalServicesCreationCard.styled";

export interface LegalServicesCreationCardProps {
  title: ReactNode;
  description: ReactNode;
  price: ReactNode;
  addNote: ReactNode;
  linkButton: ReactNode;
}

export const LegalServicesCreationCard = ({
  title,
  description,
  price,
  addNote,
  linkButton,
}: LegalServicesCreationCardProps) => {
  return (
    <S.Container>
      <Box as="div" display="flex" flexDirection="column" gap="xs" flexGrow={1}>
        <Typography fontColor="typography.primary" fontSize={1.25} lineHeight="1.75rem" fontWeight={700}>
          {title}
        </Typography>
        {description}
      </Box>
      <S.PriceBlock as="div" display="flex" gap="xs" flexDirection="column" alignItems="flex-start">
        <Typography fontSize={1.25} lineHeight="1.75rem" fontColor="status.warning" fontWeight={500}>
          {price}
        </Typography>
        <S.Note fontColor="typography.primaryCaption" fontSize="fz2" lineHeight="1.25rem">
          {addNote}
        </S.Note>
        {linkButton}
      </S.PriceBlock>
    </S.Container>
  );
};
