import { getEmptyArray } from "@sideg/helpers";
import { useAppSelector } from "../../../../common/store";
import { useCreateNavigationStateFrom } from "../../../../common/navigation/navigation-state";
import { AppLayoutResponsiveListPage } from "../../../../common/ui/layout/app-layout-containers";
import {
  selectLegalServicesCreation,
  selectLegalServicesCreationIds,
} from "../../store/legalServicesCreation.selectors";
import { LegalServicesCreationItem } from "./item/LegalServicesCreationItem";
import { LegalServicesCreationSkeletonItem } from "./item/LegalServicesCreationItem.skeleton";
import { useIsLoadingWithoutEntities } from "../../../../common/hooks";

const SKELETON_ITEMS_COUNT = 10;
const SKELETON_ITEMS = getEmptyArray(SKELETON_ITEMS_COUNT);

export const LegalServicesCreationItems = () => {
  const ids = useAppSelector(selectLegalServicesCreationIds);
  const { status } = useAppSelector(selectLegalServicesCreation);
  const isLoadingWithoutEntities = useIsLoadingWithoutEntities(status, ids);

  const from = useCreateNavigationStateFrom("Юридические услуги");

  return (
    <AppLayoutResponsiveListPage.List>
      {isLoadingWithoutEntities
        ? SKELETON_ITEMS.map((x) => <LegalServicesCreationSkeletonItem key={x} />)
        : ids.map((x) => <LegalServicesCreationItem productId={x} key={x} from={from} />)}
    </AppLayoutResponsiveListPage.List>
  );
};
