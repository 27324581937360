import { ElementColor } from "../../../common/ui-kit/types";
import { CreditExecutionDemandStageType } from "../../common/types";

export const getCreditExecutionDemandStageColorBankLabel = (stage: CreditExecutionDemandStageType): ElementColor => {
  switch (stage) {
    case "AttachingDocuments":
      return "typography.primaryCaption";
    case "Confirmed":
      return "typography.successSecondary";
    case "Rejected":
      return "typography.medium";
    default:
      return "typography.primary";
  }
};
