import { useMemo } from "react";
import { useAppDispatch } from "../../../../common/store";
import { DemandId } from "../../../../common/types/demand";
import { pushSuccessGlobalToast } from "../../../../global/toasts";
import { bankGuaranteeDetailsReloadActiveDemand } from "../../store/bankGuaranteeDetails.actions";
import { bankGuaranteeDetailsDemandReconciliationConfirmPaymentConfigs } from "../../configs/bankGuaranteeDetailsDemandReconciliationConfirmPaymentConfigs";
import { useAppLayoutModalOrOverlayPanelState } from "../../../../common/ui/layout/app-layout-containers";

export const useBankGuaranteeDetailsDemandReconciliationConfirmPayment = (demandId: DemandId) => {
  const dispatch = useAppDispatch();
  const panelKey = bankGuaranteeDetailsDemandReconciliationConfirmPaymentConfigs.confirmPaymentOverlayPanel;

  const [isModalOpen, setIsModalOpen] = useAppLayoutModalOrOverlayPanelState(panelKey);

  return useMemo(() => {
    const closeModal = () => setIsModalOpen(false);
    const openModal = () => setIsModalOpen(true);

    const afterSuccess = async (): Promise<void> => {
      closeModal();
      dispatch(pushSuccessGlobalToast("Платежное поручение отправлено"));
      dispatch(bankGuaranteeDetailsReloadActiveDemand(demandId));
    };

    return { isModalOpen, openModal, closeModal, afterSuccess, panelKey };
  }, [demandId, dispatch, isModalOpen, panelKey, setIsModalOpen]);
};
