import { ReactNode } from "react";
import { CSSObject } from "@emotion/styled";
import { BaseCssProps, FlexboxProps, ResponsiveStyleValue, SpaceType } from "../../../types";
import { Box } from "../../box";
import { BadgeVariant } from "../types/BadgeVariant";
import { getBadgeBackgroundColor } from "../utils/getBadgeBackgroundColor";

export interface BadgeProps extends BaseCssProps, FlexboxProps {
  variant: BadgeVariant;
  px?: ResponsiveStyleValue<SpaceType>;
  py?: ResponsiveStyleValue<SpaceType>;
  borderRadius?: ResponsiveStyleValue<CSSObject["borderRadius"]>;
  children: ReactNode;
}

export const Badge = ({
  variant,
  px = "xs",
  py = "sm",
  borderRadius = "4px",
  flexDirection = "row",
  alignItems = "center",
  gap = "sm",
  className,
  children,
  ...flexboxProps
}: BadgeProps) => {
  return (
    <Box
      className={className}
      display="flex"
      flexDirection={flexDirection}
      alignItems={alignItems}
      gap={gap}
      px={px}
      py={py}
      borderRadius={borderRadius}
      backgroundColor={getBadgeBackgroundColor(variant)}
      {...flexboxProps}
    >
      {children}
    </Box>
  );
};
