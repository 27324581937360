import { makeButtonElement } from "./utils/makeButtonElement";
import { MakeButtonProps } from "./types/ButtonProps";

export { BaseButton } from "./components/common/BaseButton";

export type ButtonProps = MakeButtonProps<"button">;
export const Button = makeButtonElement("button");

export type LinkButtonProps = MakeButtonProps<"a">;
export const LinkButton = makeButtonElement("a");

export type { ButtonLoadingProps } from "./components/loading/ButtonLoading";
export { ButtonLoading } from "./components/loading/ButtonLoading";

export { withButtonStyles } from "./HOCs/withButtonStyles";
