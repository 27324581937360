import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { StackPanel } from "../../../../../common/ui-kit/containers/stack-panel";
import { BankGuaranteeCreationContractFinalSumField } from "../fields/BankGuaranteeCreationContractFinalSumField";
import { BankGuaranteeCreationContractPrepaidSumField } from "../fields/BankGuaranteeCreationContractPrepaidSumField";
import { BankGuaranteeCreationEnforcementSumField } from "../fields/BankGuaranteeCreationEnforcementSumField";
import { useBankGuaranteeCreationForm } from "../../../hooks/creation/useBankGuaranteeCreationForm";
import { useBankGuaranteeCreationFieldHelpers } from "../../../hooks/creation/fields/useBankGuaranteeCreationFieldHelpers";
import { useGetFormFieldError } from "../../../../../common/form";
import { BankGuaranteeCreationFromValues } from "../../../types/BankGuaranteeCreationFormValues";

export interface BankGuaranteeCreationCostBlockProps {
  isDisabled?: boolean;
}

export const BankGuaranteeCreationCostBlock = ({ isDisabled }: BankGuaranteeCreationCostBlockProps) => {
  const form = useBankGuaranteeCreationForm();
  const { getFieldName } = useBankGuaranteeCreationFieldHelpers();
  const getFieldError = useGetFormFieldError<BankGuaranteeCreationFromValues>();

  return (
    <CreationBlockWithTitle title="Цена контракта">
      <StackPanel direction="column" gap="md">
        <FormInputPrice
          autoComplete="off"
          label="Начальная цена контракта, ₽"
          size="medium"
          {...form.getFieldProps(getFieldName("contractFirstSum"))}
          error={getFieldError(form, "contractFirstSum")}
          disabled={isDisabled}
        />
        <BankGuaranteeCreationContractFinalSumField disabled={isDisabled} />
        <BankGuaranteeCreationContractPrepaidSumField disabled={isDisabled} />
        <BankGuaranteeCreationEnforcementSumField disabled={isDisabled} />
      </StackPanel>
    </CreationBlockWithTitle>
  );
};
