import * as S from "./BankGuaranteeDocumentsListGroup.styled";
import { Text } from "../../../../common/ui-kit/typography";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { BankGuaranteeDocumentsUniqueId } from "../../types/BankGuaranteeDocumentsUniqueId";
import { BankGuaranteeDocumentsListHeaderDesktop } from "./BankGuaranteeDocumentsListHeader.desktop";
import { BankGuaranteeDocumentsListItemDesktop } from "./BankGuaranteeDocumentsListItem.desktop";
import { StackPanel } from "../../../../common/ui-kit/containers/stack-panel";

export interface BankGuaranteeDocumentsListGroupProps extends BaseCssProps {
  title: string;
  ids: BankGuaranteeDocumentsUniqueId[];
}

export const BankGuaranteeDocumentsListGroup = ({ title, ids }: BankGuaranteeDocumentsListGroupProps) => {
  return (
    <StackPanel direction="column" gap="md">
      <Text fontWeight={500} fontSize={1.5} as="h4" lineHeight="defaultLineHeight">
        {title}
      </Text>
      <S.Container>
        <BankGuaranteeDocumentsListHeaderDesktop />
        {ids.map((x) => (
          <BankGuaranteeDocumentsListItemDesktop id={x} key={x} />
        ))}
      </S.Container>
    </StackPanel>
  );
};
