import { createAppEntityAdapter } from "../../../../common/store/utils";
import { PublicUserRegistrationAgentDocument } from "../../types/agent/PublicUserRegistrationAgentDocument";
import { IssuingCompanyId } from "../../../../common/types/companies";

export const publicUserRegistrationAgentAdapter = createAppEntityAdapter<
  PublicUserRegistrationAgentDocument,
  IssuingCompanyId
>({
  selectId: (x) => x.issuingCompanyId,
});
