import { createSlice, isAsyncThunkAction } from "@reduxjs/toolkit";
import { getLoadingStatusFromAction, LoadingStatusEnum } from "../../../common/types/state";
import { chatDialogAdapter } from "./chatDialog.adapter";
import { ChatDialogSliceState } from "./chatDialog.types";
import { CHAT_DIALOG_SLICE_NAME, getMessagesChatDialog } from "./chatDialog.thunks";
import { ChatDialogMessage } from "../types/ChatDialogMessage";
import { isNewMessage } from "../utils/isNewMessage";

const initialState = chatDialogAdapter.getInitialState<ChatDialogSliceState>({
  status: LoadingStatusEnum.Idle,
  hasNewMessage: false,
});

const slice = createSlice({
  name: CHAT_DIALOG_SLICE_NAME,
  initialState,
  reducers: {
    update: () => initialState,
    newMessageReceived: (state, { payload }: { payload: ChatDialogMessage }) => {
      chatDialogAdapter.upsertOne(state, payload);
      state.hasNewMessage = state.hasNewMessage || isNewMessage(payload);
    },
    markMessagesRead: (state) => {
      chatDialogAdapter.updateMany(
        state,
        state.ids.map((x) => ({ id: x, changes: { isNew: false } })),
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessagesChatDialog.fulfilled, (state, action) => {
        chatDialogAdapter.upsertMany(state, action.payload.body.data);
        state.pagination = action.payload.body.paginationInformation;

        if (!state.hasNewMessage) {
          state.hasNewMessage = action.payload.body.data.some((x) => isNewMessage(x));
        }
      })
      .addMatcher(isAsyncThunkAction(getMessagesChatDialog), (state, action) => {
        state.status = getLoadingStatusFromAction(action);
      });
  },
});

export const {
  update: chatDialogWaitingUpdate,
  newMessageReceived: chatDialogHubNewMessageReceived,
  markMessagesRead: chatDialogHubMarkMessagesRead,
} = slice.actions;

export const chatDialogReducer = slice.reducer;
