import { useMemo } from "react";
import { useAppDispatch } from "../../../common/store";
import { makeProductRejectionOverlayPanelKey, useProductRejectionModalPanel } from "../../../common/product-rejection";
import { pushSuccessGlobalToast } from "../../../global/toasts";
import { creditWorkingAssetsDetailsReloadActiveProduct } from "../store/creditWorkingAssetsDetails.actions";
import { CreditWorkingAssetsId, DemandId } from "../../../common/types/demand";

export const useCreditWorkingAssetsDetailsRejection = (
  creditWorkingAssetsId: CreditWorkingAssetsId,
  demandId?: DemandId
) => {
  const dispatch = useAppDispatch();
  const panelKey = makeProductRejectionOverlayPanelKey("CreditWorkingAssets", creditWorkingAssetsId, demandId);
  const [isModalOpen, setIsModalOpen] = useProductRejectionModalPanel(panelKey);

  return useMemo(() => {
    const closeModal = () => setIsModalOpen(false);
    const openModal = () => setIsModalOpen(true);

    const afterSuccess = async (): Promise<void> => {
      closeModal();
      dispatch(pushSuccessGlobalToast("Заявка в банк отменена"));
      dispatch(creditWorkingAssetsDetailsReloadActiveProduct());
    };

    return { isModalOpen, openModal, closeModal, afterSuccess, panelKey };
  }, [dispatch, isModalOpen, panelKey, setIsModalOpen]);
};
