import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { CreationBlockWithTitle } from "../../../../../common/ui-kit/cards/creation-block-card";
import { CreditExecutionBriefDescriptionList } from "../../../../common/brief/components/CreditExecutionBriefDescriptionList";
import { CreditExecutionBrief } from "../../../../common/brief/types/CreditExecutionBrief";

export interface CreditExecutionCreationScoringCreateByExistsBriefProps extends BaseCssProps {
  isLoading: boolean;
  data: CreditExecutionBrief | undefined;
}

export const CreditExecutionCreationScoringCreateByExistsBrief = ({
  isLoading,
  data,
  className,
}: CreditExecutionCreationScoringCreateByExistsBriefProps) => {
  return (
    <CreationBlockWithTitle title="Информация о заявке" className={className}>
      <CreditExecutionBriefDescriptionList isLoading={isLoading} data={data} isShowDateStartMovedToToday />
    </CreationBlockWithTitle>
  );
};
