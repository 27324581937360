import { ControlSize } from "@sideg/ui-kit/common/types";
import { ThemeTypographyVariantType } from "@sideg/ui-kit/core/theme/types/typography/ThemeTypography";

export const getSelectTextVariant = (size?: ControlSize): ThemeTypographyVariantType => {
  switch (size) {
    case "medium":
      return "controlsDesktop.inputs.large";
    case "default":
      return "controlsDesktop.inputs.medium";
    case "small":
      return "controlsDesktop.inputs.small";
    default:
      return "controlsDesktop.inputs.medium";
  }
};

export const getSelectSecondLineTextVariant = (size?: ControlSize): ThemeTypographyVariantType => {
  switch (size) {
    case "medium":
      return "controlsDesktop.inputs.signature";
    case "default":
      return "controlsDesktop.inputs.signature";
    case "small":
      return "controlsDesktop.popover.small";
    default:
      return "controlsDesktop.inputs.signature";
  }
};
