import { useEffect, useMemo, useState } from "react";
import { PopupButtonOptions, usePopupButton } from "../../../buttons/hooks/usePopupButton";
import { useDebounce } from "../../../../hooks/useDebounce";

export type UseTooltipEventType = "hover" | "click" | "hoverOrClick";

export interface UseTooltipOptions extends PopupButtonOptions {
  hoverDebounce?: number;
  type?: UseTooltipEventType;
}

export const useTooltip = ({
  position = "bottom",
  hoverDebounce = 1_000,
  offset = [0, 6],
  type = "hover",
}: UseTooltipOptions) => {
  const {
    state: [isOpened, setIsOpen],
    buttonProps,
    popupProps,
    arrow,
    placement,
  } = usePopupButton<HTMLDivElement>({ position, offset });

  const [isHover, setIsHover] = useState(false);
  const [debounceHover, setDebounceHover] = useDebounce(isHover, hoverDebounce);

  useEffect(() => {
    setIsOpen(debounceHover);
  }, [debounceHover, setIsOpen]);

  return useMemo(() => {
    const onClick =
      type !== "hover"
        ? () => {
            setIsOpen((prev) => !prev);
          }
        : undefined;

    const onMouseEnter =
      type !== "click"
        ? () => {
            setIsHover(true);
          }
        : undefined;

    const onMouseLeave =
      type !== "click"
        ? () => {
            setIsHover(false);
            setDebounceHover(false);
          }
        : undefined;

    return { popupProps, arrow, placement, isOpened, onClick, ref: buttonProps.ref, onMouseEnter, onMouseLeave };
  }, [arrow, placement, buttonProps.ref, isOpened, popupProps, setDebounceHover, setIsOpen, type]);
};
