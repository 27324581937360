import { useFormikContext } from "formik";
import { FormInputPrice } from "@sideg/ui-kit/atoms/form-controls/price-input";
import { useCreationFormFieldTouchedEffect, useGetFieldError, useGetFieldName } from "../../../../common/form";
import { useAppSelector } from "../../../../common/store";
import { CreationBlockWithTitle } from "../../../../common/ui-kit/cards/creation-block-card";
import { FullPageContainerCenteredMainWithFooter } from "../../../../common/ui/containers/full-page";
import { useCreditExecutionCreationCreatingFormStage } from "../../hooks/creation/useCreditExecutionCreationCreatingFormStage";
import {
  selectCreditExecutionCreation,
  selectCreditExecutionCreationClientSearch,
  selectCreditExecutionCreationIsPurchaseStatusFetching,
} from "../../store/creditExecutionCreation.selectors";
import { CreditExecutionCreationFormValues } from "../../types/creation/CreditExecutionCreationFormValues";
import { CreditExecutionCreationFormTermBlock } from "./blocks/CreditExecutionCreationFormTermBlock";
import { CreationSubmitButton } from "../../../../common/ui/buttons/creation-submit-button";
import { isLoading } from "../../../../common/types/state";
import { ClientSearchCreationClientBlock } from "../../../../client-search";
import { CreditExecutionCreationContractBlock } from "./blocks/CreditExecutionCreationContractBlock";
import { CreditExecutionCreationBeneficiaryBlock } from "./blocks/CreditExecutionCreationBeneficiaryBlock";

export const CreditExecutionCreationFormContent = () => {
  const stage = useCreditExecutionCreationCreatingFormStage();

  const {
    creating: { error, status },
  } = useAppSelector(selectCreditExecutionCreation);

  const isPurchaseFetching = useAppSelector(selectCreditExecutionCreationIsPurchaseStatusFetching);

  const form = useFormikContext<CreditExecutionCreationFormValues>();

  const getFieldName = useGetFieldName<CreditExecutionCreationFormValues>();
  const getFieldError = useGetFieldError<CreditExecutionCreationFormValues>();

  useCreationFormFieldTouchedEffect();

  return (
    <FullPageContainerCenteredMainWithFooter
      footer={
        stage === "terms" && (
          <CreationSubmitButton type="submit" isLoading={isLoading(status)} disabled={form.isSubmitting} error={error}>
            Перейти к выбору банков
          </CreationSubmitButton>
        )
      }
    >
      <ClientSearchCreationClientBlock<CreditExecutionCreationFormValues>
        clientStateSelector={selectCreditExecutionCreationClientSearch}
        fixedKey="CreditExecution"
        isDisabled={form.isSubmitting}
      />
      {stage === "terms" && (
        <>
          <CreditExecutionCreationContractBlock
            isDisabled={isPurchaseFetching || form.isSubmitting}
            isPurchaseNumberDisabled={form.isSubmitting}
          />
          <CreditExecutionCreationBeneficiaryBlock isDisabled={isPurchaseFetching || form.isSubmitting} />
          <CreationBlockWithTitle title="Сумма контракта">
            <FormInputPrice
              autoComplete="off"
              label="Сумма контракта, ₽"
              size="medium"
              {...form.getFieldProps(getFieldName("creditSum"))}
              error={getFieldError("creditSum", form.touched.creditSum, form.errors.creditSum)}
              disabled={isPurchaseFetching || form.isSubmitting}
            />
          </CreationBlockWithTitle>
          <CreditExecutionCreationFormTermBlock isDisabled={isPurchaseFetching || form.isSubmitting} />
        </>
      )}
    </FullPageContainerCenteredMainWithFooter>
  );
};
