import styled from "@emotion/styled";
import { Fragment } from "react";
import { colorWithAlpha } from "../../../../../ui-kit/utils";
import { AppExternalLink } from "../../../../../urls/external";
import { AppLink } from "../../../../../urls/internal/components";

export const Footer = styled.footer`
  padding: ${({ theme }) => theme.spaces.lg};
  border-top: 1px solid ${({ theme }) => colorWithAlpha(theme.palette.background.light, Math.round(255 * 0.12))};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.md};
  font-size: ${({ theme }) => theme.font.fz1};
  font-weight: 500;
  flex-grow: 0;
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spaces.xxl};
  }
`;

export const LinksContainer = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  text-align: center;

  ${({ theme }) => theme.breakpoints.mqUpDesktop()} {
    display: inline-flex;
    flex-direction: row;

    & > * + *:not(:empty)::before {
      content: "•";
      display: inline-block;
      margin: 0 ${({ theme }) => theme.spaces.sm};
    }
  }
`;

const CommonLink = styled(Fragment)`
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &:focus-visible {
    color: ${({ theme }) => theme.palette.typography.primaryLight};
  }
`;

export const ExternalLink = CommonLink.withComponent(AppExternalLink);

export const InternalLink = CommonLink.withComponent(AppLink);
