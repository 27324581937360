import { useMemo } from "react";
import { useGetFieldError, useGetFieldName } from "../../../../../common/form";
import { BankGuaranteeCreationFromValues } from "../../../types/BankGuaranteeCreationFormValues";

export const useBankGuaranteeCreationFieldHelpers = () => {
  const getFieldName = useGetFieldName<BankGuaranteeCreationFromValues>();
  const getFieldError = useGetFieldError<BankGuaranteeCreationFromValues>();

  return useMemo(() => ({ getFieldError, getFieldName }), [getFieldError, getFieldName]);
};
