import { GetFiltrationParametersCreditWorkingAssetsListOutputDto } from "../../api/dto/output/GetFiltrationParametersCreditWorkingAssetsListOutputDto";
import { CreditWorkingAssetsListFilters } from "../../types/filters/CreditWorkingAssetsListFilters";
import { getEntitiesCodeWithTitle } from "../../../../common/types/objects";

const mapFilters = ({
  bankIds,
  taskTypes,
  statuses,
}: GetFiltrationParametersCreditWorkingAssetsListOutputDto): CreditWorkingAssetsListFilters => {
  return {
    bankIds: getEntitiesCodeWithTitle(bankIds),
    statuses: getEntitiesCodeWithTitle(statuses),
    taskTypes: getEntitiesCodeWithTitle(taskTypes),
  };
};

export const creditWorkingAssetsListFiltersMapper = { mapFilters };
