import {
  AppPublicLayoutBlockWrapper,
  AppPublicLayoutLinkButton,
} from "../../../common/ui/layout/app-public-layout-containers";
import { usePageTitle } from "../../../common/navigation/page-title";
import { PublicUserAuthenticationCard } from "../components/login/PublicUserAuthenticationCard";

export const PublicUserAuthenticationLoginPage = () => {
  usePageTitle("Вход в личный кабинет");

  return (
    <AppPublicLayoutBlockWrapper>
      <PublicUserAuthenticationCard />
      <AppPublicLayoutLinkButton to={(x) => x.publicUser.registration.new}>
        Стать клиентом или агентом
      </AppPublicLayoutLinkButton>
    </AppPublicLayoutBlockWrapper>
  );
};
