import { useCallback } from "react";
import { PaginatedFilteringObjectType } from "../types/PaginatedFilteringObjectType";
import { FilteringObjectValue } from "../types/FilteringObjectValue";
import { usePaginatedQueryStateMutator } from "../use-cases";
import { FilteringArrayKeys } from "../types/FilteringArrayKeys";
import { FilteringItem } from "../types/FilteringItem";

export type UseFilteringItemsConverterResult<TFilter extends PaginatedFilteringObjectType> = (
  filteringObject: TFilter
) => FilteringItem<TFilter>[];

export const useFilteringItemsConverter = <TFilter extends PaginatedFilteringObjectType>(
  getTitle: <TKey extends keyof TFilter>(
    key: TKey,
    value: FilteringObjectValue<TFilter, TKey>,
    context: TFilter
  ) => string | null
): UseFilteringItemsConverterResult<TFilter> => {
  const mutator = usePaginatedQueryStateMutator();

  const removeItem = useCallback(
    (keyName: keyof TFilter) => (filtrationObject: TFilter) => {
      mutator(filtrationObject, (filter, { removeValue }) => removeValue(filter, keyName));
    },
    [mutator]
  );

  const removeArrayValue = useCallback(
    (keyName: FilteringArrayKeys<TFilter>, value: string) => (filtrationObject: TFilter) => {
      mutator(filtrationObject, (filter, service) => service.removeArrayValue(filtrationObject, keyName, value));
    },
    [mutator]
  );

  return useCallback(
    (filteringObject: TFilter): FilteringItem<TFilter>[] => {
      const isArrayKey = (key: keyof TFilter): key is FilteringArrayKeys<TFilter> => true;
      const keys: (keyof TFilter)[] = Object.keys(filteringObject);

      return keys
        .flatMap((key): FilteringItem<TFilter> | undefined | (FilteringItem<TFilter> | undefined)[] => {
          const value = filteringObject[key];

          if (value === undefined) {
            return undefined;
          }

          if (Array.isArray(value) && isArrayKey(key)) {
            return value.map((x: string): FilteringItem<TFilter> | undefined => {
              const text = getTitle(
                key,
                x as FilteringObjectValue<TFilter, FilteringArrayKeys<TFilter>>,
                filteringObject
              );

              return text !== null
                ? {
                    uniqKey: `${String(key)}__${x}`,
                    text,
                    onRemove: removeArrayValue(key, x),
                  }
                : undefined;
            });
          }

          const text = getTitle(key, value as FilteringObjectValue<TFilter, keyof TFilter>, filteringObject);
          if (text === null) {
            return undefined;
          }

          return {
            uniqKey: `${String(key)}__${value}`,
            text,
            onRemove: removeItem(key),
          };
        })
        .filter((x): x is FilteringItem<TFilter> => x !== undefined);
    },
    [getTitle, removeArrayValue, removeItem]
  );
};
