import { useEffect } from "react";
import { getCurrentVersionApplicationUpdater } from "../store/userApplicationUpdater.thunks";
import { useApiMutation } from "../../hooks";
import { useServiceWorkerUpdateApiContext } from "../../service-worker";
import { ApplicationUpdaterService } from "../services/ApplicationUpdaterService";
import { applicationUpdaterConfigs } from "../configs/applicationUpdaterConfigs";
import { AppVersion } from "../types/AppVersion";

const { appVersion, updatesCheckIntervalMs } = applicationUpdaterConfigs;
const applicationUpdaterService = new ApplicationUpdaterService(new AppVersion(appVersion));

export const useApplicationUpdater = () => {
  const { checkUpdate } = useServiceWorkerUpdateApiContext();
  const { mutate } = useApiMutation(getCurrentVersionApplicationUpdater, {
    errorMessagePlaceholder: "",
  });

  useEffect(() => {
    const fetch = async () => {
      const result = await mutate(undefined);
      if (applicationUpdaterService.isOtherVersion(result?.currentVersion)) {
        await checkUpdate({
          isNeedForceUpdate: applicationUpdaterService.isNeedForceUpdate(result?.forceUpdateIfVersionEqualOrLowerThan),
        });
      }
    };

    const interval = setInterval(fetch, updatesCheckIntervalMs);

    return () => clearInterval(interval);
  }, [checkUpdate, mutate]);
};
