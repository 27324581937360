import { DropzoneInputProps, DropzoneRootProps, useDropzone } from "react-dropzone";
import { useMemo } from "react";
import { FormFileDropInputVariant } from "../../../common/ui-kit/form-controls/file-input";
import { useDropzoneRejectionError } from "./useDropzoneRejectionError";
import { filesUploadConfigs } from "../configs/filesUploadConfigs";

export interface FileInputDropzoneOptions {
  isMultiple?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onDrop: (acceptedFiles: File[]) => void | Promise<void>;
  minFileSize?: number;
  maxFileSizeInMegabytes?: number;
}

export interface UseFileInputDropzoneResult {
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  variant: FormFileDropInputVariant;
  error?: string;
  isFocused?: boolean;
}

export const useFileInputDropzone = (options?: FileInputDropzoneOptions): UseFileInputDropzoneResult => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    onDrop: options?.onDrop,
    multiple: options?.isMultiple,
    disabled: options?.isDisabled,
    minSize: options?.minFileSize ?? filesUploadConfigs.minFileSizeInBytes,
    maxSize: filesUploadConfigs.megabytesToBytes(
      options?.maxFileSizeInMegabytes ?? filesUploadConfigs.maxFileSizeInMegabytes
    ),
  });

  const error = useDropzoneRejectionError(
    fileRejections,
    options?.maxFileSizeInMegabytes ?? filesUploadConfigs.maxFileSizeInMegabytes
  );

  const variant: FormFileDropInputVariant = useMemo(() => {
    if (isDragReject || error) {
      return "error";
    }

    if (options?.isLoading) {
      return "loading";
    }

    if (isDragActive) {
      return "dragging";
    }

    return "default";
  }, [error, isDragActive, isDragReject, options?.isLoading]);

  return useMemo(
    () => ({
      getRootProps,
      getInputProps,
      variant,
      error,
    }),
    [error, getInputProps, getRootProps, variant]
  );
};
