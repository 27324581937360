import SkeletonComponent from "react-loading-skeleton";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { CircleIndicator } from "../../../../../ui-kit/notifications/circle-indicator";
import { AppLink } from "../../../../../urls/internal/components";
import { ElementColor, getElementColor } from "../../../../../ui-kit/types";
import { makeShouldForwardProp } from "../../../../../ui-kit/utils";

const LINE_HEIGHT_PX = 27;

export interface StatusLinkProps {
  fontColor: ElementColor;
}

export const StatusLink = styled(AppLink, {
  shouldForwardProp: makeShouldForwardProp<StatusLinkProps>((propName) => {
    return propName !== "fontColor";
  }),
})<StatusLinkProps>(
  ({ theme, fontColor }) => css`
    text-decoration: none;
    font-weight: 500;
    font-size: ${theme.font.fz2};
    line-height: ${theme.font.defaultLineHeight};
    color: ${getElementColor(fontColor, theme)};
    display: flex;
    align-items: center;
    gap: ${theme.spaces.sm};

    &:hover,
    &:focus-visible {
      & > span:first-of-type {
        text-decoration: underline;
      }
    }

    & > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `
);

export const Li = styled.li`
  list-style: none;
  display: contents;
  height: ${LINE_HEIGHT_PX}px;
  counter-increment: section;
`;

export const Skeleton = styled(SkeletonComponent)`
  height: ${LINE_HEIGHT_PX}px;
`;

export const BankNameWithIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.xs};
`;

export const BankLink = styled(AppLink, {
  shouldForwardProp: makeShouldForwardProp<StatusLinkProps>((propName) => {
    return propName !== "fontColor" && propName !== "isCrossedOut";
  }),
})<{ fontColor: ElementColor; isCrossedOut: boolean }>`
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme, fontColor }) => getElementColor(fontColor, theme)};
  text-decoration: ${({ isCrossedOut }) => (isCrossedOut ? "line-through" : "none")};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const Indicator = styled(CircleIndicator)`
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Sum = styled.span<{ fontColor: ElementColor }>`
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  color: ${({ theme, fontColor }) => getElementColor(fontColor, theme)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusContainer = styled.div``;

export const DateLabel = styled.span`
  width: 7ch;
  color: ${({ theme }) => theme.palette.typography.medium};
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.fz2};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  font-feature-settings: "tnum" on, "lnum" on;
`;

export const RemoveButton = styled.div``;

export const Container = styled.ul`
  padding: 0;
  margin: 0;
  column-gap: ${({ theme }) => theme.spaces.md};
  row-gap: ${({ theme }) => theme.spaces.sm};
  display: grid;
  grid-template-columns: 18px 180px 120px minmax(0, 1fr) 6ch 27px;
  align-items: center;
  counter-reset: section;
  max-width: 100%;
  overflow: hidden;
`;
