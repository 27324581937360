import { currencyHelper } from "@sideg/helpers";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { BankGuaranteeDocumentsUniqueId } from "../../types/BankGuaranteeDocumentsUniqueId";
import { useAppSelector } from "../../../../common/store";
import { selectBankGuaranteeDocumentsItemById } from "../../store/bankGuaranteeDocuments.selectors";
import { bankGuaranteeDocumentsClientTaxSystemType } from "../../types/BankGuaranteeDocumentsClientTaxSystemType";
import { FileDownloadLabel } from "../../../../files/download";
import { Text } from "../../../../common/ui-kit/typography";
import { BankGuaranteeDocumentsListItemContainerDesktop } from "./BankGuaranteeDocumentsListItemContainer.desktop";

export interface BankGuaranteeDocumentsListItemDesktopProps extends BaseCssProps {
  id: BankGuaranteeDocumentsUniqueId;
}

export const BankGuaranteeDocumentsListItemDesktop = ({
  id,
  className,
}: BankGuaranteeDocumentsListItemDesktopProps) => {
  const document = useAppSelector((state) => selectBankGuaranteeDocumentsItemById(state, id));

  return (
    <BankGuaranteeDocumentsListItemContainerDesktop
      className={className}
      name={
        <Text fontWeight={400} fontSize="fz3" lineHeight="defaultLineHeight">
          {document?.name}
        </Text>
      }
      taxSystem={
        <Text fontWeight={400} fontSize="fz3" lineHeight="defaultLineHeight">
          {document?.taxSystems.map((x) => bankGuaranteeDocumentsClientTaxSystemType.getTitle(x)).join(", ")}
        </Text>
      }
      amounts={
        <>
          {document?.sumGuaranteeFrom !== undefined && (
            <Text as="div" fontWeight={400} fontSize="fz3" lineHeight="defaultLineHeight">
              от {currencyHelper.getCurrencyString(document.sumGuaranteeFrom)}
            </Text>
          )}
          {document?.sumGuaranteeTo !== undefined && (
            <Text as="div" fontWeight={400} fontSize="fz3" lineHeight="defaultLineHeight">
              до {currencyHelper.getCurrencyString(document.sumGuaranteeTo)}
            </Text>
          )}
        </>
      }
      template={
        document?.template && (
          <FileDownloadLabel
            fileId={document.template.fileId}
            privateKey={document.template.privateKey}
            fileName="Шаблон"
          />
        )
      }
    />
  );
};
