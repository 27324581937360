import { useField } from "formik";
import { useThemeIsDesktop } from "@sideg/ui-kit/helpers/responsive";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { BaseCssProps } from "../../ui-kit/types";
import { CreationNote } from "../../ui-kit/notifications/note";
import { isFailedOrAborted, isIdle, isLoading, isSucceeded } from "../../types/state";
import { SimpleOpacityTransition } from "../../ui-kit/animations/transitions";
import { AlertLoadingToResult } from "../../ui-kit/notifications/alert-with-indicator";
import { ButtonLink } from "../../ui-kit/buttons/ButtonLink";
import { Text } from "../../ui-kit/typography";
import { StackPanel } from "../../ui-kit/containers/stack-panel";
import { usePurchaseOnPaste } from "../hooks/usePurchasePasteLink";
import { PurchaseFixedKey } from "../types/PurchaseFixedKey";
import { useGetFieldError } from "../../form";
import { usePurchaseFetcher } from "../hooks/usePurchaseFetcher";
import { PurchaseStateSelector } from "../types/PurchaseStateSelector";
import { PurchaseDetails } from "../types/PurchaseDetails";

export interface PurchaseCreationFieldProps extends BaseCssProps {
  purchaseStateSelector: PurchaseStateSelector;
  fixedKey: PurchaseFixedKey;
  formMutator: (data: PurchaseDetails) => void;
  name: string;
  isDisabled?: boolean;
}

export const PurchaseCreationField = ({
  purchaseStateSelector,
  fixedKey,
  isDisabled,
  formMutator,
  name,
  className,
}: PurchaseCreationFieldProps) => {
  const isDesktop = useThemeIsDesktop();
  const getFieldError = useGetFieldError();
  const [{ value, onBlur, onChange }, { error, touched }] = useField<string>(name);

  const onPaste = usePurchaseOnPaste(name);
  const { status, abort } = usePurchaseFetcher(fixedKey, value, purchaseStateSelector, formMutator);

  return (
    <StackPanel className={className} direction="column" gap="sm">
      <FormInput
        type="text"
        label="Номер закупки"
        size="medium"
        error={getFieldError(name, touched, error)}
        enterKeyHint="search"
        spellCheck={false}
        autoComplete="off"
        autoCorrect="off"
        disabled={isDisabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onPaste={onPaste}
        value={value ?? ""}
      />
      <SimpleOpacityTransition timeout={250} in={!isIdle(status)} mountOnEnter unmountOnExit>
        <AlertLoadingToResult
          isLoading={isLoading(status)}
          loadingText={
            <Text fontSize="fz2">
              Ищем закупку в открытых источниках{isDesktop ? " • " : <br />}
              <ButtonLink type="button" onClick={abort}>
                Отменить поиск
              </ButtonLink>
            </Text>
          }
          isError={isFailedOrAborted(status)}
          errorText={
            <Text fontSize="fz2">
              Мы не нашли данную закупку. Если номер верный, то нужно заполнить остальные данные вручную ↓
            </Text>
          }
          isSuccess={isSucceeded(status)}
          successText={
            <Text fontSize="fz2">Определили закупку и подставили данные. Проверьте их и заполните оставшиеся</Text>
          }
        />
      </SimpleOpacityTransition>
      {isIdle(status) && (
        <CreationNote as="p" fontSize="fz2">
          Мы попробуем заполнить по номеру закупки остальные данные.
          {isDesktop && <br />} Если не получится — заполните их самостоятельно
        </CreationNote>
      )}
    </StackPanel>
  );
};
