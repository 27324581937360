import { createAction } from "@reduxjs/toolkit";
import { combineSliceNames } from "../../../common/store/utils";
import { CREDIT_EXECUTION_SLICE_NAME, CreditExecutionTransferPayload } from "../../common/store";
import { DemandId } from "../../../common/types/demand";

export const CREDIT_EXECUTION_DETAILS_SLICE_NAME = combineSliceNames(CREDIT_EXECUTION_SLICE_NAME, "details");

export const creditExecutionDetailsActiveProductChanged = createAction<CreditExecutionTransferPayload | undefined>(
  combineSliceNames(CREDIT_EXECUTION_DETAILS_SLICE_NAME, "activeProductChanged")
);

export const creditExecutionDetailsActiveDemandChanged = createAction<DemandId | undefined>(
  combineSliceNames(CREDIT_EXECUTION_DETAILS_SLICE_NAME, "activeDemandChanged")
);

export const creditExecutionDetailsReloadActiveDemand = createAction<DemandId | undefined>(
  combineSliceNames(CREDIT_EXECUTION_DETAILS_SLICE_NAME, "reloadActiveDemand")
);

export const creditExecutionDetailsReloadActiveProduct = createAction(
  combineSliceNames(CREDIT_EXECUTION_DETAILS_SLICE_NAME, "reloadActiveProduct")
);
