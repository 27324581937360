import { BaseCssProps } from "../../../../../../common/ui-kit/types";
import { useBankGuaranteeListFilterContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterContext";
import {
  selectBankGuaranteeListFilters,
  selectBankGuaranteeListFiltersMaxLength,
} from "../../../../store/filters/bankGuaranteeListFilters.selectors";
import { FilterSelect } from "../../../../../../common/ui-kit/form-controls/select";
import { useAppSelector } from "../../../../../../common/store";
import { useBankGuaranteeListFilterApiContext } from "../../../../hooks/filter-context/useBankGuaranteeListFilterApiContext";
import { UNSELECTED_ENUM_LITERAL } from "../../../../../../common/types/utils";

export type BankGuaranteeListFiltersStatusProps = BaseCssProps;

export const BankGuaranteeListFiltersStatus = ({ className }: BankGuaranteeListFiltersStatusProps) => {
  const filteringObject = useBankGuaranteeListFilterContext();
  const mutator = useBankGuaranteeListFilterApiContext();

  const { data: filters } = useAppSelector(selectBankGuaranteeListFilters);

  const getTitleFunc = (item: string) => filters?.statuses.titles[item] ?? item;
  const maxItemsLength = useAppSelector((state) => selectBankGuaranteeListFiltersMaxLength(state, "statuses"));

  return (
    <FilterSelect
      className={className}
      text="Статус"
      selected={filteringObject.status ?? UNSELECTED_ENUM_LITERAL}
      getItemTitle={getTitleFunc}
      onSelectedChanged={(value) => {
        mutator(filteringObject, (filter, service) => service.setValue(filter, "status", value));
      }}
      items={filters?.statuses?.ids ?? []}
      maxItemsLength={maxItemsLength}
    />
  );
};
