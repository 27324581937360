import { FormRadioButtonGroup } from "@sideg/ui-kit/atoms/checkbox";
import { bankGuaranteeCreationPurchaseType } from "../../../types/BankGuaranteeCreationPurchaseType";
import { useBankGuaranteeCreationPurchaseTypeField } from "../../../hooks/creation/fields/useBankGuaranteeCreationPurchaseTypeField";

export interface BankGuaranteeCreationPurchaseTypeFieldProps {
  disabled?: boolean;
}

export const BankGuaranteeCreationPurchaseTypeField = ({ disabled }: BankGuaranteeCreationPurchaseTypeFieldProps) => {
  const { onBlur, isDisabled, onChange, isChecked } = useBankGuaranteeCreationPurchaseTypeField();

  return (
    <FormRadioButtonGroup label="Закупка" itemsPlacement="start" disabled={disabled}>
      {bankGuaranteeCreationPurchaseType.values.map((x) => (
        <FormRadioButtonGroup.Option
          key={x}
          value={x}
          label={bankGuaranteeCreationPurchaseType.getTitle(x)}
          radioSize="default"
          isSelected={isChecked(x)}
          checked={isChecked(x)}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled(x)}
        />
      ))}
    </FormRadioButtonGroup>
  );
};
