import { useState } from "react";
import { ThemeMobileDesktopView } from "@sideg/ui-kit/helpers/responsive";
import { CreditWorkingAssetsCreationCreateByExistsUrlParams } from "../types/creation/CreditWorkingAssetsCreationCreateByExistsUrlParams";
import { withValidUrlParams } from "../../../common/urls/internal/HOCs";
import { creditWorkingAssetsCreationCreateByExistsUrlValidationSchema } from "../validation/creditWorkingAssetsCreationCreateByExistsUrlValidationSchema";
import { Redirect } from "../../../common/urls/internal/components";
import {
  ProductCreationCreateByExistsBriefContainer,
  ProductCreationCreateByExistsMainContentWrapper,
} from "../../../common/product-creation";
import { FullPageContainerMainContentWrapper } from "../../../common/ui/containers/full-page";
import { CreditWorkingAssetsCreationScoringCreateByExistsForm } from "../components/scoring/create-by-exists/CreditWorkingAssetsCreationScoringCreateByExistsForm";
import { CreditWorkingAssetsCreationCreateByExistsHeader } from "../components/header/create-by-exists/CreditWorkingAssetsCreationCreateByExistsHeader";
import { CreditWorkingAssetsCreationCreateByExistsPageType } from "../types/creation/CreditWorkingAssetsCreationCreateByExistsPageType";
import { useCreditWorkingAssetsCreationScoringBriefData } from "../hooks/scoring/useCreditWorkingAssetsCreationScoringBriefData";
import { isLoading } from "../../../common/types/state";
import { CreditWorkingAssetsCreationScoringCreateByExistsBrief } from "../components/scoring/create-by-exists/CreditWorkingAssetsCreationScoringCreateByExistsBrief";

export interface CreditWorkingAssetsCreationCreateByExistsPageProps {
  match: CreditWorkingAssetsCreationCreateByExistsUrlParams;
}

const Page = ({ match }: CreditWorkingAssetsCreationCreateByExistsPageProps) => {
  const [activePageType, setActivePageType] = useState<CreditWorkingAssetsCreationCreateByExistsPageType>("scoring");
  const { data: brief, status: briefStatus } = useCreditWorkingAssetsCreationScoringBriefData(
    match.creditWorkingAssetsId,
  );

  return (
    <>
      <CreditWorkingAssetsCreationCreateByExistsHeader
        onPageTypeClick={setActivePageType}
        activePage={activePageType}
      />
      <FullPageContainerMainContentWrapper>
        <ProductCreationCreateByExistsMainContentWrapper>
          <ThemeMobileDesktopView
            mobile={
              <>
                {activePageType === "details" && (
                  <CreditWorkingAssetsCreationScoringCreateByExistsBrief
                    isLoading={isLoading(briefStatus)}
                    data={brief}
                  />
                )}
                {activePageType === "scoring" && (
                  <CreditWorkingAssetsCreationScoringCreateByExistsForm
                    creditWorkingAssetsId={match.creditWorkingAssetsId}
                    creditSum={brief?.creditSum}
                  />
                )}
              </>
            }
            desktop={
              <>
                <CreditWorkingAssetsCreationScoringCreateByExistsForm
                  creditWorkingAssetsId={match.creditWorkingAssetsId}
                  creditSum={brief?.creditSum}
                />
                <ProductCreationCreateByExistsBriefContainer>
                  <CreditWorkingAssetsCreationScoringCreateByExistsBrief
                    isLoading={isLoading(briefStatus)}
                    data={brief}
                  />
                </ProductCreationCreateByExistsBriefContainer>
              </>
            }
          />
        </ProductCreationCreateByExistsMainContentWrapper>
      </FullPageContainerMainContentWrapper>
    </>
  );
};

export const CreditWorkingAssetsCreationCreateByExistsPage = withValidUrlParams(
  Page,
  (x) => x.creditWorkingAssets.creation.createByCreditWorkingAssets,
  creditWorkingAssetsCreationCreateByExistsUrlValidationSchema,
  <Redirect to={(x) => x.creditWorkingAssets.list.root} />,
);
