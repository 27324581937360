import styled from "@emotion/styled";

export const Ol = styled.ol`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.sm};
  list-style: none;
  counter-reset: list-ol;

  & > li {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spaces.xs};

    &::before {
      counter-increment: list-ol;
      content: counter(list-ol);
      display: block;
      font-size: ${({ theme }) => theme.font.fz4};
      font-weight: 500;
      font-feature-settings: "tnum";
      min-width: 2ch;
      line-height: ${({ theme }) => theme.font.defaultLineHeight};
    }
  }
`;
