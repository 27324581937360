import { PublicUserResetPasswordForm } from "./PublicUserResetPasswordForm";
import {
  AppPublicLayoutBlockWrapper,
  AppPublicLayoutLinkButton,
} from "../../../../common/ui/layout/app-public-layout-containers";
import { usePublicUserResetPasswordInitial } from "../../hooks/usePublicUserResetPasswordInitial";
import {
  PublicUserCardWithTitle,
  PublicUserDescriptionSuccessText,
  PublicUserDescriptionTypography,
} from "../../../common/components";

export const PublicUserResetPasswordInitial = () => {
  const { onSubmit, data } = usePublicUserResetPasswordInitial();

  return (
    <AppPublicLayoutBlockWrapper>
      <PublicUserCardWithTitle title="Сброс пароля">
        {data === undefined && <PublicUserResetPasswordForm onSubmit={onSubmit} />}
        {data !== undefined && (
          <PublicUserDescriptionTypography>
            <PublicUserDescriptionSuccessText
              startText="Отправили письмо с ссылкой для сброса пароля"
              phone={data.phone}
              email={data.email}
            />
          </PublicUserDescriptionTypography>
        )}
      </PublicUserCardWithTitle>
      <AppPublicLayoutLinkButton to={(x) => x.publicUser.authentication.login}>
        Вернуться ко входу
      </AppPublicLayoutLinkButton>
    </AppPublicLayoutBlockWrapper>
  );
};
