import styled from "@emotion/styled";
import { FullPageContainerMainContentWrapper } from "../../../ui/containers/full-page";

export const ProductCreationCreateByExistsMainContentWrapper = styled(FullPageContainerMainContentWrapper)`
  padding: ${({ theme }) => theme.spaces.sm} calc(${({ theme }) => theme.layout.borderPadding} / 2)
    ${({ theme }) => theme.spaces.xxl};
  width: 100%;

  & > *:only-child {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    padding: ${({ theme }) => theme.spaces.xxl} calc(${({ theme }) => theme.layout.borderPadding});
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spaces.xxl};

    & > *:only-child {
      width: auto;
    }
  }

  ${({ theme }) => theme.breakpoints.mqUp("xxl")} {
    width: ${({ theme }) => theme.breakpoints.values.xxl}px;
  }
`;
