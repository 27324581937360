import { Fragment } from "react";
import { LegalServicesProductId } from "../../../common/types";
import { LineSeparator } from "../../../../common/ui-kit/separators";
import { LegalServicesCreationFormDocumentField } from "./LegalServicesCreationFormDocumentField";
import { useLegalServicesCreationFormDocuments } from "../../hooks/form/useLegalServicesCreationFormDocuments";

export interface LegalServicesCreationFormDocumentsProps {
  productId: LegalServicesProductId;
  isDisabled: boolean;
  isLoading: boolean;
}

export const LegalServicesCreationFormDocuments = ({
  productId,
  isDisabled,
  isLoading,
}: LegalServicesCreationFormDocumentsProps) => {
  const documents = useLegalServicesCreationFormDocuments(productId);

  return (
    documents &&
    documents.map((document, index, array) => (
      <Fragment key={document.docType}>
        <LegalServicesCreationFormDocumentField document={document} isLoading={isLoading} isDisabled={isDisabled} />
        {index !== array.length - 1 && <LineSeparator />}
      </Fragment>
    ))
  );
};
