import { useAppSelector } from "../../../../../common/store";
import { BankGuaranteeListItemRejectDemandView } from "./BankGuaranteeListItemRejectDemandView";
import { selectBankGuaranteeListItemModification } from "../../../store/item-modification/bankGuaranteeListItemModification.selectors";
import {
  isReissueGuaranteeBankGuaranteeListItemModification,
  isRejectDemandBankGuaranteeListItemModification,
  isRejectGuaranteeBankGuaranteeListItemModification,
  isRequestOriginalGuaranteeBankGuaranteeListItemModification,
} from "../../../store/item-modification/bankGuaranteeListItemModification.types";
import { BankGuaranteeListItemRejectGuaranteeView } from "./BankGuaranteeListItemRejectGuaranteeView";
import { BankGuaranteeListItemReissueGuaranteeView } from "./BankGuaranteeListItemReissueGuaranteeView";
import { BankGuaranteeListItemRequestOriginalCreationGuaranteeView } from "./BankGuaranteeListItemRequestOriginalCreationGuaranteeView";

export const BankGuaranteeListModification = () => {
  const modification = useAppSelector(selectBankGuaranteeListItemModification);

  return (
    <>
      {isRejectDemandBankGuaranteeListItemModification(modification) && (
        <BankGuaranteeListItemRejectDemandView
          demandId={modification.demandId}
          guaranteeId={modification.guaranteeId}
        />
      )}
      {isRejectGuaranteeBankGuaranteeListItemModification(modification) && (
        <BankGuaranteeListItemRejectGuaranteeView guaranteeId={modification.guaranteeId} />
      )}
      {isReissueGuaranteeBankGuaranteeListItemModification(modification) && (
        <BankGuaranteeListItemReissueGuaranteeView
          demandId={modification.demandId}
          guaranteeId={modification.guaranteeId}
        />
      )}
      {isRequestOriginalGuaranteeBankGuaranteeListItemModification(modification) && (
        <BankGuaranteeListItemRequestOriginalCreationGuaranteeView
          demandId={modification.demandId}
          guaranteeId={modification.guaranteeId}
        />
      )}
    </>
  );
};
