import { useScrollToTopOnMount } from "../../../common/hooks/scroll";
import { usePageTitle } from "../../../common/navigation/page-title";
import { AppLayoutResponsiveListPage } from "../../../common/ui/layout/app-layout-containers";
import { CreditWorkingAssetsListHeader } from "../components/header/CreditWorkingAssetsListHeader";
import { useGetQueryParams } from "../../../common/filters";
import { usePaginatedQueryStateMutator } from "../../../common/filters/use-cases";
import { creditWorkingAssetsListFiltersValidationSchema } from "../validation/creditWorkingAssetsListFiltersValidationSchema";
import { CreditWorkingAssetsListFilterContext } from "../store/filter-context/CreditWorkingAssetsListFilterContext";
import { CreditWorkingAssetsListFilterApiContext } from "../store/filter-context/CreditWorkingAssetsListFilterApiContext";
import { CreditWorkingAssetsList } from "../components/list/CreditWorkingAssetsList";
import { useAppSelector } from "../../../common/store";
import { selectCreditWorkingAssetsListCounters } from "../store/counters/creditWorkingAssetsListCounters.selectors";
import { useFirstRenderThunkDispatch } from "../../../common/hooks";
import { getCountersCreditWorkingAssetsList } from "../store/creditWorkingAssetsList.thunks";

export const CreditWorkingAssetsListPage = () => {
  usePageTitle("Мои заявки на кредит на ПОС");
  useScrollToTopOnMount();

  const [filteringObject, forceUpdate] = useGetQueryParams(creditWorkingAssetsListFiltersValidationSchema);
  const mutator = usePaginatedQueryStateMutator();
  const { status } = useAppSelector(selectCreditWorkingAssetsListCounters);

  useFirstRenderThunkDispatch(status, getCountersCreditWorkingAssetsList);

  return (
    <AppLayoutResponsiveListPage>
      <CreditWorkingAssetsListFilterContext.Provider value={filteringObject}>
        <CreditWorkingAssetsListFilterApiContext.Provider value={mutator}>
          <CreditWorkingAssetsListHeader />
          <CreditWorkingAssetsList onErrorReloadButtonClick={forceUpdate} />
        </CreditWorkingAssetsListFilterApiContext.Provider>
      </CreditWorkingAssetsListFilterContext.Provider>
    </AppLayoutResponsiveListPage>
  );
};
