import styled from "@emotion/styled";
import { Icon as IconComponent } from "../../../icons";

export const Icon = styled(IconComponent)``;

export const Button = styled.button`
  appearance: none;
  background: none;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  gap: ${({ theme }) => theme.spaces.sm};
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fz3};
  line-height: ${({ theme }) => theme.font.defaultLineHeight};
  user-select: none;

  ${({ theme }) => theme.breakpoints.mqUp(theme.breakpoints.desktopBreakpoint)} {
    & > svg {
      height: ${({ theme }) => theme.font.fz2};
    }

    font-size: ${({ theme }) => theme.font.fz2};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};

    & > svg > path {
      stroke: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  &:focus-visible {
    text-decoration: underline;
  }

  & > svg {
    width: ${({ theme }) => theme.font.fz3};

    & > path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;
