import styled from "@emotion/styled";

export const DemandQueueTaskSeparator = styled.hr`
  display: block;
  border: none;
  appearance: none;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.input.fieldsetBorder};
  width: 100%;
  margin: 0;
  padding: 0;
`;
