import { useFormikContext } from "formik";
import { useThemeDesktopBreakpoint } from "@sideg/ui-kit/helpers/responsive";
import { PhoneNumberInput } from "@sideg/ui-kit/atoms/form-controls/phone-number-input";
import { FormInput } from "@sideg/ui-kit/atoms/form-controls/input";
import { PhoneAdditionalNumberInput } from "../../../../../../../../common/ui-kit/form-controls/phone-number-input";
import { useGetFieldName, useGetFormFieldError } from "../../../../../../../../common/form";
import { BankGuaranteeDetailsComplicatedTaskProjectFormValues } from "../../../../types/BankGuaranteeDetailsComplicatedTaskProjectFormValues";
import { Box } from "../../../../../../../../common/ui-kit/containers/box";
import { BaseCssProps } from "../../../../../../../../common/ui-kit/types";
import { DemandQueueTaskActiveProjectInfoContacts } from "../../../../../../../../demand/queue-tasks";
import { BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActual } from "./actual/BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActual";
import * as S from "./BankGuaranteeDetailComplicatedTaskProjectActiveFormContactFields.styled";

export interface BankGuaranteeDetailComplicatedTaskProjectActiveFormContactFieldsProps extends BaseCssProps {
  isDisabled?: boolean;
  entityType: "client" | "beneficiary";
  actualContacts?: DemandQueueTaskActiveProjectInfoContacts;
}

export const BankGuaranteeDetailComplicatedTaskProjectActiveFormContactFields = ({
  isDisabled,
  entityType,
  actualContacts,
  className,
}: BankGuaranteeDetailComplicatedTaskProjectActiveFormContactFieldsProps) => {
  const desktopBreakpoint = useThemeDesktopBreakpoint();

  const getFieldName = useGetFieldName<BankGuaranteeDetailsComplicatedTaskProjectFormValues>();
  const getFieldError = useGetFormFieldError<BankGuaranteeDetailsComplicatedTaskProjectFormValues>();
  const form = useFormikContext<BankGuaranteeDetailsComplicatedTaskProjectFormValues>();

  const label = entityType === "client" ? "клиента" : "заказчика";

  return (
    <Box display="flex" flexDirection="column" gap={{ xs: "sm", [desktopBreakpoint]: "lg" }} className={className}>
      <S.GridContainer>
        <PhoneNumberInput
          disabled={isDisabled}
          label={`Телефон ${label}`}
          {...form.getFieldProps(getFieldName(`${entityType}PhoneNumber`))}
          error={getFieldError(form, `${entityType}PhoneNumber`)}
        />
        <PhoneAdditionalNumberInput
          disabled={isDisabled}
          label="Добавочный"
          {...form.getFieldProps(getFieldName(`${entityType}AdditionalNumber`))}
          error={getFieldError(form, `${entityType}AdditionalNumber`)}
        />
        <FormInput
          disabled={isDisabled}
          type="email"
          label={`Эл. почта ${label}`}
          {...form.getFieldProps(getFieldName(`${entityType}Email`))}
          error={getFieldError(form, `${entityType}Email`)}
        />
      </S.GridContainer>
      {actualContacts && (
        <BankGuaranteeDetailComplicatedTaskProjectActiveFormContactsActual
          additionalNumber={actualContacts.additionalNumber}
          phone={actualContacts.phone ?? ""}
          email={actualContacts.email ?? ""}
          entityType={entityType}
        />
      )}
    </Box>
  );
};
