import { BankGuaranteeCreationScoringResultRow } from "./BankGuaranteeCreationScoringResultRow";
import { BankGuaranteeCreationScoringRewardAndPrice } from "../reward/BankGuaranteeCreationScoringRewardAndPrice";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { ScoringToggleBankButton } from "../../../../../common/ui/buttons/scoring-toogle";

export interface BankGuaranteeCreationScoringSuccessResultRowProps extends BaseCssProps {
  bankName: string;
  price?: number;
  reward?: number;
  isSelected?: boolean;
  onClick?: () => void | Promise<void>;
  isDisabled?: boolean;
}

export const BankGuaranteeCreationScoringSuccessResultRow = ({
  bankName,
  price,
  reward,
  isSelected,
  onClick,
  isDisabled,
  className,
}: BankGuaranteeCreationScoringSuccessResultRowProps) => {
  return (
    <BankGuaranteeCreationScoringResultRow
      isSelected={isSelected}
      bankName={bankName}
      details={<BankGuaranteeCreationScoringRewardAndPrice price={price} reward={reward} />}
      action={onClick && <ScoringToggleBankButton isSelected={isSelected} onClick={onClick} isDisabled={isDisabled} />}
      className={className}
    />
  );
};
