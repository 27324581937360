import styled from "@emotion/styled";

export const Button = styled.button`
  appearance: none;
  border: none;
  background: none;
  padding-right: ${({ theme }) => theme.spaces.sm};
  display: flex;
  align-items: center;

  & > svg > path {
    stroke: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    & > svg > path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;
