import { CSSObject } from "@emotion/styled/macro";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { makeResponsiveStyles } from "@sideg/ui-kit";
import { ComponentProps } from "react";
import { colorWithAlpha } from "../../../../ui-kit/utils";
import { ElementColor, getElementColor, ResponsiveStyleValue, SpaceType } from "../../../../ui-kit/types";
import { AppLink } from "../../../../urls/internal/components";

const APP_LINK_PROPS_KEYS: (keyof ComponentProps<typeof AppLink>)[] = ["to", "state", "params", "search", "hash"];
const shouldForwardProp = (key: string) => {
  return isPropValid(key) || (APP_LINK_PROPS_KEYS as string[]).includes(key);
};

export interface CardProps {
  isActive?: boolean;
  isSecondary?: boolean;
  backgroundColor?: ElementColor;
  px?: ResponsiveStyleValue<SpaceType>;
  py?: ResponsiveStyleValue<SpaceType>;
  borderRadius?: ResponsiveStyleValue<CSSObject["borderRadius"]>;
  isFullWidth?: ResponsiveStyleValue<boolean>;
}

export const Card = styled(AppLink, { shouldForwardProp })<CardProps>`
  ${({ theme, borderRadius }) => makeResponsiveStyles(theme, borderRadius, (x) => ({ borderRadius: x }))}
  ${({ theme, isFullWidth }) => makeResponsiveStyles(theme, isFullWidth, (x) => (x ? { width: "100%" } : {}))}
  ${({ theme, px }) =>
    makeResponsiveStyles(theme, px, (x) => ({
      paddingLeft: x ? theme.spaces[x] : undefined,
      paddingRight: x ? theme.spaces[x] : undefined,
    }))};
  ${({ theme, py }) =>
    makeResponsiveStyles(theme, py, (x) => ({
      paddingTop: x ? theme.spaces[x] : undefined,
      paddingBottom: x ? theme.spaces[x] : undefined,
    }))};
  background-color: ${({ theme, isSecondary, backgroundColor }) =>
    isSecondary
      ? colorWithAlpha(theme.palette.background.light, Math.round(255 * 0.75))
      : getElementColor(backgroundColor, theme) ?? theme.palette.background.light};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.xs};
  border: 2px solid ${({ theme, isActive }) => (isActive ? theme.colors.primary : "transparent")};
  text-decoration: none;
  border-radius: 4px;
  appearance: none;
  color: currentColor;
  user-select: none;
  flex-grow: 1;
  flex-shrink: 1;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;
