import { ErrorBoundary } from "@sentry/react";
import { PublicUserPrivacyPoliciesCard } from "../components/PublicUserPrivacyPoliciesCard";
import {
  AppPublicLayoutBlockWrapper,
  AppPublicLayoutLinkButton,
} from "../../../common/ui/layout/app-public-layout-containers";
import { usePageTitle } from "../../../common/navigation/page-title";
import { useNavigationStateFromWithFallback } from "../../../common/navigation/navigation-state";
import { generatePath } from "../../../common/urls/internal/utils";
import { ErrorBoundaryDefaultFallbackAlert } from "../../../common/ui/containers/error-boundary";

export const PublicUserPrivacyPoliciesPage = () => {
  usePageTitle("Политика обработки персональных данных");

  const { from } = useNavigationStateFromWithFallback(
    generatePath((x) => x.root),
    "Вернуться на главную",
  );

  return (
    <AppPublicLayoutBlockWrapper>
      <ErrorBoundary fallback={<ErrorBoundaryDefaultFallbackAlert />}>
        <PublicUserPrivacyPoliciesCard />
        <AppPublicLayoutLinkButton to={from.url}>{from.backTitle}</AppPublicLayoutLinkButton>
      </ErrorBoundary>
    </AppPublicLayoutBlockWrapper>
  );
};
