import { ReactNode } from "react";
import { Box } from "../../../../../common/ui-kit/containers/box";
import { Typography } from "../../../../../common/ui-kit/typography";
import * as S from "./LegalServicesCreationCard.styled";
import { BaseCssProps } from "../../../../../common/ui-kit/types";

export interface LegalServicesCreationCardProps extends BaseCssProps {
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
  children: ReactNode;
}

export const LegalServicesCreationCard = ({
  icon,
  title,
  description,
  children,
  className,
}: LegalServicesCreationCardProps) => {
  return (
    <S.Container display="flex" flexDirection="column" gap="xxl" className={className}>
      <Box display="flex" flexDirection="column" gap="xxl" alignItems="center" flexGrow={1}>
        {icon}
        <Typography fontColor="dark" fontSize={1.5} fontWeight={700} textAlign="center">
          {title}
        </Typography>
        <Typography fontColor="dark" fontSize={1} fontWeight={500} lineHeight="defaultLineHeight" textAlign="center">
          {description}
        </Typography>
      </Box>
      {children}
    </S.Container>
  );
};
