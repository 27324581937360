import { currencyHelper } from "@sideg/helpers";
import { NavigationState } from "../../../../../../../../common/navigation/navigation-state";
import { useAppDispatch, useAppSelector } from "../../../../../../../../common/store";
import { CreditExecutionId, DemandId } from "../../../../../../../../common/types/demand";
import { BaseCssProps } from "../../../../../../../../common/ui-kit/types";
import { ProductListItemDemandItemLayoutDesktop } from "../../../../../../../../common/ui/product/list/product-list-item";
import { CreditExecutionStageIcon } from "../../../../../../../common/components";
import { useCreditExecutionListItemLink } from "../../../../../../hooks/useCreditExecutionListItemLink";
import { onDemandRejectCreditExecutionListItemModification } from "../../../../../../store/item-modification/creditExecutionListItemModification.slice";
import { selectCreditExecutionListItemsDemandById } from "../../../../../../store/items/creditExecutionListItems.selectors";
import { getCreditExecutionDemandStageColorBankLabel } from "../../../../../../utils/getCreditExecutionDemandStageColorBankLabel";
import { getCreditExecutionDemandStageColorStatus } from "../../../../../../utils/getCreditExecutionDemandStageColorStatus";

export interface CreditExecutionListItemDemandDesktopProps extends BaseCssProps {
  creditExecutionId: CreditExecutionId;
  demandId: DemandId;
  from: NavigationState;
}

export const CreditExecutionListItemDemandDesktop = ({
  creditExecutionId,
  demandId,
  from,
  className,
}: CreditExecutionListItemDemandDesktopProps) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectCreditExecutionListItemsDemandById(state, demandId))!;
  const linkProps = useCreditExecutionListItemLink(creditExecutionId, demandId);

  const onRemoveClick = () => {
    dispatch(onDemandRejectCreditExecutionListItemModification({ creditExecutionId, demandId }));
  };

  return (
    <ProductListItemDemandItemLayoutDesktop className={className}>
      <ProductListItemDemandItemLayoutDesktop.Icon icon={<CreditExecutionStageIcon stage={demand.stage.code} />} />
      <ProductListItemDemandItemLayoutDesktop.Bank
        bankTitle={demand.bank.title}
        linkProps={linkProps}
        from={from}
        fontColor={getCreditExecutionDemandStageColorBankLabel(demand.stage.code)}
        isLineThrough={demand.stage.code === "Rejected"}
        isUpdated={demand.isUpdated}
      />
      <ProductListItemDemandItemLayoutDesktop.Sum
        fontColor={demand.isConfirmed ? "typography.successSecondary" : "typography.medium"}
        sum={currencyHelper.getCurrencyStringNullable(demand.confirmedCreditSum ?? demand.creditSum, "-")}
      />
      <ProductListItemDemandItemLayoutDesktop.StatusContainer
        statusTitle={demand.status.title}
        linkProps={linkProps}
        from={from}
        fontColor={getCreditExecutionDemandStageColorStatus(demand.stage.code, demand.countActiveTasks > 0)}
      >
        <span>{demand.status.title}</span>
        {demand.countActiveTasks > 1 && !demand.isTerminated && (
          <ProductListItemDemandItemLayoutDesktop.StatusLinkCounter countActiveTasks={demand.countActiveTasks} />
        )}
        {demand.termination?.comment && (
          <ProductListItemDemandItemLayoutDesktop.RejectTooltipIcon
            reason={demand.termination.reason.title}
            comment={demand.termination.comment}
          />
        )}
      </ProductListItemDemandItemLayoutDesktop.StatusContainer>
      <ProductListItemDemandItemLayoutDesktop.DateLabel date={demand.dateUpdated} />
      <ProductListItemDemandItemLayoutDesktop.RemoveButton
        onRemoveClick={onRemoveClick}
        canRemove={!demand.isTerminated}
      />
    </ProductListItemDemandItemLayoutDesktop>
  );
};
