import { useCallback } from "react";
import { useAppDispatch } from "../../../../common/store";
import { FormSubmitHandler, formValidationFieldService } from "../../../../common/form";
import { registerAgentPublicUserRegistration } from "../../store/publicUserRegistration.thunks";
import { publicUserRegistrationFormValidationSchema } from "../../validation/publicUserRegistrationFormValidationSchema";
import { changeStagePublicUserRegistrationAgent } from "../../store/agent/publicUserRegistrationAgent.slice";
import { useAppNavigation } from "../../../../common/urls/internal/hooks";
import { PublicUserRegistrationFormValues } from "../../types/PublicUserRegistrationFormValues";

export const usePublicUserRegistrationRegisterAgentFormOnSubmit =
  (): FormSubmitHandler<PublicUserRegistrationFormValues> => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigation();

    return useCallback(
      async (values, { setErrors }) => {
        try {
          const castedValues = publicUserRegistrationFormValidationSchema.cast(values);
          const result = await dispatch(registerAgentPublicUserRegistration(castedValues)).unwrap();
          if (result.body?.agencyId !== undefined) {
            dispatch(changeStagePublicUserRegistrationAgent("documents"));
            navigate((x) => x.publicUser.registration.agent, { agencyId: result.body.agencyId });
          }
        } catch (e: unknown) {
          const formErrors =
            formValidationFieldService.tryGetValidationErrorsNullable<PublicUserRegistrationFormValues>(e);
          if (formErrors !== undefined) {
            setErrors(formErrors);
          }
        }
      },
      [dispatch, navigate]
    );
  };
