import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { ModalWrapper } from "../../../../wrapper/ModalWrapper";
import AgentReportFileModel from "../../../../../../models/AgentReportFileModel";
import AgentReportType from "../../../../../../models/AgentReportType";
import { Modal } from "../../../Modal";
import AttachmentLink from "../../../../../controls-old/attachment/AttachmentLink";
import Loader from "../../../../../common/Loader";
import { ISelectValue } from "../../../../../controls-old/select/Select";
import SelectRequired from "../../../../../controls-old/select/SelectRequired";
import Button, { ButtonColor, ButtonType } from "../../../../../controls-old/button/Button";
import IValue from "../../../../../controls-old/IValue";
import { fileHash } from "../../../../../../store/actions/fileActions";
import { FileStatus, IFileState } from "../../../../../../store/actions/fileActionsTypes";
import { agentReportSignFile } from "../../../../../../store/actions/agentReportActions";
import { AgentReportAction, AgentReportSignForm } from "../../../../../../store/actions/agentReportActionsTypes";
import "../AgentReportSignModal.scss";
import { useScript } from "../../../../../../domains/common/hooks";
import { LoadingStatusEnum } from "../../../../../../domains/common/types/state";
import { useAppDispatch } from "../../../../../../domains/common/store";

import CertificateAdjuster from "../cadesplugin/certificateAdjuster";
import { doesPluginWork, getCerificates, getCerificateInfo, signHash, gost } from "../cadesplugin/cadespluginApi";

interface IAgentReportSignSimplifiedModalWrapperProps {
  reportId: number;
  files: AgentReportFileModel[];
  onClose: (sign: boolean) => void;
}

export const AgentReportSignQualifiedModalWrapper: React.FC<IAgentReportSignSimplifiedModalWrapperProps> = (props) => {
  const status = useScript(`/assets/js/cadesplugin_api.js`);
  return status === LoadingStatusEnum.Succeeded ? <AgentReportSignQualifiedModal {...props} /> : <></>;
};

const AgentReportSignQualifiedModal: React.FC<IAgentReportSignSimplifiedModalWrapperProps> = (props) => {
  const dispatch = useAppDispatch();

  const [isSign, setIsSign] = useState(false);
  const [pluginWork, setPluginWork] = useState<boolean>();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState<string[]>();

  const [files, setFiles] = useState<AgentReportFileModel[]>(props.files);

  const [thumbprint, setThumbprint] = useState<IValue>({ isValid: false });
  const [viewThumbprintError, setViewThumbprintError] = useState(false);

  const [certs, setCerts] = useState<ISelectValue[]>([]);

  useEffect(() => {
    setLoader(true);
    doesPluginWork(onDoesPluginWork, onError);
  }, []);

  const onDoesPluginWork = (result: any) => {
    setLoader(false);

    if (result) {
      setPluginWork(true);
      getCerts();
    } else {
      setPluginWork(false);
    }
  };

  const getCerts = () => {
    let certProperties = ["Thumbprint", "SubjectName", "ValidFromDate", "ValidToDate"];
    setLoader(true);
    getCerificates(onCertReceive, onError, certProperties, validTimeFilterFunc, keyAlgFilterFunc);
  };

  const onCertReceive = (data: any) => {
    const certAdj = Object.create(CertificateAdjuster);
    const certs = Array<ISelectValue>();

    for (let i = 0; i < data.length; ++i) {
      const cert = data[i];
      certs.push({
        key: cert.Thumbprint,
        value:
          certAdj.getCertDate(cert.ValidFromDate) +
          " - " +
          certAdj.getCertDate(cert.ValidToDate) +
          " " +
          certAdj.getCertName(cert.SubjectName),
      });
    }

    setCerts(certs);
    setLoader(false);
    if (certs.length === 0) {
      let errs = errors?.slice() ?? [];
      errs.push("Не удалось найти подходящие сертификаты");
      setErrors(errs);
    }
  };

  const keyAlgFilterFunc = (keyAlg: string): boolean => {
    return (
      keyAlg == gost.OID_CP_GOST_R3410EL ||
      keyAlg == gost.OID_CP_GOST_R3410_12_256 ||
      keyAlg == gost.OID_CP_GOST_R3410_12_512
    );
  };

  const validTimeFilterFunc = (certValidFromDate: Date, certValidToDate: Date): boolean => {
    const nowTime = new Date().getTime();
    return nowTime >= certValidFromDate.getTime() && nowTime <= certValidToDate.getTime();
  };

  const onError = (err: any) => {
    setErrors((prev) => {
      let errs = prev?.slice() ?? [];
      errs.push(err);
      return errs;
    });
    setLoader(false);
  };

  const handleModalClose = () => {
    props.onClose(isSign);
  };

  const getFileTitle = (item: AgentReportFileModel): string => {
    const title = [item.issuingCompany.name];

    if (item.typeReport === AgentReportType.Common) {
      title.push("общий");
    } else if (item.typeReport === AgentReportType.Other) {
      title.push("другие продукты");
    } else {
      title.push("банковские гарантии");
    }

    return title.join(" / ");
  };

  const handleSign = () => {
    setErrors((prev) => undefined);
    if (!thumbprint.isValid) {
      setViewThumbprintError(true);
      return;
    }
    setLoader(true);
    getCerificateInfo(onCertInfo, onError, thumbprint.value);
  };

  const onCertInfo = (certificate: any) => {
    const providerType = certificate.PrivateKey.ProviderType;
    files.forEach((agentFile) => {
      const fileId = agentFile.fileManager.fileId;
      const privateKey = agentFile.fileManager.privateKey ?? null;
      dispatch(
        fileHash(fileId, privateKey, providerType, (state: IFileState) => {
          switch (state.status) {
            case FileStatus.Processing:
              break;
            case FileStatus.Failed:
              setErrors((prev) => {
                let errs = prev?.slice() ?? [];
                errs.push(state.errorMessage!!);
                return errs;
              });
              break;
            case FileStatus.Success:
              signHash(
                (sign: any) => {
                  const form = {
                    fileId: fileId,
                    privateKey: privateKey,
                    sign: sign,
                  } as AgentReportSignForm;
                  dispatch(
                    agentReportSignFile(props.reportId, form, (state: any) => {
                      switch (state.type) {
                        case AgentReportAction.Processing:
                          break;
                        case AgentReportAction.Failed:
                          setErrors((prev) => {
                            let errs = prev?.slice() ?? [];
                            errs.push(state.errorMessage!!);
                            return errs;
                          });
                          break;
                        case AgentReportAction.Success:
                          setIsSign(state.data.reportSigned);
                          if (!state.data.result) {
                            setErrors((prev) => {
                              let errs = prev?.slice() ?? [];
                              errs.push(state.data.message);
                              return errs;
                            });
                          }
                          break;
                      }
                    }),
                  );
                },
                onError,
                state.data,
                thumbprint.value,
                true,
              );
              break;
          }
        }),
      );
    });
  };

  return (
    <ModalWrapper onClose={handleModalClose}>
      <Modal title={"Подписать документы акта сверки"} message={"Будут подписаны следующие документы:"}>
        <table className="table table_condensed table_bordered">
          <tbody className="table__body">
            {files.map((item, index) => {
              return (
                <tr className="table__row" key={index}>
                  <td className="table__row-item">{getFileTitle(item)}</td>
                  <td className="table__row-item">
                    <AttachmentLink file={item.fileManager} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="agent-report-sign-modal__actions">
          {loader && !isSign && !errors && <Loader />}
          {pluginWork !== undefined && !pluginWork && (
            <div className="alert alert_danger">КриптоПро ЭЦП Browser plug-in не установлен или заблокирован.</div>
          )}
          {errors && (
            <div className="alert alert_danger">
              {errors.map((item, index) => {
                return (
                  <Fragment key={index}>
                    {item}
                    <br />
                  </Fragment>
                );
              })}
            </div>
          )}
          {!isSign && certs.length > 0 && (
            <>
              <SelectRequired
                values={certs}
                value={thumbprint.value}
                placeholder={"Выберите сертификат"}
                viewError={viewThumbprintError}
                name={"cert-thumbprint"}
                onChange={(e) => setThumbprint(e)}
              />

              <Button
                onClick={() => handleSign()}
                isDisabled={false}
                type={ButtonType.Button}
                color={ButtonColor.Green}
              >
                Подписать
              </Button>
            </>
          )}
          {isSign && <div className="alert alert_success">Документы подписаны</div>}
        </div>
      </Modal>
    </ModalWrapper>
  );
};
