import { ButtonLink } from "../../../ui-kit/buttons/ButtonLink";
import { Text } from "../../../ui-kit/typography";
import { useProductCreationIsBeforeActiveStage } from "../../hooks/creation/useProductCreationIsBeforeActiveStage";
import { ProductCreationStages, productCreationStages } from "../../types/ProductCreationStages";

export interface ProductCreationPageHeaderStageProps {
  stage: ProductCreationStages;
  activeStage: ProductCreationStages;
  onClick: (stage: ProductCreationStages) => void | Promise<void>;
}

export const ProductCreationPageHeaderStage = ({
  activeStage,
  stage,
  onClick,
}: ProductCreationPageHeaderStageProps) => {
  const isBeforeActive = useProductCreationIsBeforeActiveStage(stage, activeStage);

  return (
    <Text
      as="div"
      fontSize="fz2"
      lineHeight="defaultLineHeight"
      fontWeight={stage === activeStage ? 600 : 400}
      fontColor={stage === activeStage ? "primary" : "primaryCaption"}
    >
      {isBeforeActive ? (
        <ButtonLink type="button" onClick={() => onClick(stage)}>
          {productCreationStages.getTitle(stage)}
        </ButtonLink>
      ) : (
        productCreationStages.getTitle(stage)
      )}
    </Text>
  );
};
