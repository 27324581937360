import { useState } from "react";
import { BaseCssProps } from "../../../../../../../common/ui-kit/types";
import { useAppSelector } from "../../../../../../../common/store";
import { FilterFormMobileCheckboxesWithCollapse } from "../../../../../../../common/filters/use-cases";
import { FormSearchInput } from "../../../../../../../common/ui-kit/form-controls/search-input";
import { selectCreditExecutionListFiltersBanks } from "../../../../../store/filters/creditExecutionListFilters.selectors";
import { CreditExecutionListFiltersMobile } from "../../../../../types/filters/CreditExecutionListFiltersMobile";

export type CreditExecutionListHeaderFiltersBanksMobileProps = BaseCssProps;

export const CreditExecutionListHeaderFiltersBanksMobile = ({
  className,
}: CreditExecutionListHeaderFiltersBanksMobileProps) => {
  const [inputValue, setInputValue] = useState("");
  const banks = useAppSelector((state) => selectCreditExecutionListFiltersBanks(state, inputValue));

  return (
    <FilterFormMobileCheckboxesWithCollapse<CreditExecutionListFiltersMobile>
      title="Банки"
      fieldName="bankIds"
      items={banks}
      className={className}
      beforeList={
        <FormSearchInput
          placeholder="Наименование банка"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          spellCheck={false}
          autoComplete="off"
          autoCorrect="off"
        />
      }
    />
  );
};
