import { useEffect, useState } from "react";
import { BaseCssProps } from "../../../../../common/ui-kit/types";
import { DemandQueueTasksNewComment } from "../../../../../demand/queue-tasks";
import { CommentFormFormValues } from "../../../../../common/product-comment";
import { useApiMutation } from "../../../../../common/hooks";
import { sendRequestOriginalCommentBankGuaranteeDetails } from "../../../store/bankGuaranteeDetails.thunks";
import { useBankGuaranteeDetailsLayoutContext } from "../../../hooks/layout/useBankGuaranteeDetailsLayoutContext";
import { useAppDispatch, useAppSelector } from "../../../../../common/store";
import { updateBankGuaranteeDetailsRequestOriginal } from "../../../store/request-original/bankGuaranteeDetailsRequestOriginal.slice";
import { selectBankGuaranteeDetailsRequestOriginal } from "../../../store/request-original/bankGuaranteeDetailsRequestOriginal.selectors";
import { bankGuaranteeDetailsGuaranteeDetailsActions } from "../../../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.slice";

export type BankGuaranteeDetailsRequestOriginalNewCommentProps = BaseCssProps;

export const BankGuaranteeDetailsRequestOriginalNewComment = ({
  className,
}: BankGuaranteeDetailsRequestOriginalNewCommentProps) => {
  const dispatch = useAppDispatch();

  const { details } = useAppSelector(selectBankGuaranteeDetailsRequestOriginal);
  const [isCommentFormOpened, setIsCommentFormOpened] = useState(details?.canSendComment === true);
  const { error, mutate } = useApiMutation(sendRequestOriginalCommentBankGuaranteeDetails, {
    errorMessagePlaceholder: "",
  });
  const { demandId } = useBankGuaranteeDetailsLayoutContext();

  useEffect(() => {
    if (details?.canSendComment === true) {
      setIsCommentFormOpened(true);
    }
  }, [details?.canSendComment]);

  const onSubmit = async ({ comment, files }: CommentFormFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = await mutate({ demandId: demandId!, comment, files: files?.map((x) => x.file) });
    if (result?.body) {
      setIsCommentFormOpened(false);
      dispatch(bankGuaranteeDetailsGuaranteeDetailsActions.reload());
      dispatch(updateBankGuaranteeDetailsRequestOriginal());
    }
  };

  return (
    <DemandQueueTasksNewComment
      canSendComment={details?.canSendComment === true}
      isCommentFormOpened={isCommentFormOpened}
      onSendCommentButtonClick={() => setIsCommentFormOpened(true)}
      onSubmit={onSubmit}
      onCancel={() => setIsCommentFormOpened(false)}
      className={className}
      submitError={error}
    />
  );
};
