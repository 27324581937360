import styled from "@emotion/styled";
import { SearchInput } from "../../../../ui-kit/inputs/SearchInput";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.xl};
  padding-top: ${({ theme }) => theme.spaces.xxl};
  padding-bottom: ${({ theme }) => theme.spaces.md};
`;

export const Search = styled(SearchInput)`
  min-width: 312px;
  flex-grow: 0;
  flex-shrink: 0;
`;
