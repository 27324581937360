import * as React from "react";
import { DemandQueueTaskActiveDecision } from "../../types/active/DemandQueueTaskActiveDecision";
import { useDemandQueueTaskActiveField } from "../../hooks/active/form/useDemandQueueTaskActiveField";
import {
  FormChipRadioButtonGroup,
  FormChipRadioButtonGroupVariant,
} from "../../../../common/ui-kit/form-controls/chip-radiobutton-group";
import { DemandQueueTaskDecisionType } from "../../types/active/DemandQueueTaskDecisionType";

export interface DemandQueueTaskActiveFormDecisionsFieldProps {
  decisions: DemandQueueTaskActiveDecision[];
  onDecisionChanged?: (decision: DemandQueueTaskActiveDecision) => void | Promise<void>;
  className?: string;
  isDisabled?: boolean;
}

const getChipVariantByDecisionType = (type: DemandQueueTaskDecisionType): FormChipRadioButtonGroupVariant => {
  switch (type) {
    case DemandQueueTaskDecisionType.Success:
      return "success";
    case DemandQueueTaskDecisionType.Failure:
      return "negative";
    default:
      return "neutral";
  }
};

export const DemandQueueTaskActiveFormDecisionsField = ({
  decisions,
  className,
  onDecisionChanged,
  isDisabled,
}: DemandQueueTaskActiveFormDecisionsFieldProps) => {
  const [{ value, name, error, onBlur, onChange }] = useDemandQueueTaskActiveField("decision");

  const handleChange = (decision: DemandQueueTaskActiveDecision) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    onDecisionChanged?.(decision);
  };

  return (
    <FormChipRadioButtonGroup
      disabled={isDisabled}
      error={error}
      label="Выберите короткий ответ или действие"
      className={className}
    >
      {decisions.map((x) => (
        <FormChipRadioButtonGroup.Option
          label={x.title}
          key={x.code}
          onChange={handleChange(x)}
          value={x.code}
          checked={x.code === value}
          name={name}
          variant={getChipVariantByDecisionType(x.type)}
          onBlur={onBlur}
          disabled={isDisabled}
        />
      ))}
    </FormChipRadioButtonGroup>
  );
};
