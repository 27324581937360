import { useRef } from "react";
import * as React from "react";
import { Formik, FormikHelpers } from "formik";
import { BaseCssProps } from "../../../../common/ui-kit/types";
import { useScrollToTopOnMount } from "../../../../common/hooks/scroll";
import { creditWorkingAssetsCreationScoringValidationSchema } from "../../validation/creditWorkingAssetsCreationScoringValidationSchema";
import { FormBox, FormScrollToFirstErrorAfterSubmit } from "../../../../common/form";
import { CreditWorkingAssetsCreationScoringFormValues } from "../../types/scoring/CreditWorkingAssetsCreationScoringFormValues";

export interface CreditWorkingAssetsCreationScoringFormProps extends BaseCssProps {
  children: React.ReactNode;
  onSubmit: (
    values: CreditWorkingAssetsCreationScoringFormValues,
    helpers: FormikHelpers<CreditWorkingAssetsCreationScoringFormValues>
  ) => void | Promise<void>;
}

export const CreditWorkingAssetsCreationScoringForm = ({
  children,
  onSubmit,
  className,
}: CreditWorkingAssetsCreationScoringFormProps) => {
  const ref = useRef<HTMLFormElement | null>(null);
  useScrollToTopOnMount("smooth");

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={creditWorkingAssetsCreationScoringValidationSchema.getDefault()}
      validationSchema={creditWorkingAssetsCreationScoringValidationSchema}
    >
      <FormBox ref={ref} flexGrow={1} flexShrink={1} display="flex" flexDirection="column" className={className}>
        <FormScrollToFirstErrorAfterSubmit containerRef={ref} />
        {children}
      </FormBox>
    </Formik>
  );
};
