import * as React from "react";
import * as S from "./ProductDetailsLayout.styled";
import { BaseCssProps } from "../../../ui-kit/types";

export interface ProductDetailsLayoutProps extends BaseCssProps {
  isAsideShown: boolean;
  isOnlyAsideShown: boolean;
  children?: React.ReactNode;
  aside?: React.ReactNode;
}

export const ProductDetailsLayout = React.memo(
  ({ isAsideShown, isOnlyAsideShown, children, aside, className }: ProductDetailsLayoutProps) => {
    return (
      <S.Container className={className}>
        {(isAsideShown || isOnlyAsideShown) && <S.Aside>{aside}</S.Aside>}
        {!isOnlyAsideShown && <S.Content>{children}</S.Content>}
      </S.Container>
    );
  }
);
