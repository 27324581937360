import { SizeInRems } from "@sideg/ui-kit/common/types";

type Result<TObject extends Record<string, number>> = { [K in keyof TObject]: SizeInRems<TObject[K]> };

export const makeRemValues = <TObject extends Record<string, number>>(object: TObject): Result<TObject> => {
  return Object.entries(object).reduce(
    (accum, [key, value]) => {
      accum[key] = `${value}rem`;

      return accum;
    },
    {} as Record<string, SizeInRems>,
  ) as Result<TObject>;
};
