import { useEffect } from "react";
import { widgetsNewsConfig } from "../configs";
import { LoadingStatusEnum } from "../../../types/state";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectWidgetsNewsState } from "../store/widgetsNews.selectors";
import { getWidgetsNews } from "../store/widgetsNews.thunks";
import { widgetsNewsStorageService } from "../services/widgetsNewsStorageService";
import { loggingService } from "../../../logging/services/loggingService";

export const useWidgetsNewsFetch = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectWidgetsNewsState);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const news = await dispatch(getWidgetsNews({ pageSize: widgetsNewsConfig.maxNewsCount })).unwrap();
        widgetsNewsStorageService.save(news.body.data);
      } catch (ex) {
        loggingService.logError(ex);
      }
    };

    if (status === LoadingStatusEnum.Idle) {
      fetchNews();
    }
  }, [dispatch, status]);
};
