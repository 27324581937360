import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { useField, useFormikContext } from "formik";
import styles from "./ClientFormSelect.module.css";
import { ReactComponent as Controls } from "../../../../../images/icons/controls-down.svg";
import cn from "classnames";
import ClientFormSelectOption from "./option/ClientFormSelectOption";

export interface ClientFormSelectOpt<T = any> {
  title: string;
  value: string;
  meta?: T;
}

interface ClientFormSelectProps {
  label?: string;
  options: ClientFormSelectOpt[];
  name: string;
  selectedValue: string | null;
  touched?: any;
  errors?: any;
  removable?: boolean;
}

const ClientFormSelect: React.FC<ClientFormSelectProps> = ({
  label,
  options,
  name,
  selectedValue,
  touched,
  errors,
  removable,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  const optionsBlock = useRef<HTMLDivElement | null>(null);

  const handleClickOutsideOptions = useCallback((e: any) => {
    if (optionsBlock?.current && !optionsBlock.current.contains(e.target)) handleClose();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideOptions);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOptions);
    };
  }, [optionsBlock, handleClickOutsideOptions]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChoice = (value: string): void => {
    if (removable) {
      if (field.value === value) {
        setFieldValue(name, "");
      } else {
        setFieldValue(name, value);
        handleClose();
      }
      return;
    }
    setFieldValue(name, value);
    handleClose();
  };

  const selected = useMemo(() => options.find((x) => x.value == selectedValue), [selectedValue]);

  return (
    <div className={styles["wrapper"]}>
      {label && <div className={styles["label"]}>{label}</div>}
      <div className={styles["container"]} onClick={handleOpen}>
        <Controls
          className={cn({
            [styles["controls"]]: true,
            [styles["upside-down"]]: open,
          })}
        />
        {selected ? (
          <span className={styles["selected"]}>{selected.title}</span>
        ) : (
          <span className={styles["placeholder"]}>Выберите</span>
        )}
      </div>
      {open && (
        <div className={styles["options"]} ref={optionsBlock}>
          {options.map((option) => (
            <ClientFormSelectOption
              key={option.value}
              option={option}
              selected={selectedValue == option.value}
              onChange={handleChoice}
            />
          ))}
        </div>
      )}
      {touched && errors && <p className={styles["error"]}>{errors}</p>}
    </div>
  );
};

export default ClientFormSelect;
