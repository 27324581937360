import { useAppSelector } from "../../../common/store";
import { selectBankGuaranteeDetailsGuaranteeDetails } from "../store/guarantee-details/bankGuaranteeDetailsGuaranteeDetails.selectors";
import { ProductDetailsInformation } from "../../../common/product-details";
import { BankGuaranteeDetailsGuaranteeDetailsAuctionBlock } from "../components/guarantee-details/BankGuaranteeDetailsGuaranteeDetailsAuctionBlock";

export const BankGuaranteeDetailsPage = () => {
  const { data } = useAppSelector(selectBankGuaranteeDetailsGuaranteeDetails);

  return (
    <ProductDetailsInformation data={data}>
      {data?.briefAndFounders && (
        <BankGuaranteeDetailsGuaranteeDetailsAuctionBlock briefAndFounders={data.briefAndFounders} />
      )}
    </ProductDetailsInformation>
  );
};
