import { useAppSelector } from "../../../common/store";
import { selectCreditExecutionDetailsProductDetails } from "../store/product-details/creditExecutionDetailsProductDetails.selectors";
import { ProductDetailsInformation } from "../../../common/product-details";
import { CreditExecutionDetailsAuctionBlock } from "../component/info/CreditExecutionDetailsAuctionBlock";

export const CreditExecutionDetailsPage = () => {
  const { data } = useAppSelector(selectCreditExecutionDetailsProductDetails);

  return (
    <ProductDetailsInformation data={data}>
      {data?.briefAndFounders && <CreditExecutionDetailsAuctionBlock briefAndFounders={data.briefAndFounders} />}
    </ProductDetailsInformation>
  );
};
