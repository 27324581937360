import { RootState } from "../../../../common/store";
import { CreditWorkingAssetsDetailsTasksHistoryState } from "./creditWorkingAssetsDetailsTasksHistory.types";
import { selectCreditWorkingAssetsDetails } from "../creditWorkingAssetsDetails.selectors";
import { demandQueueTasksAdapter } from "../../../../demand/queue-tasks";

export const selectCreditWorkingAssetsDetailsTasksHistory = (
  state: RootState
): CreditWorkingAssetsDetailsTasksHistoryState => selectCreditWorkingAssetsDetails(state).tasksHistory;

export const {
  selectIds: selectCreditWorkingAssetsDetailsTasksHistoryIds,
  selectById: selectCreditWorkingAssetsDetailsTasksHistoryById,
  selectTotal: selectCreditWorkingAssetsDetailsTasksHistoryTotal,
} = demandQueueTasksAdapter.getSelectors(selectCreditWorkingAssetsDetailsTasksHistory);
