import * as React from "react";
import * as S from "./AppPublicLayout.styled";
import { BrandLink } from "../../../navigation";
import { AppPublicLayoutFooter } from "./footer/AppPublicLayoutFooter";
import { GlobalToastsContainer } from "../../../../../global/toasts";

export interface AppPublicLayoutProps {
  children?: React.ReactNode;
}

export const AppPublicLayout = ({ children }: AppPublicLayoutProps) => {
  return (
    <S.Container>
      <S.Header>
        <BrandLink />
      </S.Header>
      <S.Main>{children}</S.Main>
      <GlobalToastsContainer />
      <AppPublicLayoutFooter />
    </S.Container>
  );
};
