import { BankGuaranteeCreationScoringBlocks } from "./BankGuaranteeCreationScoringBlocks";
import { isSucceeded } from "../../../../../common/types/state";
import { useBankGuaranteeCreationSubmitCreateNew } from "../../../hooks/creation-submit/create-new/useBankGuaranteeCreationSubmitCreateNew";
import { useBankGuaranteeCreationScoringSubmitState } from "../../../hooks/creation-submit/useBankGuaranteeCreationScoringSubmitState";
import { FullPageContainerCenteredMainWithFooter } from "../../../../../common/ui/containers/full-page";
import {
  useCreationScoringSelectBanksState,
  useCreationScoringSubmitButtonText,
} from "../../../../../common/product-creation";
import { useScrollToTopOnMount } from "../../../../../common/hooks/scroll";
import { CreationSubmitButton } from "../../../../../common/ui/buttons/creation-submit-button";
import { useAppSelector } from "../../../../../common/store";
import { selectBankGuaranteeCreationScoring } from "../../../store/bankGuaranteeCreation.selectors";

export const BankGuaranteeCreationScoringCreateNew = () => {
  const { toggleBank, selectedBanks, setSelectedBanks } = useCreationScoringSelectBanksState();
  const { status } = useAppSelector(selectBankGuaranteeCreationScoring);

  const submitState = useBankGuaranteeCreationSubmitCreateNew();
  const { onClick, error, isSubmitting } = useBankGuaranteeCreationScoringSubmitState(selectedBanks, submitState);

  const submitButtonText = useCreationScoringSubmitButtonText(selectedBanks.length);

  useScrollToTopOnMount("smooth");

  return (
    <FullPageContainerCenteredMainWithFooter
      footer={
        isSucceeded(status) && (
          <CreationSubmitButton type="button" onClick={onClick} isLoading={isSubmitting} error={error}>
            {submitButtonText}
          </CreationSubmitButton>
        )
      }
    >
      <BankGuaranteeCreationScoringBlocks
        setSelectedBanks={setSelectedBanks}
        onBankSelectToggle={toggleBank}
        selectedBankIds={selectedBanks}
        isDisabled={isSubmitting}
      />
    </FullPageContainerCenteredMainWithFooter>
  );
};
