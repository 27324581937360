import { joinUrlParts, makeAppUrl } from "../../../common/urls/internal/utils";
import { CREDIT_EXECUTION_ROOT_URL } from "../../common/urls";
import { UserFeatureFlagEnum } from "../../../common/types/user";
import { makeUserCheckFeatureFlagPermission } from "../../../common/urls/internal/utils/makeAppUrl";

export const creditExecutionConditionsUrls = {
  root: makeAppUrl(
    joinUrlParts(CREDIT_EXECUTION_ROOT_URL, "conditions"),
    "/credit/conditions",
    makeUserCheckFeatureFlagPermission(UserFeatureFlagEnum.IsProductCe),
  ),
};
