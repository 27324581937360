import { createAppEntityAdapter } from "../../../common/store/utils";
import { IssuingCompanyId } from "../../../common/types/companies";
import { PublicUserPrivacyPoliciesPolicyFile } from "../types/PublicUserPrivacyPoliciesPolicyFile";

export const publicUserPrivacyPoliciesAdapter = createAppEntityAdapter<
  PublicUserPrivacyPoliciesPolicyFile,
  IssuingCompanyId
>({
  selectId: (x) => x.issuingCompanyId,
});
