import styled from "@emotion/styled";

export const Header = styled.header`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.layout.sidebar.menu.linkColor};
  border-bottom: 1px solid ${({ theme }) => theme.layout.sidebar.backgroundColor};
  font-size: ${({ theme }) => theme.font.fz2};
`;
